/**
 * Manage Payment
 *  manages payment add
 */
import _ from 'lodash';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, Formik } from 'formik';
import { Collapse, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown, faPlus, faReceipt, faTimes } from '@fortawesome/free-solid-svg-icons';

import { Datepicker, Input, Select, Textarea } from 'react-formik-ui';
import * as Yup from 'yup';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
// Components
import { Alerts } from '../../../components/ui';
import { Constants, ErrorMessages, SelectList, Strings } from '../../../constants';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import OrderListSelector from '../../order/components/OrderListSelector';

/* Component ==================================================================== */

class PaymentAdd extends React.Component {
  static componentName = 'PaymentManage';

  static propTypes = {
    match: PropTypes.object,
    order: PropTypes.object,
    orders: PropTypes.object,
    property: PropTypes.object,
    guestData: PropTypes.object,
    consolidated: PropTypes.bool,
    guestSettings: PropTypes.bool,
    updateResponse: PropTypes.func,
  };

  static defaultProps = {
    guestSettings: true
  };

  addPayment = (credentials) => {
    const {guestData} = this.props;
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});

      const payload = {
        total: credentials.total,
        payment_method: credentials.method,
        description: credentials.description,
        payment_type: credentials.payment_type,
      };

      if (this.props.consolidated) {
        payload.order_ids = this.state.selectedOrder;
      } else if (this.props.order) {
        payload.order = this.props.order.id;
      }

      if (credentials.due_date) {
        payload.due_date = Moment(credentials.due_date).utc().format();
      }

      if (!_.isEmpty(guestData)) {
        payload.guest_id = guestData.id;
      } else {
        payload.guest = {
          first_name: credentials.first_name ? credentials.first_name : null,
          last_name: credentials.last_name ? credentials.last_name : null,
          email: credentials.email ? credentials.email : null,
          phone: credentials.phone ? (credentials.code + credentials.phone) : null,
        };
      }

      AppAPI.payment
        .post('', payload)
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
              if (this.props.updateResponse) {
                this.props.updateResponse(res);
              }
              this.props.onHide();
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      let guestSettings = true;
      if (!_.isEmpty(this.props.order) && !_.isEmpty(this.props.order.guest)) {
        guestSettings = false;
      }

      if (this.props.consolidated) {
        guestSettings = false;
      }

      if (!this.props.guestSettings) {
        guestSettings = false;
      }

      this.setState({
        guestSettings: guestSettings,
        selectedOrder: [],
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  selectOrder = (selector, data, item, formikProps) => {
    const stateData = {};
    stateData[`${selector}`] = AppUtil.insertOrRemoveArray(data, item.id);
    this.setState(stateData, () => this.recalculateTotal(formikProps));
  };

  recalculateTotal = (formikProps) => {
    const {orders} = this.props;
    const {selectedOrder} = this.state;
    formikProps.setFieldValue('total', orders.reduce((a, b) => parseFloat(a) + (selectedOrder.includes(b.id) ? parseFloat(b.total_unpaid_payment) : 0), 0));
  };

  prefillDescription = (order) => {
    const description = [];
    if (!_.isEmpty(order.attributes.booking_ref_no)) {
      description.push(`Payment for booking #${order.attributes.booking_ref_no}`);
    }
    if (!_.isEmpty(order.attributes.booking_checkin)) {
      description.push(`Date : ${AppUtil.formatDateTime(order.attributes.booking_checkin, 'datef', 'YYYY-MM-DD')} to ${AppUtil.formatDateTime(order.attributes.booking_checkout, 'datef', 'YYYY-MM-DD')}`);
    }

    if (!_.isEmpty(order.booking_rooms_attributes)) {
      description.push(`No of Room(s) : ${order.booking_rooms_attributes.length}`);
    }

    return description.join('; ');
  };

  render = () => {

    const {loading, error, resultMsg, selectedOrder} = this.state;
    const {property, order, show, consolidated, orders} = this.props;
    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    if (!show) return <React.Fragment/>;

    const formValidation = Yup.object().shape({
      first_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
      description: Yup.string().max(150, 'Too Long!'),
      email: Yup.string().email('Invalid email'),
      method: Yup.string().required('Select payment method'),
      phone: Yup.number(),
      code: Yup.string(),
      total: Yup.number().positive().required(),
    });

    const colClass = `${consolidated ? 'col-lg-6' : 'col-lg-12'} col-md-12 col-sm-12 mx-auto`;

    const orderList = [];
    if (!_.isEmpty(orders)) {
      orders.forEach((data) => {
        if (parseFloat(data.total_unpaid_payment) > 0 && data.lock) {
          orderList.push(data);
        }
      });
    }

    let paymentMethods = [];
    if (!_.isEmpty(property.config) && property.config.payment_methods) {
      Constants.PAYMENT_METHODS_INPUTS.forEach((data) => {
        if (property.config.payment_methods.includes(data.value)) {
          paymentMethods.push(data);
        }
      });
    } else {
      paymentMethods = Constants.PAYMENT_METHODS_INPUTS;
    }

    return (
      <Modal
        {...this.props}
        size={consolidated ? 'lg' : 'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Payment
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            email: '',
            phone: '',
            code: property.phone_number_code,
            total: order ? order.total_unpaid_payment : 0,
            payment_type: 1,
            method: 1,
          }}
          validationSchema={formValidation}
          onSubmit={values => this.addPayment(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <Form className={'form-group'}>
                  <div className="row">
                    <div className={colClass}>
                      <div className="form-row">
                        <div className="col-lg-6 col-md-6 form-group">
                          <Input
                            name="total"
                            disabled={consolidated}
                            className={'form-control'}
                            label={'Total (' + property.currency + ')'}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 form-group">
                          <Select
                            className={'form-control rounded-right-0'}
                            name="method"
                            label={'Payment Method'}
                            placeholder="Select an Option"
                            options={paymentMethods}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-lg-12 form-group">
                          <Textarea
                            name="description"
                            label={'Description (Optional)'}
                            className={'form-control'}
                          />
                        </div>
                      </div>
                      {!_.isEmpty(order) && !_.isEmpty(order.attributes) && !_.isEmpty(order.attributes.booking_ref_no) && (
                        <div className={'text-center'}>
                          <button
                            type={'button'}
                            className={'btn btn-link text-muted btn-sm my-0'}
                            onClick={()=>formikProps.setFieldValue('description', this.prefillDescription(order))}
                          >
                            <FontAwesomeIcon icon={faReceipt} size={'sm'} className={'mr-2'}/>
                            Prefill Description : Booking details
                          </button>
                        </div>
                      )}
                    </div>
                    <div className={colClass}>
                      <div
                        className={'border p-2 text-center anchor mt-3'}
                        onClick={() => this.setState({guestSettings: !this.state.guestSettings})}
                      >
                        <h6 className={'mb-0'}>
                          <FontAwesomeIcon
                            icon={faChevronCircleDown} size={'sm'} className={'mx-2 green-cl float-left mt-1'}/>
                          Guest Information (Optional)
                          <FontAwesomeIcon
                            icon={faChevronCircleDown} size={'sm'} className={'mx-2 green-cl float-right mt-1'}/>
                        </h6>
                      </div>
                      <Collapse in={this.state.guestSettings}>
                        <div className={'py-2 border p-2'}>
                          <div className="form-row">
                            <div className="col-md-7 form-group">
                              <div className={'form-row'}>
                                <div className="col-4 ">
                                  <Select
                                    name="salutation"
                                    label={'Salutation'}
                                    className={'form-control'}
                                    placeholder="Select an Option"
                                    options={Constants.SALUTATION}
                                  />
                                </div>
                                <div className="col-8">
                                  <Input
                                    name="first_name"
                                    label={'First Name'}
                                    className={'form-control'}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-5 form-group">
                              <Input
                                name="last_name"
                                label={'Last Name'}
                                className={'form-control'}
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="col-lg-6 col-md-6 form-group">
                              <Input
                                name="email"
                                label={'E-mail'}
                                className={'form-control'}
                                type={'email'}
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 ">
                              <div className={'row'}>
                                <div className={'col-4 pr-0 form-group'}>
                                  <Select
                                    className={'form-control rounded-right-0'}
                                    name="code"
                                    label={'Country'}
                                    placeholder="Select an Option"
                                    options={SelectList.phoneCountryCode()}
                                  />
                                </div>
                                <div className={'col-8 pl-0 form-group'}>
                                  <Input
                                    name="phone"
                                    label={'Phone'}
                                    className={'form-control rounded-left-0'}
                                    type={'tel'}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Collapse>


                      <div
                        className={'border p-2 text-center anchor mt-3'}
                        onClick={() => this.setState({advanceSettings: !this.state.advanceSettings})}
                      >
                        <h6 className={'mb-0'}>
                          <FontAwesomeIcon
                            icon={faChevronCircleDown} size={'sm'} className={'mx-2 green-cl float-left mt-1'}/>
                          Advance Settings (Optional)
                          <FontAwesomeIcon
                            icon={faChevronCircleDown} size={'sm'} className={'mx-2 green-cl float-right mt-1'}/>
                        </h6>
                      </div>
                      <Collapse in={this.state.advanceSettings} appear={true}>
                        <div className={'py-2 border p-2'}>
                          <div className="form-row">
                            <div className="col-lg-12 form-group">
                              <Datepicker
                                disabled={String(formikProps.values.method) !== String(Constants.PAYMENT_METHODS.ONLINE_PAYMENT)}
                                label="Payment Expiry"
                                name="due_date"
                                wrapperClassName={'w-100'}
                                className={'form-control'}
                                showTimeSelect
                                dateFormat="dd MMM yyyy | hh:mm aa"
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                minDate={new Date()}
                                hint={Strings.paymentExpiry}
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="col-lg-12 form-group">
                              <Select
                                name="payment_type"
                                label={'Payment Type'}
                                className={'form-control'}
                                options={Constants.PAYMENT_TYPE_INPUTS}
                                hint={Strings.paymentTypeText}
                              />
                              <ul>
                                <li><p className={'small text-muted mb-0'}>Direct : {Strings.paymentTypeTextDirect}</p>
                                </li>
                                <li><p className={'small text-muted mb-0'}>Indirect
                                  : {Strings.paymentTypeTextInDirect}</p></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                  {consolidated &&
                  <div className={''}>
                    <p className={'mb-1 text-muted small'}>
                      Select order(s) to be included in consolidated payment
                    </p>
                    <OrderListSelector
                      orders={orderList}
                      property={property}
                      selectedOrder={selectedOrder}
                      action={(data) => this.selectOrder('selectedOrder', selectedOrder, data, formikProps)}
                      bulkAction={(data) => {
                        this.setState({selectedOrder: data}, () => this.recalculateTotal(formikProps));
                      }}
                    />
                  </div>
                  }
                </Form>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <button
                      disabled={consolidated ? _.isEmpty(selectedOrder) : false}
                      type="submit" onClick={formikProps.handleSubmit} className={'btn btn-success btn-lg btn-block'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add Payment
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      selectedOrder: [],
      guestSettings: false,
      advanceSettings: false,
    };
  }
}

/* Export Component ==================================================================== */
export default PaymentAdd;
