/**
 *
 * Room List
 *
 */
import React, {Component} from 'react';
import queryString from 'query-string';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import Moment from 'moment';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import AppWebUtil from '../../lib/webUtils';
// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import PageHeader from '../../components/dashboard/PageHeader';
import ExportButton from '../../components/dashboard/ExportButton';
import HousekeepingRoomListView from './HousekeepingRoomListView';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSync} from '@fortawesome/free-solid-svg-icons';
import SelectorButton from '../../components/dashboard/SelectorButton';
import {Constants, Filters} from '../../constants';
import FilterButton from '../../components/dashboard/FilterButton';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class HousekeepingRoomList extends Component {
  static componentName = 'RoomList';
  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchData = (download) => {
    const urlParams = {...this.state.urlParams};
    let baseUrl = '';

    if (download) {
      urlParams['download'] = download.file;
      urlParams['all_data'] = true;
      this.setState({fileDownload: true});
    }

    AppAPI.housekeeping.get(`${baseUrl}?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (download) {
          let fileName = `Room-Report-${AppUtil.formatDateTime(Moment(), 'short')}.${download.file}`;
          AppWebUtil.downloadFile(res, fileName);
        } else {
          this.setState({
            loading: false,
            rooms: res,
          });
        }
        this.setState({loading: false, fileDownload: false});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({loading: false, fileDownload: false, error});
      });
  };

  fetchInitData = () => {
    AppAPI.housekeeping
      .get('')
      .then(res => {
        if (res.actions !== '') {
          this.setState({
            loading: false,
            rooms: res,
            error: null,
          });
        } else {
          this.setState({
            loading: false,
            error: true,
          });
        }
      })
      .catch(err => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: error,
          resultMsg: {error},
        });
      });
  };

  renderRow = (data, key) => {
    return (
      <div key={key} className={'col-sm-4 col-md-3 col-6 p-3'}>
        <HousekeepingRoomListView data={data} history={this.props.history}/>
      </div>
    );
  };

  handleInputChange = (value) => {
    this.setState({value: value, searching: true});
    this.onChangeDebounced(value);
  };

  onChangeDebounced = (value) => {
    this.setState({searchText: value, searching: false});
  };

  render = () => {
    const {loading, error, fileDownload, view, roomStatusType, searching, searchText} = this.state;

    if (error) return <Error full={true} text={error}/>;

    let {rooms} = this.state;
    if (roomStatusType === 'occupied') {
      rooms = rooms.filter(item => (item.availability_status === Constants.ROOM_STATUS.OCCUPIED));
    } else if (roomStatusType === 'empty') {
      rooms = rooms.filter(item => (item.availability_status === Constants.ROOM_STATUS.AVAILABLE));
    }

    if (searchText) {
      rooms = rooms.filter((el) => {
        let searchValue = el.name.toLowerCase();
        return searchValue.indexOf(searchText.toLowerCase()) !== -1;
      });
    }

    let rooms_list = AppUtil.sortArray(rooms, 'room_type_id');

    return (
      <div className="room-list screen-container">
        <Helmet>
          <title>Room List</title>
        </Helmet>

        <PageHeader history={this.props.history} title={'Room List'} description={'Rooms & status in the property'}/>
        <div>
          <div className={'row'}>
            <div className={'col-12 col-sm-9'}>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button
                    className={'btn btn-outline-secondary btn-sm'}
                    onClick={() => this.setState({loading: true}, () => this.fetchInitData())}
                  >
                    <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                  </button>
                </li>
                <li className="list-inline-item">
                  <SelectorButton
                    title={'View Type'}
                    buttonList={Filters.tableViewSelector}
                    selectButtonKey={view}
                    action={(key) => this.setState({view: key})}
                  />
                </li>
                <li className="list-inline-item">
                  <FilterButton
                    title={'Room Status'}
                    data={Filters.roomStatusType}
                    selectKey={roomStatusType}
                    action={(data) => this.setState({roomStatusType: data.key})}
                    size={'sm'}
                  />
                </li>
              </ul>
            </div>
            <div className={'col-12 col-sm-3 text-center text-sm-right'}>
              <ExportButton
                size={'sm'}
                files={['csv', 'pdf']}
                action={(key) => this.fetchData({file: key})}
                loading={fileDownload}
              />
            </div>
          </div>
          <div className="form-row align-items-center mb-3">
            <div className={searching ? 'col-sm-11' : 'col-sm-12'}>
              <input
                type="search"
                className="form-control"
                placeholder={'Search Room ( Room Name )'}
                name="q"
                autoComplete={'off'}
                onChange={(data) => this.handleInputChange(data.target.value)}
              />
            </div>
            <div className="col-auto">
              {searching && <i className="fa fa-spinner"/>}
            </div>
          </div>
          {loading ? <Loading/> :
            <React.Fragment>
              {
                Object.keys(rooms_list).length > 0 ? (
                  <div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        {view === 'grid' ?
                          <React.Fragment>
                            {Object.keys(rooms_list).map((i) => {
                              return (
                                <div key={i}>
                                  <div className={'row text-center'}>
                                    {rooms_list[i].map((data, j) => this.renderRow(data, j, 2))}
                                  </div>
                                  <hr/>
                                </div>
                              );
                            })}
                          </React.Fragment>
                          :
                          <React.Fragment>
                            <div className={'table-responsive mt-4 border'}>
                              <table className={'table border format-table'}>
                                <thead className={'text-center'}>
                                  <tr>
                                    <th colSpan="4" className="center-text">House Information</th>
                                    <th colSpan="4" className="center-text">Booking Information</th>
                                  </tr>
                                  <tr>
                                    <th className="data-table-cell-lg">Room No</th>
                                    <th className="data-table-cell-lg">Service Status</th>
                                    <th className="data-table-cell-md">Operation Status</th>

                                    <th className="data-table-cell-md">Booking (ID/Reference)</th>
                                    <th className="data-table-cell-lg">Guest</th>
                                    <th className="data-table-cell-md">Checkin</th>
                                    <th className="data-table-cell-md">Checkout</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {rooms && rooms.map((data, i) => (
                                    <tr key={i}>
                                      <td>
                                        <Link to={'/house-keeping/room/' + data.hash}>
                                          {data.name}
                                        </Link>
                                        {data.buffer && <span className={'ml-2 badge badge-warning'}>Buffer</span>}
                                        <br/>
                                        <small>{data.attributes.room_type_name}</small>
                                      </td>
                                      <td>
                                        <span
                                          className={'badge ' + (data.service_status === 1 ? 'badge-success' : 'badge-danger')}>
                                          {data.service_status_display}
                                        </span>
                                      </td>
                                      <td>
                                        <span
                                          className={'badge ' + (data.maintenance_status === 1 ? 'badge-success' : 'badge-danger')}>
                                          {data.maintenance_status_display}
                                        </span>
                                      </td>
                                      {data.booking_room_data ?
                                        <React.Fragment>
                                          <td>
                                            <Link
                                              to={`/booking/${(data.booking_room_data.attributes && data.booking_room_data.attributes.booking_hash) ? data.booking_room_data.attributes.booking_hash : data.booking_id}/booking-room/${data.booking_room_data.hash}`}
                                              rel='noopener noreferrer' target={'_blank'}>
                                              {data.booking_room_data.attributes.booking_ref_no}
                                            </Link>
                                          </td>
                                          <td>
                                            {data.booking_room_data.guests && data.booking_room_data.guests.length > 0 ?
                                              <React.Fragment>
                                                <div
                                                  className={'btn-link text-center anchor'} data-toggle="collapse"
                                                  href={`#orderActions${i}`}
                                                  role="button" aria-expanded="false" aria-controls="orderActions">
                                                  Guest Information
                                                </div>
                                                <div className={'border collapse'} id={`orderActions${i}`}>
                                                  <table className={'table table-sm mb-0'}>
                                                    <tbody>
                                                      {data.booking_room_data.guests.map((guest, k) => (
                                                        <tr key={k}>
                                                          <td>{guest.name}</td>
                                                        </tr>
                                                      ))}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </React.Fragment>
                                              :
                                              <React.Fragment>
                                                <p>No Guest Information</p>
                                              </React.Fragment>
                                            }
                                          </td>
                                          <td>{AppUtil.formatDateTime(data.booking_room_data.checkin, 'date')}</td>
                                          <td>{AppUtil.formatDateTime(data.booking_room_data.checkout, 'date')}</td>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                          <td colSpan={4} className={'text-center'}>
                                            <span
                                              className={'badge ' + (data.availability_status === -1 ? 'badge-danger' : data.availability_status === 1 ? 'badge-success' : 'badge-info')}>
                                              {data.availability_status_display}
                                            </span>
                                          </td>
                                        </React.Fragment>
                                      }
                                    </tr>
                                  ))}

                                </tbody>
                              </table>
                            </div>
                          </React.Fragment>
                        }
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="mx-auto text-center border p-3">
                        <span className="fa fa-check-square fa-5x green-cl"/>
                        <p>Rooms not configured, no rooms added to property. </p>
                        <p>Please complete configuration.</p>
                      </div>
                    </div>
                  </div>
                )
              }
            </React.Fragment>
          }
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);
    this.onChangeDebounced = _.debounce(this.onChangeDebounced, 10, {});

    this.state = {
      loading: true,
      error: null,
      rooms: [],
      urlParams: {},
      view: 'grid',
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HousekeepingRoomList);
