/**
 * Manage Expense
 *  manages expense updates ( Discount, Expense Additional Information)
 */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
// Consts and Libs
import AppAPI from '../../lib/api';
import {ErrorMessages} from '../../constants';
// Components
import {Alerts} from '../../components/ui';
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
// Forms
import * as Yup from 'yup';
import {Form, Formik} from 'formik';
import {Input, Select} from 'react-formik-ui';


/* Component ==================================================================== */

class ExpenseEdit extends React.PureComponent {
  static componentName = 'ExpenseEdit';

  static propTypes = {
    match: PropTypes.object,
    expense: PropTypes.object,
    property: PropTypes.object,
    updateResponse: PropTypes.func,
    editType: PropTypes.string,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        loading: false,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  updateOrder = (credentials = null) => {
    const {editType} = this.props;

    let payload = {};
    if (credentials) {
      switch (editType) {
      case 'discount':
        payload.order_discount = credentials.discount;
        payload.order_discount_description = credentials.order_discount_description;
        if (credentials.percentage) {
          payload.order_discount = parseFloat((credentials.discount * this.props.order.absolute_total) / 100).toFixed(2);
        }
        break;
      default:
        payload.tax_number = credentials.tax_number;
        payload.tax_company = credentials.tax_company;
        payload.expense_order_number = credentials.expense_order_number;
      }
    }

    if (!_.isEmpty(payload)) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.expense
          .patch(`${this.props.expense.hash}/`, payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                this.props.updateResponse(res);
                this.props.onHide();
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {expense, show, editType, property} = this.props;
    if (loading) return <Loading heightMatch={true}/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    if (!show) {
      return <React.Fragment/>;
    }

    let formValidation, formInputData;


    switch (editType) {
    case 'discount':
      formValidation = Yup.object().shape({
        discount: Yup.number(),
      });
      formInputData = (formikProps) => (
        <React.Fragment>
          <div className="form-row">
            <div className="col-8 form-group">
              <Input
                required
                name='discount'
                label={`Discount ${formikProps.values.percentage ? '%' : expense.currency}`}
                className={'form-control'}
              />
            </div>
            <div className="col-4 form-group">
              <Select
                required
                name='percentage'
                label={'Type'}
                className={'form-control'}
                options={[
                  {
                    'value': true,
                    'label': expense.currency,
                  },
                  {
                    'value': false,
                    'label': '% value',
                  },
                ]}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-12 form-group">
              <Input
                required
                name='order_discount_description'
                label={'Discount Description'}
                className={'form-control'}
              />
            </div>
          </div>
        </React.Fragment>
      );
      break;
    default:
      formValidation = Yup.object().shape({
        tax_number: Yup.string().nullable(),
        tax_company: Yup.string().nullable(),
        expense_order_number: Yup.string().nullable(),
      });
      formInputData = () => (
        <React.Fragment>
          <div className="form-row">
            <div className="col-lg-6 col-md-12 form-group">
              <Input
                required
                name='tax_company'
                label={'Tax Company Name'}
                className={'form-control'}
              />
            </div>
            <div className="col-lg-6 col-md-12 form-group">
              <Input
                required
                name='tax_number'
                label={`Tax Number ${property && property.tax_number_type && `(${property.tax_number_type})`}`}
                className={'form-control'}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-lg-6 col-md-12 form-group">
              <Input
                required
                name='expense_order_number'
                label={'Expense Document Number'}
                className={'form-control'}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Expense
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            discount: expense.order_discount,
            tax_number: expense.tax_number,
            tax_company: expense.tax_company,
            expense_order_number: expense.expense_order_number,
            order_discount_description: expense.order_discount_description,
          }}
          validationSchema={formValidation}
          onSubmit={values => this.updateOrder(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <Form className={'form-group'}>
                      <div className="mt-2">
                        {formInputData(formikProps)}
                      </div>
                    </Form>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <Alerts
                  status={resultMsg.status}
                  success={resultMsg.success}
                  error={resultMsg.error}
                />
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <button
                      type="submit" onClick={formikProps.handleSubmit} className={'btn btn-success btn-lg btn-block'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default ExpenseEdit;
