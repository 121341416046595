/**
 *
 * Order Details
 */
import {Link, Route, Switch} from 'react-router-dom';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import _ from 'lodash';
// Consts and Libs
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import NavigationTabs from '../../../components/dashboard/NavigationTabs';

import OrderOverView from './OrderOverView';
import OrderRefundDetails from './OrderRefundDetails';
import OrderPaymentDetails from './OrderPaymentDetails';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faBed} from '@fortawesome/free-solid-svg-icons';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  order: state.order.order,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class OrderDetails extends Component {
  static componentName = 'OrderDetails';

  static propTypes = {};

  componentDidMount = () => {
  };

  fetchInitData = () => {
  };

  render = () => {

    const {loading, error} = this.state;
    const {order} = this.props;

    if (loading || _.isEmpty(order)) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    let baseUrl = '/order';
    let absBaseUrl = `${baseUrl}/${order.hash}`;
    const menu = [
      {
        title: 'Order Details',
        link: `${absBaseUrl}/`,
        isExact: true,
      },
      {
        title: 'Order Payment(s)',
        link: `${absBaseUrl}/order-payment/`,
        disabled: !order.lock,
      }
    ];

    if (parseFloat(order.total_refunded) > 0) {
      menu.push(
        {
          title: 'Order Refund(s)',
          link: `${absBaseUrl}/order-refund/`,
        }
      );
    }

    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Order Details</title>
        </Helmet>

        <div>
          <Link to={'/order/list'} className={'btn btn-outline-dark mr-2 btn-sm mt-2'}>
            <FontAwesomeIcon icon={faArrowLeft} className={'mr-2'} size={'sm'}/> Order List
          </Link>
          {!_.isEmpty(order.attributes) &&
            <React.Fragment>
              {order.attributes.booking_hash &&
              <Link
                to={`/booking/${order.attributes.booking_hash}/`}
                className={'btn btn-outline-dark mr-2 btn-sm mt-2'}
              >
                <FontAwesomeIcon icon={faBed} className={'mr-2'} size={'sm'}/>
                {order.attributes.booking_ref_no || 'Booking'}
              </Link>
              }
            </React.Fragment>
          }
        </div>

        <PageHeader
          border={false}
          history={this.props.history} title={'Order details'}
          description={`Reference number : ${_.isEmpty(order) ? '...' : order.ref_no}`}/>

        <NavigationTabs navItems={menu} classNameContainer={'mb-4'}/>

        <Switch>
          <Route
            path={absBaseUrl} exact
            render={() => <OrderOverView {...this.props}/>}
          />
          <Route
            path={`${absBaseUrl}/order-payment/`} exact
            render={() => <OrderPaymentDetails {...this.props}/>}
          />
          <Route
            path={`${absBaseUrl}/order-refund/`} exact
            render={() => <OrderRefundDetails {...this.props}/>}
          />
        </Switch>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
