/**
 * Event Reducer
 *  event
 *
 */

// Set initial state
const initialState = {
  event: {},
  ticket: {},
  ticketbooking: {},
  selectiondata: {},
  lookupresponse: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
  case 'SET_EVENT':
    let event = [];
    if (action.data && typeof action.data === 'object') {
      event = action.data;
    }

    return {
      ...state,
      event
    };

  case 'SET_EVENT_TICKET':
    let ticket = [];
    if (action.data && typeof action.data === 'object') {
      ticket = action.data;
    }

    return {
      ...state,
      ticket
    };

  case 'SET_EVENT_LOOKUP': {
    let lookupresponse = null;
    if (action.data && typeof action.data === 'object') {
      lookupresponse = action.data;
    }
    return {
      ...state,
      lookupresponse,
    };
  }

  case 'SET_EVENT_SELECTION': {
    let selectiondata = [];
    if (action.data && typeof action.data === 'object') {
      selectiondata = action.data;
    }
    return {
      ...state,
      selectiondata,
    };
  }

  case 'SET_TICKET_BOOKING': {
    let ticketbooking = [];
    if (action.data && typeof action.data === 'object') {
      ticketbooking = action.data;
    }
    return {
      ...state,
      ticketbooking,
    };
  }

  default:
    return state;
  }
};
