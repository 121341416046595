/**
 *
 * Amendment List
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../lib/api';
import Strings from '../../constants/strings';
import {Constants} from '../../constants';
// Components
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import ListView from '../../components/general/ListView';
import {faPlus, faShoppingBasket} from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class AmendmentList extends Component {
  static componentName = 'BookingRoomAmendmentList';

  componentDidMount = () => {
  };

  fetchData = (page = 1, callback, searchText) => {
    const urlParams = {...this.state.urlParams};
    urlParams['page'] = page;
    if (searchText) {
      this.setState({searching: true, clear: true});
      urlParams['q'] = searchText;
    }
    const queryString = Object.keys(urlParams).map(key => key + '=' + urlParams[key]).join('&');
    AppAPI.bookingapi.get(`booking-amendment-settings/?${queryString}`)
      .then((res) => {
        if (res.results.length > 0) {
          callback(res.results, {allLoaded: !res.next});
        } else {
          callback();
        }
        this.setState({searching: false, clear: false, loading: false});
      })
      .catch((err) => {
        callback([], {allLoaded: true});
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, loading: false, error});
      });
  };

  renderRow = (data, key) => {
    return (
      <tr key={key}>
        <td>
          <Link to={`/settings/inventory/amendments/${data.id}`}>{data.name}</Link>
        </td>
        <td>{`${data.currency} ${data.price_effective}`}</td>
        <td>
          {parseInt(data.sub_quantity_type) !== Constants.QUANTITY_TYPE.NOS && `${data.sub_quantity_type_display} `}
          {parseInt(data.quantity_type) !== Constants.QUANTITY_TYPE.NOS && ` / ${data.quantity_type_display} `}
        </td>
        <td>
          <span className={'badge ' + (data.multiple ? 'badge-success' : 'badge-warning')}>
            {data.multiple ? 'Yes' : 'No'}
          </span>
        </td>
        <td>
          <span className={'badge ' + (data.active ? 'badge-success' : 'badge-secondary')}>
            {data.active ? 'Active' : 'De-active'}
          </span>
        </td>
      </tr>
    );
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={6}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faShoppingBasket}/> No Amendments </h4>
            {Strings.bookingRoomAmendmentsListEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };


  render = () => {

    const {loading, error} = this.state;
    const {property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="room-list screen-container ">
        <Helmet>
          <title>Booking Amendments</title>
        </Helmet>

        <PageHeader
          history={this.props.history}
          title={'Booking Amendments'}
          description={`Amendments in ${property.name}`}
          subHeader={true}
        />

        <div className="row">
          <div className="col-lg-9 col-sm-12 col-md-9 mb-5">
            <table className={'table border'}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Multiple</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <ListView
                  rowView={this.renderRow}
                  onFetch={this.fetchData}
                  firstLoader={true}
                  pagination
                  emptyView={this.emptyView}
                  class={'list-group'}
                  colSpan={6}
                />
              </tbody>
            </table>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link to={'/settings/inventory/amendments/new'} className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Amendment
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      searching: false,
      clear: false,
      amendments: [],
      urlParams: {},
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AmendmentList);
