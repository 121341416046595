/**
 *
 * Booking Engine Settings
 * property booking engine parameters settings.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {FieldArray, Formik} from 'formik';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants} from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import {Form, Input, Select, SubmitBtn, Textarea} from 'react-formik-ui';
import {faChevronDown, faChevronUp, faMinus, faPlus, faSave} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// Actions
import * as PropertyActions from '../../../redux/property/actions';
import Alerts from '../../../components/ui/Alerts';
import CopyToClipboard from '../../../components/general/CopyToClipboard';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetPropertyContent: PropertyActions.isSetPropertyContent,
};

/* Component ==================================================================== */
class PropertyBookingEngineContentManage extends Component {
  static componentName = 'PropertyBookingEngineSettings';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    AppAPI.property.get(`${this.props.property.id}/content-settings/`)
      .then((res) => {
        this.setState({
          loading: false,
          initialValues: {
            bengine_header_links: res.bengine_header_links,
            bengine_footer_links: res.bengine_footer_links,
            bengine_social_links: res.bengine_social_links,
            bengine_title: res.bengine_title,
            bengine_sub_title: res.bengine_sub_title,
            bengine_description: res.bengine_description,
            bengine_short_description: res.bengine_short_description,
            bengine_slider_description: res.bengine_slider_description,
            bengine_slider_title: res.bengine_slider_title,
            bengine_description_title: res.bengine_description_title,
          },
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: true,
          resultMsg: {error},
        });
      });
  };

  savePropertyContent = (values) => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.property.patch(`${this.props.property.id}/content-settings/`, {
        'bengine_header_links': values.bengine_header_links,
        'bengine_footer_links': values.bengine_footer_links,
        'bengine_social_links': values.bengine_social_links,
        'bengine_title': values.bengine_title,
        'bengine_sub_title': values.bengine_sub_title,
        'bengine_description': values.bengine_description,
        'bengine_short_description': values.bengine_short_description,
        'bengine_slider_description': values.bengine_slider_description,
        'bengine_description_title': values.bengine_description_title,
        'bengine_slider_title': values.bengine_slider_title,
      })
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                this.props.isSetPropertyContent(res)
                  .then(() => {
                    this.props.history.goBack();
                  });
              }, 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    });
  };

  render = () => {
    const {loading, error, initialValues, resultMsg} = this.state;
    const {property} = this.props;
    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const titleValidation = Yup.object().shape({
      title: Yup.string().min(1, 'too short').required('Required'),
    });

    const linkValidation = Yup.object().shape({
      title: Yup.string().min(1, 'too short').required('Required'),
      link: Yup.string().url('Please enter a valid URL, including scheme').required('Required'),
    });
    const defaultFormValidation = Yup.object().shape({
      bengine_title: Yup.string().max(255, 'Too Long!').nullable(),
      bengine_sub_title: Yup.string().max(255, 'Too Long!').nullable(),
      bengine_header_links: Yup.array().of(titleValidation).nullable(),
      bengine_footer_links: Yup.array().of(titleValidation).nullable(),
      bengine_social_links: Yup.array().of(linkValidation).nullable(),
    });

    return (
      <div className="bank-details screen-container ">
        <Helmet>
          <title>Booking Engine Settings</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'Content Settings'}
          description={'Manage the contents of booking engine.'}
        />
        <Formik
          onSubmit={(values) => (this.savePropertyContent(values))}
          validationSchema={defaultFormValidation}
          initialValues={initialValues}>
          {({values}) => (
            <Form>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12 mb-5">
                  <div
                    className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                    role="button" aria-expanded="false" aria-controls="orderActions">
                    <h6 className={'mb-0'}>
                      Header Links
                    </h6>
                  </div>
                  <div className={'border p-2'} id={'primaryAction'}>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-12 form-group">
                        <FieldArray
                          name="bengine_header_links"
                          render={arrayHelpers => (
                            <div>
                              {values.bengine_header_links && values.bengine_header_links.length > 0 ? (
                                <div>
                                  {values.bengine_header_links.map((public_link, index) => (
                                    <div key={index} className="form-row">
                                      <div className="col-5 form-group">
                                        <Input
                                          required
                                          name={`bengine_header_links.${index}.title`}
                                          label={'Title'}
                                          className={'form-control'}
                                        />
                                      </div>
                                      <div className="col-5 form-group">
                                        <Input
                                          required
                                          name={`bengine_header_links.${index}.link`}
                                          hint={'http:// or https://'}
                                          label={'URL/Link'}
                                          className={'form-control'}
                                        />
                                      </div>
                                      <div className={'col-2 text-center'}>
                                        <div className="btn-group btn-group-sm" role="group">
                                          <button
                                            onClick={() => arrayHelpers.remove(index)}
                                            type="button" className="btn btn-outline-danger">
                                            <FontAwesomeIcon className={'mr-2 d-none d-md-inline-block'} icon={faMinus} size={'sm'}/> Remove
                                          </button>
                                        </div>
                                        <div className="btn-group btn-group-sm mt-1 text-center" role="group">
                                          <button
                                            disabled={index<0}
                                            onClick={() => arrayHelpers.move(index, index-1)}
                                            type="button" className="btn btn-outline-info">
                                            <FontAwesomeIcon className={'d-none d-md-inline-block'} icon={faChevronUp} size={'sm'}/>
                                          </button>
                                          <button
                                            onClick={() => arrayHelpers.move(index, index+1)}
                                            type="button" className="btn btn-outline-info">
                                            <FontAwesomeIcon className={'d-none d-md-inline-block'} icon={faChevronDown} size={'sm'}/>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                  <div className={'pt-3 border-top'}>
                                    <button
                                      className={'btn btn-success'} type="button"
                                      onClick={() => arrayHelpers.push('')}>
                                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add a
                                      Link
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div className={'text-center'}>
                                  <p>No links.</p>
                                  <button
                                    className={'btn btn-success'} type="button"
                                    onClick={() => arrayHelpers.push('')}>
                                    <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add a Link
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mb-5">
                  <p>For linking in house pages use the permanent url without domain name.</p>
                  <p>
                    Example : To link <code>http://&lt;booking.renzo.in/Property-Name/AboutUS&gt;</code> use only
                    <code>/Property-Name/AboutUS&gt;</code>
                  </p>

                  <p>In House Pages : </p>
                  <ul className={'list-group-sm p-0'}>
                    <li className={'list-group-item'}>
                      <strong>Amenities : </strong> <code>{property.property_slug}/amenities</code>
                      <CopyToClipboard
                        buttonClass={'d-inline ml-2'} buttonClassCopied={'d-inline ml-2 green-cl'}
                        content={`${property.property_slug}/amenities`}/>
                    </li>
                    <li className={'list-group-item'}>
                      <strong>Events : </strong> <code>{property.property_slug}/event</code>
                      <CopyToClipboard
                        buttonClass={'d-inline ml-2'} buttonClassCopied={'d-inline ml-2 green-cl'}
                        content={`${property.property_slug}/event`}/>
                    </li>
                    <li className={'list-group-item'}>
                      <strong>Gallery : </strong> <code>{property.property_slug}/gallery</code>
                      <CopyToClipboard
                        buttonClass={'d-inline ml-2'} buttonClassCopied={'d-inline ml-2 green-cl'}
                        content={`${property.property_slug}/gallery`}/>
                    </li>
                    <li className={'list-group-item'}>
                      <strong>Rooms : </strong> <code>{property.property_slug}/room</code>
                      <CopyToClipboard
                        buttonClass={'d-inline ml-2'} buttonClassCopied={'d-inline ml-2 green-cl'}
                        content={`${property.property_slug}/room`}/>
                    </li>

                  </ul>
                </div>

              </div>

              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12 mb-5">
                  <div
                    className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                    role="button" aria-expanded="false" aria-controls="orderActions">
                    <h6 className={'mb-0'}>
                      Content
                    </h6>
                  </div>
                  <div className={'border p-2'} id={'primaryAction'}>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Input
                          name='bengine_title'
                          label={'Title'}
                          className={'form-control'}
                          hint={'Title on the booking engine homepage. (Max 255)'}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Input
                          name='bengine_sub_title'
                          label={'Sub Title'}
                          hint={'Sub title on the booking engine homepage. (Max 255)'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <hr/>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Textarea
                          name='bengine_short_description'
                          label={'Short Description'}
                          hint={'Short description used for search engine optimization and it\'s would be shown when links are shared across social medias. No max limit but recommended to keep below 300 words.'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <hr/>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Input
                          name='bengine_description_title'
                          label={'Description Header'}
                          hint={'Title above the description (Max 255)'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Textarea
                          name='bengine_description'
                          label={'Description'}
                          hint={'Property description.'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <hr/>
                    <div className="form-row">

                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <table className={'w-100 h-100 bg-dark'}>
                          <tbody>
                            <tr>
                              <td className="align-middle">
                                <h1 className={'text-white text-center align-middle'}>Slider</h1>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <div className={'row'}>
                          <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                            <Input
                              name='bengine_slider_title'
                              label={'Slider Header'}
                              hint={'Title above the Slider description (Max 255)'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                            <Textarea
                              name='bengine_slider_description'
                              label={'Slider Description'}
                              hint={'Contents will come on the right side of image slider. Note this is shown only when slider is active with images.'}
                              className={'form-control'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12 mb-5">
                  <div
                    className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                    role="button" aria-expanded="false" aria-controls="orderActions">
                    <h6 className={'mb-0'}>
                      Footer Links
                    </h6>
                  </div>
                  <div className={'border p-2'} id={'primaryAction'}>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-12 form-group">
                        <FieldArray
                          name="bengine_footer_links"
                          render={arrayHelpers => (
                            <div>
                              {values.bengine_footer_links && values.bengine_footer_links.length > 0 ? (
                                <div>
                                  {values.bengine_footer_links.map((public_link, index) => (
                                    <div key={index} className="form-row">
                                      <div className="col-5 form-group">
                                        <Input
                                          required
                                          name={`bengine_footer_links.${index}.title`}
                                          label={'Title'}
                                          className={'form-control'}
                                        />
                                      </div>
                                      <div className="col-5 form-group">
                                        <Input
                                          required
                                          name={`bengine_footer_links.${index}.link`}
                                          hint={'http:// or https://'}
                                          label={'URL/Link'}
                                          className={'form-control'}
                                        />
                                      </div>
                                      <div className={'col-2 text-center'}>
                                        <div className="btn-group btn-group-sm" role="group">
                                          <button
                                            onClick={() => arrayHelpers.remove(index)}
                                            type="button" className="btn btn-outline-danger">
                                            <FontAwesomeIcon className={'mr-2 d-none d-md-inline-block'} icon={faMinus} size={'sm'}/>
                                            Remove
                                          </button>
                                        </div>
                                        <div className="btn-group btn-group-sm mt-1 text-center" role="group">
                                          <button
                                            disabled={index<0}
                                            onClick={() => arrayHelpers.move(index, index-1)}
                                            type="button" className="btn btn-outline-info">
                                            <FontAwesomeIcon className={'d-none d-md-inline-block'} icon={faChevronUp} size={'sm'}/>
                                          </button>
                                          <button
                                            onClick={() => arrayHelpers.move(index, index+1)}
                                            type="button" className="btn btn-outline-info">
                                            <FontAwesomeIcon className={'d-none d-md-inline-block'} icon={faChevronDown} size={'sm'}/>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                  <div className={'pt-3 border-top'}>
                                    <button
                                      className={'btn btn-success'} type="button"
                                      onClick={() => arrayHelpers.push('')}>
                                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add a
                                      Link
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div className={'text-center'}>
                                  <p>No links.</p>
                                  <button
                                    className={'btn btn-success'} type="button"
                                    onClick={() => arrayHelpers.push('')}>
                                    <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add a Link
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12 mb-5">
                  <div
                    className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                    role="button" aria-expanded="false" aria-controls="orderActions">
                    <h6 className={'mb-0'}>
                      Social Links
                    </h6>
                  </div>
                  <div className={'border p-2'} id={'primaryAction'}>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-12 form-group">
                        <FieldArray
                          name="bengine_social_links"
                          render={arrayHelpers => (
                            <div>
                              {values.bengine_social_links && values.bengine_social_links.length > 0 ? (
                                <div>
                                  {values.bengine_social_links.map((public_link, index) => (
                                    <div key={index} className="form-row">
                                      <div className="col-5 form-group">
                                        <Select
                                          className={'form-control rounded-right-0'}
                                          required
                                          name={`bengine_social_links.${index}.title`}
                                          label={'Type'}
                                          placeholder='Select an Option'
                                          options={Constants.SOCIAL_LINK_TYPE_INPUT}
                                        />
                                      </div>
                                      <div className="col-5 form-group">
                                        <Input
                                          required
                                          name={`bengine_social_links.${index}.link`}
                                          hint={'http:// or https://'}
                                          label={'URL/Link'}
                                          className={'form-control'}
                                        />
                                      </div>
                                      <div className={'col-2 text-center'}>
                                        <div className="btn-group btn-group-sm" role="group">
                                          <button
                                            onClick={() => arrayHelpers.remove(index)}
                                            type="button" className="btn btn-outline-danger">
                                            <FontAwesomeIcon className={'mr-2 d-none d-md-inline-block'} icon={faMinus} size={'sm'}/> Remove
                                          </button>
                                        </div>
                                        <div className="btn-group btn-group-sm mt-1 text-center" role="group">
                                          <button
                                            disabled={index<0}
                                            onClick={() => arrayHelpers.move(index, index-1)}
                                            type="button" className="btn btn-outline-info">
                                            <FontAwesomeIcon className={'d-none d-md-inline-block'} icon={faChevronUp} size={'sm'}/>
                                          </button>
                                          <button
                                            onClick={() => arrayHelpers.move(index, index+1)}
                                            type="button" className="btn btn-outline-info">
                                            <FontAwesomeIcon className={'d-none d-md-inline-block'} icon={faChevronDown} size={'sm'}/>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                  <div className={'pt-3 border-top'}>
                                    <button
                                      className={'btn btn-success'} type="button"
                                      onClick={() => arrayHelpers.push('')}>
                                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add a
                                      Link
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div className={'text-center'}>
                                  <p>No links.</p>
                                  <button
                                    className={'btn btn-success'} type="button"
                                    onClick={() => arrayHelpers.push('')}>
                                    <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add a Link
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'row mb-5'}>
                <div className="col-lg-8 col-md-8 col-sm-12 mb-5">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn className={'btn btn-md btn-success btn-lg btn-block'}>
                    <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      initialValues: {
        footer_notes: '',
        property_links: [],
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyBookingEngineContentManage);