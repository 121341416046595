/**
 *
 * Payment Analytics : Terminals
 */
import _ from 'lodash';
import Moment from 'moment';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import React, { Component } from 'react';
// Components
import Error from '../../../components/general/Error';
import { Constants, Strings } from '../../../constants';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister } from '@fortawesome/free-solid-svg-icons';
// Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PaymentAnalyticsTerminals extends Component {
  static componentName = 'PaymentAnalyticsTerminals';

  static propTypes = {
    endDate: PropTypes.object,
    startDate: PropTypes.object
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {startDate, endDate, dateType} = this.props;
    this.setState({loading: true});
    const queryParams = {
      'date_type': dateType,
      'report_type': 'payment_terminal',
      'start_date': (startDate ? startDate : Moment()).format('YYYY-MM-DD'),
      'end_date': endDate ? endDate.format('YYYY-MM-DD') : null
    };
    AppAPI.paymentapi.get(`payment-analytics/?${queryString.stringify(queryParams)}`)
      .then((res) => {
        this.setState({loading: false, response: res});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({loading: false, error: error});
      });
  };

  render = () => {
    const {loading, error, response} = this.state;
    const {property} = this.props;
    let paymentTerminals = [];

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const paymentData = {
      paymentStatusCount: {},
      paymentStatusTotal: {},
      settlementStatusCount: {},
      settlementStatusTotal: {},
    };

    const paymentTerminalData = {};
    const globalPaymentData = _.cloneDeep(paymentData);
    const globalPaymentTerminalData = _.cloneDeep(paymentData);
    const globalNonPaymentTerminalData = _.cloneDeep(paymentData);

    // Setting up container for Payment terminal level counts.
    if (!_.isEmpty(response) && !_.isEmpty(response.meta_data)) {
      paymentTerminals = response.meta_data.payment_terminal || [];
      if (!_.isEmpty(paymentTerminals)) {
        paymentTerminals.forEach((data) => {
          paymentTerminalData[data.id] = _.cloneDeep(paymentData);
        });
      }
    }


    if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
      const responseData = response.data;
      // Payment Status
      if (!_.isEmpty(responseData.payment_status_terminal_data)) {
        responseData.payment_status_terminal_data.forEach((data) => {
          // Check if terminal entry exist, if so enter value. [ Null value might not exist ]
          if (data.payment_terminal && paymentTerminalData[data.payment_terminal]) {
            paymentTerminalData[data.payment_terminal].paymentStatusCount[data.payment_status] = data.count;
            paymentTerminalData[data.payment_terminal].paymentStatusTotal[data.payment_status] = data.total;

            AppUtil.insertOrAddObject(globalPaymentTerminalData.paymentStatusCount, data.payment_status, data.count);
            AppUtil.insertOrAddObject(globalPaymentTerminalData.paymentStatusTotal, data.payment_status, data.total, 'float');
          } else {
            globalNonPaymentTerminalData.paymentStatusCount[data.payment_status] = data.count;
            globalNonPaymentTerminalData.paymentStatusTotal[data.payment_status] = data.total;
          }
          AppUtil.insertOrAddObject(globalPaymentData.paymentStatusCount, data.payment_status, data.count);
          AppUtil.insertOrAddObject(globalPaymentData.paymentStatusTotal, data.payment_status, data.total, 'float');
        });
      }

      // Settlement Data
      if (!_.isEmpty(responseData.payment_paid_settlement_terminal_data)) {
        responseData.payment_paid_settlement_terminal_data.forEach((data) => {
          // Check if terminal entry exist, if so enter value. [ Null value might not exist ]
          if (data.payment_terminal && paymentTerminalData[data.payment_terminal]) {
            paymentTerminalData[data.payment_terminal].settlementStatusCount[data.settlement_status] = data.count;
            paymentTerminalData[data.payment_terminal].settlementStatusTotal[data.settlement_status] = data.total_settled;

            AppUtil.insertOrAddObject(globalPaymentTerminalData.settlementStatusCount, data.settlement_status, data.count);
            AppUtil.insertOrAddObject(globalPaymentTerminalData.settlementStatusTotal, data.settlement_status, data.total_settled, 'float');
          } else {
            globalNonPaymentTerminalData.settlementStatusCount[data.settlement_status] = data.count;
            globalNonPaymentTerminalData.settlementStatusTotal[data.settlement_status] = data.total_settled;
          }
          AppUtil.insertOrAddObject(globalPaymentData.settlementStatusCount, data.settlement_status, data.count);
          AppUtil.insertOrAddObject(globalPaymentData.settlementStatusTotal, data.settlement_status, data.total_settled, 'float');
        });
      }
    }


    const terminalPaymentPercentage = AppUtil.calculatePercentage(
      (globalPaymentTerminalData.paymentStatusTotal[Constants.PAYMENT_STATUS.PAID] || 0),
      AppUtil.sumArray(Object.values(globalPaymentData.paymentStatusTotal), 'float')
    );

    const paidPayments = (
      AppUtil.sumArray(Object.values(globalPaymentData.paymentStatusTotal), 'float') -
      (globalPaymentData.paymentStatusTotal[Constants.PAYMENT_STATUS.CANCELLED] || 0)
    ).toFixed(2);
    const paidPaymentsCount = (
      AppUtil.sumArray(Object.values(globalPaymentData.paymentStatusCount)) -
      (globalPaymentData.paymentStatusCount[Constants.PAYMENT_STATUS.CANCELLED] || 0)
    );

    const paidPaymentPercentage = AppUtil.calculatePercentage(
      (globalPaymentData.paymentStatusTotal[Constants.PAYMENT_STATUS.PAID] || 0),
      paidPayments
    );

    return (
      <div className="screen-container">
        <Helmet>
          <title>Payment Terminal : Overview</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={'Payment Terminal'}
          description={'Incoming payments & bifurcation based on payment terminal.'}
        />

        <div className={'row'}>
          <div className={'col-12 col-lg-6 mb-3'}>
            <div className={'card mb-3'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col '}>
                    <p className={'text-muted mb-1'}>Total Active Payment(s)</p>
                    <h3 className={'card-title mb-0 pb-0'}>
                      {`${property.currency} ${paidPayments}`}
                    </h3>
                    <p className={'text-muted mb-1'}>
                      {paidPaymentsCount} payment entries.
                    </p>
                  </div>
                </div>
                <hr/>
                <div className={'row'}>
                  <div className={'col '}>
                    <p className={'text-muted mb-1'}>Total Paid</p>
                    <h5 className={'card-title mb-0 pb-0 green-cl'}>
                      {`${property.currency} ${globalPaymentData.paymentStatusTotal[Constants.PAYMENT_STATUS.PAID] || 0}`}
                    </h5>
                  </div>
                  <div className={'col'}>
                    <p className={'text-muted mb-1'}>Pending Payment</p>
                    <h5 className={'card-title mb-0 pb-0 red-cl'}>
                      {`${property.currency} ${globalPaymentData.paymentStatusTotal[Constants.PAYMENT_STATUS.NOTPAID] || 0}`}
                    </h5>
                  </div>
                </div>
                <div className="progress mt-3 red-bg">
                  <div
                    className="progress-bar green-bg"
                    style={{width: `${paidPaymentPercentage}%`}}
                    aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"/>
                </div>
                <div className={'row'}>
                  <div className={'col-8'}>
                    <p className={'text-muted mb-0 mt-1 small'}>
                      Count - Paid : {globalPaymentData.paymentStatusCount[Constants.PAYMENT_STATUS.PAID] || 0} |
                      Pending : {globalPaymentData.paymentStatusCount[Constants.PAYMENT_STATUS.NOTPAID] || 0}
                    </p>
                  </div>
                  <div className={'text-right col'}>
                    <p className={'text-muted mb-0 mt-1 small'}>{paidPaymentPercentage}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'col-12 col-lg-6 mb-3'}>
            <div className={'card mb-3'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col'}>
                    <p className={'text-muted mb-1'}>Total Paid(s)</p>
                    <h3 className={'card-title mb-0 pb-0 green-cl'}>
                      {`${property.currency} ${globalPaymentData.paymentStatusTotal[Constants.PAYMENT_STATUS.PAID] || 0}`}
                    </h3>
                    <p className={'text-muted mb-1'}>
                      {AppUtil.sumArray(Object.values(globalPaymentData.settlementStatusCount))} paid payment entries.
                    </p>
                  </div>
                </div>
                <hr/>
                <div className={'row'}>
                  <div className={'col '}>
                    <p className={'text-muted mb-1'}>Terminal</p>
                    <h5 className={'card-title mb-0 pb-0 orange-cl'}>
                      {`${property.currency} ${globalPaymentTerminalData.paymentStatusTotal[Constants.PAYMENT_STATUS.PAID] || 0}`}
                    </h5>
                  </div>
                  <div className={'col'}>
                    <p className={'text-muted mb-1'}>Non Terminal</p>
                    <h5 className={'card-title mb-0 pb-0 blue-cl'}>
                      {`${property.currency} ${globalNonPaymentTerminalData.paymentStatusTotal[Constants.PAYMENT_STATUS.PAID] || 0}`}
                    </h5>
                  </div>
                </div>
                <div className="progress mt-3 blue-bg">
                  <div
                    className="progress-bar orange-bg"
                    style={{width: `${terminalPaymentPercentage}%`}}
                    aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"/>
                </div>
                <div className={'row'}>
                  <div className={'col-8'}>
                    <p className={'text-muted mb-0 mt-1 small'}>
                      Terminal : {globalPaymentTerminalData.paymentStatusCount[Constants.PAYMENT_STATUS.PAID] || 0} |
                      Non Terminal
                      : {globalNonPaymentTerminalData.paymentStatusCount[Constants.PAYMENT_STATUS.PAID] || 0}
                    </p>
                  </div>
                  <div className={'text-right col'}>
                    <p className={'text-muted mb-0 mt-1 small'}>{terminalPaymentPercentage}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className={'table table-bordered'}>
            <thead>
              <tr>
                <th>Terminal</th>
                <th>Count</th>
                <th>Total <small>({property.currency})</small></th>
                <th>Pending <small>({property.currency})</small></th>
                <th>Paid <small>({property.currency})</small></th>
                <th>Settled <small>({property.currency})</small></th>
                <th>Pending Sett <small><small>({property.currency})</small></small></th>
              </tr>
            </thead>
            <tbody>
              {_.isEmpty(paymentTerminals)
                ? (
                  <tr>
                    <td colSpan={8}>
                      <div className={'text-center table-list-empty-sm'}>
                        <h4><FontAwesomeIcon icon={faCashRegister}/> No Terminal(s)</h4>
                        {Strings.paymentTerminalListEmpty}
                      </div>
                    </td>
                  </tr>
                )
                : (
                  <>
                    {paymentTerminals.map((data, i) => (
                      <tr className={'tr-align-middle'} key={i}>
                        <td className={'data-table-cell-lg'}>
                          <p className={'mb-0'}><strong>{data.name}</strong></p>
                          {data.short_description && <p className={'small text-muted mb-0'}>{data.short_description}</p>}
                        </td>
                        <td className={'data-table-cell-md text-center'}>
                          {AppUtil.sumArray(Object.values(paymentTerminalData[data.id].paymentStatusCount)) - (paymentTerminalData[data.id].paymentStatusCount[Constants.PAYMENT_STATUS.CANCELLED] || 0)}
                        </td>
                        <td className={'data-table-cell-md text-center'}>
                          {(AppUtil.sumArray(Object.values(paymentTerminalData[data.id].paymentStatusTotal)) - (paymentTerminalData[data.id].paymentStatusTotal[Constants.PAYMENT_STATUS.CANCELLED] || 0)).toFixed(0)}
                        </td>
                        <td className={'data-table-cell-md text-center'}>
                          {paymentTerminalData[data.id].paymentStatusTotal[Constants.PAYMENT_STATUS.NOTPAID] || 0}
                        </td>
                        <td className={'data-table-cell-md text-center'}>
                          {paymentTerminalData[data.id].paymentStatusTotal[Constants.PAYMENT_STATUS.PAID] || 0}
                        </td>
                        <td className={'data-table-cell-md text-center'}>
                          {paymentTerminalData[data.id].settlementStatusTotal[Constants.SETTLEMENT_STATUS.SETTLED] || 0}
                        </td>
                        <td className={'data-table-cell-md text-center'}>
                          {paymentTerminalData[data.id].settlementStatusTotal[Constants.SETTLEMENT_STATUS.NOT_SETTLED] || 0}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td><strong>Total</strong></td>
                      <td className={'text-center'}><strong>
                        {AppUtil.sumArray(Object.values(globalPaymentTerminalData.paymentStatusCount)) - (globalPaymentTerminalData.paymentStatusCount[Constants.PAYMENT_STATUS.CANCELLED] || 0)}
                      </strong></td>
                      <td className={'text-center'}><strong>
                        {(AppUtil.sumArray(Object.values(globalPaymentTerminalData.paymentStatusTotal)) - (globalPaymentTerminalData.paymentStatusTotal[Constants.PAYMENT_STATUS.CANCELLED] || 0)).toFixed(2)}
                      </strong></td>
                      <td className={'data-table-cell-md text-center'}>
                        <strong>{globalPaymentTerminalData.paymentStatusTotal[Constants.PAYMENT_STATUS.NOTPAID] || 0}</strong>
                      </td>
                      <td className={'data-table-cell-md text-center'}>
                        <strong>{globalPaymentTerminalData.paymentStatusTotal[Constants.PAYMENT_STATUS.PAID] || 0}</strong>
                      </td>
                      <td className={'data-table-cell-md text-center'}>
                        <strong>{globalPaymentTerminalData.settlementStatusTotal[Constants.SETTLEMENT_STATUS.SETTLED] || 0}</strong>
                      </td>
                      <td className={'data-table-cell-md text-center'}>
                        <strong>{globalPaymentTerminalData.settlementStatusTotal[Constants.SETTLEMENT_STATUS.NOT_SETTLED] || 0}</strong>
                      </td>
                    </tr>
                  </>
                )
              }
              <tr>
                <td colSpan={8} className={'text-center table-secondary'}>{Strings.paymentNonTerminalEntry}</td>
              </tr>
              <tr className={'tr-align-middle'}>
                <td>
                  <p className={'mb-0'}><strong>Non Terminal</strong></p>
                  <p className={'small text-muted mb-0'}>{Strings.paymentNonTerminalEntry}</p>
                </td>

                <td className={'data-table-cell-md text-center'}>
                  {AppUtil.sumArray(Object.values(globalNonPaymentTerminalData.paymentStatusCount)) - (globalNonPaymentTerminalData.paymentStatusCount[Constants.PAYMENT_STATUS.CANCELLED] || 0)}
                </td>
                <td className={'data-table-cell-md text-center'}>
                  {(AppUtil.sumArray(Object.values(globalNonPaymentTerminalData.paymentStatusTotal)) - (globalNonPaymentTerminalData.paymentStatusTotal[Constants.PAYMENT_STATUS.CANCELLED] || 0)).toFixed(2)}
                </td>
                <td className={'data-table-cell-md text-center'}>
                  {globalNonPaymentTerminalData.paymentStatusTotal[Constants.PAYMENT_STATUS.NOTPAID] || 0}
                </td>
                <td className={'data-table-cell-md text-center'}>
                  {globalNonPaymentTerminalData.paymentStatusTotal[Constants.PAYMENT_STATUS.PAID] || 0}
                </td>
                <td className={'data-table-cell-md text-center'}>
                  {globalNonPaymentTerminalData.settlementStatusTotal[Constants.SETTLEMENT_STATUS.SETTLED] || 0}
                </td>
                <td className={'data-table-cell-md text-center'}>
                  {globalNonPaymentTerminalData.settlementStatusTotal[Constants.SETTLEMENT_STATUS.NOT_SETTLED] || 0}
                </td>
              </tr>
              <tr>
                <td><strong>Total</strong></td>
                <td className={'data-table-cell-md text-center'}>
                  <strong>
                    {AppUtil.sumArray(Object.values(globalPaymentData.paymentStatusCount)) - (globalPaymentData.paymentStatusCount[Constants.PAYMENT_STATUS.CANCELLED] || 0)}
                  </strong>
                </td>
                <td className={'data-table-cell-md text-center'}>
                  <strong>
                    {(AppUtil.sumArray(Object.values(globalPaymentData.paymentStatusTotal)) - (globalPaymentData.paymentStatusTotal[Constants.PAYMENT_STATUS.CANCELLED] || 0)).toFixed(2)}
                  </strong>
                </td>
                <td className={'data-table-cell-md text-center'}>
                  <strong>{globalPaymentData.paymentStatusTotal[Constants.PAYMENT_STATUS.NOTPAID] || 0}</strong>
                </td>
                <td className={'data-table-cell-md text-center'}>
                  <strong>{globalPaymentData.paymentStatusTotal[Constants.PAYMENT_STATUS.PAID] || 0}</strong>
                </td>
                <td className={'data-table-cell-md text-center'}>
                  <strong>{globalPaymentData.settlementStatusTotal[Constants.SETTLEMENT_STATUS.SETTLED] || 0}</strong>
                </td>
                <td className={'data-table-cell-md text-center'}>
                  <strong>{globalPaymentData.settlementStatusTotal[Constants.SETTLEMENT_STATUS.NOT_SETTLED] || 0}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      startDate: Moment().startOf('day'),
      endDate: Moment().add(30, 'days'),
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentAnalyticsTerminals);
