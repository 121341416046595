/**
 *  POS Table multiple add
 *
 */
import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Actions
import * as POSActions from '../../../redux/pos/actions';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import {Constants, ErrorMessages, Strings} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
// Form
import * as Yup from 'yup';
import {FieldArray, Formik} from 'formik';
import {Form, Input, Select, SubmitBtn} from 'react-formik-ui';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pos: state.pos.pos,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPOSTable: POSActions.setPOSTable,
};

/* Component ==================================================================== */

class POSTableMultipleAdd extends Component {
  static componentName = 'POSTableMultipleAdd';

  static propTypes = {
    match: PropTypes.object,
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  addItem = (credentials) => {
    const {pos} = this.props;
    if (credentials && !_.isEmpty(credentials.table_list)) {

      const itemsData = [];

      credentials.table_list.forEach((data)=>{
        itemsData.push({
          'name': data.name,
          'occupancy': data.occupancy,
          'table_shape': data.table_shape,
        });
      });

      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.posapi.post(`pos-table-settings/?pos=${pos.hash}`, itemsData)
          .then(() => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.history.goBack();
                }, 500);
              },
            );
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };


  fetchInitData = () => {};


  render = () => {

    const {
      loading,
      error,
      resultMsg,
    } = this.state;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    const initialObjValue = {
      'name': '',
      'occupancy': '',
      'table_shape': '',
    };

    const initialValues = {
      'table_list': [initialObjValue]
    };

    const contentValidation = Yup.object().shape({
      name: Yup.string().min(2, 'Too Short!').required('Required'),
      occupancy: Yup.number().required('Required'),
      table_shape: Yup.number().required('Required'),
    });

    const formValidation = Yup.object().shape({
      table_list: Yup.array().of(contentValidation).nullable(),
    });

    return (

      <div className="room-type-list-manage screen-container ">
        <Helmet>
          <title>POS Add Multiple Table</title>
        </Helmet>
        <PageHeader
          subHeader={true} history={this.props.history}
          title={'Add Table'}
          description={'Add table to the point of sales'}
        />

        <Formik
          onSubmit={(values) => this.addItem(values)}
          validationSchema={formValidation}
          initialValues={initialValues}>
          {(formikProps) => (
            <Form>
              <div className={'row'}>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Item Information
                    </h6>
                  </div>
                  <div className={'border p-2 mb-3'}>
                    <FieldArray
                      name="table_list"
                      render={arrayHelpers => (
                        <div>
                          {!_.isEmpty(formikProps.values.table_list) ? (
                            <div>{formikProps.values.table_list.map((data, index) => (
                              <div key={index} className={`${(index > 0) && ' border-top pt-3'} row`}>
                                <div className={'col-10'}>
                                  <div className={`form-row ${(index%2 === 0) && 'cream-bg' } p-1`}>
                                    <div className="col-4 form-group">
                                      <Input
                                        required
                                        name={`table_list.${index}.name`}
                                        label={'Table Name'}
                                        hint={Strings.posTableName}
                                        className={'form-control'}
                                      />
                                    </div>
                                    <div className="col-4 form-group">
                                      <Select
                                        required
                                        className={'form-control rounded-right-0'}
                                        name={`table_list.${index}.occupancy`}
                                        label={'Occupancy'}
                                        placeholder='Select an Option'
                                        hint={Strings.posTableOccupancy}
                                        options={AppUtil.numericSelector(1, 30)}
                                      />
                                    </div>
                                    <div className="col-4 form-group">
                                      <Select
                                        required
                                        className={'form-control rounded-right-0'}
                                        name={`table_list.${index}.table_shape`}
                                        label={'Table Shape'}
                                        placeholder='Select an Option'
                                        hint={Strings.posTableShape}
                                        options={Constants.POS_TABLE_SHAPE}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className={'col-2 text-center'}>
                                  <div className="btn-group btn-group-sm mt-4" role="group">
                                    <button
                                      onClick={() => arrayHelpers.remove(index)}
                                      type="button" className="btn btn-outline-danger">
                                      <FontAwesomeIcon className={'mr-2 d-none d-md-inline-block'} icon={faMinus} size={'sm'}/> Remove
                                    </button>
                                  </div>
                                  <div className="btn-group btn-group-sm mt-4 text-center" role="group">
                                    <button
                                      disabled={index<0}
                                      onClick={() => arrayHelpers.move(index, index-1)}
                                      type="button" className="btn btn-outline-info">
                                      <FontAwesomeIcon className={'d-none d-md-inline-block'} icon={faChevronUp} size={'sm'}/>
                                    </button>
                                    <button
                                      onClick={() => arrayHelpers.move(index, index+1)}
                                      type="button" className="btn btn-outline-info">
                                      <FontAwesomeIcon className={'d-none d-md-inline-block'} icon={faChevronDown} size={'sm'}/>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className={'pt-3 border-top text-center'}>
                              <button
                                className={'btn btn-success'} type="button"
                                onClick={() => arrayHelpers.push(initialObjValue)}>
                                <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add Table
                              </button>
                            </div>
                            </div>
                          ) : (
                            <div className={'text-center'}>
                              <p>No Table.</p>
                              <button
                                className={'btn btn-success'} type="button"
                                onClick={() => arrayHelpers.push(initialObjValue)}>
                                <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add Table
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>


              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn
                    disabled={_.isEmpty(formikProps.values.table_list)}
                    className={'btn btn-success btn-block btn-lg'}
                  >
                    <FontAwesomeIcon
                      className={'white-cl mr-2'}
                      icon={faPlus} size={'sm'}
                    /> Add Multiple Tables
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>


      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      initialValues: {},
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(POSTableMultipleAdd);
