/**
 *
 * House keeping History List
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHistory} from '@fortawesome/free-solid-svg-icons';
import SearchBar from '../../../components/forms/SearchBar';

// Actions

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  room: state.room.roomHousekeeping,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class HousekeepingHistory extends Component {
  static componentName = 'HousekeepingHistory';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };
  componentDidMount = () => {

  };

  fetchData = (page = 1, callback) => {
    AppAPI.roomapi.get('room-history/?page=' + page + '&room=' + this.props.room.id)
      .then((res) => {
        this.setState({
          loading: false,
          error: null,
        });
        if (res.results.length > 0) {
          if (res.next) {
            callback(res.results);
          } else {
            callback(res.results, {
              allLoaded: true, // the end of the list is reached
            });
          }
        } else {
          callback();
        }
      })
      .catch((err) => {
        callback(null, {
          allLoaded: true, // the end of the list is reached
        });
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };

  renderRow = (data, key) => {
    return (
      <tr key={key}>
        <td>{AppUtil.formatDateTime(data.time)}</td>
        <td>
          <span
            className={'badge ' + (data.availability_status === -1 ? 'badge-danger' : data.availability_status === 1 ? 'badge-success' : 'badge-info')}>
            {data.availability_status_display}
          </span>
        </td>
        <td>
          <span className={'badge ' + (data.service_status === 1 ? 'badge-success' : 'badge-danger')}>
            {data.service_status_display}
          </span>
        </td>
        <td>
          <span className={'badge ' + (data.maintenance_status === 1 ? 'badge-success' : 'badge-danger')}>
            {data.maintenance_status_display}
          </span>
        </td>
        {data.booking_room_data ?
          <React.Fragment>
            <td>
              <Link
                to={`/booking/${(data.booking_room_data.attributes && data.booking_room_data.attributes.booking_hash) ? data.booking_room_data.attributes.booking_hash : data.booking_id}/booking-room/${data.booking_room_data.hash}`}
                rel='noopener noreferrer' target={'_blank'}>
                {data.booking_room_data.attributes.booking_ref_no}
              </Link>
            </td>
            <td>{AppUtil.formatDateTime(data.booking_room_data.checkin, 'date')}</td>
            <td>{AppUtil.formatDateTime(data.booking_room_data.checkout, 'date')}</td>
          </React.Fragment>
          :
          <React.Fragment>
            <td colSpan={3} className={'text-center'}>
              <span
                className={'badge ' + (data.availability_status === -1 ? 'badge-danger' : data.availability_status === 1 ? 'badge-success' : 'badge-info')}>
                {data.availability_status_display}
              </span>
            </td>
            <td/>
          </React.Fragment>
        }
      </tr>
    );
  };
  emptyView = () => {
    return (
      <tr>
        <td colSpan={7}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faHistory}/> No History </h4>
          </div>
        </td>
      </tr>
    );
  };


  tableHeader = () => {
    return (
      <tr>
        <th>Time</th>
        <th>Availability</th>
        <th>Service</th>
        <th>Operation</th>
        <th className="center-text">Booking</th>
        <th className="center-text">Checkin</th>
        <th className="center-text">Checkout</th>
      </tr>
    );
  };

  render = () => {

    const {loading, error} = this.state;
    const {room} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="room-list screen-container ">
        <Helmet>
          <title>Maintenance History | {room.name}</title>
        </Helmet>

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 mb-5">
            <SearchBar
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
              colSpan={7}
            />
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HousekeepingHistory);