/**
 * Booking Room Actions
 *
 */

import AppAPI from '../../lib/api';

/**
 * Set Booking Room
 */
export const setBookingRoom = (bookingroomData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof bookingroomData === 'object') {
    dispatch({
      type: 'SET_BOOKING_ROOM',
      data: bookingroomData
    });
    return resolve(bookingroomData);
  } else if (bookingroomData) {
    // Fetch Booking Room Data
    return AppAPI.bookingroom.get(bookingroomData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_BOOKING_ROOM',
          data: res
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Booking Room
 */
export const updateBookingRoom = (bookingroomData) => setBookingRoom(bookingroomData);

/**
 * Remove Booking Room
 */
export function unSetBookingRoom() {
  return (dispatch) => {
    dispatch({
      type: 'SET_BOOKING_ROOM',
      data: null
    });
  };
}


/**
 * Set Booking Room Amendment
 */
export const setBookingRoomAmendment = (bookingroomAmendmentData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof bookingroomAmendmentData === 'object') {
    dispatch({
      type: 'SET_BOOKING_ROOM_AMENDMENT',
      data: bookingroomAmendmentData
    });
    return resolve(bookingroomAmendmentData);
  } else if (bookingroomAmendmentData) {
    // Fetch Booking Room Data
    return AppAPI.bookingapi.get(`booking-amendment-settings/${bookingroomAmendmentData}/`)
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_BOOKING_ROOM_AMENDMENT',
          data: res
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Booking Room Amendment
 */
export const updateBookingRoomAmendment = (bookingroomAmendmentData) => setBookingRoomAmendment(bookingroomAmendmentData);

/**
 * Remove Booking Room Amendment
 */
export function unSetBookingRoomAmendment() {
  return (dispatch) => {
    dispatch({
      type: 'SET_BOOKING_ROOM_AMENDMENT',
      data: null
    });
  };
}

/**
 * Set guest status
 */
export function setBookingRoomGuest(status=true) {
  return (dispatch) => {
    dispatch({
      type: 'SET_GUEST_STATUS',
      data: status
    });
  };
}
