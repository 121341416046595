import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Route, Switch} from 'react-router-dom';
// Components
import BookingRoomList from './BookingRoomList';
import PageHeader from '../../components/dashboard/PageHeader';


class BookingRoomListIndex extends Component {
  static propTypes = {
    match: PropTypes.object,
    hideHeader: PropTypes.bool,
    baseUrl: PropTypes.string,
    guestId: PropTypes.number,
    corporateId: PropTypes.number,
  };


  static defaultProps = {
    baseUrl: '/booking',
  };

  render = () => {

    const {baseUrl, hideHeader, guestId, corporateId} = this.props;

    return (
      <div>
        {!hideHeader &&
         <PageHeader
           history={this.props.history} title={'Booking Room\'s'}
           description={'Booking rooms list.'}
         />
        }

        <div className={'row'}>
          <div className={'col-12'}>
            <Switch>
              <Route
                path={`${baseUrl}/booking-room/list/`} exact
                render={(props) => <BookingRoomList guestId={guestId} corporateId={corporateId} {...props}/>}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default BookingRoomListIndex;