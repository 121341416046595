/**
 * Order - Payment Selector
 */
import React, {Component} from 'react';
import queryString from 'query-string';
import {Link} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';
// Consts and Libs
import AppUtil from '../../../lib/util';
import AppAPI from '../../../lib/api';
import {Constants, Filters, Strings} from '../../../constants';

// Components
import {
  faArrowLeft, faCheckSquare,
  faExternalLinkAlt,
  faFileUpload,
  faMoneyBillWave, faSquare, faSync,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Alerts} from '../../../components/ui';
import SearchBar from '../../../components/forms/SearchBar';
import {confirmAlert} from '../../../components/general/ConfirmAlert';
import FilterButton from '../../../components/dashboard/FilterButton';

/* Component ==================================================================== */

class OrderPaymentSelector extends Component {
  static componentName = 'OrderPaymentSelector';

  static propTypes = {
    order: PropTypes.object,
    updateOrder: PropTypes.func,
    updatePayments: PropTypes.func,
    property: PropTypes.object.isRequired,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
        loading: true,
        selectPayment: null,
      });
    }
    return true;
  }


  resetSelection = () => {
    this.setState(
      {
        confirmAttachment: false,
        selectPayment: null,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      },
    );
  };

  updateOrder = (payload) => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.orderapi.patch(`order-payment-link/${this.props.order.hash}/`, payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              this.props.updateOrder(res);
              if (this.props.updatePayments) {
                this.props.updatePayments();
              }
              this.props.onHide();
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };


  fetchData = (page = 1, callback, searchText) => {
    const urlParams = {...this.state.urlParams};
    let baseUrl = 'payments', apiUrl = AppAPI.paymentapi;
    urlParams['page'] = page;
    urlParams['payment_status'] = Constants.PAYMENT_STATUS.PAID;
    urlParams['refund_status'] = Constants.PAYMENT_STATUS.NOTPAID;
    urlParams['empty_order'] = true;

    switch (this.state.paymentMethod) {
    case 'cash':
      urlParams['payment_method'] = Constants.PAYMENT_METHODS.CASH;
      break;
    case 'cheque':
      urlParams['payment_method'] = Constants.PAYMENT_METHODS.CHECK;
      break;
    case 'card':
      urlParams['payment_method'] = Constants.PAYMENT_METHODS.CARD;
      break;
    case 'online-payment':
      urlParams['payment_method'] = Constants.PAYMENT_METHODS.ONLINE_PAYMENT;
      break;
    case 'bank-transfer':
      urlParams['payment_method'] = Constants.PAYMENT_METHODS.BANK_TRANSFER;
      break;
    case 'online-external':
      urlParams['payment_method'] = Constants.PAYMENT_METHODS.ONLINE_EXTERNAL;
      break;
    default:
      break;
    }

    if (searchText) {
      this.setState({searching: true, clear: true});
      baseUrl = 'payment-search';
      urlParams['search'] = searchText;
    }

    apiUrl.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (!_.isEmpty(res.results)) {
          callback(res.results, {allLoaded: !res.next});
        } else {
          callback();
        }
        this.setState({
          searching: false,
          clear: false,
          refresh: false,
          loading: false,
          page: page,
        });
      })
      .catch((err) => {
        if (callback) {
          callback([], {allLoaded: true});
        }
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, refresh: false, loading: false, error});
      });
  };


  renderRow = (data, key) => {
    return (
      <tr key={key}>
        <td>
          <strong>{`${data.currency} ${data.total}`}</strong> <br/>
          <small>{data.payment_method_display}</small>
        </td>
        <td>
          <small>{data.ref_no}</small> <br/>
          <span className={'badge mr-2 ' + (AppUtil.paymentStatusColor(data.payment_status))}>
            {data.payment_status_display}
          </span>
          <span className={'mr-2 badge ' + (AppUtil.settlementStatusColor(data.settlement_status))}>
            {data.settlement_status_display}
          </span>
        </td>
        <td>
          <small>
            Created : {AppUtil.formatDateTime(data.created)} <br/>
            {data.payment_time &&
            <React.Fragment>Payment : {AppUtil.formatDateTime(data.payment_time)} </React.Fragment>}
          </small>
        </td>
        <td>
          {!_.isEmpty(data.guest) ?
            <small>
              {AppUtil.limitChars(data.guest.name, 16) || '..'}
              {data.guest.phone && <React.Fragment>
                <br/> {data.guest.phone}
              </React.Fragment>}
            </small>
            :
            '...'
          }

        </td>
        <td className={'text-center'}>
          <ul className="list-inline list-item-mb-1">
            <li className="list-inline-item">
              <Link to={`/payment/${data.hash}`} target={'_blank'} className={'btn btn-outline-primary btn-sm'}>
                <FontAwesomeIcon icon={faExternalLinkAlt} className={'mr-1'}/> View
              </Link>
            </li>
            <li className="list-inline-item">
              <button
                type={'button'}
                className={'btn btn-success btn-sm'}
                onClick={() => this.setState({selectPayment: data})}
              >Select
              </button>
            </li>
          </ul>
        </td>
      </tr>
    );
  }

  emptyView = () => {
    return (
      <tr>
        <td colSpan={5}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faMoneyBillWave}/> No Payment(s) </h4>
            {Strings.paymentsEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };

  tableHeader = () => {
    return (
      <tr>
        <th className={'data-table-cell-md'}>Amount</th>
        <th className={'data-table-cell-lg'}>Reference</th>
        <th className={'data-table-cell-lg'}>Time</th>
        <th className={'data-table-cell-lg'}>Customer</th>
        <th className={'data-table-cell-sm'}/>
      </tr>
    );
  };


  render = () => {

    const {selectPayment, resultMsg, clear, searching, results, refresh, confirmAttachment} = this.state;

    const {show, order} = this.props;

    if (!show) {
      return null;
    }

    return (
      <Modal
        {...this.props}
        size={'lg'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {selectPayment &&
            <button className={'btn btn-outline-secondary btn-sm mr-2'} onClick={() => this.resetSelection()}>
              <FontAwesomeIcon icon={faArrowLeft}/>
            </button>
            }
            {selectPayment ? 'Review Payment Attachment' : 'Paid Payment'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectPayment ?
            <div>
              <div className={'row'}>
                <div className={'col-12 col-md-6'}>
                  <p><strong>Order Details : </strong> {order.ref_no}</p>
                  <table className={'table table-bordered'}>
                    <tbody>
                      <tr>
                        <td colSpan={2}>
                          <p className={'mb-0 text-muted'}>Total</p>
                          <div className={'d-inline-flex align-items-baseline'}>
                            <h4>{`${order.currency} ${order.total}`}</h4>
                            <div className={'align-items-baseline'}>
                              <span className={`ml-2 badge ${AppUtil.paymentStatusColor(order.order_status)}`}>
                                {order.order_status_display}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={'data-table-cell-lg'}>
                          <p className={'mb-0 text-muted'}>Source</p>
                          <p className={'mb-1'}>{order.order_source_display}</p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <p className={'mb-0 text-muted'}>Guest / Customer</p>
                          <p className={'mb-1'}>{!_.isEmpty(order.guest) ? (order.guest.name || '...') : '...'}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className={'col-12 col-md-6'}>
                  <p>
                    <strong>Payment Details : </strong>
                    <Link to={`/payment/${selectPayment.hash}`} target={'_blank'}>
                      {selectPayment.ref_no} <FontAwesomeIcon icon={faExternalLinkAlt} className={'ml-1'}/>
                    </Link>
                  </p>

                  <table className={'table table-bordered'}>
                    <tbody>
                      <tr>
                        <td colSpan={3}>
                          <p className={'mb-0 text-muted'}>Total</p>
                          <div className={'d-inline-flex align-items-baseline'}>
                            <h4>{`${selectPayment.currency} ${selectPayment.total}`}</h4>
                            <span
                              className={'ml-2 badge ' + (AppUtil.paymentStatusColor(selectPayment.payment_status))}>
                              {selectPayment.payment_status_display}
                            </span>
                            <span
                              className={'ml-2 badge ' + (AppUtil.settlementStatusColor(selectPayment.settlement_status))}>
                              {selectPayment.settlement_status_display}
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={'data-table-cell-lg'}>
                          <p className={'mb-0 text-muted'}>Method</p>
                          <p className={'mb-1'}>{selectPayment.payment_method_display}</p>
                        </td>
                        <td className={'data-table-cell-lg'}>
                          <p className={'mb-0 text-muted'}>Payment Time</p>
                          <p className={'mb-1'}><small>{AppUtil.formatDateTime(selectPayment.payment_time)}</small></p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <p className={'mb-0 text-muted'}>Guest / Customer</p>
                          <p className={'mb-1'}>
                            {!_.isEmpty(selectPayment.guest) ?
                              <React.Fragment>
                                {selectPayment.guest.name || '...'} {selectPayment.guest.phone && ` | ${selectPayment.guest.phone}`}
                              </React.Fragment>
                              :
                              '...'
                            }
                          </p>
                        </td>
                      </tr>
                      {selectPayment.description &&
                        <tr>
                          <td colSpan={2}>
                            <p className={'mb-0 text-muted'}>Description</p>
                            <p className={'mb-1'}>{selectPayment.description}</p>
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-12 col-md-6'}>
                  <table className={'table table-bordered'}>
                    <thead>
                      <tr className={'bg-dark text-light'}>
                        <th className={'p-1'} colSpan={2}>Order Total ( Pre Payment )</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className={'table-warning'}>
                        <td className={'data-table-cell-lg'}>
                          <p className={'mb-0 text-muted'}>Paid Payment</p>
                          <h5>{`${order.currency} ${order.total_paid}`}</h5>
                        </td>
                        <td className={'data-table-cell-lg'}>
                          <p className={'mb-0 text-muted'}>Pending Payment</p>
                          <h5>{`${order.currency} ${order.total_unpaid_payment}`}</h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className={'col-12 col-md-6'}>
                  <table className={'table table-bordered'}>
                    <thead>
                      <tr className={'bg-dark text-light'}>
                        <th className={'p-1'} colSpan={2}>Order Total ( Post Payment )</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className={'data-table-cell-lg table-success'}>
                          <p className={'mb-0 text-muted'}>Paid Payment</p>
                          <h5>{`${order.currency} ${parseFloat(order.total_paid) + parseFloat(selectPayment.total)}`}</h5>
                        </td>
                        <td className={'data-table-cell-lg table-danger'}>
                          <p className={'mb-0 text-muted'}>Pending Payment</p>
                          <h5>{`${order.currency} ${parseFloat(order.total_unpaid_payment) - parseFloat(selectPayment.total)}`}</h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <p className={'text-muted small mb-1'}>{Strings.orderPaymentAttachmentWarning}</p>
              <hr/>

              <p>
                <FontAwesomeIcon icon={confirmAttachment ? faCheckSquare : faSquare } className={'mr-2'} onClick={()=>this.setState({confirmAttachment: !confirmAttachment})}/>
                {Strings.orderPaymentAttachmentAcknowledge}
              </p>
            </div>
            :
            <React.Fragment>
              <div className={'row mb-3'}>
                <div className={'col-12 col-sm-9'}>
                  <ul className="list-inline list-item-mb-1">
                    <li className="list-inline-item">
                      <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.setState({refresh: true})}>
                        <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                      </button>
                    </li>
                    <li className="list-inline-item">
                      <FilterButton
                        title={'Payment Method'}
                        data={Filters.paymentMethod}
                        selectKey={this.state.paymentMethod}
                        action={(data) => this.setState({paymentMethod: data.key, refresh: true})}
                        size={'sm'}
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <SearchBar
                clear={clear}
                refresh={refresh}
                results={results}
                searching={searching}
                renderRow={this.renderRow}
                fetchData={this.fetchData}
                emptyView={this.emptyView}
                tableHeader={this.tableHeader}
                searchFunction={this.fetchData}
                placeHolder="Payment ID"
                colSpan={7}
              />
            </React.Fragment>
          }
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          <div className={'px-3'}>
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
          </div>
          <div className={'row'}>
            {selectPayment ?
              <React.Fragment>
                <div className={'col-6'}>
                  <button className={'btn btn-secondary btn-lg btn-block'} onClick={() => this.resetSelection()}>
                    <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Cancel
                  </button>
                </div>
                <div className={'col-6'}>
                  <button
                    disabled={!confirmAttachment}
                    className={'btn btn-success btn-block btn-lg'}
                    onClick={() => {
                      confirmAlert({
                        title: 'Confirm',
                        message: Strings.orderPaymentAttachmentConfirmation,
                        buttons: [
                          {
                            className: 'btn-success',
                            label: 'Attach Payment',
                            onClick: () => this.updateOrder({
                              'payment_id': selectPayment.id,
                            }),
                          },
                          {
                            className: 'btn-secondary',
                            label: 'Exit',
                          },
                        ],
                      });
                    }}
                  >
                    <FontAwesomeIcon className={'white-cl mr-2'} icon={faFileUpload} size={'sm'}/> Attach Payment
                  </button>
                </div>
              </React.Fragment>
              :
              <div className={'col'}>
                <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                  <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                </button>
              </div>
            }
          </div>
        </Modal.Footer>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      paymentMethod: 'online-payment',
      selectPayment: null,
      confirmAttachment: false,
      init: true,
    };
  }
}

/* Export Component ==================================================================== */
export default OrderPaymentSelector;
