/**
 *
 * POS Details
 */
import _ from 'lodash';
import Moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import Helmet from 'react-helmet';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import {Constants, Filters} from '../../constants';

// Components
import {Alerts} from '../../components/ui';
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import FilterButton from '../../components/dashboard/FilterButton';
import SelectorButton from '../../components/dashboard/SelectorButton';
import HousekeepingRoomListView from '../room/HousekeepingRoomListView';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDoorOpen, faPlus, faTable} from '@fortawesome/free-solid-svg-icons';

import POSOrderAdd from './models/POSOrderAdd';
import POSTableListView from './components/POSTableListView';

// Actions
import * as POSActions from '../../redux/pos/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  user: state.user.user,
  property: state.property.property,
  posDetails: state.pos.posDetails,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPOSDetails: POSActions.setPOSDetails,
};

/* Component ==================================================================== */
class POSOverView extends Component {
  static componentName = 'POSOverView';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  cacheReset = () => {
    this.setState({loading: true});
    this.props.setPOSDetails(this.props.match.params.POSId, true, true)
      .then(() => (
        this.setState({
          loading: false,
        })
      ))
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };

  fetchInitData = () => {
    this.setState({
      loading: false,
      error: false,
    });
  };

  handleInputChange = (value) => {
    this.setState({value: value, searching: true});
    this.onChangeDebounced(value);
  };

  onChangeDebounced = (value) => {
    this.setState({searchText: value, searching: false});
  };


  render = () => {

    const {
      loading, error, resultMsg, tableStatusType, searching, searchText, roomStatusType, POSOrderDataDateType
    } = this.state;
    const {posDetails, property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    let tables, rooms;

    if (posDetails.pos_has_table) {
      tables = posDetails.tables;

      if (tableStatusType === 'occupied') {
        tables = tables.filter(item => (item.availability_status === Constants.POS_TABLE_STATUS.OCCUPIED));
      } else if (tableStatusType === 'empty') {
        tables = tables.filter(item => (item.availability_status === Constants.POS_TABLE_STATUS.AVAILABLE));
      }

      if (searchText) {
        tables = tables.filter((el) => {
          let searchValue = el.name.toLowerCase();
          return searchValue.indexOf(searchText.toLowerCase()) !== -1;
        });
      }
    }

    if (posDetails.pos_has_room) {
      rooms = posDetails.rooms || [];
      if (roomStatusType === 'occupied') {
        rooms = rooms.filter(item => (item.availability_status === Constants.ROOM_STATUS.OCCUPIED));
      } else if (roomStatusType === 'empty') {
        rooms = rooms.filter(item => (item.availability_status === Constants.ROOM_STATUS.AVAILABLE));
      }

      if (searchText) {
        rooms = rooms.filter((el) => {
          let searchValue = el.name.toLowerCase();
          return searchValue.indexOf(searchText.toLowerCase()) !== -1;
        });
      }
    }

    const {analytics} = posDetails;
    const defaultValue = _.isEmpty(analytics) ? '...' : 0;
    const defaultZeroValue = {
      'count': defaultValue,
      'total': defaultValue,
      'occupancy': defaultValue
    };
    const POSOrderDataDate = {
      'tabsActive': {
        ...defaultZeroValue,
        'title': 'Active',
        'class': 'yellow-bg black-cl',
        'key': 'open'
      },
      'tabsHold': {
        ...defaultZeroValue,
        'title': 'Hold',
        'class': 'blue-bg white-cl',
        'key': 'hold'
      },
      'tabsComplete': {
        ...defaultZeroValue,
        'title': 'Completed',
        'class': 'green-bg white-cl',
        'key': 'completed'
      },
      'tabsVoid': {
        ...defaultZeroValue,
        'title': 'Cancelled',
        'class': 'grey-bg white-cl',
        'key': 'canceled'
      }
    };

    const POSOrderDataPayment = {
      'tabsNotPaid': {
        'title': 'Not Paid',
        'class': 'red-cl',
        'key': 'not-paid',
        'count': defaultValue,
        'total': defaultValue
      },
      'tabsPartialPaid': {
        'title': 'Partial Paid',
        'class': 'yellow-cl',
        'key': 'part-paid',
        'count': defaultValue,
        'total': defaultValue
      },
    };

    if (!_.isEmpty(analytics)) {
      if (!_.isEmpty(analytics.pos_orders_status_date)) {
        analytics.pos_orders_status_date.forEach((data) => {
          switch (data.pos_order_status) {
          case  Constants.POS_ORDER_STATUS.OPEN:
            POSOrderDataDate['tabsActive']['count'] = data.count;
            POSOrderDataDate['tabsActive']['total'] = data.total;
            POSOrderDataDate['tabsActive']['occupancy'] = data.occupancy;
            break;
          case  Constants.POS_ORDER_STATUS.CANCELLED:
            POSOrderDataDate['tabsVoid']['count'] = data.count;
            POSOrderDataDate['tabsVoid']['total'] = data.total;
            POSOrderDataDate['tabsVoid']['occupancy'] = data.occupancy;
            break;
          case  Constants.POS_ORDER_STATUS.HOLD:
            POSOrderDataDate['tabsHold']['count'] = data.count;
            POSOrderDataDate['tabsHold']['total'] = data.total;
            POSOrderDataDate['tabsHold']['occupancy'] = data.occupancy;
            break;
          case  Constants.POS_ORDER_STATUS.COMPLETED:
            POSOrderDataDate['tabsComplete']['count'] = data.count;
            POSOrderDataDate['tabsComplete']['total'] = data.total;
            POSOrderDataDate['tabsComplete']['occupancy'] = data.occupancy;
            break;
          default:
            break;
          }
        });
      }

      if (!_.isEmpty(analytics.pos_orders_order_status)) {
        analytics.pos_orders_order_status.forEach((data) => {
          switch (data.order_status) {
          case  Constants.ORDER_STATUS.NOTPAID:
            POSOrderDataPayment['tabsNotPaid']['count'] = data.count;
            POSOrderDataPayment['tabsNotPaid']['total'] = data.total;
            POSOrderDataPayment['tabsNotPaid']['occupancy'] = data.occupancy;
            break;
          case  Constants.ORDER_STATUS.PARTPAID:
            POSOrderDataPayment['tabsPartialPaid']['count'] = data.count;
            POSOrderDataPayment['tabsPartialPaid']['total'] = data.total;
            POSOrderDataPayment['tabsPartialPaid']['occupancy'] = data.occupancy;
            break;
          default:
            break;
          }
        });
      }
    }

    const viewButtonList = [
      {
        title: 'Count (Nos)',
        key: 'count',
      },
      {
        title: `Value (${property.currency})`,
        key: 'value',
      }
    ];

    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Overview</title>
        </Helmet>

        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />

            {!_.isEmpty(POSOrderDataDate) &&
            <div>
              <h6 className={'small text-uppercase text-muted'}>Tab(s) Day Overview</h6>
              <div className={'row mb-3'}>
                {Object.entries(POSOrderDataDate).map(([key, value]) => (
                  <div className={'col-3'} key={key}>
                    <div
                      className={`${value.class} p-2 card a-class`}
                      onClick={() => this.props.history.push(`/point-of-sale/${posDetails.hash}/pos-order/list?pos_order_status=${value.key}&end_date&start_date=${AppUtil.formatDateTime(Moment(), 'date-iso')}`)}
                    >
                      <small>{value.title}</small>
                      <h6 className={'card-title mb-0 pb-0'}>
                        {POSOrderDataDateType === 'value'
                          ? `${property.currency} ${value.total}`
                          : `${value.count} Nos`
                        }
                        <small>{value.occupancy > 0 ? ` / ${value.occupancy} Cover(s)` : ''}</small>
                      </h6>
                    </div>
                  </div>
                ))}
              </div>
              <div className={'row mb-3'}>
                <div className={'col-6'}>
                  {(!_.isEmpty(analytics) && analytics.last_updated) &&
                  <p className={'small text-secondary mt-3'}>
                    last updated at {AppUtil.formatDateTime(analytics.last_updated)}.
                  </p>
                  }
                </div>
                <div className={'col-6 text-right'}>
                  <SelectorButton
                    title={'View'}
                    buttonList={viewButtonList}
                    selectButtonKey={POSOrderDataDateType}
                    action={(key) => this.setState({POSOrderDataDateType: key})}
                  />
                </div>
              </div>
            </div>
            }

            {(posDetails.pos_has_table && !_.isEmpty(posDetails.tables)) &&
            <div className="row mb-5">
              <div className="col-lg-12 col-md-12 col-sm-12 h-100">
                <h4 className={'mb-4'}><FontAwesomeIcon icon={faTable} className={'mr-2'} size={'sm'}/> Table(s)</h4>
                <ul className="list-inline list-item-mb-1">
                  <li className="list-inline-item">
                    <FilterButton
                      title={'Table Status'}
                      data={Filters.tableStatusType}
                      selectKey={tableStatusType}
                      action={(data) => this.setState({tableStatusType: data.key})}
                      size={'sm'}
                    />
                  </li>
                </ul>
                <div className="form-row align-items-center mb-4">
                  <div className={searching ? 'col-sm-11' : 'col-sm-12'}>
                    <input
                      type="search"
                      className="form-control"
                      placeholder={'Search Table ( Table Name )'}
                      name="q"
                      autoComplete={'off'}
                      onChange={(data) => this.handleInputChange(data.target.value)}
                    />
                  </div>
                  <div className="col-auto">
                    {searching && <i className="fa fa-spinner"/>}
                  </div>
                </div>
                <div className={'pos-overview-list border px-2 h-100'}>
                  {_.isEmpty(tables) ?
                    <div className={'h-100 d-flex'}>
                      <p className={'w-100 text-muted text-center align-self-center'}>It's Empty.</p>
                    </div>
                    :
                    <div className={'row'}>
                      {tables.map((data, i) => (
                        <div key={i} className={'col-sm-4 col-md-4 col-6 p-3'}>
                          <POSTableListView
                            data={data} history={this.props.history}
                            action={() => {
                              if (!_.isEmpty(data.pos_order_data)) {
                                this.props.history.push(`/point-of-sale/${posDetails.hash}/pos-order/${data.pos_order_data.hash}/`);
                              } else {
                                this.setState({showPOSOrderAdd: true, posTable: data});
                              }
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  }
                </div>
              </div>
            </div>
            }

            {(posDetails.pos_has_room && !_.isEmpty(posDetails.rooms)) &&
            <div className="row mb-5">
              <div className="col-lg-12 col-md-12 col-sm-12 h-100">
                <h4 className={'mb-4'}><FontAwesomeIcon icon={faDoorOpen} className={'mr-2'} size={'sm'}/> Rooms(s)</h4>
                <div className="form-row align-items-center mb-4">
                  <div className={searching ? 'col-sm-11' : 'col-sm-12'}>
                    <input
                      type="search"
                      className="form-control"
                      placeholder={'Search Rooms ( Room Name )'}
                      name="q"
                      autoComplete={'off'}
                      onChange={(data) => this.handleInputChange(data.target.value)}
                    />
                  </div>
                  <div className="col-auto">
                    {searching && <i className="fa fa-spinner"/>}
                  </div>
                </div>
                <div className={'pos-overview-list border px-2 h-100'}>
                  {_.isEmpty(rooms) ?
                    <div className={'h-100 d-flex'}>
                      <p className={'w-100 text-muted text-center align-self-center'}>It's Empty.</p>
                    </div>
                    :
                    <div className={'row'}>
                      {rooms.map((data, i) => (
                        <div key={i} className={'col-sm-4 col-md-4 col-6 p-3'}>
                          <HousekeepingRoomListView
                            data={data}
                            serviceStatus={false}
                            action={() => {
                              this.setState({showPOSOrderAdd: true, posRoom: data});
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  }
                </div>
              </div>
            </div>
            }


          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group mb-5">
              <li className="list-group-item  align-items-center" onClick={() => this.cacheReset()}>
                <small>Point of Sale Actions</small>
              </li>
              <li className="list-group-item  align-items-center">
                <button onClick={() => this.setState({showPOSOrderAdd: true})} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> New Order
                </button>
              </li>
            </ul>

            <h6 className={'small text-uppercase text-muted'}>Overall</h6>
            <div className={'row mb-3'}>
              {Object.entries(POSOrderDataPayment).map(([key, value]) => (
                <div className={'col-6'} key={key}>
                  <div
                    className={'p-2 card a-class'}
                    onClick={() => this.props.history.push(`/point-of-sale/${posDetails.hash}/pos-order/list?order_status=${value.key}`)}
                  >
                    <small>{value.title}</small>
                    <h6 className={`card-title mb-0 pb-0 ${value.class} `}>
                      {POSOrderDataDateType === 'value' ? `${property.currency} ${value.total}` : `${value.count} Nos`}
                    </h6>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <POSOrderAdd
          user={this.props.user}
          posDetails={posDetails}
          posTable={this.state.posTable}
          posRoom={this.state.posRoom}
          show={this.state.showPOSOrderAdd}
          updateResponse={(posOrder) => {
            this.props.setPOSDetails(posDetails.hash);
            this.props.history.push(`pos-order/${posOrder.hash}/`);
          }}
          onHide={() => this.setState({showPOSOrderAdd: false, posTable: null, posRoom: null})}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);
    this.onChangeDebounced = _.debounce(this.onChangeDebounced, 10, {});

    this.state = {
      showPOSOrderAdd: false,
      roomStatusType: 'occupied',
      POSOrderDataDateType: 'count',
      posTable: null,
      posRoom: null,
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(POSOverView);
