/**
 *
 * ReportsList
 * List Orders
 */
import _ from 'lodash';
import React, {Component} from 'react';
import queryString from 'query-string';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import Moment from 'moment';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import AppWebUtil from '../../lib/webUtils';
import {Constants, Filters, Strings} from '../../constants';
// Components
import ReportAdd from './models/ReportAdd';
import ReportDetails from './models/ReportDetails';
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import SearchBar from '../../components/forms/SearchBar';
import FilterDate from '../../components/dashboard/FilterDate';
import FilterButton from '../../components/dashboard/FilterButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFile, faLongArrowAltRight, faPlusCircle, faSync} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class ReportsList extends Component {
  static componentName = 'ReportsList';

  static propTypes = {
    pos: PropTypes.object,
    event: PropTypes.object,
    source: PropTypes.number,
    escalated: PropTypes.bool,
    dateType: PropTypes.object,
    reportOptions: PropTypes.object,
  };


  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };

  componentDidMount = () => {
    let urlParams = {}, startDate = null, endDate = null;

    switch (this.getQueryParams('created_type')) {
    case 'user-crated':
      urlParams['created_type'] = Constants.CREATED_TYPE.USER_CREATED;
      break;
    case 'system-created':
      urlParams['created_type'] = Constants.CREATED_TYPE.SYSTEM_CREATED;
      break;
    default:
      break;
    }

    if (this.getQueryParams('start_date')) {
      startDate = Moment(String(this.getQueryParams('start_date')));
    }
    if (this.getQueryParams('end_date')) {
      endDate = Moment(String(this.getQueryParams('end_date')));
    }

    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('start_date'), this.getQueryParams('end_date'), 'created_date');

    this.setState({
      loading: false,
      urlParams,
      startDate,
      endDate,
    });
  };

  fetchData = (page = 1, callback, searchText, download) => {
    const {pos, event, source, escalated} = this.props;
    const urlParams = {...this.state.urlParams};
    let baseUrl = 'report', apiUrl;
    urlParams['page'] = page;

    if (escalated) {
      baseUrl = 'report-escalated';
    }

    if (searchText) {
      baseUrl = 'order-search';
      urlParams['search'] = searchText;
      this.setState({searching: true, clear: true});
    }

    if (download) {
      urlParams['download'] = download.file;
      urlParams['all_data'] = true;
      this.setState({fileDownload: true});
    }

    if (source) {
      urlParams['source'] = source;
    }


    if (pos) {
      apiUrl = AppAPI.posapi;
      baseUrl = 'pos-report';
      urlParams['pos'] = pos.hash;
    } else if (event) {
      baseUrl = 'event-report';
      apiUrl = AppAPI.eventapi;
      urlParams['event'] = event.hash;
    } else {
      apiUrl = AppAPI.reportapi;
    }
    apiUrl.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (download) {
          let fileName = `Order-Report-${AppUtil.formatDateTime(Moment(), 'short')}.${download.file}`;
          AppWebUtil.downloadFile(res, fileName);
        } else {
          if (res.results.length > 0) {
            callback(res.results, {allLoaded: !res.next});
          } else {
            callback();
          }
        }
        this.setState({
          searching: false,
          clear: false,
          refresh: false,
          loading: false,
          fileDownload: false,
          page: page,
          taxes: res.taxes,
        });
      })
      .catch((err) => {
        if (callback) {
          callback([], {allLoaded: true});
        }
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, refresh: false, loading: false, fileDownload: false, error});
      });
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={7}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faFile}/> No Reports </h4>
            {Strings.orderListEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };


  tableHeader = () => {
    return (
      <tr>
        <th className={'data-table-cell-md'}>Reference</th>
        <th className={'data-table-cell-lg text-left'}>Report Type</th>
        <th className={'data-table-cell-md'}/>
        <th className={'data-table-cell-md'}>Type</th>
        <th className={'data-table-cell-md'}>Source</th>
        <th className={'data-table-cell-md'}/>
        <th/>
      </tr>
    );
  };

  renderRow = (data, key) => {
    return (
      <tr key={key}>
        <td>
          {data.ref_no}
        </td>
        <td className={'text-left'}>
          <strong>{data.report_display}</strong>
          <br/>
          <small>
            {`${data.date_type_display} : ${AppUtil.formatDateTime(data.start_date, 'datef')} ${data.end_date ? `- ${AppUtil.formatDateTime(data.end_date, 'datef')}` : ''}`}
          </small>
        </td>
        <td className={'data-table-cell-md text-left'}>
          <span className={'ml-2 badge ' + (AppUtil.reportStatusColor(data.report_status))}>
            {data.report_status_display}
          </span>
        </td>
        <td>
          {data.created_type_display}
        </td>
        <td>
          {data.source_display}
          {!_.isEmpty(data.attributes) &&
          <React.Fragment>
            {data.attributes.pos_name && <p className={'mb-0 small text-muted'}>{data.attributes.pos_name}</p>}
            {data.attributes.event_name && <p className={' mb-0 small text-muted'}>{data.attributes.event_name}</p>}
          </React.Fragment>
          }
        </td>
        <td>
          <small>{AppUtil.formatDateTime(data.created)}</small>
          {!_.isEmpty(data.attributes) &&
          <React.Fragment>
            {data.attributes.created_user && <p className={'mb-0 small text-muted'}>{data.attributes.created_user}</p>}
          </React.Fragment>
          }
        </td>
        <td>
          <button className={'btn btn-link'} onClick={() => this.setState({reportData: data, showReportDetails: true})}>
            Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
          </button>
        </td>
      </tr>
    );
  };


  render = () => {
    const {loading, error, clear, searching, results, refresh} = this.state;
    const {pos, event, reportOptions, source} = this.props;
    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="order-list screen-container">
        <Helmet>
          <title>Reports List</title>
        </Helmet>

        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-9'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.setState({refresh: true})}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                </button>
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Created Type'}
                  data={Filters.createdType}
                  selectKey={this.getQueryParams('created_type')}
                  action={(data) => this.setQueryParams([{key: 'created_type', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data))}
                  size={'sm'}
                  maxDate={Moment().add(1, 'days')}
                />
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-3 text-center text-sm-right'}>
            {(reportOptions && source) &&
              <button className={'btn btn-sm btn-outline-primary'} onClick={()=>this.setState({showReportAdd: true})}>
                <FontAwesomeIcon icon={faPlusCircle} className={'mr-2'}/> Create Report
              </button>
            }
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <SearchBar
              clear={clear}
              refresh={refresh}
              results={results}
              searching={searching}
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
              colSpan={7}
            />
          </div>
        </div>

        <ReportDetails
          pos={pos}
          event={event}
          escalated={this.props.escalated}
          show={this.state.showReportDetails}
          onHide={() => this.setState({showReportDetails: false, reportData: null})}
          reportData={this.state.reportData}
        />

        <ReportAdd
          pos={pos}
          event={event}
          source={this.props.source}
          dateType={this.props.dateType}
          show={this.state.showReportAdd}
          reportSource={this.props.source}
          escalated={this.props.escalated}
          reportOptions={this.props.reportOptions}
          onHide={() => this.setState({showReportAdd: false})}
          updateResponse={() => this.setState({refresh: true})}
        />

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      reportData: null,
      showReportAdd: false,
      showReportDetails: false,
      searching: false,
      clear: false,
      results: [],
      urlParams: {},
      page: 1,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsList);
