/**
 *
 * Property Dashboard
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {Link} from 'react-router-dom';
// Consts and Libs
import {Strings} from '../../constants';
// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import PropertyLogoManage from './PropertyLogoManage';
import PageHeader from '../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUpload} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as PropertyActions from '../../redux/property/actions';
import AppUtil from '../../lib/util';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetPropertyDetails: PropertyActions.isSetPropertyDetails,
};

/* Component ==================================================================== */
class Dashboard extends Component {
  static componentName = 'Dashboard';
  componentDidMount = () => {
    this.setState({loading: false});
  };

  render = () => {
    const {loading, error} = this.state;
    const {property} = this.props;

    if (!property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="dashboard screen-container">
        <Helmet>
          <title>Settings Overview</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'Property Details'}
        />

        <div className={'row'}>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
            <ul className="list-group">
              <li className="list-group-item">
                <p className="mb-1"><strong>Name :</strong> {property.name}</p>
              </li>
              <li className="list-group-item">
                <p className="mb-1"><strong>Address :</strong> {property.full_address || 'Nil'}</p>
              </li>
            </ul>

            <table className={'table border my-2'}>
              <thead>
                <tr>
                  <th/>
                  <th>Email</th>
                  <th>Phone</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><strong>Contact</strong></td>
                  <td>{property.email}</td>
                  <td>{property.phone}</td>
                </tr>
                <tr>
                  <td><strong>Support</strong></td>
                  <td>{property.support_email}</td>
                  <td>{property.support_phone}</td>
                </tr>
                <tr>
                  <td><strong>Sales</strong></td>
                  <td>{property.sales_email}</td>
                  <td>{property.sales_phone}</td>
                </tr>
              </tbody>
            </table>

            <ul className="list-group mt-3">
              <li className="list-group-item">
                <p className="mb-1"><strong>Currency :</strong> {property.currency}
                </p>
              </li>
              <li className="list-group-item">
                <p className="mb-1"><strong>Company :</strong> {property.tax_company || 'Nil'}
                </p>
              </li>
              <li className="list-group-item">
                <p className="mb-1"><strong>Tax Name
                  :</strong> {property.tax_number_type_display ? property.tax_number_type_display : 'N.A'}</p>
              </li>

              <li className="list-group-item">
                <p className="mb-1"><strong>Tax Number :</strong> {property.tax_number || 'Nil'}
                </p>
              </li>
            </ul>

            <ul className="list-group mt-3">
              <li className="list-group-item">
                <p className="mb-1">
                  <strong>Checkin Time :</strong>
                  {AppUtil.formatDateTime(property.checkin_time, 'time', 'HH:mm:ss')}
                </p>
              </li>
              <li className="list-group-item">
                <p className="mb-1">
                  <strong>Checkout Time :</strong>
                  {AppUtil.formatDateTime(property.checkout_time, 'time', 'HH:mm:ss')}
                </p>
              </li>
            </ul>
            <hr/>
            <p className={'text-secondary'}>This information will be used in bills generated, emails, booking etc, so ensure
              its correctness.</p>
            <Link
              to={{
                pathname: '/settings/property/info/manage',
                state: {fromDashboard: true},
              }}
              className="btn btn-primary">Edit Property Details</Link>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h5>Logo</h5>
            <div className={'mx-auto mb-2'}>
              {property.logo && Object.keys(property.logo).length !== 0 ?
                <div>
                  <p className={'text-muted'}>
                    {Strings.propertyLogoDescription}
                  </p>

                  <ul className={'list-inline logo-list'}>
                    {property.logo.logo_2x &&
                     <li className={'list-inline-item text-center'}>
                       <img
                         width={150} height={75}
                         src={property.logo.logo_2x} alt={property.name} className="img-thumbnail"
                       />
                       <p className={'small mb-0'}>150px</p>
                     </li>
                    }
                    {property.logo.logo_square &&
                     <li className={'list-inline-item text-center'}>
                       <img
                         width={64} height={64} src={property.logo.logo_square} alt={property.name}
                         className="img-thumbnail"
                       />
                       <p className={'small mb-0'}>64px</p>
                     </li>
                    }
                  </ul>

                  <button
                    onClick={() => this.setState({showPropertyLogoManage: true})} className="btn btn-link small">
                    <FontAwesomeIcon icon={faUpload} size={'sm'} className={'mr-2'}/> Update logo
                  </button>
                </div>
                :
                <div>
                  <p className={'text-muted'}>Upload your logo</p>
                  <ul className={'list-inline logo-list'}>
                    <li className={'list-inline-item text-center'}>
                      <img
                        className="img-thumbnail w150"
                        src={require('../../images/logo-placeholder.png')}
                        alt={'your logo'}
                      />
                    </li>
                  </ul>
                  <button
                    onClick={() => this.setState({showPropertyLogoManage: true})}
                    className="btn btn-success">
                    <FontAwesomeIcon icon={faUpload} size={'sm'} className={'mr-2'}/> Upload Logo
                  </button>
                </div>
              }
              {property.google_maps_url &&
               <div>
                 <hr/>
                 <h5>Google Maps</h5>
                 <p className={'text-muted'}>Property location marker on google maps.</p>
                 <iframe
                   title={'google maps'}
                   src={property.google_maps_url}
                   className={'w-100'}
                   height={300}
                   frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
                 />
               </div>
              }
            </div>
          </div>
        </div>
        <PropertyLogoManage
          data={property}
          show={this.state.showPropertyLogoManage}
          onHide={() => this.setState({showPropertyLogoManage: false})}
          onSave={(data) => this.props.isSetPropertyDetails(data)}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      showPropertyLogoManage: false,
      error: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
