/**
 *
 * EventList
 * List Events
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
// Consts and Libs
import AppAPI from '../../../lib/api';
import Strings from '../../../constants/strings';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import SearchBar from '../../../components/forms/SearchBar';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCampground, faCheckCircle, faPlus, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import AppUtil from '../../../lib/util';
import PageHeader from '../../../components/dashboard/PageHeader';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class EventList extends Component {
  static componentName = 'EventList';

  static propTypes = {
    guestId: PropTypes.number,
    corporateId: PropTypes.number,
  };

  componentDidMount = () => {
    let eventStatus = null;

    switch (this.props.match.params.eventType) {
    case 'active':
      eventStatus = 1;
      break;
    case 'inactive':
      eventStatus = 0;
      break;
    default:
      break;
    }
    let urlParams = {};

    if (eventStatus) {
      urlParams['active'] = eventStatus;
    }

    this.setState({loading: false, urlParams: urlParams});
  };

  fetchData = (page = 1, callback, searchText) => {
    const urlParams = {...this.state.urlParams};
    let baseUrl = 'event';
    urlParams['page'] = page;
    if (searchText) {
      this.setState({searching: true, clear: true});
      baseUrl = 'event-search';
      urlParams['search'] = searchText;
    }
    const queryString = Object.keys(urlParams).map(key => key + '=' + urlParams[key]).join('&');
    AppAPI.eventapi.get(`${baseUrl}/?${queryString}`)
      .then((res) => {
        if (res.results.length > 0) {
          callback(res.results, {allLoaded: !res.next});
        } else {
          callback();
        }
        this.setState({searching: false, clear: false, loading: false});
      })
      .catch((err) => {
        callback([], {allLoaded: true});
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, loading: false, error});
      });
  };

  renderRow = (data, key) => {
    return (
      <tr key={key}>
        <td><Link to={`/settings/event/${data.hash}`}>{data.name}</Link></td>
        <td>
          <span className={'badge ' + (AppUtil.bookingStatusColor(data.event_status))}>
            {data.event_status_display}
          </span>
        </td>
        <td>
          {AppUtil.formatDateTime(data.event_start_date, 'date')}
        </td>
        <td>
          <span className={'badge badge-info'}>
            {data.event_type_display}
          </span>
        </td>
        <td>
          <FontAwesomeIcon
            icon={data.active ? faCheckCircle : faTimesCircle}
            className={data.active ? 'green-cl' :'red-cl'}
          />
        </td>
      </tr>
    );
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={5}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faCampground}/> No Events </h4>
            {Strings.eventListEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };

  tableHeader = () => {
    return (
      <tr>
        <th>Name</th>
        <th>Status</th>
        <th>Date</th>
        <th>Type</th>
        <th>Active</th>
      </tr>
    );
  };


  render = () => {
    const {loading, error, clear, searching, results} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="booking-list screen-container">
        <Helmet>
          <title>Event List</title>
        </Helmet>

        <PageHeader
          subHeader={true} history={this.props.history} title={'Event(s)'}
        />

        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12 mb-5">
            <SearchBar
              clear={clear}
              results={results}
              searching={searching}
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              searchFunction={this.fetchData}
              placeHolder="Event Name"
              tableHeader={this.tableHeader}
              colSpan={5}
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link to={'/settings/event/new'} className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Event
                </Link>
              </li>
            </ul>
          </div>
        </div>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      searching: false,
      clear: false,
      results: [],
      urlParams: {},
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventList);
