import React from 'react';
import {Route, Switch} from 'react-router-dom';


import HousekeepingView from './HousekeepingView';
import HousekeepingRoomList from './HousekeepingRoomList';
import HousekeepingDetails from './HousekeepingDetails';

import RoomServiceRequestList from './RoomServiceRequestList';
import RoomServiceRequestDetails from './RoomServiceRequestDetails';

import roomHousekeepingRequired from '../../components/decorators/roomHousekeepingRequired';
import NotFound from '../NotFound';

const RoomRoute = () => (
  <div>

    <div className="container">
      <Switch>
        <Route path="/house-keeping/" exact component={HousekeepingView}/>
        <Route path="/house-keeping/list" exact component={HousekeepingRoomList}/>
        <Route path="/house-keeping/room/:roomId" component={roomHousekeepingRequired(HousekeepingDetails)}/>


        <Route path="/house-keeping/service-request/list" exact component={RoomServiceRequestList}/>
        <Route path="/house-keeping/service-request/:roomServiceRequestId" exact component={RoomServiceRequestDetails}/>

        <Route exact component={NotFound}/>
      </Switch>
    </div>

  </div>
);

export default RoomRoute;