/**
 * Room Actions
 *  Actions with Room
 */

import AppAPI from '../../lib/api';

/**
 * Set Room
 */
export const setRoom = (roomData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof roomData === 'object') {
    dispatch({
      type: 'SET_ROOM',
      data: roomData,
    });
    return resolve(roomData);
  } else if (roomData) {
    return AppAPI.room.get(roomData + '/')
      .then((res) => {
        dispatch({
          type: 'SET_ROOM',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Room
 */
export const updateRoom = (roomData) => setRoom(roomData);

/**
 * Remove Room
 */
export function unSetRoom () {
  return (dispatch) => {
    dispatch({
      type: 'SET_ROOM',
      data: null,
    });
  };
}


/**
 * Set Housekeeping Room
 */
export const setRoomHousekeeping = (roomData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof roomData === 'object') {
    dispatch({
      type: 'SET_ROOM_HOUSEKEEPING',
      data: roomData,
    });
    return resolve(roomData);
  } else if (roomData) {
    return AppAPI.housekeeping.get(roomData + '/')
      .then((res) => {
        dispatch({
          type: 'SET_ROOM_HOUSEKEEPING',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Room
 */
export const updateRoomHousekeeping = (roomData) => setRoom(roomData);

/**
 * Remove Room
 */
export function unSetRoomHousekeeping () {
  return (dispatch) => {
    dispatch({
      type: 'SET_ROOM_HOUSEKEEPING',
      data: null,
    });
  };
}


/**
 * Set Room
 */
export const setRoomServiceRequest = (roomData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof roomData === 'object') {
    dispatch({
      type: 'SET_ROOM_SERVICE_REQUEST',
      data: roomData,
    });
    return resolve(roomData);
  } else if (roomData) {
    // Fetch Room Data
    return AppAPI.roomapi.get(`room-service-request/${roomData}/`)
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_ROOM_SERVICE_REQUEST',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Room
 */
export const updateRoomServiceRequest = (roomData) => setRoomServiceRequest(roomData);

/**
 * Remove Room
 */
export function unSetRoomServiceRequest () {
  return (dispatch) => {
    dispatch({
      type: 'SET_ROOM_SERVICE_REQUEST',
      data: null,
    });
  };
}
