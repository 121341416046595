/**
 * Rate details
 *  Rate details
 */
import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Consts and Libs
import AppUtil from '../../../lib/util';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faPen } from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */

class AvailabilityDetails extends Component {
  static componentName = 'AvailabilityDetails';

  static propTypes = {
    property: PropTypes.object,
    rateDateEdit: PropTypes.func,
    roomTypeData: PropTypes.object,
    rateDateDetails: PropTypes.object,
    roomTypePackageData: PropTypes.object,
  };


  render = () => {
    const {roomTypePackageData, roomTypeData, property, rateDateDetails} = this.props;
    if (_.isEmpty(roomTypePackageData) || _.isEmpty(roomTypeData)) return <React.Fragment/>;
    const dateDelta = parseFloat(rateDateDetails.base_price) -  parseFloat(roomTypePackageData.base_price);
    return (
      <React.Fragment>
        <div className={'row'}>
          <div className={'col-md-12 col-lg-6'}>
            <table className={'table table-bordered'}>
              <thead>
                <tr>
                  <th colSpan={2}>General Information</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={'bg-light'}><strong>Room Type</strong></td>
                  <td className={'text-left'}>{roomTypeData.name}</td>
                </tr>
                <tr>
                  <td className={'bg-light'}><strong>Package</strong></td>
                  <td className={'text-left'}>{roomTypePackageData.name}</td>
                </tr>
                <tr>
                  <td className={'bg-light'}><strong>Tax Class</strong></td>
                  <td className={'text-left text-muted'}>{!_.isEmpty(roomTypePackageData.tax_cache) ? roomTypePackageData.tax_cache.description : 'N.A'}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={'col-md-12 col-lg-6'}>
            <table className={'table table-bordered'}>
              <thead>
                <tr className={'bg-dark text-light'}>
                  <th colSpan={3}>Day price {AppUtil.formatDateTime(rateDateDetails.date, 'datef')}</th>
                </tr>
              </thead>
              <tbody>
                <tr className={'text-center'}>
                  <td className={'w-50 bg-light'}/>
                  <td className={'small bg-light'}>Price ({property.currency})</td>
                  <td className={'small bg-light'}>Inc Tax ({property.currency})</td>
                </tr>
                <tr>
                  <td className={'bg-light'}>
                    Base Price
                    <button
                      onClick={() => this.props.rateDateEdit('basePrice')}
                      className="btn btn-sm btn-link p-0 mx-1 float-right">
                      <FontAwesomeIcon icon={faPen} size={'sm'}/>
                    </button>
                  </td>
                  <td className={'text-center'}><strong>{rateDateDetails.base_price}</strong></td>
                  <td className={'text-center'}><strong>{rateDateDetails.base_price_effective}</strong></td>
                </tr>
                {rateDateDetails.default_rate ? (
                  <tr>
                    <td colSpan={3}>
                      <p className={'mb-0 text-center'}>Default / Rack Rate</p>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>
                      <p className={'mb-0 text-center'}>{dateDelta >= 0 ? 'Increase' : 'Decrease'}</p>
                    </td>
                    <td colSpan={2}>
                      <p className={'mb-0 text-center'}>
                        <FontAwesomeIcon
                          icon={dateDelta >= 0 ? faChevronUp : faChevronDown}
                          className={`mr-3 ${dateDelta >= 0 ? 'green-cl' : 'red-cl'}`}
                        />
                        {AppUtil.calculatePercentage(dateDelta, parseFloat(roomTypePackageData.base_price))} %
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <p className={'text-muted small mb-0'}>Only base price can be changed on date basic.</p>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-md-12 col-lg-6'}>
            <table className={'table table-bordered'}>
              <thead>
                <tr>
                  <th className={'w-50'} colSpan={3}>
                    Default / Rack Rate(s)
                  </th>
                </tr>
                <tr className={'text-center'}>
                  <td className={'w-50 bg-light'}/>
                  <td className={'small bg-light'}>Price ({property.currency})</td>
                  <td className={'small bg-light'}>Inc Tax ({property.currency})</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={'bg-light'}>Base Price</td>
                  <td className={'text-center'}>{roomTypePackageData.base_price}</td>
                  <td className={'text-center'}>{roomTypePackageData.base_price_effective}</td>
                </tr>
                <tr>
                  <td className={'bg-light'}>Child</td>
                  <td className={'text-center'}>{roomTypePackageData.child_price}</td>
                  <td className={'text-center'}>{roomTypePackageData.child_price_effective}</td>
                </tr>
                <tr>
                  <td className={'bg-light'}>Infant</td>
                  <td className={'text-center'}>{roomTypePackageData.infant_price}</td>
                  <td className={'text-center'}>{roomTypePackageData.infant_price_effective}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={'col-md-12 col-lg-6'}>

          </div>
        </div>
      </React.Fragment>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: true,
      availabilityChartData: [],
    };
  }
}

/* Export Component ==================================================================== */
export default AvailabilityDetails;
