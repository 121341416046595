/**
 *
 * POS Details
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import {Constants} from '../../../constants';

// Components
import Error from '../../../components/general/Error';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faLongArrowAltRight,
  faPauseCircle,
  faPlayCircle,
  faStopCircle,
  faSync
} from '@fortawesome/free-solid-svg-icons';
import {History} from '../../../components/ui';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import ModelPDFView from '../../../components/general/ModelPDFView';
import SelectorButton from '../../../components/dashboard/SelectorButton';
import TimeCounterView from '../../../components/dashboard/TimeCounterView';

import POSOrderDetails from './POSOrderDetails';
import POSOrderManage from './POSOrderManage';
import POSOrderKOTDetails from './POSOrderKOTDetails';

// Actions
import * as POSActions from '../../../redux/pos/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  posDetails: state.pos.posDetails,
  posOrder: state.pos.posOrder,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPOSDetails: POSActions.setPOSDetails,
  setPOSOrder: POSActions.setPOSOrder,
};

/* Component ==================================================================== */
class Index extends Component {
  static componentName = 'Index';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    this.setState({loading: true});
    this.props.setPOSOrder(this.props.match.params.POSOrderId, this.props.match.params.POSId)
      .then((res) => (
        this.setState({loading: false, posViewType: res.lock ? 'details' : 'edit'})
      ))
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  }

  cacheReset = () => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.posapi
        .post(`pos-order/${this.props.posOrder.hash}/cache-reset/`, {})
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            this.props.setPOSOrder(res);
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            resultMsg: {error},
          });
        });
    });
  };


  updatePosOrderAdditional = (payload, callback = null, refreshPos = false, posViewType = this.state.posViewType) => {
    const {posDetails, posOrder} = this.props;
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.posapi
          .patch(`pos-order-additional/${posOrder.hash}/?pos=${posDetails.hash}`, payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              this.props.setPOSOrder(res);
              if (refreshPos) this.props.setPOSDetails(this.props.posDetails.hash);
              setTimeout(() => {
                let stateParams = {resultMsg: {success: ''}, posViewType};
                if (posDetails.pos_kot_force_print && !_.isEmpty(res.generated_kot)){
                  stateParams = {
                    ...stateParams,
                    urlModelPDFView: res.generated_kot.resources_link,
                    showModelPDFView: true,
                    showModelPDFViewTitle: 'Print KOT',
                  };
                }else if (posDetails.pos_order_force_print && !_.isEmpty(res.generated_order)){
                  stateParams = {
                    ...stateParams,
                    urlModelPDFView: res.generated_order.resources_link_pos,
                    showModelPDFView: true,
                    showModelPDFViewTitle: 'Print Order',
                  };
                }
                this.setState(stateParams);
                if (callback) callback();
              }, 500);
            });
          })
          .catch(err => {
            if (callback) callback();
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  }

  updatePosOrder = (payload, callback = null, refreshPos = false, posViewType = this.state.posViewType) => {
    const {posDetails, posOrder} = this.props;
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.posapi.patch(`pos-order/${posOrder.hash}/?pos=${posDetails.hash}`, payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              this.props.setPOSOrder(res);
              if (refreshPos) this.props.setPOSDetails(this.props.posDetails.hash);
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}, posViewType});
                if (callback) callback();
              }, 500);
            });
          })
          .catch(err => {
            if (callback) callback();
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  }

  escalatedPermissionUpdated = (payload, callback = null, refreshPos = false, posViewType = this.state.posViewType) => {
    const {posDetails, posOrder} = this.props;
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.posapi
          .patch(`pos-order-escalated/${posOrder.hash}/?pos=${posDetails.hash}`, payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              this.props.setPOSOrder(res);
              if (refreshPos) this.props.setPOSDetails(this.props.posDetails.hash);
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}, posViewType});
                if (callback) callback();
              }, 500);
            });
          })
          .catch(err => {
            if (callback) callback();
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  render = () => {

    const {loading, error, resultMsg, posViewType} = this.state;
    const {posDetails, posOrder} = this.props;

    if (error) return <Error full={true} text={error}/>;
    if (loading) return <Loading/>;


    const viewButtonList = [
      {
        title: 'Details View',
        key: 'details',
      },
      {
        title: 'Edit View',
        key: 'edit',
      },
    ];

    if (posDetails.pos_has_kot) {
      viewButtonList.push({
        title: 'KOT View',
        key: 'kot'
      });
    }
    let viewElement;
    switch (posViewType) {
    case 'edit':
      viewElement = <POSOrderManage
        loading={loading}
        posOrder={posOrder}
        resultMsg={resultMsg}
        posDetails={posDetails}
        cacheReset={this.cacheReset}
        property={this.props.property}
        fetchInitData={this.fetchInitData}
        updatePosOrder={this.updatePosOrder}
        setPOSOrder={this.props.setPOSOrder}
        updatePosOrderAdditional={this.updatePosOrderAdditional}
        escalatedPermissionUpdated={this.escalatedPermissionUpdated}
      />;
      break;
    case 'kot':
      viewElement = <POSOrderKOTDetails
        loading={loading}
        posOrder={posOrder}
        resultMsg={resultMsg}
        posDetails={posDetails}

        cacheReset={this.cacheReset}
        updatePosOrder={this.updatePosOrder}
        setPOSOrder={this.props.setPOSOrder}
        updatePosOrderAdditional={this.updatePosOrderAdditional}
        escalatedPermissionUpdated={this.escalatedPermissionUpdated}
      />;
      break;
    case'details':
    default:
      viewElement = <POSOrderDetails
        loading={loading}
        posOrder={posOrder}
        resultMsg={resultMsg}
        posDetails={posDetails}
        cacheReset={this.cacheReset}
        property={this.props.property}
        setPOSOrder={this.props.setPOSOrder}
        fetchInitData={this.fetchInitData}
        updatePosOrder={this.updatePosOrder}
        updatePosOrderAdditional={this.updatePosOrderAdditional}
        escalatedPermissionUpdated={this.escalatedPermissionUpdated}
      />;
    }

    return (
      <div className="booking-details screen-container ">
        <div className="row">
          <div className={'col-lg-8 col-md-8 col-sm-12'}>
            <PageHeader
              subHeader={true}
              border={false}
              title={'POS Order Details'}
              description={'Reference number : ' + posOrder.ref_no}
              history={this.props.history}
            />
          </div>
          <div className={'col'}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <p onClick={() => this.cacheReset()} className={'mb-0 text-muted'}>Total</p>
                    <h2>{posOrder.currency + ' ' + posOrder.total}</h2>
                  </td>
                  <td>
                    <span className={'ml-2 badge ' + (AppUtil.posOrderStatusColor(posOrder.pos_order_status))}>
                      {posOrder.pos_order_status_display}
                    </span>
                    <span className={`ml-2 badge ${AppUtil.paymentStatusColor(posOrder.order_status)}`}>
                      {posOrder.order_status_display}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <p>
                      Time Elapsed :
                      <TimeCounterView time={posOrder.created} time2={posOrder.complete_time} timeInterval={1000}/>
                    </p>
                    <p className={'small text-muted mb-0'}>
                      <FontAwesomeIcon icon={faPlayCircle} className={'mr-1'} /> {AppUtil.formatDateTime(posOrder.created)}

                      {(posOrder.hold_time && posOrder.pos_order_status === Constants.POS_ORDER_STATUS.HOLD) &&
                        <React.Fragment>
                          <FontAwesomeIcon icon={faLongArrowAltRight} className={'mx-2'} />
                          <FontAwesomeIcon icon={faPauseCircle} className={'mr-1 blue-cl'} /> {AppUtil.formatDateTime(posOrder.hold_time)}
                        </React.Fragment>
                      }

                      {(posOrder.lock && posOrder.complete_time) &&
                      <React.Fragment>
                        <FontAwesomeIcon icon={faLongArrowAltRight} className={'mx-2'} />
                        <FontAwesomeIcon icon={faStopCircle} className={'mr-1'} /> {AppUtil.formatDateTime(posOrder.complete_time)}
                      </React.Fragment>
                      }
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr/>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.fetchInitData()}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                </button>
              </li>
              <li className="list-inline-item">
                <SelectorButton
                  title={'View Type'}
                  buttonList={viewButtonList}
                  selectButtonKey={posViewType}
                  action={(key) => this.setState({posViewType: key})}
                />
              </li>
            </ul>
            {viewElement}
          </div>
        </div>
        <History object={posOrder}/>

        <ModelPDFView
          title={this.state.showModelPDFViewTitle}
          onHide={() => this.setState({showModelPDFView: false, urlModelPDFView: null})}
          show={this.state.showModelPDFView}
          pdfUrl={this.state.urlModelPDFView}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      posViewType: 'details',
      urlModelPDFView: null,
      showModelPDFView: false,
      showModelPDFViewTitle: 'Print',
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Index);