/**
 * Payment Actions
 *
 */
import AppAPI from '../../lib/api';

/**
 * Set Payment
 */
export const setPayment = (paymentData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof paymentData === 'object') {
    dispatch({
      type: 'SET_ORDER',
      data: paymentData,
    });
    return resolve(paymentData);
  } else if (paymentData) {
    // Fetch Payment Data
    return AppAPI.payment.get(paymentData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_ORDER',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Payment
 */
export const updatePayment = (paymentData) => setPayment(paymentData);

/**
 * Remove Payment
 */
export function unSetPayment () {
  return (dispatch) => {
    dispatch({
      type: 'SET_ORDER',
      data: null,
    });
  };
}



/**
 * Set Refund
 */
export const setRefund = (refundData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof refundData === 'object') {
    dispatch({
      type: 'SET_REFUND',
      data: refundData
    });
    return resolve(refundData);
  } else if (refundData) {
    // Verify property is permitted for user
    return AppAPI.refund.get(refundData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_REFUND',
          data: res
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Refund
 */
export const updateRefund = (refundData) => setRefund(refundData);

/**
 * Remove refundData
 */
export function unSetRefund() {
  return (dispatch) => {
    dispatch({
      type: 'SET_REFUND',
      data: null
    });
  };
}



/**
 * Set PGateway
 */
export const setPGateway = (pgatewayData) => dispatch =>
  new Promise(async (resolve, reject) => {
    if (typeof pgatewayData === 'object') {
      dispatch({
        type: 'SET_PGATEWAY',
        data: pgatewayData,
      });
      return resolve(pgatewayData);
    } else if (pgatewayData) {
      return AppAPI.pgateway.get(`${pgatewayData}/`)
        .then(res => {
          dispatch({
            type: 'SET_PGATEWAY',
            data: res,
          });
          return resolve(res);
        })
        .catch(err => reject(err));
    }

    return reject();
  });

/**
 * Update PGateway
 */
export const updatePGateway = (pGatewatData) => setPGateway(pGatewatData);

/**
 * Remove PGateway
 */
export function unSetPGateway() {
  return (dispatch) => {
    dispatch({
      type: 'SET_PGATEWAY',
      data: null
    });
  };
}

/**
 * Check PGateway Status
 */
export const checkPaymentStatus = (pgatewayData) => (dispatch) => new Promise(async (resolve, reject) => {
  return AppAPI.pgateway.post(`${pgatewayData}/status`, {})
    .then((res) => {
      dispatch({
        type: 'SET_PGATEWAY',
        data: res,
      });
      return resolve(res);
    })
    .catch(err => reject(err));
});



/**
 * Set Route
 */
export const setRoute = routeData => dispatch =>
  new Promise(async (resolve, reject) => {
    if (typeof routeData === 'object') {
      dispatch({
        type: 'SET_ROUTE',
        data: routeData,
      });
      return resolve(routeData);
    } else if (routeData) {
      // Verify property is permitted for user
      return AppAPI.pgatewayapi
        .get('route/' + routeData + '/')
        .then(res => {
          // 200 response its permitted
          dispatch({
            type: 'SET_ROUTE',
            data: res,
          });
          return resolve(res);
        })
        .catch(err => reject(err));
    }

    return reject();
  });

/**
 * Update Route
 */
export const updateRoute = routeData => setRoute(routeData);

/**
 * Remove routeData
 */
export function unSetRoute() {
  return dispatch => {
    dispatch({
      type: 'SET_ROUTE',
      data: null,
    });
  };
}


/**
 * Set PTerminal
 */
export const setPTerminal = (pterminalData) => dispatch =>
  new Promise(async (resolve, reject) => {
    if (typeof pgatewayData === 'object') {
      dispatch({
        type: 'SET_PTERMINAL',
        data: pterminalData,
      });
      return resolve(pterminalData);
    } else if (pterminalData) {
      return AppAPI.pterminal.get(`${pterminalData}/`)
        .then(res => {
          dispatch({
            type: 'SET_PTERMINAL',
            data: res,
          });
          return resolve(res);
        })
        .catch(err => reject(err));
    }

    return reject();
  });

/**
 * Update Route
 */
export const updatePTerminal = routeData => setPTerminal(routeData);

/**
 * Remove routeData
 */
export function unSetPTerminal() {
  return dispatch => {
    dispatch({type: 'SET_PTERMINAL', data: null});
  };
}
