/**
 * Payment Reducer
 *
 */

// Set initial state
const initialState = {
  payment: {},
  refund: {},
  pgateway: {},
  ptermina: {},
  route: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
  case 'SET_ORDER': {
    let payment = [];
    if (action.data && typeof action.data === 'object') {
      payment = action.data;
    }
    return {
      ...state,
      payment,
    };
  }
  case 'SET_REFUND': {
    let refund = [];
    if (action.data && typeof action.data === 'object') {
      refund = action.data;
    }

    return {
      ...state,
      refund,
    };
  }
  case 'SET_PGATEWAY': {
    let pgateway = [];
    if (action.data && typeof action.data === 'object') {
      pgateway = action.data;
    }

    return {
      ...state,
      pgateway,
    };
  }
  case 'SET_PTERMINAL': {
    let pterminal = [];
    if (action.data && typeof action.data === 'object') {
      pterminal = action.data;
    }

    return {
      ...state,
      pterminal,
    };
  }
  case 'SET_ROUTE': {
    let route = [];
    if (action.data && typeof action.data === 'object') {
      route = action.data;
    }

    return {
      ...state,
      route,
    };
  }
  default:
    return state;
  }
};
