/**
 * Booking Channel log
 * Booking channel log details
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Loading from '../../../components/general/Loading';
import ModelPDFView from '../../../components/general/ModelPDFView';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
// Components
import { Constants, Strings } from '../../../constants';
import { Alerts } from '../../../components/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrderListSelector from '../../order/components/OrderListSelector';
import { faCheckSquare, faFileDownload, faSquare, faTimes } from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */

class BookingSummary extends Component {
  static componentName = 'BookingSummary';

  static propTypes = {
    show: PropTypes.bool,
    booking: PropTypes.object,
  };


  shouldComponentUpdate(nextProps) {
    if (nextProps.show !== this.props.show) {
      this.setState({
        init: true,
        loading: true,
        selectedOrder: [],
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
      if (nextProps.show === true) {
        this.fetchInitData();
      }
    }
    return true;
  }

  fetchInitData = () => {
    AppAPI.booking.get(`${this.props.booking.hash}/related-details/?key=order`)
      .then((res) => {
        this.setState({
          response: res,
          loading: false,
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          resultMsg: {error: error}
        });
      });
  };

  selectGuest = (selector, data, item) => {
    const stateData = {};
    stateData[`${selector}`] = AppUtil.insertOrRemoveArray(data, item.id);
    this.setState(stateData);
  };

  render = () => {

    const {resultMsg, loading, response, selectedOrder, expandOrder} = this.state;
    const {show, booking, property} = this.props;

    if (!show) {
      return null;
    }
    const {orders} = response;

    let orderList;
    if (!_.isEmpty(orders)) {
      orderList = orders.filter(item => (item.lock === true && item.void === false));
      if (booking.booking_order_mask) {
        orderList = orderList.filter(item => (item.order_source !== Constants.ORDER_SOURCE.BOOKING));
      }
    }

    return (
      <>
        <Modal {...this.props} size={'lg'} aria-labelledby="contained-modal-title-vcenter" scrollable centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter text-center">
              Booking Folio
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loading
              ? <Loading heightMatch={false}/>
              : (
                <>
                  {!_.isEmpty(orderList)
                    ? (
                      <>
                        {booking.booking_order_mask && (
                          <div className={'mb-3'}><p className={'text-muted'}>{Strings.bookingOrderMasked}</p></div>
                        )}
                        <p className={'mb-1 text-muted small'}>{Strings.bookingSummaryOrderSelection}</p>
                        <OrderListSelector
                          orders={orderList}
                          property={property}
                          selectedOrder={selectedOrder}
                          action={(data) => this.selectGuest('selectedOrder', selectedOrder, data)}
                          bulkAction={(data) => this.setState({selectedOrder: data})}
                        />
                        <p onClick={() => this.setState({expandOrder: !expandOrder})} className={'a-class'}>
                          <FontAwesomeIcon icon={expandOrder ? faCheckSquare : faSquare} className={'mr-2'}/>
                          {Strings.bookingSummaryOrderExpand}
                        </p>
                      </>
                    )
                    : (
                      <div className={'border p-4 text-center'}>
                        {booking.booking_order_mask && (
                          <div className={'mb-3'}><p className={'text-muted'}>{Strings.bookingOrderMasked}</p></div>
                        )}
                        <p className={'text-muted mb-0'}>{Strings.bookingSummaryOrderEmpty}</p>
                      </div>
                    )
                  }
                </>
              )
            }

          </Modal.Body>
          <Modal.Footer className={'d-block'}>
            <div className={'px-3'}>
              <Alerts
                error={resultMsg.error}
                status={resultMsg.status}
                success={resultMsg.success}
              />
            </div>
            <div className={'row'}>
              <div className={'col'}>
                <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                  <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                </button>
              </div>
              <div className={'col'}>
                <a
                  href={
                    !_.isEmpty(selectedOrder)
                      ? `${booking.resources_link}?orders=${selectedOrder.join(',')}${expandOrder ? '&orders_expanded=True' : ''}`
                      : `${booking.resources_link}?orders=null`
                  }
                  rel="noopener noreferrer" target={'_blank'} className={'btn btn-success btn-lg btn-block'}
                >
                  <FontAwesomeIcon className={'white-cl mr-2'} icon={faFileDownload} size={'sm'}/> Download Folio
                </a>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* PDF View */}
        <ModelPDFView
          title={'Print Order'}
          onHide={() => this.setState({showModelPDFView: false, urlModelPDFView: null})}
          show={this.state.showModelPDFView}
          pdfUrl={this.state.urlModelPDFView}
        />
      </>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      response: {},
      selectedOrder: [],
      expandOrder: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      }
    };
  }
}

/* Export Component ==================================================================== */
export default BookingSummary;
