/**
 * OrderSetListView
 *  View for Listing related orders
 *
 */

import _ from 'lodash';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import React, {Component} from 'react';
// Consts and Libs
import AppUtil from '../../../lib/util';

// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLongArrowAltRight} from '@fortawesome/free-solid-svg-icons';


/* Component ==================================================================== */
class BookingRowView extends Component {
  static componentName = 'BookingRowView';

  static propTypes = {
    data: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false
  }

  render = () => {
    const {data, disabled} = this.props;
    return (
      <React.Fragment>
        <table className={'table border table-sm format-table'}>
          <thead>
            <tr>
              <th className={'data-table-cell-md'}>Amount</th>
              <th className={'data-table-cell-sm'}/>
              <th className={'data-table-cell-sm'}>Reference</th>
              <th className={'data-table-cell-md'}>Source</th>
              <th className={'data-table-cell-xm'}>Date</th>
              <th/>
            </tr>
          </thead>
          <tbody>
            {data.map((data, key) => (
              <tr key={key}>
                <td><strong>{`${data.currency} ${data.total}`}</strong></td>
                <td>
                  <span className={'badge ' + (AppUtil.paymentStatusColor(data.order_status))}>
                    {data.order_status_display}
                  </span>
                  {!data.lock && <span className={'badge badge-warning small ml-2'}>Open Order</span>}
                </td>
                <td><small>{data.ref_no}</small></td>
                <td>
                  {data.order_source_display}
                  {!_.isEmpty(data.attributes) &&
                  <React.Fragment>
                    {data.attributes.pos_name && <p className={'small mb-0 text-muted'}>{data.attributes.pos_name}</p>}
                    {data.attributes.event_name && <p className={'small mb-0 text-muted'}>{data.attributes.event_name}</p>}
                    {data.attributes.booking_ota && <p className={'small mb-0 text-muted'}>{data.attributes.booking_ota}</p>}
                  </React.Fragment>
                  }
                </td>
                <td><small>{AppUtil.formatDateTime(data.created)}</small></td>
                <td>
                  {!disabled &&
                    <Link to={`/order/${data.hash}`}>
                      Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
                    </Link>
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </React.Fragment>
    );
  };
}

/* Export Component ==================================================================== */
export default BookingRowView;
