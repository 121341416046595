/**
 * POS Membership selector
 */
import queryString from 'query-string';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import {Link} from 'react-router-dom';
// Consts and Libs
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faUser} from '@fortawesome/free-solid-svg-icons';
import AppAPI from '../../../lib/api';
import SearchBar from '../../../components/forms/SearchBar';
import {Alerts} from '../../../components/ui';
import {confirmAlert} from '../../../components/general/ConfirmAlert';

/* Component ==================================================================== */

class POSMembershipAdd extends Component {
  static componentName = 'POSMembershipAdd';

  static propTypes = {
    property: PropTypes.object.isRequired,
    pos: PropTypes.object.isRequired,
    history: PropTypes.object,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
        loading: true,
        item: null,
      });
    }
    return true;
  }


  preFillData = () => {
    this.setState(
      {
        init: false,
        loading: false,
      },
    );
  };

  addMembership = (membership_id) => {
    const urlParams = {};
    const {pos} = this.props;
    if (pos.hash) {
      urlParams['pos'] = pos.hash;
    }
    let baseUrl = 'pos-membership-settings';
    if (membership_id) {
      this.setState({resultMsg: {status: 'One moment...'}});
      AppAPI.posapi.post(`${baseUrl}/?${queryString.stringify(urlParams)}`,
        {
          membership_ids: [membership_id],
        })
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            if (this.props.postAction) {
              this.props.postAction(res);
            }
            // if (this.props.history) {
            //   this.props.history.push(`/order/${res.id}`);
            // }
            this.props.onHide();
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  fetchData = (page = 1, callback, searchText) => {
    const urlParams = {...this.state.urlParams};
    urlParams['page'] = page;
    if (searchText) {
      this.setState({searching: true, clear: true});
      urlParams['q'] = searchText;
    }
    AppAPI.membership.get(`?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (res.results.length > 0) {
          callback(res.results, {allLoaded: !res.next});
        } else {
          callback();
        }
        this.setState({searching: false, clear: false, loading: false});
      })
      .catch((err) => {
        callback([], {allLoaded: true});
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, loading: false, error});
      });
  };


  emptyView = () => {
    return (
      <tr>
        <td colSpan={3}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faUser}/> No User Accounts </h4>
            <p>No user accounts linked in this property.</p>
          </div>
        </td>
      </tr>
    );
  };

  renderRow = (data, key) => {
    const {pos} = this.props;
    return (
      <tr key={key}>
        <td>
          <Link to={`/settings/property/accounts/${data.id}/`}>
            {data.user_profile ? data.user_profile.display_name : 'Account'}
          </Link>
        </td>
        <td>{data.user_profile ? data.user_profile.email : 'E-Mail'}</td>
        <td>{data.type_display}</td>
        <td>
          <button
            className={'btn btn-success'}
            onClick={() => {
              confirmAlert({
                title: 'Confirm Action',
                message: `This will add ${data.user_profile.display_name} to the Point of Sale ${pos.name}`,
                buttons: [
                  {
                    className: 'btn-success',
                    label: 'Confirm',
                    onClick: () => this.addMembership(data.id),
                  },
                  {
                    className: 'btn-secondary',
                    label: 'Cancel',
                  },
                ],
              });
            }}
          >
            Add User
          </button>
        </td>
      </tr>
    );
  };

  tableHeader = () => {
    return (
      <tr>
        <th className={'data-table-cell-md'}>Name</th>
        <th className={'data-table-cell-lg'}>Email</th>
        <th className={'data-table-cell-xm'}/>
        <th className={'data-table-cell-xm'}/>
      </tr>
    );
  };

  render = () => {

    const {init, resultMsg} = this.state;
    const {show} = this.props;

    if (show && init) {
      this.preFillData();
    }
    if (!show) {
      return null;
    }
    return (
      <Modal
        {...this.props}
        size={'lg'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Membership List
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SearchBar
            renderRow={this.renderRow}
            fetchData={this.fetchData}
            emptyView={this.emptyView}
            tableHeader={this.tableHeader}
            colSpan={4}
          />
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          <Alerts
            status={resultMsg.status}
            success={resultMsg.success}
            error={resultMsg.error}
          />
          <div className={'row'}>
            <div className={'col'}>
              <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: true,
      urlParams: {},
    };
  }
}

/* Export Component ==================================================================== */
export default POSMembershipAdd;
