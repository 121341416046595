/**
 *
 * Room Type Channel Manager Overview
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Consts and Libs
import AppAPI from '../../../lib/api';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PageHeader from '../../../components/dashboard/PageHeader';
import {confirmAlert} from '../../../components/general/ConfirmAlert';
import Strings from '../../../constants/strings';
import {Alerts} from '../../../components/ui';
import RoomInventoryList from '../../../components/functional/RoomInventoryList';
// Actions
import * as RoomTypeActions from '../../../redux/roomtype/actions';



/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  roomType: state.roomType.roomType,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setRoomType: RoomTypeActions.setRoomType,
};

/* Component ==================================================================== */
class RoomTypeChannelManagerOverview extends Component {
  static componentName = 'RoomTypeChannelManagerOverview';

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {property, roomType} = this.props;
    if (property.channel_manager_ok && roomType.channel_manager_ok) {
      AppAPI.roomapi.get(`room-type-overview/?room_type=${roomType.id}`)
        .then((res) => {
          this.setState({
            loading: false,
            error: null,
            roomTypes: res.room_types,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  updateRoomType = (roomType, payload) => {
    if (roomType && payload) {
      this.setState({resultMsg: {status: 'One moment...'}});
      AppAPI.roomapi.patch(`room-type-distribution/${roomType}/`, payload)
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}});
          this.props.setRoomType(res);
          setTimeout(() => {
            this.setState({
              resultMsg: {success: ''},
            });
          }, 500);
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  render = () => {
    const {loading, error, resultMsg, roomTypes} = this.state;
    if (!this.props.property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const {property, roomType} = this.props;

    return (
      <div className="channel-manager-settings screen-container">
        <Helmet>
          <title>Channel Manager Settings</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'Channel Manager Settings'}
        />

        <Alerts
          status={resultMsg.status}
          success={resultMsg.success}
          error={resultMsg.error}
        />
        <div className={'row mb-3'}>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <table className={'table border'}>
              <thead>
                <tr>
                  <td>Level</td>
                  <td>Connection</td>
                  <td>Distribution</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><strong>Property Level</strong></td>
                  <td>
                    <span className={'badge ' + (property.channel_manager_ok ? 'badge-success' : 'badge-secondary')}>
                      {property.channel_manager_ok ? 'Connected' : 'Not Connected'}
                    </span>
                  </td>
                  <td>
                    <span
                      className={'badge ' + (property.allow_channel_manager_distribution ? 'badge-success' : 'badge-secondary')}>
                      {property.allow_channel_manager_distribution ? 'Enabled' : 'Disabled'}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td><strong>Room Type Level</strong></td>
                  <td>
                    <span className={'badge ' + (roomType.channel_manager_ok ? 'badge-success' : 'badge-secondary')}>
                      {roomType.channel_manager_ok ? 'Connected' : 'Not Connected'}
                    </span>
                  </td>
                  <td>
                    <span
                      className={'badge ' + (roomType.allow_channel_manager_distribution ? 'badge-success' : 'badge-secondary')}>
                      {roomType.allow_channel_manager_distribution ? 'Enabled' : 'Disabled'}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {roomType.channel_manager_ok &&
           <div className="col-lg-6 col-md-6 col-sm-12">
             <ul className="list-group">
               <li className="list-group-item">
                 <button
                   onClick={() => {
                     confirmAlert({
                       title: (roomType.allow_channel_manager_distribution ? 'Disable' : ' Enable') + ' Distribution',
                       message: roomType.allow_channel_manager_distribution ? Strings.channelManagerSectionDistributionDisable : Strings.channelManagerSectionDistributionEnable,
                       buttons: [
                         {
                           className: (roomType.allow_channel_manager_distribution ? 'btn-danger' : ' btn-success'),
                           label: 'Yes',
                           onClick: () => this.updateRoomType(roomType.id, {allow_channel_manager_distribution: !roomType.allow_channel_manager_distribution}),
                         },
                         {
                           className: 'btn-secondary',
                           label: 'No',
                         },
                       ],
                     });
                   }}
                   className={'btn btn-block ' + (roomType.allow_channel_manager_distribution ? 'btn-danger' : ' btn-success')}>
                   {(roomType.allow_channel_manager_distribution ? 'Disable' : ' Enable') + ' Distribution'}
                 </button>
               </li>
               <li className="list-group-item">
                 <p className={'sm mb-2 text-secondary small'}>{Strings.channelManagerDistribution}</p>
               </li>
             </ul>
           </div>
          }
        </div>
        {roomType.channel_manager_ok &&
         <div>
           <hr/>
           <h5>Room Type and Packages</h5>
           <p>Room type and packages connected to channel manager.</p>
           <RoomInventoryList bookingEngine={false} refreshContent={this.fetchInitData} roomTypes={roomTypes}/>
         </div>
        }

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      roomTypes: [],
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomTypeChannelManagerOverview);
