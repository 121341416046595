/**
 *
 * Property Notification Template Details
 */
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../../lib/api';
import Strings from '../../../constants/strings';
// Components
import Error from '../../../components/general/Error';
import {faPen} from '@fortawesome/free-solid-svg-icons';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class NotificationContentDetails extends Component {
  static componentName = 'NotificationContentDetails';

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    AppAPI.property.get(`${this.props.property.id}/notification/email-content/?email_template=${this.props.match.params.contentId}`)
      .then((res) => {
        this.setState({templateData: res, loading: false});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: true,
          resultMsg: {error},
        });
      });
  }

  render = () => {
    const {loading, error, templateData} = this.state;
    const {property} = this.props;


    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const {content} = templateData;

    return (
      <div className="channel-manager-settings screen-container">
        <Helmet>
          <title>Notification Contents</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={`Email Content : ${templateData.name}`}
          description={`Customise the contents for ${templateData.name} email`}
        />

        <div className={'row'}>
          <div className="col-lg-9 col-md-8 col-sm-12 mb-5">
            <div className={'border p-2 text-center anchor w-100'}>
              <h6 className={'mb-0'}>Email Content</h6>
            </div>
            <div className={'border p-2 mb-3'} id={'primaryAction'}>
              <div className={'row'}>
                <div className={'col-12 text-center'}>
                  <img
                    width={75}
                    src={property.logo.logo} alt={property.name} className="img-thumbnail mx-auto"
                  />
                </div>
              </div>
              <hr/>

              <div className={'bg-light py-5 text-center mb-3'}>
                <p className={'text-muted'}>Email Content for {templateData.name}</p>
              </div>

              {_.isEmpty(content) ?
                <p className={'text-muted text-center'}>No Custom Contents Set</p>
                :
                <React.Fragment>
                  {content.pre_qa_content && <pre><p>{content.pre_qa_content}</p></pre>}
                  {
                    !_.isEmpty(content.qa_content) &&
                      <ul className={'list-unstyled'}>
                        {content.qa_content.map((data, i) => (
                          <li key={i} className={'list-unstyled-item'}>
                            <strong>{data.title}</strong>
                            <p className={'mb-1 text-muted'}><pre>{data.description}</pre></p>
                          </li>
                        ))}
                      </ul>
                  }
                  {content.post_qa_content && <p><pre>{content.post_qa_content}</pre></p>}
                </React.Fragment>
              }
              <hr/>
              <div className={'bg-light py-3 text-center'}>
                <p className={'text-muted mb-0'}>Footer Content</p>
              </div>
            </div>
            <p className={'small text-muted'}>{Strings.propertyNotificationContentDetailsWarning}</p>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-12 mb-5">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link to={`/settings/property/notification-settings/content/${templateData.key}/manage`}  className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      templateData: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContentDetails);
