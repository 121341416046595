/**
 *
 * Billing Order Details
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

// Consts and Libs
import AppAPI from '../../lib/api';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';

// Actions
import * as BillingAction from '../../redux/billing/actions';
import AppUtil from '../../lib/util';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  billingorder: state.billing.billingorder
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setBillingOrder: BillingAction.setBillingOrder,
  setBillingPayment: BillingAction.setBillingPayment
};

/* Component ==================================================================== */
class BillingOrderDetails extends Component {
  static componentName = 'BillingOrderDetails';
  static propTypes = {
    match: PropTypes.object.isRequired
  };
  paymentAction = (data) => {
    this.props.setBillingPayment(data);
    this.props.history.push('/billing/invoice/payment/' + data.hash);
  };
  componentDidMount = () => {
    if (this.props.match.params.orderId) {
      this.props.setBillingOrder(this.props.match.params.orderId)
        .then(() => {
          this.setState({
            loading: false
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error
          });
        });
    } else {
      this.setState({
        loading: false,
        error: true
      });
    }
  };
  render = () => {

    const {loading, error} = this.state;
    const {billingorder} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    return (
      <div className="order-details screen-container ">
        <Helmet>
          <title>Order Details</title>
        </Helmet>


        <div className="row">
          <div className="col-lg-7 col-md-12 col-sm-12">

            <div
              role="presentation" onKeyPress={this.handleKeyPress}
              onClick={() => this.props.history.goBack()} className="btn btn-outline-info">
              <span className="fa fa-chevron-left"/> Back
            </div>

            <a href={billingorder.resources_link} className="btn btn-outline-success float-right"><span
              className="fa fa-download"/> PDF</a>
            <div className="spacer-20"/>

            <h3 className={'mb-3'}>Total : {billingorder.currency + ' ' + billingorder.total.toString()}</h3>
            {!billingorder.order_status && <span className={'badge badge-dark mb-3'}>Cancelled</span>}
            {!billingorder.lock && <span className={'badge badge-warning mb-3'}>Open</span>}

            <div className="row mb-5">
              <div className="col">
                <ul className="list-group">
                  {billingorder.order_id &&
                   <li className="list-group-item">
                     <strong>Order ID</strong> : {billingorder.order_id}
                   </li>
                  }
                  <li className="list-group-item">
                    <strong>Payment Status </strong> :
                    <span
                      className={'badge ' + (AppUtil.paymentStatusColor(billingorder.payment_status))}>
                      {billingorder.get_payment_status_display}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  {billingorder.tax_company &&
                   <li className="list-group-item">
                     <strong>Company Name</strong> : {billingorder.tax_company}
                   </li>
                  }
                  {billingorder.tax_number &&
                   <li className="list-group-item">
                     <strong>Tax ID (GST)</strong> : {billingorder.tax_number}
                   </li>
                  }
                </ul>
              </div>
            </div>

            {(billingorder.payment_status === 2 || billingorder.payment_status === 3) &&
             <div className="row mb-5">
               {billingorder.payment_status === 2 &&
                <div className="col">
                  <ul className="list-group">
                    <li className="list-group-item">
                      <strong>Pending Payment</strong> : {billingorder.currency} {billingorder.total_pending}</li>
                  </ul>
                </div>
               }
               {billingorder.payment_status === 3 &&
                <div className="col">
                  <ul className="list-group">
                    <li className="list-group-item">
                      <strong>Refunded</strong> : {billingorder.currency} {billingorder.total_refunded}
                    </li>
                    <li className="list-group-item">
                      <strong>Available</strong> : {billingorder.currency} {billingorder.total_available_fund}
                    </li>
                  </ul>
                </div>
               }
             </div>
            }

            <div className="spacer-20"/>

            <h3>Items</h3>
            <p>Items present in the billing.</p>
            {
              (billingorder.billingorderitems_set && billingorder.billingorderitems_set.length) > 0
                ? <div>
                  <table className="table table-sm">
                    <tbody>
                      {billingorder.billingorderitems_set.map((data, i) => {
                        if (data.status) {
                          return (
                            <tr key={i}>
                              <td><p className={'mb-0'}>{data.quantity} x</p></td>
                              <td>
                                <strong>{data.description}</strong>
                                {data.plan && <p className={'mb-0 small'}>Term : {data.get_plan_type_display}</p>}
                                {data.tax > 0 &&
                                 <div>
                                   <p className={'mb-0 small'}>Price: {billingorder.currency} {data.price}</p>
                                   <p className={'mb-0 small'}>Tax: {billingorder.currency} {data.tax}</p>
                                 </div>
                                }
                                {data.plan_trial_length_days > 0 &&
                                 <p className={'mb-0 small'}>
                                   Trial : {data.plan_trial_length_days} day(s)
                                 </p>
                                }
                                {data.discount > 0 &&
                                 <p className={'mb-0 small'}>
                                   Discount: {billingorder.currency} {data.discount} ({data.discount_percent}%)
                                 </p>
                                }
                                {(data.taxtotal > 0) &&
                                 <small>
                                   <br/>
                                   Tax : {data.tax_description} = {data.taxtotal}
                                 </small>
                                }
                              </td>
                              <td className="text-right">
                                <p className={'mb-0'}><strong>{billingorder.currency} {data.total}</strong></p>
                                {data.item_status_available &&
                                 <span
                                   className={'badge ' + AppUtil.paymentStatusColor(data.item_status)}>
                                   {data.get_item_status_display}
                                 </span>
                                }
                              </td>
                            </tr>
                          );
                        }
                        return null;
                      })}
                    </tbody>
                  </table>

                  <table className="table table-sm">
                    <tbody>
                      <tr>
                        <td className="text-left">Sub Total</td>
                        <td className="text-right">{billingorder.currency} {billingorder.sub_total}</td>
                      </tr>
                      <tr>
                        <td className="text-left">Tax</td>
                        <td className="text-right">{billingorder.currency} {billingorder.tax}</td>
                      </tr>

                      {billingorder.discount &&
                       <tr>
                         <td className="text-left">Discount</td>
                         <td className="text-right">{billingorder.currency} {billingorder.discount}</td>
                       </tr>
                      }
                      <tr>
                        <td className="text-left"><strong>Total</strong></td>
                        <td className="text-right">
                          <strong>{billingorder.currency} {billingorder.total}</strong></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                : <div className="text-center">
                  <span className="glyphicon glyphicon-exclamation-sign large-font"/>
                  <p>No items in this order</p>
                </div>
            }

            {(billingorder.payments) &&
             <div>
               <hr/>
               <h4>Payments</h4>
               <p>Source and type of payments</p>
               <div
                 role="presentation"
                 rel="noopener noreferrer"
                 className="list-group-item list-group-item-action flex-column align-items-start a-class">
                 <div className="d-flex w-100 justify-content-between">
                   <h5 className="mb-1">{billingorder.payments.currency + ' ' + billingorder.payments.total}</h5>
                   <span className={'badge ' +
                                    (billingorder.payments.payment_status === 1 ? 'badge-success' : (billingorder.payments.lock === true ? 'badge-secondary' : 'badge-danger'))
                   }>{billingorder.payments.payment_status ? billingorder.payments.get_payment_status_display : billingorder.payments.lock ? 'Canceled' : billingorder.payments.get_payment_status_display}</span>
                 </div>
                 <p className="mb-1">Payment ID : {billingorder.payments.invoice_no}</p>
                 <p className="mb-1"><strong>Due
                   Date</strong> : {AppUtil.formatDateTime(billingorder.due_date, 'datef')} </p>
               </div>

               <div className="row mt-5 align-items-center">
                 <div className="col">
                   <ul className="list-group">
                     <li className="list-group-item">
                       <strong>Payment
                         Link</strong> : {billingorder.payments.payment_link ? billingorder.payments.payment_link : billingorder.payments.payment_link}
                     </li>
                   </ul>
                 </div>
                 <div className="col">
                   <a
                     className={'btn btn-success'}
                     target={'blank'}
                     href={billingorder.payments.payment_link ? billingorder.payments.payment_link : billingorder.payments.payment_link}>
                     {billingorder.payment_status === 1 ? 'View Payment Receipt' : 'Proceed to Payment'}
                   </a>
                 </div>
               </div>
             </div>
            }
          </div>
        </div>
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingOrderDetails);