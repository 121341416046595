/**
 * Expense Reducer
 *
 */

// Set initial state
const initialState = {
  category: {},
  expense: {},
  department: {},
  payment: {},
  vendor: {}
};

export default (state = initialState, action) => {
  switch (action.type) {

  case 'SET_EXPENSE_CATEGORY': {
    let category = [];
    if (action.data && typeof action.data === 'object') {
      category = action.data;
    }
    return {
      ...state,
      category,
    };
  }

  case 'SET_EXPENSE': {
    let expense = [];
    if (action.data && typeof action.data === 'object') {
      expense = action.data;
    }
    return {
      ...state,
      expense,
    };
  }

  case 'SET_EXPENSE_DEPARTMENT': {
    let department = [];
    if (action.data && typeof action.data === 'object') {
      department = action.data;
    }
    return {
      ...state,
      department,
    };
  }

  case 'SET_EXPENSE_PAYMENT': {
    let payment = [];
    if (action.data && typeof action.data === 'object') {
      payment = action.data;
    }
    return {
      ...state,
      payment,
    };
  }

  case 'SET_EXPENSE_VENDOR': {
    let vendor = [];
    if (action.data && typeof action.data === 'object') {
      vendor = action.data;
    }
    return {
      ...state,
      vendor,
    };
  }

  default:
    return state;
  }
};
