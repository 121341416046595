/**
 *
 * Guest List
 *
 */
import _ from 'lodash';
import Moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// Components
import Error from '../../components/general/Error';
import SearchBar from '../../components/forms/SearchBar';
import FilterDate from '../../components/dashboard/FilterDate';
import PageHeader from '../../components/dashboard/PageHeader';
import ExportButton from '../../components/dashboard/ExportButton';
// Components
import { Constants } from '../../constants';
import Strings from '../../constants/strings';
import GuestQuickManage from './GuestManageQuick';
import Loading from '../../components/general/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding,
  faFolder,
  faLongArrowAltRight,
  faPlus,
  faSuitcase,
  faSync,
  faUser
} from '@fortawesome/free-solid-svg-icons';

// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import AppWebUtil from '../../lib/webUtils';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class GuestList extends Component {
  static componentName = 'GuestList';

  static propTypes = {
    isGuest: PropTypes.bool,
    isAgent: PropTypes.bool,
    isVendor: PropTypes.bool,
    subHeader: PropTypes.bool,
    isCorporate: PropTypes.bool,
  };


  static defaultProps = {
    subHeader: false,
  };

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };

  componentDidMount = () => {
    let type = 'Guest', profile_type = Constants.PROFILE_TYPE.GUEST;
    if (this.props.isCorporate) {
      type = 'Corporate';
      profile_type = Constants.PROFILE_TYPE.CORPORATE;
    } else if (this.props.isAgent) {
      type = 'Agent';
      profile_type = Constants.PROFILE_TYPE.AGENT;
    } else if (this.props.isVendor) {
      type = 'Vendor';
      profile_type = Constants.PROFILE_TYPE.VENDOR;
    }

    let urlParams = {}, startDate = null, endDate = null;
    if (this.getQueryParams('start_date')) {
      startDate = Moment(String(this.getQueryParams('start_date')));
    }
    if (this.getQueryParams('end_date')) {
      endDate = Moment(String(this.getQueryParams('end_date')));
    }
    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('start_date'), this.getQueryParams('end_date'), 'created_date');


    this.setState({
      urlParams,
      startDate,
      endDate,
      loading: false, type: type, profile_type: profile_type
    });
  };


  fetchData = (page = null, callback, searchText, download) => {
    const urlParams = {...this.state.urlParams};
    let baseUrl = 'guest-v2';
    urlParams['profile_type'] = this.state.profile_type;
    if (page) {
      urlParams['cursor'] = page;
    }

    if (searchText) {
      this.setState({searching: true, clear: true});
      baseUrl = 'guest-search';
      urlParams['search'] = searchText;
      if (page){
        urlParams['page'] = page;
      }
    }

    if (download) {
      urlParams['download'] = download.file;
      urlParams['all_data'] = true;
      baseUrl = 'guest-escalated-permission';
      this.setState({fileDownload: true});
    }

    AppAPI.guestapi.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (download) {
          let fileName = `Guest-Report-${AppUtil.formatDateTime(Moment(), 'short')}.${download.file}`;
          AppWebUtil.downloadFile(res, fileName);
        } else {
          if (res.results.length > 0) {
            callback(
              res.results,
              {
                allLoaded: !res.next,
                nextParam: res.count ? AppUtil.getURLParam(res.next, 'page') : AppUtil.getURLParam(res.next, 'cursor')
              }
            );
          } else {
            callback();
          }
        }
        this.setState({
          searching: false,
          clear: false,
          refresh: false,
          loading: false,
          fileDownload: false,
          page: page,
        });
      })
      .catch((err) => {
        if (callback) {
          callback([], {allLoaded: true});
        }
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, refresh: false, loading: false, fileDownload: false, error});
      });
  };

  emptyView = () => {
    const {profile_type} = this.state;
    let image = faUser, emptyText = Strings.guestListEmptyText, title = 'Guest';
    if (profile_type === Constants.PROFILE_TYPE.CORPORATE) {
      image = faBuilding;
      title = 'Corporate';
      emptyText = Strings.corporateListEmptyText;
    } else if (profile_type === Constants.PROFILE_TYPE.AGENT) {
      title = 'Agent';
      image = faSuitcase;
      emptyText = Strings.agentListEmptyText;
    } else if (profile_type === Constants.PROFILE_TYPE.VENDOR) {
      title = 'Vendor';
      image = faSuitcase;
      emptyText = Strings.agentListEmptyText;
    }

    return (
      <tr>
        <td colSpan={8}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={image}/> {title} </h4>
            {emptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };

  renderRow = (data, key) => {
    let baseUrl;
    if (data.profile_type === Constants.PROFILE_TYPE.CORPORATE) {
      baseUrl = `/customer/corporate/${data.hash}/`;
    } else if (data.profile_type === Constants.PROFILE_TYPE.AGENT) {
      baseUrl = `/customer/agent/${data.hash}/`;
    } else if (data.profile_type === Constants.PROFILE_TYPE.VENDOR) {
      baseUrl = `/settings/expense/vendor/${data.hash}/`;
    } else {
      baseUrl = `/customer/guest/${data.hash}/`;
    }

    if (this.props.settings) {
      baseUrl = `/settings/${baseUrl}/`;
    }

    return (
      <tr className={'tr-align-middle'} key={key}>
        <td className={'data-table-cell-lg'}>
          {data.entity_name ?
            <strong>{data.entity_name}</strong>
            :
            <strong>{data.name}</strong>
          }

        </td>
        <td className={'data-table-cell-lg text-left'}>{data.email}</td>
        <td className={'data-table-cell-lg text-left'}>{data.phone}</td>
        <td className={'data-table-cell-sm'}>
          <span className={`ml-2 badge ${data.record_exist ? 'badge-success' : 'badge-warning'}`}>
            Document
          </span>
        </td>
        <td className={'data-table-cell-md'}><small>{AppUtil.formatDateTime(data.created)}</small></td>
        <td className={'data-table-cell-md'}>
          <Link to={baseUrl}>
            Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
          </Link>
        </td>
      </tr>
    );
  };

  tableHeader = () => {
    return (
      <tr>
        <th>Name</th>
        <th className={'text-left'}>Email</th>
        <th className={'text-left'}>Phone</th>
        <th>Record</th>
        <th>Date</th>
        <th/>
      </tr>
    );
  };


  action = (data) => {
    if (data) {
      this.props.history.push(`/customer/guest/${data.hash}/`);
    }
  };

  render = () => {
    const {loading, error, clear, searching, results, type, profile_type, refresh, fileDownload} = this.state;
    const {subHeader, property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="dashboard screen-container">
        <Helmet>
          <title>{type} List</title>
        </Helmet>

        <PageHeader
          history={this.props.history}
          title={`${type} List`}
          description={`Property ${type} list.`}
          subHeader={subHeader}
        />

        <div className={'row'}>
          <div className={'col-12 col-sm-6'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.setState({refresh: true})}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                </button>
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data))}
                  size={'sm'}
                  maxDate={Moment().add(1, 'days')}
                  title={'Created'}
                />
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-6 text-center text-sm-right'}>
            <ul className="list-inline">
              {(profile_type === Constants.PROFILE_TYPE.GUEST) && (
                <React.Fragment>
                  <li className="list-inline-item">
                    <button
                      onClick={() => this.setState({showGuestManagement: true})}
                      className={'btn btn-sm btn-outline-primary'}>
                      <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Guest
                    </button>
                  </li>
                  <li className="list-inline-item">
                    <Link to={'/customer/guest/new'} className="btn btn-sm btn-outline-primary">
                      <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Guest [Advance]
                    </Link>
                  </li>
                </React.Fragment>
              )}

              {(profile_type === Constants.PROFILE_TYPE.AGENT) &&
              <li className="list-inline-item">
                <Link to={'/customer/agent/new'} className="btn btn-sm btn-outline-primary">
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add {type}
                </Link>
              </li>
              }

              {(profile_type === Constants.PROFILE_TYPE.CORPORATE) &&
              <li className="list-inline-item">
                <Link to={'/customer/corporate/new'} className="btn btn-sm btn-outline-primary">
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add {type}
                </Link>
              </li>
              }

              {(profile_type === Constants.PROFILE_TYPE.VENDOR) &&
              <li className="list-inline-item">
                <Link to={'/settings/expense/vendor/new'} className="btn btn-sm btn-outline-primary">
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add {type}
                </Link>
              </li>
              }
              {(!_.isEmpty(property.config) && property.config.profile && property.config.profile.profile_export) && (
                <ExportButton
                  size={'sm'}
                  files={['csv']}
                  action={(key) => this.fetchData(this.state.page, null, null, {file: key})}
                  loading={fileDownload}
                  disabled={!(this.state.startDate)}
                />
              )}
            </ul>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-12">
            <SearchBar
              clear={clear}
              results={results}
              refresh={refresh}
              searching={searching}
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              searchFunction={this.fetchData}
              tableHeader={this.tableHeader}
              colSpan={6}
              placeHolder="Name, phone or email"
              listVersion={'V2'}
            />
          </div>
        </div>
        {(!_.isEmpty(property.config) && property.config.profile && property.config.profile.profile_record_export) && (
          <div>
            <Link to={'/customer/records'} className="btn btn-sm btn-outline-primary">
              <FontAwesomeIcon icon={faFolder} size={'sm'} className={'mr-2'}/> View records
            </Link>
          </div>
        )}
        <GuestQuickManage
          search={false}
          title={'Add Guest'}
          guestAddAction={this.action}
          property={this.props.property}
          show={this.state.showGuestManagement}
          onHide={() => this.setState({showGuestManagement: false})}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      searching: false,
      refresh: false,
      clear: false,
      results: [],
      type: 'Guest',
      profile_type: 1,
      showGuestManagement: false,
      showGuestDetails: false,
      showGuestDetailsId: null,
      addGuestToRoom: false,
      urlParams: {},
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuestList);
