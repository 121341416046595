/**
 *  POS Membership Manage
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Actions
import * as POSActions from '../../../redux/pos/actions';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import {Constants, ErrorMessages, Strings} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave} from '@fortawesome/free-solid-svg-icons';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pos: state.pos.pos,
  posMembership: state.pos.posMembership,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPOSMembership: POSActions.setPOSMembership,
};

/* Component ==================================================================== */

class POSMembershipManage extends Component {
  static componentName = 'POSMembershipManage';

  static propTypes = {
    match: PropTypes.object,
  };

  componentDidMount = () => {
    if (this.props.match.params.POSMembershipId) {
      this.props.setPOSMembership(this.props.match.params.POSMembershipId, this.props.match.params.POSId)
        .then(() => {
          this.fetchInitData();
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.fetchInitData();
    }
  };

  saveMembership = () => {
    const {pos} = this.props;
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.posapi.patch(`pos-membership-settings/${this.props.match.params.POSMembershipId}/?pos=${pos.hash}`,
        {
          'permission_set': this.state.permissions,
        })
        .then(() => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                this.props.history.goBack();
              }, 500);
            },
          );
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    });
  };

  fetchInitData = () => {
    const {posMembership} = this.props;

    this.setState({
      permissions: posMembership.permissions ? posMembership.permissions.split(',') : [],
      loading: false,
    });
  };

  selectPermission = (data) => {
    let {permissions} = this.state;
    permissions = AppUtil.insertOrRemoveArray(permissions, data.key);
    this.setState({permissions});
  };


  render = () => {

    const {posMembership} = this.props;
    const {loading, error, resultMsg, permissions} = this.state;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    let userName = posMembership.user ? posMembership.user.display_name : posMembership.id;
    return (

      <div className="pos-table-list-manage screen-container ">
        <Helmet>
          <title>POS Membership Manage</title>
        </Helmet>
        <PageHeader
          subHeader={true} history={this.props.history}
          title={'Manager Membership'}
          description={`Manager permissions for ${userName}`}
        />

        <div className={'row'}>
          <div className="col-lg-6 col-md-12 col-sm-12 mt-2">
            <ul className="list-group mt-3">
              <li className="list-group-item">
                <strong>Name : </strong> {posMembership.user.display_name}
              </li>
              <li className="list-group-item">
                <strong>Email : </strong> {posMembership.user ? posMembership.user.email : 'N.A'}
              </li>
              <li className="list-group-item">
                <strong>Type : </strong> {posMembership.membership.type_display}
              </li>
            </ul>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 mt-2">
            <h5>{Strings.membershipPermissionTitle}</h5>
            {Strings.membershipPermissionManageDescription.map((data, i) => (
              <p className={'mb-0 small'} key={i}>{data}</p>
            ))}

            <ul className="list-group my-3">
              {/* eslint-disable-next-line array-callback-return */}
              {Constants.POS_MEMBERSHIP_PERMISSIONS.map((data, i) => {
                return (
                  <li className="list-group-item" key={i}>
                    <div className={'custom-control custom-switch'}>
                      <input
                        id={i}
                        type="checkbox"
                        className={'custom-control-input mr-3'}
                        checked={permissions && permissions.includes(data.key)}
                        onChange={() => this.selectPermission(data)}
                      />
                      <label className="custom-control-label" htmlFor={i}>
                        {data.name} <br/>
                        <p className={'small mb-0'}>{data.description}</p>
                      </label>
                    </div>
                  </li>
                );
              })
              }
            </ul>

            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />

            <button className={'btn btn-success btn-block btn-lg mt-2'} onClick={() => this.saveMembership()}>
              <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
            </button>
          </div>
        </div>


      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      permissions: [],
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      initialValues: {},
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(POSMembershipManage);
