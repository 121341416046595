/**
 * Model Instant PDF Viewer
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
// Consts and Libs
import Loading from './Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClipboardList, faTimes} from '@fortawesome/free-solid-svg-icons';
// Components
import { Alerts } from '../ui';

/* Component ==================================================================== */

class ModelPDFView extends Component {
  static componentName = 'ModelPDFView';

  static propTypes = {
    size: PropTypes.string,
    match: PropTypes.object,
    error: PropTypes.string,
    height: PropTypes.string,
    pdfUrl: PropTypes.string.isRequired
  };

  static defaultProps = {
    height: '400px'
  }


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  preFillData = () => {
    this.setState(
      {
        init: false,
        loading: false,
      },
    );
  };

  render = () => {

    const {init, loading} = this.state;
    const {show, pdfUrl, title, size, height} = this.props;

    if (show && init) {
      this.preFillData();
    }

    return (
      <Modal
        {...this.props}
        size={size ? size : 'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ?
            <Loading heightMatch={true}/>
            :
            <div>
              <object
                data={pdfUrl}
                type="application/pdf"
                width="100%"
                height={height}>
                <p>Your browser does not support PDFs.</p>
                <a
                  href={pdfUrl}
                  rel='noopener noreferrer' target={'_blank'}
                  className={'btn btn-link mr-2 '}>
                  <FontAwesomeIcon icon={faClipboardList} size={'sm'} className={'mr-2'}/> Download PDF
                </a>
              </object>
            </div>
          }
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          {this.props.error && <Alerts error={this.props.error}/>}
          <div className={'row'}>
            <div className={'col mx-auto'}>
              <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      pdfData: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: true,
    };
  }
}

/* Export Component ==================================================================== */
export default ModelPDFView;
