/**
 * Order List Selector
 */
import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Consts and Libs
import AppUtil from '../../../lib/util';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faCircle, faInfo, faLongArrowAltRight} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */
class OrderListSelector extends Component {
  static componentName = 'OrderListSelector';

  static propTypes = {
    action: PropTypes.func,
    disabled: PropTypes.bool,
    orders: PropTypes.object,
    bulkAction: PropTypes.func,
    property: PropTypes.object,
    className: PropTypes.string,
    selectedOrder: PropTypes.object,
  };

  static defaultProps = {
    className: '',
    disabled: false,
  };

  action = (data) => {
    if (this.props.action && !this.props.disabled) {
      this.props.action(data);
    }
  };

  render = () => {
    const {className, orders, selectedOrder, property} = this.props;
    const orderList = [];
    orders.forEach((data) => orderList.push(data.id));
    return (
      <div className={className}>
        <div className="table-responsive border rounded mb-3">
          <table className={'table table-sm mb-0'}>
            <thead>
              <tr>
                <td/>
                <th>Reference</th>
                <th/>
                <th className={'text-center'}>Source</th>
                <th className={'text-left'}>Total</th>
                <th className={'text-left'}>Paid</th>
                <th className={'text-left'}>Remaining</th>
              </tr>
            </thead>
            <tbody>
              {!_.isEmpty(orders) ?
                <React.Fragment>
                  {orders.map((data, i) => (
                    <tr
                      key={i}
                      onClick={() => this.action(data)}
                      className={`tr-align-middle a-class ${selectedOrder.includes(data.id) ? 'lime-bg' : ''}`}
                    >
                      <td>
                        <FontAwesomeIcon
                          icon={selectedOrder.includes(data.id) ? faCheckCircle : faCircle}
                          className={`${selectedOrder.includes(data.id) ? 'green-cl' : 'grey-cl'} mr-2`}
                        />
                      </td>
                      <td className={'data-table-cell-lg'}>{data.ref_no}</td>
                      <td className={'data-table-cell-sm'}>
                        <span className={'ml-2 badge ' + (AppUtil.paymentStatusColor(data.order_status))}>
                          {data.order_status_display}
                        </span>
                      </td>
                      <td className={'data-table-cell-md text-center'}>
                        {data.order_source_display}
                        {!_.isEmpty(data.attributes) &&
                        <React.Fragment>
                          {data.attributes.pos_name &&
                          <p className={'mb-0 small text-muted'}>{data.attributes.pos_name}</p>}
                          {data.attributes.event_name &&
                          <p className={' mb-0 small text-muted'}>{data.attributes.event_name}</p>}
                          {data.attributes.booking_ota &&
                          <p className={'mb-0 small text-muted'}>{data.attributes.booking_ota}</p>}
                        </React.Fragment>
                        }
                      </td>
                      <td className={'data-table-cell-md text-left'}>{`${data.currency} ${data.total}`}</td>
                      <td className={'data-table-cell-md text-left'}>{`${data.currency} ${data.total_paid}`}</td>
                      <td className={'data-table-cell-md text-left'}>{`${data.currency} ${data.total_unpaid_payment}`}</td>
                    </tr>
                  ))}
                </React.Fragment>
                :
                <React.Fragment>
                  <tr>
                    <td colSpan={7} className={'text-center py-3'}>
                      <FontAwesomeIcon icon={faInfo}/>
                      <p className={'text-muted'}>No Orders to select</p>
                    </td>
                  </tr>
                </React.Fragment>

              }
              <tr>
                <td colSpan={4}>
                  {this.props.bulkAction &&
                    <button type={'button'} onClick={() => {
                      this.props.bulkAction(orderList.length === selectedOrder.length ? [] : orderList);
                    }} className={'btn btn-outline-secondary btn-sm float-left'}>
                      {orderList.length === selectedOrder.length ? 'Remove all' : 'Select all'}
                    </button>
                  }
                  <p className={'text-muted text-right mb-0'}>
                  Total of selected orders <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-3'}/>
                  </p>
                </td>
                <td>{`${property.currency} ${orders.reduce((a, b) => parseFloat(a) + (selectedOrder.includes(b.id) ? parseFloat(b.total) : 0), 0)}`}</td>
                <td>{`${property.currency} ${orders.reduce((a, b) => parseFloat(a) + (selectedOrder.includes(b.id) ? parseFloat(b.total_paid) : 0), 0)}`}</td>
                <td>{`${property.currency} ${orders.reduce((a, b) => parseFloat(a) + (selectedOrder.includes(b.id) ? parseFloat(b.total_unpaid_payment) : 0), 0)}`}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default OrderListSelector;
