/**
 *
 * Event Settings
 * event information
 */

import React, {Component} from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppUtil from '../../lib/util';
import {Constants, Strings} from '../../constants';
// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import {History} from '../../components/ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen} from '@fortawesome/free-solid-svg-icons';
import TicketDetailsView from './TicketDetailsView';

/* Component ==================================================================== */
class EventOverView extends Component {
  static componentName = 'EventOverView';
  static propTypes = {
    match: PropTypes.object.isRequired,
    event: PropTypes.object.isRequired,
    property: PropTypes.object.isRequired
  };

  render = () => {
    const {loading, error} = this.state;
    const {event} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="pos-details screen-container ">
        <Helmet>
          <title>Event Details</title>
        </Helmet>

        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                <ul className="list-group">
                  <li className="list-group-item"><strong>Reference Number</strong> : {event.ref_no}</li>
                </ul>
              </div><div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Event Status</strong> :
                    <span className={'badge ' + (AppUtil.ticketBookingStatusColor(event.event_status))}>
                      {event.event_status_display}
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 mb-5 mb-md-3">
                <ul className="list-group">
                  <li className="list-group-item"><strong>Name</strong> : {event.name}</li>
                  <li className="list-group-item"><strong>Event Type</strong> : {event.event_type_display}</li>
                  {event.event_type === Constants.EVENT_TYPE.RECURRING &&
                   <React.Fragment>
                     <li className="list-group-item">
                       <strong>Recurring Type</strong> : {event.event_recurring_type_display}
                     </li>
                     {event.event_recurring_type === Constants.EVENT_RECURRING_TYPE.WEEKLY &&
                      <li className="list-group-item">
                        <strong>Week day</strong> : {event.event_recurring_week_day_display}
                      </li>
                     }
                   </React.Fragment>
                  }
                  {event.event_type === Constants.EVENT_TYPE.RECURRING &&
                   <li className="list-group-item">
                     <strong>
                       {event.event_recurring_type === Constants.EVENT_RECURRING_TYPE.LIMITED ? 'Length of event ' : 'Advanced booking '} :
                     </strong>
                     {event.allow_advance_booking_days} days
                   </li>
                  }
                </ul>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mb-5 mb-md-3">
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Event {event.event_type === Constants.EVENT_TYPE.RECURRING && 'Start'} Date </strong> : {AppUtil.formatDateTime(event.event_start_date, 'datef')}
                  </li>
                  {event.event_type === Constants.EVENT_TYPE.RECURRING &&
                   <React.Fragment>
                     <li className="list-group-item">
                       <strong>Event {event.event_recurring_type === Constants.EVENT_RECURRING_TYPE.LIMITED ? 'End ' : ' Booking End'} Date </strong> : {AppUtil.formatDateTime(event.event_end_date, 'datef', )}
                     </li>
                     <li className="list-group-item">
                       <strong>Week Days</strong> : {AppUtil.formatWeekDays(event.event_recurring_week_days)}
                     </li>
                   </React.Fragment>
                  }
                  <li className="list-group-item">
                    <strong>Event Schedule</strong> : {AppUtil.formatDateTime(event.event_schedule_start_time, 'time')} to {parseInt(event.event_schedule_length) > 1 && ` + ${event.event_schedule_length} day(s)`} {AppUtil.formatDateTime(event.event_schedule_end_time, 'time')}
                  </li>

                </ul>
              </div>
            </div>

            <div className={'row'}>
              <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Status</strong> :
                    <span className={'badge ' + (event.active === true ? 'badge-success' : 'badge-danger')}>
                      {(event.active === true ? 'Active' : 'Deactivated')}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                <ul className="list-group">
                </ul>
              </div>
            </div>
            <hr/>
            <h4>Tickets</h4>
            {
              Strings.eventTicketsDescriptions.map((data, i) => <p key={i}>{data}</p>)
            }
            <table className={'table'}>
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Name</th>
                  <th>Price ({this.props.property.currency} )</th>
                  <th>Ticket Type</th>
                  <th>Status</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {event.tickets && event.tickets.length > 0 ?
                  <React.Fragment>
                    {event.tickets.map((data, key) => (
                      <tr key={key}>
                        <td>{data.ref_no}</td>
                        <td>{data.name}</td>
                        <td>{data.base_price_effective}</td>
                        <td>
                          {data.ticket_quantity_type_display}
                          {data.ticket_quantity_type === Constants.EVENT_TICKET_TYPE.LIMITED &&
                            <React.Fragment> ({data.ticket_quantity})</React.Fragment>
                          }
                        </td>
                        <td>
                          <span className={'badge ' + (data.active ? ' badge-success' : 'badge-secondary')}>
                            {data.active ? 'Active' : 'De-active'}
                          </span>
                        </td>
                        <td>
                          <button className={'btn btn-link'} onClick={()=>this.setState({showTicketDetailsView: true, showTicketDetailsViewId: data.id})}>
                            Details
                          </button>
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                  :
                  <tr>
                    <td colSpan={8} className={'text-center'}>
                      {
                        Strings.eventTicketsEmptyText.map((data, i) => <p key={i}>{data}</p>)
                      }
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item">
                <Link to={`/event/${event.hash}/lookup/`} className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Look up
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <TicketDetailsView
          show={this.state.showTicketDetailsView}
          ticketId={this.state.showTicketDetailsViewId}
          onHide={()=>this.setState({showTicketDetailsView: false})}
          property={this.props.property}
        />
        <History object={event}/>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      showTicketDetailsView: false,
      showTicketDetailsViewId: null,
    };
  }
}

export default EventOverView;