/**
 * Manage Notification Config
 *  manages notification config
 */

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { FieldArray, Form, Formik } from 'formik';
// Form Data
import * as Yup from 'yup';
import { Input } from 'react-formik-ui';
// Components
import { Alerts } from '../../../../components/ui';
// Consts and Libs
import AppAPI from '../../../../lib/api';
import { AppConfig } from '../../../../constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
/* Component ==================================================================== */

class NotificationConfigManage extends React.Component {
  static componentName = 'NotificationConfigManage';

  static propTypes = {
    property: PropTypes.object,
    updateResponse: PropTypes.func,
    notificationType: PropTypes.string,
    notificationData: PropTypes.object,
    notificationTemplateData: PropTypes.object,
  };

  saveNotificationConfig = (credentials) => {
    const {property, notificationTemplateData, notificationType} = this.props;
    if (credentials) {
      const email_address = [], phone_numbers = [];

      if (!_.isEmpty(credentials.address)) {
        credentials.address.forEach((data) => {
          email_address.push(data.email);
          phone_numbers.push(data.phone);
        });
      }
      this.setState({resultMsg: {status: 'One moment...'}});

      const payload = {};
      if (notificationType === 'email') {
        payload.email_address = email_address;
      } else {
        payload.phone_numbers = phone_numbers;
      }

      AppAPI.property.post(
        `${property.id}/notification/config-bcc/?template=${notificationTemplateData.key}&config_type=${notificationType}`,
        payload
      )
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
              if (this.props.updateResponse) {
                this.props.updateResponse(res);
              }
              this.props.onHide();
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        selectedOrder: [],
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  render = () => {

    const {resultMsg} = this.state;
    const {notificationTemplateData, notificationType, notificationData, show} = this.props;

    if (!show || _.isEmpty(notificationTemplateData)) return <React.Fragment/>;

    const contentValidationEmail = Yup.object().shape({
      email: Yup.string().email('Invalid email').required('Enter an email address.'),
    });
    const contentValidationPhone = Yup.object().shape({
      phone: Yup.string().matches(
        AppConfig.regXValidation.phoneRegex, 'Invalid Phone'
      ).min(8, 'to short').max(13, 'Max number length is 12').required('Enter a phone number.')
    });
    const defaultFormValidation = Yup.object().shape({
      address: Yup.array().of(notificationType === 'email' ? contentValidationEmail : contentValidationPhone)
    });

    const notificationName = (notificationType === 'email' ? 'Email Address' : 'Phone Number');
    const notificationKey = (notificationType === 'email' ? 'email' : 'phone');

    const addressInitial = [];
    if (!_.isEmpty(notificationData)){
      notificationData.forEach((data)=>{
        addressInitial.push({
          [notificationKey]: data
        });
      });
    }

    return (
      <Modal
        {...this.props}
        centered
        size={'md'}
        scrollable={true}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Notification Config : {notificationTemplateData.name}
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            address: addressInitial,
          }}
          validationSchema={defaultFormValidation}
          onSubmit={values => this.saveNotificationConfig(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <p className={'small text-muted'}>
                  Copy {notificationName} list for notification template - {notificationTemplateData.name}. ( Max 3 )
                </p>
                <Form className={'form-group'}>
                  <FieldArray
                    name="address"
                    render={arrayHelpers => (
                      <div>
                        {!_.isEmpty(formikProps.values.address) ? (
                          <div>
                            {formikProps.values.address.map((address_data, index) => (
                              <div key={index} className={`form-row ${index > 0 && 'border-top pt-3'}`}>
                                <div className="col-9">
                                  <div className={'form-group'}>
                                    <Input
                                      required
                                      name={`address.${index}.${notificationKey}`}
                                      label={notificationName}
                                      className={'form-control'}
                                    />
                                  </div>
                                </div>
                                <div className={'col-3 text-center'}>
                                  <div className="btn-group btn-group-sm mt-4" role="group">
                                    <button
                                      onClick={() => arrayHelpers.remove(index)}
                                      type="button" className="btn btn-outline-danger">
                                      <FontAwesomeIcon
                                        className={'mr-2 d-none d-md-inline-block'} icon={faMinus} size={'sm'}
                                      /> Remove
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className={'pt-3 border-top text-center'}>
                              <button
                                disabled={formikProps.values.address.length > 2}
                                className={'btn btn-success'} type="button"
                                onClick={() => arrayHelpers.push({[notificationKey]: ''})}
                              >
                                <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/>
                                Add {notificationName}
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className={'text-center border p-3'}>
                            <p>No {notificationName} configured for copy.</p>
                            <button
                              className={'btn btn-success'} type="button"
                              onClick={() => arrayHelpers.push({[notificationKey]: ''})}
                            >
                              <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/>
                              Add {notificationName}
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  />
                </Form>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <button
                      type="submit" onClick={formikProps.handleSubmit} className={'btn btn-success btn-lg btn-block'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default NotificationConfigManage;
