/**
 * User Reducer
 *
 */

// Set initial state
const initialState = {
  user: {}
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
  case 'USER_REPLACE': {
    let user = [];
    user = action.data;
    return {
      ...user,
      user,
    };
  }

  default:
    return state;
  }
};

export default userReducer;