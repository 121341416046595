/**
 * Payment Bifurcation Table
 *
 */

import { faExternalLinkSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
// Consts and Libs
import AppUtil from '../../../../lib/util';
// Components


/* Component ==================================================================== */
class PABifurcationTable extends Component {
  static componentName = 'PABifurcationTable';

  static propTypes = {
    expandData: PropTypes.func,
    property: PropTypes.object.isRequired,
    orderData: PropTypes.object.isRequired,
    deductionKey: PropTypes.string.isRequired,
    bifurcationData: PropTypes.object.isRequired,
    bifurcationTitle: PropTypes.string.isRequired,
    deductionType: PropTypes.string.isRequired,
    deductionTypeShort: PropTypes.string.isRequired,
  };


  render = () => {
    const {
      bifurcationData,
      bifurcationTitle,
      orderData,
      property,
      expandData,
      deductionType,
      deductionTypeShort,
      deductionKey
    } = this.props;

    let otherDeductionGlobal = 0, taxSumGlobal = 0;

    return (
      <>
        <div className="table-responsive">
          <table className={'table table-bordered'}>
            <thead>
              <tr>
                <th>{bifurcationTitle}</th>
                <th>Count</th>
                <th>
                  Items Total
                  <br/><small>(A)</small>
                </th>
                <th>
                  {deductionTypeShort}
                  <br/><small>(B)</small>
                </th>
                <th>
                  Deduction
                  <br/><small>(C)</small>
                </th>
                <th>
                  Tax
                  <br/><small>(D)</small>
                </th>
                <th>
                  Total
                  <br/><small>(A - B - C + D)</small>
                </th>
                <th>Paid</th>
                <th>Pending</th>
              </tr>
            </thead>
            <tbody>
              {bifurcationData.map((data, i) => {
                const otherDeduction = (
                  (orderData.orderDiscountPostPayment[data.value] || 0) +
                (orderData.orderDiscount[data.value] || 0) +
                (orderData[deductionKey][data.value] || 0)
                );
                otherDeductionGlobal += otherDeduction;
                const taxSum = (
                  (orderData.orderTax[data.value] || 0) +
                (orderData.orderTaxTotal[data.value] || 0) +
                (orderData.orderServiceCharge[data.value] || 0)
                );
                taxSumGlobal += taxSum;
                return (
                  <tr className={'tr-align-middle'} key={i}>
                    <td className={'data-table-cell-lg'}>
                      {expandData && (
                        <FontAwesomeIcon
                          onClick={() => expandData(data)}
                          icon={faExternalLinkSquare}
                          className={'mr-2 a-class'}
                        />
                      )}
                      {data.label}
                    </td>
                    <td className={'data-table-cell-xs text-center'}>
                      {orderData.orderCount[data.value] || 0}
                    </td>
                    <td className={'data-table-cell-md text-center'}>
                      {(orderData.orderSubTotalBilled[data.value] || 0).toFixed(2)}
                    </td>
                    <td className={'data-table-cell-md text-center lime-bg'}>
                      {(orderData[deductionKey][data.value] || 0).toFixed(2)}
                    </td>
                    <td className={'data-table-cell-md text-center'}>
                      {(otherDeduction).toFixed(2)}
                    </td>
                    <td className={'data-table-cell-md text-center'}>
                      {(taxSum).toFixed(2)}
                    </td>
                    <td className={'data-table-cell-md text-center'}>
                      {(orderData.orderTotal[data.value] || 0).toFixed(2)}
                    </td>
                    <td className={'data-table-cell-md text-center'}>
                      {(orderData.orderPaidPayment[data.value] || 0).toFixed(2)}
                    </td>
                    <td className={'data-table-cell-md text-center'}>
                      {((orderData.orderTotal[data.value] || 0) -
                      (orderData.orderPaidPayment[data.value] || 0)).toFixed(2)}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td><strong>Total</strong></td>
                <td className={'data-table-cell-xs text-center'}>
                  <strong>{AppUtil.sumArray(Object.values(orderData.orderCount))}</strong>
                </td>
                <td className={'data-table-cell-md text-center'}>
                  <strong>{AppUtil.sumArray(Object.values(orderData.orderSubTotalBilled), 'float')}</strong>
                </td>
                <td className={'data-table-cell-md text-center lime-bg'}>
                  <strong>{AppUtil.sumArray(Object.values(orderData[deductionKey]), 'float')}</strong>
                </td>
                <td className={'data-table-cell-md text-center'}>
                  <strong>{otherDeductionGlobal.toFixed(2)}</strong>
                </td>
                <td className={'data-table-cell-md text-center'}>
                  <strong>{taxSumGlobal.toFixed(2)}</strong>
                </td>
                <td className={'data-table-cell-md text-center'}>
                  <strong>{AppUtil.sumArray(Object.values(orderData.orderTotal), 'float')}</strong>
                </td>
                <td className={'data-table-cell-md text-center green-cl'}>
                  <strong>{AppUtil.sumArray(Object.values(orderData.orderPaidPayment), 'float')}</strong>
                </td>
                <td className={'data-table-cell-md text-center red-cl'}>
                  <strong>{(
                    AppUtil.sumArray(Object.values(orderData.orderTotal), 'float') -
                  AppUtil.sumArray(Object.values(orderData.orderPaidPayment), 'float')
                  ).toFixed(2)}</strong>
                </td>
              </tr>
            </tbody>
          </table>
          <p className={'mb-2 text-muted small'}>All values except count in {property.currency}</p>

          <p className={'mb-1 text-muted'}>Items Total : Net total of items inclusive of all item level deductions.</p>
          <p className={'mb-1 text-muted'}>Deduction : Includes sum of any other order level deduction in form of
            deductions like discounts etc excluding {deductionType.toLowerCase()}.</p>
          <p className={'mb-1 text-muted'}>Tax : Includes sum of tax on order level and item level.</p>
        </div>
      </>
    );
  };
}

/* Export Component ==================================================================== */
export default PABifurcationTable;

