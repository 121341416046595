import React from 'react';
import {NavLink, Redirect, Route, Switch} from 'react-router-dom';

import AccountSettings from '../account';
import NotFound from '../NotFound';


const menu = [
  {
    title: 'Account Settings',
    link: '/user/account',
    isExact: false,
  },
  // {
  //   title: 'Billing',
  //   link: '/user/billing',
  //   isExact: false,
  // },
];

const menuItems = [];

menu.map((item) => {
  const {title, link, isExact} = item;
  return menuItems.push(
    <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
      <NavLink
        to={{
          pathname: link,
          state: {fromDashboard: true},
        }}
        exact={isExact}
        className="nav-link"
        activeClassName="selected">
        {title}
      </NavLink>
    </li>,
  );
});

const UserSettings = () => (
  <div>
    <div className="container">
      <div className="my-3">
        <h1>Account Details</h1>
        <p>User account settings.</p>
      </div>
      <ul className="nav nav-tabs mb-3">
        {menuItems}
      </ul>
      <Switch>
        <Route exact path={'/user'} render={() => (<Redirect to={'/user/account'}/>)}/>
        <Route path="/user/account" component={AccountSettings}/>
        <Route exact component={NotFound}/>
      </Switch>
    </div>
  </div>
);

export default UserSettings;