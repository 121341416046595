/**
 * Manage Order
 *  manages order updates ( Order Over Ride)
 */
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import React, {Component} from 'react';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import {Constants, ErrorMessages} from '../../../constants';
// Components
import {Formik} from 'formik';
import {Form} from 'react-formik-ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faTimes,} from '@fortawesome/free-solid-svg-icons';

import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import OrderItemEdit from '../../order/components/OrderItemEdit';

/* Component ==================================================================== */

class POSOrderItemAdd extends Component {
  static componentName = 'POSOrderItemAdd';

  static propTypes = {
    match: PropTypes.object,
    property: PropTypes.object,
    order: PropTypes.object,
    orderItem: PropTypes.object,
    posDetails: PropTypes.object,
    updateResponse: PropTypes.func,
    onHide: PropTypes.func.isRequired,
    type: PropTypes.string,
  };


  static defaultProps = {
    type: 'order'
  };

  addItem = credentials => {
    const {posDetails, order} = this.props;
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.posapi.post(`pos-order-item/?order=${order.hash}&pos=${posDetails.hash}`, {
          order: this.props.order.id,
          price: credentials.price,
          quantity: credentials.quantity,
          quantity_type: credentials.quantity_type,
          tax_classes: this.state.selectedTaxClasses,
          custom_description: credentials.description,
          additional_description: credentials.additional_description,
        })
          .then(() => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              this.props.updateResponse(order.hash, posDetails.hash);
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                this.props.onHide();
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  advanceEdit = (advanceEdit) => {
    let tax_class = [];
    const {orderItem} = this.props;
    if (this.state.tax_class.length < 1) {
      this.setState({loading: true}, () => {
        AppAPI.propertyapi
          .get('tax-classes-list/')
          .then(res => {
            res.forEach(data => {
              tax_class[data.id.toString()] = data;
            });
            this.setState({
              loading: false,
              tax_class: tax_class,
              advanceEdit: advanceEdit,
              selectedTaxClasses: orderItem && orderItem.tax_data ? orderItem.tax_data.tax_classes : [],
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({
              loading: false,
              error: error,
            });
          });
      });
    } else {
      this.setState({
        advanceEdit: advanceEdit,
        selectedTaxClasses: orderItem && orderItem.tax_data ? orderItem.tax_data.tax_classes : this.state.selectedTaxClasses,
      });
    }
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
        selectedTaxClasses: [],
      });
    }
    return true;
  }


  selectTax = tax => {
    let {selectedTaxClasses} = this.state;
    selectedTaxClasses = AppUtil.insertOrRemoveArray(selectedTaxClasses, tax);
    this.setState({selectedTaxClasses});
  };

  render = () => {

    const {loading, error, resultMsg, tax_class, selectedTaxClasses} = this.state;
    const {order, orderItem, property, show} = this.props;

    if (!loading && error) return <Error full={true} text={ErrorMessages.initData}/>;

    if (!show) return <React.Fragment/>;

    let validationSchema = Yup.object().shape({
      description: Yup.string().min(2, 'Too Short!').required(),
      quantity: Yup.number().required(),
      sub_quantity: Yup.number().required(),
      price: Yup.number().required(),
    });

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Item
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            price: 0,
            quantity: 1,
            sub_quantity: 1,
            description: '',
            quantity_type: Constants.ORDER_QUANTITY_TYPE_EXPENSE.NOS,
            additional_description: '',
          }}
          validationSchema={validationSchema}
          onSubmit={values => this.addItem(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                {loading ?
                  <Loading heightMatch={false}/>
                  :
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                      <Form className={'form-group'}>
                        <OrderItemEdit
                          order={order}
                          orderItem={orderItem}
                          formikProps={formikProps}
                          selectTax={this.selectTax}
                          advanceEdit={this.advanceEdit}
                          property={property}
                          title={'Expense'}
                          subQuantity={false}
                          selectedTaxClasses={selectedTaxClasses}
                          tax_class={tax_class}
                        />
                      </Form>
                    </div>
                  </div>
                }
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <button
                      type="submit" onClick={formikProps.handleSubmit}
                      className={'btn btn-success btn-lg btn-block'}
                    >
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      title: 'order',
      editViewType: 'discount',
      complimentaryType: 0,
      advanceEdit: false,
      initialValues: {
        quantity: 1,
        sub_quantity: 1,
        price: 0,
        discount: 0,
      },
      selectedTaxClasses: [],
      tax_class: [],
    };
  }
}

/* Export Component ==================================================================== */
export default POSOrderItemAdd;
