/**
 *
 * Booking Details
 */
import {Link, NavLink, Route, Switch} from 'react-router-dom';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import _ from 'lodash';
// Consts and Libs
// Components
import Error from '../../../components/general/Error';
import {faBed} from '@fortawesome/free-solid-svg-icons';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PageHeader from '../../../components/dashboard/PageHeader';
import NavigationTabs from '../../../components/dashboard/NavigationTabs';

import BookingRoomOverView from './BookingRoomOverView';
import BookingOrderDetails from '../../booking/bookingDetails/BookingOrderDetails';
import BookingAmendmentDetails from '../../booking/bookingDetails/BookingAmendmentDetails';
// Actions
import * as BookingActions from '../../../redux/booking/actions';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  booking: state.booking.booking,
  bookingRoom: state.bookingroom.bookingroom,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setBooking: BookingActions.setBooking,
};

/* Component ==================================================================== */
class BookingDetails extends Component {
  static componentName = 'BookingDetails';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
  };

  fetchInitData = () => {
  };

  render = () => {

    const {loading, error} = this.state;
    const {booking, bookingRoom} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    let baseUrl = `/booking/${this.props.match.params.bookingId}/booking-room`;
    let absBaseUrl = `${baseUrl}/${this.props.match.params.bookingRoomId}`;
    const menu = [
      {
        title: 'Booking Room Details',
        link: `${absBaseUrl}/`,
        isExact: true,
      },
      {
        title: 'Room Order(s)',
        link: `${absBaseUrl}/booking-order/`,
      },
      {
        title: 'Room Amendment(s)',
        link: `${absBaseUrl}/booking-amendment/`,
      },
    ];

    let menuItems = [];
    menu.map((item) => {
      const {title, link, isExact} = item;
      return menuItems.push(
        <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
          <NavLink
            to={{
              pathname: link,
              state: {fromDashboard: true},
            }}
            exact={isExact}
            className="nav-link rounded-0"
            activeClassName="selected">
            {title}
          </NavLink>
        </li>,
      );
    });

    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Booking Room Details</title>
        </Helmet>

        <Link to={`/booking/${booking.hash}/`} className={'btn btn-outline-dark mr-2 btn-sm mt-2'}>
          <FontAwesomeIcon icon={faBed} size={'sm'}/> {'Booking Details'}
        </Link>

        <PageHeader
          border={false}
          history={this.props.history} title={'Booking Room details'}
          description={`Reference number : ${_.isEmpty(bookingRoom) ? '...' : bookingRoom.ref_no}`}
        />

        <NavigationTabs navItems={menu} classNameContainer={'mb-4'}/>

        <Switch>
          <Route
            path={absBaseUrl} exact
            render={() => <BookingRoomOverView {...this.props}/>}
          />
          <Route
            path={`${absBaseUrl}/booking-order/`} exact
            render={() => <BookingOrderDetails {...this.props}/>}
          />
          <Route
            path={`${absBaseUrl}/booking-amendment/`} exact
            render={() => <BookingAmendmentDetails {...this.props}/>}
          />
        </Switch>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      showEditMenu: false,
      modType: null,
      showGuestManagement: false,
      showNotesManager: false,
      showProfileDetails: false,
      profileDetailsData: null,
      showMessagingManager: false,
      addProfile: false,
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(BookingDetails);
