import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
// Consts and Libs
import {AppConfig} from '../../constants';
// Components
import PropertyUpdate from '../ui/model/PropertyUpdate';
import Loading from '../general/Loading';
// Actions
import * as PropertyActions from '../../redux/property/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  user: state.user,
  property: state.property.property
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetProperty: PropertyActions.isSetProperty,
  unSetProperty: PropertyActions.unSetProperty,
};

export default function (ComposedComponent) {
  class PropertyRequired extends Component {
    static contextTypes = {
      router: PropTypes.object
    };

    componentDidMount () {
      window.addEventListener('storage', this.propertyManager);

      if (_.isEmpty(this.props.property)) {
        this.props.isSetProperty()
          .then((propertyData)=>{
            Sentry.setContext('property', {
              Name: propertyData.name,
              Reference: propertyData.ref_no,
            });
          })
          .catch(() => {
            this.props.unSetProperty();
            this.props.history.push('/property');
          });
      }
    }

    componentWillUnmount() {
      window.removeEventListener('storage', this.propertyManager);
    }

    propertyManager = (e) => {
      if (e.key === AppConfig.localStorageKeys.PROPERTY_ID) {
        this.setState({showPropertyRefresh: true});
      }
    }

    render () {
      const {showPropertyRefresh} = this.state;
      if (_.isEmpty(this.props.property)) return <Loading/>;
      return <React.Fragment>
        <PropertyUpdate
          show={showPropertyRefresh}
          currentUrl={this.props.match.url}
          onHide={()=>this.setState({showPropertyRefresh: false})}
        />
        {showPropertyRefresh ? <Loading/> : <ComposedComponent {...this.props} />}
      </React.Fragment>;
    }

    constructor(props) {
      super(props);

      this.state = {
        showPropertyRefresh: false,
      };
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(PropertyRequired);
}