/**
 * Availability details
 *  availability details
 */
import { faDoorOpen, faHourglass, faMinus, faPen, faToolbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AppUtil from '../../../lib/util';

/* Component ==================================================================== */

class AvailabilityDetails extends Component {
  static componentName = 'AvailabilityDetails';

  static propTypes = {
    roomTypeData: PropTypes.object,
    availabilityDateEdit: PropTypes.func,
    availabilityDetails: PropTypes.object,
  };



  render = () => {
    const {availabilityDetails, roomTypeData} = this.props;
    const roomType = !_.isEmpty(roomTypeData) ? roomTypeData[availabilityDetails.room_type_id] : {};

    const availability_percent = AppUtil.calculatePercentage(availabilityDetails.available, roomType.total_room);

    return (
      <React.Fragment>
        <div className={'row'}>
          <div className={'col-md-12 col-lg-6'}>
            {!_.isEmpty(roomType) &&
            (
              <>
                <table className={'table table-bordered'}>
                  <thead>
                    <tr>
                      <th colSpan={2}>Inventory Information - {roomType.name}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className={'w-50 bg-light'}>
                        <FontAwesomeIcon icon={faDoorOpen} size={'sm'} className={'green-cl mr-2'} />
                        <strong>Total Room(s)</strong></td>
                      <td className={'text-center data-table-cell-md'}>
                        <strong>{roomType.total_room}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td className={'w-50 bg-light'}>
                        <FontAwesomeIcon icon={faHourglass} size={'sm'} className={'yellow-cl mr-2'} />
                        Buffer Room(s)
                        <FontAwesomeIcon className={'float-right red-cl'} icon={faMinus} />
                      </td>
                      <td className={'text-center yellow-cl'}>
                        <strong>{roomType.buffer_room}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td className={'w-50 bg-light'}>
                        <FontAwesomeIcon icon={faToolbox} size={'sm'} className={'red-cl mr-2'} />
                        Maintenance Room(s)
                        <FontAwesomeIcon className={'float-right red-cl'} icon={faMinus} />
                      </td>
                      <td className={'text-center red-cl'}>
                        <strong>{roomType.maintenance_room}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td className={'w-50 bg-light'}><strong>Operational Room(s)</strong></td>
                      <td className={'text-center'}>
                        <strong>{roomType.net_available_room}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}

            <table className={'table table-bordered'}>
              <thead>
                <tr>
                  <th colSpan={2}>Other Information</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={'w-50 bg-light'}>Cancelled Booking(s)</td>
                  <td className={'text-center'}>{availabilityDetails.cancelled}</td>
                </tr>
                <tr>
                  <td className={'w-50 bg-light'}>No Show Booking(S)</td>
                  <td className={'text-center'}>{availabilityDetails.no_show}</td>
                </tr>
                <tr>
                  <td className={'w-50 bg-light'}>Occupied/Completed Booking(S)</td>
                  <td className={'text-center'}>{availabilityDetails.complete}</td>
                </tr>
              </tbody>
            </table>

          </div>
          <div className={'col-md-12 col-lg-6'}>
            <table className={'table  table-bordered'}>
              <thead>
                <tr className={'bg-dark text-light'}>
                  <th colSpan={2}>Occupancy / Booking</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={'w-50 bg-light'}><strong>Operational Room(s)</strong></td>
                  <td className={'text-center'}>
                    <strong>{roomType.net_available_room}</strong>
                  </td>
                </tr>
                <tr>
                  <td className={'w-50 bg-light'}>
                    Blocked Room(s)
                    <button
                      onClick={() => this.props.availabilityDateEdit('inventory_block')}
                      className="btn btn-sm btn-link p-0 mx-1">
                      <FontAwesomeIcon icon={faPen} size={'sm'}/>
                    </button>
                    <FontAwesomeIcon className={'float-right red-cl'} icon={faMinus} />
                  </td>
                  <td className={'text-center'}>
                    {availabilityDetails.all_channel_block || 0}
                  </td>
                </tr>
                <tr>
                  <td className={'w-50 bg-light'}>
                    Confirmed Booking(s)
                    <FontAwesomeIcon className={'float-right red-cl'} icon={faMinus} />
                  </td>
                  <td className={'text-center'}>{availabilityDetails.booked}</td>
                </tr>
                <tr>
                  <td className={'w-50 bg-light'}>
                    Provisional Booking(s)
                    <FontAwesomeIcon className={'float-right red-cl'} icon={faMinus} />
                  </td>
                  <td className={'text-center'}>{availabilityDetails.blocked}</td>
                </tr>
                <tr>
                  <td className={'w-50 bg-light'}>
                    <strong>Available Room(s)</strong>
                  </td>
                  <td className={'text-center'}><strong>{availabilityDetails.available}</strong></td>
                </tr>
                <tr>
                  <td className={'w-50 text-center'}>
                    <strong>Availability %</strong>
                  </td>
                  <td className={`text-center ${AppUtil.availabilityStatusColor(availability_percent)}`}>
                    <strong>{`${availability_percent}%`}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
            <p className={'text-muted small mb-0'}>Only blocked room(s) can be changed on date basic.</p>
          </div>
        </div>
      </React.Fragment>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: true,
      availabilityChartData: [],
    };
  }
}

/* Export Component ==================================================================== */
export default AvailabilityDetails;
