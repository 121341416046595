/**
 *
 * POS Order KOT Details
 * point of sales KOT information
 */

import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

// Consts and Libs
import AppUtil from '../../lib/util';
import AppAPI from '../../lib/api';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle, faStickyNote,
  faUnlock,
  faUserClock,
} from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../../components/dashboard/PageHeader';
import {confirmAlert} from '../../components/general/ConfirmAlert';
import {Constants, Strings} from '../../constants';
import {Alerts} from '../../components/ui';
import NotesManager from '../notes/NotesManager';
import NotesListView from '../notes/NotesListView';

// Actions
import * as POSActions from '../../redux/pos/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  posDetails: state.pos.posDetails,
  posOrderKOT: state.pos.posOrderKOT,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPOSDetails: POSActions.setPOSDetails,
  setPOSOrderKOT: POSActions.setPOSOrderKOT,
};

/* Component ==================================================================== */
class POSOrderKOTDetails extends Component {
  static componentName = 'POSOrderKOTDetails';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    if (this.props.match.params.POSOrderKOTId) {
      this.props.setPOSOrderKOT(this.props.match.params.POSOrderKOTId, this.props.match.params.POSId)
        .then(() => {
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  }

  cacheReset = () => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.posapi
        .post(`pos-order-kot/${this.props.posOrderKOT.hash}/cache-reset/`, {})
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            this.props.setPOSOrderKOT(res);
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            resultMsg: {error},
          });
        });
    });
  };

  updatePOSTableEscalatedPermission = (payload) => {
    const {posDetails, posOrderKOT} = this.props;
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.posapi.patch(`pos-order-kot-escalated/${posOrderKOT.hash}/?pos=${posDetails.hash}`, payload)
          .then(res => {
            this.props.setPOSOrderKOT(res);
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };


  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {posDetails, posOrderKOT} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="pos-table-details screen-container ">
        <Helmet>
          <title>Point of Sales | KOT</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history} title={`KOT #${posOrderKOT.kot_id}`}
          description={`Reference Number : ${posOrderKOT.ref_no}`}
        />

        <div className={'row'}>
          <div className={'col-lg-9 col-md-9 col-sm-12'}>
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
            <div className={'row'}>
              <div className={'col-12 col-md-6'}>
                <table className={'table table-sm table-borderless'}>
                  <tbody>
                    <tr>
                      <td className={'w-25 text-muted'}>Reference</td>
                      <td>{posOrderKOT.ref_no}</td>
                    </tr>
                    <tr>
                      <td className={'w-25 text-muted'}>KOT #</td>
                      <td>{posOrderKOT.kot_id}</td>
                    </tr>
                    <tr>
                      <td className={'w-25 text-muted'}>Created</td>
                      <td>{AppUtil.formatDateTime(posOrderKOT.created)}</td>
                    </tr>
                    <tr>
                      <td className={'w-25 text-muted'}>Order</td>
                      <td>
                        <Link
                          to={`/point-of-sale/${posDetails.hash}/pos-order/${posOrderKOT.attributes.order_hash}`}>{posOrderKOT.attributes.order_ref_no}</Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={'col-12 col-md-6'}>
                <table className={'table table-sm table-borderless'}>
                  <tbody>
                    <tr>
                      <td className={'w-25 text-muted'}>Status</td>
                      <td>
                        <span className={`badge ${AppUtil.posOrderKotStatusColor(posOrderKOT.kot_status)}`}>
                          {posOrderKOT.kot_status_display}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className={'w-25 text-muted'}>User</td>
                      <td>{(posOrderKOT.attributes && posOrderKOT.attributes.created_user)? posOrderKOT.attributes.created_user : '...'}</td>
                    </tr>
                    {posOrderKOT.kot_status !== Constants.POS_KOT_STATUS.ORIGINAL &&
                    <tr>
                      <td className={'w-25 text-muted'}>Modified</td>
                      <td>{AppUtil.formatDateTime(posOrderKOT.time)}</td>
                    </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>

            <hr/>
            <div className={'row'}>
              <div className={'col-12 col-md-12'}>
                <table className={'table border format-table'}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.isEmpty(posOrderKOT.kot_items) ?
                      <tr>
                        <td colSpan={3}>
                          <div className="text-center">
                            <FontAwesomeIcon
                              icon={faExclamationTriangle} size={'2x'}
                              className={'mt-3 red-cl'}
                            />
                            <p className={'text-secondary small'}>No item in this KOT</p>
                          </div>
                        </td>
                      </tr>
                      :
                      <React.Fragment>
                        {posOrderKOT.kot_items.map((itemData, k) => (
                          <tr
                            key={k}
                            className={`${!itemData.status && 'table-dark'}`}
                          >
                            <td className={'w-50'}>
                              <strong>{itemData.description}</strong>
                              <p className={'text-muted small mb-1'}>
                                {itemData.additional_description}
                              </p>
                              {itemData.item_edited &&
                              <p className={'text-muted small mb-1'}>
                                Modified at {AppUtil.formatDateTime(itemData.time)}
                              </p>
                              }
                            </td>
                            <td>
                              <FontAwesomeIcon
                                icon={itemData.item_edited ? faInfoCircle : faCheckCircle}
                                className={`mr-2 ${itemData.item_edited ? 'red-cl' : 'green-cl'}`}
                              />
                              {itemData.item_edited ? 'Modified' : 'Original'}
                            </td>
                            <td>
                              {itemData.quantity} {itemData.quantity_type_display}
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    }
                  </tbody>
                </table>
              </div>
            </div>

            {!_.isEmpty(posOrderKOT.notes) &&
            <div>
              <div className={'spacer-20'}/>
              <div className={'row'}>
                <div className={'col-lg-6 col-md-12'}>
                  <h6>Notes</h6>
                  <div className="list-group">
                    {posOrderKOT.notes.map((data, key) => (
                      <NotesListView
                        data={data}
                        key={key}
                        successAction={() => this.fetchInitData()}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            }

            {/*<object width="100%" height="400" data={posOrderKOT.resources_link} type="application/pdf">*/}
            {/*  <p>Alternative {posOrderKOT.resources_link}</p>*/}
            {/*</object>*/}
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group mb-5">
              <li className="list-group-item  align-items-center">
                <button onClick={() => this.setState({showNotesManager: true})} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faStickyNote} size={'sm'} className={'mr-2'}/> Add Note
                </button>
              </li>
            </ul>
            <ul className="list-group">
              <li className="list-group-item  align-items-center" onClick={() => this.cacheReset()}>
                <small>KOT Actions</small>
              </li>
              <li className="list-group-item">
                <Link to={`/point-of-sale/${posDetails.hash}/pos-order-kot/${posOrderKOT.hash}/history`}>
                  <FontAwesomeIcon icon={faUserClock} size={'sm'} className={'mr-2'}/> KOT Change Logs
                </Link>
                <p className={'small text-muted mb-0'}>Previous actions / updates on the KOT.</p>
              </li>
              <li className="list-group-item">
                <button
                  className="btn btn-link orange-cl m-0 p-0"
                  disabled={posOrderKOT.lock}
                  onClick={() =>
                    confirmAlert({
                      title: 'Confirm KOT',
                      message: Strings.cancelKOT,
                      buttons: [
                        {
                          className: 'btn-danger',
                          label: 'Cancel KOT',
                          onClick: () => this.updatePOSTableEscalatedPermission({kot_status: Constants.POS_KOT_STATUS.CANCELLED}),
                        },
                        {
                          className: 'btn btn-secondary',
                          label: 'Exit',
                        },
                      ],
                    })}
                >
                  <FontAwesomeIcon icon={faUnlock} size={'sm'} className={'mr-2'}/> Cancel/Void KOT
                </button>
                <p className={'small text-muted mb-0'}>Requires Manager Permission.</p>
              </li>
            </ul>
          </div>
        </div>

        {/* Notes */}
        <NotesManager
          onHide={() => this.setState({showNotesManager: false})}
          show={this.state.showNotesManager}
          successAction={() => this.fetchInitData()}
          POSOrderKOT={posOrderKOT}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      showNotesManager: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(POSOrderKOTDetails);