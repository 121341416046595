/**
 * Order Items Bulk Edit
 *  manages order items edit ( item price )
 */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
// Consts and Libs
import AppAPI from '../../../lib/api';
// Components
import {Alerts} from '../../../components/ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle, faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
// Forms
import {Form, Formik} from 'formik';
import {Input} from 'react-formik-ui';
import AppUtil from '../../../lib/util';


/* Component ==================================================================== */

class OrderItemsBulkEdit extends React.Component {
  static componentName = 'OrderItemsBulkEdit';

  static propTypes = {
    match: PropTypes.object,
    order: PropTypes.object,
    property: PropTypes.object,
    updateResponse: PropTypes.func,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        loading: false,
        init: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  updateOrder = (values = null) => {
    const payload = [];
    Object.keys(values).forEach((key)=>{
      let object_id = key.replace('item_', '');
      object_id = object_id.replace('_price', '');
      payload.push({
        'id': object_id,
        'price': values[key]
      });
    });

    if (!_.isEmpty(payload)) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.orderapi.patch(`order-update/${this.props.order.hash}/`, {
          'order_items': payload
        })
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                this.props.updateResponse(res);
                this.props.onHide();
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }

  };

  calculateItemTotal = (orderItem, price = 0) => {
    if (orderItem.complimentary || orderItem.no_charge) {
      return 0;
    }

    const tax = this.calculateItemTax(orderItem, price);
    const quantity = parseInt(orderItem.sub_quantity) * parseInt(orderItem.quantity);
    if (price){
      const total = (parseFloat(price) * quantity) - parseFloat(orderItem.discount) + parseFloat(tax);
      return total.toFixed(2);
    }
    return 0;
  }

  calculateItemTax = (orderItem, price=0) => {
    let discount = 0;

    if (orderItem.complimentary || orderItem.no_charge) {
      return 0;
    }

    if (orderItem.discount_before_tax) {
      discount = orderItem.discount;
    }
    const quantity = parseInt(orderItem.sub_quantity) * parseInt(orderItem.quantity);
    if (price){
      return AppUtil.calculateTax(orderItem.tax_data, parseFloat(price), quantity, discount);
    }
    return 0;
  }

  reCalculateItemTax = (orderItem, price) => {
    const {orderItemData} = this.state;
    orderItemData[`item_${orderItem.id}_price`] = price;
    orderItemData[`item_${orderItem.id}_tax`] = this.calculateItemTax(orderItem, price);
    orderItemData[`item_${orderItem.id}_total`] = this.calculateItemTotal(orderItem, price);
    return orderItemData[`item_${orderItem.id}_tax`];
  }

  prefillData = () =>{
    const {order} = this.props;
    const initialValues = {}, orderItemData = {};

    if (!_.isEmpty(order.order_items) ) {
      order.order_items.forEach(data => {
        orderItemData[`item_${data.id}`] = data;
        orderItemData[`item_${data.id}_tax`] = this.calculateItemTax(data, data.price);
        orderItemData[`item_${data.id}_total`] = this.calculateItemTotal(data, data.price);
        orderItemData[`item_${data.id}_qty`] = parseInt(data.sub_quantity) * parseInt(data.quantity);
        orderItemData[`item_${data.id}_price`] = data.price;
        orderItemData[`item_${data.id}_discount`] = data.discount;
        initialValues[`item_${data.id}_price`] = data.price;
      });
    }
    this.setState({
      init: false,
      initialValues,
      orderItemData
    });
  }

  printTotal = () =>{
    const {orderItemData} = this.state;
    const {order} = this.props;
    let total = parseFloat('0'), subTotal = parseFloat('0'), taxTotal = parseFloat('0');
    let discountTotal = parseFloat('0');

    if (!_.isEmpty(order.order_items)) {
      order.order_items.forEach((data)=>{
        if (!data.complimentary && !data.no_charge) {
          total += parseFloat(orderItemData[`item_${data.id}_total`]);
          discountTotal += parseFloat(orderItemData[`item_${data.id}_discount`]);
          subTotal += (parseFloat(orderItemData[`item_${data.id}_price`]) * orderItemData[`item_${data.id}_qty`]);
          taxTotal += parseFloat(orderItemData[`item_${data.id}_tax`]);
        }
      });

      if (parseFloat(order.order_discount) > 0) {
        total -= parseFloat(order.order_discount);
      }
    }

    return (
      <table className="table border">
        <tbody>
          <tr>
            <td className={'text-right'}><strong>Sub Total</strong></td>
            <td className={'w-25 text-right'}>{`${order.currency} ${subTotal.toFixed(2)}`}</td>
          </tr>
          {(discountTotal > 0 ) &&
            <tr>
              <td className={'text-right'}><strong>Discount</strong></td>
              <td className={'w-25 text-right'}>- {`${order.currency} ${discountTotal.toFixed(2)}`}</td>
            </tr>
          }
          <tr>
            <td className={'text-right'}><strong>Tax Total</strong></td>
            <td className={'w-25 text-right'}>{`${order.currency} ${taxTotal.toFixed(2)}`}</td>
          </tr>
          {(parseFloat(order.order_discount) > 0) &&
            <tr>
              <td className={'text-right'}><strong>Order Discount</strong></td>
              <td className={'w-25 text-right'}>- {`${order.currency} ${order.order_discount}`}</td>
            </tr>
          }
          <tr>
            <td className={'text-right'}>
              <span className={'small text-muted'}>(Rounded) </span>
              <strong>Total</strong>
            </td>
            <td className={'w-25 text-right'}>{`${order.currency} ${Math.round(total).toFixed(2)}`}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  render = () => {

    const {resultMsg, init, initialValues, orderItemData} = this.state;
    const {order, show} = this.props;

    if (init && show) {
      this.prefillData();
    }

    if (!show || init) return <React.Fragment/>;

    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Order Edit
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          onSubmit={values => this.updateOrder(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <Form className={'form-group'}>
                      <table className="table border">
                        <tbody>
                          {!_.isEmpty(order.order_items) ?
                            <React.Fragment>
                              {order.order_items.map((data, i) => {
                                return (
                                  <tr key={i}>
                                    <td className={'w-50 align-middle'}>
                                      <strong>{data.description}</strong>
                                      {data.additional_description &&
                                      <p className={'small mb-0 text-muted'}>{data.additional_description}</p>
                                      }
                                      {!_.isEmpty(data.tax_data) &&
                                      <p className={'mb-0 small text-muted'}>
                                        Tax : {data.tax_data.description}
                                      </p>
                                      }
                                      <p className={'mb-0 small'}>
                                        {parseFloat(data.discount) > 0 && `Discount: ${order.currency} ${data.discount} (${data.discount_percent}%)`}
                                      </p>
                                    </td>
                                    <td>
                                      <div className="form-row">
                                        <div className="col form-group">
                                          <Input
                                            required
                                            label={'Rate'}
                                            type={'number'}
                                            name={`item_${data.id}_price`}
                                            className={'form-control'}
                                            onChange={(value) => {
                                              this.reCalculateItemTax(orderItemData[`item_${data.id}`], value.target.value);
                                              formikProps.handleChange(value);
                                            }}
                                          />
                                        </div>
                                        <div className="col form-group">
                                          <p className={''}>Tax</p>
                                          <p><strong>{orderItemData[`item_${data.id}_tax`]}</strong></p>
                                        </div>
                                        <div className="col form-group">
                                          <p className={''}>Quantity</p>
                                          <p className={'small mb-0'}><strong>
                                            {`${data.quantity} ${(parseInt(data.sub_quantity) > 1 || parseInt(data.sub_quantity_type) !== 1) ? ` x ${data.sub_quantity}` : ''}`}
                                          </strong></p>
                                          <p className={'mb-0 small text-muted'}>
                                            {`${data.quantity_type_display}  ${(parseInt(data.sub_quantity) > 1 || parseInt(data.sub_quantity_type) !== 1) ? ` x ${data.sub_quantity_type_display}` : ''}`}
                                          </p>
                                        </div>
                                        <div className="col form-group text-right">
                                          <p className={''}>Total</p>
                                          <p className={'mb-0'}><strong>{orderItemData[`item_${data.id}_total`]}</strong></p>
                                          {data.complimentary &&
                                            <span className={'badge badge-info'}>
                                              {data.complimentary_type_display}
                                            </span>
                                          }
                                          {data.no_charge &&
                                            <span className={'badge badge-info'}>{data.no_charge_type_display}</span>
                                          }
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                              }
                            </React.Fragment>
                            :
                            <tr>
                              <td colSpan={2}>
                                <div className="text-center">
                                  <FontAwesomeIcon icon={faExclamationTriangle} size={'2x'} className={'mt-3 red-cl'}/>
                                  <p className={'text-secondary small'}>No items in this order</p>
                                </div>
                              </td>
                            </tr>
                          }
                        </tbody>
                      </table>
                      {this.printTotal()}
                    </Form>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <button
                      type="submit" onClick={formikProps.handleSubmit} className={'btn btn-success btn-lg btn-block'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      initialValues: {},
      orderItemTotal: {},
      orderItemData: {},
      loading: false,
      init: true,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default OrderItemsBulkEdit;
