/**
 *
 * Property Booking Engine Settings
 */
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import { Img } from 'react-image';
// Consts and Libs
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {faPen} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropertyBookingEngineCoverImageManage from './PropertyBookingEngineCoverImageManage';
// Actions
import * as PropertyActions from '../../../redux/property/actions';
import PageHeader from '../../../components/dashboard/PageHeader';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  propertyContent: state.property.content,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetPropertyContent: PropertyActions.isSetPropertyContent,
};

/* Component ==================================================================== */
class PropertyBookingEngine extends Component {
  static componentName = 'PropertyBookingEngineSettings';

  componentDidMount = () => {
  };

  render = () => {
    const {loading, error} = this.state;
    const {propertyContent, property} = this.props;

    if (!this.props.property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="channel-manager-settings screen-container">
        <Helmet>
          <title>Booking Engine Settings</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={'Content Settings'}
          description={'Booking engine contents like home page, header links, footer links.'}
        />

        <div className={'row'}>
          <div className="col-lg-10 col-md-12 col-sm-12 mb-5">
            <div className={'row'}>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div
                  className={'border p-2 text-center anchor w-100'} data-toggle="collapse"
                  role="button" aria-expanded="false" aria-controls="orderActions">
                  <h6 className={'mb-0'}>
                    Header Links
                  </h6>
                </div>
                <div className={'border p-2'} id={'primaryAction'}>
                  {propertyContent.bengine_header_links && propertyContent.bengine_header_links.length ?
                    <ul className="list-group list-group-horizontal-sm">
                      {propertyContent.bengine_header_links.map((data, i) => (
                        <li className="list-group-item text-center" key={i}>
                          {data.title}
                        </li>
                      ))}
                    </ul>
                    :
                    <div>
                      <p className={'text-muted'}>No header links.</p>
                    </div>
                  }
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <button
                  onClick={() => this.setState({showPropertyBookingEngineCoverImageManage: true})}
                  className="btn btn-link mb-2 float-right"
                >
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit Cover
                </button>
                <div
                  className={'border p-2 text-center anchor w-100'} data-toggle="collapse"
                  role="button" aria-expanded="false" aria-controls="orderActions">
                  <h6 className={'mb-0'}>
                    Content
                  </h6>
                </div>
                <div className={'border'} id={'primaryAction'}>
                  <div
                    className={'home-header'}
                    style={propertyContent.bengine_cover_image && propertyContent.bengine_cover_image.cover && {
                      backgroundImage: `url(${propertyContent.bengine_cover_image.cover})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                    }}
                  >
                    <table className={'w-100 h-100  header-inner'}>
                      <tbody>
                        <tr>
                          <td className="align-middle">
                            <ul className="list-unstyled my-3 text-light">
                              <li className="text-center mt-3">
                                <h1 className={propertyContent.bengine_title ? '' : 'text-muted'}>
                                  {propertyContent.bengine_title || 'Enter Title'}
                                </h1>
                              </li>
                              <li className="text-center mb-5">
                                <h3 className={propertyContent.bengine_sub_title ? '' : 'text-muted'}>
                                  {propertyContent.bengine_sub_title || 'Enter Sub Title'}
                                </h3>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className={'border p-2 text-center anchor w-100'} data-toggle="collapse"
                  role="button" aria-expanded="false" aria-controls="orderActions">
                  <h6 className={'mb-0'}>
                    Content
                  </h6>
                </div>
                <div className={'border p-2'} id={'primaryAction'}>
                  <ul className={'list-unstyled mb-2'}>
                    <li className="my-3 p-2">
                      <h3 className={propertyContent.bengine_description_title ? '' : 'text-muted'}>
                        {propertyContent.bengine_description_title || 'Enter Description Title'}
                      </h3>
                      {propertyContent.bengine_description ?
                        <p className={'display-linebreak'}>{propertyContent.bengine_description}</p>
                        :
                        <div>
                          <p className={'text-muted'}>Enter a brief description about the property.</p>
                        </div>
                      }
                    </li>
                  </ul>
                </div>
                <Link
                  to={'property-slider-images'}
                  className="btn btn-link mb-2 float-right"
                >
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit Slider Images
                </Link>
                <div
                  className={'border p-2 text-center anchor w-100'} data-toggle="collapse"
                  role="button" aria-expanded="false" aria-controls="orderActions">
                  <h6 className={'mb-0'}>
                    Slider
                  </h6>
                </div>
                <div className={'border p-2'} id={'primaryAction'}>
                  <div className={'row'}>
                    <div className={'col-6'}>
                      {(propertyContent.slider_image && propertyContent.slider_image.length > 0) ?
                        <div>
                          <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                            <ol className="carousel-indicators">
                              {propertyContent.slider_image.map((data, i) => (
                                <li
                                  data-target="#carouselExampleIndicators"
                                  key={i}
                                  data-slide-to={i}
                                  className={i === 0 ? 'active' : ''}
                                />
                              ))}
                            </ol>
                            <div className="carousel-inner">
                              {propertyContent.slider_image.map((data, i) => (
                                <div className={`carousel-item ${i === 0 && 'active'}`} key={i}>
                                  <Img
                                    className="d-block w-100"
                                    src={data.image.slider}
                                    loader={<Loading heightMatch={false}/>}
                                  />
                                </div>
                              ))}
                            </div>
                            <a
                              className="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                              data-slide="prev">
                              <span className="carousel-control-prev-icon" aria-hidden="true"/>
                              <span className="sr-only">Previous</span>
                            </a>
                            <a
                              className="carousel-control-next" href="#carouselExampleIndicators" role="button"
                              data-slide="next">
                              <span className="carousel-control-next-icon" aria-hidden="true"/>
                              <span className="sr-only">Next</span>
                            </a>
                          </div>
                        </div>
                        : <table className={'w-100 h-100 bg-dark'}>
                          <tbody>
                            <tr>
                              <td className="align-middle">
                                <h1 className={'text-white text-center align-middle'}>Slider</h1>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      }
                    </div>
                    <div className={'col-6'}>
                      <h3 className={propertyContent.bengine_slider_title ? '' : 'text-muted'}>
                        {propertyContent.bengine_slider_title || 'Enter Slider Title'}
                      </h3>
                      {propertyContent.bengine_slider_description ?
                        <p>{propertyContent.bengine_slider_description}</p>
                        :
                        <div>
                          <p className={'text-muted'}>
                            Enter a brief description here for slider. If left empty slider will tax full width of the
                            page.
                          </p>
                          <p className={'text-muted'}>
                            This is a placeholder text to demonstrate contents in this area.
                          </p>
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div
                  className={'border p-2 text-center anchor w-100'} data-toggle="collapse"
                  role="button" aria-expanded="false" aria-controls="orderActions">
                  <h6 className={'mb-0'}>
                    Footer Links
                  </h6>
                </div>
                <div className={'border p-2'} id={'primaryAction'}>
                  {propertyContent.bengine_footer_links && propertyContent.bengine_footer_links.length ?
                    <ul className="list-group list-group-horizontal-sm">
                      {propertyContent.bengine_footer_links.map((data, i) => (
                        <li className="list-group-item text-center" key={i}>
                          {data.title}
                        </li>
                      ))}
                    </ul>
                    :
                    <div>
                      <p className={'text-muted'}>No footer links.</p>
                    </div>
                  }
                </div>

                <div
                  className={'border p-2 text-center anchor w-100'} data-toggle="collapse"
                  role="button" aria-expanded="false" aria-controls="orderActions">
                  <h6 className={'mb-0'}>
                    Social Media Links
                  </h6>
                </div>
                <div className={'border p-2'} id={'primaryAction'}>
                  {propertyContent.bengine_social_links && propertyContent.bengine_social_links.length ?
                    <ul className="list-group list-group-horizontal-sm">
                      {propertyContent.bengine_social_links.map((data, i) => (
                        <li className="list-group-item text-center" key={i}>
                          {data.title}
                        </li>
                      ))}
                    </ul>
                    :
                    <div>
                      <p className={'text-muted'}>No Social Media links.</p>
                    </div>
                  }
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div
                  className={'border p-2 text-center anchor w-100 mt-5'} data-toggle="collapse"
                  role="button" aria-expanded="false" aria-controls="orderActions">
                  <h6 className={'mb-0'}>
                    Short Description / SEO description
                  </h6>
                </div>
                <div className={'border p-2'} id={'primaryAction'}>
                  <ul className="list-unstyled">
                    <li className="text-center my-3">
                      {propertyContent.bengine_short_description ?
                        <p className={'display-linebreak'}>{propertyContent.bengine_short_description}</p>
                        :
                        <div>
                          <p className={'text-muted'}>Enter a short description about the property.</p>
                        </div>
                      }
                    </li>
                  </ul>
                </div>

              </div>

            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-12 mb-5">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link to={'manage'} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <PropertyBookingEngineCoverImageManage
          show={this.state.showPropertyBookingEngineCoverImageManage}
          onHide={() => this.setState({showPropertyBookingEngineCoverImageManage: false})}
          onSave={(res) => {
            this.setState({loading: true}, () => {
              this.props.isSetPropertyContent(res);
              this.setState({loading: false});
            });
          }}
          propertyContent={propertyContent}
          property={property}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      showPropertyBookingEngineCoverImageManage: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyBookingEngine);
