import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Route, Switch} from 'react-router-dom';
// Components
import ExpenseList from './ExpenseList';
import PageHeader from '../../components/dashboard/PageHeader';

class ExpenseListIndex extends Component {
  static propTypes = {
    match: PropTypes.object,
    hideHeader: PropTypes.bool,
    baseUrl: PropTypes.string,
    vendorId: PropTypes.number,
  };


  static defaultProps = {
    baseUrl: '',
  };

  render = () => {
    const {baseUrl, hideHeader, vendorId} = this.props;

    return (
      <div>
        {!hideHeader &&
         <PageHeader
           history={this.props.history} title={'Expense\'s'}
           description={'All expenses recorded in property. Choose category to filter expense.'}
         />
        }

        <div className={'row'}>
          <div className={'col-lg-12 col-md-12 col-sm-12'}>
            <Switch>
              <Route
                path={`${baseUrl}/expense/list/`} exact
                render={(props) => <ExpenseList vendorId={vendorId} {...props}/>}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}


export default ExpenseListIndex;