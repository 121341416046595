/**
 * Room Service Request ItemDetails
 */
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import React, {Component} from 'react';

// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants} from '../../../constants';

// Components
import {Formik} from 'formik';
import {Form, Select, SubmitBtn, Textarea} from 'react-formik-ui';

import {Alerts} from '../../../components/ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave, faTimes} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */

class RoomServiceRequestItemDetails extends Component {
  static componentName = 'RoomServiceRequestItemDetails';

  static propTypes = {
    roomServiceRequestItem: PropTypes.object.isRequired,
    roomServiceRequest: PropTypes.object.isRequired,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
        loading: true,
      });
      this.fetchInitData();
    }
    return true;
  }


  fetchInitData = () => {
    this.setState({loading: false});
  };

  update = (data) => {
    const {roomServiceRequest, roomServiceRequestItem} = this.props;
    if (data) {
      let payload = {
        request_status: data.request_status,
        response_description: data.response_description,
      };
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.roomapi.patch(`room-service-request-item/${roomServiceRequestItem.id}/?service_request=${roomServiceRequest.hash}`, payload)
          .then(() => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                if (this.props.updateResponse) {
                  this.props.updateResponse(roomServiceRequest.hash);
                }
                this.props.onHide();
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }

  }


  render = () => {

    const {resultMsg} = this.state;
    const {show, roomServiceRequestItem, roomServiceRequest} = this.props;

    if (!show) {
      return <React.Fragment/>;
    }

    const initialValue = {
      request_status: '',
      response_description: roomServiceRequestItem.response_description,
    };

    const defaultFormValidation = Yup.object().shape({
      request_status: Yup.number().required(),
    });
    return (
      <Modal
        {...this.props}
        size={'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Request Details
          </Modal.Title>
        </Modal.Header>
        <Formik
          onSubmit={(values) => (this.update(values))}
          validationSchema={defaultFormValidation}
          initialValues={initialValue}>
          {() => (
            <Form>
              <React.Fragment>
                <Modal.Body>

                  <table className={'table table border'}>
                    <tbody>
                      <tr>
                        <td className={'border-top-0'}>
                          <strong>{roomServiceRequestItem.description}</strong>
                          {roomServiceRequestItem.additional_description &&
                          <p className={'small text-muted mb-1'}>{roomServiceRequestItem.additional_description}</p>
                          }
                        </td>
                      </tr>
                      {roomServiceRequestItem.service_type !== Constants.ROOM_SERVICE_TYPE.AMENITY &&
                      <tr>
                        <td>Quantity
                          : <strong> {roomServiceRequestItem.quantity} </strong> {roomServiceRequestItem.quantity_type_display}
                        </td>
                      </tr>
                      }
                      {(roomServiceRequestItem.lock && roomServiceRequest.lock) &&
                      <tr>
                        <td>
                          <p className={'small text-muted mb-0'}>
                            Notes : {roomServiceRequestItem.response_description || '...'}
                          </p>
                        </td>
                      </tr>
                      }
                    </tbody>
                  </table>
                  {!(roomServiceRequestItem.lock && roomServiceRequest.lock) &&
                  <React.Fragment>
                    <hr/>
                    <div className="form-row">
                      <div className="col-12">
                        <Select
                          name='request_status'
                          label={'Status'}
                          placeholder='Select an Option'
                          className={'form-control'}
                          options={Constants.ROOM_SERVICE_REQUEST_STATUS_INPUT}
                        />
                      </div>
                      <div className="col-12">
                        <Textarea
                          name='response_description'
                          label={'Note'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                  }
                </Modal.Body>
                <Modal.Footer className={'d-block'}>
                  <div className={'px-3'}>
                    <Alerts
                      status={resultMsg.status}
                      success={resultMsg.success}
                      error={resultMsg.error}
                    />
                  </div>
                  <div className={'row'}>
                    <div className={'col'}>
                      <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                      </button>
                    </div>
                    {!(roomServiceRequestItem.lock || roomServiceRequest.lock) &&
                    <div className={'col'}>
                      <SubmitBtn className={'btn btn-success btn-lg btn-block'}>
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Update
                      </SubmitBtn>
                    </div>
                    }
                  </div>
                </Modal.Footer>
              </React.Fragment>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: true,
      clear: false,
      results: [],
      roomServiceItems: [],
    };
  }
}

/* Export Component ==================================================================== */
export default RoomServiceRequestItemDetails;
