/**
 *
 * Property Content Pages List
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Img } from 'react-image';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import SearchBar from '../../../components/forms/SearchBar';
import ProfileRecordManage from '../model/ProfileRecordManage';
import ProfileRecordDetails from '../model/ProfileRecordDetails';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faFolder, faPencilAlt, faPlusCircle, faSync} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class ProfileDocumentList extends Component {
  static componentName = 'ProfileDocumentList';

  static propTypes = {
    guest: PropTypes.object,
    reloadData: PropTypes.func,
    editControl: PropTypes.bool,
  };

  static defaultProps = {
    editControl: false,
  };

  fetchData = (page = 1, callback) => {
    const {guest} = this.props;

    AppAPI.profilerecords.get(`?page=${page}&profile=${guest.id}`)
      .then((res) => {
        this.setState({
          loading: false,
          error: null,
          refresh: false,
        });
        if (res.results.length > 0) {
          if (res.next) {
            callback(res.results);
          } else {
            callback(res.results, {
              allLoaded: true, // the end of the list is reached
            });
          }
        } else {
          callback();
        }
      })
      .catch((err) => {
        callback(null, {
          allLoaded: true, // the end of the list is reached
        });
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          refresh: false,
          error,
        });
      });
  };

  renderRow = (data, key) => {
    return (
      <tr key={key}>
        <td className={'data-table-cell-md record-container text-center'}>
          {(!_.isEmpty(data.record_image_signed) && data.record_image_signed.record) ?
            <Img
              className={'border p-1 text-center mx-auto'}
              src={data.record_image_signed.record}
              loader={<Loading heightMatch={false}/>}
            />
            : 'No Record'
          }
        </td>
        <td className={'data-table-cell-sm align-middle'}>
          {data.ref_no}
        </td>
        <td className={'data-table-cell-md align-middle'}>
          {data.record_type_display}
        </td>
        <td className={'align-middle '}><small>{AppUtil.formatDateTime(data.created)}</small></td>
        <td className={'data-table-cell-sm align-middle'}>
          <ul className="list-inline list-item-mb-1">
            <li className={'list-inline-item'}>
              <button
                className={'btn btn-sm  btn-outline-success'}
                onClick={() => this.setState({
                  showProfileRecordsDetails: true,
                  profileRecordsDetails: data,
                })}>
                <FontAwesomeIcon icon={faEye} className={'mr-1'} /> View
              </button>
            </li>
            <li className={'list-inline-item'}>
              <button
                disabled={!this.props.editControl}
                className={'btn btn-sm btn-outline-primary'}
                onClick={() => this.setState({
                  showProfileRecordsManage: true,
                  profileRecordsDetails: data,
                })}>
                <FontAwesomeIcon icon={faPencilAlt} className={'mr-1'} /> Edit
              </button>
            </li>
          </ul>
        </td>
      </tr>
    );
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={5}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faFolder}/> No Records </h4>
            <p>No records added in profile.</p>
          </div>
        </td>
      </tr>
    );
  };

  tableHeader = () =>{
    return (
      <tr>
        <th>Reference</th>
        <th>Preview</th>
        <th>Type</th>
        <th>Date</th>
        <th/>
      </tr>
    );
  };

  render = () => {

    const {loading, error, refresh} = this.state;
    const {guest} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="tax-list screen-container ">
        <Helmet>
          <title>Profile Records</title>
        </Helmet>

        <div className={'row'}>
          <div className={'col-12 col-sm-9'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.setState({refresh: true})}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                </button>
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-3 text-center text-sm-right'}>
            <button
              disabled={!this.props.editControl}
              onClick={() => this.setState({showProfileRecordsManage: true})}
              className="btn btn-outline-primary btn-sm">
              <FontAwesomeIcon icon={faPlusCircle} size={'sm'} className={'mr-2'}/> Add Record
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-12">
            <SearchBar
              refresh={refresh}
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              searchFunction={this.fetchData}
              tableHeader={this.tableHeader}
              colSpan={5}
            />
          </div>
        </div>
        <ProfileRecordDetails
          profile={guest}
          data={this.state.profileRecordsDetails}
          show={this.state.showProfileRecordsDetails}
          onSave={() => this.setState({refresh: true})}
          onHide={() => this.setState({showProfileRecordsDetails: false, profileRecordsDetails: null})}
        />

        <ProfileRecordManage
          profile={guest}
          property={this.props.property}
          reloadData={this.props.reloadData}
          data={this.state.profileRecordsDetails}
          show={this.state.showProfileRecordsManage}
          onSave={() => this.setState({refresh: true})}
          onHide={() => this.setState({showProfileRecordsManage: false, profileRecordsDetails: null})}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: false,
      refresh: false,
      profileSelected: null,
      profileRecordsDetails: null,
      showProfileRecordsManage: false,
      showProfileRecordsDetails: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDocumentList);
