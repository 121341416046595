/**
 *
 * Booking View
 * shows an overview of bookings.
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {Link} from 'react-router-dom';
import Moment from 'moment';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import {Constants} from '../../constants';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBarChart,
  faBed,
  faCalendar,
  faCalendarCheck, faChartGantt,
  faChevronCircleDown, faDoorOpen,
  faList,
  faPlus, faUniversity, faUsers,
} from '@fortawesome/free-solid-svg-icons';
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import BookingRowView from './components/BookingRowView';
import PageHeader from '../../components/dashboard/PageHeader';
import ModelTableView from '../../components/general/ModelTableView';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class FrontDesk extends Component {
  static componentName = 'BookingView';
  componentDidMount = () => {
    this.fetchInitData();
  };


  fetchInitData = (no_cache, loading = true) => {
    this.setState({loading: loading});
    AppAPI.bookingapi.get('status-v2/' + (no_cache ? '?reset_cache=true' : ''))
      .then((res) => {
        let responseData  = {};
        if (!_.isEmpty(res.data)){
          responseData = {
            currency: res.data.currency,
            time: res.data.time,
            response: res.data,
            bookings: res.data.bookings || {},
            bookingData: res.data.booking_data || {},
          };
        }

        // Processing flag indicates
        if (res.processing) {
          setTimeout(()=>this.fetchInitData(false, false), 5000);
        }

        this.setState({
          ...responseData,
          loading: false,
          processing: res.processing || false
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: error,
          resultMsg: {error},
        });
      });
  };


  tableHeader = () => {

  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={5}>
          <div className={'text-center table-list-empty-sm'}>
            <h6><FontAwesomeIcon icon={faBed}/> No Booking (s)</h6>
          </div>
        </td>
      </tr>
    );
  };

  renderRow = (data, key) => {
    const {bookingKey} = this.state;
    return (
      <React.Fragment key={key}>
        <BookingRowView
          data={data}
          roomExpanded={bookingKey === data.id}
          history={this.props.history}
          setRoomExpanded={(bookingKey) => this.setState({bookingKey: bookingKey})}
        />
      </React.Fragment>
    );
  };

  searchList = (listData, searchText) => {
    if (searchText) {
      listData = listData.filter((el) => {
        let searchValue = (el.guest && el.guest.name) ? el.guest.name.toLowerCase() : '',
          searchCode = el.ref_no ? el.ref_no.toLowerCase() : '',
          OTACode = (el.attributes && el.attributes.booking_ota) ? el.attributes.booking_ota.toLowerCase() : '';
        return OTACode.indexOf(searchText.toLowerCase()) !== -1 || searchValue.indexOf(searchText.toLowerCase()) !== -1 || searchCode.indexOf(searchText.toLowerCase()) !== -1;
      });
    }
    return listData;
  }

  countBookingRooms = (bookingList, bookingStatus= null) => {
    if (!_.isEmpty(bookingList)) {
      if (bookingStatus) {
        return bookingList.reduce((a, b)=> a + (
          !_.isEmpty(b.booking_rooms) ? b.booking_rooms.filter(item => (bookingStatus.includes(item.booking_status))).length : 0
        ), 0);
      } else {
        return bookingList.reduce((a, b)=> a + (!_.isEmpty(b.booking_rooms) ? b.booking_rooms.length : 0), 0);
      }
    }
    return '...';
  }

  render = () => {
    const {loading, error, bookings, bookingData, processing} = this.state;
    const {property} = this.props;
    const {last_updated} = this.state.response;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const bookingRoomCounts = {
      checkinBookingRooms : this.countBookingRooms(bookingData.checkin_bookings, [Constants.BOOKING.UPCOMING, Constants.BOOKING.ACTIVE]),
      checkinBookingRoomsPending : this.countBookingRooms(bookingData.checkin_bookings_pending, [Constants.BOOKING.UPCOMING]),
      checkoutBookingRooms : this.countBookingRooms(bookingData.checkout_bookings, [Constants.BOOKING.ACTIVE, Constants.BOOKING.COMPLETED]),
      checkoutBookingRoomsPending : this.countBookingRooms(bookingData.checkout_bookings_pending, [Constants.BOOKING.ACTIVE]),
    };

    bookingRoomCounts.checkinPercent = AppUtil.calculatePercentage(
      (bookingRoomCounts.checkinBookingRooms - bookingRoomCounts.checkinBookingRoomsPending),
      bookingRoomCounts.checkinBookingRooms
    );

    bookingRoomCounts.checkoutPercent = AppUtil.calculatePercentage(
      (bookingRoomCounts.checkoutBookingRooms - bookingRoomCounts.checkoutBookingRoomsPending),
      bookingRoomCounts.checkoutBookingRooms
    );

    return (
      <div className="booking-view screen-container">
        <Helmet>
          <title>Booking Overview</title>
        </Helmet>

        <PageHeader
          history={this.props.history} title={'Front Desk'}
          description={property.name + ' booking(s) status at ' + AppUtil.formatDateTime(Moment(), 'datetime')}/>

        <div className="row">
          <div className="col-lg-8 col-sm-12 col-md-8 mb-5">
            <div>
              <div className={'row mb-3'}>
                <div className={'col-6'}>
                  <div className={'card'}>
                    <div className="card-body">
                      <div className={'row'}>
                        <div className={'col a-class'} onClick={() => this.setState({
                          InstantListView: !_.isEmpty(bookingData) ? bookingData.checkin_bookings : null,
                          showInstantListView: true,
                        })}>
                          <small>Today's Booking</small>
                          <h4 className={'card-title mb-0 pb-0 green-cl'}>
                            {(!_.isEmpty(bookingData) && !processing) ? bookingData.checkin_bookings.length : '...'}
                          </h4>
                        </div>
                        <div className={'col a-class'} onClick={() => this.setState({
                          InstantListView: !_.isEmpty(bookingData) ? bookingData.checkin_bookings_pending : null,
                          showInstantListView: true,
                        })}>
                          <small>Remaining</small>
                          <h4 className={'card-title mb-0 pb-0 blue-cl'}>
                            {(!_.isEmpty(bookingData) && !processing) ? bookingData.checkin_bookings_pending.length : '...'}
                          </h4>
                        </div>
                      </div>
                      <div className="progress mt-3">
                        <div
                          className="progress-bar"
                          style={{width: `${bookingRoomCounts.checkinPercent}%`}}
                          aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"/>
                      </div>
                      <div className={'row'}>
                        <div className={'col-10'}>
                          <p className={'text-muted mb-0 mt-1 small'}>
                            Room(s) : {bookingRoomCounts.checkinBookingRoomsPending > 0
                              ? `${bookingRoomCounts.checkinBookingRoomsPending} remaining out of ${bookingRoomCounts.checkinBookingRooms}.`
                              : 'All clear :).'
                            }
                          </p>
                        </div>
                        <div>
                          <p className={'text-muted mb-0 mt-1 small'}>{bookingRoomCounts.checkinPercent}%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={'col-6'}>
                  <div className={'card'}>
                    <div className="card-body">
                      <div className={'row'}>
                        <div className={'col a-class'} onClick={() => this.setState({
                          InstantListView: !_.isEmpty(bookingData) ? bookingData.checkout_bookings : null,
                          showInstantListView: true,
                        })}>
                          <small>Today's Checkout</small>
                          <h4 className={'card-title mb-0 pb-0 red-cl'}>
                            {(!_.isEmpty(bookingData) && !processing) ? bookingData.checkout_bookings.length : '...'}
                          </h4>
                        </div>
                        <div className={'col a-class'} onClick={() => this.setState({
                          InstantListView: !_.isEmpty(bookingData) ? bookingData.checkout_bookings_pending : null,
                          showInstantListView: true,
                        })}>
                          <small>Remaining</small>
                          <h4 className={'card-title mb-0 pb-0 blue-cl'}>
                            {(!_.isEmpty(bookingData) && !processing) ? bookingData.checkout_bookings_pending.length : '...'}
                          </h4>
                        </div>
                      </div>
                      <div className="progress mt-3">
                        <div
                          className="progress-bar"
                          style={{width: `${bookingRoomCounts.checkoutPercent}%`}}
                          aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"/>
                      </div>
                      <div className={'row'}>
                        <div className={'col-10'}>
                          <p className={'text-muted mb-0 mt-1 small'}>
                            Room(s) : {bookingRoomCounts.checkoutBookingRoomsPending > 0
                              ? `${bookingRoomCounts.checkoutBookingRoomsPending} remaining out of ${bookingRoomCounts.checkoutBookingRooms}.`
                              : 'All clear :).'
                            }
                          </p>
                        </div>
                        <div>
                          <p className={'text-muted mb-0 mt-1 small'}>{bookingRoomCounts.checkoutPercent}%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-lg-6 col-md-12 mb-3'}>
                  <div className={'row'}>
                    <div className={'col-6'}>
                      <div className={'card bg-warning a-class'} onClick={() => this.setState({
                        InstantListView: !_.isEmpty(bookingData) ? bookingData.active_bookings : null,
                        showInstantListView: true,
                      })}>
                        <div className="card-body ">
                          <small>Active Bookings</small>
                          <h4>{(!_.isEmpty(bookingData) && !processing) ? bookingData.active_bookings.length : '...'}</h4>
                        </div>
                      </div>
                      <p className={'text-muted mb-0 mt-1 small text-center'}>{this.countBookingRooms(bookingData.active_bookings, [Constants.BOOKING.ACTIVE])} Room(s)</p>
                    </div>
                    <div className={'col-6'}>
                      <div className={'card text-light bg-danger a-class'} onClick={() => this.setState({
                        InstantListView: !_.isEmpty(bookingData) ? bookingData.over_due_bookings : null,
                        showInstantListView: true,
                      })}>
                        <div className="card-body ">
                          <small>Over Due Bookings</small>
                          <h4>{(!_.isEmpty(bookingData) && !processing) ? bookingData.over_due_bookings.length : '...'}</h4>
                        </div>
                      </div>
                      <p className={'text-muted mb-0 mt-1 small text-center'}>{this.countBookingRooms(bookingData.over_due_bookings, [Constants.BOOKING.ACTIVE])} Room(s)</p>
                    </div>
                  </div>
                </div>
                <div className={'col-lg-6 col-md-12 mb-3'}>
                  <div className={'row'}>
                    <div className={'col-6'}>
                      <div className={'card a-class'} onClick={() => this.setState({
                        InstantListView: !_.isEmpty(bookingData) ? bookingData.other_bookings : null,
                        showInstantListView: true,
                      })}>
                        <div className="card-body ">
                          <small>Other Bookings</small>
                          <h4>{(!_.isEmpty(bookingData) && !processing) ? bookingData.other_bookings.length : '...'}</h4>
                        </div>
                      </div>
                      <p className={'text-muted mb-0 mt-1 small text-center'}>{this.countBookingRooms(bookingData.other_bookings)} Room(s)</p>
                    </div>
                    <div className={'col-6'}>
                      <div className={'card a-class'} onClick={() => this.setState({
                        InstantListView: !_.isEmpty(bookingData) ? bookingData.no_show_cancel_bookings : null,
                        showInstantListView: true,
                      })}>
                        <div className="card-body ">
                          <small>No Show/Cancel </small>
                          <h4>{(!_.isEmpty(bookingData) && !processing) ? bookingData.no_show_cancel_bookings.length : '...'}</h4>
                        </div>
                      </div>
                      <p className={'text-muted mb-0 mt-1 small text-center'}>
                        {this.countBookingRooms(bookingData.no_show_cancel_bookings, [Constants.BOOKING.CANCELED, Constants.BOOKING.NOSHOW])} Room(s)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {last_updated &&
            <p className={'small text-secondary'}>last updated at {AppUtil.formatDateTime(last_updated)}.</p>
            }
            <div>
              <div
                className={'border p-2 text-center anchor mt-3'} data-toggle="collapse" href="#Action"
                role="button" aria-expanded="false" aria-controls="maintinanceActions">
                <h6 className={'mb-0'}>
                  <FontAwesomeIcon
                    onClick={() => this.fetchInitData(true)}
                    icon={faChevronCircleDown} size={'sm'} className={'mx-2 green-cl float-left mt-1'}/>
                  View booking(s) active for today
                  <FontAwesomeIcon
                    icon={faChevronCircleDown} size={'sm'} className={'mx-2 green-cl float-right mt-1'}/>
                </h6>
              </div>
              <div className={'py-2 border collapse p-2 list-max-height'} id={'Action'}>
                <table className={'table border'}>
                  <tbody>
                    {!_.isEmpty(bookings) ?
                      <React.Fragment>
                        {bookings.map((data, i) => (
                          this.renderRow(data, i)
                        ))}
                      </React.Fragment>
                      :
                      this.emptyView()
                    }
                  </tbody>
                </table>
              </div>
            </div>

            <div className="spacer-20"/>
            <div className={'row'}>
              <div className={'col-6'}>
                <Link className="btn btn-success btn-lg btn-block" to="/booking/list">
                  <FontAwesomeIcon icon={faList} size={'sm'} className={'mr-2'}/> Booking List
                </Link>
              </div>
              <div className={'col-6'}>
                <Link className="btn btn-success btn-lg btn-block" to="/booking/lookup">
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Booking Lookup
                </Link>
              </div>
            </div>
            <div className="spacer-20"/>
            <div className={'row'}>
              <div className={'col-6'}>
                <Link className="btn btn-outline-success btn-lg btn-block" to="/booking/booking-room/list">
                  <FontAwesomeIcon icon={faBed} size={'sm'} className={'mr-2'}/> Booking Room List
                </Link>
              </div>
              <div className={'col-6'}>
                <Link className="btn btn-outline-success btn-lg btn-block" to="/booking/room-list">
                  <FontAwesomeIcon icon={faDoorOpen} size={'sm'} className={'mr-2'}/> Room List
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group mb-5">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className="" to="/booking/rate-chart">
                  <FontAwesomeIcon icon={faCalendar} size={'sm'} className={'mr-2'}/> Rate Chart
                </Link>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className="" to="/booking/availability-chart">
                  <FontAwesomeIcon icon={faCalendarCheck} size={'sm'} className={'mr-2'}/> Availability Chart
                </Link>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className="" to="/booking/occupancy-chart">
                  <FontAwesomeIcon icon={faCalendarCheck} size={'sm'} className={'mr-2'}/> Occupancy Chart
                </Link>
              </li>
              {/*<li className="list-group-item d-flex justify-content-between align-items-center">*/}
              {/*  <Link className="" to="/review">*/}
              {/*    <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Reviews*/}
              {/*  </Link>*/}
              {/*</li>*/}
              {/*<li className="list-group-item d-flex justify-content-between align-items-center">*/}
              {/*  <Link className="" to="/booking/booking-room/list">*/}
              {/*    <FontAwesomeIcon icon={faBed} size={'sm'} className={'mr-2'}/> Booking Rooms*/}
              {/*  </Link>*/}
              {/*</li>*/}
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className="" to="/booking/booking-calender-view">
                  <FontAwesomeIcon icon={faCalendar} size={'sm'} className={'mr-2'}/> Calendar view
                </Link>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className="" to="/booking/amendment-request">
                  <FontAwesomeIcon icon={faCalendar} size={'sm'} className={'mr-2'}/> Amendment List
                </Link>
              </li>
            </ul>

            <ul className="list-group mb-5">
              <li className="list-group-item d-flex justify-content-between align-items-center small">Reports / Analytics</li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className="" to="/booking/reports/">
                  <FontAwesomeIcon icon={faUniversity} size={'sm'} className={'mr-2'}/> Report(s)
                </Link>
              </li>
              <li className="list-group-item">
                <Link className="" to="/booking/analytics/">
                  <FontAwesomeIcon icon={faBarChart} size={'sm'} className={'mr-2'}/> Analytics
                </Link>
              </li>
              <li className="list-group-item">
                <Link className="" to="/booking/digest/">
                  <FontAwesomeIcon icon={faChartGantt} size={'sm'} className={'mr-2'}/> Digest
                </Link>
              </li>
            </ul>

            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center small">Reports ( Archived )</li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className="" to="/reports/guest-report">
                  <FontAwesomeIcon icon={faUsers} size={'sm'} className={'mr-2'}/> Guest Report
                </Link>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className="" to="/reports/booking-report">
                  <FontAwesomeIcon icon={faBed} size={'sm'} className={'mr-2'}/> Booking Report
                </Link>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className="" to="/reports/booking-room-report">
                  <FontAwesomeIcon icon={faDoorOpen} size={'sm'} className={'mr-2'}/> Booking Room Report
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <ModelTableView
          size={'lg'}
          tableFormatted={false}
          listData={this.state.InstantListView}
          show={this.state.showInstantListView}
          title={this.state.InstantListViewTitle}
          onHide={() => this.setState({showInstantListView: false})}
          renderRow={this.renderRow}
          emptyView={this.emptyView}
          tableHeader={this.tableHeader}
          searchFunction={this.searchList}
          searchPlaceHolder={'Guest Name, Booking Ref or OTA Name'}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      processing: null,
      error: null,
      response: {},
      bookingKey: null,
      bookings: {},
      bookingData: {},
      showInstantListView: false,
      InstantListView: [],
      InstantListViewTitle: 'Bookings',
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FrontDesk);
