/**
 * Property Reducer
 *
 */

// Set initial state
const initialState = {
  property: {},
  taxclass: {},
  membership: {},
  content: {},
  paymentTerminals: {}
};

export default (state = initialState, action) => {
  let paymentTerminals={};
  switch (action.type) {
  case 'SET_PROPERTY':
    let property = [];
    if (action.data && typeof action.data === 'object') {
      property = action.data;
    }

    return {
      ...state,
      property,
      paymentTerminals,
    };

  case 'SET_PAYMENT_TERMINALS':
    if (action.data && typeof action.data === 'object') {
      paymentTerminals = action.data;
    }

    return {
      ...state,
      paymentTerminals,
    };

  case 'SET_PROPERTY_CONTENT':
    let content = [];
    if (action.data && typeof action.data === 'object') {
      content = action.data;
    }

    return {
      ...state,
      content,
    };

  case 'SET_PROPERTY_TAX':
    let taxclass = [];
    if (action.data && typeof action.data === 'object') {
      taxclass = action.data;
    }

    return {
      ...state,
      taxclass,
    };

  case 'SET_MEMBERSHIP': {
    let membership = [];
    if (action.data && typeof action.data === 'object') {
      membership = action.data;
    }

    return {
      ...state,
      membership,
    };
  }

  default:
    return state;
  }
};
