/**
 *
 * Property Room Inventory Links
 * Houses sections related to property inventory
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {NavLink, Route, Switch} from 'react-router-dom';
// Components
import Loading from '../../../../components/general/Loading';
import Error from '../../../../components/general/Error';
import PageHeader from '../../../../components/dashboard/PageHeader';
// Components
import POSCategoryManage from './../POSCategoryManage';
import POSCategoryDetails from './../POSCategoryDetails';
import POSItemList from './../POSItemList';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pos: state.pos.pos,
  category: state.pos.category,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class POSCategorySettings extends Component {
  static componentName = 'POSCategorySettings';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    if (this.props.match.params.POSCategoryId) {
      this.setState({
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };


  render = () => {

    const {loading, error} = this.state;
    const {pos, category} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    let baseUrl = '/settings/pos';
    let absBaseUrl = `${baseUrl}/${pos.hash}/category/${category.id}`;
    const menu = [
      {
        title: 'Category Details',
        link: `${absBaseUrl}/`,
        isExact: true,
      },
      {
        title: 'Items(s)',
        link: `${absBaseUrl}/items/`,
      },
      // {
      //   title: 'Booking Engine',
      //   link: `${absBaseUrl}/booking-engine/`,
      // },
    ];

    let menuItems = [];
    menu.map((item) => {
      const {title, link, isExact} = item;
      return menuItems.push(
        <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
          <NavLink
            to={{
              pathname: link,
              state: {fromDashboard: true},
            }}
            exact={isExact}
            className="nav-link rounded-0"
            activeClassName="selected">
            {title}
          </NavLink>
        </li>,
      );
    });

    return (
      <div className="room-type-details screen-container ">
        <Helmet>
          <title>POS Category Settings</title>
        </Helmet>


        <PageHeader
          subHeader={true} history={this.props.history} title={'POS Category Details'}
          description={`Settings & Configuration for Category : ${category.name}`}
        />

        <div className={'row'}>
          <div className={'col col-lg-2 col-md-3 col-sm-12 mb-5'}>
            <ul className="nav nav-pills flex-md-column flex-sm-row border rounded mb-3">
              {menuItems}
            </ul>
          </div>
          <div className={'col-lg-10 col-md-9 col-sm-12'}>
            <Switch>
              <Route path={`${baseUrl}/:POSId/category/:POSCategoryId/`} exact component={POSCategoryDetails}/>
              <Route path={`${baseUrl}/:POSId/category/:POSCategoryId/edit`} exact component={POSCategoryManage}/>


              <Route
                exact
                path={`${baseUrl}/:POSId/category/:POSCategoryId/items`}
                render={(props) => (
                  <POSItemList
                    {...props}
                    category={category.id}
                  />
                )}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(POSCategorySettings);
