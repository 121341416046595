/**
 *
 * Property Channel Manager Overview
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Consts and Libs
import AppUtil from '../../../lib/util';
import AppAPI from '../../../lib/api';
// Components
import {Alerts} from '../../../components/ui';
import Strings from '../../../constants/strings';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import BadgeStatus from '../../../components/general/BadgeStatus';
import {confirmAlert} from '../../../components/general/ConfirmAlert';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBusinessTime, faCalendar, faCheckCircle, faClock, faLink, faUnlink} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as PropertyActions from '../../../redux/property/actions';



/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetPropertyDetails: PropertyActions.isSetPropertyDetails,
};

/* Component ==================================================================== */
class PropertyChannelManagerOverview extends Component {
  static componentName = 'PropertyChannelManagerSettings';

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    this.setState({
      loading: false,
      error: null,
    });
  };

  updateProperty = (payload) => {
    if (payload){
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.propertyapi.patch(`property-distribution/${this.props.property.id}/`, payload)
          .then((res) => {
            this.setState({resultMsg: {success: 'Success'}});
            this.props.isSetPropertyDetails(res).then(() => {
              setTimeout(() => {
                this.setState({
                  resultMsg: {success: ''},
                });
              }, 500);
            });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  render = () => {
    const {loading, error, resultMsg} = this.state;
    if (!this.props.property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const {property} = this.props;

    return (
      <div className="channel-manager-settings screen-container">
        <Helmet>
          <title>Channel Manager Settings</title>
        </Helmet>

        <Alerts
          status={resultMsg.status}
          success={resultMsg.success}
          error={resultMsg.error}
        />
        <div className={'row mb-3'}>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <ul className="list-group mb-4">
              <li className="list-group-item d-flex">
                <div>
                  <FontAwesomeIcon
                    icon={property.channel_manager_ok ? faLink : faUnlink}
                    className={`${property.channel_manager_ok ? 'green-cl' : 'red-cl'} mr-2`}
                  />
                </div>
                <div>
                  <strong>Channel Manager Connection :</strong>
                  <BadgeStatus successText={'Connected'} failText={'Not Connected'} status={property.channel_manager_ok}/>
                  <br/>
                  <p className={'sm mb-0 text-secondary small'}>{Strings.channelManagerConnectionSettings}</p>
                </div>
              </li>
              <li className="list-group-item d-flex">
                <div>
                  <FontAwesomeIcon
                    icon={faBusinessTime}
                    className={'green-cl mr-2'}
                  />
                </div>
                <div>
                  <strong>Channel Manager : </strong> {property.channel_manager_provider_display} <br/>
                  <p className={'sm mb-0 text-secondary small'}>{Strings.channelManagerConnectionVendor}</p>
                </div>
              </li>
            </ul>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12">
            <ul className="list-group mb-4">
              <li className="list-group-item">
                <div className="custom-control custom-switch">
                  <input
                    disabled={!property.channel_manager_ok}
                    type="checkbox" className="custom-control-input" id={'allow_channel_manager_distribution'}
                    checked={property.allow_channel_manager_distribution}
                    onClick={() => {
                      confirmAlert({
                        title: (property.allow_channel_manager_distribution ? 'Disable' : ' Enable') + ' Distribution',
                        message: property.allow_channel_manager_distribution ? Strings.channelManagerDistributionDisable : Strings.channelManagerDistributionEnable,
                        buttons: [
                          {
                            className: (property.allow_channel_manager_distribution ? 'btn-danger' : ' btn-success'),
                            label: 'Yes',
                            onClick: () => this.updateProperty({
                              'allow_channel_manager_distribution': !property.allow_channel_manager_distribution,
                            }),
                          },
                          {
                            className: 'btn-secondary',
                            label: 'No',
                          },
                        ],
                      });
                    }}
                  />
                  <label className="custom-control-label" htmlFor={'allow_channel_manager_distribution'}>
                    <strong>Distribution Status : </strong>
                    <BadgeStatus status={property.allow_channel_manager_distribution}/>
                    <br/>
                    <p className={'sm mb-0 text-secondary small'}>{Strings.channelManagerDistribution}</p>
                  </label>
                </div>
              </li>
              <li className="list-group-item">
                <div className="custom-control custom-switch">
                  <input
                    disabled={!property.channel_manager_ok}
                    type="checkbox" className="custom-control-input" id={'channel_manager_rate_tax_inclusive'}
                    checked={property.channel_manager_rate_tax_inclusive}
                    onClick={() => {
                      confirmAlert({
                        title: (property.channel_manager_rate_tax_inclusive ? 'Disable' : ' Enable') + ' Tax inclusion',
                        message: property.channel_manager_rate_tax_inclusive ? Strings.channelManagerTaxInclusionDisable : Strings.channelManagerTaxInclusionEnable,
                        buttons: [
                          {
                            className: (property.channel_manager_rate_tax_inclusive ? 'btn-danger' : ' btn-success'),
                            label: 'Yes',
                            onClick: () => this.updateProperty({
                              'channel_manager_rate_tax_inclusive': !property.channel_manager_rate_tax_inclusive,
                            }),
                          },
                          {
                            className: 'btn-secondary',
                            label: 'No',
                          },
                        ],
                      });
                    }}
                  />
                  <label className="custom-control-label" htmlFor={'channel_manager_rate_tax_inclusive'}>
                    <strong>Tax Inclusion : </strong>
                    <BadgeStatus successText={'Included'} failText={'Not Included'} status={property.channel_manager_rate_tax_inclusive}/>
                    <br/>
                    <p className={'sm mb-0 text-secondary small'}>{Strings.channelManagerTaxInclusion}</p>
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className={'row mb-3'}>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <ul className="list-group mb-4">
              <li className="list-group-item">Connection Details</li>
              <li className="list-group-item d-flex">
                <div>
                  <FontAwesomeIcon
                    icon={property.channel_manager_inventory_push ? faCheckCircle : faCheckCircle}
                    className={`${property.channel_manager_inventory_push ? 'green-cl' : 'grey-cl'} mr-2`}
                  />
                </div>
                <div>
                  <strong>Inventory</strong>
                  <BadgeStatus
                    successText={'Connected'} failText={'Not Connected'} className={'ml-2'}
                    status={property.channel_manager_inventory_push}
                  /><br/>
                  <p className={'sm mb-0 text-secondary small'}>{Strings.channelManagerInventoryConnection}</p>
                </div>
              </li>

              <li className="list-group-item d-flex">
                <div>
                  <FontAwesomeIcon
                    icon={property.channel_manager_rate_push ? faCheckCircle : faCheckCircle}
                    className={`${property.channel_manager_rate_push ? 'green-cl' : 'grey-cl'} mr-2`}
                  />
                </div>
                <div>
                  <strong>Rate</strong>
                  <BadgeStatus
                    successText={'Connected'} failText={'Not Connected'} className={'ml-2'}
                    status={property.channel_manager_rate_push}
                  /><br/>
                  <p className={'sm mb-0 text-secondary small'}>{Strings.channelManagerRateConnection}</p>
                </div>
              </li>

              <li className="list-group-item d-flex">
                <div>
                  <FontAwesomeIcon
                    icon={property.channel_manager_restriction_push ? faCheckCircle : faCheckCircle}
                    className={`${property.channel_manager_restriction_push ? 'green-cl' : 'grey-cl'} mr-2`}
                  />
                </div>
                <div>
                  <strong>Restriction</strong>
                  <BadgeStatus
                    successText={'Connected'} failText={'Not Connected'} className={'ml-2'}
                    status={property.channel_manager_restriction_push}
                  /><br/>
                  <p className={'sm mb-0 text-secondary small'}>{Strings.channelManagerRestrictionConnection}</p>
                </div>
              </li>

              <li className="list-group-item d-flex">
                <div>
                  <FontAwesomeIcon
                    icon={property.channel_manager_accept_booking ? faCheckCircle : faCheckCircle}
                    className={`${property.channel_manager_accept_booking ? 'green-cl' : 'grey-cl'} mr-2`}
                  />
                </div>
                <div>
                  <strong>Booking</strong>
                  <BadgeStatus
                    successText={'Connected'} failText={'Not Connected'} className={'ml-2'}
                    status={property.channel_manager_accept_booking}
                  /><br/>
                  <p className={'sm mb-0 text-secondary small'}>{Strings.channelManagerBookingConnection}</p>
                </div>
              </li>


            </ul>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <ul className="list-group mb-4">
              <li className="list-group-item">Distribution Details</li>
              <li className="list-group-item d-flex">
                <div>
                  <FontAwesomeIcon
                    icon={property.channel_manager_daily_sync ? faCheckCircle : faCheckCircle}
                    className={`${property.channel_manager_daily_sync ? 'green-cl' : 'grey-cl'} mr-2`}
                  />
                </div>
                <div>
                  <strong>Daily Sync</strong>
                  <BadgeStatus className={'ml-2'} status={property.channel_manager_daily_sync}/><br/>
                  <p className={'sm mb-0 text-secondary small'}>{Strings.channelManagerDailySync}</p>
                </div>
              </li>
              <li className="list-group-item d-flex">
                <div>
                  <FontAwesomeIcon
                    icon={faClock}
                    className={`${property.channel_manager_daily_sync ? 'green-cl' : 'grey-cl'} mr-2`}
                  />
                </div>
                <div>
                  <strong>Last Sync</strong> : {property.channel_manager_last_sync ? AppUtil.formatDateTime(property.channel_manager_last_sync) : 'N.A'}<br/>
                  <p className={'sm mb-0 text-secondary small'}>{Strings.channelManagerLastSyncTime}</p>

                </div>
              </li>
              <li className="list-group-item d-flex">
                <div>
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className={'green-cl mr-2'}
                  />
                </div>
                <div>
                  <strong>Distribution Length</strong> : {property.channel_manager_advance_booking_days}<br/>
                  <p className={'sm mb-0 text-secondary small'}>{Strings.propertyBookingLength}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className={'row mb-3'}>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <ul className="list-group mb-4">
              <li className="list-group-item">Booking Incoming</li>
              <li className="list-group-item">
                <div className="custom-control custom-switch">
                  <input
                    disabled={!property.channel_manager_ok}
                    type="checkbox" className="custom-control-input" id={'channel_manager_booking_tax_parse_auto'}
                    checked={property.channel_manager_booking_tax_parse_auto}
                    onClick={() => {
                      confirmAlert({
                        title: (property.channel_manager_booking_tax_parse_auto ? 'Disable' : ' Enable') + ' Auto Tax Parsing',
                        message: property.channel_manager_booking_tax_parse_auto ? Strings.channelManagerAutoTaxParsingDisable : Strings.channelManagerAutoTaxParsingEnable,
                        buttons: [
                          {
                            className: (property.channel_manager_booking_tax_parse_auto ? 'btn-danger' : ' btn-success'),
                            label: 'Yes',
                            onClick: () => this.updateProperty({
                              'channel_manager_booking_tax_parse_auto': !property.channel_manager_booking_tax_parse_auto,
                            }),
                          },
                          {
                            className: 'btn-secondary',
                            label: 'No',
                          },
                        ],
                      });
                    }}
                  />
                  <label className="custom-control-label" htmlFor={'channel_manager_booking_tax_parse_auto'}>
                    <strong>Auto Tax Parsing : </strong>
                    <BadgeStatus status={property.channel_manager_booking_tax_parse_auto}/>
                    <br/>
                    <p className={'sm mb-0 text-secondary small'}>{Strings.channelManagerAutoTaxParsing}</p>
                  </label>
                </div>
              </li>

              <li className="list-group-item">
                <div className="custom-control custom-switch">
                  <input
                    disabled={!property.channel_manager_ok}
                    type="checkbox" className="custom-control-input" id={'channel_manager_order_auto_sequence_number'}
                    checked={property.channel_manager_order_auto_sequence_number}
                    onClick={() => {
                      confirmAlert({
                        title: (property.channel_manager_order_auto_sequence_number ? 'Disable' : ' Enable') + ' Auto Tax Parsing',
                        message: property.channel_manager_order_auto_sequence_number ? Strings.channelManagerAutoSequenceNumberDisable : Strings.channelManagerAutoSequenceNumberEnable,
                        buttons: [
                          {
                            className: (property.channel_manager_order_auto_sequence_number ? 'btn-danger' : ' btn-success'),
                            label: 'Yes',
                            onClick: () => this.updateProperty({
                              'channel_manager_order_auto_sequence_number': !property.channel_manager_order_auto_sequence_number,
                            }),
                          },
                          {
                            className: 'btn-secondary',
                            label: 'No',
                          },
                        ],
                      });
                    }}
                  />
                  <label className="custom-control-label" htmlFor={'channel_manager_order_auto_sequence_number'}>
                    <strong>Auto Sequence : </strong>
                    <BadgeStatus status={property.channel_manager_order_auto_sequence_number}/>
                    <br/>
                    <p className={'sm mb-0 text-secondary small'}>{Strings.channelManagerAutoSequenceNumber}</p>
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      roomTypes: [],
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyChannelManagerOverview);
