/**
 *
 * Booking Guest Count Stats
 */
import _ from 'lodash';
import Moment from 'moment';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import React, { Component } from 'react';
import { Alerts } from '../../../components/ui';
// Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
// Constants
import { Constants, Strings } from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import {
  faDoorOpen,
  faBowlFood, faLongArrowAltRight, faUser, faChild, faBaby,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class BookingGuestCountStats extends Component {
  static componentName = 'BookingGuestCountStats';

  static propTypes = {
    startDate: PropTypes.object
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {startDate} = this.props;
    this.setState({loading: true});
    const date = (startDate ? startDate : Moment()).format('YYYY-MM-DD');
    const queryParams = {
      'date': date,
    };

    AppAPI.roomapi.get('room-type-info/')
      .then((res)=>{
        this.setState({roomTypeResponse: res});
        AppAPI.bookingapi.get(`booking-room-digest/?${queryString.stringify(queryParams)}`)
          .then((res) => {
            this.setState({loading: false, response: res, date:date});
          });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({loading: false, error: error});
      });
  };

  render = () => {
    const {loading, error, response, date, roomTypeResponse, bookingPackageDetails, selectedRoomTypePackage} = this.state;
    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const activeResponse = response;
    const bookingStatusData = [
      {
        title: 'Arrival',
        color: 'green-cl',
        data: (bookings) => bookings.filter(data => (data.checkin === date && ![Constants.BOOKING.CANCELED,Constants.BOOKING.NOSHOW].includes(data.booking_status)))
      },
      {
        title: 'Departure',
        color: 'red-cl',
        data: (bookings) => bookings.filter(data => (data.checkout === date && ![Constants.BOOKING.CANCELED,Constants.BOOKING.NOSHOW].includes(data.booking_status)))
      },
      {
        title: 'Active',
        color: 'yellow-cl',
        data: (bookings) => bookings.filter(data => (data.booking_status === Constants.BOOKING.ACTIVE))
      }
    ];

    const roomTypePackageList = [];

    if (!_.isEmpty(roomTypeResponse.room_types)) {
      roomTypeResponse.room_types.forEach((roomType)=>{
        roomType.packages.forEach((packageData)=>{
          if (!roomTypePackageList.includes(packageData.name)) {
            roomTypePackageList.push(packageData.name);
          }
        });
      });
    }

    return (
      <div className="screen-container">
        <Helmet>
          <title>Booking Guest Count Stats</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={'Booking Guest - Stats [ Booked ] '}
          description={'Count / stats of guest count added to the bookings for selection date.'}
        />

        <div className={'row'}>
          <div className={'col-12 col-lg-12 mb-3'}>
            <div className={'row mb-3'}>
              {bookingStatusData.map((data, i)=>(
                <div className={'col-4 col-md-4 mb-3 align-items-center'} key={i}>
                  <div className={'card'}>
                    <div className="card-body ">
                      <p className={'mb-1 text-muted'}>{data.title}</p>
                      <div className={'row'}>
                        <div className={'col'}>
                          <h5 className={data.color}><FontAwesomeIcon icon={faUser} size={'sm'}/> {data.data(activeResponse).reduce((a, b)=> (a + b.no_of_guest) ,0)}</h5>
                        </div>
                        <div className={'col'}>
                          <h5 className={data.color}><FontAwesomeIcon icon={faChild} size={'sm'}/> {data.data(activeResponse).reduce((a, b)=> (a + b.no_of_children) ,0)}</h5>
                        </div>
                        <div className={'col'}>
                          <h5 className={data.color}><FontAwesomeIcon icon={faBaby} size={'sm'}/> {data.data(activeResponse).reduce((a, b)=> (a + b.no_of_infant) ,0)}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button className={'btn btn-sm btn-link'} disabled={true}>
                  Filter <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-1'}/>
                </button>
              </li>
              <li className="list-inline-item mr-3">
                <button
                  onClick={() => this.setState({ bookingPackageDetails: !bookingPackageDetails })}
                  className={`btn ${bookingPackageDetails ? 'btn-primary' : 'btn-outline-secondary' } btn-sm`}
                >
                  {bookingPackageDetails  ? 'Hide' : 'Show'} Rate Plan(s)
                </button>
              </li>
            </ul>

            {!_.isEmpty(roomTypePackageList) && (
              <ul className="list-inline list-item-mb-1">
                <li className="list-inline-item">
                  <button className={'btn btn-sm btn-link'} disabled={true}>
                    Rate Plan <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-1'}/>
                  </button>
                </li>
                {roomTypePackageList.map((data, i)=>(
                  <li className="list-inline-item mr-3" key={i}>
                    <button
                      disabled={!bookingPackageDetails}
                      onClick={() => this.setState({ selectedRoomTypePackage: AppUtil.insertOrRemoveArray(selectedRoomTypePackage, data) })}
                      className={`btn ${selectedRoomTypePackage.includes(data) ? 'btn-primary' : 'btn-outline-secondary' } btn-sm`}
                    >
                      {data}
                    </button>
                  </li>
                ))}
              </ul>
            )}

            <Alerts status={Strings.bookingGuestStatsCountMessage}/>

          </div>
        </div>

        <div className={'row'}>
          <div className={'col-12 col-lg-12 mb-3'}>
            <div className={'table-responsive'}>
              <table className={'table table border'}>
                <thead>
                  <tr>
                    <th className="data-table-cell-xl">Room Type / Package</th>
                    {bookingStatusData.map((data, i)=>(
                      <th className="data-table-cell-md" key={i}>{data.title}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {!_.isEmpty(roomTypeResponse.room_types) ? (
                    <>
                      {roomTypeResponse.room_types.map((roomTypeData, i)=> {
                        const roomTypeBookings  = activeResponse.filter(data =>  data.room_type_id === roomTypeData.id);
                        return (
                          <React.Fragment key={i}>
                            <tr className={bookingPackageDetails ? 'grey-light-bg' : ''}>
                              <td className="data-table-cell-xl border-right">
                                <FontAwesomeIcon icon={faDoorOpen} className={'mr-2'}/>
                                {roomTypeData.name}
                              </td>
                              {bookingStatusData.map((data, j)=>{
                                return (
                                  <td className={'text-center border-right'} key={j}>
                                    {(data.data(roomTypeBookings).length && !bookingPackageDetails) ? (
                                      <div className={'row mx-2'}>
                                        <div className={'col'}>
                                          <FontAwesomeIcon icon={faUser} size={'sm'}/> {data.data(roomTypeBookings).reduce((a, b)=> (a + b.no_of_guest) ,0)}
                                        </div>
                                        <div className={'col'}>
                                          <FontAwesomeIcon icon={faChild} size={'sm'}/> {data.data(roomTypeBookings).reduce((a, b)=> (a + b.no_of_children) ,0)}
                                        </div>
                                        <div className={'col'}>
                                          <FontAwesomeIcon icon={faBaby} size={'sm'}/> {data.data(roomTypeBookings).reduce((a, b)=> (a + b.no_of_infant) ,0)}
                                        </div>
                                      </div>
                                    )
                                      : (bookingPackageDetails ? '-' : '.')
                                    }
                                  </td>
                                );
                              })}
                            </tr>
                            {bookingPackageDetails && (
                              <React.Fragment>
                                {roomTypeData.packages.filter((data)=> (!_.isEmpty(selectedRoomTypePackage) ? selectedRoomTypePackage.includes(data.name) : true )).map((packageData, j)=>(
                                  <tr key={j}>
                                    <td className={'border-right'}>
                                      <FontAwesomeIcon icon={faBowlFood} className={'mr-2 ml-4'}/>
                                      {packageData.name}
                                    </td>
                                    {bookingStatusData.map((data, j)=>{
                                      const bookingPackageData = roomTypeBookings.filter(data=> AppUtil.searchRoomPackage(data, packageData,  Moment(date)));
                                      const adultCount = data.data(bookingPackageData).reduce((a, b)=> (a + b.no_of_guest) ,0);
                                      const childCount = data.data(bookingPackageData).reduce((a, b)=> (a + b.no_of_children) ,0);
                                      const infantCount = data.data(bookingPackageData).reduce((a, b)=> (a + b.no_of_infant) ,0);
                                      return (
                                        <td className={'text-center'} key={j}>
                                          {data.data(bookingPackageData).length > 0 ? (
                                            <div className={'row mx-2'}>
                                              <div className={'col'}>
                                                <FontAwesomeIcon icon={faUser} size={'sm'}/> {adultCount}
                                              </div>
                                              <div className={'col'}>
                                                <FontAwesomeIcon icon={faChild} size={'sm'}/> {childCount}
                                              </div>
                                              <div className={'col'}>
                                                <FontAwesomeIcon icon={faBaby} size={'sm'}/> {infantCount}
                                              </div>
                                            </div>
                                          ) : '.'}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                ))}
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={10} className={'text-center'}>
                        <p className={'text-center my-4'}>
                            Property Inventory Configuration incomplete.
                        </p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      showPAOverview: false,
      showPAOrderData: {},
      response: [],
      roomTypeResponse: [],
      bookingFilter: null,
      bookingPackageDetails: true,
      selectedRoomTypePackage: [],
      date: Moment().format('YYYY-MM-DD')
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingGuestCountStats);
