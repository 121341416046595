/**
 * Payment Lock Edit
 *  manages payment updates post lock ( Update instrument / type)
 */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants, Strings} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
// Forms
import {Form, Formik} from 'formik';
import {Select} from 'react-formik-ui';
import * as Yup from 'yup';


/* Component ==================================================================== */

class PaymentLockedEdit extends React.Component {
  static componentName = 'PaymentLockedEdit';

  static propTypes = {
    match: PropTypes.object,
    payment: PropTypes.object,
    updateResponse: PropTypes.func,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        loading: false,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  updatePayment = (credentials = null) => {
    let payload = {
      payment_method: credentials.payment_method,
      payment_type: credentials.payment_type,
    };

    if (!_.isEmpty(payload)) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.paymentapi.patch(`payments-locked-edit/${this.props.payment.hash}/`, payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                this.props.updateResponse(res);
                this.props.onHide();
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  render = () => {

    const {resultMsg} = this.state;
    const {payment, show} = this.props;

    if (!show) return <React.Fragment/>;

    const formValidation = Yup.object().shape({
      payment_method: Yup.string().required('Select a valid method'),
      payment_type: Yup.string().required('Select a valid type'),
    });

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Payment
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            payment_method: payment.payment_method,
            payment_type: payment.payment_type,
          }}
          validationSchema={formValidation}
          onSubmit={values => this.updatePayment(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <Form className={'form-group'}>
                      <div className="mt-2">
                        <React.Fragment>
                          <div className="form-row">
                            <div className="col-lg-6 col-md-6 form-group">
                              <Select
                                className={'form-control rounded-right-0'}
                                name="payment_method"
                                label={'Payment Method'}
                                placeholder="Select an Option"
                                options={Constants.PAYMENT_METHODS_OFFLINE_INPUTS}
                                hint={Strings.paymentMethodEdit}
                              />
                            </div>
                            <div className="col-lg-6 col-md-12 form-group">
                              <Select
                                name="payment_type"
                                label={'Payment Type'}
                                className={'form-control'}
                                options={Constants.PAYMENT_TYPE_INPUTS}
                                hint={Strings.paymentTypeText}
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      </div>
                    </Form>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <button
                      type="submit" onClick={formikProps.handleSubmit} className={'btn btn-success btn-lg btn-block'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default PaymentLockedEdit;
