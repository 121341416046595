/**
 * Manage Order
 *  manages order updates ( Order Over Ride)
 */
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import React, {Component} from 'react';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import {Constants, ErrorMessages} from '../../constants';
// Components
import {Formik} from 'formik';
import {Form} from 'react-formik-ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faPen, faPlus, faSave, faTimes,} from '@fortawesome/free-solid-svg-icons';

import {Alerts} from '../../components/ui';
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import OrderItemEdit from './components/OrderItemEdit';
import OrderItemDetails from './components/OrderItemDetails';
import OrderDiscountEdit from './components/OrderDiscountEdit';
import SelectorButton from '../../components/dashboard/SelectorButton';
import OrderComplimentaryEdit from './components/OrderComplimentaryEdit';

/* Component ==================================================================== */

class OrderItemDetailsView extends Component {
  static componentName = 'OrderItemDetailsView';

  static propTypes = {
    match: PropTypes.object,
    property: PropTypes.object,
    order: PropTypes.object,
    orderItem: PropTypes.object,
    updateResponse: PropTypes.func,
    onHide: PropTypes.func.isRequired,
    type: PropTypes.string,
  };


  static defaultProps = {
    type: 'order'
  };

  updateOrderItem = credentials => {
    let payload = {};
    if (!this.state.advanceEdit && this.props.orderItem) {
      if (credentials.discount !== null) {
        let discount = credentials.discount;
        if (credentials.percentage === 'true') {
          if (credentials.discount_before_tax) {
            discount = parseFloat(
              (credentials.discount * this.props.orderItem.sub_total) / 100,
            ).toFixed(2);
          } else {
            discount = parseFloat(
              (credentials.discount * this.props.orderItem.total) / 100,
            ).toFixed(2);
          }
        }
        payload = {
          discount: discount,
          discount_type: credentials.discount_type,
          discount_before_tax: credentials.discount_before_tax,
          discount_description: credentials.discount_description,
        };
      }
    } else {
      payload = {
        custom_description: credentials.description,
        additional_description: credentials.additional_description,
        quantity: credentials.quantity,
        sub_quantity: credentials.sub_quantity,
        quantity_type: credentials.quantity_type,
        sub_quantity_type: credentials.sub_quantity_type,
        price: credentials.price,
        tax_classes: this.state.selectedTaxClasses,
      };
    }

    if (payload) {
      this.setState({form_values: credentials}, () => {
        this.setState({resultMsg: {status: 'One moment...'}});
        const {appApi} = this.state;
        appApi.patch(this.props.orderItem.id + '/?order=' + this.props.order.id, payload)
          .then(res => {
            this.setState(
              {resultMsg: {success: 'Success'}, orderItem: res},
              () => {
                setTimeout(() => {
                  this.props.updateResponse(this.props.order.id)
                    .then(() => {
                      this.setState({advanceEdit: false, resultMsg: {success: ''}});
                      this.props.onHide();
                    });
                }, 500);
              },
            );
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  addItem = credentials => {
    if (credentials) {
      this.setState({form_values: credentials}, () => {
        this.setState({resultMsg: {status: 'One moment...'}});
        const {appApi} = this.state;
        appApi.post('?order=' + this.props.order.id, {
          order: this.props.order.id,
          custom_description: credentials.description,
          additional_description: credentials.additional_description,
          quantity: credentials.quantity,
          sub_quantity: credentials.sub_quantity,
          quantity_type: credentials.quantity_type,
          sub_quantity_type: credentials.sub_quantity_type,
          price: credentials.price,
          tax_classes: this.state.selectedTaxClasses,
        })
          .then(() => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.props.updateResponse(this.props.order.id)
                  .then(() => {
                    this.setState({advanceEdit: false, resultMsg: {success: ''}});
                    this.props.onHide();
                  });
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  advanceEdit = (advanceEdit) => {
    let tax_class = [];
    const {orderItem} = this.props;
    if (this.state.tax_class.length < 1) {
      this.setState({loading: true}, () => {
        AppAPI.propertyapi
          .get('tax-classes-list/')
          .then(res => {
            res.forEach(data => {
              tax_class[data.id.toString()] = data;
            });
            this.setState({
              loading: false,
              tax_class: tax_class,
              advanceEdit: advanceEdit,
              selectedTaxClasses: orderItem && orderItem.tax_data ? orderItem.tax_data.tax_classes : [],
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({
              loading: false,
              error: error,
            });
          });
      });
    } else {
      this.setState({
        advanceEdit: advanceEdit,
        selectedTaxClasses: orderItem && orderItem.tax_data ? orderItem.tax_data.tax_classes : this.state.selectedTaxClasses,
      });
    }
  };

  escalatedPermissionUpdated = (values) => {
    if (values) {

      let payload = {
        complimentary: values.complimentary,
        complimentary_type: values.complimentary_type,
        complimentary_description: values.complimentary_description,
      };

      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        const {appApi} = this.state;
        appApi.post(`${this.props.orderItem.id}/escalated-permission/?order=${this.props.order.id}`, payload)
          .then(() => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.props.updateResponse(this.props.order.id)
                  .then(() => {
                    this.props.onHide();
                  });
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
        advanceEdit: false,
        selectedTaxClasses: [],
        editViewType: 'discount',
      });
    }
    return true;
  }


  selectTax = tax => {
    let {selectedTaxClasses} = this.state;
    selectedTaxClasses = AppUtil.insertOrRemoveArray(selectedTaxClasses, tax);
    this.setState({selectedTaxClasses});
  };

  render = () => {

    const {loading, error, resultMsg, advanceEdit, tax_class, selectedTaxClasses, title, editViewType} = this.state;
    const {order, orderItem, property, show} = this.props;

    if (!loading && error) return <Error full={true} text={ErrorMessages.initData}/>;

    if (!show) return <React.Fragment/>;
    const formValidationRules = {
      discount: Yup.number().required(),
      discount_type: Yup.number().required(),
      discount_description: Yup.string().max(200, 'Too Long!').nullable(),
    };
    const viewButtonList = [
      {
        title: 'Discount',
        key: 'discount',
      }
    ];

    if (this.props.type === 'order') {
      formValidationRules.complimentary_type = Yup.number().required();
      formValidationRules.complimentary_description = Yup.string().max(200, 'Too Long!').nullable();
      viewButtonList.push({
        title: 'Complimentary',
        key: 'complimentary',
      });
    }

    let formInputData;
    let formValidation = Yup.object().shape(formValidationRules);
    switch (editViewType) {
    case 'complimentary':
      formInputData = (formikProps) => <OrderComplimentaryEdit order={order} formikProps={formikProps}/>;
      break;
    case 'discount':
    default:
      formInputData = (formikProps) => <OrderDiscountEdit order={order} formikProps={formikProps} discountPosition={this.props.type === 'order'}/>;
    }

    let validationSchema = Yup.object().shape({
      description: Yup.string().min(2, 'Too Short!').required(),
      quantity: Yup.number().required(),
      sub_quantity: Yup.number().required(),
      price: Yup.number().required(),
    });

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {title} Item Details
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            percentage: 'false',
            discount: orderItem ? orderItem.discount : 0,
            discount_type: orderItem ? orderItem.discount_type : 0,
            discount_description: orderItem ? orderItem.discount_description : '',
            discount_before_tax: orderItem ? orderItem.discount_before_tax : false,

            complimentary: orderItem ? orderItem.complimentary : false,
            complimentary_type: orderItem ? orderItem.complimentary_type : 0,
            complimentary_description: orderItem ? orderItem.complimentary_description : '',

            price: orderItem ? orderItem.price : 0,
            quantity: orderItem ? orderItem.quantity : 1,
            description: orderItem ? orderItem.description : '',
            sub_quantity: orderItem ? orderItem.sub_quantity : 1,
            quantity_type: orderItem ? orderItem.quantity_type : Constants.QUANTITY_TYPE.NOS,
            sub_quantity_type: orderItem ? orderItem.sub_quantity_type : Constants.QUANTITY_TYPE.NOS,
            additional_description: orderItem ? orderItem.additional_description : '',
          }}
          validationSchema={!advanceEdit && orderItem ? formValidation : validationSchema}
          onSubmit={values => orderItem ? (editViewType === 'complimentary' ? this.escalatedPermissionUpdated(values) : this.updateOrderItem(values)) : this.addItem(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                {loading ? <Loading heightMatch={false}/>
                  :
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                      {(orderItem && !order.lock) &&
                      <p className={'mb-2'}>
                        <button
                          type="submit" onClick={() => this.advanceEdit(!advanceEdit)}
                          className={'btn btn-outline-primary btn-sm'}
                        >
                          <FontAwesomeIcon icon={advanceEdit ? faChevronLeft : faPen} size={'sm'} className={'mr-2'}/>
                          {advanceEdit ? 'Back' : 'Advance Edit'}
                        </button>
                      </p>
                      }
                      <Form className={'form-group'}>
                        {!advanceEdit && orderItem ?
                          <React.Fragment>
                            <OrderItemDetails order={order} orderItem={orderItem}/>
                            {!order.lock &&
                            <React.Fragment>
                              <div className={'mb-3'}>
                                <SelectorButton
                                  buttonList={viewButtonList}
                                  selectButtonKey={editViewType}
                                  action={(key) => this.setState({editViewType: key})}
                                />
                              </div>
                              <div className={'border p-2'}>
                                {formInputData(formikProps)}
                              </div>
                            </React.Fragment>
                            }
                          </React.Fragment>
                          :
                          <OrderItemEdit
                            order={order}
                            orderItem={orderItem}
                            formikProps={formikProps}
                            selectTax={this.selectTax}
                            advanceEdit={this.advanceEdit}
                            property={property}
                            title={title}
                            selectedTaxClasses={selectedTaxClasses}
                            tax_class={tax_class}
                          />
                        }
                      </Form>
                    </div>
                  </div>
                }
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  {!order.lock &&
                  <div className={'col'}>
                    <button
                      type="submit" onClick={formikProps.handleSubmit} className={'btn btn-success btn-lg btn-block'}>
                      <FontAwesomeIcon
                        className={'white-cl mr-2'} icon={orderItem ? faSave : faPlus}
                        size={'sm'}/> {orderItem ? 'Save' : 'Add'}
                    </button>
                  </div>
                  }
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  }

  constructor(props) {
    super(props);

    let Api, title;
    switch (this.props.type) {
    case 'expense':
      Api = AppAPI.expenseitem;
      title = 'Expense';
      break;
    case 'order':
      Api = AppAPI.orderitem;
      title = 'Order';
      break;
    default:
      Api = AppAPI.orderitem;
      title = 'Order';
    }

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      appApi: Api,
      title: title,
      editViewType: 'discount',
      complimentaryType: 0,
      advanceEdit: false,
      initialValues: {
        quantity: 1,
        sub_quantity: 1,
        price: 0,
        discount: 0,
      },
      selectedTaxClasses: [],
      tax_class: [],
    };
  }
}

/* Export Component ==================================================================== */
export default OrderItemDetailsView;
