/**
 *
 * Booking Digest
 */

import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'moment';
import queryString from 'query-string';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import FilterDate from '../../../components/dashboard/FilterDate';
import PageHeader from '../../../components/dashboard/PageHeader';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';

// Components
import { Filters } from '../../../constants';

// Consts and Libs
import AppUtil from '../../../lib/util';
import AppWebUtil from '../../../lib/webUtils';

import BookingDigestOW from './BookingDigest';
import BookingRoomDigest from './BookingRoomDigest';
import BookingGuestDigest from './BookingGuestDigest';

import BookingRoomStatus from './BookingRoomStats';
import BookingGuestStats from './BookingGuestStats';
import BookingGuestCountStats from './BookingGuestCountStats';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class BookingDigest extends Component {
  static componentName = 'BookingDigest';

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key, defaultValue = false) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return defaultValue;
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    let startDate = Moment();
    if (this.getQueryParams('start_date')) {
      startDate = Moment(String(this.getQueryParams('start_date')));
    }

    const urlParams = {
      start_date: startDate.format('YYYY-MM-DD'),
    };

    this.setState({
      loading: false,
      startDate: startDate,
      selectionType: 'single',
      URLQueryParams: urlParams
    });
  };

  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    const baseUrl = '/booking/digest';
    const menu = [
      {
        title: 'Booking(s)',
        link: `${baseUrl}/booking-list`,
        component: (props) => <BookingDigestOW {...props} />
      },
      {
        title: 'Room(s)',
        link: `${baseUrl}/booking-room-list`,
        component: (props) => <BookingRoomDigest {...props} />
      },
      {
        title: 'Guest(s)',
        link: `${baseUrl}/booking-guest-list`,
        component: (props) => <BookingGuestDigest {...props} />
      },
    ];

    const menuStats = [
      {
        title: 'Room(s)',
        link: `${baseUrl}/booking-room-status`,
        component: (props) => <BookingRoomStatus {...props} />
      },
      {
        title: 'Guest(s) (Actual)',
        link: `${baseUrl}/booking-guest-status`,
        component: (props) => <BookingGuestStats {...props} />
      },
      {
        title: 'Guest(s) (Booked)',
        link: `${baseUrl}/booking-guest-count-status`,
        component: (props) => <BookingGuestCountStats {...props} />
      },
    ];


    const commonProps = {
      ...this.props,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      dateType: AppUtil.valueFromList(
        Filters.bookingDateType, 'key', this.getQueryParams('date_type'), 'value'
      )
    };


    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Booking Digest</title>
        </Helmet>

        <PageHeader
          border={false}
          title={'Front Desk Digest'}
          history={this.props.history}
          description={
            `Daily arrival, departure, active booking(s) & related information for ${AppUtil.formatDateTime(this.state.startDate, 'datef')}.`
          }
        />

        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-12 col-lg-8'}>
            <ul className="list-inline list-item-mb-1 rounded">
              <li className="list-inline-item">
                <FilterDate
                  title={''}
                  disableSelection={true}
                  selectionType={'single'}
                  startDate={this.state.startDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data))}
                />
              </li>
              <li className="list-inline-item">
                <button
                  className={'btn btn-outline-secondary'}
                  onClick={() => this.setState({loading: true}, () => this.fetchInitData())}
                >
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={''}/>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <hr/>
        <div className={'row'}>
          <div className={'col-lg-2 col-12 mb-5'}>
            <ul className="nav nav-pills flex-md-column flex-sm-row border rounded mb-3">
              <li className="nav-item" role="presentation">
                <span className={'nav-link rounded disabled'}><small>List</small></span>
              </li>
              {menu.map((data, i) => (
                <li className="nav-item" role="presentation" key={i}>
                  <NavLink
                    to={{
                      pathname: data.link,
                      search: queryString.stringify(this.state.URLQueryParams),
                    }}
                    className={`nav-link rounded-0 ${!data.component && 'disabled'}`}
                    activeClassName="selected">
                    {data.title}
                  </NavLink>
                </li>
              ))}
            </ul>

            <ul className="nav nav-pills flex-md-column flex-sm-row border rounded mb-3">
              <li className="nav-item" role="presentation">
                <span className={'nav-link rounded disabled'}><small>Stats</small></span>
              </li>
              {menuStats.map((data, i) => (
                <li className="nav-item" role="presentation" key={i}>
                  <NavLink
                    to={{
                      pathname: data.link,
                      search: queryString.stringify(this.state.URLQueryParams),
                    }}
                    className={`nav-link rounded-0 ${!data.component && 'disabled'}`}
                    activeClassName="selected">
                    {data.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>

          <div className={'col-lg-10 col-12'}>
            <Switch>
              <Route
                exact
                path={baseUrl}
                render={() => (<Redirect to={`${baseUrl}/booking-list`}/>)}
              />
              {[...menu, ...menuStats].map((data, i) => (
                data.component ?
                  <Route
                    key={i}
                    path={data.link} exact
                    render={() => data.component(commonProps)}
                  />
                  :
                  <></>
              ))}
            </Switch>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      endDate: null,
      loading: true,
      URLQueryParams: {},
      selectionType: 'single',
      startDate: Moment().startOf('day'),
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(BookingDigest);
