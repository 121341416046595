/**
 *
 * Property Settings
 */
import React, {Component} from 'react';
import {Formik} from 'formik';
import Helmet from 'react-helmet';
import {connect} from 'react-redux';
import * as Yup from 'yup';
// Consts and Libs
import {SelectList} from '../../constants';
// Components
import {Alerts} from '../../components/ui';
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import PageHeader from '../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {Form, Input, Select, SubmitBtn} from 'react-formik-ui';
// Actions
import * as PropertyActions from '../../redux/property/actions';
import AppAPI from '../../lib/api';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setProperty: PropertyActions.setProperty,
};

/* Component ==================================================================== */
class PropertyAdd extends Component {
  static componentName = 'PropertyAdd';
  componentDidMount = () => {
    this.fetchInitData();
  };


  addProperty = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.property.post('create/',
          {
            'name': credentials.name,
            'address_1': credentials.address_1,
            'address_2': credentials.address_2,
            'state': credentials.state,
            'country': credentials.country,
            'pin_code': credentials.pin_code,

            'currency': credentials.currency,

            'email': credentials.email,
            'phone': credentials.phone ? credentials.code + credentials.phone : null,
          })
          .then((property) => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.setState({
                    resultMsg: '',
                  });
                }, 500);
                this.props.setProperty(property.hash)
                  .then(()=>this.props.history.push('/dashboard'));
              });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  fetchInitData = () => {
    this.setState({
      loading: false,
      initialValues: {
        'name': '',
        'address_1': '',
        'country': '',
        'pin_code': '',
        'email': '',
        'phone': '',
        'currency': '',
      },
    });
  };


  render = () => {
    const {loading, error, initialValues, resultMsg} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;


    const defaultFormValidation = Yup.object().shape({
      name: Yup.string().min(2, 'Too Short!').required('Required'),
      address_1: Yup.string().required('Required'),
      state: Yup.string().required('Required'),
      country: Yup.string().required('Required'),
      pin_code: Yup.number().required('Required'),
      email: Yup.string().email('Invalid email').required('Required'),
      phone: Yup.number().required('Required'),
      currency: Yup.string().required('Required'),
    });


    return (
      <div className="dashboard screen-container">
        <Helmet>
          <title>Settings Overview</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'Add Property'}
        />

        <Formik
          onSubmit={(values) => this.addProperty(values)}
          validationSchema={defaultFormValidation}
          initialValues={initialValues}>
          {() => (
            <Form>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div
                    className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                    role="button" aria-expanded="false" aria-controls="orderActions">
                    <h6 className={'mb-0'}>
                      Property Details
                    </h6>
                  </div>
                  <div className={'border p-2'} id={'primaryAction'}>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Input
                          name='name'
                          label={'Property Name'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Input
                          name='address_1'
                          label={'Address Line 1'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Input
                          name='address_2'
                          label={'Address Line 2'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-lg-4 col-md-4 col-sm-6 form-group">
                        <Input
                          name='state'
                          label={'State'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 form-group">
                        <Select
                          className={'form-control rounded-right-0'}
                          name='country'
                          label={'Country'}
                          placeholder='Select an Option'
                          options={SelectList.countryList()}
                        />
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 form-group">
                        <Input
                          name='pin_code'
                          label={'Pin Code'}
                          className={'form-control'}
                          type={'number'}
                        />
                      </div>
                    </div>
                    <hr/>
                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 form-group">
                        <Input
                          required
                          name='email'
                          type={'email'}
                          label={'Contact E-mail'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 ">
                        <div className={'row'}>
                          <div className={'col-4 pr-0 form-group'}>
                            <Select
                              className={'form-control rounded-right-0'}
                              name='code'
                              label={'Country'}
                              placeholder='Select an Option'
                              options={SelectList.phoneCountryCode()}
                            />
                          </div>
                          <div className={'col-8 pl-0 form-group'}>
                            <Input
                              required
                              type={'tel'}
                              name='phone'
                              label={'Contact Phone'}
                              className={'form-control rounded-left-0'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='currency'
                          label={'Currency'}
                          placeholder='Select an Option'
                          options={SelectList.currencyList()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5 align-self-center ">
                  <p>
                    <strong>Address</strong> information will be shared to guest in the confirmation emails and text
                    messages.
                  </p>
                  <p className={''}>
                    <strong>Property contact</strong> will only be used for internal communications from renzo, never
                    shared to public or your guest.
                  </p>
                  <p>All System level warnings and error if detected will be informed to this contact.</p>
                  <p><strong>Currency</strong> of operation for the property.</p>

                </div>
              </div>
              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 mt-2">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn className={'btn btn-success btn-block btn-lg mt-2'}>
                    <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyAdd);
