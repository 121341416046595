/**
 * Room Reducer
 *  Room
 *
 */

// Set initial state
const initialState = {
  room: {},
  roomHousekeeping: {},
  roomServiceRequest: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
  case 'SET_ROOM':
    let room = [];
    if (action.data && typeof action.data === 'object') {
      room = action.data;
    }

    return {
      ...state,
      room,
    };
  case 'SET_ROOM_HOUSEKEEPING':
    let roomHousekeeping = [];
    if (action.data && typeof action.data === 'object') {
      roomHousekeeping = action.data;
    }

    return {
      ...state,
      roomHousekeeping,
    };

  case 'SET_ROOM_SERVICE_REQUEST':
    let roomServiceRequest = [];
    if (action.data && typeof action.data === 'object') {
      roomServiceRequest = action.data;
    }

    return {
      ...state,
      roomServiceRequest,
    };
  default:
    return state;
  }
};
