/**
 * Model Instant Table View
 */
import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
// Consts and Libs
// Components
import Loading from '../../components/general/Loading';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

/* Component ==================================================================== */

class ModelTableView extends Component {
  static componentName = 'ModelTableView';

  static propTypes = {
    size: PropTypes.string,
    match: PropTypes.object,
    property: PropTypes.object,
    listData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    tableHeader: PropTypes.func,
    renderRow: PropTypes.func.isRequired,
    emptyView: PropTypes.func.isRequired,
    searchFunction: PropTypes.func,
    searchPlaceHolder: PropTypes.string,
  };

  static defaultProps = {
    tableFormatted : true,
    searchPlaceHolder: ''
  }


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        searchText:'',
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  handleInputChange = (value) => {
    this.setState({value: value, searching: true});
    this.onChangeDebounced(value);
  };

  onChangeDebounced = (value) => {
    this.setState({searchText: value, searching: false});
  };


  render = () => {

    const {loading, searching, searchText} = this.state;
    const {show, title, size, tableFormatted, searchPlaceHolder} = this.props;
    let {listData} = this.props;

    if (!show) return <React.Fragment/>;

    if (this.props.searchFunction && searchText) {
      listData = this.props.searchFunction(listData, searchText);
    }

    return (
      <Modal
        {...this.props}
        size={size ? size : 'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? <Loading heightMatch={false}/>
            : <div>
              {this.props.searchFunction &&
              <div className="form-row align-items-center mb-4">
                <div className={searching ? 'col-sm-11' : 'col-sm-12'}>
                  <input
                    type="search"
                    className="form-control"
                    placeholder={searchPlaceHolder}
                    name="q"
                    autoComplete={'off'}
                    onChange={(data) => this.handleInputChange(data.target.value)}
                  />
                </div>
                <div className="col-auto">
                  {searching && <i className="fa fa-spinner"/>}
                </div>
              </div>
              }
              <div className="table-responsive border">
                <table className={`table  ${tableFormatted && 'format-table '}`}>
                  {this.props.tableHeader && <thead>{this.props.tableHeader()}</thead> }
                  <tbody>
                    {!_.isEmpty(listData) && this.props.renderRow ?
                      <React.Fragment>
                        {listData.map((data, i) => (
                          this.props.renderRow(data, i)
                        ))}
                      </React.Fragment>
                      :
                      <React.Fragment>
                        {this.props.emptyView()}
                      </React.Fragment>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          }
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          <div className={'row'}>
            <div className={'col mx-auto'}>
              <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.onChangeDebounced = _.debounce(this.onChangeDebounced, 200, {});

    this.state = {
      loading: false,
      searching: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: true,
    };
  }
}

/* Export Component ==================================================================== */
export default ModelTableView;
