/**
 * App Theme - colors
 *
 * React Native Starter App
 * https://github.com/mcnamee/react-native-starter-app
 */

const colors = {
  transparent: 'transparent',
  white: '#FFFFFF',
  cream: '#EEEFF0',
  green: '#28a745',
  greenLight: '#20c997',
  lime: '#e9fac8',
  red: '#dc3545',
  orange: '#fd7e14',
  yellow: '#ffc107',
  blue: '#007bff',
  purple: '#6610f2',
  black: '#000000',
  purpleDark: '#6f42c1',
  grey: '#868e96',
  greyLight: '#e9ecef',
  greyDark: '#343a40',
  greyMed: '#adb5bd',
};

const app = {
  background: '#e9ecef',
  border: '#cccccc',
};

const brand = {
  brand: {
    primary: colors.blue,
    secondary: colors.purple,
    alert: colors.red,
  },
};

const text = {
  textPrimary: colors.greyDark,
  textSecondary: colors.grey,
  headingPrimary: brand.brand.primary,
  headingSecondary: brand.brand.primary,
};

const borders = {
  border: colors.greyLight,
};

const tabbar = {
  tabbar: {
    background: colors.white,
    iconDefault: '#BABDC2',
    iconSelected: brand.brand.primary,
  },
};

export default {
  ...colors,
  ...app,
  ...brand,
  ...text,
  ...borders,
  ...tabbar
};
