/**
 * Booking Actions
 *
 */

import AppAPI from '../../lib/api';

/**
 * Set Booking
 */
export const setBooking = (bookingData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof bookingData === 'object') {
    dispatch({
      type: 'SET_BOOKING',
      data: bookingData,
    });
    return resolve(bookingData);
  } else if (bookingData) {
    return AppAPI.booking.get(bookingData + '/')
      .then((res) => {
        dispatch({
          type: 'SET_BOOKING',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Booking
 */
export const updateBooking = (bookingData) => setBooking(bookingData);

/**
 * Remove Booking
 */
export function unSetBooking() {
  return (dispatch) => {
    dispatch({
      type: 'SET_BOOKING',
      data: null,
    });
  };
}

/**
 * Perform Lookup
 */
export function setLookup(lookupData) {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOOKUP',
      data: lookupData,
    });
  };
}

/**
 * Remove Booking
 */
export function unSetLookup() {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOOKUP',
      data: null,
    });
  };
}


/**
 * Set selection data
 */
export function setSelectionData (selectionData) {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOOKUP_SELECTION',
      data: selectionData,
    });
  };
}

/**
 * Set Booking Amendment
 */
export const setBookingAmendment = (bookingAmendmentData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof bookingAmendmentData === 'object') {
    dispatch({
      type: 'SET_BOOKING_AMENDMENT',
      data: bookingAmendmentData,
    });
    return resolve(bookingAmendmentData);
  } else if (bookingAmendmentData) {
    return AppAPI.bookingapi.get(`booking-amendment-request/${bookingAmendmentData}/`)
      .then((res) => {
        dispatch({
          type: 'SET_BOOKING_AMENDMENT',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Booking
 */
export const updateBookingAmendment = (bookingData) => setBookingAmendment(bookingData);

/**
 * Remove Booking
 */
export function unSetBookingAmendment() {
  return (dispatch) => {
    dispatch({
      type: 'SET_BOOKING_AMENDMENT',
      data: null,
    });
  };
}