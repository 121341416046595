/**
 * Property Actions
 *
 */
import _ from 'lodash';
import AppAPI from '../../lib/api';
import {AppConfig} from '../../constants';


export const setProperty = (propertyData) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    return AppAPI.account.post('property-sessions/', {hash: propertyData})
      .then((res) => {
        if (!_.isEmpty(res) && res.property) {
          dispatch({ type: 'SET_PROPERTY', data: res.property});
          localStorage.setItem(AppConfig.localStorageKeys.PROPERTY_ID, res.property.id.toString());
          const storageEvent = new StorageEvent('storage', {
            key: AppConfig.localStorageKeys.PROPERTY_ID,
            newProperty: res.property
          });
          window.dispatchEvent(storageEvent);
        }
        return resolve(res);
      })
      .catch((err) => reject(err));
  });

export const isSetProperty = () => {
  return (dispatch) => new Promise(async (resolve, reject) => {
    const selectedPropertyId = localStorage.getItem(AppConfig.localStorageKeys.PROPERTY_ID);
    if (selectedPropertyId) {
      // Verify property is permitted for user
      return AppAPI.property.get(selectedPropertyId + '/')
        .then((res) => {
          // 200 response its permitted
          dispatch({
            type: 'SET_PROPERTY',
            data: res,
          });
          return resolve(res);
        })
        .catch(err => reject(err));
    }

    return reject();

  });
};

export const isSetPropertyDetails = (propertyDetails) => {
  return (dispatch) => new Promise(async (resolve, reject) => {
    if (typeof propertyDetails === 'object') {
      dispatch({
        type: 'SET_PROPERTY',
        data: propertyDetails,
      });
      return resolve(propertyDetails);
    } else {
      const selectedPropertyId = localStorage.getItem(AppConfig.localStorageKeys.PROPERTY_ID);
      if (selectedPropertyId) {
        // Verify property is permitted for user
        return AppAPI.propertydetails.get(selectedPropertyId + '/')
          .then((res) => {
            // 200 response its permitted
            dispatch({
              type: 'SET_PROPERTY',
              data: res,
            });
            // Unset property content if any
            dispatch({
              type: 'SET_PROPERTY_CONTENT',
              data: null,
            });
            return resolve(res);
          })
          .catch(err => reject(err));
      }
    }
    return reject();

  });
};


export const isSetPropertyContent = (propertyContent) => (dispatch) => new Promise(async (resolve, reject) => {
  if (propertyContent) {
    dispatch({
      type: 'SET_PROPERTY_CONTENT',
      data: propertyContent,
    });
    return resolve(propertyContent);
  } else {
    const selectedPropertyId = localStorage.getItem(AppConfig.localStorageKeys.PROPERTY_ID);
    if (selectedPropertyId) {
      // Verify property is permitted for user
      return AppAPI.property.get(`${selectedPropertyId}/content-settings/`)
        .then((res) => {
          // 200 response its permitted
          dispatch({
            type: 'SET_PROPERTY_CONTENT',
            data: res,
          });
          return resolve(res);
        })
        .catch(err => reject(err));
    }

    return reject();
  }

});

/**
 * Remove property
 */
export function unSetProperty() {
  return (dispatch) => new Promise(async () => {
    localStorage.setItem(AppConfig.localStorageKeys.PROPERTY_ID, '');

    return dispatch({
      type: 'SET_PROPERTY',
      data: null,
    });

  });
}

/**
 * Set Property Tax
 */
export const setPropertyTax = (PropertyTaxData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof PropertyTaxData === 'object') {
    dispatch({
      type: 'SET_PROPERTY_TAX',
      data: PropertyTaxData,
    });
    return resolve(PropertyTaxData);
  } else if (PropertyTaxData) {
    // Fetch POS Data
    return AppAPI.propertytaxclass.get(PropertyTaxData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_PROPERTY_TAX',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Property Tax
 */
export const updatePropertyTax = (PropertyTaxData) => setPropertyTax(PropertyTaxData);

/**
 * Remove Property Tax
 */
export function unSetPropertyTax() {
  return (dispatch) => {
    dispatch({
      type: 'SET_PROPERTY_TAX',
      data: null,
    });
  };
}

/**
 * Set Membership
 */
export const setMembership = (membershipData) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    if (typeof membershipData === 'object') {
      dispatch({
        type: 'SET_MEMBERSHIP',
        data: membershipData,
      });
      return resolve(membershipData);
    } else if (membershipData) {
      return AppAPI.membership
        .get(membershipData + '/')
        .then((res) => {
          // 200 response its permitted
          dispatch({
            type: 'SET_MEMBERSHIP',
            data: res,
          });
          return resolve(res);
        })
        .catch((err) => reject(err));
    }

    return reject();
  });

/**
 * Update Membership
 */
export const updateMembership = (membershipData) =>
  setMembership(membershipData);

/**
 * Remove Membership
 */
export function unSetMembership() {
  return (dispatch) => {
    dispatch({
      type: 'SET_MEMBERSHIP',
      data: null,
    });
  };
}


/**
 * Set PaymentTerminals
 */
export const setPaymentTerminals = () => (dispatch) =>
  new Promise(async (resolve, reject) => {
    return AppAPI.propertyapi.get('property/payment-terminal-list/')
      .then((res) => {
        dispatch({
          type: 'SET_PAYMENT_TERMINALS',
          data: res,
        });
        return resolve(res);
      })
      .catch((err) => reject(err));
  });

/**
 * Update Membership
 */
export const updatePaymentTerminals = (membershipData) =>
  setPaymentTerminals(membershipData);

/**
 * Remove Membership
 */
export function unSetPaymentTerminals() {
  return (dispatch) => {
    dispatch({
      type: 'SET_PAYMENT_TERMINALS',
      data: null,
    });
  };
}
