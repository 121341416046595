import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Consts and Libs
import AppAPI from '../../lib/api';
import {AppConfig, Locations} from '../../constants';

// Actions
import * as UserActions from '../../redux/user/actions';
import * as PropertyActions from '../../redux/property/actions';

// Components
import Error from '../general/Error';
import Loading from '../general/Loading';
import AuthLogin from '../ui/model/AuthLogin';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  user: state.user.user,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  logout: UserActions.logout,
  setUser: UserActions.setUser,
  unSetProperty: PropertyActions.unSetProperty,
};

export default function (ComposedComponent) {
  class Authentication extends Component {
    static contextTypes = {
      router: PropTypes.object
    };

    componentWillUnmount() {
      // if(this.unlisten) {
      //   this.unlisten();
      // }
      window.removeEventListener('storage', this.authManager);
    }

    authManager = (e) => {
      if (e.key === AppConfig.localStorageKeys.USER_TOKEN) {
        const {user} = this.props;
        if (!_.isEmpty(user) && user.email) {
          if (e.newValue) {
            this.setState({showAuthLogin: false});
          } else {
            this.setState({showAuthLogin: true});
          }
        } else {
          this.props.logout();
          this.props.history.push('/auth/login');
        }
      }
    }

    componentDidMount () {
      window.addEventListener('storage', this.authManager);

      if (localStorage.getItem(AppConfig.localStorageKeys.USER_TOKEN)){
        if (_.isEmpty(this.props.user)) {
          this.props.setUser()
            .then((userData)=>{
              if (userData.force_reset_password) {
                // this.unlisten = this.props.history.listen((location) => {
                //   if (this.props.user.force_reset_password && location.pathname !== Locations.ACCOUNT_PASSWORD_UPDATE) {
                //     this.props.history.push(Locations.ACCOUNT_PASSWORD_UPDATE);
                //   }
                // });
                this.props.history.push(Locations.ACCOUNT_PASSWORD_UPDATE);
              }
            })
            .catch((err) => {
              const error = AppAPI.handleError(err);
              if (error === 'Failed to fetch') {
                this.setState({error: 'It looks like we are having trouble reach to our servers, please try to refresh the page or check your internet connection.'});
              } else {
                this.setState({error: 'Unknown error occurred please try again.'});
              }
            });
        }
      } else {
        this.props.history.push('/auth/login');
      }
    }

    render () {
      const {user} = this.props;
      const {error, showAuthLogin} = this.state;
      if (error) return <Error full={false} text={error}/>;
      if (_.isEmpty(user)) return <Loading/>;
      return (
        <React.Fragment>
          {showAuthLogin ? <Loading/> : <ComposedComponent {...this.props} />}
          <AuthLogin
            logoutRedirect={()=>this.props.history.push('/auth/login')}
            show={this.state.showAuthLogin}
            onHide={()=>this.setState({showAuthLogin: false})}
          />
        </React.Fragment>
      );
    }

    constructor(props) {
      super(props);

      this.state = {
        error: null,
        showAuthLogin: false,
      };
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(Authentication);
}