/**
 *
 * Booking Analytics OTA
 */
import { faReceipt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Moment from 'moment';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import React, { Component } from 'react';
import PageHeader from '../../../components/dashboard/PageHeader';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
// Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import { AnalyticConstants, Constants, Strings } from '../../../constants';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class BookingAnalyticsOTA extends Component {
  static componentName = 'BookingAnalyticsOTA';

  static propTypes = {
    pos: PropTypes.string,
    endDate: PropTypes.object,
    startDate: PropTypes.object
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {startDate, endDate, dateType} = this.props;
    this.setState({loading: true});
    const queryParams = {
      'date_type': dateType,
      'report_type': 'booking_ota',
      'start_date': (startDate ? startDate : Moment()).format('YYYY-MM-DD'),
      'end_date': endDate ? endDate.format('YYYY-MM-DD') : null
    };
    AppAPI.bookingapi.get(`booking-analytics/?${queryString.stringify(queryParams)}`)
      .then((res) => {
        this.setState({loading: false, response: res});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({loading: false, error: error});
      });
  };

  render = () => {
    const {loading, error, response} = this.state;
    let ota = [];

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    // Initialize with empty array for order data set
    const bookingDataSet = {};
    Object.keys(AnalyticConstants.bookingAnalytics).forEach((data)=>{
      bookingDataSet[data] = {};
    });

    // Containers for order status data & order source data
    const bookingData = {
      bookingStatusData: _.cloneDeep(bookingDataSet),
      otaData: {},
    };

    // Setting up container for Payment terminal level counts.
    if (!_.isEmpty(response) && !_.isEmpty(response.meta_data) ) {
      ota = response.meta_data.booking_ota || [];
      if (!_.isEmpty(ota)) {
        ota.forEach((data)=>{
          bookingData.otaData[data.id] = _.cloneDeep(bookingDataSet);
        });
      }
    }

    if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
      const responseData = response.data;
      if (!_.isEmpty(responseData.booking_ota_status_data)) {
        responseData.booking_ota_status_data.forEach((data) => {
          Object.entries(AnalyticConstants.bookingAnalytics).forEach(([key, value])=>{
            if (data[value]) {
              AppUtil.insertOrAddObject(bookingData.bookingStatusData[key], data.booking_status, data[value]);
              AppUtil.insertOrAddObject(bookingData.otaData[data.booking_ota][key], data.booking_status, data[value]);
            }
          });
        });
      }
    }

    const cancelNoShowBookingsCount =( bookingData.bookingStatusData.bookingCount[Constants.BOOKING.NOSHOW] || 0) + (bookingData.bookingStatusData.bookingCount[Constants.BOOKING.CANCELED] || 0);
    const activeBookingsCount = AppUtil.sumArray(Object.values(bookingData.bookingStatusData.bookingCount)) - cancelNoShowBookingsCount;

    const cancelNoShowBookingsNights = (bookingData.bookingStatusData.bookingNights[Constants.BOOKING.NOSHOW] || 0) + (bookingData.bookingStatusData.bookingNights[Constants.BOOKING.CANCELED] || 0);
    const activeBookingsNights = AppUtil.sumArray(Object.values(bookingData.bookingStatusData.bookingNights)) - cancelNoShowBookingsNights;

    const completedBookingsPercentage = AppUtil.calculatePercentage(
      bookingData.bookingStatusData.bookingCount[Constants.BOOKING.COMPLETED],
      activeBookingsCount
    );

    return (
      <div className="screen-container">
        <Helmet>
          <title>Booking Analytics - OTA</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={'Booking Analytics : OTA'}
          description={`Booking bifurcation based on ota, ${Strings.otaAnalyticsDisclaimer}`}
        />

        <div className={'row'}>
          <div className={'col-12 col-lg-6'}>
            <div className={'card mb-5'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col-6'}>
                    <p className={'text-muted mb-1'}>Total Booking(s)</p>
                    <h4 className={'card-title mb-2'}>
                      {AppUtil.sumArray(Object.values(bookingData.bookingStatusData.bookingCount))}
                    </h4>
                    <p className={'text-muted mb-3'}>
                      {AppUtil.sumArray(Object.values(bookingData.bookingStatusData.bookingNights))} booking night(s).
                    </p>
                  </div>
                  <div className={'col-6'}>
                    <p className={'text-muted mb-1'}>Cancelled</p>
                    <h4 className={'grey-cl mb-2'}>
                      {bookingData.bookingStatusData.bookingCount[Constants.BOOKING.CANCELED] || 0}
                    </h4>
                    <p className={'text-muted mb-3'}>
                      {bookingData.bookingStatusData.bookingNights[Constants.BOOKING.CANCELED] || 0} booking night(s).
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={'col-12 col-lg-6'}>
            <div className={'card mb-5'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col '}>
                    <p className={'text-muted mb-1'}>Completed Booking</p>
                    <h5 className={'card-title mb-0 pb-0 green-cl'}>
                      {bookingData.bookingStatusData.bookingCount[Constants.BOOKING.COMPLETED] || 0}
                    </h5>
                  </div>
                  <div className={'col'}>
                    <p className={'text-muted mb-1'}>Active / Up Coming</p>
                    <h5 className={'card-title mb-0 pb-0 blue-cl'}>
                      {activeBookingsCount - (bookingData.bookingStatusData.bookingCount[Constants.BOOKING.COMPLETED] || 0)}
                    </h5>
                  </div>
                </div>
                <div className="progress mt-3 blue-bg">
                  <div
                    className="progress-bar green-bg"
                    style={{width: `${completedBookingsPercentage}%`}}
                    aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"/>
                </div>
                <div className={'row'}>
                  <div className={'col-8'}>
                    <p className={'text-muted mb-0 mt-1 small'}>
                      Night(s) - Completed : {(bookingData.bookingStatusData.bookingNights[Constants.BOOKING.COMPLETED] || 0)} |
                      Active / UP : {activeBookingsNights - (bookingData.bookingStatusData.bookingNights[Constants.BOOKING.COMPLETED] || 0)}
                    </p>
                  </div>
                  <div className={'text-right col'}>
                    <p className={'text-muted mb-0 mt-1 small'}>{completedBookingsPercentage}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={'row mb-2'}>
          <div className={'col-12 col-lg-12'}>
            <table className={'table table-bordered'}>
              <thead>
                <tr>
                  <th className={'data-table-cell-md'}>OTA</th>
                  {Object.values(Constants.BOOKING_STATUS_INPUT).map((data, i) => (
                    <th colSpan={2} key={i}>{data.label}</th>
                  ))}
                </tr>
                <tr>
                  <th/>
                  {Object.values(Constants.BOOKING_STATUS_INPUT).map((data, i) => (
                    <React.Fragment key={i}>
                      <th>Nos</th>
                      <th>Night</th>
                    </React.Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>
                {_.isEmpty(ota)
                  ? (
                    <tr>
                      <td colSpan={12}>
                        <div className={'text-center table-list-empty'}>
                          <h4><FontAwesomeIcon icon={faReceipt}/> No OTA Bookings </h4>
                          {Strings.bookingOTAListEmpty}
                        </div>
                      </td>
                    </tr>
                  )
                  : (
                    <>
                      {ota.map((data, key)=>{
                        return (
                          <tr key={key}>
                            <td className={'data-table-cell-md'}>
                              {data.name}
                            </td>
                            {Object.values(Constants.BOOKING_STATUS_INPUT).map((bStatus, i) => (
                              <React.Fragment key={i}>
                                <td className={'text-center align-middle'}>
                                  {bookingData.otaData[data.id].bookingCount[parseInt(bStatus.value)] || 0}
                                </td>
                                <td className={'text-center  align-middle'}>
                                  {bookingData.otaData[data.id].bookingNights[parseInt(bStatus.value)] || 0}
                                </td>
                              </React.Fragment>
                            ))}
                          </tr>
                        );
                      })}
                      <tr>
                        <td><strong>Total</strong></td>
                        {Object.values(Constants.BOOKING_STATUS_INPUT).map((data, i) => (
                          <React.Fragment key={i}>
                            <td className={'text-center'}>
                              <strong>{(bookingData.bookingStatusData.bookingCount[parseInt(data.value)] || 0)}</strong>
                            </td>
                            <td className={'text-center'}>
                              <strong>{(bookingData.bookingStatusData.bookingNights[parseInt(data.value)] || 0)}</strong>
                            </td>
                          </React.Fragment>
                        ))}
                      </tr>
                    </>
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      showPAOverview: false,
      showPAOrderData: {},
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingAnalyticsOTA);
