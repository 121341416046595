/**
 * Manage Re Login
 *  manages user re-login
 */
import _ from 'lodash';
import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';
import {connect} from 'react-redux';
import {Modal} from 'react-bootstrap';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Locations} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import {Input, SubmitBtn} from 'react-formik-ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLongArrowAltRight, faPowerOff, faSpinner} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as UserActions from '../../../redux/user/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  user: state.user.user,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  login: UserActions.login
};

/* Component ==================================================================== */

class AuthLogin extends React.Component {
  static componentName = 'AuthLogin';

  static propTypes = {
    match: PropTypes.object,
  };

  login = (credentials) => {
    const {user} = this.props;
    if (credentials && !_.isEmpty(user)) {
      this.setState({loading: true});
      this.props.login({
        username: user.email,
        password: credentials.password
      }, true).then((userData) => {
        this.setState({
          resultMsg: {success: 'Awesome, you\'re now logged in!'}
        }, () => {
          setTimeout(() => {
            if (userData.force_reset_password) {
              this.props.history.push(Locations.ACCOUNT_PASSWORD_UPDATE);
            } else {
              this.props.onHide();
            }
          }, 1000);
        });
      }).catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({resultMsg: {error}, loading: false});
      });
    }
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        loading: false,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  render = () => {

    const {loading, resultMsg} = this.state;
    const {user, show} = this.props;

    if (!show) return <React.Fragment/>;

    const formValidation = Yup.object().shape({
      password: Yup.string().required('Enter your password'),
    });

    const initialValues = {
      email: user.email,
      password: ''
    };

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Session Expired
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={formValidation}
          onSubmit={(values) => this.login(values)}
        >
          {() => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <Form className={'form-group'}>
                      <div className="form-row">
                        <div className="col-12 form-group">
                          <Input
                            name="email"
                            disabled={true}
                            label={'Email Address'}
                            className={'form-control'}
                          />
                        </div>
                        <div className="col-12 form-group">
                          <Input
                            name="password"
                            type="password"
                            label={'Password'}
                            className={'form-control'}
                          />
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-danger btn-lg btn-block'} onClick={() => this.props.logoutRedirect()}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faPowerOff} size={'sm'}/> Logout
                    </button>
                  </div>
                  <div className={'col'}>
                    <SubmitBtn disabled={loading} className={'btn btn-success btn-lg btn-block'}>
                      Login
                      <FontAwesomeIcon
                        className={'ml-3'}
                        spin={loading}
                        icon={loading ? faSpinner : faLongArrowAltRight}
                      />
                    </SubmitBtn>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(AuthLogin);

