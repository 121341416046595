/**
 *
 * Payment Terminal Transaction list
 * List Payment terminal transactions
 */
import queryString from 'query-string';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import Moment from 'moment';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import AppWebUtil from '../../../lib/webUtils';
import {Constants, Filters, Strings} from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import SearchBar from '../../../components/forms/SearchBar';
import FilterDate from '../../../components/dashboard/FilterDate';
import PageHeader from '../../../components/dashboard/PageHeader';
import ExportButton from '../../../components/dashboard/ExportButton';
import FilterButton from '../../../components/dashboard/FilterButton';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLink, faLongArrowAltRight, faSync} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PTerminalList extends Component {
  static componentName = 'PTerminalList';

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };

  componentDidMount = () => {
    let urlParams = {}, startDate = null, endDate = null;
    switch (this.getQueryParams('payment_status')) {
    case 'paid':
      urlParams['payment_status'] = Constants.PTERMINAL_STATUS.PAID;
      break;
    case 'cancelled':
      urlParams['payment_status'] = Constants.PTERMINAL_STATUS.CANCELLED;
      break;
    case 'not-paid':
      urlParams['payment_status'] = Constants.PTERMINAL_STATUS.NOTPAID;
      break;
    case 'failed':
      urlParams['payment_status'] = Constants.PTERMINAL_STATUS.FAILED;
      break;
    case 'initial':
      urlParams['payment_status'] = Constants.PTERMINAL_STATUS.INITIAL;
      break;
    case 'action':
      urlParams['payment_status'] = Constants.PTERMINAL_STATUS.ACTION;
      break;
    case 'waiting':
      urlParams['payment_status'] = Constants.PTERMINAL_STATUS.TERMINAL_RESPONSE;
      break;
    default:
      urlParams = {};
    }

    if (this.getQueryParams('start_date')) {
      startDate = Moment(String(this.getQueryParams('start_date')));
    }
    if (this.getQueryParams('end_date')) {
      endDate = Moment(String(this.getQueryParams('end_date')));
    }

    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('start_date'), this.getQueryParams('end_date'), 'created_date');

    if (this.props.guestId) {
      urlParams += `&guest_data=${this.props.guestId}`;
    }

    if (this.props.corporateId) {
      urlParams += `&corporate_data=${this.props.corporateId}`;
    }

    this.setState({loading: false, urlParams, startDate, endDate});
  };

  fetchData = (page = 1, callback, searchText, download) => {
    const urlParams = {...this.state.urlParams};
    let baseUrl = 'pterminal';
    urlParams['page'] = page;

    if (searchText) {
      this.setState({searching: true, clear: true});
      baseUrl = 'pterminal-search';
      urlParams['search'] = searchText;
    }

    if (download) {
      urlParams['download'] = download.file;
      urlParams['all_data'] = true;
      this.setState({fileDownload: true});
    }

    AppAPI.pterminalapi.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (download) {
          let fileName = `Payment-Terminal-Report-${AppUtil.formatDateTime(Moment(), 'short')}.${download.file}`;
          AppWebUtil.downloadFile(res, fileName);
        } else {
          if (res.results.length > 0) {
            callback(res.results, {allLoaded: !res.next});
          } else {
            callback();
          }
        }
        this.setState({
          searching: false,
          clear: false,
          refresh: false,
          loading: false,
          fileDownload: false,
          page: page,
        });
      })
      .catch((err) => {
        if (callback) {
          callback([], {allLoaded: true});
        }
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, refresh: false, loading: false, fileDownload: false, error});
      });
  };

  renderRow = (data, key) => {
    return (
      <tr className={'tr-align-middle'} key={key}>
        <td className={'data-table-cell-md'}>
          <Link to={`/payment/payment-terminal/${data.hash}/`}><small>{data.ref_no}</small></Link>
        </td>
        <td className={'data-table-cell-md text-left'}><strong>{`${data.currency} ${data.total}`}</strong></td>
        <td className={'data-table-cell-md text-left'}>
          <span className={'badge ' + (AppUtil.pTerminalStatusColor(data.payment_status))}>
            {data.payment_status_display}
          </span>
        </td>
        <td className={'data-table-cell-md'}>{data.terminal_provider_display}</td>
        <td className={'data-table-cell-md'}>
          {!_.isEmpty(data.attributes) &&
          <Link to={`/payment/${data.attributes.payment_hash}/`}>
            {data.attributes.payment_ref_no || 'Payment'}
          </Link>
          }
        </td>
        <td className={'data-table-cell-md'}><small>{AppUtil.formatDateTime(data.created)}</small></td>
        <td className={'data-table-cell-md'}>
          <Link to={`/payment/payment-terminal/${data.hash}/`}>
            Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
          </Link>
        </td>
      </tr>
    );
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={7}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faLink}/> No Terminal Transactions </h4>
            {Strings.pTerminalEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };

  tableHead = () => {
    return (
      <tr>
        <th>Reference</th>
        <th className={'text-left'}>Amount</th>
        <th />
        <th>Gateway</th>
        <th>Payment</th>
        <th>Date</th>
        <th/>
      </tr>
    );
  };


  render = () => {
    const {loading, error, results, fileDownload, refresh} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="payment-list screen-container">
        <Helmet>
          <title>Payment Terminal List</title>
        </Helmet>

        <PageHeader
          history={this.props.history} title={'Payment Terminal'}
          description={'All payments terminal transactions in the property.'}
        />

        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-9'}>
            <ul className="list-inline">
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.setState({refresh: true})}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                </button>
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Payment Status'}
                  data={Filters.pTerminalStatus}
                  selectKey={this.getQueryParams('payment_status')}
                  action={(data) => this.setQueryParams([{key: 'payment_status', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data))}
                  size={'sm'}
                  maxDate={Moment().add(1, 'days')}
                />
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-3 text-center text-sm-right'}>
            <ExportButton
              size={'sm'}
              files={['csv']}
              action={(key) => this.fetchData(this.state.page, null, null, {file: key})}
              loading={fileDownload}
              disabled={!this.state.startDate}
            />
          </div>
        </div>


        <div className="row">
          <div className="col-12">
            <SearchBar
              refresh={refresh}
              results={results}
              tableHeader={this.tableHead}
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              placeHolder="Payment Reference or Payment Link Reference"
              colSpan={7}
            />
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      searching: false,
      clear: false,
      results: [],
      urlParams: {},
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PTerminalList);
