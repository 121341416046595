/**
 *  Room Type Content Management
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Actions
import * as RoomTypeActions from '../../../../redux/roomtype/actions';
// Consts and Libs
import AppAPI from '../../../../lib/api';
import {ErrorMessages} from '../../../../constants';
// Components
import Error from '../../../../components/general/Error';
import Loading from '../../../../components/general/Loading';
import PageHeader from '../../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faSave} from '@fortawesome/free-solid-svg-icons';
// Form
import * as Yup from 'yup';
import {Form, Input, Select, SubmitBtn, Textarea} from 'react-formik-ui';
import {Formik} from 'formik';
import {Alerts} from '../../../../components/ui';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  roomType: state.roomType.roomType,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setRoomType: RoomTypeActions.setRoomType,
};

/* Component ==================================================================== */

class RoomTypeContentManage extends Component {
  static componentName = 'RoomTypeContentManage';

  static propTypes = {
    match: PropTypes.object,
  };

  componentDidMount = () => {
    if (this.props.match.params.roomTypeId) {
      this.props.setRoomType(this.props.match.params.roomTypeId)
        .then(() => {
          this.fetchInitData();
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.fetchInitData();
    }
  };

  saveRoomType = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.roomtype.patch(this.props.match.params.roomTypeId + '/',
          {
            'bed_type': credentials.bed_type,
            'view_type': credentials.view_type,
            'room_width': credentials.room_width,
            'room_shape': credentials.room_shape,
            'description': credentials.description,
            'room_length': credentials.room_length,
            'smoking_type': credentials.smoking_type,
            'short_description': credentials.short_description,
            'room_dimension_unit': credentials.room_dimension_unit,
          })
          .then((roomType) => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.setRoomType(roomType);
                  this.props.history.goBack();
                }, 500);
              },
            );
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  fetchInitData = () => {
    const {roomType} = this.props;
    const bedType = [], smokingType = [], viewType = [], roomShape = [], roomDimension = [];

    AppAPI.roomtype.options()
      .then((res) => {
        if (res.actions !== '') {

          for (let i in res.actions.POST.smoking_type.choices) {
            smokingType.push({
              value: res.actions.POST.smoking_type.choices[i].value.toString(),
              label: res.actions.POST.smoking_type.choices[i].display_name.toString(),
            });
          }

          for (let i in res.actions.POST.view_type.choices) {
            viewType.push({
              value: res.actions.POST.view_type.choices[i].value.toString(),
              label: res.actions.POST.view_type.choices[i].display_name.toString(),
            });
          }

          for (let i in res.actions.POST.bed_type.choices) {
            bedType.push({
              value: res.actions.POST.bed_type.choices[i].value.toString(),
              label: res.actions.POST.bed_type.choices[i].display_name.toString(),
            });
          }

          for (let i in res.actions.POST.room_shape.choices) {
            roomShape.push({
              value: res.actions.POST.room_shape.choices[i].value.toString(),
              label: res.actions.POST.room_shape.choices[i].display_name.toString(),
            });
          }

          for (let i in res.actions.POST.room_dimension_unit.choices) {
            roomDimension.push({
              value: res.actions.POST.room_dimension_unit.choices[i].value.toString(),
              label: res.actions.POST.room_dimension_unit.choices[i].display_name.toString(),
            });
          }
        }
        let initialValues = {
          'bed_type': roomType.bed_type,
          'view_type': roomType.view_type,
          'room_width': roomType.room_width,
          'room_shape': roomType.room_shape,
          'description': roomType.description,
          'room_length': roomType.room_length,
          'smoking_type': roomType.smoking_type,
          'short_description': roomType.short_description,
          'room_dimension_unit': roomType.room_dimension_unit,
        };
        let formValidation = Yup.object().shape({});
        this.setState({
          bedType,
          smokingType,
          viewType,
          roomShape,
          roomDimension,
          initialValues,
          formValidation,
          loading: false,
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({resultMsg: {error}});
      });
  };

  render = () => {

    const {roomType} = this.props;
    const {loading, error, initialValues, formValidation, resultMsg} = this.state;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    return (

      <div className="room-type-list-manage screen-container ">
        <Helmet>
          <title>Room Types Content Manage</title>
        </Helmet>
        <PageHeader
          subHeader={true} history={this.props.history}
          title={'Room Type Content'}
          description={`Manage content settings for room type ${roomType.name}`}
        />

        <Formik
          onSubmit={(values) => this.saveRoomType(values)}
          validationSchema={formValidation}
          initialValues={initialValues}>
          {() => (
            <Form>
              <div className={'row'}>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      General Information
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="form-row">
                        <div className="col-lg-12 col-sm-12 form-group">
                          <Textarea
                            name='short_description'
                            label={'Short Description'}
                            hint={'Short description will appear on top of room type details page and will be used as Meta description'}
                            className={'form-control'}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-sm-12 form-group">
                        <Textarea
                          name='description'
                          label={'Description'}
                          hint={'Room Type description.'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={'row'}>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Room Options
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-4 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='bed_type'
                          label={'Bed type'}
                          placeholder='Select an Option'
                          options={this.state.bedType}
                        />
                      </div>
                      <div className="col-4 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='view_type'
                          label={'View type'}
                          placeholder='Select an Option'
                          options={this.state.viewType}
                        />
                      </div>
                      <div className="col-4 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='room_shape'
                          label={'Shape'}
                          placeholder='Select an Option'
                          options={this.state.roomShape}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-4 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='smoking_type'
                          label={'Smoking Preference'}
                          placeholder='Select an Option'
                          options={this.state.smokingType}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Room Dimensions
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-4 form-group">
                        <Input
                          required
                          name='room_length'
                          label={'Length'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-4 form-group">
                        <Input
                          required
                          name='room_width'
                          label={'Width'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-4 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='room_dimension_unit'
                          label={'Dimension'}
                          placeholder='Select an Option'
                          options={this.state.roomDimension}
                        />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn className={'btn btn-success btn-block btn-lg mt-2'}>
                    <FontAwesomeIcon
                      className={'white-cl mr-2'}
                      icon={this.props.match.params.roomTypeId ? faSave : faPlus} size={'sm'}
                    /> {this.props.match.params.roomTypeId ? 'Save' : 'Add'}
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>


      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      initialValues: {},
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(RoomTypeContentManage);
