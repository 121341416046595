/**
 *
 * Property Room Inventory Links
 * Houses sections related to property inventory
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link, NavLink, Route, Switch} from 'react-router-dom';
// Consts and Libs
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PageHeader from '../../../components/dashboard/PageHeader';
// Components
import POSDetails from './POSDetails';
import POSManage from './POSManage';
import POSCategoryList from './POSCategoryList';
import POSCategoryManage from './POSCategoryManage';
import POSCategoryCSVAdd from './POSCategoryCSVAdd';
import POSCategoryMultipleAdd from './POSCategoryMultipleAdd';
import POSCategorySettings from './POSCategory';
import POSItemList from './POSItemList';
import POSTableList from './POSTableList';
import POSItemDetails from './POSItemDetails';
import POSItemManage from './POSItemManage';
import POSItemCSVAdd from './POSItemCSVAdd';
import POSItemMultipleAdd from './POSItemMultipleAdd';
import POSTableManage from './POSTableManage';
import POSTableDetails from './POSTableDetails';
import POSTableMultipleAdd from './POSTableMultipleAdd';
import POSMembershipList from './POSMembershipList';
import POSSequenceSettings from './POSSequenceSettings';
import POSMembershipManage from './POSMembershipManage';
import POSMembershipDetails from './POSMembershipDetails';
import POSSequenceSettingsManage from './POSSequenceSettingsManage';

import POSContentSettings from './POSContentSettings';
import POSContentSettingsManage from './POSContentSettingsManage';

// Decorator
import posCategoryRequired from '../../../components/decorators/posCategoryRequired';


import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pos: state.pos.pos,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class Index extends Component {
  static componentName = 'POSSettings';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    if (this.props.match.params.POSId) {
      this.setState({
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };


  render = () => {

    const {loading, error} = this.state;
    const {pos} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    let baseUrl = '/settings/pos';
    let absBaseUrl = `${baseUrl}/${pos.hash}`;
    const menu = [
      {
        title: 'POS Details',
        link: `${absBaseUrl}/`,
        isExact: true,
      },
      {
        title: 'Category',
        link: `${absBaseUrl}/category/`,
      },
      {
        title: 'Item(s)',
        link: `${absBaseUrl}/item/`,
      },
      {
        title: 'Account(s)',
        link: `${absBaseUrl}/account/`,
      },
      {
        title: 'Sequence',
        link: `${absBaseUrl}/sequence/`,
      },
      {
        title: 'Content',
        link: `${absBaseUrl}/content/`,
      },
      // {
      //   title: 'Booking Engine',
      //   link: `${absBaseUrl}/booking-engine/`,
      // },
    ];

    if (pos.pos_has_table) {
      menu.push(
        {
          title: 'Table(s)',
          link: `${absBaseUrl}/table/`,
        }
      );
    }

    let menuItems = [];
    menu.map((item) => {
      const {title, link, isExact} = item;
      return menuItems.push(
        <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
          <NavLink
            to={{
              pathname: link,
              state: {fromDashboard: true},
            }}
            exact={isExact}
            className="nav-link rounded-0"
            activeClassName="selected">
            {title}
          </NavLink>
        </li>,
      );
    });

    return (
      <div className="room-type-details screen-container ">
        <Helmet>
          <title>Point of Sales Settings</title>
        </Helmet>

        {baseUrl &&
        <Link to={baseUrl} className={'btn btn-outline-dark mr-2 btn-sm mt-2'}>
          <FontAwesomeIcon icon={faArrowLeft} size={'sm'}/> {'POS List'}
        </Link>
        }

        <PageHeader
          subHeader={true} history={this.props.history} title={'Point of Sale Details'}
          description={`Settings & Configuration for point of sale : ${pos.name}`}
        />


        <ul className="nav nav-tabs mb-3">
          {menuItems}
        </ul>

        <Switch>
          <Route path={`${baseUrl}/:POSId/`} exact component={POSDetails}/>
          <Route path={`${baseUrl}/:POSId/edit`} exact component={POSManage}/>

          <Route path={`${baseUrl}/:POSId/category`} exact component={POSCategoryList}/>
          <Route path={`${baseUrl}/:POSId/category/new`} exact component={POSCategoryManage}/>
          <Route path={`${baseUrl}/:POSId/category/new-csv`} exact component={POSCategoryCSVAdd}/>
          <Route path={`${baseUrl}/:POSId/category/new-multiple`} exact component={POSCategoryMultipleAdd}/>
          <Route path={`${baseUrl}/:POSId/category/:POSCategoryId/`} component={posCategoryRequired(POSCategorySettings)}/>


          <Route path={`${baseUrl}/:POSId/item`} exact component={POSItemList}/>
          <Route path={`${baseUrl}/:POSId/item/new`} exact component={POSItemManage}/>
          <Route path={`${baseUrl}/:POSId/item/new-csv`} exact component={POSItemCSVAdd}/>
          <Route path={`${baseUrl}/:POSId/item/new-multiple`} exact component={POSItemMultipleAdd}/>
          <Route path={`${baseUrl}/:POSId/item/:POSItemId`} exact component={POSItemDetails}/>
          <Route path={`${baseUrl}/:POSId/item/:POSItemId/edit`} exact component={POSItemManage}/>


          <Route path={`${baseUrl}/:POSId/account`} exact component={POSMembershipList}/>
          <Route path={`${baseUrl}/:POSId/account/:POSMembershipId`} exact component={POSMembershipDetails}/>
          <Route path={`${baseUrl}/:POSId/account/:POSMembershipId/edit`} exact component={POSMembershipManage}/>


          <Route path={`${baseUrl}/:POSId/content`} exact component={POSContentSettings}/>
          <Route path={`${baseUrl}/:POSId/content/edit`} exact component={POSContentSettingsManage}/>


          <Route path={`${baseUrl}/:POSId/sequence`} exact component={POSSequenceSettings}/>
          <Route path={`${baseUrl}/:POSId/sequence/edit`} exact component={POSSequenceSettingsManage}/>

          <Route path={`${baseUrl}/:POSId/table`} exact component={POSTableList}/>
          <Route path={`${baseUrl}/:POSId/table/new`} exact component={POSTableManage}/>
          <Route path={`${baseUrl}/:POSId/table/new-multiple`} exact component={POSTableMultipleAdd}/>

          <Route path={`${baseUrl}/:POSId/table/:POSTableId`} exact component={POSTableDetails}/>
          <Route path={`${baseUrl}/:POSId/table/:POSTableId/edit`} exact component={POSTableManage}/>


        </Switch>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
