/**
 *
 * Billing Payment Details
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';

// Actions
import * as PaymentActions from '../../redux/payment/actions';
import * as BillingAction from '../../redux/billing/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  payment: state.billing.payment
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPayment: PaymentActions.setPayment,
  setBillingPayment: BillingAction.setBillingPayment,
};


/* Component ==================================================================== */
class BillingPaymentDetails extends Component {
  static componentName = 'BillingPaymentDetails';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };
  componentDidMount = () => {
    if (this.props.match.params.paymentId) {
      this.props.setBillingPayment(this.props.match.params.paymentId)
        .then(() => {
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.setState({
        loading: false,
        error: true
      });
    }
  };

  render = () => {

    const {loading, error} = this.state;
    const {payment} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="order-details screen-container ">
        <Helmet>
          <title>Payment Details</title>
        </Helmet>


        <div className="row">
          <div className="col-lg-7 col-md-12 col-sm-12">
            <div
              role="presentation" onKeyPress={this.handleKeyPress}
              onClick={() => this.props.history.goBack()} className="btn btn-outline-info">
              <span className="fa fa-chevron-left"/> Back
            </div>
            <div className="spacer-20"/>

            <h3>Total : {payment.currency + ' ' + payment.total}</h3>
            <span className={'badge ' +
            (payment.payment_status === 1 ? 'badge-success' : (payment.lock === true ? 'badge-secondary' : 'badge-danger')
            )}>
              {payment.payment_status ? payment.get_payment_status_display : payment.lock ? 'Canceled' : payment.get_payment_status_display}</span>

            {(payment.payment_status === 1) &&
            <p className="mb-1"><strong>Payment Time</strong> : {AppUtil.formatDateTime(payment.time)} </p>}
            <p className="mb-1"><strong>Payment ID : </strong> {payment.invoice_no}</p>


          </div>
        </div>
      </div>
    );
  };

  constructor (props) {
    super(props);

    this.state = {
      loading: true,
      error: false
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(BillingPaymentDetails);