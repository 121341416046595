import React from 'react';
import {Route, Switch} from 'react-router-dom';


import NotFound from '../NotFound';

import POSList from '../pos/settings/POSList';
import POSManage from '../pos/settings/POSManage';
import POSSettings from '../pos/settings/';


import posRequired from '../../components/decorators/posRequired';


const PropertyRoute = () => (
  <div>
    <div className={'row'}>
      <div className={'col-lg-12 col-md-12 col-sm-12'}>
        <Switch>
          <Route path="/settings/pos/" exact component={POSList}/>
          <Route path="/settings/pos/new" exact component={POSManage}/>
          <Route path="/settings/pos/:POSId" component={posRequired(POSSettings)}/>


          {/*<Route path="/settings/pos/:POSId/category/" exact component={POSCategoryList}/>*/}
          {/*<Route path="/settings/pos/:POSId/category/new" exact component={POSCategoryManage}/>*/}
          {/*<Route path="/settings/pos/:POSId/category/multiple/" exact component={POSCategoryAddMultiple}/>*/}
          {/*<Route path="/settings/pos/:POSId/category/:POSCategoryId" exact component={POSCategorySettings}/>*/}
          {/*<Route path="/settings/pos/:POSId/category/:POSCategoryId/edit" exact component={POSCategoryManage}/>*/}


          {/*<Route path="/settings/pos/:POSId/item/" exact component={POSItem}/>*/}
          {/*<Route path="/settings/pos/:POSId/item/new" exact component={POSItemManage}/>*/}
          {/*<Route path="/settings/pos/:POSId/item/:POSItemId" exact component={POSItemSettings}/>*/}
          {/*<Route path="/settings/pos/:POSId/item/:POSItemId/edit" exact component={POSItemManage}/>*/}

          {/*<Route path="/settings/pos/:POSId/table/" exact component={POSTable}/>*/}
          {/*<Route path="/settings/pos/:POSId/table/new" exact component={POSTablesManage}/>*/}
          {/*<Route path="/settings/pos/:POSId/table/multiple/" exact component={POSTableAddMultiple}/>*/}
          {/*<Route path="/settings/pos/:POSId/table/:POSTableId" exact component={POSTableSettings}/>*/}
          {/*<Route path="/settings/pos/:POSId/table/:POSTableId/edit" exact component={POSTablesManage}/>*/}
          {/*<Route path="/settings/pos/:POSId/table/:tableId/history" exact component={POSTableMaintenanceHistory}/>*/}
          <Route exact component={NotFound}/>
        </Switch>
      </div>
    </div>
  </div>
);

export default PropertyRoute;