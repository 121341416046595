/**
 *
 * Room Type Booking Engine Settings
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import { Img } from 'react-image';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../../../lib/api';
// Components
import {
  faBaby,
  faBed,
  faChild,
  faPen,
  faRulerCombined,
  faSmoking,
  faSmokingBan,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import Error from '../../../../components/general/Error';
import Loading from '../../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import RoomTypeCoverImageManage from './RoomTypeCoverImageManage';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  roomType: state.roomType.roomType,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class RoomTypeBookingEngineContent extends Component {
  static componentName = 'RoomTypeBookingEngineContent';

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    AppAPI.roomtype.get(`${this.props.roomType.id}/?content=True`)
      .then((res) => {
        this.setState({
          roomType: res,
          loading: false,
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({resultMsg: {error}});
      });
  };

  render = () => {
    const {loading, error, roomType} = this.state;
    if (!roomType || loading) return <Loading heightMatch={faPen}/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="channel-manager-settings screen-container">
        <Helmet>
          <title>Booking Engine Content</title>
        </Helmet>
        <div className={'row'}>
          <div className="col-lg-12 col-md-12 col-sm-12 mb-5">
            <button
              onClick={() => this.setState({showRoomTypeCoverImageManage: true})}
              className="btn btn-link mb-2 float-right"
            >
              <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit Cover
            </button>
            <div
              className={'border p-2 text-center anchor w-100'} data-toggle="collapse"
              role="button" aria-expanded="false" aria-controls="orderActions">
              <h6 className={'mb-0'}>
                Room Type Cover
              </h6>
            </div>
            <div className={'border'}>
              <div
                className={'home-header'}
                style={roomType.room_type_cover_image && roomType.room_type_cover_image.cover && {
                  backgroundImage: `url(${roomType.room_type_cover_image.cover})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                <table className={'w-100 h-100  header-inner'}>
                  <tbody>
                    <tr>
                      <td className="align-middle">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <Link to={'manage'} className="btn btn-link mb-2 float-right">
              <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit
            </Link>
            <div
              className={'border p-2 text-center anchor w-100'} data-toggle="collapse"
              role="button" aria-expanded="false" aria-controls="orderActions">
              <h6 className={'mb-0'}>
                Content
              </h6>
            </div>
            <div className={'border p-2'} id={'primaryAction'}>
              <h3 className={'mb-4'}>{roomType.name}</h3>
              <div className={'row'}>
                <div className={'col-8 mx-auto mb-3'}>
                  {(roomType.slider_image && roomType.slider_image.length > 0) ?
                    <div>
                      <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                        <ol className="carousel-indicators">
                          {roomType.slider_image.map((data, i) => (
                            <li
                              data-target="#carouselExampleIndicators"
                              key={i}
                              data-slide-to={i}
                              className={i === 0 ? 'active' : ''}
                            />
                          ))}
                        </ol>
                        <div className="carousel-inner">
                          {roomType.slider_image.map((data, i) => (
                            <div className={`carousel-item ${i === 0 && 'active'}`} key={i}>
                              <Img
                                className="d-block w-100"
                                src={data.image.slider}
                                loader={<Loading heightMatch={false}/>}
                              />
                            </div>
                          ))}
                        </div>
                        <a
                          className="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                          data-slide="prev">
                          <span className="carousel-control-prev-icon" aria-hidden="true"/>
                          <span className="sr-only">Previous</span>
                        </a>
                        <a
                          className="carousel-control-next" href="#carouselExampleIndicators" role="button"
                          data-slide="next">
                          <span className="carousel-control-next-icon" aria-hidden="true"/>
                          <span className="sr-only">Next</span>
                        </a>
                      </div>
                    </div>
                    : <table className={'w-100 h-100 bg-dark'}>
                      <tbody>
                        <tr>
                          <td className="align-middle">
                            <h1 className={'text-white text-center align-middle'}>Slider</h1>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  }
                </div>
                <div className={'col-12'}>
                  <ul className="list-inline inline-list-spacing small mb-3">
                    <li className="list-inline-item">
                      <FontAwesomeIcon icon={faUser} size={'sm'} className={'mr-2 blue-cl'}/>
                      {roomType.base_occupancy} {parseInt(roomType.child_occupancy) === 1 ? 'Guest' : 'Guests'}

                    </li>
                    {roomType.child_occupancy !== 0 &&
                     <li className="list-inline-item">
                       <FontAwesomeIcon icon={faChild} size={'sm'} className={'mr-2 blue-cl'}/>
                       {roomType.child_occupancy} {parseInt(roomType.child_occupancy) === 1 ? 'Child' : 'Children'}
                     </li>
                    }
                    {roomType.infant_occupancy !== 0 &&
                     <li className="list-inline-item">
                       <FontAwesomeIcon icon={faBaby} className={'mr-2 blue-cl'}/>
                       {roomType.infant_occupancy} {parseInt(roomType.infant_occupancy) === 1 ? 'Infant' : 'Infants'}
                     </li>
                    }

                    {roomType.room_dimension &&
                     <li className="list-inline-item">
                       <FontAwesomeIcon icon={faRulerCombined} className={'mr-2 blue-cl'}/>
                       {roomType.room_dimension}
                     </li>
                    }

                    {roomType.smoking_type_display &&
                     <li className="list-inline-item">
                       <FontAwesomeIcon
                         icon={roomType.smoking_type_display.includes('Non') ? faSmokingBan : faSmoking}
                         className={'mr-2 blue-cl'}/> {roomType.smoking_type_display}
                     </li>
                    }

                    {roomType.bed_type_display &&
                     <li className="list-inline-item">
                       <FontAwesomeIcon icon={faBed} className={'mr-2 blue-cl'}/> {roomType.bed_type_display}
                     </li>
                    }
                  </ul>

                  {roomType.short_description ?
                    <p className={'font-weight-light'}>{roomType.short_description}</p>
                    :
                    <p className={'text-muted'}>Short description.</p>
                  }
                </div>
                <div className={'col-12'}>
                  <hr/>
                  {roomType.description ?
                    <p className={'font-weight-light'}>{roomType.description}</p>
                    :
                    <p className={'text-muted'}>Room type description.</p>
                  }
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className={'row'}>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="row mb-5">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Bed Type</strong> : {roomType.bed_type ? roomType.bed_type_display : 'N.A'}</li>
                  <li className="list-group-item">
                    <strong>View</strong> : {roomType.view_type ? roomType.view_type_display : 'N.A'}</li>
                  <li className="list-group-item">
                    <strong>Smoking </strong> : {roomType.smoking_type ? roomType.smoking_type_display : 'N.A'}</li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Dimension</strong> : {roomType.room_dimension ? roomType.room_dimension : 'N.A'}
                  </li>
                  <li className="list-group-item">
                    <strong>Shape</strong> : {roomType.room_shape ? roomType.room_shape_display : 'N.A'}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <RoomTypeCoverImageManage
          show={this.state.showRoomTypeCoverImageManage}
          onHide={() => this.setState({showRoomTypeCoverImageManage: false})}
          onSave={() => {
            this.setState({loading: true}, () => {
              this.fetchInitData();
            });
          }}
          roomType={roomType}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      roomType: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      showRoomTypeCoverImageManage: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomTypeBookingEngineContent);
