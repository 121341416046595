/**
 *
 * Billing Payment Fail
 * overview of billing Plans
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = () => ({});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class BillingPaymentFail extends Component {
  static componentName = 'BillingPaymentFail';
  componentDidMount = () => {
    this.fetchInitData();
  };
  fetchInitData = () => {
  };
  render = () => {
    const {loading} = this.state;

    if (loading) return <Loading/>;
    return <Error full={true} text={'Payment Failed, contact us for further information.'}/>;
  };

  constructor (props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingPaymentFail);
