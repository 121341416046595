/**
 * Environment Warning
 */
import React, { Component } from 'react';

import AppConfig from '../../constants/config';
// import AppUtil from '../../lib/util';
//
// import moment from 'moment';

/* Component ==================================================================== */
class EnvironmentWarning extends Component {
  static componentName = 'EnvironmentWarning';

  render = () => {
    // const start_time = '2021-09-28T02:30:00+05:30';
    // const end_time = '2021-09-28T03:30:00+05:30';
    return (
      <React.Fragment>
        {(AppConfig.environment !== 'production') &&
         <div className="header sticky-top mb-0 environment-warning">
           <div className="alert alert-warning alert-dismissible fade show mb-0" role="alert">
             <strong>Warning: </strong> This is a staging site, connected to end point : {AppConfig.endpoint}.
             The production site is <a href={AppConfig.dashboardHostname}>here</a>.
             <button type="button" className="close" data-dismiss="alert" aria-label="Close">
               <span aria-hidden="true">&times;</span>
             </button>
           </div>
         </div>
        }

        {/*{(moment() < moment(end_time)) &&*/}
        {/*<div className="header sticky-top mb-0 environment-warning">*/}
        {/*  <div className="alert alert-warning alert-dismissible fade show mb-0" role="alert">*/}
        {/*    <strong>Scheduled Maintenance : </strong> Due to scheduled maintenance our service will be disrupted from {AppUtil.formatDateTime(start_time)} to {AppUtil.formatDateTime(end_time)}. We sincerely regret the inconvenience caused to you.*/}
        {/*    <button type="button" className="close" data-dismiss="alert" aria-label="Close">*/}
        {/*      <span aria-hidden="true">&times;</span>*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*}*/}
      </React.Fragment>

    );
  };
}

/* Export Component ==================================================================== */
export default EnvironmentWarning;
