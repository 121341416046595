/**
 * BookingListView
 *  View for Booking Listing
 *
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
// Consts and Libs
import AppUtil from '../../lib/util';
// Actions
import * as BookingActions from '../../redux/booking/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setBooking: BookingActions.setBooking,
};

/* Component ==================================================================== */
class BookingListView extends Component {
  static componentName = 'BookingListView';

  static propTypes = {
    data: PropTypes.object.isRequired,
    history: PropTypes.object,
    disabled: PropTypes.bool
  };

  action = (data) => {
    if (this.props.disabled !== true && this.props.history){
      this.props.setBooking(data);
      this.props.history.push('/booking/' + data.hash);
    }
  };

  render = () => {
    const data = this.props.data;
    return (
      <div
        role="presentation"
        onKeyPress={this.handleKeyPress}
        onClick={() => this.action(data)}
        className="list-group-item list-group-item-action flex-column align-items-start a-class">
        <div className="d-flex w-100 justify-content-between">
          <div>
            <h5 className="mb-1 text-break">
              {(data.guest && data.guest.name)? data.guest.name : data.ref_no}
            </h5>
            <p className="mb-0 small">
              {AppUtil.formatDateTime(data.checkin, 'date') + '-' + AppUtil.formatDateTime(data.checkout, 'date')}
            </p>
            {data.guest && <p className={'text-secondary small mb-0'}>Reference : {data.ref_no}<br/></p>}
          </div>
          <div className={'text-right'}>
            <span className={'badge ' + (AppUtil.bookingStatusColor(data.booking_status))}>
              {data.booking_status_display}
            </span>
            {!data.confirm_status &&
             <div className={''}>
               <small>[Not Confirmed]</small>
             </div>}
          </div>
        </div>
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(BookingListView);

