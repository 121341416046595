/**
 *
 * Room Type Booking Engine Settings
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {NavLink, Route, Switch} from 'react-router-dom';
// Components
import Error from '../../../../components/general/Error';
import Loading from '../../../../components/general/Loading';
import RoomTypeBookingEngineOverview from './RoomTypeBookingEngineOverview';
import RoomTypeBookingEngineContent from './RoomTypeBookingEngineContent';
import PropertySliderImageList from '../../../property/bookingEngine/PropertySliderImageList';
import RoomTypeBookingEngineManage from './RoomTypeBookingEngineManage';
import PropertyBookingEngineAmenities from '../../../property/bookingEngine/PropertyBookingEngineAmenities';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  roomType: state.roomType.roomType,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class RoomTypeBookingEngine extends Component {
  static componentName = 'RoomTypeBookingEngine';

  componentDidMount = () => {
  };


  render = () => {
    const {loading, error} = this.state;
    const {roomType} = this.props;
    if (!this.props.property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;


    let baseUrl = '/settings/inventory/room-type/:roomTypeId/booking-engine';
    let absBaseUrl = `/settings/inventory/room-type/${roomType.id}/booking-engine`;
    const menu = [
      {
        title: 'Overview',
        link: `${absBaseUrl}/`,
        isExact: true,
      },
      {
        title: 'Content',
        link: `${absBaseUrl}/content/`,
      },
      {
        title: 'Slider Images',
        link: `${absBaseUrl}/slider-images/`,
      },
      {
        title: 'Amenities',
        link: `${absBaseUrl}/amenities/`,
      },
    ];

    let menuItems = [];
    menu.map((item) => {
      const {title, link, isExact} = item;
      return menuItems.push(
        <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
          <NavLink
            to={{
              pathname: link,
              state: {fromDashboard: true},
            }}
            exact={isExact}
            className="nav-link rounded-0"
            activeClassName="selected">
            {title}
          </NavLink>
        </li>,
      );
    });

    const imageSlider = () => (
      <div>
        <Switch>
          <Route
            render={(props) => <PropertySliderImageList roomType={this.props.roomType} {...props} isRoomType={true}/>}
          />
        </Switch>
      </div>
    );

    return (
      <div className="channel-manager-settings screen-container">
        <Helmet>
          <title>Booking Engine Settings</title>
        </Helmet>

        <div className={'row'}>
          <div className={'col col-lg-2 col-md-3 col-sm-12 mb-5'}>
            <ul className="nav nav-pills flex-md-column flex-sm-row border rounded mb-3">
              {menuItems}
            </ul>
          </div>
          <div className={'col-lg-10 col-md-9 col-sm-12'}>
            <Switch>
              <Route path={baseUrl} exact component={RoomTypeBookingEngineOverview}/>
              <Route path={`${baseUrl}/content`} exact component={RoomTypeBookingEngineContent}/>
              <Route path={`${baseUrl}/content/manage`} exact component={RoomTypeBookingEngineManage}/>
              <Route path={`${baseUrl}/slider-images`} exact component={(imageSlider)}/>
              <Route
                path={`${baseUrl}/amenities/`} exact
                render={(props) => <PropertyBookingEngineAmenities  {...props} roomTypeAmenities={true}/>}
              />

            </Switch>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomTypeBookingEngine);
