/**
 * Guest Actions
 *
 */
import AppAPI from '../../lib/api';

/**
 * Set Guest
 */
export const setGuest = (guestData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof guestData === 'object') {
    dispatch({
      type: 'SET_GUEST',
      data: guestData,
    });
    return resolve(guestData);
  } else if (guestData) {
    // Fetch Guest Data
    return AppAPI.guest.get(guestData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_GUEST',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Guest
 */
export const updateGuest = (guestData) => setGuest(guestData);

/**
 * Remove Guest
 */
export function unSetGuest () {
  return (dispatch) => {
    dispatch({
      type: 'SET_GUEST',
      data: null,
    });
  };
}
