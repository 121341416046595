/**
 *
 * RoomView
 * overview of room
 */
import { faBroom, faChevronCircleDown, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Moment from 'moment';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PageHeader from '../../components/dashboard/PageHeader';
import Error from '../../components/general/Error';
// Components
import Loading from '../../components/general/Loading';
import ModelListView from '../../components/general/ModelListView';
import { Constants } from '../../constants';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import HousekeepingRoomListView from './HousekeepingRoomListView';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class HousekeepingView extends Component {
  static componentName = 'RoomView';
  componentDidMount = () => {
    this.fetchInitData();
  };
  fetchInitData = (no_cache) => {
    this.setState({processing: true});
    AppAPI.roomapi.get('status-v2/' + (no_cache ? '?no_cache=true' : ''))
      .then((res) => {
        if (res.actions !== '') {
          this.setState({
            loading: false,
            time: res.time,
            roomData: res.rooms ? res.rooms : {},
            roomTypesData: res.room_types ? res.room_types : {},
            processing: false
          });
        } else {
          this.setState({
            loading: false,
            error: true,
          });
        }
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: error,
          resultMsg: {error},
        });
      });
  };

  renderRow = (data, key) => {
    return (
      <div key={key} className={'col-sm-6 col-lg-4 col-6 p-3'}>
        <HousekeepingRoomListView data={data} history={this.props.history}/>
      </div>
    );
  };

  renderPopUpRow = (data, key) => {
    return (
      <div key={key} className={'col-sm-6 col-lg-4 col-6 p-3'}>
        <HousekeepingRoomListView data={data} history={this.props.history}/>
      </div>
    );
  };

  render = () => {
    const {loading, error, rooms, roomData, processing} = this.state;
    const {property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;


    const occupancyPercent = AppUtil.calculatePercentage(
      (roomData.filter(item => item.availability_status === Constants.ROOM.OCCUPIED).length),
      roomData.length
    );

    const serviceRoomPercent = AppUtil.calculatePercentage(
      (roomData.filter(item => item.service_status === Constants.ROOM.CLEAN).length),
      roomData.length
    );

    return (
      <div className="room-view screen-container">
        <Helmet>
          <title>House keeping Overview</title>
        </Helmet>

        <PageHeader
          history={this.props.history} title={'House Keeping'}
          description={property.name + ' room(s) status at ' + AppUtil.formatDateTime(Moment(), 'datetime')}/>

        <div className="row">
          <div className="col-lg-8 col-sm-12 mb-5 col-md-8 col-sm-12">

            <div className={'row mb-3'}>
              <div className={'col-6'}>
                <div className={'card'}>
                  <div className="card-body">
                    <div className={'row'}>
                      <div
                        className={'col a-class'}
                        onClick={() => this.setState({
                          InstantListView: roomData,
                          InstantListViewTitle: 'Total Room(s)',
                          showInstantListView: true,
                        })}
                      >
                        <small>Total Rooms</small>
                        <h4 className={'card-title mb-0 pb-0'}>
                          {(!_.isEmpty(roomData) && !processing) ? roomData.length : '...'}
                        </h4>
                      </div>
                      <div
                        className={'col a-class'}
                        onClick={() => this.setState({
                          InstantListView: roomData.filter(item => item.availability_status === Constants.ROOM.OCCUPIED),
                          InstantListViewTitle: 'Occupied Room(s)',
                          showInstantListView: true,
                        })}
                      >
                        <small>Occupied</small>
                        <h4 className={'card-title mb-0 pb-0 blue-cl'}>
                          {(!_.isEmpty(roomData) && !processing) ? roomData.filter(item => item.availability_status === Constants.ROOM.OCCUPIED).length : '...'}
                        </h4>
                      </div>
                    </div>
                    <div className="progress mt-3">
                      <div
                        className="progress-bar"
                        style={{width: `${occupancyPercent}%`}}
                        aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"/>
                    </div>
                    <div className={'row'}>
                      <div className={'col-10'}>
                        <p className={'text-muted mb-0 mt-1 small'}>
                          Room(s)
                          : {roomData.filter(item => item.availability_status === Constants.ROOM.OCCUPIED).length} occupied
                          out of {roomData.length} room(s).
                        </p>
                      </div>
                      <div>
                        <p className={'text-muted mb-0 mt-1 small'}>{occupancyPercent}%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'col-6'}>
                <div className={'card'}>
                  <div className="card-body">
                    <div className={'row'}>
                      <div
                        className={'col a-class'}
                        onClick={() => this.setState({
                          InstantListView: roomData.filter(item => item.service_status === Constants.ROOM.CLEAN),
                          InstantListViewTitle: 'In Service Room(s)',
                          showInstantListView: true,
                        })}
                      >
                        <small>In Service Rooms</small>
                        <h4 className={'card-title mb-0 pb-0 green-cl'}>
                          {(!_.isEmpty(roomData) && !processing) ? roomData.filter(item => item.service_status === Constants.ROOM.CLEAN).length : '...'}
                        </h4>
                      </div>
                      <div
                        className={'col a-class'}
                        onClick={() => this.setState({
                          InstantListView: roomData.filter(item => item.service_status !== Constants.ROOM.CLEAN),
                          InstantListViewTitle: 'Service Required Room(s)',
                          showInstantListView: true,
                        })}
                      >
                        <small>Service Required</small>
                        <h4 className={'card-title mb-0 pb-0 yellow-cl'}>
                          {(!_.isEmpty(roomData) && !processing) ? roomData.filter(item => item.service_status !== Constants.ROOM.CLEAN).length : '...'}
                        </h4>
                      </div>
                    </div>
                    <div className="progress mt-3 yellow-bg">
                      <div
                        className="progress-bar green-bg"
                        style={{width: `${serviceRoomPercent}%`}}
                        aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"/>
                    </div>
                    <div className={'row'}>
                      <div className={'col-10'}>
                        <p className={'text-muted mb-0 mt-1 small'}>
                          Room(s)
                          : {roomData.filter(item => item.service_status !== Constants.ROOM.CLEAN).length} room(s)
                          require attention.
                        </p>
                      </div>
                      <div>
                        <p className={'text-muted mb-0 mt-1 small'}>{serviceRoomPercent}%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={'row mb-3'}>
              <div className={'col-6'}>
                <div className={'row'}>
                  <div className={'col a-class'}>
                    <div
                      className={'card bg-danger text-light a-class'}
                      onClick={() => this.setState({
                        InstantListView: roomData.filter(item => item.maintenance_status === Constants.ROOM.IN_MAINTENANCE),
                        InstantListViewTitle: 'Maintenance Room(s)',
                        showInstantListView: true,
                      })}
                    >
                      <div className="card-body">
                        <small>Maintenance</small>
                        <h6 className={'card-title mb-0 pb-0'}>
                          {(!_.isEmpty(roomData) && !processing) ? roomData.filter(item => item.maintenance_status === Constants.ROOM.IN_MAINTENANCE).length : '...'}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className={'col a-class'}>
                    <div
                      className={'card a-class'}
                      onClick={() => this.setState({
                        InstantListView: roomData.filter(item => item.maintenance_status === Constants.ROOM.MAINTENANCE_REQUESTED),
                        InstantListViewTitle: 'Maintenance Requested Room(s)',
                        showInstantListView: true,
                      })}
                    >
                      <div className="card-body">
                        <small>Maintenance Req</small>
                        <h6 className={'card-title orange-cl  mb-0 pb-0'}>
                          {(!_.isEmpty(roomData) && !processing) ? roomData.filter(item => item.maintenance_status === Constants.ROOM.MAINTENANCE_REQUESTED).length : '...'}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {rooms && rooms.length > 0 &&
            <div>
              <div className="spacer-20"/>
              <div
                className={'border p-2 text-center anchor mt-3'} data-toggle="collapse" href="#Action"
                role="button" aria-expanded="false" aria-controls="Action">
                <h6 className={'mb-0'}>
                  <FontAwesomeIcon
                    onClick={() => this.fetchInitData(true)}
                    icon={faChevronCircleDown} size={'sm'} className={'mx-2 green-cl float-left mt-1'}/>
                  View room(s) needing attention
                  <FontAwesomeIcon
                    icon={faChevronCircleDown} size={'sm'} className={'mx-2 green-cl float-right mt-1'}/>
                </h6>
              </div>
              <div className={'py-2 border collapse p-2'} id={'Action'}>
                <div className="row">
                  {rooms.map((data, i) => (
                    this.renderRow(data, i)
                  ))}
                </div>
              </div>
            </div>
            }

            <div className="spacer-20"/>
            <div className={'row'}>
              <div className="col-lg-6 col-md-12">
                <Link className="btn btn-lg btn-block btn-success" to="/house-keeping/list">
                  <FontAwesomeIcon icon={faList} size={'sm'} className={'mr-2'}/> Room List
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 mb-5">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className="" to="/house-keeping/list">
                  <FontAwesomeIcon icon={faList} size={'sm'} className={'mr-2'}/> Room List
                </Link>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className="" to="/house-keeping/service-request/list">
                  <FontAwesomeIcon icon={faBroom} size={'sm'} className={'mr-2'}/> Service Request List
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <ModelListView
          size={'lg'}
          renderRow={this.renderPopUpRow}
          listData={this.state.InstantListView}
          show={this.state.showInstantListView}
          title={this.state.InstantListViewTitle}
          onHide={() => this.setState({showInstantListView: false})}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: true,
      processing: false,
      roomTypesData: {},
      InstantListView: [],
      showInstantListView: false,
      InstantListViewTitle: 'Room(s)'
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HousekeepingView);
