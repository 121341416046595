/**
 * Availability details
 *  availability details
 */
import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
// Components
import AvailabilityDateUpdate from './AvailabilityDateUpdate';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import BookingRoomList from '../../bookingroom/BookingRoomList';
import {faSync, faTimes} from '@fortawesome/free-solid-svg-icons';
import AvailabilityHistoryList from '../AvailabilityHistoryList';
import DayAvailabilityOverView from '../components/AvailabilityDateOverView';

/* Component ==================================================================== */

class AvailabilityDateDetails extends Component {
  static componentName = 'AvailabilityDateDetails';

  static propTypes = {
    match: PropTypes.object,
    property: PropTypes.object,
    selectedTab: PropTypes.string,
    roomTypeData: PropTypes.object,
    updateResponse: PropTypes.func,
    availabilityDetails: PropTypes.object,
  };

  static defaultProps = {
    selectedTab: 'overview',
  }


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        selectedTab: 'overview',
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  fetchData = () => {
    const {availabilityDetails} = this.props;
    if (availabilityDetails.id){
      this.setState({spin: true});
      AppAPI.availabilityapi.get(`availability/${availabilityDetails.hash}/`)
        .then((res) => {
          this.setState({spin: false});
          if (this.props.updateResponse) {
            this.props.updateResponse(res, false);
          }
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  }

  updateAvailabilityDate = (payload) => {
    const {availabilityDetails} = this.props;
    if (payload){
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        payload.date = availabilityDetails.date;
        payload.room_type = availabilityDetails.room_type_id;

        let appApi;

        if (availabilityDetails.hash){
          appApi = AppAPI.availabilityapi.patch(`availability/${availabilityDetails.hash}/`, payload);
        } else {
          appApi = AppAPI.availabilityapi.post('availability/', payload);
        }

        appApi.then((res) => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              if (this.props.updateResponse) {
                this.props.updateResponse(res);
              }
              setTimeout(() => {
                this.setState({showAvailabilityDateUpdate: false, resultMsg: {success: ''}});
              }, 300);
            });
        })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  }

  availabilityDateEdit = (editType, channelType='channel_manager') => {
    this.setState({showAvailabilityDateUpdate: true, availabilityDateUpdateEditType: editType, availabilityDateUpdateChannelType:channelType});
  }

  render = () => {
    const {show, availabilityDetails, roomTypeData} = this.props;
    const {spin} = this.state;
    const currentSelectedTab = this.state.selectedTab || this.props.selectedTab;
    if (!show) return <React.Fragment/>;

    const menu = [
      {
        title: 'Overview',
        key: 'overview',
      },
    ];

    if (!_.isEmpty(availabilityDetails)) {
      menu.push({
        title: 'Booking(s)',
        key: 'bookings',
      });
      if (availabilityDetails.id && availabilityDetails.future) {
        menu.push({
          title: 'History',
          key: 'history',
        });
      }
    }
    let menuItems = [];
    menu.map((item) => {
      const {title, key} = item;
      return menuItems.push(
        <li className={'nav-item'} role="presentation" key={`menu-item-${title}`}>
          <div
            onClick={() => this.setState({selectedTab: key})}
            className={`nav-link rounded-0 a-class ${key === currentSelectedTab && 'active'}`}
          >
            {title}
          </div>
        </li>
      );
    });


    let content;

    switch (currentSelectedTab) {
    case 'bookings':
      content = <BookingRoomList
        roomTypeId={availabilityDetails.room_type_id}
        disableFilters={true}
        bookingDate={availabilityDetails.date}
        {...this.props}
      />;
      break;
    case 'history':
      content = <AvailabilityHistoryList
        {...this.props}
        availabilityHash={availabilityDetails.hash}
      />;
      break;
    case 'overview':
    default:
      content = <DayAvailabilityOverView
        roomTypeData={roomTypeData}
        availabilityDetails={availabilityDetails}
        availabilityDateEdit={this.availabilityDateEdit}
      />;
    }

    const roomType = !_.isEmpty(roomTypeData) ? roomTypeData[availabilityDetails.room_type_id] : {};

    return (
      <Modal
        {...this.props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <button className={`btn btn-sm mr-2 ${spin && 'btn-outline-secondary'}`} onClick={()=>this.fetchData()}>
              <FontAwesomeIcon spin={spin} icon={faSync} size={'sm'}/>
            </button>
            {(availabilityDetails && availabilityDetails.future) ? 'Availability' : 'Occupancy'} Details
            of {availabilityDetails ? roomType.name + ' on ' + AppUtil.formatDateTime(availabilityDetails.date, 'date') : ''}
          </Modal.Title>
        </Modal.Header>
        <React.Fragment>
          <Modal.Body>
            <ul className="nav nav-tabs mb-4">
              {menuItems}
            </ul>
            {content}
            {availabilityDetails.time &&
              <p className={'small text-muted mb-0'}>Last updated at {AppUtil.formatDateTime(availabilityDetails.time)} / {availabilityDetails.hash}</p>
            }
          </Modal.Body>
          <Modal.Footer className={'d-block'}>
            <div className={'row'}>
              <div className={'col'}>
                <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                  <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                </button>
              </div>
            </div>
          </Modal.Footer>
        </React.Fragment>
        <AvailabilityDateUpdate
          roomType={roomType}
          show={this.state.showAvailabilityDateUpdate}
          editType={this.state.availabilityDateUpdateEditType}
          channelType={this.state.availabilityDateUpdateChannelType}
          availabilityDetails={availabilityDetails}
          updateAvailabilityDate={this.updateAvailabilityDate}
          resultMsg={this.state.resultMsg}
          onHide={()=>this.setState({
            showAvailabilityDateUpdate: false,
          })}
        />
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: true,
      showAvailabilityDateUpdate: false,
      availabilityChartData: [],
    };
  }
}

/* Export Component ==================================================================== */
export default AvailabilityDateDetails;
