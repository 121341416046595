/**
 *
 * Property Tax Details
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants} from '../../../constants';

// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';

// Actions
import * as PropertyActions from '../../../redux/property/actions';
import {History} from '../../../components/ui';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  taxclass: state.property.taxclass,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPropertyTax: PropertyActions.setPropertyTax,
};

/* Component ==================================================================== */
class PropertyTaxClassSettings extends Component {
  static componentName = 'PropertyTaxClassSettings';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    if (this.props.match.params.propertyTaxClassId) {
      this.props.setPropertyTax(this.props.match.params.propertyTaxClassId)
        .then(() => {
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };
  render = () => {

    const {loading, error} = this.state;
    const {taxclass} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    return (
      <div className="room-type-details screen-container ">
        <Helmet>
          <title>Property Tax Settings</title>
        </Helmet>


        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'Tax Class Details'}
          description={`Ref no: ${taxclass.ref_no}`}
        />


        <div className={'row'}>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <table className={'table table-bordered'}>
                  <tbody>
                    <tr>
                      <td className={'w-50'}>Name</td>
                      <td><strong>{taxclass.name}</strong></td>
                    </tr>
                    <tr>
                      <td className={'w-50'}><strong>Slab Type</strong></td>
                      <td>{taxclass.tax_slab_display}</td>
                    </tr>
                    {taxclass.tax_slab === Constants.TAX_SLAB.FLAT &&
                     <tr>
                       <td className={'w-50'}><strong>Value</strong></td>
                       <td>
                         <strong>{taxclass.tax} {(taxclass.tax_type === Constants.TAX_TYPE.PERCENTAGE ? '%' : taxclass.currency)}</strong>
                       </td>
                     </tr>
                    }
                    <tr>
                      <td className={'w-50'}>Description</td>
                      <td>{taxclass.description || '...'}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                {!_.isEmpty(taxclass.tax_slab_data) &&
                 <div>
                   <table className={'table table-bordered'}>
                     <thead>
                       <tr>
                         <th colSpan={3} className={'text-center'}>
                           Tax Values
                         </th>
                       </tr>
                       <tr>
                         <th>Start Price</th>
                         <th>End Price</th>
                         <th>Tax</th>
                       </tr>
                     </thead>
                     <tbody>
                       {taxclass.tax_slab_data.map((data, i) => (
                         <tr key={i}>
                           <td>{`${data.start_range} ${taxclass.currency}`}</td>
                           <td>{data.end_range ? `${data.end_range} ${taxclass.currency}` : '...'}</td>
                           <td>{`${data.tax} ${(taxclass.tax_type === Constants.TAX_TYPE.PERCENTAGE ? '%' : taxclass.currency)}`}</td>
                         </tr>
                       ))}
                     </tbody>
                   </table>
                 </div>
                }
              </div>
            </div>
            <History object={taxclass}/>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyTaxClassSettings);