/**
 *
 * Reports
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import Moment from 'moment';
// Libs
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PageHeader from '../../../components/dashboard/PageHeader';
import {Link} from 'react-router-dom';
import {faBed, faCalendarCheck, faKey, faUser} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class Reports extends Component {
  static componentName = 'Reports';

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
  };


  render = () => {
    const {loading, error} = this.state;
    const {property} = this.props;

    if (!property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className={'container ml-0'}>
        <div className="dashboard screen-container">
          <Helmet>
            <title>Report</title>
          </Helmet>

          <PageHeader title={'Report'} description={'Export or view reports'}/>


          <div className="spacer-20"/>

          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-12 col-md-6 mb-3">
                  <ul className="list-group">
                    <li className="list-group-item">
                      <strong>Reports</strong>
                    </li>
                    <li className="list-group-item">
                      <Link
                        to={'/reports/list'}
                        className="btn btn-outline-primary btn-block">
                        <FontAwesomeIcon icon={faCalendarCheck} size={'sm'} className={'mr-2'}/> All Reports
                      </Link>
                    </li>
                    <li className="list-group-item">
                      <Link
                        to={'/reports/e/reports/list'}
                        className="btn btn-outline-primary btn-block">
                        <FontAwesomeIcon icon={faCalendarCheck} size={'sm'} className={'mr-2'}/> Reports ( Escalated Permission )
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <ul className="list-group mb-5">
                    <li className="list-group-item">
                      <strong>Analytics</strong>
                    </li>
                    <li className="list-group-item">
                      <Link
                        to={'/analytics/booking'}
                        className="btn btn-outline-primary btn-block">
                        <FontAwesomeIcon icon={faCalendarCheck} size={'sm'} className={'mr-2'}/> Booking
                      </Link>
                    </li>
                  </ul>
                  <ul className="list-group">
                    <li className="list-group-item">
                      <strong>Reports (moved to front desk)</strong>
                    </li>
                    <li className="list-group-item">
                      <Link
                        to={'/reports/booking-report'}
                        className="btn btn-outline-primary btn-block">
                        <FontAwesomeIcon icon={faBed} size={'sm'} className={'mr-2'}/> Booking Report
                      </Link>
                    </li>
                    <li className="list-group-item">
                      <Link
                        to={'/reports/guest-report'}
                        className="btn btn-outline-primary btn-block">
                        <FontAwesomeIcon icon={faUser} size={'sm'} className={'mr-2'}/> Guest Report
                      </Link>
                    </li>
                    <li className="list-group-item">
                      <Link
                        to={'/reports/booking-room-report'}
                        className="btn btn-outline-primary btn-block">
                        <FontAwesomeIcon icon={faKey} size={'sm'} className={'mr-2'}/> Room Report
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      data: [],
      date: Moment().startOf('day'),
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
