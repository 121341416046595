/**
 * Manage Expense Refund
 *  manages refund add
 */
import _ from 'lodash';
import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';
import {Modal} from 'react-bootstrap';
// Consts and Libs
import AppAPI from '../../lib/api';
import {Constants, ErrorMessages, Strings} from '../../constants';
// Components
import {Alerts} from '../../components/ui';
import {Input, Select} from 'react-formik-ui';
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faTimes} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */

class ExpenseRefundAdd extends React.PureComponent {
  static componentName = 'ExpenseRefundAdd';

  static propTypes = {
    match: PropTypes.object,
    property: PropTypes.object,
    order: PropTypes.object,
    payment: PropTypes.object.isRequired,
    updateResponse: PropTypes.func,
  };

  addPayment = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});

      const payload = {
        total: credentials.total,
        payment_method: credentials.method,
        description: credentials.description,
        payment: this.props.payment.id,
      };

      AppAPI.expenseapi
        .post('expense-refund/', payload)
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            this.props.onCompleteHide();
            setTimeout(() => {
              if (this.props.updateResponse) {
                this.props.updateResponse(res);
              }
              this.setState({resultMsg: {success: ''}});
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {property, show, payment} = this.props;
    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    if (!show) return <React.Fragment/>;
    const formValidation = Yup.object().shape({
      method: Yup.string().required('Select payment method'),
      total: Yup.number().positive().required(),
    });

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Refund
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            total: payment ? payment.total_available_fund : 0,
            method: payment ? payment.payment_method : 0,
          }}
          validationSchema={formValidation}
          onSubmit={values => this.addPayment(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <Form className={'form-group'}>
                      <div className="form-row">
                        <div className="col-lg-6 col-md-6 form-group">
                          <Input
                            name='total'
                            label={'Total (' + property.currency + ')'}
                            className={'form-control'}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 form-group">
                          <Select
                            className={'form-control rounded-right-0'}
                            name='method'
                            label={'Payment Method'}
                            placeholder='Select an Option'
                            options={Constants.PAYMENT_METHODS_INPUTS}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-lg-12 form-group">
                          <Input
                            name='description'
                            label={'Description (Optional)'}
                            className={'form-control'}
                          />
                        </div>
                      </div>
                    </Form>
                    {(payment && !_.isEmpty(payment.refunds)) &&
                     <div className={'alert alert-info'}>
                       <p className={'mb-0'}>{Strings.paymentRefundAdd}</p>
                     </div>
                    }
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <Alerts
                  status={resultMsg.status}
                  success={resultMsg.success}
                  error={resultMsg.error}
                />
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <button
                      type="submit" onClick={formikProps.handleSubmit} className={'btn btn-success btn-lg btn-block'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add Refund
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default ExpenseRefundAdd;
