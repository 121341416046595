/**
 *
 * RefundList
 * List Refunds
 */
import queryString from 'query-string';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import Moment from 'moment';
// Consts and Libs
import AppWebUtil from '../../lib/webUtils';
import AppUtil from '../../lib/util';
import AppAPI from '../../lib/api';
import {Constants, Filters, Strings} from '../../constants';
// Components
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import SearchBar from '../../components/forms/SearchBar';
import FilterDate from '../../components/dashboard/FilterDate';
import FilterButton from '../../components/dashboard/FilterButton';
import ExportButton from '../../components/dashboard/ExportButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLongArrowAltRight, faMoneyBillWave, faSync} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';
import _ from 'lodash';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class RefundList extends Component {
  static componentName = 'RefundList';

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };

  componentDidMount = () => {
    let urlParams = {}, startDate = null, endDate = null;

    switch (this.getQueryParams('payment_status')) {
    case 'refunded':
      urlParams['payment_status'] = Constants.REFUND_STATUS.PAID;
      break;
    case 'not-refunded':
      urlParams['payment_status'] = Constants.REFUND_STATUS.NOTPAID;
      urlParams['void'] = false;
      break;
    case 'cancelled':
      urlParams['payment_status'] = Constants.REFUND_STATUS.CANCELLED;
      break;
    default:
      break;
    }

    switch (this.getQueryParams('payment_method')) {
    case 'cash':
      urlParams['payment_method'] = Constants.PAYMENT_METHODS.CASH;
      break;
    case 'cheque':
      urlParams['payment_method'] = Constants.PAYMENT_METHODS.CHECK;
      break;
    case 'card':
      urlParams['payment_method'] = Constants.PAYMENT_METHODS.CARD;
      break;
    case 'online-payment':
      urlParams['payment_method'] = Constants.PAYMENT_METHODS.ONLINE_PAYMENT;
      break;
    case 'bank-transfer':
      urlParams['payment_method'] = Constants.PAYMENT_METHODS.BANK_TRANSFER;
      break;
    case 'other':
      urlParams['payment_method'] = Constants.PAYMENT_METHODS.OTHER;
      break;
    default:
      break;
    }

    if (this.getQueryParams('start_date')) {
      startDate = Moment(String(this.getQueryParams('start_date')));
    }
    if (this.getQueryParams('end_date')) {
      endDate = Moment(String(this.getQueryParams('end_date')));
    }

    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('start_date'), this.getQueryParams('end_date'), 'created_date');

    if (this.props.guestId) {
      urlParams['guest_data'] = this.props.guestId;
    }

    if (this.props.corporateId) {
      urlParams['corporate_data'] = this.props.corporateId;
    }

    this.setState({loading: false, urlParams, startDate, endDate});
  };

  fetchData = (page = null, callback, searchText, download) => {
    const urlParams = {...this.state.urlParams};
    const {pos, event} = this.props;

    let baseUrl = 'refunds-v2', apiUrl;
    if (page) {
      urlParams['cursor'] = page;
    }

    if (searchText) {
      this.setState({searching: true, clear: true});
      baseUrl = 'refund-search';
      urlParams['search'] = searchText;
      if (page){
        urlParams['page'] = page;
      }
    }

    if (download) {
      urlParams['download'] = download.file;
      urlParams['all_data'] = true;
      this.setState({fileDownload: true});
    }

    if (pos){
      apiUrl = AppAPI.posapi;
      baseUrl = 'pos-refund-v2';
      urlParams['pos'] = pos.hash;
      if (searchText) { baseUrl = 'pos-refund-search';}
    } else if (event) {
      apiUrl = AppAPI.eventapi;
      baseUrl = 'event-refund';
      urlParams['event'] = event.hash;
      if (searchText) { baseUrl = 'event-refund-search';}
    } else {
      apiUrl = AppAPI.paymentapi;
    }

    apiUrl.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (download) {
          let fileName = `Refund-Report-${AppUtil.formatDateTime(Moment(), 'short')}.${download.file}`;
          AppWebUtil.downloadFile(res, fileName);
        } else {
          if (res.results.length > 0) {
            callback(
              res.results,
              {
                allLoaded: !res.next,
                nextParam: res.count ? AppUtil.getURLParam(res.next, 'page') : AppUtil.getURLParam(res.next, 'cursor')
              }
            );
          } else {
            callback();
          }
        }
        this.setState({
          searching: false,
          clear: false,
          refresh: false,
          loading: false,
          fileDownload: false,
          page: page,
        });
      })
      .catch((err) => {
        if (callback) {
          callback([], {allLoaded: true});
        }
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, refresh: false, loading: false, fileDownload: false, error});
      });
  };

  renderRow = (data, key) => {
    return (
      <tr className={'tr-align-middle'} key={key}>
        <td className={'data-table-cell-md'}>
          <Link className={'small'} to={`/payment/refund/${data.hash}`}>{data.ref_no}</Link>
        </td>
        <td className={'data-table-cell-md text-left'}><strong>{`${data.currency} ${data.total}`}</strong></td>
        <td className={'data-table-cell-lg text-left'}>
          <span className={'badge ' + (AppUtil.paymentStatusColor(data.payment_status))}>
            {data.payment_status_display}
          </span>
        </td>
        <td className={'data-table-cell-lg'}>{data.payment_method_display}</td>
        <td className={'data-table-cell-md'}>
          {(!_.isEmpty(data.attributes) && data.attributes.payment_hash )&&
            <Link to={`/payment/${data.attributes.payment_hash}/`}>
              {data.attributes.payment_ref_no}
            </Link>
          }
          {!_.isEmpty(data.payment) &&
            <Link to={`/payment/${data.payment.hash}/`}>
              {data.payment.ref_no}
            </Link>
          }
        </td>
        <td className={'data-table-cell-lg'}><small>{AppUtil.formatDateTime(data.created)}</small></td>
        <td className={'data-table-cell-md'}>
          <Link to={`/payment/refund/${data.hash}`}>
            Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
          </Link>
        </td>
      </tr>
    );
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={8}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faMoneyBillWave}/> No Refunds </h4>
            {Strings.refundsEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };

  tableHeader = () => {
    return (
      <tr>
        <th>Reference</th>
        <th className={'text-left'}>Amount</th>
        <th/>
        <th>Method</th>
        <th>Payment</th>
        <th>Date</th>
        <th/>
      </tr>
    );
  };

  render = () => {
    const {loading, error, clear, searching, results, fileDownload, refresh} = this.state;
    const {guestId, corporateId} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="refund-list screen-container">
        <Helmet>
          <title>Refund's List</title>
        </Helmet>

        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-9'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.setState({refresh: true})}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                </button>
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Payment Status'}
                  data={Filters.refundStatus}
                  selectKey={this.getQueryParams('payment_status')}
                  action={(data) => this.setQueryParams([{key: 'payment_status', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Payment Method'}
                  data={Filters.paymentMethod}
                  selectKey={this.getQueryParams('payment_method')}
                  action={(data) => this.setQueryParams([{key: 'payment_method', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data))}
                  size={'sm'}
                  maxDate={Moment().add(1, 'days')}
                />
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-3 text-center text-sm-right'}>
            <ExportButton
              size={'sm'}
              files={['csv']}
              action={(key) => this.fetchData(this.state.page, null, null, {file: key})}
              loading={fileDownload}
              disabled={!this.state.startDate}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <SearchBar
              clear={clear}
              refresh={refresh}
              results={results}
              searching={searching}
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
              searchFunction={(guestId || corporateId) ? null : this.fetchData}
              placeHolder="Refund ID"
              listVersion={'V2'}
              colSpan={7}
            />
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      searching: false,
      clear: false,
      results: [],
      urlParams: {},
      page: 1,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RefundList);
