/**
 * Room Type Actions
 *
 */

import AppAPI from '../../lib/api';

/**
 * Set Room Type
 */
export const setRoomType = (roomTypeData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof roomTypeData === 'object') {
    dispatch({
      type: 'SET_ROOM_TYPE',
      data: roomTypeData,
    });
    return resolve(roomTypeData);
  } else if (roomTypeData) {
    // Fetch Room Data
    return AppAPI.roomtype.get(roomTypeData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_ROOM_TYPE',
          data: res,setRoomType
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Room Type
 */
export const updateRoomType = (roomTypeData) => setRoomType(roomTypeData);

/**
 * Remove Room Type
 */
export function unSetRoomType() {
  return (dispatch) => {
    dispatch({
      type: 'SET_ROOM_TYPE',
      data: null,
    });
  };
}


/**
 * Set Room Type Package
 */
export const setRoomTypePackage = (roomTypePackageData, roomType) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof roomTypePackageData === 'object') {
    dispatch({
      type: 'SET_ROOM_TYPE_PACKAGE',
      data: roomTypePackageData,
    });
    return resolve(roomTypePackageData);
  } else if (roomTypePackageData) {
    // Fetch Room Data
    return AppAPI.roomtypepackage.get(roomTypePackageData + '/?room_type=' + roomType)
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_ROOM_TYPE_PACKAGE',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Room Type Package
 */
export const updateRoomTypePackage = (roomTypePackageData) => setRoomTypePackage(roomTypePackageData);

/**
 * Remove Room Type Package
 */
export function unSetRoomTypePackage() {
  return (dispatch) => {
    dispatch({
      type: 'SET_ROOM_TYPE_PACKAGE',
      data: null,
    });
  };
}

/**
 * Set Room Type Item
 */
export const setRoomTypeItem = (roomTypeItemData, roomType) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof roomTypeItemData === 'object') {
    dispatch({
      type: 'SET_ROOM_TYPE_ITEM',
      data: roomTypeItemData,
    });
    return resolve(roomTypeItemData);
  } else if (roomTypeItemData) {
    // Fetch Room Data
    return AppAPI.roomtypeitem.get(roomTypeItemData + '/?room_type=' + roomType)
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_ROOM_TYPE_ITEM',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Room Type Item
 */
export const updateRoomTypeItem = (roomTypeItemData) => setRoomTypeItem(roomTypeItemData);

/**
 * Remove Room Type Item
 */
export function unSetRoomTypeItem () {
  return (dispatch) => {
    dispatch({
      type: 'SET_ROOM_TYPE_ITEM',
      data: null,
    });
  };
}

