/**
 * Booking Edit Menu
 *  Manage date select and booking & order select
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import Moment from 'moment';
import _ from 'lodash';
// Consts and Libs
import AppUtil from '../../../lib/util';
import AppAPI from '../../../lib/api';
import {Constants, Strings} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import DateRangePicker from 'react-daterange-picker';
import DatesView from '../../../components/dashboard/DatesView';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import BookingRoomListView from '../../bookingroom/BookingRoomListView';
import {faArrowRight, faExclamationCircle, faTimes} from '@fortawesome/free-solid-svg-icons';


/* Component ==================================================================== */

class BookingEditMenu extends Component {
  static componentName = 'BookingEditMenu';

  static propTypes = {
    property: PropTypes.object,
    booking: PropTypes.object,
    bookingRooms: PropTypes.array,
    modType: PropTypes.number,
    show: PropTypes.bool,
    setLookup: PropTypes.func,
    disableCalender: PropTypes.bool,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
        selectedRooms: [],
      });
    }
    return true;
  }


  preFillData = () => {
    let checkin = Moment().startOf('day'),
      checkout = Moment().add(1, 'days'),
      minDate = Moment().subtract(14, 'hours'),
      lookup = true,
      maxDate = null,
      lockFocus = false,
      roomSelectionRequired = false,
      title = 'Booking Edit';

    const {modType} = this.props;
    if (this.props.booking) {
      checkin = Moment(this.props.booking.checkin);
      checkout = Moment(this.props.booking.checkout);

      if (modType === Constants.BOOKING_MODIFICATION_TYPES.ADD_ROOMS) {
        title = 'Add Rooms';
      } else if (
        modType === Constants.BOOKING_MODIFICATION_TYPES.EDIT_DATE ||
        modType === Constants.BOOKING_MODIFICATION_TYPES.EXTEND_DATE
      ) {
        roomSelectionRequired = true;
        lookup = false;
      }

      if (modType === Constants.BOOKING_MODIFICATION_TYPES.EXTEND_DATE) {
        lockFocus = 'endDate';
        minDate = checkin;
        title = 'Booking Extent / Shrink';
      } else {
        if (checkin < Moment().startOf('day')) {
          checkin = Moment().startOf('day');
        }
      }
    }

    this.setState(
      {
        init: false,
        loading: false,
        checkin: checkin,
        checkout: checkout,
        title,
        lookup,
        minDate,
        maxDate,
        lockFocus,
        roomSelectionRequired,
      },
    );
  };


  onSelect = (date) => {
    const {modType} = this.props;
    if (modType === Constants.BOOKING_MODIFICATION_TYPES.EXTEND_DATE) {
      this.setState({
        checkout: date.end,
      });
    } else {
      this.setState({
        checkin: date.start,
        checkout: date.end,
      });
    }
  };

  toggleDatePicker = () => {
    this.setState({showDatePicker: !this.state.showDatePicker});
  };

  performLookup = () => {
    const {modType, booking} = this.props;
    let checkin = Moment(this.state.checkin).format('YYYY-MM-DD');
    let checkout = Moment(this.state.checkout).format('YYYY-MM-DD');

    if (booking) {
      if (modType === Constants.BOOKING_MODIFICATION_TYPES.ADD_ROOMS && this.props.history) {
        this.props.history.push(`/booking/${booking.hash}/lookup/${checkin}/to/${checkout}`);
      } else {
        if (
          modType === Constants.BOOKING_MODIFICATION_TYPES.EDIT_DATE ||
          modType === Constants.BOOKING_MODIFICATION_TYPES.EXTEND_DATE
        ) {

          this.setState({
            resultMsg: {status: 'One moment...'},
          });

          let url = `booking/${booking.hash}/modify/`;
          AppAPI.bookingapi
            .post(url, {
              checkin: checkin,
              checkout: checkout,
              rooms_selected: this.state.selectedRooms,
              booking_extension: modType === Constants.BOOKING_MODIFICATION_TYPES.EXTEND_DATE,
            })
            .then(res => {
              this.setState({resultMsg: {success: 'Success'}}, () => {
                setTimeout(() => {
                  this.props.setLookup(res);
                  this.props.history.push(`/booking/${booking.hash}/modify/${checkin}/to/${checkout}`);
                }, 500);
              });
            })
            .catch(err => {
              const error = AppAPI.handleError(err);
              this.setState({resultMsg: {error}});
            });
        }
      }
    }
  };

  selectRoom = room => {
    const {modType} = this.props;
    let {selectedRooms} = this.state;
    let checkin = Moment(room.checkin);
    let checkout = Moment(room.checkout);
    selectedRooms = AppUtil.insertOrRemoveArray(selectedRooms, room.id);
    if (modType === Constants.BOOKING_MODIFICATION_TYPES.EXTEND_DATE) {
      this.setState({
        checkin,
        checkout,
        selectedRooms,
        lookup: selectedRooms.length > 0,
      });
    } else {
      this.setState({selectedRooms, lookup: selectedRooms.length > 0});
    }
  };

  render = () => {

    const {checkin, checkout, title, init, selectedRooms, lookup, roomSelectionRequired, resultMsg} = this.state;

    const {show, bookingRooms, disableCalender, modType} = this.props;

    if (!show) {
      return null;
    }

    if (show && init) {
      this.preFillData();
    }

    let availBookingRooms = bookingRooms;
    switch(modType) {
    case Constants.BOOKING_MODIFICATION_TYPES.ADD_ROOMS:
    default:
      availBookingRooms = [];
      break;
    case Constants.BOOKING_MODIFICATION_TYPES.EXTEND_DATE:
      availBookingRooms = bookingRooms.filter(item => (item.booking_status === Constants.BOOKING.ACTIVE));
      break;
    case Constants.BOOKING_MODIFICATION_TYPES.EDIT_DATE:
      availBookingRooms = bookingRooms.filter(item => (item.booking_status === Constants.BOOKING.UPCOMING));
      break;
    }

    return (
      <Modal
        {...this.props}
        size={roomSelectionRequired ? 'xl' : 'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            {title ? title : 'Booking Modify'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-4">
            <div className={'col-md-12 col-12-sm ' + (roomSelectionRequired ? 'col-lg-6' : 'col-lg-12')}>
              <DatesView checkout={checkout} checkin={checkin} onClick={this.toggleDatePicker}/>
              <div>
                <div className="bg-white">
                  <div className="text-center mt-2">
                    {disableCalender !== false &&
                     <DateRangePicker
                       className={'w-100'}
                       selectionType={'range'}
                       minimumDate={this.state.minDate}
                       maximumDate={this.state.maxDate}
                       value={Moment.range(checkin, checkout)}
                       onSelect={this.onSelect}
                       numberOfCalendars={2}
                     />
                    }
                  </div>
                </div>
              </div>
            </div>
            {roomSelectionRequired &&
            <div className={'col-md-12 col-12-sm col-lg-6'}>
              <h6>Booking Room(s)</h6>
              <p className={'text-secondary small'}>{Strings.bookingModifyLookupSelectBookings}</p>
              {_.isEmpty(availBookingRooms) ?
                <div className={'my-2 text-center border p-4'}>
                  <h6><FontAwesomeIcon icon={faExclamationCircle} className={'text-center'}/> No Room(s)</h6>
                  <p className={'text-secondary small mb-0'}>{Strings.bookingModifyEmpty}</p>
                </div>
                :
                <div className={'mt-3'}>
                  {availBookingRooms.map((data, key) => (
                    <BookingRoomListView
                      key={key}
                      data={data}
                      disabled={true}
                      selectable={true}
                      action={this.selectRoom}
                      history={this.props.history}
                      title={`Room ${data.room_number}`}
                      selected={selectedRooms.includes(data.id)}
                    />
                  ))}
                </div>
              }
            </div>
            }
          </div>
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          <div className={'px-3'}>
            <Alerts
              error={resultMsg.error}
              status={resultMsg.status}
              success={resultMsg.success}
            />
          </div>
          <div className={'row'}>
            <div className={'col'}>
              <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
              </button>
            </div>
            <div className={'col'}>
              <button
                disabled={!lookup} className="btn btn-success btn-block btn-lg" onClick={() => this.performLookup()}>
                Look Up <FontAwesomeIcon className={'white-cl'} icon={faArrowRight} size={'sm'}/>
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: true,
      lookup: true,
      lookupResults: {},
      selectedRooms: [],
      showDatePicker: false,
      roomSelectionRequired: false,
      checkin: Moment().startOf('day'),
      checkout: Moment().add(1, 'days'),
      minDate: Moment().subtract(14, 'hours'),
      maxDate: null,
    };
  }
}

/* Export Component ==================================================================== */
export default BookingEditMenu;
