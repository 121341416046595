/**
 *
 * POS Table List
 */
import _ from 'lodash';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import React, {Component} from 'react';
// Consts and Libs
import AppAPI from '../../lib/api';

// Components
import {Constants, Filters, Strings} from '../../constants';
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';

import {Alerts} from '../../components/ui';
import POSItemDetails from './models/POSItemDetails';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSync, faTable} from '@fortawesome/free-solid-svg-icons';
import POSTableListView from './components/POSTableListView';
import FilterButton from '../../components/dashboard/FilterButton';
import SelectorButton from '../../components/dashboard/SelectorButton';
// Actions
import * as POSActions from '../../redux/pos/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  posDetails: state.pos.posDetails,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPOSDetails: POSActions.setPOSDetails,
};

/* Component ==================================================================== */
class POSTableList extends Component {
  static componentName = 'POSTableList';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
  };

  fetchInitData = () => {
    this.setState({loading: true});
    this.props.setPOSDetails(this.props.match.params.POSId)
      .then(() => (
        this.setState({
          loading: false,
        })
      ))
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  }

  handleInputChange = (value) => {
    this.setState({value: value, searching: true});
    this.onChangeDebounced(value);
  };

  onChangeDebounced = (value) => {
    this.setState({searchText: value, searching: false});
  };


  render = () => {

    const {loading, error, resultMsg, tableViewType, tableStatusType, searching, searchText} = this.state;
    const {posDetails} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    let tables = posDetails.tables;

    if (tableStatusType === 'occupied') {
      tables = tables.filter(item => (item.availability_status === Constants.POS_TABLE_STATUS.OCCUPIED));
    } else if (tableStatusType === 'empty') {
      tables = tables.filter(item => (item.availability_status === Constants.POS_TABLE_STATUS.AVAILABLE));
    }

    if (searchText) {
      tables = tables.filter((el) => {
        let searchValue = el.name.toLowerCase();
        return searchValue.indexOf(searchText.toLowerCase()) !== -1;
      });
    }

    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Table(s) list</title>
        </Helmet>

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h4 className={'mb-4'}><FontAwesomeIcon icon={faTable} className={'mr-2'} size={'sm'}/> Table(s)</h4>

                <ul className="list-inline list-item-mb-1">
                  <li className="list-inline-item">
                    <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.fetchInitData()}>
                      <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                    </button>
                  </li>
                  <li className="list-inline-item">
                    <SelectorButton
                      title={'View Type'}
                      buttonList={Filters.tableViewSelector}
                      selectButtonKey={tableViewType}
                      action={(key) => this.setState({tableViewType: key})}
                    />
                  </li>
                  <li className="list-inline-item">
                    <FilterButton
                      title={'Table Status'}
                      data={Filters.tableStatusType}
                      selectKey={tableStatusType}
                      action={(data) => this.setState({tableStatusType: data.key})}
                      size={'sm'}
                    />
                  </li>
                </ul>

                <div className="form-row align-items-center mb-4">
                  <div className={searching ? 'col-sm-11' : 'col-sm-12'}>
                    <input
                      type="search"
                      className="form-control"
                      placeholder={'Search Table ( Table Name )'}
                      name="q"
                      autoComplete={'off'}
                      onChange={(data) => this.handleInputChange(data.target.value)}
                    />
                  </div>
                  <div className="col-auto">
                    {searching && <i className="fa fa-spinner"/>}
                  </div>
                </div>

                {(tableViewType === 'grid' && !_.isEmpty(tables)) ?
                  <div className={'row'}>
                    {tables.map((data, i) => (
                      <div key={i} className={'col-sm-4 col-md-3 col-6 p-3'}>
                        <POSTableListView
                          data={data} history={this.props.history}
                          action={() => {
                            this.props.history.push(`/point-of-sale/${posDetails.hash}/tables/${data.hash}/`);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  :
                  <React.Fragment>
                    <div className={'table-responsive mt-4'}>
                      <table className={'table border format-table'}>
                        <thead>
                          <tr>
                            <th colSpan="4" className="text-center">Table Information</th>
                            <th colSpan="3" className="text-center">POS Tab / Order Information</th>
                          </tr>
                          <tr>
                            <th>Table Name</th>
                            <th>Table Type</th>
                            <th>Occupancy Status</th>
                            <th>Operation Status</th>

                            <th>Tab (ID/Reference)</th>
                            <th>Occupancy</th>
                            <th>Served By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {_.isEmpty(tables) ?
                            <tr>
                              <td colSpan={7}>
                                <div className={'text-center table-list-empty'}>
                                  <h4><FontAwesomeIcon icon={faTable}/> No Table(s) </h4>
                                  {Strings.POSOverViewEmptyTable.map((data, i) => <p key={i}>{data}</p>)}
                                </div>
                              </td>
                            </tr>
                            :
                            <React.Fragment>
                              {tables.map((data, i) => (
                                <tr key={i}>
                                  <td>
                                    <Link to={`/point-of-sale/${posDetails.hash}/tables/${data.hash}/`}>
                                      {data.name}
                                    </Link>
                                  </td>
                                  <td>{data.table_shape_display}</td>
                                  <td>
                                    <span
                                      className={'badge ' + (data.availability_status === 1 ? 'badge-success' : 'badge-warning')}>
                                      {data.availability_status_display}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={'badge ' + (data.maintenance_status === 1 ? 'badge-success' : 'badge-danger')}>
                                      {data.maintenance_status_display}
                                    </span>
                                  </td>
                                  {!_.isEmpty(data.pos_order_data) ?
                                    <React.Fragment>
                                      <td>
                                        <Link
                                          to={`/point-of-sale/${posDetails.hash}/pos-order/${data.pos_order_data.hash}/`}>
                                          {data.pos_order_data.ref_no}
                                        </Link>
                                      </td>
                                      <td>{data.pos_order_data.occupancy}</td>
                                      <td>{!_.isEmpty(data.pos_order_data.attributes.serving_user) ? data.pos_order_data.attributes.serving_user : '...'}</td>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                      <td>...</td>
                                      <td>...</td>
                                      <td>...</td>
                                    </React.Fragment>
                                  }

                                </tr>
                              ))}
                            </React.Fragment>
                          }
                        </tbody>
                      </table>
                    </div>
                  </React.Fragment>
                }
              </div>
            </div>
          </div>
        </div>
        <POSItemDetails
          show={this.state.showPOSItemDetails}
          item={this.state.selectPOSItem}
          posDetails={posDetails}
          onHide={() => this.setState({showPOSItemDetails: false})}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.onChangeDebounced = _.debounce(this.onChangeDebounced, 10, {});

    this.state = {
      tableViewType: 'grid',
      error: false,
      loading: false,
      tableStatusType: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(POSTableList);
