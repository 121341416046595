/**
 *
 * Event Lookup Results
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {Constants} from '../../constants';


/* Component ==================================================================== */
class EventLookupResults extends Component {
  static componentName = 'EventLookupResults';
  static propTypes = {
    lookupResponse: PropTypes.object.isRequired,
    action: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    if (this.props.lookupResponse) {
      this.calculateAvailabilityChart();
    }
  };

  calculateAvailabilityChart = () => {
    const {lookupResponse} = this.props;
    const availabilityChart = {};
    const selectedChart = {};


    if (lookupResponse && lookupResponse.results) {
      lookupResponse.results.map(data => {
        let total_tickets = 0;
        let available_tickets = 0, ready_to_book = true;
        if (data.availability && data.availability.total_tickets) {
          total_tickets = data.availability.total_tickets;
          available_tickets = data.availability.available_tickets;
        }
        if (data.ticket.ticket_quantity_type === Constants.EVENT_TICKET_TYPE.LIMITED && available_tickets < 1) {
          ready_to_book = false;
        }
        availabilityChart[data.ticket_id] = {
          ticket_id: data.ticket_id,
          ticket: data.ticket,
          total_tickets: total_tickets,
          available_tickets: available_tickets,
          selected_ticket: ready_to_book ? 1 : 0,
          ready_to_book: ready_to_book,
          price: data.ticket ? data.ticket.base_price_effective : 0,
          total: data.ticket ? data.ticket.base_price_effective : 0,
        };
        return true;
      });
      this.setState({
        availabilityChart: availabilityChart,
        selectedChart: selectedChart,
      });
    }
    return selectedChart;
  };

  updateIndex = (i) => {
    let {selectedChart} = this.state;
    if (i === 0) {
      // Subtract
      if (selectedChart.selected_ticket > 1) {
        selectedChart.selected_ticket = selectedChart.selected_ticket > 0 ? selectedChart.selected_ticket - 1 : 0;
      } else if (selectedChart.selected_ticket < 2) {
        selectedChart = null;
      }
    } else if (i === 2) {
      // Add
      if (selectedChart.available_tickets > selectedChart.selected_ticket || selectedChart.available_tickets === 0) {
        selectedChart.selected_ticket = selectedChart.selected_ticket + 1;
      }
    }
    if (selectedChart) {
      selectedChart.total = selectedChart.selected_ticket * parseFloat(selectedChart.price);
    }

    this.setState({
      selectedChart: selectedChart,
    });
  };

  action = () => {
    const {selectedChart} = this.state;
    if (selectedChart && selectedChart.selected_ticket > 0) {
      this.props.action(selectedChart);
    }
  };

  render = () => {
    const {lookupResponse} = this.props;
    const {availabilityChart, selectedChart} = this.state;
    return (
      <div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12-sm">
            {lookupResponse && lookupResponse.results && lookupResponse.results.length > 0 &&
             <ul className={'list-unstyled'}>
               {lookupResponse.results.map((data, i) => (
                 <li key={i} className={'mb-3 border rounded p-3 cream-bg'}>
                   <div className={'row'}>
                     <div className={'col-10'}>
                       <h5>{data.name}</h5>
                       <ul className={'list-inline'}>
                         <li className={'list-inline-item'}>
                           <p className="mb-0 small">
                             Availability :
                             {(data.ticket && data.ticket.ticket_quantity_type === Constants.EVENT_TICKET_TYPE.LIMITED) ?
                               <React.Fragment>{data.availability.available_tickets} / {data.availability.total_tickets}</React.Fragment> :
                               'Unlimited'
                             }
                             <span
                               className={'link small ml-2 a-class'}
                               data-toggle="collapse"
                               data-target={'#idavailabilitybreakdown' + String(i)} aria-expanded="false"
                               aria-controls={'#idavailabilitybreakdown' + String(i)}>
                               <FontAwesomeIcon icon={faInfoCircle} size={'sm'}/> Details
                             </span>
                           </p>
                         </li>
                       </ul>

                       {data.ticket.alert_description &&
                        <p className={'small'}>{data.ticket.alert_description || 'N.A'}</p>
                       }
                       <button
                         className={'btn btn-outline-secondary btn-sm btn-block my-2'}
                         data-toggle="collapse"
                         data-target={'#iddescritption' + String(i)} aria-expanded="false"
                         aria-controls={'#iddescritption' + String(i)}>
                         More Information
                       </button>
                       <div className={'row collapse mt-2'} id={'iddescritption' + String(i)}>
                         <div className={'col-12'}>
                           <h6>Description</h6>
                           <p className={'small'}>{data.ticket.description || 'N.A'}</p>
                         </div>
                       </div>
                     </div>
                     <div className={'col-2 text-right'}>
                       <p className={'mb-0'}>
                         <strong>{lookupResponse.currency} {data.ticket.base_price_effective}</strong>
                       </p>
                       <p className={'small'}>
                         per ticket
                       </p>
                       {
                         selectedChart && selectedChart.ticket_id === data.ticket_id ?
                           <div>
                             <div className="btn-group btn-group-sm white-bg" role="group">
                               <button
                                 type="button" className="btn border red-cl"
                                 onClick={() => this.updateIndex(0, data.ticket_id)}>-
                               </button>
                               <button type="button" className="btn border">
                                 {selectedChart.selected_ticket}
                               </button>
                               <button
                                 type="button" className="btn border green-cl"
                                 onClick={() => this.updateIndex(2, data.ticket_id)}>+
                               </button>
                             </div>
                           </div>
                           :
                           <button
                             disabled={(selectedChart && selectedChart.ticket_id)}
                             type="button" className="btn btn-success btn-sm"
                             onClick={() => selectedChart && selectedChart.ticket_id ? null : this.setState({selectedChart: availabilityChart[data.ticket_id]})}>
                             Select
                           </button>
                       }

                     </div>
                   </div>
                   {(data.availability.availability_data_set && data.availability.availability_data_set.date) &&
                    <React.Fragment>
                      <span
                        className={'a-class'}
                        data-toggle="collapse"
                        data-target={'#idavailabilitybreakdown' + String(i)} aria-expanded="false"
                        aria-controls={'#idavailabilitybreakdown' + String(i)}
                      >
                        <small>Availability Breakdown</small>
                      </span>
                      <div className={'collapse'} id={'idavailabilitybreakdown' + String(i)}>
                        <table className={'table small table-sm table-striped text-center rounded bg-white border'}>
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Cancelled</th>
                              <th>In Booking</th>
                              <th>Booked</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{data.availability.availability_data_set.date}</td>
                              <td>{data.availability.availability_data_set.cancelled}</td>
                              <td>{data.availability.availability_data_set.blocked}</td>
                              <td>{data.availability.availability_data_set.booked}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </React.Fragment>
                   }
                 </li>
               ))}
             </ul>
            }

            <button
              className={'btn btn-block btn-lg btn-success'}
              disabled={!(selectedChart && selectedChart.ticket_id)}
              onClick={this.action}
            >
              <h5 className={'my-0 mt-1'}>
                {selectedChart && selectedChart.ticket_id ? `Book ticket (${lookupResponse.currency} ${selectedChart.total})` : 'Select a ticket'}
              </h5>
            </button>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      selectedRoom: [],
      selectedChart: null,
      availabilityChart: [],
      totalSelectedTicket: 0,
      overBooking: false,
    };
  }
}

export default EventLookupResults;
