/**
 *
 * OrderView
 * overview of orders
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {Link} from 'react-router-dom';
import Moment from 'moment';

// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import Colors from '../../theme/colors';
import { Constants } from '../../constants';

// Components
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import PageHeader from '../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import { faBarChart, faCheckCircle, faFile, faList, faSpinner, faUniversity } from '@fortawesome/free-solid-svg-icons';

import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class OrderView extends Component {
  static componentName = 'OrderView';
  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = (no_cache, loading= true) => {
    this.setState({loading: loading});
    AppAPI.orderapi.get('status-v2/' + (no_cache ? '?reset_cache=true' : ''))
      .then((res) => {
        let responseData  = {};
        if (!_.isEmpty(res.data)){
          responseData = {
            currency: res.data.currency,
            time: res.data.time,
            response: res.data,
          };
        }

        // Processing flag indicates
        if (res.processing) {
          setTimeout(()=>this.fetchInitData(false, false), 5000);
        }

        this.setState({
          ...responseData,
          loading: false,
          processing: res.processing || false
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: error,
          resultMsg: {error},
        });
      });
  };
  emptyView = () => {
    return (
      <tr>
        <td colSpan={6}>
          <div className={'text-center table-list-empty-sm'}>
            <h6><FontAwesomeIcon icon={faFile}/> No Order </h6>
          </div>
        </td>
      </tr>
    );
  };


  tableHeader = () => {
    return (
      <tr>
        <th className={'data-table-cell-md'}>Amount</th>
        <th className={'data-table-cell-sm'}/>
        <th className={'data-table-cell-sm'}>Source</th>
        <th className={'data-table-cell-sm'}/>
        <th/>
      </tr>
    );
  };

  renderRow = (data, key) => {
    return (
      <tr key={key}>
        <td><strong>{`${data.currency} ${data.total}`}</strong></td>
        <td className={'data-table-cell-md'}>
          <span className={'badge ' + (AppUtil.paymentStatusColor(data.order_status))}>
            {data.order_status_display}
          </span>
          {!data.lock && <span className={'badge badge-warning small ml-2'}>Open Order</span>}
        </td>
        <td>{data.order_source_display}</td>
        <td><Link to={`/order/${data.hash}`}><small>{data.ref_no}</small></Link></td>
      </tr>
    );
  };
  render = () => {
    const {loading, error, currency, response, chartValueType, processing} = this.state;
    const {property} = this.props;

    const chartCount = [], chartValue = [];

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const orderData = {
      'openOrder': {
        'count': 0,
        'total': 0,
      },
      'notPaidOrder': {
        'count': 0,
        'total': 0,
      },
      'partPaidOrder': {
        'count': 0,
        'total': 0,
      },
      'dateOrderPaid': {
        'count': 0,
        'total': 0,
      },
      'dateOrderNotPaid': {
        'count': 0,
        'total': 0,
      }
    };

    if (!_.isEmpty(response)) {
      const {orders_date_source_data, orders_open, orders_not_paid, orders_date_data} = response;
      if (!_.isEmpty(orders_date_source_data)) {
        orders_date_source_data.forEach((data) => {
          switch (data.order_source) {
          case Constants.ORDER_SOURCE.SYSTEM:
            chartCount.push({name: 'System', value: data.count, color: Colors.yellow});
            chartValue.push({name: 'System', value: data.total, color: Colors.yellow});
            break;
          case Constants.ORDER_SOURCE.BOOKING:
            chartCount.push({name: 'Booking', value: data.count, color: Colors.green});
            chartValue.push({name: 'Booking', value: data.total, color: Colors.green});
            break;
          case Constants.ORDER_SOURCE.POINT_OF_SALES:
            chartCount.push({name: 'Point of Sales', value: data.count, color: Colors.orange});
            chartValue.push({name: 'Point of Sales', value: data.total, color: Colors.orange});
            break;
          case Constants.ORDER_SOURCE.AMENDMENT:
            chartCount.push({name: 'Amendment', value: data.total, color: Colors.blue});
            chartValue.push({name: 'Amendment', value: data.total, color: Colors.blue});
            break;
          case Constants.ORDER_SOURCE.EXTERNAL:
            chartCount.push({name: 'External', value: data.count, color: Colors.red});
            chartValue.push({name: 'External', value: data.total, color: Colors.red});
            break;
          case Constants.ORDER_SOURCE.USER:
            chartCount.push({name: 'User', value: data.count, color: Colors.yellowLight});
            chartValue.push({name: 'User', value: data.total, color: Colors.yellowLight});
            break;
          case Constants.ORDER_SOURCE.EVENT:
            chartCount.push({name: 'Event', value: data.count, color: Colors.info});
            chartValue.push({name: 'Event', value: data.total, color: Colors.info});
            break;
          default:
            break;
          }
        });
      }

      if (!_.isEmpty(orders_date_data)) {
        orders_date_data.forEach((data) => {
          if (data.order_status === Constants.ORDER_STATUS.PAID) {
            orderData['dateOrderPaid']['count'] = data['count'];
            orderData['dateOrderPaid']['total'] = data['total'];
          }

          if (data.order_status === Constants.ORDER_STATUS.NOTPAID) {
            orderData['dateOrderNotPaid']['count'] = data['count'];
            orderData['dateOrderNotPaid']['total'] = data['total'];
          }
        });
      }

      if (!_.isEmpty(orders_open)) {
        orders_open.forEach((data) => {
          orderData['openOrder']['count'] = data['count'];
          orderData['openOrder']['total'] = data['total'];
        });
      }
      if (!_.isEmpty(orders_not_paid)) {
        orders_not_paid.forEach((data) => {
          orderData['notPaidOrder']['count'] = data['count'];
          orderData['notPaidOrder']['total'] = data['total'];
        });
      }
    }

    let chartData = (chartValueType === 'count' ? chartCount : chartValue);

    return (
      <div className="order-view screen-container">
        <Helmet>
          <title>Order Overview</title>
        </Helmet>

        <PageHeader
          history={this.props.history} title={'Order(s)'}
          description={property.name + ' order(s) status at ' + AppUtil.formatDateTime(Moment(), 'datetime') + '.'}/>

        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12 mb-5">
            <div>
              <div className={'row'}>
                <div className={'col-lg-6 col-sm-12 mb-3'}>
                  <h6 className={'small text-uppercase text-muted'}>Day Overview ({currency})</h6>
                  <div className={'card mb-3'}>
                    <div className="card-body">
                      <div className={'row'}>
                        <div
                          className={'col a-class'}
                          onClick={() => this.props.history.push(`/order/list?order_status=paid&start_date=${AppUtil.formatDateTime(Moment(), 'date-iso')}`)}
                        >
                          <small>Paid Order(s)</small>
                          <h6 className={'card-title mb-0 pb-0 green-cl'}>
                            {(!_.isEmpty(orderData) && !processing) ? orderData.dateOrderPaid.total : '...'}
                          </h6>
                        </div>
                        <div
                          className={'col a-class'}
                          onClick={() => this.props.history.push(`/order/list?order_status=not-paid&start_date=${AppUtil.formatDateTime(Moment(), 'date-iso')}`)}
                        >
                          <small>Unpaid Order(s)</small>
                          <h6 className={'card-title mb-0 pb-0 red-cl'}>
                            {(!_.isEmpty(orderData) && !processing) ? orderData.dateOrderNotPaid.total : '...'}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={'row'}>
                    <div
                      className={'col a-class'}
                      onClick={() => this.props.history.push('/order/list?order_status=not-paid')}
                    >
                      <h6 className={'small text-uppercase text-muted'}>Overall ({currency})</h6>
                      <div className={'card bg-danger text-light a-class'}>
                        <div className="card-body">
                          <small>Unpaid Order</small>
                          <h6 className={'card-title mb-0 pb-0'}>
                            {(!_.isEmpty(orderData) && !processing) ? orderData.notPaidOrder.total : '...'}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div
                      className={'col a-class'}
                      onClick={() => this.props.history.push('/order/list?order_status=open')}
                    >
                      <h6 className={'small text-uppercase text-muted'}>Overall ({currency})</h6>
                      <div className={'card bg-warning a-class'}>
                        <div className="card-body">
                          <small>Open Order</small>
                          <h6 className={'card-title mb-0 pb-0'}>
                            {(!_.isEmpty(orderData) && !processing) ? orderData.openOrder.total : '...'}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(response && response.last_updated) &&
                  <p className={'small text-secondary mt-3'}>
                    last updated at {AppUtil.formatDateTime(response.last_updated)}.
                  </p>
                  }
                </div>
                {!_.isEmpty(chartData) ?
                  <div className={'col-lg-6 col-md-12 mb-3'}>
                    <ResponsiveContainer height={210}>
                      <PieChart onMouseEnter={this.onPieEnter}>
                        <Pie
                          data={chartData}
                          innerRadius={0}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          <Cell fill={Colors.yellow}/>
                          <Cell fill={Colors.green}/>
                          <Cell fill={Colors.orange}/>
                          <Cell fill={Colors.blue}/>
                          <Cell fill={Colors.red}/>
                          <Cell fill={Colors.yellowLight}/>
                          <Cell fill={Colors.info}/>
                        </Pie>
                        <Tooltip/>
                        <Legend onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}/>
                      </PieChart>
                    </ResponsiveContainer>
                    <div className={'text-center mt-2'}>
                      <div className="btn-group btn-group-sm" role="group">
                        <button disabled={true} className={'btn btn-outline-secondary'}>Day Overview</button>
                        <button
                          type="button"
                          onClick={() => this.setState({chartValueType: 'count'})}
                          className={`btn ${chartValueType === 'count' ? 'btn-success' : 'btn-outline-secondary'}`}>
                          Count (Nos)
                        </button>
                        <button
                          type="button"
                          onClick={() => this.setState({chartValueType: 'value'})}
                          className={`btn ${chartValueType === 'value' ? 'btn-success' : 'btn-outline-secondary'}`}>
                          Value ({currency})
                        </button>
                      </div>
                    </div>
                  </div>
                  :
                  <div className={'col-lg-6 col-md-12 mb-3 d-flex border rounded'}>
                    <div className="align-self-center text-center mx-auto">
                      <FontAwesomeIcon
                        size={'2x'}
                        spin={processing}
                        className={'green-cl'}
                        icon={processing ? faSpinner : faCheckCircle}
                      />
                      <p className={'small'}>{processing ? '...' : 'No orders today.'}</p>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className="spacer-20"/>
            <div className={'row'}>
              <div className={'col-6'}>
                <Link className="btn btn-success btn-lg btn-block" to="/order/list">
                  <FontAwesomeIcon icon={faList} size={'sm'} className={'mr-2'}/> Order List
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group mb-5">
              <li className="list-group-item" onClick={() => this.fetchInitData(true)}>
                <small>Order List(s)</small>
              </li>
              <li className="list-group-item">
                <Link className="" to="/order/list">
                  <FontAwesomeIcon icon={faList} size={'sm'} className={'mr-2'}/> Order List
                </Link>
              </li>
              <li className="list-group-item">
                <Link className="" to="/order/list-tax-data">
                  <FontAwesomeIcon icon={faUniversity} size={'sm'} className={'mr-2'}/> Order List (Tax Breakup)
                </Link>
              </li>
            </ul>
            <ul className="list-group">
              <li className="list-group-item">
                <small>Reports / Analytics</small>
              </li>
              <li className="list-group-item">
                <Link className="" to="/order/reports/list">
                  <FontAwesomeIcon icon={faUniversity} size={'sm'} className={'mr-2'}/> Report(s)
                </Link>
              </li>
              <li className="list-group-item">
                <Link className="" to="/order/analytics/">
                  <FontAwesomeIcon icon={faBarChart} size={'sm'} className={'mr-2'}/> Analytics
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: null,
      response: {},
      loading: true,
      processing: false,
      chartValueType: 'count'
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderView);
