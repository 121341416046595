/**
 * Order Item List View
 *
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faPen} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */
class OrderItemListView extends Component {
  static componentName = 'Alerts';

  static propTypes = {
    lock: PropTypes.bool,
    data: PropTypes.object,
    onClick: PropTypes.func,
    currency: PropTypes.string,
    rowClassName: PropTypes.string,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    rowClassName: '',
    disabled: false,
  };

  action = (data) => {
    if (this.props.onClick && !this.props.disabled) {
      this.props.onClick(data);
    }
  };


  render() {
    const {data, currency, lock, rowClassName, disabled} = this.props;
    return (
      <React.Fragment>
        <tr className={rowClassName}>
          <td className={'w-10'}>
            <p className={'mb-0 small text-right'}>
              {data.quantity} <strong>x</strong>
              <br/> {data.quantity_type_display}
            </p>
          </td>
          <td className={'w-50'}>
            <strong>{data.description}</strong>
            {data.additional_description &&
             <p className={'small mb-0 text-muted'}>{data.additional_description}</p>
            }
            <p className={'mb-0 small'}>
              {(parseInt(data.sub_quantity) > 1 || parseInt(data.sub_quantity_type) !== 1) &&
               <React.Fragment>
                 {data.sub_quantity} {data.sub_quantity_type_display} |
               </React.Fragment>
              }
              Price: {currency} {data.price} | Tax: {currency} {data.tax}
              {parseFloat(data.discount) > 0 &&
               <React.Fragment>
                 | Discount: {currency} {data.discount} ({data.discount_percent}%)
               </React.Fragment>
              }
            </p>
            {this.props.onClick &&
             <button
               type="button" className="btn btn-link btn-sm m-0 p-0"
               onClick={() => this.action(data)} disabled={disabled}>
               <FontAwesomeIcon icon={(lock === false ? faPen : faEye)} size={'sm'} className={'mr-2'}/>
               {lock === false ? 'Edit' : 'Details'}
             </button>
            }
          </td>
          <td className="w-35 text-right">
            <p className={'mb-0'}><strong>{currency} {data.total}</strong></p>
            {data.complimentary &&
             <span className={'badge badge-info'}>
               {data.complimentary_type_display}
             </span>
            }
            {data.no_charge &&
            <span className={'badge badge-info'}>
              {data.no_charge_type_display}
            </span>
            }
          </td>
        </tr>
      </React.Fragment>
    );
  };
}

/* Export Component ==================================================================== */
export default OrderItemListView;
