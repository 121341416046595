/**
 * API Config
 *
 */

let hostUrl = 'https://api.renzo.in/api/1';

if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
  hostUrl = 'https://staging-api.renzo.in/api/1';

  if (process.env.REACT_APP_API_ENDPOINT) {
    hostUrl = process.env.REACT_APP_API_ENDPOINT;
  }
}

export default {
  // The URL we're connecting to
  // iOS
  hostname: hostUrl,

  // Android
  // hostname: "http://10.0.2.2:8000/api/1",

  // Map shortnames to the actual endpoints, so that we can
  // use them like so: AppAPI.ENDPOINT_NAME.METHOD()
  //  NOTE: They should start with a /
  //    eg.
  //    - AppAPI.account.get()
  endpoints: new Map([
    ['account', '/accounts'],
    ['accountapi', '/accounts/accounts'],
    ['analyticsapi', '/analytics'],
    ['auth', '/accounts'],
    ['availability', '/availability/availability'],
    ['availabilityapi', '/availability'],
    ['billingapi', '/billing'],
    ['booking', '/booking/booking'],
    ['bookingamendment', '/booking/booking-amendment'],
    ['bookingapi', '/booking'],
    ['bookingroom', '/booking/booking-room'],
    ['dashboardapi', '/dashboard'],
    ['eventapi', '/event'],
    ['expenseapi', '/expense'],
    ['expenseitem', '/expense/expense-item'],
    ['expense', '/expense/expense'],
    ['guest', '/guest/guest'],
    ['profilerecords', '/guest/profile-records'],
    ['guestapi', '/guest'],
    ['housekeeping', '/room/housekeeping'],
    ['membership', '/property/membership'],
    ['notes', '/notes/notes'],
    ['notesapi', '/notes'],
    ['notificationapi', '/notification'],
    ['notification', '/notification/notification'],
    ['order', '/order/order'],
    ['orderitem', '/order/order-item'],
    ['orderapi', '/order'],
    ['payment', '/payments/payments'],
    ['refund', '/payments/refunds'],
    ['paymentapi', '/payments'],
    ['pgateway', '/pgateway/pgateway'],
    ['pterminal', '/pterminal/pterminal'],
    ['pterminalapi', '/pterminal'],
    ['pgatewayapi', '/pgateway'],
    ['pos', '/pos/pos'],
    ['posapi', '/pos'],
    ['positems', '/pos/positems'],
    ['postables', '/pos/postables'],
    ['poscategory', '/pos/poscategory'],
    ['property', '/property/property'],
    ['propertyapi', '/property'],
    ['propertydetails', '/property/property-details'],
    ['propertytaxclass', '/property/taxclass'],
    ['propertybankaccounts', '/property/bankaccounts'],
    ['rateapi', '/rate'],
    ['reportapi', '/reports'],
    ['review', '/review/review'],
    ['reviewapi', '/review'],
    ['room', '/room/room'],
    ['roomapi', '/room'],
    ['roomtype', '/room/room-type'],
    ['roomtypepackage', '/room/room-type-package'],
    ['ticket', '/event/ticket'],
    ['ticketbooking', '/event/ticket-booking'],

    // TO Remove
    ['roomtypeitem', '/room/roomtypeitems'],
  ]),

  // Which 'endpoint' key deals with our tokens?
  tokenKey: 'auth'
};
