/**
 *
 * Property Front Desk Overview
 */
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import BadgeStatus from '../../../components/general/BadgeStatus';
import { confirmAlert } from '../../../components/general/ConfirmAlert';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
// Components
import { Alerts } from '../../../components/ui';
import Strings from '../../../constants/strings';
import AppAPI from '../../../lib/api';
// Consts and Libs
// Actions
import * as PropertyActions from '../../../redux/property/actions';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetPropertyDetails: PropertyActions.isSetPropertyDetails,
};

/* Component ==================================================================== */
class PropertyFrontDeskOverview extends Component {
  static componentName = 'PropertyFrontDeskOverview';

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    this.setState({
      loading: false,
      error: null,
    });
  };

  updateProperty = (payload) => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.propertydetails.patch(`${this.props.property.id}/`, payload)
          .then(() => {
            this.setState({resultMsg: {success: 'Success'}, disabled: true},
              () => {
                this.setState({resultMsg: {success: ''}});
                this.props.isSetPropertyDetails(this.props.property.id);
              });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  render = () => {
    const {loading, error, resultMsg} = this.state;
    if (!this.props.property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const {property} = this.props;

    return (
      <div className="front-desk-settings screen-container">
        <Helmet>
          <title>Front Desk Settings</title>
        </Helmet>

        <Alerts
          status={resultMsg.status}
          success={resultMsg.success}
          error={resultMsg.error}
        />
        <div className={'row mb-3'}>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <ul className="list-group mb-4">
              <li className="list-group-item">Profile Validations</li>
              <li className="list-group-item">
                <div className="custom-control custom-switch">
                  <input
                    disabled={!property.channel_manager_ok}
                    type="checkbox" className="custom-control-input" id={'profile_validation_checkin'}
                    checked={property.profile_validation_checkin}
                    onClick={() => {
                      confirmAlert({
                        title: (property.profile_validation_checkin ? 'Disable' : ' Enable') + ' Distribution',
                        message: property.profile_validation_checkin ? Strings.frontDeskCheckinValidationDisable : Strings.frontDeskCheckinValidationEnable,
                        buttons: [
                          {
                            className: (property.profile_validation_checkin ? 'btn-danger' : ' btn-success'),
                            label: 'Yes',
                            onClick: () => this.updateProperty({
                              'profile_validation_checkin': !property.profile_validation_checkin,
                            }),
                          },
                          {
                            className: 'btn-secondary',
                            label: 'No',
                          },
                        ],
                      });
                    }}
                  />
                  <label className="custom-control-label" htmlFor={'profile_validation_checkin'}>
                    <strong>Enforce Checkin Validation : </strong>
                    <BadgeStatus status={property.profile_validation_checkin}/>
                    <br/>
                    <p className={'sm mb-0 text-secondary small'}>{Strings.frontDeskCheckinValidation}</p>
                  </label>
                </div>
              </li>
              <li className="list-group-item">
                <div className="custom-control custom-switch">
                  <input
                    disabled={!property.channel_manager_ok}
                    type="checkbox" className="custom-control-input" id={'profile_validation_checkout'}
                    checked={property.profile_validation_checkout}
                    onClick={() => {
                      confirmAlert({
                        title: (property.profile_validation_checkout ? 'Disable' : ' Enable') + ' Tax inclusion',
                        message: property.profile_validation_checkout ? Strings.frontDeskCheckoutValidationDisable : Strings.frontDeskCheckoutValidationEnable,
                        buttons: [
                          {
                            className: (property.profile_validation_checkout ? 'btn-danger' : ' btn-success'),
                            label: 'Yes',
                            onClick: () => this.updateProperty({
                              'profile_validation_checkout': !property.profile_validation_checkout,
                            }),
                          },
                          {
                            className: 'btn-secondary',
                            label: 'No',
                          },
                        ],
                      });
                    }}
                  />
                  <label className="custom-control-label" htmlFor={'profile_validation_checkout'}>
                    <strong>Enforce Checkout Validation : </strong>
                    <BadgeStatus
                      successText={'Included'} failText={'Not Included'}
                      status={property.profile_validation_checkout}
                    />
                    <br/>
                    <p className={'sm mb-0 text-secondary small'}>{Strings.frontDeskCheckoutValidation}</p>
                  </label>
                </div>
              </li>



              <li className="list-group-item">
                <div className="custom-control custom-switch">
                  <input
                    disabled={!property.channel_manager_ok}
                    type="checkbox" className="custom-control-input" id={'profile_validation_count'}
                    checked={property.profile_validation_count}
                    onClick={() => {
                      confirmAlert({
                        title: (property.profile_validation_count ? 'Disable' : ' Enable') + ' Tax inclusion',
                        message: property.profile_validation_count ? Strings.frontDeskGuestCountValidationDisable : Strings.frontDeskGuestCountValidationEnable,
                        buttons: [
                          {
                            className: (property.profile_validation_count ? 'btn-danger' : ' btn-success'),
                            label: 'Yes',
                            onClick: () => this.updateProperty({
                              'profile_validation_count': !property.profile_validation_count,
                            }),
                          },
                          {
                            className: 'btn-secondary',
                            label: 'No',
                          },
                        ],
                      });
                    }}
                  />
                  <label className="custom-control-label" htmlFor={'profile_validation_count'}>
                    <strong>Minimum Guest Validation : </strong>
                    <BadgeStatus
                      successText={'Included'} failText={'Not Included'}
                      status={property.profile_validation_count}
                    />
                    <br/>
                    <p className={'sm mb-0 text-secondary small'}>{Strings.frontDeskGuestCountValidation}</p>
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>


      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      roomTypes: [],
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyFrontDeskOverview);
