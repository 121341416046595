/**
 * Order/Order Tax Edit
 *  Order/Order Tax Edit
 *
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Actions
import {Input, Textarea} from 'react-formik-ui';


/* Component ==================================================================== */
class OrderTaxEdit extends Component {
  static componentName = 'OrderTaxEdit';

  static propTypes = {
    order: PropTypes.object.isRequired,
  };

  static defaultProps = {
    title: '',
  }

  render = () => {
    const {order} = this.props;
    return (
      <React.Fragment>
        <div className="form-row">
          <div className="col-12 col-sm-12">
            <div className={'form-row'}>
              <div className="col-12 form-group">
                <Input
                  required
                  type={'number'}
                  name="order_tax"
                  label={`Order Tax (${order.currency})`}
                  className={'form-control'}
                  hint={'Any tax to be applied on order level.'}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-12 form-group">
            <Textarea
              name="order_tax_description"
              label={'Tax Description'}
              hint={'Will be visible to customer on order PDF.'}
              className={'form-control'}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };
}

/* Export Component ==================================================================== */
export default (OrderTaxEdit);

