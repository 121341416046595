/**
 *
 * Payment Terminal Details
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
// Consts and Libs
import AppUtil from '../../../lib/util';
import AppAPI from '../../../lib/api';
import {Strings} from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageSubHeader from '../../../components/dashboard/PageSubHeader';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faCashRegister} from '@fortawesome/free-solid-svg-icons';
// Actions

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  payment: state.payment.payment,
});

// Any actions to map to the component?
const mapDispatchToProps = {};


/* Component ==================================================================== */
class PaymentTerminalList extends Component {
  static componentName = 'PaymentTerminalList';

  static propTypes = {
    match: PropTypes.object.isRequired,
    selectKey: PropTypes.string,
  };

  static defaultProps = {
    selectKey: 'pterminal-list'
  }

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {selectKey} = this.props;
    this.setState({loading: true});

    AppAPI.payment.get(`${this.props.match.params.paymentId}/related-details/?key=${selectKey}`)
      .then((res) => {
        this.setState({
          response: res,
          loading: false,
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };


  render = () => {

    const {loading, error, response, paymentStatus} = this.state;
    const {payment} = this.props;


    if (loading || _.isEmpty(payment) || _.isEmpty(response)) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    const {pterminals} = response;


    let itemList = pterminals;

    if (paymentStatus || paymentStatus === 0) {
      itemList = itemList.filter(item => (item.refund_status === paymentStatus));
    }

    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Payment Sub Payments</title>
        </Helmet>

        <Link to={`/payment/${payment.hash}/payment-terminal/`} className={'btn btn-outline-dark mr-2 btn-sm mb-4'}>
          <FontAwesomeIcon icon={faArrowLeft} size={'sm'}/> {'Payment Terminal'}
        </Link>

        <PageSubHeader icon={faCashRegister} title={'Payment terminal'} description={'Payment terminal transactions.'}/>

        <div className="table-responsive border">
          <table className={`table ${!_.isEmpty(itemList) && 'table-hover'} mb-0`}>
            <thead>
              <tr>
                <th className={'text-left'}>Reference</th>
                <th>Gateway ID</th>
                <th/>
                <th>Vendor</th>
                <th>Device Name</th>
                <th>Payment</th>
                <th className={'text-left'}>Total</th>
              </tr>
            </thead>
            <tbody>
              {!_.isEmpty(itemList) ?
                <React.Fragment>
                  {itemList.map((data, i) => {
                    return (
                      <tr className={'tr-align-middle'} key={i}>
                        <td className={'data-table-cell-md'}>
                          <Link to={`/payment/payment-terminal/${data.hash}/`}>{data.ref_no}</Link><br/>
                          <p className={'small mb-0 text-muted'}>{AppUtil.formatDateTime(data.created)}</p>
                        </td>
                        <td className={'data-table-cell-md text-center'}>
                          {data.gateway_unique_id}
                        </td>
                        <td className={'text-left data-table-cell-md'}>
                          <span className={`badge mr-2 ${AppUtil.pTerminalStatusColor(data.payment_status)}`}>
                            {data.payment_status_display}
                          </span>
                        </td>
                        <td className={'text-center data-table-cell-md'}>
                          {data.terminal_provider_display}
                        </td>
                        <td className={'text-center data-table-cell-md'}>
                          {!_.isEmpty(data.attributes) &&
                            <React.Fragment>
                              <p className={'mb-0'}>{data.attributes.pterminal_device_name}</p>
                              <p className={'mb-0 text-muted small'}>{data.attributes.pterminal_device_serial}</p>
                            </React.Fragment>
                          }
                        </td>
                        <td className={'text-center data-table-cell-md'}>
                          {(!_.isEmpty(data.attributes) && data.attributes.payment_hash )&&
                          <Link to={`/payment/${data.attributes.payment_hash}/`}>
                            {data.attributes.payment_ref_no}
                          </Link>
                          }
                        </td>
                        <td className={'text-left data-table-cell-md'}><strong>{`${data.currency} ${data.total}`}</strong></td>
                      </tr>
                    );
                  })}
                </React.Fragment>
                :
                <tr>
                  <td colSpan={9}>
                    <div className={'text-center table-list-empty'}>
                      <h6><FontAwesomeIcon icon={faCashRegister} className={'mr-2'}/> No Transactions</h6>
                      <p className={'text-muted'}>{Strings.paymentTerminalEmpty}</p>
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      response: {},
      selectPTerminal: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PaymentTerminalList);