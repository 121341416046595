/**
 * Manage Booking Room Guest Count
 *  edit booking guest
 */
import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';
import {Modal} from 'react-bootstrap';
// Consts and Libs
import {Strings} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import {Input} from 'react-formik-ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave, faTimes} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */

class BookingRoomGuestCountEdit extends React.Component {
  static componentName = 'BookingRoomGuestCountEdit';

  static propTypes = {
    bookingRoom: PropTypes.object,
    updateBookingRoom: PropTypes.func,
  };

  updateBookingRoom = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});

      const payload = {
        no_of_guest: credentials.no_of_guest,
        no_of_children: credentials.no_of_children,
        no_of_infant: credentials.no_of_infant,
      };

      if (this.props.updateBookingRoom) {
        this.props.updateBookingRoom(payload, () => {
          this.setState({resultMsg: {success: 'Success'}});
          setTimeout(() => this.props.onHide(), 500);
        });
      }
    }
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  render = () => {

    const {resultMsg} = this.state;
    const {bookingRoom, show} = this.props;

    if (!show) return <React.Fragment/>;

    const formValidation = Yup.object().shape({
      no_of_infant: Yup.number().required(),
      no_of_children: Yup.number().required(),
      no_of_guest: Yup.number().positive().required(),
    });

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Booking Occupancy
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            no_of_guest: bookingRoom.no_of_guest,
            no_of_infant: bookingRoom.no_of_infant,
            no_of_children: bookingRoom.no_of_children,
          }}
          validationSchema={formValidation}
          onSubmit={values => this.updateBookingRoom(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <Form className={'form-group'}>
                      <div className="form-row">
                        <div className="col-lg-6 col-md-6 form-group">
                          <Input
                            name='no_of_guest'
                            label={'No of Adult(s)'}
                            className={'form-control'}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-lg-6 col-md-6 form-group">
                          <Input
                            name='no_of_children'
                            label={'No of Children'}
                            className={'form-control'}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 form-group">
                          <Input
                            name='no_of_infant'
                            label={'No of Infant(s)'}
                            className={'form-control'}
                          />
                        </div>
                      </div>
                    </Form>
                    <div className={'alert alert-info'}>
                      <p className={'mb-0'}>{Strings.bookingRoomGuestCountEdit}</p>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <button
                      type="submit" onClick={formikProps.handleSubmit} className={'btn btn-success btn-lg btn-block'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default BookingRoomGuestCountEdit;
