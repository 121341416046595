/**
 * Date View
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AppUtil from '../../lib/util';

/* Component ==================================================================== */
class DatesView extends Component {
  static componentName = 'Error';

  static propTypes = {
    checkin: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    checkout: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    onClick: PropTypes.func
  };

  onClick = () =>{
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render = () => {
    const {checkin, checkout} = this.props;
    return (
      <div className={'dates dates-wrapper'}>
        <div className="row">
          <div className="col-4">
            <div className="border text-center rounded" onClick={()=>this.onClick()}>
              <small><span className="fa fa-sign-in"/> Checkin</small>
              <h5>{checkin ? AppUtil.formatDateTime(checkin, 'date') : '....'}</h5>
            </div>
          </div>

          <div className="col-4">
            <div className="border text-center rounded" onClick={()=>this.onClick()}>
              <small><span className="fa fa-moon-o"/> Night(s)</small>
              <h5>{checkin && checkout ? (AppUtil.diffDateTime(checkin, checkout)) : '...'}</h5>
            </div>
          </div>

          <div className="col-4">
            <div className="border text-center rounded" onClick={()=>this.onClick()}>
              <small><span className="fa fa-sign-out"/> Checkout</small>
              <h5>{checkout ? AppUtil.formatDateTime(checkout, 'date') : '....'}</h5>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default DatesView;