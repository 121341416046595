/**
 * Model for Availability Date update
 */
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';
import {Modal} from 'react-bootstrap';
import React, {Component} from 'react';
// Consts and Libs
import AppUtil from '../../../lib/util';
// Components
import { Alerts } from '../../../components/ui';
import {Input, SubmitBtn} from 'react-formik-ui';
import {faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

/* Component ==================================================================== */

class AvailabilityDateUpdate extends Component {
  static componentName = 'AvailabilityDateUpdate';

  static propTypes = {
    roomType: PropTypes.object,
    editType: PropTypes.string,
    channelType: PropTypes.string,
    availabilityDetails: PropTypes.object,
    updateAvailabilityDate: PropTypes.func,
  };

  static defaultProps = {
    editType: 'basePrice',
    channelType: 'channel_manager'
  }


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        searchText: '',
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  updateAvailabilityDate = (formData) => {
    const payload = {};
    const {editType} = this.props;
    switch (editType) {
    case 'inventory_block':
    default:
      payload.all_channel_block = formData.all_channel_block;
    }
    this.props.updateAvailabilityDate(payload);
  }


  render = () => {

    const {show, editType, availabilityDetails, roomType, resultMsg} = this.props;

    let formValidation, formInputData, modelTitle;
    if (!show) return <React.Fragment/>;

    const initialValues = {
      all_channel_block: availabilityDetails.all_channel_block,
    };

    switch (editType) {
    case 'inventory_block':
    default:
      modelTitle = 'Inventory Block';
      formValidation = Yup.object().shape({
        all_channel_block: Yup.number().nullable().integer().max(
          roomType.total_room, `Block cannot exceed no of room(s) in the room type : ${roomType.total_room} Room(s).`
        ),
      });
      formInputData = () => (
        <React.Fragment>
          <div className="form-row">
            <div className="col-lg-12 col-md-12 form-group">
              <Input
                label={'Blocked Room(s)'}
                hint={'No of rooms to be blocked from inventory.'}
                type={'number'}
                name={'all_channel_block'}
                className={'form-control'}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }


    return (
      <Modal
        {...this.props}
        size={'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {modelTitle} for {AppUtil.formatDateTime(availabilityDetails.date, 'datef')}
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={formValidation}
          onSubmit={values => this.updateAvailabilityDate(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <Form className={'form-group'}>
                      <div className="mt-2">
                        {formInputData(formikProps)}
                      </div>
                    </Form>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className="px-3">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary  btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <SubmitBtn type="submit" className={'btn btn-success  btn-block'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                    </SubmitBtn>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      searching: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: true,
    };
  }
}

/* Export Component ==================================================================== */
export default AvailabilityDateUpdate;
