/**
 *
 * Property Notification Settings
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {NavLink, Redirect, Route, Switch} from 'react-router-dom';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';

import NotificationLogList from './NotificationLogList';
import NotificationOverview from './NotificationOverview';
import NotificationContent from './NotificationContentList';
import NotificationLogDetails from './NotificationLogDetails';
import NotificationContentManage from './NotificationContentManage';
import NotificationConfigBCCList from './NotificationConfigBCCList';
import NotificationContentDetails from './NotificationContentDetails';
import NotificationsAutoTriggerSettings from './NotificationsAutoTriggerSettings';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class NotificationSettings extends Component {
  static componentName = 'NotificationSettings';

  componentDidMount = () => {
  };


  render = () => {
    const {loading, error} = this.state;

    if (!this.props.property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;


    let baseUrl = '/settings/property/notification-settings';
    const menu = [
      {
        title: 'Overview',
        link: `${baseUrl}/overview`,
        isExact: true,
      },
      {
        title: 'Notification Preference',
        link: `${baseUrl}/notification-settings`,
      },
      {
        title: 'Notification Logs',
        link: `${baseUrl}/logs`,
      },
      {
        title: 'Notification Content',
        link: `${baseUrl}/content`,
      },
      {
        title: 'Notification Config',
        link: `${baseUrl}/config`,
      },
    ];

    let menuItems = [];
    menu.map((item) => {
      const {title, link, isExact} = item;
      return menuItems.push(
        <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
          <NavLink
            to={{
              pathname: link,
              state: {fromDashboard: true},
            }}
            exact={isExact}
            className="nav-link rounded-0"
            activeClassName="selected">
            {title}
          </NavLink>
        </li>,
      );
    });

    return (
      <div className="channel-manager-settings screen-container">
        <Helmet>
          <title>Notification Settings</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={'Notification Settings'}
        />

        <ul className="nav nav-tabs mb-3">
          {menuItems}
        </ul>
        <Switch>
          <Route exact path={baseUrl} render={() => (<Redirect to={`${baseUrl}/overview`}/>)}/>
          <Route path={`${baseUrl}/overview/`} exact component={NotificationOverview}/>

          <Route path={`${baseUrl}/logs/`} exact component={NotificationLogList}/>
          <Route path={`${baseUrl}/logs/:notificationId`} exact component={NotificationLogDetails}/>

          <Route path={`${baseUrl}/config`} exact render={() => (<Redirect to={`${baseUrl}/config/bcc`}/>)}/>
          <Route path={`${baseUrl}/config/bcc`} exact component={NotificationConfigBCCList}/>

          <Route path={`${baseUrl}/content/`} exact component={NotificationContent}/>
          <Route path={`${baseUrl}/content/:contentId/`} exact component={NotificationContentDetails}/>
          <Route path={`${baseUrl}/content/:contentId/manage`} exact component={NotificationContentManage}/>

          <Route path={`${baseUrl}/notification-settings`} exact component={NotificationsAutoTriggerSettings}/>
        </Switch>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings);
