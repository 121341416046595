/**
 *
 * Booking Add
 * Final booking step
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import moment from 'moment';
import * as Yup from 'yup';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import { AppConfig, Constants, Strings } from '../../constants';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faAt,
  faBuilding,
  faEnvelopeOpenText,
  faPhone,
  faPlus,
  faPlusCircle,
  faUser,
  faWalking,
} from '@fortawesome/free-solid-svg-icons';

import {Alerts} from '../../components/ui';
import Error from '../../components/general/Error';
import PageHeader from '../../components/dashboard/PageHeader';
import Loading from '../../components/general/Loading';

import 'react-daterange-picker/dist/css/react-calendar.css';
import DatesView from '../../components/dashboard/DatesView';
import BookingListView from './BookingListView';

import BookingOTASearch from './BookingOTASearch';
import GuestListView from '../guest/GuestListView';
import GuestQuickManage from '../guest/GuestManageQuick';
import GuestDetailsOverView from '../guest/GuestDetailsOverView';
import ProfileQuickSearch from '../guest/ProfileQuickSearch';
// Forms
import {Form, Formik} from 'formik';
import { Checkbox, Input, Select } from 'react-formik-ui';

/* Redux ======================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  booking: state.booking,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class BookingAdd extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };


  componentDidMount = () => {
    if (this.props.match.params.bookingId && Object.keys(this.props.booking.booking).length === 0) {
      this.props.history.replace('/booking/' + this.props.match.params.bookingId + '/');
    } else if (!this.props.booking || (!this.props.booking.selectiondata || !this.props.booking.lookupresponse)) {
      this.props.history.replace('/booking/lookup/');
    } else {
      this.fetchInitData();
    }
    this.fetchInitData();
  };

  addBooking = (values=null) => {
    // Get new credentials and update
    const {lookupresponse} = this.props.booking;
    const {guestData, corporateData, OTAData, bookingSelectedSource, bookingConfirmationStatus} = this.state;
    let url = null, payload = {};
    if (lookupresponse && Object.keys(lookupresponse).length > 0) {
      // Preparing Room Data
      const bookingRooms = [];
      const checkin = moment(lookupresponse.checkin).format('YYYY-MM-DD');
      const checkout = moment(lookupresponse.checkout).format('YYYY-MM-DD');
      // eslint-disable-array-callback-return
      this.state.rooms.map(data => {
        const bookingRoom = {};
        // eslint-disable-next-line radix
        bookingRoom.no_of_guest = parseInt(data.guest);
        // eslint-disable-next-line radix
        bookingRoom.no_of_children = parseInt(data.child);
        // eslint-disable-next-line radix
        bookingRoom.no_of_infant = parseInt(data.infant);
        bookingRoom.room_type = data.room_type.room_type;
        bookingRoom.checkin = checkin;
        bookingRoom.checkout = checkout;
        bookingRoom.room_type_packages = [
          {
            room_type_package: data.package.data.room_type_package,
            start: checkin,
            end: checkout,
          },
        ];
        bookingRooms.push(bookingRoom);
        return true;
      });

      payload.booking_room_data = bookingRooms;
      payload.booking_property_id = this.props.property.id;


      // Type of request
      // 1. New Booking
      // 2. Booking Room ADD
      //
      if (lookupresponse.booking) {
        url = `booking/${lookupresponse.booking.hash}/amend/`;
      } else {
        url = 'booking/create/';

        payload.booking_source = bookingSelectedSource;
        payload.booking_confirm_status = bookingConfirmationStatus;

        if (values) {
          payload.booking_order_mask = values.booking_order_mask;
          payload.booking_remarks = values.booking_remarks;
          payload.booking_reference = values.booking_reference;
          payload.booking_visit_purpose = values.booking_visit_purpose;
          payload.booking_reference_description = values.booking_reference_description;
          payload.booking_visit_purpose_description = values.booking_visit_purpose_description;
        }

        if (guestData) {
          payload.guest = {
            profile_id: guestData.id,
            first_name: guestData.first_name,
            last_name: guestData.last_name,
            email: guestData.email,
            phone: guestData.code && guestData.phone ? guestData.code + guestData.phone : guestData.phone,
            gender: guestData.gender,
            date_of_birth: guestData.date_of_birth ? moment(guestData.date_of_birth).format('YYYY-MM-DD') : null,
          };

          if (guestData.age) {
            payload.guest.age = guestData.age;
          }

          if (guestData.nationality && guestData.nationality.code) {
            payload.guest.nationality = guestData.nationality.code;
          } else if (guestData.nationality) {
            payload.guest.nationality = guestData.nationality;
          }
        }

        if (bookingSelectedSource === Constants.BOOKING_SOURCE.OTA && OTAData) {
          payload.booking_ota = OTAData.id;
        }

        if ((bookingSelectedSource === Constants.BOOKING_SOURCE.CORPORATE || bookingSelectedSource === Constants.BOOKING_SOURCE.AGENT)) {
          if (corporateData) {
            payload.corporate = {
              profile_id: corporateData.id,
              entity_name: corporateData.entity_name,
              email: corporateData.email,
              phone: corporateData.code && corporateData.phone ? corporateData.code + corporateData.phone : corporateData.phone,
            };
          }
        }
      }

      if (url) {

        this.setState({
          resultMsg: {status: 'One moment...'},
        });

        AppAPI.bookingapi.post(url, payload)
          .then(res => {
            if (res.id) {
              this.setState({resultMsg: {success: 'Success'}}, () => {
                setTimeout(() => {
                  this.props.history.replace('/booking/' + res.hash);
                }, 500);
              });
            } else {
              this.setState({resultMsg: {error: 'Booking Failed.'}});
            }

          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      }
    }
  };

  fetchInitData = () => {
    const {selectiondata} = this.props.booking;
    const rooms = [];

    let total = parseFloat('0.0');

    if (selectiondata) {
      // eslint-disable-next-line
      Object.keys(selectiondata).map((data, i) => {
        if (selectiondata[data] && selectiondata[data].selected_rooms > 0) {
          let roomTypeData = selectiondata[data];
          // eslint-disable-next-line
          Object.keys(selectiondata[data].package).map(packageId => {
            let no_of_room = 0;
            let packageData = selectiondata[data].package[packageId];

            while (no_of_room < packageData.selected_rooms) {
              let roomData = {
                guest: roomTypeData.occupancy_data.base_occupancy,
                child: 0,
                infant: 0,
                extra_bed: 0,
                room_type: roomTypeData,
                occupancy_data: roomTypeData.occupancy_data,
                package: packageData.data,
                total: 0,
              };
              no_of_room = no_of_room + 1;
              roomData = AppUtil.calculateRoomTotals(roomData);
              rooms.push(roomData);
              total = parseFloat(roomData.total) + parseFloat(total);
            }
          });
        }
      });
    }

    this.setState({
      total: total,
      rooms: rooms,
      base_total: total,
      sub_total: total,
      loading: false,
    });
  };

  updateIndex = (i, j, k) => {
    if (j != null && k) {
      const {rooms} = this.state;
      // eslint-disable-next-line
      switch (k) {
      case 'g+':
        rooms[j].guest =
          rooms[j].guest < rooms[j].occupancy_data.max_occupancy
            ? rooms[j].guest + 1
            : rooms[j].guest;
        break;
      case 'g-':
        rooms[j].guest = rooms[j].guest > 1 ? rooms[j].guest - 1 : 1;
        break;
      case 'c+':
        if (rooms[j].child < rooms[j].occupancy_data.child_occupancy) {
          rooms[j].child = rooms[j].child + 1;
        }
        break;
      case 'c-':
        if (rooms[j].child > 0) {
          rooms[j].child = rooms[j].child - 1;
        }
        break;
      case 'i+':
        if (rooms[j].infant < rooms[j].occupancy_data.infant_occupancy) {
          rooms[j].infant = rooms[j].infant + 1;
        }
        break;
      case 'i-':
        if (rooms[j].infant > 0) {
          rooms[j].infant = rooms[j].infant - 1;
        }
        break;
      }

      rooms[j] = AppUtil.calculateRoomTotals(rooms[j]);

      let total = 0;
      rooms.map(data => (total = parseFloat(total) + parseFloat(data.total)));
      this.setState({
        rooms: rooms,
        total,
      });
    }
  };

  showGuestDetails = (guestId, add_guest = false) => {
    this.setState({showGuestDetailsId: guestId, showGuestDetails: true, addGuestToRoom: add_guest});
  };

  render = () => {
    const {
      resultMsg, loading, rooms, total, error, bookingConfirmationStatus, bookingSelectedSource, guestData, corporateData,
      OTAData,
    } = this.state;
    const {lookupresponse} = this.props.booking;
    const {property} = this.props;
    let required_profile_fields = [], required_booking_fields = [];

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;


    const validation = {
      first_name: Yup.string().min(2, 'Too Short!').required('Required'),
      email: Yup.string().email('Invalid email'),
      phone: Yup.string(Strings.phoneNumberValidation).matches(
        AppConfig.regXValidation.phoneRegex, Strings.phoneNumberValidation
      ).min(8, 'to short').max(10, `${Strings.phoneNumberValidation} without appending 0`).required(
        Strings.phoneNumberValidation
      ),
      age: Yup.number().min(1, 'Min age is 1').max(120, 'Max age is 120').nullable(),
    };

    const bookingValidation = {
      booking_remarks: Yup.string().max(200, 'Too Long!').nullable(),
    };

    if (!_.isEmpty(property.config)) {
      if (property.config.profile){
        const profile_fields = property.config.profile.profile_advance_fields;
        if (!_.isEmpty(profile_fields) && !_.isEmpty(profile_fields.required_profile_fields)) {
          required_profile_fields = profile_fields.required_profile_fields;
          if (required_profile_fields.includes('age')){
            validation['age'] = Yup.number().min(1, 'Min age is 1').max(120, 'Max age is 120').required('Required');
          }
          if (required_profile_fields.includes('gender')) {
            validation['gender'] = Yup.string().required('Required');
          }
          if (required_profile_fields.includes('nationality')) {
            validation['nationality'] = Yup.string().required('Required');
          }
        }
      }

      if (property.config.booking && property.config.booking.booking_advance_details) {
        const profile_fields = property.config.profile.profile_advance_fields;
        if (!_.isEmpty(profile_fields) && !_.isEmpty(profile_fields.required_booking_fields)) {
          required_booking_fields = profile_fields.required_booking_fields;
          if (required_booking_fields.includes('booking_visit_purpose')){
            // 0 is default so anything above 0 is valid one.
            bookingValidation['booking_visit_purpose'] = Yup.number().min(1, 'Required').required('Required');
          }
        }
      }
    }

    const formValidationGuest = Yup.object().shape(validation);
    const formValidationBooking = Yup.object().shape(bookingValidation);

    const bookingSource = [
      {
        icon: faWalking,
        text: 'Walk In',
        value: Constants.BOOKING_SOURCE.WALKIN,
      },
      {
        icon: faPhone,
        text: 'Phone',
        value: Constants.BOOKING_SOURCE.PHONE,
      },
      {
        icon: faEnvelopeOpenText,
        text: 'E-Mail',
        value: Constants.BOOKING_SOURCE.EMAIL,
      },
      {
        icon: faUser,
        text: 'Agent',
        value: Constants.BOOKING_SOURCE.AGENT,
      },
      {
        icon: faAt,
        text: 'OTA',
        value: Constants.BOOKING_SOURCE.OTA,
      },
      {
        icon: faBuilding,
        text: 'Corporate',
        value: Constants.BOOKING_SOURCE.CORPORATE,
      },
    ];

    return (
      <div className="booking-view screen-container">
        <Helmet>
          <title>Booking Information</title>
        </Helmet>

        <PageHeader history={this.props.history} title={'Add Booking'}/>

        <div className={'booking-details-container'}>
          <div className={'container mt-5'}>
            <div className="row">
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className={'lookup-box-container mt-2'}>
                  <DatesView checkout={lookupresponse.checkout} checkin={lookupresponse.checkin}/>
                  <div className="spacer-20"/>
                  {lookupresponse.booking &&
                   <div>
                     <h6>Booking</h6>
                     <BookingListView
                       data={lookupresponse.booking}
                     />
                     <div className="spacer-20"/>
                   </div>
                  }

                  {!_.isEmpty(rooms) &&
                   <div>
                     <h6>Room Details</h6>
                     <table className={'table'}>
                       <tbody>
                         {rooms.map((data, i) => (
                           <tr key={i}>
                             <td>
                               <h6>Room : {(i + 1)} [{data.room_type.name}]</h6>
                               <p className={'mb-0 small'}>Package : {data.package.data.name}</p>
                             </td>
                             <td>
                               <div className={'row text-center'}>
                                 <div className={'col-12 col-sm-4'}>
                                   <p className={'small mb-0'}>Adult</p>
                                   <div className="btn-group btn-group-sm" role="group" >
                                     <button
                                       type="button" className="btn  border red-cl"
                                       onClick={() => this.updateIndex(0, i, 'g-')}>-
                                     </button>
                                     <button type="button" className="btn border">{data.guest}</button>
                                     <button
                                       type="button" className="btn border green-cl"
                                       onClick={() => this.updateIndex(2, i, 'g+')}>+
                                     </button>
                                   </div>
                                   <p className={'small mb-0 text-muted'}>Max {rooms[i].occupancy_data.max_occupancy}</p>
                                 </div>
                                 <div className={'col-12 col-sm-4'}>
                                   <p className={'small mb-0'}>Children</p>
                                   <div className="btn-group btn-group-sm" role="group" >
                                     <button
                                       type="button" className="btn  border red-cl"
                                       onClick={() => this.updateIndex(0, i, 'c-')}>-
                                     </button>
                                     <button type="button" className="btn border">{data.child}</button>
                                     <button
                                       type="button" className="btn border green-cl"
                                       onClick={() => this.updateIndex(2, i, 'c+')}>+
                                     </button>
                                   </div>
                                   <p className={'small mb-0 text-muted'}>Max {rooms[i].occupancy_data.child_occupancy}</p>
                                 </div>
                                 <div className={'col-12 col-sm-4'}>
                                   <p className={'small mb-0'}>Infant</p>
                                   <div className="btn-group btn-group-sm" role="group" >
                                     <button
                                       type="button" className="btn  border red-cl"
                                       onClick={() => this.updateIndex(0, i, 'i-')}>-
                                     </button>
                                     <button type="button" className="btn border">{data.infant}</button>
                                     <button
                                       type="button" className="btn border green-cl"
                                       onClick={() => this.updateIndex(2, i, 'i+')}>+
                                     </button>
                                   </div>
                                   <p className={'small mb-0 text-muted'}>Max {rooms[i].occupancy_data.infant_occupancy}</p>
                                 </div>
                               </div>
                             </td>
                             <td className={'text-center'}>
                               <p><strong>{lookupresponse.currency} {data.total}</strong></p>
                             </td>
                           </tr>
                         ))}
                         <tr>
                           <td colSpan={2}><p><strong>Total</strong></p></td>
                           <td className={'text-center'}><p>
                             <strong>{lookupresponse.currency} {total.toFixed(2)}</strong>
                           </p></td>
                         </tr>
                         <tr>
                           <td colSpan={3}>
                             <p className={'text-secondary small'}>{Strings.bookingTotalWarningText}</p>
                           </td>
                         </tr>
                       </tbody>
                     </table>
                   </div>
                  }

                  {!lookupresponse.booking ?
                    (
                      <React.Fragment>
                        <hr/>
                        <div className={'mb-3'}>
                          <h6>Booking Source</h6>
                          <p>Select the source of booking.</p>
                          <div className={'row'}>
                            {bookingSource.map((data, i) => (
                              <div key={i} className={'col'}>
                                <div
                                  className={'p-3 border border-rounded text-center anchor ' + (bookingSelectedSource === data.value ? 'green-bg text-light' : 'bg-light')}
                                  onClick={() => this.setState({bookingSelectedSource: data.value})}>
                                  <FontAwesomeIcon className={''} icon={data.icon} size={'2x'}/>
                                  <p className={'small my-1'}>{data.text}</p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <hr/>
                        <div className="row">
                          <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                            <h6>Guest Details</h6>
                            {guestData && guestData.first_name ?
                              <GuestListView
                                data={guestData}
                                hideStatus={true}
                                action={() => this.showGuestDetails(guestData, false)}
                              />
                              :
                              <div>
                                {(this.state.bookingSelectedSource === null) &&
                                 <p className={'text-secondary'}>Select a booking source.</p>
                                }
                                <button
                                  disabled={!bookingSelectedSource}
                                  onClick={() => this.setState({showGuestManagement: true})}
                                  className={'btn btn-success btn-block mt-3'}>
                                  <FontAwesomeIcon icon={faPlusCircle} size={'sm'} className={'mr-2'}/> Add Guest
                                </button>
                              </div>
                            }

                          </div>
                          {(bookingSelectedSource === Constants.BOOKING_SOURCE.CORPORATE || bookingSelectedSource === Constants.BOOKING_SOURCE.AGENT) &&
                           <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                             <h6>Corporate / Agent Details</h6>
                             {corporateData && corporateData.name ?
                               <GuestListView
                                 data={corporateData}
                                 hideStatus={true}
                                 action={() => this.showGuestDetails(corporateData, false)}
                               />
                               :
                               <div>
                                 <button
                                   disabled={!bookingSelectedSource}
                                   onClick={() => this.setState({showProfileSearch: true})}
                                   className={'btn btn-success btn-block mt-3'}>
                                   <FontAwesomeIcon icon={faPlusCircle} size={'sm'} className={'mr-2'}/> Add Corporate
                                 </button>
                               </div>
                             }
                           </div>
                          }
                          {(bookingSelectedSource === Constants.BOOKING_SOURCE.OTA && !_.isEmpty(lookupresponse.ota_data)) &&
                           <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                             <h6>OTA</h6>
                             {OTAData && OTAData.name ?
                               <div
                                 role="presentation"
                                 onKeyPress={this.handleKeyPress}
                                 onClick={() => this.setState({showBookingOTASearch: true})}
                                 className="list-group-item list-group-item-action flex-column align-items-start a-class">
                                 <div className="d-flex w-100 justify-content-between">
                                   <div>
                                     <h5 className="mb-1 text-break">
                                       {OTAData.name}
                                     </h5>
                                   </div>
                                 </div>
                               </div>
                               :
                               <div>
                                 <button
                                   disabled={!bookingSelectedSource}
                                   onClick={() => this.setState({showBookingOTASearch: true})}
                                   className={'btn btn-success btn-block mt-3'}>
                                   <FontAwesomeIcon icon={faPlusCircle} size={'sm'} className={'mr-2'}/> Add OTA
                                 </button>
                               </div>
                             }
                           </div>
                          }
                        </div>
                        <hr/>
                        <h6>Confirmation Status</h6>
                        <p>Select the confirmation status for the booking.</p>
                        <div className={'row'}>
                          <div className={'col-lg-8 col-md-7 col-sm-12 mb-3'}>
                            <div className="btn-group btn-block mb-3" role="group">
                              <button
                                onClick={() => this.setState({bookingConfirmationStatus: false})}
                                className={'a btn ' + (bookingConfirmationStatus ? 'btn-outline-warning black-cl' : 'btn-warning')}>Provisional
                              </button>
                              <button
                                onClick={() => this.setState({bookingConfirmationStatus: true})}
                                className={'a btn ' + (bookingConfirmationStatus ? 'btn-success' : 'btn-outline-success')}>Confirm
                              </button>
                            </div>
                            {bookingConfirmationStatus ? (
                              <div>
                                {Strings.bookingAddConfirmed.map((data, i) => (
                                  <p className={'text-secondary mb-1'} key={i}>{data}</p>
                                ))}
                              </div>
                            ) : (
                              <div>
                                {Strings.bookingAddProvisional.map((data, i) => (
                                  <p className={'text-secondary mb-1'} key={i}>{data}</p>
                                ))}
                              </div>
                            )}
                          </div>
                          <div className={'col-lg-4 col-md-5 col-sm-12 mb-3'}>
                          </div>
                        </div>
                        <hr/>
                        <Formik
                          initialValues={{
                            booking_remarks: '',
                            booking_reference: 0,
                            booking_order_mask: false,
                            booking_visit_purpose: 0,
                            booking_reference_description: '',
                            booking_visit_purpose_description: ''
                          }}
                          validationSchema={formValidationBooking}
                          onSubmit={values => this.addBooking(values)}
                        >
                          {(formikProps) => (
                            <React.Fragment>
                              {(!_.isEmpty(property.config) && property.config.booking && property.config.booking.booking_advance_details) && (
                                <Form>
                                  <div className="mt-2">
                                    <div className="form-row">
                                      <div className="col-12 form-group">
                                        <Input
                                          name='booking_remarks'
                                          label={'Booking Remarks'}
                                          className={'form-control'}
                                          hint={'Additional information / remarks for the booking'}
                                        />
                                      </div>
                                    </div>
                                    <hr/>
                                    <div className="form-row">
                                      <div className="col-12 col-md-6 form-group">
                                        <div className="form-row">
                                          <div className="col-12 form-group">
                                            <Select
                                              label={'Visit Type'}
                                              name='booking_visit_purpose'
                                              className={'form-control'}
                                              options={Constants.BOOKING_PURPOSE_OF_VISIT}
                                              hint={'Guest visit Type.'}
                                              required={required_booking_fields.includes('booking_visit_purpose')}
                                            />
                                          </div>
                                          {(String(formikProps.values.booking_visit_purpose) === '7') && (
                                            <div className="col-12 form-group">
                                              <Input
                                                label={'Visit Type'}
                                                className={'form-control'}
                                                disabled={parseInt(formikProps.values.booking_visit_purpose) !== 7}
                                                name="booking_visit_purpose_description"
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6 form-group">
                                        <div className="form-row">
                                          <div className="col-12 form-group">
                                            <Select
                                              label={'Booking Reference'}
                                              name='booking_reference'
                                              className={'form-control'}
                                              options={Constants.BOOKING_REFERENCE}
                                              hint={'Reference for the booking if any.'}
                                            />
                                          </div>
                                          {(String(formikProps.values.booking_reference) !== '0') && (
                                            <div className="col-12 form-group">
                                              <Input
                                                label={'Reference Description'}
                                                className={'form-control'}
                                                name='booking_reference_description'
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-row">
                                      <div className="col-12 form-group">
                                        <Checkbox
                                          className={'mr-2'}
                                          name='booking_order_mask'
                                          text='Mask Booking Order'
                                          title='Order Visibility'
                                          hint={Strings.bookingOrderMaskText}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <hr/>
                                </Form>
                              )}
                              <div className="spacer-20"/>
                              <div className={'row'}>
                                <div className={'col-lg-12 col-md-12 col-sm-12 mb-3'}>
                                  <Alerts
                                    status={resultMsg.status}
                                    success={resultMsg.success}
                                    error={resultMsg.error}
                                  />
                                  {(this.state.guestData === null && this.state.corporateData === null) &&
                                  <p className={'text-center text-secondary'}>Select a booking source and guest to continue.</p>
                                  }
                                  <button
                                    disabled={(this.state.guestData === null && this.state.corporateData === null) || (resultMsg && resultMsg.status)}
                                    onClick={formikProps.handleSubmit}
                                    className={'btn btn-success btn-block btn-lg'}>
                                    <FontAwesomeIcon icon={faPlus} size={'lg'} className={'white-cl mr-2'}/> Add Booking
                                  </button>
                                </div>
                              </div>
                            </React.Fragment>
                          )}
                        </Formik>


                      </React.Fragment>
                    ) :
                    (
                      <div>
                        <div className="row">
                          <div className={'col-lg-12 col-md-12 col-sm-12 mb-3'}>
                            <Alerts
                              status={resultMsg.status}
                              success={resultMsg.success}
                              error={resultMsg.error}
                            />
                            <button
                              disabled={(resultMsg && resultMsg.status)}  onClick={() => this.addBooking()}
                              className={'btn btn-success btn-lg btn-block'}
                            >
                              <FontAwesomeIcon icon={faPlus} size={'lg'} className={'white-cl mr-2'}/>Add Room(s)
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <BookingOTASearch
          onHide={() => this.setState({showBookingOTASearch: false})}
          show={this.state.showBookingOTASearch}
          addOTAFunction={(data)=>this.setState({OTAData: data})}
          OTAList={lookupresponse.ota_data ? lookupresponse.ota_data : null}
        />
        <GuestQuickManage
          onHide={() => this.setState({showGuestManagement: false})}
          show={this.state.showGuestManagement}
          property={this.props.property}
          showGuestDetails={this.showGuestDetails}
          guestAddAction={this.updateBookingRoom}
          formValidation={formValidationGuest}
          addGuestFunction={(value) => this.setState({guestData: value})}
        />
        <GuestDetailsOverView
          guestId={this.state.showGuestDetailsId}
          onHide={() => this.setState({showGuestDetails: false})}
          show={this.state.showGuestDetails}
          addGuestFunction={this.state.addGuestToRoom}
          resultMsg={this.state.resultMsg}
          action={this.addProfileAction}
        />
        <ProfileQuickSearch
          onHide={() => this.setState({showProfileSearch: false})}
          show={this.state.showProfileSearch}
          showProfileDetails={this.showGuestDetails}
          isCorporate={this.state.bookingSelectedSource === Constants.BOOKING_SOURCE.CORPORATE}
          isAgent={this.state.bookingSelectedSource === Constants.BOOKING_SOURCE.AGENT}
          corporateBooking={true}
        />
      </div>
    );
  };

  addProfileAction = (data) => {
    if (data) {
      if (this.state.showGuestManagement) {
        this.setState({guestData: data, showGuestManagement: false, showGuestDetails: false});
      } else if (this.state.showProfileSearch) {
        this.setState({corporateData: data, showProfileSearch: false, showGuestDetails: false});
      } else {
        if (parseInt(data.profile_type) === Constants.PROFILE_TYPE.AGENT || parseInt(data.profile_type) === Constants.PROFILE_TYPE.CORPORATE) {
          this.setState({corporateData: null, showGuestDetails: false});
        } else {
          this.setState({guestData: null, showGuestDetails: false});
        }
      }
    }
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      minDate: moment().startOf('day'),
      maxDate: moment().subtract(2, 'days'),
      bookingActionDate: moment().startOf('day'),
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      results: [],
      showBookingOTASearch: false,
      OTAData: [],
      bookingConfirmationStatus: true,
      bookingSelectedSource: null,
      bookingAction: false,
      showGuestManagement: false,
      showGuestDetails: false,
      showGuestDetailsId: null,
      addGuestToRoom: false,
      showProfileSearch: false,
      guestData: null,
      corporateData: null,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingAdd);
