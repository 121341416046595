/**
 * Manage Report Add
 *  manages report add
 */
import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';
import {Collapse, Modal} from 'react-bootstrap';
import DateRangePicker from 'react-daterange-picker';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import {Checkbox, Select} from 'react-formik-ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleDown, faPlus, faTimes} from '@fortawesome/free-solid-svg-icons';
import Moment from 'moment';
import queryString from 'query-string';

/* Component ==================================================================== */

class ReportAdd extends React.Component {
  static componentName = 'ReportAdd';

  static propTypes = {
    pos: PropTypes.object,
    match: PropTypes.object,
    event: PropTypes.object,
    escalated: PropTypes.bool,
    source: PropTypes.number,
    dateType: PropTypes.func,
    property: PropTypes.object,
    updateResponse: PropTypes.func,
    reportOptions: PropTypes.object,
  };

  static defaultProps = {
    reportOptions: [],
    dateType: Constants.REPORTS_DATE_TYPE
  }

  addReport = (credentials) => {
    const {pos, event, source} = this.props;
    const {startDate, endDate} = this.state;
    let baseUrl = 'report-request', apiUrl, urlParams = {};

    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});
      const report_attributes = {};
      const report_attrs = [
        'tax_breakup', 'bk_date_wise', 'bk_order_data', 'bk_payment_data', 'booking_status',
        'or_tax_data', 'or_date_wise', 'or_open_order', 'or_payment_data', 'or_order_status', 'or_provisional_booking',
        'or_tax_total_data', 'or_booking_source', 'or_external_order',
        'pos_tax_data', 'pos_date_wise', 'pos_open_order', 'pos_payment_data', 'pos_order_status', 'pos_pos_order_status',
        'pos_tax_total_data', 'pos_order_item_type', 'pos_content_type_data',
        'pa_date_wise', 'pa_payment_terminal', 'pa_refund_status', 'pa_payment_status', 'pa_payment_type',
        'pa_payment_method', 'pa_settlement_status', 'pa_order_details', 'pa_booking_details', 'pa_order_external_order',
        'pa_booking_date',
        'ex_tax_data', 'ex_date_wise', 'ex_open_order', 'ex_payment_data', 'ex_tax_total_data', 'ex_order_status'
      ];

      report_attrs.forEach((data) => {
        if (credentials[`${data}`] !== null && credentials[`${data}`] !== ''){
          report_attributes[`${data}`] = credentials[`${data}`];
        }
      });

      if (credentials.order_source_selection && credentials.or_order_source) {
        report_attributes['or_order_source'] = credentials.or_order_source;
      }

      if (credentials.order_booking_source_selection && credentials.or_booking_source) {
        report_attributes['or_booking_source'] = credentials.or_booking_source;
      }

      const payload = {
        source: source,
        report: credentials.report,
        date_type: credentials.date_type,
        report_attributes: report_attributes,
        start_date: Moment(startDate).format('YYYY-MM-DD'),
        end_date: endDate ? Moment(endDate).format('YYYY-MM-DD') : null
      };

      if (pos) {
        payload.pos = pos.id;
        payload.source = Constants.REPORT_SOURCE.POINT_OF_SALE;
      }

      if (event) {
        payload.pos = event.id;
        payload.source = Constants.REPORT_SOURCE.EVENT;
      }

      if (pos) {
        apiUrl = AppAPI.posapi;
        baseUrl = 'pos-report-request';
        urlParams['pos'] = pos.hash;
      } else if (event) {
        baseUrl = 'event-report-request';
        apiUrl = AppAPI.eventapi;
        urlParams['event'] = event.hash;
      } else {
        apiUrl = AppAPI.reportapi;
      }

      if (this.props.escalated) {
        baseUrl = 'report-request-escalated';
      }

      apiUrl
        .post(`${baseUrl}/?${queryString.stringify(urlParams)}`, payload)
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
              if (this.props.updateResponse) {
                this.props.updateResponse(res);
              }
              this.props.onHide();
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        selectionType: 'range',
        endDate: null,
        startDate: null,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  onSelect = (date) => {
    const {selectionType} = this.state;
    if (selectionType === 'range') {
      this.setState({
        startDate: date.start,
        endDate: date.end,
      });
    } else {
      this.setState({startDate: date});
    }
  };

  handleReportOptions = (event) => {
    const {reportOptions} = this.props;

    reportOptions.forEach((data) => {
      if (data.value === event.target.value) {
        // Date Selection Type
        if (data.dateSelectionType && data.dateSelectionType === 'single') {
          this.setState({selectionType: 'single', endDate: null, selection: false});
        } else {
          this.setState({selectionType: 'range', endDate: this.state.startDate, selection: true});
        }
        if (data.dateType) {
          this.setState({stateDateType: data.dateType});
        } else {
          this.setState({stateDateType: null});
        }
      }
    });
  }

  handleDateOptions = (event) => {
    const {dateType} = this.props;
    const {stateDateType} = this.state;

    const currentDateType = stateDateType || dateType;
    currentDateType.forEach((data) => {
      if (data.value === parseInt(event.target.value)) {
        this.setState({
          maxDate: typeof data.maxDate !== 'undefined' ? data.maxDate: Moment().startOf('day')
        });
      }
    });
  }

  render = () => {

    const {resultMsg, selectionType, startDate, endDate, selection, stateDateType, minDate, maxDate} = this.state;
    const {show, reportOptions, source, dateType} = this.props;

    if (!show) return <React.Fragment/>;

    const formValidation = Yup.object().shape({
      report: Yup.string().min(2, 'Too Short!').required('Report type required.+'),
      date_type: Yup.number().required('Report date type required.+'),
    });

    const initialValues = {
      report: '',
      date_type: 1,
    };

    let additionalSettings;
    if (source === Constants.REPORT_SOURCE.POINT_OF_SALE) {
      initialValues.pos_pos_order_status = 3;
      initialValues.pos_order_status = 1;
      initialValues.pos_order_item_type = 0;
      additionalSettings = (formikProps) => (
        <React.Fragment>
          <div className="form-row">
            {(formikProps.values.report === 'pos_order_consol') ?
              <React.Fragment>
                <div className="col-lg-6 form-group">
                  <Select
                    className={'form-control rounded-right-0'}
                    options={Constants.ORDER_STATUS_INPUT}
                    name='pos_order_status'
                    label={'Order Status'}
                    placeholder={'All Order Status'}
                    hint={'Payment status of orders to be exported.'}
                  />
                </div>
                <div className="col-lg-6 form-group">
                  <Checkbox
                    disabled={formikProps.values.or_order_status !== '0' && formikProps.values.or_order_status}
                    className={'mr-2'}
                    label={'Order Inclusion'}
                    name='pos_open_order'
                    text='Include Open order'
                    hint={'Includes open orders only applicable for not paid.'}
                  />
                </div>
              </React.Fragment>
              :
              <React.Fragment>
                <div className="col-lg-6 form-group">
                  <Select
                    className={'form-control rounded-right-0'}
                    options={Constants.POS_ORDER_STATUS_INPUT}
                    name='pos_pos_order_status'
                    label={'POS Order Status'}
                    placeholder={'All Order Status'}
                    hint={'Status of orders to be exported.'}
                  />
                </div>
              </React.Fragment>
            }
            {['pos_order_items', 'pos_order_items_consol', 'pos_order_tabs_consol'].includes(formikProps.values.report) &&(
              <div className="col-lg-6 form-group">
                <Select
                  className={'form-control rounded-right-0'}
                  options={Constants.ORDER_STATUS_INPUT}
                  name='or_order_status'
                  label={'Order Status'}
                  placeholder={'All Order Status'}
                  hint={'Payment status of orders to be exported.'}
                />
              </div>
            )}
            {!['pos_order_items', 'pos_order_items_consol', 'posk_consol'].includes(formikProps.values.report) &&
              <React.Fragment>
                <div className="col-lg-6 form-group">
                  <Checkbox
                    className={'mr-2'}
                    label={'Date Filter'}
                    name='pos_date_wise'
                    text='Date Split'
                    hint={'Splits order(s) into new worksheets based on date.'}
                  />
                </div>
                <div className="col-lg-6 col-md-6 form-group">
                  <Checkbox
                    label='Payment Information'
                    className={'mr-2'}
                    name='pos_payment_data'
                    text='Payment Data'
                    hint={'Include payment data.'}
                  />
                </div>
                <div className="col-lg-6 col-md-6 form-group">
                  <Checkbox
                    label='Type Information'
                    className={'mr-2'}
                    name='pos_content_type_data'
                    text='Content Total Data'
                    hint={'Content type breakup of total.'}
                  />
                </div>
              </React.Fragment>
            }
            {formikProps.values.report === 'pos_order_items' &&
            <React.Fragment>
              <div className="col-lg-6 form-group">
                <Select
                  className={'form-control rounded-right-0'}
                  options={Constants.POS_ORDER_ITEM_TYPE}
                  name='pos_order_item_type'
                  label={'Item Deduction Type'}
                  hint={'Deduction status of item to be exported.'}
                />
              </div>
            </React.Fragment>
            }
            {formikProps.values.report !== 'pos_order_items' &&
              <React.Fragment>
                <div className="col-lg-6 col-md-6 form-group">
                  <Checkbox
                    label={'Tax Information'}
                    className={'mr-2'}
                    name='pos_tax_data'
                    text='Tax Data'
                    hint={'Include tax class level breakup data.'}
                  />
                </div>
                <div className="col-lg-6 col-md-6 form-group">
                  <Checkbox
                    disabled={!formikProps.values.pos_tax_data}
                    label={'Tax Total Breakup'}
                    className={'mr-2'}
                    name='pos_tax_total_data'
                    text='Tax Total Data'
                    hint={'Include tax wise total breakup data.'}
                  />
                </div>
              </React.Fragment>
            }
          </div>
        </React.Fragment>
      );
    }

    if (source === Constants.REPORT_SOURCE.ORDER) {
      initialValues.or_order_status = 1;
      additionalSettings = (formikProps) => (
        <React.Fragment>
          <div className="form-row">
            <div className="col-lg-6 form-group">
              <Select
                className={'form-control rounded-right-0'}
                options={Constants.ORDER_STATUS_INPUT}
                name='or_order_status'
                label={'Order Status'}
                placeholder={'All Order Status'}
                hint={'Status of orders to be exported.'}
              />
            </div>
            {(formikProps.values.report === 'or_order_consol') &&
            <div className="col-lg-6 form-group">
              <Checkbox
                disabled={formikProps.values.or_order_status !== '0' && formikProps.values.or_order_status}
                className={'mr-2'}
                label={'Order Inclusion'}
                name='or_open_order'
                text='Include Open order'
                hint={'Includes open orders only applicable for not paid.'}
              />
            </div>
            }
            {(['or_booking_consol', 'or_booking_ledger'].includes(formikProps.values.report)) &&
              <React.Fragment>
                <div className="col-lg-6 form-group">
                  <Checkbox
                    className={'mr-2'}
                    label={'Booking type'}
                    text={'Provisional Booking'}
                    name='or_provisional_booking'
                    hint={'Include provisional booking data.'}
                  />
                </div>
                <div className="col-lg-12 form-group">
                  <Checkbox
                    className={'mr-2'}
                    label={'Order Inclusion'}
                    text={'Include External Order'}
                    name='or_external_order'
                    hint={'Will include booking orders from external source [ Channel Manager / OTA ].'}
                  />
                </div>
                <React.Fragment>
                  <div className="col-lg-12 form-group">
                    <Checkbox
                      className={'mr-2'}
                      label={'Booking Filter'}
                      name='order_booking_source_selection'
                      text='Select Booking Sources'
                      hint={'Filter export data by booking source.'}
                    />
                  </div>
                  <div className="col-lg-12 form-group">
                    <Select
                      disabled={!formikProps.values.order_booking_source_selection}
                      className={'form-control rounded-right-0'}
                      multiple
                      options={Constants.REPORT_BOOKING_SOURCES}
                      name='or_booking_source'
                      label={'Booking Source'}
                      hint={'Select the sources of booking to be included in report, by default all sources are included.'}
                    />
                  </div>
                </React.Fragment>
              </React.Fragment>
            }
            {(formikProps.values.report === 'or_order_ledger') &&
              <React.Fragment>
                <div className="col-lg-12 form-group">
                  <Checkbox
                    className={'mr-2'}
                    label={'Source Filter'}
                    name='order_source_selection'
                    text='Select Sources'
                    hint={'Filter export data by source.'}
                  />
                </div>
                <div className="col-lg-12 form-group">
                  <Select
                    disabled={!formikProps.values.order_source_selection}
                    className={'form-control rounded-right-0'}
                    multiple
                    options={Constants.REPORT_ORDER_SOURCES}
                    name='or_order_source'
                    label={'Order Source'}
                    hint={'Select the sources of order to be included in report, by default all sources are included.'}
                  />
                </div>
              </React.Fragment>
            }
            {(formikProps.values.report === 'or_order_consol') &&
            <div className="col-lg-6 form-group">
              <Checkbox
                className={'mr-2'}
                label={'Date Filter'}
                name='or_date_wise'
                text='Date Split'
                hint={'Splits order(s) into new worksheets based on date.'}
              />
            </div>
            }
          </div>
          {(formikProps.values.report === 'or_order_consol') &&
            <div className="form-row">
              <div className="col-lg-6 col-md-6 form-group">
                <Checkbox
                  label={'Tax Information'}
                  className={'mr-2'}
                  name='or_tax_data'
                  text='Tax Data'
                  hint={'Include tax class level breakup data.'}
                />
              </div>
              <div className="col-lg-6 col-md-6 form-group">
                <Checkbox
                  disabled={!formikProps.values.or_tax_data}
                  label={'Tax Total Breakup'}
                  className={'mr-2'}
                  name='or_tax_total_data'
                  text='Tax Total Data'
                  hint={'Include tax wise total breakup data.'}
                />
              </div>
              <div className="col-lg-6 col-md-6 form-group">
                <Checkbox
                  label='Payment Information'
                  className={'mr-2'}
                  name='or_payment_data'
                  text='Payment Data'
                  hint={'Include payment data.'}
                />
              </div>
            </div>
          }
        </React.Fragment>
      );
    }

    if (source === Constants.REPORT_SOURCE.EXPENSE) {
      initialValues.ex_order_status = 1;
      additionalSettings = (formikProps) => (
        <React.Fragment>
          <div className="form-row">
            <div className="col-lg-6 form-group">
              <Select
                className={'form-control rounded-right-0'}
                options={Constants.ORDER_STATUS_INPUT}
                name='ex_order_status'
                label={'Expense Status'}
                placeholder={'All expense status'}
                hint={'Status of expense to be exported.'}
              />
            </div>
            {(formikProps.values.report === 'ex_expense_consol') &&
            <div className="col-lg-6 form-group">
              <Checkbox
                disabled={formikProps.values.ex_order_status !== '0' && formikProps.values.ex_order_status}
                className={'mr-2'}
                label={'Expense Inclusion'}
                name='ex_open_order'
                text='Include Open expense'
                hint={'Includes open expense only applicable for not paid.'}
              />
            </div>
            }

            {(formikProps.values.report === 'ex_expense_consol') &&
            <div className="col-lg-6 form-group">
              <Checkbox
                className={'mr-2'}
                label={'Date Filter'}
                name='ex_date_wise'
                text='Date Split'
                hint={'Splits expense(s) into new worksheets based on date.'}
              />
            </div>
            }
          </div>
          {(formikProps.values.report === 'ex_expense_consol') &&
          <div className="form-row">
            <div className="col-lg-6 col-md-6 form-group">
              <Checkbox
                label={'Tax Information'}
                className={'mr-2'}
                name='ex_tax_data'
                text='Tax Data'
                hint={'Include tax class level breakup data.'}
              />
            </div>
            <div className="col-lg-6 col-md-6 form-group">
              <Checkbox
                disabled={!formikProps.values.ex_tax_data}
                label={'Tax Total Breakup'}
                className={'mr-2'}
                name='ex_tax_total_data'
                text='Tax Total Data'
                hint={'Include tax wise total breakup data.'}
              />
            </div>
            <div className="col-lg-6 col-md-6 form-group">
              <Checkbox
                label='Payment Information'
                className={'mr-2'}
                name='ex_payment_data'
                text='Payment Data'
                hint={'Include payment data.'}
              />
            </div>
          </div>
          }
        </React.Fragment>
      );
    }

    if (source === Constants.REPORT_SOURCE.BOOKING) {
      additionalSettings = (formikProps) => (
        <>
          {(formikProps.values.report !== 'bk_guest_consol') && (
            <React.Fragment>
              <div className="form-row">
                <div className="col-lg-6 col-md-6 form-group">
                  <Checkbox
                    className={'mr-2'}
                    name='bk_order_data'
                    text='Order Data'
                    hint={'Include order data.'}
                  />
                </div>
                <div className="col-lg-6 col-md-6 form-group">
                  <Checkbox
                    disabled={!formikProps.values.bk_order_data}
                    className={'mr-2'}
                    name='bk_payment_data'
                    text='Payment Data'
                    hint={'Include payment data. (requires order data)'}
                  />
                </div>
              </div>
              <hr/>
              <div className="form-row">
                <div className="col-lg-6 col-md-6 form-group">
                  <Checkbox
                    className={'mr-2'}
                    name='bk_date_wise'
                    text='Date Split'
                    hint={'Splits booking(s) into new worksheets based on date.'}
                  />
                </div>
              </div>
            </React.Fragment>
          )}
          <hr/>
          <div className="form-row">
            <div className="col-lg-6 form-group">
              <Select
                className={'form-control rounded-right-0'}
                options={Constants.BOOKING_STATUS_INPUT}
                name='booking_status'
                label={'Booking Status'}
                placeholder={'All Status'}
                hint={'Status of booking to be exported.'}
              />
            </div>
          </div>
        </>
      );
    }

    if (source === Constants.REPORT_SOURCE.PAYMENT) {
      additionalSettings = (formikProps) => (
        <React.Fragment>
          <div className="form-row">
            {(formikProps.values.report !== 'pa_payment_booking_consol') && (
              <div className="col-lg-6 col-md-6 form-group">
                <Checkbox
                  className={'mr-2'}
                  name='pa_date_wise'
                  text='Date Split'
                  hint={'Splits entry(s) into new worksheets based on date.'}
                />
              </div>
            )}
            {(formikProps.values.report === 'pa_payment_consol' || formikProps.values.report === 'pa_refund_consol') &&
            <div className="col-lg-6 form-group">
              <Select
                className={'form-control rounded-right-0'}
                options={Constants.PAYMENT_METHODS_INPUTS}
                name='pa_payment_method'
                label={'Payment Method'}
                placeholder={'All Payment Methods'}
                hint={'Payment method to be exported.'}
              />
            </div>
            }
          </div>
          {(formikProps.values.report === 'pa_payment_booking_consol') && (
            <React.Fragment>
              <div className="form-row">
                <div className="col-lg-6 form-group">
                  <Checkbox
                    className={'mr-2'}
                    name='pa_order_external_order'
                    text='External Order'
                    hint={'Include Payments for External Details (OTA/Channel Manager).'}
                  />
                </div>
                <div className="col-lg-6 form-group">
                  <Select
                    className={'form-control rounded-right-0'}
                    options={Constants.REPORTS_DATE_TYPE_LEDGER_BOOKING}
                    name='pa_booking_date'
                    label={'Booking Sort Date'}
                    placeholder={'Created'}
                    hint={'Date to sort bookings.'}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-lg-6 form-group">
                  <Checkbox
                    className={'mr-2'}
                    name='pa_order_details'
                    text='Order Details'
                    hint={'Include Order Details associated with the payment.'}
                  />
                </div>
                <div className="col-lg-6 form-group">
                  <Checkbox
                    className={'mr-2'}
                    name='pa_booking_details'
                    text='Booking Details'
                    hint={'Include Booking Details associated with the payment.'}
                  />
                </div>
              </div>
            </React.Fragment>
          )}
          {(['pa_payment_consol', 'pa_payment_booking_consol'].includes(formikProps.values.report)) &&
          <React.Fragment>
            <hr/>
            <div className="form-row">
              <div className="col-lg-6 form-group">
                <Select
                  className={'form-control rounded-right-0'}
                  options={Constants.REFUND_STATUS_INPUT}
                  name='pa_refund_status'
                  label={'Refund Status'}
                  placeholder={'All Status'}
                  hint={'Refund status to be exported.'}
                />
              </div>
              <div className="col-lg-6 form-group">
                <Select
                  className={'form-control rounded-right-0'}
                  options={Constants.SETTLEMENT_STATUS_INPUT}
                  name='pa_settlement_status'
                  label={'Settlement Status'}
                  placeholder={'All Status'}
                  hint={'Payment status to be exported.'}
                />
              </div>
            </div>
          </React.Fragment>
          }

          {(formikProps.values.report === 'pa_refund_consol') &&
          <React.Fragment>
            <hr/>
            <div className="form-row">
              <div className="col-lg-6 form-group">
                <Select
                  className={'form-control rounded-right-0'}
                  options={Constants.PAYMENT_METHODS_INPUTS}
                  name='pa_payment_method'
                  label={'Refund Method'}
                  placeholder={'All Refund Methods'}
                  hint={'Refund method to be exported.'}
                />
              </div>
            </div>
          </React.Fragment>
          }

        </React.Fragment>
      );
    }

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Generate Report
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={formValidation}
          onSubmit={values => this.addReport(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <Form className={'form-group'}>
                      <div className="form-row">
                        <div className="col-12 col-md-6 form-group">
                          <Select
                            className={'form-control rounded-right-0'}
                            name='report'
                            label={'Report Type'}
                            placeholder='Select an Option'
                            options={reportOptions}
                            onChange={(event) => {
                              this.handleReportOptions(event);
                              formikProps.setFieldValue('report', event.target.value);
                            }}
                          />
                        </div>
                        <div className="col-12 col-md-6 form-group">
                          <Select
                            className={'form-control rounded-right-0'}
                            name='date_type'
                            label={'Date Type'}
                            placeholder='Select an Option'
                            options={stateDateType || dateType}
                            onChange={(event) => {
                              this.handleDateOptions(event);
                              formikProps.setFieldValue('date_type', event.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-12 form-group">
                          <p className={'mb-1'}>Date Selection Type</p>
                          <div className="btn-group btn-group-sm w-100 mb-2" role="group">
                            <button
                              type="button"
                              disabled={true}
                              className={'btn btn-outline-secondary'}
                            >
                              Date Selection
                            </button>
                            <button
                              type="button"
                              disabled={!selection}
                              className={`btn ${selectionType === 'single' ? 'btn-primary' : 'btn-outline-secondary'}`}
                              onClick={() => this.setState({selectionType: 'single', endDate: null})}
                            >
                              Single
                            </button>
                            <button
                              type="button"
                              disabled={!selection}
                              className={`btn ${selectionType === 'range' ? 'btn-primary' : 'btn-outline-secondary'}`}
                              onClick={() => this.setState({selectionType: 'range', endDate: this.state.startDate})}
                            >
                              Range
                            </button>
                          </div>
                          <p className={'mb-1'}>Report date</p>
                          <DateRangePicker
                            className={'mx-auto w-100 border'}
                            selectionType={selectionType}
                            onSelect={this.onSelect}
                            numberOfCalendars={2}
                            value={selectionType === 'single' ? (startDate && startDate) : (startDate && endDate && Moment.range(startDate, endDate))}
                            maximumDate={maxDate}
                            minimumDate={minDate}
                          />
                        </div>
                      </div>
                      {additionalSettings &&
                      <React.Fragment>
                        <div
                          className={'border p-2 text-center anchor mt-3'}
                          onClick={() => this.setState({advanceSettings: !this.state.advanceSettings})}
                        >
                          <h6 className={'mb-0'}>
                            <FontAwesomeIcon
                              icon={faChevronCircleDown} size={'sm'} className={'mx-2 green-cl float-left mt-1'}/>
                            Advance Settings (Optional)
                            <FontAwesomeIcon
                              icon={faChevronCircleDown} size={'sm'} className={'mx-2 green-cl float-right mt-1'}/>
                          </h6>
                        </div>
                        <Collapse in={this.state.advanceSettings}>
                          <div className={'py-2 border p-2'}>
                            {additionalSettings(formikProps)}
                          </div>
                        </Collapse>
                      </React.Fragment>
                      }
                    </Form>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <button
                      disabled={!startDate}
                      type="submit" onClick={formikProps.handleSubmit} className={'btn btn-success btn-lg btn-block'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Generate Report
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      selection: true,
      selectionType: 'range',
      endDate: null,
      startDate: null,
      minDate: null,
      maxDate: Moment().startOf('day'),
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      advanceSettings: false,
    };
  }
}

/* Export Component ==================================================================== */
export default ReportAdd;
