/**
 * PTerminal Devices list
 *  Lists Pterminal devices
 *
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Strings} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCashRegister} from '@fortawesome/free-solid-svg-icons';
// Forms
import {Form, Formik} from 'formik';
import {Select, SubmitBtn} from 'react-formik-ui';

/* Component ==================================================================== */
class PTerminalDevices extends Component {
  static componentName = 'PTerminalDevices';

  static propTypes = {
    action: PropTypes.func,
    payment: PropTypes.object.isRequired,
    PTerminalDevices: PropTypes.array.isRequired,
  };

  static defaultProps = {
    disabled: false,
  }

  initPTerminal = terminal => {
    if (terminal) {
      const payload = {
        'payment': this.props.payment.hash,
        'pterminal_device': terminal.device_hash,
        'payment_mode': terminal.payment_mode || null,
      };
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.pterminalapi.post('pterminal/', payload)
          .then((res) => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                // this.props.history.push(`/payment/${payment.hash}/payment-terminal`);
                if (this.props.action) {
                  this.props.action(res);
                }
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  render = () => {
    const {PTerminalDevices} = this.props;
    const {resultMsg} = this.state;
    return (
      <div className={'row mb-4'}>
        <div className={'col-12'}>
          <Alerts status={resultMsg.status} success={resultMsg.success} error={resultMsg.error} />
          <table className={'table border table-sm format-table'}>
            <thead>
              <tr><th colSpan={2}><div className={'text-center'}>Payment Terminal</div></th></tr>
            </thead>
            <tbody>
              {!_.isEmpty(PTerminalDevices) ?
                <React.Fragment>
                  {PTerminalDevices.map((data, i)=>(
                    <tr key={i} className={'tr-align-middle'}>
                      <td>
                        <strong>{data.name}</strong> <br/>
                        <p className={'mb-0 text-muted small'}>Serial : {data.device_serial}</p>
                      </td>
                      <td className={'data-table-cell-lg'}>
                        <Formik
                          initialValues={{
                            device_hash: data.hash,
                            payment_mode: ''
                          }}
                          onSubmit={(values)=>this.initPTerminal(values)}
                        >
                          <Form>
                            <ul className={'list-inline mb-0'}>
                              {!_.isEmpty(data.payment_modes_list) &&
                                <li className={'list-inline-item data-table-cell-lg'}>
                                  <Select
                                    name='payment_mode'
                                    className={'form-control'}
                                    options={data.payment_modes_list}
                                  />
                                </li>
                              }
                              <li className={'list-inline-item data-table-cell-sm'}>
                                <SubmitBtn
                                  disabled={!data.accept_payment || !_.isEmpty(resultMsg.status)}
                                  className={'btn btn-sm btn-success'}
                                >
                                  Initiate
                                </SubmitBtn>
                              </li>
                            </ul>
                          </Form>
                        </Formik>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
                :
                <tr>
                  <td colSpan={2}>
                    <div className={'py-5 text-center'}>
                      <h6><FontAwesomeIcon icon={faCashRegister} className={'mr-2'}/> No Device(s) </h6>
                      <p className={'mb-0'}>{Strings.PTerminalDevicesListEmpty}</p>
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default (PTerminalDevices);

