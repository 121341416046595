/**
 * NoteDetails / Edit / Actions
 * Preview notes / add or edit notes / perform actions
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {Form, Formik} from 'formik';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import {Constants, Strings} from '../../constants';
// Components
import {Alerts, History} from '../../components/ui';
import Loading from '../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Input, Select, SubmitBtn, Textarea} from 'react-formik-ui';
import {
  faArrowLeft,
  faCheckCircle,
  faPen,
  faPlus,
  faSave,
  faStickyNote,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import {confirmAlert} from '../../components/general/ConfirmAlert';


/* Component ==================================================================== */

class NotesManager extends Component {
  static componentName = 'NotesManager';

  static propTypes = {
    note: PropTypes.object,
    order: PropTypes.object,
    expense: PropTypes.object,
    booking: PropTypes.object,
    successAction: PropTypes.func,
    bookingRoom: PropTypes.object,
    ticketBooking: PropTypes.object,
    POSOrderKOT: PropTypes.object,
    POSOrder: PropTypes.object,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.initData();
    }
    return true;
  }


  initData = () => {
    let ApiURL = null, preview = false, ApiPath = 'notes';
    const {note} = this.props;
    if (this.props.booking || (note && note.booking_id)) {
      ApiURL = AppAPI.bookingapi;
    }
    if (this.props.order || (note && note.order_id)) {
      ApiURL = AppAPI.orderapi;
    }
    if (this.props.bookingRoom || (note && note.booking_room_id)) {
      ApiURL = AppAPI.bookingroom;
    }
    if (this.props.ticketBooking || (note && note.ticket_booking_id)) {
      ApiURL = AppAPI.eventapi;
    }

    if (this.props.expense || (note && note.expense_id)) {
      ApiURL = AppAPI.expenseapi;
    }

    if (this.props.POSOrderKOT || (note && note.pos_order_kot_id)) {
      ApiURL = AppAPI.posapi;
      ApiPath = 'pos-order-kot-notes';
    }

    if (this.props.POSOrder || (note && note.pos_order_id)) {
      ApiURL = AppAPI.posapi;
      ApiPath = 'pos-order-notes';
    }

    let initialValues = {
      title: '',
      description: '',
      type: Constants.NOTES_TYPE.NOTE,
    };

    if (note) {
      preview = true;
      initialValues = {
        title: this.props.note.title,
        type: this.props.note.note_type,
        description: this.props.note.description,
      };
    }
    this.setState({
      ApiURL,
      preview,
      ApiPath,
      initialValues,
      loading: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    });
  };

  addNotes = (credentials) => {
    if (credentials && this.state.ApiURL) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        this.state.ApiURL.post(`${this.state.ApiPath}/`, {
          'title': credentials.title,
          'description': credentials.description,
          'note_type': credentials.type,
          'order': this.props.order ? this.props.order.id : null,
          'booking': this.props.booking ? this.props.booking.id : null,
          'booking_room': this.props.bookingRoom ? this.props.bookingRoom.id : null,
          'expense': this.props.expense ? this.props.expense.id : null,
          'ticket_booking': this.props.ticketBooking ? this.props.ticketBooking.id : null,
          'pos_order': this.props.POSOrder ? this.props.POSOrder.id : null,
          'pos_order_kot': this.props.POSOrderKOT ? this.props.POSOrderKOT.id : null,
        })
          .then(() => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                if (this.props.successAction) {
                  this.props.successAction();
                }
                setTimeout(() => this.props.onHide(), 600);
              });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  saveNotes = (credentials) => {
    if (credentials) {
      this.updateNotes({
        'title': credentials.title,
        'description': credentials.description,
        'note_type': credentials.type,
      });
    }
  };

  updateNotes = (credentials) => {
    const {note} = this.props;
    if (credentials && this.state.ApiURL && note) {
      this.setState({resultMsg: {status: 'One moment...'}});
      this.state.ApiURL.patch(`${this.state.ApiPath}/${note.hash}/`, credentials)
        .then(() => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              if (this.props.successAction) {
                this.props.successAction();
              }
              this.props.onHide();
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                this.props.onHide();
              }, 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };


  render = () => {

    const {loading, resultMsg, preview, initialValues} = this.state;
    const {note, show} = this.props;

    if (!show) return <React.Fragment/>;

    if (loading) return <Loading/>;

    const formValidation = Yup.object().shape({
      title: Yup.string().max(200, 'Too Long').required('Enter a valid title'),
      type: Yup.string().min(1, 'Too Short!').required('Select note type'),
    });

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <FontAwesomeIcon icon={faStickyNote} size={'sm'} className={'mr-2'}/>
            {note ? `Note Details : ${note.title}` : 'Add Note'}
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={formValidation}
          onSubmit={values => note ? this.saveNotes(values) : this.addNotes(values)}
        >
          {() => (
            <React.Fragment>
              <Modal.Body>
                {(preview && note) ?
                  <div className={'notes-details'}>
                    <div className={'row'}>
                      <div className={'col-lg-6 col-sm-12'}>
                        <table className={'table table-sm table-borderless'}>
                          <tbody>
                            <tr>
                              <td className={'w-25 text-muted'}>Type</td>
                              <td>
                                <span
                                  className={'badge ' + (note.note_type === Constants.NOTES_TYPE.ACTION ? 'badge-warning' : 'badge-info')}>
                                  {note.note_type_display}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className={'w-25 text-muted'}>Time</td>
                              <td className={'small align-middle'}>{AppUtil.formatDateTime(note.created)}</td>
                            </tr>
                            {note.action_status_time &&
                            <tr>
                              <td className={'w-25 text-muted'}>Completed</td>
                              <td
                                className={'small align-middle'}>{AppUtil.formatDateTime(note.action_status_time)}</td>
                            </tr>
                            }
                          </tbody>
                        </table>
                      </div>
                      <div className={'col-lg-6 col-sm-12'}>
                        <table className={'table table-sm table-borderless'}>
                          <tbody>
                            <tr>
                              <td className={'w-25 text-muted'}>Source</td>
                              <td>{note.source_display}</td>
                            </tr>
                            <tr>
                              <td className={'w-25 text-muted'}>Created</td>
                              <td>{(!_.isEmpty(note.attributes) && note.attributes.created_user) ? note.attributes.created_user : '...'}</td>
                            </tr>
                            {note.attributes && note.attributes.action_user &&
                            <tr>
                              <td className={'w-25 text-muted'}>Action User</td>
                              <td>{(!_.isEmpty(note.attributes) && note.attributes.action_user) ? note.attributes.action_user : '...'}</td>
                            </tr>
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className={'p-1'}>
                      <h6>Content</h6>
                      <div className={'border p-3 grey-light-bg rounded'}>
                        <p>{note.description || 'N.A'}</p>
                      </div>

                      {(note.note_type === Constants.NOTES_TYPE.ACTION) &&
                      <React.Fragment>
                        <button
                          className={`mt-2 btn btn-lg mt-4 ${note.action_status ? 'btn-outline-success' : 'btn-success'} btn-block`}
                          disabled={note.action_status}
                          onClick={() => {
                            confirmAlert({
                              title: 'Confirm Action',
                              message: Strings.confirmNotes,
                              buttons: [
                                {
                                  className: 'btn-success',
                                  label: 'Confirm',
                                  onClick: () => this.updateNotes({'action_status': true}),
                                },
                                {
                                  className: 'btn-secondary',
                                  label: 'Exit',
                                },
                              ],
                            });
                          }}
                        >
                          <FontAwesomeIcon className={'mr-2'} icon={faCheckCircle} size={'sm'}/>
                          {note.action_status ? 'Completed' : 'Mark Task Complete'}
                          {note.action_status_time && ` on ${AppUtil.formatDateTime(note.action_status_time)}.`}
                        </button>
                      </React.Fragment>
                      }
                      <History object={note}/>
                    </div>
                  </div>
                  :
                  <div className={'notes-manage'}>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                        <Form className={'form-group'}>
                          <div className="form-row">
                            <div className="col-6 form-group">
                              <Input
                                name='title'
                                label={'Title'}
                                className={'form-control'}
                              />
                            </div>
                            <div className="col-6 form-group">
                              <Select
                                className={'form-control'}
                                name='type'
                                label={'Type'}
                                placeholder='Select an Option'
                                options={Constants.NOTES_TYPE_INPUTS}
                              />
                            </div>
                            <div className="col-12 form-group">
                              <Textarea
                                name={'description'}
                                label={'Description'}
                                className={'form-control'}
                              />
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                }
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    {(!preview && note) ?
                      <button
                        className={'btn btn-secondary  btn-block'}
                        onClick={() => this.setState({preview: true})}
                      >
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faArrowLeft} size={'sm'}/> Back
                      </button>
                      :
                      <button className={'btn btn-secondary  btn-block'} onClick={this.props.onHide}>
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                      </button>
                    }
                  </div>

                  <div className={'col'}>
                    {(preview && note) ?
                      <button
                        disabled={note.action_status || note.source === Constants.NOTES_SOURCE.EXTERNAL}
                        className={'btn btn-block btn-info'}
                        onClick={() => this.setState({preview: false})}
                      >
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faPen} size={'sm'}/>
                        Edit
                      </button>
                      :
                      <SubmitBtn className={'btn btn-block  btn-success'}>
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={note ? faSave : faPlus} size={'sm'}/>
                        {`${note ? 'Save' : 'Add'} Note`}
                      </SubmitBtn>
                    }
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      preview: false,
      initialValues: {
        type: '',
        title: '',
        description: '',
      },
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default NotesManager;
