/**
 *
 * RefundView
 * overview of payments
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {Link} from 'react-router-dom';
import Moment from 'moment';

// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import ReviewListView from './ReviewListView';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class ReviewView extends Component {
  static componentName = 'ReviewView';
  componentDidMount = () => {
    this.fetchInitData();
  };
  fetchInitData = () => {

    AppAPI.reviewapi.get('status/')
      .then((res) => {
        if (res.actions !== '') {
          this.setState({
            loading: false,
            reviews: res.reviews,
            time: res.time
          });
        } else {
          this.setState({
            loading: false,
            error: true
          });
        }
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: error,
          resultMsg: {error}
        });
      });
  };
  renderRow = (data, key) => {
    return (
      <ReviewListView
        data={data}
        key={key}
        history={this.props.history}
      />
    );
  };
  render = () => {
    const {loading, error, reviews} = this.state;
    const {property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="refund-view screen-container">
        <Helmet>
          <title>Reviews Overview</title>
        </Helmet>

        <div className="page-header">
          <h1>Reviews's</h1>
          <p>{property.name} reviews status at {AppUtil.formatDateTime(Moment(), 'datetime')}.</p>
        </div>

        <div className="row">
          <div className="col-lg-7 col-md-12 col-sm-12">
            {reviews.length > 0
              ? <div>
                <p>These refunds require your attention.</p>
                <div className="list-group">
                  {reviews.map((data, i) => (
                    this.renderRow(data, i)
                  ))}
                </div>
              </div>
              : <div className="mx-auto text-center border p-3">
                <span className="fa fa-check-square fa-5x green-cl"/>
                <p>All Fine for the day.</p>
                <p>No open or pending review exist in this property at the moment.</p>
              </div>
            }
            <div className="spacer-20"/>
            <div className="text-center">
              <Link className="btn btn-success" to="/review/list">Reviews List</Link>
            </div>
          </div>
        </div>

      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewView);
