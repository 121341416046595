/**
 *
 * Booking Lookup
 * booking lookup
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Moment from 'moment';
// Consts and Libs
import AppAPI from '../../lib/api';
import {Constants} from '../../constants';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';

import Error from '../../components/general/Error';
import DateRangePicker from 'react-daterange-picker';
import Loading from '../../components/general/Loading';
import 'react-daterange-picker/dist/css/react-calendar.css';
import EventLookupResults from './EventLookupResults';
// Actions
import * as EventActions from '../../redux/event/actions';

/* Redux ======================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  event: state.event.event,
  lookupResponse: state.event.lookupresponse
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setEvent: EventActions.setEvent,
  setLookup: EventActions.setLookup,
  unSetLookup: EventActions.unSetLookup,
  setSelectionData: EventActions.setSelectionData
};

/* Component ==================================================================== */
class EventLookUp extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    let minDate = Moment().startOf('day'),
      date = Moment().startOf('day'),
      maxDate = null;
    const {event} = this.props;
    if (event) {
      if (Moment(event.event_start_date) > Moment().startOf('day')) {
        minDate = Moment(event.event_start_date);
      }
      maxDate = Moment(event.event_end_date);

      if (event.event_type === Constants.EVENT_TYPE.SINGLE){
        date = Moment(event.event_start_date);
      } else {
        if (Moment(event.event_start_date) > Moment().startOf('day')) {
          date = Moment(event.event_start_date);
        }
      }
    }
    if (this.props.match.params.date) {
      date = Moment(String(this.props.match.params.date));
      this.setState({
        date: date,
        loading: false,
        minDate,
        maxDate
      });
      this.lookup(date.format('YYYY-MM-DD'));
    } else {
      this.props.unSetLookup();
      this.setState({
        date: date,
        loading: false,
        minDate,
        maxDate
      }, ()=>{
        if (event.event_type === Constants.EVENT_TYPE.SINGLE) {
          // single auto perform lookup
          this.performLookup(date);
        }
      });

    }
  };

  performLookup = (lookupDate = this.state.date) => {
    let date = Moment(lookupDate).format('YYYY-MM-DD');
    this.props.history.replace(`/event/${this.props.event.hash}/lookup/${date}/`);
  };

  lookup = (date) => {
    const {event} = this.props;

    if (date && event) {
      this.setState({loadingInner: true, results: []});
      AppAPI.eventapi.post(`${event.id}/lookup`, {
        date: date,
      })
        .then(res => {
          this.setState({resultMsg: {success: ''}}, () => {
            this.props.setLookup(res);
            setTimeout(() => {
              this.setState({
                resultMsg: {error: ''},
                loadingInner: false,
                loading: false,
                updateSearch: false,
                date: Moment(res.date),
              });
            }, 500);
          });
        })
        .catch(err => {
          this.props.unSetLookup();
          const error = AppAPI.handleError(err);
          this.setState({loadingInner: false, loading: false, error: error});
        });
    }
  };

  onSelect = (date) => {
    this.setState({date: date, updateSearch: true});
  };

  toggleDatePicker = () => {
    this.setState({showDatePicker: !this.state.showDatePicker});
  };

  finishBooking = (selectionData) => {
    if (selectionData) {
      this.props.setSelectionData(selectionData);
      this.props.history.push(`/event/${this.props.event.hash}/add`);
    }
  };

  render = () => {
    const {lookupResponse} = this.props;
    const {showDatePicker, loading, date, updateSearch, loadingInner, error, minDate, maxDate} = this.state;
    if (loading) return <Loading/>;

    return (
      <div className="booking-view screen-container">
        <Helmet>
          <title>Ticket Lookup</title>
        </Helmet>

        <div className={'lookup-box-container mt-2'}>
          <div className={'row'}>
            <div className={'col-lg-5 col-md-12'}>
              <div className="date-selector ">
                <div
                  className={(showDatePicker ? 'collapse.show' : 'collapse') + ' w-100'}
                  id="datePicker">
                  <div className="bg-white">
                    <div className="text-center mt-2">
                      <DateRangePicker
                        className={'w-100'}
                        selectionType={'single'}
                        minimumDate={minDate}
                        maximumDate={maxDate}
                        value={date}
                        onSelect={this.onSelect}
                        numberOfCalendars={2}
                      />
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-success btn-lg btn-block my-3"
                  onClick={() => this.performLookup()}
                  disabled={!updateSearch}>
                  <h5 className={'m-0 p-0'}>
                    {lookupResponse ? 'Update Search' : 'Lookup Availability'}
                    <FontAwesomeIcon className={'white-cl mx-1'} icon={faArrowRight} size={'sm'}/>
                  </h5>
                </button>
              </div>
            </div>
            <div className={'col-lg-7 col-md-12'}>
              {loadingInner ?
                <Loading content={true}/>
                :
                <div>
                  {lookupResponse !== null ?
                    <div>
                      <div className={'row'}>
                        <div className={'col-sm-12 col-md-12 col-lg-12'}>
                          <EventLookupResults lookupResponse={lookupResponse} action={this.finishBooking}/>
                        </div>
                      </div>
                    </div>
                    :
                    <div className="empty">
                      {error && <Error full={false} text={error}/> }
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      updateSearch: true,
      showDatePicker: true,
      minDate: Moment().startOf('day'),
      maxDate: null,
      date: Moment().startOf('day'),
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      results: [],
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventLookUp);
