/**
 * Manage Event
 *  Event add and edit
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {Formik} from 'formik';
import * as Yup from 'yup';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants, ErrorMessages, SelectList, Strings} from '../../../constants';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PageHeader from '../../../components/dashboard/PageHeader';
import { Checkbox, Datepicker, Form, Input, Select, SubmitBtn, Textarea } from 'react-formik-ui';
import {Alerts} from '../../../components/ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faCircle, faPlus, faSave} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as EventActions from '../../../redux/event/actions';
import AppUtil from '../../../lib/util';
import Moment from 'moment';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setEvent: EventActions.setEvent,
};


/* Component ==================================================================== */

class EventManage extends Component {
  static componentName = 'EventManage';

  static propTypes = {
    match: PropTypes.object,
  };

  componentDidMount = () => {
    this.setState({settings: (this.props.match.params && this.props.match.params.eventId)}, () => this.fetchInitData());
  };

  addEvent = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});
      AppAPI.eventapi.post('event/',
        {
          'name': credentials.name,
          'description': credentials.description,
          'booking_description': credentials.booking_description,
          'event_type': credentials.event_type,
          'event_recurring_type': credentials.event_recurring_type,
          'allow_advance_booking_days': credentials.allow_advance_booking_days,
          'event_start_date': credentials.event_start_date ? Moment(credentials.event_start_date).format('YYYY-MM-DD') : null,
          'event_booking_end_duration': `${this.state.eventEndDurationDay} ${Moment(credentials.event_booking_end_duration).format('HH:mm:ss')}`,
          'event_schedule_start_time': credentials.event_schedule_start_time,
          'event_schedule_end_time': credentials.event_schedule_end_time,
          'event_schedule_length': credentials.event_schedule_length,
          'active': credentials.active,

          'address_1': credentials.address_1,
          'address_2': credentials.address_2,
          'state': credentials.state,
          'country': credentials.country ? credentials.country : null,
          'pin_code': credentials.pin_code,
          'google_maps_url': credentials.google_maps_url,
          'booking_summary_pdf': credentials.booking_summary_pdf,

          'sales_email': credentials.sales_email,
          'sales_phone': credentials.sales_phone ? credentials.sales_code + credentials.sales_phone : null,

          'support_email': credentials.support_email,
          'support_phone': credentials.support_phone ? credentials.support_code + credentials.support_phone : null,
          'event_recurring_week_days': this.state.eventRecurringWeekDays.join(','),
        })
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                this.props.history.replace(this.props.location.pathname.replace(/[^/]*$/, `${res.hash}/`));
              }, 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  saveEvent = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});
      AppAPI.eventapi.patch(`event/${this.props.match.params.eventId}/`,
        {
          'name': credentials.name,
          'booking_description': credentials.booking_description,
          'description': credentials.description,
          'event_type': credentials.event_type,
          'event_recurring_type': credentials.event_recurring_type,
          'allow_advance_booking_days': credentials.allow_advance_booking_days,
          'event_start_date': credentials.event_start_date ? Moment(credentials.event_start_date).format('YYYY-MM-DD') : null,
          'event_booking_end_duration': `${this.state.eventEndDurationDay} ${Moment(credentials.event_booking_end_duration).format('HH:mm:ss')}`,
          'event_schedule_start_time': credentials.event_schedule_start_time,
          'event_schedule_end_time': credentials.event_schedule_end_time,
          'event_schedule_length': credentials.event_schedule_length,
          'active': credentials.active,
          'featured': credentials.featured,

          'address_1': credentials.address_1,
          'address_2': credentials.address_2,
          'state': credentials.state,
          'country': credentials.country ? credentials.country : null,
          'pin_code': credentials.pin_code,
          'google_maps_url': credentials.google_maps_url,
          'booking_summary_pdf': credentials.booking_summary_pdf,

          'sales_email': credentials.sales_email,
          'sales_phone': credentials.sales_phone ? credentials.sales_code + credentials.sales_phone : null,

          'support_email': credentials.support_email,
          'support_phone': credentials.support_phone ? credentials.support_code + credentials.support_phone : null,
          'event_recurring_week_days': this.state.eventRecurringWeekDays.join(','),
        })
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                this.props.setEvent(res);
                this.props.history.goBack();
              }, 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  fetchInitData = () => {
    if (this.state.settings) {
      this.setState({loading: true});
      AppAPI.eventapi.get('event/' + this.props.match.params.eventId + '/')
        .then((res) => {
          let sales_phone = AppUtil.processPhoneNumber(res.sales_phone);
          let support_phone = AppUtil.processPhoneNumber(res.support_phone);
          this.setState({
            loading: false,
            eventRecurringWeekDays: res.event_recurring_week_days ? res.event_recurring_week_days.split(',') : [],
            eventEndDurationDay: res.event_booking_end_duration ? Moment.duration(res.event_booking_end_duration.replace(/\s/g, '.')).days() : 0,
            initialValues: {
              'name': res.name,
              'description': res.description,
              'booking_description': res.booking_description,
              'event_type': res.event_type,
              'event_recurring_type': res.event_recurring_type,
              'allow_advance_booking_days': res.allow_advance_booking_days,
              'event_start_date': res.event_start_date,
              'event_schedule_start_time': res.event_schedule_start_time,
              'event_schedule_end_time': res.event_schedule_end_time,
              'event_schedule_length': res.event_schedule_length,
              'event_booking_end_duration': res.event_booking_end_duration ? Moment(res.event_booking_end_duration, 'hh:mm:ss') : Moment().startOf('day'),
              'active': res.active,
              'featured': res.featured,

              'address_1': res.address_1 || '',
              'address_2': res.address_2,
              'state': res.state || '',
              'country': res.country ? res.country.code : '',
              'pin_code': res.pin_code || '',
              'google_maps_url': res.google_maps_url,
              'booking_summary_pdf': res.booking_summary_pdf,

              'sales_email': res.sales_email || '',
              'sales_code': sales_phone ? '+' + String(sales_phone.getCountryCode()) : '+' + res.phone_number_code,
              'sales_phone': sales_phone ? sales_phone.getNationalNumber() : '',

              'support_email': res.support_email || '',
              'support_code': support_phone ? '+' + String(support_phone.getCountryCode()) : '+' + res.phone_number_code,
              'support_phone': support_phone ? support_phone.getNationalNumber() : '',
            },
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error: {error},
            resultMsg: {error},
          });
        });
    } else {
      this.setState({loading: false});
    }
  };

  selectWeekDays = (data) => {
    let eventRecurringWeekDays = AppUtil.insertOrRemoveArray(this.state.eventRecurringWeekDays, data);
    this.setState({eventRecurringWeekDays});
  };

  render = () => {

    const {loading, error, resultMsg, settings, initialValues, eventRecurringWeekDays} = this.state;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    const formValidation = Yup.object().shape({
      name: Yup.string().min(2, 'Too Short!').required('Required'),
      event_start_date: Yup.date().required('Required'),
      event_schedule_start_time: Yup.date().required('Required'),
      event_schedule_end_time: Yup.date().required('Required'),
      event_booking_end_duration: Yup.date().required('Required'),
      event_schedule_length: Yup.number().required('Required'),
      event_type: Yup.number().required('Required'),

      address_1: Yup.string(),
      state: Yup.string(),
      country: Yup.string(),
      pin_code: Yup.number(),
      google_maps_url: Yup.string().url().nullable(),

      sales_email: Yup.string().email('Invalid email'),
      sales_phone: Yup.number(),
      support_email: Yup.string().email('Invalid email'),
      support_phone: Yup.number(),
    });


    return (
      <div className="agent-manage screen-container ">
        <Helmet>
          <title>Event Manage</title>
        </Helmet>
        <PageHeader
          subHeader={true} history={this.props.history} title={(settings ? 'Manage' : 'Add') + ' Event'}
        />
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <Formik
              onSubmit={(values) => (settings ? this.saveEvent(values) : this.addEvent(values))}
              validationSchema={formValidation}
              initialValues={initialValues}>
              {({values}) => (
                <Form>
                  <div className={'row'}>
                    <div className="col-lg-8 col-md-8 col-sm-12">
                      <div className={'border mt-4 p-2 text-center anchor w-100'}>
                        <h6 className={'mb-0'}>
                          Event Information
                        </h6>
                      </div>
                      <div className={'border p-2'} id={'primaryAction'}>
                        <div className="form-row">
                          <div className="col-lg-6 col-md-6 form-group">
                            <Input
                              required
                              name='name'
                              label={'Event Name'}
                              className={'form-control'}
                            />
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="col-lg-12 col-md-12 form-group">
                            <Textarea
                              name='description'
                              label={'Event Description'}
                              hint={'Event description'}
                              className={'form-control'}
                            />
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="col-lg-12 col-md-12 form-group">
                            <Textarea
                              name='booking_description'
                              label={'Booking Description'}
                              className={'form-control'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                      <p><strong>Event Description : </strong> {Strings.eventDescription}</p>
                      <p><strong>Booking Description : </strong> {Strings.eventBookingDescription}</p>
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className="col-lg-8 col-md-8 col-sm-12">
                      <div className={'border mt-4 p-2 text-center anchor w-100'}>
                        <h6 className={'mb-0'}>
                          Event Type
                        </h6>
                      </div>
                      <div className={'border p-2'}>
                        <div className="form-row">
                          <div className="col-6 form-group">
                            <Select
                              className={'form-control rounded-right-0'}
                              name='event_type'
                              label={'Event Type'}
                              placeholder='Select an Option'
                              options={Constants.EVENT_TYPE_INPUT}
                            />
                          </div>
                          <div className="col-6 form-group">
                            <Select
                              disabled={parseInt(values.event_type) !== Constants.EVENT_TYPE.RECURRING}
                              className={'form-control rounded-right-0'}
                              name='event_recurring_type'
                              label={'Recurring Type'}
                              placeholder='Select an Option'
                              options={Constants.EVENT_RECURRING_TYPE_INPUT}
                            />
                          </div>
                        </div>

                        {(parseInt(values.event_type) === Constants.EVENT_TYPE.RECURRING) &&
                         <div className="form-row">
                           <div className="col-12 form-group">
                             <p className={'mb-1'}>Active Day(s) of week</p>
                             <div className={'row'}>
                               {Constants.DAY_OF_WEEK_INPUT.map((data, i) => (
                                 <div
                                   className={'align-items-center col-6 anchor d-inline-flex mb-2'} key={i}
                                   onClick={() => this.selectWeekDays(data.value)}>
                                   <FontAwesomeIcon
                                     icon={eventRecurringWeekDays && eventRecurringWeekDays.includes(data.value) ? faCheckCircle : faCircle}
                                     className={`${eventRecurringWeekDays && eventRecurringWeekDays.includes(data.value) ? 'green-cl' : 'grey-light-cl'} mr-2`}
                                   />
                                   <div className={'p-2 border w-100'}>
                                     {data.label}
                                   </div>
                                 </div>
                               ))}
                             </div>
                           </div>
                         </div>
                        }

                        <div className={'form-row'}>
                          <div className="col-6 form-group">
                            <Input
                              disabled={parseInt(values.event_type) !== Constants.EVENT_TYPE.RECURRING}
                              name='allow_advance_booking_days'
                              label={`${(parseInt(values.event_recurring_type) === Constants.EVENT_RECURRING_TYPE.LIMITED ? 'Length of event' : 'Length of booking')} in days`}
                              hint={'in days'}
                              className={'form-control'}
                            />
                          </div>
                        </div>
                        <div className={'form-row'}>
                          <div className="col-6 form-group">
                            <p className={'mb-1'}>
                              {parseInt(values.event_type) === Constants.EVENT_TYPE.RECURRING ? 'Event Start Date' : 'Event Date'}
                            </p>
                            <Datepicker
                              required
                              name='event_start_date'
                              className={'form-control'}
                              dateFormat="dd MMM yyyy"
                            />
                          </div>
                          {(parseInt(values.event_type) === Constants.EVENT_TYPE.RECURRING) &&
                           <div className="col-6 form-group">
                             <p
                               className={'mb-1'}>{parseInt(values.event_recurring_type) === Constants.EVENT_RECURRING_TYPE.LIMITED ? 'Event End Date' : 'Booking Possible Open till'}</p>
                             <input
                               disabled={true}
                               className={'form-control'}
                               value={(Moment(values.event_start_date) > Moment() ? AppUtil.formatDateTime(Moment(values.event_start_date).add(values.allow_advance_booking_days, 'days'), 'datef') : AppUtil.formatDateTime(Moment().add(values.allow_advance_booking_days, 'days'), 'datef'))}
                             />
                           </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                      <p><strong>Event Type : </strong> {Strings.eventType}</p>
                      <p><strong>Recurring Type : </strong> {Strings.eventRecurringType}</p>
                      <p><strong>Week Day : </strong> {Strings.eventWeekDay}</p>
                      <p><strong>Event Length : </strong> {Strings.eventLength}</p>
                      <p><strong>Booking Length : </strong> {Strings.eventLengthBooking}</p>
                      <p><strong>Event Date: </strong> {Strings.eventDate}</p>
                    </div>
                  </div>

                  <div className={'row'}>
                    <div className="col-lg-8 col-md-8 col-sm-12">
                      <div className={'border mt-4 p-2 text-center anchor w-100'}>
                        <h6 className={'mb-0'}>
                          Event Timing
                        </h6>
                      </div>
                      <div className={'border p-2'} id={'primaryAction'}>
                        <div className="form-row">
                          <div className="col-4 form-group time_only">
                            <p className={'mb-1'}>Event Start Time *</p>
                            <Datepicker
                              required
                              name='event_schedule_start_time'
                              className={'form-control'}
                              showTimeSelect
                              dateFormat="hh:mm aa"
                              timeFormat="HH:mm"
                              disabled={!values.event_start_date}
                              minDate={values.event_start_date}
                              maxDate={values.event_start_date}
                            />
                          </div>
                          <div className="col-4 form-group">
                            <Select
                              required
                              className={'form-control rounded-right-0'}
                              name='event_schedule_length'
                              label={'Event Length (Days)'}
                              placeholder='Select an Option'
                              options={AppUtil.numericSelector(0, 10, 'Day')}
                            />
                          </div>
                          <div className="col-4 form-group time_only">
                            <p className={'mb-1'}>Event End Time *</p>
                            <Datepicker
                              required
                              name='event_schedule_end_time'
                              className={'form-control time_only'}
                              showDateSelect={false}
                              showTimeSelect
                              dateFormat="hh:mm aa"
                              timeFormat="HH:mm"
                              disabled={!values.event_schedule_start_time}
                              minDate={values.event_schedule_start_time}
                              maxDate={values.event_start_date}
                            />
                          </div>
                        </div>
                        <hr/>
                        <p>When should ticket sales end with respective to event start time ? </p>
                        <div className="form-row">
                          <div className="col-4 form-group">
                            <p className={'mb-1'}>Days</p>
                            <select
                              required
                              value={this.state.eventEndDurationDay}
                              className={'form-control rounded-right-0'}
                              onChange={(event) => this.setState({eventEndDurationDay: event.target.value})}
                              placeholder='Select an Option'
                            >
                              {AppUtil.numericSelector(0, 10, 'Day').map((data, i) => (
                                <option key={i} value={data.value}>{data.label}</option>
                              ))}
                            </select>
                          </div>
                          <div className="col-6 form-group time_only">
                            <p className={'mb-1'}>Time in hour and minutes</p>
                            <Datepicker
                              required
                              name='event_booking_end_duration'
                              className={'form-control'}
                              showTimeSelect
                              dateFormat="HH:mm"
                              timeFormat="HH:mm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                      <p>
                        <strong>Event Time : </strong> {Strings.eventScheduleTime}
                      </p>
                      <p>
                        <strong>Event Length: </strong> {Strings.eventScheduleLength}
                      </p>
                      <p><strong>Ticket Sale End: </strong> {Strings.eventTicketSaleEnd}</p>
                    </div>

                  </div>


                  <div className={'row'}>
                    <div className="col-lg-8 col-md-8 col-sm-12">
                      <div
                        className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                        role="button" aria-expanded="false" aria-controls="orderActions">
                        <h6 className={'mb-0'}>
                          Event Address / Contact
                        </h6>
                      </div>
                      <div className={'border p-2'} id={'primaryAction'}>
                        <p className={'text-center text-muted'}>{Strings.eventAddress}</p>
                        <hr/>
                        <div className="form-row">
                          <div className="col-6 form-group">
                            <Input
                              name='address_1'
                              label={'Address Line 1'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-6 form-group">
                            <Input
                              name='address_2'
                              label={'Address Line 2'}
                              className={'form-control'}
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-lg-4 col-md-4 col-sm-6 form-group">
                            <Input
                              name='state'
                              label={'State'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-6 form-group">
                            <Select
                              className={'form-control rounded-right-0'}
                              name='country'
                              label={'Country'}
                              placeholder='Select an Option'
                              options={SelectList.countryList()}
                            />
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-6 form-group">
                            <Input
                              name='pin_code'
                              label={'Pin Code'}
                              className={'form-control'}
                              type={'number'}
                            />
                          </div>
                        </div>
                        <hr/>
                        <div className="form-row">
                          <div className="col-lg-6 col-md-6 form-group">
                            <Input
                              required
                              name='sales_email'
                              type={'email'}
                              label={'Sales E-mail'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 ">
                            <div className={'row'}>
                              <div className={'col-4 pr-0 form-group'}>
                                <Select
                                  className={'form-control rounded-right-0'}
                                  name='sales_code'
                                  label={'Country'}
                                  placeholder='Select an Option'
                                  options={SelectList.phoneCountryCode()}
                                />
                              </div>
                              <div className={'col-8 pl-0 form-group'}>
                                <Input
                                  required
                                  type={'tel'}
                                  name='sales_phone'
                                  label={'Sales Phone'}
                                  className={'form-control rounded-left-0'}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr/>
                        <div className="form-row">
                          <div className="col-lg-6 col-md-6 form-group">
                            <Input
                              required
                              name='support_email'
                              type={'email'}
                              label={'Support E-mail'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 ">
                            <div className={'row'}>
                              <div className={'col-4 pr-0 form-group'}>
                                <Select
                                  className={'form-control rounded-right-0'}
                                  name='support_code'
                                  label={'Country'}
                                  placeholder='Select an Option'
                                  options={SelectList.phoneCountryCode()}
                                />
                              </div>
                              <div className={'col-8 pl-0 form-group'}>
                                <Input
                                  required
                                  type={'tel'}
                                  name='support_phone'
                                  label={'Support Phone'}
                                  className={'form-control rounded-left-0'}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr/>
                        <div className={'form-row'}>
                          <div className={'col-12'}>
                            <Input
                              name={'google_maps_url'}
                              label={'Google Maps URL'}
                              className={'form-control rounded-left-0'}
                            />
                          </div>
                        </div>
                        <hr/>
                        <div className="form-row">
                          <div className="col-6 form-group">
                            <Checkbox
                              className={'mr-2'}
                              name='booking_summary_pdf'
                              label='Booking Details PDF'
                              text={'Include Booking details pdf in email?'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                      <p>
                        <strong>Address : </strong> {Strings.eventAddressDescription}
                      </p>
                      <p className={''}>
                        <strong>Sales contact : </strong> {Strings.eventSalesContactDescription}
                      </p>
                      <p className={''}>
                        <strong>Support contact : </strong> {Strings.eventSupportContactDescription}
                      </p>

                      <div className={'alert alert-primary'}>
                        <p><strong>Note : </strong>{Strings.eventSupportContactWarning}</p>
                      </div>

                      <p className={''}>
                        <strong>Google Maps URL : </strong> {Strings.eventGoogleMapsUrl}
                      </p>
                      <p className={''}>
                        <strong>Booking Details PDF: </strong> {Strings.eventBookingSummaryPDF}
                      </p>
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className="col-lg-8 col-md-8 col-sm-12">
                      <div className={'border mt-4 p-2 text-center anchor w-100'}>
                        <h6 className={'mb-0'}>
                          Event Status
                        </h6>
                      </div>
                      <div className={'border p-2'} id={'primaryAction'}>
                        <div className="form-row">
                          <div className="col-6 form-group">
                            <Checkbox
                              className={'mr-2'}
                              name='active'
                              label='Event Status'
                              text={'Enable event sale ?'}
                            />
                          </div>
                          <div className="col-6 form-group">
                            <Checkbox
                              className={'mr-2'}
                              name='featured'
                              label='Featured'
                              text={'Highlight event on booking engine?'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-12 mt-2">
                      <Alerts
                        status={resultMsg.status}
                        success={resultMsg.success}
                        error={resultMsg.error}
                      />
                      <SubmitBtn className={'btn btn-success btn-block btn-lg mt-2'}>
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={(settings ? faSave : faPlus)} size={'sm'}/>
                        {(settings ? 'Save' : 'Add')} Event
                      </SubmitBtn>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>

    );
  };


  constructor(props) {
    super(props);
    const {property} = this.props;

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      eventRecurringWeekDays: [],
      eventEndDurationDay: 0,
      initialValues: {
        name: '',
        event_start_date: '',
        allow_advance_booking_days: '0',
        event_schedule_start_time: '',
        event_schedule_end_time: '',
        event_schedule_length: '0',
        event_type: '',
        event_booking_end_duration: Moment().startOf('day'),
        sales_code: property && property.phone_number_code ? property.phone_number_code : '',
        support_code: property && property.phone_number_code ? property.phone_number_code : '',
        country: property && property.country ? property.country.code : '',
        booking_summary_pdf: true,
      },
      settings: false,
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(EventManage);
