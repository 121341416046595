/**
 *
 * Event List
 *
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Consts and Libs
import AppAPI from '../../lib/api';
import {Constants, Strings} from '../../constants';
// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import PageHeader from '../../components/dashboard/PageHeader';
import SearchBar from '../../components/forms/SearchBar';
import {Link} from 'react-router-dom';
import AppUtil from '../../lib/util';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCampground, faLongArrowAltRight} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class EventList extends Component {
  static componentName = 'EventList';

  static propTypes = {};

  componentDidMount = () => {
    this.setState({loading: false});
  };


  fetchData = (page = 1, callback, searchText) => {
    const urlParams = {...this.state.urlParams};
    let baseUrl = 'event';
    urlParams['page'] = page;
    urlParams['active'] = true;
    if (searchText) {
      this.setState({searching: true, clear: true});
      baseUrl = 'event-search';
      urlParams['search'] = searchText;
    }
    const queryString = Object.keys(urlParams).map(key => key + '=' + urlParams[key]).join('&');
    AppAPI.eventapi.get(`${baseUrl}/?${queryString}`)
      .then((res) => {
        if (res.results.length > 0) {
          callback(res.results, {allLoaded: !res.next});
        } else {
          callback();
        }
        this.setState({searching: false, clear: false, loading: false});
      })
      .catch((err) => {
        callback([], {allLoaded: true});
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, loading: false, error});
      });
  };


  action = (data) => {
    this.props.history.push(`/event/${data.id}/`);
  };

  renderRow = (data, key) => {
    return (
      <tr key={key}>
        <td>{data.name}</td>
        <td>
          <span className={'badge ' + (AppUtil.ticketBookingStatusColor(data.event_status))}>
            {data.event_status_display}
          </span>
          <span className={'ml-2 badge badge-white'}>
            {data.event_type_display}
          </span>
        </td>
        <td>
          {AppUtil.formatDateTime(data.event_start_date, 'date')}
          {data.event_type === Constants.EVENT_TYPE.RECURRING && `- ${AppUtil.formatDateTime(data.event_end_date, 'date')}`}
        </td>
        <td>
          {AppUtil.formatDateTime(data.event_start_time, 'time')} to {parseInt(data.event_schedule_length) > 1 && ` + ${data.event_schedule_length} day(s)`} {AppUtil.formatDateTime(data.event_end_time, 'time')}
        </td>
        <td>
          <Link to={`/event/${data.hash}`}>
            Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
          </Link>
        </td>
      </tr>
    );
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={8}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faCampground}/> No Events </h4>
            {Strings.eventListEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };

  tableHeader = () => {
    return (
      <tr>
        <th>Name</th>
        <th/>
        <th>Date</th>
        <th>Schedule</th>
        <th/>
      </tr>
    );
  };


  render = () => {
    const {loading, error, clear, searching, results} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="dashboard screen-container">
        <Helmet>
          <title>Event List</title>
        </Helmet>

        <PageHeader
          history={this.props.history}
          title={'Event List'}
          description={'Active events available for booking'}
        />

        <div className="row">
          <div className="col-lg-12 mb-5">
            <SearchBar
              clear={clear}
              results={results}
              searching={searching}
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
              searchFunction={this.fetchData}
              placeHolder="Event Name"
              colSpan={6}
            />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">

          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      searching: false,
      clear: false,
      results: [],
      type: 'Guest',
      profile_type: 1,
      showGuestManagement: false,
      showGuestDetails: false,
      showGuestDetailsId: null,
      addGuestToRoom: false,
      urlParams: {},
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventList);
