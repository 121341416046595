/**
 *
 * BookingAmendmentRequestList
 * List Bookings Amendment Request
 */
import React, {Component} from 'react';
import queryString from 'query-string';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Moment from 'moment';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import AppWebUtil from '../../lib/webUtils';
import {Constants, Filters, Strings} from '../../constants';
// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import SearchBar from '../../components/forms/SearchBar';
import FilterButton from '../../components/dashboard/FilterButton';
import FilterDate from '../../components/dashboard/FilterDate';
import ExportButton from '../../components/dashboard/ExportButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBed, faLongArrowAltRight, faSync} from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../../components/dashboard/PageHeader';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class BookingAmendmentRequestList extends Component {
  static componentName = 'BookingAmendmentRequestList';

  static propTypes = {
    guestId: PropTypes.number,
    corporateId: PropTypes.number,
  };

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };

  componentDidMount = () => {
    let urlParams = {}, startDate = null, endDate = null;


    switch (this.getQueryParams('booking_amendment_status')) {
    case 'cancelled':
      urlParams['booking_amendment_status'] = Constants.BOOKING_AMENDMENT.CANCELED;
      break;
    case 'requested':
      urlParams['booking_amendment_status'] = Constants.BOOKING_AMENDMENT.REQUESTED;
      break;
    case 'completed':
      urlParams['booking_amendment_status'] = Constants.BOOKING_AMENDMENT.COMPLETED;
      break;
    default:
      break;
    }

    switch (this.getQueryParams('booking_amendment_status_type')) {
    case 'confirmed':
      urlParams['confirm_status'] = 1;
      break;
    case 'provisional':
      urlParams['confirm_status'] = 0;
      break;
    default:
      break;
    }

    if (this.getQueryParams('start_date')) {
      startDate = Moment(String(this.getQueryParams('start_date')));
    }
    if (this.getQueryParams('end_date')) {
      endDate = Moment(String(this.getQueryParams('end_date')));
    }
    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('start_date'), this.getQueryParams('end_date'), 'created_date');


    this.setState({
      loading: false,
      urlParams,
      startDate,
      endDate,
    });

  };

  fetchData = (page = null, callback, searchText, download) => {
    const urlParams = {...this.state.urlParams};
    let baseUrl = 'booking-amendment-request-v2';
    if (page) {
      urlParams['cursor'] = page;
    }

    if (searchText) {
      this.setState({searching: true, clear: true});
      baseUrl = 'booking-amendment-request-search';
      urlParams['search'] = searchText;
      if (page){
        urlParams['page'] = page;
      }
    }

    if (download) {
      urlParams['download'] = download.file;
      urlParams['all_data'] = true;
      this.setState({fileDownload: true});
    }

    AppAPI.bookingapi.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (download) {
          let fileName = `Booking-Amendment-Report-${AppUtil.formatDateTime(Moment(), 'short')}.${download.file}`;
          AppWebUtil.downloadFile(res, fileName);
        } else {
          if (res.results.length > 0) {
            callback(
              res.results,
              {
                allLoaded: !res.next,
                nextParam: res.count ? AppUtil.getURLParam(res.next, 'page') : AppUtil.getURLParam(res.next, 'cursor')
              }
            );
          } else {
            callback();
          }
        }
        this.setState({
          searching: false,
          clear: false,
          refresh: false,
          loading: false,
          fileDownload: false,
          page: page,
        });
      })
      .catch((err) => {
        if (callback) {
          callback([], {allLoaded: true});
        }
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, refresh: false, loading: false, fileDownload: false, error});
      });
  };

  renderRow = (data, key) => {
    return (
      <tr key={key}>
        <td className={'align-left'}>{!_.isEmpty(data.attributes) ? data.attributes.amendment : 'N.A' }</td>
        <td className={'align-middle'}><small>{data.ref_no}</small></td>
        <td className={'align-middle'}>
          <span className={'badge ' + (AppUtil.bookingAmendmentStatusColor(data.booking_amendment_status))}>
            {data.booking_amendment_status_display}
          </span>
        </td>
        <td className={'align-middle'}>
          {(!_.isEmpty(data.attributes) && data.attributes.booking_hash) ?
            <Link to={`/booking/${data.attributes.booking_hash}`}>{data.attributes.booking_ref_no}</Link>
            :
            'N.A'
          }
        </td>
        <td className={'align-middle'}>
          {(!_.isEmpty(data.attributes) && data.attributes.booking_hash && data.attributes.booking_room_hash) ?
            <Link to={`/booking/${data.attributes.booking_hash}/booking-room/${data.attributes.booking_room_hash}`}>
              Room : {data.attributes.booking_room_number}
            </Link>
            :
            'N.A'
          }
        </td>
        <td className={'align-left'}>{!_.isEmpty(data.attributes) ? data.attributes.room_name : 'N.A' }</td>
        <td className={'align-middle'}><small>{AppUtil.formatDateTime(data.created)}</small></td>
        <td className={'align-middle'}>
          <Link to={`/booking/${data.attributes.booking_hash}/amendment-request/${data.hash}`}>
            Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
          </Link>
        </td>
      </tr>
    );
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={8}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faBed}/> No Booking Amendments </h4>
            {Strings.bookingAmendmentListEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };


  tableHeader = () => {
    return (
      <tr>
        <th className={'data-table-cell-lg'}>Amendment</th>
        <th className={'data-table-cell-md'}>Reference no</th>
        <th className={'data-table-cell-sm'}/>
        <th className={'data-table-cell-md'}>Booking</th>
        <th className={'data-table-cell-md'}>Room</th>
        <th className={'data-table-cell-md'}>Room</th>
        <th className={'data-table-cell-xm'}>Date</th>
        <th className={'data-table-cell-xm'}/>
      </tr>
    );
  };


  render = () => {
    const {loading, error, clear, searching, results, fileDownload, refresh} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="booking-list screen-container">
        <Helmet>
          <title>Booking Amendment Request List</title>
        </Helmet>

        <PageHeader
          history={this.props.history} title={'Amendment Request'}
          description={'List of all amendment requests.'}
        />

        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-9'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.setState({refresh: true})}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                </button>
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Amendment Status'}
                  data={Filters.bookingAmendmentFilter}
                  selectKey={this.getQueryParams('amendment_status')}
                  action={(data) => this.setQueryParams([{key: 'amendment_status', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data))}
                  size={'sm'}
                  maxDate={Moment().add(1, 'days')}
                  title={'Created'}
                />
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-3 text-center text-sm-right'}>
            <ExportButton
              size={'sm'}
              files={['csv']}
              action={(key) => this.fetchData(this.state.page, null, null, {file: key})}
              loading={fileDownload}
              disabled={!(this.state.startDate)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <SearchBar
              clear={clear}
              refresh={refresh}
              results={results}
              searching={searching}
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
              placeHolder="Booking ID or Amendment Ref"
              listVersion={'V2'}
              colSpan={8}
            />
          </div>
        </div>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      searching: false,
      clear: false,
      results: [],
      urlParams: {},
      page: 1,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingAmendmentRequestList);
