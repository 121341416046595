/**
 *
 * OrderList
 * List Orders
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {NavLink} from 'react-router-dom';

// Consts and Libs
import AppAPI from '../../lib/api';
import Strings from '../../constants/strings';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import ListView from '../../components/general/ListView';
import AppUtil from '../../lib/util';

// Actions
import * as BillingAction from '../../redux/billing/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setBillingOrder: BillingAction.setBillingOrder
};

/* Component ==================================================================== */
class OrderList extends Component {
  static componentName = 'OrderList';
  componentDidMount = () => {
    this.setState({loading: false});
  };
  fetchOrder = (page = 1, callback) => {

    let params = '';

    switch (this.props.match.params.orderType) {
    case 'paid':
      params = '&payment_status=1&lock=1';
      break;
    case 'notpaid':
      params = '&payment_status=0&lock=1&order_status=1';
      break;
    case 'pending':
      params = '&payment_status=5';
      break;
    case 'canceled':
      params = '&lock=1&order_status=0';
      break;
    default:
      params = '&payment_status=1';
    }

    AppAPI.billingapi.get('billingorder/?page=' + page + params)
      .then((res) => {
        this.setState({
          loading: false,
          error: null
        });
        if (res.results.length > 0) {
          if (res.next) {
            callback(res.results);
          } else {
            callback(res.results, {
              allLoaded: true // the end of the list is reached
            });
          }
        } else {
          callback();
        }
      })
      .catch((err) => {
        callback(null, {
          allLoaded: true // the end of the list is reached
        });
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error
        });
      });
  };
  action = (data) => {
    this.props.setBillingOrder(data);
    this.props.history.push('/user/billing/invoice/' + data.id);
  };
  renderRow = (data, key) => {
    return (
      <div
        role="presentation"
        onKeyPress={this.handleKeyPress}
        onClick={() => this.action(data)}
        key={key}
        className="list-group-item list-group-item-action flex-column align-items-start a-class">
        <div className="d-flex w-100 justify-content-between">
          <h5 className="mb-1">{data.order_id}</h5>
          <span
            className={'badge ' + (data.order_status ? (data.lock === true ? AppUtil.paymentStatusColor(data.payment_status) : 'badge-warning') : 'badge-dark')}>
            {data.total.toString()} {data.currency}
          </span>
        </div>
        <small className="text-muted">{AppUtil.formatDateTime(data.created)}</small>
      </div>
    );
  };
  emptyView = () => {
    return (
      <div className="empty">
        <div className="mx-auto featured-image">
          <img className={'w-100'} alt={'Order List'} src={require('../../images/drawings/Orders.png')}/>
        </div>
        <div className="spacer-20"/>
        {
          Strings.billingOrdersEmptyText.map((data, i) => <p key={i}>{data}</p>)
        }
      </div>
    );
  };
  render = () => {
    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="order-list screen-container">
        <Helmet>
          <title>Invoices</title>
        </Helmet>

        <div className="row">

          <div className="col-lg-7 col-md-12 col-sm-12">
            <div className="page-header">
              <h3>Invoices</h3>
              <p className="mb-1">Invoices are created monthly and emailed to the account owner.</p>
            </div>
            <ul className="nav nav-pills nav-fill border border-r">
              <li className="nav-item" role="presentation">
                <NavLink
                  to={'/user/billing/invoice/'}
                  exact={true}
                  className="nav-link"
                  activeClassName="selected">
                  Paid
                </NavLink>
              </li>
              <li className="nav-item" role="presentation">
                <NavLink
                  to={'/user/billing/invoice/type/notpaid'}
                  exact={true}
                  className="nav-link"
                  activeClassName="selected">
                  Not Paid
                </NavLink>
              </li>
              <li className="nav-item" role="presentation">
                <NavLink
                  to={'/user/billing/invoice/type/pending'}
                  exact={true}
                  className="nav-link"
                  activeClassName="selected">
                  Pending
                </NavLink>
              </li>
            </ul>

            <div className={'spacer-20'}/>
            <ListView
              rowView={this.renderRow}
              onFetch={this.fetchOrder}
              firstLoader={true}
              pagination
              emptyView={this.emptyView}
              class={'list-group'}
            />
          </div>
        </div>

      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
