/**
 * GuestListView
 *  View for Guest Listing
 *
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
// Consts and Libs
import {Constants} from '../../constants';
// Actions
import * as GuestActions from '../../redux/guest/actions';
import * as BookingRoomActions from '../../redux/bookingroom/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setGuest: GuestActions.setGuest,
  setBookingRoomGuest: BookingRoomActions.setBookingRoomGuest,
};

/* Component ==================================================================== */
class GuestListView extends Component {
  static componentName = 'GuestListView';

  static propTypes = {
    remove: PropTypes.bool,
    action: PropTypes.func,
    params: PropTypes.object,
    disabled: PropTypes.bool,
    settings: PropTypes.bool,
    hideStatus: PropTypes.bool,
    data: PropTypes.object.isRequired,
    history: PropTypes.object,
  };

  action = (data) => {
    if (this.props.action) {
      this.props.action(data);
    } else if (data.id) {
      if (this.props.history && this.props.disabled !== false) {
        if (data.profile_type === Constants.PROFILE_TYPE.CORPORATE) {
          this.props.history.push('/settings/profile/corporate/' + data.id + '/');
        } else if (data.profile_type === Constants.PROFILE_TYPE.AGENT) {
          this.props.history.push('/settings/profile/agent/' + data.id + '/');
        } else {
          if (this.props.settings){
            this.props.history.push('/settings/profile/guest/' + data.id + '/');
          } else{
            this.props.history.push('/guest/' + data.id + '/');
          }
        }
      }
    }
  };

  render = () => {
    const data = this.props.data;
    return (
      <div
        role="presentation"
        onKeyPress={this.handleKeyPress}
        onClick={() => this.action(data)}
        className="list-group-item list-group-item-action flex-column align-items-start a-class">
        <div className="d-flex w-100 justify-content-between">
          <div>
            <h5 className="mb-1 text-break">
              {data.entity_name ?
                <React.Fragment>{data.entity_name}</React.Fragment>
                :
                <React.Fragment>{data.name ? data.name : (data.first_name ? (data.first_name + ' ' + data.last_name) : '')}</React.Fragment>
              }
            </h5>
            <p className="mb-0 small">
              Email : {data.email ? data.email : 'N.A'} <br/>
              Phone : {data.phone ? data.phone : 'N.A'}
            </p>
          </div>

          <div className={'text-center'}>
            {this.props.hideStatus !== true &&
              <React.Fragment>
                {data.id ?
                  <span
                    className={'badge ' + (data.record_exist === true ? 'badge-success' : 'badge-danger')}>Record</span>
                  :
                  <span className={'badge badge-info'}>Processing Record</span>
                }
              </React.Fragment>
            }
            {data.profile_type_display &&
              <React.Fragment>
                <br/><span className={'badge badge-light'}>{data.profile_type_display}</span>
              </React.Fragment>
            }
          </div>
        </div>

      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(GuestListView);

