/**
 * PaymentListView
 *  View for Review Listing
 *
 */
import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import APPUtil from '../../lib/util';
// Actions
import {Constants} from '../../constants';


/* Component ==================================================================== */
class HousekeepingRoomListView extends Component {
  static componentName = 'HousekeepingRoomListView';

  static propTypes = {
    data: PropTypes.object.isRequired,
    history: PropTypes.object,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    serviceStatus: true,
  };

  action = (data) => {
    if (this.props.disabled !== true) {
      if (this.props.action) {
        this.props.action(data);
      } else if (this.props.history) {
        this.props.history.push('/house-keeping/room/' + data.hash);
      }
    }
  };

  render = () => {
    const {data, serviceStatus} = this.props;
    const {booking_room_data} = data;
    return (
      <div
        className={'border border-bottom-0 pt-3 position-relative anchor list-group-item-action ' + (data.maintenance_status === Constants.ROOM.NORMAL ? 'white-bg' : 'yellow-bg')}
        role="presentation"
        onKeyPress={this.handleKeyPress} onClick={() => this.action(data)}>
        <div className={'housekeeping-room-box'}>
          {serviceStatus &&
          <span
            className={'position-absolute top-position-badge badge ' + (data.service_status === Constants.ROOM.CLEAN ? 'badge-success' : 'badge-danger')}
          >
            {data.service_status_display} {data.buffer && '(Buffer)'}
          </span>
          }
          {_.isEmpty(booking_room_data) ?
            <React.Fragment>
              <h4 className={'text-center'}>{APPUtil.limitChars(data.name, 16)}</h4>
              <p className={'text-center mb-0'}>{data.attributes.room_type_name}</p>
            </React.Fragment>
            :
            <React.Fragment>
              <p className={'text-center small mb-0'}>
                <strong>{APPUtil.limitChars(data.name, 16)} | {data.attributes.room_type_name}</strong></p>
              <p className={'small mb-0 text-center'}>
                {
                  !_.isEmpty(booking_room_data.room_type_package_attributes) ?
                    <React.Fragment>
                      {booking_room_data.room_type_package_attributes.map((packageData, i) => (
                        <React.Fragment key={i}>
                          {` ${(i < 1) && `${packageData.room_type_package ? packageData.room_type_package.name : '..'}`}`}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                    :
                    '...'
                } |
                ({`${APPUtil.formatDateTime(booking_room_data.checkin, 'date')} - ${APPUtil.formatDateTime(booking_room_data.checkout, 'date')}`})
              </p>
              <p className={'mb-0 text-center'}>
                {!_.isEmpty(booking_room_data.guests) ?
                  <React.Fragment>
                    {booking_room_data.guests.map((guest, k) => (
                      <React.Fragment key={k}>
                        { (k < 2) && <span className={'small'}>{k > 0 && ', '} {APPUtil.limitChars(guest.name, 20)}</span>}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                  :
                  <span className={'text-muted small'}>
                    No Guest Information
                  </span>
                }
              </p>
            </React.Fragment>
          }
        </div>
        <table className={'table mb-0 table-sm small w-100 border text-center'}>
          <tbody>
            <tr>
              <td
                className={'w-50 border-right ' + (data.availability_status === Constants.ROOM.AVAILABLE ? 'lime-bg' : 'yellow-bg')}
              >
                <p className={'small mb-0'}>
                  {_.isEmpty(booking_room_data) ?
                    data.availability_status_display :
                    <React.Fragment>
                      {booking_room_data.attributes.booking_ota_name ? `(OTA : ${booking_room_data.attributes.booking_ota_name})` : booking_room_data.attributes.booking_ref_no}
                    </React.Fragment>
                  }
                </p>
              </td>
              <td className={data.maintenance_status === Constants.ROOM.NORMAL ? 'lime-bg' : 'red-bg white-cl'}>
                <p className={'small mb-0'}>{APPUtil.limitChars(data.maintenance_status_display, 12)}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default (HousekeepingRoomListView);

