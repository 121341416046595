/**
 * Payment Link view
 *  Lists payment link and its params
 *
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Actions
import CopyToClipboard from '../../../components/general/CopyToClipboard';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';


/* Component ==================================================================== */
class PaymentLink extends Component {
  static componentName = 'PaymentLink';

  static propTypes = {
    paymentLink: PropTypes.string.isRequired,
  };

  static defaultProps = {
    disabled: false,
  }

  render = () => {
    const {paymentLink} = this.props;
    return (
      <div className={'row mb-4'}>
        <div className={'col-12'}>
          <p className={'mb-1 text-muted'}>Payment URL</p>
          <div className="input-group">
            <input type="text" value={paymentLink} disabled={true} className={'form-control bg-light'}/>
            <div className="input-group-prepend"><CopyToClipboard content={paymentLink} text={'Copy'}/></div>
            <div className="input-group-prepend">
              <a className={'btn btn-primary'} href={paymentLink} rel="noopener noreferrer" target={'_blank'}>
                <FontAwesomeIcon icon={faExternalLinkAlt} size={'sm'} className={'mr-2'}/> Open
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default (PaymentLink);

