/**
 * Payment analytics overview
 *  Payment analytics overview
 *
 */

import { faExternalLinkSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
// Consts and Libs
import AppUtil from '../../../../lib/util';


/* Component ==================================================================== */
class PABifurcationTable extends Component {
  static componentName = 'PABifurcationTable';

  static propTypes = {
    expandData: PropTypes.func,
    property: PropTypes.object.isRequired,
    paymentData: PropTypes.object.isRequired,
    bifurcationData: PropTypes.object.isRequired,
    bifurcationTitle: PropTypes.string.isRequired,
  };


  render = () => {
    const {bifurcationData, bifurcationTitle, paymentData, property, expandData} = this.props;

    return (
      <>
        <div className="table-responsive">
          <table className={'table table-bordered'}>
            <thead>
              <tr>
                <th>{bifurcationTitle}</th>
                <th>Count</th>
                <th>
                  Payment <small>({property.currency})</small>
                  <br/><small>(A + B)</small>
                </th>
                <th>
                  Settled <small>({property.currency})</small>
                  <br/><small>(A)</small>
                </th>
                <th>
                  Fee <small>({property.currency})</small>
                  <br/><small>(B)</small>
                </th>
                <th className={'text-muted'}>
                  Tax <small>({property.currency})</small>
                  <br/><small>(C)</small>
                </th>
              </tr>
            </thead>
            <tbody>
              {bifurcationData.map((data, i) => {
                return (
                  <tr className={'tr-align-middle'} key={i}>
                    <td>
                      {expandData && (
                        <FontAwesomeIcon
                          onClick={()=>expandData(data)}
                          icon={faExternalLinkSquare}
                          className={'mr-2 a-class'}
                        />
                      )}
                      {data.label}
                    </td>
                    <td className={'data-table-cell-sm text-center'}>
                      {paymentData.paymentCount[data.value] || 0}
                    </td>
                    <td className={'data-table-cell-md text-center'}>
                      {paymentData.paymentTotal[data.value] || 0}
                    </td>
                    <td className={'data-table-cell-md text-center'}>
                      {paymentData.paymentSettled[data.value] || 0}
                    </td>
                    <td className={'data-table-cell-md text-center'}>
                      {((paymentData.paymentTotal[data.value] || 0) - (paymentData.paymentSettled[data.value] || 0)).toFixed(2)}
                    </td>
                    <td className={'data-table-cell-md text-center text-muted grey-light-bg'}>
                      {paymentData.paymentSettledFeeTax[data.value] || 0}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td><strong>Total</strong></td>
                <td className={'data-table-cell-sm text-center'}>
                  <strong>{AppUtil.sumArray(Object.values(paymentData.paymentCount))}</strong>
                </td>
                <td className={'data-table-cell-sm text-center'}>
                  <strong>{AppUtil.sumArray(Object.values(paymentData.paymentTotal), 'float')}</strong>
                </td>
                <td className={'data-table-cell-sm text-center'}>
                  <strong>{AppUtil.sumArray(Object.values(paymentData.paymentSettled), 'float')}</strong>
                </td>
                <td className={'data-table-cell-sm text-center'}>
                  <strong>{(
                    AppUtil.sumArray(Object.values(paymentData.paymentTotal), 'float') -
                  AppUtil.sumArray(Object.values(paymentData.paymentSettled), 'float')
                  ).toFixed(2)}
                  </strong>
                </td>
                <td className={'data-table-cell-sm text-center text-muted grey-light-bg'}>
                  <strong>{AppUtil.sumArray(Object.values(paymentData.paymentSettledFeeTax), 'float')}</strong>
                </td>
              </tr>
            </tbody>
          </table>
          <p className={'text-muted small'}>Fee is inclusive of the tax mentioned in column [C].</p>
        </div>
      </>
    );
  };
}

/* Export Component ==================================================================== */
export default PABifurcationTable;

