/**
 * OrderListView
 *  View for Order Listing
 *
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
// Consts and Libs
import AppUtil from '../../lib/util';
// Actions
import * as OrderActions from '../../redux/order/actions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSquare, faCheckSquare} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setOrder: OrderActions.setOrder,
};

/* Component ==================================================================== */
class OrderListView extends Component {
  static componentName = 'OrderListView';

  static propTypes = {
    data: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    action: PropTypes.func,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    selectable: PropTypes.bool,
  };

  action = (data) => {
    if (this.props.action) {
      this.props.action(data);
    } else if (!this.props.disabled) {
      this.props.setOrder(data);
      this.props.history.push('/order/' + data.hash);
    }
  };

  render = () => {
    const {data, selected, selectable} = this.props;
    return (
      <div
        role="presentation"
        onKeyPress={this.handleKeyPress}
        onClick={() => this.action(data)}
        key={this.props.id}
        className={'list-group-item flex-column align-items-start a-class ' + (selected ? 'lime-bg' : 'list-group-item-action ')}>
        <div className="d-flex w-100 justify-content-between">
          <div>
            <h5 className="mb-1">{selectable && <FontAwesomeIcon className={'mr-1'} icon={selected ? faCheckSquare : faSquare} size={'sm'}/>}  {data.ref_no}</h5>

            <p className="mb-0 small">Type : {data.order_source_display}</p>
            <p className="mb-0 small text-muted">{AppUtil.formatDateTime(data.created)}</p>
          </div>

          <div className={'text-right float-right'}>
            <span
              className={'badge badge-light'}>
              {data.currency + ' ' + data.total.toString()}
            </span>
            <br/>
            {(!data.lock) ?
              <span className={'badge badge-warning small'}>Open Order</span>
              :
              <React.Fragment>
                <span className={'badge ' + AppUtil.paymentStatusColor(data.order_status)}>
                  {data.order_status_display}
                </span>
              </React.Fragment>
            }
          </div>
        </div>
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(OrderListView);

