/**
 *
 * Room Type Booking Engine Settings
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Consts and Libs
import {Strings} from '../../../../constants';
import AppAPI from '../../../../lib/api';
// Components
import {Alerts} from '../../../../components/ui';
import Error from '../../../../components/general/Error';
import Loading from '../../../../components/general/Loading';
import {confirmAlert} from '../../../../components/general/ConfirmAlert';
// Actions
import * as RoomTypeActions from '../../../../redux/roomtype/actions';
import RoomInventoryList from '../../../../components/functional/RoomInventoryList';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  roomType: state.roomType.roomType,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setRoomType: RoomTypeActions.setRoomType,
};

/* Component ==================================================================== */
class RoomTypeBookingEngineOverview extends Component {
  static componentName = 'RoomTypeBookingEngineOverview';

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {property, roomType} = this.props;
    if (property.booking_engine_ok) {
      AppAPI.roomapi.get(`room-type-overview/?room_type=${roomType.id}`)
        .then((res) => {
          this.setState({
            loading: false,
            error: null,
            roomTypes: res.room_types,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  updateRoomType = (roomType, payload) => {
    if (roomType && payload) {
      this.setState({resultMsg: {status: 'One moment...'}});
      AppAPI.roomapi.patch(`room-type-distribution/${roomType}/`, payload)
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}});
          this.props.setRoomType(res);
          setTimeout(() => {
            this.setState({
              resultMsg: {success: ''},
            });
          }, 500);
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  render = () => {
    const {loading, error, resultMsg, roomTypes} = this.state;
    const {roomType} = this.props;
    if (!this.props.property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const {property} = this.props;

    return (
      <div className="channel-manager-settings screen-container">
        <Helmet>
          <title>Booking Engine Settings</title>
        </Helmet>
        <Alerts
          status={resultMsg.status}
          success={resultMsg.success}
          error={resultMsg.error}
        />
        <div className={'row'}>
          <div className="col-lg-12 col-md-12 col-sm-12 mb-5">
            <div className={'row'}>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <table className={'table border'}>
                  <thead>
                    <tr>
                      <td>Level</td>
                      <td>Connection</td>
                      <td>Distribution</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><strong>Property Level</strong></td>
                      <td>
                        <span className={'badge ' + (property.booking_engine_ok ? 'badge-success' : 'badge-secondary')}>
                          {property.booking_engine_ok ? 'Connected' : 'Not Connected'}
                        </span>
                      </td>
                      <td>
                        <span
                          className={'badge ' + (property.allow_booking_engine_distribution ? 'badge-success' : 'badge-secondary')}>
                          {property.allow_booking_engine_distribution ? 'Enabled' : 'Disabled'}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Room Booking </strong></td>
                      <td/>
                      <td>
                        <span
                          className={'badge ' + (property.allow_room_booking_engine ? 'badge-success' : 'badge-secondary')}>
                          {property.allow_room_booking_engine ? 'Enabled' : 'Disabled'}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Room Type Level</strong></td>
                      <td>
                        <span className={'badge ' + (roomType.booking_engine_ok ? 'badge-success' : 'badge-secondary')}>
                          {roomType.booking_engine_ok ? 'Connected' : 'Not Connected'}
                        </span>
                      </td>
                      <td>
                        <span
                          className={'badge ' + (roomType.allow_booking_engine_distribution ? 'badge-success' : 'badge-secondary')}>
                          {roomType.allow_booking_engine_distribution ? 'Enabled' : 'Disabled'}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {property.booking_engine_ok &&
               <div className="col-lg-6 col-md-6 col-sm-12">
                 <ul className="list-group">
                   <li className="list-group-item">
                     <button
                       onClick={() => {
                         confirmAlert({
                           title: (roomType.allow_booking_engine_distribution ? 'Disable' : ' Enabled') + ' Distribution',
                           message: roomType.allow_booking_engine_distribution ? Strings.bookingEngineSectionDistributionDisable : Strings.bookingEngineSectionDistributionEnable,
                           buttons: [
                             {
                               className: (roomType.allow_booking_engine_distribution ? 'btn-danger' : ' btn-success'),
                               label: 'Yes',
                               onClick: () => this.updateRoomType(roomType.id, {allow_booking_engine_distribution: !roomType.allow_booking_engine_distribution}),
                             },
                             {
                               className: 'btn-secondary',
                               label: 'No',
                             },
                           ],
                         });
                       }}
                       className={'btn btn-block ' + (roomType.allow_booking_engine_distribution ? 'btn-danger' : ' btn-success')}>
                       {(roomType.allow_booking_engine_distribution ? 'Disable' : 'Enable') + ' Distribution'}
                     </button>
                   </li>
                   <li className="list-group-item">
                     {
                       Strings.bookingEngineSectionDistribution.map((data, i) =>
                         <p className={'sm mb-2 text-secondary'} key={i}>{data}</p>)
                     }
                   </li>
                 </ul>
               </div>
              }
            </div>
          </div>
        </div>
        {roomType.booking_engine_ok &&
         <div>
           <hr/>
           <h5>Room Type and Packages</h5>
           <p>Room type and packages connected to booking engine.</p>
           <RoomInventoryList channelManager={false} refreshContent={this.fetchInitData} roomTypes={roomTypes}/>
         </div>
        }
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      roomTypes: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomTypeBookingEngineOverview);
