import React from 'react';
import {NavLink, Redirect, Route, Switch} from 'react-router-dom';

import NotFound from '../NotFound';

import RoomList from '../inventory/roomType/room/RoomListSettings';

import RoomTypeList from '../inventory/roomType/RoomTypeList';
import RoomTypeManage from '../inventory/roomType/RoomTypeManage';
import OverView from '../inventory/OverView';

import RoomTypePackagesList from '../inventory/roomType/roomTypePackages/RoomTypePackagesList';

import RoomServiceItem from '../inventory/roomServiceItem';

import AmendmentList from '../inventory/AmendmentList';
import AmendmentManage from '../inventory/AmendmentManage';
import AmendmentDetails from '../inventory/AmendmentDetails';

import RoomTypeRequired from '../../components/decorators/roomTypeRequired';
import RoomTypeInventory from '../inventory/roomType';

const menu = [
  {
    title: 'Overview',
    link: '/settings/inventory/overview',
    isExact: true,
  },
  {
    title: 'Room(s)',
    link: '/settings/inventory/room',
    isExact: false,
  },
  {
    title: 'Room Type(s)',
    link: '/settings/inventory/room-type',
    isExact: false,
  },
  {
    title: 'Package(s)',
    link: '/settings/inventory/package',
    isExact: false,
  },
  {
    title: 'Amendment(s)',
    link: '/settings/inventory/amendments',
    isExact: false,
  },
];
const menuItems = [];

menu.map((item) => {
  const {title, link, isExact} = item;
  return menuItems.push(
    <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
      <NavLink
        to={{
          pathname: link,
          state: {fromDashboard: true},
        }}
        exact={isExact}
        className="nav-link rounded-0"
        activeClassName="selected">
        {title}
      </NavLink>
    </li>,
  );
});

const PropertyRoute = () => (
  <div>
    <div className={'row'}>
      <div className={'col col-lg-2 col-md-3 col-sm-12 mb-5'}>
        <ul className="nav nav-pills flex-md-column flex-sm-row border rounded mb-3">
          {menuItems}
        </ul>
      </div>
      <div className={'col-lg-10 col-md-9 col-sm-12'}>
        <Switch>
          <Route exact path="/settings/inventory/" render={() => (<Redirect to="/settings/inventory/overview"/>)}/>
          <Route path="/settings/inventory/overview" exact component={OverView}/>

          {/* Room Type */}
          <Route path="/settings/inventory/room-type/" exact component={RoomTypeList}/>
          <Route path="/settings/inventory/room-type/new" exact component={RoomTypeManage}/>
          <Route path="/settings/inventory/room-type/:roomTypeId" component={RoomTypeRequired(RoomTypeInventory)}/>
          {/* Room Type Sub Views */}
          <Route path="/settings/inventory/room" exact component={RoomList}/>
          {/* Service Items */}
          <Route path="/settings/inventory/room/service-items" component={RoomServiceItem}/>
          <Route path="/settings/inventory/package/" exact component={RoomTypePackagesList}/>

          {/* Amendments */}
          <Route path="/settings/inventory/amendments" exact component={AmendmentList}/>
          <Route path="/settings/inventory/amendments/new" exact component={AmendmentManage}/>
          <Route path="/settings/inventory/amendments/:bookingAmendmentId" exact component={AmendmentDetails}/>
          <Route path="/settings/inventory/amendments/:bookingAmendmentId/edit" exact component={AmendmentManage}/>

          <Route exact component={NotFound}/>
        </Switch>
      </div>
    </div>
  </div>
);

export default PropertyRoute;