/**
 *
 * POS Details View
 */
import _ from 'lodash';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Collapse} from 'react-bootstrap';
// Consts and Libs
import {Constants, Strings} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowsAltH,
  faCheck,
  faExclamationTriangle,
  faLink,
  faLockOpen,
  faMoneyBillWave,
  faPencilAlt,
  faPlus,
  faStickyNote,
  faUnlink,
} from '@fortawesome/free-solid-svg-icons';

import POSOrderEdit from '../models/POSOrderEdit';
import NotesManager from '../../notes/NotesManager';
import NotesListView from '../../notes/NotesListView';
import POSTableSelector from '../models/POSTableSelector';
import OrderItemListView from '../../order/OrderItemListView';
import ProfileQuickSearch from '../../guest/ProfileQuickSearch';
import POSBillingOrderList from '../components/POSBillingOrderList';
import POSRoomSelectListView from '../models/POSRoomSelectListView';
import GuestDetailsOverView from '../../guest/GuestDetailsOverView';
import {confirmAlert} from '../../../components/general/ConfirmAlert';
import POSOrderItemDetailsView from '../models/POSOrderItemDetailsView';
import POSOrderEscalatedPermissionEdit from '../models/POSOrderEscalatedPermissionEdit';

/* Component ==================================================================== */
class POSOrderDetails extends Component {
  static componentName = 'POSOrderDetails';

  static propTypes = {
    loading: PropTypes.bool.isRequired,
    posOrder: PropTypes.object.isRequired,
    resultMsg: PropTypes.object.isRequired,
    posDetails: PropTypes.object.isRequired,

    cacheReset: PropTypes.func,
    fetchInitData: PropTypes.func,
    updatePosOrder: PropTypes.func,
    updatePosOrderAdditional: PropTypes.func,
    escalatedPermissionUpdated: PropTypes.func,
  };

  componentDidMount = () => {
  };

  showProfileDetails = (guestId, add_guest = false) => {
    this.setState({profileDetailsData: guestId, showProfileDetails: true, addProfile: add_guest});
  };

  profileActions = (profile) => {
    if (profile) {
      if (profile.profile_type === Constants.PROFILE_TYPE.GUEST) {
        this.props.updatePosOrder({guest_data: this.state.addProfile ? profile.id : null});
      } else {
        this.props.updatePosOrder({corporate_data: this.state.addProfile ? profile.id : null});
      }
      this.setState({
        showProfileDetails: false,
        showProfileSearch: false,
      });
    }
  };


  render = () => {

    const {posDetails, posOrder, resultMsg, loading} = this.props;

    if (loading) return <Loading/>;


    return (
      <div className="pos-order-details screen-container ">
        <Helmet>
          <title>POS Order Overview | {posOrder.ref_no}</title>
        </Helmet>

        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
            <div className={'row'}>
              <div className={'col-lg-6 col-md-12'}>
                <table className={'table table-borderless table-sm mb-3'}>
                  <tbody>

                    <tr>
                      <td className={'w-50 text-muted'}>Table</td>
                      <td>
                        {
                          !_.isEmpty(posOrder.pos_tables_attributes) ?
                            <React.Fragment>
                              {posOrder.pos_tables_attributes.map((data, i) =>
                                <React.Fragment>
                                  {(i > 0) && ', '}
                                  {`${data.name}`}
                                </React.Fragment>
                              )}
                            </React.Fragment>
                            : '....'
                        }
                      </td>
                    </tr>

                    {!_.isEmpty(posOrder.attributes) &&
                    <React.Fragment>
                      <tr>
                        <td className={'w-50 text-muted'}>Served By</td>
                        <td>{posOrder.attributes.serving_user || 'N.A'}</td>
                      </tr>
                      <tr>
                        <td className={'w-50 text-muted'}>Created By</td>
                        <td>{posOrder.attributes.created_user || 'N.A'}</td>
                      </tr>
                    </React.Fragment>
                    }

                    {!_.isEmpty(posOrder.corporate) &&
                    <React.Fragment>
                      <tr>
                        <td className={'w-50 text-muted'}>Corporate</td>
                        <td>
                          <button
                            onClick={() => this.showProfileDetails(posOrder.corporate.id)}
                            className={'btn btn-link m-0 p-0 text-left'}
                          >
                            {posOrder.corporate.entity_name || 'Corporate'}
                          </button>
                        </td>
                      </tr>
                    </React.Fragment>
                    }
                    {!_.isEmpty(posOrder.guest) &&
                    <React.Fragment>
                      <tr>
                        <td className={'w-50 text-muted'}>Guest</td>
                        <td>{posOrder.guest.name}</td>
                      </tr>
                    </React.Fragment>
                    }
                  </tbody>
                </table>
              </div>
              <div className={'col-lg-6 col-md-12'}>
                <table className={'table table-borderless table-sm mb-3'}>
                  <tbody>
                    {parseInt(posOrder.pos_kot_number) > 0 &&
                    <tr>
                      <td className={'w-50 text-muted'}>Confirmation #</td>
                      <td>{posOrder.pos_kot_number}</td>
                    </tr>
                    }
                    <tr>
                      <td className={'w-50 text-muted'}>Cover / Guest</td>
                      <td>{posOrder.occupancy}</td>
                    </tr>
                    {!_.isEmpty(posOrder.attributes) &&
                    <React.Fragment>
                      {posOrder.attributes.booking_ref_no &&
                      <tr>
                        <td className={'w-50 text-muted'}>Booking</td>
                        <td>{posOrder.attributes.booking_ref_no || 'N.A'}</td>
                      </tr>
                      }
                      {posOrder.attributes.booking_room_ref_no &&
                      <tr>
                        <td className={'w-50 text-muted'}>Booking Room</td>
                        <td>{posOrder.attributes.booking_room_ref_no || 'N.A'}</td>
                      </tr>
                      }
                      {posOrder.attributes.room &&
                      <tr>
                        <td className={'w-50 text-muted'}>Room</td>
                        <td>{posOrder.attributes.room || 'N.A'}</td>
                      </tr>
                      }
                    </React.Fragment>
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className={'spacer-20'}/>
            <table className="table border">
              <tbody>
                {!_.isEmpty(posOrder.order_items) ?
                  <React.Fragment>
                    {posOrder.order_items.map((data, i) => {
                      return (
                        <OrderItemListView
                          key={i}
                          currency={posOrder.currency}
                          lock={posOrder.lock}
                          data={data}
                          disabled={!data.status}
                          onClick={(data) => this.setState({
                            showPOSOrderItemDetailsView: true,
                            selectPOSOrderItemDetailsView: data,
                          })}
                          rowClassName={`${!data.status && 'table-dark'} ${!data.lock && 'table-warning'}`}
                        />
                      );
                    })
                    }
                  </React.Fragment>
                  :
                  <tr>
                    <td colSpan={3}>
                      <div className="text-center">
                        <FontAwesomeIcon icon={faExclamationTriangle} size={'2x'} className={'mt-3 red-cl'}/>
                        <p className={'text-secondary small'}>No items in this order</p>
                      </div>
                    </td>
                  </tr>
                }
              </tbody>
            </table>

            <table className="table border">
              <tbody>
                <tr>
                  <td className="text-left">Sub Total</td>
                  <td className="text-right">{posOrder.currency} {posOrder.sub_total}</td>
                </tr>
                {parseFloat(posOrder.complimentary_total) > 0 &&
                <tr>
                  <td className="text-left">Complimentary</td>
                  <td className="text-right">{posOrder.currency} {posOrder.complimentary_total}</td>
                </tr>
                }
                {parseFloat(posOrder.no_charge_total) > 0 &&
                <tr>
                  <td className="text-left">No Charge</td>
                  <td className="text-right">{posOrder.currency} {posOrder.no_charge_total}</td>
                </tr>
                }
                {parseFloat(posOrder.discount_total) > 0 &&
                <tr>
                  <td className="text-left">
                    Discount
                    <button
                      className={'btn btn-sm btn-link p-0 m-0 ml-2'}
                      onClick={() => this.setState({discountDetails: !this.state.discountDetails})}
                    >
                      Discount Information
                    </button>
                    <Collapse in={this.state.discountDetails}>
                      <table className={'table table-sm small text-secondary border mt-2'}>
                        <tbody>
                          <tr>
                            <td className={'small'}>Discount Pre Tax</td>
                            <td
                              className={'text-right small'}>{posOrder.currency} {posOrder.discount_total_pre_tax}</td>
                          </tr>
                          <tr>
                            <td className={'small'}>Discount Post Tax</td>
                            <td
                              className={'text-right small'}>{posOrder.currency} {posOrder.discount_total_post_tax}</td>
                          </tr>
                        </tbody>
                      </table>
                    </Collapse>
                  </td>
                  <td className="text-right">- {posOrder.currency} {posOrder.discount_total}</td>
                </tr>
                }
                <tr>
                  <td className="text-left">Tax</td>
                  <td className="text-right">{posOrder.currency} {posOrder.tax_total}</td>
                </tr>
                <tr>
                  <td className="text-left">
                    Discount
                    {!posOrder.lock &&
                    <button
                      type="button" className="btn btn-link mx-1 btn-sm" data-toggle="modal"
                      onClick={() => this.setState({
                        showPOSOrderEscalatedPermissionEdit: true,
                        showPOSOrderEscalatedPermissionEditType: 'discount',
                      })}
                    >(Edit)</button>
                    }
                    {parseFloat(posOrder.order_discount) !== 0 &&
                    <p className={'mb-0 small text-muted'}>
                      ({posOrder.order_discount_type_display}) {posOrder.order_discount_description}
                    </p>
                    }
                  </td>
                  <td className="text-right">{posOrder.currency} {posOrder.order_discount}</td>
                </tr>
                {parseFloat(posOrder.order_discount_post_payment) > 0 &&
                <tr>
                  <td>
                    <strong>Discount ( Post Payment )</strong>
                    {posOrder.order_discount_post_payment_description &&
                    <p className={'mb-0 small text-muted'}>
                      ({posOrder.order_discount_post_payment_type_display}) {posOrder.order_discount_post_payment_description}
                    </p>
                    }
                  </td>
                  <td className="text-right">{`${posOrder.currency} ${posOrder.order_discount_post_payment}`}</td>
                </tr>
                }
                {parseFloat(posOrder.service_charge) !== 0 &&
                <tr>
                  <td className="text-left">Service Charge</td>
                  <td className="text-right">{posOrder.currency} {posOrder.service_charge}</td>
                </tr>
                }
                {parseFloat(posOrder.total_rounding) !== 0 &&
                <tr>
                  <td className="text-left">Rounding</td>
                  <td className="text-right">{posOrder.currency} {posOrder.total_rounding}</td>
                </tr>
                }
                <tr>
                  <td className="text-left"><strong>Total</strong></td>
                  <td className="text-right"><strong>{posOrder.currency} {posOrder.total}</strong></td>
                </tr>
              </tbody>
            </table>


            <div className={'spacer-20'}/>
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
            {!posOrder.lock &&
            <div>
              <div className={'row'}>
                <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                  {posOrder.locked_items ?
                    <button
                      disabled={!posOrder.locked_items}
                      className={`btn btn-lg btn-block ${posOrder.pos_order_status === Constants.POS_ORDER_STATUS.HOLD ? 'btn-warning' : 'btn-primary'}`}
                      onClick={() => {
                        confirmAlert({
                          title: posOrder.pos_order_status === Constants.POS_ORDER_STATUS.HOLD ? 'Open Order' : 'Hold Order',
                          message: posOrder.pos_order_status === Constants.POS_ORDER_STATUS.HOLD ? Strings.posOpenOrder : Strings.posHoldOrder,
                          buttons: [
                            {
                              className: posOrder.pos_order_status === Constants.POS_ORDER_STATUS.HOLD ? 'btn-warning' : 'btn-primary',
                              label: posOrder.pos_order_status === Constants.POS_ORDER_STATUS.HOLD ? 'Open Order' : 'Hold Order',
                              onClick: () => this.props.updatePosOrderAdditional({pos_order_status: posOrder.pos_order_status === Constants.POS_ORDER_STATUS.HOLD ? Constants.POS_ORDER_STATUS.OPEN : Constants.POS_ORDER_STATUS.HOLD}, null, true),
                            },
                            {
                              className: 'btn-secondary',
                              label: 'Close',
                            },
                          ],
                        });
                      }}
                    >
                      {posOrder.pos_order_status === Constants.POS_ORDER_STATUS.HOLD ? 'Open Order' : 'Hold Order'}
                    </button>
                    :
                    <button
                      disabled={posOrder.locked_items}
                      className={'btn btn-lg btn-block btn-secondary'}
                      onClick={() => {
                        confirmAlert({
                          title: 'Cancel Order',
                          message: Strings.posCloseOrder,
                          buttons: [
                            {
                              className: 'btn-danger',
                              label: 'Cancel Order',
                              onClick: () => this.props.updatePosOrderAdditional({pos_order_status: Constants.POS_ORDER_STATUS.CANCELLED}, null, true),
                            },
                            {
                              className: 'btn-secondary',
                              label: 'Close',
                            },
                          ],
                        });
                      }}
                    >
                      Cancel Order
                    </button>
                  }

                </div>
                <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                  <button
                    disabled={!posOrder.locked_items}
                    className={'btn btn-lg btn-block btn-success'}
                    onClick={() => {
                      confirmAlert({
                        title: 'Confirm Order',
                        message: Strings.posConfirmOrder,
                        buttons: [
                          {
                            className: 'btn btn-success',
                            label: 'Confirm Order',
                            onClick: () => this.props.updatePosOrderAdditional({lock_order: true}, null, true, 'details'),
                          },
                          {
                            className: 'btn-secondary',
                            label: 'Close',
                          },
                        ],
                      });
                    }}
                  >
                    Confirm Order
                  </button>
                </div>
              </div>
              <p className={'text-muted text-center'}>
                {posOrder.locked_items ? Strings.posOrderConfirmationMessage : Strings.posOrderConfirmationMessageDisabled}
              </p>
              <div className={'spacer-20'}/>
            </div>
            }

            {!_.isEmpty(posOrder.orders) &&
            <div className={'row'}>
              <div className={'spacer-20'}/>
              <div className={'col-lg-12 col-md-12'}>
                <POSBillingOrderList
                  posDetails={posDetails}
                  orders={posOrder.orders}
                  property={this.props.property}
                  fetchPOSOrderData={() => this.props.fetchInitData()}
                />
              </div>
            </div>
            }

            {!_.isEmpty(posOrder.notes) &&
            <div>
              <div className={'spacer-20'}/>
              <div className={'row'}>
                <div className={'col-lg-6 col-md-12'}>
                  <h6>Notes</h6>
                  <div className="list-group">
                    {posOrder.notes.map((data, key) => (
                      <NotesListView
                        data={data}
                        key={key}
                        successAction={() => this.props.fetchInitData()}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            }
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group mb-5">
              <li className="list-group-item  align-items-center">
                <button onClick={() => this.setState({showNotesManager: true})} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faStickyNote} size={'sm'} className={'mr-2'}/> Add Note
                </button>
              </li>
            </ul>

            <ul className="list-group mb-5">
              <li className="list-group-item  align-items-center" onClick={() => this.props.cacheReset()}>
                <small>Order Actions</small>
              </li>
              <li className="list-group-item">
                <button
                  className="btn btn-link green-cl m-0 p-0"
                  disabled={posOrder.lock}
                  onClick={() => this.setState({
                    showPOSOrderEdit: true,
                    showPOSOrderEditType: 'edit',
                  })}
                >
                  <FontAwesomeIcon icon={faPencilAlt} size={'sm'} className={'mr-2'}/> Edit
                </button>
                <p className={'small text-muted mb-0'}>Edit Order / Tab details.</p>
              </li>
              <li className="list-group-item">
                <button
                  className="btn btn-link green-cl m-0 p-0"
                  disabled={posOrder.lock}
                  onClick={() => this.setState({
                    showPOSTableSelector: true,
                    showPOSTableSelectorType: 'shift',
                  })}
                >
                  <FontAwesomeIcon icon={faArrowsAltH} size={'sm'} className={'mr-2'}/> Shift Table
                </button>
                <p className={'small text-muted mb-0'}>Change order to another table.</p>
              </li>
              <li className="list-group-item">
                <button
                  disabled={(!_.isEmpty(posOrder.corporate) || posOrder.lock)}
                  type="button" className="btn btn-link m-0 p-0" data-toggle="modal"
                  onClick={() => this.setState({
                    showProfileSearch: true,
                    profileSource: Constants.PROFILE_TYPE.CORPORATE,
                  })}
                >
                  <FontAwesomeIcon
                    icon={faPlus} size={'sm'} className={'mr-2'}/>
                  Add Corporate
                </button>
                <p className={'small text-muted mb-0'}>Attach a corporate profile to order.</p>
              </li>
              <li className="list-group-item">
                <button
                  className="btn btn-link green-cl m-0 p-0"
                  disabled={posOrder.lock}
                  onClick={() => this.setState({
                    showPOSTableSelector: true,
                    showPOSTableSelectorType: 'add',
                  })}
                >
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Attach Table
                </button>
                <p className={'small text-muted mb-0'}>Attach new table to this order.</p>
              </li>
              <li className="list-group-item">
                <button
                  disabled={(posOrder.lock)}
                  type="button" className={`btn btn-link m-0 p-0 ${posOrder.room_id && 'orange-cl'}`}
                  data-toggle="modal"
                  data-target="#orderDiscountModel"
                  onClick={() => posOrder.room_id ?
                    confirmAlert({
                      title: 'Remove Room',
                      message: Strings.posOrderUnlinkRoom,
                      buttons: [
                        {
                          className: 'btn-warning',
                          label: 'Remove Room',
                          onClick: () => this.props.updatePosOrder({room: null}),
                        },
                        {
                          className: 'btn-secondary',
                          label: 'Close',
                        },
                      ],
                    })
                    : this.setState({showRoomQuickSelectListView: true})}
                >
                  <FontAwesomeIcon icon={posOrder.room_id ? faUnlink : faLink} size={'sm'} className={'mr-2'}/>
                  {posOrder.room_id ? 'Unlink ' : 'Link '} Room
                </button>
                <p className={'small text-muted mb-0'}>Link / Unlink Point of sale order-room.</p>
              </li>
              <li className="list-group-item">
                <button
                  className="btn btn-link orange-cl m-0 p-0"
                  disabled={posOrder.lock}
                  onClick={() => this.setState({
                    showPOSOrderEscalatedPermissionEdit: true,
                    showPOSOrderEscalatedPermissionEditType: 'complimentary',
                  })}
                >
                  <FontAwesomeIcon icon={faMoneyBillWave} size={'sm'} className={'mr-2'}/> Complimentary Order
                </button>
                <p className={'small text-muted mb-0'}>Requires Manager Permission.</p>
              </li>
              <li className="list-group-item">
                <button
                  className="btn btn-link orange-cl m-0 p-0"
                  disabled={posOrder.lock}
                  onClick={() => this.setState({
                    showPOSOrderEscalatedPermissionEdit: true,
                    showPOSOrderEscalatedPermissionEditType: 'nocharge',
                  })}
                >
                  <FontAwesomeIcon icon={faMoneyBillWave} size={'sm'} className={'mr-2'}/> NC Order
                </button>
                <p className={'small text-muted mb-0'}>Requires Manager Permission.</p>
              </li>
            </ul>

            <ul className="list-group">
              <li className="list-group-item  align-items-center">
                <small>Billing Actions</small>
              </li>
              <li className="list-group-item">
                <button
                  className="btn btn-link red-cl m-0 p-0"
                  disabled={!posOrder.allow_unlock}
                  onClick={() => {
                    confirmAlert({
                      title: 'Unlock Order',
                      message: Strings.posOrderUnlockText,
                      buttons: [
                        {
                          className: 'btn-warning',
                          label: 'Unlock Order',
                          onClick: () => this.props.escalatedPermissionUpdated({unlock_order: true}),
                        },
                        {
                          className: 'btn-secondary',
                          label: 'Close',
                        },
                      ],
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faLockOpen} size={'sm'} className={'mr-2'}/> Unlock Order
                </button>
                <p className={'small text-muted mb-0'}>Requires Manager Permission.</p>
              </li>
              <li className="list-group-item">
                <button
                  className="btn btn-link green-cl m-0 p-0"
                  disabled={!posOrder.allow_payment}
                  onClick={() => {
                    confirmAlert({
                      title: 'Generate Bill',
                      message: Strings.posOrderGenerateOrder,
                      buttons: [
                        {
                          className: 'btn-secondary',
                          label: 'Close',
                        },
                        {
                          className: 'btn-success',
                          label: 'Generate Bill',
                          onClick: () => this.props.escalatedPermissionUpdated({generate_bill: true}),
                        },
                      ],
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faCheck} size={'sm'} className={'mr-2'}/> Regenerate Bill
                </button>
                <p className={'small text-muted mb-0'}>Requires Manager Permission.</p>
              </li>
              <li className="list-group-item">
                <button
                  className="btn btn-link red-cl m-0 p-0"
                  disabled={posOrder.lock}
                  onClick={() => {
                    confirmAlert({
                      title: 'Cancel Order',
                      message: Strings.posCloseOrder,
                      buttons: [
                        {
                          className: 'btn-secondary',
                          label: 'Close',
                        },
                        {
                          className: 'btn-danger',
                          label: 'Cancel Order',
                          onClick: () => this.props.escalatedPermissionUpdated({pos_order_status: Constants.POS_ORDER_STATUS.CANCELLED}, null, true),
                        },
                      ],
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faCheck} size={'sm'} className={'mr-2'}/> Cancel Order
                </button>
                <p className={'small text-muted mb-0'}>Requires Manager Permission.</p>
              </li>
            </ul>
            <a
              href={posOrder.lock ? posOrder.resources_link : '#'} rel="noopener noreferrer" target={'_blank'}
              className={'btn btn-link text-muted m-0 p-0 ' + (posOrder.lock ? '' : 'disabled')}>.</a>
          </div>

        </div>

        <POSOrderItemDetailsView
          posOrder={posOrder}
          posDetails={posDetails}
          updateResponse={this.props.setPOSOrder}
          show={this.state.showPOSOrderItemDetailsView}
          POSOrderItem={this.state.selectPOSOrderItemDetailsView}
          onHide={() => this.setState({showPOSOrderItemDetailsView: false, selectPOSOrderItemDetailsView: null})}
        />
        <POSRoomSelectListView
          show={this.state.showRoomQuickSelectListView}
          onHide={() => this.setState({showRoomQuickSelectListView: false})}
          availabilityStatus={Constants.ROOM_STATUS.OCCUPIED}
          posDetails={posDetails}
          resultMsg={resultMsg}
          action={(data) => {
            this.props.updatePosOrder(data, () => this.setState({showRoomQuickSelectListView: false}));
          }}
        />
        <POSOrderEscalatedPermissionEdit
          updateResponse={this.props.setPOSOrder}
          onHide={() => this.setState({showPOSOrderEscalatedPermissionEdit: false})}
          show={this.state.showPOSOrderEscalatedPermissionEdit}
          posOrder={posOrder}
          posDetails={posDetails}
          editType={this.state.showPOSOrderEscalatedPermissionEditType}
        />
        <POSOrderEdit
          updateResponse={this.props.setPOSOrder}
          onHide={() => this.setState({showPOSOrderEdit: false})}
          show={this.state.showPOSOrderEdit}
          posOrder={posOrder}
          posDetails={posDetails}
          editType={this.state.showPOSOrderEditType}
        />
        <POSTableSelector
          resultMsg={resultMsg}
          posDetails={posDetails}
          show={this.state.showPOSTableSelector}
          changeType={this.state.showPOSTableSelectorType}
          onHide={() => this.setState({showPOSTableSelector: false})}
          updatePosOrder={(data) => this.props.updatePosOrderAdditional(data, () => this.setState({showPOSTableSelector: false}), true)}
        />
        {/* Notes */}
        <NotesManager
          onHide={() => this.setState({showNotesManager: false})}
          show={this.state.showNotesManager}
          successAction={() => this.props.fetchInitData()}
          POSOrder={posOrder}
        />

        {/*  Profile Search */}
        <ProfileQuickSearch
          onHide={() => this.setState({showProfileSearch: false})}
          show={this.state.showProfileSearch}
          showProfileDetails={this.showProfileDetails}
          isCorporate={true}
          corporatePOS={true}
        />

        {/*  Profile Details */}
        <GuestDetailsOverView
          onHide={() => this.setState({showProfileDetails: false})}
          guestId={this.state.profileDetailsData}
          show={this.state.showProfileDetails}
          addGuestFunction={this.state.addProfile}
          action={this.profileActions}
          resultMsg={resultMsg}
        />

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: false,
      discountDetails: false,
      showNotesManager: false,
      showProfileSearch: false,
      showProfileDetails: false,
      showPOSTableSelector: false,
      showPOSTableSelectorType: 'shift',
      showPOSOrderItemDetailsView: false,
      showRoomQuickSelectListView: false,
      showPOSOrderEscalatedPermissionEdit: false,
      profileSource: Constants.PROFILE_TYPE.CORPORATE,
      showPOSOrderEscalatedPermissionEditType: 'discount',
      showPOSOrderEdit: false,
      showPOSOrderEditType: 'edit',
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default POSOrderDetails;
