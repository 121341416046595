/**
 * Manage Package Map
 *  manages seasonal rates
 */
/* eslint no-eval: 0 */
import _ from 'lodash';
import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import Moment from 'moment';
import * as Yup from 'yup';
// Consts and Libs
import AppAPI from '../../../lib/api';
// Components
import {Alerts} from '../../../components/ui';
import DateRangePicker from 'react-daterange-picker';
import Loading from '../../../components/general/Loading';
import {faFile, faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Checkbox, Form, Input, Select, SubmitBtn} from 'react-formik-ui';
import {Strings} from '../../../constants';

const moment = require('moment');

/* Component ==================================================================== */

class AvailabilityBulkUpdate extends Component {
  static componentName = 'AvailabilityBulkUpdate';

  static propTypes = {
    match: PropTypes.object,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    property: PropTypes.object,
    roomTypes: PropTypes.array,
    roomTypeSelect: PropTypes.number,
    updateResponse: PropTypes.func,
    channelList: PropTypes.array,
    restrictionList: PropTypes.array,
    restrictionListOptions: PropTypes.array,
  };


  static defaultProps = {
    minDate: Moment().startOf('day'),
    maxDate: Moment().add(30, 'days'),
  }


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        loading: true,
        channelList: this.props.channelList,
        minDate: Moment(this.props.minDate),
        maxDate: this.props.maxDate ? Moment(this.props.maxDate) : null,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  updateAvailabilityData = (credentials) => {
    const {channelList, restrictionList, restrictionListOptions} = this.props;
    if (credentials) {

      let payload = {
        'room_type': credentials.roomType,
        'end_date': moment(this.state.end).format('YYYY-MM-DD'),
        'start_date': moment(this.state.start).format('YYYY-MM-DD'),
      };


      restrictionListOptions.forEach((data) => {
        if (restrictionList.includes(data.key)) {
          if (data.global) {
            payload[data.key] = credentials[data.key];
          } else {
            if (channelList.includes('booking_engine')) {
              if (eval(`credentials.booking_engine_${data.key}`) !== null) {
                payload[`booking_engine_${data.key}`] = eval(`credentials.booking_engine_${data.key}`);
              }
            }

            if (channelList.includes('channel_manager')) {
              if (eval(`credentials.channel_manager_${data.key}`)  !== null) {
                payload[`channel_manager_${data.key}`] = eval(`credentials.channel_manager_${data.key}`);
              }
            }

            if (channelList.includes('internal_engine')) {
              if (eval(`credentials.internal_engine_${data.key}`)  !== null) {
                payload[`internal_engine_${data.key}`] = eval(`credentials.internal_engine_${data.key}`);
              }
            }
          }
        }
      });

      if (!_.isEmpty(payload)) {
        this.setState({resultMsg: {status: 'One moment...'}}, () => {
          AppAPI.availabilityapi.post('availability-update/', payload)
            .then(() => {
              this.setState({resultMsg: {success: 'Success'}},
                () => {
                  setTimeout(() => {
                    this.setState({resultMsg: {success: ''}});
                    if (this.props.updateResponse) {
                      this.props.updateResponse();
                    }
                    this.props.onHide();
                  }, 500);
                });
            })
            .catch((err) => {
              const error = AppAPI.handleError(err);
              this.setState({resultMsg: {error}});
            });
        });
      }
    }
  };


  preFillData = () => {
    const {roomTypes, restrictionList, channelList} = this.props;
    let roomTypesData = [];
    if (roomTypes && roomTypes.length) {
      roomTypes.forEach(data => {
        roomTypesData.push({
          'value': data.room_type_id,
          'label': data.name,
        });
      });
      roomTypesData = roomTypesData.reverse();
    }

    let validation = {
      roomType: Yup.number().required(),
      all_channel_block: Yup.number().nullable().integer(),
    };

    let initialValues = {
      roomType: this.props.roomTypeSelect || '',
    };

    if (restrictionList.includes('all_channel_block')){
      initialValues['all_channel_block'] = 0;
    }

    channelList.forEach((data) => {
      if (restrictionList.includes('restriction')){
        initialValues[`${data}_restriction`] = false;
      }
    });

    this.setState(
      {
        roomTypesData,
        initialValues: initialValues,
        formValidation: Yup.object().shape(validation),
        init: false,
        loading: false,
        start: this.props.minDate,
        end: this.props.minDate,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      },
    );
  };

  onSelect = (date) => {
    const {selectionType} = this.state;
    if (selectionType === 'range') {
      this.setState({
        start: date.start,
        end: date.end,
      });
    } else {
      this.setState({start: date, end: date});
    }
  };

  render = () => {

    const {loading, init, resultMsg, roomTypesData, start, end, formValidation, initialValues, selectionType, channelList} = this.state;
    const {show, restrictionListOptions, restrictionList} = this.props;

    if (show && init) {
      this.preFillData();
    }

    if (loading) return null;


    return (
      <Modal
        {...this.props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Bulk Update Constrains
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={formValidation}
          onSubmit={values => this.updateAvailabilityData(values)}
        >
          {() => (
            <React.Fragment>
              <Modal.Body>
                {loading ? <Loading heightMatch={false}/>
                  :
                  <Form>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                        <div className="btn-group btn-group-sm w-100 mb-2" role="group">
                          <button
                            type="button"
                            disabled={true}
                            className={'btn btn-outline-secondary'}
                          >
                            Date Selection
                          </button>
                          <button
                            type="button"
                            className={`btn ${selectionType === 'single' ? 'btn-primary' : 'btn-outline-secondary'}`}
                            onClick={() => this.setState({selectionType: 'single', endDate: null})}
                          >
                            Single
                          </button>
                          <button
                            type="button"
                            className={`btn ${selectionType === 'range' ? 'btn-primary' : 'btn-outline-secondary'}`}
                            onClick={() => this.setState({selectionType: 'range'})}
                          >
                            Range
                          </button>
                        </div>
                        <div>
                          <div className="bg-white">
                            <div className="text-center mt-2">
                              <DateRangePicker
                                className={'w-100'}
                                selectionType={selectionType}
                                minimumDate={this.state.minDate}
                                maximumDate={this.state.maxDate}
                                value={start && (selectionType === 'single' ? start : Moment.range(start, end))}
                                onSelect={this.onSelect}
                                numberOfCalendars={2}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                        <table className={'table table-bordered text-center'}>
                          <thead>
                            <tr>
                              <th>Constrain</th>
                              <th className={`${channelList.includes('booking_engine') ? '' : 'd-none'}`}>Booking
                                Engine
                              </th>
                              <th className={`${channelList.includes('channel_manager') ? '' : 'd-none'}`}>Channel
                                Manager
                              </th>
                              <th className={`${channelList.includes('internal_engine') ? '' : 'd-none'}`}>Internal
                                Engine
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {(_.isEmpty(restrictionList)) &&
                             <tr>
                               <td colSpan={1 + Object.keys(channelList).length}>
                                 <div className={'text-center py-5'}>
                                   <h6><FontAwesomeIcon icon={faFile}/> No Channel Selected </h6>
                                   {Strings.channelOptionSelectionEmpty.map((data, i) => <p key={i} className={'text-muted'}>{data}</p>)}
                                 </div>
                               </td>
                             </tr>
                            }
                            {restrictionListOptions.filter(item => (item.input === 'bool' && item.editable !== false)).map((bookData, i) => (
                              <tr key={i} className={`${restrictionList.includes(bookData.key) ? '' : 'd-none'}`}>
                                <td className={'text-left align-middle'}>{bookData.name}</td>
                                <td
                                  className={`${channelList.includes('booking_engine') ? 'booking_engine-box' : 'd-none'}`}>
                                  <Checkbox
                                    className={'mr-2'}
                                    name={`booking_engine_${bookData.key}`}
                                  />
                                </td>
                                <td
                                  className={`${channelList.includes('channel_manager') ? 'channel_manager-box' : 'd-none'}`}>
                                  <Checkbox
                                    className={'mr-2'}
                                    name={`channel_manager_${bookData.key}`}
                                  />
                                </td>
                                <td
                                  className={`${channelList.includes('internal_engine') ? 'internal_engine-box' : 'd-none'}`}>
                                  <Checkbox
                                    className={'mr-2'}
                                    name={`internal_engine_${bookData.key}`}
                                  />
                                </td>
                              </tr>
                            ))}
                            {restrictionListOptions.filter(item => (item.input === 'number' && item.editable !== false)).map((bookData, i) => (
                              <tr key={i} className={`${restrictionList.includes(bookData.key) ? '' : 'd-none'}`}>
                                {bookData.global ? (
                                  <>
                                    <td className={'text-left align-middle'}>{bookData.name}</td>
                                    <td colSpan={3}>
                                      <Input
                                        type={'number'}
                                        name={bookData.key}
                                        className={'form-control'}
                                      />
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td className={'text-left align-middle'}>{bookData.name}</td>
                                    <td
                                      className={`${channelList.includes('booking_engine') ? 'booking_engine-box' : 'd-none'}`}>
                                      <Input
                                        type={'number'}
                                        name={`booking_engine_${bookData.key}`}
                                        className={'form-control'}
                                      />
                                    </td>
                                    <td
                                      className={`${channelList.includes('channel_manager') ? 'channel_manager-box' : 'd-none'}`}>
                                      <Input
                                        type={'number'}
                                        name={`channel_manager_${bookData.key}`}
                                        className={'form-control'}
                                      />
                                    </td>
                                    <td
                                      className={`${channelList.includes('internal_engine') ? 'internal_engine-box' : 'd-none'}`}>
                                      <Input
                                        type={'number'}
                                        name={`internal_engine_${bookData.key}`}
                                        className={'form-control'}
                                      />
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="form-row form-group">
                          <div className="col-12">
                            <Select
                              name='roomType'
                              className={'form-control'}
                              label='Room Type'
                              placeholder='Select an Option'
                              options={roomTypesData}
                              hint={'Select room type on to which the bulk update is to be applied to.'}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                }
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className="px-3">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <SubmitBtn
                      disabled={(!start || !end) || _.isEmpty(restrictionList)}
                      type="submit" className={'btn btn-success btn-lg btn-block'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/>
                      Update Restriction
                    </SubmitBtn>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: true,
      channelList: [],
      selectionType: 'range',
      initialValues: {
      },
    };
  }
}

/* Export Component ==================================================================== */
export default AvailabilityBulkUpdate;
