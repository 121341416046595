/**
 * Order Item Details
 *  Order Item Details
 *
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Actions
import {Constants} from '../../../constants';
// Components
import {Collapse} from 'react-bootstrap';

/* Component ==================================================================== */
class OrderItemDetails extends Component {
  static componentName = 'OrderItemDetails';

  static propTypes = {
    orderItem: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
    action: PropTypes.func,
    disabled: PropTypes.bool,
    bgClass: PropTypes.string,
  };

  static defaultProps = {
    bgClass: '',
    disabled: false,
  }

  action = (data) => {
    if (this.props.disabled !== true) {
      if (this.props.action) {
        this.props.action(data);
      }
    }
  };

  render = () => {
    const {orderItem, order, bgClass} = this.props;
    return (
      <React.Fragment>
        <table className={`table table border ${bgClass}`}>
          <tbody>
            <tr>
              <td className={'border-top-0'} colSpan={2}>
                {orderItem.description}
                {orderItem.additional_description &&
                <p className={'small text-muted mb-0'}>{orderItem.additional_description}</p>
                }
              </td>
            </tr>
            <tr>
              <td className={'w-75'}>
                Price
              </td>
              <td className={'text-right'}>
                <strong>{order.currency} {orderItem.price}</strong>
              </td>
            </tr>
            {orderItem.sub_quantity_type !== Constants.QUANTITY_TYPE.NOS &&
            <tr>
              <td className={'w-75'}>
                {orderItem.sub_quantity} <small>{orderItem.sub_quantity_type_display}</small> Price
              </td>
              <td className={'text-right'}>
                <strong>{order.currency} {orderItem.sub_quantity_sub_total}</strong>
              </td>
            </tr>
            }
            <tr>
              <td className={'w-75'}>
                {orderItem.sub_quantity} <small>{orderItem.sub_quantity_type_display}</small>
                <strong>x</strong> {orderItem.quantity}
                <small>{orderItem.quantity_type_display}</small>
              </td>
              <td className={'text-right'}>
                <strong>{order.currency} {orderItem.sub_total}</strong>
              </td>
            </tr>
          </tbody>
        </table>
        <hr/>
        <table className={'table table border'}>
          <tbody>
            <tr>
              <td className={'w-75'}>Sub Total</td>
              <td className={'text-right'}><strong>{order.currency} {orderItem.sub_total}</strong>
              </td>
            </tr>
            {orderItem.complimentary &&
            <tr>
              <td colSpan={2}>
                <p className={'mb-1'}><strong>Complimentary Item </strong></p>
                <p className={'text-muted small mb-0'}>{orderItem.complimentary_description} ({orderItem.complimentary_type_display})</p>
              </td>
            </tr>
            }
            {orderItem.no_charge &&
            <tr>
              <td colSpan={2}>
                <p className={'mb-1'}><strong>No Charge Item (NC) </strong></p>
                <p className={'text-muted small mb-0'}>{orderItem.no_charge_description} ({orderItem.no_charge_type_display})</p>
              </td>
            </tr>
            }
            {parseFloat(orderItem.discount) > 0 && orderItem.discount_before_tax && (
              <tr>
                <td className={'w-75'}>
                  Discount
                  <p className={'text-muted small mb-0'}>{orderItem.discount_description} ({orderItem.discount_type_display})</p>
                </td>
                <td className={'text-right'}>
                  <strong>- {order.currency} {orderItem.discount}</strong>
                </td>
              </tr>
            )}
            <tr>
              <td className={'w-75'}>
                Tax
                {(orderItem.tax_data && orderItem.tax_data.description && !orderItem.complimentary) && (
                  <p className={'small text-muted mb-0'}>{orderItem.tax_data.description}</p>
                )}

                {orderItem.tax_expanded && orderItem.tax_expanded.tax_data && orderItem.tax_expanded.tax_data.length > 0 &&
                <React.Fragment>
                  <button
                    type={'button'}
                    className={'btn btn-link p-0 m-0'}
                    onClick={() => this.setState({taxDetails: !this.state.taxDetails})}
                  >
                    <small>Tax Information</small>
                  </button>
                  <Collapse in={this.state.taxDetails}>
                    <table className={'table table-sm small text-secondary border mt-2'}>
                      <thead>
                        <tr>
                          <th className={'small text-dark'}>Tax Name</th>
                          <th className={'text-right text-dark small'}>Tax Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderItem.tax_expanded.tax_data.map((data, i) => (
                          <tr key={i}>
                            <td className={'small'}>{data.name}</td>
                            <td className={'text-right small'}>{order.currency} {data.value}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Collapse>
                </React.Fragment>
                }
              </td>
              <td className={'text-right'}><strong>{order.currency} {orderItem.tax}</strong></td>
            </tr>
            {parseFloat(orderItem.discount) > 0 && !orderItem.discount_before_tax && (
              <tr>
                <td className={'w-75'}>
                  Discount
                  <p className={'text-muted small mb-0'}>{orderItem.discount_description} ({orderItem.discount_type_display})</p>
                </td>
                <td className={'text-right'}><strong>{order.currency} {orderItem.discount}</strong>
                </td>
              </tr>
            )}
            <tr>
              <td className={'w-75'}><strong>Total</strong></td>
              <td className={'text-right'}><strong>{order.currency} {orderItem.total}</strong></td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  };

  constructor(props) {
    super(props);
    this.state = {
      taxDetails: false
    };
  }
}

/* Export Component ==================================================================== */
export default (OrderItemDetails);

