/**
 * Booking Room Reducer
 *
 */

// Set initial state
const initialState = {
  bookingroom: {},
  bookingroomamendment: null,
  guest_remove: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case 'SET_BOOKING_ROOM':
    let bookingroom = [];
    if (action.data && typeof action.data === 'object') {
      bookingroom = action.data;
    }

    return {
      ...state,
      bookingroom,
    };
  case 'SET_BOOKING_ROOM_AMENDMENT':
    let bookingroomamendment = [];
    if (action.data && typeof action.data === 'object') {
      bookingroomamendment = action.data;
    }

    return {
      ...state,
      bookingroomamendment,
    };
  case 'SET_GUEST_STATUS':
    return {
      ...state,
      guest_remove: action.data,
    };
  default:
    return state;
  }
};
