/**
 *
 * Room Type Details
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../../../lib/api';
// Components
import {faPen} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PageHeader from '../../../../components/dashboard/PageHeader';
import Loading from '../../../../components/general/Loading';
import Error from '../../../../components/general/Error';
// Actions
import * as RoomTypeActions from '../../../../redux/roomtype/actions';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  roomType: state.roomType.roomType,
  roomTypePackage: state.roomType.roomTypePackage,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setRoomTypePackage: RoomTypeActions.setRoomTypePackage,
};

/* Component ==================================================================== */
class RoomTypePackagesSettings extends Component {
  static componentName = 'RoomTypePackagesSettings';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };
  componentDidMount = () => {
    if (this.props.match.params.roomTypeId && this.props.match.params.roomTypePackageId) {
      this.props.setRoomTypePackage(this.props.match.params.roomTypePackageId, this.props.match.params.roomTypeId)
        .then(() => {
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };
  render = () => {

    const {loading, error} = this.state;
    const {roomType, roomTypePackage, property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    return (
      <div className="room-type-details screen-container ">
        <Helmet>
          <title>Room Types Package Settings</title>
        </Helmet>

        <PageHeader
          subHeader={true} history={this.props.history} title={'Room Type Package'}
          description={`Settings & Configuration for package : ${roomType.name}`}
        />
        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12 mb-5">
            <div className="row">
              <div className="col mb-3">
                <ul className="list-group">
                  <li className="list-group-item"><strong>Reference</strong> : {roomTypePackage.ref_no}</li>
                  <li className="list-group-item"><strong>Name</strong> : {roomTypePackage.name}</li>
                  <li className="list-group-item"><strong>Package Code</strong> : {roomTypePackage.channel_manager_package_id || 'N.A'}</li>
                </ul>
              </div>
              <div className="col mb-3">
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Status</strong> :
                    <span className={'badge ' + (roomTypePackage.active === true ? 'badge-success' : 'badge-danger')}>
                      {(roomTypePackage.active === true ? 'Active' : 'Deactivated')}
                    </span>
                  </li>
                  <li className="list-group-item">
                    <strong>Booking Engine</strong> :
                    <span
                      className={'badge ' + (roomTypePackage.booking_engine_ok === true ? 'badge-success' : 'badge-danger')}>
                      {(roomTypePackage.booking_engine_ok === true ? 'Connected' : 'Not Connected')}
                    </span>
                  </li>
                  <li className="list-group-item">
                    <strong>Channel Manager</strong> :
                    <span
                      className={'badge ' + (roomTypePackage.channel_manager_ok === true ? 'badge-success' : 'badge-danger')}>
                      {(roomTypePackage.channel_manager_ok === true ? 'Connected' : 'Not Connected')}
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="col mb-3">
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Description</strong> : {roomTypePackage.description ? roomTypePackage.description : 'N.A'}
                  </li>
                  <li className="list-group-item">
                    <strong>Cancellation Policy</strong> :
                    {roomTypePackage.cancellation_policy ? roomTypePackage.cancellation_policy_display : 'N.A'}
                  </li>
                  <li className="list-group-item">
                    <ul className="list-inline">
                      <li className="list-inline-item"><p className={'mb-0'}>Inclusions</p></li>
                      <li className="list-inline-item">
                        <strong>Breakfast</strong> :
                        {roomTypePackage.inclusion_break_fast ? <span className="fa mx-2 green-cl fa-sm fa-check-circle"/> :
                          <span className="fa mx-2 red-cl fa-sm fa-times-circle"/>}
                      </li>
                      <li className="list-inline-item">
                        <strong>Lunch</strong> :
                        {roomTypePackage.inclusion_lunch ? <span className="fa mx-2 green-cl fa-sm fa-check-circle"/> :
                          <span className="fa mx-2 red-cl fa-sm fa-times-circle"/>}
                      </li>
                      <li className="list-inline-item">
                        <strong>Dinner</strong> :
                        {roomTypePackage.inclusion_dinner ? <span className="fa mx-2 green-cl fa-sm fa-check-circle"/> :
                          <span className="fa mx-2 red-cl fa-sm fa-times-circle"/>}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            
            <p className={'mb-0 small text-secondary'}>All rates other than base price is per guest</p>
            <div className="row mb-5">
              <div className={'col-12'}>
                <div className={'table-responsive'}>
                  <table className={'table border'}>
                    <thead>
                      <tr>
                        <th className={'w-50'}>Type</th>
                        <th className={'text-right'}>Price ({property.currency})</th>
                        <th className={'text-right'}>Price (Inc Tax) ({property.currency})</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><strong>Base Price</strong></td>
                        <td className={'text-right'}>{roomTypePackage.base_price}</td>
                        <td className={'text-right'}>{roomTypePackage.base_price_effective}</td>
                      </tr>
                      <tr>
                        <td><strong>Additional Guest</strong></td>
                        <td className={'text-right'}>{roomTypePackage.guest_price}</td>
                        <td className={'text-right'}>{roomTypePackage.guest_price_effective}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Discount Per Guest</strong>
                          <p className={'mb-0 small text-secondary'}>Discount per guest when selling to guest below min occupancy.</p>
                        </td>
                        <td className={'text-right'}>{roomTypePackage.discount_per_guest_price}</td>
                        <td className={'text-right'}>{roomTypePackage.discount_per_guest_price_effective}</td>
                      </tr>
                      <tr>
                        <td><strong>Child</strong></td>
                        <td className={'text-right'}>{roomTypePackage.child_price}</td>
                        <td className={'text-right'}>{roomTypePackage.child_price_effective}</td>
                      </tr>
                      <tr>
                        <td><strong>Infant</strong></td>
                        <td className={'text-right'}>{roomTypePackage.infant_price}</td>
                        <td className={'text-right'}>{roomTypePackage.infant_price_effective}</td>
                      </tr>
                      <tr>
                        <td><strong>Extra Bed</strong></td>
                        <td className={'text-right'}>{roomTypePackage.extra_bed_price}</td>
                        <td className={'text-right'}>{roomTypePackage.extra_bed_price_effective}</td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          <strong>Tax :</strong> {roomTypePackage.tax_cache && roomTypePackage.tax_cache.description ? roomTypePackage.tax_cache.description : 'N.A'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link
                  to={`/settings/inventory/room-type/${this.props.match.params.roomTypeId}/package/${roomTypePackage.id}/edit`}
                  className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomTypePackagesSettings);