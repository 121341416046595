import React from 'react';
import {Route, Switch, NavLink} from 'react-router-dom';
import ReviewList from './ReviewList';

const menu = [
  {
    title: 'All',
    link: '/review/list/',
    isExact: true
  },
  {
    title: 'Completed',
    link: '/review/list/complete'
  },
  {
    title: 'Pending',
    link: '/review/list/pending'
  },
  {
    title: 'Cancelled',
    link: '/review/list/cancelled'
  }
];

const menuItems = [];

menu.map((item) => {
  const {title, link, isExact} = item;
  return menuItems.push(
    <li role="presentation" className="nav-item" key={`menu-item-${title}`}>
      <NavLink
        to={{
          pathname: link,
          state: {fromDashboard: true}
        }}
        exact={isExact}
        className="nav-link"
        activeClassName="selected">
        {title}
      </NavLink>
    </li>
  );
});

const ReviewListRoute = () => (
  <div>
    <div className="page-header">
      <h1>Review's</h1>
      <p>Lists all reviews in the property. Choose category to filter reviews.</p>
    </div>

    <ul className="nav nav-tabs my-3 my-md-5">
      {menuItems}
    </ul>

    <Switch>
      <Route path="/review/list/" exact component={ReviewList}/>
      <Route path="/review/list/:reviewType" component={ReviewList}/>
    </Switch>

  </div>
);

export default ReviewListRoute;