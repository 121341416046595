/**
 * App Error Messages
 *
 */

export default {
  // Defaults
  default: 'Hmm, an unknown error occurred',
  timeout: 'Server Timed Out. Check your internet connection',
  invalidJson: 'Response returned is not valid JSON',
  initData: 'Failed to fetch form data',
  userSessionExpired: 'User Session Expired.',

  emptyProperty: 'No Property Linked to the account',
  emptyRoomType: 'No Room Types added to property',
  emptyRoom: 'No rooms added to property',
  emptyGuest: 'No guest added to property',
  emptyCorporate: 'No Corporate added to property',
  emptyBooking: 'No Bookings',
  emptyBookingRoom: 'No Bookings',
  emptyOrder: 'No Order',
  emptyPOS: 'No Point of sales in property',
  emptyPOSTables: 'No Tables in the POS',
  emptyBookingAmendment: 'No Booking Amendment added to Property',
  emptyUsers: 'No Users exist to manage',

  // Recipes
  recipe404: 'No recipes found',
  missingMealId: 'Missing meal definition',
};
