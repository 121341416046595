/**
 *  POS Content Manage
 *
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import _ from 'lodash';
// Actions
import * as POSActions from '../../../redux/pos/actions';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {ErrorMessages, Strings} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave} from '@fortawesome/free-solid-svg-icons';
// Form
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Form, Input, SubmitBtn, Textarea} from 'react-formik-ui';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pos: state.pos.pos,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPOS: POSActions.setPOS,
};

/* Component ==================================================================== */

class POSContentSettingsManage extends Component {
  static componentName = 'POSContentSettingsManage';

  static propTypes = {
    match: PropTypes.object,
  };

  componentDidMount = () => {
    if (this.props.match.params.POSId) {
      this.props.setPOS(this.props.match.params.POSId)
        .then(() => {
          this.fetchInitData();
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.fetchInitData();
    }
  };

  savePOS = (values) => {
    if (values) {

      const printContent = {
        'order': {
          'header_title': values.bill_header_title,
          'header_sub_title': values.bill_header_sub_title,
          'footer_notes': values.bill_print_footer_notes,
        },
        'kot': {
          'header_title': values.kot_header_title,
          'header_sub_title': values.kot_header_sub_title,
          'footer_notes': values.kot_print_footer_notes,
        }
      };

      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.posapi.patch(`pos-settings/${this.props.match.params.POSId}/`,
          {
            'print_content': printContent,

            'bill_width': values.bill_width,
            'bill_item_height': values.bill_item_height,
            'bill_header_height': values.bill_header_height,
            'bill_footer_height': values.bill_footer_height,

            'kot_width': values.kot_width,
            'kot_item_height': values.kot_item_height,
            'kot_header_height': values.kot_header_height,
            'kot_footer_height': values.kot_footer_height,
          })
          .then((posData) => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.setPOS(posData);
                  this.props.history.goBack();
                }, 500);
              },
            );
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  fetchInitData = () => {
    const {pos} = this.props;
    const printContent = pos.print_content || {};

    let initialValues = {
      'bill_header_title': _.isEmpty(printContent.order) ? '' : printContent.order.header_title,
      'bill_header_sub_title': _.isEmpty(printContent.order) ? '' : printContent.order.header_sub_title,
      'bill_print_footer_notes': _.isEmpty(printContent.order) ? '' : printContent.order.footer_notes,

      'kot_header_title': _.isEmpty(printContent.kot) ? '' : printContent.kot.header_title,
      'kot_header_sub_title': _.isEmpty(printContent.kot) ? '' : printContent.kot.header_sub_title,
      'kot_print_footer_notes': _.isEmpty(printContent.kot) ? '' : printContent.kot.footer_notes,

      'bill_width': pos.bill_width,
      'bill_item_height': pos.bill_item_height,
      'bill_header_height': pos.bill_header_height,
      'bill_footer_height': pos.bill_footer_height,

      'kot_width': pos.kot_width,
      'kot_item_height': pos.kot_item_height,
      'kot_header_height': pos.kot_header_height,
      'kot_footer_height': pos.kot_footer_height,
    };


    const validation = {
      bill_header_title: Yup.string().max(200, 'Too Long!'),
      bill_header_sub_title: Yup.string().max(200, 'Too Long!'),

      bill_width: Yup.number().positive().required(),
      bill_item_height: Yup.number().positive().required(),
      bill_header_height: Yup.number().positive().required(),
      bill_footer_height: Yup.number().positive().required(),

    };

    if (pos.pos_has_kot) {
      validation.kot_header_title = Yup.string().max(200, 'Too Long!');
      validation.kot_header_sub_title = Yup.string().max(200, 'Too Long!');

      validation.kot_width = Yup.number().positive().required();
      validation.kot_width = Yup.number().positive().required();
      validation.kot_width = Yup.number().positive().required();
      validation.kot_width = Yup.number().positive().required();
    }

    let formValidation = Yup.object().shape(validation);
    this.setState({
      initialValues,
      formValidation,
      loading: false,
    });
  };

  render = () => {

    const {loading, error, initialValues, formValidation, resultMsg} = this.state;
    const {pos} = this.props;
    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    return (

      <div className="room-type-list-manage screen-container ">
        <Helmet>
          <title>POS Manage</title>
        </Helmet>
        <PageHeader
          subHeader={true} history={this.props.history}
          title={'Manage Content'}
          description={'Manage content of Point of Sale'}
        />

        <Formik
          onSubmit={(values) => this.savePOS(values)}
          validationSchema={formValidation}
          initialValues={initialValues}>
          {() => (
            <Form>
              <div className={'row'}>
                <div className={'col-lg-8 col-md-8 col-sm-12'}>
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      POS Invoice/Order Settings
                    </h6>
                  </div>
                  <div className={'border p-2 mb-3'}>
                    <div className={'row'}>
                      <div className={'col-12'}>
                        <div className="form-row">
                          <div className="col-lg-12 col-sm-12 form-group">
                            <Input
                              required
                              name={'bill_header_title'}
                              hint={Strings.financialHeaderTitle}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-lg-12 col-sm-12 form-group">
                            <Input
                              required
                              name={'bill_header_sub_title'}
                              hint={Strings.financialHeaderSubTitle}
                              className={'form-control'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr/>

                    <div className={'bg-light py-5 text-center'}>
                      <p className={'text-muted'}>POS Order Content</p>
                    </div>

                    <hr/>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Textarea
                          name='bill_print_footer_notes'
                          label={'Footer Notes'}
                          hint={Strings.financialFooterNotes}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Order/Invoice Print Settings ( All dimension in inch)
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-3 form-group">
                        <Input
                          required
                          label={'Page Width'}
                          name={'bill_width'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-3 form-group">
                        <Input
                          required
                          label={'Header Height'}
                          name={'bill_header_height'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-3 form-group">
                        <Input
                          required
                          label={'Footer Height'}
                          name={'bill_footer_height'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-3 form-group">
                        <Input
                          required
                          label={'Line Height'}
                          name={'bill_item_height'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                  </div>


                  <hr/>

                  {pos.pos_has_kot &&
                  <React.Fragment>
                    <div className={'border mt-4 p-2 text-center anchor w-100'}>
                      <h6 className={'mb-0'}>
                        POS KOT Settings
                      </h6>
                    </div>
                    <div className={'border p-2 mb-3'}>
                      <div className={'row'}>
                        <div className={'col-12'}>
                          <div className="form-row">
                            <div className="col-lg-12 col-sm-12 form-group">
                              <Input
                                required
                                name={'kot_header_title'}
                                hint={Strings.financialHeaderTitle}
                                className={'form-control'}
                              />
                            </div>
                            <div className="col-lg-12 col-sm-12 form-group">
                              <Input
                                required
                                name={'kot_header_sub_title'}
                                hint={Strings.financialHeaderSubTitle}
                                className={'form-control'}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr/>

                      <div className={'bg-light py-5 text-center'}>
                        <p className={'text-muted'}>POS KOT Content</p>
                      </div>
                    </div>

                    <div className={'border mt-4 p-2 text-center anchor w-100'}>
                      <h6 className={'mb-0'}>
                        KOT Print Settings ( All dimension in inch)
                      </h6>
                    </div>
                    <div className={'border p-2'}>
                      <div className="form-row">
                        <div className="col-3 form-group">
                          <Input
                            required
                            label={'Page Width'}
                            name={'kot_width'}
                            className={'form-control'}
                          />
                        </div>
                        <div className="col-3 form-group">
                          <Input
                            required
                            label={'Header Height'}
                            name={'kot_header_height'}
                            className={'form-control'}
                          />
                        </div>
                        <div className="col-3 form-group">
                          <Input
                            required
                            label={'Footer Height'}
                            name={'kot_footer_height'}
                            className={'form-control'}
                          />
                        </div>
                        <div className="col-3 form-group">
                          <Input
                            required
                            label={'Line Height'}
                            name={'kot_item_height'}
                            className={'form-control'}
                          />
                        </div>
                      </div>
                    </div>

                  </React.Fragment>
                  }

                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn className={'btn btn-success btn-block btn-lg mt-2'}>
                    <FontAwesomeIcon
                      className={'white-cl mr-2'}
                      icon={faSave} size={'sm'}
                    /> Save
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>


      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      initialValues: {},
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(POSContentSettingsManage);
