/**
 *  POS Table Manage
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Actions
import * as POSActions from '../../../redux/pos/actions';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants, ErrorMessages, Strings} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faSave} from '@fortawesome/free-solid-svg-icons';
// Form
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Form, Input, Select, SubmitBtn, Textarea} from 'react-formik-ui';
import AppUtil from '../../../lib/util';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pos: state.pos.pos,
  table: state.pos.table,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPOSTable: POSActions.setPOSTable,
};

/* Component ==================================================================== */

class POSTableManage extends Component {
  static componentName = 'POSTableManage';

  static propTypes = {
    match: PropTypes.object,
  };

  componentDidMount = () => {
    if (this.props.match.params.POSTableId) {
      this.props.setPOSTable(this.props.match.params.POSTableId, this.props.match.params.POSId)
        .then(() => {
          this.fetchInitData();
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.fetchInitData();
    }
  };

  addCategory = (credentials) => {
    const {pos} = this.props;
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.posapi.post(`pos-table-settings/?pos=${pos.hash}`,
          {
            'name': credentials.name,
            'occupancy': credentials.occupancy,
            'active': credentials.active,
            'table_note': credentials.table_note,
            'table_shape': credentials.table_shape,
            'service_status': credentials.service_status,
            'maintenance_status': credentials.maintenance_status,
          })
          .then((res) => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.setPOSTable(res);
                  this.props.history.replace(this.props.location.pathname.replace(/[^/]*$/, `${res.hash}/`));
                }, 500);
              },
            );
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  saveCategory = (credentials) => {
    const {pos} = this.props;
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.posapi.patch(`pos-table-settings/${this.props.match.params.POSTableId}/?pos=${pos.hash}`,
          {
            'name': credentials.name,
            'occupancy': credentials.occupancy,
            'active': credentials.active,
            'table_note': credentials.table_note,
            'table_shape': credentials.table_shape,
            'service_status': credentials.service_status,
            'maintenance_status': credentials.maintenance_status,
          })
          .then((res) => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.setPOSTable(res);
                  this.props.history.goBack();
                }, 500);
              },
            );
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  fetchInitData = () => {
    const {table} = this.props;

    let initialValues = {
      'name': '',
      'occupancy': '',
      'table_shape': '',
      'service_status': '1',
      'maintenance_status': '1',
    };

    if (this.props.match.params.POSTableId) {
      initialValues = {
        'name': table.name,
        'occupancy': table.occupancy,
        'active': table.active,
        'table_note': table.table_note,
        'table_shape': table.table_shape,
        'service_status': table.service_status,
        'maintenance_status': table.maintenance_status,
      };
    }
    let formValidation = Yup.object().shape({
      name: Yup.string().min(2, 'Too Short!').required('Required'),
      occupancy: Yup.number().required('Required'),
      table_shape: Yup.number().required('Required'),
      service_status: Yup.number().required('Required'),
      maintenance_status: Yup.number().required('Required'),
    });
    this.setState({
      initialValues,
      formValidation,
      loading: false,
    });
  };

  render = () => {

    const {table} = this.props;
    const {loading, error, initialValues, formValidation, resultMsg} = this.state;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    return (

      <div className="pos-table-list-manage screen-container ">
        <Helmet>
          <title>POS Table Manage</title>
        </Helmet>
        <PageHeader
          subHeader={true} history={this.props.history}
          title={(this.props.match.params.POSTableId ? 'Manage' : 'Add') + ' Table'}
          description={this.props.match.params.POSTableId ? `Edit Table ${table.name}` : 'Add new table'}
        />

        <Formik
          onSubmit={(values) => this.props.match.params.POSTableId ? this.saveCategory(values) : this.addCategory(values)}
          validationSchema={formValidation}
          initialValues={initialValues}>
          {() => (
            <Form>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Table Information
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Input
                          required
                          name='name'
                          label={'Table Name'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-6 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='occupancy'
                          label={'Occupancy'}
                          placeholder='Select an Option'
                          options={AppUtil.numericSelector(1, 30)}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='table_shape'
                          label={'Table Shape'}
                          placeholder='Select an Option'
                          options={Constants.POS_TABLE_SHAPE}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-12 form-group">
                        <Textarea
                          name='table_note'
                          label={'Quick Note'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                  <p className={''}><strong>Name : </strong> {Strings.posTableName}</p>
                  <p className={''}><strong>Table Occupancy : </strong> {Strings.posTableOccupancy}</p>
                  <p className={''}><strong>Table Shape : </strong> {Strings.posTableShape}</p>
                  <p className={''}><strong>Table Note : </strong> {Strings.posTableNote}</p>
                </div>
              </div>

              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Table Status
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-6 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='service_status'
                          label={'Service Status'}
                          placeholder='Select an Option'
                          options={Constants.POS_SERVICE_STATUS}
                        />
                        <p className={'small text-muted'}>{Strings.posServiceStatus}</p>
                      </div>
                      <div className="col-6 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='maintenance_status'
                          label={'Maintenance Status'}
                          placeholder='Select an Option'
                          options={Constants.POS_MAINTENANCE_STATUS}
                        />
                        <p className={'small text-muted'}>{Strings.posMaintenanceStatus}</p>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                </div>
              </div>

              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 mt-2">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn className={'btn btn-success btn-block btn-lg mt-2'}>
                    <FontAwesomeIcon
                      className={'white-cl mr-2'}
                      icon={this.props.match.params.POSTableId ? faSave : faPlus} size={'sm'}
                    /> {this.props.match.params.POSTableId ? 'Save' : 'Add'}
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>


      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      initialValues: {},
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(POSTableManage);
