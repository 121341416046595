/**
 * Booking Channel log
 * Booking channel log details
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import React, {Component} from 'react';
// Consts and Libs
import AppUtil from '../../../lib/util';
import {Constants} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import BadgeStatus from '../../../components/general/BadgeStatus';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLinkAlt, faTimes} from '@fortawesome/free-solid-svg-icons';


/* Component ==================================================================== */

class BookingChannelLogDetails extends Component {
  static componentName = 'BookingChannelLogDetails';

  static propTypes = {
    show: PropTypes.bool,
    booking: PropTypes.object,
    bookingLog: PropTypes.object,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  render = () => {

    const {resultMsg} = this.state;
    const {show, booking, bookingLog, property} = this.props;

    if (!show || _.isEmpty(bookingLog)) { return null; }

    const {booking_data} = bookingLog;

    return (
      <Modal {...this.props} size={'lg'} aria-labelledby="contained-modal-title-vcenter" scrollable centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            Booking Log #{bookingLog.ref_no}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-4">
            <div className={'col-md-6 col-sm-12'}>
              <table className={'table table-borderless table-sm'}>
                <tbody>
                  <tr>
                    <td className={'w-50 text-muted'}>Booking Log Ref</td>
                    <td><strong>{bookingLog.ref_no}</strong></td>
                  </tr>
                  <tr>
                    <td className={'w-50 text-muted'}>Channel Manager</td>
                    <td>{booking.booking_channel_manager_display}</td>
                  </tr>
                  <tr>
                    <td className={'w-50 text-muted'}>Online Travel Agent (OTA)</td>
                    <td>{booking.attributes.booking_ota}</td>
                  </tr>
                  <tr>
                    <td className={'w-50 text-muted'}>Time</td>
                    <td>{AppUtil.formatDateTime(bookingLog.created)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={'col-md-6 col-sm-12'}>
              <table className={'table table-borderless table-sm'}>
                <tbody>
                  <tr>
                    <td className={'w-50 text-muted'}>Log Status</td>
                    <td>
                      <BadgeStatus successText={'Applied'} failText={'Not Applied'} status={bookingLog.log_status}/>
                    </td>
                  </tr>
                  <tr>
                    <td className={'w-50 text-muted'}>Log Type</td>
                    <td><strong>{bookingLog.type_display}</strong></td>
                  </tr>
                  {bookingLog.type === Constants.BOOKING_LOG_TYPE.MODIFY &&
                    <tr>
                      <td className={'w-50 text-muted'}>Edit Type</td>
                      <td>{bookingLog.update_type_display}</td>
                    </tr>
                  }
                  {!_.isEmpty(bookingLog.attributes) &&
                    <React.Fragment>
                      {bookingLog.attributes.previous_log_hash &&
                      <tr>
                        <td className={'w-50 text-muted'}>Last applied log</td>
                        <td>{bookingLog.attributes.previous_log_hash}</td>
                      </tr>
                      }
                    </React.Fragment>
                  }
                </tbody>
              </table>
            </div>
          </div>
          {bookingLog.description &&
          <div className="alert alert-warning" role="alert">
            <p className={'mb-0'}><strong>Information</strong> : {bookingLog.description}</p>
          </div>
          }
          <div className={'border p-3 bg-light rounded mb-3'}>
            <p><strong>Guest Detail</strong></p>
            {!_.isEmpty(booking_data.guest) &&
            <div className={'pl-5'}>
              <p><span className={'text-muted'}>Name</span> : {`${booking_data.guest.first_name} ${booking_data.guest.last_name}`}</p>
            </div>
            }
            <p><strong>Booking Detail</strong></p>
            {!_.isEmpty(booking_data.booking_room_data) &&
            <div className={'pl-5'}>
              {booking_data.booking_room_data.map((data, i)=>(
                <div key={i} className={'mb-3'}>
                  {i > 0 && <hr/>}
                  <div className={'row'}>
                    <div className={'col-6 col-md-4'}>
                      <p className={'mb-1'}>
                        <span className={'text-muted'}>Checkin</span> :
                        {AppUtil.formatDateTime(data.checkin, 'datef')}
                      </p>
                    </div>
                    <div className={'col-6 col-md-4'}>
                      <p className={'mb-1'}>
                        <span className={'text-muted'}>Checkout</span> :
                        {AppUtil.formatDateTime(data.checkout, 'datef')}
                      </p>
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className={'col-6 col-md-4'}>
                      <p className={'mb-1'}><span className={'text-muted'}>Room Type</span> : {data.room_type}</p>
                    </div>
                    <div className={'col-12 col-md-8'}>
                      <ul className="list-inline mb-0">
                        <li className={'list-inline-item'}><span className={'text-muted'}>Occupancy : </span></li>
                        <li className={'list-inline-item'}>Adult : {data.no_of_guest}</li>
                        <li className={'list-inline-item'}>Children : {data.no_of_children || 0}</li>
                        <li className={'list-inline-item'}>Infant : {data.no_of_infant || 0}</li>
                      </ul>
                    </div>
                  </div>
                  {!_.isEmpty(data.guests_note) &&
                    <div className={'row'}>
                      <div className={'col-12'}>
                        <p className={'mb-1'}>
                          <span className={'text-muted'}>Guests : </span>
                          {data.guests_note.map((guest_data, k)=>(
                            <span key={k}>{`${guest_data.first_name} ${guest_data.last_name}`}</span>
                          ))}
                        </p>
                      </div>
                    </div>
                  }
                  {!_.isEmpty(data.room_type_packages) &&
                    <div className={'row my-2'}>
                      <div className={'col-12 col-md-8'}>
                        <table className={'table table-bordered table-sm grey-light-bg text-center'}>
                          <thead>
                            <tr>
                              <th>Package</th>
                              <th>Start</th>
                              <th>End</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.room_type_packages.map((package_data, k) => (
                              <tr key={k}>
                                <td>{package_data.room_type_package}</td>
                                <td>{AppUtil.formatDateTime(package_data.start, 'datef')}</td>
                                <td>{AppUtil.formatDateTime(package_data.end, 'datef')}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  }
                </div>
              ))}
            </div>
            }
            <p><strong>Billing & Payments</strong></p>
            {!_.isEmpty(booking_data.order) &&
              <div className={'pl-5'}>
                <div className={'row'}>
                  <div className={'col-12 col-md-8'}>
                    <table className={'table table-bordered table-sm grey-light-bg text-center'}>
                      <tbody>
                        <tr>
                          <td className={'w-75 text-left'}>
                          External Tax ({booking_data.order.order_external_tax_description})
                          </td>
                          <td className={'w-75 text-right'}>
                            {`${booking_data.order.order_external_tax || 0} ${property.currency}`}
                          </td>
                        </tr>
                        <tr>
                          <td className={'w-75 text-left'}>
                            <strong>Total</strong>
                          </td>
                          <td className={'w-75 text-right'}>
                            <strong>{`${booking_data.order.total} ${property.currency}`}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td className={'w-75 text-left'}>
                          Commission
                          </td>
                          <td className={'w-75 text-right'}>
                            {`${booking_data.order.order_external_commission || 0} ${property.currency} `}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    {!_.isEmpty(booking_data.order.payments) &&
                    <table className={'table table-bordered table-sm grey-light-bg text-center'}>
                      <tbody>
                        {booking_data.order.payments.map((payment_data, k)=>(
                          <tr key={k}>
                            <td className={'w-75 text-left'}>Payment</td>
                            <td className={'w-75 text-right'}>
                              {`${payment_data.total} ${property.currency}`}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    }
                  </div>
                </div>
              </div>
            }

          </div>
          {!_.isEmpty(bookingLog.attributes) &&
          <div className={'row'}>
            <div className={'col-4'}>
              <p>
                <span className={'text-muted'}>Booking : </span>
                <Link rel='noopener noreferrer' target={'_blank'} to={`/booking/${bookingLog.attributes.booking_hash}/`}>
                  <FontAwesomeIcon className={'mr-2'} icon={faExternalLinkAlt} size={'sm'}/>
                  {bookingLog.attributes.booking_ref_no}
                </Link>
              </p>
            </div>
            {bookingLog.attributes.order_ref_no &&
              <p>
                <span className={'text-muted'}>Order : </span>
                <Link rel='noopener noreferrer' target={'_blank'} to={`/booking/${bookingLog.attributes.order_hash}/`}>
                  <FontAwesomeIcon className={'mr-2'} icon={faExternalLinkAlt} size={'sm'}/>
                  {bookingLog.attributes.order_ref_no}
                </Link>
              </p>
            }
          </div>
          }
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          <div className={'px-3'}>
            <Alerts
              error={resultMsg.error}
              status={resultMsg.status}
              success={resultMsg.success}
            />
          </div>
          <div className={'row'}>
            <div className={'col'}>
              <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      }
    };
  }
}

/* Export Component ==================================================================== */
export default BookingChannelLogDetails;
