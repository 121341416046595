/**
 *
 * POS Details
 * point of sales information
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

// Consts and Libs

// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pos: state.pos.pos,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class POSDetails extends Component {
  static componentName = 'POSDetails';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
  };


  render = () => {

    const {loading, error} = this.state;
    const {pos} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="pos-details screen-container ">
        <Helmet>
          <title>Point of Sales</title>
        </Helmet>

        <div className={'row'}>
          <div className={'col-lg-9 col-md-9 col-sm-12'}>
            <div className={'row'}>
              <div className={'col-12 col-md-6 mb-3'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Reference</strong> : {pos.ref_no}
                  </li>
                  <li className="list-group-item"><strong>Name</strong> : {pos.name}</li>
                  <li className="list-group-item"><strong>Type</strong> : {pos.pos_type_display}</li>
                </ul>
              </div>
              <div className={'col-12 col-md-6 mb-3'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Operation Type</strong> : {pos.operation_type_display}
                  </li>
                  <li className="list-group-item">
                    <strong>Operation Status</strong> : {pos.operation_status_display}
                  </li>
                </ul>
              </div>
            </div>

            <div className={'row'}>
              <div className={'col-12 col-md-6 mb-3'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Table Available </strong> :
                    <span className={'badge ' + (pos.pos_has_table ? 'badge-success' : 'badge-danger')}>
                      {(pos.pos_has_table ? 'Yes' : 'No')}
                    </span>
                  </li>
                  <li className="list-group-item">
                    <strong>Table Required</strong> :
                    <span className={'badge ' + (pos.order_table_required ? 'badge-success' : 'badge-danger')}>
                      {(pos.order_table_required ? 'Yes' : 'No')}
                    </span>
                  </li>
                </ul>
              </div>
              <div className={'col-12 col-md-6 mb-3'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Room Available </strong> :
                    <span className={'badge ' + (pos.pos_has_room ? 'badge-success' : 'badge-danger')}>
                      {(pos.pos_has_room ? 'Yes' : 'No')}
                    </span>
                  </li>
                  <li className="list-group-item">
                    <strong>Room Required</strong> :
                    <span className={'badge ' + (pos.pos_order_booking_room_required ? 'badge-success' : 'badge-danger')}>
                      {(pos.pos_order_booking_room_required ? 'Yes' : 'No')}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-12 col-md-6 mb-3'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Service Charge </strong> :
                    <span className={'badge ' + (pos.pos_has_service_charge ? 'badge-success' : 'badge-danger')}>
                      {(pos.pos_has_service_charge ? 'Yes' : 'No')}
                    </span>
                  </li>
                  <li className="list-group-item">
                    <strong>Service Value</strong> : {pos.pos_service_charge_value} %
                  </li>
                </ul>
              </div>
              <div className={'col-12 col-md-6 mb-3'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Custom Item </strong> :
                    <span className={'badge ' + (pos.pos_has_custom_item ? 'badge-success' : 'badge-danger')}>
                      {(pos.pos_has_custom_item ? 'Yes' : 'No')}
                    </span>
                  </li>
                  <li className="list-group-item">
                    <strong>Action Prompts </strong> :
                    <span className={'badge ' + (pos.pos_prompts_required ? 'badge-success' : 'badge-danger')}>
                      {(pos.pos_prompts_required ? 'Yes' : 'No')}
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className={'row'}>
              <div className={'col-12 col-md-6 mb-3'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Auto Print Billing Order </strong> :
                    <span className={'badge ' + (pos.pos_order_force_print ? 'badge-success' : 'badge-danger')}>
                      {(pos.pos_order_force_print ? 'Yes' : 'No')}
                    </span>
                  </li>
                  <li className="list-group-item">
                    <strong>Auto Print KOT</strong> :
                    <span className={'badge ' + (pos.pos_kot_force_print ? 'badge-success' : 'badge-danger')}>
                      {(pos.pos_kot_force_print ? 'Yes' : 'No')}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-12 col-md-6 mb-3'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Discount Settings </strong> :
                    {(pos.pos_discount_before_tax ? ' Before' : ' After')} Tax
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item">
                <Link
                  to={`/settings/pos/${pos.hash}/edit`}
                  className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit
                </Link>
              </li>
            </ul>
          </div>
        </div>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(POSDetails);
