/**
 *
 * Room Type List
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

// Consts and Libs
import AppAPI from '../../../lib/api';
import Strings from '../../../constants/strings';

// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';

// Actions

import ListView from '../../../components/general/ListView';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  roomType: state.roomType.roomType
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class RoomTypeList extends Component {
  static componentName = 'RoomList';
  static propTypes = {
    refreshable: PropTypes.bool,
    roomSettings: PropTypes.bool,
    match: PropTypes.object.isRequired
  };


  fetchRoomType = (page = 1, callback) => {
    AppAPI.roomtypeitem.get('?page=' + page + ((this.props.match.params.roomTypeId ? '&room_type=' + this.props.match.params.roomTypeId : '')))
      .then((res) => {
        this.setState({
          loading: false,
          error: null
        });
        if (res.results.length > 0) {
          if (res.next) {
            callback(res.results);
          } else {
            callback(res.results, {
              allLoaded: true // the end of the list is reached
            });
          }
        } else {
          callback();
        }
      })
      .catch((err) => {
        callback(null, {
          allLoaded: true // the end of the list is reached
        });
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error
        });
      });
  };

  emptyView = () => {
    return (
      <div className="empty">
        <div className="mx-auto featured-image">
          <img className={'w-100'} alt={'Property List'} src={require('../../../images/drawings/Crab.png')}/>
        </div>
        <div className="spacer-20"/>
        {
          Strings.roomTypeItemEmptyText.map((data, i) => <p key={i}>{data}</p>)
        }
      </div>
    );
  };

  renderRow = (data, key) => {
    return (
      <Link
        to={'/settings/inventory/room-type/' + this.props.match.params.roomTypeId + '/item/' + data.id} key={key}
        className="list-group-item list-group-item-action flex-column align-items-start">
        <div className="d-flex w-100 justify-content-between">
          <h4 className="list-group-item-heading">{data.name}</h4>
          <span
            className={'badge ' + (data.active ? 'badge-success' : 'badge-secondary')}>{data.active ? 'Active' : 'De-active'}</span>
        </div>
      </Link>
    );
  };
  render = () => {

    const {loading, error} = this.state;
    const {roomType} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="room-type-list screen-container ">
        <Helmet>
          <title>Room Types Items</title>
        </Helmet>

        <PageHeader
          subHeader={true} history={this.props.history} title={'Room Types'}
          description={`Service able items in the room type ${roomType.name}`}
        />

        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12 mb-5">
            <ListView
              rowView={this.renderRow}
              onFetch={this.fetchRoomType}
              firstLoader={true}
              pagination
              emptyView={this.emptyView}
              class={'list-group'}
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link
                  to={'/settings/inventory/room-type/' + this.props.match.params.roomTypeId + '/item/new'}
                  className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Room Type Item
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomTypeList);