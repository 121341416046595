/**
 *
 * Plan
 * overview of billing Plans
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';

// Consts and Libs
import AppAPI from '../../lib/api';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import PlanView from '../../components/functional/PlansView';

// Actions
import * as BillingAction from '../../redux/billing/actions';
import {Link} from 'react-router-dom';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  user: state.user.user,
  plandata: state.billing.plandata
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setBillingOrder: BillingAction.setBillingOrder,
  setSelectPlan: BillingAction.setSelectPlan,
  unSetSelectPlan: BillingAction.unSetSelectPlan,
};

/* Component ==================================================================== */
class Plans extends Component {
  static componentName = 'Plans';

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    AppAPI.billingapi.get('plans/')
      .then((res) => {
        if (res.actions !== '') {

          if (Object.keys(this.props.plandata).length !== 0) {
            this.setState({
              loading: false,
              plans: res.results
            });
            this.initChangePlan(this.props.plandata.planId, this.props.plandata.priceType);
          } else {
            this.setState({
              loading: false,
              plans: res.results
            });
          }
        } else {
          this.setState({
            loading: false,
            error: true
          });
        }
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: error,
          resultMsg: {error}
        });
      });
  };

  initChangePlan = (planId, priceType) => {
    this.setState({
      loading: true,
      resultMsg: {}
    });

    AppAPI.billingapi.get('plans/' + planId + '/?plan_type=' + priceType)
      .then((data) => {
        let orderData = [];
        let item = [];
        let total = 0;
        let discount = 0;
        let tax = 0;
        let tax_description = null;
        let currency = null;

        if (data.message) {
          this.props.history.push('/user/account/');
        } else {
          let plan = data.plan;
          item['name'] = plan.name;
          item['plan'] = plan.id;
          item['description'] = plan.description;
          item['price'] = (priceType === 'year' ? plan.price_365 : plan.price_30);
          item['duration'] = priceType;
          currency = plan.currency;

          total += item['price'];

          tax = (total - discount) * data.tax_value;
          // add tax to total
          total += tax;
          tax_description = data.tax_description;

          orderData.push(item);

          this.setState({
            loading: false,
            orderData: orderData,
            total: total,
            tax: tax,
            currency: currency,
            tax_description: tax_description,
            trial: data.trial,
            current_charges: data.current_charges
          });
        }
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          resultMsg: {error}
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: error,
          resultMsg: {error}
        });
      });
  };

  cancelPreview = () => {
    this.props.unSetSelectPlan();
    this.setState({
      loading: false,
      orderData: []
    });
  };

  checkoutOrder = () => {
    this.setState({loading: true}, () => {
      this.setState({resultMsg: {status: 'One moment...'}});
      let orderItems = [];
      const orderData = this.state.orderData;

      Object.keys(orderData).forEach((i) => {
        const orderItem = {};
        orderItem.plan = orderData[i].plan;
        orderItem.plan_type = (orderData[i].duration === 'year' ? 1 : 0);

        orderItems.push(orderItem);
      });

      AppAPI.billingapi.post('billingorder/',
        {
          'billingorderitems_set': orderItems
        })
        .then((data) => {
          if (data.order_id) {
            this.props.history.push('/user/billing/invoice/' + data.id);
            if (data.payments) {
              window.open(data.payments.payment_link, '_blank');
            }
          } else {
            this.setState({
              loading: false,
              plan_changed: data.message
            });
            if (data.action) {
              this.props.history.push('/user/billing/');
              window.location.reload(false);
            }
          }

        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    });
  };
  render = () => {
    const {
      loading, error, plans, orderData, total, tax, tax_description, currency, resultMsg, trial, current_charges,
      plan_changed
    } = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="room-view screen-container">
        <Helmet>
          <title>Pricing Plans</title>
        </Helmet>

        <div className={'row'}>
          {((orderData.length) < 1)
            ? <div className="col-lg-10 col-md-10 col-sm-12 mx-auto">
              <PlanView
                statusMsg={resultMsg}
                planData={plans}
                onSelect={(id, priceType) => this.initChangePlan(id, priceType)}/>
            </div>
            : <div className="col-lg-6 col-md-12 col-sm-12 mx-auto">
              <div
                role="presentation" onKeyPress={this.handleKeyPress}
                onClick={() => this.cancelPreview()} className="btn btn-outline-info">
                <span className="fa fa-chevron-left"/> Back
              </div>
              <div className={'spacer-20'}/>
              <ul className="list-group mb-3">
                {
                  orderData.map((data, i) => (
                    <li
                      key={i}
                      className="list-group-item d-flex justify-content-between lh-condensed">
                      <div>
                        <h6 className="my-0">{data.name} </h6>
                        <small className="text-muted">{data.description}</small>
                      </div>
                      <span className="text-muted">
                        {currency} {data.price.toFixed(2)} <br/>
                        {data.duration && <span>per {data.duration}</span>}
                      </span>
                    </li>
                  ))
                }

                {
                  (parseFloat(tax) > 0 && tax_description) &&
                  <li className="list-group-item d-flex justify-content-between">
                    <span>Tax : {tax_description} </span>
                    <strong> {currency} {tax.toFixed(2)}</strong>
                  </li>
                }

                <li className="list-group-item d-flex justify-content-between">
                  <span>Total </span>
                  <strong> {currency} {total.toFixed(2)}</strong>
                </li>
              </ul>
              <ul className="list-group mb-3">
                {trial &&
                 <li className="list-group-item d-flex justify-content-between">
                   <span>Includes <strong> {trial} day(s)</strong> Free trial.</span>
                 </li>
                }
                <li className="list-group-item d-flex justify-content-between">
                  <span>{current_charges > 0 ? 'Today\'s charge' : 'Carry over credit'} </span>
                  <strong> {currency} {current_charges.toFixed(2)}</strong>
                </li>
                {current_charges > 0 &&
                 <li className="list-group-item d-flex justify-content-between">
                   <span>Additional Charge will be made on your subscription payment immediately.</span>
                 </li>
                }
              </ul>
              {!plan_changed ?
                <button onClick={() => this.checkoutOrder()} className='btn btn-success btn-block'>
                  {trial ? 'Start Trial' : 'Change Plan'}
                </button>
                :
                <div>
                  <ul className="list-group mb-3">
                    <li className="list-group-item d-flex justify-content-between">
                      <span>Plan Changed Initiated, we will inform you once the upgrade is complete</span>
                    </li>
                  </ul>
                  <Link to={'/user/billing/new'} className="btn btn-success mx-auto">
                    Billing Overview <span className="fa fa-arrow-right"/>
                  </Link>
                </div>
              }
            </div>
          }
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: ''
      },
      plans: [],
      orderData: [],
      plan_changed: false
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
