/**
 *
 * POS Order Manage
 */
import _ from 'lodash';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

// Consts and Libs
import APPUtil from '../../../lib/util';
import EXTUtil from '../../../lib/extUtils';

import {Constants, Filters, Strings} from '../../../constants';
// Components
import Loading from '../../../components/general/Loading';
import POSItemListView from '../components/POSItemListView';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowsAltH,
  faBroom,
  faCheckCircle,
  faCopy,
  faExclamationTriangle,
  faLink,
  faMoneyBillWave,
  faPauseCircle,
  faPen,
  faPlayCircle,
  faPlus,
  faSave,
  faTimesCircle,
  faUnlink,
} from '@fortawesome/free-solid-svg-icons';

import {Alerts} from '../../../components/ui';
import POSOrderEdit from '../models/POSOrderEdit';
import POSItemDetails from '../models/POSItemDetails';
import POSTableSelector from '../models/POSTableSelector';
import FilterButton from '../../../components/dashboard/FilterButton';
import {confirmAlert} from '../../../components/general/ConfirmAlert';
import POSOrderItemDetailsView from '../models/POSOrderItemDetailsView';
import POSRoomSelectListView from '../models/POSRoomSelectListView';
import POSOrderEscalatedPermissionEdit from '../models/POSOrderEscalatedPermissionEdit';
import POSOrderItemAdd from '../models/POSOrderItemAdd';
import ModelPDFView from '../../../components/general/ModelPDFView';
import ProfileQuickSearch from '../../guest/ProfileQuickSearch';
import GuestDetailsOverView from '../../guest/GuestDetailsOverView';
import POSOrderItemListView from '../components/POSOrderItemListView';
import POSOrderSplitBill from '../models/POSOrderSplitBill';
import POSKOTList from '../components/POSKOTList';
import POSBillingOrderList from '../components/POSBillingOrderList';
import SelectorButton from '../../../components/dashboard/SelectorButton';

/* Component ==================================================================== */
class POSOrderManage extends Component {
  static componentName = 'POSOrderManage';

  static propTypes = {
    posOrder: PropTypes.object.isRequired,
    resultMsg: PropTypes.object.isRequired,
    posDetails: PropTypes.object.isRequired,

    cacheReset: PropTypes.func,
    fetchInitData: PropTypes.func,
    updatePosOrder: PropTypes.func,
    updatePosOrderAdditional: PropTypes.func,
    escalatedPermissionUpdated: PropTypes.func,
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    if (this.props.posDetails.pos_has_kot) {
      this.setState({POSOrderTabSelection: this.props.posOrder.lock ? 'order' : 'kot'});
    }
  }

  handleInputChange = (value) => {
    this.setState({value: value, searching: true});
    this.onChangeDebounced(value);
  };

  onChangeDebounced = (value) => {
    this.setState({searchText: value, searching: false});
  };

  addPOSOrderItem = (itemData, remove = false) => {
    const {posOrderStageData} = this.state;

    if (itemData && itemData.item) {
      if (remove) {
        delete posOrderStageData[itemData.item];
      } else {
        posOrderStageData[itemData.item] = itemData;
      }
      this.setState({posOrderStageData});
    }
  }

  showProfileDetails = (guestId, add_guest = false) => {
    this.setState({profileDetailsData: guestId, showProfileDetails: true, addProfile: add_guest});
  };

  profileActions = (profile) => {
    if (profile) {
      if (profile.profile_type === Constants.PROFILE_TYPE.GUEST) {
        this.props.updatePosOrder({guest_data: this.state.addProfile ? profile.id : null});
      } else {
        this.props.updatePosOrder({corporate_data: this.state.addProfile ? profile.id : null});
      }
      this.setState({
        showProfileDetails: false,
        showProfileSearch: false,
      });
    }
  };


  render = () => {

    const {
      selectCategory, posOrderStageData, selectFoodType, selectFoodContentType, selectDrinkContentType, searching,
      POSOrderTabSelection
    } = this.state;

    const {posDetails, posOrder, resultMsg, loading} = this.props;

    if (loading) return <Loading/>;

    const categoryFilter = [], viewButtonList = [];

    if (!_.isEmpty(posDetails.category)) {
      posDetails.category.forEach((data) => categoryFilter.push({
        title: data.name,
        key: data.id,
      }));
    }

    if (!_.isEmpty(posOrder.orders)) {
      viewButtonList.push({
        title: 'Billing Order',
        key: 'order',
      });
    }

    if (!_.isEmpty(posOrder.kot_items)) {
      viewButtonList.push({
        title: 'Kitchen Order',
        key: 'kot',
      });
    }

    let items = EXTUtil.filterPOSItems(posDetails.items, this.state);

    let posOrderStageDataTable = [], total = 0;

    Object.keys(posOrderStageData).forEach((key) => {

      let itemTotal = parseFloat(posOrderStageData[key].price_effective) * parseInt(posOrderStageData[key].quantity) * parseInt(posOrderStageData[key].sub_quantity);
      total = parseFloat(total) + parseFloat(itemTotal);

      posOrderStageDataTable.push(
        <tr>
          <td className={'w-10'}>
            <p className={'mb-0 small text-right'}>
              {posOrderStageData[key].quantity} <strong>x</strong>
              <br/> {posOrderStageData[key].quantity_type_display}
            </p>
          </td>
          <td className={'w-50'}>
            <strong>{posOrderStageData[key].name}</strong>
            {posOrderStageData[key].additional_description &&
            <p className={'mb-0 small'}>Note : {posOrderStageData[key].additional_description}</p>
            }
            <p className={'mb-0 small'}>
              {(parseInt(posOrderStageData[key].sub_quantity) > 1 || parseInt(posOrderStageData[key].sub_quantity_type) !== 1) &&
              <React.Fragment>
                {posOrderStageData[key].sub_quantity} {posOrderStageData[key].sub_quantity_type_display}
              </React.Fragment>
              }
            </p>
          </td>
          <td className="w-35 text-right">
            <strong>{`${posOrderStageData[key].currency} ${itemTotal}`}</strong>
            <button
              className={'btn btn-sm btn-outline-primary ml-2'}
              onClick={() => this.setState({
                showPOSItemDetails: true,
                selectPOSItem: posOrderStageData[key],
                selectPOSItemData: posOrderStageData[key],
              })}>
              <FontAwesomeIcon size={'sm'} icon={faPen}/>
            </button>
          </td>
        </tr>,
      );
    });

    if (total > 0) {
      posOrderStageDataTable.push(
        <tr>
          <td colSpan={2}><strong>Total</strong></td>
          <td className="w-35 text-right">
            <strong>{`${posOrder.currency} ${total}`}</strong>
          </td>
        </tr>,
      );
    }

    const posAdditionalElements = <React.Fragment>
      <hr/>
      {!_.isEmpty(viewButtonList) &&
      <div className={'mb-4'}>
        <SelectorButton
          title={'View'}
          buttonList={viewButtonList}
          selectButtonKey={POSOrderTabSelection}
          action={(key) => this.setState({POSOrderTabSelection: key})}
        />
      </div>
      }
      {POSOrderTabSelection === 'order' &&
        <POSBillingOrderList
          posDetails={posDetails}
          orders={posOrder.orders}
          property={this.props.property}
          fetchPOSOrderData={() => this.props.fetchInitData()}
        />
      }
      {POSOrderTabSelection === 'kot' && <POSKOTList kots={posOrder.kot_items} posDetails={posDetails}/>}
    </React.Fragment>;

    return (
      <div className="booking-details screen-container ">

        <Helmet>
          <title>POS Order Edit | {posOrder.ref_no}</title>
        </Helmet>

        <div className="row">
          <div className="col-lg-7 col-md-12 col-sm-12">
            {[Constants.POS_TYPE.RESTRO_BAR, Constants.POS_TYPE.RESTAURANT, Constants.POS_TYPE.BAR].includes(parseInt(posDetails.pos_type)) &&
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <FilterButton
                  title={'Item Type'}
                  data={Filters.foodType}
                  selectKey={selectFoodType}
                  action={(data) => this.setState({selectFoodType: data.key})}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  disabled={selectFoodType !== Constants.POS_FOOD_TYPE.FOOD}
                  title={'Food Content'}
                  data={Filters.foodContentType}
                  selectKey={selectFoodContentType}
                  action={(data) => this.setState({selectFoodContentType: data.key})}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  disabled={selectFoodType !== Constants.POS_FOOD_TYPE.DRINK}
                  title={'Drink Content'}
                  data={Filters.drinkContentType}
                  selectKey={selectDrinkContentType}
                  action={(data) => this.setState({selectDrinkContentType: data.key})}
                  size={'sm'}
                />
              </li>
            </ul>
            }

            <ul className="list-inline list-item-mb-1 border text-center px-1 py-2 rounded pos-category-list overflow-auto">
              <li className="list-inline-item w-15">
                <button
                  onClick={() => this.setState({selectCategory: null})}
                  className={`btn btn-block ${selectCategory === null ? 'btn-primary' : 'btn-outline-primary'}`}
                >
                  All
                </button>
              </li>

              {!_.isEmpty(posDetails.category) &&
              posDetails.category.map((data, i) => (
                <li className="list-inline-item w-15" key={i}>
                  <button
                    onClick={() => this.setState({selectCategory: data.id})}
                    className={`btn btn-block  text-wrap ${selectCategory === data.id ? 'btn-primary' : 'btn-outline-secondary'}`}
                  >
                    <small>{APPUtil.limitChars(data.name, 7)}</small>
                  </button>
                </li>
              ))
              }
            </ul>

            <div className="form-row align-items-center mb-4">
              <div className={searching ? 'col-sm-11' : 'col-sm-12'}>
                <input
                  type="search"
                  className="form-control"
                  placeholder={'Search Item'}
                  name="q"
                  autoComplete={'off'}
                  onChange={(data) => this.handleInputChange(data.target.value)}
                />
              </div>
              <div className="col-auto">
                {searching && <i className="fa fa-spinner"/>}
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className={'pos-items-list border px-2 h-100'}>
                  {_.isEmpty(items) ?
                    <div className={'h-100 d-flex'}>
                      <p className={'w-100 text-muted text-center align-self-center'}>It's Empty.</p>
                    </div>
                    :
                    <div className="row text-center">
                      {
                        items.map((data, i) => (
                          <div key={i} className={'col-sm-6 col-lg-4 col-6 p-3'}>
                            <POSItemListView
                              disabled={posOrder.lock || posOrder.pos_order_status === Constants.POS_ORDER_STATUS.HOLD}
                              bgClass={posOrderStageData.hasOwnProperty(data.id) ? 'yellow-bg' : ''}
                              data={data}
                              posData={posDetails}
                              action={() =>
                                posOrderStageData.hasOwnProperty(data.id) ?
                                  this.setState({
                                    showPOSItemDetails: true,
                                    selectPOSItem: posOrderStageData[data.id],
                                    selectPOSItemData: posOrderStageData[data.id],
                                  })
                                  :
                                  this.setState({showPOSItemDetails: true, selectPOSItem: data})

                              }
                            />
                          </div>
                        ))
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
            {posDetails.pos_has_custom_item &&
              <div className={'mb-4'}>
                <button
                  disabled={posOrder.lock || posOrder.pos_order_status === Constants.POS_ORDER_STATUS.HOLD}
                  className={'btn btn-outline-primary'}
                  onClick={() => this.setState({showPOSOrderItemAdd: true})}
                >
                  <FontAwesomeIcon icon={faPlus} className={'mr-2'}/> Add Custom Item
                </button>
              </div>
            }

            <div className={'d-none d-lg-block'}>
              {posAdditionalElements}
            </div>

          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 pt-5">
            <div className={'border rounded p-3'}>
              <div className={'row'}>
                <div className={'col-lg-12 col-md-12'}>
                  <table className={'table table-borderless table-sm mb-3'}>
                    <tbody>
                      {!_.isEmpty(posOrder.attributes) &&
                      <React.Fragment>
                        <tr>
                          <td className={'w-25 text-muted'}>Served By</td>
                          <td>{posOrder.attributes.serving_user || 'N.A'}</td>
                        </tr>
                        {posOrder.attributes.room &&
                        <tr>
                          <td className={'w-50 text-muted'}>Room</td>
                          <td>{posOrder.attributes.room || 'N.A'}</td>
                        </tr>
                        }
                      </React.Fragment>
                      }
                      {!_.isEmpty(posOrder.corporate) &&
                      <React.Fragment>
                        <tr>
                          <td className={'w-50 text-muted'}>Corporate</td>
                          <td>
                            <button
                              onClick={() => this.showProfileDetails(posOrder.corporate.id)}
                              className={'btn btn-link m-0 p-0 text-left'}
                            >
                              {posOrder.corporate.entity_name || 'Corporate'}
                            </button>
                          </td>
                        </tr>
                      </React.Fragment>
                      }
                      <tr>
                        <td className={'w-25 text-muted'}>Cover/Guest</td>
                        <td>{posOrder.occupancy}</td>
                      </tr>
                      <tr>
                        <td className={'w-25 text-muted'}>Table</td>
                        <td>
                          {
                            !_.isEmpty(posOrder.pos_tables_attributes) ?
                              <React.Fragment>
                                {posOrder.pos_tables_attributes.map((data, i) =>
                                  <React.Fragment>
                                    {(i > 0) && ', '}
                                    {`${data.name}`}
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                              : '....'
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <hr/>
              <table className="table border">
                <tbody>
                  {!_.isEmpty(posOrder.order_items) ?
                    <React.Fragment>
                      {posOrder.order_items.map((data, i) => (
                        <POSOrderItemListView
                          key={i}
                          rowClassName={`${!data.status && 'table-dark'} ${!data.lock && 'table-warning'}`}
                          lock={posOrder.lock}
                          currency={posOrder.currency}
                          data={data}
                          disabled={!data.status}
                          actionRight={() => this.setState({
                            showPOSOrderItemDetailsView: true,
                            selectPOSOrderItemDetailsView: data,
                          })}
                        />
                      ))}

                      <tr>
                        <td colSpan={'2'} className="text-right">Sub Total</td>
                        <td className="text-right">{posOrder.currency} {posOrder.sub_total}</td>
                      </tr>

                      <tr>
                        <td colSpan={'2'} className={'text-right'}>Tax</td>
                        <td className={'text-right'}>
                          <strong>{`${posOrder.currency} ${posOrder.tax_total}`}</strong>
                        </td>
                      </tr>


                      <tr>
                        <td colSpan={'2'} className={'text-right'}>
                          {!posOrder.lock &&
                          <button
                            type="button" className="btn btn-link mr-1 btn-sm" data-toggle="modal"
                            onClick={() => this.setState({
                              showPOSOrderEscalatedPermissionEdit: true,
                              showPOSOrderEscalatedPermissionEditType: 'discount',
                            })}
                          >(Edit)</button>
                          }
                          Discount
                          {parseFloat(posOrder.order_discount) !== 0 &&
                          <p
                            className={'mb-0 small text-muted'}>({posOrder.order_discount_type_display}) {posOrder.order_discount_description}</p>
                          }
                        </td>
                        <td className="text-right">{posOrder.currency} {posOrder.order_discount}</td>
                      </tr>

                      {parseFloat(posOrder.order_discount_post_payment) > 0 &&
                      <tr>
                        <td colSpan={'2'} className={'text-right'}>
                          <strong>Discount ( Post Payment )</strong>
                          {posOrder.order_discount_post_payment_description &&
                          <p className={'mb-0 small text-muted'}>
                            ({posOrder.order_discount_post_payment_type_display}) {posOrder.order_discount_post_payment_description}
                          </p>
                          }
                        </td>
                        <td className="text-right">{`${posOrder.currency} ${posOrder.order_discount_post_payment}`}</td>
                      </tr>
                      }

                      {(posDetails.pos_has_service_charge || parseFloat(posOrder.order_service_charge_value) > 0) &&
                      <tr>
                        <td colSpan={'2'} className={'text-right'}>
                          {!posOrder.lock &&
                          <button
                            type="button" className="btn btn-link mr-1 btn-sm" data-toggle="modal"
                            onClick={() => this.setState({
                              showPOSOrderEscalatedPermissionEdit: true,
                              showPOSOrderEscalatedPermissionEditType: 'service-charge',
                            })}
                          >(Edit)</button>
                          }
                          Service Charge
                          {parseFloat(posOrder.order_service_charge_value) > 0 &&
                          ` (${posOrder.order_service_charge_value} %)`
                          }
                        </td>
                        <td className="text-right">{posOrder.currency} {posOrder.service_charge}</td>
                      </tr>
                      }

                      {parseFloat(posOrder.total_rounding) !== 0 &&
                      <tr>
                        <td colSpan={'2'} className="text-right">
                          Rounding
                        </td>
                        <td className="text-right">{posOrder.currency} {posOrder.total_rounding}</td>
                      </tr>
                      }
                      <tr>
                        <td colSpan={'2'} className={'text-right'}>
                          <strong>Total</strong>
                        </td>
                        <td className={'text-right'}>
                          <strong>{`${posOrder.currency} ${posOrder.total}`}</strong>
                        </td>
                      </tr>
                    </React.Fragment>
                    :
                    <tr>
                      <td colSpan={3}>
                        <div className="text-center py-5">
                          <FontAwesomeIcon icon={faExclamationTriangle} size={'2x'} className={'mt-3 red-cl'}/>
                          <p className={'text-secondary small'}>No items in this order</p>
                        </div>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
              {!_.isEmpty(posOrderStageDataTable) &&
              <div>
                <hr/>
                <table className={'table border table-warning'}>
                  <tbody>
                    {posOrderStageDataTable}
                  </tbody>
                </table>
              </div>
              }

              <Alerts
                status={resultMsg.status}
                success={resultMsg.success}
                error={resultMsg.error}
              />

              {!posOrder.lock &&
              <React.Fragment>
                <div className={'spacer-20'}/>
                {!_.isEmpty(posOrderStageDataTable) ?
                  <div className={'row'}>
                    <div className={'col-4'}>
                      <button
                        className={'btn btn-block btn-secondary'}
                        onClick={() => !posDetails.pos_prompts_required
                          ? this.setState({posOrderStageData: {}})
                          : confirmAlert({
                            title: 'Clear Items',
                            message: Strings.posClearStagedItems,
                            buttons: [
                              {
                                className: 'btn-warning',
                                label: 'Clear Items',
                                onClick: () => this.setState({posOrderStageData: {}}),
                              },
                              {
                                className: 'btn-secondary',
                                label: 'Exit',
                              },
                            ],
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faBroom} className={'mr-2'}/> Clear
                      </button>
                    </div>
                    <div className={'col-8'}>
                      <button
                        className={'btn btn-block btn-warning'}
                        disabled={(resultMsg && resultMsg.status)}
                        onClick={() => this.props.updatePosOrder({
                          order_items: Object.values(posOrderStageData),
                        }, () => this.setState({posOrderStageData: {}}))}
                      >
                        <FontAwesomeIcon icon={faSave} className={'mr-2'}/>
                        Save Order
                      </button>
                    </div>
                  </div>
                  :
                  <div className={'row'}>
                    <div className={'col-4'}>
                      {posOrder.locked_items ?
                        <button
                          disabled={!posOrder.locked_items}
                          className={`btn btn-block ${posOrder.pos_order_status === Constants.POS_ORDER_STATUS.HOLD ? 'btn-warning' : 'btn-primary'}`}
                          onClick={() => !posDetails.pos_prompts_required
                            ? this.props.updatePosOrderAdditional({pos_order_status: posOrder.pos_order_status === Constants.POS_ORDER_STATUS.HOLD ? Constants.POS_ORDER_STATUS.OPEN : Constants.POS_ORDER_STATUS.HOLD}, null, true)
                            : confirmAlert({
                              title: posOrder.pos_order_status === Constants.POS_ORDER_STATUS.HOLD ? 'Open Order' : 'Hold Order',
                              message: posOrder.pos_order_status === Constants.POS_ORDER_STATUS.HOLD ? Strings.posOpenOrder : Strings.posHoldOrder,
                              buttons: [
                                {
                                  className: posOrder.pos_order_status === Constants.POS_ORDER_STATUS.HOLD ? 'btn-warning' : 'btn-primary',
                                  label: posOrder.pos_order_status === Constants.POS_ORDER_STATUS.HOLD ? 'Open Order' : 'Hold Order',
                                  onClick: () => this.props.updatePosOrderAdditional({pos_order_status: posOrder.pos_order_status === Constants.POS_ORDER_STATUS.HOLD ? Constants.POS_ORDER_STATUS.OPEN : Constants.POS_ORDER_STATUS.HOLD}, null, true),
                                },
                                {
                                  className: 'btn-secondary',
                                  label: 'Close',
                                },
                              ],
                            })
                          }
                        >
                          <FontAwesomeIcon
                            icon={posOrder.pos_order_status === Constants.POS_ORDER_STATUS.HOLD ? faPlayCircle : faPauseCircle}
                            className={'mr-1'}/>
                          {posOrder.pos_order_status === Constants.POS_ORDER_STATUS.HOLD ? 'Open Order' : 'Hold'}
                        </button>
                        :
                        <button
                          disabled={posOrder.locked_items}
                          className={'btn btn-block btn-secondary'}
                          onClick={() => !posDetails.pos_prompts_required
                            ? this.props.updatePosOrderAdditional({pos_order_status: Constants.POS_ORDER_STATUS.CANCELLED}, null, true)
                            : confirmAlert({
                              title: 'Cancel Order',
                              message: Strings.posCloseOrder,
                              buttons: [
                                {
                                  className: 'btn-danger',
                                  label: 'Cancel Order',
                                  onClick: () => this.props.updatePosOrderAdditional({pos_order_status: Constants.POS_ORDER_STATUS.CANCELLED}, null, true),
                                },
                                {
                                  className: 'btn-secondary',
                                  label: 'Close',
                                },
                              ],
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faTimesCircle} className={'mr-2'}/>
                          Cancel
                        </button>
                      }
                    </div>
                    <div className={'col-8'}>
                      {posOrder.unlocked_items ?
                        <button
                          className={'btn btn-block btn-warning'}
                          disabled={(resultMsg && resultMsg.status)}
                          onClick={() => !posDetails.pos_prompts_required
                            ? this.props.updatePosOrderAdditional({lock_order_items: true})
                            : confirmAlert({
                              title: 'Confirm Selected Items',
                              message: posDetails.pos_has_kot ? Strings.posGenerateKOT : Strings.posConfirmItems,
                              buttons: [
                                {
                                  className: 'btn-warning',
                                  label: (posDetails.pos_has_kot ? 'Generate KOT' : 'Confirm Selected Items'),
                                  onClick: () => this.props.updatePosOrderAdditional({lock_order_items: true}),
                                },
                                {
                                  className: 'btn-secondary',
                                  label: 'Exit',
                                },
                              ],
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faCheckCircle} className={'mr-2'}/>
                          {posDetails.pos_has_kot ? 'Generate KOT' : 'Confirm Selected Items'}
                        </button>
                        :
                        <button
                          className={'btn btn-block btn-success'}
                          disabled={!posOrder.locked_items || (resultMsg && resultMsg.status)}
                          onClick={() => !posDetails.pos_prompts_required
                            ? this.props.updatePosOrderAdditional({lock_order: true}, null, true, 'details')
                            : confirmAlert({
                              title: 'Confirm Order',
                              message: Strings.posConfirmOrder,
                              buttons: [
                                {
                                  className: 'btn-success',
                                  label: 'Confirm',
                                  onClick: () => this.props.updatePosOrderAdditional({lock_order: true}, null, true, 'details'),
                                },
                                {
                                  className: 'btn-secondary',
                                  label: 'Exit',
                                },
                              ],
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faCheckCircle} className={'mr-2'}/>
                          Confirm / Close Tab
                        </button>
                      }
                    </div>
                  </div>
                }
                <div className={'spacer-20'}/>
                <hr/>
                <div className={'row'}>
                  <div className={'col-4'}>
                    <button
                      className="btn btn-block btn-outline-primary"
                      disabled={posOrder.lock}
                      onClick={() => this.setState({
                        showPOSOrderEscalatedPermissionEdit: true,
                        showPOSOrderEscalatedPermissionEditType: 'complimentary',
                      })}
                    >
                      <FontAwesomeIcon icon={faMoneyBillWave} size={'sm'} className={'mr-2'}/> Comp
                    </button>
                  </div>
                  <div className={'col-3'}>
                    <button
                      className="btn btn-block btn-outline-primary"
                      disabled={posOrder.lock}
                      onClick={() => this.setState({
                        showPOSOrderEscalatedPermissionEdit: true,
                        showPOSOrderEscalatedPermissionEditType: 'nocharge',
                      })}
                    >
                      <FontAwesomeIcon icon={faMoneyBillWave} size={'sm'} className={'mr-2'}/> NC
                    </button>
                  </div>
                  <div className={'col-5'}>
                    <button
                      disabled={(posOrder.lock)}
                      type="button" className={`btn btn-block btn-outline-${posOrder.room_id ? 'danger' : 'primary'}`}
                      data-toggle="modal"
                      data-target="#orderDiscountModel"
                      onClick={() => posOrder.room_id ?
                        confirmAlert({
                          title: 'Remove Room',
                          message: Strings.posOrderUnlinkRoom,
                          buttons: [
                            {
                              className: 'btn-warning',
                              label: 'Remove Room',
                              onClick: () => this.props.updatePosOrder({room: null}),
                            },
                            {
                              className: 'btn-secondary',
                              label: 'Close',
                            },
                          ],
                        })
                        : this.setState({showRoomQuickSelectListView: true})}
                    >
                      <FontAwesomeIcon icon={posOrder.room_id ? faUnlink : faLink} size={'sm'} className={'mr-1'}/>
                      {posOrder.room_id ? 'Unlink ' : 'Link '} Room
                    </button>
                  </div>
                </div>
                <div className={'spacer-20'}/>
                <div className={'row'}>
                  <div className={'col-6'}>
                    <button
                      className="btn btn-block btn-outline-primary"
                      disabled={posOrder.lock}
                      onClick={() => this.setState({
                        showPOSTableSelector: true,
                        showPOSTableSelectorType: 'add',
                      })}
                    >
                      <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Attach Table
                    </button>
                  </div>
                  <div className={'col-6'}>
                    <button
                      className="btn btn-block btn-outline-primary"
                      disabled={posOrder.lock}
                      onClick={() => this.setState({
                        showPOSTableSelector: true,
                        showPOSTableSelectorType: 'shift',
                      })}
                    >
                      <FontAwesomeIcon icon={faArrowsAltH} size={'sm'} className={'mr-2'}/> Shift Table
                    </button>
                  </div>
                </div>
                <div className={'spacer-20'}/>
                <div className={'row'}>
                  <div className={'col-6 mx-auto'}>
                    <button
                      className="btn btn-block btn-outline-primary"
                      disabled={posOrder.lock}
                      onClick={() => this.setState({
                        showProfileSearch: true,
                        profileSource: Constants.PROFILE_TYPE.CORPORATE,
                      })}
                    >
                      <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Corporate
                    </button>
                  </div>
                  <div className={'col-6 mx-auto'}>
                    <button
                      className="btn btn-block btn-outline-primary"
                      disabled={posOrder.lock || posOrder.unlocked_items || !_.isEmpty(posOrderStageDataTable)}
                      onClick={() => this.setState({showPOSOrderSplitBill: true})}
                    >
                      <FontAwesomeIcon icon={faCopy} size={'sm'} className={'mr-2'}/> Split Bill
                    </button>
                  </div>
                </div>
              </React.Fragment>
              }
            </div>
          </div>

        </div>

        <div className={'row d-lg-none'}>
          <div className={'col-12'}>
            {posAdditionalElements}
          </div>
        </div>

        <POSItemDetails
          posDetails={posDetails}
          item={this.state.selectPOSItem}
          show={this.state.showPOSItemDetails}
          posOrderAction={this.addPOSOrderItem}
          itemData={this.state.selectPOSItemData}
          onHide={() => this.setState({showPOSItemDetails: false, selectPOSItem: null, selectPOSItemData: null})}
        />
        <POSOrderItemDetailsView
          posOrder={posOrder}
          posDetails={posDetails}
          updateResponse={this.props.setPOSOrder}
          show={this.state.showPOSOrderItemDetailsView}
          POSOrderItem={this.state.selectPOSOrderItemDetailsView}
          onHide={() => this.setState({showPOSOrderItemDetailsView: false, selectPOSOrderItemDetailsView: null})}
        />
        <POSRoomSelectListView
          show={this.state.showRoomQuickSelectListView}
          onHide={() => this.setState({showRoomQuickSelectListView: false})}
          availabilityStatus={Constants.ROOM_STATUS.OCCUPIED}
          posDetails={posDetails}
          resultMsg={resultMsg}
          action={(data) => {
            this.props.updatePosOrder(data, () => this.setState({showRoomQuickSelectListView: false}));
          }}
        />
        <POSOrderEscalatedPermissionEdit
          updateResponse={this.props.setPOSOrder}
          onHide={() => this.setState({showPOSOrderEscalatedPermissionEdit: false})}
          show={this.state.showPOSOrderEscalatedPermissionEdit}
          posOrder={posOrder}
          posDetails={posDetails}
          editType={this.state.showPOSOrderEscalatedPermissionEditType}
        />
        <POSOrderEdit
          updateResponse={this.props.setPOSOrder}
          onHide={() => this.setState({showPOSOrderEdit: false})}
          show={this.state.showPOSOrderEdit}
          posOrder={posOrder}
          posDetails={posDetails}
          editType={this.state.showPOSOrderEditType}
        />
        <POSTableSelector
          resultMsg={resultMsg}
          posDetails={posDetails}
          show={this.state.showPOSTableSelector}
          changeType={this.state.showPOSTableSelectorType}
          onHide={() => this.setState({showPOSTableSelector: false})}
          updatePosOrder={(data) => this.props.updatePosOrderAdditional(data, () => this.setState({showPOSTableSelector: false}), true)}
        />
        <POSOrderItemAdd
          order={posOrder}
          posDetails={posDetails}
          show={this.state.showPOSOrderItemAdd}
          updateResponse={this.props.setPOSOrder}
          onHide={() => this.setState({showPOSOrderItemAdd: false})}
        />
        {/*  Profile Search */}
        <ProfileQuickSearch
          onHide={() => this.setState({showProfileSearch: false})}
          show={this.state.showProfileSearch}
          showProfileDetails={this.showProfileDetails}
          isCorporate={true}
          corporatePOS={true}
        />

        {/*  Profile Details */}
        <GuestDetailsOverView
          onHide={() => this.setState({showProfileDetails: false})}
          guestId={this.state.profileDetailsData}
          show={this.state.showProfileDetails}
          addGuestFunction={this.state.addProfile}
          action={this.profileActions}
          resultMsg={resultMsg}
        />

        {/* POS Order Split Bill  */}
        <POSOrderSplitBill
          posOrder={posOrder}
          resultMsg={resultMsg}
          posDetails={posDetails}
          show={this.state.showPOSOrderSplitBill}
          updateResponse={this.props.updatePosOrderAdditional}
          onHide={() => this.setState({showPOSOrderSplitBill: false})}
        />

        <ModelPDFView
          title={'Print Order'}
          onHide={() => this.setState({showModelPDFView: false, urlModelPDFView: null})}
          show={this.state.showModelPDFView}
          pdfUrl={this.state.urlModelPDFView}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);
    this.onChangeDebounced = _.debounce(this.onChangeDebounced, 200, {});

    this.state = {
      error: false,
      loading: false,
      searching: false,
      selectPOSItem: {},
      selectCategory: null,
      urlModelPDFView: null,
      showModelPDFView: false,
      selectPOSItemData: null,
      showProfileSearch: false,
      showPOSItemDetails: false,
      showProfileDetails: false,
      showPOSOrderItemAdd: false,
      showPOSOrderItemDetailsView: false,
      selectPOSOrderItemDetailsView: null,
      showPOSOrderEscalatedPermissionEdit: false,
      showPOSOrderEscalatedPermissionEditType: 'discount',
      POSOrderTabSelection: 'order',
      discountDetails: false,
      showPOSTableSelector: false,
      showPOSTableSelectorType: 'shift',
      showRoomQuickSelectListView: false,
      showPOSOrderEdit: false,
      showPOSOrderEditType: 'edit',
      posOrderStageData: {},
    };
  }
}


export default POSOrderManage;
