/**
 * Manage Escalated Permission functions
 *  manages order updates ( Complimentary )
 */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import * as Yup from 'yup';
// Consts and Libs
import AppAPI from '../../lib/api';
import {ErrorMessages} from '../../constants';
// Components
import {Alerts} from '../../components/ui';
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
// Forms
import {Form, Formik} from 'formik';
import OrderComplimentaryEdit from './components/OrderComplimentaryEdit';


/* Component ==================================================================== */

class OrderEscalatedPermissionEdit extends React.Component {
  static componentName = 'OrderEscalatedPermissionEdit';

  static propTypes = {
    match: PropTypes.object,
    order: PropTypes.object,
    property: PropTypes.object,
    updateResponse: PropTypes.func,
    editType: PropTypes.string,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        loading: false,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  updateOrder = (credentials = null) => {
    const {editType} = this.props;

    let payload = {};
    if (credentials) {
      switch (editType) {
      case 'complimentary':
      default:
        payload.complimentary_type_order = credentials.complimentary_type;
        payload.complimentary_description = credentials.complimentary_description;
        payload.complimentary_order = true;
      }
    }

    if (!_.isEmpty(payload)) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.order
          .post(`${this.props.order.hash}/escalated-permission/`, payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                this.props.updateResponse(res);
                this.props.onHide();
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };


  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {show, editType, order} = this.props;
    if (loading) return <Loading heightMatch={true}/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    if (!show) {
      return <React.Fragment/>;
    }

    let formValidation, formInputData;


    switch (editType) {
    case 'someCase':
      formValidation = Yup.object().shape({
        discount: Yup.number(),
      });
      formInputData = () => (
        <React.Fragment/>
      );
      break;
    default:
      formValidation = Yup.object().shape({
        complimentary_type: Yup.number().required(),
        complimentary_description: Yup.string().max(200, 'Too Long!').nullable(),
      });
      formInputData = (formikProps) => <OrderComplimentaryEdit order={order} formikProps={formikProps}/>;
    }

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Order ( Manager Permission )
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            complimentary_type: '0',
            complimentary_description: '',
          }}
          validationSchema={formValidation}
          onSubmit={values => this.updateOrder(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <Form className={'form-group'}>
                      <div className="mt-2">
                        {formInputData(formikProps)}
                      </div>
                    </Form>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <button
                      type="submit" onClick={formikProps.handleSubmit} className={'btn btn-success btn-lg btn-block'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default OrderEscalatedPermissionEdit;
