/**
 *
 * Property Notification Template List
 */
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../../lib/api';
import Strings from '../../../constants/strings';

// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen, faLongArrowAltRight, faTimes } from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class NotificationContentList extends Component {
  static componentName = 'NotificationContentList';

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    AppAPI.property.get(`${this.props.property.id}/notification/email-content/`)
      .then((res) => {
        this.setState({emailTemplates: res.email_templates, loading: false});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: true,
          resultMsg: {error},
        });
      });
  }

  renderRow = (data, key) => {
    return (
      <tr key={key}>
        <td>
          <p className={'mb-0'}><strong>{data.name}</strong></p>
          <p className={'text-muted mb-0 small'}>{data.description}</p>
        </td>
        <td>
          <Link
            to={`/settings/property/notification-settings/content/${data.key}`}
            className={`btn btn-sm ${data.config ? 'btn-outline-primary' : 'btn-outline-secondary disabled'}`}
          >
            {data.config ? 'Edit' : 'Not Allowed'}
            <FontAwesomeIcon className={'ml-2'} icon={data.config ? faLongArrowAltRight : faTimes} size={'sm'}/>
          </Link>
        </td>
      </tr>
    );
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={5}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faEnvelopeOpen}/> No Content </h4>
            <p>{Strings.propertyNotificationContentListEmptyText}</p>
          </div>
        </td>
      </tr>
    );
  };

  render = () => {
    const {loading, error, emailTemplates} = this.state;

    if (!this.props.property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="channel-manager-settings screen-container">
        <Helmet>
          <title>Notification Contents</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={'Notifications Content'}
          description={'Customise the contents of notifications sent from the system'}
        />

        <div className="row">
          <div className="col-12 col-sm-12">
            <table className={'table border format-table'}>
              <thead>
                <tr>
                  <th className={'data-table-cell-xl'}>Notification Template</th>
                  <th className={'data-table-cell-xm'}/>
                </tr>
              </thead>
              <tbody>
                {_.isEmpty(emailTemplates) ?
                  this.emptyView()
                  :
                  <React.Fragment>
                    {emailTemplates.map((data, key) => this.renderRow(data, key))}
                  </React.Fragment>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      emailTemplates: [],
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContentList);
