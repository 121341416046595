import React from 'react';
import {NavLink, Redirect, Route, Switch} from 'react-router-dom';


import NotFound from '../NotFound';
import GuestList from '../guest/GuestList';
import PageHeader from '../../components/dashboard/PageHeader';

const menu = [
  {
    title: 'Guest',
    link: '/customer/guest',
    isExact: false,
  },
  {
    title: 'Agent',
    link: '/customer/agent',
    isExact: false,
  },
  {
    title: 'Corporate',
    link: '/customer/corporate',
    isExact: false,
  },
];
const menuItems = [];

menu.map((item) => {
  const {title, link, isExact} = item;
  return menuItems.push(
    <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
      <NavLink
        to={{
          pathname: link,
          state: {fromDashboard: true},
        }}
        exact={isExact}
        className="nav-link rounded-0"
        activeClassName="selected">
        {title}
      </NavLink>
    </li>,
  );
});


const ProfileRoute = () => (
  <div>
    <div>
      <PageHeader
        title={'Customer Accounts'}
        border={false}
      />

      <div className={'row'}>
        <div className={'col col-lg-12 col-md-12 col-sm-12'}>
          <ul className="nav nav-tabs mb-3">
            {menuItems}
          </ul>
        </div>
        <div className={'col-lg-12 col-md-9 col-sm-12'}>
          <Switch>
            <Route exact path="/customer/" render={() => (<Redirect to="/customer/guest"/>)}/>
            <Route
              path="/customer/guest" exact
              render={(props) => <GuestList {...props} subHeader={true} isGuest={true}/>}/>
            <Route
              path="/customer/agent" exact
              render={(props) => <GuestList {...props} subHeader={true} isAgent={true}/>}/>
            <Route
              path="/customer/corporate" exact
              render={(props) => <GuestList {...props} subHeader={true} isCorporate={true}/>}/>
            <Route exact component={NotFound}/>
          </Switch>
        </div>
      </div>
    </div>
  </div>
);

export default ProfileRoute;