/**
 * PaymentListView
 *  View for Payment Listing
 *
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
// Consts and Libs
import AppUtil from '../../lib/util';
// Actions
import * as PaymentActions from '../../redux/payment/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPayment: PaymentActions.setPayment,
};

/* Component ==================================================================== */
class PaymentListView extends Component {
  static componentName = 'PaymentListView';

  static propTypes = {
    data: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  action = (data) => {
    this.props.setPayment(data);
    this.props.history.push(`/payment/${data.hash}/`);
  };

  render = () => {
    const data = this.props.data;
    return (
      <div
        role="presentation"
        onKeyPress={this.handleKeyPress}
        onClick={() => this.action(data)}
        className="list-group-item list-group-item-action flex-column align-items-start a-class">
        <div className="d-flex w-100 justify-content-between">
          <div>
            <h5 className="mb-1 text-break">{data.ref_no}</h5>
            {data.payment_id && <p className="mb-0 small">{'Payment ID : ' + data.payment_id}</p>}
            <p className="mb-0 small">{'Method: ' + data.payment_method_display}</p>
            {(data.corporate_name) &&
             <p className="mb-0 small"><strong>Billed to</strong> : {data.corporate_name} </p>}
            <small className="text-muted">{AppUtil.formatDateTime(data.created)}</small>
          </div>
          <div className={'text-center'}>
            <span className={'badge badge-light'}>
              {data.currency + ' ' + data.total}
            </span><br/>
            <span className={'badge ' + (AppUtil.paymentStatusColor(data.payment_status))}>
              {data.payment_status_display}
            </span>
          </div>

        </div>
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(PaymentListView);

