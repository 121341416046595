/**
 * Guest Search or Add
 */
import _ from 'lodash';
import moment from 'moment';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Collapse} from 'react-bootstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';
import queryString from 'query-string';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import {Constants, Countries, Strings, AppConfig} from '../../constants';
// Components
import Loading from '../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleDown, faPlus, faTimes} from '@fortawesome/free-solid-svg-icons';
import { Datepicker, Form, Input, Select, SubmitBtn } from 'react-formik-ui';
import GuestListView from './GuestListView';
import {Alerts} from '../../components/ui';
import ListView from '../../components/general/ListViewV2';

/* Component ==================================================================== */

class GuestManageQuick extends Component {
  static componentName = 'GuestSearchAdd';

  static propTypes = {
    search: PropTypes.bool,
    data: PropTypes.object,
    title: PropTypes.string,
    match: PropTypes.object,
    property: PropTypes.object,
    guestAddAction: PropTypes.func,
    showGuestDetails: PropTypes.func,
    addGuestFunction: PropTypes.func,
    formValidation: PropTypes.object,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
        searchParam: {},
        initial: true
      });
    }
    return true;
  }


  preFillData = () => {
    this.setState(
      {
        init: false,
        loading: false,
        results: null,
        searching: false,
      },
    );
  };


  addGuest = (credentials) => {
    if (credentials) {
      if (this.props.addGuestFunction) {
        this.props.addGuestFunction(credentials);
        this.props.onHide();
      } else {
        this.setState({resultMsg: {status: 'One moment...'}});
        AppAPI.guest.post('',
          {
            'first_name': credentials.first_name,
            'last_name': credentials.last_name,
            'email': credentials.email,
            'phone': credentials.phone ? credentials.code + credentials.phone : credentials.phone,
            'gender': credentials.gender ? credentials.gender : null,
            'age': credentials.age ? credentials.age : null,
            'date_of_birth': credentials.date_of_birth ? moment(credentials.date_of_birth).format('YYYY-MM-DD') : null,
            'nationality': credentials.nationality ? credentials.nationality : '',
          })
          .then((res) => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  if (this.props.guestAddAction) {
                    this.props.guestAddAction(res);
                  }
                  this.props.onHide();
                }, 500);
              });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      }
    }
  };

  searchData = (key, values) => {
    if (key && this.props.search !== false) {
      const {searchParam} = this.state;
      let searchText = '';

      if (key === 'first_name') {
        searchParam['first_name'] = values;
      }

      if (key === 'last_name') {
        searchParam['last_name'] = values;
      }

      if (key === 'email') {
        searchParam['email'] = values;
      }

      if (key === 'phone') {
        searchParam['phone'] = values;
      }

      // eslint-disable-next-line array-callback-return
      Object.keys(searchParam).map((key) => {
        if (searchParam[key]) {
          searchText += `&search=${key}:${searchParam[key]}`;
        }
      });
      this.setState({searchText: searchText, searchParam});
    } else {
      this.setState({
        searching: false,
        clear: false,
        results: [],
      });
    }
  };


  fetchData = (page = null, callback, searchText) => {
    const urlParams = {...this.state.urlParams};
    let baseUrl = 'guest-v2';
    if (page) {
      urlParams['cursor'] = page;
    }
    urlParams['profile_type'] = 1;
    let encodedUrlParam = queryString.stringify(urlParams);
    if (searchText) {
      this.setState({searching: true, clear: true});
      baseUrl = 'guest-search';
      encodedUrlParam = `${encodedUrlParam}${searchText}`;
      if (page){
        urlParams['page'] = page;
      }
    }
    AppAPI.guestapi.get(`${baseUrl}/?${encodedUrlParam}`)
      .then((res) => {
        if (res.results.length > 0) {
          callback(
            res.results,
            {
              allLoaded: !res.next,
              nextParam: res.count ? AppUtil.getURLParam(res.next, 'page') : AppUtil.getURLParam(res.next, 'cursor')
            }
          );
        } else {
          callback();
        }
        this.setState({searching: false, clear: false, loading: false, results: res.results});
      })
      .catch((err) => {
        callback([], {allLoaded: true});
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, loading: false, error});
      });
  };

  handleInputChange = (key, value) => {
    if (this.state.autoSearch) {
      this.setState({searching: true, initial: false});
      this.onChangeDebounced(key, value);
    }
  };

  onChangeDebounced = (key, value) => {
    this.searchData(key, value);
  };

  initialRow = () => {
    return (
      <div>
        <div>
          <div className="mx-auto w-50 featured-image">
            <img className={'w-100'} alt={'Guest List'} src={require('../../images/drawings/Guests.png')}/>
          </div>
          {
            Strings.guestSearchOrAddText.map((data, i) => <p key={i}>{data}</p>)
          }
          <button className={'btn btn-success btn-block'} onClick={()=>this.setState({initial: false})}>
            Show Guest List
          </button>

          <button
            disabled={this.state.autoSearch} className={'btn btn-link mx-auto'}
            onClick={()=>this.setState({autoSearch: true})}
          >
            Auto Search
          </button>
        </div>
      </div>
    );
  };


  emptySearch = () => {
    return (
      <div>
        <div>
          <h4>No Results</h4>
          {
            Strings.guestSearchOrAddTextEmpty.map((data, i) => <p key={i}>{data}</p>)
          }
        </div>
      </div>
    );
  };

  renderRow = (data, key) => {
    return (
      <GuestListView
        data={data}
        key={key}
        action={() => this.props.showGuestDetails(data.id, true)}
      />
    );
  };

  render = () => {

    const {init, results, searching, resultMsg, initialValues, searchText, initial} = this.state;
    const {show, formValidation, search, title, property} = this.props;

    let required_profile_fields = [];

    if (show && init) {
      this.preFillData();
    }
    if (!show) {
      return null;
    }

    const phoneCountryCode = Countries.map((data) => ({
      'value': data.phone,
      'label': '(' + data.phone + ')  ' + data.name,
    }));

    const countryList = Countries.map((data) => ({
      'value': data.code,
      'label': data.name,
    }));

    const validation = {
      first_name: Yup.string().min(2, 'Too Short!').required('Required'),
      email: Yup.string().email('Invalid email'),
      phone: Yup.string(Strings.phoneNumberValidation).matches(
        AppConfig.regXValidation.phoneRegex, Strings.phoneNumberValidation
      ).min(8, 'to short').max(10, `${Strings.phoneNumberValidation} without appending 0`),
      age: Yup.number().min(1, 'Min age is 1').max(120, 'Max age is 120').nullable(),
    };

    if (!_.isEmpty(property.config) && property.config.profile) {
      const profile_fields = property.config.profile.profile_advance_fields;
      if (!_.isEmpty(profile_fields) && !_.isEmpty(profile_fields.required_profile_fields)) {
        required_profile_fields = profile_fields.required_profile_fields;
        if (required_profile_fields.includes('phone')){
          validation['phone'] = Yup.string(Strings.phoneNumberValidation).matches(
            AppConfig.regXValidation.phoneRegex, Strings.phoneNumberValidation
          ).min(8, 'to short').max(10, `${Strings.phoneNumberValidation} without appending 0`).required(
            Strings.phoneNumberValidation
          );
        }
        if (required_profile_fields.includes('age')){
          validation['age'] = Yup.number().min(1, 'Min age is 1').max(120, 'Max age is 120').required('Required');
        }
        if (required_profile_fields.includes('gender')) {
          validation['gender'] = Yup.string().required('Required');
        }
        if (required_profile_fields.includes('nationality')) {
          validation['nationality'] = Yup.string().required('Required');
        }
      }
    }

    const defaultFormValidation = Yup.object(validation).shape();

    return (
      <Modal
        {...this.props}
        size={(search === false ? 'md' : 'lg')}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {title ? title : 'Guest Management'}
          </Modal.Title>
        </Modal.Header>
        <Formik
          onSubmit={(values) => this.addGuest(values)}
          validationSchema={formValidation ? formValidation : defaultFormValidation}
          initialValues={initialValues}>
          {(formikProps) => (
            <Form>
              <Modal.Body>
                <div className={'row'}>
                  <div className={'col-md-12 border-right ' + (search === false ? 'col-lg-12' : 'col-lg-5')}>
                    <div className="form-row">
                      <div className="col-lg-6 col-md-6">
                        <Input
                          name='first_name'
                          label={'First Name'}
                          autoComplete={'off'}
                          className={'form-control'}
                          required
                          onChange={(value) => {
                            this.handleInputChange('first_name', value.target.value);
                            formikProps.handleChange(value);
                          }}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 form-group">
                        <Input
                          name='last_name'
                          label={'Last Name'}
                          autoComplete={'off'}
                          className={'form-control'}
                          onChange={(value) => {
                            this.handleInputChange('last_name', value.target.value);
                            formikProps.handleChange(value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 form-group">
                        <Input
                          name='email'
                          label={'E-mail'}
                          className={'form-control'}
                          type={'email'}
                          onChange={(value) => {
                            this.handleInputChange('email', value.target.value);
                            formikProps.handleChange(value);
                          }}
                        />
                      </div>
                      <div className="col-lg-12 col-md-6 ">
                        <div className={'row'}>
                          <div className={'col-4 pr-0 form-group'}>
                            <Select
                              className={'form-control rounded-right-0'}
                              name='code'
                              label={'Country'}
                              placeholder='Select an Option'
                              options={phoneCountryCode}
                            />
                          </div>
                          <div className={'col-8 pl-0 form-group'}>
                            <Input
                              name='phone'
                              label={'Phone'}
                              autoComplete={'off'}
                              className={'form-control rounded-left-0'}
                              onChange={(value) => {
                                this.handleInputChange('phone', value.target.value);
                                formikProps.handleChange(value);
                              }}
                              required={required_profile_fields.includes('phone')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={'border mt-4 p-2 text-center anchor'}
                      onClick={() => this.setState({advanceSettings: !this.state.advanceSettings})}
                    >
                      <h6 className={'mb-0'}>
                        <FontAwesomeIcon
                          icon={faChevronCircleDown} size={'sm'} className={'mx-2 green-cl float-left mt-1'}/>
                        Additional Information
                        <FontAwesomeIcon
                          icon={faChevronCircleDown} size={'sm'} className={'mx-2 green-cl float-right mt-1'}/>
                      </h6>
                    </div>
                    <Collapse in={this.state.advanceSettings}>
                      <div className={'py-2 border p-2'}>
                        <div className="form-row ">
                          <div className="col-sm-12 col-md-6 form-group ">
                            <Select
                              className={'form-control rounded-right-0'}
                              name='nationality'
                              label={'Nationality'}
                              placeholder='Select an Option'
                              options={countryList}
                              required={required_profile_fields.includes('gender')}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6 form-group">
                            <Select
                              name='gender'
                              label={'Gender'}
                              placeholder='Select an Option'
                              options={Constants.GENDER}
                              className={'form-control rounded-right-0'}
                              required={required_profile_fields.includes('gender')}
                            />
                          </div>
                        </div>
                        {(!_.isEmpty(property.config) && property.config.profile && property.config.profile.profile_advance_details) && (
                          <>
                            <hr/>
                            <div className={'form-row'}>
                              <div className="col-5 form-group">
                                <Input
                                  name='age'
                                  label={'Age'}
                                  className={'form-control'}
                                  required={required_profile_fields.includes('age')}
                                />
                              </div>
                              <div className="col-7 form-group">
                                <p className={'mb-1'}>Date of Birth</p>
                                <Datepicker
                                  name='date_of_birth'
                                  className={'form-control'}
                                  maxDate={moment.now()}
                                  hint={'Date format DD/MM/YYYY'}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </Collapse>
                    {search !== false &&
                     <React.Fragment>
                       <div className={'spacer-20'}/>
                       <SubmitBtn className={'btn btn-block btn-lg btn-success'}>
                         <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add New Guest
                       </SubmitBtn>
                     </React.Fragment>
                    }
                  </div>
                  {search !== false &&
                   <div className={'col-lg-7 col-md-12 overflow-auto guest-search-height mb-3'}>
                     {searching && <Loading heightMatch={false}/>}
                     {results && results.length < 1 && this.emptySearch()}
                     {initial ?
                       this.initialRow():
                       <ListView
                         rowView={this.renderRow}
                         onFetch={this.fetchData}
                         searchParams={searchText}
                         pagination
                         firstLoader={true}
                         emptyView={this.emptySearch}
                         class={'list-group'}
                       />
                     }
                   </div>
                  }
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <Alerts
                  status={resultMsg.status}
                  success={resultMsg.success}
                  error={resultMsg.error}
                />
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  {search === false &&
                   <div className={'col'}>
                     <SubmitBtn className={'btn btn-block btn-lg btn-success'}>
                       <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add Guest
                     </SubmitBtn>
                   </div>
                  }
                </div>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    const {property} = this.props;
    this.onChangeDebounced = _.debounce(this.onChangeDebounced, 300, {});

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: true,
      results: [],
      autoSearch: false,
      searching: false,
      advanceSettings: (!_.isEmpty(property.config) && property.config.profile && property.config.profile.profile_advance_details),
      initial: true,
      searchParam: {},
      urlParams: {},
      initialValues: {
        'salutation': '',
        'first_name': '',
        'last_name': '',
        'email': '',
        'code': property && property.phone_number_code ? property.phone_number_code : '',
        'nationality': property && property.country ? property.country.code : '',
        'phone': '',
        'age': '',
        'gender': '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default GuestManageQuick;
