/**
 *
 * PaymentView
 * overview of payments
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {Link} from 'react-router-dom';
import Moment from 'moment';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import {Constants} from '../../constants';
import Colors from '../../theme/colors';
// Components
import PaymentAdd from './models/PaymentAdd';
import PaymentListView from './PaymentListView';
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import PageHeader from '../../components/dashboard/PageHeader';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBarChart,
  faCashRegister,
  faCheckCircle,
  faSpinner,
  faLink,
  faList,
  faPlus,
  faReceipt,
  faUniversity
} from '@fortawesome/free-solid-svg-icons';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PaymentView extends Component {
  static componentName = 'PaymentView';
  componentDidMount = () => {
    this.fetchInitData();
  };
  action = (data) => {
    this.props.history.push('/payment/' + data.id);
  };
  fetchInitData = (no_cache, loading= true) => {
    this.setState({loading: loading});
    AppAPI.paymentapi.get('payment-status-v2/' + (no_cache ? '?reset_cache=true' : ''))
      .then((res) => {
        let responseData  = {};
        if (!_.isEmpty(res.data)){
          responseData = {
            currency: res.data.currency,
            time: res.data.time,
            response: res.data,
          };
        }

        // Processing flag indicates
        if (res.processing) {
          setTimeout(()=>this.fetchInitData(false, false), 5000);
        }

        this.setState({
          ...responseData,
          loading: false,
          processing: res.processing || false
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: error,
          resultMsg: {error},
        });
      });
  };
  renderRow = (data, key) => {
    return (
      <PaymentListView
        data={data}
        key={key}
        history={this.props.history}
      />
    );
  };
  render = () => {
    const {loading, error, response, currency, chartValueType, processing} = this.state;
    const {property} = this.props;
    const {last_updated} = this.state.response;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const chartCount = [], chartValue = [];

    const paymentData = {
      'unSettledPayment': {
        'count': 0,
        'total': 0,
      },
      'notPaidPayment': {
        'count': 0,
        'total': 0,
      },
      'partPaidPayment': {
        'count': 0,
        'total': 0,
      },
      'datePaymentPaid': {
        'count': 0,
        'total': 0,
      },
      'datePaymentNotPaid': {
        'count': 0,
        'total': 0,
      }
    };

    if (!_.isEmpty(response)) {
      const {payments_date_method_data, payments_not_settled, payments_not_paid, payments_date_data} = response;
      if (!_.isEmpty(payments_date_method_data)) {
        payments_date_method_data.forEach((data) => {
          switch (data.payment_method) {
          case Constants.PAYMENT_METHODS.CASH:
            chartCount.push({name: 'Cash', value: data.count, color: Colors.yellow});
            chartValue.push({name: 'Cash', value: data.total, color: Colors.yellow});
            break;
          case Constants.PAYMENT_METHODS.CHECK:
            chartCount.push({name: 'Cheque', value: data.count, color: Colors.green});
            chartValue.push({name: 'Cheque', value: data.total, color: Colors.green});
            break;
          case Constants.PAYMENT_METHODS.BANK_TRANSFER:
            chartCount.push({name: 'Bank Transfer', value: data.count, color: Colors.orange});
            chartValue.push({name: 'Bank Transfer', value: data.total, color: Colors.orange});
            break;
          case Constants.PAYMENT_METHODS.ONLINE_PAYMENT:
            chartCount.push({name: 'Online Payment', value: data.count, color: Colors.blue});
            chartValue.push({name: 'Online Payment', value: data.total, color: Colors.blue});
            break;
          case Constants.PAYMENT_METHODS.CARD:
            chartCount.push({name: 'Card', value: data.count, color: Colors.red});
            chartValue.push({name: 'Card', value: data.total, color: Colors.red});
            break;
          case Constants.PAYMENT_METHODS.OTHER:
            chartCount.push({name: 'Other', value: data.count, color: Colors.yellowLight});
            chartValue.push({name: 'Other', value: data.total, color: Colors.yellowLight});
            break;
          case Constants.ORDER_SOURCE.ONLINE_EXTERNAL:
            chartCount.push({name: 'Online (Other)', value: data.count, color: Colors.info});
            chartValue.push({name: 'Online (Other)', value: data.total, color: Colors.info});
            break;
          default:
            break;
          }
        });
      }

      if (!_.isEmpty(payments_date_data)) {
        payments_date_data.forEach((data) => {
          if (data.payment_status === Constants.PAYMENT_STATUS.PAID) {
            paymentData['datePaymentPaid']['count'] = data['count'];
            paymentData['datePaymentPaid']['total'] = data['total'];
          }

          if (data.payment_status === Constants.PAYMENT_STATUS.NOTPAID) {
            paymentData['datePaymentNotPaid']['count'] = data['count'];
            paymentData['datePaymentNotPaid']['total'] = data['total'];
          }
        });
      }

      if (!_.isEmpty(payments_not_settled)) {
        payments_not_settled.forEach((data) => {
          paymentData['unSettledPayment']['count'] = data['count'];
          paymentData['unSettledPayment']['total'] = data['total'];
        });
      }
      if (!_.isEmpty(payments_not_paid)) {
        payments_not_paid.forEach((data) => {
          paymentData['notPaidPayment']['count'] = data['count'];
          paymentData['notPaidPayment']['total'] = data['total'];
        });
      }
    }

    let chartData = (chartValueType === 'count' ? chartCount : chartValue);

    return (
      <div className="payment-view screen-container">
        <Helmet>
          <title>Payments Overview</title>
        </Helmet>

        <PageHeader
          history={this.props.history} title={'Payment(s)'}
          description={`${property.name} payment(s) status at ${AppUtil.formatDateTime(Moment(), 'datetime')}.`}
        />

        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12 mb-5">
            <div className={'row'}>
              <div className={'col-lg-6 col-sm-12 mb-3'}>
                <h6 className={'small text-uppercase text-muted'}>Day Overview ({currency})</h6>
                <div className={'card mb-3 a-class'}>
                  <div className="card-body">
                    <div className={'row'}>
                      <div
                        className={'col a-class'}
                        onClick={() => this.props.history.push(`/payment/list?payment_status=paid&start_date=${AppUtil.formatDateTime(Moment(), 'date-iso')}`)}
                      >
                        <small>Paid Payment(s)</small>
                        <h5 className={'card-title mb-0 pb-0 green-cl'}>
                          {(!_.isEmpty(paymentData) && !processing) ? paymentData.datePaymentPaid.total : '...'}
                        </h5>
                      </div>
                      <div
                        className={'col a-class'}
                        onClick={() => this.props.history.push(`/payment/list?payment_status=not-paid&start_date=${AppUtil.formatDateTime(Moment(), 'date-iso')}`)}
                      >
                        <small>Unpaid Payment(s)</small>
                        <h6 className={'card-title mb-0 pb-0 red-cl'}>
                          {(!_.isEmpty(paymentData) && !processing) ? paymentData.datePaymentNotPaid.total : '...'}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={'row'}>
                    <div
                      className={'col a-class'}
                      onClick={() => this.props.history.push('/payment/list?payment_status=not-paid')}
                    >
                      <h6 className={'small text-uppercase text-muted'}>Overall ({currency})</h6>
                      <div className={'card bg-danger text-light a-class'}>
                        <div className="card-body">
                          <small>Unpaid Payment(s)</small>
                          <h6 className={'card-title mb-0 pb-0'}>
                            {(!_.isEmpty(paymentData) && !processing) ? paymentData.notPaidPayment.total : '...'}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div
                      className={'col a-class'}
                      onClick={() => this.props.history.push('/payment/list?settlement_status=not-settled')}
                    >
                      <h6 className={'small text-uppercase text-muted'}>Overall ({currency})</h6>
                      <div className={'card bg-warning a-class'}>
                        <div className="card-body">
                          <small>Pending Settlement</small>
                          <h6 className={'card-title mb-0 pb-0'}>
                            {(!_.isEmpty(paymentData) && !processing) ? paymentData.unSettledPayment.total : '...'}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!_.isEmpty(chartData) ?
                <div className={'col-lg-6 col-md-12 mb-3'}>
                  <ResponsiveContainer height={210}>
                    <PieChart onMouseEnter={this.onPieEnter}>
                      <Pie
                        data={chartData}
                        innerRadius={0}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        <Cell fill={Colors.yellow}/>
                        <Cell fill={Colors.green}/>
                        <Cell fill={Colors.orange}/>
                        <Cell fill={Colors.blue}/>
                        <Cell fill={Colors.red}/>
                        <Cell fill={Colors.yellowLight}/>
                        <Cell fill={Colors.info}/>
                      </Pie>
                      <Tooltip/>
                      <Legend onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}/>
                    </PieChart>
                  </ResponsiveContainer>
                  <div className={'text-center mt-2'}>
                    <div className="btn-group btn-group-sm" role="group">
                      <button disabled={true} className={'btn btn-outline-secondary'}>Day Overview</button>
                      <button
                        type="button"
                        onClick={() => this.setState({chartValueType: 'count'})}
                        className={`btn ${chartValueType === 'count' ? 'btn-success' : 'btn-outline-secondary'}`}>
                        Count (Nos)
                      </button>
                      <button
                        type="button"
                        onClick={() => this.setState({chartValueType: 'value'})}
                        className={`btn ${chartValueType === 'value' ? 'btn-success' : 'btn-outline-secondary'}`}>
                        Value ({currency})
                      </button>
                    </div>
                  </div>
                </div>
                :
                <div className={'col-lg-6 col-md-12 mb-3 d-flex border rounded'}>
                  <div className="align-self-center text-center mx-auto">
                    <FontAwesomeIcon
                      size={'2x'}
                      spin={processing}
                      className={'green-cl'}
                      icon={processing ? faSpinner : faCheckCircle}
                    />
                    <p className={'small'}>{processing ? '...' : 'No payments today.'}</p>
                  </div>
                </div>
              }
            </div>
            {last_updated &&
            <p className={'small text-secondary'}>
              last updated at {AppUtil.formatDateTime(last_updated)}.
            </p>
            }
            <div className="spacer-20"/>
            <div className={'row'}>
              <div className={'col-6'}>
                <Link className="btn btn-success btn-lg btn-block" to="/payment/list">
                  <FontAwesomeIcon icon={faList} size={'sm'} className={'mr-2'}/> Payments List
                </Link>
              </div>
              <div className={'col-6'}>
                <button onClick={() => this.setState({showPayment: true})} className="btn btn-success btn-lg btn-block">
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> New Payment
                </button>
              </div>
            </div>
            <div className="spacer-20"/>
            <div className={'row'}>
              <div className={'col-6'}>
                <Link className="btn btn-success btn-lg btn-block" to="/payment/refund/">
                  <FontAwesomeIcon icon={faReceipt} size={'sm'} className={'mr-2'}/> Refunds
                </Link>
              </div>
              <div className={'col-6'}/>
            </div>
            <div className="spacer-20"/>
            <div className={'row'}>
              <div className={'col-3'}>
                <Link className="btn btn-outline-success btn-sm btn-block" to="/payment/payment-gateway/">
                  <FontAwesomeIcon icon={faLink} size={'sm'} className={'mr-2'}/> Online Payments
                </Link>
              </div>
              <div className={'col-3'}>
                <Link className="btn btn-outline-success btn-sm btn-block" to="/payment/payment-terminal/">
                  <FontAwesomeIcon icon={faCashRegister} size={'sm'} className={'mr-2'}/> Terminal Payments
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 mb-5">
            <ul className="list-group mb-5">
              <li className="list-group-item" onClick={() => this.fetchInitData(true)}>
                <small>Payment Options(s)</small>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <button onClick={() => this.setState({showPayment: true})} className="btn btn-link p-0">
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Payment
                </button>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className="" to="/payment/list">
                  <FontAwesomeIcon icon={faList} size={'sm'} className={'mr-2'}/> Payments List
                </Link>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className="" to="/payment/payment-gateway/list">
                  <FontAwesomeIcon icon={faLink} size={'sm'} className={'mr-2'}/> Payment Links
                </Link>
              </li>
            </ul>

            <ul className="list-group">
              <li className="list-group-item">
                <small>Reports / Analytics</small>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className="" to="/payment/reports/">
                  <FontAwesomeIcon icon={faUniversity} size={'sm'} className={'mr-2'}/> Reports
                </Link>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className="" to="/payment/analytics">
                  <FontAwesomeIcon icon={faBarChart} size={'sm'} className={'mr-2'}/> Analytics
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <PaymentAdd
          updateResponse={(data) => this.props.history.push(`/payment/${data.hash}/`)}
          onHide={() => this.setState({showPayment: false})}
          show={this.state.showPayment}
          property={this.props.property}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: null,
      response: {},
      loading: true,
      processing: false,
      showPayment: false,
      chartValueType: 'count'
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentView);
