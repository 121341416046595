/**
 *
 * Booking Analytics
 */

import Moment from 'moment';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import queryString from 'query-string';
import React, { Component } from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';

// Consts and Libs
import AppUtil from '../../../lib/util';
import AppWebUtil from '../../../lib/webUtils';

// Components
import { Filters } from '../../../constants';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import FilterDate from '../../../components/dashboard/FilterDate';
import PageHeader from '../../../components/dashboard/PageHeader';
import FilterButton from '../../../components/dashboard/FilterButton';

import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BookingAnalyticsOTA from './BookingAnalyticsOTA';
import BookingAnalyticsSource from './BookingAnalyticsSource';
import BookingAnalyticsOverview from './BookingAnalyticsOverview';
import BookingAnalyticsVisitType from './BookingAnalyticsVisitType';
import BookingAnalyticsReference from './BookingAnalyticsReference';

import BookingRoomAnalyticsOTA from './BookingRoomAnalyticsOTA';
import BookingRoomAnalyticsRoom from './BookingRoomAnalyticsRoom';
import BookingRoomAnalyticsGuest from './BookingRoomAnalyticsGuest';
import BookingRoomAnalyticsSource from './BookingRoomAnalyticsSource';
import BookingRoomAnalyticsRoomType from './BookingRoomAnalyticsRoomType';
import BookingRoomAnalyticsOverview from './BookingRoomAnalyticsOverview';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class BookingAnalytics extends Component {
  static componentName = 'BookingAnalytics';

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key, defaultValue=false) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return defaultValue;
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    let startDate = Moment(), endDate = null;
    if (this.getQueryParams('start_date')) {
      startDate = Moment(String(this.getQueryParams('start_date')));
    }
    if (this.getQueryParams('end_date')) {
      endDate = Moment(String(this.getQueryParams('end_date')));
    }

    const urlParams = {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate ? endDate.format('YYYY-MM-DD') : null
    };

    if (this.getQueryParams('date_type')) {
      urlParams['date_type'] = this.getQueryParams('date_type');
    }

    this.setState({
      loading: false,
      endDate: endDate,
      startDate: startDate,
      selectionType: endDate ? 'range' : 'single',
      URLQueryParams: urlParams
    });
  };

  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    const baseUrl = '/booking/analytics';
    const menu = [
      {
        title: 'Overview',
        link: `${baseUrl}/booking-overview`,
        isExact: false,
        component: (props) => <BookingAnalyticsOverview {...props}/>
      },
      {
        title: 'OTA',
        link: `${baseUrl}/booking-ota`,
        isExact: true,
        component: (props) => <BookingAnalyticsOTA {...props}/>
      },
      {
        title: 'Source',
        link: `${baseUrl}/booking-source`,
        isExact: true,
        component: (props) => <BookingAnalyticsSource {...props}/>
      },
      {
        title: 'Visit Type',
        link: `${baseUrl}/booking-visit-type`,
        isExact: false,
        component: (props) => <BookingAnalyticsVisitType {...props}/>
      },
      {
        title: 'Reference',
        link: `${baseUrl}/booking-reference`,
        isExact: false,
        component: (props) => <BookingAnalyticsReference {...props}/>
      },
    ];

    const menuBookingRooms = [
      {
        title: 'Overview',
        link: `${baseUrl}/booking-room-overview`,
        isExact: true,
        component: (props) => <BookingRoomAnalyticsOverview {...props}/>
      },
      {
        title: 'Source',
        link: `${baseUrl}/booking-room-source`,
        isExact: true,
        component: (props) => <BookingRoomAnalyticsSource {...props}/>
      },
      {
        title: 'OTA',
        link: `${baseUrl}/booking-room-ota`,
        isExact: true,
        component: (props) => <BookingRoomAnalyticsOTA {...props}/>
      },
      {
        title: 'Guest',
        link: `${baseUrl}/booking-room-guest`,
        isExact: true,
        component: (props) => <BookingRoomAnalyticsGuest {...props}/>
      },
    ];

    const menuRoomRoomTypes = [
      {
        title: 'Room',
        link: `${baseUrl}/booking-room-data`,
        isExact: true,
        component: (props) => <BookingRoomAnalyticsRoom {...props}/>
      },
      {
        title: 'Room Type',
        link: `${baseUrl}/booking-room-type-data`,
        isExact: true,
        component: (props) => <BookingRoomAnalyticsRoomType {...props}/>
      }
    ];

    const dateType = AppUtil.valueFromList(
      Filters.bookingDateType, 'key', this.getQueryParams('date_type'), 'value'
    );

    const commonProps = {
      ...this.props,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      dateType: dateType
    };


    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Booking Analytics</title>
        </Helmet>

        <PageHeader
          border={false}
          title={'Booking Analytics'}
          history={this.props.history}
          description={
            `Booking summary and activity for ${AppUtil.formatDateTime(this.state.startDate, 'date')}
            ${this.state.endDate ? ` to ${AppUtil.formatDateTime(this.state.endDate, 'date')}. ` : '.'}`
          }
        />

        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-12 col-lg-8'}>
            <ul className="list-inline list-item-mb-1 rounded">
              <li className="list-inline-item">
                <FilterDate
                  title={''}
                  maxDate={(!dateType || parseInt(dateType) === 1) ? Moment() : null}
                  endDate={this.state.endDate}
                  startDate={this.state.startDate}
                  selectionType={this.state.selectionType}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data))}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Date Type'}
                  removeFilter={false}
                  data={Filters.bookingDateType}
                  selectKey={this.getQueryParams('date_type', 'created')}
                  action={(data) => this.setQueryParams([{key: 'date_type', value: data.key}])}
                />
              </li>
              <li className="list-inline-item">
                <button
                  className={'btn btn-outline-secondary'}
                  onClick={() => this.setState({loading: true}, () => this.fetchInitData())}
                >
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={''}/>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <hr/>
        <div className={'row'}>
          <div className={'col-lg-2 col-12 mb-5'}>
            <ul className="nav nav-pills flex-md-column flex-sm-row border rounded mb-3">
              <li className="nav-item" role="presentation">
                <span className={'nav-link rounded disabled'}><small>Booking</small></span>
              </li>
              {menu.map((data, i) => (
                <li className="nav-item" role="presentation" key={i}>
                  <NavLink
                    to={{
                      pathname: data.link,
                      search: queryString.stringify(this.state.URLQueryParams),
                    }}
                    className={`nav-link rounded-0 ${!data.component && 'disabled'}`}
                    activeClassName="selected">
                    {data.title}
                  </NavLink>
                </li>
              ))}
            </ul>

            <ul className="nav nav-pills flex-md-column flex-sm-row border rounded mb-3">
              <li className="nav-item" role="presentation">
                <span className={'nav-link rounded disabled'}><small>Booking Room</small></span>
              </li>
              {menuBookingRooms.map((data, i) => (
                <li className="nav-item" role="presentation" key={i}>
                  <NavLink
                    to={{
                      pathname: data.link,
                      search: queryString.stringify(this.state.URLQueryParams),
                    }}
                    className={`nav-link rounded-0 ${!data.component && 'disabled'}`}
                    activeClassName="selected">
                    {data.title}
                  </NavLink>
                </li>
              ))}
            </ul>

            <ul className="nav nav-pills flex-md-column flex-sm-row border rounded mb-3">
              <li className="nav-item" role="presentation">
                <span className={'nav-link rounded disabled'}><small>Room / Room Type</small></span>
              </li>
              {menuRoomRoomTypes.map((data, i) => (
                <li className="nav-item" role="presentation" key={i}>
                  <NavLink
                    to={{
                      pathname: data.link,
                      search: queryString.stringify(this.state.URLQueryParams),
                    }}
                    className={`nav-link rounded-0 ${!data.component && 'disabled'}`}
                    activeClassName="selected">
                    {data.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>

          <div className={'col-lg-10 col-12'}>
            <Switch>
              <Route
                exact
                path={baseUrl}
                render={() => (<Redirect to={`${baseUrl}/booking-overview`}/>)}
              />
              {[...menu, ...menuBookingRooms, ...menuRoomRoomTypes].map((data, i) => (
                data.component ?
                  <Route
                    key={i}
                    path={data.link} exact
                    render={() => data.component(commonProps)}
                  />
                  :
                  <></>
              ))}
            </Switch>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      endDate: null,
      loading: true,
      URLQueryParams: {},
      selectionType: 'single',
      startDate: Moment().startOf('day'),
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(BookingAnalytics);
