import React from 'react';
import {Route, Switch} from 'react-router-dom';


import NotFound from '../NotFound';

import EventInventory from '../event/Settings';
import EventManage from '../event/Settings/EventManage';
import EventList from '../event/Settings/EventList';
import eventRequired from '../../components/decorators/eventRequired';


const PropertyRoute = () => (
  <div>
    <div className={'row'}>
      <div className={'col-lg-10 col-md-12 col-sm-12'}>
        <Switch>
          {/* Event */}
          <Route path="/settings/event/" exact component={EventList}/>
          <Route path="/settings/event/new" exact component={EventManage}/>
          <Route path="/settings/event/:eventId" component={eventRequired(EventInventory)}/>
          <Route exact component={NotFound}/>
        </Switch>
      </div>
    </div>
  </div>
);

export default PropertyRoute;