import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';


import ExpenseAdd from './ExpenseAdd';
import ExpenseView from './ExpenseView';
import ExpenseListIndex from './ExpenseListIndex';
import ExpenseDetails from './ExpenseDetails';
import NotFound from '../NotFound';
import ReportsListIndex from '../reports/ReportsListIndex';
import {Constants} from '../../constants';

const OrderRoute = () => (
  <div>
    
    <div className="container">
      <Switch>
        <Route path="/expense/" exact component={ExpenseView}/>
        <Route path="/expense/add" exact component={ExpenseAdd}/>
        <Route path="/expense/list" component={ExpenseListIndex}/>
        <Route
          exact
          path={'/expense/reports/'}
          render={() => (<Redirect to={'/expense/reports/list'}/>)}
        />

        <Route
          path={'/expense/reports/list'} exact
          render={(props) =>
            <ReportsListIndex
              {...props}
              baseUrl={'/expense'}
              source={Constants.REPORT_SOURCE.EXPENSE}
              headerTitle={'Expense Report\'s'}
              headerSubTitle={'Consolidated expense reports.'}
              reportOptions={Constants.REPORTS_EXPENSE}
            />
          }
        />

        <Route path="/expense/:expenseOrderId" exact component={ExpenseDetails}/>
        <Route exact component={NotFound}/>
      </Switch>
    </div>
    
  </div>
);

export default OrderRoute;