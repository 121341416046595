import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../lib/api';
// Components
import Error from '../general/Error';
import Loading from '../general/Loading';
// Actions
import * as RoomActions from '../../redux/room/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  user: state.user,
  room: state.room.roomHousekeeping,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setRoom: RoomActions.setRoomHousekeeping,
};

export default function (ComposedComponent) {
  class RoomHousekeepingRequired extends Component {
    static contextTypes = {
      router: PropTypes.object,
    };

    componentDidMount() {
      if (_.isEmpty(this.props.room) || this.props.match.params.roomId !== this.props.room.hash) {
        this.firstLoad = true;
        this.props.setRoom(this.props.match.params.roomId)
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({error});
          });
      }
    }

    render() {
      if (this.state.error) return <Error full={false} text={this.state.error}/>;
      if (_.isEmpty(this.props.room.hash) || this.props.room.hash !== this.props.match.params.roomId) return <Loading/>;
      return <ComposedComponent {...this.props} firstLoad={this.firstLoad}/>;
    }


    constructor(props) {
      super(props);
      this.firstLoad = false;
      this.state = { error: null };
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(RoomHousekeepingRequired);
}