/**
 * Manage Amendment
 *  manages amendments
 */
import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import AppUtil from '../../lib/util';
// Consts and Libs
import AppAPI from '../../lib/api';
import { Constants, ErrorMessages, Strings } from '../../constants';
// Components
import {Alerts} from '../../components/ui';
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import PageHeader from '../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faSave, faPlus, faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
// Form
import * as Yup from 'yup';
import {Formik} from 'formik';
import { Checkbox, Form, Input, Select, SubmitBtn } from 'react-formik-ui';
// Actions
import * as BookingRoomActions from '../../redux/bookingroom/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  bookingRoomAmendment: state.bookingroom.bookingroomamendment
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setBookingRoomAmendment: BookingRoomActions.setBookingRoomAmendment
};

/* Component ==================================================================== */

class BookingAmendmentManage extends Component {
  static componentName = 'BookingAmendmentManage';

  static propTypes = {
    match: PropTypes.object,
  };

  componentDidMount = () => {
    if (this.props.match.params.bookingAmendmentId) {
      this.props.setBookingRoomAmendment(this.props.match.params.bookingAmendmentId)
        .then(() => {
          this.fetchInitData();
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.fetchInitData();
    }
  };

  addBookingAmendment = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});
      AppAPI.bookingapi.post('booking-amendment-settings/',
        {
          'name': credentials.name,
          'price': credentials.price,
          'active': credentials.active,
          'multiple': credentials.multiple,
          'quantity_type':credentials.quantity_type,
          'sub_quantity_type':credentials.sub_quantity_type,
          'tax_class': this.state.selectedTaxClasses,
        })
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                this.props.history.push(`/settings/inventory/amendments/${res.id}/`);
              }, 500);
            },
          );
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  saveBookingAmendment = (credentials) => {
    if (credentials)  {
      this.setState({resultMsg: {status: 'One moment...'}});
      AppAPI.bookingapi.patch(`booking-amendment-settings/${this.props.match.params.bookingAmendmentId}/`,
        {
          'name': credentials.name,
          'price': credentials.price,
          'active': credentials.active,
          'multiple': credentials.multiple,
          'quantity_type':credentials.quantity_type,
          'sub_quantity_type':credentials.sub_quantity_type,
          'tax_class': this.state.selectedTaxClasses,
        })
        .then(() => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                this.props.history.goBack();
              }, 500);
            },
          );
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  fetchInitData = () => {
    const {bookingRoomAmendment} = this.props;
    let initialValues = {
      name: '',
      price: '0',
      active: true,
      multiple: false,
      quantity_type: '1',
      sub_quantity_type: '1',
    };
    let taxClass = [], selectedTaxClasses = [];

    let formValidation = Yup.object().shape({
      name: Yup.string().min(2, 'Too Short!').required('Required'),
      price: Yup.number().positive().required(),
      quantity_type: Yup.number().positive().required(),
      sub_quantity_type: Yup.number().positive().required(),
    });

    AppAPI.propertyapi.get('tax-classes-list/')
      .then((res) => {
        if (!_.isEmpty(res)) {
          res.forEach(data => taxClass.push(data));
        }

        if (!_.isEmpty(bookingRoomAmendment) && this.props.match.params.bookingAmendmentId) {
          initialValues = {
            name: bookingRoomAmendment.name,
            price: bookingRoomAmendment.price,
            active: bookingRoomAmendment.active,
            multiple: bookingRoomAmendment.multiple,
            quantity_type: String(bookingRoomAmendment.quantity_type),
            sub_quantity_type: String(bookingRoomAmendment.sub_quantity_type)
          };
          selectedTaxClasses = bookingRoomAmendment.tax_class;
        }

        this.setState({
          taxClass,
          initialValues,
          formValidation,
          selectedTaxClasses,
          loading: false,
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: true,
          resultMsg: {error}
        });
      });
  };

  selectTax = tax => {
    let {selectedTaxClasses} = this.state;
    selectedTaxClasses = AppUtil.insertOrRemoveArray(selectedTaxClasses, tax);
    this.setState({selectedTaxClasses});
  };

  render = () => {

    const {bookingRoomAmendment, property} = this.props;
    const {loading, error, initialValues, formValidation, resultMsg, selectedTaxClasses, taxClass} = this.state;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    return (

      <div className="room-type-list-manage screen-container ">
        <Helmet>
          <title>Manage Booking Amendment</title>
        </Helmet>
        <PageHeader
          subHeader={true} history={this.props.history}
          title={(!this.props.match.params.bookingAmendmentId ? 'Add' : 'Manage') + ' Booking Amendment'}
        />

        <Formik
          initialValues={initialValues}
          validationSchema={formValidation}
          onSubmit={
            (values) => !this.props.match.params.bookingAmendmentId
              ? this.addBookingAmendment(values)
              : this.saveBookingAmendment(values)
          }
        >
          {() => (
            <Form>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center w-100'}>
                    <h6 className={'mb-0'}>
                      Amendment Information
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Input
                          required
                          name='name'
                          label={'Amendment Name'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                  <p><strong>Name : </strong> {Strings.bookingAmendmentManageName}</p>
                </div>
              </div>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center w-100'}>
                    <h6 className={'mb-0'}>
                      Rate Information [{property.currency}]
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <Input
                          required
                          name='price'
                          label={'Price'}
                          className={'form-control'}
                          hint={Strings.bookingAmendmentManagePrice}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                  <p><strong>Rate Information : </strong> {Strings.bookingAmendmentManageRateInfo}</p>
                </div>
              </div>

              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div
                    className={'border p-2 text-center anchor'} data-toggle="collapse" href="#taxitems"
                    role="button" aria-expanded="true" aria-controls="taxitems"
                  >
                    <h6 className={'mb-0'}>
                      <FontAwesomeIcon
                        icon={faChevronCircleDown} size={'sm'}
                        className={'mx-2 green-cl float-left mt-1'}/>
                      Tax Information
                      {selectedTaxClasses.length > 0 &&
                      <span className={'ml-3 badge badge-success'}>{selectedTaxClasses.length} Tax applied</span>
                      }
                      <FontAwesomeIcon
                        icon={faChevronCircleDown} size={'sm'}
                        className={'mx-2 green-cl float-right mt-1'}/>
                    </h6>
                  </div>
                  <div className={'py-2 border collapse p-2'} id={'taxitems'}>
                    <ul className="list-group mt-3 list-inline">
                      {
                        taxClass.map((data, i) => (
                          <li
                            className="list-group-item list-inline-item a-class"
                            key={i}
                            onClick={() => this.selectTax(data.id)}
                          >
                            <input
                              className={'mr-2'} type="checkbox"
                              checked={selectedTaxClasses.includes(data.id)}/>
                            {data.name} : {data.description}
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
              </div>

              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center w-100'}>
                    <h6 className={'mb-0'}>
                      Amendment Type
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-6 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='sub_quantity_type'
                          label={'Sub Quantity Type'}
                          placeholder='Select an Option'
                          options={Constants.ORDER_QUANTITY_TYPE}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='quantity_type'
                          label={'Quantity Type'}
                          placeholder='Select an Option'
                          options={Constants.ORDER_QUANTITY_TYPE}
                        />
                      </div>
                    </div>

                    <hr/>

                    <div className="form-row">
                      <div className="col-12 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='multiple'
                          label='Include Multiple'
                          text={'Whether multiple addition of the amendment is allowed in single order.'}
                        />
                      </div>
                    </div>
                    <hr/>
                    <div className="form-row">
                      <div className="col-12 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='active'
                          label='Active'
                          text={'Enable sale of this amendment'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                  <p><strong>Amendment Type : </strong> {Strings.bookingAmendmentManageType}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-12 mt-2">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn className={'btn btn-success btn-block btn-lg mt-2'}>
                    <FontAwesomeIcon
                      className={'white-cl mr-2'}
                      icon={_.isEmpty(bookingRoomAmendment) ? faPlus : faSave} size={'sm'}
                    /> {_.isEmpty(bookingRoomAmendment) ? 'Add' : 'Save'}
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>


      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      initialValues: {},
      taxClass: [],
      selectedTaxClasses: [],
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(BookingAmendmentManage);
