/**
 * Manage Refund Actions
 *  manages payment Confirm
 */
import _ from 'lodash';
import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';
import {Modal} from 'react-bootstrap';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {ErrorMessages} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import {Select, Textarea} from 'react-formik-ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';
import {confirmAlert} from '../../../components/general/ConfirmAlert';

/* Component ==================================================================== */

class RefundAction extends React.Component {
  static componentName = 'RefundAction';

  static propTypes = {
    match: PropTypes.object,
    refund: PropTypes.object,
    updateResponse: PropTypes.func,
    paymentTerminals: PropTypes.object,
  };

  updateRefund = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});

      const payload = {
        mark_payment: true,
        reference_id: credentials.reference_id,
        payment_terminal: credentials.payment_terminal,
      };
      AppAPI.refund
        .patch(`${this.props.refund.hash}/`, payload)
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
              if (this.props.updateResponse) {
                this.props.updateResponse(res);
              }
              this.props.onHide();
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {refund, show, paymentTerminals} = this.props;
    if (loading) return <Loading heightMatch={false}/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    if (!show || !refund) return <React.Fragment/>;

    const formValidation = Yup.object().shape({
      reference_id: Yup.string().nullable(),
    });

    const paymentTerminalControls = () => {
      const {payment_terminals} = paymentTerminals;
      if(!_.isEmpty(payment_terminals)) {
        let payment_terminals_list = payment_terminals.filter(item => (item.refund_allowed === true && item.active === true));
        payment_terminals_list = payment_terminals_list.filter(item => (parseInt(item.payment_method) === parseInt(refund.payment_method)));

        const TERMINAL_OPTIONS =  payment_terminals_list.map((data)=> ({'value': data.id, 'label': `${data.name} ${data.short_description ? `(${data.short_description})` : ''}`}));

        if (!_.isEmpty(payment_terminals_list)) {
          return (
            <div>
              <div className="form-row">
                <div className="col-12 form-group">
                  <Select
                    className={'form-control rounded-right-0'}
                    name='payment_terminal'
                    placeholder={'Select payment location/terminal'}
                    label={'Payment Terminal'}
                    options={TERMINAL_OPTIONS}
                  />
                </div>
              </div>
            </div>
          );
        }
      }
    };

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirm Refund
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            reference_id: refund.reference_id,
          }}
          validationSchema={formValidation}
          onSubmit={values => this.updateRefund(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <Form className={'form-group'}>
                      <div className={'border rounded p-2 mb-3'}>
                        <div className={'row'}>
                          <div className="col">
                            <p className={'mb-0 text-muted'}>Total</p>
                            <p className={'mb-0'}><strong>{`${refund.currency} ${refund.total}`}</strong></p>
                          </div>
                          <div className="col">
                            <p className={'mb-0 text-muted'}>Method</p>
                            <p className={'mb-0'}><strong>{refund.payment_method_display}</strong></p>
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-12 form-group">
                          <Textarea
                            name='reference_id'
                            label={'Refund Reference'}
                            hint={'Cheque Number or Transaction ID etc.'}
                            className={'form-control'}
                          />
                        </div>
                      </div>
                      {paymentTerminalControls(formikProps)}
                    </Form>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <Alerts
                  status={resultMsg.status}
                  success={resultMsg.success}
                  error={resultMsg.error}
                />
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <button
                      type="button"
                      onClick={() => {
                        confirmAlert({
                          title: 'Confirm Refund',
                          message: 'The action cannot be reversed.',
                          buttons: [
                            {
                              className: 'btn-secondary',
                              label: 'Exit',
                            },
                            {
                              className: 'btn-success',
                              label: 'Confirm Refund',
                              onClick: formikProps.handleSubmit,
                            },
                          ],
                        });
                      }}
                      className={'btn btn-success btn-lg btn-block'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faCheck} size={'sm'}/> Confirm Refund
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      advanceSettings: false,
    };
  }
}

/* Export Component ==================================================================== */
export default RefundAction;
