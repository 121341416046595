/**
 *
 * Booking Room Analytics Overview
 */
import _ from 'lodash';
import Moment from 'moment';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import React, { Component } from 'react';
import PageHeader from '../../../components/dashboard/PageHeader';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
// Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import {AnalyticConstants, Constants} from '../../../constants';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class BookingRoomAnalyticsOverview extends Component {
  static componentName = 'BookingRoomAnalyticsOverview';

  static propTypes = {
    pos: PropTypes.string,
    endDate: PropTypes.object,
    startDate: PropTypes.object
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {startDate, endDate, dateType} = this.props;
    this.setState({loading: true});
    const queryParams = {
      'date_type': dateType,
      'report_type': 'booking_room_overview',
      'start_date': (startDate ? startDate : Moment()).format('YYYY-MM-DD'),
      'end_date': endDate ? endDate.format('YYYY-MM-DD') : null
    };
    AppAPI.bookingapi.get(`booking-analytics/?${queryString.stringify(queryParams)}`)
      .then((res) => {
        this.setState({loading: false, response: res});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({loading: false, error: error});
      });
  };

  render = () => {
    const {loading, error, response} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    // Initialize with empty array for order data set
    const bookingDataSet = {};
    const bookingValueSet = {};
    Object.keys(AnalyticConstants.bookingAnalytics).forEach((data)=>{
      bookingDataSet[data] = {};
      bookingValueSet[data] = 0;
    });



    // Containers for order status data & order source data
    const bookingData = {
      bookingStatusData: _.cloneDeep(bookingDataSet),
      bookingSourceData: {},
      bookingConfirmStatusData: {
        confirm:_.cloneDeep(bookingValueSet),
        provisional:_.cloneDeep(bookingValueSet),
      }
    };

    Object.values(Constants.BOOKING_SOURCES_INPUT).forEach((data)=>{
      bookingData.bookingSourceData[data.value] = _.cloneDeep(bookingDataSet);
    });

    if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
      const responseData = response.data;
      if (!_.isEmpty(responseData.booking_room_status_source_data)) {
        responseData.booking_room_status_source_data.forEach((data) => {
          Object.entries(AnalyticConstants.bookingAnalytics).forEach(([key, value])=>{
            if (data[value]) {
              AppUtil.insertOrAddObject(bookingData.bookingStatusData[key], data.booking_status, data[value]);
            }
          });
        });
      }
    }

    const cancelNoShowBookingsCount =( bookingData.bookingStatusData.bookingCount[Constants.BOOKING.NOSHOW] || 0) + (bookingData.bookingStatusData.bookingCount[Constants.BOOKING.CANCELED] || 0);
    const activeBookingsCount = AppUtil.sumArray(Object.values(bookingData.bookingStatusData.bookingCount)) - cancelNoShowBookingsCount;

    const activeBookingsCountPercentage = AppUtil.calculatePercentage(
      activeBookingsCount,
      AppUtil.sumArray(Object.values(bookingData.bookingStatusData.bookingCount))
    );

    const cancelNoShowBookingsNights = (bookingData.bookingStatusData.bookingNights[Constants.BOOKING.NOSHOW] || 0 )+ (bookingData.bookingStatusData.bookingNights[Constants.BOOKING.CANCELED] || 0);
    const activeBookingsNights = AppUtil.sumArray(Object.values(bookingData.bookingStatusData.bookingNights)) - cancelNoShowBookingsNights;

    const completedBookingsPercentage = AppUtil.calculatePercentage(
      bookingData.bookingStatusData.bookingCount[Constants.BOOKING.COMPLETED],
      activeBookingsCount
    );

    return (
      <div className="screen-container">
        <Helmet>
          <title>Booking Room Analytics Overview</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={'Booking Room Overview'}
          description={'Total booking rooms and its related details in the selected range.'}
        />

        <div className={'row'}>
          <div className={'col-12 col-lg-6 mb-3'}>
            <div className={'card'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col'}>
                    <p className={'text-muted mb-1'}>Confirmed Booking Room(s)</p>
                    <h3 className={'card-title mb-0 pb-0'}>
                      {AppUtil.sumArray(Object.values(bookingData.bookingStatusData.bookingCount))}
                    </h3>
                    <p className={'text-muted mb-1'}>
                      {AppUtil.sumArray(Object.values(bookingData.bookingStatusData.bookingNights))} room night(s).
                    </p>
                  </div>
                </div>
                <hr/>
                <div className={'row'}>
                  <div className={'col '}>
                    <p className={'text-muted mb-1'}>Active</p>
                    <h5 className={'card-title mb-0 pb-0 green-cl'}>
                      {activeBookingsCount}
                    </h5>
                  </div>
                  <div className={'col'}>
                    <p className={'text-muted mb-1'}>Cancel / No Show</p>
                    <h5 className={'card-title mb-0 pb-0 grey-cl'}>
                      {cancelNoShowBookingsCount}
                    </h5>
                  </div>
                </div>
                <div className="progress mt-3 grey-bg">
                  <div
                    className="progress-bar green-bg"
                    style={{width: `${activeBookingsCountPercentage}%`}}
                    aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"/>
                </div>
                <div className={'row'}>
                  <div className={'col-8'}>
                    <p className={'text-muted mb-0 mt-1 small'}>
                      Night(s) - Active : {activeBookingsNights} |
                      Cancel / NS : {cancelNoShowBookingsNights}
                    </p>
                  </div>
                  <div className={'text-right col'}>
                    <p className={'text-muted mb-0 mt-1 small'}>{activeBookingsCountPercentage}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={'col-12 col-lg-6 mb-3'}>
            <div className={'card'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col '}>
                    <p className={'text-muted mb-1'}>Active Booking Room(s)</p>
                    <h3 className={'card-title mb-0 pb-0 green-cl'}>
                      {activeBookingsCount}
                    </h3>
                    <p className={'text-muted mb-1'}>
                      {activeBookingsNights} room night(s)
                    </p>
                  </div>
                </div>
                <hr/>
                <div className={'row'}>
                  <div className={'col'}>
                    <p className={'text-muted mb-1'}>Completed</p>
                    <h5 className={'card-title mb-0 pb-0 green-cl'}>
                      {bookingData.bookingStatusData.bookingCount[Constants.BOOKING.COMPLETED] || 0}
                    </h5>
                  </div>
                  <div className={'col '}>
                    <p className={'text-muted mb-1'}>Active / Up Coming</p>
                    <h5 className={'card-title mb-0 pb-0 blue-cl'}>
                      {activeBookingsCount - (bookingData.bookingStatusData.bookingCount[Constants.BOOKING.COMPLETED] || 0)}
                    </h5>
                  </div>
                </div>
                <div className="progress mt-3 blue-bg">
                  <div
                    className="progress-bar green-bg"
                    style={{width: `${completedBookingsPercentage}%`}}
                    aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"/>
                </div>
                <div className={'row'}>
                  <div className={'col-8'}>
                    <p className={'text-muted mb-0 mt-1 small'}>
                      Night(s) - Completed : {activeBookingsNights} |
                      Active / UP : {activeBookingsCount - (bookingData.bookingStatusData.bookingCount[Constants.BOOKING.COMPLETED] || 0)}
                    </p>
                  </div>
                  <div className={'text-right col'}>
                    <p className={'text-muted mb-0 mt-1 small'}>{completedBookingsPercentage}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className={'text-muted small'}>Active Booking include - Upcoming + Active + Completed</p>

        <div className={'row mb-2'}>
          <div className={'col-12 col-lg-6 mb-3'}>
            <table className={'table table-bordered'}>
              <thead>
                <tr>
                  <th className={'data-table-cell-md'}>Status</th>
                  <th className={'data-table-cell-md'}>Count</th>
                  <th className={'data-table-cell-md'}>Night</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(Constants.BOOKING_STATUS_INPUT).map((data, i) => (
                  <tr key={i}>
                    <td>{data.label}</td>
                    <td className={'text-center'}>
                      {( bookingData.bookingStatusData.bookingCount[parseInt(data.value)] || 0)}
                    </td>
                    <td className={'text-center'}>
                      {( bookingData.bookingStatusData.bookingNights[parseInt(data.value)] || 0)}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td><strong>Total</strong></td>
                  <td className={'text-center'}>
                    <strong>{AppUtil.sumArray(Object.values(bookingData.bookingStatusData.bookingCount))}</strong>
                  </td>
                  <td className={'text-center'}>
                    <strong>{AppUtil.sumArray(Object.values(bookingData.bookingStatusData.bookingNights))}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      showPAOverview: false,
      showPAOrderData: {},
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingRoomAnalyticsOverview);
