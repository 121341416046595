/**
 * Manage Membership
 *  manages membership add / edit
 */
import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import * as Yup from 'yup';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import {Constants, ErrorMessages, Strings} from '../../../constants';
// Actions
import * as PropertyActions from '../../../redux/property/actions';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PageHeader from '../../../components/dashboard/PageHeader';
import {Form, Input, Select, SubmitBtn} from 'react-formik-ui';
import {Formik} from 'formik';
import {Alerts} from '../../../components/ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faSave} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  membership: state.property.membership,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setMembership: PropertyActions.setMembership,
  updateMembership: PropertyActions.updateMembership,
  unSetMembership: PropertyActions.unSetMembership,

};

/* Component ==================================================================== */
class PropertyMembershipManage extends Component {
  static componentName = 'PropertyMembershipManage';

  static propTypes = {
    match: PropTypes.object,
  };
  componentDidMount = () => {
    this.fetchInitData();
  };

  addMembership = (credentials) => {
    const {selectedPermissions} = this.state;
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.membership
          .post('', {
            type: credentials.type,
            email: credentials.email,
            permission_set: selectedPermissions,
          })
          .then((res) => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.props.history.replace(`/settings/property/accounts/${res.id}`);
              }, 500);
            });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  saveMembership = (credentials) => {
    const {selectedPermissions} = this.state;
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.membership
          .patch(this.props.membership.id + '/', {
            type: credentials.type,
            permission_set: selectedPermissions,
          })
          .then(() => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.props.history.goBack();
              }, 500);
            });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  fetchInitData = () => {
    if (this.props.match.params.membershipId) {
      this.setState({loading: true});
      this.props
        .setMembership(this.props.match.params.membershipId)
        .then((res) => {
          this.setState({
            loading: false,
            selectedPermissions: !_.isEmpty(res.permission_data) ? res.permission_data : []
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({loading: false, resultMsg: {error}});
        });
    } else {
      this.props.unSetMembership();
      this.setState({loading: false});
    }
  };

  selectPermission = (data) => {
    let {selectedPermissions} = this.state;
    selectedPermissions = AppUtil.insertOrRemoveArray(selectedPermissions, data.key);
    this.setState({selectedPermissions});
  };

  render = () => {

    const {loading,  error, resultMsg, selectedPermissions} = this.state;
    const {membership} = this.props;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    let defaultFormValidation = Yup.object().shape({
      email: Yup.string().email('Invalid email').required('Required'),
      type: Yup.string().required('Required'),
    });

    let initialValues = {
      email: '',
      type: 0,
    };

    if (!_.isEmpty(membership)) {
      initialValues = {
        email: membership.user_profile.email,
        type: membership.type,
      };

      defaultFormValidation = Yup.object().shape({
        type: Yup.string().required('Required'),
      });
    }

    return (
      <div className="membership-manage screen-container ">
        <Helmet>
          <title>Membership Manage</title>
        </Helmet>

        <PageHeader
          subHeader={true} history={this.props.history}
          title={_.isEmpty(membership) ? 'Add User' : 'Edit User'}/>


        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <Formik
              onSubmit={(values) =>
                _.isEmpty(membership)
                  ? this.addMembership(values)
                  : this.saveMembership(values)
              }
              validationSchema={defaultFormValidation}
              initialValues={initialValues}>
              {() => (
                <Form>
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 mt-2">
                      <div className="form-row">
                        <div className="col-lg-12 form-group">
                          <Input
                            required
                            disabled={!_.isEmpty(membership)}
                            type={'email'}
                            name='email'
                            label={'E-Mail Address'}
                            className={'form-control rounded-left-0'}
                          />
                        </div>
                        <div className="col-lg-12 form-group">
                          <Select
                            className={'form-control rounded-right-0'}
                            name='type'
                            label={'Account Type'}
                            placeholder='Select an Option'
                            options={Constants.MEMBERSHIP_TYPE_INPUT}
                            hint={Strings.membershipUserType}
                          />
                        </div>
                      </div>

                      <Alerts
                        status={resultMsg.status}
                        success={resultMsg.success}
                        error={resultMsg.error}
                      />
                      <SubmitBtn
                        disabled={_.isEmpty(membership) && !selectedPermissions.length}
                        className={'btn btn-success btn-block btn-lg mt-2'}>
                        <FontAwesomeIcon
                          className={'white-cl mr-2'} icon={_.isEmpty(membership) ? faPlus : faSave}
                          size={'sm'}/>
                        {_.isEmpty(membership) ? 'Add User' : 'Save'}
                      </SubmitBtn>

                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 mt-2">
                      <h5>{Strings.membershipPermissionTitle}</h5>
                      {Strings.membershipPermissionManageDescription.map((data, i) => (
                        <p className={'mb-0 small'} key={i}>{data}</p>
                      ))}

                      <ul className="list-group my-3">
                        {/* eslint-disable-next-line array-callback-return */}
                        {Constants.MEMBERSHIP_PERMISSIONS.map((data, i) => {
                          return (
                            <li className="list-group-item" key={i}>
                              <div className={'custom-control custom-switch'}>
                                <input
                                  id={i}
                                  type="checkbox"
                                  className={'custom-control-input mr-3'}
                                  checked={selectedPermissions.includes(data.key)}
                                  onChange={() => this.selectPermission(data)}
                                />
                                <label className="custom-control-label" htmlFor={i}>
                                  {data.name} <br/>
                                  <p className={'small mb-0'}>{data.description}</p>
                                </label>
                              </div>
                            </li>
                          );
                        })
                        }
                      </ul>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      selectedPermissions: [],
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(PropertyMembershipManage);
