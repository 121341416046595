/**
 * Room Type Reducer
 *
 */

// Set initial state
const initialState = {
  roomType: {},
  roomTypeList: {},
  roomTypePackage: {},
  roomTypeItem: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
  case 'SET_ROOM_TYPE':
    let roomType = [];
    if (action.data && typeof action.data === 'object') {
      roomType = action.data;
    }

    return {
      ...state,
      roomType,
    };
  case 'SET_ROOM_TYPE_PACKAGE':
    let roomTypePackage = [];
    if (action.data && typeof action.data === 'object') {
      roomTypePackage = action.data;
    }

    return {
      ...state,
      roomTypePackage,
    };
  case 'SET_ROOM_TYPE_ITEM':
    let roomTypeItem = [];
    if (action.data && typeof action.data === 'object') {
      roomTypeItem = action.data;
    }

    return {
      ...state,
      roomTypeItem,
    };
  case 'SET_ROOM_TYPE_LIST':
    let roomTypeList = [];
    if (action.data && typeof action.data === 'object') {
      roomTypeList = action.data;
    }

    return {
      ...state,
      roomTypeList,
    };
  default:
    return state;
  }
};
