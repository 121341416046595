/**
 * Manage Escalated Permission functions
 *  manages order updates ( Complimentary, No Charge, Discount )
 */
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import React from 'react';
import _ from 'lodash';
// Consts and Libs
import AppUtil from '../../../lib/util';
import {Strings} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationTriangle,
  faInfo, faLongArrowAltRight, faMinusCircle,
  faPlusCircle,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import POSOrderItemListView from '../components/POSOrderItemListView';
import {confirmAlert} from '../../../components/general/ConfirmAlert';


/* Component ==================================================================== */

class POSOrderSplitBill extends React.Component {
  static componentName = 'POSOrderSplitBill';

  static propTypes = {
    match: PropTypes.object,
    posOrder: PropTypes.object,
    posDetails: PropTypes.object,
    updateResponse: PropTypes.func,
    resultMsg: PropTypes.object.isRequired,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      const {posOrder} = this.props;
      const posOrderItems = [];
      posOrder.order_items.forEach((data)=>{
        if (data.status) {
          data.splitOrder = null;
          data.selected = null;
          posOrderItems.push(data);
        }
      });

      this.setState({
        posOrderItems,
        loading: true,
        selectedItems: [],
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  updateSplit = (sign) => {
    const {splitOrders} = this.state;

    if (sign === '+') {
      const val = splitOrders.length + 1;
      splitOrders.push(val);
    }

    if (sign === '-' && splitOrders.length > 2) {
      splitOrders.pop();
    }

    this.setState({splitOrders});
  }

  selectOrderItem = (item) => {
    let {selectedItems} = this.state;
    selectedItems = AppUtil.insertOrRemoveArray(selectedItems, item.id);
    this.setState({selectedItems});
  }

  updateSplitOrderItems = (splitId, sign) => {
    let {selectedItems, posOrderItems, splitOrders} = this.state;
    let posOrderItemsModified = [];
    if (splitOrders.includes(splitId) && selectedItems.length){
      posOrderItems.forEach((data) => {
        if (selectedItems.includes(data.id)) {
          const splitOrder = data.splitOrder;

          if (sign === '+' && data.splitOrder === null) { data.splitOrder = splitId; }

          if (sign === '-' && data.splitOrder === splitId) { data.splitOrder = null; }

          if (splitOrder !== data.splitOrder){
            selectedItems = AppUtil.insertOrRemoveArray(selectedItems, data.id);
          }
        }
        posOrderItemsModified.push(data);
      });
      this.setState({posOrderItems: posOrderItemsModified, selectedItems: selectedItems});
    }
  }

  generateOrder = () => {
    const {posOrderItems} = this.state;
    const unAssignedOrderItems = posOrderItems.filter(item => (item.splitOrder === null));
    const assignedOrderItems = posOrderItems.filter(item => (item.splitOrder !== null));
    if (unAssignedOrderItems.length === 0 && this.props.updateResponse) {
      const orderData = {};
      assignedOrderItems.forEach((data)=>{
        if (orderData[data.splitOrder]) {
          orderData[data.splitOrder].push(data.id);
        } else {
          orderData[data.splitOrder] = [data.id];
        }
      });
      this.props.updateResponse({lock_order: true, split_oder: Object.values(orderData) }, null, true, 'details');

    }
  }

  render = () => {

    const {splitOrders, posOrderItems, selectedItems} = this.state;
    const {show, posOrder, resultMsg} = this.props;

    if (!show) {
      return <React.Fragment/>;
    }

    const unAssignedOrderItems = posOrderItems.filter(item => (item.splitOrder === null));
    const assignedOrderItems = posOrderItems.filter(item => (item.splitOrder !== null));

    return (
      <Modal
        {...this.props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Order Split
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <React.Fragment>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                <table className="table border table-small">
                  <thead>
                    <tr className={'bg-dark text-light'}>
                      <th className={'text-center'} colSpan={3}>Order Items</th>
                    </tr>
                  </thead>
                  <tbody className={'pos-items-list'}>
                    {!_.isEmpty(posOrderItems) ?
                      <React.Fragment>
                        {!_.isEmpty(unAssignedOrderItems) ?
                          <React.Fragment>
                            {unAssignedOrderItems.map((data, i) => (
                              <POSOrderItemListView
                                key={i}
                                rowClassName={`${selectedItems.includes(data.id) ? 'table-warning' : ''} a-class`}
                                lock={posOrder.lock}
                                currency={posOrder.currency}
                                data={data}
                                disabled={!data.status}
                                action={() => this.selectOrderItem(data)}
                              />
                            ))}
                            <tr className={'table-danger'}>
                              <td className={'text-right'}><strong>Total</strong></td>
                              <td/>
                              <td className={'text-right'}><strong>
                                {`${posOrder.currency} ${(unAssignedOrderItems.reduce((a, b) => (parseFloat(a) + parseFloat(b.total)), 0)).toFixed(2)}`}
                              </strong></td>
                            </tr>
                          </React.Fragment>
                          :
                          <tr>
                            <td colSpan={3}>
                              <div className="text-center py-5">
                                <FontAwesomeIcon icon={faCheckCircle} size={'2x'} className={'mt-3 green-cl'}/>
                                <p className={'text-secondary small'}>Order split completed</p>
                              </div>
                            </td>
                          </tr>
                        }
                      </React.Fragment>
                      :
                      <tr>
                        <td colSpan={3}>
                          <div className="text-center py-5">
                            <FontAwesomeIcon icon={faExclamationTriangle} size={'2x'} className={'mt-3 red-cl'}/>
                            <p className={'text-secondary small'}>No items in this pos order.</p>
                          </div>
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
                <p className={'text-muted small mb-1'}>{Strings.posSplitBillWarning}</p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                {splitOrders.map((data, i)=>{
                  const billOrderItems = posOrderItems.filter(item => (item.splitOrder === data));
                  return (
                    <div key={i}>
                      <table className={'table table-small border'}>
                        <thead>
                          <tr className={'bg-dark text-light'}>
                            <th className={'text-left'} colSpan={2}>Bill #{data}</th>
                            <th className={'text-right'}>
                              <div className="btn-group btn-group-sm" role="group">
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  disabled={selectedItems.length < 1}
                                  onClick={()=>this.updateSplitOrderItems(data, '+')}
                                >
                                  <FontAwesomeIcon icon={faPlusCircle} className={'mr-2'}/>
                                  Insert
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-warning"
                                  disabled={assignedOrderItems.length < 1 || billOrderItems.length < 1}
                                  onClick={()=>this.updateSplitOrderItems(data, '-')}
                                >
                                  <FontAwesomeIcon icon={faMinusCircle} className={'mr-2'}/>
                                  Remove
                                </button>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {!_.isEmpty(billOrderItems) ?
                            <React.Fragment>
                              {billOrderItems.map((itemData, k)=>(
                                <POSOrderItemListView
                                  key={k}
                                  rowClassName={`${selectedItems.includes(itemData.id) ? 'table-primary' : ''} a-class`}
                                  lock={posOrder.lock}
                                  currency={posOrder.currency}
                                  data={itemData}
                                  action={() => this.selectOrderItem(itemData)}
                                />
                              ))}
                              <tr className={'table-success'}>
                                <td className={'text-right'}><strong>Total</strong></td>
                                <td/>
                                <td className={'text-right'}><strong>
                                  {`${posOrder.currency} ${(billOrderItems.reduce((a, b) => (parseFloat(a) + parseFloat(b.total)), 0)).toFixed(2)}`}
                                </strong></td>
                              </tr>
                            </React.Fragment>
                            :
                            <tr>
                              <td colSpan={3}>
                                <div className="text-center py-2">
                                  <FontAwesomeIcon icon={faInfo} size={'2x'} className={'mt-3 red-cl'}/>
                                  <p className={'text-secondary small'}>No items the bill</p>
                                </div>
                              </td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  );
                })}
                <div className={'text-center'}>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={()=>this.updateSplit('+')}
                    disabled={unAssignedOrderItems.length < 1}
                  >
                    Add Split #{splitOrders.length + 1}
                  </button>
                </div>
              </div>
            </div>
          </React.Fragment>
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          <div className={'px-3'}>
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
          </div>
          <div className={'row'}>
            <div className={'col'}>
              <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
              </button>
            </div>
            <div className={'col'}>
              <button
                onClick={()=>confirmAlert({
                  title: 'Confirm Order',
                  message: Strings.posConfirmOrder,
                  buttons: [
                    {
                      className: 'btn-success',
                      label: 'Confirm',
                      onClick: () => this.generateOrder(),
                    },
                    {
                      className: 'btn-secondary',
                      label: 'Exit',
                    },
                  ],
                })}
                disabled={posOrderItems.length < 1 || unAssignedOrderItems.length > 0}
                type="submit"  className={'btn btn-success btn-lg btn-block'}
              >
                Split Bills & Close Tab <FontAwesomeIcon className={'white-cl ml-2'} icon={faLongArrowAltRight} size={'sm'}/>
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: false,
      splitOrders: [1, 2],
      selectedItems: [],
      posOrderItems: [],
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default POSOrderSplitBill;
