/**
 * Order Item Edit
 *  Order Item Edit
 *
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Actions
import {Constants} from '../../../constants';
import {Input, Select, Textarea} from 'react-formik-ui';
import AppUtil from '../../../lib/util';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleDown} from '@fortawesome/free-solid-svg-icons';


/* Component ==================================================================== */
class OrderItemEdit extends Component {
  static componentName = 'OrderItemEdit';

  static propTypes = {
    formikProps: PropTypes.object.isRequired,
    orderItem: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
    subQuantity: PropTypes.bool,
    selectTax: PropTypes.func,
    title: PropTypes.string,
  };

  static defaultProps = {
    title: '',
    subQuantity: true
  }

  render = () => {
    const {orderItem, order, selectedTaxClasses, tax_class, title, formikProps, subQuantity} = this.props;
    return (
      <React.Fragment>
        <div className="mt-2 border p-2">
          <div className="form-row">
            <div className="col-lg-12 col-md-12 form-group">
              <Input
                required
                name='description'
                label={'Description'}
                className={'form-control'}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-3 form-group">
              <Input
                required
                type={'number'}
                name='quantity'
                label={'Quantity'}
                className={'form-control'}
              />
            </div>
            <div className="col-2 form-group">
              <Select
                required
                name='quantity_type'
                label={'Type'}
                className={'form-control'}
                options={title === 'Expense' ? Constants.ORDER_QUANTITY_TYPE_EXPENSE : Constants.ORDER_QUANTITY_TYPE}
              />
            </div>
            <div className="col-3 form-group">
              <Input
                required
                type={'number'}
                name='price'
                label={'Price (' + order.currency + ')'}
                className={'form-control'}
              />
            </div>
            <div className="col-4 form-group text-right">
              <p className={''}>Total</p>
              <p className={'small'}>
                <strong>{order.currency + ' ' + AppUtil.formatPrice(formikProps.values.price * formikProps.values.quantity * formikProps.values.sub_quantity)}</strong>
              </p>
            </div>
          </div>

          {subQuantity &&
          <div className="form-row">
            <div className="col-3 form-group">
              <Input
                required
                type={'number'}
                name='sub_quantity'
                label={'Sub Quantity'}
                className={'form-control'}
              />
            </div>
            <div className="col-2 form-group">
              <Select
                required
                name='sub_quantity_type'
                label={'Type'}
                className={'form-control'}
                options={title === 'Expense' ? Constants.ORDER_QUANTITY_TYPE_EXPENSE : Constants.ORDER_QUANTITY_TYPE}
              />
            </div>
          </div>
          }
          <div className="form-row">
            <div className="col-12 form-group">
              <Textarea
                name={'additional_description'}
                label={'Additional Information'}
                className={'form-control'}
              />
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12'}>
              <div
                className={'border p-2 text-center anchor'} data-toggle="collapse" href="#taxitems"
                role="button" aria-expanded="false" aria-controls="taxitems"
                onClick={() => this.props.advanceEdit(true)}
              >
                <h6 className={'mb-0'}>
                  <FontAwesomeIcon
                    icon={faChevronCircleDown} size={'sm'}
                    className={'mx-2 green-cl float-left mt-1'}/>
                  Tax Information
                  {selectedTaxClasses.length > 0 &&
                  <span className={'ml-3 badge badge-success'}>{selectedTaxClasses.length} Tax applied</span>
                  }
                  <FontAwesomeIcon
                    icon={faChevronCircleDown} size={'sm'}
                    className={'mx-2 green-cl float-right mt-1'}/>
                </h6>
                {orderItem && orderItem.tax_data && <p
                  className={'small text-muted mb-0'}>{orderItem.tax_data.description}</p>}
              </div>
              <div className={'py-2 border collapse p-2'} id={'taxitems'}>
                <ul className="list-group mt-3 list-inline">
                  {
                    tax_class.map((data, i) => (
                      <li
                        className="list-group-item list-inline-item a-class"
                        key={i}
                        onClick={() => this.props.selectTax(data.id)}
                      >
                        <input
                          className={'mr-2'} type="checkbox"
                          checked={selectedTaxClasses.includes(data.id)}/>
                        {data.name} : {data.description}
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
}

/* Export Component ==================================================================== */
export default (OrderItemEdit);

