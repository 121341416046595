/**
 * Manage Agent
 *  manages agent add and edit
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import * as Yup from 'yup';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {ErrorMessages, Strings} from '../../../constants';
// Components
import {Formik} from 'formik';
import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {Form, Input, SubmitBtn} from 'react-formik-ui';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUserSecret} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as UserActions from '../../../redux/user/actions';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  user: state.user.user,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setUser: UserActions.setUser
};

/* Component ==================================================================== */

class ProfilePasswordUpdate extends Component {
  static componentName = 'ProfilePasswordUpdate';

  updatePassword = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.auth.post('password/change/',
          {
            'old_password': credentials.old_password,
            'new_password1': credentials.new_password1,
            'new_password2': credentials.new_password2,
          })
          .then(() => {
            this.props.setUser();
            this.setState({resultMsg: {success: 'Password Updated'}},
              () => {
                setTimeout(() => {
                  this.setState({resultMsg: {success: ''}});
                  this.props.history.goBack();
                }, 500);
              });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  render = () => {

    const {loading, initialValues, error, resultMsg} = this.state;
    const {user} = this.props;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    const defaultFormValidation = Yup.object().shape({
      old_password: Yup.string().min(2, 'Too Short!').required('Required'),
      new_password1: Yup.string().min(1, 'Too Short!').required('Required'),
      new_password2: Yup.string().min(1, 'Too Short!').required('Required'),
    });

    return (
      <div className="agent-manage screen-container ">
        <Helmet>
          <title>Update Password</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'Password'}
          description={'Change login password.'}
        />

        <Formik
          onSubmit={(values) => this.updatePassword(values)}
          validationSchema={defaultFormValidation}
          initialValues={initialValues}>
          {() => (
            <Form>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  {user.force_reset_password &&
                    <Alerts
                      status={Strings.changePasswordForce}
                    />
                  }
                  <div className={'border p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Change Password
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 form-group">
                        <Input
                          required
                          type={'password'}
                          name='old_password'
                          label={'Current Password'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 form-group">
                        <Input
                          required
                          type={'password'}
                          name='new_password1'
                          label={'New Password'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 form-group">
                        <Input
                          required
                          type={'password'}
                          name='new_password2'
                          label={'Repeat New Password'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-12 mt-2">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn className={'btn btn-success btn-block btn-lg mt-2'}>
                    <FontAwesomeIcon className={'white-cl mr-2'} icon={faUserSecret} size={'sm'}/> Update Password
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>

    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      initialValues: {
        'old_password': '',
        'new_password1': '',
        'new_password2': '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePasswordUpdate);
