/**
 * Manage Escalated Permission functions
 *  manages order updates ( Complimentary, No Charge, Discount )
 */
import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import * as Yup from 'yup';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {ErrorMessages} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
// Forms
import {Form, Formik} from 'formik';
import OrderDiscountEdit from '../../order/components/OrderDiscountEdit';
import OrderNoChargeEdit from '../../order/components/OrderNoChargeEdit';
import OrderServiceChargeEdit from '../../order/components/OrderServiceChargeEdit';
import OrderComplimentaryEdit from '../../order/components/OrderComplimentaryEdit';


/* Component ==================================================================== */

class OrderEscalatedPermissionEdit extends React.Component {
  static componentName = 'OrderEscalatedPermissionEdit';

  static propTypes = {
    match: PropTypes.object,
    posOrder: PropTypes.object,
    posDetails: PropTypes.object,
    updateResponse: PropTypes.func,
    editType: PropTypes.string,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        loading: false,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  updateOrder = (credentials = null) => {
    const {editType, posDetails} = this.props;

    let payload = {};
    if (credentials) {
      switch (editType) {
      case 'nocharge':
        payload.no_charge_type_order = credentials.no_charge_type;
        payload.no_charge_description = credentials.no_charge_description;
        payload.no_charge_order = true;
        break;
      case 'service-charge':
        payload.order_service_charge_value = credentials.order_service_charge_value;
        break;
      case 'discount':
        payload.order_discount = credentials.discount;
        payload.order_discount_type = credentials.discount_type;
        payload.order_discount_description = credentials.discount_description;
        if (credentials.percentage === 'true') {
          payload.order_discount = parseFloat((credentials.discount * this.props.posOrder.absolute_total) / 100).toFixed(2);
        }
        break;
      case 'complimentary':
      default:
        payload.complimentary_type_order = credentials.complimentary_type;
        payload.complimentary_description = credentials.complimentary_description;
        payload.complimentary_order = true;
      }
    }

    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.posapi
          .patch(`pos-order-escalated/${this.props.posOrder.hash}/?pos=${posDetails.hash}`, payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                this.props.updateResponse(res, posDetails.hash);
                this.props.onHide();
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };


  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {show, editType, posOrder} = this.props;
    if (loading) return <Loading heightMatch={true}/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    if (!show) {
      return <React.Fragment/>;
    }

    let formValidation, formInputData;


    switch (editType) {
    case 'nocharge':
      formValidation = Yup.object().shape({
        no_charge_type: Yup.number().required(),
        no_charge_description: Yup.string().max(200, 'Too Long!').nullable(),
      });
      formInputData = (formikProps) => <OrderNoChargeEdit order={posOrder} formikProps={formikProps}/>;
      break;
    case 'service-charge':
      formValidation = Yup.object().shape({
        order_service_charge_value: Yup.number().required(),
      });
      formInputData = (formikProps) => <OrderServiceChargeEdit order={posOrder} formikProps={formikProps}/>;
      break;
    case 'discount':
      formValidation = Yup.object().shape({
        discount: Yup.number().required(),
        discount_type: Yup.number().required(),
        discount_description: Yup.string().max(200, 'Too Long!').nullable(),
      });
      formInputData = (formikProps) => <OrderDiscountEdit order={posOrder} formikProps={formikProps}/>;
      break;
    case 'complimentary':
    default:
      formValidation = Yup.object().shape({
        complimentary_type: Yup.number().required(),
        complimentary_description: Yup.string().max(200, 'Too Long!').nullable(),
      });
      formInputData = (formikProps) => <OrderComplimentaryEdit order={posOrder} formikProps={formikProps}/>;
      break;
    }

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Order ( Manager Permission )
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            complimentary_type: '0',
            complimentary_description: '',

            no_charge_type: '0',
            no_charge_description: '',

            percentage: 'false',
            discount: posOrder.order_discount,
            discount_type: posOrder.order_discount_type,
            discount_description: posOrder.order_discount_description,
            order_service_charge_value: posOrder.order_service_charge_value,
          }}
          validationSchema={formValidation}
          onSubmit={values => this.updateOrder(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <Form className={'form-group'}>
                      <div className="mt-2">
                        {formInputData(formikProps)}
                      </div>
                    </Form>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <button
                      type="submit" onClick={formikProps.handleSubmit} className={'btn btn-success btn-lg btn-block'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default OrderEscalatedPermissionEdit;
