/**
 * Manage PGateway Refund
 *  update pgateway with refund ref no
 */
import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';
import {Modal} from 'react-bootstrap';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {ErrorMessages} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import {Input} from 'react-formik-ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faCheckSquare, faSquare, faTimes} from '@fortawesome/free-solid-svg-icons';
import {confirmAlert} from '../../../components/general/ConfirmAlert';

/* Component ==================================================================== */

class PGatewayRefundUpdate extends React.Component {
  static componentName = 'PGatewayRefundUpdate';

  static propTypes = {
    property: PropTypes.object,
    pgateway: PropTypes.object,
    updateResponse: PropTypes.func,
  };

  addPayment = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});

      AppAPI.pgatewayapi.patch(`pgateway-refund-escalated/${this.props.pgateway.hash}/`, {
        gateway_refund_id: credentials.gateway_refund_id,
      })
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
              if (this.props.updateResponse) {
                this.props.updateResponse(res);
              }
              this.props.onHide();
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({loading: false, resultMsg: {error}});
        });
    }
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  render = () => {

    const {loading, error, resultMsg, confirmAttachment} = this.state;
    const {pgateway, show} = this.props;
    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    if (!show) return <React.Fragment/>;

    const formValidation = Yup.object().shape({
      gateway_refund_id: Yup.string().required('Gateway Refund ID is required'),
    });

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Refund Details
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            gateway_refund_id: '',
          }}
          validationSchema={formValidation}
          onSubmit={values => this.addPayment(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <p className={'mb-1 text-muted small'}>
                  Use this function to record a successful refund in renzo if the payment fails to updated
                  automatically.
                </p>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <Form className={'form-group mb-0'}>
                      <div className="form-row">
                        <div className="col form-group">
                          <Input
                            name="gateway_refund_id"
                            label={'Gateway Refund ID'}
                            className={'form-control'}
                            hint={`Refund ID from the gateway ${pgateway.payment_gateway_display}.`}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col form-group">
                          <p className={'mb-2'}>
                            <FontAwesomeIcon icon={confirmAttachment ? faCheckSquare : faSquare } className={'mr-2'} onClick={()=>this.setState({confirmAttachment: !confirmAttachment})}/>
                            I have validated the Payment ID {pgateway.ref_no} and total
                            {`${pgateway.currency} ${pgateway.total}`} in the payment gateway for the refund reference number.
                          </p>
                          <div className={'alert alert-info mb-0'}>
                            <p className={'mb-0'}>
                              On submission the system will validate the Reference number with the gateway for payment
                              status. Transaction will only be updated if the Payment ID {pgateway.invoice_no} and
                              total {`${pgateway.currency} ${pgateway.total}`} is matched.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <button
                      type="submit"
                      disabled={!confirmAttachment}
                      className={'btn btn-success btn-lg btn-block'}
                      onClick={() =>
                        confirmAlert({
                          title: 'Confirm',
                          message: 'If validation is successful the refund status will be updated and the action cannot be reverted.',
                          buttons: [
                            {
                              className: 'btn-secondary',
                              label: 'Exit',
                            },
                            {
                              className: 'btn-success',
                              label: 'Confirm Refund',
                              onClick: formikProps.handleSubmit,
                            },
                          ],
                        })
                      }
                    >
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faCheck} size={'sm'}/> Validate
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      confirmAttachment: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default PGatewayRefundUpdate;
