/**
 *  POS Membership Manage
 *
 */
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants, ErrorMessages, Strings} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import BadgeStatus from '../../../components/general/BadgeStatus';
import PageHeader from '../../../components/dashboard/PageHeader';
import {confirmAlert} from '../../../components/general/ConfirmAlert';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBan, faCheckCircle, faInfoCircle, faPen} from '@fortawesome/free-solid-svg-icons';

// Actions
import * as POSActions from '../../../redux/pos/actions';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pos: state.pos.pos,
  posMembership: state.pos.posMembership,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPOSMembership: POSActions.setPOSMembership,
};

/* Component ==================================================================== */

class POSMembershipManage extends Component {
  static componentName = 'POSMembershipManage';

  static propTypes = {
    match: PropTypes.object,
  };

  componentDidMount = () => {
    if (this.props.match.params.POSMembershipId) {
      this.props.setPOSMembership(this.props.match.params.POSMembershipId, this.props.match.params.POSId)
        .then(() => {
          this.fetchInitData();
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.fetchInitData();
    }
  };

  updateMembership = (payload) => {
    const {pos} = this.props;
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.posapi.patch(`pos-membership-settings/${this.props.match.params.POSMembershipId}/?pos=${pos.hash}`, payload)
        .then((res) => {
          this.props.setPOSMembership(res, this.props.match.params.POSId);
          this.setState(
            {resultMsg: {success: 'Success'}},
            () => setTimeout(() => this.setState({resultMsg: ''}), 500)
          );
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    });
  };

  fetchInitData = () => {
    this.setState({
      loading: false,
    });
  };

  render = () => {

    const {posMembership} = this.props;
    const {loading, error, resultMsg} = this.state;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    let userName = posMembership.user ? posMembership.user.display_name : posMembership.id;
    return (

      <div className="pos-table-list-manage screen-container ">
        <Helmet>
          <title>POS Membership Manage</title>
        </Helmet>
        <PageHeader
          subHeader={true} history={this.props.history}
          title={'Manager Membership'}
          description={`Account permissions settings for ${userName}`}
        />

        <Alerts
          status={resultMsg.status}
          success={resultMsg.success}
          error={resultMsg.error}
        />

        <div className={'row'}>
          <div className="col-lg-9 col-md-8 col-sm-12">
            <div className={'row'}>
              <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                <ul className="list-group mt-3">
                  <li className="list-group-item">
                    <strong>Name : </strong> {posMembership.user.display_name}
                  </li>
                  <li className="list-group-item">
                    <strong>Status : </strong>
                    <BadgeStatus
                      successText={'Active'} failText={'Suspended'} className={'ml-2'}
                      status={posMembership.access_status}
                    />
                  </li>
                </ul>
              </div>
              <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                <ul className="list-group mt-3">
                  <li className="list-group-item">
                    <strong>Email : </strong> {posMembership.user ? posMembership.user.email : 'N.A'}
                  </li>
                  <li className="list-group-item">
                    <strong>Type : </strong> {posMembership.membership.type_display}
                  </li>
                </ul>
              </div>
            </div>

            <hr/>

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h5>{Strings.membershipPermissionTitle}</h5>
                {Strings.membershipPermissionDescription.map((data, i) => (
                  <p className={'mb-0 small'} key={i}>{data}</p>
                ))}

                <table className={'mt-2 table border'}>
                  <thead>
                    <tr>
                      <td colSpan={2}>Permission</td>
                      <td>Description</td>
                    </tr>
                  </thead>
                  <tbody>
                    {!_.isEmpty(posMembership.permissions) ? (
                      <React.Fragment>
                        {/* eslint-disable-next-line array-callback-return */}
                        {Constants.POS_MEMBERSHIP_PERMISSIONS.map((data, i) => {
                          if (posMembership.permissions.includes(data.key)) {
                            return (
                              <tr key={i}>
                                <td><FontAwesomeIcon icon={faCheckCircle} size={'sm'} className={'green-cl mr-1'}/></td>
                                <td className={'w-30'}>
                                  <strong>
                                    {data.name}
                                  </strong>
                                </td>
                                <td>
                                  <p>{data.description}</p>
                                </td>
                              </tr>
                            );
                          }
                        })}
                      </React.Fragment>
                    ) :
                      <tr>
                        <td className={'text-center'} colSpan={4}>
                          <FontAwesomeIcon icon={faInfoCircle} size={'2x'} className={'mr-2 grey-cl'}/>
                          <p>{Strings.POSMembershipUserPermissionEmpty}</p>
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>

              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item">
                <Link to={'edit/'} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit
                </Link>
              </li>
              <li className="list-group-item">
                <button
                  type="button"
                  className={`btn btn-link m-0 p-0 ${posMembership.access_status ? 'red-cl' :  'green-cl'}`}
                  onClick={() => {
                    confirmAlert({
                      title: `${posMembership.access_status ? 'Suspend' :  'Activate'} User`,
                      message: posMembership.access_status ? Strings.POSMembershipUserSuspendDescription : Strings.POSMembershipUserActivateDescription,
                      buttons: [
                        {
                          className: 'btn-secondary',
                          label: 'Cancel',
                        },
                        {
                          className: posMembership.access_status ? 'btn-danger' : 'btn-success',
                          label: posMembership.access_status ? 'Suspend User' : 'Activate User',
                          onClick: () => this.updateMembership({access_status : !posMembership.access_status}),
                        },
                      ],
                    });
                  }}
                >
                  <FontAwesomeIcon icon={posMembership.access_status ? faBan : faCheckCircle} size={'sm'} className={'mr-2'}/>
                  {posMembership.access_status ? 'Suspend' :  'Activate'} User
                </button>
              </li>
            </ul>
          </div>
        </div>


      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      initialValues: {},
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(POSMembershipManage);
