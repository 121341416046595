import _ from 'lodash';

const ExtUTIL = {
  /**
   * Filters POS Items
   *
   */
  filterPOSItems: (items, filterList) => {
    const {
      selectCategory, searchText, selectFoodType, selectFoodContentType,
      selectDrinkContentType
    } = filterList;

    if (!_.isEmpty(items)) {
      if (selectCategory) {
        items = items.filter(item => (item.category_id === selectCategory));
      }

      if (searchText) {
        items = items.filter((el) => {
          let searchValue = el.name.toLowerCase(), searchCode = el.item_code ? el.item_code.toLowerCase() : '';
          return searchValue.indexOf(searchText.toLowerCase()) !== -1 || searchCode.indexOf(searchText.toLowerCase()) !== -1;
        });
      }

      if (selectFoodType) {
        items = items.filter(item => (item.food_type === selectFoodType));

        if (selectFoodContentType) {
          items = items.filter(item => (item.food_content === selectFoodContentType));
        }

        if (selectDrinkContentType) {
          items = items.filter(item => (item.drink_content === selectDrinkContentType));
        }
      }
    }

    return items;
  },
};

/* Export ==================================================================== */
export default ExtUTIL;