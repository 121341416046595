import React from 'react';


import eventRequired from '../../components/decorators/eventRequired';


import {Route, Switch} from 'react-router-dom';
import EventList from './EventList';
import EventDetails from './EventDetails';
import NotFound from '../NotFound';


const EventRoute = () => (
  <div>

    <div className="container">
      <Switch>
        <Route path="/event" exact component={EventList}/>
        <Route path="/event/:eventId/" component={eventRequired(EventDetails)}/>
        <Route exact component={NotFound}/>
      </Switch>
    </div>

  </div>
);

export default EventRoute;