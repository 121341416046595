/**
 * NotesListView
 *  View for Notes Listing
 *
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import React, {Component} from 'react';
// Consts and Libs
import AppUtil from '../../lib/util';
// Actions
import * as NotesActions from '../../redux/notes/actions';

// Components
import NotesManager from './NotesManager';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setNotes: NotesActions.setNotes,
};

/* Component ==================================================================== */
class NotesListView extends Component {
  static componentName = 'NotesListView';

  static propTypes = {
    data: PropTypes.object.isRequired,
    successAction: PropTypes.func,
  };

  render = () => {
    const data = this.props.data;
    return (
      <React.Fragment>
        <div
          role="presentation"
          onClick={() => this.setState({showNotesManager: true})}
          className="list-group-item list-group-item-action flex-column align-items-start a-class">
          <div>
            <div className="d-flex w-100 justify-content-between">
              <div>
                <h5 className="mb-1 text-break">{data.title}</h5>
                <p className="mb-0 small">
                  Source : {data.source_display}
                  {(!_.isEmpty(data.attributes) && data.attributes.created_user) && ` (${data.attributes.created_user})`}
                </p>
                <p className={'mb-0 small text-muted'}>
                  {AppUtil.formatDateTime(data.created)}
                </p>
              </div>
              <div className={'text-right float-right'}>
                <span className={`badge ${AppUtil.noteTypeColor(data.note_type)}`}>
                  {data.note_type_display}
                </span>
                {data.action_status &&
                <React.Fragment>
                  <br/>
                  <span className={'badge badge-success'}>
                    Completed
                  </span>
                </React.Fragment>
                }
              </div>
            </div>
          </div>
        </div>
        <NotesManager
          show={this.state.showNotesManager}
          onHide={() => this.setState({showNotesManager: false})}
          note={data}
          successAction={this.props.successAction}
        />
      </React.Fragment>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      showNotesManager: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(NotesListView);

