import React from 'react';
import { Route, Switch } from 'react-router-dom';


import DashboardView from './DashboardView';
import NotFound from '../NotFound';

const DashboardRoute = () => (
  <div>
    <div>
      <Switch>
        <Route path="/dashboard" exact component={DashboardView}/>
        <Route exact component={NotFound}/>
      </Switch>
    </div>
  </div>
);

export default DashboardRoute;