/**
 *
 * Property Distribution settings
 * inventory distribution management
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import {Strings} from '../../constants';
// Components
import {Alerts} from '../../components/ui';
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import PageHeader from '../../components/dashboard/PageHeader';
import {confirmAlert} from '../../components/general/ConfirmAlert';
import PropertyDistributionManage from './PropertyDistributionManage';
// Actions
import * as PropertyActions from '../../redux/property/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetPropertyDetails: PropertyActions.isSetPropertyDetails,
};

/* Component ==================================================================== */
class PropertyDistribution extends Component {
  static componentName = 'PropertyDistribution';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    this.props.isSetPropertyDetails(this.props.property.id)
      .then(() => {
        this.setState({
          loading: false,
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: true,
          resultMsg: {error},
        });
      });
  };

  updateProperty = (payload) => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.propertyapi.patch(`property-distribution/${this.props.property.id}/`, payload)
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}});
          this.props.isSetPropertyDetails(res).then(() => {
            setTimeout(() => {
              this.setState({
                resultMsg: {success: ''},
                shopPropertyDistributionManage: false,
              });
            }, 500);
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    });
  };

  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {property} = this.props;
    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="bank-details screen-container ">
        <Helmet>
          <title>Policy Distribution Settings</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'Distribution'}
          description={'Distribution configs for property inventory.'}
        />
        <div className={'row'}>
          <div className="col-lg-12 col-md-12 col-sm-12 mb-5">
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />

            <table className={'table border table-bordered table'}>
              <thead className={'text-center'}>
                <tr>
                  <th colSpan={3} className={'text-center'}>Channel Overview</th>
                  <th rowSpan={2}>
                    Distribution <br/> Length <br/>
                    <div className={'btn btn-link p-0 m-0'} onClick={()=>this.setState({shopPropertyDistributionManage: true})}>(Edit)</div>
                  </th>
                  <th colSpan={3}>
                    Date Restrictions
                    <div className={'btn btn-link p-0 m-0 ml-2'} onClick={()=>this.setState({shopPropertyDistributionManage: true})}>(Edit)</div>
                  </th>
                </tr>
                <tr>
                  <th>Channel</th>
                  <th>Connection</th>
                  <th>Distribution</th>
                  <th>Restriction</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Internal Engine</td>
                  <td>
                    <span className={'badge ' + (property.internal_engine_ok ? 'badge-success' : 'badge-secondary')}>
                      {property.internal_engine_ok ? 'Connected' : 'Not Connected'}
                    </span>
                  </td>
                  <td>
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox" className="custom-control-input" id="allow_internal_engine_distribution"
                        checked={property.allow_internal_engine_distribution}
                        onClick={() => {
                          confirmAlert({
                            title: (property.allow_internal_engine_distribution ? 'Disable' : ' Enabled') + ' Distribution',
                            message: property.allow_internal_engine_distribution ? Strings.internalEngineDistributionDisable : Strings.internalEngineDistributionEnable,
                            buttons: [
                              {
                                className: (property.allow_internal_engine_distribution ? 'btn-danger' : ' btn-success'),
                                label: 'Yes',
                                onClick: () => this.updateProperty({allow_internal_engine_distribution: !property.allow_internal_engine_distribution}),
                              },
                              {
                                className: 'btn-secondary',
                                label: 'No',
                              },
                            ],
                          });
                        }}
                      />
                      <label className="custom-control-label small pt-1 text-muted" htmlFor="allow_internal_engine_distribution">
                        {property.allow_internal_engine_distribution ? 'Distributing' : ' Not Distributing'}
                      </label>
                    </div>
                  </td>
                  <td>{property.internal_engine_advance_booking_days} day(s)</td>
                  <td>
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox" className="custom-control-input" id="internal_engine_distribution_date_range"
                        checked={property.allow_internal_engine_distribution_date_range}
                        onClick={() => {
                          confirmAlert({
                            title: (property.allow_internal_engine_distribution_date_range ? 'Disable' : ' Enabled') + ' Distribution',
                            message: property.allow_internal_engine_distribution_date_range ? Strings.internalEngineDateDistributionDisable : Strings.internalEngineDateDistributionEnable,
                            buttons: [
                              {
                                className: (property.allow_internal_engine_distribution_date_range ? 'btn-danger' : ' btn-success'),
                                label: 'Yes',
                                onClick: () => this.updateProperty({allow_internal_engine_distribution_date_range: !property.allow_internal_engine_distribution_date_range}),
                              },
                              {
                                className: 'btn-secondary',
                                label: 'No',
                              },
                            ],
                          });
                        }}
                      />
                      <label className="custom-control-label small pt-1 text-muted" htmlFor="internal_engine_distribution_date_range">
                        {property.internal_engine_distribution_date_range ? 'Restricting' : ' No Restriction'}
                      </label>
                    </div>
                  </td>
                  <td>
                    {property.internal_engine_distribution_start_date ?
                      AppUtil.formatDateTime(property.internal_engine_distribution_start_date, 'datef')
                      : 'N.A'
                    }
                  </td>
                  <td>
                    {property.internal_engine_distribution_end_date ?
                      AppUtil.formatDateTime(property.internal_engine_distribution_end_date, 'datef')
                      : 'N.A'
                    }
                  </td>
                </tr>
                <tr>
                  <td>Booking Engine</td>
                  <td>
                    <span className={'badge ' + (property.booking_engine_ok ? 'badge-success' : 'badge-secondary')}>
                      {property.booking_engine_ok ? 'Connected' : 'Not Connected'}
                    </span>
                  </td>
                  <td>
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox" className="custom-control-input" id="allow_booking_engine_distribution"
                        checked={property.allow_booking_engine_distribution}
                        onClick={() => {
                          confirmAlert({
                            title: (property.allow_booking_engine_distribution ? 'Disable' : ' Enabled') + ' Distribution',
                            message: property.allow_booking_engine_distribution ? Strings.bookingEngineDistributionDisable : Strings.bookingEngineDistributionEnable,
                            buttons: [
                              {
                                className: (property.allow_booking_engine_distribution ? 'btn-danger' : ' btn-success'),
                                label: 'Yes',
                                onClick: () => this.updateProperty({allow_booking_engine_distribution: !property.allow_booking_engine_distribution}),
                              },
                              {
                                className: 'btn-secondary',
                                label: 'No',
                              },
                            ],
                          });
                        }}
                      />
                      <label className="custom-control-label small pt-1 text-muted" htmlFor="allow_booking_engine_distribution">
                        {property.allow_booking_engine_distribution ? 'Distributing' : ' Not Distributing'}
                      </label>
                    </div>
                  </td>
                  <td>{property.booking_engine_advance_booking_days} day(s)</td>
                  <td>
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox" className="custom-control-input" id="booking_engine_distribution_date_range"
                        checked={property.allow_booking_engine_distribution_date_range}
                        onClick={() => {
                          confirmAlert({
                            title: (property.allow_booking_engine_distribution_date_range ? 'Disable' : ' Enabled') + ' Distribution',
                            message: property.allow_booking_engine_distribution_date_range ? Strings.bookingEngineDateDistributionDisable : Strings.bookingEngineDateDistributionEnable,
                            buttons: [
                              {
                                className: (property.allow_booking_engine_distribution_date_range ? 'btn-danger' : ' btn-success'),
                                label: 'Yes',
                                onClick: () => this.updateProperty({allow_booking_engine_distribution_date_range: !property.allow_booking_engine_distribution_date_range}),
                              },
                              {
                                className: 'btn-secondary',
                                label: 'No',
                              },
                            ],
                          });
                        }}
                      />
                      <label className="custom-control-label small pt-1 text-muted" htmlFor="booking_engine_distribution_date_range">
                        {property.booking_engine_distribution_date_range ? 'Restricting' : ' No Restriction'}
                      </label>
                    </div>
                  </td>
                  <td>
                    {property.booking_engine_distribution_start_date ?
                      AppUtil.formatDateTime(property.booking_engine_distribution_start_date, 'datef')
                      : 'N.A'
                    }
                  </td>
                  <td>
                    {property.booking_engine_distribution_end_date ?
                      AppUtil.formatDateTime(property.booking_engine_distribution_end_date, 'datef')
                      : 'N.A'
                    }
                  </td>
                </tr>
                <tr>
                  <td>Channel Manager</td>
                  <td>
                    <span className={'badge ' + (property.channel_manager_ok ? 'badge-success' : 'badge-secondary')}>
                      {property.channel_manager_ok ? 'Connected' : 'Not Connected'}
                    </span>
                  </td>
                  <td>
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox" className="custom-control-input" id="allow_channel_manager_distribution"
                        checked={property.allow_channel_manager_distribution}
                        onClick={() => {
                          confirmAlert({
                            title: (property.allow_channel_manager_distribution ? 'Disable' : ' Enabled') + ' Distribution',
                            message: property.allow_channel_manager_distribution ? Strings.channelManagerDistributionDisable : Strings.channelManagerDistributionEnable,
                            buttons: [
                              {
                                className: (property.allow_channel_manager_distribution ? 'btn-danger' : ' btn-success'),
                                label: 'Yes',
                                onClick: () => this.updateProperty({allow_channel_manager_distribution: !property.allow_channel_manager_distribution}),
                              },
                              {
                                className: 'btn-secondary',
                                label: 'No',
                              },
                            ],
                          });
                        }}
                      />
                      <label className="custom-control-label small pt-1 text-muted" htmlFor="allow_channel_manager_distribution">
                        {property.allow_channel_manager_distribution ? 'Distributing' : ' Not Distributing'}
                      </label>
                    </div>
                  </td>
                  <td>{property.channel_manager_advance_booking_days} day(s)</td>
                  <td>
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox" className="custom-control-input" id="channel_manager_distribution_date_range"
                        checked={property.allow_channel_manager_distribution_date_range}
                        onClick={() => {
                          confirmAlert({
                            title: (property.allow_channel_manager_distribution_date_range ? 'Disable' : ' Enabled') + ' Distribution',
                            message: property.allow_channel_manager_distribution_date_range ? Strings.channelManagerDateDistributionDisable : Strings.channelManagerDateDistributionEnable,
                            buttons: [
                              {
                                className: (property.allow_channel_manager_distribution_date_range ? 'btn-danger' : ' btn-success'),
                                label: 'Yes',
                                onClick: () => this.updateProperty({allow_channel_manager_distribution_date_range: !property.allow_channel_manager_distribution_date_range}),
                              },
                              {
                                className: 'btn-secondary',
                                label: 'No',
                              },
                            ],
                          });
                        }}
                      />
                      <label className="custom-control-label small pt-1 text-muted" htmlFor="channel_manager_distribution_date_range">
                        {property.channel_manager_distribution_date_range ? 'Restricting' : ' No Restriction'}
                      </label>
                    </div>
                  </td>
                  <td className={'a-class'}>
                    {property.channel_manager_distribution_start_date ?
                      AppUtil.formatDateTime(property.channel_manager_distribution_start_date, 'datef')
                      : 'N.A'
                    }
                  </td>
                  <td>
                    {property.channel_manager_distribution_end_date ?
                      AppUtil.formatDateTime(property.channel_manager_distribution_end_date, 'datef')
                      : 'N.A'
                    }
                  </td>
                </tr>
              </tbody>
            </table>

            <ul className={'list-unstyled'}>
              <li><strong>Distribution Length</strong> : {Strings.propertyBookingLength} </li>
              <li><strong>Date Restrictions</strong> : {Strings.propertyDistributionRestrictions} </li>
            </ul>
            <p className={'text-muted'}>
              {Strings.propertyDistributionThrottled}
            </p>
          </div>
        </div>
        <PropertyDistributionManage
          resultMsg={resultMsg}
          onHide={() => this.setState({shopPropertyDistributionManage: false})}
          show={this.state.shopPropertyDistributionManage}
          action={this.updateProperty}
          property={property}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      shopPropertyDistributionManage: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDistribution);