import React from 'react';
import { connect, Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import reduxThunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './redux';
import { BrowserRouter, Route } from 'react-router-dom';
import Navigator from './Navigator';
import Analytics from './Analytics';

// CSS
import './theme/styles.css';

const RouterWithRedux = connect()(BrowserRouter);

const logger = createLogger({
  // ...options
});

// Load middleware
let middleware = [
  reduxThunk, // Allows action creators to return functions (not just plain objects)
];

if (process.env.REACT_APP_ENVIRONMENT === 'development') {
  // Dev-only middleware
  middleware = [
    ...middleware,
    logger
  ];
}

// Init redux store (using the given reducer & middleware)
const store = compose(applyMiddleware(...middleware))(createStore)(rootReducer);

// Analytics Integration
let APP = Navigator;
if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
  APP = Analytics(Navigator);
}

/* Component ==================================================================== */
// Wrap App in Redux provider (makes Redux available to all sub-components)
export default function AppContainer () {
  return (
    <Provider store={store}>
      <RouterWithRedux>
        <Route path='*' component={APP}/>
      </RouterWithRedux>
    </Provider>
  );
}
