/**
 *
 * Profile Record List
 *
 */
import { faFolder, faLongArrowAltRight, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ExportButton from '../../components/dashboard/ExportButton';
import FilterDate from '../../components/dashboard/FilterDate';
import PageHeader from '../../components/dashboard/PageHeader';
import SearchBar from '../../components/forms/SearchBar';
// Components
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import { Constants } from '../../constants';
// Components
import Strings from '../../constants/strings';

// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import AppWebUtil from '../../lib/webUtils';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class GuestRecordList extends Component {
  static componentName = 'GuestRecordList';

  static propTypes = {
    subHeader: PropTypes.bool,
  };


  static defaultProps = {
    subHeader: false,
  };

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };

  componentDidMount = () => {
    let urlParams = {}, startDate = null, endDate = null;
    if (this.getQueryParams('start_date')) {
      startDate = Moment(String(this.getQueryParams('start_date')));
    }
    if (this.getQueryParams('end_date')) {
      endDate = Moment(String(this.getQueryParams('end_date')));
    }
    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('start_date'), this.getQueryParams('end_date'), 'created_date');


    this.setState({
      urlParams,
      startDate,
      endDate,
      loading: false
    });
  };


  fetchData = (page = 1, callback, searchText, download) => {
    const urlParams = {...this.state.urlParams};
    let baseUrl = 'profile-records';
    urlParams['page'] = page;

    if (download) {
      urlParams['download'] = download.file;
      urlParams['all_data'] = true;
      baseUrl = 'profile-records-permission';
      this.setState({fileDownload: true});
    }

    AppAPI.guestapi.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (download) {
          let fileName = `Profile-Record-Report-${AppUtil.formatDateTime(Moment(), 'short')}.${download.file}`;
          AppWebUtil.downloadFile(res, fileName);
        } else {
          if (res.results.length > 0) {
            callback(res.results, {allLoaded: !res.next});
          } else {
            callback();
          }
        }
        this.setState({
          searching: false,
          clear: false,
          refresh: false,
          loading: false,
          fileDownload: false,
          page: page,
        });
      })
      .catch((err) => {
        if (callback) {
          callback([], {allLoaded: true});
        }
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, refresh: false, loading: false, fileDownload: false, error});
      });
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={8}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faFolder}/> No Records </h4>
            <p>{Strings.profileRecordListEmptyText}</p>
          </div>
        </td>
      </tr>
    );
  };

  renderRow = (data, key) => {
    const profile = !_.isEmpty(data.profile) ? data.profile : {};
    let baseUrl;
    if (data.profile_type === Constants.PROFILE_TYPE.CORPORATE) {
      baseUrl = `/customer/corporate/${profile.hash}/`;
    } else if (data.profile_type === Constants.PROFILE_TYPE.AGENT) {
      baseUrl = `/customer/agent/${profile.hash}/`;
    } else if (data.profile_type === Constants.PROFILE_TYPE.VENDOR) {
      baseUrl = `/settings/expense/vendor/${profile.hash}/`;
    } else {
      baseUrl = `/customer/guest/${profile.hash}/`;
    }


    return (
      <tr className={'tr-align-middle'} key={key}>
        <td className={'data-table-cell-md'}>{data.ref_no}</td>
        <td className={'data-table-cell-md'}>
          <Link to={baseUrl}>{profile.ref_no}</Link>
        </td>
        <td className={'data-table-cell-sm'}>{data.record_type_display}</td>
        <td className={'data-table-cell-sm'}>{profile.profile_type_display}</td>
        <td className={'data-table-cell-md'}><small>{AppUtil.formatDateTime(data.created)}</small></td>
        <td className={'data-table-cell-md'}>
          <Link to={`${baseUrl}documents`}>
            Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
          </Link>
        </td>
      </tr>
    );
  };

  tableHeader = () => {
    return (
      <tr>
        <th>Ref No</th>
        <th>Guest Ref</th>
        <th>Record Type</th>
        <th>Profile Type</th>
        <th>Date</th>
        <th/>
      </tr>
    );
  };

  render = () => {
    const {loading, error, clear, searching, results, refresh, fileDownload} = this.state;
    const {subHeader, property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="dashboard screen-container">
        <Helmet>
          <title>Profile Record List</title>
        </Helmet>

        <PageHeader
          subHeader={subHeader}
          title={'Profile Records'}
          history={this.props.history}
          description={'Property profile record list.'}
        />

        <div className={'row'}>
          <div className={'col-12 col-sm-6'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.setState({refresh: true})}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                </button>
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data))}
                  size={'sm'}
                  maxDate={Moment().add(1, 'days')}
                  title={'Created'}
                />
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-6 text-center text-sm-right'}>
            <ul className="list-inline">
              {(!_.isEmpty(property.config) && property.config.profile && property.config.profile.profile_export) && (
                <ExportButton
                  size={'sm'}
                  files={['csv']}
                  action={(key) => this.fetchData(this.state.page, null, null, {file: key})}
                  loading={fileDownload}
                  disabled={!(this.state.startDate)}
                />
              )}
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <SearchBar
              clear={clear}
              results={results}
              refresh={refresh}
              searching={searching}
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
              colSpan={6}
            />
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      searching: false,
      refresh: false,
      clear: false,
      results: [],
      urlParams: {},
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuestRecordList);
