/**
 *
 * Amendment Details
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

// Consts and Libs
import AppAPI from '../../lib/api';
import {Constants} from '../../constants';
// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import {Link} from 'react-router-dom';
import PageHeader from '../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen} from '@fortawesome/free-solid-svg-icons';

// Actions
import * as BookingRoomActions from '../../redux/bookingroom/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  bookingroomamendment: state.bookingroom.bookingroomamendment
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setBookingRoomAmendment: BookingRoomActions.setBookingRoomAmendment
};


/* Component ==================================================================== */
class AmendmentDetails extends Component {
  static componentName = 'BookingAmendmentDetails';
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  componentDidMount = () => {
    if (this.props.match.params.bookingAmendmentId) {
      this.props.setBookingRoomAmendment(this.props.match.params.bookingAmendmentId)
        .then(() => {
          this.setState({
            loading: false
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error
          });
        });
    } else {
      this.setState({
        loading: false,
        error: true
      });
    }
  };
  render = () => {

    const {loading, error} = this.state;
    const {bookingroomamendment} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Booking Amendment Details</title>
        </Helmet>

        <PageHeader
          history={this.props.history}
          title={'Booking Amendments'}
          description={`Settings & Configuration for Amendment : ${bookingroomamendment.name}`}
          subHeader={true}
        />

        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12">
            <div className={'row'}>
              <div className={'col-md-6 col-sm-12 mb-3'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Ref :</strong> {bookingroomamendment.ref_no}
                  </li>
                </ul>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-md-6 col-sm-12 mb-3'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Name :</strong> {bookingroomamendment.name}
                  </li>
                  <li className="list-group-item">
                    <strong>Sub Quantity Type :</strong> {bookingroomamendment.sub_quantity_type_display}
                  </li>
                </ul>
              </div>
              <div className={'col-md-6 col-sm-12 mb-3'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Allow Multiple : </strong>
                    <span className={'badge ' + (bookingroomamendment.multiple ? 'badge-success' : 'badge-warning')}>
                      {bookingroomamendment.multiple ? 'Yes' : 'No'}
                    </span>
                  </li>
                  <li className="list-group-item">
                    <strong>Quantity Type :</strong> {bookingroomamendment.quantity_type_display}
                  </li>

                </ul>
              </div>
            </div>

            <div className={'table-responsive'}>
              <table className={'table border'}>
                <thead>
                  <tr>
                    <th className={'w-50'}>Type</th>
                    <th className={'text-right'}>Price ({bookingroomamendment.currency})</th>
                    <th className={'text-right'}>Price (Inc Tax) ({bookingroomamendment.currency})</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><strong>Price : </strong>
                      {`${bookingroomamendment.currency} ${bookingroomamendment.price}`}
                      {parseInt(bookingroomamendment.sub_quantity_type) !== Constants.QUANTITY_TYPE.NOS && ` / ${bookingroomamendment.sub_quantity_type_display} ` }
                      {parseInt(bookingroomamendment.quantity_type) !== Constants.QUANTITY_TYPE.NOS && ` / ${bookingroomamendment.quantity_type_display} ` }</td>
                    <td className={'text-right'}>{bookingroomamendment.price}</td>
                    <td className={'text-right'}>{bookingroomamendment.price_effective}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className={'row'}>
              <div className={'col-md-6 col-sm-12 mb-3'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Tax :</strong> {bookingroomamendment.tax_description}
                  </li>
                </ul>
              </div>
              <div className={'col-md-6 col-sm-12 mb-3'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Status : </strong>
                    <span className={'badge ' + (bookingroomamendment.active ? 'badge-success' : 'badge-warning')}>
                      {bookingroomamendment.active ? 'Active' : 'De-Active'}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link to={'/settings/inventory/amendments/' + bookingroomamendment.id + '/edit'} className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AmendmentDetails);