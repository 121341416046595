/**
 * Global Util Functions
 *
 */
import _ from 'lodash';
import React from 'react';
import Moment from 'moment';
import moment from 'moment-timezone';
import queryString from 'query-string';
import { Constants } from '../constants';


const Entities = require('html-entities').AllHtmlEntities;

const entities = new Entities();

const RADIAN = Math.PI / 180;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

function striptags(input) {
  return input.replace(/(<([^>]+)>)/ig, '');
}

const UTIL = {
  /**
   * Search in package list for existance of room type package
   *
   * @param bookingRoom
   * @param packageData
   * @param searchDate
   * @returns {boolean}
   */
  searchRoomPackage: (bookingRoom, packageData, searchDate = Moment()) => {
    let date = searchDate || Moment();

    if (!_.isEmpty(bookingRoom.room_type_package_attributes)) {
      let value = false, packageFound= false;
      bookingRoom.room_type_package_attributes.forEach((pkgData) => {
        if (pkgData.room_type_package.name === packageData.name || pkgData.room_type_package.id === packageData.id) {
          // Check for date range
          packageFound = true;
          if (date.isSameOrAfter(Moment(pkgData.start))  && date.isSameOrBefore(Moment(pkgData.end)) ) {
            value = true;
          }
        }
      });
      return value || packageFound;
    }
    return false;
  },
  /**
   * Returns key for matched key from list data
   * @param listData
   * @param findKey : string to search for
   * @param findValue : string to check for
   * @param returnKey : string key of value
   */
  valueFromList: (listData, findKey, findValue, returnKey) => {
    let returnValue = null;
    if (!_.isEmpty(listData)) {
      listData.forEach((data) => {
        if (data[findKey] && data[findKey] === findValue) {
          returnValue = data[returnKey] ? data[returnKey] : null;
        }
      });
    }
    return returnValue;
  },
  /**
   *  Insert element or Add if key exist in object
   */
  insertOrAddObject: (objectData, key, value, type = 'int') => {
    const incrementValue = (type === 'int' ? parseInt(value) : parseFloat(value));
    // Convert keys to string as Object.keys returns them as string as key can only be strings in JS
    if (Object.keys(objectData).includes(String(key))) {
      objectData[key] += incrementValue;
    } else {
      objectData[key] = incrementValue;
    }
    objectData[key] = (type === 'int' ? objectData[key] : parseFloat(objectData[key].toFixed(2)));
    return objectData;
  },
  /**
   *   Return sum of items in array
   */
  sumArray: (arrayData, type = 'int') => {
    let sum = 0;
    if (!_.isEmpty(arrayData)) {
      if (type === 'int') {
        sum = arrayData.reduce((x, a) => x + parseInt(a), 0);
      } else {
        sum = arrayData.reduce((x, a) => x + parseFloat(a), parseFloat('0'));
        sum = sum.toFixed(2);
      }
    }
    return sum;
  },
  /**
   * Format duration
   * Formats django's default duration field
   */
  formatDuration: (duration) => {
    if (duration) {
      const duration_data = moment.duration(duration.replace(/\s/g, '.'));
      const humanized_data = [];
      if (duration_data.days()) {
        humanized_data.push(`${duration_data.days()} Day`);
      }

      humanized_data.push(`${duration_data.hours()} Hour and ${duration_data.minutes()} Mins`);
      return humanized_data.join(', ');
    }
    return '';
  },
  /**
   * Format Week Days
   */
  formatWeekDays: (weekDaysList, expanded = false) => {
    const daysList = [
      {key: 'Sun', value: 'Sunday'},
      {key: 'Mon', value: 'Monday'},
      {key: 'Tue', value: 'Tuesday'},
      {key: 'Wed', value: 'Wednesday'},
      {key: 'Thu', value: 'Thursday'},
      {key: 'Fri', value: 'Friday'},
      {key: 'Sat', value: 'Saturday'},
    ];
    const activeDays = [];
    if (weekDaysList) {
      daysList.forEach((data) => {
        if (weekDaysList.includes(data.key) || weekDaysList.includes(data.key.toLowerCase())) {
          if (expanded) {
            activeDays.push(data.value);
          } else {
            activeDays.push(data.key);
          }

        }
      });
    }
    return activeDays.join(', ');
  },
  /**
   * Dynamic Select inputs
   */
  numericSelector: (start = 0, end = 10, labelPostFix = '') => {
    let selectOptions = [];
    while (start <= end) {
      selectOptions.push({
        value: start,
        label: `${start} ${labelPostFix}`,
      });
      start += 1;
    }
    return selectOptions;
  },
  /**
   * Expand Phone number
   */
  processPhoneNumber: (phone) => {
    try {
      return phoneUtil.parse(phone) ? phoneUtil.parseAndKeepRawInput(phone) : null;
    } catch (e) {
      return false;
    }
  },
  /**
   *  Calculates % of 2 numbers
   * */
  calculatePercentage: (data1, data2) => {
    let percent = 0;
    if (parseInt(data2) > 0) {
      percent = (data1 / data2) * 100;
    }
    return percent.toFixed(2);
  },
  /**
   *  Calculates X% of a number
   * */
  calculateXPercentage: (number, percentage) => {
    let value = 0;
    if (parseInt(number) > 0 && parseInt(percentage) > 0) {
      value = (percentage / 100) * number;
    }
    return value.toFixed(2);
  },
  /**
   *  Prints label in center of pie chart
   * */
  renderCustomizedLabel: ({cx, cy, midAngle, innerRadius, outerRadius, percent}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    if (percent > 0) {
      return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    }
  },
  /**
   * Sorts object array based on a key in object
   * @param rooms
   * @param key
   */
  sortArray: (rooms, key) => {
    let sortedArray = {};
    // eslint-disable-next-line array-callback-return
    rooms.map((data) => {
      let arrayKey = 'default';
      if (data[key]) {
        arrayKey = data[key];
      }
      if (sortedArray[arrayKey]) {
        sortedArray[arrayKey].push(data);
      } else {
        sortedArray[arrayKey] = [data];
      }
    });
    return sortedArray;
  },
  /**
   * Formats price to 2 decimal place
   * @param price
   * @returns {string}
   */
  formatPrice: price => {
    return parseFloat(price).toFixed(2);
  },
  /**
   * Calculates room total from room data
   * @param roomData
   * @returns {*}
   */
  calculateRoomTotalsV2: roomData => {
    let room_total = parseFloat(roomData.package.base_price_effective_total);
    // check if room guest is less than base occupancy
    if (
      roomData.no_of_guest < roomData.occupancy_data.base_occupancy &&
      roomData.occupancy_data.sell_below_min_occupancy
    ) {
      let diff_guest = roomData.occupancy_data.base_occupancy - roomData.no_of_guest;
      room_total =
        room_total -
        UTIL.calculateDiscountGuestPrice(roomData.occupancy_data, room_total) *
        diff_guest;
    }

    if (roomData.no_of_guest > roomData.occupancy_data.base_occupancy) {
      let diff_guest = roomData.no_of_guest - roomData.occupancy_data.base_occupancy;
      room_total =
        room_total +
        parseFloat(roomData.package.guest_price_effective_total) * diff_guest;
    }

    // Child Guest
    if (roomData.no_of_child > 0) {
      room_total =
        room_total +
        parseFloat(roomData.package.child_price_effective_total) *
        roomData.no_of_child;
    }

    // Infant guest
    if (roomData.no_of_infant > 0) {
      room_total =
        room_total +
        parseFloat(roomData.package.infant_price_effective_total) *
        roomData.no_of_infant;
    }

    roomData.total = parseFloat(room_total).toFixed(2);

    return roomData;
  },
  /**
   * Calculates room total from room data
   * @param roomData
   * @returns {*}
   */
  calculateRoomTotals: roomData => {
    let room_total = parseFloat(roomData.package.base_price_effective_total);
    // check if room guest is less than base occupancy
    if (
      roomData.guest < roomData.occupancy_data.base_occupancy &&
      roomData.occupancy_data.sell_below_min_occupancy
    ) {
      let diff_guest = roomData.occupancy_data.base_occupancy - roomData.guest;
      room_total =
        room_total -
        UTIL.calculateDiscountGuestPrice(roomData.occupancy_data, room_total) *
        diff_guest;
    }

    if (roomData.guest > roomData.occupancy_data.base_occupancy) {
      let diff_guest = roomData.guest - roomData.occupancy_data.base_occupancy;
      room_total =
        room_total +
        parseFloat(roomData.package.guest_price_effective_total) * diff_guest;
    }

    // Child Guest
    if (roomData.child > 0) {
      room_total =
        room_total +
        parseFloat(roomData.package.child_price_effective_total) *
        roomData.child;
    }

    // Infant guest
    if (roomData.infant > 0) {
      room_total =
        room_total +
        parseFloat(roomData.package.infant_price_effective_total) *
        roomData.infant;
    }

    roomData.total = parseFloat(room_total).toFixed(2);

    return roomData;
  },
  /**
   * Calculate Discounted Guest Price
   *
   * Calculates discounted price for guest below the min occupancy
   * for occupancy_data object
   *    {
   *      sell_below_min_occupancy : True or False,
   *      discount_per_guest: xx,
   *      discount_type: [1 = percent, any other fixed]
   *    }
   */
  calculateDiscountGuestPrice: (occupancy_data, base_price = 0) => {
    let price = 0;
    if (occupancy_data.sell_below_min_occupancy) {
      if (occupancy_data.discount_type === 1) {
        // percentage
        price = (base_price * occupancy_data.discount_per_guest) / 100;
      } else {
        price = occupancy_data.discount_per_guest;
      }
    }
    return price.toFixed(2);
  },
  availabilityStatusColor: status => {
    if (status > 75) {
      return 'green-cl';
    } else if (status > 50) {
      return 'blue-cl';
    } else if (status > 25) {
      return 'purple-dark-cl';
    } else if (status > 10) {
      return 'orange-cl';
    } else {
      return 'red-cl';
    }
  },
  occupancyStatusColor: status => {
    if (status > 75) {
      return 'green-cl';
    } else if (status > 50) {
      return 'purple-dark-cl';
    } else if (status > 25) {
      return 'blue-cl';
    } else if (status > 10) {
      return 'orange-cl';
    } else {
      return 'red-cl';
    }
  },
  /**
   * Add item or remove item from array
   */
  insertOrRemoveArray: (dataSet = null, data = null) => {
    if (dataSet && data) {
      if (dataSet.includes(data)) {
        dataSet = dataSet.filter(function (item) {
          return item !== data;
        });
      } else {
        dataSet.push(data);
      }
      return dataSet;
    }
    return [];
  },
  /**
   * Calculate tax
   *
   * Calculates tax for given total, tax class object
   *   {
   *         value : {
   *             fixed: xx,
   *             dynamic: xx
   *        },
   *        description : xxxx,
   *        tax_classes: [ x, x ]
   *    }
   */
  /**
   *  Returns tax slabs where total belongs to
   *  Possible scenarios
   *    1. Total is b/w start_range & end_range
   *    2. Total is greater than start_range and no end_range
   */
  taxSlabInRange: (tax_slab_set, total) => {
    const in_range_sets = [];

    if (!_.isEmpty(tax_slab_set)) {
      tax_slab_set.forEach((data) => {
        if (!data.end_range || parseFloat(data.end_range) >= total) {
          if (total >= parseFloat(data.start_range)) {
            in_range_sets.push(data);
          }
        }
      });
    }
    return in_range_sets;
  },
  calculateTax: (tax_cache_object, price = 0, quantity = 0, discount = 0) => {

    let tax_value = parseFloat('0.0');
    if (!_.isEmpty(tax_cache_object)) {

      if (!_.isEmpty(tax_cache_object.value)) {
        const value = tax_cache_object.value;
        if (value.fixed) {
          tax_value += quantity * value.fixed;
        }

        if (value.dynamic) {
          tax_value += ((price * quantity) - discount) * parseFloat(value.dynamic);
        }

        if (!_.isEmpty(value.slab)) {
          const slab = value.slab;
          if (!_.isEmpty(slab.fixed)) {
            const fixedSlabs = UTIL.taxSlabInRange(slab.fixed, price);
            fixedSlabs.forEach((data) => {
              tax_value += quantity * parseFloat(data.tax_value);
            });
          }

          if (!_.isEmpty(slab.dynamic)) {
            const dynamicSlabs = UTIL.taxSlabInRange(slab.dynamic, price);
            dynamicSlabs.forEach((data) => {
              tax_value += ((price * quantity) - discount) * parseFloat(data.tax_value);
            });
          }

        }
      }
    }
    return tax_value.toFixed(2);
  },
  /**
   * Transfer status
   */
  routeStatusColor: status => {
    switch (status) {
    case -1:
      return 'badge-danger';
    case 0:
      return 'badge-primary';
    case 1:
      return 'badge-success';
    case 2:
      return 'badge-warning';
    case 3:
      return 'badge-warning';
    case 4:
      return 'badge-light';
    default:
      return 'badge-secondary';
    }
  },
  reviewStatusColor: (status) => {
    switch (status) {
    case 0:
      return 'badge-danger';
    case 1:
      return 'badge-success';
    case 2:
      return 'badge-secondary';
    default:
      return 'badge-secondary';
    }
  },
  /**
   * Order item status
   */
  orderItemStatusColor: (status) => {
    switch (status) {
    case 0:
      return 'badge-info';
    case 1:
      return 'badge-warning';
    case 2:
      return 'badge-light';
    case 3:
      return 'badge-success';
    default:
      return 'badge-secondary';
    }
  },
  /**
   * Payment Status Color
   */
  transferStatusColor: (status) => {
    switch (status) {
    case -1:
      return 'badge-danger';
    case 0:
      return 'badge-info';
    case 1:
      return 'badge-success';
    case 2:
    case 3:
      return 'badge-warning';
    case 4:
      return 'badge-info';
    default:
      return 'badge-secondary';
    }
  },
  ticketBookingStatusColor: (status) => {
    switch (status) {
    case 1:
      return 'badge-success';
    case 2:
      return 'badge-warning';
    case 4:
      return 'badge-dark';
    default:
      return 'badge-secondary';
    }
  },
  ticketBookingClaimColor: (status) => {
    switch (status) {
    case 5:
      return 'badge-warning';
    case 4:
      return 'badge-success';
    default:
      return 'badge-secondary';
    }
  },
  bookingStatusColor: (status) => {
    switch (status) {
    case 1:
      return 'badge-info';
    case 2:
      return 'badge-warning';
    case 3:
      return 'badge-success';
    case 4:
      return 'badge-dark';
    default:
      return 'badge-secondary';
    }
  },
  bookingAmendmentStatusColor: (status) => {
    switch (status) {
    case 1:
      return 'badge-info';
    case 2:
      return 'badge-success';
    default:
      return 'badge-secondary';
    }
  },
  bookingLogTypeColor: (status) => {
    switch (status) {
    case 1:
      return 'badge-success';
    case 2:
      return 'badge-warning';
    default:
      return 'badge-secondary';
    }
  },
  noteTypeColor: (status) => {
    switch (status) {
    case Constants.NOTES_TYPE.ACTION:
      return 'badge-warning';
    default:
      return 'badge-info';
    }
  },
  paymentStatusColor: (status) => {
    switch (status) {
    case -1:
    case 0:
      return 'badge-danger';
    case 1:
      return 'badge-success';
    case 2:
      return 'badge-warning';
    case 3:
      return 'badge-primary';
    case 4:
      return 'badge-info';
    case 5:
      return 'badge-info';
    default:
      return 'badge-secondary';
    }
  },
  pTerminalStatusColor: (status) => {
    switch (status) {
    case 0:
      return 'badge-info';
    case 1:
      return 'badge-primary';
    case 2:
    case 4:
      return 'badge-danger';
    case 3:
      return 'badge-success';
    case 5:
      return 'badge-warning';
    default:
      return 'badge-secondary';
    }
  },
  pGatewayStatusColor: (status) => {
    switch (status) {
    case 0:
    case 7:
      return 'badge-danger';
    case 1:
      return 'badge-success';
    case 2:
    case 5:
      return 'badge-info';
    case 3:
      return 'badge-primary';
    case 4:
      return 'badge-warning';
    default:
      return 'badge-secondary';
    }
  },
  paymentModeColor: (status) => {
    switch (status) {
    case 1:
      return 'badge';
    case 2:
      return 'badge-primary';
    case 3:
      return 'badge';
    default:
      return 'badge-secondary';
    }
  },
  fetchStatusColor: (status) => {
    switch (status) {
    case 0:
      return 'badge-info';
    case 1:
      return 'badge-success';
    case 2:
      return 'badge-warning';
    case 3:
      return 'badge-primary';
    case 4:
      return 'badge-secondary';
    default:
      return 'badge-danger';
    }
  },
  reportStatusColor: (status) => {
    switch (status) {
    case -1:
      return 'badge-danger';
    case 0:
      return 'badge-info';
    case 1:
      return 'badge-success';
    case 2:
      return 'badge-warning';
    case 3:
      return 'badge-primary';
    default:
      return 'badge-secondary';
    }
  },
  settlementStatusColor: (status) => {
    switch (status) {
    case -1:
    case 0:
      return 'badge-warning';
    case 1:
      return 'badge-success';
    default:
      return 'badge-secondary';
    }
  },
  subscriptionStatusColor: (status) => {
    switch (status) {
    case 0:
    case 1:
      return 'badge-info';
    case 2:
    case 3:
      return 'badge-success';
    case 4:
      return 'badge-warning';
    case 5:
      return 'badge-secondary';
    default:
      return 'badge-danger';
    }
  },
  notificationStatusColor: (status) => {
    switch (status) {
    case 0:
      return 'badge-primary';
    case 1:
      return 'badge-success';
    case 2:
      return 'badge-info';
    case 3:
      return 'badge-warning';
    default:
      return 'badge-danger';
    }
  },
  foodContentColor: (status) => {
    switch (status) {
    case 1:
      return 'green-cl';
    case 2:
    case 3:
    case 5:
      return 'red-cl';
    case 4:
      return 'yellow-cl';
    default:
      return 'grey-cl';
    }
  },
  drinkContentColor: (status) => {
    switch (status) {
    case 1:
      return 'red-cl';
    case 2:
      return 'green-cl';
    default:
      return 'grey-cl';
    }
  },
  posOrderStatusColor: (status) => {
    switch (status) {
    case 1:
      return 'badge-warning';
    case 2:
      return 'badge-primary';
    case 3:
      return 'badge-success';
    default:
      return 'badge-secondary';
    }
  },
  posOrderKotStatusColor: (status) => {
    switch (status) {
    case 1:
      return 'badge-success';
    case 2:
      return 'badge-warning';
    default:
      return 'badge-secondary';
    }
  },
  roomServiceTypeColor: (status) => {
    switch (status) {
    case 1:
      return 'badge-primary';
    case 2:
      return 'badge-info';
    case 3:
      return 'badge-danger';
    default:
      return 'badge-secondary';
    }
  },
  roomServiceRequestStatusColor: (status) => {
    switch (status) {
    case 1:
      return 'badge-warning';
    case 2:
      return 'badge-info';
    case 3:
      return 'badge-success';
    case 4:
      return 'badge-dark';
    default:
      return 'badge-secondary';
    }
  },
  /**
   * Capitalize first letter
   */
  ucFirst: string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  /**
   * Days difference
   */
  diffDateTime: (obj1, obj2, format) => {
    const t1 = moment(obj1),
      t2 = moment(obj2);

    switch (format) {
    case 'seconds':
      return t2.diff(t1, 'seconds');
    case 'hours':
      return t2.diff(t1, 'hours');
    default:
      return t2.diff(t1, 'days');
    }
  },
  /**
   * Format time
   */
  formatDateTime: (obj, format, parseFormat = null) => {
    let dateTimeObject;
    if (parseFormat) {
      dateTimeObject = moment(obj, parseFormat);
    } else {
      dateTimeObject = moment(obj);
    }

    switch (format) {
    case 'time':
      return dateTimeObject.format('h:mm a');
    case 'timez':
      return dateTimeObject.format('h:mm a ZZ');
    case 'date':
      return dateTimeObject.format('Do MMM');
    case 'datef':
      return dateTimeObject.format('Do MMM YYYY');
    case 'short':
      return dateTimeObject.format('DD-MM-YYYY HH:mm');
    case '24':
      return dateTimeObject.format('Do MMM YYYY HH:mm');
    case 'day':
      return dateTimeObject.format('dddd');
    case 'dte':
      return dateTimeObject.format('D');
    case 'month':
      return dateTimeObject.format('MMM');
    case 'year':
      return dateTimeObject.format('YYYY');
    case 'date-iso':
      return dateTimeObject.format('YYYY-MM-DD');
    default:
      return dateTimeObject.format('Do MMM YY h:mm a');
    }
  },
  /**
   * Convert Obj to Arr
   */
  objToArr: obj => Object.keys(obj).map(k => obj[k]),
  /**
   * Limit characters, placing a ... at the end
   */
  limitChars: (str, limit = 15) => {
    if (!_.isEmpty(str) && str.length > limit) return `${str.substr(0, limit).trim()} ...`;
    return str;
  },
  /**
   * Decode HTML Entites
   */
  htmlEntitiesDecode: str => entities.decode(str),
  /**
   * Convert all HTMLEntities when Array
   */
  convertHtmlEntitiesArray: (arr) => {
    const finalArr = arr;

    if (arr instanceof Array) {
      arr.forEach((item, key) => {
        if (item instanceof Array) {
          finalArr[key] = UTIL.convertHtmlEntitiesArray(item);
        } else if (typeof item === 'object') {
          finalArr[key] = UTIL.convertHtmlEntitiesObject(item);
        } else if (typeof item === 'string') {
          finalArr[key] = entities.decode(striptags(item));
        }
      });
    }
    return finalArr;
  },
  /**
   * Convert all HTMLEntities when Object
   */
  convertHtmlEntitiesObject: (obj) => {
    const finalObj = obj;

    if (typeof obj === 'object' && !(obj instanceof Array)) {
      Object.keys(obj).forEach((key) => {
        const item = obj[key];

        if (item instanceof Array) {
          finalObj[key] = UTIL.convertHtmlEntitiesArray(item);
        } else if (typeof item === 'object') {
          finalObj[key] = UTIL.convertHtmlEntitiesObject(item);
        } else if (typeof item === 'string') {
          finalObj[key] = entities.decode(striptags(item));
        }
      });
    }

    return finalObj;
  },
  /**
   * Strips all HTML tags
   */
  stripTags: str => striptags(str),
  /**
   * Returns param value from url
   */
  getURLParam: (url, key) => {
    if (url){
      const params = url.split('?');
      if (params.length === 2) {
        const queryParams = queryString.parse(params[1]);
        if (Object.keys(queryParams).includes(key)) return queryParams[key];
      }
    }
    return false;
  },
};

/* Export ==================================================================== */
export default UTIL;
