/**
 * Review Actions
 *
 */
import AppAPI from '../../lib/api';

/**
 * Set Review
 */
export const setReview = (reviewData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof reviewData === 'object') {
    dispatch({
      type: 'SET_REVIEW',
      data: reviewData
    });
    return resolve(reviewData);
  } else if (reviewData) {
    // Fetch Review Data
    return AppAPI.review.get(reviewData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_REVIEW',
          data: res
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Review
 */
export const updateReview = (reviewData) => setReview(reviewData);

/**
 * Remove Review
 */
export function unSetReview() {
  return (dispatch) => {
    dispatch({
      type: 'SET_REVIEW',
      data: null
    });
  };
}
