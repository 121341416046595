import Moment from 'moment';

/**
 * React Web Util Functions
 *
 */

const WEBUTIL = {
  /**
   * Set date params
   */
  setDateParams: (dateParams, start_date, end_date, variable) => {
    if (start_date){
      let startDate = Moment(String(start_date));
      if (end_date) {
        let endDate = Moment(String(end_date));
        dateParams[`${variable}_start`] = startDate.format('YYYY-MM-DD');
        dateParams[`${variable}_end`] = endDate.format('YYYY-MM-DD');
      } else {
        dateParams[`${variable}`] = startDate.format('YYYY-MM-DD');
      }
    }
    return dateParams;
  },
  /**
   *  Process Date for URL
   */
  processDate: (date, key='') => {
    const dateResults = [];
    if (date) {
      if (date.startDate && date.endDate) {
        dateResults.push({key: `${key}start_date`, value: date.startDate.format('YYYY-MM-DD')});
        dateResults.push({key: `${key}end_date`, value: date.endDate.format('YYYY-MM-DD')});
      } else if (date.startDate) {
        dateResults.push({key: `${key}start_date`, value: date.startDate.format('YYYY-MM-DD')});
        dateResults.push({key: `${key}end_date`, value: null});
      } else {
        dateResults.push({key: `${key}start_date`});
        dateResults.push({key: `${key}end_date`});
      }
    }
    return dateResults;
  },
  /**
   *  Download File
   */
  downloadFile: (response, fileName = 'file') => {
    response.blob().then((blob) => {
      // 2. Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  },

  /**
   * Clear Browser Cache
   * **/
  clearBrowserCache : () => {
    // Moved to try catch to capture any errors in browser
    try {
      if('caches' in window){
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach(name => {
            caches.delete(name);
          });
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
      }
    } catch { }
  }
};

/* Export ==================================================================== */
export default WEBUTIL;
