/**
 *
 * Booking Room Analytics : Room
 */
import _  from 'lodash';
import Moment from 'moment';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import React, { Component } from 'react';
import PageHeader from '../../../components/dashboard/PageHeader';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
// Constants & Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import {AnalyticConstants} from '../../../constants';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class BookingRoomAnalyticsRoom extends Component {
  static componentName = 'BookingRoomAnalyticsRoom';

  static propTypes = {
    pos: PropTypes.string,
    endDate: PropTypes.object,
    startDate: PropTypes.object
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {startDate, endDate, dateType} = this.props;
    this.setState({loading: true});
    const queryParams = {
      'date_type': dateType,
      'report_type': 'booking_room_room',
      'start_date': (startDate ? startDate : Moment()).format('YYYY-MM-DD'),
      'end_date': endDate ? endDate.format('YYYY-MM-DD') : null
    };

    // Calculating total nights
    let totalNights = 1;
    if (endDate){
      totalNights = AppUtil.diffDateTime(queryParams.start_date, queryParams.end_date);
    }

    AppAPI.bookingapi.get(`booking-analytics/?${queryString.stringify(queryParams)}`)
      .then((res) => {
        this.setState({loading: false, response: res, totalNights: totalNights});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({loading: false, error: error});
      });
  };

  render = () => {
    const {loading, error, response, totalNights} = this.state;
    let rooms = [], roomTypes = [];

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    // Initialize with empty array for order data set
    const bookingDataSet = {};

    Object.keys(AnalyticConstants.bookingAnalytics).forEach((data)=>{
      bookingDataSet[data] = 0;
    });

    // Containers for order status data & order source data
    const bookingData = {
      bookingData:_.cloneDeep(bookingDataSet),
      nullRoomData:_.cloneDeep(bookingDataSet),
      roomData: {},
      roomTypeData: {},
    };

    // Setting up container for Payment terminal level counts.
    if (!_.isEmpty(response) && !_.isEmpty(response.meta_data) ) {
      rooms = response.meta_data.rooms || [];
      if (!_.isEmpty(rooms)) {
        rooms.forEach((data)=>{
          bookingData.roomData[data.id] = _.cloneDeep(bookingDataSet);
        });
      }

      roomTypes = response.meta_data.room_types || [];
      if (!_.isEmpty(roomTypes)) {
        roomTypes.forEach((data)=>{
          bookingData.roomTypeData[data.id] = _.cloneDeep(bookingDataSet);
        });
      }
    }


    if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
      const responseData = response.data;
      if (!_.isEmpty(responseData.booking_room_room_data)) {
        responseData.booking_room_room_data.forEach((data) => {
          Object.entries(AnalyticConstants.bookingAnalytics).forEach(([key, value])=>{
            if (data[value]) {
              AppUtil.insertOrAddObject(bookingData.bookingData, key, data[value]);
              if (data.room && bookingData.roomData[data.room]){
                AppUtil.insertOrAddObject(bookingData.roomData[data.room], key,data[value]);
              } else {
                AppUtil.insertOrAddObject(bookingData.nullRoomData, key, data[value]);
              }
            }
          });
        });
      }
    }

    const assignedRoomPercentage = AppUtil.calculatePercentage(
      bookingData.bookingData.bookingCount - bookingData.nullRoomData.bookingCount,
      bookingData.bookingData.bookingCount
    );

    const occupiedRoomPercentage = AppUtil.calculatePercentage(
      bookingData.bookingData.bookingNights,
      totalNights * rooms.length
    );

    return (
      <div className="screen-container">
        <Helmet>
          <title>Booking Room Analytics : Room</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={'Booking Room Analytics : Room'}
          description={'Booking room bifurcation based on room and its related details in the selected range.'}

        />

        <div className={'row'}>
          <div className={'col-12 col-lg-6 mb-3'}>
            <div className={'card'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col-6'}>
                    <p className={'text-muted mb-1'}>Active Booking(s)</p>
                    <h3 className={'card-title mb-0 pb-0'}>
                      {bookingData.bookingData.bookingCount}
                    </h3>
                    <p className={'text-muted mb-1'}>
                      {bookingData.bookingData.bookingNights} Active Night(s)
                    </p>
                  </div>
                  <div className={'col-6'}>
                    <p className={'text-muted mb-1'}>Total Room(s)</p>
                    <h3 className={'card-title mb-0 pb-0'}>
                      {rooms.length}
                    </h3>
                    <p className={'text-muted mb-1'}>{rooms.length * totalNights} Room Night(s)</p>
                  </div>
                </div>
                <div className="progress mt-3 grey-light-bg">
                  <div
                    className="progress-bar green-bg"
                    style={{width: `${occupiedRoomPercentage}%`}}
                    aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"/>
                </div>
                <div className={'row'}>
                  <div className={'col-8'}>
                    <p className={'text-muted mb-0 mt-1 small'}>
                      Night(s) - Occupied : {bookingData.bookingData.bookingNights} |
                      Total : {totalNights * rooms.length}
                    </p>
                  </div>
                  <div className={'text-right col'}>
                    <p className={'text-muted mb-0 mt-1 small'}>{occupiedRoomPercentage}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={'col-12 col-lg-6 mb-3'}>
            <div className={'card'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col'}>
                    <p className={'text-muted mb-1'}>Assigned</p>
                    <h3 className={'card-title mb-0 pb-0 green-cl'}>
                      {bookingData.bookingData.bookingCount - bookingData.nullRoomData.bookingCount}
                    </h3>
                    <p className={'text-muted mb-1'}>{bookingData.bookingData.bookingNights - bookingData.nullRoomData.bookingNights} Room Night(s)</p>
                  </div>
                  <div className={'col'}>
                    <p className={'text-muted mb-1'}>Not Assigned</p>
                    <h3 className={'card-title mb-0 pb-0 yellow-cl'}>
                      {bookingData.nullRoomData.bookingCount || 0}
                    </h3>
                    <p className={'text-muted mb-1'}>{bookingData.nullRoomData.bookingNights} Room Night(s)</p>
                  </div>
                </div>
                <div className="progress mt-3 yellow-bg">
                  <div
                    className="progress-bar green-bg"
                    style={{width: `${assignedRoomPercentage}%`}}
                    aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"/>
                </div>
                <div className={'row'}>
                  <div className={'col-8'}>
                    <p className={'text-muted mb-0 mt-1 small'}>
                      Night(s) - Assigned : {bookingData.bookingData.bookingNights - bookingData.nullRoomData.bookingNights} |
                      Not Assigned : { bookingData.nullRoomData.bookingNights}
                    </p>
                  </div>
                  <div className={'text-right col'}>
                    <p className={'text-muted mb-0 mt-1 small'}>{assignedRoomPercentage}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className={'text-muted small mb-4'}>Active include - Upcoming + Active + Completed</p>


        <div className={'row mb-2'}>
          <div className={'col-12 col-lg-12'}>
            <table className={'table table-bordered'}>
              <thead>
                <tr>
                  <th className={'data-table-cell-md'}>Room</th>
                  <th>Booking(s)</th>
                  <th>Night(s)</th>
                </tr>
              </thead>
              <tbody>
                {roomTypes.map((roomTypeData, i) => {
                  const roomData = rooms.filter(data => (data.room_type_id=== roomTypeData.id));
                  let noOfNights = 0, bookingCount = 0;
                  return (
                    <React.Fragment key={i}>
                      <tr>
                        <td colSpan={4} className={'text-center table-active'}>
                          <strong>{roomTypeData.name}</strong>
                        </td>
                      </tr>
                      {roomData.map((data , j)=>{
                        bookingCount += bookingData.roomData[data.id].bookingCount || 0;
                        noOfNights += bookingData.roomData[data.id].bookingNights || 0;
                        return (
                          <tr key={j}>
                            <td className={'data-table-cell-md'}>{data.name}</td>
                            <td className={'text-center'}>
                              {bookingData.roomData[data.id].bookingCount || 0}
                            </td>
                            <td className={'text-center'}>
                              {bookingData.roomData[data.id].bookingNights || 0}
                            </td>
                          </tr>
                        );
                      })}
                      <tr className={'bg-light'}>
                        <td><strong>Total - {roomTypeData.name}</strong></td>
                        <td className={'text-center'}><strong>{bookingCount}</strong></td>
                        <td className={'text-center'}><strong>{noOfNights}</strong></td>
                      </tr>
                      <tr>
                        <td colSpan={4}>&nbsp;</td>
                      </tr>
                    </React.Fragment>
                  );
                })}
                {(bookingData.nullRoomData.bookingCount !== 0) && (
                  <React.Fragment>
                    <tr>
                      <td colSpan={4} className={'text-center table-active'}>
                        <strong>Not Assigned</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Not Assigned</td>
                      <td className={'text-center'}>{bookingData.nullRoomData.bookingCount}</td>
                      <td className={'text-center'}>{bookingData.nullRoomData.bookingNights}</td>
                    </tr>
                  </React.Fragment>
                )}
                <tr className={'bg-light'}>
                  <td><strong>Total</strong></td>
                  <td className={'text-center'}><strong>{bookingData.bookingData.bookingCount}</strong></td>
                  <td className={'text-center'}><strong>{bookingData.bookingData.bookingNights}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      totalNights: 1,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingRoomAnalyticsRoom);
