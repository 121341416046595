/**
 * Manage Order
 *  manages order update ( Post Payment discount )
 */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import * as Yup from 'yup';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {ErrorMessages, Strings} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
import OrderDiscountEdit from '../components/OrderDiscountEdit';
// Forms
import {Form, Formik} from 'formik';
import {confirmAlert} from '../../../components/general/ConfirmAlert';


/* Component ==================================================================== */

class OrderEdit extends React.Component {
  static componentName = 'OrderManageView';

  static propTypes = {
    match: PropTypes.object,
    order: PropTypes.object,
    property: PropTypes.object,
    updateResponse: PropTypes.func,
    editType: PropTypes.string,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        loading: false,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  updateOrder = (credentials = null) => {
    const {editType} = this.props;

    let payload = {};
    if (credentials) {
      switch (editType) {
      case 'postPaymentDiscount':
      default:
        payload.order_discount_post_payment = credentials.discount;
        payload.order_discount_post_payment_type = credentials.discount_type;
        payload.order_discount_post_payment_description = credentials.discount_description;
        if (credentials.percentage === 'true') {
          payload.order_discount_post_payment = parseFloat((credentials.discount * this.props.order.total_unpaid_payment) / 100).toFixed(2);
        }
        break;
      }
    }

    if (!_.isEmpty(payload)) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.orderapi.patch(`order-post-payment-update/${this.props.order.hash}/`, payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                this.props.updateResponse(res);
                this.props.onHide();
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {order, show, editType} = this.props;

    if (loading) return <Loading heightMatch={true}/>;
    if (!loading && error) return <Error full={true} text={ErrorMessages.initData}/>;
    if (!show) return <React.Fragment/>;

    let formValidation, formInputData;

    switch (editType) {
    case 'discount':
    default:
      formValidation = Yup.object().shape({
        discount: Yup.number().max(
          parseFloat(order.total_unpaid_payment), `Cannot exceed remaining payment ${order.currency} ${order.total_unpaid_payment}`
        ).required(),
        discount_type: Yup.number().required(),
        discount_description: Yup.string().max(200, 'Too Long!').nullable(),
      });
      formInputData = (formikProps) => <OrderDiscountEdit
        order={order} formikProps={formikProps}
        discountHint={`Pending payment ${order.currency} ${order.total_unpaid_payment} (max value)`}
      />;
      break;
    }

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Post Payment Discount
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            percentage: 'false',
            discount: parseFloat(order.order_discount_post_payment) > 0 ? order.order_discount_post_payment : order.total_unpaid_payment,
            discount_type: order.order_discount_post_payment_type,
            discount_description: order.order_discount_post_payment_description,
          }}
          validationSchema={formValidation}
          onSubmit={values => this.updateOrder(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <Form className={'form-group'}>
                      <div className="mt-2">
                        {formInputData(formikProps)}
                        <div className={'alert alert-info'}>
                          <p className={'mb-0'}>{Strings.orderPostPaymentDiscount}</p>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <button
                      type="submit"
                      onClick={() => {
                        confirmAlert({
                          title: 'Confirm Discount',
                          message: Strings.orderPostPaymentDiscount,
                          buttons: [
                            {
                              className: 'btn-success',
                              label: 'Confirm Discount',
                              onClick: formikProps.handleSubmit,
                            },
                            {
                              className: 'btn-secondary',
                              label: 'Exit',
                            },
                          ],
                        });
                      }}
                      className={'btn btn-success btn-lg btn-block'}
                    >
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default OrderEdit;
