/**
 *
 * Event Details
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link, NavLink, Redirect, Route, Switch} from 'react-router-dom';
// Components
import NotFound from '../NotFound';
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import PageHeader from '../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';

import TicketBookingDetails from './TicketBookingDetails';
import EventOverView from './EventOverView';
import OrderListIndex from '../order/OrderListIndex';
import TicketBookingListIndex from './TicketBookingListIndex';
import EventAvailabilityChart from '../availability/EventAvailabilityChart';
import TicketBookingAdd from './TicketBookingAdd';
import EventLookUp from './EventLookUp';
// Actions
import * as EventActions from '../../redux/event/actions';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  event: state.event.event,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setEvent: EventActions.setEvent,
};

/* Component ==================================================================== */
class EventDetails extends Component {
  static componentName = 'EventDetails';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    this.setState({
      loading: false,
    });
  };

  fetchInitData = () => {
    this.setState({
      loading: false,
    });
  };

  render = () => {

    const {loading, error} = this.state;
    const {event} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    let baseUrl = '/event';
    let absBaseUrl = `${baseUrl}/${event.hash}`;
    const menu = [
      {
        title: 'Event Details',
        link: `${absBaseUrl}/`,
        isExact: true,
      },
      {
        title: 'Ticket Bookings',
        link: `${absBaseUrl}/ticket-booking/`,
        isExact: false,
      },
      {
        title: 'Event Availability',
        link: `${absBaseUrl}/availability-chart/`,
        isExact: false,
      },
      {
        title: 'Ticket Lookup',
        link: `${absBaseUrl}/lookup/`,
        isExact: false,
      },
      // {
      //   title: 'Orders',
      //   link: `${absBaseUrl}/order/list/`,
      // },
    ];

    let menuItems = [];
    menu.map((item) => {
      const {title, link, isExact} = item;
      return menuItems.push(
        <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
          <NavLink
            to={{
              pathname: link,
              state: {fromDashboard: true},
            }}
            exact={isExact}
            className="nav-link rounded-0"
            activeClassName="selected">
            {title}
          </NavLink>
        </li>,
      );
    });

    return (
      <div className="guest-details screen-container ">
        <Helmet>
          <title>Event Details</title>
        </Helmet>

        {baseUrl &&
         <Link to={baseUrl} className={'btn btn-outline-dark mr-2 btn-sm mt-2'}>
           <FontAwesomeIcon icon={faArrowLeft} size={'sm'}/> {'Event List'}
         </Link>
        }

        <PageHeader
          subHeader={true}
          title={'Event Details'}
          description={event.name}
          history={this.props.history}
        />

        <ul className="nav nav-tabs mb-3">
          {menuItems}
        </ul>

        <Switch>

          <Route
            path={`${baseUrl}/:eventId/`} exact
            render={(props) =>
              <EventOverView
                {...props}
                event={event}
                baseUrl={absBaseUrl}
                property={this.props.property}
                reloadData={this.fetchInitData}
              />}
          />
          {/* Ticket Details */}
          <Route path={`${absBaseUrl}/availability-chart/`} exact component={EventAvailabilityChart}/>
          <Route
            path={`${absBaseUrl}/availability-chart/:start_date/to/:end_date/`} exact
            component={EventAvailabilityChart}
          />
          {/* Ticket Details */}
          <Route path={`${baseUrl}/:eventId/ticket-booking/booking/:ticketBookingId`} exact component={TicketBookingDetails}/>
          {/* Ticket List */}
          <Route
            exact
            path={`${absBaseUrl}/ticket-booking/`}
            render={() => (<Redirect to={`${absBaseUrl}/ticket-booking/list/all`}/>)}
          />
          <Route
            path={`${absBaseUrl}/ticket-booking/:date/list/:bookingType`}
            render={(props) =>
              <TicketBookingListIndex
                {...props}
                event={event}
                eventId={event.id}
                hideHeader={true}
                baseUrl={`${absBaseUrl}/ticket`}
                property={this.props.property}
                reloadData={this.fetchInitData}
              />}
          />
          <Route
            path={`${absBaseUrl}/ticket-booking/list/:bookingType`}
            render={(props) =>
              <TicketBookingListIndex
                {...props}
                event={event}
                eventId={event.id}
                hideHeader={true}
                baseUrl={`${absBaseUrl}/ticket`}
                property={this.props.property}
                reloadData={this.fetchInitData}
              />}
          />

          {/* Lookup */}
          <Route path={`${absBaseUrl}/lookup/`} exact component={(EventLookUp)}/>
          <Route path={`${absBaseUrl}/lookup/:date/`} exact component={(EventLookUp)}/>
          <Route path={`${absBaseUrl}/add/`} exact component={TicketBookingAdd}/>

          {/* Order List */}
          <Route
            exact
            path={`${baseUrl}/:guestId/order/`}
            render={() => (<Redirect to={`${absBaseUrl}/order/list`}/>)}
          />
          <Route
            path={`${baseUrl}/:guestId/order/list`}
            render={(props) =>
              <OrderListIndex
                {...props}
                hideHeader={true}
                baseUrl={absBaseUrl}
              />}
          />
          <Route exact component={NotFound}/>
        </Switch>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventDetails);