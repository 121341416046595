/**
 * Order Actions
 *
 */
import AppAPI from '../../lib/api';

/**
 * Set Order
 */
export const setOrder = (orderData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof orderData === 'object') {
    dispatch({
      type: 'SET_ORDER',
      data: orderData,
    });
    return resolve(orderData);
  } else if (orderData) {
    // Fetch Order Data
    return AppAPI.order.get(orderData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_ORDER',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Order
 */
export const updateOrder = (orderData) => setOrder(orderData);

/**
 * Remove Order
 */
export function unSetOrder() {
  return (dispatch) => {
    dispatch({
      type: 'SET_ORDER',
      data: null,
    });
  };
}


/**
 * Set Order
 */
export const setOrderItem = (orderItemData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof orderItemData === 'object') {
    dispatch({
      type: 'SET_ORDER_ITEM',
      data: orderItemData,
    });
    return resolve(orderItemData);
  } else if (orderItemData) {
    // Fetch Order Data
    return AppAPI.orderitem.get(orderItemData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_ORDER_ITEM',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Remove Order
 */
export function unSetOrderItem() {
  return (dispatch) => {
    dispatch({
      type: 'SET_ORDER_ITEM',
      data: null,
    });
  };
}