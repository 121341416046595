/**
 * Billing Order Reducer
 *
 */

// Set initial state
const initialState = {
  billing: {},
  billingorder: {},
  plandata: {},
  payment: {},
  purchasedplan: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
  case 'SET_BILLING_ORDER': {
    let billingorder = [];
    if (action.data && typeof action.data === 'object') {
      billingorder = action.data;
    }
    return {
      ...state,
      billingorder,
    };
  }

  case 'SET_BILLING_PLAN_DATA': {
    let plandata = [];
    if (action.data && typeof action.data === 'object') {
      plandata = action.data;
    }
    return {
      ...state,
      plandata
    };
  }

  case 'SET_BILLING_PAYMENT': {
    let payment = [];
    if (action.data && typeof action.data === 'object') {
      payment = action.data;
    }
    return {
      ...state,
      payment,
    };
  }

  case 'SET_BILLING_PURCHASE': {
    let purchasedplan = [];
    if (action.data && typeof action.data === 'object') {
      purchasedplan = action.data;
    }
    return {
      ...state,
      purchasedplan,
    };
  }

  default:
    return state;
  }
};
