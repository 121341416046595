/**
 * Manage Profile credentials verification
 *  manages profile email / phone number
 */
import React, {Component} from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';
import {Modal} from 'react-bootstrap';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants, ErrorMessages} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import {Input} from 'react-formik-ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faIdCard, faPaperPlane, faStarOfLife, faSync} from '@fortawesome/free-solid-svg-icons';
import Moment from 'moment';


/* Component ==================================================================== */

class ProfileVerification extends Component {
  static componentName = 'Verification';

  static propTypes = {
    profile: PropTypes.object,
    type: PropTypes.string.isRequired,
    updateResponse: PropTypes.func,
  };

  static defaultProps = {
    title: 'Guest',
    profile_type: Constants.PROFILE_TYPE.GUEST,
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        starterScreen: true,
        resendCode: false,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  startTiming() {
    this.interval = setInterval(() => {
      const delta = Moment().diff(this.state.requestTime, 'second');
      this.setState({
        resendCode:(delta >= 30),
        remainingTime: 30 - delta,
      });

      if (delta >= 30) {
        this.setState({remainingTime: null});
        clearInterval(this.interval);
      }
    }, 1000);
  }

  componentWillUnmount() {
    if(this.interval) {
      clearInterval(this.interval);
    }
  }

  verifyUserProfile = (payload, close = true) => {

    const {profile} = this.props;
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}});

      let Api;

      if (profile.profile_type && profile.hash) {
        Api = AppAPI.guest.post(`${profile.hash}/verification/`, payload);
      } else {
        Api = AppAPI.account.post('verification/', payload);
      }

      Api.then((res) => {
        this.setState({resultMsg: {success: res.message}},
          () => {
            setTimeout(() => {
              this.setState({
                resultMsg: '',
                resendCode: false,
                starterScreen: false,
                requestTime : Moment()
              });
              this.startTiming();
              if (close) {
                this.props.updateResponse();
                this.props.onHide();
              }
            }, 1000);
          });
      })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  render = () => {

    const {loading, error, resultMsg, starterScreen, resendCode, remainingTime} = this.state;
    const {type, profile, show} = this.props;

    if (!show) return null;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    const formValidation = Yup.object().shape({
      code: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Enter verification code.'),
    });

    const verificationType = (type === 'phone' ? 'phone number' : 'e-mail address');
    const verificationId = (type === 'phone' ? profile.phone : profile.email);
    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Contact Verification
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{code: ''}}
          validationSchema={formValidation}
          onSubmit={values => this.verifyUserProfile({verification_code: values.code, verification_type: type})}
        >
          {(formikProps) => (
            <Form>
              <Modal.Body>
                <div className="row px-3">
                  <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    {starterScreen ?
                      <React.Fragment>
                        <div className={'text-center'}>
                          <FontAwesomeIcon icon={faIdCard} className={'my-3'} size={'5x'}/>
                        </div>
                        <p>In order to ensure the contact {verificationType} is correct, renzo system will send a
                          temporary verification code to {verificationId}.</p>
                        <div className={'text-center'}>
                          <button
                            type={'button'}
                            className={'btn btn-link p-0 m-0 small text-muted'}
                            onClick={() => this.setState({starterScreen: false, resendCode: true})}
                          >
                            Already received the code ?
                          </button>
                        </div>
                      </React.Fragment>
                      :
                      <React.Fragment>
                        <div className={'text-center'}>
                          <p className={'my-3'}>
                            <FontAwesomeIcon icon={faStarOfLife} className={'mx-1'} size={'2x'}/>
                            <FontAwesomeIcon icon={faStarOfLife} className={'mx-1'} size={'2x'}/>
                            <FontAwesomeIcon icon={faStarOfLife} className={'mx-1'} size={'2x'}/>
                            <FontAwesomeIcon icon={faStarOfLife} className={'mx-1'} size={'2x'}/>
                          </p>
                          <p>Please enter the verification code send to the {verificationType}  <strong>{verificationId}</strong>.</p>
                        </div>
                        <form className={'form-group mb-0'}>
                          <div className="form-row">
                            <div className="col-12 col-md-8 form-group mx-auto">
                              <Input
                                name="code"
                                placeholder={'Verification Code'}
                                autocomplete={'off'}
                                className={'form-control'}
                              />
                            </div>
                          </div>
                          <div className={'text-center'}>
                            <button
                              disabled={!resendCode}
                              type={'button'}
                              className={'btn btn-link p-0 m-0 small'}
                              onClick={() => this.verifyUserProfile({verification_type: type, resend_verification_code: true,}, false)}
                            >
                              <FontAwesomeIcon className={'mr-2'} icon={faSync} size={'sm'}/> re-send verification code
                              {remainingTime && `( ${remainingTime} Seconds )`}
                            </button>
                          </div>
                        </form>
                      </React.Fragment>
                    }
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className="px-3">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    {starterScreen ?
                      <button
                        type={'button'}
                        onClick={
                          () => this.verifyUserProfile({
                            verification_type: type,
                            resend_verification_code: true,
                          }, false)
                        }
                        className={'btn btn-primary btn-block btn-lg'}>
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faPaperPlane} size={'sm'}/> Send Verification
                        Code
                      </button>
                      :
                      <button
                        type="submit" onClick={formikProps.handleSubmit} className={'btn btn-success btn-block btn-lg'}>
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faCheckCircle} size={'sm'}/> Verify
                      </button>
                    }
                  </div>
                </div>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      starterScreen: true,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default ProfileVerification;
