/**
 *
 * POS Content Settings
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import _ from 'lodash';
// Consts and Libs
import {Alerts} from '../../../components/ui';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen} from '@fortawesome/free-solid-svg-icons';

// Actions

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pos: state.pos.pos,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class POSContentSettings extends Component {
  static componentName = 'POSContentSettings';
  static propTypes = {
    refreshable: PropTypes.bool,
    match: PropTypes.object.isRequired,
  };

  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {pos} = this.props;
    const printContent = pos.print_content || {};

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="pos-item-list screen-container ">
        <Helmet>
          <title>POS Sequence Settings</title>
        </Helmet>

        <Alerts
          status={resultMsg.status}
          success={resultMsg.success}
          error={resultMsg.error}
        />

        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12 mb-5">
            <h6>POS Bill Design</h6>
            <p className={'small text-muted'}>Descriptions, footer notes and other parameter of bill.</p>
            <hr/>
            <div className={'row'}>
              <div className={'col-sm-12 col-md-10 mx-auto'}>
                <div className={'border p-2 text-center anchor w-100'}>
                  <h6 className={'mb-0'}>
                    POS Invoice/Order Settings
                  </h6>
                </div>
                <div className={'border p-2 mb-3'} id={'primaryAction'}>
                  <div className={'row'}>
                    <div className={'col-12 text-center'}>
                      {_.isEmpty(printContent.order) ?
                        <p className={'text-muted text-center'}>No Contents Set</p>
                        :
                        <p>
                          <strong>{printContent.order.header_title || 'N.A'}</strong> <br/>
                          {printContent.order.header_sub_title || 'N.A'}
                        </p>
                      }
                    </div>
                  </div>
                  <hr/>

                  <div className={'bg-light py-5 text-center'}>
                    <p className={'text-muted'}>Order Content</p>
                  </div>

                  <hr/>
                  {_.isEmpty(printContent.order) ?
                    <p className={'text-muted text-center'}>No Contents Set</p>
                    :
                    <p className="mb-1">
                      <pre className={'w-100'}>{printContent.order.footer_notes || 'N.A'}</pre>
                    </p>
                  }
                </div>
              </div>
            </div>

            <p className={'mb-2'}>Dimensions, height of POS bill elements. ( All Dimensions in Inch)</p>
            <table className={'table table-sm table-bordered'}>
              <thead>
                <tr>
                  <th>Page Width</th>
                  <th>Header Height</th>
                  <th>Footer Height</th>
                  <th>Line Height</th>
                </tr>
              </thead>
              <tbody>
                <tr className={'text-center'}>
                  <td>{pos.bill_width}</td>
                  <td>{pos.bill_header_height}</td>
                  <td>{pos.bill_footer_height}</td>
                  <td>{pos.bill_item_height}</td>
                </tr>
              </tbody>
            </table>

            {pos.pos_has_kot &&
            <React.Fragment>
              <div className={'spacer-20'}/>
              <h6>POS KOT Design</h6>
              <p className={'small text-muted'}>Descriptions, footer notes and other parameter of KOT.</p>
              <hr/>
              <div className={'row'}>
                <div className={'col-sm-12 col-md-10 mx-auto'}>
                  <div className={'border p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      POS KOT Settings
                    </h6>
                  </div>
                  <div className={'border p-2 mb-3'} id={'primaryAction'}>
                    <div className={'row'}>
                      <div className={'col-12 text-center'}>
                        {_.isEmpty(printContent.kot) ?
                          <p className={'text-muted text-center'}>No Contents Set</p>
                          :
                          <p>
                            <strong>{printContent.kot.header_title || 'N.A'}</strong> <br/>
                            {printContent.kot.header_sub_title || 'N.A'}
                          </p>
                        }
                      </div>
                    </div>
                    <hr/>

                    <div className={'bg-light py-5 text-center'}>
                      <p className={'text-muted'}>KOT Content</p>
                    </div>
                  </div>
                </div>
              </div>

              <p className={'mb-2'}>Dimensions, height of POS kitchen order ticket elements. ( All Dimensions in Inch)</p>
              <table className={'table table-sm table-bordered'}>
                <thead>
                  <tr>
                    <th>Page Width</th>
                    <th>Header Height</th>
                    <th>Footer Height</th>
                    <th>Line Height</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className={'text-center'}>
                    <td>{pos.kot_width}</td>
                    <td>{pos.kot_header_height}</td>
                    <td>{pos.kot_footer_height}</td>
                    <td>{pos.kot_item_height}</td>
                  </tr>
                </tbody>
              </table>

            </React.Fragment>
            }
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item">
                <Link
                  to={`/settings/pos/${pos.hash}/content/edit`}
                  className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      sequenceData: {},
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(POSContentSettings);