/**
 * Order Bifurcation Table
 *
 */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
// Components
import OABifurcationTable from './OABifurcationTable';

/* Component ==================================================================== */
class OADeduction extends Component {
  static componentName = 'OADeduction';

  static propTypes = {
    expandData: PropTypes.func,
    property: PropTypes.object.isRequired,
    orderData: PropTypes.object.isRequired,
    deductionKey: PropTypes.string.isRequired,
    deductionType: PropTypes.string.isRequired,
    bifurcationData: PropTypes.object.isRequired,
    bifurcationTitle: PropTypes.string.isRequired,
    deductionTypeShort: PropTypes.string.isRequired,
  };


  render = () => {
    const {
      orderData,
      property,
      deductionType,
      deductionTypeShort,
      deductionKey,
      expandData,
      bifurcationData,
      bifurcationTitle
    } = this.props;

    return (
      <>
        <div className={'row'}>
          <div className={'col-12 col-lg-12 mb-3'}>
            <OABifurcationTable
              property={property}
              orderData={orderData}
              expandData={expandData}
              deductionKey={deductionKey}
              deductionType={deductionType}
              bifurcationData={bifurcationData}
              bifurcationTitle={bifurcationTitle}
              deductionTypeShort={deductionTypeShort}
            />
          </div>
        </div>
      </>
    );
  };
}

/* Export Component ==================================================================== */
export default OADeduction;

