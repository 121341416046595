/**
 *
 * App Dashboard
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';

// Actions
import * as PropertyActions from '../../redux/property/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetPropertyDetails: PropertyActions.isSetPropertyDetails
};

/* Component ==================================================================== */
class Dashboard extends Component {
  static componentName = 'Dashboard';
  componentDidMount = () => {
    this.props.isSetPropertyDetails()
      .then(() => {
        this.setState({loading: false});
      })
      .catch(() => {
        this.props.history.push('/others/noaccess');
      });
  };

  render = () => {
    const {loading, error} = this.state;
    const {property} = this.props;

    if (!property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="dashboard screen-container">
        <Helmet>
          <title>Settings Overview</title>
        </Helmet>

        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 border-right mb-5">
            <div className={'w-25 mb-4'}>
              {property.logo && property.logo.logo_2x ?
                <img width={'150'} src={property.logo.logo_2x} alt={property.name} className="img-thumbnail"/>
                :
                <img className={'w-100'} alt="Brand" src={require('../../images/logo-placeholder.png')}/>
              }
            </div>

            <ul className="list-group mt-3">
              <li className="list-group-item">
                <p className="mb-1"><strong>Name :</strong> {property.name}</p>
              </li>
              <li className="list-group-item">
                <p className="mb-1"><strong>Address :</strong> {property.address}
                  {property.address_2 && ', ' + property.address_2}
                  {property.state && ', ' + property.state}
                  {property.country ? ', ' + property.country.name : ''}</p>
              </li>

              <li className="list-group-item">
                <p className="mb-1"><strong>Tax Name :</strong> {property.tax_name ? property.tax_name : 'N.A'}</p>
              </li>

              <li className="list-group-item">
                <p className="mb-1"><strong>Tax Number :</strong> {property.tax_number ? property.tax_number : 'N.A'}
                </p>
              </li>
            </ul>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
            {/*<h5>Usage Statistic's</h5>*/}
            {/*<div className={'row mb-5'}>*/}
            {/*  <div className="col-lg-6 col-md-6 col-sm-12">*/}
            {/*    <ul className="list-group mt-3">*/}
            {/*      <li className="list-group-item">*/}
            {/*        <div className="d-flex w-100 justify-content-between">*/}
            {/*          <p className="mb-1">Booking Amendments (s)</p>*/}
            {/*          <span*/}
            {/*            className={'badge ' + (this.props.property.counts && this.props.property.counts.booking_amendment > 0 ? 'badge-success' : 'badge-dark')}>*/}
            {/*            {this.props.property.counts && this.props.property.counts.booking_amendment ?*/}
            {/*              this.props.property.counts.booking_amendment :*/}
            {/*              '0'}*/}
            {/*          </span>*/}
            {/*        </div>*/}
            {/*      </li>*/}

            {/*      <li className="list-group-item">*/}
            {/*        <div className="d-flex w-100 justify-content-between">*/}
            {/*          <p className="mb-1">Corporate / Agent (s)</p>*/}
            {/*          <span*/}
            {/*            className={'badge ' + (this.props.property.counts && this.props.property.counts.corporate > 0 ? 'badge-success' : 'badge-dark')}>*/}
            {/*            {this.props.property.counts && this.props.property.counts.corporate ? this.props.property.counts.corporate : '0'}*/}
            {/*          </span>*/}
            {/*        </div>*/}
            {/*      </li>*/}

            {/*      <li className="list-group-item">*/}
            {/*        <div className="d-flex w-100 justify-content-between">*/}
            {/*          <p className="mb-1">Expense Category (s)</p>*/}
            {/*          <span*/}
            {/*            className={'badge ' + (this.props.property.counts && this.props.property.counts.expense_category > 0 ? 'badge-success' : 'badge-dark')}>*/}
            {/*            {this.props.property.counts && this.props.property.counts.expense_category ?*/}
            {/*              this.props.property.counts.expense_category :*/}
            {/*              '0'}*/}
            {/*          </span>*/}
            {/*        </div>*/}
            {/*      </li>*/}

            {/*      <li className="list-group-item">*/}
            {/*        <div className="d-flex w-100 justify-content-between">*/}
            {/*          <p className="mb-1">Expense Department (s)</p>*/}
            {/*          <span*/}
            {/*            className={'badge ' + (this.props.property.counts && this.props.property.counts.expense_department > 0 ? 'badge-success' : 'badge-dark')}>*/}
            {/*            {this.props.property.counts && this.props.property.counts.expense_department ?*/}
            {/*              this.props.property.counts.expense_department :*/}
            {/*              '0'}*/}
            {/*          </span>*/}
            {/*        </div>*/}
            {/*      </li>*/}
            {/*    </ul>*/}
            {/*  </div>*/}

            {/*  <div className="col-lg-6 col-md-6 col-sm-12">*/}
            {/*    <ul className="list-group mt-3">*/}
            {/*      <li className="list-group-item">*/}
            {/*        <div className="d-flex w-100 justify-content-between">*/}
            {/*          <p className="mb-1">Expense Vendor (s)</p>*/}
            {/*          <span*/}
            {/*            className={'badge ' + (this.props.property.counts && this.props.property.counts.expense_vendor > 0 ? 'badge-success' : 'badge-dark')}>*/}
            {/*            {this.props.property.counts && this.props.property.counts.expense_vendor ?*/}
            {/*              this.props.property.counts.expense_vendor :*/}
            {/*              '0'}*/}
            {/*          </span>*/}
            {/*        </div>*/}
            {/*      </li>*/}

            {/*      <li className="list-group-item">*/}
            {/*        <div className="d-flex w-100 justify-content-between">*/}
            {/*          <p className="mb-1">Point of Sales (s)</p>*/}
            {/*          <span*/}
            {/*            className={'badge ' + (this.props.property.counts && this.props.property.counts.pos > 0 ? 'badge-success' : 'badge-dark')}>*/}
            {/*            {this.props.property.counts && this.props.property.counts.pos ?*/}
            {/*              this.props.property.counts.pos :*/}
            {/*              '0'}*/}
            {/*          </span>*/}
            {/*        </div>*/}
            {/*      </li>*/}

            {/*      <li className="list-group-item">*/}
            {/*        <div className="d-flex w-100 justify-content-between">*/}
            {/*          <p className="mb-1">Room (s)</p>*/}
            {/*          <span*/}
            {/*            className={'badge ' + (this.props.property.counts && this.props.property.counts.room > 0 ? 'badge-success' : 'badge-dark')}>*/}
            {/*            {this.props.property.counts && this.props.property.counts.room ?*/}
            {/*              this.props.property.counts.room :*/}
            {/*              '0'}*/}
            {/*          </span>*/}
            {/*        </div>*/}
            {/*      </li>*/}

            {/*      <li className="list-group-item">*/}
            {/*        <div className="d-flex w-100 justify-content-between">*/}
            {/*          <p className="mb-1">Room Type (s)</p>*/}
            {/*          <span*/}
            {/*            className={'badge ' + (this.props.property.counts && this.props.property.counts.room_type > 0 ? 'badge-success' : 'badge-dark')}>*/}
            {/*            {this.props.property.counts && this.props.property.counts.room_type ?*/}
            {/*              this.props.property.counts.room_type :*/}
            {/*              '0'}*/}
            {/*          </span>*/}
            {/*        </div>*/}
            {/*      </li>*/}
            {/*    </ul>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
