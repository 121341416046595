/**
 * Sidebar
 */
import {NavLink, Route, Switch, withRouter} from 'react-router-dom';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';

import InventorySettings from './InventorySettings';
import PropertySettings from './PropertySettings';
import ExpenseSettings from './ExpenseSettings';
import ProfileSettings from './ProfileSettings';
import EventSettings from './EventSettings';
import POSSettings from './POSSettings';
import OverView from './OverView';

import NotFound from '../NotFound';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};


/* Component ==================================================================== */
class Settings extends Component {
  static componentName = 'Settings';


  render = () => {
    const {property} = this.props;
    let menu = [
      {
        title: 'Overview',
        link: '/settings',
        isExact: true,
      },
      {
        title: 'Property',
        link: '/settings/property',
      }];

    if (!_.isEmpty(property)) {
      if (!_.isEmpty(property.modules)) {
        if (property.modules.indexOf('booking') > -1) {
          menu.push(
            {
              title: 'Room Inventory',
              link: '/settings/inventory',
            }
          );
        }

        if (property.modules.indexOf('pos') > -1) {
          menu.push(
            {
              title: 'Point of Sales',
              link: '/settings/pos',
            }
          );
        }

        if (property.modules.indexOf('expense') > -1) {
          menu.push(
            {
              title: 'Expense',
              link: '/settings/expense',
            }
          );
        }

        if (property.modules.indexOf('event') > -1) {
          menu.push(
            {
              title: 'Event',
              link: '/settings/event',
            }
          );
        }
      }
    }

    return (
      <div>

        <div className="container">
          <div className="my-3">
            <h1>Settings</h1>
            <p>Property Configurations and settings.</p>
          </div>
          <ul className="nav nav-tabs mb-3">
            {menu.map((data, i) => (
              <li className="nav-item" key={`menu-item-${i}`}>
                {data.disabled ?
                  <div className="nav-link disabled">{data.title}</div>
                  :
                  <NavLink
                    className="nav-link"
                    exact={data.isExact}
                    activeClassName="selected"
                    to={data.link}>
                    {data.title}
                  </NavLink>
                }
              </li>
            ))}
          </ul>

          <Switch>
            <Route path="/settings" exact component={OverView}/>
            <Route path="/settings/inventory" component={InventorySettings}/>
            <Route path="/settings/property" component={PropertySettings}/>
            <Route path="/settings/expense" component={ExpenseSettings}/>
            <Route path="/settings/event" component={EventSettings}/>
            <Route path="/settings/pos" component={POSSettings}/>
            <Route path="/settings/profile" component={ProfileSettings}/>

            <Route exact component={NotFound}/>
          </Switch>
        </div>

      </div>
    );
  };
}


/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Settings));
