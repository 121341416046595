/**
 *
 * Order Analytics Discount Type
 */
import _ from 'lodash';
import Moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import PageHeader from '../../../components/dashboard/PageHeader';

// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import { AnalyticConstants, Constants } from '../../../constants';
// Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import OADeduction from './components/OADeduction';
import OABifurcationViewModel from './models/OABifurcationViewModel';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class OrderAnalyticsOrderDiscount extends Component {
  static componentName = 'OrderAnalyticsOrderDiscount';

  static propTypes = {
    pos: PropTypes.string,
    endDate: PropTypes.object,
    startDate: PropTypes.object
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {startDate, endDate, dateType, pos} = this.props;
    this.setState({loading: true});
    const queryParams = {
      'date_type': dateType,
      'pos': pos ? pos : null,
      'report_type': 'order_discount',
      'start_date': (startDate ? startDate : Moment()).format('YYYY-MM-DD'),
      'end_date': endDate ? endDate.format('YYYY-MM-DD') : null
    };
    AppAPI[pos ? 'posapi' : 'orderapi'].get(`order-analytics/?${queryString.stringify(queryParams)}`)
      .then((res) => {
        this.setState({loading: false, response: res});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({loading: false, error: error});
      });
  };

  render = () => {
    const {loading, error, response} = this.state;
    const {property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    // Initialize with empty array for order data set
    const orderDataSet = {};
    Object.keys(AnalyticConstants.orderAnalytics).forEach((data) => {
      orderDataSet[data] = {};
    });

    // Containers for order status data & order source data
    const orderData = {
      orderDiscountSourceTypeData: {},
      orderDiscountSourceData: _.cloneDeep(orderDataSet),
      orderDiscountTypeData: _.cloneDeep(orderDataSet),
    };

    Object.values(Constants.ORDER_SOURCES_INPUT).forEach((data) => {
      orderData.orderDiscountSourceTypeData[data.value] = _.cloneDeep(orderDataSet);
    });


    if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
      const responseData = response.data;
      if (!_.isEmpty(responseData.order_discount)) {
        responseData.order_discount.forEach((data) => {
          Object.entries(AnalyticConstants.orderAnalytics).forEach(([key, value]) => {
            if (data[value]) {
              AppUtil.insertOrAddObject(orderData.orderDiscountTypeData[key], data.order_discount_type, data[value], 'float');
              AppUtil.insertOrAddObject(orderData.orderDiscountSourceData[key], data.order_source, data[value], 'float');
              AppUtil.insertOrAddObject(orderData.orderDiscountSourceTypeData[data.order_source][key], data.order_discount_type, data[value], 'float');
            }
          });
        });
      }
    }

    const deductionKey= 'orderDiscount';
    const deductionType='Order Discount';

    const deductionPercentage = AppUtil.calculatePercentage(
      AppUtil.sumArray(Object.values(orderData.orderDiscountTypeData[deductionKey]), 'float'),
      AppUtil.sumArray(Object.values(orderData.orderDiscountTypeData.orderSubTotalBilled), 'float')
    );

    return (
      <div className="screen-container">
        <Helmet>
          <title>Order Analytics : Order Discount</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={deductionType}
          description={`${deductionType} bifurcation based on type & source.`}
        />

        <div className={'row mb-3'}>
          <div className={'col'}>
            <div className={'card mb-5'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col-6'}>
                    <p className={'text-muted mb-1'}>Total Order(s)</p>
                    <h4 className={'card-title mb-3'}>
                      {`${property.currency} ${AppUtil.sumArray(Object.values(orderData.orderDiscountTypeData.orderTotal), 'float')}`}
                    </h4>
                  </div>
                  <div className={'col-6'}>
                    <p className={'text-muted mb-1'}>{deductionType}</p>
                    <h4 className={'blue-cl mb-3'}>
                      {`${property.currency} ${AppUtil.sumArray(Object.values(orderData.orderDiscountTypeData[deductionKey]), 'float')}`}
                    </h4>
                  </div>
                </div>
                <p className={'text-muted mb-2'}>
                  {AppUtil.sumArray(Object.values(orderData.orderDiscountTypeData.orderCount))} entries
                  with {deductionType.toLowerCase()}.
                </p>
              </div>
            </div>
          </div>
          <div className={'col'}>
            <div className={'card'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col '}>
                    <p className={'text-muted mb-1'}>Item Total</p>
                    <h5 className={'card-title mb-0 pb-0'}>
                      {`${property.currency} ${AppUtil.sumArray(Object.values(orderData.orderDiscountTypeData.orderSubTotalBilled), 'float')}`}
                    </h5>
                  </div>
                  <div className={'col'}>
                    <p className={'text-muted mb-1'}>{deductionType}</p>
                    <h5 className={'card-title mb-0 pb-0 blue-cl'}>
                      {`${property.currency} ${AppUtil.sumArray(Object.values(orderData.orderDiscountTypeData[deductionKey]), 'float')}`}
                    </h5>
                  </div>
                </div>
                <div className="progress mt-3 blue-bg">
                  <div
                    className="progress-bar green-bg"
                    style={{width: `${100-deductionPercentage}%`}}
                    aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"/>
                </div>
                <div className={'row'}>
                  <div className={'col-12 text-right'}>
                    <p className={'text-muted mb-0 mt-1 small'}>{deductionPercentage}% of Item total</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <OADeduction
          property={property}
          deductionKey={'orderDiscount'}
          deductionTypeShort={'Discount'}
          deductionType={'Order Discount'}
          bifurcationTitle={'Discount Type'}
          orderData={orderData.orderDiscountTypeData}
          bifurcationData={Constants.ORDER_DISCOUNT_TYPE}
        />

        <PageHeader
          subHeader={true}
          title={'Order Discount'}
          description={'Order discount bifurcation by source .'}
        />

        <OADeduction
          property={property}
          deductionKey={'orderDiscount'}
          deductionTypeShort={'Discount'}
          deductionType={'Order Discount'}
          bifurcationTitle={'Order Source'}
          orderData={orderData.orderDiscountSourceData}
          bifurcationData={Constants.ORDER_SOURCES_INPUT}
          expandData={(data) => {
            this.setState({
              showOABifurcationViewModel: true,
              OABifurcationViewModelTitle: data.label,
              OABifurcationViewModelData: orderData.orderDiscountSourceTypeData[data.value]
            });
          }}
        />

        <OABifurcationViewModel
          show={this.state.showOABifurcationViewModel}
          onHide={() => this.setState({showOABifurcationViewModel: false})}
          orderData={this.state.OABifurcationViewModelData}
          title={this.state.OABifurcationViewModelTitle}
          bifurcationTitle={'Discount Type'}
          bifurcationData={Constants.ORDER_DISCOUNT_TYPE}
          property={property}
          deductionKey={'orderDiscount'}
          deductionTypeShort={'Discount'}
          deductionType={'Order Discount'}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      showOABifurcationViewModel: false,
      OABifurcationViewModelData: null,
      OABifurcationViewModelTitle: null,
      error: null,
      loading: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderAnalyticsOrderDiscount);
