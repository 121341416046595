/**
 *
 * Property Front Desk Settings
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {NavLink, Route, Switch} from 'react-router-dom';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';

import PropertyChannelManagerOverview from './PropertyFrontDeskOverview';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PropertyFrontDeskConfig extends Component {
  static componentName = 'PropertyFrontDeskConfig';

  componentDidMount = () => {
  };


  render = () => {
    const {loading, error} = this.state;

    if (!this.props.property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;


    let baseUrl = '/settings/property/front-desk';
    const menu = [
      {
        title: 'Overview',
        link: `${baseUrl}/`,
        isExact: true,
      },
    ];

    let menuItems = [];
    menu.map((item) => {
      const {title, link, isExact} = item;
      return menuItems.push(
        <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
          <NavLink
            to={{
              pathname: link,
              state: {fromDashboard: true},
            }}
            exact={isExact}
            className="nav-link rounded-0"
            activeClassName="selected">
            {title}
          </NavLink>
        </li>,
      );
    });

    return (
      <div className="front-desk-settings screen-container">
        <Helmet>
          <title>Front Desk Settings</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'Front Desk Settings'}
        />

        <ul className="nav nav-tabs mb-3">
          {menuItems}
        </ul>
        <Switch>
          <Route path={baseUrl} exact component={PropertyChannelManagerOverview}/>
        </Switch>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyFrontDeskConfig);
