/**
 * PaymentTerminalManage
 *  Payment Terminal Manage
 */
import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';

// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants, ErrorMessages, Strings} from '../../../constants';

// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PageHeader from '../../../components/dashboard/PageHeader';
import {Alerts} from '../../../components/ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faSave} from '@fortawesome/free-solid-svg-icons';
// Forms
import {Checkbox, Form, Input, Select, SubmitBtn, Textarea} from 'react-formik-ui';
import {Formik} from 'formik';
import * as Yup from 'yup';

// Actions
import * as PropertyActions from '../../../redux/property/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetProperty: PropertyActions.isSetProperty,
};

/* Component ==================================================================== */

class PaymentTerminalManage extends Component {
  static componentName = 'PaymentTerminalManage';

  static propTypes = {
    match: PropTypes.object,
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  addPaymentTerminal = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        let payload = {
          'name': credentials.name,
          'description': credentials.description,
          'payment_method': credentials.payment_method,
          'refund_allowed': credentials.refund_allowed,
          'payment_allowed': credentials.payment_allowed,
          'short_description': credentials.short_description,
        };

        AppAPI.propertyapi.post('payment-terminal/', payload)
          .then(() => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.history.goBack();
                }, 500);
              });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };
  savePaymentTerminal = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        let payload = {
          'description': credentials.description,
          'refund_allowed': credentials.refund_allowed,
          'payment_allowed': credentials.payment_allowed,
          'short_description': credentials.short_description,
          'active': credentials.active,
        };

        AppAPI.propertyapi.patch(`payment-terminal/${this.props.match.params.paymentTerminalId}/`, payload)
          .then(() => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.history.goBack();
                }, 500);
              });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };


  fetchInitData = () => {
    if (this.props.match.params.paymentTerminalId) {
      AppAPI.propertyapi.get(`payment-terminal/${this.props.match.params.paymentTerminalId}/`)
        .then((res) => {
          this.setState({
            paymentTerminal: res,
            loading: false
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  render = () => {

    const {loading, error, resultMsg, paymentTerminal} = this.state;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    const formValidation = Yup.object().shape({
      name: Yup.string().min(2, 'Too Short!').required('Required'),
      payment_method: Yup.string().required('Required'),
      short_description: Yup.string().max(100, 'Too Long!'),
    });

    const initialValues = {
      'name': paymentTerminal ? paymentTerminal.name : '',
      'active': paymentTerminal ? paymentTerminal.active : true,
      'description': paymentTerminal ? paymentTerminal.description : '',
      'payment_method': paymentTerminal ? paymentTerminal.payment_method : '',
      'refund_allowed': paymentTerminal ? paymentTerminal.refund_allowed : true,
      'payment_allowed': paymentTerminal ? paymentTerminal.payment_allowed : true,
      'short_description': paymentTerminal ? paymentTerminal.short_description : '',
    };

    return (
      <div className="tax-manage screen-container ">
        <Helmet>
          <title>Property Class Manage</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'Payment Terminal'}
          description={'Manage payment terminal'}
        />

        <Formik
          onSubmit={(values) => paymentTerminal ? this.savePaymentTerminal(values) : this.addPaymentTerminal(values)}
          validationSchema={formValidation}
          initialValues={initialValues}>
          {() => (
            <Form>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Payment Terminal Information
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-md-6 col-sm-12 form-group">
                        <Input
                          required
                          disabled={!_.isEmpty(paymentTerminal)}
                          name='name'
                          label={'Terminal Name'}
                          hint={'Cannot be edited post creation.'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-md-6 col-sm-12 form-group">
                        <Select
                          disabled={!_.isEmpty(paymentTerminal)}
                          className={'form-control rounded-right-0'}
                          name='payment_method'
                          label={'Payment Method'}
                          hint={'Cannot be edited post creation.'}
                          placeholder='Select an Option'
                          options={Constants.PAYMENT_METHODS_OFFLINE_INPUTS}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-12 form-group">
                        <Input
                          name='short_description'
                          label={'Short Description'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-12 form-group">
                        <Textarea
                          name='description'
                          label={'Description'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <hr/>
                    <div className="form-row">
                      <div className="col-6 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='payment_allowed'
                          label='Allow Payment'
                          text={'Is payments allowed from this terminal.?'}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='refund_allowed'
                          label='Allow Refund'
                          text={'Is refunds allowed from this terminal.?'}
                        />
                      </div>
                    </div>
                    {!_.isEmpty(paymentTerminal) &&
                    <React.Fragment>
                      <hr/>
                      <div className="form-row">
                        <div className="col-6 form-group">
                          <Checkbox
                            className={'mr-2'}
                            name='active'
                            label='Terminal Active'
                            text={'Check to activate terminal.'}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                    }
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                  <p className={''}><strong>Name : </strong> {Strings.paymentTerminalName}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 mt-2">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn
                    className={'btn btn-success btn-block btn-lg mt-2'}
                  >
                    <FontAwesomeIcon className={'white-cl mr-2'} icon={paymentTerminal ? faSave : faPlus} size={'sm'}/>
                    {paymentTerminal ? 'Save' : 'Add'}
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>

    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      paymentTerminal: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      settings: false,
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(PaymentTerminalManage);
