/**
 *
 * Order Analytics : POS
 */
import _ from 'lodash';
import Moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Helmet from 'react-helmet';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import { AnalyticConstants, Constants, Strings } from '../../../constants';
// Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
// Components
import Error from '../../../components/general/Error';
import OAOverview from './components/OAOverview';
import OAOverviewModel from './models/OAOverviewModel';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquare, faReceipt } from '@fortawesome/free-solid-svg-icons';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class OrderAnalyticsPOS extends Component {
  static componentName = 'OrderAnalyticsPOS';

  static propTypes = {
    endDate: PropTypes.object,
    startDate: PropTypes.object
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {startDate, endDate, dateType} = this.props;
    this.setState({loading: true});
    const queryParams = {
      'date_type': dateType,
      'report_type': 'point_of_sales',
      'start_date': (startDate ? startDate : Moment()).format('YYYY-MM-DD'),
      'end_date' : endDate ? endDate.format('YYYY-MM-DD') : null
    };
    AppAPI.orderapi.get(`order-analytics/?${queryString.stringify(queryParams)}`)
      .then((res) => {
        this.setState({loading: false, response: res});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({loading: false, error: error});
      });
  };

  render = () => {
    const {loading, error, response} = this.state;
    const {property} = this.props;
    let pos = [];

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    // Initialize with empty array for order data set
    const orderDataSet = {};
    Object.keys(AnalyticConstants.orderAnalytics).forEach((data)=>{
      orderDataSet[data] = {};
    });

    // Containers for order status data & order source data
    const orderData = {
      orderStatusData: _.cloneDeep(orderDataSet),
      posData: {},
    };

    // Setting up container for Payment terminal level counts.
    if (!_.isEmpty(response) && !_.isEmpty(response.meta_data) ) {
      pos = response.meta_data.pos || [];
      if (!_.isEmpty(pos)) {
        pos.forEach((data)=>{
          orderData.posData[data.id] = _.cloneDeep(orderDataSet);
        });
      }
    }


    if (!_.isEmpty(response) && !_.isEmpty(response.data) ) {
      const responseData = response.data;
      // Payment Status
      if (!_.isEmpty(responseData.order_type_status_data)) {
        responseData.order_type_status_data.forEach((data)=>{
          Object.entries(AnalyticConstants.orderAnalytics).forEach(([key, value])=>{
            if (data[value]) {
              AppUtil.insertOrAddObject(orderData.orderStatusData[key], data.order_status, data[value], 'float');
              if (data.pos && orderData.posData[data.pos]) {
                AppUtil.insertOrAddObject(orderData.posData[data.pos][key], data.order_status, data[value], 'float');
              }
            }
          });
        });
      }
    }

    // Payment status contains cancelled payments also so subtract it to get active payments.
    const orderTotalPayment = (AppUtil.sumArray(Object.values(orderData.orderStatusData.orderTotal), 'float')-(orderData.orderStatusData.orderTotal[Constants.ORDER_STATUS.CANCELLED] || 0));
    const orderTotalPaymentCount = AppUtil.sumArray(Object.values(orderData.orderStatusData.orderCount)) - (orderData.orderStatusData.orderCount[Constants.ORDER_STATUS.CANCELLED] || 0);

    const paidOrdersPercentage = AppUtil.calculatePercentage(
      AppUtil.sumArray(Object.values(orderData.orderStatusData.orderPaidPayment), 'float'),
      orderTotalPayment
    );

    return (
      <div className="screen-container">
        <Helmet>
          <title>Order Analytics : Point of Sales</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={'Point of Sales'}
          description={'Sales bifurcation based on point of sales.'}
        />

        <div className={'row'}>
          <div className={'col-12 col-lg-6'}>
            <div className={'card mb-5'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col-6'}>
                    <p className={'text-muted mb-1'}>Total Active Order(s)</p>
                    <h4 className={'card-title mb-3'}>
                      {`${property.currency} ${orderTotalPayment.toFixed(2)}`}
                    </h4>
                    <p className={'text-muted mb-2'}>
                      {orderTotalPaymentCount} order entries.
                    </p>
                  </div>
                  <div className={'col-6'}>
                    <p className={'text-muted mb-1'}>Cancelled</p>
                    <h4 className={'grey-cl mb-3'}>
                      {`${property.currency} ${orderData.orderStatusData.orderTotal[Constants.PAYMENT_STATUS.CANCELLED] || 0}`}
                    </h4>
                    <p className={'text-muted mb-2'}>
                      {orderData.orderStatusData.orderCount[Constants.PAYMENT_STATUS.CANCELLED] || 0} order entries.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'col-12 col-lg-6'}>
            <div className={'card mb-5'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col '}>
                    <p className={'text-muted mb-1'}>Total Paid</p>
                    <h5 className={'card-title mb-0 pb-0 green-cl'}>
                      {`${property.currency} ${AppUtil.sumArray(Object.values(orderData.orderStatusData.orderPaidPayment), 'float')}`}
                    </h5>
                  </div>
                  <div className={'col'}>
                    <p className={'text-muted mb-1'}>Pending Payment</p>
                    <h5 className={'card-title mb-0 pb-0 red-cl'}>
                      {`${property.currency} ${(orderTotalPayment - AppUtil.sumArray(Object.values(orderData.orderStatusData.orderPaidPayment), 'float')).toFixed(2)}`}
                    </h5>
                  </div>
                </div>
                <div className="progress mt-3 red-bg">
                  <div
                    className="progress-bar green-bg"
                    style={{width: `${paidOrdersPercentage}%`}}
                    aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"/>
                </div>
                <div className={'row'}>
                  <div className={'col-8'}>
                    <p className={'text-muted mb-0 mt-1 small'}>
                      Count - Paid : {orderTotalPaymentCount - (orderData.orderStatusData.orderCount[Constants.ORDER_STATUS.NOTPAID] || 0)} |
                      Pending : {(orderData.orderStatusData.orderCount[Constants.ORDER_STATUS.NOTPAID] || 0)}
                    </p>
                  </div>
                  <div className={'text-right col'}>
                    <p className={'text-muted mb-0 mt-1 small'}>{paidOrdersPercentage}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="table-responsive">
          <table className={'table table-bordered mb-3'}>
            <thead>
              <tr>
                <th>Point of Sales</th>
                <th className={'data-table-cell-sm'}>Count</th>
                <th className={'data-table-cell-md'}>Total <small>({property.currency})</small></th>
                <th className={'data-table-cell-md'}>Pending <small>({property.currency})</small></th>
                <th className={'data-table-cell-md'}>Paid <small>({property.currency})</small></th>
                <th className={'data-table-cell-md'}>Refunded <small>({property.currency})</small></th>
              </tr>
            </thead>
            <tbody>
              {_.isEmpty(pos)
                ? (
                  <tr>
                    <td colSpan={8}>
                      <div className={'text-center table-list-empty'}>
                        <h4><FontAwesomeIcon icon={faReceipt}/> No Point of Sales orders </h4>
                        {Strings.orderPOSListEmpty}
                      </div>
                    </td>
                  </tr>
                )
                : (
                  <>
                    {pos.map((data, i)=>{
                      const sourceTotalPayment = (
                        AppUtil.sumArray(Object.values(orderData.posData[data.id].orderTotal), 'float') -
                        (orderData.posData[data.id].orderTotal[Constants.ORDER_STATUS.CANCELLED] || 0)
                      );
                      const sourcePaidPayment = AppUtil.sumArray(Object.values(orderData.posData[data.id].orderPaidPayment), 'float');
                      return (
                        <tr className={'tr-align-middle'} key={i}>
                          <td className={'data-table-cell-lg'}>
                            <p className={'mb-0'}>
                              <FontAwesomeIcon
                                onClick={()=>this.setState({
                                  title: `Sales Overview : ${data.name}`,
                                  showPAOverview: true,
                                  showPAOrderData: orderData.posData[data.id]
                                })}
                                icon={faExternalLinkSquare}
                                className={'mr-2 a-class'}
                              />
                              <strong>{data.name}</strong>
                            </p>
                          </td>

                          <td className={'data-table-cell-sm text-center'}>
                            {(AppUtil.sumArray(Object.values(orderData.posData[data.id].orderCount))-(orderData.posData[data.id].orderCount[Constants.ORDER_STATUS.CANCELLED] || 0))}
                          </td>
                          <td className={'data-table-cell-sm text-center'}>
                            {sourceTotalPayment.toFixed(2)}
                          </td>
                          <td className={'data-table-cell-md text-center'}>
                            {(sourceTotalPayment - sourcePaidPayment).toFixed(2)}
                          </td>
                          <td className={'data-table-cell-md text-center'}>
                            {sourcePaidPayment}
                          </td>
                          <td className={'data-table-cell-md text-center'}>
                            {AppUtil.sumArray(Object.values(orderData.posData[data.id].orderRefundedPayment), 'float')}
                          </td>

                        </tr>
                      );
                    })}
                    <tr>
                      <td><strong>Total</strong></td>
                      <td className={'text-center'}>
                        <strong>{AppUtil.sumArray(Object.values(orderData.orderStatusData.orderCount)) - (orderData.orderStatusData.orderCount[Constants.ORDER_STATUS.CANCELLED] || 0)}</strong>
                      </td>
                      <td className={'text-center'}>
                        <strong>
                          {orderTotalPayment.toFixed(2)}
                        </strong>
                      </td>
                      <td className={'text-center'}>
                        <strong>
                          {(orderTotalPayment - AppUtil.sumArray(Object.values(orderData.orderStatusData.orderPaidPayment), 'float')).toFixed(2)}
                        </strong>
                      </td>
                      <td className={'text-center'}>
                        <strong>
                          {AppUtil.sumArray(Object.values(orderData.orderStatusData.orderPaidPayment), 'float')}
                        </strong>
                      </td>
                      <td className={'text-center'}>
                        <strong>
                          {AppUtil.sumArray(Object.values(orderData.orderStatusData.orderRefundedPayment), 'float')}
                        </strong>
                      </td>
                    </tr>
                  </>
                )
              }
            </tbody>
          </table>
        </div>

        <hr className={'mt-3'}/>

        <OAOverview property={property} orderData={orderData.orderStatusData}/>


        <OAOverviewModel
          property={property}
          title={this.state.title}
          show={this.state.showPAOverview}
          showPAOverview={this.state.showPAOverview}
          orderData={this.state.showPAOrderData}
          onHide={()=>this.setState({showPAOverview: false, showPAOrderData: {}, title: null})}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      showPAOverview: false,
      showPAOrderData: {},
      startDate: Moment().startOf('day'),
      endDate: Moment().add(30, 'days'),
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderAnalyticsPOS);
