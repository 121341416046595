/**
 * RecordView
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Consts and Libs
import {Strings} from '../../constants';
// Components
import { Img } from 'react-image';
import Loading from '../general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLinkSquareAlt} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */
class RecordView extends Component {
  static componentName = 'RecordView';

  static propTypes = {
    record: PropTypes.object,
    title: PropTypes.string,
    viewOriginal: PropTypes.bool,
  };

  static defaultProps = {
    viewOriginal: true,
    title: 'Reference',
  };


  initCounter = () => {
    setTimeout(() => {
      this.setState({disabledLink: true});
    }, 20000);
  };

  render = () => {
    this.initCounter();
    const {record, viewOriginal, title} = this.props;
    const {disabledLink} = this.state;
    return (
      <React.Fragment>
        {(record && record.record_2x) &&
         <div className={'row mb-3'}>
           <div className={'col-12'}>
             <h5>{title}</h5>
             <hr/>
             <div className={'row'}>
               <div className={`col-12 mb-2 ${viewOriginal && 'col-md-8 '}`}>
                 <Img
                   className={'border p-1 w-100'}
                   src={record.record_2x}
                   loader={<Loading heightMatch={false}/>}
                 />
               </div>
               {(viewOriginal) &&
                <div className={'col-12 col-md-4 text-center align-self-center'}>
                  <a
                    target={'blank'}
                    className={`btn btn-outline-primary ${disabledLink && 'disabled'}`} rel='noopener noreferrer'
                    href={!disabledLink && record.record_original}
                  >
                    <FontAwesomeIcon icon={faExternalLinkSquareAlt} className={'mr-2'}/>View Original
                  </a>
                  {disabledLink && <p className={'mb-0 text-muted small mt-3'}>{Strings.expiredLinkText}</p>}
                </div>
               }
             </div>
           </div>
         </div>
        }
      </React.Fragment>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      disabledLink: false,
    };
  }
}

/* Export Component ==================================================================== */
export default RecordView;
