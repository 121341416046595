/**
 *
 * POS Membership List
 */
import React, {Component} from 'react';
import queryString from 'query-string';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

// Consts and Libs
import AppAPI from '../../../lib/api';
import {Filters} from '../../../constants';
import Strings from '../../../constants/strings';

// Components
import POSMembershipAdd from './POSMembershipAdd';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import SearchBar from '../../../components/forms/SearchBar';
import BadgeStatus from '../../../components/general/BadgeStatus';
import FilterButton from '../../../components/dashboard/FilterButton';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLongArrowAltRight, faPlus, faSync, faUsers} from '@fortawesome/free-solid-svg-icons';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pos: state.pos.pos,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class POSMembershipList extends Component {
  static componentName = 'POSMembershipList';
  static propTypes = {
    refreshable: PropTypes.bool,
    match: PropTypes.object.isRequired,
  };

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };

  componentDidMount = () => {
    let urlParams = {};
    switch (this.getQueryParams('membership_status')) {
    case 'active':
      urlParams['access_status'] = true;
      break;
    case 'suspended':
      urlParams['access_status'] = false;
      break;
    default:
      break;
    }
    this.setState({loading: false, urlParams});
  }

  fetchData = (page = 1, callback) => {
    const urlParams = {...this.state.urlParams};
    urlParams['page'] = page;

    const {pos} = this.props;
    if (pos.hash) {
      urlParams['pos'] = pos.hash;
    }

    let baseUrl = 'pos-membership-settings';
    AppAPI.posapi.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        this.setState({
          loading: false,
          error: null,
          refresh: false,
        });
        if (res.results.length > 0) {
          if (res.next) {
            callback(res.results);
          } else {
            callback(res.results, {allLoaded: true});
          }
        } else {
          callback();
        }
      })
      .catch((err) => {
        callback(null, {allLoaded: true});
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          refresh: false,
          error,
        });
      });
  };


  emptyView = () => {
    return (
      <tr>
        <td colSpan={8}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faUsers}/> User Account </h4>
            {Strings.POSMembershipText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };

  renderRow = (data, key) => {
    const {pos} = this.props;
    return (
      <tr key={key}>
        <td className={'data-table-cell-lg'}>
          {data.user ? data.user.display_name : 'Account'}
          <br/>
          <p className={'small mb-0 text-muted'}>{data.user ? data.user.email : 'E-Mail'}</p>
        </td>
        <td className={'data-table-cell-lg'}>
          <Link to={`/settings/pos/${pos.hash}/account/${data.id}/`}><small>{data.ref_no}</small></Link>
        </td>
        <td className={'data-table-cell-md'}>
          <BadgeStatus
            successText={'Active'} failText={'Suspended'} className={'ml-2'}
            status={data.access_status}
          />
        </td>
        <td>
          <Link to={`/settings/pos/${pos.hash}/account/${data.id}/`}>
            Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
          </Link>
        </td>
      </tr>
    );
  };

  tableHeader = () => {
    return (
      <tr>
        <th>User</th>
        <th>Reference</th>
        <th/>
        <th/>
      </tr>
    );
  }

  render = () => {

    const {loading, error, refresh} = this.state;
    const {pos} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="pos-item-list screen-container ">
        <Helmet>
          <title>POS Account</title>
        </Helmet>

        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12 mb-5">
            <div className={'row mb-3'}>
              <div className={'col-12 col-sm-9'}>
                <ul className="list-inline list-item-mb-1">
                  <li className="list-inline-item">
                    <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.setState({refresh: true})}>
                      <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                    </button>
                  </li>
                  <li className="list-inline-item">
                    <FilterButton
                      title={'Status'}
                      data={Filters.membershipStatus}
                      selectKey={this.getQueryParams('membership_status')}
                      action={(data) => this.setQueryParams([{key: 'membership_status', value: data.key}])}
                      size={'sm'}
                    />
                  </li>
                </ul>
              </div>
            </div>

            <SearchBar
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
              refresh={refresh}
              colSpan={4}
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <button className={'btn btn-link p-0'} onClick={() => this.setState({showPOSMembershipAdd: true})}>
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add User
                </button>
              </li>
            </ul>
          </div>
        </div>

        {/* Booking Amendments */}
        <POSMembershipAdd
          property={this.props.property}
          onHide={() => this.setState({showPOSMembershipAdd: false})}
          show={this.state.showPOSMembershipAdd}
          pos={pos}
          postAction={() => this.setState({refresh: true})}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      refresh: false,
      urlParams: {},
      showPOSMembershipAdd: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(POSMembershipList);