/**
 * Model Instant List View
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
// Consts and Libs
// Components
import Loading from '../../components/general/Loading';
import {faCheckCircle, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
/* Component ==================================================================== */

class ModelListView extends Component {
  static componentName = 'ModelListView';

  static propTypes = {
    size: PropTypes.string,
    match: PropTypes.object,
    property: PropTypes.object,
    listData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    renderRow: PropTypes.func.isRequired,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  preFillData = () => {
    this.setState(
      {
        init: false,
        loading: false,
      },
    );
  };

  render = () => {

    const {loading, init} = this.state;
    const {show, listData, title, size} = this.props;

    if (show && init) {
      this.preFillData();
    }
    return (
      <Modal
        {...this.props}
        size={size ? size : 'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? <Loading heightMatch={false}/>
            : <div>
              {listData && listData.length > 0 && this.props.renderRow
                ? <div>
                  <div className="row">
                    {listData.map((data, i) => (
                      this.props.renderRow(data, i)
                    ))}
                  </div>
                </div>
                : <div className="row p-3">
                  <div className={'col-lg-12 col-md-12 mb-3 d-flex'}>
                    <div className="align-self-center text-center mx-auto">
                      <FontAwesomeIcon icon={faCheckCircle} size={'2x'} className={'green-cl'}/>
                      <p className={'small'}>No {title}.</p>
                    </div>
                  </div>
                </div>
              }
            </div>
          }
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          <div className={'row'}>
            <div className={'col mx-auto'}>
              <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: true,
    };
  }
}

/* Export Component ==================================================================== */
export default ModelListView;
