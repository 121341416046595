/**
 *
 * Property Room Service Items
 * Room Service Items list
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link, NavLink, Route, Switch, Redirect} from 'react-router-dom';
// Consts and Libs
import {Constants} from '../../../constants';

// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PageHeader from '../../../components/dashboard/PageHeader';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';


import RoomServiceItemList from './RoomServiceItemList';
import RoomServiceItemManage from './RoomServiceItemManage';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  roomType: state.roomType.roomType,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class RoomServiceItem extends Component {
  static componentName = 'RoomServiceItem';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
  };


  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    let baseUrl = '/settings/inventory/room/service-items';
    const menu = [
      {
        title: 'Service List',
        link: `${baseUrl}/service/`,
      },
      {
        title: 'Amenity List',
        link: `${baseUrl}/amenity/`,
      },
      {
        title: 'Maintenance List',
        link: `${baseUrl}/maintenance/`,
      },
    ];

    let menuItems = [];
    menu.map((item) => {
      const {title, link, isExact} = item;
      return menuItems.push(
        <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
          <NavLink
            to={{
              pathname: link,
              state: {fromDashboard: true},
            }}
            exact={isExact}
            className="nav-link rounded-0"
            activeClassName="selected">
            {title}
          </NavLink>
        </li>,
      );
    });

    return (
      <div className="room-type-details screen-container ">
        <Helmet>
          <title>Room Types Manage</title>
        </Helmet>


        {baseUrl &&
         <Link to={'/settings/inventory/room/'} className={'btn btn-outline-dark mr-2 btn-sm mt-2'}>
           <FontAwesomeIcon icon={faArrowLeft} size={'sm'}/> {'Room List'}
         </Link>
        }

        <PageHeader
          subHeader={true} history={this.props.history} title={'Room Service Items'}
          description={'Settings & Configuration for room service items'}
        />


        <ul className="nav nav-tabs mb-3">
          {menuItems}
        </ul>

        <Switch>

          <Route exact path={baseUrl} render={() => (<Redirect from={baseUrl} to={`${baseUrl}/service`} />)}/>

          <Route path={`${baseUrl}/service/`} exact render={(props) =>
            <RoomServiceItemList {...props} serviceType={Constants.ROOM_SERVICE_TYPE.SERVICE} />}
          />
          <Route path={`${baseUrl}/service/add`}  exact render={(props) =>
            <RoomServiceItemManage {...props} serviceType={Constants.ROOM_SERVICE_TYPE.SERVICE} />}
          />
          <Route path={`${baseUrl}/service/:roomServiceItemId/edit`}  exact render={(props) =>
            <RoomServiceItemManage {...props} serviceType={Constants.ROOM_SERVICE_TYPE.SERVICE} />}
          />

          <Route path={`${baseUrl}/amenity`} exact render={(props) =>
            <RoomServiceItemList {...props} serviceType={Constants.ROOM_SERVICE_TYPE.AMENITY} />}
          />
          <Route path={`${baseUrl}/amenity/add`} exact render={(props) =>
            <RoomServiceItemManage {...props} serviceType={Constants.ROOM_SERVICE_TYPE.AMENITY} />}
          />
          <Route path={`${baseUrl}/amenity/:roomServiceItemId/edit`} exact render={(props) =>
            <RoomServiceItemManage {...props} serviceType={Constants.ROOM_SERVICE_TYPE.AMENITY} />}
          />


          <Route path={`${baseUrl}/maintenance`} exact render={(props) =>
            <RoomServiceItemList {...props} serviceType={Constants.ROOM_SERVICE_TYPE.MAINTENANCE} />}
          />
          <Route path={`${baseUrl}/maintenance/add`} exact render={(props) =>
            <RoomServiceItemManage {...props} serviceType={Constants.ROOM_SERVICE_TYPE.MAINTENANCE} />}
          />
          <Route path={`${baseUrl}/maintenance/:roomServiceItemId/edit`} exact render={(props) =>
            <RoomServiceItemManage {...props} serviceType={Constants.ROOM_SERVICE_TYPE.MAINTENANCE} />}
          />
        </Switch>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomServiceItem);