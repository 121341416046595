import React from 'react';
import {Route, Switch} from 'react-router-dom';



import ReviewView from './ReviewView';
import ReviewDetails from './ReviewDetails';
import ReviewListIndex from './ReviewListIndex';
import NotFound from '../NotFound';

const PaymentRoute = () => (
  <div>
    
    <div className="container">
      <Switch>
        <Route path="/review/" exact component={ReviewView}/>
        <Route path="/review/list" component={ReviewListIndex}/>
        <Route path="/review/:reviewId" exact component={ReviewDetails}/>
        <Route exact component={NotFound}/>
      </Switch>
    </div>
    
  </div>
);

export default PaymentRoute;