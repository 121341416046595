/**
 * Event Actions
 *  Actions with point of sales
 */

import AppAPI from '../../lib/api';


/**
 * Set Event
 */
export const setEvent = (EventData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof EventData === 'object') {
    dispatch({
      type: 'SET_EVENT',
      data: EventData
    });
    return resolve(EventData);
  } else if (EventData) {
    // Fetch Event Data
    return AppAPI.eventapi.get(`event/${EventData}/?tickets=True`)
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_EVENT',
          data: res
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Event
 */
export const updateEvent = (EventData) => setEvent(EventData);

/**
 * Remove Event
 */
export function unSetEvent() {
  return (dispatch) => {
    dispatch({
      type: 'SET_EVENT',
      data: null
    });
  };
}


/**
 * Set Event
 */
export const setTicket = (TicketData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof TicketData === 'object') {
    dispatch({
      type: 'SET_EVENT',
      data: TicketData
    });
    return resolve(TicketData);
  } else if (TicketData) {
    // Fetch Event Data
    return AppAPI.eventapi.get(`ticket/${TicketData}/`)
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_EVENT_TICKET',
          data: res
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Event
 */
export const updateTicket = (TicketData) => setTicket(TicketData);

/**
 * Remove Event
 */
export function unSetTicket() {
  return (dispatch) => {
    dispatch({
      type: 'SET_EVENT_TICKET',
      data: null
    });
  };
}

/**
 * Perform Lookup
 */
export function setLookup(lookupData) {
  return (dispatch) => {
    dispatch({
      type: 'SET_EVENT_LOOKUP',
      data: lookupData,
    });
  };
}

/**
 * Remove Lookup
 */
export function unSetLookup() {
  return (dispatch) => {
    dispatch({
      type: 'SET_EVENT_LOOKUP',
      data: null,
    });
  };
}

/**
 * Set selection data
 */
export function setSelectionData (selectionData) {
  return (dispatch) => {
    dispatch({
      type: 'SET_EVENT_SELECTION',
      data: selectionData,
    });
  };
}



/**
 * Set Booking
 */
export const setTicketBooking = (bookingData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof bookingData === 'object') {
    dispatch({
      type: 'SET_TICKET_BOOKING',
      data: bookingData,
    });
    return resolve(bookingData);
  } else if (bookingData) {
    // Verify property is permitted for user
    return AppAPI.eventapi.get('ticket-booking/' + bookingData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_TICKET_BOOKING',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Booking
 */
export const updateTicketBooking = (bookingData) => setTicketBooking(bookingData);

/**
 * Remove Booking
 */
export function unSetTicketBooking() {
  return (dispatch) => {
    dispatch({
      type: 'SET_TICKET_BOOKING',
      data: null,
    });
  };
}