/**
 *
 * Booking Guest Details
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Strings} from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import MessagingManager from '../../messaging/MessagingManager';
import GuestDetailsOverView from '../../guest/GuestDetailsOverView';
import PageSubHeader from '../../../components/dashboard/PageSubHeader';
import {faDoorOpen, faEye, faLongArrowAltRight, faPaperPlane, faUser} from '@fortawesome/free-solid-svg-icons';
// Actions

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  booking: state.booking.booking,
});

// Any actions to map to the component?
const mapDispatchToProps = {};


/* Component ==================================================================== */
class BookingGuestDetails extends Component {
  static componentName = 'BookingGuestDetails';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    if (this.props.match.params.bookingId) {
      this.fetchInitData();
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };

  fetchInitData = () => {
    AppAPI.booking.get(`${this.props.match.params.bookingId}/related-details/?key=guest`)
      .then((res) => {
        this.setState({
          response: res,
          loading: false,
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };

  showProfileDetails = (guestId, add_guest = false) => {
    this.setState({profileDetailsData: guestId, showProfileDetails: true, addProfile: add_guest});
  };


  render = () => {

    const {loading, error, resultMsg, response} = this.state;
    const {booking} = this.props;

    if (loading || _.isEmpty(booking) || _.isEmpty(response)) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const {booking_rooms} = response;

    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Booking Guest List</title>
        </Helmet>

        <PageSubHeader icon={faUser} title={'Guest(s)'} description={'Room guest allocation list.'}/>
        <div className="row">
          <div className="col-lg-12 col-sm-12">
            <div className={'row'}>
              <div className={'col-lg-6 col-md-12'}>
                <table className={'table table-borderless table-sm mb-3'}>
                  <tbody>
                    <tr>
                      <td className={'w-25 text-muted'}>Total Rooms</td>
                      <td><strong>{!_.isEmpty(booking_rooms) ? booking_rooms.length : 0}</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <table className={'table border table-hover format-table'}>
              <thead>
                <tr>
                  <th className={'data-table-cell-md'}>Name</th>
                  <th className={'data-table-cell-md'}>Phone</th>
                  <th className={'data-table-cell-md'}>Email</th>
                  <th className={'data-table-cell-sm'}/>
                  <th className={'data-table-cell-sm'}/>
                  <th className={'data-table-cell-md'}>Allotted Room</th>
                  <th className={'data-table-cell-sm'}>Booking Room</th>
                </tr>
              </thead>
              <tbody>
                {!_.isEmpty(booking_rooms) ?
                  <React.Fragment>
                    {booking_rooms.map((data, i) => {
                      const generalCols = <React.Fragment>
                        <td>
                          {(data.attributes && data.attributes.room_name) ?
                            <strong>Room : {data.attributes.room_name}</strong>
                            :
                            <span className={'text-muted'}>...</span>
                          }
                        </td>
                        <td>
                          <Link
                            to={`/booking/${this.props.match.params.bookingId}/booking-room/${data.hash}/`}
                            className={'btn btn-link mr-2'}
                          >
                            Room {data.room_number} <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
                          </Link>
                        </td>
                      </React.Fragment>;

                      if (!_.isEmpty(data.guests)) {
                        return (
                          <React.Fragment key={i}>
                            {data.guests.map((guestData, k) => (
                              <tr key={k}>
                                <td>
                                  <button className={'btn btn-link m-0 p-0'} onClick={() => this.setState({
                                    showProfileDetails: true,
                                    profileDetailsData: guestData.hash,
                                  })}>
                                    <FontAwesomeIcon icon={faEye} className={'mr-2'}/> {guestData.name || guestData.ref_no}
                                  </button>
                                </td>
                                <td>{guestData.phone || '...'}</td>
                                <td>{guestData.email || '...'}</td>
                                <td>
                                  {guestData.email || guestData.phone ?
                                    <button
                                      className={'btn btn-link p-0 m-0'}
                                      onClick={() => this.setState({
                                        selectedGuest: guestData,
                                        showMessagingManager: true
                                      })}
                                    >
                                      <FontAwesomeIcon icon={faPaperPlane} size={'sm'} className={'mr-2'}/> Notification
                                    </button>
                                    : '....'}
                                </td>
                                <td>
                                  <span
                                    className={`ml-2 badge ${guestData.record_exist ? 'badge-success' : 'badge-warning'}`}>
                                    Document
                                  </span>
                                </td>
                                {generalCols}
                              </tr>
                            ))}
                          </React.Fragment>
                        );
                      } else {
                        return (
                          <React.Fragment key={i}>
                            <tr key={i}>
                              <td colSpan={5}>
                                <p
                                  className={'text-muted mb-0 text-center mb-0'}>{Strings.bookingRoomGuestEmptyText}</p>
                              </td>
                              {generalCols}
                            </tr>
                          </React.Fragment>
                        );
                      }
                    })}
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <tr>
                      <td colSpan={7}>
                        <div className={'text-center table-list-empty'}>
                          <h6><FontAwesomeIcon icon={faDoorOpen} className={'mr-2'}/> No Rooms </h6>
                          <p className={'text-muted'}>{Strings.bookingBookingRoomEmpty}</p>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                }
              </tbody>
            </table>
          </div>
        </div>


        {/*  Profile Details */}
        <GuestDetailsOverView
          onHide={() => this.setState({showProfileDetails: false})}
          guestId={this.state.profileDetailsData}
          show={this.state.showProfileDetails}
          addGuestFunction={this.state.addProfile}
          action={this.profileActions}
          resultMsg={resultMsg}
        />

        {/* Messaging */}
        <MessagingManager
          onHide={() => this.setState({
            showMessagingManager: false,
            selectedGuest: null,
          })}
          show={this.state.showMessagingManager}
          guests={this.state.selectedGuest ? [this.state.selectedGuest] : null}
          property={this.props.property}
          booking={booking}
        />

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      response: {},
      profileDetailsData: null,
      showProfileDetails: false,
      showMessagingManager: false,
      selectedGuest: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(BookingGuestDetails);
