/**
 * Report Details
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import React, {Component} from 'react';
import queryString from 'query-string';

// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';

// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBed,
  faCalendarAlt,
  faCashRegister, faCreditCard,
  faDownload,
  faExclamationCircle, faList, faMoneyBill,
  faNewspaper,
  faReceipt,
  faSyncAlt, faUniversity, faUnlock
} from '@fortawesome/free-solid-svg-icons';
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import {Strings} from '../../../constants';
import {faSourcetree} from '@fortawesome/free-brands-svg-icons';

/* Component ==================================================================== */

class ReportDetails extends Component {
  static componentName = 'ReportDetails';

  static propTypes = {
    reportData: PropTypes.object,
    escalated: PropTypes.bool,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
        loading: true,
        reportData: {},
      });
      if (!_.isEmpty(nextProps.reportData)) {
        this.fetchInitData(nextProps.reportData);
      }
    }
    return true;
  }

  fetchInitData = (reportData) => {
    let baseUrl = 'report', apiUrl, urlParams = {};
    const {pos, event, escalated} = this.props;

    if (escalated) {
      baseUrl = 'report-escalated';
    }

    if (pos) {
      apiUrl = AppAPI.posapi;
      baseUrl = 'pos-report';
      urlParams['pos'] = pos.hash;
    } else if (event) {
      baseUrl = 'event-report';
      apiUrl = AppAPI.eventapi;
      urlParams['pos'] = event.hash;
    } else {
      apiUrl = AppAPI.reportapi;
    }

    this.setState({
      loading: true,
    }, () => {
      apiUrl.get(`${baseUrl}/${reportData.hash}/?${queryString.stringify(urlParams)}`)
        .then((res) => {
          this.setState({
            loading: false,
            disabledLink: false,
            reportData: res,
          });
          setTimeout(() => {
            this.setState({disabledLink: true});
          }, 20000);
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    });
  };


  render = () => {
    const {show} = this.props;
    const {loading, error, disabledLink, reportData} = this.state;

    if (!show) {
      return null;
    }

    if (error) return <Error full={true} text={error}/>;

    const {report_attributes} = reportData;
    return (
      <Modal
        {...this.props}
        size={'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Report Details
            {reportData && <p className={'mb-0'}><small>{reportData.report_display}</small></p>}
          </Modal.Title>
        </Modal.Header>
        {loading ? <Loading heightMatch={false}/>
          :
          <React.Fragment>
            <Modal.Body>
              <div className={'row'}>
                <div className={'col-lg-12 col-sm-12'}>
                  <table className={'table table-sm table-borderless'}>
                    <tbody>
                      <tr>
                        <td className={'w-25 text-muted'}>Report</td>
                        <td>
                          <strong>{reportData.report_display}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td className={'w-25 text-muted'}>Date</td>
                        <td>
                          {reportData.date_type_display} - {AppUtil.formatDateTime(reportData.start_date, 'datef')} {reportData.end_date ? `- ${AppUtil.formatDateTime(reportData.end_date, 'datef')}` : ''}
                        </td>
                      </tr>
                      <tr>
                        <td className={'w-25 text-muted'}>Source</td>
                        <td>
                          {reportData.source_display}
                        </td>
                      </tr>
                      {!_.isEmpty(reportData.attributes) &&
                      <React.Fragment>
                        {reportData.attributes.pos_name &&
                        <tr>
                          <td className={'w-25 text-muted'}>Point of Sale</td>
                          <td>
                            {reportData.attributes.pos_name}
                          </td>
                        </tr>
                        }
                        {reportData.attributes.event_name &&
                        <tr>
                          <td className={'w-25 text-muted'}>Event</td>
                          <td>
                            {reportData.attributes.event_name}
                          </td>
                        </tr>
                        }
                      </React.Fragment>
                      }
                      {!_.isEmpty(reportData.attributes) &&
                      <React.Fragment>
                        {reportData.attributes.created_user &&
                        <tr>
                          <td className={'w-25 text-muted'}>User</td>
                          <td>
                            <strong>{reportData.attributes.created_user}</strong>
                          </td>
                        </tr>
                        }
                      </React.Fragment>
                      }
                      <tr>
                        <td className={'w-25 text-muted'}>Created</td>
                        <td>
                          {AppUtil.formatDateTime(reportData.created)}
                        </td>
                      </tr>
                      <tr>
                        <td className={'w-25 text-muted'}>Status</td>
                        <td>
                          <span className={'badge ' + (AppUtil.reportStatusColor(reportData.report_status))}>
                            {reportData.report_status_display}
                          </span>
                        </td>
                      </tr>
                      {!_.isEmpty(report_attributes) &&
                      <tr>
                        <td className={'w-25 text-muted'}>Report Inclusion</td>
                        <td>
                          <table>
                            <tbody>
                              {(report_attributes.bk_date_wise || report_attributes.or_date_wise || report_attributes.ex_date_wise || report_attributes.pos_date_wise || report_attributes.pa_date_wise) &&
                              <tr>
                                <td><FontAwesomeIcon icon={faCalendarAlt}/></td>
                                <td>Date Split</td>
                              </tr>
                              }

                              {(report_attributes.booking_status) &&
                              <tr>
                                <td><FontAwesomeIcon icon={faList}/></td>
                                <td>Booking Status</td>
                              </tr>
                              }

                              {(report_attributes.or_open_order || report_attributes.pos_open_order) &&
                              <tr>
                                <td><FontAwesomeIcon icon={faUnlock}/></td>
                                <td>Open Order</td>
                              </tr>
                              }

                              {(report_attributes.ex_open_order) &&
                              <tr>
                                <td><FontAwesomeIcon icon={faUnlock}/></td>
                                <td>Open Expense</td>
                              </tr>
                              }

                              {!_.isEmpty(report_attributes.bk_order_data) &&
                              <tr>
                                <td><FontAwesomeIcon icon={faReceipt}/></td>
                                <td>Order Data</td>
                              </tr>
                              }

                              {(report_attributes.bk_payment_data || report_attributes.or_payment_data || report_attributes.pos_payment_data) &&
                              <tr>
                                <td><FontAwesomeIcon icon={faMoneyBill}/></td>
                                <td>Payment Data</td>
                              </tr>
                              }

                              {('or_order_status' in report_attributes || 'pos_order_status' in report_attributes) &&
                              <tr>
                                <td><FontAwesomeIcon icon={faNewspaper}/></td>
                                <td>Order Status</td>
                              </tr>
                              }

                              {'ex_order_status' in report_attributes &&
                              <tr>
                                <td><FontAwesomeIcon icon={faNewspaper}/></td>
                                <td>Expense Status</td>
                              </tr>
                              }

                              {(!_.isEmpty(report_attributes.pos_pos_order_status)) &&
                              <tr>
                                <td><FontAwesomeIcon icon={faNewspaper}/></td>
                                <td>Tab Status</td>
                              </tr>
                              }

                              {(!_.isEmpty(report_attributes.pos_order_item_type)) &&
                              <tr>
                                <td><FontAwesomeIcon icon={faSourcetree}/></td>
                                <td>Order Item Type</td>
                              </tr>
                              }

                              {(!_.isEmpty(report_attributes.or_order_source)) &&
                              <tr>
                                <td><FontAwesomeIcon icon={faSourcetree}/></td>
                                <td>Order Source</td>
                              </tr>
                              }

                              {(!_.isEmpty(report_attributes.or_booking_source)) &&
                              <tr>
                                <td><FontAwesomeIcon icon={faBed}/></td>
                                <td>Booking Source</td>
                              </tr>
                              }

                              {(report_attributes.or_external_order) &&
                              <tr>
                                <td><FontAwesomeIcon icon={faNewspaper}/></td>
                                <td>External Order</td>
                              </tr>
                              }

                              {(report_attributes.bk_provisional_booking || report_attributes.or_provisional_booking) &&
                              <tr>
                                <td><FontAwesomeIcon icon={faExclamationCircle}/></td>
                                <td>Provisional Booking</td>
                              </tr>
                              }

                              {(report_attributes.tax_breakup || report_attributes.or_tax_data || report_attributes.pos_tax_data ||  report_attributes.ex_tax_data) &&
                              <tr>
                                <td><FontAwesomeIcon icon={faCashRegister}/></td>
                                <td>Tax Breakup</td>
                              </tr>
                              }

                              {(report_attributes.or_tax_total_data || report_attributes.pos_tax_total_data || report_attributes.ex_tax_total_data) &&
                              <tr>
                                <td><FontAwesomeIcon icon={faReceipt}/></td>
                                <td>Tax Total Breakup</td>
                              </tr>
                              }

                              {report_attributes.pa_payment_method &&
                              <tr>
                                <td><FontAwesomeIcon icon={faCashRegister}/></td>
                                <td>Payment Method</td>
                              </tr>
                              }

                              {report_attributes.pa_refund_status &&
                              <tr>
                                <td><FontAwesomeIcon icon={faCreditCard}/></td>
                                <td>Refund</td>
                              </tr>
                              }

                              {report_attributes.pa_booking_details &&
                              <tr>
                                <td><FontAwesomeIcon icon={faBed}/></td>
                                <td>Booking Details</td>
                              </tr>
                              }

                              {report_attributes.pa_order_details &&
                              <tr>
                                <td><FontAwesomeIcon icon={faReceipt}/></td>
                                <td>Order Details</td>
                              </tr>
                              }

                              {report_attributes.pa_booking_date &&
                              <tr>
                                <td><FontAwesomeIcon icon={faCalendarAlt}/></td>
                                <td>Booking Date</td>
                              </tr>
                              }

                              {report_attributes.pa_settlement_status &&
                              <tr>
                                <td><FontAwesomeIcon icon={faUniversity}/></td>
                                <td>Settlement</td>
                              </tr>
                              }
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className={'d-block'}>
              <div>
                <div className={'row'}>
                  {disabledLink &&
                  <div className={'col-3'}>
                    <button onClick={() => this.fetchInitData(reportData)} className={'btn py-2 btn-block btn-success'}>
                      <FontAwesomeIcon icon={faSyncAlt} className={'mx-2'}/>
                    </button>
                  </div>
                  }
                  <div className={'col'}>
                    <a
                      target={'blank'}
                      className={`btn py-2 btn-block btn-${disabledLink ? 'outline-success' : 'success'} btn-block ${(disabledLink || !reportData.xlsx_file_signed) && 'disabled'}`}
                      rel="noopener noreferrer"
                      href={!disabledLink && reportData.xlsx_file_signed}
                    >
                      <FontAwesomeIcon icon={faDownload} className={'mr-2'}/> XLS Download
                    </a>
                  </div>
                </div>
                {disabledLink && <p className={'mb-0 text-muted small mt-3'}>{Strings.expiredLinkText}</p>}
              </div>
            </Modal.Footer>
          </React.Fragment>
        }
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      disabledLink: true,
      error: false,
      init: true,
      reportData: {},
    };
  }
}

/* Export Component ==================================================================== */
export default ReportDetails;
