/**
 * PGatewayInfo.js
 *  Payment Gateway link
 *
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Consts and Libs
import AppUtil from '../../../../lib/util';
import {Constants} from '../../../../constants';
// Components
import TimeLineList from '../../../../components/dashboard/TimeLineList';

/* Component ==================================================================== */
class PGatewayInfo extends Component {
  static componentName = 'PGatewayInfo';

  static propTypes = {
    pgateway: PropTypes.object.isRequired,
  };

  static defaultProps = {
  }

  render = () => {
    const {pgateway} = this.props;

    const timeLine= [
      {
        'title': 'Created',
        'iconClass': 'green-cl',
        'description': AppUtil.formatDateTime(pgateway.created),
      },
      {
        'title': 'Payment',
        'iconClass': pgateway.payment_status === Constants.PGATEWAY_STATUS.PAID ? 'green-cl' : 'grey-cl',
        'description': pgateway.payment_time ? AppUtil.formatDateTime(pgateway.payment_time) : pgateway.payment_status_display
      }
    ];

    if (pgateway.refund_status !== Constants.PGATEWAY_STATUS.NOTPAID) {
      timeLine.push({
        'title': 'Refund',
        'iconClass': pgateway.refund_status !== Constants.PAYMENT_STATUS.NOTPAID ? 'green-cl' : 'grey-cl',
        'description': pgateway.refund_time ? AppUtil.formatDateTime(pgateway.refund_time) : pgateway.refund_status_display
      });
    }

    return (
      <React.Fragment>
        <div className={'row'}>
          <div className={'col-md-6 col-12'}>
            <table className={'table table-borderless table-sm mb-0'}>
              <tbody>
                <tr>
                  <td className={'w-50 text-muted'}>Total</td>
                  <td><strong>{`${pgateway.currency} ${pgateway.total}`}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={'col-md-6 col-12'}>
            <table className={'table table-borderless table-sm mb-0'}>
              <tbody>
                <tr>
                  <td className={'w-50 text-muted'}>Payment ID</td>
                  <td><strong>{pgateway.ref_no}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-md-6 col-12'}>
            <table className={'table table-borderless table-sm mb-3'}>
              <tbody>
                {pgateway.due_date &&
              <tr>
                <td className={'w-50 text-muted'}>Expire on</td>
                <td>{AppUtil.formatDateTime(pgateway.due_date)}</td>
              </tr>
                }
                <tr>
                  <td className={'w-50 text-muted'}>Gateway</td>
                  <td>{pgateway.payment_gateway_display || '...'}</td>
                </tr>
                <tr>
                  <td className={'w-50 text-muted'}>Payment Type</td>
                  <td>{pgateway.payment_type_display}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={'col-md-6 col-12'}>
            <table className={'table table-borderless table-sm mb-3'}>
              <tbody>
                <tr>
                  <td className={'w-50 text-muted'}>Payment Status</td>
                  <td>
                    <span className={`badge ${AppUtil.pGatewayStatusColor(pgateway.payment_status)}`}>
                      {pgateway.payment_status_display}
                    </span>
                  </td>
                </tr>
                {pgateway.refund_status !== Constants.PGATEWAY_STATUS.NOTPAID &&
                  <tr>
                    <td className={'w-50 text-muted'}>Refund Status</td>
                    <td>
                      <span className={`badge ${AppUtil.pGatewayStatusColor(pgateway.refund_status)}`}>
                        {pgateway.refund_status_display}
                      </span>
                    </td>
                  </tr>
                }

              </tbody>
            </table>
          </div>

        </div>
        <div className={''}>
          <TimeLineList timeLine={timeLine}/>
        </div>
      </React.Fragment>
    );
  };
}

/* Export Component ==================================================================== */
export default (PGatewayInfo);

