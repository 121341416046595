/**
 *
 * ExpenseView
 * overview of expenses
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {Link} from 'react-router-dom';
import Moment from 'moment';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import Colors from '../../theme/colors';
import {Constants} from '../../constants';
// Components
import ExpenseListView from './ExpenseListView';
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import PageHeader from '../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faCheckCircle, faList, faPlus, faSpinner, faUniversity } from '@fortawesome/free-solid-svg-icons';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class ExpenseView extends Component {
  static componentName = 'ExpenseView';
  componentDidMount = () => {
    this.fetchInitData();
  };
  fetchInitData = (no_cache, loading= true) => {
    this.setState({loading: loading});
    AppAPI.expenseapi.get('status-v2/' + (no_cache ? '?reset_cache=true' : ''))
      .then((res) => {
        let responseData  = {};
        if (!_.isEmpty(res.data)){
          responseData = {
            currency: res.data.currency,
            time: res.data.time,
            response: res.data,
          };
        }

        // Processing flag indicates
        if (res.processing) {
          setTimeout(()=>this.fetchInitData(false, false), 5000);
        }

        this.setState({
          ...responseData,
          loading: false,
          processing: res.processing || false
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: error,
          resultMsg: {error},
        });
      });
  };
  renderRow = (data, key) => {
    return (
      <ExpenseListView
        data={data}
        key={key}
        history={this.props.history}
      />
    );
  };
  render = () => {
    const {loading, error, currency, response, chartValueType, processing} = this.state;
    const {property} = this.props;

    const chartCount = [], chartValue = [];

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const expenseData = {
      'openExpense': {
        'count': 0,
        'total': 0,
      },
      'notPaidExpense': {
        'count': 0,
        'total': 0,
      },
      'partPaidExpense': {
        'count': 0,
        'total': 0,
      },
      'dateExpensePaid': {
        'count': 0,
        'total': 0,
      },
      'dateExpenseNotPaid': {
        'count': 0,
        'total': 0,
      }
    };

    if (!_.isEmpty(response)) {
      const {expenses_open, expenses_not_paid, expenses_date_data} = response;

      if (!_.isEmpty(expenses_date_data)) {
        expenses_date_data.forEach((data) => {
          if (data.order_status === Constants.ORDER_STATUS.PAID) {
            expenseData['dateExpensePaid']['count'] = data['count'];
            expenseData['dateExpensePaid']['total'] = data['total'];
          }

          if (data.order_status === Constants.ORDER_STATUS.NOTPAID) {
            expenseData['dateExpenseNotPaid']['count'] = data['count'];
            expenseData['dateExpenseNotPaid']['total'] = data['total'];
          }
        });
      }

      if (!_.isEmpty(expenses_open)) {
        expenses_open.forEach((data) => {
          expenseData['openExpense']['count'] = data['count'];
          expenseData['openExpense']['total'] = data['total'];
        });
      }
      if (!_.isEmpty(expenses_not_paid)) {
        expenses_not_paid.forEach((data) => {
          expenseData['notPaidExpense']['count'] = data['count'];
          expenseData['notPaidExpense']['total'] = data['total'];
        });
      }
    }

    let chartData = (chartValueType === 'count' ? chartCount : chartValue);

    return (
      <div className="order-view screen-container">
        <Helmet>
          <title>Expense Overview</title>
        </Helmet>

        <PageHeader
          history={this.props.history} title={'Expense(s)'}
          description={property.name + ' expense(s) status at ' + AppUtil.formatDateTime(Moment(), 'datetime') + '.'}/>

        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12 mb-5">
            <div>
              <div className={'row'}>
                <div className={'col-lg-6 col-sm-12 mb-3'}>
                  <h6 className={'small text-uppercase text-muted'}>Day Overview ({currency})</h6>
                  <div className={'card mb-3'}>
                    <div className="card-body">
                      <div className={'row'}>
                        <div
                          className={'col a-class'}
                          onClick={() => this.props.history.push(`/expense/list?order_status=paid&start_date=${AppUtil.formatDateTime(Moment(), 'date-iso')}`)}
                        >
                          <small>Paid Expense(s)</small>
                          <h6 className={'card-title mb-0 pb-0 green-cl'}>
                            {(!_.isEmpty(expenseData) && !processing) ? expenseData.dateExpensePaid.total : '...'}
                          </h6>
                        </div>
                        <div
                          className={'col a-class'}
                          onClick={() => this.props.history.push(`/expense/list?order_status=not-paid&start_date=${AppUtil.formatDateTime(Moment(), 'date-iso')}`)}
                        >
                          <small>Unpaid Expense(s)</small>
                          <h6 className={'card-title mb-0 pb-0 red-cl'}>
                            {(!_.isEmpty(expenseData) && !processing) ? expenseData.dateExpenseNotPaid.total : '...'}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={'row'}>
                    <div
                      className={'col a-class'}
                      onClick={() => this.props.history.push('/expense/list?order_status=not-paid')}
                    >
                      <h6 className={'small text-uppercase text-muted'}>Overall ({currency})</h6>
                      <div className={'card bg-danger text-light a-class'}>
                        <div className="card-body">
                          <small>Unpaid Expense</small>
                          <h6 className={'card-title mb-0 pb-0'}>
                            {(!_.isEmpty(expenseData) && !processing) ? expenseData.notPaidExpense.total : '...'}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div
                      className={'col a-class'}
                      onClick={() => this.props.history.push('/expense/list?order_status=open')}
                    >
                      <h6 className={'small text-uppercase text-muted'}>Overall ({currency})</h6>
                      <div className={'card bg-warning a-class'}>
                        <div className="card-body">
                          <small>Open Expense</small>
                          <h6 className={'card-title mb-0 pb-0'}>
                            {(!_.isEmpty(expenseData) && !processing) ? expenseData.openExpense.total : '...'}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(response && response.last_updated) &&
                  <p className={'small text-secondary mt-3'}>
                    last updated at {AppUtil.formatDateTime(response.last_updated)}.
                  </p>
                  }
                </div>
                {!_.isEmpty(chartData) ?
                  <div className={'col-lg-6 col-md-12 mb-3'}>
                    <ResponsiveContainer height={210}>
                      <PieChart onMouseEnter={this.onPieEnter}>
                        <Pie
                          data={chartData}
                          innerRadius={0}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          <Cell fill={Colors.yellow}/>
                          <Cell fill={Colors.green}/>
                          <Cell fill={Colors.orange}/>
                          <Cell fill={Colors.blue}/>
                          <Cell fill={Colors.red}/>
                          <Cell fill={Colors.yellowLight}/>
                          <Cell fill={Colors.info}/>
                        </Pie>
                        <Tooltip/>
                        <Legend onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}/>
                      </PieChart>
                    </ResponsiveContainer>
                    <div className={'text-center mt-2'}>
                      <div className="btn-group btn-group-sm" role="group">
                        <button disabled={true} className={'btn btn-outline-secondary'}>Day Overview</button>
                        <button
                          type="button"
                          onClick={() => this.setState({chartValueType: 'count'})}
                          className={`btn ${chartValueType === 'count' ? 'btn-success' : 'btn-outline-secondary'}`}>
                          Count (Nos)
                        </button>
                        <button
                          type="button"
                          onClick={() => this.setState({chartValueType: 'value'})}
                          className={`btn ${chartValueType === 'value' ? 'btn-success' : 'btn-outline-secondary'}`}>
                          Value ({currency})
                        </button>
                      </div>
                    </div>
                  </div>
                  :
                  <div className={'col-lg-6 col-md-12 mb-3 d-flex border rounded'}>
                    <div className="align-self-center text-center mx-auto">
                      <FontAwesomeIcon
                        size={'2x'}
                        spin={processing}
                        className={'green-cl'}
                        icon={processing ? faSpinner : faCheckCircle}
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className="spacer-20"/>
            <div className={'row'}>
              <div className={'col-6'}>
                <Link className="btn btn-success btn-lg btn-block" to="/expense/list">
                  <FontAwesomeIcon icon={faList} size={'sm'} className={'mr-2'}/> Expense List
                </Link>
              </div>
              <div className={'col-6'}>
                <Link className="btn btn-success btn-lg btn-block" to="/expense/add">
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Expense
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item" onClick={() => this.fetchInitData(true)}>
                <small>Expense List(s)</small>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className="" to="/expense/list">
                  <FontAwesomeIcon icon={faList} size={'sm'} className={'mr-2'}/> Expense List
                </Link>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className="" to="/expense/add">
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Expense
                </Link>
              </li>
              <li className="list-group-item">
                <Link className="" to="/expense/reports/list">
                  <FontAwesomeIcon icon={faUniversity} size={'sm'} className={'mr-2'}/> Report(s)
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: null,
      response: {},
      loading: true,
      processing: true,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseView);
