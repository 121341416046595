/**
 *
 * Review Details
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import StarRatingComponent from 'react-star-rating-component';
// Consts and Libs
import AppAPI from '../../lib/api';
// Components
import Loading from '../../components/general/Loading';
import Alerts from '../../components/ui/Alerts';
import Error from '../../components/general/Error';
// Actions
import * as ReviewActions from '../../redux/review/actions';
import AppUtil from '../../lib/util';
import {Link} from 'react-router-dom';
import Strings from '../../constants/strings';
import * as MessagingActions from '../../redux/messaging/actions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBan, faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import {confirmAlert} from '../../components/general/ConfirmAlert';
import MessagingManager from '../messaging/MessagingManager';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  review: state.review.review,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setReview: ReviewActions.setReview,
  setMessagingParameter: MessagingActions.setMessagingParameter,
};

/* Component ==================================================================== */
class ReviewDetails extends Component {
  static componentName = 'ReviewDetails';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  messagingLink = (review) => {
    if (review) {
      this.props.setMessagingParameter({
        'review': review,
      })
        .then(this.props.history.push('/messages/new'));
    }
  };

  componentDidMount = () => {
    if (this.props.match.params.reviewId) {
      this.props.setReview(this.props.match.params.reviewId)
        .then(() => {
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };

  updateReview = (payload) => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.review.patch(this.props.review.id + '/', payload)
          .then(() => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.history.push('/review/' + this.props.review.id);
                }, 500);
              });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {review} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    return (
      <div className="review-details screen-container ">
        <Helmet>
          <title>Review Details</title>
        </Helmet>


        <div className="page-header">
          <h3>{review.ref_no}</h3>
          <p className="mb-1">Review details.</p>
        </div>

        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div
              role="presentation" onKeyPress={this.handleKeyPress}
              onClick={() => this.props.history.goBack()} className="btn btn-outline-info">
              <span className="fa fa-chevron-left"/> Back
            </div>
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
            <div className="spacer-20"/>

            <div className={'row mb-5'}>
              <div className={'col-md-6 col-sm-12'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Ref no :</strong> {review.ref_no}
                  </li>
                  <li className="list-group-item">
                    <strong>Review ID :</strong> {review.review_id}
                  </li>
                </ul>
              </div>
              <div className={'col-md-6 col-sm-12'}>
                <ul className="list-group">
                  {(review.attributes && review.attributes.booking_id) &&
                   <li className="list-group-item">
                     <strong>Booking ID :</strong> {review.attributes.booking_id}
                   </li>
                  }
                  {(review.attributes && review.attributes.booking_ref_no) &&
                   <li className="list-group-item">
                     <strong>Booking Ref :</strong>
                     <Link to={'/booking/' + review.booking}>
                       {review.attributes.booking_ref_no}
                     </Link>
                   </li>
                  }
                </ul>
              </div>
            </div>

            <div className={'row mb-5'}>
              <div className={'col-md-6 col-sm-12'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Review Status :</strong>
                    <span
                      className={'badge ' + AppUtil.reviewStatusColor(review.review_status)}>
                      {review.review_status_display}
                    </span>
                  </li>
                </ul>
              </div>
            </div>


            {review.review_status === 0 &&
             <div>
               <div className="alert alert-warning" role="alert">
                 {
                   Strings.reviewInformationString.map((data, i) => <p key={i}>{data}</p>)
                 }
               </div>
             </div>
            }

            {review.review_status === 1 &&
             <div>
               {(review.review_answer && review.review_answer.length > 0) ?
                 <div>
                   <hr/>
                   <h3>Answers</h3>
                   <p>Answers in the review.</p>

                   <div>
                     {review.review_answer.map((data, i) => {
                       if (data.question) {
                         let answer = '';
                         switch (data.question.type) {
                         case 6:
                           answer = <div>
                             <StarRatingComponent
                               starCount={5}
                               value={parseInt(data.answer)}
                             />
                           </div>;
                           break;
                         default:
                           answer = data.answer;
                         }
                         return (
                           <ul className="list-group mb-2" key={i}>
                             <li className="list-group-item">
                               <strong>Question : </strong>
                               {data.question.question['en-US']}
                               <span className={'badge badge-info float-right'}>{data.question.type_display}</span>
                             </li>
                             <li className="list-group-item">
                               <strong>Answer : </strong> {answer}
                             </li>
                           </ul>
                         );
                       }
                       return null;
                     })}
                   </div>
                 </div>
                 :
                 <div>
                   <p>No answer(s).</p>
                   <p>
                     It seems there is no answer in this review, contact support for information.
                   </p>
                 </div>
               }
             </div>
            }
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group mb-5">
              <li className="list-group-item align-items-center">
                <button onClick={() => this.setState({showMessagingManager: true})} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faPaperPlane} size={'sm'} className={'mr-2'}/> Notification ( E-Mail / SMS )
                </button>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <button
                  className="btn btn-link red-cl m-0 p-0"
                  disabled={review.review_status !== 0}
                  onClick={() => {
                    confirmAlert({
                      title: 'Cancel Review',
                      message: 'This will cancel and close the review.',
                      buttons: [
                        {
                          className: 'btn-danger',
                          label: 'Cancel Review',
                          onClick: () => this.updateReview({void: true}),
                        },
                        {
                          className: 'btn-secondary',
                          label: 'Exit',
                        },
                      ],
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faBan} size={'sm'} className={'mr-2'}/> Cancel Review
                </button>
              </li>
            </ul>
          </div>
        </div>

        {/* Messaging */}
        <MessagingManager
          onHide={()=>this.setState({showMessagingManager: false})}
          show={this.state.showMessagingManager}
          property={this.props.property}
          review={review}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewDetails);