/**
 *
 * Property Content Pages List
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
// Consts and Libs
import AppAPI from '../../../lib/api';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import ListView from '../../../components/general/ListView';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faPlus, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../../../components/dashboard/PageHeader';
import {Link} from 'react-router-dom';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PropertyBookingEngineContentPageList extends Component {
  static componentName = 'PropertyBookingEngineContentPagesList';

  static propTypes = {
    isEvent: PropTypes.bool,
    isGallery: PropTypes.bool,
    isRoomType: PropTypes.bool,
    hideHeader: PropTypes.bool,
  };


  static defaultProps = {
    isRoomType: false,
    isEvent: false,
    hideHeader: false,
  };


  fetchData = (page = 1, callback) => {


    AppAPI.propertyapi.get(`property-content-page/?page=${page}`).then((res) => {
      this.setState({
        loading: false,
        error: null,
        refresh: false,
      });
      if (res.results.length > 0) {
        if (res.next) {
          callback(res.results);
        } else {
          callback(res.results, {
            allLoaded: true, // the end of the list is reached
          });
        }
      } else {
        callback();
      }
    })
      .catch((err) => {
        callback(null, {
          allLoaded: true, // the end of the list is reached
        });
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };

  renderRow = (data, key) => {
    return (
      <tr key={key}>
        <td className={'w-25 text-left'}>
          <Link to={`/settings/property/booking-engine/pages/${data.id}/`}>{data.title}</Link>
        </td>
        <td>{data.layout_type_display}</td>
        <td>
          <FontAwesomeIcon
            className={data.active ? 'green-cl' : 'red-cl'}
            icon={data.active ? faCheckCircle : faTimesCircle} size={'sm'}
          />
        </td>
        <td>
          <FontAwesomeIcon
            className={data.featured ? 'green-cl' : 'red-cl'}
            icon={data.active ? faCheckCircle : faTimesCircle} size={'sm'}
          />
        </td>
      </tr>
    );
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={5} className={'text-center'}>
          <div className="text-center">
            <h4>No Pages</h4>
          </div>
          <p className={'small'}>
            You don't have any pages.
          </p>
        </td>
      </tr>
    );
  };
  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="tax-list screen-container ">
        <Helmet>
          <title>Booking Engien Content Pages</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={'Content Pages'}
          description={'Custom content pages.'}
        />

        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12">
            <table className={'table'}>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Layout</th>
                  <th>Status</th>
                  <th>Featured</th>
                </tr>
              </thead>
              <tbody>
                <ListView
                  rowView={this.renderRow}
                  onFetch={this.fetchData}
                  firstLoader={true}
                  pagination
                  emptyView={this.emptyView}
                  class={'list-group'}
                  refresh={this.state.refresh}
                  colSpan={4}
                />
              </tbody>
            </table>
          </div>

          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item">
                <Link to={'new'} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Page
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyBookingEngineContentPageList);