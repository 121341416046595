/**
 *
 * Room Item List
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../../lib/api';
import Strings from '../../../constants/strings';
import {Constants} from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import ListView from '../../../components/general/ListView';
import {faList, faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import AppUtil from '../../../lib/util';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class RoomServiceItemList extends Component {
  static componentName = 'RoomServiceItemList';

  static propTypes = {
    serviceType: PropTypes.object
  };

  static defaultProps = {
    serviceType: Constants.ROOM_SERVICE_TYPE.SERVICE
  }

  componentDidMount = () => {
    let title, baseUrl;

    switch (this.props.serviceType) {
    case Constants.ROOM_SERVICE_TYPE.MAINTENANCE:
      title = 'Maintenance';
      baseUrl = 'maintenance';
      break;

    case Constants.ROOM_SERVICE_TYPE.AMENITY:
      title = 'Amenity';
      baseUrl = 'amenity';
      break;

    case Constants.ROOM_SERVICE_TYPE.SERVICE:
    default:
      title = 'Service';
      baseUrl = 'service';
    }

    this.setState({
      title: title,
      baseUrl: baseUrl
    });

  };

  fetchData = (page = 1, callback, searchText) => {
    const urlParams = {...this.state.urlParams};
    urlParams['page'] = page;
    if (searchText) {
      this.setState({searching: true, clear: true});
      urlParams['q'] = searchText;
    }

    const {serviceType} = this.props;
    if (serviceType) {
      urlParams['service_type'] = serviceType;
    }
    const queryString = Object.keys(urlParams).map(key => key + '=' + urlParams[key]).join('&');
    AppAPI.roomapi.get(`room-service-item-settings/?${queryString}`)
      .then((res) => {
        if (res.results.length > 0) {
          callback(res.results, {allLoaded: !res.next});
        } else {
          callback();
        }
        this.setState({searching: false, clear: false, loading: false});
      })
      .catch((err) => {
        callback([], {allLoaded: true});
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, loading: false, error});
      });
  };

  renderRow = (data, key) => {
    const {baseUrl} = this.state;

    return (
      <tr key={key}>
        <td>
          <Link to={`/settings/inventory/room/service-items/${baseUrl}/${data.id}/edit`}>{data.name}</Link>
        </td>
        <td>
          <span className={`badge ${AppUtil.roomServiceTypeColor(data.service_type)}`}>
            {data.service_type_display}
          </span>
        </td>
        <td>
          <span className={'badge ' + (data.auto_select ? 'badge-success' : 'badge-secondary')}>
            Auto Select
          </span>
        </td>
        <td>
          <span className={'badge ' + (data.active ? 'badge-success' : 'badge-secondary')}>
            {data.active ? 'Active' : 'De-active'}
          </span>
        </td>
      </tr>
    );
  };

  emptyView = () => {
    const {serviceType} = this.props;
    let title, descriptionString;
    switch (serviceType) {
    case Constants.ROOM_SERVICE_TYPE.MAINTENANCE:
      title = 'Maintenance List';
      descriptionString = Strings.roomServiceItemMaintenanceListEmptyText;
      break;

    case Constants.ROOM_SERVICE_TYPE.AMENITY:
      title = 'Amenity List';
      descriptionString = Strings.roomServiceItemAmenityListEmptyText;
      break;

    case Constants.ROOM_SERVICE_TYPE.SERVICE:
    default:
      title = 'Service List';
      descriptionString = Strings.roomServiceItemServiceListEmptyText;
    }
    return (
      <tr>
        <td colSpan={4}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faList}/> {title} </h4>
            {descriptionString.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };


  render = () => {

    const {loading, error, title, baseUrl} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="room-list screen-container ">
        <Helmet>
          <title>{title} List</title>
        </Helmet>

        <div className="row">
          <div className="col-lg-9 col-sm-12 col-md-9 mb-5">
            <table className={'table border format-table'}>
              <thead>
                <tr>
                  <th className={'data-table-cell-xl'}>Name</th>
                  <th className={'data-table-cell-md'}>Type</th>
                  <th className={'data-table-cell-md'}/>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <ListView
                  rowView={this.renderRow}
                  onFetch={this.fetchData}
                  firstLoader={true}
                  pagination
                  emptyView={this.emptyView}
                  class={'list-group'}
                  colSpan={4}
                />
              </tbody>
            </table>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link to={`/settings/inventory/room/service-items/${baseUrl}/add`} className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add {title}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      searching: false,
      clear: false,
      amendments: [],
      urlParams: {},
      baseUrl: '',
      title: '',
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomServiceItemList);