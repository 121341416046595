/**
 * Session Details
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import React, {Component} from 'react';

// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';

// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBan, faTimes} from '@fortawesome/free-solid-svg-icons';
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import {Alerts} from '../../../components/ui';
import {confirmAlert} from '../../../components/general/ConfirmAlert';
import {Strings} from '../../../constants';

/* Component ==================================================================== */

class SessionDetails extends Component {
  static componentName = 'SessionDetails';

  static propTypes = {
    data: PropTypes.object,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
        loading: true,
        sessionData: {},
      });
      if (!_.isEmpty(nextProps.data)) {
        this.fetchInitData(nextProps.data);
      }
    }
    return true;
  }

  fetchInitData = (sessionData) => {
    this.setState({
      loading: true,
    }, () => {
      AppAPI.account.get(`sessions/${sessionData.hash}/`)
        .then((res) => {
          this.setState({
            loading: false,
            sessionData: res,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    });
  };

  updateSession = (payload) => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}});
      AppAPI.account.patch(`sessions/${this.state.sessionData.hash}/`, payload)
        .then((res) => {
          if (this.props.onAction) this.props.onAction();
          this.setState({sessionData: res, resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
              }, 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  render = () => {
    const {show} = this.props;
    const {loading, error, sessionData, resultMsg} = this.state;

    if (!show) {
      return null;
    }

    if (error) return <Error full={true} text={error}/>;

    let sessionInformation = [];
    if (!_.isEmpty(sessionData.session_ip_information)){
      const sessionDataIPData = sessionData.session_ip_information;
      if (sessionDataIPData.city) {sessionInformation.push(sessionDataIPData.city);}
      if (sessionDataIPData.region) {sessionInformation.push(sessionDataIPData.region);}
      if (sessionDataIPData.country_name) {sessionInformation.push(sessionDataIPData.country_name);}
    }

    return (
      <Modal
        {...this.props}
        size={'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Session Details
          </Modal.Title>
        </Modal.Header>
        {loading ? <Loading heightMatch={false}/>
          :
          <React.Fragment>
            <Modal.Body>
              <div className={'row'}>
                <div className={'col-lg-12 col-sm-12'}>
                  <table className={'table table-sm table-borderless'}>
                    <tbody>
                      <tr>
                        <td className={'w-25 text-muted'}>Device</td>
                        <td><strong>{sessionData.session_device_display}</strong></td>
                      </tr>
                      <tr>
                        <td className={'w-25 text-muted'}>Identifier</td>
                        <td>{sessionData.hash}</td>
                      </tr>
                      <tr>
                        <td className={'w-25 text-muted'}>Agent</td>
                        <td>{sessionData.session_agent}</td>
                      </tr>
                      <tr>
                        <td className={'w-25 text-muted'}>IP Address</td>
                        <td>{sessionData.session_ip}</td>
                      </tr>
                      {!_.isEmpty(sessionInformation) &&
                        <tr>
                          <td className={'w-25 text-muted'}>Location</td>
                          <td>{sessionInformation.join(' / ')}</td>
                        </tr>
                      }
                      <tr>
                        <td className={'w-25 text-muted'}>Start</td>
                        <td>{AppUtil.formatDateTime(sessionData.created)}</td>
                      </tr>

                      <tr>
                        <td className={'w-25 text-muted'}>End</td>
                        <td>{sessionData.end_time ? AppUtil.formatDateTime(sessionData.end_time) : '...'}</td>
                      </tr>

                      <tr>
                        <td className={'w-25 text-muted'}>Status</td>
                        <td>
                          <span className={`badge ${sessionData.session_active ? 'badge-warning' : 'badge-success'} small`}>
                            {sessionData.session_status_display}
                          </span>
                        </td>
                      </tr>

                      {sessionData.session_current &&
                        <tr>
                          <td className={'w-25 text-muted'}>Status</td>
                          <td>
                            <span className={'badge badge-info small'}>Current Session</span>
                            <p className={'small mb-0'}>Current session cannot be killed, perform logout instead.</p>
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className={'d-block'}>
              <div className={'px-3'}>
                <Alerts
                  status={resultMsg.status}
                  success={resultMsg.success}
                  error={resultMsg.error}
                />
              </div>
              <div className={'row'}>
                <div className={'col'}>
                  <button
                    disabled={sessionData.session_current || !sessionData.session_active}
                    onClick={() => {
                      confirmAlert({
                        title: 'End Session',
                        message: Strings.sessionKillSession,
                        buttons: [
                          {
                            className: 'btn-secondary',
                            label: 'Exit',
                          },
                          {
                            className: 'btn-success',
                            label: 'End Session',
                            onClick: () => this.updateSession({logout_session: true})
                          },
                        ],
                      });
                    }}
                    className={'btn py-2 btn-block btn-danger'}
                  >
                    <FontAwesomeIcon icon={faBan} className={'mx-2'}/> {sessionData.session_current ? 'Perform Logout' : 'End Session'}
                  </button>
                </div>
                <div className={'col'}>
                  <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                    <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </React.Fragment>
        }
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      init: true,
      sessionData: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default SessionDetails;
