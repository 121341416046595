/**
 * Guest Details Over View
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import React, {Component} from 'react';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants} from '../../../constants';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLinkAlt, faPlus, faSync, faSyncAlt, faTimes} from '@fortawesome/free-solid-svg-icons';

import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import ProfileDetails from '../GuestDetails/ProfileDetails';
import {confirmAlert} from '../../../components/general/ConfirmAlert';
import ProfileDocumentList from '../GuestDetails/ProfileDocumentList';
import NavigationTabs from '../../../components/dashboard/NavigationTabs';

/* Component ==================================================================== */

class ProfileDetailsOverview extends Component {
  static componentName = 'ProfileDetailsOverview';

  static propTypes = {
    guestId: PropTypes.number,
    property: PropTypes.object,
    resultMsg: PropTypes.object,
    editControl: PropTypes.bool,
    showRecords: PropTypes.bool,
    profileAction: PropTypes.func,
    profileActionType: PropTypes.string
  };

  static defaultProps = {
    profileActionType: '+',
    editControl: false,
    showRecords: false,
    resultMsg: {
      status: '',
      success: '',
      error: '',
    },
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        guest: {},
        loading: true,
        selectionTab: 'overview',
      });
      if (nextProps.guestId) {
        this.fetchInitData(nextProps.guestId);
      }
    }
    return true;
  }

  fetchInitData = (guestId=this.props.guestId) => {
    this.setState({spin: true});
    AppAPI.guest.get(`${guestId}/`)
      .then((res) => {
        this.setState({ guest: res, spin: false, loading: false, error: null});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({ loading: false,  spin: false, error,});
      });
  };

  guestAction = (guest) => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      this.props.profileAction(guest, ()=>{
        this.setState({resultMsg: {success: ''}});
        this.props.onHide();
      });
    });
  }

  cacheReset = () => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      this.props.cacheReset(()=>{
        this.setState({resultMsg: {success: ''}});
      });
    });
  }

  render = () => {

    const {loading, selectionTab, spin, guest, error} = this.state;
    const {show, profileActionType, editControl, resultMsg, showRecords} = this.props;

    const profileAdd = (profileActionType === '+');

    if (!show) return null;

    let editUrl = null, title = '';
    if (guest && guest.id) {
      editUrl = '/customer/guest/' + guest.hash + '/';
      title = 'Guest';
      if (guest.profile_type === Constants.PROFILE_TYPE.CORPORATE) {
        editUrl = '/customer/corporate/' + guest.hash + '/';
        title = 'Corporate';
      } else if (guest.profile_type === Constants.PROFILE_TYPE.AGENT) {
        editUrl = '/customer/agent/' + guest.hash + '/';
        title = 'Agent';
      } else if (guest.profile_type === Constants.PROFILE_TYPE.VENDOR) {
        editUrl = '/settings/expense/vendor/' + guest.hash + '/';
        title = 'Vendor';
      }
    }

    const menu = [
      {
        title: 'Overview',
        key: 'overview',
      },
    ];
    if (showRecords) {
      menu.push({
        title: 'Records(s)',
        key: 'records',
      });
    }

    let content;
    switch(selectionTab) {
    case'records':
      content = <ProfileDocumentList
        guest={guest}
        editControl={editControl}
        reloadData={()=>{
          // new record updated so refresh parent cache
          if(this.props.cacheReset && editControl) {
            this.props.cacheReset();
          }
          this.fetchInitData();
        }}
      />;
      break;
    case 'overview':
    default:
      content = <ProfileDetails
        guest={guest}
        showSideBar={false}
        editControl={editControl}
        additionalContent={false}
        property={this.props.property}
        reloadData={()=>{
          // new record updated so refresh parent cache
          if(this.props.cacheReset && editControl) {
            this.props.cacheReset();
          }
          this.fetchInitData();
        }}
      />;
    }

    if (!_.isEmpty(error)) {
      content = <Error full={false} text={error}/>;
    }

    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <button className={`btn btn-sm mr-2 ${spin && 'btn-outline-secondary'} border`} onClick={()=>this.fetchInitData()}>
              <FontAwesomeIcon spin={spin} icon={faSync} size={'sm'}/>
            </button>
            {title} Details
            {editUrl &&
            <Link rel='noopener noreferrer' className={'btn btn-outline-primary btn-sm ml-4'} target={'_blank'} to={editUrl}>
              <FontAwesomeIcon className={'mr-2'} icon={faExternalLinkAlt} size={'sm'}/>Detail View
            </Link>
            }
            {(this.props.cacheReset && editControl) &&
            <button onClick={() => this.cacheReset()} className={'btn btn-outline-primary btn-sm m-0 ml-2'}>
              <FontAwesomeIcon className={'mr-2'} icon={faSyncAlt} size={'sm'}/> Sync Guest Data
            </button>
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(loading) ?
            <Loading heightMatch={false}/>
            :
            <div>
              <NavigationTabs
                activeKey={selectionTab}
                actionFunction={(key)=>this.setState({selectionTab: key})}
                navItems={menu}
                classNameContainer={'mb-4'}
              />
              {content}
            </div>
          }
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          {resultMsg &&
          <Alerts
            status={resultMsg.status}
            success={resultMsg.success}
            error={resultMsg.error}
          />
          }
          <div className={'row'}>
            {this.props.profileAction &&
              <div className={'col'}>
                <button
                  className={`btn btn-lg btn-block ${profileAdd ? 'btn-success' : 'btn-danger'}`}
                  onClick={() =>
                    (profileAdd ?
                      this.guestAction(guest)
                      :
                      confirmAlert({
                        title: `Remove / Unlink ${title}`,
                        message: `This will unlink ${title} profile.`,
                        buttons: [
                          {className:'btn-danger', label: 'Yes', onClick: () => this.guestAction(guest)},
                          {className: 'btn-secondary', label: 'No', },
                        ],
                      })
                    )
                  }
                >
                  <FontAwesomeIcon className={'white-cl mr-2'} icon={profileAdd ? faPlus : faTimes} size={'sm'}/>
                  {profileAdd ? 'Add' : 'Remove'}
                </button>
              </div>
            }
            <div className={'col'}>
              <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }
}

/* Export Component ==================================================================== */
export default ProfileDetailsOverview;
