/**
 *
 * Room Details
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../lib/api';
import {Constants, Strings} from '../../constants';
// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import {Alerts, History} from '../../components/ui';
import {confirmAlert} from '../../components/general/ConfirmAlert';
import PageHeader from '../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBan,
  faCheckCircle,
  faCircle,
  faExclamationTriangle,
  faEye,
  faLongArrowAltRight,
  faPen,
  faPlus
} from '@fortawesome/free-solid-svg-icons';

// Actions
import * as RoomActions from '../../redux/room/actions';
import AppUtil from '../../lib/util';
import RoomServiceRequest from './models/RoomServiceRequest';
import RoomServiceRequestItemDetails from './models/RoomServiceRequestItemDetails';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  roomServiceRequest: state.room.roomServiceRequest,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setRoomServiceRequest: RoomActions.setRoomServiceRequest,
};

/* Component ==================================================================== */
class RoomServiceRequestDetails extends Component {
  static componentName = 'RoomServiceRequestDetails';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    if (this.props.match.params.roomServiceRequestId) {
      this.props.setRoomServiceRequest(this.props.match.params.roomServiceRequestId)
        .then(() => {
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };

  updateOrder = (payload) => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.roomapi
          .patch(`room-service-request/${this.props.roomServiceRequest.hash}/`, payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              this.props.setRoomServiceRequest(res);
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  cacheReset = () => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.roomapi
        .post(`room-service-request/${this.props.roomServiceRequest.hash}/cache-reset/`, {})
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            this.props.setRoomServiceRequest(res);
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            resultMsg: {error},
          });
        });
    });
  };


  render = () => {
    const {loading, error, resultMsg} = this.state;
    const {roomServiceRequest} = this.props;

    const attributes = roomServiceRequest.attributes;
    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    return (
      <div className="room-details screen-container ">
        <Helmet>
          <title>Service Request Details</title>
        </Helmet>
        <PageHeader
          history={this.props.history}
          title={'Service Request'}
          description={`Room service request : ${roomServiceRequest.ref_no}`}
        />
        <div className="row">
          <div className="col-lg-8 col-sm-12 mb-5 col-md-8 col-sm-12">
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />

            <div className={'row'}>
              <div className={'col-lg-6 col-md-12'}>
                <table className={'table table-borderless table-sm mb-3'}>
                  <tbody>
                    <tr>
                      <td className={'w-50 text-muted'}>Room</td>
                      <td><strong>{attributes.room || '...'}</strong></td>
                    </tr>

                    <tr>
                      <td className={'w-50 text-muted'}>Request Type</td>
                      <td>
                        <span className={`badge ${AppUtil.roomServiceTypeColor(roomServiceRequest.service_type)}`}>
                          {roomServiceRequest.service_type_display}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className={'w-50 text-muted'}>Status</td>
                      <td>
                        <span
                          className={`badge ${AppUtil.roomServiceRequestStatusColor(roomServiceRequest.request_status)}`}>
                          {roomServiceRequest.request_status_display}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={'col-lg-6 col-md-12'}>
                <table className={'table table-borderless table-sm mb-3'}>
                  <tbody>
                    <tr>
                      <td className={'w-50 text-muted'}>Booking</td>
                      <td>
                        {attributes.booking_ref_no ?
                          <Link to={`/booking/${attributes.booking_hash}/`}>
                            {attributes.booking_ref_no}
                          </Link>
                          :
                          '...'
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className={'w-50 text-muted'}>Booking Room</td>
                      <td>
                        {attributes.booking_room_ref_no ?
                          <Link to={`/booking/${attributes.booking_hash}/booking-room/${attributes.booking_room_hash}`}>
                            {attributes.booking_room_ref_no}
                          </Link>
                          :
                          '...'
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div>
              <h5>Timeline</h5>
              <hr/>
              <ul className={'list-inline d-inline-flex list-inline-formatted'}>
                <li className={'list-inline-item align-items-center'}>
                  <p className={'mb-0'}>
                    <FontAwesomeIcon icon={faCheckCircle} size={'sm'} className={'green-cl mr-2'}/>
                    <strong>Created</strong>
                  </p>
                  <p className={'text-muted small'}>{AppUtil.formatDateTime(roomServiceRequest.created)}</p>
                </li>
                <li className={'mx-3 list-inline-item d-inline-flex align-items-center'}>
                  <FontAwesomeIcon icon={faLongArrowAltRight}/>
                </li>

                {roomServiceRequest.in_progress_time &&
                <React.Fragment>
                  <li className={'list-inline-item align-items-center'}>
                    <p className={'mb-0'}>
                      <FontAwesomeIcon icon={faCheckCircle} size={'sm'} className={'green-cl mr-2'}/>
                      <strong>In Progress</strong>
                    </p>
                    <p className={'text-muted small'}>{AppUtil.formatDateTime(roomServiceRequest.in_progress_time)}</p>
                  </li>
                  <li className={'mx-3 list-inline-item d-inline-flex align-items-center'}>
                    <FontAwesomeIcon icon={faLongArrowAltRight}/>
                  </li>
                </React.Fragment>
                }


                {roomServiceRequest.cancel_time ?
                  <React.Fragment>
                    <li className={'list-inline-item align-items-center'}>
                      <p className={'mb-0'}>
                        <FontAwesomeIcon icon={faCheckCircle} size={'sm'} className={'green-cl mr-2'}/>
                        <strong>Cancelled</strong>
                      </p>
                      <p
                        className={'text-muted small'}>{AppUtil.formatDateTime(roomServiceRequest.cancel_time)}</p>
                    </li>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <li className={'list-inline-item align-items-center'}>
                      <p className={'mb-0'}>
                        <FontAwesomeIcon
                          icon={faCheckCircle} size={'sm'}
                          className={`${roomServiceRequest.complete_time ? 'green-cl' : 'grey-cl'} mr-2`}
                        />
                        <strong>Completed</strong>
                      </p>
                      <p className={'text-muted small'}>{
                        roomServiceRequest.complete_time ?
                          AppUtil.formatDateTime(roomServiceRequest.complete_time) :
                          roomServiceRequest.request_status_display
                      }</p>
                    </li>
                  </React.Fragment>
                }
              </ul>
            </div>

            <div className={'spacer-20'}/>

            <table className="table border format-table">
              <tbody>
                {!_.isEmpty(roomServiceRequest.request_items) ?
                  <React.Fragment>
                    {roomServiceRequest.request_items.map((data, i) => (
                      <tr key={i}>
                        <td className={'w-75'}>
                          <div className={'row'}>
                            <div className={'col-1'}>
                              <FontAwesomeIcon
                                icon={data.request_status === Constants.ROOM_SERVICE_REQUEST_STATUS.COMPLETE ? faCheckCircle : faCircle}
                                className={data.request_status === Constants.ROOM_SERVICE_REQUEST_STATUS.COMPLETE ? 'green-cl' : 'grey-cl'}
                              />
                            </div>
                            <div className={'col'}>
                              <p className={'mb-0'}><strong>{data.description}</strong></p>
                              {data.additional_description &&
                              <p className={'mb-0 text-muted small'}>Created Note : {data.additional_description}</p>
                              }

                              {data.response_description &&
                              <p className={'mb-0 text-muted small'}>Action Note : {data.response_description}</p>
                              }
                            </div>
                          </div>
                        </td>
                        <td className={'data-table-cell-sm'}>
                          {data.service_type === Constants.ROOM_SERVICE_TYPE.AMENITY &&
                          <React.Fragment>
                            <strong>x {data.quantity} </strong> {data.quantity_type_display}
                          </React.Fragment>
                          }
                        </td>
                        <td className={'data-table-cell-md'}>
                          <span className={`badge ${AppUtil.roomServiceRequestStatusColor(data.request_status)}`}>
                            {data.request_status_display}
                          </span>
                          <br/>
                          <small>{data.service_type_display}</small>
                        </td>
                        <td className={'w-10'}>
                          <button
                            className={'btn btn-sm btn-outline-primary ml-2'}
                            onClick={() => this.setState({
                              roomServiceRequestItem: data,
                              showRoomServiceRequestItemDetails: true,
                            })}>
                            <FontAwesomeIcon size={'sm'} icon={data.lock ? faEye : faPen}/>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <tr>
                      <td colSpan={3}>
                        <div className="text-center">
                          <FontAwesomeIcon icon={faExclamationTriangle} size={'2x'} className={'mt-3 grey-cl'}/>
                          <p className={'text-secondary small'}>No items in this Request</p>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                }

                {!roomServiceRequest.lock &&
                <tr>
                  <td colSpan={4}>
                    <div className={'text-center'}>
                      <button
                        type="button" className="btn btn-link btn-sm m-0 p-0" data-toggle="modal"
                        onClick={() => this.setState({
                          showRoomServiceRequestType: roomServiceRequest.service_type,
                          showRoomServiceRequest: true,
                        })}><FontAwesomeIcon icon={faPlus} size={'sm'}/> Add {roomServiceRequest.service_type_display}
                      </button>
                      {roomServiceRequest.service_type === Constants.ROOM_SERVICE_TYPE.SERVICE &&
                      <button
                        type="button" className="btn btn-link btn-sm m-0 p-0 ml-5" data-toggle="modal"
                        onClick={() => this.setState({
                          showRoomServiceRequestType: Constants.ROOM_SERVICE_TYPE.AMENITY,
                          showRoomServiceRequest: true,
                        })}><FontAwesomeIcon icon={faPlus} size={'sm'}/> Add Amenity
                      </button>
                      }
                    </div>
                  </td>
                </tr>
                }
              </tbody>
            </table>
            <div className={'spacer-20'}/>


            {!roomServiceRequest.lock &&
            <div>
              <Alerts
                status={resultMsg.status}
                success={resultMsg.success}
                error={resultMsg.error}
              />

              <div className={'row'}>
                <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                  <button
                    disabled={roomServiceRequest.locked_items}
                    className={'btn btn-lg btn-block btn-secondary'}
                    onClick={() => {
                      confirmAlert({
                        title: 'Cancel Request',
                        message: Strings.roomServiceRequestCancellation,
                        buttons: [
                          {
                            className: 'btn-danger',
                            label: 'Cancel Request',
                            onClick: () => this.updateOrder({request_status: Constants.ROOM_SERVICE_REQUEST_STATUS.CANCELLED}),
                          },
                          {
                            className: 'btn-secondary',
                            label: 'Close',
                          },
                        ],
                      });
                    }}
                  >
                    Cancel Order
                  </button>
                </div>
                <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                  <button
                    disabled={roomServiceRequest.unlocked_items}
                    className={'btn btn-lg btn-block btn-success'}
                    onClick={() => {
                      confirmAlert({
                        title: 'Complete & Close Request',
                        message: Strings.roomServiceRequestConfirm,
                        buttons: [
                          {
                            className: 'btn btn-success',
                            label: 'Complete & Close Request',
                            onClick: () => this.updateOrder({request_status: Constants.ROOM_SERVICE_REQUEST_STATUS.COMPLETE}),
                          },
                          {
                            className: 'btn-secondary',
                            label: 'Close',
                          },
                        ],
                      });
                    }}
                  >
                    Complete & Close Request
                  </button>
                </div>
              </div>
              <p className={'text-muted text-center'}>{Strings.roomServiceRequestConfirmationMessage}</p>
              <div className={'spacer-20'}/>
            </div>
            }
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item  align-items-center" onClick={() => this.cacheReset()}>
                <small>Request Actions</small>
              </li>
              <li className="list-group-item">
                <button
                  disabled={roomServiceRequest.locked_items || roomServiceRequest.lock}
                  onClick={() => confirmAlert({
                    title: 'Deny Request',
                    message: Strings.roomServiceRequestDeny,
                    buttons: [
                      {
                        className: 'btn-danger',
                        label: 'Deny Request',
                        onClick: () => this.updateOrder({request_status: Constants.ROOM_SERVICE_REQUEST_STATUS.CANNOT_SERVE}),
                      },
                      {
                        className: 'btn-secondary',
                        label: 'Close',
                      },
                    ],
                  })}
                  className="btn btn-link orange-cl m-0 p-0">
                  <FontAwesomeIcon icon={faBan} size={'sm'} className={'mr-2'}/> Deny Request
                </button>
                <p className={'small text-muted mb-0'}>Request not serviceable.</p>
              </li>
            </ul>
          </div>
        </div>

        <RoomServiceRequest
          roomServiceRequest={roomServiceRequest}
          room={roomServiceRequest.room_id}
          bookingRoom={roomServiceRequest.booking_room_id ? roomServiceRequest.booking_room_id : null}
          show={this.state.showRoomServiceRequest}
          serviceType={this.state.showRoomServiceRequestType}
          onHide={() => this.setState({showRoomServiceRequest: false})}
          updateResponse={(data) => this.props.setRoomServiceRequest(data)}
        />

        <RoomServiceRequestItemDetails
          onHide={()=>this.setState({showRoomServiceRequestItemDetails: false})}
          show={this.state.showRoomServiceRequestItemDetails}
          roomServiceRequest={roomServiceRequest}
          roomServiceRequestItem={this.state.roomServiceRequestItem}
          updateResponse={(data) => this.props.setRoomServiceRequest(data)}
        />
        <History object={roomServiceRequest}/>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      showRoomServiceRequest: false,
      showRoomServiceRequestType: Constants.ROOM_SERVICE_TYPE.SERVICE,
      showRoomServiceRequestItemDetails: false,
      roomServiceRequestItem: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomServiceRequestDetails);