/**
 * Review Reducer
 *
 */

// Set initial state
const initialState = {
  review: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
  case 'SET_REVIEW': {
    let review = [];
    if (action.data && typeof action.data === 'object') {
      review = action.data;
    }
    return {
      ...state,
      review,
    };
  }
  default:
    return state;
  }
};
