/**
 *
 * Order Analytics Complimentary
 */
import _ from 'lodash';
import Moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';

// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import { AnalyticConstants } from '../../../constants';
// Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import OAItemDeduction from './components/OAItemDeduction';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class OrderAnalyticsItemComp extends Component {
  static componentName = 'OrderAnalyticsItemComp';

  static propTypes = {
    pos: PropTypes.string,
    endDate: PropTypes.object,
    startDate: PropTypes.object
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {startDate, endDate, dateType, pos} = this.props;
    this.setState({loading: true});
    const queryParams = {
      'date_type': dateType,
      'pos': pos ? pos : null,
      'report_type': 'complimentary',
      'start_date': (startDate ? startDate : Moment()).format('YYYY-MM-DD'),
      'end_date': endDate ? endDate.format('YYYY-MM-DD') : null
    };
    AppAPI[pos ? 'posapi' : 'orderapi'].get(`order-analytics/?${queryString.stringify(queryParams)}`)
      .then((res) => {
        this.setState({loading: false, response: res});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({loading: false, error: error});
      });
  };

  render = () => {
    const {loading, error, response} = this.state;
    const {property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    // Initialize with empty array for order data set
    const orderDataSet = {};
    Object.keys(AnalyticConstants.orderAnalytics).forEach((data) => {
      orderDataSet[data] = {};
    });

    // Containers for order status data & order source data
    const orderData = {
      orderCompData: _.cloneDeep(orderDataSet),
    };


    if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
      const responseData = response.data;
      if (!_.isEmpty(responseData.order_items_complimentary)) {
        responseData.order_items_complimentary.forEach((data) => {
          Object.entries(AnalyticConstants.orderAnalytics).forEach(([key, value]) => {
            if (data[value]) {
              AppUtil.insertOrAddObject(orderData.orderCompData[key], data.order_source, data[value], 'float');
            }
          });
        });
      }
    }

    return (
      <div className="screen-container">
        <Helmet>
          <title>Order : Complimentary</title>
        </Helmet>
        <OAItemDeduction
          property={property}
          deductionTypeShort={'Comp'}
          deductionType={'Complimentary'}
          orderData={orderData.orderCompData}
          deductionKey={'orderComplimentaryTotal'}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderAnalyticsItemComp);
