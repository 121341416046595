/**
 *
 * Booking Notes Logs
 */

import _ from 'lodash';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';

// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageSubHeader from '../../../components/dashboard/PageSubHeader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight, faReceipt, faStickyNote, faSync } from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  booking: state.booking.booking,
});

// Any actions to map to the component?
const mapDispatchToProps = {};


/* Component ==================================================================== */
class BookingNotes extends Component {
  static componentName = 'BookingNotes';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    this.setState({loading: true});
    let appApi = AppAPI.booking.get(`${this.props.match.params.bookingId}/related-details/?key=notes`);

    appApi.then((res) => {
      this.setState({
        response: res,
        loading: false,
      });
    })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };


  renderNotes = (title, notes=[], initialCol = () => <></>) => {
    return (
      <React.Fragment>
        {_.isEmpty(notes)
          ? (
            <tr className={'tr-align-middle'}>
              <td className={'border-right data-table-cell-xm'}>
                {initialCol()}
              </td>
              <td className={'bg-light data-table-cell-xl'} colSpan={2}>
                <div className={'text-center py-2'}>
                  <h6><FontAwesomeIcon icon={faReceipt} className={'mr-2'}/> No notes attached to the {title} </h6>
                </div>
              </td>
            </tr>
          )
          : (
            <React.Fragment>
              {notes.map((data, i) => (
                <React.Fragment key={i}>
                  <tr className={'tr-align-middle'}>
                    {i === 0 && (
                      <td rowSpan={notes.length} className={'border-right data-table-cell-md'}>
                        {initialCol()}
                      </td>
                    )}
                    <React.Fragment>
                      <td className={'data-table-cell-lg'}>
                        <h6 className="mb-1 text-break">{data.title}</h6>
                        <p className="mb-0 small">
                          Source : {data.source_display}
                          {(!_.isEmpty(data.attributes) && data.attributes.created_user) && ` (${data.attributes.created_user})`}
                        </p>
                        <p className={'mb-0 small text-muted'}>{AppUtil.formatDateTime(data.created)}</p>
                        <ul className={'list-inline mb-0'}>
                          <li className={'list-inline-item'}>
                            <span className={`badge ${AppUtil.noteTypeColor(data.note_type)}`}>{data.note_type_display}</span>
                          </li>
                          <li>{data.action_status && <span className={'badge badge-success'}>Completed</span>}</li>
                        </ul>
                      </td>
                      <td className={'data-table-cell-xl'}>
                        <div className={'border p-3 grey-light-bg rounded'}>
                          <p>{data.description || 'N.A'}</p>
                        </div>
                      </td>
                    </React.Fragment>
                  </tr>
                </React.Fragment>
              ))}
            </React.Fragment>
          )
        }
      </React.Fragment>
    );
  };

  render = () => {

    const {loading, error, response} = this.state;
    const {booking} = this.props;


    if (loading || _.isEmpty(booking) || _.isEmpty(response)) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const {orders, booking_rooms} = response;

    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Booking Notes</title>
        </Helmet>

        <PageSubHeader
          icon={faStickyNote} title={'Notes'}
          description={'Notes from all orders and rooms linked to the room.'}
        />


        <div className={'row'}>
          <div className={'col-12 col-sm-8'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button onClick={() => this.fetchInitData()} className={'btn btn-sm btn-outline-secondary'}>
                  <FontAwesomeIcon icon={faSync} className={'mr-2'} size={'sm'}/> Refresh
                </button>
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-4 text-center text-sm-right'}>
          </div>
        </div>

        <h5 className={'mb-3'}>Booking Notes</h5>
        <div className="table-responsive border mb-5">
          <table className={'table mb-0'}>
            <tbody>
              {this.renderNotes('booking', booking.notes, () => (
                <div>
                  <p className={'mb-0'}><strong>Booking : {booking.ref_no}</strong></p>
                </div>
              ))}
            </tbody>
          </table>
        </div>

        <hr/>
        <h5 className={'mb-3'}>Booking Room Notes</h5>
        <div className="table-responsive border mb-5">
          <table className={'table mb-0'}>
            <tbody>
              {booking_rooms.map((data, i) => (
                <React.Fragment key={i}>
                  {this.renderNotes('room', data.notes, ()=>(
                    <div>
                      <p className={'mb-1'}><strong>Room {data.room_number} : {data.ref_no}</strong></p>
                      {(data.attributes) && (
                        <React.Fragment>
                          {data.attributes.room_type_name && <p className={'mb-1 text-muted'}>Room Type : {data.attributes.room_type_name} </p>}
                          {data.attributes.room_name && <p className={'mb-1 text-muted'}>Room : {data.attributes.room_name} </p>}
                        </React.Fragment>
                      )}

                      <p className={'mb-3'}>
                        <span className={`badge ${AppUtil.bookingStatusColor(data.booking_status)}`}>
                          {data.booking_status_display}
                        </span>
                      </p>
                      <Link
                        className={'btn btn-outline-primary btn-sm'}
                        to={`/booking/${booking.hash}/booking-room/${data.hash}/`}
                      >
                        Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
                      </Link>
                    </div>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>

        <hr/>
        <h5 className={'mb-3'}>Order Notes</h5>
        <div className="table-responsive border mb-5">
          <table className={'table mb-0'}>
            <tbody>
              {orders.map((data, i) => (
                <React.Fragment key={i}>
                  {this.renderNotes('order', data.notes, ()=>(
                    <div>
                      <p className={'mb-1'}><strong>Order : {data.ref_no}</strong></p>
                      <p className={'mb-1 text-muted'}>Source : {data.order_source_display}</p>
                      {!_.isEmpty(data.attributes) &&
                      <React.Fragment>
                        {data.attributes.pos_name && <p className={'mb-1 small text-muted'}>{data.attributes.pos_name}</p>}
                        {data.attributes.event_name && <p className={' mb-1 small text-muted'}>{data.attributes.event_name}</p>}
                        {data.attributes.booking_ota && <p className={'mb-1 small text-muted'}>{data.attributes.booking_ota}</p>}
                      </React.Fragment>
                      }
                      <p className={'mb-3'}>
                        <span className={`mr-2 badge ${AppUtil.paymentStatusColor(data.order_status)}`}>
                          {data.order_status_display}
                        </span>
                        {!data.lock && <span className={'badge badge-warning small mr-2'}>Open Order</span>}
                      </p>

                      <Link className={'btn btn-outline-primary btn-sm'} to={`/order/${data.hash}/`}>
                        Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
                      </Link>
                    </div>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      noteData: null,
      showNotesManager: false,
      response: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(BookingNotes);
