/**
 *
 * Booking Engine Content Pages manage
 * property booking engine content pages settings.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
// Consts and Libs
import AppAPI from '../../../lib/api';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import {faCheckCircle, faPen, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropertyBookingEngineCoverImageManage from './PropertyBookingEngineCoverImageManage';
import {Link} from 'react-router-dom';
import {Constants} from '../../../constants';
import { Img } from 'react-image';
import CopyToClipboard from '../../../components/general/CopyToClipboard';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PropertyBookingEngineContentPagesManage extends Component {
  static componentName = 'PropertyBookingEngineContentPagesManage';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    if (this.props.match.params.pageId) {
      AppAPI.propertyapi.get(`property-content-page/${this.props.match.params.pageId}/`)
        .then((res) => {
          this.setState({
            loading: false,
            pageContent: res,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error: error,
            resultMsg: {error},
          });
        });
    } else {
      this.setState({loading: false});
    }
  };


  render = () => {
    const {loading, error, pageContent} = this.state;
    const {property} = this.props;
    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;


    return (
      <div className="bank-details screen-container ">
        <Helmet>
          <title>Content Page : {pageContent.title}</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={`Content Page : ${pageContent.title} `}
        />

        <div className={'row'}>
          <div className="col-lg-10 col-md-12 col-sm-12 mb-5">
            <div
              className={'border p-2 text-center anchor w-100'} data-toggle="collapse"
              role="button" aria-expanded="false" aria-controls="orderActions">
              <h6 className={'mb-0'}>
                Content
              </h6>
            </div>
            <div className={'border p-2 mb-5'} id={'primaryAction'}>
              <div className={'row mb-3'}>
                <div className={'col-12 col-md-6'}>
                  <ul className="list-group list-group-sm">
                    <li className="list-group-item">
                      <p className={'mb-0'}><strong>Title</strong> : {pageContent.title}</p>
                    </li>
                    <li className="list-group-item">
                      <p className={'mb-0'}><strong>Featured</strong> :
                        <FontAwesomeIcon
                          className={pageContent.featured ? 'green-cl' : 'red-cl'}
                          icon={pageContent.featured ? faCheckCircle : faTimesCircle} size={'sm'}
                        />
                      </p>
                    </li>
                  </ul>
                </div>
                <div className={'col-12 col-md-6'}>
                  <ul className="list-group list-group-sm">
                    <li className="list-group-item">
                      <p className={'mb-0'}><strong>Layout Type</strong> : {pageContent.layout_type_display}</p>
                    </li>
                    <li className="list-group-item">
                      <p className={'mb-0'}><strong>Status</strong> :
                        <FontAwesomeIcon
                          className={pageContent.active ? 'green-cl' : 'red-cl'}
                          icon={pageContent.active ? faCheckCircle : faTimesCircle} size={'sm'}
                        />
                      </p>
                    </li>

                  </ul>
                </div>
              </div>
              <ul className="list-group list-group-sm">
                <li className="list-group-item">
                  <p className={'mb-0'}>
                    <strong>Permanent URL</strong> : <code>{pageContent.page_public_url}</code>
                    <CopyToClipboard
                      buttonClass={'d-inline ml-2'} buttonClassCopied={'d-inline ml-2 green-cl'}
                      content={pageContent.page_public_url}/>
                  </p>
                </li>
                <li className="list-group-item">
                  <p className={'mb-0'}>
                    <strong>Internal URL</strong> : <code>{`${property.property_slug}/${pageContent.page_url}`}</code>
                    <CopyToClipboard
                      buttonClass={'d-inline ml-2'} buttonClassCopied={'d-inline ml-2 green-cl'}
                      content={`${property.property_slug}/${pageContent.page_url}`}/>
                  </p>
                </li>
              </ul>
            </div>

            <button
              onClick={() => this.setState({showPropertyBookingEngineCoverImageManage: true})}
              className="btn btn-link mb-2 float-right"
            >
              <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit Featured Image
            </button>
            <div
              className={'border p-2 text-center anchor w-100'} data-toggle="collapse"
              role="button" aria-expanded="false" aria-controls="orderActions">
              <h6 className={'mb-0'}>
                Content
              </h6>
            </div>
            <div className={'border p-2'} id={'primaryAction'}>
              <div className={'my-2'}>
                {(pageContent.layout_type === Constants.PAGE_LAYOUT.FULL_WIDTH_COVER_IMAGE || pageContent.layout_type === Constants.PAGE_LAYOUT.SIDEBAR_COVER_IMAGE) ?
                  <div>
                    <div
                      className={'home-header'}
                      style={pageContent.image && pageContent.image.cover && {
                        backgroundImage: `url(${pageContent.image.cover})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                      }}
                    >
                      <table className={'w-100 h-100  header-inner'}>
                        <tbody>
                          <tr>
                            <td className="align-middle">
                              <div className={'text-light'}>
                                <h1>{pageContent.title}</h1>
                                <p>
                                  {pageContent.short_description && pageContent.short_description}
                                </p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  :
                  <div>
                    <h3 className={pageContent.title ? '' : 'text-muted my-4'}>
                      {pageContent.title || 'Enter Description Title'}
                    </h3>
                    {pageContent.short_content ?
                      <p className={'font-weight-light'}>{pageContent.short_content}</p>
                      :
                      <p className={'text-muted'}>Short description.</p>
                    }

                    {pageContent.image && pageContent.image.cover &&
                     <Img
                       className="d-block w-75"
                       src={pageContent.image.cover}
                       loader={<Loading heightMatch={false}/>}
                     />
                    }

                  </div>
                }
              </div>


              {pageContent.content ?
                <p className={'display-linebreak'}>{pageContent.content}</p>
                :
                <div>
                  <p className={'text-muted'}>Page content.</p>
                </div>
              }

            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-12 mb-5">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link to={'manage'} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <PropertyBookingEngineCoverImageManage
          show={this.state.showPropertyBookingEngineCoverImageManage}
          onHide={() => this.setState({showPropertyBookingEngineCoverImageManage: false})}
          onSave={(res) => {
            this.setState({loading: true, pageContent: res}, () => {
              this.setState({loading: false});
            });
          }}
          pageContent={pageContent}
        />

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      pageContent: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      initialValues: {
        active: true,
        layout_type: 1,
      },
      showPropertyBookingEngineCoverImageManage: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyBookingEngineContentPagesManage);
