/**
 * Badge
 *  Status badge for displaying status.
 */
import React from 'react';
import PropTypes from 'prop-types';

class BadgeStatus extends React.PureComponent {
  static componentName = 'BadgeStatus';

  static propTypes = {
    status: PropTypes.bool,
    failText: PropTypes.string,
    className: PropTypes.string,
    successText: PropTypes.string,
    failClass: PropTypes.string,
    successClass: PropTypes.string,
  };

  static defaultProps = {
    failText: 'Disabled',
    successText: 'Enabled',
    failClass: 'badge-secondary',
    successClass: 'badge-success',
  };


  render = () => {
    const {failText, successText, failClass, successClass, status, className} = this.props;

    return (
      <span className={`badge ${className} ${status ? successClass : failClass}`}>
        {status ? successText : failText}
      </span>
    );
  };
}

/* Export Component ==================================================================== */
export default BadgeStatus;
