/**
 * Payment Detail Model
 * Contains details of payment
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import React, {Component} from 'react';

// Consts and Libs
import AppUtil from '../../../lib/util';
import {Constants} from '../../../constants';
import MessagingManager from '../../messaging/MessagingManager';

// Components
import PaymentLink from '../Components/PaymentLink';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PTerminalDevices from '../Components/PTerminalDevices';
import PTerminalAction from '../PTerminal/Components/PTerminalAction';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faExternalLinkAlt,
  faLongArrowAltRight,
  faPaperPlane,
  faStickyNote,
  faTimes
} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */

class PaymentDetailsModel extends Component {
  static componentName = 'PaymentDetailsModel';

  static propTypes = {
    payment: PropTypes.object.isRequired,
  };

  static defaultProps = {
    selectedTab: 'payments',
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
        loading: false,
      });

    }
    return true;
  }


  render = () => {
    const {show, payment} = this.props;
    const {error, loading} = this.state;

    if (!show || !this.props.payment) return null;

    if (error) return <Error full={true} text={error}/>;


    return (
      <Modal
        {...this.props}
        size={'lg'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Payment Details #{payment && payment.ref_no}

            <Link
              rel='noopener noreferrer' target={'_blank'}
              to={`/payment/${payment.hash}`} className={'btn btn-outline-primary btn-sm mx-5'}
            >
              <FontAwesomeIcon className={'mr-2'} icon={faExternalLinkAlt} size={'sm'}/> Payment Details
            </Link>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(loading || _.isEmpty(payment)) ? <Loading heightMatch={false}/> :
            <React.Fragment>
              <div className={'d-inline-flex align-items-baseline mb-3'}>
                <h4>{`${payment.currency} ${payment.total}`}</h4>
                <div className={'align-items-baseline'}>
                  <span className={'ml-2 badge ' + (AppUtil.paymentStatusColor(payment.payment_status))}>
                    {payment.payment_status_display}
                  </span>
                  {payment.payment_status === Constants.PAYMENT_STATUS.PAID &&
                  <span className={'ml-2 badge ' + (AppUtil.settlementStatusColor(payment.settlement_status))}>
                    {payment.settlement_status_display}
                  </span>
                  }
                  {payment.payment_mode !== Constants.PAYMENT_MODE.PM_STANDARD &&
                  <span className={`ml-2 badge ${AppUtil.paymentModeColor(payment.payment_mode)}`}>
                    {payment.payment_mode_display}
                  </span>
                  }
                  {payment.refund_status !== Constants.REFUND_STATUS.NOTPAID &&
                  <span className={'ml-2 badge ' + (AppUtil.paymentStatusColor(payment.refund_status))}>
                    {payment.refund_status_display}
                  </span>
                  }
                </div>
              </div>

              <div className={'row mb-3'}>
                <div className={'col-lg-6 col-md-12'}>
                  <table className={'table table-borderless table-sm mb-0'}>
                    <tbody>
                      {payment.payment_id &&
                    <tr>
                      <td className={'w-50 text-muted'}>Payment ID</td>
                      <td><strong>{payment.payment_id}</strong></td>
                    </tr>
                      }
                      <tr>
                        <td className={'w-50 text-muted'}>Payment Method</td>
                        <td>{payment.payment_method_display}</td>
                      </tr>
                      <tr>
                        <td className={'w-50 text-muted'}>Payment Type</td>
                        <td>{payment.payment_type_display}</td>
                      </tr>
                      <tr>
                        <td className={'w-50 text-muted'}>Payment Mode</td>
                        <td>{payment.payment_mode_display}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className={'col-lg-6 col-md-12'}>
                  <table className={'table table-borderless table-sm mb-0'}>
                    <tbody>
                      {payment.payment_settlement_id &&
                    <tr>
                      <td className={'w-50 text-muted'}>Settlement ID</td>
                      <td>{payment.payment_settlement_id}</td>
                    </tr>
                      }
                      {!_.isEmpty(payment.attributes) &&
                    <React.Fragment>
                      {payment.attributes.consolidated_payment_hash &&
                      <tr>
                        <td className={'w-50 text-muted'}>Consolidated Payment</td>
                        <td>
                          <Link to={`/payment/${payment.attributes.consolidated_payment_hash}/`}>
                            {payment.attributes.consolidated_payment_ref_no || 'Payment'}
                          </Link>
                        </td>
                      </tr>
                      }
                      {payment.attributes.payment_terminal &&
                      <tr>
                        <td className={'w-50 text-muted'}>Terminal</td>
                        <td>{payment.attributes.payment_terminal}</td>
                      </tr>
                      }
                      <tr>
                        <td className={'w-50 text-muted'}>Created By</td>
                        <td>{payment.attributes.created_user || 'N.A'}</td>
                      </tr>
                      {payment.attributes.paid_user &&
                      <tr>
                        <td className={'w-50 text-muted'}>Paid by</td>
                        <td>{payment.attributes.paid_user}</td>
                      </tr>
                      }
                      {payment.attributes.settled_user &&
                      <tr>
                        <td className={'w-50 text-muted'}>Settled by</td>
                        <td>{payment.attributes.settled_user}</td>
                      </tr>
                      }
                      {payment.attributes.attached_user &&
                      <tr>
                        <td className={'w-50 text-muted'}>Attached by</td>
                        <td>{payment.attributes.attached_user}</td>
                      </tr>
                      }
                    </React.Fragment>
                      }
                    </tbody>
                  </table>
                </div>
                <div className={'col-12'}>
                  {payment.description &&
                  <p className={'p-1'}><span className={'text-muted'}>Description</span> : {payment.description}</p>
                  }
                </div>
              </div>


              <div className={'row mb-3'}>
                <div className={'col-12'}>
                  <p><strong>Payment Breakdown</strong></p>
                  <hr/>
                  <table className={'table table-borderless table-sm'}>
                    <tbody>
                      <tr>
                        <td className={'w-25'}>Payment</td>
                        <td>{payment.currency + ' ' + payment.total}</td>
                        <td>
                          {payment.reference_id &&
                        <React.Fragment>
                          <FontAwesomeIcon icon={faStickyNote} className={'mr-2'}/> {payment.reference_id}
                        </React.Fragment>
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className={'w-25'}>Settled</td>
                        <td
                          className={'w-25'}>{payment.currency + ' ' + payment.total_settled} {payment.settlement_status === Constants.SETTLEMENT_STATUS.NOT_SETTLED && payment.get_settlement_status_display}</td>
                        <td>
                          {payment.settlement_id &&
                        <React.Fragment>
                          <FontAwesomeIcon icon={faStickyNote} className={'mr-2'}/> {payment.settlement_id}
                        </React.Fragment>
                          }
                        </td>
                      </tr>
                      {parseFloat(payment.settlement_difference) > 0 &&
                    <tr>
                      <td className={'w-25'}>{payment.settlement_fee_type_display}</td>
                      <td className={'w-25'}>
                        - {payment.currency + ' ' + payment.settlement_difference}
                        {payment.settlement_fee_tax > 0 &&
                        <React.Fragment> ( Tax {payment.settlement_fee_tax} )</React.Fragment>
                        }
                      </td>
                      <td>
                        {payment.settlement_fee_description &&
                        <React.Fragment>
                          <FontAwesomeIcon icon={faStickyNote} className={'mr-2'}/>
                          {payment.settlement_fee_description}
                        </React.Fragment>
                        }
                      </td>
                    </tr>
                      }

                      {parseFloat(payment.total_refunded) > 0 &&
                    <tr>
                      <td className={'w-25'}>Refunded</td>
                      <td className={'w-25'}>{payment.currency + ' ' + payment.total_refunded}</td>
                      <td/>
                    </tr>
                      }
                      {(payment.settlement_status === Constants.SETTLEMENT_STATUS.SETTLED || parseFloat(payment.total_refunded) > 0) &&
                    <tr>
                      <td className={'w-25'}><strong>Net Payment</strong></td>
                      <td className={'w-25'}><strong>{payment.currency + ' ' + payment.total_available_fund}</strong>
                      </td>
                      <td/>
                    </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>


              {payment.payment_link && <PaymentLink paymentLink={payment.payment_link}/>}


              {(payment.payment_method === Constants.PAYMENT_METHODS.PAYMENT_TERMINAL) &&
                <React.Fragment>
                  {(payment.payment_status === Constants.PAYMENT_STATUS.NOTPAID && _.isEmpty(payment.pterminal_data)) &&
                  <PTerminalDevices
                    payment={payment}
                    PTerminalDevices={payment.payment_terminal_devices}
                    action={() => this.props.fetchPaymentData(payment, false)}
                  />
                  }
                  {!_.isEmpty(payment.pterminal_data) &&
                  <PTerminalAction
                    allowVoid={false}
                    pterminal={payment.pterminal_data}
                    action={(data)=>{
                      switch(data.payment_status){
                      case Constants.PTERMINAL_STATUS.CANCELLED:
                        this.props.fetchPaymentData(payment);
                        break;
                      case Constants.PTERMINAL_STATUS.PAID:
                        this.props.fetchPaymentData(payment, true);
                        break;
                      default:
                        break;
                      }
                    }}
                  />
                  }
                </React.Fragment>
              }

              {(!_.isEmpty(payment.attributes) && payment.attributes.consolidated_payment_hash) &&
              <React.Fragment>
                <div className={'row mb-3'}>
                  <div className={'col-lg-12 col-md-12 col-sm-12 mb-3'}>
                    <Link
                      target={'_blank'} className={'btn btn-lg btn-block btn-success'}
                      to={`/payment/${payment.attributes.consolidated_payment_hash}/`}
                    >
                      Consolidated Payment <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
                    </Link>
                  </div>
                </div>
              </React.Fragment>
              }
            </React.Fragment>
          }
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          <div>
            <div className={'row'}>
              <div className={'col'}>
                <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                  <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                </button>
              </div>
              <div className={'col'}>
                {payment.payment_method === Constants.PAYMENT_METHODS.ONLINE_PAYMENT
                  ? (
                    <button
                      className={'btn btn-primary btn-lg btn-block'}
                      onClick={() => this.setState({showMessagingManager: true})}
                    >
                      <FontAwesomeIcon icon={faPaperPlane} size={'sm'} className={'mr-2'}/> Notification ( E-Mail/SMS )
                    </button>
                  )
                  : (
                    <Link
                      rel="noopener noreferrer" target={'_blank'}
                      to={`/payment/${payment.hash}`} className={'btn btn-outline-primary btn-lg btn-block'}
                    >
                      <FontAwesomeIcon className={'mr-2'} icon={faExternalLinkAlt} size={'sm'}/> Payment Details
                    </Link>)
                }
              </div>
            </div>
          </div>
        </Modal.Footer>

        {/* Messaging */}
        <MessagingManager
          onHide={() => this.setState({showMessagingManager: false})}
          show={this.state.showMessagingManager}
          property={this.props.property}
          payment={payment}
        />
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      showMessagingManager: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default PaymentDetailsModel;
