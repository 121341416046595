/**
 *
 * POS Items List
 */
import _ from 'lodash';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import React, {Component} from 'react';
// Consts and Libs
import AppAPI from '../../lib/api';
import EXTUtil from '../../lib/extUtils';

// Components
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';

import {Alerts} from '../../components/ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle, faCircleNotch, faEye, faIceCream, faShoppingCart, faSync} from '@fortawesome/free-solid-svg-icons';
import Strings from '../../constants/strings';
import {Constants, Filters} from '../../constants';
import FilterButton from '../../components/dashboard/FilterButton';
import PosItemTypeDetailsView from '../../components/dashboard/PosItemTypeDetailsView';
import POSItemDetails from './models/POSItemDetails';
// Actions
import * as POSActions from '../../redux/pos/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  posDetails: state.pos.posDetails,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPOSDetails: POSActions.setPOSDetails,
};

/* Component ==================================================================== */
class POSItemsList extends Component {
  static componentName = 'POSItemsList';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
  };

  fetchInitData = () => {
    this.setState({loading: true});
    this.props.setPOSDetails(this.props.match.params.POSId)
      .then(() => (
        this.setState({
          loading: false,
        })
      ))
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  }

  handleInputChange = (value) => {
    this.setState({value: value, searching: true});
    this.onChangeDebounced(value);
  };

  onChangeDebounced = (value) => {
    this.setState({searchText: value, searching: false});
  };


  render = () => {

    const {
      loading, error, resultMsg, selectCategory, searching, selectFoodType, selectFoodContentType,
      selectDrinkContentType
    } = this.state;
    const {posDetails} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const categoryFilter = [];

    if (!_.isEmpty(posDetails.category)) {
      posDetails.category.forEach((data) => categoryFilter.push({
        title: data.name,
        key: data.id,
      }));
    }

    let items = EXTUtil.filterPOSItems(posDetails.items, this.state);

    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Items list</title>
        </Helmet>

        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h4 className={'mb-4'}>
                  <FontAwesomeIcon icon={faShoppingCart} className={'mr-2'} size={'sm'}/> Products(s)
                </h4>

                <div className={'row mb-3'}>
                  <div className={'col-12 col-sm-12'}>
                    <ul className="list-inline list-item-mb-1">
                      <li className="list-inline-item">
                        <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.fetchInitData()}>
                          <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                        </button>
                      </li>
                      {!_.isEmpty(categoryFilter) &&
                       <li className="list-inline-item">
                         <FilterButton
                           title={'Category'}
                           data={categoryFilter}
                           selectKey={selectCategory}
                           action={(data) => this.setState({selectCategory: data.key})}
                           size={'sm'}
                         />
                       </li>
                      }
                      {[Constants.POS_TYPE.RESTRO_BAR, Constants.POS_TYPE.RESTAURANT, Constants.POS_TYPE.BAR].includes(parseInt(posDetails.pos_type)) &&
                      <React.Fragment>
                        <li className="list-inline-item">
                          <FilterButton
                            title={'Item Type'}
                            data={Filters.foodType}
                            selectKey={selectFoodType}
                            action={(data) => this.setState({selectFoodType: data.key})}
                            size={'sm'}
                          />
                        </li>
                        <li className="list-inline-item">
                          <FilterButton
                            disabled={selectFoodType !== Constants.POS_FOOD_TYPE.FOOD}
                            title={'Food Content'}
                            data={Filters.foodContentType}
                            selectKey={selectFoodContentType}
                            action={(data) => this.setState({selectFoodContentType: data.key})}
                            size={'sm'}
                          />
                        </li>
                        <li className="list-inline-item">
                          <FilterButton
                            disabled={selectFoodType !== Constants.POS_FOOD_TYPE.DRINK}
                            title={'Drink Content'}
                            data={Filters.drinkContentType}
                            selectKey={selectDrinkContentType}
                            action={(data) => this.setState({selectDrinkContentType: data.key})}
                            size={'sm'}
                          />
                        </li>
                      </React.Fragment>
                      }
                    </ul>
                  </div>
                </div>

                <div className="form-row align-items-center mb-4">
                  <div className={searching ? 'col-sm-11' : 'col-sm-12'}>
                    <input
                      type="search"
                      className="form-control"
                      placeholder={'Search Item'}
                      name="q"
                      autoComplete={'off'}
                      onChange={(data) => this.handleInputChange(data.target.value)}
                    />
                  </div>
                  <div className="col-auto">
                    {searching && <i className="fa fa-spinner"/>}
                  </div>
                </div>

                <div className="table-responsive border">
                  <table className={'table format-table'}>
                    <thead>
                      <tr>
                        <th className={'data-table-cell-lg'}>Name</th>
                        {[Constants.POS_TYPE.RESTRO_BAR, Constants.POS_TYPE.RESTAURANT, Constants.POS_TYPE.BAR].includes(parseInt(posDetails.pos_type)) &&
                         <th className={'data-table-cell-md'}/>
                        }
                        <th className={'data-table-cell-md'}>Price</th>
                        <th className={'data-table-cell-md'}>Quantity</th>
                        <th className={'data-table-cell-md'}>Category</th>
                        <th className={'data-table-cell-md'}/>
                      </tr>
                    </thead>
                    <tbody>
                      {_.isEmpty(items) ?
                        <React.Fragment>
                          <tr>
                            <td colSpan={6}>
                              <div className={'text-center table-list-empty'}>
                                <h4><FontAwesomeIcon icon={faIceCream} className={'mr-2'}/> Items </h4>
                                {Strings.POSOverViewEmptyItems.map((data, i) => <p key={i}>{data}</p>)}
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                        :
                        <React.Fragment>
                          {items.map((data, i) => (
                            <tr key={i}>
                              <td><strong>{data.name}</strong></td>
                              {[Constants.POS_TYPE.RESTRO_BAR, Constants.POS_TYPE.RESTAURANT, Constants.POS_TYPE.BAR].includes(parseInt(posDetails.pos_type)) &&
                               <td>
                                 <PosItemTypeDetailsView item={data}/>
                               </td>
                              }
                              <td>{data.price_effective} {data.currency}</td>
                              <td>
                                {parseInt(data.sub_quantity_type) !== Constants.QUANTITY_TYPE.NOS && ` ${data.sub_quantity_type_display} / `}
                                {data.quantity_type_display}
                              </td>
                              <td>
                                {(!_.isEmpty(data.attributes) && data.attributes.category_name) ? data.attributes.category_name : '...'}
                              </td>
                              <td>
                                <button
                                  className={'btn btn-link p-0 m-0'}
                                  onClick={() => this.setState({showPOSItemDetails: true, selectPOSItem: data})}
                                >
                                  <FontAwesomeIcon icon={faEye} className={'mr-2'}/> Details
                                </button>
                              </td>
                            </tr>
                          ))}

                        </React.Fragment>
                      }
                    </tbody>

                  </table>
                </div>
              </div>
            </div>


          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item  align-items-center">
                <small>Category</small>
              </li>

              <li
                className="list-group-item a-class"
                onClick={() => this.setState({selectCategory: null})}
              >
                <div className={`${selectCategory === null ? 'blue-cl' : 'grey-cl'}`}>
                  <FontAwesomeIcon
                    icon={selectCategory === null ? faCircle : faCircleNotch} size={'sm'}
                    className={'mr-2'}
                  /> All Category
                </div>
              </li>

              {!_.isEmpty(posDetails.category) &&
               posDetails.category.map((data, i) => (
                 <li
                   className="list-group-item a-class" key={i}
                   onClick={() => this.setState({selectCategory: data.id})}
                 >
                   <div className={`${selectCategory === data.id ? 'blue-cl' : 'grey-cl'}`}>
                     <FontAwesomeIcon
                       icon={selectCategory === data.id ? faCircle : faCircleNotch} size={'sm'}
                       className={'mr-2'}
                     /> {data.name}
                   </div>
                 </li>
               ))
              }
            </ul>
          </div>
        </div>
        <POSItemDetails
          show={this.state.showPOSItemDetails}
          item={this.state.selectPOSItem}
          posDetails={posDetails}
          onHide={() => this.setState({showPOSItemDetails: false})}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.onChangeDebounced = _.debounce(this.onChangeDebounced, 200, {});

    this.state = {
      error: false,
      loading: false,
      searching: false,
      searchText: null,
      selectCategory: null,
      showPOSItemDetails: false,
      selectPOSItem: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(POSItemsList);
