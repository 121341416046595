/**
 * Export Button
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleNotch, faFileCsv, faFileDownload, faFileExcel, faFilePdf} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */
class ExportButton extends Component {
  static componentName = 'ExportButton';

  static propTypes = {
    files: PropTypes.array,
    size: PropTypes.string,
    action: PropTypes.func,
    title: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    selectKey: PropTypes.string,
    className: PropTypes.string,
    classNameActive: PropTypes.string,
  };

  static defaultProps = {
    className: 'btn-outline-secondary',
    classNameActive: 'btn-outline-primary',
    size: 'md',
    selection: null,
    title: 'Export Data',
    loading: false,
    disabled: false,
  };

  action = (data) => {
    if (this.props.action && !this.props.disabled) {
      this.props.action(data);
    }
  };

  render = () => {
    const {title, size, files, className, classNameActive, loading, disabled} = this.props;
    const {selection} = this.state;
    return (
      <div className="btn-group">
        <button
          type="button"
          className={`btn btn-${size} ${selection ? classNameActive : className} dropdown-toggle dropdown-toggle-split`}
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <FontAwesomeIcon
            icon={loading ? faCircleNotch : faFileDownload}
            size={'sm'} className={'mr-2'}
            spin={loading}
          />
          {title && title} {selection && ` : ${selection}`}
        </button>
        <div className="dropdown-menu dropdown-menu-right">
          {files && files.includes('csv') &&
          <div className={`dropdown-item a-class ${disabled && 'disabled'}`} onClick={() => this.action('csv')}>
            <FontAwesomeIcon icon={faFileCsv} size={'sm'} className={'mr-2'}/> CSV
          </div>
          }
          {files && files.includes('xlsx') &&
          <div className={`dropdown-item a-class ${disabled && 'disabled'}`} onClick={() => this.action('csv')}>
            <FontAwesomeIcon icon={faFileExcel} size={'sm'} className={'mr-2'}/> Excel
          </div>
          }
          {files && files.includes('pdf') &&
          <div className={`dropdown-item a-class ${disabled && 'disabled'}`} onClick={() => this.action('pdf')}>
            <FontAwesomeIcon icon={faFilePdf} size={'sm'} className={'mr-2'}/> PDF
          </div>
          }
          {disabled &&
          <React.Fragment>
            <div className="dropdown-divider"/>
            <div className={'px-2 mb-0'}>
              <p className={'small text-muted break-word mb-0'}>Select a date range to export data.</p>
            </div>
          </React.Fragment>
          }
        </div>
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      selection: null,
    };
  }
}

/* Export Component ==================================================================== */
export default ExportButton;