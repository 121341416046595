/**
 * Manage Property update
 *  manages user updating property mid way
 */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Modal} from 'react-bootstrap';

// Consts and Libs
import {AppConfig, Strings} from '../../../constants';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLongArrowAltRight} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */

class PropertyUpdate extends React.Component {
  static componentName = 'PropertyUpdate';

  static propTypes = {
    match: PropTypes.object,
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        loading: false,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  render = () => {

    const {show, newProperty} = this.props;

    if (!show) return <React.Fragment/>;

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Property Changed
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
              <p>{Strings.propertyUpdated}</p>
              {!_.isEmpty(newProperty) &&
                <p className={'text-center'}>Property : <strong>{newProperty.name}</strong></p>
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          <div className={'row'}>
            <div className={'col'}>
              <a className={'btn btn-success btn-lg btn-block'} href={`${AppConfig.dashboardHostname}/dashboard`}>
                Proceed Dashboard <FontAwesomeIcon className={'white-cl ml-2'} icon={faLongArrowAltRight} size={'sm'}/>
              </a>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(PropertyUpdate);

