import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Route, Switch} from 'react-router-dom';
// Components
import BookingList from './BookingList';
import PageHeader from '../../components/dashboard/PageHeader';


class BookingListIndex extends Component {
  static propTypes = {
    match: PropTypes.object,
    hideHeader: PropTypes.bool,
    baseUrl: PropTypes.string,
    guestId: PropTypes.number,
    corporateId: PropTypes.number,
  };


  static defaultProps = {
    baseUrl: '',
  };

  render = () => {

    const {baseUrl, hideHeader, guestId, corporateId} = this.props;


    return (
      <div>
        {!hideHeader &&
         <PageHeader
           history={this.props.history} title={'Booking\'s'}
           description={'All bookings made in the property. Choose category to filter bookings.'}
         />
        }

        <div className={'row'}>
          <div className={'col-12'}>
            <Switch>
              <Route
                path={`${baseUrl}/booking/list/`} exact
                render={(props) => <BookingList guestId={guestId} corporateId={corporateId} {...props}/>}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default BookingListIndex;