/**
 * ExpenseExpensePaymentListView
 *  View for Payment Listing
 *
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Consts and Libs
import AppUtil from '../../lib/util';

// Actions
import * as ExpenseActions from '../../redux/expense/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setExpensePayment: ExpenseActions.setExpensePayment
};

/* Component ==================================================================== */
class ExpensePaymentListView extends Component {
  static componentName = 'ExpensePaymentListView';

  static propTypes = {
    data: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  action = (data) => {
    this.props.setExpensePayment(data);
    this.props.history.push('/expense/payment/' + data.hash);
  };

  render = () => {
    const data = this.props.data;
    return (
      <div
        role="presentation"
        onKeyPress={this.handleKeyPress}
        onClick={() => this.action(data)}
        className="list-group-item list-group-item-action flex-column align-items-start a-class">
        <div className="d-flex w-100 justify-content-between">
          <h5 className="mb-1">{data.ref_no}</h5>
          <span className={'badge ' + (!data.void ? AppUtil.paymentStatusColor(data.payment_status) : 'badge-dark')}>
            {data.currency + ' ' + data.total}
          </span>
        </div>
        <p className="mb-1">
          Payment Status :
          <span
            className={'badge ' + (AppUtil.paymentStatusColor(data.payment_status))}>
            {data.get_payment_status_display}
          </span>
        </p>
        <p className="mb-1">{'Method: ' + data.get_payment_method_display}</p>
        {(data.corporate_name) &&
         <p className="mb-1"><strong>Billed to</strong> : {data.corporate_name} </p>}
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(ExpensePaymentListView);

