/**
 * PropertyTaxClassManage
 *  manages property tax class
 */
import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';

// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants, ErrorMessages, Strings} from '../../../constants';

// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PageHeader from '../../../components/dashboard/PageHeader';
import {Alerts} from '../../../components/ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
// Forms
import {Form, Input, Select, SubmitBtn} from 'react-formik-ui';
import {FieldArray, Formik} from 'formik';
import * as Yup from 'yup';

// Actions
import * as PropertyActions from '../../../redux/property/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetProperty: PropertyActions.isSetProperty,
};

/* Component ==================================================================== */

class PropertyTaxClassManage extends Component {
  static componentName = 'PropertyTaxClassManage';

  static propTypes = {
    match: PropTypes.object,
  };
  componentDidMount = () => {
    this.fetchInitData();
  };

  addTaxClass = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        let payload = {
          'name': credentials.tax_name,
          'tax_type': credentials.tax_type,
          'tax_slab': credentials.tax_slab,
        };

        if (credentials.tax_slab === String(Constants.TAX_SLAB.FLAT)) {
          payload.tax = credentials.tax;
        } else {
          payload.tax_slabs = credentials.tax_slabs;
        }

        AppAPI.propertytaxclass.post('', payload)
          .then((res) => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.history.replace(`/settings/property/tax/${res.id}/`);
                }, 500);
              });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };


  fetchInitData = () => {
    this.setState({
      loading: false,
    });
  };
  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {property} = this.props;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    const contentValidation = Yup.object().shape({
      start_range: Yup.number().required(),
      end_range: Yup.number().moreThan(Yup.ref('start_range'), 'End Value should be greater than start value').nullable(),
      tax: Yup.number().required('Tax value is required'),
    });

    const defaultFormValidation = Yup.object().shape({
      tax_name: Yup.string().min(2, 'Too Short!').required('Required'),
      tax_type: Yup.string().required('Required'),
      tax_slab: Yup.string().required('Required'),
      tax_slabs: Yup.array().of(contentValidation).nullable(),
    });


    const initialValuesRange = {
      'start_range': '1',
      'end_range': '',
      'tax': ''
    };

    const initialValues = {
      'tax_name': '',
      'tax_type': '1',
      'tax_slab': '1',
    };

    return (
      <div className="tax-manage screen-container ">
        <Helmet>
          <title>Property Class Manage</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'Tax Class '}
          description={'Manage Tax Classes'}
        />

        <Formik
          onSubmit={(values) => this.addTaxClass(values)}
          validationSchema={defaultFormValidation}
          initialValues={initialValues}>
          {({values, setFieldValue, handleChange}) => (
            <Form>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div
                    className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                    role="button" aria-expanded="false" aria-controls="orderActions">
                    <h6 className={'mb-0'}>
                      Tax Information
                    </h6>
                  </div>
                  <div className={'border p-2'} id={'primaryAction'}>
                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <Input
                          required
                          name='tax_name'
                          label={'Tax Name'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <Select
                          className={'form-control rounded-right-0'}
                          name='tax_type'
                          label={'Tax Type'}
                          placeholder='Select an Option'
                          options={Constants.TAX_TYPE_INPUT}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-lg-12 col-sm-12 form-group">
                        <Select
                          className={'form-control rounded-right-0'}
                          name='tax_slab'
                          label={'Tax Slab Type'}
                          placeholder='Select an Option'
                          options={Constants.TAX_SLAB_INPUT}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue('tax_slabs', [initialValuesRange]);

                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  {values.tax_slab === String(Constants.TAX_SLAB.FLAT) ?
                    <React.Fragment>
                      <div
                        className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                        role="button" aria-expanded="false" aria-controls="orderActions">
                        <h6 className={'mb-0'}>
                          Tax Rate
                        </h6>
                      </div>
                      <div className={'border p-2'} id={'primaryAction'}>
                        <div className="form-row">
                          <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <Input
                              required
                              name='tax'
                              label={`Tax Value (${values.tax_type === String(Constants.TAX_TYPE.PERCENTAGE) ? '%' : property.currency})`}
                              className={'form-control'}
                            />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <div
                        className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                        role="button" aria-expanded="false" aria-controls="orderActions">
                        <h6 className={'mb-0'}>
                          Slab Information
                        </h6>
                      </div>
                      <div className={'border p-2'} id={'primaryAction'}>
                        <div className="form-row">
                          <div className="col-lg-12 col-md-12 form-group">
                            <FieldArray
                              name="tax_slabs"
                              render={arrayHelpers => (
                                <div>
                                  {!_.isEmpty(values.tax_slabs) ? (
                                    <div>{values.tax_slabs.map((public_link, index) => (
                                      <div key={index} className={`form-row ${index > 0 && 'border-top pt-3'}`}>
                                        <div className="col-10">
                                          <div className="form-row">
                                            <div className="col-4 form-group">
                                              <Input
                                                required
                                                disabled={index > 0}
                                                type={'number'}
                                                name={`tax_slabs.${index}.start_range`}
                                                label={'Start Value'}
                                                className={'form-control'}
                                              />
                                            </div>
                                            <div className="col-4 form-group">
                                              <Input
                                                name={`tax_slabs.${index}.end_range`}
                                                label={'End Value'}
                                                type={'number'}
                                                className={'form-control'}
                                                onChange={(e) => {
                                                  arrayHelpers.form.handleChange(e);
                                                  arrayHelpers.form.setFieldValue(`tax_slabs.${index+1}.start_range`, parseFloat(e.target.value) + 1);
                                                  arrayHelpers.form.setFieldValue(`tax_slabs.${index+1}.tax`, '');
                                                }}
                                                hint={'Leave blank for no upper limit.'}
                                              />
                                            </div>
                                            <div className="col-4 form-group">
                                              <Input
                                                required
                                                type={'number'}
                                                name={`tax_slabs.${index}.tax`}
                                                label={`Tax Value (${values.tax_type === String(Constants.TAX_TYPE.PERCENTAGE) ? '%' : property.currency})`}
                                                className={'form-control'}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className={'col-2 text-center'}>
                                          {index > 0 &&
                                           <button
                                             className={'btn btn-outline-danger mt-4 btn-sm mb-0'} type="button"
                                             onClick={() => arrayHelpers.pop()}>
                                             <FontAwesomeIcon className={'mx-1'} icon={faMinus} size={'sm'}/>
                                           </button>
                                          }
                                        </div>
                                      </div>
                                    ))}
                                    </div>
                                  ) : (
                                    <div className={'text-center'}>
                                      <p>No Range.</p>
                                      <button
                                        className={'btn btn-success'} type="button"
                                        onClick={() => arrayHelpers.push('')}>
                                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add a Range
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  }
                </div>
              </div>

              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 mt-2">
                  <div className={'alert alert-info'}>
                    <p className={'mb-0'}>{Strings.propertyTaxClassAddMessage}</p>
                  </div>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn className={'btn btn-success btn-block btn-lg mt-2'}>
                    <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add Tax Class
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>

    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      form_values: {},
      options: {},
      settings: false,
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(PropertyTaxClassManage);
