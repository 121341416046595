/**
 *
 * Booking Room Actions Room update
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
// Consts and Libs
import AppAPI from '../../lib/api';
// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import PageHeader from '../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faList} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';
import {Alerts} from '../../components/ui';
import HousekeepingRoomListView from '../room/HousekeepingRoomListView';
// Actions
import * as BookingRoomAction from '../../redux/bookingroom/actions';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  bookingroom: state.bookingroom.bookingroom,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setBookingRoom: BookingRoomAction.setBookingRoom,
};

/* Component ==================================================================== */
class BookingRoomManage extends Component {
  static componentName = 'BookingRoomUpdate';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };


  componentDidMount = () => {
    if (this.props.match.params.bookingId && this.props.match.params.bookingRoomId && this.props.bookingroom) {
      this.fetchInitData();
    } else {
      this.props.history.goBack();
    }
  };

  fetchInitData = () => {
    const checkbox = [];
    let i;
    AppAPI.bookingapi
      .get(
        `booking-room/${this.props.match.params.bookingRoomId}/update-room/`,
      )
      .then(res => {
        if (res.actions !== '') {
          for (i in res.messages) {
            checkbox[i] = false;
          }

          this.setState({
            loading: false,
            rooms: res.rooms,
            messages: res.messages,
            checkbox,
          });
        } else {
          this.setState({
            loading: false,
            error: true,
          });
        }
      })
      .catch(err => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: true,
          resultMsg: {error},
        });
      });
  };

  changeRoom = () => {
    if(this.state.selectedRoom){
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.bookingapi
          .post(`booking-room/${this.props.match.params.bookingRoomId}/update-room/`, {
            room: this.state.selectedRoom.id,
          })
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                this.props.setBookingRoom(res);
                this.props.history.goBack();
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  checboxAction = (i) => {
    const {checkbox} = this.state;
    checkbox[i] = !checkbox[i];
    this.setState({checkbox});
  };

  selectRoom = (data) => {
    this.setState({selectedRoom: data});
  };

  renderRow = (data, key) => {
    const {selectedRoom} = this.state;
    return (
      <div key={key} className={'col-sm-4 col-md-4 col-6 p-3 ' + ((selectedRoom && selectedRoom.id === data.id) ? 'green-bg' : '')}>
        <div
          className={'border p-2 position-relative anchor ' + ((selectedRoom && selectedRoom.id === data.id) ? 'lime-bg' : 'grey-light-bg')}
          role="presentation"
          onKeyPress={this.handleKeyPress} onClick={() => this.selectRoom(data)}>
          <HousekeepingRoomListView data={data} disabled={true} />
        </div>
      </div>
    );
  };

  render = () => {

    const {loading, error, checkbox, rooms, messages, selectedRoom, resultMsg} = this.state;
    const {bookingroom} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Booking Details</title>
        </Helmet>

        <PageHeader
          history={this.props.history}
          title={'Room Change'}
          description={'Reference number : ' + bookingroom.ref_no}
        />


        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className={'row'}>
              <div className={'col-md-8 col-sm-12 mb-3'}>
                <h6>Rooms</h6>
                {
                  (rooms && rooms.length > 0) ? (
                    <div>
                      <p>Select a room.</p>
                      <div className={'row text-center'}>
                        {rooms.map((data, i) => this.renderRow(data, i, 2))}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="mx-auto text-center border p-3">
                        <span className="fa fa-times-circle fa-3x red-cl"/>
                        <p>No Rooms available. </p>

                        <Link className="btn btn-warning btn-lg btn-block" to="/house-keeping">
                          <FontAwesomeIcon icon={faList} size={'sm'} className={'mr-2'}/> Room List
                        </Link>

                      </div>
                    </div>)
                }
              </div>
              <div className={'col-lg-4 col-sm-12 mb-3'}>
                <div className={'sticky-top pt-5'}>
                  <div className={'p-3 rounded border white-bg mt-5'}>
                    {(messages && messages.length > 0) &&
                     <div>
                       <h6>Attention</h6>
                       <ul className="list-group my-3">
                         {
                           messages.map((data, i) => (
                             <li className="list-group-item a-class" key={i}>
                               <div className={'custom-control custom-switch'}>
                                 <input
                                   id={`e${i}`}
                                   readOnly={true}
                                   type="checkbox"
                                   className={'custom-control-input mr-3'}
                                   checked={checkbox[i]}
                                   onClick={() => {
                                     this.checboxAction(i);
                                   }}
                                 />
                                 <label className="custom-control-label" htmlFor={`e${i}`}>
                                   {data}
                                 </label>
                               </div>
                             </li>
                           ))
                         }
                       </ul>
                     </div>
                    }
                    <Alerts
                      status={resultMsg.status}
                      success={resultMsg.success}
                      error={resultMsg.error}
                    />

                    <p className={'small'}>Select a room and check the actions.</p>
                    <button
                      className={'btn btn-block btn-success btn-lg'}
                      disabled={!selectedRoom || String(checkbox).includes('false')}
                      onClick={() => this.changeRoom()}
                    >
                      Change Room
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      selectedRoom: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingRoomManage);