/**
 * Form
 *  manages form render
 *
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormValidation from 'tcomb-form';

//Components
import { Alerts } from '../ui/index';


/* Component ==================================================================== */
class Forms extends Component {

  static componentName = 'Forms';

  static propTypes = {
    formFields: PropTypes.func.isRequired,
    formValues: PropTypes.object,
    formOptions: PropTypes.object,
    formAction: PropTypes.func,
    formActionOnChange: PropTypes.func,
    formActionName: PropTypes.string.isRequired,
    statusMsg: PropTypes.object.isRequired,
    formButton: PropTypes.string,
    colType: PropTypes.number,
  };

  formAction = () => {
    // Get new credentials and update
    const credentials = this.form.getValue();
    if (this.props.formAction) {
      this.props.formAction(credentials);
    }
  };

  render = () => {
    const {
      statusMsg, formActionName, formOptions, formValues, formFields, formActionOnChange,
      formButton, colType
    } = this.props;

    const Form = FormValidation.form.Form;
    return (
      <div>
        <Alerts
          status={statusMsg.status}
          success={statusMsg.success}
          error={statusMsg.error}
        />
        <div className={colType === 2 ? 'two-col' : 'one-col'}>
          <Form
            ref={(b) => {
              this.form = b;
            }}
            type={formFields}
            value={formValues}
            options={formOptions}
            onChange={formActionOnChange || null}
          />
        </div>
        <button
          onClick={this.formAction}
          className={'btn btn-md mb-3 mt-3 ' + (formButton ? formButton : 'btn-success')}
          disabled={this.props.formAction === null}
        >
          {formActionName === 'Save' && <span className="fa fa-floppy-o"/>}
          {formActionName.includes('Add') && <span className="fa fa-plus"/>}
          {formActionName}
        </button>
      </div>
    );
  };
}


/* Export Component ==================================================================== */
export default Forms;
