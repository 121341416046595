/**
 * PageSubHeader
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

/* Component ==================================================================== */
class PageSubHeader extends Component {
  static componentName = 'PageHeader';

  static propTypes = {
    border: PropTypes.bool,
    icon: PropTypes.object,
    title: PropTypes.string,
    history: PropTypes.object,
    description: PropTypes.string,
  };

  static defaultProps = {
    border: false
  };

  render = () => {
    const {title, description, icon, border} = this.props;
    return (
      <div className={'page-sub-header-wrapper'}>
        <div className={`page-sub-header ${!border && 'border-0 mb-1'}`}>
          <h5 className={'mb-1'}>
            {icon && <FontAwesomeIcon icon={icon} className={'mr-2'} size={'sm'}/>} {title}
          </h5>
          {description && <p className={'small text-muted'}>{description}</p>}
        </div>
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default PageSubHeader;
