/**
 *
 * BookingList
 * List Bookings
 */
import queryString from 'query-string';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Moment from 'moment';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppWebUtil from '../../lib/webUtils';
import AppUtil from '../../lib/util';
import AppAPI from '../../lib/api';
import {Constants, Filters, Strings} from '../../constants';
// Components
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import SearchBar from '../../components/forms/SearchBar';
import FilterDate from '../../components/dashboard/FilterDate';
import FilterButton from '../../components/dashboard/FilterButton';
import ExportButton from '../../components/dashboard/ExportButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLongArrowAltRight, faSync, faTicketAlt} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  event: state.event.event,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class BookingList extends Component {
  static componentName = 'BookingList';

  static propTypes = {
    eventId: PropTypes.number,
    guestId: PropTypes.number,
    corporateId: PropTypes.number,
  };

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };

  componentDidMount = () => {
    let urlParams = {}, startDate = null, endDate = null, eventStartDate = null, eventEndDate = null;

    switch (this.getQueryParams('booking_status')) {
    case 'provisional':
      urlParams['ticket_booking_status'] = Constants.TICKET_BOOKING_STATUS.PROVISIONAL;
      break;
    case 'booked':
      urlParams['ticket_booking_status'] = Constants.TICKET_BOOKING_STATUS.BOOKED;
      break;
    case 'cancelled':
      urlParams['ticket_booking_status'] = Constants.TICKET_BOOKING_STATUS.CANCELLED;
      break;
    case 'claimed':
      urlParams['ticket_claim_status'] = Constants.TICKET_BOOKING_CLAIM_STATUS.CLAIMED;
      break;
    case 'not-claimed':
      urlParams['ticket_claim_status'] = Constants.TICKET_BOOKING_CLAIM_STATUS.NOT_CLAIMED;
      break;
    default:
      break;
    }

    switch (this.getQueryParams('booking_source')) {
    case 'agent':
      urlParams['ticket_booking_source'] = Constants.BOOKING_SOURCE.AGENT;
      break;
    case 'email':
      urlParams['ticket_booking_source'] = Constants.BOOKING_SOURCE.EMAIL;
      break;
    case 'walkin':
      urlParams['ticket_booking_source'] = Constants.BOOKING_SOURCE.WALKIN;
      break;
    case 'phone':
      urlParams['ticket_booking_source'] = Constants.BOOKING_SOURCE.PHONE;
      break;
    case 'booking_engine':
      urlParams['ticket_booking_source'] = Constants.BOOKING_SOURCE.BOOKING_ENGINE;
      break;
    case 'corporate':
      urlParams['ticket_booking_source'] = Constants.BOOKING_SOURCE.CORPORATE;
      break;
    default:
      break;
    }

    if (this.props.guestId) {
      urlParams['guest_data'] = this.props.guestId;
    }

    if (this.props.corporateId) {
      urlParams['corporate_data'] = this.props.corporateId;
    }

    if (this.props.eventId) {
      urlParams['event'] = this.props.eventId;
    }

    if (this.getQueryParams('start_date')) {
      startDate = Moment(String(this.getQueryParams('start_date')));
    }
    if (this.getQueryParams('end_date')) {
      endDate = Moment(String(this.getQueryParams('end_date')));
    }

    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('start_date'), this.getQueryParams('end_date'), 'created_date');

    if (this.getQueryParams('event_start_date')) {
      eventStartDate = Moment(String(this.getQueryParams('event_start_date')));
    }
    if (this.getQueryParams('event_end_date')) {
      eventEndDate = Moment(String(this.getQueryParams('event_end_date')));
    }

    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('event_start_date'), this.getQueryParams('event_end_date'), 'ticket_date');


    this.setState({loading: false, urlParams, startDate, endDate, eventStartDate, eventEndDate});
  };

  fetchData = (page = 1, callback, searchText, download) => {
    const urlParams = {...this.state.urlParams};
    let baseUrl = 'ticket-booking';
    urlParams['page'] = page;

    if (searchText) {
      this.setState({searching: true, clear: true});
      baseUrl = 'ticket-booking-search';
      urlParams['search'] = searchText;
    }

    if (download) {
      urlParams['all_data'] = true;
      urlParams['download'] = download.file;
      this.setState({fileDownload: true});
    }

    AppAPI.eventapi.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (download) {
          let fileName = `Ticket-Booking-Report-${AppUtil.formatDateTime(Moment(), 'short')}.${download.file}`;
          AppWebUtil.downloadFile(res, fileName);
        } else {
          if (res.results.length > 0) {
            callback(res.results, {allLoaded: !res.next});
          } else {
            callback();
          }
        }
        this.setState({
          searching: false,
          clear: false,
          refresh: false,
          loading: false,
          fileDownload: false,
          page: page,
        });
      })
      .catch((err) => {
        if (callback) {
          callback([], {allLoaded: true});
        }
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, refresh: false, loading: false, fileDownload: false, error});
      });
  };

  renderRow = (data, key) => {
    const {event} = this.props;
    return (
      <tr key={key}>
        <td>
          <strong>{(data.guest && data.guest.name) ? data.guest.name : '...'}</strong>
        </td>
        <td><small>{data.ref_no}</small></td>
        <td>{AppUtil.formatDateTime(data.ticket_date, 'date')}</td>
        <td>
          <span className={'badge ' + (AppUtil.ticketBookingStatusColor(data.ticket_booking_status))}>
            {data.ticket_booking_status_display}
          </span>
        </td>
        <td>{data.attributes && data.attributes.ticket ? data.attributes.ticket : '...'}</td>
        <td>
          <Link to={`/event/${event.hash}/ticket-booking/booking/${data.hash}`}>
            Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
          </Link>
        </td>
      </tr>
    );
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={8}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faTicketAlt}/> No Booking </h4>
            {Strings.ticketBookingListEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };


  tableHeader = () => {
    return (
      <tr>
        <th className={'data-table-cell-md'}>Name</th>
        <th className={'data-table-cell-sm'}>Reference</th>
        <th className={'data-table-cell-md'}>Booking Date</th>
        <th className={'data-table-cell-xm'}/>
        <th className={'data-table-cell-sm'}>Ticket</th>
        <th/>
      </tr>
    );
  };


  render = () => {
    const {loading, error, clear, searching, results, fileDownload, refresh} = this.state;
    const {guestId, corporateId, event} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    let minDate = null, maxDate = null;
    if (event) {
      minDate = Moment(event.event_start_date);
      maxDate = Moment(event.event_end_date);
    }

    return (
      <div className="booking-list screen-container">
        <Helmet>
          <title>Booking List</title>
        </Helmet>

        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-9'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.setState({refresh: true})}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                </button>
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Booking Status'}
                  data={Filters.ticketBookingStatus}
                  selectKey={this.getQueryParams('booking_status')}
                  action={(data) => this.setQueryParams([{key: 'booking_status', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Source'}
                  data={Filters.ticketBookingSource}
                  selectKey={this.getQueryParams('booking_source')}
                  action={(data) => this.setQueryParams([{key: 'booking_source', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.eventStartDate}
                  endDate={this.state.eventEndDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data, 'event_'))}
                  size={'sm'}
                  selectionType={'single'}
                  title={'Event Date'}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data))}
                  size={'sm'}
                  title={'Created'}
                  maxDate={Moment().add(1, 'days')}
                />
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-3 text-center text-sm-right'}>
            <ExportButton
              size={'sm'}
              files={['csv']}
              action={(key) => this.fetchData(this.state.page, null, null, {file: key})}
              loading={fileDownload}
              disabled={(guestId || corporateId) ? false : !(this.state.startDate || this.state.eventStartDate)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <SearchBar
              clear={clear}
              refresh={refresh}
              results={results}
              searching={searching}
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
              searchFunction={(guestId || corporateId) ? null : this.fetchData}
              placeHolder="Booking ID or Guest Name"
              colSpan={7}
            />
          </div>
        </div>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      searching: false,
      clear: false,
      results: [],
      urlParams: {},
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingList);
