/**
 *
 * BookingList
 * List Bookings
 */
import React, {Component} from 'react';
import queryString from 'query-string';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Moment from 'moment';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import AppWebUtil from '../../lib/webUtils';
import {Constants, Filters, Strings} from '../../constants';
// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import SearchBar from '../../components/forms/SearchBar';
import FilterButton from '../../components/dashboard/FilterButton';
import FilterDate from '../../components/dashboard/FilterDate';
import ExportButton from '../../components/dashboard/ExportButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBed, faLongArrowAltRight, faSync} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class BookingList extends Component {
  static componentName = 'BookingList';

  static propTypes = {
    guestId: PropTypes.number,
    corporateId: PropTypes.number,
  };

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };

  componentDidMount = () => {
    let urlParams = {}, startDate = null, endDate = null, bookingCheckinStartDate = null, bookingCheckinEndDate = null,
      bookingCheckoutStartDate = null, bookingCheckoutEndDate = null, bookingDate = null;


    switch (this.getQueryParams('booking_status')) {
    case 'upcoming':
      urlParams['booking_status'] = Constants.BOOKING.UPCOMING;
      break;
    case 'active':
      urlParams['booking_status'] = Constants.BOOKING.ACTIVE;
      break;
    case 'completed':
      urlParams['booking_status'] = Constants.BOOKING.COMPLETED;
      break;
    case 'no_show':
      urlParams['booking_status'] = Constants.BOOKING.NOSHOW;
      break;
    case 'cancelled':
      urlParams['booking_status'] = Constants.BOOKING.CANCELED;
      break;
    default:
      break;
    }

    switch (this.getQueryParams('booking_source')) {
    case 'agent':
      urlParams['booking_source'] = Constants.BOOKING_SOURCE.AGENT;
      break;
    case 'email':
      urlParams['booking_source'] = Constants.BOOKING_SOURCE.EMAIL;
      break;
    case 'walkin':
      urlParams['booking_source'] = Constants.BOOKING_SOURCE.WALKIN;
      break;
    case 'phone':
      urlParams['booking_source'] = Constants.BOOKING_SOURCE.PHONE;
      break;
    case 'channel_manager':
      urlParams['booking_source'] = Constants.BOOKING_SOURCE.CHANNEL_MANAGER;
      break;
    case 'booking_engine':
      urlParams['booking_source'] = Constants.BOOKING_SOURCE.BOOKING_ENGINE;
      break;
    case 'corporate':
      urlParams['booking_source'] = Constants.BOOKING_SOURCE.CORPORATE;
      break;
    case 'ota':
      urlParams['booking_source'] = Constants.BOOKING_SOURCE.OTA;
      break;
    default:
      break;
    }

    switch (this.getQueryParams('booking_type')) {
    case 'confirmed':
      urlParams['confirm_status'] = 1;
      break;
    case 'provisional':
      urlParams['confirm_status'] = 0;
      break;
    default:
      break;
    }

    if (this.getQueryParams('start_date')) {
      startDate = Moment(String(this.getQueryParams('start_date')));
    }
    if (this.getQueryParams('end_date')) {
      endDate = Moment(String(this.getQueryParams('end_date')));
    }
    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('start_date'), this.getQueryParams('end_date'), 'created_date');

    if (this.getQueryParams('booking_checkin_start_date')) {
      bookingCheckinStartDate = Moment(String(this.getQueryParams('booking_checkin_start_date')));
    }
    if (this.getQueryParams('booking_checkin_end_date')) {
      bookingCheckinEndDate = Moment(String(this.getQueryParams('booking_checkin_end_date')));
    }
    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('booking_checkin_start_date'), this.getQueryParams('booking_checkin_end_date'), 'booking_checkin');

    if (this.getQueryParams('booking_checkout_start_date')) {
      bookingCheckoutStartDate = Moment(String(this.getQueryParams('booking_checkout_start_date')));
    }
    if (this.getQueryParams('booking_checkout_end_date')) {
      bookingCheckoutEndDate = Moment(String(this.getQueryParams('booking_checkout_end_date')));
    }
    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('booking_checkout_start_date'), this.getQueryParams('booking_checkout_end_date'), 'booking_checkout');

    if (this.getQueryParams('booking_date_start_date')) {
      bookingDate = Moment(String(this.getQueryParams('booking_date_start_date')));
      urlParams['booking_date'] = bookingDate.format('YYYY-MM-DD');
    }

    if (this.props.guestId) {
      urlParams['guest_data'] = this.props.guestId;
    }

    if (this.props.corporateId) {
      urlParams['corporate_data'] = this.props.corporateId;
    }

    this.setState({
      loading: false,
      urlParams,
      startDate,
      endDate,
      bookingDate,
      bookingCheckinStartDate,
      bookingCheckinEndDate,
      bookingCheckoutStartDate,
      bookingCheckoutEndDate,
    });

  };

  fetchData = (page = null, callback, searchText, download) => {
    const urlParams = {...this.state.urlParams};
    let baseUrl = 'booking-v2';
    if (page) {
      urlParams['cursor'] = page;
    }


    if (searchText) {
      this.setState({searching: true, clear: true});
      baseUrl = 'booking-search';
      urlParams['search'] = searchText;
      if (page){
        urlParams['page'] = page;
      }
    }

    if (download) {
      urlParams['download'] = download.file;
      urlParams['all_data'] = true;
      this.setState({fileDownload: true});
    }

    AppAPI.bookingapi.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (download) {
          let fileName = `Booking-Report-${AppUtil.formatDateTime(Moment(), 'short')}.${download.file}`;
          AppWebUtil.downloadFile(res, fileName);
        } else {
          if (res.results.length > 0) {
            callback(
              res.results, {
                allLoaded: !res.next,
                nextParam: res.count ? AppUtil.getURLParam(res.next, 'page') : AppUtil.getURLParam(res.next, 'cursor')
              }
            );
          } else {
            callback();
          }
        }
        this.setState({
          searching: false,
          clear: false,
          refresh: false,
          loading: false,
          fileDownload: false,
          page: page,
        });
      })
      .catch((err) => {
        if (callback) {
          callback([], {allLoaded: true});
        }
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, refresh: false, loading: false, fileDownload: false, error});
      });
  };

  renderRow = (data, key) => {
    return (
      <tr className={'tr-align-middle'} key={key}>
        <td className={'data-table-cell-lg '}>
          <strong>{(data.guest && data.guest.name) ? data.guest.name : '...'}</strong>
        </td>
        <td className={'data-table-cell-md'}><small>{data.ref_no}</small></td>
        <td className={'data-table-cell-lg text-left'}>
          {`${AppUtil.formatDateTime(data.checkin, 'date')} - ${AppUtil.formatDateTime(data.checkout, 'date')}`}
        </td>
        <td className={' data-table-cell-lg text-left'}>
          <span className={'badge ' + (AppUtil.bookingStatusColor(data.booking_status))}>
            {data.booking_status_display}
          </span>
          <span className={`badge ${data.confirm_status ? 'badge-success' : 'badge-warning'} small ml-2`}>
            {data.confirm_status ? 'Confirmed' : 'Provisional'}
          </span>
        </td>
        <td className={'data-table-cell-lg'}>
          {data.booking_source_display}
          {(data.attributes && data.attributes.booking_ota_name) && <div className={'ml-2 small'}>({data.attributes.booking_ota_name})</div>}
          {(data.booking_ota && data.booking_ota.name) && <div className={'ml-2 small'}>({data.booking_ota.name})</div>}
        </td>
        <td className={'data-table-cell-lg'}><small>{AppUtil.formatDateTime(data.created)}</small></td>
        <td className={'data-table-cell-md'}>
          <Link to={`/booking/${data.hash}`}>
            Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
          </Link>
        </td>
      </tr>
    );
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={8}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faBed}/> No Booking </h4>
            {Strings.bookingListEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };


  tableHeader = () => {
    return (
      <tr>
        <th className={'data-table-cell-md'}>Name</th>
        <th className={'data-table-cell-sm'}>Reference</th>
        <th className={'data-table-cell-md'}>Booking Date</th>
        <th className={'data-table-cell-xm'}/>
        <th className={'data-table-cell-sm'}>Source</th>
        <th className={'data-table-cell-xm'}>Date</th>
        <th className={'data-table-cell-xm'}/>
      </tr>
    );
  };


  render = () => {
    const {loading, error, clear, searching, results, fileDownload, refresh} = this.state;
    const {guestId, corporateId} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="booking-list screen-container">
        <Helmet>
          <title>Booking List</title>
        </Helmet>

        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-9'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.setState({refresh: true})}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                </button>
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Booking Status'}
                  data={Filters.bookingFilter}
                  selectKey={this.getQueryParams('booking_status')}
                  action={(data) => this.setQueryParams([{key: 'booking_status', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Booking Source'}
                  data={Filters.bookingSource}
                  selectKey={this.getQueryParams('booking_source')}
                  action={(data) => this.setQueryParams([{key: 'booking_source', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Booking Type'}
                  data={Filters.bookingType}
                  selectKey={this.getQueryParams('booking_type')}
                  action={(data) => this.setQueryParams([{key: 'booking_type', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.bookingDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data, 'booking_date_'))}
                  size={'sm'}
                  title={'Booking Date'}
                  disableSelection={true}
                  selectionType={'single'}
                />
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data))}
                  size={'sm'}
                  maxDate={Moment().add(1, 'days')}
                  title={'Created'}
                />
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.bookingCheckinStartDate}
                  endDate={this.state.bookingCheckinEndDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data, 'booking_checkin_'))}
                  size={'sm'}
                  title={'Checkin'}
                />
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.bookingCheckoutStartDate}
                  endDate={this.state.bookingCheckoutEndDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data, 'booking_checkout_'))}
                  size={'sm'}
                  title={'Checkout'}
                />
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-3 text-center text-sm-right'}>
            <ExportButton
              size={'sm'}
              files={['csv']}
              action={(key) => this.fetchData(this.state.page, null, null, {file: key})}
              loading={fileDownload}
              disabled={!(this.state.startDate || this.state.bookingCheckoutStartDate || this.state.bookingCheckinStartDate || this.state.bookingDate)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <SearchBar
              clear={clear}
              refresh={refresh}
              results={results}
              searching={searching}
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
              searchFunction={(guestId || corporateId) ? null : this.fetchData}
              placeHolder="Booking ID or Guest Name"
              listVersion={'V2'}
              colSpan={7}
            />
          </div>
        </div>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      searching: false,
      clear: false,
      results: [],
      urlParams: {},
      page: 1,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingList);
