import React from 'react';
import {Route, Switch} from 'react-router-dom';
import { Constants } from '../../constants';
import BookingReports from './otherReport/BookingReports';
import BookingRoomReports from './otherReport/BookingRoomReports';
import GuestReports from './otherReport/GuestReports';
import Reports from './otherReport/Reports';
import NotFound from '../NotFound';

import ReportsListIndex from './ReportsListIndex';

const DashboardRoute = () => (
  <div>

    <div className="container">
      <Switch>
        <Route path="/reports" exact component={Reports}/>
        <Route
          path={'/reports/list'} exact
          render={(props) => <ReportsListIndex{...props}/>}
        />

        <Route
          path={'/reports/e/reports/list'} exact
          render={(props) =>
            <ReportsListIndex
              escalated={true}
              baseUrl={'/reports/e'}
              source={Constants.REPORT_SOURCE.BOOKING}
              reportOptions={Constants.REPORTS_ESCALATED}
              {...props}
            />
          }
        />

        <Route path="/reports/booking-report" exact component={BookingReports}/>
        <Route path="/reports/booking-report/:date/" exact component={BookingReports}/>

        <Route path="/reports/booking-room-report" exact component={BookingRoomReports}/>
        <Route path="/reports/booking-room-report/:date/" exact component={BookingRoomReports}/>

        <Route path="/reports/guest-report" exact component={GuestReports}/>
        <Route path="/reports/guest-report/:date/" exact component={GuestReports}/>

        <Route exact component={NotFound}/>
      </Switch>
    </div>

  </div>
);

export default DashboardRoute;
