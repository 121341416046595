/**
 * Error
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Components
import {ErrorMessages} from '../../constants';

/* Component ==================================================================== */
class Error extends Component {
  static componentName = 'Error';

  static propTypes = {
    text: PropTypes.string,
    full: PropTypes.bool,
    history: PropTypes.object,
    heightMatch: PropTypes.bool,
  };

  static defaultProps = {
    heightMatch: true,
  };


  componentDidMount() {
    this.setState({height: document.body.scrollHeight * 0.7});
    this.handleConnectionChange();
  }


  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const webPing = setInterval(
        () => {
          fetch('https://google.com', {
            mode: 'no-cors',
          })
            .then(() => {
              this.setState({isDisconnected: false}, () => {
                return clearInterval(webPing);
              });
            }).catch(() => this.setState({isDisconnected: true}));
        }, 2000);
    } else {
      this.setState({isDisconnected: true});
    }
  };


  render = () => {
    const {text, history, full, heightMatch} = this.props;
    const {isDisconnected} = this.state;
    return (
      <div
        className="d-flex justify-content-center"
        style={{height: (this.state.height && heightMatch ? this.state.height + 'px' : 'auto')}}>
        <div className="align-self-center text-center">
          <h1><i className="fa fa-exclamation-triangle red-cl fa-1x mr-2"/> Error</h1>
          <p>{text ? text : ErrorMessages.default}</p>
          {full && <p>{full}</p>}
          {isDisconnected &&
           <p className={'text-muted'}>It seems your device isn't connected to internet.</p>
          }
          {history &&
           <div
             role="presentation" onKeyPress={this.handleKeyPress}
             onClick={() => this.props.history.goBack()} className="btn btn-outline-info">
             <span className="fa fa-chevron-left"/> Back
           </div>
          }
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      height: null,
      isDisconnected: false,
    };
  }
}

/* Export Component ==================================================================== */
export default Error;
