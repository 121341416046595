/**
 *
 * Payment Refund Details
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
// Consts and Libs
import AppUtil from '../../../lib/util';
import AppAPI from '../../../lib/api';
import {Constants, Filters, Strings} from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import RefundAdd from '../../refund/models/RefundAdd';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FilterButton from '../../../components/dashboard/FilterButton';
import PageSubHeader from '../../../components/dashboard/PageSubHeader';
import {faCashRegister, faPlusCircle} from '@fortawesome/free-solid-svg-icons';
// Actions

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  payment: state.payment.payment,
});

// Any actions to map to the component?
const mapDispatchToProps = {};


/* Component ==================================================================== */
class PaymentRefundDetails extends Component {
  static componentName = 'PaymentRefundDetails';

  static propTypes = {
    match: PropTypes.object.isRequired,
    selectKey: PropTypes.string,
  };

  static defaultProps = {
    selectKey: 'refund'
  }

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {selectKey} = this.props;
    this.setState({loading: true});

    AppAPI.payment.get(`${this.props.match.params.paymentId}/related-details/?key=${selectKey}`)
      .then((res) => {
        this.setState({
          response: res,
          loading: false,
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };


  render = () => {

    const {loading, error, response, paymentType, paymentStatus, paymentMethod, refundStatus} = this.state;
    const {payment, property} = this.props;


    if (loading || _.isEmpty(payment) || _.isEmpty(response)) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    const {refunds} = response;


    let itemList = refunds;

    if (paymentType) {
      itemList = itemList.filter(item => (item.payment_type === paymentType));
    }

    if (paymentStatus || paymentStatus === 0) {
      itemList = itemList.filter(item => (item.payment_status === paymentStatus));
    }

    if (paymentMethod) {
      itemList = itemList.filter(item => (item.payment_method === paymentMethod));
    }

    if (refundStatus || refundStatus === 0) {
      itemList = itemList.filter(item => (item.refund_status === refundStatus));
    }

    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Payment Refunds</title>
        </Helmet>

        <PageSubHeader icon={faCashRegister} title={'Refund(s)'} description={'Refunds for the payment.'}/>

        <div className={'row'}>
          <div className={'col-md-4 col-12'}>
            <table className={'table table-borderless table-sm mb-3'}>
              <tbody>
                <tr>
                  <td className={'w-50 text-muted'}>Payment Total</td>
                  <td><strong>{`${payment.currency} ${payment.total}`}</strong></td>
                </tr>
                <tr>
                  <td className={'w-50 text-muted'}>Total Settled</td>
                  <td><strong>{`${payment.currency} ${payment.total_settled}`}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={'col-md-4 col-12'}>
            <table className={'table table-borderless table-sm mb-3'}>
              <tbody>
                <tr>
                  <td className={'w-50 text-muted'}>Net Payment</td>
                  <td><strong>{`${payment.currency} ${payment.total_available_fund}`}</strong></td>
                </tr>
                <tr>
                  <td className={'w-50 text-muted'}>Total Refunded</td>
                  <td><strong>{`${payment.currency} ${payment.total_refunded}`}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={'col-md-4 col-12'}>
            <table className={'table table-borderless table-sm mb-3'}>
              <tbody>
                <tr>
                  <td className={'w-50 text-muted'}>Paid Refunds</td>
                  <td>
                    <strong>{!_.isEmpty(refunds) ? refunds.filter(item => (item.payment_status === Constants.PAYMENT_STATUS.PAID)).length : 0}</strong>
                  </td>
                </tr>
                <tr>
                  <td className={'w-50 text-muted'}>Other Refunds</td>
                  <td>
                    <strong>{!_.isEmpty(refunds) ? refunds.filter(item => (item.payment_status === Constants.ORDER_STATUS.CANCELLED || item.payment_status === Constants.ORDER_STATUS.NOTPAID)).length : 0}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={'spacer-20'}/>

        <div className={'row'}>
          <div className={'col-12 col-sm-9'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <FilterButton
                  title={'Payment Method'}
                  selectKey={paymentMethod}
                  data={Filters.paymentMethodValue}
                  action={(data) => this.setState({paymentMethod: data.key})}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Refund Status'}
                  selectKey={paymentStatus}
                  data={Filters.paymentStatusValue}
                  action={(data) => this.setState({paymentStatus: data.key})}
                  size={'sm'}
                />
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-3 text-center text-sm-right'}>
            <button
              onClick={() => this.setState({showRefund: true})}
              className={'btn btn-outline-success btn-sm'}
              disabled={!payment.allow_refund || payment.payment_method === Constants.PAYMENT_METHODS.ONLINE_PAYMENT}
            >
              <FontAwesomeIcon icon={faPlusCircle} size={'sm'} className={'mr-2'}/> Add Refund
            </button>
          </div>
        </div>

        <div className="table-responsive border">
          <table className={`table ${!_.isEmpty(itemList) && 'table-hover'} mb-0`}>
            <thead>
              <tr>
                <th>Reference</th>
                <th/>
                <th>Method</th>
                <th>Payment</th>
                <th className={'text-left'}>Total</th>
                <th className={'text-left blue-cl'}>Refunded</th>
              </tr>
            </thead>
            <tbody>
              {!_.isEmpty(itemList) ?
                <React.Fragment>
                  {itemList.map((data, i) => {
                    return (
                      <tr className={'tr-align-middle'} key={i}>
                        <td className={'data-table-cell-sm'}>
                          <Link to={`/payment/refund/${data.hash}`}>{data.ref_no}</Link><br/>
                          <p className={'small mb-0 text-muted'}>{AppUtil.formatDateTime(data.created)}</p>
                        </td>
                        <td className={'data-table-cell-md text-left'}>
                          <span className={'badge ' + (AppUtil.paymentStatusColor(data.payment_status))}>
                            {data.payment_status_display}
                          </span>
                        </td>
                        <td className={'data-table-cell-sm text-center'}>{data.payment_method_display}</td>
                        <td className={'data-table-cell-md text-center'}>
                          {(!_.isEmpty(data.attributes) && data.attributes.payment_hash )&&
                          <Link to={`/payment/${data.attributes.payment_hash}/`}>
                            {data.attributes.payment_ref_no}
                          </Link>
                          }
                        </td>
                        <td><strong>{`${data.currency} ${data.total}`}</strong></td>
                        <td className={'blue-cl'}><strong>{`${data.currency} ${data.total_refunded}`}</strong></td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={4}/>
                    <td className={'data-table-cell-md text-left'}>
                      <strong>{`${property.currency} ${itemList.reduce((a, b) => parseFloat(a) + parseFloat(b.total), 0).toFixed(2)}`}</strong>
                    </td>
                    <td className={'data-table-cell-md text-left blue-cl'}>
                      <strong>{`${property.currency} ${itemList.reduce((a, b) => parseFloat(a) + parseFloat(b.total_refunded), 0).toFixed(2)}`}</strong>
                    </td>
                  </tr>
                </React.Fragment>
                :
                <tr>
                  <td colSpan={9}>
                    <div className={'text-center table-list-empty'}>
                      <h6><FontAwesomeIcon icon={faCashRegister} className={'mr-2'}/> No Refunds(s) </h6>
                      <p className={'text-muted'}>{Strings.paymentRefundEmpty}</p>
                      <p>
                        <button
                          onClick={() => this.setState({showRefund: true})}
                          className={'btn btn-outline-success btn-sm'}
                          disabled={!payment.allow_refund}
                        >
                          <FontAwesomeIcon icon={faPlusCircle} size={'sm'} className={'mr-2'}/> Add Refund
                        </button>
                      </p>
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>

        <RefundAdd
          property={this.props.property}
          payment={payment}
          updateResponse={this.fetchInitData}
          onHide={() => this.setState({showRefund: false})}
          show={this.state.showRefund}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      response: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PaymentRefundDetails);