import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../lib/api';
// Components
import Error from '../general/Error';
import Loading from '../general/Loading';
// Actions
import * as POSActions from '../../redux/pos/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  user: state.user,
  posDetails: state.pos.posDetails
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPOSDetails: POSActions.setPOSDetails,
};

export default function (ComposedComponent) {
  class PosDetailsRequired extends Component {
    static contextTypes = {
      router: PropTypes.object
    };

    componentDidMount () {
      if (_.isEmpty(this.props.posDetails) || this.props.match.params.POSId !== this.props.posDetails.hash) {
        this.props.setPOSDetails(this.props.match.params.POSId, false)
          // run in then to fetch additional content for POS
          .then(()=>this.props.setPOSDetails(this.props.match.params.POSId, true))
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({error});
          });
      }
    }

    render () {
      if (this.state.error) return <Error full={false} text={this.state.error}/>;
      if (_.isEmpty(this.props.posDetails) || this.props.match.params.POSId !== this.props.posDetails.hash) return <Loading/>;
      return <ComposedComponent {...this.props} />;
    }

    constructor(props) {
      super(props);
      this.firstLoad = false;
      this.state = { error: null };
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(PosDetailsRequired);
}