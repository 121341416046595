/**
 * Order Lock Edit
 *  manages order updates post lock ( Tax Information Additional Information)
 */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {ErrorMessages} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
// Forms
import {Form, Formik} from 'formik';
import {Input} from 'react-formik-ui';


/* Component ==================================================================== */

class OrderLockedEdit extends React.Component {
  static componentName = 'OrderLockedEdit';

  static propTypes = {
    match: PropTypes.object,
    order: PropTypes.object,
    property: PropTypes.object,
    updateResponse: PropTypes.func,
    editType: PropTypes.string,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        loading: false,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  updateOrder = (credentials = null) => {
    let payload = {
      tax_number : credentials.tax_number,
      tax_company : credentials.tax_company,
    };

    if (!_.isEmpty(payload)) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.order
          .post(`${this.props.order.hash}/locked-order/`, payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                this.props.updateResponse(res);
                this.props.onHide();
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {order, show, property} = this.props;

    if (loading) return <Loading heightMatch={true}/>;
    if (!loading && error) return <Error full={true} text={ErrorMessages.initData}/>;
    if (!show) return <React.Fragment/>;

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Order
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            tax_number: order.tax_number || '',
            tax_company: order.tax_company || '',
          }}
          onSubmit={values => this.updateOrder(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <Form className={'form-group'}>
                      <div className="mt-2">
                        <React.Fragment>
                          <div className="form-row">
                            <div className="col-lg-6 col-md-12 form-group">
                              <Input
                                required
                                name='tax_company'
                                label={'Tax Company Name'}
                                className={'form-control'}
                              />
                            </div>
                            <div className="col-lg-6 col-md-12 form-group">
                              <Input
                                required
                                name='tax_number'
                                label={`Tax Number ${property && property.tax_number_type && `(${property.tax_number_type})`}`}
                                className={'form-control'}
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      </div>
                    </Form>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <button
                      type="submit" onClick={formikProps.handleSubmit} className={'btn btn-success btn-lg btn-block'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default OrderLockedEdit;
