/**
 *  POS Item Manage
 *  Add POS item from csv
 *
 */
import { faBroom, faChevronCircleDown, faCog, faPlus, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import _ from 'lodash';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form, Select, SubmitBtn, Textarea } from 'react-formik-ui';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
// Form
import * as Yup from 'yup';
import PageHeader from '../../../components/dashboard/PageHeader';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
// Components
import { Alerts } from '../../../components/ui';
import { Constants, ErrorMessages } from '../../../constants';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
// Actions
import * as POSActions from '../../../redux/pos/actions';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pos: state.pos.pos,
  item: state.pos.item,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPOSItem: POSActions.setPOSItem,
};

/* Component ==================================================================== */

class POSItemCSVAdd extends Component {
  static componentName = 'POSItemCSVAdd';

  static propTypes = {
    match: PropTypes.object,
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  addItem = (credentials) => {
    const {pos} = this.props;
    const VALID_ITEM_STATUS = Constants.ITEM_STATUS_INPUT.map(data => data.value);
    const VALID_ORDER_QUANTITY_TYPE = Constants.ORDER_QUANTITY_TYPE_EXPENSE.map(data => data.value);

    if (credentials && !_.isEmpty(this.state.itemsData)) {

      const itemsData = [];
      this.state.itemsData.forEach((data) => {
        itemsData.push({
          'multiple': true,

          'name': data.Name,
          'price': data.Rate,
          'item_code': data.ItemCode,
          'item_status': VALID_ITEM_STATUS.includes(parseInt(data.ItemStatus)) ? data.ItemStatus : 1,
          'quantity_type': VALID_ORDER_QUANTITY_TYPE.includes(parseInt(data.QuantityType)) ? data.QuantityType : 1,
          'sub_quantity_type': VALID_ORDER_QUANTITY_TYPE.includes(parseInt(data.SubQuantityType)) ? data.SubQuantityType : 1,

          'category': this.state.categoryData[data.CategoryCode] ? data.CategoryCode : credentials.category,
          'food_type': [...Constants.POS_FOOD_TYPE_INPUT.map((data)=>data.value)].includes(parseInt(data.FoodType)) ? data.FoodType : credentials.food_type,
          'food_content': ([...Constants.FOOD_CONTENT_TYPE_INPUT.map((data)=>data.value)].includes(parseInt(data.FoodContent)) && data.FoodType.toString() === Constants.POS_FOOD_TYPE.FOOD.toString()) ? data.FoodContent : credentials.food_content,
          'drink_content': ([...Constants.DRINK_CONTENT_TYPE_INPUT.map((data)=>data.value)].includes(parseInt(data.DrinkContent)) && data.FoodType.toString() === Constants.POS_FOOD_TYPE.DRINK.toString()) ? data.DrinkContent :  credentials.drink_content,

          'description': credentials.description,
          'tax_class': this.state.selectedTaxClasses
        });
      });

      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.posapi.post(`pos-item-settings/?pos=${pos.hash}`, itemsData)
          .then(() => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.history.goBack();
                }, 500);
              },
            );
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };


  fetchInitData = () => {
    const {pos} = this.props;
    let selectedTaxClasses = [];
    let taxClass = [], categoryClass = [], categoryData = {};
    AppAPI.posapi.get(`pos-category-tax-list/?pos=${pos.hash}`)
      .then((res) => {
        if (!_.isEmpty(res.tax_data)) {
          res.tax_data.forEach(data => {
            taxClass.push(data);
          });
        }

        if (!_.isEmpty(res.category_data)) {
          res.category_data.forEach(data => {
            categoryClass.push(
              {'value': data.id.toString(), 'label': data.name.toString()}
            );
            categoryData[data.id] = data.name;
          });
        }

        let formValidation = Yup.object().shape({});
        this.setState({
          taxClass,
          categoryClass,
          categoryData,
          formValidation,
          selectedTaxClasses,
          loading: false,

        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: true,
          resultMsg: {error}
        });
      });
  };

  selectTax = tax => {
    let {selectedTaxClasses} = this.state;
    selectedTaxClasses = AppUtil.insertOrRemoveArray(selectedTaxClasses, tax);
    this.setState({selectedTaxClasses});
  };

  parseInput = (rawData) => {
    Papa.parse(rawData, {
      header: true,
      skipEmptyLines: true,
      error: () => {
        this.setState({
          resultMsg: {
            error: 'Parsing CSV Failed'
          }
        });
      },
      complete: (results) => {
        this.setState({itemsData: results.data});
      },
    });
  };

  render = () => {

    const {pos, property} = this.props;
    const {
      loading,
      error,
      formValidation,
      resultMsg,
      selectedTaxClasses,
      taxClass,
      categoryClass
    } = this.state;

    if (loading) return <Loading/>;

    const VALID_ITEM_STATUS = Constants.ITEM_STATUS_INPUT.map(data => data.value);
    const VALID_ORDER_QUANTITY_TYPE = Constants.ORDER_QUANTITY_TYPE_EXPENSE.map(data => data.value);

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    const initialValues = {
      'import_data': 'Name,ItemCode,CategoryCode,Rate,ItemStatus,QuantityType,SubQuantityType,FoodType,FoodContent,DrinkContent',
      'item_status': '1',
      'food_type': '0',
      'drink_content': '0',
      'food_content': '0',
    };

    return (

      <div className="room-type-list-manage screen-container ">
        <Helmet>
          <title>POS Add Item CSV</title>
        </Helmet>
        <PageHeader
          subHeader={true} history={this.props.history}
          title={'Add Item : CSV'}
          description={'Add item to the point of sales via CSV String'}
        />

        <Formik
          onSubmit={(values) => this.addItem(values)}
          validationSchema={formValidation}
          initialValues={initialValues}>
          {(formikProps) => (
            <Form>
              <div className={'row'}>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Item Information
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <Textarea
                      name="import_data"
                      label={'Input Data CSV Format'}
                      className={'form-control'}
                    />
                    <div className={'my-3'}>
                      <button
                        disabled={_.isEmpty(formikProps.values.import_data)}
                        onClick={() => this.setState({itemsData: []})}
                        type={'button'} className={'btn btn-secondary btn-lg mr-2'}
                      >
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faBroom} size={'sm'}/>
                        Clear
                      </button>
                      <button
                        disabled={_.isEmpty(formikProps.values.import_data)}
                        onClick={() => this.parseInput(formikProps.values.import_data)}
                        type={'button'} className={'btn btn-info btn-lg'}
                      >
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faCog} size={'sm'}/>
                        Parse
                      </button>
                    </div>
                    {!_.isEmpty(this.state.itemsData) && (
                      <table className={'table table-sm table-bordered border format-table'}>
                        <tbody>
                          <tr>
                            <th className={'data-table-cell-lg'}>Name</th>
                            <th className={'data-table-cell-md'}>Item Code</th>
                            <th className={'data-table-cell-sm'}>Category Code</th>
                            <th className={'data-table-cell-md'}>Category</th>
                            <th className={'data-table-cell-md'}>Rate ({property.currency})</th>
                            <th className={'data-table-cell-sm'}>Item Status</th>
                            <th className={'data-table-cell-sm'}>Quantity</th>
                            <th className={'data-table-cell-sm'}>Sub Quantity</th>
                            <th className={'data-table-cell-sm'}>Item Type</th>
                            <th className={'data-table-cell-sm'}>Food Type</th>
                            <th className={'data-table-cell-sm'}>Drink Type</th>
                          </tr>
                        </tbody>
                        <tbody>
                          {this.state.itemsData.map((data, i) => (
                            <tr key={i}>
                              <td>{data.Name}</td>
                              <td>{data.ItemCode}</td>
                              <td>{data.CategoryCode}</td>
                              <td>{this.state.categoryData[data.CategoryCode] || 'N.A'}</td>
                              <td>{data.Rate}</td>
                              <td>{VALID_ITEM_STATUS.includes(parseInt(data.ItemStatus)) ? data.ItemStatus : 'Invalid'}</td>
                              <td>{VALID_ORDER_QUANTITY_TYPE.includes(parseInt(data.QuantityType)) ? data.QuantityType : 'Invalid'}</td>
                              <td>{VALID_ORDER_QUANTITY_TYPE.includes(parseInt(data.SubQuantityType)) ? data.SubQuantityType : 'Invalid'}</td>
                              <td>{[...Constants.POS_FOOD_TYPE_INPUT.map((data)=>data.value)].includes(parseInt(data.FoodType)) ? data.FoodType : 'Invalid'}</td>
                              <td>
                                {([...Constants.FOOD_CONTENT_TYPE_INPUT.map((data)=>data.value)].includes(parseInt(data.FoodContent)) && data.FoodType.toString() === Constants.POS_FOOD_TYPE.FOOD.toString()) ? data.FoodContent : 'Invalid'}
                              </td>
                              <td>
                                {([...Constants.DRINK_CONTENT_TYPE_INPUT.map((data)=>data.value)].includes(parseInt(data.DrinkContent)) && data.FoodType.toString() === Constants.POS_FOOD_TYPE.DRINK.toString()) ? data.DrinkContent : 'Invalid'}
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan={5}>
                              <p className={'small mb-0 text-center text-secondary'}>
                                If invalid options is detected for Quantity, Sub Quantity or Item Status they will be set
                                to default value.
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
              <div className={'row mb-3'}>
                <div className="col-lg-12 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Item Information
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className={'row'}>
                      <div className="col-4 form-group">
                        <Select
                          className={'form-control rounded-right-0'}
                          name={'category'}
                          label={'Category Type'}
                          placeholder="Select an Option"
                          options={categoryClass}
                        />
                      </div>

                      {[Constants.POS_TYPE.RESTRO_BAR, Constants.POS_TYPE.RESTAURANT, Constants.POS_TYPE.BAR].includes(parseInt(pos.pos_type)) &&
                      <React.Fragment>
                        <div className="col-4 form-group">
                          <Select
                            required
                            className={'form-control rounded-right-0'}
                            name="food_type"
                            label={'Item Type'}
                            placeholder="Select an Option"
                            options={Constants.POS_FOOD_TYPE_INPUT}
                          />
                        </div>
                        {formikProps.values.food_type.toString() === Constants.POS_FOOD_TYPE.DRINK.toString() &&
                        <div className="col-4 form-group">
                          <Select
                            required
                            className={'form-control rounded-right-0'}
                            name="drink_content"
                            label={'Drink Type'}
                            placeholder="Select an Option"
                            options={Constants.DRINK_CONTENT_TYPE_INPUT}
                          />
                        </div>
                        }
                        {formikProps.values.food_type.toString() === Constants.POS_FOOD_TYPE.FOOD.toString() &&
                        <div className="col-4 form-group">
                          <Select
                            required
                            className={'form-control rounded-right-0'}
                            name="food_content"
                            label={'Food Content Type'}
                            placeholder="Select an Option"
                            options={Constants.FOOD_CONTENT_TYPE_INPUT}
                          />
                        </div>
                        }
                      </React.Fragment>
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className={'row mb-3'}>
                <div className="col-lg-12 col-md-8 col-sm-12">
                  <div
                    className={'border p-2 text-center anchor'} data-toggle="collapse" href="#taxitems"
                    role="button" aria-expanded="false" aria-controls="taxitems"
                  >
                    <h6 className={'mb-0'}>
                      <FontAwesomeIcon
                        icon={faChevronCircleDown} size={'sm'}
                        className={'mx-2 green-cl float-left mt-1'}/>
                      Tax Information
                      {selectedTaxClasses.length > 0 &&
                      <span className={'ml-3 badge badge-success'}>{selectedTaxClasses.length} Tax applied</span>
                      }
                      <FontAwesomeIcon
                        icon={faChevronCircleDown} size={'sm'}
                        className={'mx-2 green-cl float-right mt-1'}/>
                    </h6>
                  </div>
                  <div className={'py-2 border collapse p-2'} id={'taxitems'}>
                    {this.props.match.params.ticketId &&
                    <Alerts
                      status={'Changes will only be effective on future orders.'}
                    />
                    }
                    <ul className="list-group mt-3 list-inline">
                      {
                        taxClass.map((data, i) => (
                          <li
                            className="list-group-item list-inline-item a-class"
                            key={i}
                            onClick={() => this.selectTax(data.id)}
                          >
                            <input
                              className={'mr-2'} type="checkbox"
                              checked={selectedTaxClasses.includes(data.id)}/>
                            {data.name} : {data.description}
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn
                    disabled={_.isEmpty(this.state.itemsData)}
                    className={'btn btn-success btn-block btn-lg'}
                  >
                    <FontAwesomeIcon
                      className={'white-cl mr-2'}
                      icon={faPlus} size={'sm'}
                    /> Add Multiple Items
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>


      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      itemsData: [],
      initialValues: {},
      taxClass: [],
      categoryData: {},
      categoryClass: [],
      selectedTaxClasses: [],
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(POSItemCSVAdd);
