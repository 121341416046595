/**
 *
 * Property Notification Settings
 * property notification management
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
// Consts and Libs
import AppAPI from '../../../lib/api';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import Alerts from '../../../components/ui/Alerts';
import Strings from '../../../constants/strings';
import PageHeader from '../../../components/dashboard/PageHeader';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// Actions
import * as PropertyActions from '../../../redux/property/actions';
import {confirmAlert} from '../../../components/general/ConfirmAlert';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetPropertyDetails: PropertyActions.isSetPropertyDetails,
};

/* Component ==================================================================== */
class NotificationsAutoTriggerSettings extends Component {
  static componentName = 'NotificationsAutoTriggerSettings';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    AppAPI.property.get(`${this.props.property.id}/notification/settings/`)
      .then((res) => {
        this.processNotificationData(res);
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: true,
          resultMsg: {error},
        });
      });
  }

  processNotificationData = (res) => {
    const emailsCheckBox = {}, textsCheckBox = {};
    if (res.notification_list && res.notification_list.length > 0) {
      res.notification_list.forEach((data) => {
        if (data.notification_list && data.notification_list.length > 0) {
          data.notification_list.forEach((notification_data) => {
            if (notification_data.email) {
              emailsCheckBox[notification_data.notification_key] = !(res.email_blocked && res.email_blocked.length > 0 && res.email_blocked.includes(notification_data.notification_key));
            }
            if (notification_data.text) {
              textsCheckBox[notification_data.notification_key] = !(res.text_blocked && res.text_blocked.length > 0 && res.text_blocked.includes(notification_data.notification_key));
            }
          });
        }
      });
    }
    this.setState({
      notification_list: res.notification_list,
      loading: false,
      emailsCheckBox,
      textsCheckBox,
    });
  };

  updateProperty = (payload) => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.property.post(`${this.props.property.id}/notification/settings/`, payload)
          .then(() => {
            this.setState({resultMsg: {success: 'Success'}, disabled: true},
              () => {
                this.setState({resultMsg: {success: ''}});
                this.props.isSetPropertyDetails(this.props.property.id);
              });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  }

  updateSettings = () => {
    const emails = [], texts = [];

    const {emailsCheckBox, textsCheckBox} = this.state;

    Object.keys(emailsCheckBox).forEach((key) => {
      if (emailsCheckBox[key] === false) {
        emails.push(key);
      }
    });

    Object.keys(textsCheckBox).forEach((key) => {
      if (textsCheckBox[key] === false) {
        texts.push(key);
      }
    });

    this.updateProperty({
      'email_blocked': emails,
      'text_blocked': texts,
    });
  };

  checkAction = (i, type = 'email') => {
    const {emailsCheckBox, textsCheckBox} = this.state;
    if (type === 'text') {
      textsCheckBox[i] = !textsCheckBox[i];
    } else {
      emailsCheckBox[i] = !emailsCheckBox[i];
    }
    this.setState({emailsCheckBox, textsCheckBox, disabled: false});
  };


  render = () => {

    const {loading, error, resultMsg, notification_list, disabled, emailsCheckBox, textsCheckBox} = this.state;
    const {property} = this.props;
    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="notification-details screen-container ">
        <Helmet>
          <title>Auto Notification Settings</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={'Notification Preference'}
          description={'Customise property notification preferences.'}
        />


        <div className={'row'}>
          <div className="col-lg-12 col-md-12 col-sm-12 mb-5">
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
            <table className={'table border mb-4'}>
              <thead>
                <tr className={'text-center'}>
                  <th>Auto Notification Control</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className={'custom-control custom-switch'}>
                      <input
                        id={'emailNotification'}
                        type="checkbox"
                        className={'custom-control-input mr-3'}
                        checked={property.auto_email_notification}
                        onClick={() => {
                          confirmAlert({
                            title: 'Email Auto Notification',
                            message: property.auto_email_notification ? Strings.notificationAutoEmailMasterControlDisable : Strings.notificationAutoEmailMasterControlEnable,
                            buttons: [
                              {
                                className: (property.auto_email_notification ? 'btn-danger' : ' btn-success'),
                                label: 'Yes',
                                onClick: () => this.updateProperty({auto_email_notification: !property.auto_email_notification}),
                              },
                              {
                                className: 'btn-secondary',
                                label: 'No',
                              },
                            ],
                          });
                        }}
                      />
                      <label className="custom-control-label" htmlFor="emailNotification">
                        <strong>Email Notification</strong> : Master control for auto email notifications.
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className={'custom-control custom-switch'}>
                      <input
                        id={'textNotification'}
                        type="checkbox"
                        className={'custom-control-input mr-3'}
                        checked={property.auto_text_notification}
                        onClick={() => {
                          confirmAlert({
                            title: 'Text Auto Notification',
                            message: property.auto_text_notification ? Strings.notificationAutoTextMasterControlDisable : Strings.notificationAutoTextMasterControlEnable,
                            buttons: [
                              {
                                className: (property.auto_text_notification ? 'btn-danger' : ' btn-success'),
                                label: 'Yes',
                                onClick: () => this.updateProperty({auto_text_notification: !property.auto_text_notification}),
                              },
                              {
                                className: 'btn-secondary',
                                label: 'No',
                              },
                            ],
                          });
                        }}
                      />
                      <label className="custom-control-label" htmlFor="textNotification">
                        <strong>Text Notification</strong> : Master control for auto text notifications.
                      </label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <hr/>
            <p><strong>Auto Notification Settings</strong></p>

            {
              Strings.notificationSettingsText.map((data, i) => <p className={' text-muted'} key={i}>{data}</p>)
            }

            <table className={'table table-bordered border'}>
              <thead>
                <tr className={'text-center'}>
                  <th className={'w-20'}>Action</th>
                  <th className={'w-50'}>Description</th>
                  <th>E-mail</th>
                  <th>Text</th>
                </tr>
              </thead>
              <tbody>
                {
                  (notification_list && notification_list.length > 0) ?
                    <React.Fragment>
                      {notification_list.map((data, i) => (
                        <React.Fragment key={i}>
                          <tr className={'table-info'}>
                            <td colSpan={4} className={'text-center'}>
                              <h6 className={'m-0 p-0'}>{data.title}</h6>
                              <p className={'m-0 p-0 small'}>{data.description}</p>
                            </td>
                          </tr>

                          {(data.notification_list && notification_list.length > 0) ?
                            <React.Fragment>
                              {data.notification_list.map((notification, k) => (
                                <tr key={k}>
                                  <td>
                                    {notification.title} {notification.mandatory && '[ M ] '}
                                  </td>
                                  <td>{notification.description}</td>
                                  <td className={`text-center ${!property.auto_email_notification && 'table-secondary'}`}>
                                    {notification.text ?
                                      <div className={'custom-control custom-switch'}>
                                        <input
                                          id={`e${notification.notification_key}`}
                                          disabled={notification.mandatory}
                                          type="checkbox"
                                          className={'custom-control-input mr-3'}
                                          checked={emailsCheckBox[notification.notification_key]}
                                          onChange={() => {
                                            this.checkAction(notification.notification_key);
                                          }}
                                        />
                                        <label className="custom-control-label"
                                          htmlFor={`e${notification.notification_key}`}/>
                                      </div>
                                      :
                                      <FontAwesomeIcon
                                        icon={faTimesCircle}
                                        className={'gray-cl'}
                                      />
                                    }
                                  </td>
                                  <td className={`text-center ${!property.auto_text_notification && 'table-secondary'}`}>
                                    {notification.text ?
                                      <div className={'custom-control custom-switch'}>
                                        <input
                                          id={`t${notification.notification_key}`}
                                          disabled={notification.mandatory}
                                          type="checkbox"
                                          className={'custom-control-input mr-3'}
                                          checked={textsCheckBox[notification.notification_key]}
                                          onChange={() => {
                                            this.checkAction(notification.notification_key, 'text');
                                          }}
                                        />
                                        <label className="custom-control-label"
                                          htmlFor={`t${notification.notification_key}`}/>
                                      </div>
                                      :
                                      <FontAwesomeIcon
                                        icon={faTimesCircle}
                                        className={'gray-cl'}
                                      />
                                    }</td>
                                </tr>
                              ))}
                            </React.Fragment>
                            :
                            <React.Fragment>
                              <tr>
                                <td colSpan={4} className={'text-center'}>
                                  <p>No notifications to configure</p>
                                </td>
                              </tr>
                            </React.Fragment>
                          }
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                    : <div className={'mx-auto'}>
                      <h5><span className={'fa fa-times-circle red-cl'}/> {Strings.notificationSettingsNoEmailTitle} </h5>
                      {
                        Strings.notificationSettingsNoEmailText.map((data, i) => <p key={i}>{data}</p>)
                      }
                    </div>
                }
              </tbody>
            </table>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                [ M ] : Mandatory notification, cannot be disabled.
              </li>
            </ul>
          </div>
        </div>

        <div className={'row'}>
          <div className="col-lg-12 col-sm-12 mb-5">
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
            <button
              onClick={this.updateSettings}
              disabled={disabled} className={'btn btn-md btn-success btn-lg btn-block'}>
              Save
            </button>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      disabled: true,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsAutoTriggerSettings);