/**
 * Manage PGateway Refund
 *  Initiate refund for the PGateway
 */
import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';
import {Modal} from 'react-bootstrap';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import {ErrorMessages} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import {Input} from 'react-formik-ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';
import {confirmAlert} from '../../../components/general/ConfirmAlert';

/* Component ==================================================================== */

class PGatewayRefund extends React.Component {
  static componentName = 'PGatewayRefund';

  static propTypes = {
    property: PropTypes.object,
    payment: PropTypes.object,
    pgateway: PropTypes.object,
    updateResponse: PropTypes.func,
  };

  addPayment = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});

      AppAPI.pgatewayapi.patch(`pgateway/${this.props.pgateway.hash}/`, {
        refund_total: credentials.refund_total,
      })
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
              if (this.props.updateResponse) {
                this.props.updateResponse(res);
              }
              this.props.onHide();
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({loading: false, resultMsg: {error}});
        });
    }
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {pgateway, show, payment} = this.props;
    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    if (!show) return <React.Fragment/>;

    const maxRefund = pgateway.total;
    const formValidation = Yup.object().shape({
      refund_total: Yup.number().max(maxRefund, `Refund amount cannot exceed ${payment.currency} ${maxRefund}`).required(),
    });


    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Online Payment Refund
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            refund_total: maxRefund,
          }}
          validationSchema={formValidation}
          onSubmit={values => this.addPayment(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <Form className={'form-group mb-0'}>
                      <div className={'border rounded p-2 mb-3'}>
                        <div className={'row'}>
                          <div className="col">
                            <p className={'mb-0 text-muted'}>Total</p>
                            <p className={'mb-0'}><strong>{`${pgateway.currency} ${pgateway.total}`}</strong></p>
                          </div>
                          <div className="col">
                            <p className={'mb-0 text-muted'}>Settled Payment</p>
                            <p className={'mb-0'}><strong>{`${pgateway.currency} ${pgateway.total_settled}`}</strong></p>
                          </div>
                          {pgateway.refund_time_limit &&
                          <div className="col">
                            <p className={'mb-0 text-muted'}>Refund Expiry</p>
                            <p className={'mb-0'}>
                              <strong>{AppUtil.formatDateTime(pgateway.refund_time_limit, 'datef')}</strong>
                            </p>
                          </div>
                          }
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col form-group">
                          <Input
                            name="refund_total"
                            label={'Refund amount'}
                            className={'form-control'}
                            hint={`Change amount for a partial refund, to a max of ${`${pgateway.currency} ${pgateway.total}`}.`}
                          />
                        </div>
                      </div>
                      <p className={'small mb-0 text-muted'}>Expiry date to issue refund is {AppUtil.formatDateTime(pgateway.refund_time_limit)}</p>
                    </Form>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <button
                      type="submit"
                      className={'btn btn-success btn-lg btn-block'}
                      onClick={() =>
                        confirmAlert({
                          title: 'Confirm Refund',
                          message: 'Renzo will initiate the refunds immediately and the action cannot be reverted.',
                          buttons: [
                            {
                              className: 'btn-secondary',
                              label: 'Exit',
                            },
                            {
                              className: 'btn-success',
                              label: 'Confirm Refund',
                              onClick: formikProps.handleSubmit,
                            },
                          ],
                        })
                      }
                    >
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faCheck} size={'sm'}/> Issue Refund
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default PGatewayRefund;
