/**
 * Property Records add or manage
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import * as Yup from 'yup';
import Moment from 'moment';
// Consts and Libs
import {Strings} from '../../constants';
// Components
import {Formik} from 'formik';
import {Alerts} from '../../components/ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
import {Datepicker, Form, Input, SubmitBtn} from 'react-formik-ui';

/* Component ==================================================================== */

class PropertyDistributionManage extends Component {
  static componentName = 'PropertyDistributionManage';

  static propTypes = {
    data: PropTypes.object,
    resultMsg: PropTypes.object,
  };

  static defaultProps = {
    resultMsg: {
      status: '',
      success: '',
      error: '',
    },
  }


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        loading: true,
        record: null,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  action = (credentials) => {
    if (credentials) {
      const payload = {
        channel_manager_advance_booking_days: credentials.channel_manager_advance_booking_days,
        booking_engine_advance_booking_days: credentials.booking_engine_advance_booking_days,
        internal_engine_advance_booking_days: credentials.internal_engine_advance_booking_days,

        internal_engine_distribution_start_date: credentials.internal_engine_distribution_start_date ? Moment(credentials.internal_engine_distribution_start_date).format('YYYY-MM-DD') : null,
        internal_engine_distribution_end_date: credentials.internal_engine_distribution_end_date ? Moment(credentials.internal_engine_distribution_end_date).format('YYYY-MM-DD') : null,
        booking_engine_distribution_start_date: credentials.booking_engine_distribution_start_date ? Moment(credentials.booking_engine_distribution_start_date).format('YYYY-MM-DD') : null,
        booking_engine_distribution_end_date: credentials.booking_engine_distribution_end_date ? Moment(credentials.booking_engine_distribution_end_date).format('YYYY-MM-DD') : null,
        channel_manager_distribution_start_date: credentials.channel_manager_distribution_start_date ? Moment(credentials.channel_manager_distribution_start_date).format('YYYY-MM-DD') : null,
        channel_manager_distribution_end_date: credentials.channel_manager_distribution_end_date ? Moment(credentials.channel_manager_distribution_end_date).format('YYYY-MM-DD') : null,
      };

      if (this.props.action) {
        this.props.action(payload);
      }
    }
  }

  render = () => {

    const {show, resultMsg, property} = this.props;

    if (!show) return null;


    const formValidation = Yup.object().shape({
      channel_manager_advance_booking_days: Yup.number().required('Required'),
      booking_engine_advance_booking_days: Yup.number().required('Required'),
      internal_engine_advance_booking_days: Yup.number().required('Required'),
    });

    const initialValues = {
      channel_manager_advance_booking_days: property.channel_manager_advance_booking_days,
      booking_engine_advance_booking_days: property.booking_engine_advance_booking_days,
      internal_engine_advance_booking_days: property.internal_engine_advance_booking_days,


      internal_engine_distribution_start_date: property.internal_engine_distribution_start_date,
      internal_engine_distribution_end_date: property.internal_engine_distribution_end_date,
      booking_engine_distribution_start_date: property.booking_engine_distribution_start_date,
      booking_engine_distribution_end_date: property.booking_engine_distribution_end_date,
      channel_manager_distribution_start_date: property.channel_manager_distribution_start_date,
      channel_manager_distribution_end_date: property.channel_manager_distribution_end_date,
    };

    return (
      <Modal
        {...this.props}
        size={'lg'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Property Distribution Settings
          </Modal.Title>
        </Modal.Header>
        <Formik
          onSubmit={this.action}
          validationSchema={formValidation}
          initialValues={initialValues}>
          {({values}) => (
            <React.Fragment>
              <Modal.Body>
                <Form className={'mb-5'}>
                  <table className={'table table-bordered mb-5'}>
                    <thead className={'text-center'}>
                      <tr>
                        <th rowSpan={2}/>
                        <th rowSpan={2} align={'middle'}>
                          Distribution Length
                          <p className={'small text-muted'}>in days</p>
                        </th>
                        <th colSpan={2}>Date Restrictions</th>
                      </tr>
                      <tr>
                        <th>
                          Start Date
                          <p className={'small text-muted mb-0'}>Leave empty to disable start date</p>
                        </th>
                        <th>
                          End Date
                          <p className={'small text-muted mb-0'}>Leave empty to disable end date</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Internal Engine</td>
                        <td>
                          <Input
                            name='internal_engine_advance_booking_days'
                            className={'form-control'}
                          />
                        </td>
                        <td>
                          <Datepicker
                            required
                            name='internal_engine_distribution_start_date'
                            className={'form-control'}
                            dateFormat="dd MMM yyyy"
                          />
                        </td>
                        <td>
                          <Datepicker
                            required
                            name='internal_engine_distribution_end_date'
                            minDate={values.internal_engine_distribution_start_date}
                            className={'form-control'}
                            dateFormat="dd MMM yyyy"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Booking Engine</td>
                        <td>
                          <Input
                            name='booking_engine_advance_booking_days'
                            className={'form-control'}
                          />
                        </td>
                        <td>
                          <Datepicker
                            required
                            name='booking_engine_distribution_start_date'
                            className={'form-control'}
                            dateFormat="dd MMM yyyy"
                          />
                        </td>
                        <td>
                          <Datepicker
                            required
                            name='booking_engine_distribution_end_date'
                            minDate={values.booking_engine_distribution_start_date}
                            className={'form-control'}
                            dateFormat="dd MMM yyyy"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Channel Manager</td>
                        <td>
                          <Input
                            name='channel_manager_advance_booking_days'
                            className={'form-control'}
                          />
                        </td>
                        <td>
                          <Datepicker
                            required
                            name='channel_manager_distribution_start_date'
                            className={'form-control'}
                            dateFormat="dd MMM yyyy"
                          />
                        </td>
                        <td>
                          <Datepicker
                            required
                            name='channel_manager_distribution_end_date'
                            minDate={values.channel_manager_distribution_start_date}
                            className={'form-control'}
                            dateFormat="dd MMM yyyy"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Form>

                <ul className={'list-unstyled mb-5'}>
                  <li><strong>Distribution Length</strong> : {Strings.propertyBookingLength} </li>
                  <li><strong>Date Restrictions</strong> : {Strings.propertyDistributionRestrictions} </li>
                </ul>
                <p className={'text-muted'}>
                  {Strings.propertyDistributionThrottled}
                </p>

              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <Alerts
                  status={resultMsg.status}
                  success={resultMsg.success}
                  error={resultMsg.error}
                />
                <div className={'row'}>
                  <div className={'col-6'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col-6'}>
                    <SubmitBtn className={'btn btn-success btn-lg btn-block'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/>
                      Update
                    </SubmitBtn>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      initialValues: {},
    };
  }
}

/* Export Component ==================================================================== */
export default PropertyDistributionManage;
