/**
 * Order Reducer
 *
 */

// Set initial state
const initialState = {
  order: {},
  orderItem: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
  case 'SET_ORDER': {
    let order = [];
    if (action.data && typeof action.data === 'object') {
      order = action.data;
    }
    return {
      ...state,
      order,
    };
  }
  case 'SET_ORDER_ITEM': {
    let orderItem = [];
    if (action.data && typeof action.data === 'object') {
      orderItem = action.data;
    }
    return {
      ...state,
      orderItem,
    };
  }
  default:
    return state;
  }
};
