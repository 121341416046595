/**
 *
 * Payment Gateway Details
 */
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
// Components
import PGatewayInfo from './Components/PGatewayInfo';
import PGatewayAction from './Components/PGatewayAction';
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PageHeader from '../../../components/dashboard/PageHeader';
import {confirmAlert} from '../../../components/general/ConfirmAlert';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBan, faLongArrowAltRight} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as PaymentActions from '../../../redux/payment/actions';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pgateway: state.payment.pgateway,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPGateway: PaymentActions.setPGateway,
};

/* Component ==================================================================== */
class PGatewayDetails extends Component {
  static componentName = 'PGatewayDetails';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  checkStatus = (pgateway, type = 1) => {
    this.setState({resultMsg: {status: 'One moment...'}},
      () => {
        AppAPI.pgateway.post(`${pgateway}/status`, {
          status_type: type
        })
          .then((res) => {
            this.props.setPGateway(res);
            this.setState({resultMsg: {success: 'Success'}});
            setTimeout(() => this.setState({resultMsg: {success: ''}}), 500);
          })
          .catch((err) => {
            this.setState({resultMsg: {error: AppAPI.handleError(err)}, loading: false});
          });
      });
  };

  cacheReset = () => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.pgateway
        .post(`${this.props.pgateway.hash}/cache-reset/`, {})
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            this.props.setPGateway(res);
            setTimeout(() => (this.setState({resultMsg: {success: ''}})), 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({loading: false, resultMsg: {error}});
        });
    });
  };

  updatePayment = payload => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.payment
          .patch(this.props.pgateway.payment_id + '/', payload)
          .then(() => {
            this.props.setPGateway(this.props.match.params.pgatewayId);
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => this.setState({resultMsg: {success: ''}}), 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  fetchInitData = () => {
    this.setState({loading: true},
      () => {
        this.props.setPGateway(this.props.match.params.pgatewayId)
          .then(() => this.setState({loading: false}))
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({loading: false, error});
          });
      });
  };

  render = () => {

    const {loading, error} = this.state;
    const {pgateway} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    return (
      <div className="payment-details screen-container ">
        <Helmet>
          <title>Payment Details : {pgateway.invoice_no}</title>
        </Helmet>


        <PageHeader
          history={this.props.history} title={'Online Payment'}
          description={'Reference number : ' + pgateway.invoice_no}/>

        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className={'row'}>
              <div className={'col'}>
                <p className={'mb-0 text-muted'}>Total</p>
                <div className={'d-inline-flex align-items-baseline'}>
                  <h2>
                    {pgateway.currency + ' ' + pgateway.total}
                  </h2>
                  <div className={'align-items-baseline'}>
                    <span className={'ml-2 badge ' + (AppUtil.pGatewayStatusColor(pgateway.payment_status))}>
                      {pgateway.payment_status_display}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <hr/>
            <PGatewayInfo pgateway={pgateway}/>
            <PGatewayAction pgateway={pgateway} action={(data)=>this.props.setPGateway(data)}/>
            <hr/>
            {!_.isEmpty(pgateway.attributes) &&
              <Link to={`/payment/${pgateway.attributes.payment_hash}/`} className="btn btn-success btn-lg btn-block">
                Payment <FontAwesomeIcon icon={faLongArrowAltRight} size={'sm'} className={'ml-2'}/>
              </Link>
            }
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group mb-5">
              <li
                className="list-group-item d-flex justify-content-between align-items-center"
                onClick={() => this.cacheReset()}
              >
                <small>Payment Actions</small>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <button
                  className="btn btn-link red-cl m-0 p-0"
                  disabled={!pgateway.allow_payment}
                  onClick={() => {
                    confirmAlert({
                      title: 'Cancel Payment',
                      message: 'This will cancel and close the payment.',
                      buttons: [
                        {
                          className: 'btn-danger',
                          label: 'Cancel Payment',
                          onClick: () => this.updatePayment({void: true}),
                        },
                        {
                          className: 'btn-secondary',
                          label: 'Exit',
                        },
                      ],
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faBan} size={'sm'} className={'mr-2'}/> Cancel Payment
                </button>
              </li>
              {!_.isEmpty(pgateway.attributes) &&
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  <Link className="" to={`/payment/${pgateway.attributes.payment_hash}/`}>
                    Payment <FontAwesomeIcon icon={faLongArrowAltRight} size={'sm'} className={'ml-2'}/>
                  </Link>
                </li>
              }
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      showRefund: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PGatewayDetails);