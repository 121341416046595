/**
 * Date View
 */
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import DateRangePicker from 'react-daterange-picker';
// Libs
import AppUtil from '../../lib/util';

import { faCalendarAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


/* Component ==================================================================== */
class FilterDate extends Component {
  static componentName = 'FilterDate';

  static propTypes = {
    size: PropTypes.string,
    action: PropTypes.func,
    title: PropTypes.string,
    className: PropTypes.string,
    classNameActive: PropTypes.string,
    disableSelection: PropTypes.bool,
    endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  };

  static defaultProps = {
    className: 'btn-outline-secondary',
    classNameActive: 'btn-outline-primary',
    size: 'md',
    selectionType: 'range',
    title: 'Date',
    disableSelection: false,
  };

  action = (data) => {
    if (this.props.action) {
      this.props.action(data);
    }
  };

  onSelect = (date) => {
    const {selectionType} = this.state;
    if (selectionType === 'range') {
      this.setState({
        endDate: date.end,
        startDate: date.start,
        showDatePicker: false,
      });
      this.action({startDate: date.start, endDate: date.end});
    } else {
      this.setState({startDate: date, showDatePicker: false});
      this.action({startDate: date, endDate: null});
    }
  };

  toggleDatePicker = () => {
    this.setState({showDatePicker: !this.state.showDatePicker});
  };


  render = () => {
    const {maxDate, minDate, classNameActive, className, size, title, disableSelection} = this.props;
    const {showDatePicker, selectionType, startDate, endDate} = this.state;
    return (
      <div className="dropdown">
        <button
          className={`btn ${(startDate || (startDate && endDate)) ? classNameActive : className} btn-${size}`}
          type="button" onClick={() => this.toggleDatePicker()}
        >
          <FontAwesomeIcon icon={faCalendarAlt} size={'sm'} className={'mr-2'}/>
          {title && `${title} `}
          {(title && startDate )&& ' : '}
          {startDate && `${AppUtil.formatDateTime(startDate, 'date')} ${endDate ? `- ${AppUtil.formatDateTime(endDate, 'date')}` : ''}`}
        </button>
        <div className={`dropdown-menu daterage-2-cal-min-width ${(showDatePicker && 'show')}`}>
          <div className={'row mb-2 px-2'}>
            <div className={'col-6 text-center'}>
              <div className="btn-group btn-group-sm" role="group">
                <button
                  type="button"
                  disabled={true}
                  className={'btn btn-outline-secondary'}
                >
                  Date Selection
                </button>
                <button
                  disabled={disableSelection}
                  type="button"
                  className={`btn ${selectionType === 'single' ? 'btn-primary' : 'btn-outline-secondary'}`}
                  onClick={() => this.setState({selectionType: 'single', endDate: null})}
                >
                  Single
                </button>
                <button
                  disabled={disableSelection}
                  type="button"
                  className={`btn ${selectionType === 'range' ? 'btn-primary' : 'btn-outline-secondary'}`}
                  onClick={() => this.setState({selectionType: 'range', endDate: this.state.startDate})}
                >
                  Range
                </button>
              </div>
            </div>
            <div className={'col-6'}>
              <button
                className={'btn btn-block btn-sm btn-outline-danger'}
                onClick={() => this.onSelect(selectionType === 'range' ? {start: null, end: null} : null)}
              >
                <FontAwesomeIcon icon={faTimes} size={'sm'} className={'mr-2'}/> Clear Selection
              </button>
            </div>
          </div>
          <div className="dropdown-divider"/>
          <DateRangePicker
            className={'mx-auto w-100'}
            selectionType={selectionType}
            onSelect={this.onSelect}
            value={selectionType === 'single' ? (startDate && startDate) : (startDate && endDate && Moment.range(startDate, endDate))}
            numberOfCalendars={2}
            minimumDate={minDate}
            maximumDate={maxDate}
          />
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      showDatePicker: false,
      selectionType: (this.props.startDate && !this.props.endDate ? 'single' : (this.props.selectionType || 'range')),
      startDate: this.props.startDate,
      endDate: this.props.endDate,
    };
  }
}

/* Export Component ==================================================================== */
export default FilterDate;
