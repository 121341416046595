import React from 'react';
import {NavLink, Redirect, Route, Switch} from 'react-router-dom';


import NotFound from '../NotFound';

import ExpenseDepartmentList from '../expense/settings/department/ExpenseDepartmentList';
import ExpenseDepartmentSettings from '../expense/settings/department/ExpenseDepartmentSettings';

import GuestList from '../guest/GuestList';
import ProfileManage from '../guest/ProfileManage';
import profileRequired from '../../components/decorators/profileRequired';
import GuestDetails from '../guest/GuestDetails';

const menu = [
  {
    title: 'Department(s)',
    link: '/settings/expense/department',
    isExact: false,
  },
  {
    title: 'Vendor(s)',
    link: '/settings/expense/vendor',
    isExact: false,
  },
];
const menuItems = [];

menu.map((item) => {
  const {title, link, isExact} = item;
  return menuItems.push(
    <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
      <NavLink
        to={{
          pathname: link,
          state: {fromDashboard: true},
        }}
        exact={isExact}
        className="nav-link rounded-0"
        activeClassName="selected">
        {title}
      </NavLink>
    </li>,
  );
});

const PropertyRoute = () => (
  <div>
    <div className={'row'}>
      <div className={'col col-lg-2 col-md-3 col-sm-12 mb-5'}>
        <ul className="nav nav-pills flex-md-column flex-sm-row border rounded mb-3">
          {menuItems}
        </ul>
      </div>
      <div className={'col-lg-10 col-md-9 col-sm-12'}>
        <Switch>
          <Route exact path="/settings/expense" render={() => (<Redirect to="/settings/expense/department"/>)}/>
          <Route path="/settings/expense/department" exact component={ExpenseDepartmentList}/>
          <Route path="/settings/expense/department/:departmentId" exact component={ExpenseDepartmentSettings}/>
          <Route
            path="/settings/expense/vendor" exact
            render={(props) => <GuestList {...props} subHeader={true} isVendor={true}/>}/>
          <Route
            path="/settings/expense/vendor/new" exact
            render={(props) => <ProfileManage {...props} subHeader={true} isVendor={true}/>}/>
          <Route path="/settings/expense/vendor/:guestId/" component={profileRequired(GuestDetails)}/>
          <Route exact component={NotFound}/>
        </Switch>
      </div>
    </div>
  </div>
);

export default PropertyRoute;