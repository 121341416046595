import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
// Consts and Libs
import {Constants} from '../../constants';

import NotFound from '../NotFound';
import OrderView from './OrderView';
import OrderAnalytics from './Analytics';
import OrderDetails from './OrderDetails';
import OrderListIndex from './OrderListIndex';
import ReportsListIndex from '../reports/ReportsListIndex';

import orderRequired from '../../components/decorators/orderRequired';

const OrderRoute = () => (
  <div>

    <div className="container">
      <Switch>
        <Route path="/order/" exact component={OrderView}/>
        <Route path="/order/list" component={OrderListIndex}/>
        <Route path="/order/list-tax-data" component={OrderListIndex}/>
        <Route exact path={'/order/reports/'} render={() => (<Redirect to={'/order/reports/list'}/>)}/>

        <Route
          path={'/order/reports/list'} exact
          render={(props) =>
            <ReportsListIndex
              {...props}
              baseUrl={'/order'}
              source={Constants.REPORT_SOURCE.ORDER}
              headerTitle={'Order Report\'s'}
              headerSubTitle={'Consolidated order reports.'}
              reportOptions={Constants.REPORTS_ORDER}
            />
          }
        />

        <Route path="/order/analytics/" component={OrderAnalytics}/>
        <Route path="/order/:orderId" component={orderRequired(OrderDetails)}/>
        <Route exact component={NotFound}/>
      </Switch>
    </div>

  </div>
);

export default OrderRoute;
