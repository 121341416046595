/**
 *
 * POS Table History
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import PropTypes from 'prop-types';
import PageHeader from '../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHistory} from '@fortawesome/free-solid-svg-icons';
import SearchBar from '../../components/forms/SearchBar';
import _ from 'lodash';
import queryString from 'query-string';

// Actions

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  table: state.pos.table,
  posDetails: state.pos.posDetails,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class POSTableHistory extends Component {
  static componentName = 'POSTableHistory';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };
  componentDidMount = () => {
  };

  fetchData = (page = 1, callback) => {
    const urlParams = {
      'page': page,
    };

    const {posDetails, table} = this.props;

    if (posDetails.hash) {
      urlParams['pos'] = posDetails.hash;
    }

    if (table) {
      urlParams['table'] = this.props.match.params.POSTableId;
    }

    let baseUrl = 'pos-table-history';
    AppAPI.posapi.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        this.setState({
          loading: false,
          error: null,
        });
        if (res.results.length > 0) {
          if (res.next) {
            callback(res.results);
          } else {
            callback(res.results, {
              allLoaded: true, // the end of the list is reached
            });
          }
        } else {
          callback();
        }
      })
      .catch((err) => {
        callback(null, {
          allLoaded: true, // the end of the list is reached
        });
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };

  renderRow = (data, key) => {
    const {posDetails} = this.props;
    return (
      <tr key={key}>
        <td>{AppUtil.formatDateTime(data.time)}</td>
        <td>
          <span
            className={'badge ' + (data.availability_status === -1 ? 'badge-danger' : data.availability_status === 1 ? 'badge-success' : 'badge-info')}>
            {data.availability_status_display}
          </span>
        </td>
        <td>
          <span
            className={'badge ' + (data.availability_status === 1 ? 'badge-success' : 'badge-warning')}>
            {data.availability_status_display}
          </span>
        </td>
        <td>
          <span
            className={'badge ' + (data.maintenance_status === 1 ? 'badge-success' : 'badge-danger')}>
            {data.maintenance_status_display}
          </span>
        </td>
        {!_.isEmpty(data.pos_order_data) ?
          <React.Fragment>
            <td>
              <Link
                to={`/point-of-sale/${posDetails.hash}/pos-order/${data.pos_order_data.hash}/`}>
                {data.pos_order_data.ref_no}
              </Link>
            </td>
            <td>{data.pos_order_data.occupancy}</td>
            <td>{!_.isEmpty(data.pos_order_data.attributes.serving_user) ? data.pos_order_data.attributes.serving_user : '...'}</td>
          </React.Fragment>
          :
          <React.Fragment>
            <td>...</td>
            <td>...</td>
            <td>...</td>
          </React.Fragment>
        }
      </tr>
    );
  };
  emptyView = () => {
    return (
      <tr>
        <td colSpan={6}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faHistory}/> No History </h4>
          </div>
        </td>
      </tr>
    );
  };


  tableHeader = () => {
    return (
      <tr>
        <th>Time</th>
        <th className="center-text">Occupancy Status</th>
        <th className="center-text">Operation Status</th>
        <th className="center-text">Service Status</th>

        <th className="center-text">Tab (ID/Reference)</th>
        <th className="center-text">Occupancy</th>
        <th className="center-text">Served By</th>
      </tr>
    );
  };

  render = () => {

    const {loading, error} = this.state;
    const {table} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="room-list screen-container ">
        <Helmet>
          <title>Maintenance History Rooms</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'Table History'}
          description={`Maintenance history for table ${table.name}`}
        />

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 mb-5">
            <SearchBar
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
              colSpan={7}
            />
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(POSTableHistory);