/**
 * Messaging Actions
 *
 */

import AppAPI from '../../lib/api';


/**
 * Set Messaging
 */
export const setMessaging = (messagingData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof messagingData === 'object') {
    dispatch({
      type: 'SET_MESSAGING',
      data: messagingData,
    });
    return resolve(messagingData);
  } else if (messagingData) {
    return AppAPI.messaging.get(messagingData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_MESSAGING',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Messaging
 */
export const updateMessaging = (messagingData) => setMessaging(messagingData);

/**
 * Remove Messaging
 */
export function unSetMessaging () {
  return (dispatch) => {
    dispatch({
      type: 'SET_MESSAGING',
      data: null,
    });
  };
}

/**
 * Set Parameters for Messaging
 */
export const setMessagingParameter = (messagingData) => (dispatch) => new Promise(async (resolve, reject) => {

  let data = {
    'order': null,
    'booking': null,
    'bookingroom': null,
    'room': null,
    'expense': null,
    'review': null,
    'payment': null
  };

  // Reset to null
  dispatch({
    type: 'SET_MESSAGING_PARAMETER',
    data: data,
  });

  if (typeof messagingData === 'object') {

    if (messagingData.order) {
      data['order'] = messagingData.order;
    }

    if (messagingData.booking) {
      data['booking'] = messagingData.booking;
    }

    if (messagingData.bookingroom) {
      data['bookingroom'] = messagingData.bookingroom;
    }

    if (messagingData.room) {
      data['room'] = messagingData.room;
    }

    if (messagingData.expense) {
      data['expense'] = messagingData.expense;
    }

    if (messagingData.review) {
      data['review'] = messagingData.review;
    }

    if (messagingData.payment) {
      data['payment'] = messagingData.payment;
    }

    dispatch({
      type: 'SET_MESSAGING_PARAMETER',
      data: data,
    });
    return resolve(messagingData);
  }
  return reject();
});

/**
 * UnSet Messaging
 */
export function unSetMessagingParameter () {

  let data = {
    'order': null,
    'booking': null,
    'bookingroom': null,
    'room': null,
    'expense': null,
  };

  return (dispatch) => {
    dispatch({
      type: 'SET_MESSAGING_PARAMETER',
      data: data,
    });
  };
}