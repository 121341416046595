/**
 *
 * Room Details
 */
import React, {Component} from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
// Consts and Libs
import {Constants} from '../../../constants';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PageHeader from '../../../components/dashboard/PageHeader';

import HousekeepingOverView from './HousekeepingOverView';
import HousekeepingHistory from './HousekeepingHistory';
// Actions
import * as RoomActions from '../../../redux/room/actions';
import {Link, NavLink, Route, Switch} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import RoomServiceRequestList from '../RoomServiceRequestList';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  room: state.room.roomHousekeeping,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setRoom: RoomActions.setRoomHousekeeping,
};

/* Component ==================================================================== */
class HousekeepingDetails extends Component {
  static componentName = 'RoomDetails';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    this.setState({
      loading: false,
    });
  };


  render = () => {

    const {loading, error} = this.state;
    const {room} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    let baseUrl = '/house-keeping/room';
    let absBaseUrl = `${baseUrl}/${this.props.match.params.roomId}`;

    const menu = [
      {
        title: 'Room Details',
        link: `${absBaseUrl}/`,
        isExact: true,
      },
      {
        title: 'Service Requests',
        link: `${absBaseUrl}/service-request/`,
        isExact: true,
      },
      {
        title: 'Change Logs',
        link: `${absBaseUrl}/change-logs/`,
      },
    ];

    let menuItems = [];
    menu.map((item) => {
      const {title, link, isExact} = item;
      return menuItems.push(
        <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
          <NavLink
            to={{
              pathname: link,
              state: {fromDashboard: true},
            }}
            exact={isExact}
            className="nav-link rounded-0"
            activeClassName="selected">
            {title}
          </NavLink>
        </li>,
      );
    });


    return (
      <div className="room-details screen-container ">
        <Helmet>
          <title>Room Details</title>
        </Helmet>

        <Link to={'/house-keeping/list'} className={'btn btn-outline-dark mr-2 btn-sm mt-2'}>
          <FontAwesomeIcon icon={faArrowLeft} size={'sm'}/> {'Room List'}
        </Link>

        <PageHeader
          border={false} history={this.props.history} title={'Room Details'}
          description={`Reference number ${room.ref_no}.`}
        />

        <ul className="nav nav-tabs mb-4">
          {menuItems}
        </ul>

        <Switch>
          <Route path={absBaseUrl} exact component={HousekeepingOverView}/>
          <Route path={`${absBaseUrl}/change-logs`} exact component={HousekeepingHistory}/>
          <Route
            path={`${absBaseUrl}/service-request`} exact
            render={() => <RoomServiceRequestList room={room.id} showHeader={false} {...this.props}/>}
          />
        </Switch>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      showRoomServiceRequest: false,
      showRoomServiceRequestType: Constants.ROOM_SERVICE_TYPE.SERVICE,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HousekeepingDetails);