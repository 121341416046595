/**
 * POSOrderBoxList
 *  List view for POS Order
 *
 */
import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Actions
import {faDoorOpen, faTable, faUser, faUsers} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import AppUtil from '../../../lib/util';
import {Constants} from '../../../constants';
import APPUtil from '../../../lib/util';


/* Component ==================================================================== */
class POSTableListView extends Component {
  static componentName = 'POSTableListView';

  static propTypes = {
    data: PropTypes.object.isRequired,
    action: PropTypes.func,
    disabled: PropTypes.bool,
  };

  action = (data) => {
    if (this.props.disabled !== true) {
      if (this.props.action) {
        this.props.action(data);
      }
    }
  };

  render = () => {
    const {data} = this.props;
    const tableName = !_.isEmpty(data.pos_tables_attributes) ? data.pos_tables_attributes.map((data, i) => `${(i > 0) ? ', ' : ''} ${data.name}`) : '....';
    return (
      <div
        className={'border border-bottom-0 pt-3 position-relative anchor list-group-item-action'}
        role="presentation"
        onKeyPress={this.handleKeyPress} onClick={() => this.action(data)}>
        <div className={'pos-table-box'}>
          <React.Fragment>
            <p className={'text-center small mb-1'}>
              #{data.ref_no}
              <span className={'ml-2 badge ' + (AppUtil.paymentStatusColor(data.order_status))}>
                {data.order_status_display}
              </span>
            </p>
            <p className={'mb-1 small text-center'}>
              <strong>
                {!_.isEmpty(data.pos_tables_attributes) &&
                <span className={'mr-2'}>
                  <FontAwesomeIcon icon={faTable} size={'sm'} className={'mr-2'}/>
                  {APPUtil.limitChars(String(tableName), 20)} |
                </span>
                }
                <FontAwesomeIcon icon={faUsers} size={'sm'} className={'mx-2'}/> {data.occupancy}
                {!_.isEmpty(data.attributes.room) &&
                <span className={'ml-2'}>
                    | <FontAwesomeIcon icon={faDoorOpen} size={'sm'} className={'mx-2'}/> {data.attributes.room}
                </span>
                }
              </strong>
            </p>
            <p className={'mb-1 small text-center'}>
              {!_.isEmpty(data.attributes.serving_user) &&
              <React.Fragment>
                <FontAwesomeIcon icon={faUser} size={'sm'} className={'mr-2'}/> {data.attributes.serving_user}
              </React.Fragment>
              }
            </p>
          </React.Fragment>
        </div>

        <table className={'table mb-0 table-sm small w-100 border text-center'}>
          <tbody>
            <tr>
              <td
                className={`border-right w-50 ${data.pos_order_status === Constants.POS_ORDER_STATUS.OPEN ? 'yellow-bg' : 'blue-bg white-cl'}`}>
                {data.pos_order_status_display}
              </td>
              <td className={'lime-bg'}><strong>{`${data.currency} ${data.total}`}</strong></td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default (POSTableListView);