/**
 * Room Service Request
 */
import _ from 'lodash';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import React, {Component} from 'react';

// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import {Constants} from '../../../constants';

// Components
import {FieldArray, Formik} from 'formik';
import {Form, Select, SubmitBtn, Textarea} from 'react-formik-ui';

import {Alerts} from '../../../components/ui';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinus, faPlus, faSave, faTimes} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */

class RoomServiceRequest extends Component {
  static componentName = 'RoomServiceRequest';

  static propTypes = {
    room: PropTypes.number,
    action: PropTypes.func,
    bookingRoom: PropTypes.object,
    serviceType: PropTypes.number,
    roomServiceRequest: PropTypes.object,
  };

  static defaultProps = {
    serviceType: Constants.ROOM_SERVICE_TYPE.SERVICE
  }


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
        loading: true,
      });
      this.fetchInitData();
    }
    return true;
  }


  fetchInitData = () => {
    const {roomServiceItems} = this.state;
    if (_.isEmpty(roomServiceItems)) {
      AppAPI.roomapi.get('room-service-item-list/').then(res => {
        if (res.actions !== '') {
          this.setState({
            loading: false,
            roomServiceItems: res.service_items,
            error: null,
          });
        } else {
          this.setState({
            loading: false,
            error: true,
          });
        }
      })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error: error,
            resultMsg: {error},
          });
        });
    } else {
      this.setState({loading: false});
    }
  };

  addRequest = (credentials) => {
    const {room, bookingRoom, serviceType, roomServiceRequest} = this.props;
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});

      let payload = {
        room: room,
        request_items: credentials.service_request_items,
        service_type: serviceType
      };
      if (bookingRoom) {
        payload.booking_room = bookingRoom.id;
      }
      
      let api;
      
      if (roomServiceRequest) {
        payload = {
          request_items: credentials.service_request_items,
        };
        api = AppAPI.roomapi.patch(`room-service-request/${roomServiceRequest.hash}/`, payload);
      } else {
        api = AppAPI.roomapi.post('room-service-request/', payload);
      }

      if (api){
        api.then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
              if (this.props.updateResponse) {
                this.props.updateResponse(res);
              }
              this.props.onHide();
            }, 500);
          });
        })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      }
    }
  };



  render = () => {

    const {loading, roomServiceItems, resultMsg} = this.state;
    const {show, serviceType} = this.props;

    if (!show) {
      return <React.Fragment/>;
    }


    let validationObject = {
      room_service_item: Yup.number().required('Required')
    };

    let title;
    switch (this.props.serviceType) {
    case Constants.ROOM_SERVICE_TYPE.MAINTENANCE:
      title = 'Maintenance';
      break;

    case Constants.ROOM_SERVICE_TYPE.AMENITY:
      title = 'Amenity';
      validationObject['quantity'] =  Yup.number().required('Required');
      break;

    case Constants.ROOM_SERVICE_TYPE.SERVICE:
    default:
      title = 'Service';
    }

    const initialValueList = {
      service_request_items: '',
      additional_description: '',
      quantity: '1'
    };

    const initialValues = {
      service_request_items: [initialValueList],
    };


    const defaultFormValidation = Yup.object().shape({
      service_request_items: Yup.array().of(Yup.object().shape(validationObject)),
    });


    const SERVICE_ITEMS = [];
    if (!_.isEmpty(roomServiceItems)) {
      roomServiceItems.forEach((data) => {
        if (data.service_type === serviceType && data.active) {
          SERVICE_ITEMS.push({
            value: String(data.id),
            label: data.name,
          });
        }
      });
    }


    return (
      <Modal
        {...this.props}
        size={'lg'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {title} Request
          </Modal.Title>
        </Modal.Header>
        <Formik
          onSubmit={(values) => (this.addRequest(values))}
          validationSchema={defaultFormValidation}
          initialValues={initialValues}>
          {({values}) => (
            <Form>
              <React.Fragment>
                <Modal.Body>
                  {loading ?
                    <Loading heightMatch={false}/> :
                    <React.Fragment>
                      <div className="form-row">
                        <div className="col-lg-12 col-md-12 form-group">
                          <FieldArray
                            name="service_request_items"
                            render={arrayHelpers => (
                              <div>
                                {values.service_request_items && values.service_request_items.length > 0 ? (
                                  values.service_request_items.map((public_link, index) => (
                                    <React.Fragment>
                                      <div key={index} className="form-row mb-3">
                                        <div className={'col-10'}>
                                          <div className={'row'}>
                                            <div className="col form-group">
                                              <Select
                                                className={'form-control form-control-sm rounded-right-0'}
                                                name={`service_request_items.${index}.room_service_item`}
                                                label={'Service'}
                                                placeholder='Select an Option'
                                                options={SERVICE_ITEMS}
                                              />
                                            </div>
                                            {serviceType === Constants.ROOM_SERVICE_TYPE.AMENITY &&
                                            <div className="col-4 form-group">
                                              <Select
                                                className={'form-control form-control-sm rounded-right-0'}
                                                name={`service_request_items.${index}.quantity`}
                                                label={'Quantity'}
                                                placeholder='Select an Option'
                                                options={AppUtil.numericSelector(1, 30)}
                                              />
                                            </div>
                                            }
                                          </div>
                                          <div className={'row'}>
                                            <div className={'col-12'}>
                                              <Textarea
                                                className={'form-control form-control-sm rounded-right-0'}
                                                name={`service_request_items.${index}.additional_description`}
                                                label={'Description'}
                                                placeholder='Any remarks or notes'
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className={'col-2 text-center'}>
                                          <div className="btn-group" role="group">
                                            <button
                                              onClick={() => arrayHelpers.remove(index)}
                                              type="button" className="btn btn-secondary btn-warning">
                                              <FontAwesomeIcon className={'white-cl'} icon={faMinus} size={'sm'}/>
                                            </button>
                                            <button
                                              onClick={() => arrayHelpers.insert(index, '')}
                                              type="button" className="btn btn-secondary btn-success">
                                              <FontAwesomeIcon className={'white-cl'} icon={faPlus} size={'sm'}/>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  ))
                                ) : (
                                  <div className={'text-center'}>
                                    <p>No Service Request Items.</p>
                                    <button
                                      className={'btn btn-success'} type="button"
                                      onClick={() => arrayHelpers.push(initialValueList)}>
                                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add an
                                      Item
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  }
                </Modal.Body>
                <Modal.Footer className={'d-block'}>
                  <div className={'px-3'}>
                    <Alerts
                      status={resultMsg.status}
                      success={resultMsg.success}
                      error={resultMsg.error}
                    />
                  </div>
                  <div className={'row'}>
                    <div className={'col'}>
                      <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                      </button>
                    </div>
                    <div className={'col'}>
                      <SubmitBtn className={'btn btn-success btn-lg btn-block'}>
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Submit
                      </SubmitBtn>
                    </div>
                  </div>
                </Modal.Footer>
              </React.Fragment>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: true,
      clear: false,
      results: [],
      roomServiceItems: [],
    };
  }
}

/* Export Component ==================================================================== */
export default RoomServiceRequest;
