/**
 *
 * Rate Calender
 */
import _ from 'lodash';
import React, {Component} from 'react';
import queryString from 'query-string';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import Moment from 'moment';
// Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import AppWebUtil from '../../lib/webUtils';
import Strings from '../../constants/strings';
// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import PageHeader from '../../components/dashboard/PageHeader';
import {
  faCheckCircle,
  faExclamationCircle,
  faLink,
  faLongArrowAltRight,
  faPlus, faStop,
  faSync,
  faToggleOff,
  faToggleOn,
  faUnlink,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FilterDate from '../../components/dashboard/FilterDate';
import RateBulkUpdate from './models/RateBulkUpdate';
import RateDateDetails from './models/RateDateDetails';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class RateChart extends Component {
  static componentName = 'RateChart';

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = (silentLoad = false) => {
    this.setState({
      loading_inner: !silentLoad,
    });
    let startDate = Moment(), urlParams = {};
    if (this.getQueryParams('availability_start_date')) {
      startDate = Moment(String(this.getQueryParams('availability_start_date')));
    }
    if (this.getQueryParams('availability_end_date')) {
      let endDate = Moment(String(this.getQueryParams('availability_end_date')));
      urlParams['end_date'] = endDate.format('YYYY-MM-DD');
    }

    if (startDate) {
      urlParams['start_date'] = startDate.format('YYYY-MM-DD');
    }

    AppAPI.rateapi
      .get(`rate-chart/?${queryString.stringify(urlParams)}`)
      .then(res => {
        if (res) {
          this.setState({
            startDate: Moment(res.start_date),
            endDate: Moment(res.end_date),
            room_types: res.room_types,
            distribution_settings: res.distribution_settings,
            loading: false,
            loading_inner: false,
            effectivePrice: this.getQueryParams('tax_inclusion') !== 'false',
          });
          this.processRateChartData(res);
        } else {
          this.setState({
            loading: false,
            loading_inner: false,
            error: true,
          });
        }
      })
      .catch(err => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          loading_inner: false,
          error: error,
          resultMsg: {error},
        });
      });
  };

  processRateChartData = res => {
    let tableHead = [], tableBody = [];

    if (!_.isEmpty(res.room_types)) {
      res.room_types.forEach((roomTypeData) => {
        tableBody[roomTypeData.id] = {
          print: roomTypeData.name,
          data: roomTypeData,
          packageData: {}
        };
        if (!_.isEmpty(roomTypeData.packages)) {
          roomTypeData.packages.forEach((packageData) => {
            tableBody[roomTypeData.id]['packageData'][packageData.id] = [];
            const colData = {
              print: packageData.name,
              data: packageData
            };
            tableBody[roomTypeData.id]['packageData'][packageData.id].push(colData);
          });
        }
      });
      if (!_.isEmpty(res.rate_data)) {
        res.rate_data.forEach((rateData) => {
          tableHead.push({'date': rateData.date});

          if (!_.isEmpty(rateData.data)) {
            rateData.data.forEach((dayData) => {
              const colData = {
                print: dayData.base_price_effective,
                data: dayData
              };
              tableBody[dayData.room_type_id]['packageData'][dayData.room_type_package_id].push(colData);
              //tableBody[dayData.room_type_id].push();
            });
          }
        });
      }
    }

    this.setState({
      error: false,
      loading: false,
      loading_inner: false,
      tableHead,
      tableBody,
    });

  }

  selectRestrictionList = (type) => {
    if (type) {
      this.setState({
        restrictionList: AppUtil.insertOrRemoveArray(this.state.restrictionList, type),
      });
    }
  }

  selectChannelList = (type) => {
    if (type) {
      this.setState({
        channelList: AppUtil.insertOrRemoveArray(this.state.channelList, type),
      });
    }
  }

  render = () => {
    const {property} = this.props;
    const {
      loading,
      error,
      tableHead,
      tableBody,
      loading_inner,
      restrictionList,
      channelList,
      distribution_settings,
      restrictionListOptions,
      displayList,
      effectivePrice,
    } = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;


    const distributionSettings = [];

    if (!_.isEmpty(distribution_settings)) {
      distributionSettings.push(
        {
          'name': 'Booking Engine',
          'key': 'booking_engine',
          'connection_settings': distribution_settings.booking_engine_ok,
          'distribution_settings': distribution_settings.allow_booking_engine_distribution,
          'description': Strings.bookingEngineDistribution,
          'btn': 'success',
        },
        {
          'name': 'Channel Manager',
          'key': 'channel_manager',
          'connection_settings': distribution_settings.channel_manager_ok,
          'distribution_settings': distribution_settings.allow_channel_manager_distribution,
          'description': Strings.channelManagerDistributionRateChart,
          'btn': 'warning',
        },
        {
          'name': 'Internal Engine',
          'key': 'internal_engine',
          'connection_settings': distribution_settings.internal_engine_ok,
          'distribution_settings': distribution_settings.allow_internal_engine_distribution,
          'description': Strings.internalEngineDistribution,
          'btn': 'primary',
        },
      );
    }
    return (
      <div className="dashboard screen-container">
        <Helmet>
          <title>Rate Calendar</title>
        </Helmet>

        <PageHeader
          history={this.props.history} title={'Rate Calendar'}
          description={'Date wise rate information.'}
        />

        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-12 col-md-4'}>
            <p className={'small text-muted'}>Date Range</p>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                {loading_inner ?
                  <button className={'btn btn-outline-secondary px-5'}>
                    ....Loading....
                  </button>
                  :
                  <FilterDate
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    minDate={Moment()}
                    action={(data) => {
                      const params = AppWebUtil.processDate(data, 'availability_');
                      params.push({key: 'tax_inclusion', value: effectivePrice});
                      this.setQueryParams(params);
                    }}
                    title={'Date Range'}
                    selectionType={'range'}
                    disableSelection={true}
                  />
                }
              </li>
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary'} onClick={() => this.fetchInitData()}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={''}/>
                </button>
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-12 col-md-8'}>
            {!_.isEmpty(distributionSettings) && <p className={'small text-right text-muted'}>Distribution Channels</p>}
            <ul className="list-group list-group-horizontal small float-md-right">
              {distributionSettings.map((data, i) => (
                <li className={`list-group-item p-0 ${data.key}-box`} key={i}>
                  <button
                    id="btnGroupDrop1"
                    type="button" className={'btn btn-sm dropdown-toggle'} data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <FontAwesomeIcon
                      icon={(data.distribution_settings && data.connection_settings) ? faCheckCircle : faExclamationCircle}
                      className={`mr-2 ${(data.distribution_settings && data.connection_settings) ? 'green-cl' : 'grey-cl'}`}
                    />
                  </button>
                  <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                    <div className={'dropdown-item small'}>
                      Distribution :
                      <span className={`ml-2 badge badge-${(data.distribution_settings ? 'success' : 'secondary')}`}>
                        {data.distribution_settings ? 'Enabled' : ' Disabled'}
                      </span>
                    </div>
                    <div className="dropdown-divider"/>
                    <div className={'dropdown-item small'}>
                      Connection :
                      <span className={`ml-2 badge badge-${(data.connection_settings ? 'success' : 'secondary')}`}>
                        {data.connection_settings ? 'Enabled' : ' Disabled'}
                      </span>
                    </div>
                    <div className="dropdown-divider"/>
                    <div className={'px-2 mb-0'}>
                      {
                        data.description.map((data, i) =>
                          <p className={'small break-word mb-0 text-secondary'} key={i}>{data}</p>,
                        )
                      }
                    </div>
                  </div>
                  <button
                    disabled={!data.connection_settings}
                    onClick={() => this.selectChannelList(data.key)}
                    className={`btn btn-sm ${channelList.includes(data.key) ? `btn-${data.btn}` : ''}`}
                  >{data.name}</button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className={'row mb-2'}>
          <div className={'col-12 col-sm-9'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button className={'btn btn-sm btn-link'} disabled={true}>
                  Constrains <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-1'}/>
                </button>
              </li>
              {restrictionListOptions.map((data, i) => (
                <li className="list-inline-item" key={i}>
                  <button
                    onClick={() => this.selectRestrictionList(data.key)}
                    className={`btn btn-sm btn-outline-${restrictionList.includes(data.key) ? 'primary' : 'secondary'}`}
                  >{data.name}</button>
                </li>
              ))}
            </ul>
          </div>
          <div className={'col-12 col-sm-3 text-center text-sm-right'}>
            <button
              onClick={() => this.setState({showAvailabilityManage: true})}
              className="btn btn-sm btn-outline-primary">
              <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Bulk Update
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            {loading_inner ?
              <Loading/>
              :
              <React.Fragment>
                {(_.isEmpty(tableHead) || _.isEmpty(tableBody)) ?
                  <div/>
                  :
                  <div className={'table-responsive border mb-3'}>
                    <table
                      className={'table table-hover table-bordered border-0 table-sm text-center fixed-table availability-table mb-0'}>
                      <thead className="align-middle">
                        <tr>
                          <th className={'data-table-cell-lg bg-dark align-middle border'}/>
                          <th className={'data-table-cell-xl bg-dark align-middle fixed-2 border'}/>
                          {tableHead.map((data, i) => (
                            <th className={'data-table-cell-md bg-dark text-light'} key={i}>
                              <strong>{AppUtil.formatDateTime(data.date, 'date')}</strong> <br/>
                              <small>{AppUtil.formatDateTime(data.date, 'day')}</small>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {tableBody.map((rowData, i) => (
                          <React.Fragment key={i}>
                            <tr>
                              <td className={'bg-dark text-light'} colSpan={2}>
                                <strong>{rowData.print}</strong>
                                <div className={'float-left'}>
                                  {channelList.includes('booking_engine') &&
                                  <React.Fragment>
                                    <FontAwesomeIcon
                                      icon={rowData.data.booking_engine_ok ? faLink : faUnlink}
                                      title={rowData.data.booking_engine_ok ? Strings.bookingEngineConnected : Strings.bookingEngineDisconnected}
                                      className={'green-cl ml-2'}
                                      size={'sm'}
                                    />
                                    {(rowData.data.booking_engine_ok && !rowData.data.allow_booking_engine_distribution) &&
                                      <FontAwesomeIcon
                                        size={'sm'}
                                        icon={faStop}
                                        className={'green-cl ml-2'}
                                        title={rowData.data.allow_booking_engine_distribution ? Strings.bookingEngineDistributionEnabled : Strings.bookingEngineDistributionDisable}
                                      />
                                    }
                                  </React.Fragment>
                                  }
                                  {channelList.includes('channel_manager') &&
                                  <React.Fragment>
                                    <FontAwesomeIcon
                                      size={'sm'}
                                      className={'yellow-cl ml-2'}
                                      icon={rowData.data.channel_manager_ok ? faLink : faUnlink}
                                      title={rowData.data.channel_manager_ok ? Strings.channelManagerConnected : Strings.channelManagerDisconnected}
                                    />
                                    {(rowData.data.channel_manager_ok && !rowData.data.allow_channel_manager_distribution) &&
                                      <FontAwesomeIcon
                                        size={'sm'}
                                        icon={faStop}
                                        title={rowData.data.allow_channel_manager_distribution ? Strings.channelManagerDistributionEnable : Strings.channelManagerDistributionDisable}
                                        className={'yellow-cl ml-2'}
                                      />
                                    }
                                  </React.Fragment>
                                  }
                                </div>
                              </td>
                              <td className={'table-active text-left'} colSpan={Object.keys(tableHead).length}>
                              </td>
                            </tr>


                            {Object.keys(rowData.packageData).map((id) => (
                              <React.Fragment key={id}>
                                <tr key={id}>
                                  {rowData.packageData[id].map((colData, k) => (
                                    <React.Fragment key={k}>
                                      {k === 0 ?
                                        <React.Fragment>
                                          <td
                                            rowSpan={1 + Object.keys(restrictionList).length + Object.keys(displayList).length}
                                            className={'bg-dark text-light text-left align-middle'}
                                            key={k}
                                          >
                                            {(Object.keys(restrictionList).length + Object.keys(displayList).length > 1) ? colData.print : AppUtil.limitChars(colData.print, 15)}
                                          </td>
                                          <td
                                            className={(Object.keys(restrictionList).length + Object.keys(displayList).length) > 1 ? 'bg-dark text-light' : 'd-none'}/>
                                        </React.Fragment>
                                        :
                                        <td
                                          className={(Object.keys(restrictionList).length + Object.keys(displayList).length) > 1 ? 'bg-dark text-light' : 'd-none'}/>
                                      }
                                    </React.Fragment>
                                  ))}
                                </tr>

                                <tr className={`${displayList.includes('rate') ? '' : 'd-none'}`}>
                                  {rowData.packageData[id].map((colData, k) => (
                                    <React.Fragment key={k}>
                                      {k === 0 ?
                                        <td className={'bg-light fixed-2 text-left m-0 p-0'}>
                                          <table className={'table table m-0 p-0'}>
                                            <tbody>
                                              <tr>
                                                <td className={'bg-light'} colSpan={4}>
                                                  Rate<span className={'ml-2'}>({property.currency})</span>
                                                  <div className={'float-right'}>
                                                    <button
                                                      onClick={() => this.setState({
                                                        rateUpdate: true,
                                                        showAvailabilityManage: true,
                                                        roomTypePackageSelect: colData.data.id,
                                                        roomTypeSelect: colData.data.room_type_id,
                                                      })}
                                                      className="btn btn-sm btn-link p-0 mx-1 float-right">
                                                      <FontAwesomeIcon icon={faUpload} size={'sm'}/>
                                                    </button>
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className={'bg-light w-20'}><small>Status</small></td>
                                                <td className={'bg-light'} colSpan={3}>
                                                  {channelList.includes('booking_engine') &&
                                                  <React.Fragment>
                                                    <FontAwesomeIcon
                                                      size={'sm'}
                                                      className={'green-cl mr-2'}
                                                      icon={colData.data.booking_engine_ok ? faLink : faUnlink}
                                                      title={colData.data.booking_engine_ok ? Strings.bookingEngineConnected : Strings.bookingEngineDisconnected}
                                                    />
                                                    {(colData.data.booking_engine_ok && !colData.data.allow_booking_engine_distribution) &&
                                                      <FontAwesomeIcon
                                                        size={'sm'}
                                                        icon={faStop}
                                                        className={'green-cl mr-2'}
                                                        title={colData.data.allow_booking_engine_distribution ? Strings.bookingEngineDistributionDisabled : Strings.bookingEngineDistributionDisable}
                                                      />
                                                    }
                                                  </React.Fragment>
                                                  }
                                                  {channelList.includes('channel_manager') &&
                                                  <React.Fragment>
                                                    <FontAwesomeIcon
                                                      size={'sm'}
                                                      className={'yellow-cl mr-2'}
                                                      icon={colData.data.channel_manager_ok ? faLink : faUnlink}
                                                      title={colData.data.channel_manager_ok ? Strings.channelManagerConnected : Strings.channelManagerDisconnected}
                                                    />
                                                    {(colData.data.channel_manager_ok && !colData.data.allow_channel_manager_distribution) &&
                                                      <FontAwesomeIcon
                                                        size={'sm'}
                                                        icon={faStop}
                                                        className={'yellow-cl mr-2'}
                                                        title={colData.data.allow_channel_manager_distribution ? Strings.channelManagerDistributionEnable : Strings.channelManagerDistributionDisable}
                                                      />
                                                    }
                                                  </React.Fragment>
                                                  }
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                        :
                                        <td
                                          className={`align-middle a-class ${(colData.data && !colData.data.default_rate) && 'data-entry-box'}`}
                                          onClick={() => this.setState({
                                            showRateDateDetails: true,
                                            rateDateDetails: colData.data,
                                            availabilityDetails2: colData.data,
                                          })}
                                        >
                                          <p className={'mb-0'}>
                                            <strong>
                                              {colData.data ?
                                                <React.Fragment>
                                                  {effectivePrice ? parseFloat(colData.data.base_price_effective).toFixed(2) : parseFloat(colData.data.base_price).toFixed(2)}
                                                </React.Fragment>
                                                :
                                                'N.A'
                                              }
                                            </strong>
                                          </p>
                                        </td>
                                      }
                                    </React.Fragment>
                                  ))}
                                </tr>

                                {restrictionListOptions.filter(item => (item.input === 'bool' && item.editable !== false)).map((bookData, i) => (
                                  <tr key={i} className={`${restrictionList.includes(bookData.key) ? '' : 'd-none'}`}>
                                    {rowData.packageData[id].map((colData, k) => (
                                      <React.Fragment key={k}>
                                        {k === 0 ?
                                          <td className={'bg-light fixed-2 text-left align-middle'}>
                                            {bookData.name}
                                            <button
                                              onClick={() => this.setState({
                                                showAvailabilityManage: true,
                                                restrictionSelectList: [bookData.key],
                                                roomTypePackageSelect: colData.data.id,
                                                roomTypeSelect: colData.data.room_type_id,
                                              })}
                                              className="btn btn-sm btn-link p-0 mx-1 float-right">
                                              <FontAwesomeIcon icon={faUpload} size={'sm'}/>
                                            </button>
                                          </td>
                                          :
                                          <td
                                            className={'m-0 p-0 a-class'}
                                            onClick={() => this.setState({
                                              selectedTab: 'constrains',
                                              rateDateDetails: colData.data,
                                              availabilityDetails2: colData.data,
                                              showRateDateDetails: true,
                                            })}
                                          >
                                            {colData.data ?
                                              <table className={'table table m-0 p-0'}>
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      className={`${channelList.includes('booking_engine') ? 'booking_engine-box' : 'd-none'}`}>
                                                      <FontAwesomeIcon
                                                        className={colData.data[`booking_engine_${bookData.key}`] ? 'green-cl h5 m-0' : 'grey-cl h5 m-0'}
                                                        icon={colData.data[`booking_engine_${bookData.key}`] ? faToggleOn : faToggleOff}
                                                      />
                                                    </td>
                                                    <td
                                                      className={`${channelList.includes('channel_manager') ? 'channel_manager-box' : 'd-none'}`}>
                                                      <FontAwesomeIcon
                                                        className={colData.data[`channel_manager_${bookData.key}`] ? 'green-cl h5 m-0' : 'grey-cl h5 m-0'}
                                                        icon={colData.data[`channel_manager_${bookData.key}`] ? faToggleOn : faToggleOff}
                                                      />
                                                    </td>
                                                    <td
                                                      className={`${channelList.includes('internal_engine') ? 'internal_engine-box' : 'd-none'}`}>
                                                      <FontAwesomeIcon
                                                        className={colData.data[`internal_engine_${bookData.key}`] ? 'green-cl h5 m-0' : 'grey-cl h5 m-0'}
                                                        icon={colData.data[`internal_engine_${bookData.key}`] ? faToggleOn : faToggleOff}
                                                      />
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                              :
                                              'N.A'
                                            }
                                          </td>
                                        }
                                      </React.Fragment>
                                    ))}
                                  </tr>
                                ))}

                                {restrictionListOptions.filter(item => (item.input === 'number' && item.editable !== false)).map((bookData, i) => (
                                  <tr key={i} className={`${restrictionList.includes(bookData.key) ? '' : 'd-none'}`}>
                                    {rowData.packageData[id].map((colData, k) => (
                                      <React.Fragment key={k}>
                                        {k === 0 ?
                                          <td className={'bg-light fixed-2 text-left'}>
                                            {bookData.name}
                                            <button
                                              onClick={() => this.setState({
                                                showAvailabilityManage: true,
                                                restrictionSelectList: [bookData.key],
                                                roomTypePackageSelect: colData.data.id,
                                                roomTypeSelect: colData.data.room_type_id,
                                              })}
                                              className="btn btn-sm btn-link p-0 mx-1 float-right">
                                              <FontAwesomeIcon icon={faUpload} size={'sm'}/>
                                            </button>
                                          </td>
                                          :
                                          <td
                                            className={'m-0 p-0 a-class'}
                                            onClick={() => this.setState({
                                              rateDateDetails: colData.data,
                                              availabilityDetails2: colData.data,
                                              selectedTab: 'constrains',
                                              showRateDateDetails: true,
                                            })}
                                          >
                                            {colData.data ?
                                              <table className={'table table m-0 p-0'}>
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      className={`${channelList.includes('booking_engine') ? 'booking_engine-box' : 'd-none'}`}>
                                                      {colData.data[`booking_engine_${bookData.key}`] ? colData.data[`booking_engine_${bookData.key}`] : '...'}
                                                    </td>
                                                    <td
                                                      className={`${channelList.includes('channel_manager') ? 'channel_manager-box' : 'd-none'}`}>
                                                      {colData.data[`channel_manager_${bookData.key}`] ? colData.data[`channel_manager_${bookData.key}`] : '...'}
                                                    </td>
                                                    <td
                                                      className={`${channelList.includes('internal_engine') ? 'internal_engine-box' : 'd-none'}`}>
                                                      {colData.data[`internal_engine_${bookData.key}`] ? colData.data[`internal_engine_${bookData.key}`] : '...'}
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                              :
                                              'N.A'
                                            }
                                          </td>
                                        }
                                      </React.Fragment>
                                    ))}
                                  </tr>
                                ))}

                              </React.Fragment>
                            ))}


                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                }
              </React.Fragment>
            }
            <div className={'row'}>
              <div className={'col-12 col-sm-9'}>
                <ul className="list-inline list-item-mb-1">
                  <li className="list-inline-item">
                    <FontAwesomeIcon
                      icon={faLink} size={'sm'}
                      className={'orange-cl mr-1'}
                    /> Connected to Channel
                  </li>
                  <li className="list-inline-item">
                    <FontAwesomeIcon
                      icon={faUnlink} size={'sm'}
                      className={'orange-cl mr-1'}
                    /> Disconnected from Channel
                  </li>
                  <li className="list-inline-item">
                    <FontAwesomeIcon
                      icon={faStop} size={'sm'}
                      className={'orange-cl mr-1'}
                    /> Stop Sell in Parent Level
                  </li>
                </ul>
              </div>
              <div className={'col-12 col-sm-3 text-center text-sm-right'}>
                <div className="btn-group btn-group-sm" role="group">
                  <button
                    type="button"
                    disabled={true}
                    className={'btn btn-outline-secondary'}
                  >
                    Tax Setting
                  </button>
                  <button
                    type="button"
                    onClick={()=>this.setState({effectivePrice: true})}
                    className={`btn btn-${effectivePrice ? 'success' : 'outline-secondary' }`}>
                    Inclusive
                  </button>
                  <button
                    type="button"
                    onClick={()=>this.setState({effectivePrice: false})}
                    className={`btn btn-${!effectivePrice ? 'success' : 'outline-secondary' }`}>
                    Exclusive
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RateDateDetails
          roomTypes={this.state.room_types}
          show={this.state.showRateDateDetails}
          onHide={() =>
            this.setState({
              showRateDateDetails: false,
              rateDateDetails: null,
              selectedTab: 'overview'
            })
          }
          property={this.props.property}
          rateDateDetails={this.state.rateDateDetails}
          availabilityDetails2={this.state.availabilityDetails2}
          distributionSettings={distribution_settings}
          updateResponse={(res, masterReload = true) => {
            if (!_.isEmpty(res)) {
              this.setState({rateDateDetails: res});
            }
            if (masterReload) {
              this.fetchInitData(true);
            }
          }}
          selectedTab={this.state.selectedTab}
        />
        <RateBulkUpdate
          show={this.state.showAvailabilityManage}
          onHide={() => this.setState({
            showAvailabilityManage: false,
            restrictionSelectList: null,
            roomTypePackageSelect: null,
            roomTypeSelect: null,
            rateUpdate: false,
          })}
          roomTypes={this.state.room_types}
          rateUpdate={this.state.rateUpdate}
          roomTypeSelect={this.state.roomTypeSelect}
          roomTypePackageSelect={this.state.roomTypePackageSelect}
          updateResponse={this.fetchInitData}
          channelList={this.state.channelList}
          restrictionList={this.state.restrictionSelectList || this.state.restrictionList}
          restrictionListOptions={this.state.restrictionListOptions}
          minDate={this.state.startDate}
          maxDate={this.state.endDate}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loading_inner: true,
      effectivePrice: true,
      error: null,
      restrictionList: [],
      restrictionSelectList: null,
      roomTypeSelect: null,
      channelList: ['channel_manager'],
      startDate: Moment().startOf('day'),
      endDate: Moment().add(30, 'days'),
      showRateDateDetails: false,
      rateDateDetails: null,
      availabilityDetails2: null,
      showAvailabilityManage: false,
      displayList: ['rate'],
      restrictionListOptions: [
        {
          'key': 'restriction',
          'name': 'Stop Sell',
          'input': 'bool',
        },
        {
          'key': 'closed_for_checkin',
          'name': 'Arrival Closed',
          'input': 'bool',
        },
        {
          'key': 'closed_for_checkout',
          'name': 'Departure Closed',
          'input': 'bool',
        },
        {
          'key': 'min_length_of_stay',
          'name': 'Min Length of Stay',
          'input': 'number',
        },
        {
          'key': 'max_length_of_stay',
          'name': 'Max Length of Stay',
          'input': 'number',
        },
      ],
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RateChart);
