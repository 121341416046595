/**
 * Sidebar
 */
import {Link, NavLink, withRouter} from 'react-router-dom';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _ from 'lodash';

//Actions
import * as PropertyActions from '../../redux/property/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  user: state.user,
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  unSetProperty: PropertyActions.unSetProperty,
};


/* Component ==================================================================== */
class Sidebar extends Component {
  static componentName = 'Header';

  static propTypes = {
    disableLinks: PropTypes.bool,
  };

  render = () => {
    const {property} = this.props;
    let menu = [{
      title: 'Dashboard',
      link: '/dashboard',
      isExact: true,
    }];
    if (!_.isEmpty(property)) {
      if (!_.isEmpty(property.modules)) {
        if (property.modules.indexOf('booking') > -1) {
          menu.push(
            {
              title: 'Front Desk',
              link: '/booking',
            },
            {
              title: 'House Keeping',
              link: '/house-keeping',
            }
          );
        }

        if (property.modules.indexOf('expense') > -1) {
          menu.push(
            {
              title: 'Expense',
              link: '/expense'
            }
          );
        }

        if (property.modules.indexOf('event') > -1) {
          menu.push(
            {
              title: 'Event Management',
              link: '/event',
            }
          );
        }


        if (property.modules.indexOf('pos') > -1) {
          menu.push(
            {
              title: 'Point of Sales',
              link: '/point-of-sale',
            }
          );
        }

        menu.push(
          {
            title: 'Order',
            link: '/order',
          },
          {
            title: 'Payment',
            link: '/payment',
          },
          {
            title: 'Customer',
            link: '/customer',
          },
          {
            title: 'Reports',
            link: '/reports',
          }
        );
      }
    } else {
      menu = [
        {
          title: 'Property List',
          link: '/property'
        }
      ];
    }

    return (
      <div className={'sidebar'}>
        <div className="sidebar-container collapse sidebar-collapse" id={'renzoSidebar'}>
          <div className={'text-center d-block border-bottom sidebar-nav-brand'}>
            <nav className="navbar navbar-expand-md navbar-light bg-light">
              <button
                className="navbar-toggler" type="button" data-toggle="collapse"
                data-target="#renzoSidebar" aria-controls="renzoSidebar"
                aria-expanded="true" aria-label="Toggle Sidebar">
                <span className="navbar-toggler-icon"/>
              </button>
              <Link className="navbar-brand mx-auto" to="/dashboard">
                <img className={'w-100'} alt="Brand" src={require('../../images/logo.png')}/>
              </Link>
              <button
                className="navbar-toggler" type="button" data-toggle="collapse"
                data-target="#renzoSidebar" aria-controls="renzoSidebar"
                aria-expanded="true" aria-label="Toggle Sidebar">
                <span className="navbar-toggler-icon"/>
              </button>
            </nav>
          </div>
          <nav className={'navbar-dark mt-3'}>
            {!_.isEmpty(menu) &&
            <ul className="navbar-nav mt-5">
              {menu.map((data,i)=>(
                <li className="nav-item" key={`menu-item-${i}`}>
                  {data.disabled ?
                    <div className="nav-link disabled">{data.title}</div>
                    :
                    <NavLink
                      className="nav-link"
                      exact={data.isExact}
                      activeClassName="selected"
                      to={data.link}>
                      {data.title}
                    </NavLink>
                  }
                </li>
              ))}
            </ul>
            }
          </nav>
        </div>
      </div>
    );
  };
}


/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));
