/**
 *
 * Property Tax Class List
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants, Strings} from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import SearchBar from '../../../components/forms/SearchBar';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLongArrowAltRight, faMoneyBillWave, faPlus} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PropertyTaxClassList extends Component {
  static componentName = 'PropertyTaxClassList';
  componentDidMount = () => {
  };
  fetchTaxClassList = (page = 1, callback) => {
    AppAPI.propertytaxclass.get('?page=' + page)
      .then((res) => {
        this.setState({
          loading: false,
          error: null,
        });
        if (res.results.length > 0) {
          if (res.next) {
            callback(res.results);
          } else {
            callback(res.results, {
              allLoaded: true, // the end of the list is reached
            });
          }
        } else {
          callback();
        }
      })
      .catch((err) => {
        callback(null, {
          allLoaded: true, // the end of the list is reached
        });
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };
  renderRow = (data, key) => {
    return (
      <tr key={key}>
        <td className={'data-table-cell-lg'}>
          <Link
            to={{
              pathname: '/settings/property/tax/' + data.id,
              state: {fromDashboard: true},
            }}
          >
            {data.name}
          </Link>
        </td>
        <td>
          {data.tax_slab === Constants.TAX_SLAB.SLAB ?
            'Slab Price'
            :
            `${data.tax} ${data.tax_type === Constants.TAX_TYPE.PERCENTAGE ? '%' : this.props.property.currency}`
          }

        </td>
        <td>{data.tax_type_display}</td>
        <td>{data.tax_slab_display}</td>
        <td>
          <Link to={`/settings/property/tax/${data.id}`}>
            Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
          </Link>
        </td>
      </tr>
    );
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={4}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faMoneyBillWave}/> No Tax Classes </h4>
            {Strings.propertyTaxClassesListEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };

  tableHeader = () => {
    return (
      <tr>
        <th>Tax Name</th>
        <th>Value</th>
        <th>Tax Type</th>
        <th>Slab Type</th>
        <th/>
      </tr>
    );
  };

  render = () => {

    const {loading, error} = this.state;
    const {property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="tax-list screen-container ">
        <Helmet>
          <title>Tax Classes</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'Tax Classes'} description={`Tax Classes available in ${property.name}.`}/>


        <div className="row">
          <div className="col-lg-9 col-md-8 col-sm-12">
            <SearchBar
              renderRow={this.renderRow}
              fetchData={this.fetchTaxClassList}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
              colSpan={5}
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item">
                <Link to={'/settings/property/tax/new'} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Tax Class
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyTaxClassList);