/**
 * POSTableListView
 *  List view for POS Tables
 *
 */
import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Actions
import {Constants} from '../../../constants';
import APPUtil from '../../../lib/util';
import {faDoorOpen, faUser, faUsers} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


/* Component ==================================================================== */
class POSTableListView extends Component {
  static componentName = 'POSTableListView';

  static propTypes = {
    data: PropTypes.object.isRequired,
    history: PropTypes.object,
    action: PropTypes.func,
    disabled: PropTypes.bool,
  };

  action = (data) => {
    if (this.props.disabled !== true && this.props.history) {
      if (this.props.action) {
        this.props.action(data);
      }
    }
  };

  render = () => {
    const {data} = this.props;
    return (
      <div
        className={'border border-bottom-0 pt-3 position-relative anchor list-group-item-action ' + (data.maintenance_status === Constants.ROOM.NORMAL ? 'white-bg' : 'yellow-bg')}
        role="presentation"
        onKeyPress={this.handleKeyPress} onClick={() => this.action(data)}>
        {/*<span*/}
        {/*  className={'position-absolute top-position-badge badge ' + (data.service_status === Constants.ROOM.CLEAN ? 'badge-success' : 'badge-danger')}>*/}
        {/*  {data.service_status_display}*/}
        {/*</span>*/}
        <div className={'pos-table-box'}>
          {!_.isEmpty(data.pos_order_data) ?
            <React.Fragment>
              <p className={'text-center small mb-1'}>{APPUtil.limitChars(data.name, 16)}</p>
              <p className={'mb-1 small text-center'}>
                <strong>#{data.pos_order_data.ref_no} | <FontAwesomeIcon icon={faUsers} size={'sm'} className={'mr-2'}/> {data.pos_order_data.occupancy}</strong>
              </p>
              <p className={'mb-1 small text-center'}>
                {!_.isEmpty(data.pos_order_data.attributes.serving_user) &&
                  <React.Fragment>
                    <FontAwesomeIcon icon={faUser} size={'sm'} className={'mr-2'}/>
                    {data.pos_order_data.attributes.serving_user}
                  </React.Fragment>
                }
                {!_.isEmpty(data.pos_order_data.attributes.room) &&
                  <React.Fragment>
                    {!_.isEmpty(data.pos_order_data.attributes.room) && ' | '}
                    <FontAwesomeIcon icon={faDoorOpen} size={'sm'} className={'mr-2'}/>
                    {data.pos_order_data.attributes.room}
                  </React.Fragment>
                }
              </p>
            </React.Fragment>
            :
            <React.Fragment>
              <h4 className={'text-center'}>{APPUtil.limitChars(data.name, 16)}</h4>
              <p className={'mb-1 small text-center'}>
                <FontAwesomeIcon icon={faUsers} size={'sm'} className={'mr-2'}/> Occupancy : {data.occupancy}
              </p>
            </React.Fragment>
          }
        </div>

        <table className={'table mb-0 table-sm small w-100 border text-center'}>
          <tbody>
            <tr>
              <td
                className={'border-right w-50 ' + (data.availability_status === Constants.ROOM.AVAILABLE ? 'lime-bg' : 'yellow-bg')}>{data.availability_status_display}</td>
              <td
                className={data.maintenance_status === Constants.ROOM.NORMAL ? 'lime-bg' : 'red-bg white-cl'}>{APPUtil.limitChars(data.maintenance_status_display, 12)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default (POSTableListView);

