/**
 *
 * Booking Date view
 */
/* eslint no-eval: 0 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Consts and Libs
import {Constants} from '../../../constants';
import AppUtil from '../../../lib/util';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PropTypes from 'prop-types';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

const BOOKING_VALUES = {
  UPCOMING: Constants.BOOKING.UPCOMING,
  ACTIVE: Constants.BOOKING.ACTIVE,
  COMPLETED: Constants.BOOKING.COMPLETED,
  NOSHOW: Constants.BOOKING.NOSHOW,
  CANCELED: Constants.BOOKING.CANCELED,
};

/* Component ==================================================================== */

class BookingAnalyticsDateView extends Component {
  static componentName = 'BookingAnalyticsDateView';

  static propTypes = {
    responseData: PropTypes.object.isRequired,
  };



  componentDidMount = () => {
  };

  processSourceData = (data, dataSourceKey, dataSelectionKey) => {
    const tabledata = [];
    let total = 0;
    Object.keys(Constants.BOOKING_SOURCE).forEach((key) => {
      const value = parseInt(eval(`data.${dataSourceKey}[${Constants.BOOKING_SOURCE[key]}][${dataSelectionKey}]`));
      total += value;
      tabledata.push(
        <td
          className={`align-middle ${value < 1 ? 'grey-light-bg' : 'white-bg'} `}
          key={key}
        >
          {value}
        </td>
      );
    });
    return(
      <React.Fragment>
        {tabledata}
        <td className={'align-middle'}><strong>{total}</strong></td>
      </React.Fragment>
    );
  }

  processStatusData = (data, dataSourceKey) => {
    const tabledata = [];
    Object.keys(BOOKING_VALUES).forEach((key) => {
      const value = parseInt(eval(`data.${dataSourceKey}[${BOOKING_VALUES[key]}]`));
      tabledata.push(
        <td
          className={`align-middle ${value < 1 ? 'grey-light-bg' : 'white-bg'} `}
          key={key}
        >
          {value}
        </td>
      );
    });
    return(
      <React.Fragment>
        {tabledata}
        <td className={'align-middle'}><strong>
          {parseInt(eval(`data.${dataSourceKey}[${Constants.BOOKING.BOOKED}]`))}
        </strong></td>
      </React.Fragment>
    );
  }

  processStatusRevenueData = (data, dataSourceKey, dataSelectionKey) => {
    const tabledata = [];
    let total = 0;
    Object.keys(BOOKING_VALUES).forEach((key) => {
      const value = parseInt(eval(`data.${dataSourceKey}[${BOOKING_VALUES[key]}][${dataSelectionKey}]`));
      total += value;
      tabledata.push(
        <td
          className={`align-middle ${value < 1 ? 'grey-light-bg' : 'white-bg'} `}
          key={key}
        >
          {value}
        </td>
      );
    });
    return(
      <React.Fragment>
        {tabledata}
        <td className={'align-middle'}><strong>{total}</strong></td>
      </React.Fragment>
    );
  }

  render = () => {
    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const {date_data, currency} = this.props.responseData;

    const bookingStatusData = <React.Fragment>
      <th className={'data-table-cell-md'}>Up Coming</th>
      <th className={'data-table-cell-md'}>Active</th>
      <th className={'data-table-cell-md'}>Completed</th>
      <th className={'data-table-cell-md'}>No Show</th>
      <th className={'data-table-cell-md'}>Cancelled</th>
      <th className={'data-table-cell-md'}>Booked</th>
    </React.Fragment>;

    const bookingRevenueData = <React.Fragment>
      <th className={'data-table-cell-md'}>Up Coming</th>
      <th className={'data-table-cell-md'}>Active</th>
      <th className={'data-table-cell-md'}>Completed</th>
      <th className={'data-table-cell-md'}>No Show</th>
      <th className={'data-table-cell-md'}>Cancelled</th>
      <th className={'data-table-cell-md'}>Total</th>
    </React.Fragment>;

    const bookingSourceData = <React.Fragment>
      <th className={'data-table-cell-sm'}>Agent</th>
      <th className={'data-table-cell-sm'}>Email</th>
      <th className={'data-table-cell-sm'}>Walk in</th>
      <th className={'data-table-cell-sm'}>Phone</th>
      <th className={'data-table-cell-sm'}>C.M</th>
      <th className={'data-table-cell-sm'}>B.E</th>
      <th className={'data-table-cell-sm'}>Corporate</th>
      <th className={'data-table-cell-sm'}>OTA</th>
      <th className={'data-table-cell-sm'}>Total</th>
    </React.Fragment>;

    const bookingDataType = (col=6)=>(
      <React.Fragment>
        <th colSpan={col}>Booking (s)</th>
        <th colSpan={col}>Room(s)</th>
        <th colSpan={col}>Room Nights(s)</th>
        <th colSpan={col}>Revenue  ({currency})</th>
        <th colSpan={col}>Refunded  ({currency})</th>
        <th colSpan={col}>Pending  ({currency})</th>
      </React.Fragment>
    );
    return (
      <div className="dashboard screen-container">
        <Helmet>
          <title>Booking Analytics</title>
        </Helmet>
        <div className="table-responsive border">
          <table className={'table table-bordered table-sm fixed-table text-center border'}>
            <thead className={'bg-dark text-light'}>
              <tr>
                <th className={'data-table-cell-lg bg-dark text-light'}>Date</th>
                <th className={'align-middle blue-bg'} colSpan={36}>Bookings Status</th>
                <th className={'align-middle yellow-bg text-dark'}  colSpan={54}>Bookings Source</th>
              </tr>
              <tr>
                <th className={'data-table-cell-lg bg-dark text-light'}/>
                {bookingDataType()}
                {bookingDataType(9)}
              </tr>
              <tr>
                <th className={'data-table-cell-lg bg-dark text-light'}/>
                {bookingStatusData}
                {bookingStatusData}
                {bookingStatusData}
                {bookingRevenueData}
                {bookingRevenueData}
                {bookingRevenueData}

                {bookingSourceData}
                {bookingSourceData}
                {bookingSourceData}
                {bookingSourceData}
                {bookingSourceData}
                {bookingSourceData}
              </tr>
            </thead>
            <tbody>
              <React.Fragment>
                {
                  date_data.map((data, i) => (
                    <tr key={i}>
                      <td className={'bg-dark text-light'}>
                        <strong>{AppUtil.formatDateTime(data.date, 'datef')}</strong><br/>
                        <small>{AppUtil.formatDateTime(data.date, 'day')}</small>
                      </td>
                      {this.processStatusData(data, 'booking_count')}
                      {this.processStatusData(data, 'booking_room_count')}
                      {this.processStatusData(data, 'booking_room_night_count')}
                      {this.processStatusRevenueData(data, 'booking_revenue_value', Constants.ORDER_STATUS.TOTAL_PAID)}
                      {this.processStatusRevenueData(data, 'booking_revenue_value', Constants.ORDER_STATUS.TOTAL_REFUNDED)}
                      {this.processStatusRevenueData(data, 'booking_revenue_value', Constants.ORDER_STATUS.NOTPAID)}
                      {this.processSourceData(data, 'booking_source_count', Constants.BOOKING.BOOKED)}
                      {this.processSourceData(data, 'booking_source_room_count', Constants.BOOKING.BOOKED)}
                      {this.processSourceData(data, 'booking_source_room_night_count', Constants.BOOKING.BOOKED)}
                      {this.processSourceData(data, 'booking_source_revenue_value', Constants.ORDER_STATUS.TOTAL_PAID)}
                      {this.processSourceData(data, 'booking_source_revenue_value', Constants.ORDER_STATUS.TOTAL_REFUNDED)}
                      {this.processSourceData(data, 'booking_source_revenue_value', Constants.ORDER_STATUS.NOTPAID)}
                    </tr>
                  ))
                }
              </React.Fragment>
            </tbody>
          </table>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loading_inner: false,
      error: null,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingAnalyticsDateView);
