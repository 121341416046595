/**
 *  Room Type Management
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Actions
import * as RoomTypeActions from '../../../redux/roomtype/actions';
// Consts and Libs
import AppUtil from '../../../lib/util';
import AppAPI from '../../../lib/api';
import {Constants, ErrorMessages, Strings} from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faSave} from '@fortawesome/free-solid-svg-icons';
// Form
import * as Yup from 'yup';
import {Checkbox, Form, Input, Radio, Select, SubmitBtn} from 'react-formik-ui';
import {Formik} from 'formik';
import {Alerts} from '../../../components/ui';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  roomType: state.roomType.roomType,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setRoomType: RoomTypeActions.setRoomType,
};

/* Component ==================================================================== */

class RoomTypeManage extends Component {
  static componentName = 'RoomTypeManage';

  static propTypes = {
    match: PropTypes.object,
  };

  componentDidMount = () => {
    if (this.props.match.params.roomTypeId) {
      this.props.setRoomType(this.props.match.params.roomTypeId)
        .then(() => {
          this.fetchInitData();
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.fetchInitData();
    }
  };

  addRoomType = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {

        AppAPI.roomtype.post('',
          {
            'name': credentials.name,
            'base_occupancy': credentials.base_occupancy,
            'max_occupancy': credentials.max_occupancy,
            'min_occupancy': credentials.min_occupancy,
            'infant_occupancy': credentials.infant_occupancy,
            'child_occupancy': credentials.child_occupancy,
            'no_of_extra_bed': credentials.no_of_extra_bed,
            'max_quantity': credentials.max_quantity,

            'sell_below_min_occupancy': credentials.sell_below_min_occupancy,
            'discount_per_guest': credentials.discount_per_guest,
            'discount_type': credentials.discount_type,

          })
          .then((res) => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.setRoomType(res);
                  this.props.history.replace(this.props.location.pathname.replace(/[^/]*$/, `${res.id}/`));
                }, 500);
              },
            );
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  saveRoomType = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.roomtype.patch(this.props.match.params.roomTypeId + '/',
          {
            'name': credentials.name,
            'base_occupancy': credentials.base_occupancy,
            'max_occupancy': credentials.max_occupancy,
            'min_occupancy': credentials.min_occupancy,
            'infant_occupancy': credentials.infant_occupancy,
            'child_occupancy': credentials.child_occupancy,
            'no_of_extra_bed': credentials.no_of_extra_bed,
            'max_quantity': credentials.max_quantity,

            'sell_below_min_occupancy': credentials.sell_below_min_occupancy,
            'discount_per_guest': credentials.discount_per_guest,
            'discount_type': credentials.discount_type,
            'active': credentials.active,
            'featured': credentials.featured,
          })
          .then((roomType) => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.setRoomType(roomType);
                  this.props.history.goBack();
                }, 500);
              },
            );
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  fetchInitData = () => {
    const {roomType} = this.props;
    let initialValues = {
      'base_occupancy': 2,
      'max_occupancy': 2,
      'min_occupancy': 1,
      'infant_occupancy': 0,
      'child_occupancy': 0,
      'no_of_extra_bed': 0,
      'sell_below_min_occupancy': 'false',
      'discount_per_guest': 0,
      'discount_type': 1,
      'max_quantity': 10,
    };
    if (this.props.match.params.roomTypeId) {
      initialValues = {
        'name': roomType.name,
        'base_occupancy': roomType.base_occupancy,
        'max_occupancy': roomType.max_occupancy,
        'min_occupancy': roomType.min_occupancy,
        'infant_occupancy': roomType.infant_occupancy,
        'child_occupancy': roomType.child_occupancy,
        'no_of_extra_bed': roomType.no_of_extra_bed,
        'sell_below_min_occupancy': roomType.sell_below_min_occupancy ? 'true' : 'false',
        'discount_per_guest': roomType.discount_per_guest,
        'discount_type': roomType.discount_type,
        'active': roomType.active,
        'featured': roomType.featured,
        'max_quantity': roomType.max_quantity,
      };
    }
    let formValidation = Yup.object().shape({
      name: Yup.string().min(2, 'Too Short!').required('Required'),
      max_quantity: Yup.number().required('Required'),
    });
    this.setState({
      initialValues,
      formValidation,
      loading: false,
    });
  };

  render = () => {

    const {roomType} = this.props;
    const {loading, error, initialValues, formValidation, resultMsg} = this.state;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    return (

      <div className="room-type-list-manage screen-container ">
        <Helmet>
          <title>Room Types Manage</title>
        </Helmet>
        <PageHeader
          subHeader={true} history={this.props.history}
          title={(this.props.match.params.roomTypeId ? 'Manage' : 'Add') + ' Room Type'}
          description={this.props.match.params.roomTypeId ? `Edit room type ${roomType.name}` : 'Add new room type'}
        />

        <Formik
          onSubmit={(values) => this.props.match.params.roomTypeId ? this.saveRoomType(values) : this.addRoomType(values)}
          validationSchema={formValidation}
          initialValues={initialValues}>
          {(formikProps) => (
            <Form>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Room Type Information
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Input
                          required
                          name='name'
                          label={'Name'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                  <p className={''}>
                    <strong>Name : </strong> {Strings.roomTypeManageName}
                  </p>
                </div>
              </div>

              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Occupancy
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='base_occupancy'
                          label={'Base Occupancy'}
                          placeholder='Select an Option'
                          options={AppUtil.numericSelector(1, 10)}
                          hint={Strings.roomTypeManageBaseOccupancy}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='max_occupancy'
                          label={'Max Occupancy'}
                          placeholder='Select an Option'
                          hint={Strings.roomTypeManageMaxOccupancy}
                          options={AppUtil.numericSelector(parseInt(formikProps.values.base_occupancy), 20)}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='child_occupancy'
                          label={'Child Occupancy'}
                          placeholder='Select an Option'
                          options={AppUtil.numericSelector()}
                          hint={Strings.roomTypeManageChildOccupancy}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='infant_occupancy'
                          label={'Infant Occupancy'}
                          placeholder='Select an Option'
                          options={AppUtil.numericSelector()}
                          hint={Strings.roomTypeManageInfantOccupancy}
                        />
                      </div>
                    </div>

                    <hr/>

                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='min_occupancy'
                          label={'Min Occupancy'}
                          placeholder='Select an Option'
                          hint={Strings.roomTypeManageMinOccupancy}
                          options={AppUtil.numericSelector(1, parseInt(formikProps.values.base_occupancy))}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='no_of_extra_bed'
                          label={'Extra Bed'}
                          placeholder='Select an Option'
                          options={AppUtil.numericSelector()}
                          hint={Strings.roomTypeManageExtraBed}
                        />
                      </div>
                    </div>


                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                  <p>
                    <strong>Occupancy Information: </strong> {Strings.roomOccupancy}
                  </p>
                </div>
              </div>

              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      More Occupancy
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                        <Radio
                          inline
                          label={Strings.roomTypeManageSellBelowMinOccupancy}
                          name='sell_below_min_occupancy'
                          className={'mr-2'}
                          options={[
                            {value: 'true', label: 'Yes'},
                            {value: 'false', label: 'No'},
                          ]}
                        />
                      </div>
                    </div>
                    {formikProps.values.sell_below_min_occupancy === 'true' &&
                     <div className="form-row">
                       <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                         <Select
                           required
                           className={'form-control rounded-right-0'}
                           name='discount_type'
                           label={'Discount type'}
                           placeholder='Select an Option'
                           options={Constants.DISCOUNT_TYPE_INPUTS}
                           hint={Strings.roomTypeManageDiscountType}
                         />
                       </div>
                       <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                         <Input
                           required
                           name='discount_per_guest'
                           label={'Discount Price'}
                           className={'form-control'}
                           hint={Strings.roomTypeManageDiscountPerGuest}
                         />
                       </div>
                     </div>
                    }
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                  <p><strong>Occupancy Options : </strong>{Strings.roomTypeManageSellBelowMinOccupancyHint}</p>
                </div>
              </div>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Booking Engine Distribution Settings
                    </h6>
                  </div>
                  <div className={'border p-2'} id={'primaryAction'}>
                    <div className="form-row">
                      <div className="col-6 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='active'
                          label='Sale Status'
                          text={'Enable room type sale ?'}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='featured'
                          label='Featured'
                          text={'Highlight room type on booking engine?'}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 form-group">
                        <Input
                          required
                          type={'number'}
                          name='max_quantity'
                          label={'Max Quantity'}
                          className={'form-control'}
                          hint={'Maximum rooms allowed in one single booking from booking engine. Recommended value 10'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                  <p>
                    <strong>Sale Status: </strong> {Strings.roomTypeSaleStatus}
                  </p>
                  <p>
                    <strong>Featured Status: </strong> {Strings.roomTypeFeaturedStatus}
                  </p>
                  <p>
                    <strong>Max Quantity: </strong> {Strings.roomTypeMaxQuantity}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 mt-2">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn className={'btn btn-success btn-block btn-lg mt-2'}>
                    <FontAwesomeIcon
                      className={'white-cl mr-2'}
                      icon={this.props.match.params.roomTypeId ? faSave : faPlus} size={'sm'}
                    /> {this.props.match.params.roomTypeId ? 'Save' : 'Add'}
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>


      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      initialValues: {},
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(RoomTypeManage);
