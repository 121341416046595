/**
 *
 * Property Room Inventory Links
 * Houses sections related to property inventory
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link, NavLink, Route, Switch} from 'react-router-dom';
// Consts and Libs
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PageHeader from '../../../components/dashboard/PageHeader';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import RoomTypeDetails from './RoomTypeDetails';
import RoomTypeItemsList from './RoomTypeItemsList';
import RoomList from './room/RoomListSettings';
import RoomTypeItemsManage from './RoomTypeItemsManage';
import RoomTypeItemsSettings from './RoomTypeItemsSettings';
import RoomTypePackagesList from './roomTypePackages/RoomTypePackagesList';
import RoomTypePackagesManage from './roomTypePackages/RoomTypePackagesManage';
import RoomTypePackagesSettings from './roomTypePackages/RoomTypePackagesSettings';
import RoomManage from './room/RoomManage';
import RoomMultipleAdd from './room/RoomMultipleAdd';
import RoomDetailsSettings from './room/RoomDetailsSettings';
import RoomHistorySettings from './room/RoomHistorySettings';
import RoomTypeChannelManagerOverview from './RoomTypeChannelManagerOverview';
import RoomTypeBookingEngine from './bookingEngine';
import RoomTypeManage from './RoomTypeManage';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  roomType: state.roomType.roomType,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class RoomTypeInventory extends Component {
  static componentName = 'RoomTypeInventory';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    if (this.props.match.params.roomTypeId) {
      this.setState({
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };


  render = () => {

    const {loading, error} = this.state;
    const {roomType} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    let baseUrl = '/settings/inventory/room-type';
    let absBaseUrl = `${baseUrl}/${roomType.id}`;
    const menu = [
      {
        title: 'Room Type Details',
        link: `${absBaseUrl}/`,
        isExact: true,
      },
      {
        title: 'Room(s)',
        link: `${absBaseUrl}/room/`,
      },
      {
        title: 'Package(s)',
        link: `${absBaseUrl}/package/`,
      },
      {
        title: 'Booking Engine',
        link: `${absBaseUrl}/booking-engine/`,
      },
      {
        title: 'Channel Manager',
        link: `${absBaseUrl}/channel-manager/`,
      },
    ];

    let menuItems = [];
    menu.map((item) => {
      const {title, link, isExact} = item;
      return menuItems.push(
        <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
          <NavLink
            to={{
              pathname: link,
              state: {fromDashboard: true},
            }}
            exact={isExact}
            className="nav-link rounded-0"
            activeClassName="selected">
            {title}
          </NavLink>
        </li>,
      );
    });

    return (
      <div className="room-type-details screen-container ">
        <Helmet>
          <title>Room Types Manage</title>
        </Helmet>


        {baseUrl &&
         <Link to={baseUrl} className={'btn btn-outline-dark mr-2 btn-sm mt-2'}>
           <FontAwesomeIcon icon={faArrowLeft} size={'sm'}/> {'Room Type List'}
         </Link>
        }

        <PageHeader
          subHeader={true} history={this.props.history} title={'Room Type Details'}
          description={`Settings & Configuration for room type : ${roomType.name}`}
        />


        <ul className="nav nav-tabs mb-3">
          {menuItems}
        </ul>

        <Switch>
          <Route path={`${baseUrl}/:roomTypeId`} exact component={RoomTypeDetails}/>
          <Route path={`${baseUrl}/:roomTypeId/edit`} exact component={RoomTypeManage}/>

          {/* Room Type Settings */}
          <Route path={`${baseUrl}/:roomTypeId/channel-manager/`} exact component={RoomTypeChannelManagerOverview}/>
          <Route path={`${baseUrl}/:roomTypeId/booking-engine/`} component={(RoomTypeBookingEngine)}/>

          {/* Room Type - Room Settings */}
          <Route path={`${baseUrl}/:roomTypeId/room/`} exact component={(RoomList)}/>
          <Route path={`${baseUrl}/:roomTypeId/room/new`} exact component={RoomManage}/>
          <Route path={`${baseUrl}/:roomTypeId/room/new-multiple`} exact component={RoomMultipleAdd}/>
          <Route path={`${baseUrl}/:roomTypeId/room/:roomId`} exact component={RoomDetailsSettings}/>
          <Route path={`${baseUrl}/:roomTypeId/room/:roomId/edit`} exact component={RoomManage}/>
          <Route path={`${baseUrl}/:roomTypeId/room/:roomId/maintenance`} exact component={RoomHistorySettings}/>

          {/* Room Type - Item (NOT USED) Settings */}
          <Route path={`${baseUrl}/:roomTypeId/item`} exact component={(RoomTypeItemsList)}/>
          <Route path={`${baseUrl}/:roomTypeId/item/new`} exact component={(RoomTypeItemsManage)}/>
          <Route path={`${baseUrl}/:roomTypeId/item/:roomTypeItemId`} exact component={(RoomTypeItemsSettings)}/>
          <Route path={`${baseUrl}/:roomTypeId/item/:roomTypeItemId/edit`} exact component={(RoomTypeItemsManage)}/>

          {/* Room Type - Package Settings */}
          <Route path={`${baseUrl}/:roomTypeId/package/`} exact component={(RoomTypePackagesList)}/>
          <Route path={`${baseUrl}/:roomTypeId/package/new`} exact component={(RoomTypePackagesManage)}/>
          <Route
            path={`${baseUrl}/:roomTypeId/package/:roomTypePackageId`} exact
            component={(RoomTypePackagesSettings)}
          />
          <Route
            path={`${baseUrl}/:roomTypeId/package/:roomTypePackageId/edit`} exact
            component={(RoomTypePackagesManage)}
          />
        </Switch>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomTypeInventory);