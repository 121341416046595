/**
 * New Version Check
 */
import React, {Component} from 'react';

// Consts and Libs
import {AppConfig} from '../../constants';
import WEBUTIL from '../../lib/webUtils';

import Moment from 'moment';

/* Component ==================================================================== */
class UpdateCheck extends Component {
  static componentName = 'UpdateCheck';

  componentDidMount() {
    if (AppConfig.dashboardHostname && !AppConfig.DEV){
      fetch(`${AppConfig.dashboardHostname}/version.json?${AppConfig.version ? AppConfig.version : ''}&${Moment().format('DHH')}`, {
        method: 'get'
      })
        .then(async (rawRes) => {
          if (rawRes.status === 200){
            const textRes = await rawRes.text();
            if (textRes){
              const versionStorage = localStorage.getItem(AppConfig.localStorageKeys.RELEASE_VERSION);
              if ( versionStorage !== textRes && textRes !== AppConfig.version) {
                localStorage.setItem(AppConfig.localStorageKeys.RELEASE_VERSION, textRes);
                WEBUTIL.clearBrowserCache();
              }
            }
          }
        })
        .catch(() => ({}));
      // catch to ignore the error.
    }
  }

  render = () => {
    const {isLatestVersion} = this.state;
    return (
      <React.Fragment>
        {isLatestVersion && (
          <div className="header sticky-top mb-0 version-warning">
            <div className="alert alert-success alert-dismissible fade show mb-0" role="alert">
              <strong>Update: </strong> New software update is available, it's just a refresh away.
              <button className={'btn btn-link my-0 py-0'} onClick={e => {
                e.preventDefault();
                WEBUTIL.clearBrowserCache();
              }}
              >Click to Update</button>
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        )}
      </React.Fragment>

    );
  };
  constructor(props) {
    super(props);

    this.state = {
      isLatestVersion: false,
    };
  }
}

/* Export Component ==================================================================== */
export default UpdateCheck;
