import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Route, Switch} from 'react-router-dom';
// Components
import PaymentList from './PaymentList';
import PageHeader from '../../components/dashboard/PageHeader';


class PaymentListIndex extends Component {
  static propTypes = {
    match: PropTypes.object,
    hideHeader: PropTypes.bool,
    baseUrl: PropTypes.string,
    guestId: PropTypes.number,
    corporateId: PropTypes.number,
    pos: PropTypes.object,
    event: PropTypes.object,
  };


  static defaultProps = {
    baseUrl: ''
  };

  render = () => {
    const {baseUrl, hideHeader, guestId, corporateId, pos, event} = this.props;

    return (
      <div>
        {!hideHeader &&
         <PageHeader
           history={this.props.history} title={'Payment\'s'}
           description={'All payments made in the property. Choose category to filter payments.'}
         />
        }
        <div className={'row'}>
          <div className={'col-12'}>
            <Switch>
              <Route
                path={`${baseUrl}/payment/list/`} exact
                render={(props) =>
                  <PaymentList
                    guestId={guestId} corporateId={corporateId}
                    event={event} pos={pos}
                    {...props}
                  />
                }
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentListIndex;