import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Form, Formik} from 'formik';
import Helmet from 'react-helmet';
import * as Yup from 'yup';

// Consts and Libs
import AppAPI from '../../lib/api';
import {Strings} from '../../constants';
import {Input, SubmitBtn} from 'react-formik-ui';

// Components
import {Alerts} from '../../components/ui';
import Error from '../../components/general/Error';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faEnvelopeOpen, faLongArrowAltRight, faSpinner} from '@fortawesome/free-solid-svg-icons';

// What data from the store shall we send to the component?
const mapStateToProps = () => ({});

// Any actions to map to the component?
const mapDispatchToProps = {};

class ResetPassword extends Component {

  action = (credentials) => {
    if (credentials) {
      this.setState({loading: true});
      AppAPI.account.post('password/reset/',
        {
          'email': credentials.email
        })
        .then((res) => {
          this.setState({
            resultMsg: {success: res.detail}, loading: false, resetRequestComplete: true,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}, loading: false});
        });
    }
  };


  render() {

    const {loading, error, resultMsg, resetRequestComplete} = this.state;

    if (error) return <Error full={true} text={error}/>;

    const formValidation = Yup.object().shape({
      email: Yup.string().email('Invalid email').required('Enter your email address.'),
    });

    const initialValues = {
      email: '',
    };

    return (
      <div className="login-container">
        <Helmet>
          <title>Reset Password | Renzo</title>
        </Helmet>

        <div className="align-items-center">
          <div className="row">
            <div className={'col-12 col-md-8 col-lg-6 col-xl-5 mx-auto'}>
              <div className={'border rounded p-4 my-3'}>
                <h4>Recover Account</h4>
                <hr/>
                <Formik
                  initialValues={initialValues}
                  validationSchema={formValidation}
                  onSubmit={values => this.action(values)}
                >
                  {() => (
                    resetRequestComplete ?
                      <React.Fragment>
                        <h3 className={'text-center my-3'}>
                          <FontAwesomeIcon icon={faCheckCircle} className={'mr-2 green-cl'}/> Recovery Initiated
                        </h3>
                        <p>{Strings.authPasswordResetComplete}</p>
                      </React.Fragment>
                      :
                      <Form className={'form-group'}>
                        <p>{Strings.authPasswordReset}</p>
                        <Alerts
                          status={resultMsg.status}
                          success={resultMsg.success}
                          error={resultMsg.error}
                        />
                        <div className="form-row">
                          <div className="col-12 form-group">
                            <Input
                              name="email"
                              label={'Email Address'}
                              className={'form-control'}
                            />
                          </div>
                        </div>

                        <SubmitBtn className={'btn btn-success btn-lg btn-block my-3'}>
                          <FontAwesomeIcon className={'mr-2'} icon={faEnvelopeOpen}/>
                          Send Email
                          <FontAwesomeIcon
                            className={'ml-3'}
                            spin={loading}
                            icon={loading ? faSpinner : faLongArrowAltRight}
                          />
                        </SubmitBtn>
                      </Form>
                  )}
                </Formik>
                <p className="mb-1 text-center">
                  <Link className={'text-muted'} to="/auth/login/">Try logging in?</Link>
                </p>
              </div>
              <p className={'text-secondary'}>By proceeding, you agree to the <a href="https://renzo.in/policies/terms">Terms
                of Service </a> and <a href={'https://renzo.in/policies/privacy'}> Privacy Notice</a>.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resetRequestComplete: false,
      resultMsg: {
        status: '',
        success: '',
        error: ''
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
