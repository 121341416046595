/**
 * User Actions
 *
 */

import * as Sentry from '@sentry/react';

// Consts and Libs
import AppAPI from '../../lib/api';


/**
 * Set user
 */

export function setUser () {
  return (dispatch) => new Promise(async (resolve, reject) => {
    return AppAPI.account.get()
      .then((userData) => {
        Sentry.setUser({name: userData.display_name, hash: userData.hash});
        dispatch({
          type: 'USER_REPLACE',
          data: userData,
        });
        return resolve(userData);
      }).catch(err => reject(err));
  });
}

/**
 * Login to API and receive Token
 */
export function login(credentials, freshLogin) {

  return (dispatch) => new Promise(async (resolve, reject) => {

    // Force logout, before logging in
    if (freshLogin && AppAPI.deleteToken) await AppAPI.deleteToken();

    // Get a new token from API
    return AppAPI.getToken(credentials)
      .then((token) => {
        const userData = token.user;
        dispatch({
          type: 'USER_REPLACE',
          data: userData,
        });
        Sentry.setUser({name: userData.display_name, hash: userData.hash});
        return resolve(userData);
      }).catch(err => reject(err));
  });
}

/**
 * Logout
 */
export function logout() {
  AppAPI.deleteToken();
  return dispatch => dispatch({
    type: 'USER_REPLACE',
    data: {},
  });
}
