/**
 *
 * App ExpenseAnalytics
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {DateRange} from 'react-date-range';
import {addDays} from 'date-fns';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import ExpenseListView from '../expense/ExpenseListView';

import AppAPI from '../../lib/api';
import {BarChart, XAxis, YAxis, Bar, CartesianGrid, ResponsiveContainer, Tooltip, Legend} from 'recharts';
import Colors from '../../constants/colors';

import Moment from 'moment';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class ExpenseAnalytics extends Component {
  static componentName = 'ExpenseAnalytics';

  componentDidMount = () => {
    this.loadGraph();
  };

  loadGraph = (ranges) => {
    this.setState({
      loading_inner: true
    });

    let start = Moment(this.state.selectionRange.selection.startDate).format('YYYY-MM-DD');
    let end = Moment(this.state.selectionRange.selection.endDate).format('YYYY-MM-DD');

    if (ranges) {
      start = Moment(ranges.selection.startDate).format('YYYY-MM-DD');
      end = Moment(ranges.selection.endDate).format('YYYY-MM-DD');
    }

    AppAPI.analyticsapi.get('expense/?start=' + start + '&end=' + end)
      .then((res) => {
        this.setState({
          loading_inner: false,
          loading: false,
          data: res,
          expense: res.expense
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading_inner: false,
          loading: false,
          error
        });
      });
  };

  handleSelect = (ranges) => {
    let end = Moment(this.state.selectionRange.selection.endDate).format('YYYY-MM-DD');
    this.setState({selectionRange: ranges});
    if (end !== Moment(ranges.selection.endDate).format('YYYY-MM-DD')) {
      this.loadGraph(ranges);
    }
  };

  render = () => {
    const {loading_inner, loading, error, selectionRange, expense} = this.state;
    const {property} = this.props;

    if (!property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="dashboard screen-container">
        <Helmet>
          <title>Expense Analytics</title>
        </Helmet>

        <h3>Expense</h3>
        <p>General information</p>

        <div className="spacer-20"/>

        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <DateRange
              className={'mx-auto'}
              ranges={[selectionRange.selection]}
              onChange={this.handleSelect}
              moveRangeOnFirstSelection={false}
              direction="horizontal"
              maxDate={addDays(new Date(), 100)}
            />
          </div>
          <div className={'col-lg-8 col-md-8 col-sm-12'}>
            {
              loading_inner ?
                <Loading/>
                :
                <div>
                  <div className={'section-header-wrap'}>
                    <h6 className={'section-header'}>Expense Order Count</h6>
                  </div>
                  <div className={'border p-2'}>
                    <ResponsiveContainer height={400}>
                      <BarChart
                        width={600} height={400} data={expense.expense_data}
                        margin={{top: 20, right: 30, left: 20, bottom: 5}}>
                        <CartesianGrid/>
                        <XAxis dataKey="date"/>
                        <YAxis/>
                        <Tooltip/>
                        <Legend/>
                        <Bar barGap={1} dataKey="paid_count" stackId="a" fill={Colors.green}/>
                        <Bar barGap={1} dataKey="partpaid_count" stackId="a" fill={Colors.orange}/>
                        <Bar barGap={1} dataKey="unpaid_count" stackId="a" fill={Colors.red}/>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
            }

            <div className={'spacer-20'}/>

            {
              expense.expenses && <div>
                {expense.expenses.map((data, i) => (
                  <ExpenseListView
                    data={data}
                    key={i}
                    history={this.props.history}
                  />
                ))}

              </div>
            }

          </div>
        </div>

        <div className={'spacer-20'}/>

      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loading_inner: true,
      error: null,
      selectionRange: {
        selection: {
          startDate: addDays(new Date(), -3),
          endDate: addDays(new Date(), 3),
          key: 'selection',
          direction: 'horizontal'
        }
      }
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseAnalytics);
