/**
 *
 * Property Notification Config BCC List
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../../lib/api';
import Strings from '../../../constants/strings';

// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import NotificationConfigManage from './models/NotificationConfigManage';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelopeOpen} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class NotificationConfigBCCList extends Component {
  static componentName = 'NotificationConfigBCCList';

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    AppAPI.property.get(`${this.props.property.id}/notification/config-bcc/`)
      .then((res) => {
        this.setState({
          loading: false,
          emailConfigBcc: res.email_config_bcc,
          textConfigBcc: res.text_config_bcc,
          emailTemplates: res.templates,
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: true,
          resultMsg: {error},
        });
      });
  }

  renderRow = (data, key) => {
    const {emailConfigBcc, textConfigBcc} = this.state;
    const emailConfig = (!_.isEmpty(emailConfigBcc) && !_.isEmpty(emailConfigBcc[data.key]) && !_.isEmpty(emailConfigBcc[data.key].email_address))  ? emailConfigBcc[data.key].email_address : null;
    const textConfig = (!_.isEmpty(textConfigBcc) && !_.isEmpty(textConfigBcc[data.key]) && !_.isEmpty(textConfigBcc[data.key].phone_numbers))  ? textConfigBcc[data.key].phone_numbers : null;
    return (
      <tr key={key} className={'tr-align-middle'}>
        <td className={'data-table-cell-xl'}>
          <p className={'mb-0'}><strong>{data.name}</strong></p>
          <p className={'text-muted mb-0 small'}>{data.description}</p>
        </td>
        <td className={'data-table-cell-lg text-center'}>
          <div className={'border p-3 grey-light-bg rounded small'}>
            {data.config  ? (emailConfig ? emailConfig.join(', ') : '....') : 'Disabled'}
          </div>
          <button
            disabled={!data.config}
            className={'btn btn-link m-0 p-0 btn-sm'}
            onClick={()=>this.setState({
              selectNotificationType: 'email',
              showNotificationConfigManage: true,
              selectNotificationData: emailConfig,
              selectNotificationTemplateData: data

            })}
          >Edit</button>
        </td>
        <td className={'data-table-cell-lg text-center'}>
          <div className={'border p-3 grey-light-bg rounded small'}>
            {data.config  ? (textConfig ? textConfig.join(', ') : '....') : 'Disabled'}
          </div>
          <button
            disabled={!data.config}
            className={'btn btn-link m-0 p-0 btn-sm'}
            onClick={()=>this.setState({
              selectNotificationType: 'phone',
              showNotificationConfigManage: true,
              selectNotificationData: textConfig,
              selectNotificationTemplateData: data

            })}
          >Edit</button>
        </td>
      </tr>
    );
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={5}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faEnvelopeOpen}/> No Content </h4>
            <p>{Strings.propertyNotificationConfigBCCListEmptyText}</p>
          </div>
        </td>
      </tr>
    );
  };

  render = () => {
    const {loading, error, emailTemplates} = this.state;

    if (!this.props.property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="channel-manager-settings screen-container">
        <Helmet>
          <title>Notification Config : BCC List</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={'Notifications Config'}
          description={'Configure the BCC address list for notifications.'}
        />

        <div className="row">
          <div className="col-12 col-sm-12">
            <table className={'table border'}>
              <thead>
                <tr>
                  <th className={'data-table-cell-xl text-left'}>Notification Template</th>
                  <th className={'data-table-cell-lg'}>Email</th>
                  <th className={'data-table-cell-lg'}>Text</th>
                </tr>
              </thead>
              <tbody>
                {_.isEmpty(emailTemplates) ?
                  this.emptyView()
                  :
                  <React.Fragment>
                    {emailTemplates.map((data, key) => this.renderRow(data, key))}
                  </React.Fragment>
                }
              </tbody>
            </table>
            <p className={'text-muted'}>{Strings.propertyNotificationDescription}</p>
          </div>
        </div>

        <NotificationConfigManage
          property={this.props.property}
          updateResponse={()=>this.fetchInitData()}
          notificationData={this.state.selectNotificationData}
          notificationType={this.state.selectNotificationType}
          notificationTemplateData={this.state.selectNotificationTemplateData}
          show={this.state.showNotificationConfigManage}
          onHide={()=>this.setState({showNotificationConfigManage: false})}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      selectNotificationData: null,
      selectNotificationType: 'email',
      selectNotificationTemplateData: null,
      showNotificationConfigManage: false,
      emailTemplates: [],
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationConfigBCCList);
