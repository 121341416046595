/**
 * RoomListView
 *  View for Room Listing
 *
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class RoomListView extends Component {
  static componentName = 'RoomListView';

  static propTypes = {
    data: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    settings: PropTypes.bool,
  };

  action = (data) => {
    if (this.props.settings) {
      this.props.history.push('/settings/room/room/' + data.id);
    } else {
      this.props.history.push('/house-keeping/room/' + data.hash);
    }
  };

  render = () => {
    const data = this.props.data;
    return (
      <div
        role="presentation"
        onKeyPress={this.handleKeyPress}
        onClick={() => this.action(data)}
        className="list-group-item list-group-item-action flex-column align-items-start a-class">
        <div className="d-flex w-100 justify-content-between">
          <div>
            <h5 className="mb-1">{data.name}</h5>
            <p className="mb-0 small">
              {data.attributes && <React.Fragment>{data.attributes.room_type_name} | </React.Fragment>}
              {data.floor_level && <React.Fragment>Floor : {data.floor_level} |  </React.Fragment>}
              <span
                className={'badge ' + (data.availability_status === -1 ? 'badge-danger' : data.availability_status === 1 ? 'badge-success' : 'badge-info')}>
                {data.availability_status_display}
              </span>
            </p>
          </div>
          <div className={'text-right'}>
            <span
              className={'badge ' + (data.service_status === 1 ? 'badge-success' : 'badge-danger')}>
              {data.service_status_display}
            </span> <br/>
            <span className={'badge ' + (data.maintenance_status === 1 ? 'badge-success' : 'badge-danger')}>
              {data.maintenance_status_display}
            </span>
          </div>
        </div>
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(RoomListView);

