/**
 * Booking Reducer
 *
 */

// Set initial state
const initialState = {
  booking: {},
  selectiondata: null,
  lookupresponse: {},
  bookingAmendmentRequest: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
  case 'SET_BOOKING': {
    let booking = [];
    if (action.data && typeof action.data === 'object') {
      booking = action.data;
    }

    return {
      ...state,
      booking,
    };
  }

  case 'SET_BOOKING_AMENDMENT': {
    let bookingAmendmentRequest = [];
    if (action.data && typeof action.data === 'object') {
      bookingAmendmentRequest = action.data;
    }

    return {
      ...state,
      bookingAmendmentRequest,
    };
  }

  case 'SET_LOOKUP': {
    let lookupresponse = null;
    if (action.data && typeof action.data === 'object') {
      lookupresponse = action.data;
    }
    return {
      ...state,
      lookupresponse,
    };
  }

  case 'SET_LOOKUP_SELECTION': {
    let selectiondata = [];
    if (action.data && typeof action.data === 'object') {
      selectiondata = action.data;
    }
    return {
      ...state,
      selectiondata,
    };
  }

  default:
    return state;
  }
};
