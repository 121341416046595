/**
 *
 * Calender Availability
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import {faChevronLeft, faChevronRight, faSync} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import EventAvailabilityDetails from './models/EventAvailabilityDetails';
import ReportsDateSelect from './ReportsDateSelect';

import Moment from 'moment';
import {Constants} from '../../constants';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  event: state.event.event,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class EventAvailabilityChart extends Component {
  static componentName = 'EventAvailabilityChart';

  componentDidMount = () => {
    if (this.props.match.params.start_date || this.props.match.params.end_date) {
      this.setState({
        start_date: Moment(String(this.props.match.params.start_date)),
        end_date: Moment(String(this.props.match.params.end_date)),
        loading: false,
      }, () => this.fetchInitData());
    } else {
      this.fetchInitData();
    }
  };


  navigate = (type) => {
    let start_date = this.state.end_date, end_date = this.state.start_date;
    if (type === 'next') {
      start_date = this.state.end_date;
      end_date = Moment(this.state.end_date).add(AppUtil.diffDateTime(this.state.start_date, this.state.end_date), 'days');
    } else {
      start_date = Moment(this.state.start_date).subtract(AppUtil.diffDateTime(this.state.start_date, this.state.end_date), 'days');
      end_date = this.state.start_date;
    }
    this.props.history.push(`/event/${this.props.event.hash}/availability-chart/${Moment(start_date).format('YYYY-MM-DD')}/to/${Moment(end_date).format('YYYY-MM-DD')}`);
  };


  fetchInitData = () => {
    let payload = '';

    this.setState({
      loading_inner: true,
      error: false,
    });
    if (this.props.event){
      payload += `${this.props.event.id}/`;
    }

    payload += Moment(this.state.start_date).format('YYYY-MM-DD') + '/to/' + Moment(this.state.end_date).format('YYYY-MM-DD') + '/';
    AppAPI.availabilityapi
      .get(`availability-chart/${payload}`)
      .then(res => {
        if (res) {
          this.setState({
            start_date: Moment(res.start_date),
            end_date: Moment(res.end_date),
            loading: false,
          });
          this.processAvailabilityChartData(res);
        } else {
          this.setState({
            loading: false,
            error: true,
          });
        }
      })
      .catch(err => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: error,
          resultMsg: {error},
        });
      });
  };


  processAvailabilityChartData = res => {
    let tableHead = [],
      tableData = [],
      rowData = {};

    if (res.tickets && res.tickets.length) {
      // eslint-disable-next-line array-callback-return
      res.tickets.map(data => {
        rowData[data.ticket_id] = [];
        rowData[data.ticket_id].push({'print': data.name, 'data': data});
      });
      tableHead.push('');


      if (res.availability_data && res.availability_data.length) {
        // eslint-disable-next-line array-callback-return
        res.availability_data.map(data => {
          tableHead.push({
            'date': AppUtil.formatDateTime(data.date, 'datef'),
            'day': AppUtil.formatDateTime(data.date, 'day'),
            'future': data.future,
          });
          if (data.data && data.data.length) {
            // eslint-disable-next-line array-callback-return
            data.data.map(data2 => {
              rowData[data2.ticket_id].push({
                'print': data2.future ? data2.available : data2.complete,
                'data': data2,
              });
            });
          }
        });
      }

      if (rowData) {
        Object.keys(rowData).forEach((i) => {
          tableData.push(rowData[i]);
        });
      }
    }
    this.setState({
      loading: false,
      loading_inner: false,
      start_date: res.start_date,
      end_date: res.end_date,
      tickets: res.tickets,
      tableData,
      tableHead,
      error: false,
    });
  };

  setData = (start_date, end_date) => {
    this.props.history.push(`/event/${this.props.event.hash}/availability-chart/${Moment(start_date).format('YYYY-MM-DD')}/to/${Moment(end_date).format('YYYY-MM-DD')}`);

  };

  render = () => {
    const {loading, error, tableHead, tableData, loading_inner, start_date, end_date, tickets} = this.state;
    const {property, event} = this.props;

    if (!property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="dashboard screen-container">
        <Helmet>
          <title>Availability Calendar</title>
        </Helmet>
        <div className={'mb-4 text-center'}>
          <div className="btn-group">
            <button
              onClick={() => this.navigate('previous')}
              type="button" className={'btn btn-outline-primary'}>
              <FontAwesomeIcon icon={faChevronLeft} className={'mx-1'}/> Previous
            </button>
            <button
              type="button" onClick={() => this.fetchInitData()}
              className="btn btn-outline-success">
              <FontAwesomeIcon icon={faSync} size={'sm'} className={'mx-2'}/>
            </button>
            <button
              type="button" onClick={() => this.setState({showDateSelect: true})}
              className={'btn btn-outline-success'}
            >
              {AppUtil.formatDateTime(start_date, 'datef')} to {AppUtil.formatDateTime(end_date, 'datef')}
            </button>
            <button
              type="button" onClick={() => this.fetchInitData()}
              className="btn btn-outline-success">
              <FontAwesomeIcon icon={faSync} size={'sm'} className={'mx-2'}/>
            </button>
            <button
              onClick={() => this.navigate('next')}
              type="button" className={'btn btn-outline-primary'}>
              Next <FontAwesomeIcon icon={faChevronRight} className={'mx-1'}/>
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            {loading_inner ?
              <Loading/>
              :
              <React.Fragment>
                {tickets && tickets.length > 0 ?
                  <div className={'table-responsive'}>
                    <table className={'table table-bordered table-sm white-br text-center'}>
                      <thead className="">
                        <tr>
                          {tableHead.map((data, i) => (
                            <th
                              scope={'col'}
                              className={(i === 0 ? 'data-table-cell-lg bg-dark text-light' : 'data-table-cell ' + (data.future ? ' bg-dark text-light' : ' bg-warning'))}
                              key={i}>
                              {data.date}<br/>
                              <small>{data.day}</small>
                            </th>
                          ))}
                        </tr>
                        <tr>
                          {tableHead.map((data, i) => (
                            <th
                              scope={'col'}
                              className={(i === 0 ? 'data-table-cell-lg bg-dark text-light' : 'data-table-cell ' + (data.future ? ' bg-dark text-light' : ' bg-warning'))}
                              key={i}>
                              {(i === 0 ? 'Ticket' : (data.future ? ' Availability' : ' Occupancy'))}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((data, i) => (
                          <tr key={i}>
                            {data.map((data2, k) => (
                              <td
                                key={k}
                                onClick={() => (k !== 0 ? this.setState({
                                  showAvailabilityDetails: true,
                                  availabilityDetails: data2.data,
                                }) : null)}
                                className={(k === 0 ? ' bg-dark text-light ' : ' a-class ' + AppUtil.occupancyStatusColor(data2.data.future ? data2.data.availability_percent : data2.data.occupancy_percent)) + ' align-middle'}>
                                {data2.print}
                                {data2.data && k !== 0 &&
                                 <div className={'flex-col'}>
                                   {data2.data.channel_manager_restriction === true &&
                                    <React.Fragment><span className={'badge badge-danger'}>C</span></React.Fragment>
                                   }
                                   {data2.data.external_booking_engine_restriction === true &&
                                    <React.Fragment><span className={'badge badge-danger'}>E</span></React.Fragment>
                                   }
                                   {data2.data.internal_booking_engine_restriction === true &&
                                    <React.Fragment><span className={'badge badge-danger'}>I</span></React.Fragment>
                                   }
                                 </div>
                                }
                                {data2.data && k === 0 &&
                                 <React.Fragment>
                                   <br/>
                                   <small>
                                     {data2.data.data.ticket_quantity_type_display}
                                     {data2.data.data.ticket_quantity_type === Constants.EVENT_TICKET_TYPE.LIMITED && <React.Fragment> ({data2.data.data.ticket_quantity})</React.Fragment> }
                                   </small>
                                 </React.Fragment>
                                }
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  :
                  <Error full={false} text={'No Data available'}/>
                }
              </React.Fragment>
            }

          </div>
        </div>
        <EventAvailabilityDetails
          show={this.state.showAvailabilityDetails}
          onHide={() => this.setState({showAvailabilityDetails: false, availabilityDetails: null})}
          property={this.props.property}
          availabilityDetails={this.state.availabilityDetails}
        />
        <ReportsDateSelect
          minDate={Moment(event.event_start_date)}
          maxDate={Moment(event.event_end_date)}
          show={this.state.showDateSelect}
          onHide={() => this.setState({showDateSelect: false})}
          action={this.setData}
        />
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loading_inner: true,
      error: null,
      tableHead: [],
      tableTitle: [],
      tableData: [],
      tickets: [],
      start_date: Moment().startOf('day'),
      end_date: Moment().add(7, 'days'),
      showAvailabilityDetails: false,
      availabilityDetails: null,
      showAvailabilityManage: false,
      showDateSelect: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAvailabilityChart);
