import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Form, Formik} from 'formik';
import Helmet from 'react-helmet';
import * as Yup from 'yup';

// Consts and Libs
import AppAPI from '../../lib/api';
import {Locations} from '../../constants';
import {Input, SubmitBtn} from 'react-formik-ui';

// Components
import {Alerts} from '../../components/ui';
import Error from '../../components/general/Error';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLongArrowAltRight, faSpinner} from '@fortawesome/free-solid-svg-icons';

// Actions
import * as UserActions from '../../redux/user/actions';

// What data from the store shall we send to the component?
const mapStateToProps = () => ({});

// Any actions to map to the component?
const mapDispatchToProps = {
  login: UserActions.login
};

class Login extends Component {

  login = (credentials) => {
    if (credentials) {
      this.setState({loading: true});
      this.props.login({
        username: credentials.email,
        password: credentials.password
      }, true).then((userData) => {
        this.setState({
          resultMsg: {success: 'Awesome, you\'re now logged in!'}
        }, () => {
          setTimeout(() => {
            if (userData.force_reset_password) {
              this.props.history.push(Locations.ACCOUNT_PASSWORD_UPDATE);
            } else {
              this.props.history.push('/dashboard');
            }
          }, 1000);
        });
      }).catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({resultMsg: {error}, loading: false});
      });
    }
  };


  render() {

    const {loading, error, resultMsg} = this.state;

    if (error) return <Error full={true} text={error}/>;

    const formValidation = Yup.object().shape({
      email: Yup.string().email('Invalid email').required('Enter your email address.'),
      password: Yup.string().required('Enter your password'),
    });

    const initialValues = {
      email: '',
      password: ''
    };

    return (
      <div className="login-container">
        <Helmet>
          <title>Login in to Renzo</title>
        </Helmet>

        <div className="align-items-center">
          <div className="row">
            <div className={'col-12 col-md-8 col-lg-6 col-xl-5 mx-auto'}>
              <div className={'border rounded p-4 my-3'}>
                <h4 className="">Sign in to continue!</h4>
                <hr/>
                <Formik
                  initialValues={initialValues}
                  validationSchema={formValidation}
                  onSubmit={values => this.login(values)}
                >
                  {() => (
                    <Form className={'form-group'}>
                      <Alerts
                        status={resultMsg.status}
                        success={resultMsg.success}
                        error={resultMsg.error}
                      />
                      <div className="form-row">
                        <div className="col-12 form-group">
                          <Input
                            name="email"
                            label={'Email Address'}
                            className={'form-control'}
                          />
                        </div>
                        <div className="col-12 form-group">
                          <Input
                            name="password"
                            type="password"
                            label={'Password'}
                            className={'form-control'}
                          />
                        </div>
                      </div>
                      <SubmitBtn disabled={loading} className={'btn btn-success btn-lg btn-block my-3'}>
                        Login
                        <FontAwesomeIcon
                          className={'ml-3'}
                          spin={loading}
                          icon={loading ? faSpinner : faLongArrowAltRight}
                        />
                      </SubmitBtn>
                      <p className="mb-1 text-center">
                        <Link className={'text-muted'} to="/auth/reset-password/">Trouble logging in or Lost your
                          password?</Link>
                      </p>
                    </Form>
                  )}
                </Formik>
              </div>
              <p className={'text-secondary'}>By proceeding, you agree to the <a href="https://renzo.in/policies/terms">Terms
                of Service </a> and <a href={'https://renzo.in/policies/privacy'}> Privacy Notice</a>.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: ''
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
