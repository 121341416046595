/**
 * PGatewayAction.js
 *  Payment Gateway actions
 *
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../../../lib/api';
import AppUtil from '../../../../lib/util';

// Components
import {Alerts} from '../../../../components/ui';
import PaymentLink from '../../Components/PaymentLink';
import {confirmAlert} from '../../../../components/general/ConfirmAlert';

import {faSync} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

/* Component ==================================================================== */
class PGatewayAction extends Component {
  static componentName = 'PGatewayAction';

  static propTypes = {
    pgateway: PropTypes.object.isRequired,
    action: PropTypes.func,
  };

  static defaultProps = {
  }

  statusCheck = (pgateway, type = 1) => {
    this.setState({resultMsg: {status: 'One moment...'}},
      () => {
        AppAPI.pgateway.post(`${pgateway}/status`, {
          status_type: type
        })
          .then((res) => {
            this.setState({resultMsg: {success: 'Success'}});
            if (this.props.action){
              this.props.action(res);
            }
            setTimeout(() => this.setState({resultMsg: {success: ''}}), 500);
          })
          .catch((err) => {
            this.setState({resultMsg: {error: AppAPI.handleError(err)}, loading: false});
          });
      });
  };

  render = () => {
    const {pgateway} = this.props;
    const {resultMsg} = this.state;

    return (
      <React.Fragment>
        <div>
          <PaymentLink paymentLink={pgateway.resources_link}/>

          {pgateway.refund_time_limit &&
          <p className={'small text-muted'}>
            Max time for refund {AppUtil.formatDateTime(pgateway.refund_time_limit)}
          </p>
          }

          <div className={'mb-3'}>
            <p><strong>Payment Transaction Details</strong></p>
            <hr/>
            <table className={'table table-borderless table-sm'}>
              <tbody>
                {!_.isEmpty(pgateway.pgateway_lock) &&
              <tr>
                <td className={'w-50 text-muted'}>Status</td>
                <td>
                  <span className={'badge badge-success'}>Transaction Active</span>
                  <p className={'small mb-0 text-muted'}>User is attempting the transaction now.</p>
                </td>
                <td/>
              </tr>
                }
                {pgateway.invoice_no_add &&
              <tr>
                <td className={'w-25 text-muted'}>Invoice Postfix</td>
                <td>{pgateway.invoice_no_add || '...'}</td>
                <td/>
              </tr>
                }
                <tr>
                  <td className={'w-25 text-muted'}>Email</td>
                  <td>{pgateway.email || '...'}</td>
                  <td/>
                </tr>
                <tr>
                  <td className={'w-25 text-muted'}>Phone</td>
                  <td>{pgateway.phone || '...'}</td>
                  <td/>
                </tr>
                {pgateway.gateway_secondary_id &&
              <tr>
                <td className={'w-25 text-muted'}>Gateway Transaction No</td>
                <td>{pgateway.gateway_secondary_id}</td>
                <td/>
              </tr>
                }
                <tr>
                  <td className={'w-25 text-muted'}>Payment Reference No</td>
                  <td>{pgateway.gateway_unique_id || '...'}</td>
                  <td className={'w-25 text-right'}>
                    <button
                      className="btn btn-link m-0 p-0"
                      disabled={!pgateway.gateway_unique_id}
                      onClick={() => {
                        confirmAlert({
                          title: 'Check Payment Status',
                          message: 'This will recheck the payment status with gateway..',
                          buttons: [
                            {
                              className: 'btn-success',
                              label: 'Check',
                              onClick: () => this.statusCheck(pgateway.hash),
                            },
                            {
                              className: 'btn-secondary',
                              label: 'Exit',
                            },
                          ],
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faSync} className={'mr-2'}/> Check Payment Status
                    </button>
                    {pgateway.payment_status_time &&
                  <p className={'small text-muted mb-0 position-absolute'}>
                    Last update : {AppUtil.formatDateTime(pgateway.payment_status_time)}
                  </p>
                    }
                  </td>
                </tr>
                <tr>
                  <td className={'w-25'}><strong>Total</strong></td>
                  <td><strong>{`${pgateway.currency} ${pgateway.total}`}</strong></td>
                  <td/>
                </tr>
                {parseFloat(pgateway.total_settled) > 0 &&
              <tr>
                <td className={'w-25'}><strong>Total Settled</strong></td>
                <td><strong>{`${pgateway.currency} ${pgateway.total_settled}`}</strong></td>
                <td/>
              </tr>
                }
                {(!_.isEmpty(pgateway.attributes) && pgateway.attributes.settled_user) &&
              <tr>
                <td className={'w-25'}>Settled by</td>
                <td><strong>{pgateway.attributes.settled_user}</strong></td>
                <td/>
              </tr>
                }
                {pgateway.payment_message &&
              <tr>
                <td className={'w-25'}>Gateway Message</td>
                <td>{pgateway.payment_message}</td>
                <td/>
              </tr>
                }
              </tbody>
            </table>
          </div>
          {pgateway.gateway_refund_id &&
          <React.Fragment>
            <div className={'mb-3'}>
              <p><strong>Refund Transaction Details</strong></p>
              <hr/>
              <table className={'table table-borderless table-sm'}>
                <tbody>
                  <tr>
                    <td className={'w-25 text-muted'}>Refund Reference No</td>
                    <td>{pgateway.gateway_refund_id || '...'}</td>
                    <td className={'w-25 text-right'}>
                      <button
                        className="btn btn-link m-0 p-0"
                        disabled={!pgateway.gateway_unique_id}
                        onClick={() => {
                          confirmAlert({
                            title: 'Check Refund Status',
                            message: 'This will recheck the refund status with gateway..',
                            buttons: [
                              {
                                className: 'btn-success',
                                label: 'Check',
                                onClick: () => this.statusCheck(pgateway.hash, 2),
                              },
                              {
                                className: 'btn-secondary',
                                label: 'Exit',
                              },
                            ],
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faSync} className={'mr-2'}/> Check Refund Status
                      </button>
                      {pgateway.refund_status_time &&
                    <p className={'small text-muted mb-0 position-absolute'}>
                      Last update : {AppUtil.formatDateTime(pgateway.refund_status_time)}
                    </p>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className={'w-25'}><strong>Total Refunded</strong></td>
                    <td><strong>{`${pgateway.currency} ${pgateway.total_refunded}`}</strong></td>
                    <td/>
                  </tr>
                  {(!_.isEmpty(pgateway.attributes) && pgateway.attributes.refunded_user) &&
                <tr>
                  <td className={'w-25'}>Refunded by</td>
                  <td><strong>{pgateway.attributes.refunded_user}</strong></td>
                  <td/>
                </tr>
                  }
                  {pgateway.refund_message &&
                <tr>
                  <td className={'w-25'}>Gateway Message</td>
                  <td>{pgateway.refund_message}</td>
                  <td/>
                </tr>
                  }
                </tbody>
              </table>
            </div>
          </React.Fragment>
          }
        </div>
        <Alerts
          status={resultMsg.status}
          success={resultMsg.success}
          error={resultMsg.error}
        />
      </React.Fragment>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default (PGatewayAction);
