/**
 *
 * POS Table List
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

// Consts and Libs
import AppAPI from '../../../lib/api';
import Strings from '../../../constants/strings';

// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';

// Actions
import queryString from 'query-string';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLongArrowAltRight, faPlus, faTable} from '@fortawesome/free-solid-svg-icons';
import SearchBar from '../../../components/forms/SearchBar';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pos: state.pos.pos,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class POSTableList extends Component {
  static componentName = 'POSTableList';
  static propTypes = {
    refreshable: PropTypes.bool,
    match: PropTypes.object.isRequired,
  };


  fetchData = (page = 1, callback) => {
    const urlParams = {
      'page': page,
    };

    const {pos} = this.props;

    if (pos.hash) {
      urlParams['pos'] = pos.hash;
    }

    let baseUrl = 'pos-table-settings';
    AppAPI.posapi.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        this.setState({
          loading: false,
          error: null,
        });
        if (res.results.length > 0) {
          if (res.next) {
            callback(res.results);
          } else {
            callback(res.results, {allLoaded: true});
          }
        } else {
          callback();
        }
      })
      .catch((err) => {
        callback(null, {allLoaded: true});
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={8}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faTable}/> Table </h4>
            {Strings.POSTableEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };

  renderRow = (data, key) => {
    const {pos} = this.props;
    return (
      <tr key={key}>
        <td>{data.name}</td>
        <td>
          <span
            className={'badge ' + (data.availability_status === -1 ? 'badge-danger' : data.availability_status === 1 ? 'badge-success' : 'badge-info')}>
            {data.availability_status_display}
          </span>
        </td>
        <td>
          <span
            className={'badge ' + (data.service_status === 1 ? 'badge-success' : 'badge-danger')}>
            {data.service_status_display}
          </span>
        </td>
        <td>
          <span className={'badge ' + (data.maintenance_status === 1 ? 'badge-success' : 'badge-danger')}>
            {data.maintenance_status_display}
          </span>
        </td>
        <td>{data.table_shape_display}</td>
        <td className={'align-middle'}>
          <Link to={`/settings/pos/${pos.hash}/table/${data.hash}/`}>
            Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
          </Link>
        </td>
      </tr>
    );
  };

  tableHeader = () => {
    return (
      <tr>
        <th className={'data-table-cell-md'}>Name</th>
        <th className={'data-table-cell-xm'}>Availability</th>
        <th className={'data-table-cell-xm'}>Usability</th>
        <th className={'data-table-cell-xm'}>Operational</th>
        <th className={'data-table-cell-xm'}>Shape</th>
        <th className={'data-table-cell-xm'}/>
      </tr>
    );
  };

  render = () => {

    const {loading, error} = this.state;
    const {pos} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="pos-item-list screen-container ">
        <Helmet>
          <title>POS Categories</title>
        </Helmet>

        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12 mb-5">
            <SearchBar
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
              colSpan={7}
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className={'btn btn-link p-0'} to={`/settings/pos/${pos.hash}/table/new`}>
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Table
                </Link>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className={'btn btn-link p-0'} to={`/settings/pos/${pos.hash}/table/new-multiple`}>
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Table (Multiple)
                </Link>
              </li>
            </ul>
          </div>
        </div>


      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(POSTableList);