/**
 * Manage Printer selection
 *  Manage printer selection, default printer selection etc.
 */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Components
import { Alerts } from '../../../components/ui';
import Loading from '../../../components/general/Loading';
import { faWindows } from '@fortawesome/free-brands-svg-icons';
import { faPrint, faSync, faTimes } from '@fortawesome/free-solid-svg-icons';
// Consts and Libs
import printerAPI from '../../../lib/printerAPI';


/* Component ==================================================================== */

class POSPrinterManage extends React.Component {
  static componentName = 'POSPrinterManage';

  static propTypes = {
    match: PropTypes.object,
    posDetails: PropTypes.object

  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        loading: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
      this.fetchPrinter();
    }
    return true;
  }


  fetchPrinter = () => {
    printerAPI(this.props.posDetails,'get')
      .then((data) => {
        this.setState({
          loading: false,
          printerList: data.printers,
          defaultPrinter: data.default_printer,
        });
      })
      .catch((error) => {
        this.setState({error, loading: false});
      });
  };

  updatePrinter = (deviceID, endPoint, payload) => {
    printerAPI(this.props.posDetails,'post', `printers/${deviceID}/${endPoint}`, payload)
      .then(() => this.fetchPrinter());
  };

  render = () => {

    const {resultMsg, loading, printerList, defaultPrinter} = this.state;
    const {show} = this.props;

    if (!show) {
      return <React.Fragment/>;
    }

    let defaultPrinterData;

    if (!_.isEmpty(printerList)) {
      defaultPrinterData = printerList.find(data => data.uuid === defaultPrinter);
    }

    return (
      <Modal
        {...this.props}
        centered
        size="lg"
        scrollable={true}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Printer Management
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading
            ? <div className={'my-5'}><Loading heightMatch={false}/></div>
            : (
              <React.Fragment>
                <div className={'row mb-4'}>
                  <div className={'col-12 col-sm-7'}>
                    <button
                      onClick={() => this.setState({loading: true}, () => this.fetchPrinter())}
                      className={'btn btn-outline-dark btn-sm'}
                    >
                      <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                    </button>
                  </div>
                  <div className={'col-12 col-sm-5 text-center text-sm-right'}>
                    <button
                      className={`btn btn-sm btn-${_.isEmpty(defaultPrinterData) ? 'danger' : 'success'}`}
                    >
                      <FontAwesomeIcon icon={faPrint} className={'mr-2'}/>
                      {_.isEmpty(defaultPrinterData) ? 'No Printer Selected' : `Selected : ${defaultPrinterData.name}`}
                    </button>
                  </div>
                </div>
                <table className={'table border format-table'}>
                  <tbody>
                    {_.isEmpty(printerList)
                      ? (
                        <tr>
                          <td colSpan={2}>
                            <h5 className={'mb-4'}><FontAwesomeIcon icon={faPrint} className={'mr-2'}/> Print Utility
                            Notfound</h5>
                            <p>System could not not detect Renzo Local Printer Reverse Proxy installed on your system.</p>
                            <p>Renzo LPRP allows the POS to initiate print directly from our servers, allowing you to
                            focus on tour tasks.</p>

                            <button className={'btn btn-success'}>
                              <FontAwesomeIcon icon={faWindows} className={'mr-2'}/> Download Renzo Print Utility<br/>
                              <span className={'small'}> Windows</span>
                            </button>
                          </td>
                        </tr>
                      )
                      : (
                        printerList.map((data, i) => (
                          <tr key={i}>
                            <td>
                              {data.name} <br/>
                              <p className={'small text-muted mb-0'}>{data.uuid}</p>
                            </td>
                            <td className={'text-center'}>
                              {data.uuid === defaultPrinter
                                ? <button className={'btn btn-success'} disabled={true}>Current Select</button>
                                : (
                                  <button
                                    onClick={() => this.updatePrinter(data.uuid, 'set-default')}
                                    className={'btn btn-outline-success'}
                                  >Set Printer</button>
                                )
                              }
                            </td>
                          </tr>
                        ))
                      )
                    }
                  </tbody>
                </table>
              </React.Fragment>
            )
          }
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          <div className={'px-3'}>
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
          </div>
          <div className={'row'}>
            <div className={'col'}>
              <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: false,
      printerList: [],
      defaultPrinter: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default POSPrinterManage;
