import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Form, Formik} from 'formik';
import Helmet from 'react-helmet';
import * as Yup from 'yup';

// Consts and Libs
import AppAPI from '../../lib/api';
import {Strings} from '../../constants';
import {Input, SubmitBtn} from 'react-formik-ui';

// Components
import {Alerts} from '../../components/ui';
import Error from '../../components/general/Error';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faKey, faLongArrowAltRight, faSpinner} from '@fortawesome/free-solid-svg-icons';


// What data from the store shall we send to the component?
const mapStateToProps = () => ({});

// Any actions to map to the component?
const mapDispatchToProps = {};

class ResetPasswordConfirm extends Component {

  action = (credentials) => {
    if (credentials) {
      this.setState({loading: true});
      AppAPI.account.post('password/reset/confirm/',
        {
          'uid': this.props.match.params.uid,
          'token': this.props.match.params.token,
          'new_password1': credentials.new_password1,
          'new_password2': credentials.new_password1
        })
        .then((res) => {
          this.setState({
            resultMsg: {success: res.detail}, loading: false, resetComplete: true
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}, loading: false});
        });
    }
  };


  render() {

    const {loading, error, resultMsg, resetComplete} = this.state;

    if (error) return <Error full={true} text={error}/>;

    const formValidation = Yup.object().shape({
      new_password1: Yup.string().min(1, 'Too Short!').required('Required'),
    });

    const initialValues = {
      new_password1: '',
    };

    return (
      <div className="login-container">
        <Helmet>
          <title>Reset Account Password | Renzo</title>
        </Helmet>

        <div className="align-items-center">
          <div className="row">
            <div className={'col-12 col-md-8 col-lg-6 col-xl-5 mx-auto'}>
              <div className={'border rounded p-4 my-3'}>
                <h4>Reset Account Password</h4>
                <hr/>
                <Formik
                  initialValues={initialValues}
                  validationSchema={formValidation}
                  onSubmit={values => this.action(values)}
                >
                  {() => (
                    <React.Fragment>
                      {resetComplete ?
                        <div>
                          <p>{Strings.authPasswordSetComplete}</p>
                          <Link className={'btn btn-success btn-lg btn-block my-3'} to="/auth/login/">
                            Proceed to Login <FontAwesomeIcon className={'ml-3'} icon={faLongArrowAltRight}/>
                          </Link>
                        </div>
                        :
                        <Form className={'form-group'}>
                          <p>{Strings.authPasswordSet}</p>
                          <Alerts
                            status={resultMsg.status}
                            success={resultMsg.success}
                            error={resultMsg.error}
                          />
                          <div className="form-row">
                            <div className="col-12 form-group">
                              <Input
                                type={'password'}
                                name="new_password1"
                                label={'New Password'}
                                className={'form-control'}
                              />
                            </div>
                          </div>

                          <SubmitBtn className={'btn btn-success btn-lg btn-block my-3'}>
                            <FontAwesomeIcon className={'mr-2'} icon={faKey}/>
                            Change Password
                            <FontAwesomeIcon
                              className={'ml-3'}
                              spin={loading}
                              icon={loading ? faSpinner : faLongArrowAltRight}
                            />
                          </SubmitBtn>
                        </Form>
                      }
                    </React.Fragment>
                  )}
                </Formik>
              </div>
              <p className={'text-secondary'}>By proceeding, you agree to the <a href="https://renzo.in/policies/terms">Terms
                of Service </a> and <a href={'https://renzo.in/policies/privacy'}> Privacy Notice</a>.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: ''
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordConfirm);
