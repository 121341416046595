/**
 *
 * POS Details KOT View
 */
import _ from 'lodash';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
// Consts and Libs
import AppUtil from '../../../lib/util';
import {Constants} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faClipboardList,
  faExclamationTriangle,
  faExternalLink,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';
import ModelPDFView from '../../../components/general/ModelPDFView';

/* Component ==================================================================== */
class POSOrderKOTDetails extends Component {
  static componentName = 'POSOrderKOTDetails';

  static propTypes = {
    loading: PropTypes.bool.isRequired,
    posOrder: PropTypes.object.isRequired,
    resultMsg: PropTypes.object.isRequired,
    posDetails: PropTypes.object.isRequired,

    cacheReset: PropTypes.func,
    updatePosOrder: PropTypes.func,
    updatePosOrderAdditional: PropTypes.func,
    escalatedPermissionUpdated: PropTypes.func,
  };

  componentDidMount = () => {
  };


  render = () => {

    const {posOrder, resultMsg, loading, posDetails} = this.props;

    if (loading) return <Loading/>;


    return (
      <div className="pos-order-details screen-container ">
        <Helmet>
          <title>POS KOT Overview | {posOrder.ref_no}</title>
        </Helmet>

        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />

            <div className={'spacer-20'}/>

            <table className={'table border'}>
              <thead>

              </thead>
              <tbody>
                {_.isEmpty(posOrder.kot_items) ?
                  <tr>
                    <td colSpan={3}>
                      <div className="text-center">
                        <FontAwesomeIcon icon={faExclamationTriangle} size={'2x'} className={'mt-3 red-cl'}/>
                        <p className={'text-secondary small'}>No KOT in this order</p>
                      </div>
                    </td>
                  </tr>
                  :
                  <React.Fragment>
                    {posOrder.kot_items.map((data, i) => (
                      <React.Fragment key={i}>
                        <tr>
                          <td>
                            <div className={'row'}>
                              <div className={'col-12 col-md-6'}>
                                <table className={'table table-sm table-borderless'}>
                                  <tbody>
                                    <tr>
                                      <td className={'w-25'}><strong>Reference </strong></td>
                                      <td>{data.ref_no}</td>
                                    </tr>
                                    <tr>
                                      <td className={'w-25'}><strong>KOT #</strong></td>
                                      <td>{data.kot_id}</td>
                                    </tr>
                                    <tr>
                                      <td className={'w-25'}><strong>Created</strong></td>
                                      <td>{AppUtil.formatDateTime(data.created)}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className={'col-12 col-md-6'}>
                                <table className={'table table-sm table-borderless'}>
                                  <tbody>
                                    <tr>
                                      <td className={'w-25'}><strong>Status</strong></td>
                                      <td>
                                        <span className={`badge ${AppUtil.posOrderKotStatusColor(data.kot_status)}`}>
                                          {data.kot_status_display}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className={'w-25'}><strong>User</strong></td>
                                      <td>{!_.isEmpty(data.attributes) ? data.attributes.created_user : '...'}</td>
                                    </tr>
                                    {data.kot_status !== Constants.POS_KOT_STATUS.ORIGINAL &&
                                    <tr>
                                      <td className={'w-25'}><strong>Modified</strong></td>
                                      <td>{AppUtil.formatDateTime(data.time)}</td>
                                    </tr>
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <hr/>
                            <div className={'row'}>
                              <div className={'col-12 col-md-8'}>
                                <table className={'table table-sm border format-table'}>
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Qty</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {_.isEmpty(data.kot_items) ?
                                      <tr>
                                        <td colSpan={3}>
                                          <div className="text-center">
                                            <FontAwesomeIcon
                                              icon={faExclamationTriangle} size={'2x'}
                                              className={'mt-3 red-cl'}
                                            />
                                            <p className={'text-secondary small'}>No item in this KOT</p>
                                          </div>
                                        </td>
                                      </tr>
                                      :
                                      <React.Fragment>
                                        {data.kot_items.map((itemData, k) => (
                                          <tr
                                            key={k}
                                            className={`${!itemData.status && 'table-dark'}`}
                                          >
                                            <td>
                                              <strong>{itemData.description}</strong>
                                              <p className={'text-muted small mb-1'}>
                                                {itemData.additional_description}
                                              </p>
                                              {itemData.item_edited &&
                                              <p className={'text-muted small mb-1'}>
                                                Modified at {AppUtil.formatDateTime(itemData.time)}
                                              </p>
                                              }
                                            </td>
                                            <td>
                                              {itemData.quantity} {itemData.quantity_type_display}
                                            </td>
                                          </tr>
                                        ))}
                                      </React.Fragment>
                                    }
                                  </tbody>
                                </table>
                              </div>
                              <div className={'col-12 col-md-4'}>
                                <ul className="list-group mb-5">
                                  <li className="list-group-item">
                                    <button
                                      className={'btn btn-link m-0 p-0'}
                                      onClick={()=>this.setState({showModelPDFView: true, urlModelPDFView:data.resources_link })}
                                    >
                                      <FontAwesomeIcon icon={faPrint} size={'sm'} className={'mr-2'}/> Print
                                    </button>
                                  </li>
                                  <li className="list-group-item">
                                    <Link
                                      target={'_blank'}
                                      to={`/point-of-sale/${posDetails.hash}/pos-order-kot/${data.hash}`}
                                    >
                                      <FontAwesomeIcon icon={faClipboardList} size={'sm'} className={'mr-2'}/>
                                      Details
                                      <FontAwesomeIcon icon={faExternalLink} className={'ml-2'}/>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                }
              </tbody>
            </table>

          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group mb-5">
              <li className="list-group-item  align-items-center" onClick={() => this.props.cacheReset()}>
                <small>KOT Actions</small>
              </li>

            </ul>
          </div>
        </div>

        <ModelPDFView
          title={'Print KOT'}
          onHide={() => this.setState({showModelPDFView: false})}
          show={this.state.showModelPDFView}
          pdfUrl={this.state.urlModelPDFView}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: false,
      urlModelPDFView: null,
      discountDetails: false,
      showModelPDFView: false,
      showPOSTableSelector: false,
      showPOSTableSelectorType: 'shift',
      showPOSOrderItemDetailsView: false,
      showRoomQuickSelectListView: false,
      showPOSOrderEscalatedPermissionEdit: false,
      showPOSOrderEscalatedPermissionEditType: 'discount',
      showPOSOrderEdit: false,
      showPOSOrderEditType: 'edit',
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default POSOrderKOTDetails;
