/**
 * Manage Notification
 *  manages payment add
 */
import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import {Form, Formik} from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import {Constants, SelectList, Strings} from '../../constants';
// Components
import {Alerts} from '../../components/ui';
import Loading from '../../components/general/Loading';
import {Input, Select, SubmitBtn, Textarea} from 'react-formik-ui';
import OrderListSelector from '../order/components/OrderListSelector';
import SelectorButton from '../../components/dashboard/SelectorButton';

import {
  faAddressCard,
  faCheckCircle,
  faCircle,
  faEnvelope,
  faMobile,
  faPaperPlane, faTimes, faUsers
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


/* Component ==================================================================== */

class MessagingManager extends Component {
  static componentName = 'NotificationManager';

  static propTypes = {
    match: PropTypes.object,
    phone: PropTypes.string,
    email: PropTypes.string,
    property: PropTypes.object,

    guests: PropTypes.array,
    preFetchData: PropTypes.bool,

    order: PropTypes.object,
    refund: PropTypes.object,
    review: PropTypes.object,
    payment: PropTypes.object,
    booking: PropTypes.object,
    bookingRoom: PropTypes.object,
    ticketBooking: PropTypes.object,
  };

  processNotification = (credentials) => {
    if (credentials) {
      const {selectedOrder, selectedGuest, notificationRecipients, additionalConfig} = this.state;
      this.setState({resultMsg: {status: 'One moment...'}});
      const payload = {
        type: this.state.notificationType,
        notification: credentials.notification,
      };

      if (!_.isEmpty(selectedGuest) && notificationRecipients === 'guest') {
        payload.guest_ids = selectedGuest;
      } else {
        if (this.state.notificationType === Constants.NOTIFICATION_TYPE.EMAIL) {
          payload.email = credentials.email;
        }

        if (this.state.notificationType === Constants.NOTIFICATION_TYPE.TEXT) {
          payload.phone = credentials.code + credentials.phone;
        }
      }

      const contentPayload = {
        message_content: {
          content_1: credentials.content_1,
          content_2: credentials.content_2
        }
      };

      if (!_.isEmpty(selectedOrder)) {
        contentPayload['bk_orders'] = selectedOrder;
      }

      if (!_.isEmpty(additionalConfig)) {
        if (!_.isEmpty(selectedOrder)) {
          contentPayload['bk_order_pdf'] = additionalConfig.includes('order_pdf');
        }

        contentPayload['bk_summary'] = additionalConfig.includes('booking_summary');
        contentPayload['bk_summary_expanded'] = additionalConfig.includes('booking_summary_expanded');
      }

      payload.payload = contentPayload;

      const {ApiEndPoint, selectionId} = this.state;

      if (ApiEndPoint && selectionId) {
        ApiEndPoint.post(`${selectionId}/notification/`, payload)
          .then(() => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                this.props.onHide();
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      }
    }
  };


  componentDidUpdate(nextProps) {
    if (nextProps.show !== this.props.show) {
      let urlParams = {}, notifications = [], ApiEndPoint = null, selectionId = null;
      if (this.props.booking) {
        ApiEndPoint = AppAPI.booking;
        selectionId = this.props.booking.hash;
        urlParams['booking'] = this.props.booking.id;
        notifications = this.props.booking.notification_list;
      }

      if (this.props.bookingRoom) {
        ApiEndPoint = AppAPI.bookingroom;
        selectionId = this.props.bookingRoom.hash;
        urlParams['booking_room'] = this.props.bookingRoom.id;
        notifications = this.props.bookingRoom.notification_list;
      }

      if (this.props.order) {
        ApiEndPoint = AppAPI.order;
        selectionId = this.props.order.hash;
        urlParams['order'] = this.props.order.id;
        notifications = this.props.order.notification_list;
      }

      if (this.props.payment) {
        ApiEndPoint = AppAPI.payment;
        selectionId = this.props.payment.hash;
        urlParams['payment'] = this.props.payment.id;
        notifications = this.props.payment.notification_list;
      }

      if (this.props.refund) {
        ApiEndPoint = AppAPI.refund;
        selectionId = this.props.refund.hash;
        urlParams['refund'] = this.props.refund.id;
        notifications = this.props.refund.notification_list;
      }

      if (this.props.review) {
        ApiEndPoint = AppAPI.review;
        selectionId = this.props.review.id;
        urlParams['review'] = this.props.review.id;
        notifications = this.props.review.notification_list;
      }

      if (this.props.ticketBooking) {
        ApiEndPoint = AppAPI.ticketbooking;
        selectionId = this.props.ticketBooking.id;
        urlParams['event_booking'] = this.props.ticketBooking.id;
        notifications = this.props.ticketBooking.notification_list;
      }

      if (this.props.show === true){
        this.fetchInitData(ApiEndPoint, selectionId, notifications, urlParams);
      }
    }
  }


  fetchInitData = (ApiEndPoint, selectionId, notifications = [], urlParams = {}) => {
    let textNotification = [], emailNotification = [];
    if (notifications && notifications.length > 0) {
      // eslint-disable-next-line array-callback-return
      notifications.map((data) => {
        let value = {value: data.value, label: data.name};
        if (data.text) {
          textNotification.push(value);
        }

        if (data.email) {
          emailNotification.push(value);
        }
      });
    }

    let stateParams = {
      urlParams,
      ApiEndPoint,
      selectionId,
      textNotification,
      emailNotification,
      loading: false,
      selectedOrder: [],
      selectedGuest: [],
      additionalConfig: [],
      selectedNotification: null,
      notificationRecipients: _.isEmpty(this.props.guests) ? 'custom': 'guest',
      notificationList: notifications,
      notificationType: Constants.NOTIFICATION_TYPE.EMAIL,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      }
    };

    if (this.props.preFetchData) {
      ApiEndPoint.get(`${selectionId}/notification/`)
        .then((res) => {
          stateParams = {
            ...stateParams,
            loading: false,
            preFetchData: res
          };
          this.setState(stateParams);
        })
        .catch(() => {
          stateParams = {...stateParams, loading: false,};
          this.setState(stateParams);
        });
    } else {
      this.setState(stateParams);
    }
  };


  selectGuest = (selector, data, item) => {
    const stateData = this.state;
    stateData[`${selector}`] = AppUtil.insertOrRemoveArray(data, item);
    this.setState(stateData);
  };


  renderOrder = (orders) => {
    const {property, booking} = this.props;
    const {selectedOrder, additionalConfig} = this.state;
    if(_.isEmpty(orders)) { return <React.Fragment/>; }
    let orderList = [];
    orders.forEach((data)=>orderList.push(data));
    if (!_.isEmpty(booking) && booking.booking_order_mask) {
      orderList = orderList.filter(item => (item.order_source !== Constants.ORDER_SOURCE.BOOKING));
    }
    return (
      <div>
        {!_.isEmpty(booking) &&  booking.booking_order_mask && (
          <div className={'mb-3'}><p className={'text-muted'}>{Strings.bookingOrderMasked}</p></div>
        )}
        <p className={'mb-1 text-muted small'}>{Strings.messagingBookingOrderSelection}</p>
        <OrderListSelector
          orders={orderList}
          property={property}
          selectedOrder={selectedOrder}
          action={(data)=>this.selectGuest('selectedOrder', selectedOrder, data.id)}
          bulkAction={(data)=>this.setState({selectedOrder: data})}
        />
        <hr/>
        <p className={'mb-3'}><strong>Additional Config</strong></p>

        <ul className="list-group mb-4">
          <li className="list-group-item">
            <div className="custom-control custom-switch">
              <input
                type="checkbox" className="custom-control-input" id={'order_pdf'}
                checked={additionalConfig.includes('order_pdf')}
                onClick={()=>this.selectGuest('additionalConfig', additionalConfig, 'order_pdf')}
              />
              <label className="custom-control-label" htmlFor={'order_pdf'}>
                <strong>Order PDF : </strong> <br/>
                <p className={'sm mb-0 text-secondary small'}>{Strings.messagingBookingOrderInclusion}</p>
              </label>
            </div>
          </li>
          <li className="list-group-item">
            <div className="custom-control custom-switch">
              <input
                type="checkbox" className="custom-control-input" id={'booking_summary'}
                checked={additionalConfig.includes('booking_summary')}
                onClick={()=>this.selectGuest('additionalConfig', additionalConfig, 'booking_summary')}
              />
              <label className="custom-control-label" htmlFor={'booking_summary'}>
                <strong>Booking Folio/Summary</strong> <br/>
                <p className={'sm mb-0 text-secondary small'}>{Strings.messagingBookingSummary}</p>
              </label>
            </div>
          </li>
          <li className="list-group-item">
            <div className="custom-control custom-switch">
              <input
                disabled={!additionalConfig.includes('booking_summary')}
                type="checkbox" className="custom-control-input" id={'booking_summary_expanded'}
                checked={additionalConfig.includes('booking_summary_expanded')}
                onClick={()=>this.selectGuest('additionalConfig', additionalConfig, 'booking_summary_expanded')}
              />
              <label className="custom-control-label" htmlFor={'booking_summary_expanded'}>
                <strong>Expand Order</strong> <br/>
                <p className={'sm mb-0 text-secondary small'}>{Strings.messagingBookingSummaryExpand}</p>
              </label>
            </div>
          </li>
        </ul>
      </div>
    );
  }

  renderGuest = (guest_list) => {
    const {selectedGuest, notificationType} = this.state;
    if(_.isEmpty(guest_list)) { return <React.Fragment/>; }
    return (
      <div>
        <p className={'mb-2'}>Guest(s)</p>
        {!_.isEmpty(guest_list) &&
        <div className={'row'}>
          {guest_list.map((data, i) => {
            if (!_.isEmpty(data)) {
              const contact = (notificationType === Constants.NOTIFICATION_TYPE.EMAIL ? data.email : data.phone);
              return (
                <div className={'col-12 col-lg-6 mb-2'} key={i}>
                  <div
                    className={`p-2 border rounded a-class d-flex ${_.isEmpty(contact) && 'text-muted grey-light-bg'} `}
                    onClick={() => !_.isEmpty(contact) ? this.selectGuest('selectedGuest', selectedGuest, data.id) : null}
                  >
                    <div>
                      <FontAwesomeIcon
                        icon={selectedGuest.includes(data.id) ? faCheckCircle : faCircle}
                        className={`${selectedGuest.includes(data.id) ? 'green-cl' : 'grey-cl'} mr-2`}
                      />
                    </div>
                    <p className={'mb-0'}>
                      {AppUtil.limitChars(data.name, 15)}
                      <small>  {!_.isEmpty(contact) ? AppUtil.limitChars(contact) : 'No Contact' }</small>
                    </p>
                  </div>
                </div>
              );
            } else {
              return <React.Fragment/>;
            }
          })}
        </div>
        }
      </div>
    );
  }

  handleNotificationOptions = (event) => {
    const {notificationList} = this.state;

    notificationList.forEach((data) => {
      if (data.value === event.target.value) {
        this.setState({selectedNotification: data});
      }
    });
  }

  render = () => {

    const {show, guests, property} = this.props;
    const {
      loading, resultMsg, notificationType, textNotification, emailNotification, notificationRecipients,
      selectedGuest, preFetchData, messageContentSelected
    } = this.state;
    const {config} = property;

    const {content} = preFetchData;
    if (!show) return null;

    const initialValues = {
      'code': property ? property.phone_number_code : '',
      'phone':'',
      'email': '',
      'notification': '',
    };

    const formValidationEmail = Yup.object().shape({
      email: Yup.string().required().email('e-mail address is not valid'),
      notification: Yup.string().min(2, 'Too Short!').required('Select notification type'),
    });

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const formValidationText = Yup.object().shape({
      phone: Yup.string().required().matches(phoneRegExp, 'Phone number is not valid'),
      notification: Yup.string().min(2, 'Too Short!').required('Select notification type'),
    });

    const formValidation = Yup.object().shape({
      notification: Yup.string().min(2, 'Too Short!').required('Select notification type'),
    });

    const notificationTypeSelection = [
      {
        title: 'Email',
        key: Constants.NOTIFICATION_TYPE.EMAIL,
        icon: faEnvelope
      },
      {
        title: 'Text',
        key: Constants.NOTIFICATION_TYPE.TEXT,
        icon: faMobile
      }
    ];

    const messageContentSelection = [
      {
        title: 'Custom Message [ Header ]',
        key: 'content_1',
      },
      {
        title: 'Custom Message [ Footer ]',
        key: 'content_2',
      }
    ];

    const notificationRecipientsSelection = [
      {
        title: 'Guest',
        key: 'guest',
        icon: faUsers,
        disabled: _.isEmpty(guests)
      },
      {
        title: 'Custom',
        key: 'custom',
        icon: faAddressCard,},
    ];

    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Notification
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={
            notificationRecipients === 'guest' ? formValidation : (notificationType === Constants.NOTIFICATION_TYPE.TEXT ? formValidationText : formValidationEmail)
          }
          onSubmit={values => this.processNotification(values)}
        >
          {() => (
            <React.Fragment>
              <Modal.Body>
                {loading ? <Loading heightMatch={false}/> :
                  <Form className={'form-group'}>
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <SelectorButton
                          btnSize={'sm'}
                          title={'Notification'}
                          className={'w-100 mb-3'}
                          buttonList={notificationTypeSelection}
                          selectButtonKey={notificationType}
                          action={(key) => this.setState({
                            notificationType: key,
                            selectedGuest: key === notificationType ? selectedGuest : []
                          })}
                        />
                        <Select
                          className={'form-control mb-md-0 mb-4'}
                          name="notification"
                          label={'Notification'}
                          placeholder="Select an Option"
                          options={notificationType === Constants.NOTIFICATION_TYPE.EMAIL ? emailNotification : textNotification}
                        />
                      </div>
                      <div className="col-12 col-sm-6 border-left">
                        <SelectorButton
                          btnSize={'sm'}
                          title={'Recipients'}
                          className={'w-100 mb-3'}
                          buttonList={notificationRecipientsSelection}
                          selectButtonKey={notificationRecipients}
                          action={(key) => this.setState({notificationRecipients: key})}
                        />
                        {notificationRecipients === 'guest' ?
                          <React.Fragment>
                            {this.renderGuest(guests)}
                          </React.Fragment>
                          :
                          <div className="form-row">
                            {notificationType === Constants.NOTIFICATION_TYPE.EMAIL &&
                            <div className="col-12 form-group">
                              <Input
                                name="email"
                                label={'E-mail'}
                                className={'form-control'}
                                type={'email'}
                              />
                            </div>
                            }
                            {notificationType === Constants.NOTIFICATION_TYPE.TEXT &&
                            <div className="col-12">
                              <div className={'row'}>
                                <div className={'col-4 pr-0 form-group'}>
                                  <Select
                                    className={'form-control rounded-right-0'}
                                    name="code"
                                    label={'Country'}
                                    placeholder="Select an Option"
                                    options={SelectList.phoneCountryCode()}
                                  />
                                </div>
                                <div className={'col-8 pl-0 form-group'}>
                                  <Input
                                    name="phone"
                                    label={'Phone'}
                                    autoComplete={'off'}
                                    className={'form-control rounded-left-0'}
                                    type={'tel'}
                                  />
                                </div>
                              </div>
                            </div>
                            }
                          </div>
                        }
                      </div>
                    </div>
                    {(notificationType === Constants.NOTIFICATION_TYPE.EMAIL) &&
                      <React.Fragment>
                        { (!_.isEmpty(config) && !_.isEmpty(config.notification) && config.notification.allow_email_custom_content) &&
                          <React.Fragment>
                            <hr/>
                            <SelectorButton
                              btnSize={'sm'}
                              title={'Content'}
                              className={'mb-3'}
                              buttonList={messageContentSelection}
                              selectButtonKey={messageContentSelected}
                              action={(key) => this.setState({messageContentSelected: key})}
                            />
                            <div className="form-row">
                              <div className={`col-12 form-group ${messageContentSelected !== 'content_1' ? 'd-none' : ''}`}>
                                <Textarea
                                  name="content_1"
                                  className={'form-control'}
                                  hint={'The contents will be inserted in header section of the message.'}
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className={`col-12 form-group ${messageContentSelected !== 'content_2' ? 'd-none' : ''}`}>
                                <Textarea
                                  name="content_2"
                                  className={'form-control'}
                                  hint={'The contents will be inserted in footer section of the message.'}
                                />
                              </div>
                            </div>
                          </React.Fragment>
                        }
                        {!_.isEmpty(content) &&
                        <React.Fragment>
                          <hr/>
                          {this.renderOrder(content.orders)}
                        </React.Fragment>
                        }
                      </React.Fragment>
                    }
                  </Form>
                }
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <SubmitBtn
                      className={'btn btn-block btn-lg btn-success'}
                      disabled={notificationRecipients === 'guest' && _.isEmpty(selectedGuest)}
                    >
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faPaperPlane} size={'sm'}/> Send Notification
                    </SubmitBtn>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      initialValues: {
        type: '',
      },
      preFetchData: {},
      selectedOrder: [],
      selectedGuest: [],
      textNotification: [],
      emailNotification: [],
      additionalConfig: [],
      notificationRecipients: 'guest',
      messageContentSelected: 'content_1',
      notificationType: Constants.NOTIFICATION_TYPE.EMAIL,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default MessagingManager;
