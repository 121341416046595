import React from 'react';
import {Route, Switch, NavLink} from 'react-router-dom';


import Plans from './Plans';
import BillingIndex from './Billing';
import Orders from './BillingOrders';
import BillingOrderDetails from './BillingOrderDetails';
import BillingPaymentDetails from './BillingPaymentDetails';
import BillingPaymentFail from './BillingPaymentFail';
import NotFound from '../NotFound';

const menu = [
  {
    title: 'Overview',
    link: '/user/billing',
    isExact: true
  },
  {
    title: 'Plans',
    link: '/user/billing/plans'
  },
  {
    title: 'Invoice',
    link: '/user/billing/invoice'
  }
];

const menuItems = [];

menu.map((item) => {
  const {title, link, isExact} = item;
  return menuItems.push(
    <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
      <NavLink
        to={{
          pathname: link,
          state: {fromDashboard: true}
        }}
        exact={isExact}
        className="nav-link"
        activeClassName="selected">
        {title}
      </NavLink>
    </li>
  );
});

const Billing = () => (
  <div>
    <div className={'row'}>
      <div className={'col-lg-2 col-md-3 col-sm-12 mb-5'}>
        <ul className="nav nav-pills flex-md-column flex-sm-row border rounded mb-3">
          {menuItems}
        </ul>
      </div>
      <div className={'col-lg-10 col-md-9 col-sm-12'}>
        <Switch>
          <Route path={'/user/billing'} exact component={BillingIndex}/>
          <Route path={'/user/billing/plans'} exact component={Plans}/>
          <Route path={'/user/billing/invoice'} exact component={Orders}/>
          <Route path={'/user/billing/invoice/type/:orderType'} component={Orders}/>
          <Route path={'/user/billing/invoice/:orderId'} exact component={BillingOrderDetails}/>
          <Route path={'/user/billing/invoice/payment/fail'} exact component={BillingPaymentFail}/>
          <Route path={'/user/billing/invoice/payment/:paymentId'} exact component={BillingPaymentDetails}/>
          <Route exact component={NotFound}/>
        </Switch>
      </div>
    </div>
  </div>
);

export default Billing;