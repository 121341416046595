/**
 * POSItemListView
 *  List view for POS Items
 *
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Actions
import {Constants} from '../../../constants';
import _ from 'lodash';
import APPUtil from '../../../lib/util';
import PosItemTypeDetailsView from '../../../components/dashboard/PosItemTypeDetailsView';


/* Component ==================================================================== */
class POSItemListView extends Component {
  static componentName = 'POSItemListView';

  static propTypes = {
    data: PropTypes.object.isRequired,
    posData: PropTypes.object.isRequired,
    action: PropTypes.func,
    disabled: PropTypes.bool,
    bgClass: PropTypes.string,
  };

  static defaultProps = {
    bgClass: '',
    disabled: false,
  }

  action = (data) => {
    if (this.props.disabled !== true ) {
      if (this.props.action) {
        this.props.action(data);
      }
    }
  };

  render = () => {
    const {data, posData, bgClass, disabled} = this.props;
    return (
      <div
        className={`border border-bottom-0 pt-3 position-relative list-group-item-action ${bgClass} ${disabled ? 'grey-light-bg' : 'anchor'}`}
        role="presentation"
        onKeyPress={this.handleKeyPress} onClick={() => this.action(data)}
      >
        <h5 className={'text-center mb-1'}>{APPUtil.limitChars(data.name, 20)}</h5>
        {[Constants.POS_TYPE.RESTRO_BAR, Constants.POS_TYPE.RESTAURANT, Constants.POS_TYPE.BAR].includes(parseInt(posData.pos_type)) &&
         <div className={'w-100'}><PosItemTypeDetailsView size={'sm'} item={data}/></div>
        }
        <p className={'text-muted small mb-0'}>
          {(!_.isEmpty(data.attributes) && data.attributes.category_name) ? APPUtil.limitChars(data.attributes.category_name, 12) : '...'}
        </p>
        <span
          className={'position-absolute top-position-badge badge ' + (data.item_status === Constants.ROOM.CLEAN ? 'badge-success' : 'badge-danger')}
        >{data.item_status_display}</span>
        <table className={'table mb-0 table-sm small w-100 border'}>
          <tbody>
            <tr>
              <td className={'border-right w-50'}>
                {data.currency} {data.price_effective}
              </td>
              <td className={'small'}>
                <small>{data.item_code || '...' }</small>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default (POSItemListView);

