/**
 *
 * POS Order List
 * List POS Order
 */
import _ from 'lodash';
import React, {Component} from 'react';
import queryString from 'query-string';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import Moment from 'moment';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import AppWebUtil from '../../lib/webUtils';
import {Constants, Filters, Strings} from '../../constants';
// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import SearchBar from '../../components/forms/SearchBar';
import FilterButton from '../../components/dashboard/FilterButton';
import FilterDate from '../../components/dashboard/FilterDate';
import ExportButton from '../../components/dashboard/ExportButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFile, faLongArrowAltRight, faSync} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  posDetails: state.pos.posDetails,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class POSOrderList extends Component {
  static componentName = 'POSOrderList';

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };

  componentDidMount = () => {
    let urlParams = {}, startDate = null, endDate = null;
    switch (this.getQueryParams('pos_order_status')) {
    case 'open':
      urlParams['pos_order_status'] = Constants.POS_ORDER_STATUS.OPEN;
      break;
    case 'hold':
      urlParams['pos_order_status'] = Constants.POS_ORDER_STATUS.HOLD;
      break;
    case 'completed':
      urlParams['pos_order_status'] = Constants.POS_ORDER_STATUS.COMPLETED;
      break;
    case 'canceled':
      urlParams['pos_order_status'] = Constants.POS_ORDER_STATUS.CANCELLED;
      break;
    default:
      break;
    }

    switch (this.getQueryParams('order_status')) {
    case 'paid':
      urlParams['order_status'] = Constants.PAYMENT_STATUS.PAID;
      break;
    case 'part-paid':
      urlParams['order_status'] = Constants.PAYMENT_STATUS.PARTPAID;
      break;
    case 'refund':
      urlParams['order_status'] = Constants.PAYMENT_STATUS.REFUNDED;
      break;
    case 'part-refund':
      urlParams['order_status'] = Constants.PAYMENT_STATUS.PART_REFUNDED;
      break;
    case 'not-paid':
      urlParams['order_status'] = Constants.PAYMENT_STATUS.NOTPAID;
      urlParams['lock'] = true;
      urlParams['void'] = false;
      break;
    case 'canceled':
      urlParams['order_status'] = Constants.PAYMENT_STATUS.CANCELLED;
      break;
    case 'open':
      urlParams['lock'] = false;
      break;
    default:
      break;
    }

    if (this.getQueryParams('start_date')) {
      startDate = Moment(String(this.getQueryParams('start_date')));
    }
    if (this.getQueryParams('end_date')) {
      endDate = Moment(String(this.getQueryParams('end_date')));
    }

    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('start_date'), this.getQueryParams('end_date'), 'created_date');

    if (this.getQueryParams('serving_user')) {
      urlParams['serving_user'] = this.getQueryParams('serving_user');
    }

    if (this.getQueryParams('table')) {
      urlParams['pos_tables'] = this.getQueryParams('table');
    }


    if (this.props.guestId) {
      urlParams['guest_data'] = this.props.guestId;
    }

    if (this.props.corporateId) {
      urlParams['corporate_data'] = this.props.corporateId;
    }

    this.setState({loading: false, urlParams, startDate, endDate});
  };

  fetchData = (page = 1, callback, searchText, download) => {
    const {posDetails} = this.props;

    const urlParams = {...this.state.urlParams};
    let baseUrl = 'pos-order-v2';
    if (page) {
      urlParams['cursor'] = page;
    }
    urlParams['pos'] = posDetails.hash;

    if (searchText) {
      baseUrl = 'pos-order-search';
      urlParams['search'] = searchText;
      this.setState({searching: true, clear: true});
      if (page){
        urlParams['page'] = page;
      }
    }

    if (download) {
      urlParams['download'] = download.file;
      urlParams['all_data'] = true;
      this.setState({fileDownload: true});
    }

    AppAPI.posapi.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (download) {
          let fileName = `POS-Order-Report-${AppUtil.formatDateTime(Moment(), 'short')}.${download.file}`;
          AppWebUtil.downloadFile(res, fileName);
        } else {
          if (res.results.length > 0) {
            callback(
              res.results,
              {
                allLoaded: !res.next,
                nextParam: res.count ? AppUtil.getURLParam(res.next, 'page') : AppUtil.getURLParam(res.next, 'cursor')
              }
            );
          } else {
            callback();
          }
        }
        this.setState({
          searching: false,
          clear: false,
          refresh: false,
          loading: false,
          fileDownload: false,
          page: page,
        });
      })
      .catch((err) => {
        if (callback) {
          callback([], {allLoaded: true});
        }
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, refresh: false, loading: false, fileDownload: false, error});
      });
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={7}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faFile}/> No Order </h4>
            {Strings.posOrderListEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };


  tableHeader = () => {
    return (
      <tr>
        <th className={'data-table-cell-sm'}>Reference</th>
        <th className={'data-table-cell-lg text-left'}>Amount</th>
        <th className={'data-table-cell-lg'}/>
        <th className={'data-table-cell-lg'}>Served By</th>
        <th className={'data-table-cell-sm'}>Table</th>
        <th className={'data-table-cell-sm'}>Date</th>
        <th className={'data-table-cell-md'}/>
      </tr>
    );
  };

  printTables = (table_data) => (
    <React.Fragment>
      {
        !_.isEmpty(table_data) ?
          <React.Fragment>
            {table_data.map((data, i) =>
              <React.Fragment>
                {(i > 0) && ', '}
                {`${data.name}`}
              </React.Fragment>
            )}
          </React.Fragment>
          : '....'
      }
    </React.Fragment>
  )

  renderRow = (data, key) => {
    const {posDetails} = this.props;
    return (
      <tr key={key}>
        <td>
          <Link to={`/point-of-sale/${posDetails.hash}/pos-order/${data.hash}`}><small>{data.ref_no}</small></Link>
        </td>
        <td className={'text-left'}>
          <strong>{`${data.currency} ${data.total}`}</strong>
          {(parseFloat(data.complimentary_total) > 0) &&
            <p className={'mb-0 small text-muted'}>{`Comp : ${data.currency}  ${data.complimentary_total}`}</p>
          }
          {(parseFloat(data.no_charge_total) > 0) &&
            <p className={'mb-0 small text-muted'}>{`NC : ${data.currency}  ${data.no_charge_total}`}</p>
          }
          {(parseFloat(data.total_discount) > 0) &&
            <p className={'mb-0 small text-muted'}>{`Discount : ${data.currency}  ${data.total_discount}`}</p>
          }
        </td>
        <td className={'data-table-cell-xl text-left'}>
          <span className={'badge ' + (AppUtil.posOrderStatusColor(data.pos_order_status))}>
            {data.pos_order_status_display}
          </span>
          <span className={'ml-2 badge ' + (AppUtil.paymentStatusColor(data.order_status))}>
            {data.order_status_display}
          </span>
        </td>
        <td>
          {!_.isEmpty(data.attributes) && <p className={'mb-0 small text-muted'}>{(data.attributes.serving_user) ? data.attributes.serving_user : '...'}</p> }
          {!_.isEmpty(data.serving_user) && <p className={'mb-0 small text-muted'}>{(data.serving_user.display_name) ? data.serving_user.display_name : '...'}</p> }
        </td>
        <td>
          {!_.isEmpty(data.pos_tables_attributes) && <p className={'mb-0 small text-muted'}>{this.printTables(data.pos_tables_attributes)}</p> }
          {!_.isEmpty(data.pos_tables) && <p className={'mb-0 small text-muted'}>{this.printTables(data.pos_tables)}</p> }
          {(!_.isEmpty(data.attributes) && data.attributes.room) && (
            <p className={'mb-0 small'}>Room : {data.attributes.room}</p>
          )}
        </td>
        <td><small>{AppUtil.formatDateTime(data.created)}</small></td>
        <td>
          <Link to={`/point-of-sale/${posDetails.hash}/pos-order/${data.hash}`}>
            Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
          </Link>
        </td>
      </tr>
    );
  };


  render = () => {
    const {loading, error, clear, searching, results, fileDownload, refresh} = this.state;
    const {posDetails} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const SERVICE_USER = [];
    if (!_.isEmpty(posDetails.membership)) {
      posDetails.membership.forEach((data) => {
        if (!_.isEmpty(data.user)) {
          SERVICE_USER.push({
            key: String(data.user.id),
            title: data.user.display_name,
          });
        }
      });
    }

    const TABLES = [];
    if (posDetails.pos_has_table && !_.isEmpty(posDetails.tables)) {
      posDetails.tables.forEach((data) => {
        TABLES.push({
          key: String(data.id),
          title: data.name,
        });
      });
    }

    return (
      <div className="order-list screen-container">
        <Helmet>
          <title>Point of Sale | Order List</title>
        </Helmet>

        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-9'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.setState({refresh: true})}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                </button>
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Order Status'}
                  data={Filters.posOrderFilter}
                  selectKey={this.getQueryParams('pos_order_status')}
                  action={(data) => this.setQueryParams([{key: 'pos_order_status', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Payment Status'}
                  data={Filters.posOrderStatusFilter}
                  selectKey={this.getQueryParams('order_status')}
                  action={(data) => this.setQueryParams([{key: 'order_status', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Table'}
                  data={TABLES}
                  selectKey={this.getQueryParams('table')}
                  action={(data) => this.setQueryParams([{key: 'table', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data))}
                  size={'sm'}
                  maxDate={Moment().add(1, 'days')}
                />
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-3 text-center text-sm-right'}>
            <ExportButton
              size={'sm'}
              files={['csv']}
              action={(key) => this.fetchData(this.state.page, null, null, {file: key})}
              loading={fileDownload}
              disabled={!this.state.startDate}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <SearchBar
              clear={clear}
              refresh={refresh}
              results={results}
              searching={searching}
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
              searchFunction={this.fetchData}
              colSpan={7}
              listVersion={'V2'}
              placeHolder="Reference ID"
            />
          </div>
        </div>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      searching: false,
      clear: false,
      results: [],
      urlParams: {},
      page: 1,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(POSOrderList);
