/**
 * Manage RoomType Screen
 *  manages roomtype add and edit
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import * as Yup from 'yup';

// Consts and Libs
import AppAPI from '../../../../lib/api';
import {Constants, ErrorMessages, Strings} from '../../../../constants';
import AppUtil from '../../../../lib/util';

// Components
import {Alerts} from '../../../../components/ui';
import Error from '../../../../components/general/Error';
import Loading from '../../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faSave} from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../../../../components/dashboard/PageHeader';
// Form
import {Formik} from 'formik';
import {Textarea, Form, Input, Select, SubmitBtn} from 'react-formik-ui';

// Actions
import * as RoomActions from '../../../../redux/room/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  roomType: state.roomType.roomType,
  room: state.room.room,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setRoom: RoomActions.setRoom,
  unSetRoom: RoomActions.unSetRoom,
};


/* Component ==================================================================== */

class RoomManage extends Component {
  static componentName = 'RoomManage';

  static propTypes = {
    match: PropTypes.object
  };

  componentDidMount = () => {
    if (this.props.match.params.roomId) {
      this.props.setRoom(this.props.match.params.roomId)
        .then(() => {
          this.fetchInitData();
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.fetchInitData();
    }
  };

  fetchInitData = () => {
    const {room} = this.props;
    let initialValues = {
      'name': '',
      'description': '',
      'smoking_type': null,
      'view_type': null,
      'bed_type': null,
      'floor_level': null,
    };
    if (this.props.match.params.roomId) {
      initialValues = {
        'name': room.name,
        'description': room.description,
        'smoking_type': room.smoking_type,
        'view_type': room.view_type,
        'bed_type': room.bed_type,
        'floor_level': room.floor_level,
      };
    }
    let formValidation = Yup.object().shape({
      name: Yup.string().min(2, 'Too Short!').required('Required'),
    });
    this.setState({
      initialValues,
      formValidation,
      loading: false,
    });
  };

  saveRoom = (payload) => {
    const {room} = this.props;
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.room.patch(`${room.id}/`,
          {
            'name': payload.name,
            'floor_level': payload.floor_level,
            'description': payload.description,
            'smoking_type': payload.smoking_type  || null,
            'view_type': payload.view_type  || null,
            'bed_type': payload.bed_type  || null
          })
          .then(() => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.history.goBack();
                }, 500);
              });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  addRoom = (payload) => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.room.post('',
          {
            'name': payload.name,
            'bed_type': payload.bed_type || null,
            'view_type': payload.view_type || null,
            'description': payload.description,
            'floor_level': payload.floor_level || null,
            'room_type': this.props.roomType.id,
            'smoking_type': payload.smoking_type || null,
          })
          .then((res) => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.history.replace(this.props.location.pathname.replace(/[^/]*$/, `${res.id}/`));
                }, 500);
              });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  render = () => {

    const {loading, error, resultMsg, initialValues, formValidation,} = this.state;
    const {room} = this.props;
    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    return (
      <div className="room-manage screen-container ">
        <Helmet>
          <title>Room Manage</title>
        </Helmet>

        <PageHeader
          subHeader={true} history={this.props.history}
          title={(this.props.match.params.roomId ? 'Manage' : 'Add') + ' Room Type'}
          description={this.props.match.params.roomId ? `Edit room type ${room.name}` : 'Add new room type'}
        />

        <Formik
          onSubmit={(values) => this.props.match.params.roomId ? this.saveRoom(values) : this.addRoom(values)}
          validationSchema={formValidation}
          initialValues={initialValues}>
          {() => (
            <Form>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Room Information
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Input
                          required
                          name='name'
                          label={'Name'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Textarea
                          name='description'
                          label={'Description'}
                          className={'form-control'}
                          hint={'Any comments for the room.'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                  <p className={''}>
                    <strong>Name : </strong> {Strings.roomManageName}
                  </p>
                </div>
              </div>

              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Room Amenity
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <Select
                          className={'form-control rounded-right-0'}
                          name='floor_level'
                          label={'Floor Level'}
                          placeholder='Select an Option'
                          options={AppUtil.numericSelector(1, 40)}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <Select
                          className={'form-control rounded-right-0'}
                          name='smoking_type'
                          label={'Smoking Type'}
                          placeholder='Select an Option'
                          options={Constants.ROOM_SMOKING_TYPE}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <Select
                          className={'form-control rounded-right-0'}
                          name='view_type'
                          label={'View Type'}
                          placeholder='Select an Option'
                          options={Constants.ROOM_VIEW_TYPE}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <Select
                          className={'form-control rounded-right-0'}
                          name='bed_type'
                          label={'Bed Config'}
                          placeholder='Select an Option'
                          options={Constants.ROOM_BED_TYPE}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                </div>
              </div>

              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 mt-2">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn className={'btn btn-success btn-block btn-lg mt-2'}>
                    <FontAwesomeIcon
                      className={'white-cl mr-2'}
                      icon={this.props.match.params.roomId ? faSave : faPlus} size={'sm'}
                    /> {this.props.match.params.roomId ? 'Save' : 'Add'}
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: ''
      },
      settings: false
    };
  }
}


/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(RoomManage);
