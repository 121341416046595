/**
 *
 * Payment Terminal Details
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PTerminalDevices from '../Components/PTerminalDevices';
import MessagingManager from '../../messaging/MessagingManager';
import PTerminalInfo from '../PTerminal/Components/PTerminalInfo';
import PTerminalAction from '../PTerminal/Components/PTerminalAction';
import PageSubHeader from '../../../components/dashboard/PageSubHeader';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCashRegister} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as PaymentActions from '../../../redux/payment/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  payment: state.payment.payment,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPayment: PaymentActions.setPayment,
};


/* Component ==================================================================== */
class PaymentTerminalDetails extends Component {
  static componentName = 'PaymentTerminalDetails';

  static propTypes = {
    match: PropTypes.object.isRequired,
    selectKey: PropTypes.string,
  };

  static defaultProps = {
    selectKey: 'pterminal'
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = (reloadPayment= false) => {
    const {selectKey} = this.props;
    this.setState({loading: true});

    AppAPI.payment.get(`${this.props.match.params.paymentId}/related-details/?key=${selectKey}`)
      .then((res) => {
        this.setState({response: res, loading: false});
        if (reloadPayment) {
          this.props.setPayment(this.props.payment.hash);
        }
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };

  cacheReset = (pterminal) => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.pterminal
        .post(`${pterminal.hash}/cache-reset/`, {})
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}, response: {pterminal: res}}, () => {
            setTimeout(() => (this.setState({resultMsg: {success: ''}})), 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({loading: false, resultMsg: {error}});
        });
    });
  };

  render = () => {

    const {loading, error, response, resultMsg} = this.state;
    const {payment} = this.props;


    if (loading || _.isEmpty(payment)) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    const {pterminal} = response;


    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Payment Terminal</title>
        </Helmet>

        <PageSubHeader
          icon={faCashRegister} title={'Payment Terminal'}
          description={'Payment terminal transaction for the payment.'}
        />


        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            {_.isEmpty(response) ?
              <React.Fragment>
                <Alerts
                  status={resultMsg.status}
                  success={resultMsg.success}
                  error={resultMsg.error}
                />
                <PTerminalDevices
                  payment={payment}
                  PTerminalDevices={payment.payment_terminal_devices}
                  action={(data) => this.setState({response: {pterminal: data}})}
                />
              </React.Fragment>
              :
              <React.Fragment>
                <PTerminalInfo pterminal={pterminal}/>
                <PTerminalAction
                  pterminal={pterminal}
                  action={(data) =>
                    this.setState({response: data.payment_status === Constants.PAYMENT_STATUS.CANCELLED ? [] : {pterminal: data}})
                  }
                />
              </React.Fragment>
            }
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className="" to={`/payment/${payment.hash}/payment-terminal/list`}>
                  <FontAwesomeIcon icon={faCashRegister} size={'sm'} className={'mr-2'}/> Terminal Transaction List
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* Messaging */}
        <MessagingManager
          onHide={() => this.setState({showMessagingManager: false})}
          show={this.state.showMessagingManager}
          property={this.props.property}
          guests={payment.guest ? [payment.guest] : null}
          payment={payment}
        />
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      response: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PaymentTerminalDetails);