import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import profileRequired from '../../components/decorators/profileRequired';


import CustomerList from './CustomerList.js';
import NotFound from '../NotFound';
import ProfileManage from '../guest/ProfileManage';
import GuestDetails from '../guest/GuestDetails';
import GuestRecordList from '../guest/GuestRecordList';

const guestDetails = () => (
  <div>
    <Switch>
      <Route render={(props) => <GuestDetails {...props} />}/>
    </Switch>
  </div>
);

const GuestRoute = () => (
  <div>
    <div className="container">
      <Switch>
        <Route exact path="/customer/" render={() => (<Redirect to="/customer/guest"/>)}/>

        <Route
          path="/customer/guest/new" exact
          render={(props) => <ProfileManage {...props} subHeader={true} isGuest={true}/>}/>
        <Route path="/customer/guest/:guestId/" component={profileRequired(guestDetails)}/>

        <Route
          path="/customer/agent/new" exact
          render={(props) => <ProfileManage {...props} subHeader={true} isAgent={true}/>}/>
        <Route path="/customer/agent/:guestId/" component={profileRequired(GuestDetails)}/>

        <Route
          path="/customer/corporate/new" exact
          render={(props) => <ProfileManage {...props} subHeader={true} isCorporate={true}/>}/>
        <Route path="/customer/corporate/:guestId/" component={profileRequired(GuestDetails)}/>

        <Route path="/customer/guest" component={CustomerList}/>
        <Route path="/customer/agent" component={CustomerList}/>
        <Route path="/customer/corporate" component={CustomerList}/>
        <Route path="/customer/records" component={GuestRecordList}/>
        <Route exact component={NotFound}/>
      </Switch>
    </div>

  </div>
);

export default GuestRoute;
