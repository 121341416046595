/**
 *
 * Property Notification Sent
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
// Consts and Libs
import {Constants} from '../../../constants';
import AppAPI from '../../../lib/api';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PageHeader from '../../../components/dashboard/PageHeader';
import AppUtil from '../../../lib/util';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class NotificationLogDetails extends Component {
  static componentName = 'NotificationLogDetails';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    if (this.props.match.params.notificationId) {
      AppAPI.notification.get(`${this.props.match.params.notificationId}/`)
        .then((res) => {
          this.setState({
            loading: false,
            notificationDetails: res,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error: error,
            resultMsg: {error},
          });
        });
    } else {
      this.setState({loading: false, error: true});
    }
  };

  render = () => {

    const {loading, error, notificationDetails} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    return (
      <div className="notification-details screen-container ">
        <Helmet>
          <title>Notification Details</title>
        </Helmet>


        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'Notification Details'}
          description={`Details of notification : ${notificationDetails.hash}`}
        />


        <div className="row">
          <div className="col-md-8 col-sm-12">
            <ul className="list-group mb-3">
              <li className="list-group-item">
                <strong>Notification ID :</strong> {notificationDetails.hash}
              </li>
              {notificationDetails.notification_channel === Constants.NOTIFICATION_TYPE.EMAIL &&
               <React.Fragment>
                 <li className="list-group-item">
                   <strong>Subject </strong> : {notificationDetails.subject}
                 </li>
                 <li className="list-group-item">
                   <strong>From/Reply </strong> : {notificationDetails.get_reply_address}
                 </li>
               </React.Fragment>
              }
              <li className="list-group-item">
                <strong>To </strong> : {notificationDetails.to_addresses}
              </li>
              {notificationDetails.description &&
              <li className="list-group-item">
                <strong>Note </strong> : {notificationDetails.description}
              </li>
              }
              {notificationDetails.body &&
               <li className="list-group-item">
                 <p><strong>Message Body : </strong></p>
                 <p className={'display-linebreak'}>{notificationDetails.body}</p>
               </li>
              }

              {notificationDetails.body_html &&
               <li className="list-group-item">
                 <p><strong>Message Body : </strong></p>
                 <div
                   className={'overflow-auto'}
                   dangerouslySetInnerHTML={{
                     __html: notificationDetails.body_html,
                   }}/>
               </li>
              }
            </ul>
          </div>
          <div className={'col-md-4 col-sm-12'}>
            <ul className="list-group mb-3">
              <li className="list-group-item">
                <strong>Type : </strong> {notificationDetails.notification_type_display}
              </li>
              <li className="list-group-item">
                <strong>Channel : </strong> {notificationDetails.notification_channel_display}
              </li>
              <li className="list-group-item">
                <strong>Status : </strong>
                <span className={`badge ${AppUtil.notificationStatusColor(notificationDetails.notification_status)}`}>
                  {notificationDetails.notification_status_display}
                </span>
              </li>
              <li className="list-group-item">
                <strong>Origin : </strong> {notificationDetails.created_type_display}
              </li>
              <li className="list-group-item">
                <strong>Time : </strong> {AppUtil.formatDateTime(notificationDetails.created)}
              </li>
            </ul>
          </div>
        </div>
        {!_.isEmpty(notificationDetails.notification_records) &&
         <div className="row">
           <div className="col-md-8 col-sm-12">
             <hr/>
             <h5 className={'mb-0'}>Notification Record</h5>
             <p className={''}>Notification recipients and processing status.</p>
             <table className={'table border format-table'}>
               <thead>
                 <tr>
                   <th>To</th>
                   <th>Status</th>
                   <th>Channel</th>
                   <th>Consumption</th>
                   <th>Time</th>
                 </tr>
               </thead>
               <tbody>
                 {notificationDetails.notification_records.map((data, i) => (
                   <tr key={i}>
                     <td>{data.to_address}</td>
                     <td>
                       <span className={`badge ${AppUtil.notificationStatusColor(data.notification_status)}`}>
                         {data.notification_status_display}
                       </span>
                     </td>
                     <td>{data.notification_channel_display}</td>
                     <td>{data.notification_count}</td>
                     <td>{AppUtil.formatDateTime(data.created)}</td>
                   </tr>
                 ))}
               </tbody>
             </table>

           </div>
         </div>
        }
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      notificationDetails: {},
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationLogDetails);