/**
 * Guest Details View
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { Img } from 'react-image';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import {Constants} from '../../constants';
// Components
import {Alerts} from '../../components/ui';
import Loading from '../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {confirmAlert} from '../../components/general/ConfirmAlert';
import {faCheck, faExternalLinkAlt, faPlus, faSyncAlt, faTimes} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as GuestActions from '../../redux/guest/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  guest: state.guest.guest,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setGuest: GuestActions.setGuest,
};

/* Component ==================================================================== */

class GuestDetailsOverView extends Component {
  static componentName = 'GuestDetailsShort';

  static propTypes = {
    action: PropTypes.func,
    property: PropTypes.object,
    resultMsg: PropTypes.object,
    addGuestFunction: PropTypes.bool,
    guestId: PropTypes.string,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState(
        {
          loading: true,
          init: true,
        },
      );
    }
    return true;
  }


  preFillData = () => {
    if (this.props.guestId) {
      this.props.setGuest(this.props.guestId)
        .then(() => {
          this.setState({
            loading: false,
            init: false,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            init: false,
            error,
          });
        });
    } else {
      this.setState({
        error: true,
        init: false,
        loading: false,
      });
    }
  };


  render = () => {

    const {init, loading} = this.state;
    const {show, guest, resultMsg} = this.props;

    if (show && init) {
      this.preFillData();
    }

    if (!show) {
      return null;
    }
    let editUrl = null, title = '';
    if (guest && guest.id) {
      editUrl = '/customer/guest/' + guest.hash + '/';
      title = 'Guest';
      if (guest.profile_type === Constants.PROFILE_TYPE.CORPORATE) {
        editUrl = '/customer/corporate/' + guest.hash + '/';
        title = 'Corporate';
      } else if (guest.profile_type === Constants.PROFILE_TYPE.AGENT) {
        editUrl = '/customer/agent/' + guest.hash + '/';
        title = 'Agent';
      } else if (guest.profile_type === Constants.PROFILE_TYPE.VENDOR) {
        editUrl = '/settings/expense/vendor/' + guest.hash + '/';
        title = 'Vendor';
      }
    }

    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {title} Details
            {editUrl &&
            <Link rel='noopener noreferrer' target={'_blank'} to={editUrl}>
              <FontAwesomeIcon className={'mx-2'} icon={faExternalLinkAlt} size={'sm'}/>Detail View</Link>
            }
            {this.props.cacheReset &&
            <button onClick={() => this.props.cacheReset()} className={'btn btn-link m-0 p-0 ml-4'}>
              <FontAwesomeIcon className={'mx-2'} icon={faSyncAlt} size={'sm'}/> Sync Guest Data
            </button>
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ?
            <Loading heightMatch={false}/>
            :
            <div>
              <table className={'table table-borderless table-sm'}>
                <tbody>
                  <tr>
                    <td className={'w-25'}>Reference Number</td>
                    <td>{guest.ref_no}</td>
                  </tr>
                  {(guest.profile_type !== Constants.PROFILE_TYPE.GUEST) &&
                  <React.Fragment>
                    <tr>
                      <td className={'w-25'}>Entity Name</td>
                      <td>{guest.entity_name}</td>
                    </tr>
                    {guest.iata_code &&
                    <tr>
                      <td className={'w-25'}>IATA Code</td>
                      <td>{guest.iata_code}</td>
                    </tr>
                    }
                  </React.Fragment>
                  }
                  <tr>
                    <td className={'w-25'}>Type</td>
                    <td>{guest.profile_type_display}</td>
                  </tr>
                </tbody>
              </table>
              <div className={'row'}>
                <div className={'col-md-12 col-lg-6'}>
                  <h5>Account Details</h5>
                  <hr/>
                  <table className={'table table-borderless table-sm'}>
                    <tbody>
                      <tr>
                        <td className={'w-50'}>Name</td>
                        <td>{guest.name}</td>
                      </tr>
                      <tr>
                        <td className={'w-50'}>Nationality</td>
                        <td>{guest.nationality ? guest.nationality.name : '...'}</td>
                      </tr>
                      <tr>
                        <td className={'w-50'}>Gender</td>
                        <td>{guest.gender_display}</td>
                      </tr>

                      <tr>
                        <td className={'w-50'}>Record Document</td>
                        <td>
                          <span className={`ml-2 badge ${guest.record_exist ? 'badge-success' : 'badge-warning'}`}>
                            {guest.record_exist ? 'Yes' : 'No Records'}
                          </span>
                        </td>
                      </tr>
                      {guest.tax_company &&
                      <tr>
                        <td className={'w-50'}>Company</td>
                        <td>{guest.tax_company || '...'}</td>
                      </tr>
                      }
                      {guest.tax_number &&
                      <tr>
                        <td className={'w-50'}>Company</td>
                        <td>{guest.tax_number || '...'}</td>
                      </tr>
                      }
                      {guest.date_of_birth &&
                      <tr>
                        <td className={'w-50'}>Date of birth</td>
                        <td>{guest.date_of_birth || '...'}</td>
                      </tr>
                      }
                      {guest.age &&
                      <tr>
                        <td className={'w-50'}>Age</td>
                        <td>{guest.age || '...'}</td>
                      </tr>
                      }
                      {guest.anniversary_date &&
                      <tr>
                        <td className={'w-50'}>Anniversary Date</td>
                        <td>{guest.anniversary_date || '...'}</td>
                      </tr>
                      }
                    </tbody>
                  </table>
                </div>
                <div className={'col-md-12 col-lg-6'}>
                  <h5>Contact</h5>
                  <hr/>
                  <table className={'table table-borderless table-sm'}>
                    <tbody>
                      <tr>
                        <td className={'w-50'}>Email</td>
                        <td>{guest.email || '...'}</td>
                        <td>
                          {guest.email &&
                          <React.Fragment>
                            <FontAwesomeIcon icon={guest.email_verified ? faCheck : faTimes}
                              className={`${guest.email_verified ? 'green-cl' : 'red-cl'} mr-2`}/>
                          </React.Fragment>
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className={'w-50'}>Phone</td>
                        <td>{guest.phone || '...'}</td>
                        <td>
                          {guest.phone &&
                          <React.Fragment>
                            <FontAwesomeIcon icon={guest.phone_verified ? faCheck : faTimes}
                              className={`${guest.phone_verified ? 'green-cl' : 'red-cl'} mr-2`}/>
                          </React.Fragment>
                          }
                        </td>
                      </tr>

                      <tr>
                        <td className={'w-50'}>Transactional Notification</td>
                        <td>
                          <span
                            className={'badge ' + (guest.subscribe_transactions ? 'badge-success' : 'badge-secondary')}>
                            {guest.subscribe_transactions ? 'Enabled' : ' Disabled'}
                          </span>
                        </td>
                        <td/>
                      </tr>

                      <tr>
                        <td className={'w-50'}>Promotional Notification</td>
                        <td>
                          <span
                            className={'badge ' + (guest.subscribe_promotions ? 'badge-success' : 'badge-secondary')}>
                            {guest.subscribe_promotions ? 'Enabled' : ' Disabled'}
                          </span>
                        </td>
                        <td/>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className={'row'}>
                <div className={'col-md-12 col-lg-6'}>
                  <h5>Address</h5>
                  <hr/>
                  <table className={'table table-borderless table-sm'}>
                    <tbody>
                      <tr>
                        <td className={'w-50'}>Address</td>
                        <td>{guest.address || '...'}</td>
                      </tr>
                      <tr>
                        <td className={'w-50'}>State</td>
                        <td>{guest.state || '...'} {guest.pincode && `, Pin : ${guest.pincode}`}</td>
                      </tr>
                      <tr>
                        <td className={'w-50'}>Country</td>
                        <td>{guest.country ? guest.country.name : '...'}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className={'col-md-12 col-lg-6'}>
                  <h5>Misc Information</h5>
                  <hr/>
                  <table className={'table table-borderless table-sm mb-5'}>
                    <tbody>
                      <tr>
                        <td className={'w-50 text-muted'}>Created</td>
                        <td>{AppUtil.formatDateTime(guest.created)}</td>
                      </tr>
                      <tr>
                        <td className={'w-50 text-muted'}>Last modified</td>
                        <td>{AppUtil.formatDateTime(guest.time)}</td>
                      </tr>
                      <tr>
                        <td className={'w-25 text-muted'}>Profile Source</td>
                        <td>{guest.profile_source_display}</td>
                      </tr>
                    </tbody>
                  </table>
                  {guest.signature_record_signed && guest.signature_record_signed.record_2x &&
                  <div className={'mb-2'}>
                    <h5>Signature Records</h5>
                    <hr/>
                    <div className={'row align-items-center'}>
                      <div className="col-6">
                        <figure className="figure border p-2">
                          <Img
                            width={'100%'}
                            src={guest.signature_record_signed.record_2x}
                            loader={<Loading heightMatch={false}/>}
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                  }
                </div>
              </div>
            </div>
          }
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          {resultMsg &&
          <Alerts
            status={resultMsg.status}
            success={resultMsg.success}
            error={resultMsg.error}
          />
          }
          <div className={'row'}>
            <div className={'col'}>
              {this.props.action && !loading &&
              <button
                className={'btn btn-lg btn-block ' + (this.props.addGuestFunction ? 'btn-success' : 'btn-danger')}
                onClick={() =>
                  (this.props.addGuestFunction ?
                    this.props.action(this.props.guest)
                    :
                    confirmAlert({
                      title: (this.props.addGuestFunction ? 'Add' : 'Remove') + ` ${title}`,
                      message: this.props.addGuestFunction ? `This will add ${title} profile.` : `This will unlink ${title} profile.`,
                      buttons: [
                        {
                          className: (this.props.addGuestFunction ? 'btn-success' : 'btn-danger'),
                          label: 'Yes',
                          onClick: () => this.props.action(this.props.guest),
                        },
                        {
                          className: 'btn-secondary',
                          label: 'No',
                        },
                      ],
                    })
                  )
                }
              >
                <FontAwesomeIcon className={'white-cl mr-2'} icon={(this.props.addGuestFunction ? faPlus : faTimes)}
                  size={'sm'}/>
                {this.props.addGuestFunction ? 'Add ' : 'Remove '}
              </button>
              }
            </div>
            <div className={'col'}>
              <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      init: true,
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(GuestDetailsOverView);
