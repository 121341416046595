/**
 *
 * Property Notification Settings
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Consts and Libs
import {Strings} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import BadgeStatus from '../../../components/general/BadgeStatus';
import {faCheckCircle, faEnvelope, faPhone, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as PropertyActions from '../../../redux/property/actions';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetPropertyDetails: PropertyActions.isSetPropertyDetails,
};

/* Component ==================================================================== */
class NotificationContentList extends Component {
  static componentName = 'NotificationContentList';

  componentDidMount = () => {
    this.setState({loading: false});
  };


  render = () => {
    const {loading, error, resultMsg} = this.state;

    if (!this.props.property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const {property} = this.props;

    return (
      <div className="channel-manager-settings screen-container">
        <Helmet>
          <title>Notification Settings</title>
        </Helmet>
        <Alerts
          status={resultMsg.status}
          success={resultMsg.success}
          error={resultMsg.error}
        />

        <div className={'row'}>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <ul className="list-group mb-3">
              <li className="list-group-item">Notification Channel Status</li>
              <li className="list-group-item d-flex">
                <div>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className={`mr-2 ${property.allow_email ? 'green-cl' : 'red-cl'}`}
                  />
                </div>
                <div>
                  <strong>Email Notification : </strong>
                  <BadgeStatus
                    successText={'Enabled'} failText={'Disabled'} className={'ml-2'}
                    status={property.allow_email}
                  />
                </div>
              </li>
              <li className="list-group-item d-flex">
                <div>
                  <FontAwesomeIcon
                    icon={faPhone}
                    className={`mr-2 ${property.allow_text ? 'green-cl' : 'red-cl'}`}
                  />
                </div>
                <div>
                  <strong>Text Notification : </strong>
                  <BadgeStatus
                    successText={'Enabled'} failText={'Disabled'} className={'ml-2'}
                    status={property.allow_email}
                  />
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <ul className="list-group mb-3">
              <li className="list-group-item">Auto Notification <small>(Master Control)</small></li>
              <li className="list-group-item">
                <FontAwesomeIcon
                  icon={property.auto_email_notification ? faCheckCircle : faTimesCircle}
                  className={`mr-2 ${property.auto_email_notification ? 'green-cl' : 'red-cl'}`}
                />
                <strong>Email  : </strong>
                <BadgeStatus
                  successText={'Enabled'} failText={'Disabled'} className={'ml-2'}
                  status={property.auto_email_notification}
                />
              </li>
              <li className="list-group-item">
                <FontAwesomeIcon
                  icon={property.auto_text_notification ? faCheckCircle : faTimesCircle}
                  className={`mr-2 ${property.auto_text_notification ? 'green-cl' : 'red-cl'}`}
                />
                <strong>Text  : </strong>
                <BadgeStatus
                  successText={'Enabled'} failText={'Disabled'} className={'ml-2'}
                  status={property.auto_text_notification}
                />
              </li>
            </ul>

          </div>
        </div>
        <div className={'row'}>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <ul className="list-group mb-3">
              <li className="list-group-item">Notification Config</li>
              <li className="list-group-item d-flex">
                <div>
                  <FontAwesomeIcon
                    icon={property.allow_email_custom_content ? faCheckCircle : faTimesCircle}
                    className={`mr-2 ${property.allow_email_custom_content ? 'green-cl' : 'red-cl'}`}
                  />
                </div>
                <div>
                  <strong>Custom Content (Email) : </strong>
                  <BadgeStatus
                    successText={'Enabled'} failText={'Disabled'} className={'ml-2'}
                    status={property.allow_email_custom_content}
                  /><br/>
                  <p className={'sm mb-0 text-secondary small'}>{Strings.propertyNotificationCustomContent}</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <ul className="list-group mb-3">
              <li className="list-group-item">Support Contacts</li>
              <li className="list-group-item d-flex">
                <div>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className={`mr-2 ${property.support_email ? 'green-cl' : 'red-cl'}`}
                  />
                </div>
                <div>
                  <strong>Email : </strong> {property.support_email || '...'}
                  <br/>
                  <p className={'sm mb-0 text-secondary small'}>{Strings.propertySupportContactEmail}</p>
                </div>
                <div>
                  <BadgeStatus
                    successText={'Configured'} failText={'Disabled'} className={'ml-2'}
                    status={property.support_email}
                  />
                </div>
              </li>
              <li className="list-group-item d-flex">
                <div>
                  <FontAwesomeIcon
                    icon={faPhone}
                    className={`mr-2 ${property.support_phone ? 'green-cl' : 'red-cl'}`}
                  />
                </div>
                <div>
                  <strong>Phone : </strong> {property.support_phone || '...'}
                  <br/>
                  <p className={'sm mb-0 text-secondary small'}>{Strings.propertySupportContactPhone}</p>
                </div>
                <div>
                  <BadgeStatus
                    successText={'Configured'} failText={'Disabled'} className={'ml-2'}
                    status={property.support_phone}
                  />
                </div>
              </li>
              <li className="list-group-item d-flex">
                <p className={'sm mb-0 text-secondary small'}>
                  {(property.support_phone && property.support_email) ? Strings.propertySupportContactsComplete : Strings.propertySupportContactsInComplete}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContentList);
