/**
 * OTA Search
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */

class BookingOTASearch extends Component {
  static componentName = 'BookingOTASearch';


  static propTypes = {
    OTAList: PropTypes.object.isRequired,
    addOTAFunction: PropTypes.func.isRequired,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.fetchInitData();
    }
    return true;
  }


  fetchInitData = () => {
    this.setState({OTAList: this.props.OTAList, loading: false});
  };

  action = (data) => {
    if (this.props.addOTAFunction) {
      this.props.addOTAFunction(data);
    }
    this.props.onHide();
  };

  searchItems = (text) => {
    const items = this.props.OTAList;
    if (text) {
      // eslint-disable-next-line array-callback-return
      const searchItems = items.filter((item) => {
        const itemData = item.name.toUpperCase();
        const textData = text.toUpperCase();
        if (itemData.includes(textData)) {
          return item;
        }
      });

      this.setState({
        search: text,
        OTAList: searchItems,
      });
    } else {
      this.setState({
        search: '',
        OTAList: items,
      });
    }
  };


  render = () => {

    const {OTAList} = this.state;
    const {show} = this.props;
    if (!show) {
      return null;
    }

    return (
      <Modal
        {...this.props}
        size={'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            OTA Search
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form>
              <div className="form-row align-items-center mb-4">
                <div className={'col-sm-12'}>
                  <input
                    type="search"
                    className="form-control"
                    placeholder={'OTA Name'}
                    name="q"
                    autoComplete={'off'}
                    onChange={(data) => this.searchItems(data.target.value)}
                  />
                </div>
              </div>
            </form>
            {OTAList.map((data, key) => (
              <div key={key} className="list-group-item">
                <div className={'row'}>
                  <div className={'col-8'}>
                    <h5 className="mb-1">{data.name}</h5>
                  </div>
                  <div className={'col-4 text-right'}>
                    <button
                      className={'btn btn-success mr-0'}
                      onClick={() => this.action(data)}
                    >Add
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          <div className={'row'}>
            <div className={'col'}>
              <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      OTAList: [],
      loading: true,
      error: false,
    };
  }
}

/* Export Component ==================================================================== */
export default BookingOTASearch;
