/**
 *
 * Property Notification Sent List
 */
import React, {Component} from 'react';
import queryString from 'query-string';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import Moment from 'moment';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import AppWebUtil from '../../../lib/webUtils';
import {Constants, Filters, Strings} from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import SearchBar from '../../../components/forms/SearchBar';
import FilterDate from '../../../components/dashboard/FilterDate';
import PageHeader from '../../../components/dashboard/PageHeader';
import ExportButton from '../../../components/dashboard/ExportButton';
import FilterButton from '../../../components/dashboard/FilterButton';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faLongArrowAltRight,
  faMobileAlt,
  faSync
} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class NotificationLogList extends Component {
  static componentName = 'NotificationLogList';

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };

  componentDidMount = () => {
    let urlParams = {}, startDate = null, endDate = null;
    switch (this.getQueryParams('channel')) {
    case 'email':
      urlParams['notification_channel'] = Constants.NOTIFICATION_TYPE.EMAIL;
      break;
    case 'text':
      urlParams['notification_channel'] = Constants.NOTIFICATION_TYPE.TEXT;
      break;
    default:
      break;
    }

    switch (this.getQueryParams('created_type')) {
    case 'user-created':
      urlParams['created_type'] = Constants.CREATED_TYPE.USER_CREATED;
      break;
    case 'system-created':
      urlParams['created_type'] = Constants.CREATED_TYPE.SYSTEM_CREATED;
      break;
    default:
      break;
    }

    if (this.getQueryParams('start_date')) {
      startDate = Moment(String(this.getQueryParams('start_date')));
    }
    if (this.getQueryParams('end_date')) {
      endDate = Moment(String(this.getQueryParams('end_date')));
    }

    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('start_date'), this.getQueryParams('end_date'), 'created_date');


    this.setState({loading: false, urlParams, startDate, endDate});

  }

  fetchData = (page = 1, callback, searchText, download) => {
    const urlParams = {...this.state.urlParams};
    let baseUrl = 'notification';
    urlParams['page'] = page;


    if (searchText) {
      baseUrl = 'notification-search';
      urlParams['search'] = searchText;
      this.setState({searching: true, clear: true});
    }

    if (download) {
      urlParams['download'] = download.file;
      urlParams['all_data'] = true;
      this.setState({fileDownload: true});
    }

    AppAPI.notificationapi.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (download) {
          let fileName = `Notification-Report-${AppUtil.formatDateTime(Moment(), 'short')}.${download.file}`;
          AppWebUtil.downloadFile(res, fileName);
        } else {
          if (res.results.length > 0) {
            callback(res.results, {allLoaded: !res.next});
          } else {
            callback();
          }
        }
        this.setState({
          searching: false,
          clear: false,
          refresh: false,
          loading: false,
          fileDownload: false,
          page: page,
          taxes: res.taxes,
        });
      })
      .catch((err) => {
        if (callback) {
          callback([], {allLoaded: true});
        }
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, refresh: false, loading: false, fileDownload: false, error});
      });
  };


  renderRow = (data, key) => {
    return (
      <tr key={key}>
        <td className={'data-table-cell-xxxl'}>
          <Link to={`/settings/property/notification-settings/logs/${data.hash}`}>
            <FontAwesomeIcon
              icon={data.notification_channel === Constants.NOTIFICATION_TYPE.EMAIL ? faEnvelopeOpenText : faMobileAlt}
              className={'mr-2'}
            />
            {data.subject}
          </Link> <br/>
          <span className={'text-muted small'}>Notification ID : {data.hash}</span>
        </td>
        <td className={'data-table-cell-xm'}>
          <span className={`badge ${AppUtil.notificationStatusColor(data.notification_status)}`}>
            {data.notification_status_display}
          </span>
        </td>
        <td className={'data-table-cell-xm'}>{data.created_type_display}</td>
        <td className={'data-table-cell-md'}><small>{AppUtil.formatDateTime(data.created)}</small></td>
        <td>
          <Link to={`/settings/property/notification-settings/logs/${data.hash}`}>
            Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
          </Link>
        </td>
      </tr>
    );
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={5}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faEnvelopeOpen}/> No Notification </h4>
            {
              Strings.propertyNotificationListEmptyText.map((data, i) => <p key={i}>{data}</p>)
            }
          </div>
        </td>
      </tr>
    );
  };

  tableHeader = () => {
    return (
      <tr>
        <th>Subject</th>
        <th>Channel</th>
        <th>Status</th>
        <th>Origin</th>
        <th className={'data-table-cell-md'}/>
      </tr>
    );
  };

  render = () => {

    const {loading, error, refresh, fileDownload} = this.state;
    const {property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="notification-list screen-container ">
        <Helmet>
          <title>Sent Notifications</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={'Notifications Logs'}
          description={`Notifications Sent from property : ${property.name}.`}
        />


        <div className={'row'}>
          <div className={'col-12 col-sm-9'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.setState({refresh: true})}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                </button>
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Channel'}
                  data={Filters.notificationChannel}
                  selectKey={this.getQueryParams('channel')}
                  action={(data) => this.setQueryParams([{key: 'channel', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Created'}
                  data={Filters.createdType}
                  selectKey={this.getQueryParams('created_type')}
                  action={(data) => this.setQueryParams([{key: 'created_type', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data))}
                  size={'sm'}
                  maxDate={Moment().add(1, 'days')}
                />
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-3 text-center text-sm-right'}>
            <ExportButton
              size={'sm'}
              files={['csv']}
              action={(key) => this.fetchData(this.state.page, null, null, {file: key})}
              loading={fileDownload}
              disabled={!this.state.startDate}
            />
          </div>
        </div>


        <SearchBar
          refresh={refresh}
          renderRow={this.renderRow}
          fetchData={this.fetchData}
          emptyView={this.emptyView}
          tableHeader={this.tableHeader}
          colSpan={5}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      refresh: false,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationLogList);