/**
 *
 * Property Booking Engine Settings
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Consts and Libs
import AppAPI from '../../../lib/api';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import RoomInventoryList from '../../../components/functional/RoomInventoryList';
// Actions
import * as PropertyActions from '../../../redux/property/actions';
import PageHeader from '../../../components/dashboard/PageHeader';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  propertyContent: state.property.content,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetPropertyDetails: PropertyActions.isSetPropertyDetails,
};

/* Component ==================================================================== */
class PropertyBookingEngine extends Component {
  static componentName = 'PropertyBookingEngineSettings';

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {property} = this.props;
    if (property.booking_engine_ok) {
      AppAPI.roomapi.get('room-type-overview/?allow_booking_engine=true')
        .then((res) => {
          this.setState({
            loading: false,
            error: null,
            roomTypes: res.room_types,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  render = () => {
    const {loading, error, roomTypes} = this.state;

    if (!this.props.property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="channel-manager-settings screen-container">
        <Helmet>
          <title>Booking Engine Settings</title>
        </Helmet>
        <PageHeader
          subHeader={true}
          title={'Inventory Config'}
          description={'Manage distribution of room type and packages connected to booking engine.'}
        />
        <div>
          <RoomInventoryList channelManager={false} refreshContent={this.fetchInitData} roomTypes={roomTypes}/>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      roomTypes: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyBookingEngine);
