/**
 *
 * Room Details
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants, Strings} from '../../../constants';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import {Alerts} from '../../../components/ui';
import BookingRoomListView from '../../bookingroom/BookingRoomListView';
import {confirmAlert} from '../../../components/general/ConfirmAlert';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBroom,
  faCheckCircle,
  faChevronCircleDown,
  faExclamationCircle,
  faTimesCircle, faToolbox,
  faUnlock,
  faUserClock
} from '@fortawesome/free-solid-svg-icons';
import RoomServiceRequest from '../models/RoomServiceRequest';

// Actions
import * as RoomActions from '../../../redux/room/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  room: state.room.roomHousekeeping,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setRoom: RoomActions.setRoomHousekeeping,
};

/* Component ==================================================================== */
class HousekeepingDetails extends Component {
  static componentName = 'RoomDetails';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
  };


  updateRoom = (action, value) => {
    if (action) {
      let payload = {};
      switch (action) {
      case 'maintenance_status':
        payload.maintenance_status = value;
        break;
      case 'service_status':
        payload.service_status = value;
        break;
      default:
        break;
      }
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.housekeeping
          .patch(this.props.room.id + '/', payload)
          .then(res => {
            this.setState({resultMsg: {success: ''}});
            this.props.setRoom(res);

            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  unLinkBooking = () => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.roomapi
        .post('room/' + this.props.room.id + '/unlink-booking/', {
          room_id: this.props.room.id,
        })
        .then(() => {
          this.setState({resultMsg: {success: ''}});
          this.props.setRoom(this.props.room.id);

          this.setState({resultMsg: {success: 'Success'}}, () => {
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            resultMsg: {error},
          });
        });
    });
  };

  cacheReset = () => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.roomapi
        .post(`room/${this.props.room.hash}/cache-reset/`, {})
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            this.props.setRoom(res);
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            resultMsg: {error},
          });
        });
    });
  };

  render = () => {


    const {loading, error, resultMsg} = this.state;
    const {room} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    return (
      <div className="room-details screen-container ">
        <Helmet>
          <title>Room Details | {room.name}</title>
        </Helmet>


        <div className="row">
          <div className="col-lg-8 col-sm-12 mb-5 col-md-8 col-sm-12">
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                <ul className="list-group">
                  <li className="list-group-item"><span className="glyphicon glyphicon-user"/>
                    <strong>Room Name</strong> : {room.name}
                    {room.buffer && <span className={'ml-2 badge badge-warning'}>Buffer</span>}
                  </li>
                  <li className="list-group-item"><span className="glyphicon glyphicon-user"/>
                    <strong>Room Type</strong> : {room.attributes && room.attributes.room_type_name}</li>
                  <li className="list-group-item">
                    <strong>Floor Level </strong> : {room.floor_level !== null ? room.floor_level : 'N.A'}
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Service Status</strong> :
                    <span className={'badge ' + (room.service_status === 1 ? 'badge-success' : 'badge-danger')}>
                      {room.service_status_display}
                    </span>
                  </li>
                  <li className="list-group-item"><span className="glyphicon glyphicon-user"/>
                    <strong>Availability Status</strong> : <span
                      className={'badge ' + (room.availability_status === -1 ? 'badge-danger' : room.availability_status === 1 ? 'badge-success' : 'badge-info')}>
                      {room.availability_status_display}</span>
                  </li>
                  <li className="list-group-item">
                    <strong>Operation Status</strong> :
                    <span className={'badge ' + (room.maintenance_status === 1 ? 'badge-success' : 'badge-danger')}>
                      {room.maintenance_status_display}
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 mb-5">
                <div className={'border p-2 text-center'}>
                  <h5 className={'mb-0'}>
                    <FontAwesomeIcon
                      icon={faChevronCircleDown}
                      size={'sm'}
                      className={'mx-2 green-cl float-left mt-1'}
                    />
                    Housekeeping Actions
                    <FontAwesomeIcon
                      icon={faChevronCircleDown}
                      size={'sm'}
                      className={'mx-2 green-cl float-right mt-1'}
                    />
                  </h5>
                </div>
                <div className={'py-2 border'}>
                  <div className={'row'}>
                    <div className={'col-3 text-center p-2'}>
                      <FontAwesomeIcon
                        icon={faExclamationCircle} size={'3x'}
                        onClick={() => confirmAlert({
                          title: 'Confirm Action',
                          message: Strings.houseKeepingRequestCleaningText,
                          buttons: [
                            {
                              className: 'btn-warning',
                              label: 'Confirm',
                              onClick: () => room.service_status !== Constants.ROOM.CLEANING_REQUESTED ? this.updateRoom('service_status', Constants.ROOM.CLEANING_REQUESTED) : null,
                            },
                            {
                              className: 'btn btn-secondary',
                              label: 'Cancel',
                            },
                          ],
                        })}
                        className={'mx-2 anchor ' + (room.service_status === Constants.ROOM.CLEANING_REQUESTED ? 'grey-cl' : 'orange-cl')}/>
                      <p className={'small mb-0'}>Request Cleaning</p>
                    </div>
                    <div className={'col-3 text-center p-2'}>
                      <FontAwesomeIcon
                        icon={faCheckCircle} size={'3x'}
                        onClick={() => confirmAlert({
                          title: 'Confirm Action',
                          message: Strings.houseKeepingCleanText,
                          buttons: [
                            {
                              className: 'btn-success',
                              label: 'Confirm',
                              onClick: () => room.service_status !== Constants.ROOM.CLEAN ? this.updateRoom('service_status', Constants.ROOM.CLEAN) : null,
                            },
                            {
                              className: 'btn btn-secondary',
                              label: 'Cancel',
                            },
                          ],
                        })}
                        className={'mx-2 anchor ' + (room.service_status === Constants.ROOM.CLEAN ? 'grey-cl' : 'green-cl')}/>
                      <p className={'small mb-0'}>Clean</p>
                    </div>
                    <div className={'col-3 text-center p-2'}>
                      <FontAwesomeIcon
                        icon={faTimesCircle} size={'3x'}
                        onClick={() => confirmAlert({
                          title: 'Confirm Action',
                          message: Strings.houseKeepingDirtyText,
                          buttons: [
                            {
                              className: 'btn-danger',
                              label: 'Confirm',
                              onClick: () => room.service_status !== Constants.ROOM.DIRTY ? this.updateRoom('service_status', Constants.ROOM.DIRTY) : null,
                            },
                            {
                              className: 'btn btn-secondary',
                              label: 'Cancel',
                            },
                          ],
                        })}
                        className={'mx-2 anchor ' + (room.service_status === Constants.ROOM.DIRTY ? 'grey-cl' : 'red-cl')}/>
                      <p className={'small mb-0'}>Dirty</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 mb-5">
                <div
                  className={'border p-2 text-center anchor'} data-toggle="collapse" href="#maintinanceActions"
                  role="button" aria-expanded="false" aria-controls="maintinanceActions">
                  <h5 className={'mb-0'}>
                    <FontAwesomeIcon
                      icon={faChevronCircleDown} size={'sm'}
                      className={'mx-2 green-cl float-left mt-1'}
                    />
                    Request
                    <FontAwesomeIcon
                      icon={faChevronCircleDown} size={'sm'}
                      className={'mx-2 green-cl float-right mt-1'}
                    />
                  </h5>
                </div>
                <div className={'py-2 border'} id={'maintinanceActions'}>
                  <div className={'row text-center'}>
                    <div className={'col-3 text-center p-2'}>
                      <FontAwesomeIcon
                        icon={faToolbox} size={'3x'}
                        onClick={() => this.setState({
                          showRoomServiceRequestType: Constants.ROOM_SERVICE_TYPE.MAINTENANCE,
                          showRoomServiceRequest: true,
                        })}
                        className={'mx-2 anchor orange-cl'}
                      />
                      <p className={'small mb-0'}>Maintenance Request</p>
                    </div>
                    <div className={'col-3 text-center p-2'}>
                      <FontAwesomeIcon
                        icon={faBroom} size={'3x'}
                        onClick={() => this.setState({
                          showRoomServiceRequestType: Constants.ROOM_SERVICE_TYPE.AMENITY,
                          showRoomServiceRequest: true,
                        })}
                        className={'mx-2 anchor green-cl'}/>
                      <p className={'small mb-0'}>Amenity Request</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            {(room.booking_room_data && room.booking_room_id)&& (
              <div>
                <div className="spacer-20"/>
                <h6>Allotted Booking</h6>
                <div>
                  <BookingRoomListView
                    id={'list-row-'}
                    data={room.booking_room_data}
                    readonly={true}
                    history={this.props.history}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item  align-items-center" onClick={() => this.cacheReset()}>
                <small>Room Actions</small>
              </li>
              <li className="list-group-item">
                <Link to={`/house-keeping/room/${room.hash}/change-logs/`}>
                  <FontAwesomeIcon icon={faUserClock} size={'sm'} className={'mr-2'}/> Change Logs
                </Link>
                <p className={'small text-muted mb-0'}>Previous actions / updates on the room.</p>
              </li>
              <li className="list-group-item">
                <button
                  onClick={() => confirmAlert({
                    title: 'Confirm Action',
                    message: Strings.unlinkBookingText,
                    buttons: [
                      {
                        className: 'btn-success',
                        label: 'Unlink Booking',
                        onClick: () => (room.booking_room_id ? this.unLinkBooking() : null),
                      },
                      {
                        className: 'btn btn-secondary',
                        label: 'Cancel',
                      },
                    ],
                  })}
                  className="btn btn-link orange-cl m-0 p-0" disabled={!room.booking_room_id}>
                  <FontAwesomeIcon icon={faUnlock} size={'sm'} className={'mr-2'}/> Unlink Booking
                </button>
                <p className={'small text-muted mb-0'}>Requires Manager Permission.</p>
              </li>
            </ul>
            <button
              onClick={() => this.setState({
                showRoomServiceRequestType: Constants.ROOM_SERVICE_TYPE.SERVICE,
                showRoomServiceRequest: true,
              })}
              className={'btn btn-link text-muted m-0 p-0 mr-2 '}
            >
              .
            </button>
            <button
              onClick={() => this.setState({
                showRoomServiceRequestType: Constants.ROOM_SERVICE_TYPE.AMENITY,
                showRoomServiceRequest: true,
              })}
              className={'btn btn-link text-muted m-0 p-0 mr-2'}
            >
              .
            </button>
            <button
              onClick={() => this.setState({
                showRoomServiceRequestType: Constants.ROOM_SERVICE_TYPE.MAINTENANCE,
                showRoomServiceRequest: true,
              })}
              className={'btn btn-link text-muted m-0 p-0 '}
            >
              .
            </button>
          </div>

        </div>

        <RoomServiceRequest
          room={room.id}
          bookingRoom={room.booking_room_id ? room.booking_room_data : null}
          show={this.state.showRoomServiceRequest}
          serviceType={this.state.showRoomServiceRequestType}
          onHide={() => this.setState({showRoomServiceRequest: false})}
          updateResponse={()=>this.props.history.push(`/house-keeping/room/${room.hash}/service-request/`)}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      showRoomServiceRequest: false,
      showRoomServiceRequestType: Constants.ROOM_SERVICE_TYPE.SERVICE,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HousekeepingDetails);