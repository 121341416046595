import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Route, Switch} from 'react-router-dom';
// Components
import ReportsList from './ReportsList';
import PageHeader from '../../components/dashboard/PageHeader';

class ReportsListIndex extends Component {
  static propTypes = {
    pos: PropTypes.object,
    event: PropTypes.object,
    match: PropTypes.object,
    baseUrl: PropTypes.string,
    dateType: PropTypes.object,
    escalated: PropTypes.bool,
    hideHeader: PropTypes.bool,
    headerTitle: PropTypes.string,
    reportOptions: PropTypes.object,
    headerSubTitle: PropTypes.string,
  };


  static defaultProps = {
    baseUrl: '',
    headerTitle: 'Report\'s',
    headerSubTitle: 'All report generated in the property. Choose category to filter reports.'
  };

  render = () => {
    const {
      baseUrl, hideHeader, headerTitle, headerSubTitle, pos, event, source, reportOptions, dateType, escalated
    } = this.props;

    return (
      <div>
        {!hideHeader &&
        <PageHeader
          history={this.props.history} title={headerTitle}
          description={headerSubTitle}
        />
        }

        <div className={'row'}>
          <div className={'col-lg-12 col-md-12 col-sm-12'}>
            <Switch>
              <Route
                path={`${baseUrl}/reports/list/`} exact
                render={(props) =>
                  <ReportsList
                    {...props}
                    pos={pos}
                    event={event}
                    source={source}
                    dateType={dateType}
                    escalated={escalated}
                    reportOptions={reportOptions}
                  />
                }
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}


export default ReportsListIndex;
