/**
 *
 * Payment Terminal List
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Strings} from '../../../constants';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import ListView from '../../../components/general/ListView';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCashRegister,
  faCheckCircle,
  faLongArrowAltRight,
  faPlus,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PaymentTerminalList extends Component {
  static componentName = 'PaymentTerminalList';
  componentDidMount = () => {
  };
  fetchTaxClassList = (page = 1, callback) => {
    AppAPI.propertyapi.get('payment-terminal/?page=' + page)
      .then((res) => {
        this.setState({
          loading: false,
          error: null,
        });
        if (res.results.length > 0) {
          if (res.next) {
            callback(res.results);
          } else {
            callback(res.results, {
              allLoaded: true, // the end of the list is reached
            });
          }
        } else {
          callback();
        }
      })
      .catch((err) => {
        callback(null, {
          allLoaded: true, // the end of the list is reached
        });
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };

  renderRow = (data, key) => {
    return (
      <tr key={key}>
        <td>
          <Link to={`/settings/property/payment-settings/payment-terminal/${data.id}`}>
            {data.name}
          </Link>
        </td>
        <td>{data.payment_method_display}</td>
        <td>
          <FontAwesomeIcon
            icon={data.payment_allowed ? faCheckCircle : faTimesCircle}
            className={`mr-2 ${data.payment_allowed ? 'green-cl' : 'grey-cl'}`}
          />
        </td>
        <td>
          <FontAwesomeIcon
            icon={data.refund_allowed ? faCheckCircle : faTimesCircle}
            className={`mr-2 ${data.refund_allowed ? 'green-cl' : 'grey-cl'}`}
          />
        </td>
        <td>
          <FontAwesomeIcon
            icon={data.active ? faCheckCircle : faTimesCircle}
            className={`mr-2 ${data.active ? 'green-cl' : 'grey-cl'}`}
          />
        </td>
        <td>
          <Link to={`/settings/property/payment-settings/payment-terminal/${data.id}/`}>
            Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
          </Link>
        </td>
      </tr>
    );
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={6}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faCashRegister}/> Payment Terminals </h4>
            {Strings.paymentTerminalListEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };

  render = () => {
    const {loading, error} = this.state;
    const {property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="tax-list screen-container ">
        <Helmet>
          <title>Payment Terminal</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={'Payment Terminals'} description={`Payment terminals available in ${property.name}.`}
        />


        <div className="row">
          <div className="col-lg-9 col-md-8 col-sm-12">
            <table className={'table border format-table'}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Method</th>
                  <th>Payment</th>
                  <th>Refund</th>
                  <th>Status</th>
                  <th className={'data-table-cell-xm'}/>
                </tr>
              </thead>
              <tbody>
                <ListView
                  rowView={this.renderRow}
                  onFetch={this.fetchTaxClassList}
                  firstLoader={true}
                  pagination
                  emptyView={this.emptyView}
                  class={'list-group'}
                  colSpan={6}
                />
              </tbody>
            </table>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item">
                <Link to={'/settings/property/payment-settings/payment-terminal/new'} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Payment Terminal
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTerminalList);