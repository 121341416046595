/**
 * Order Payment Model
 * Contains details of order payment / refund
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import React, {Component} from 'react';

// Consts and Libs
import AppAPI from '../../../lib/api';

// Components
import Error from '../../../components/general/Error';
import OrderPaymentsList from '../components/OrderPaymentsList';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLinkAlt, faTimes} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */

class OrderDetailsModel extends Component {
  static componentName = 'OrderDetailsModel';

  static propTypes = {
    order: PropTypes.object.isRequired,
    property: PropTypes.object.isRequired,
    fetchPOSOrderData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedTab: 'payments',
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        order: nextProps.order,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
        loading: true,
        reportData: {},
      });
    }
    return true;
  }

  fetchOrderData = (orderData) => {
    this.setState({
      loading: true,
    }, () => {
      AppAPI.order.get(`${orderData}/`)
        .then((res) => this.setState({loading: false, order: res}))
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    });
  };


  render = () => {
    const {show, property} = this.props;
    const {error, order} = this.state;

    const selectedTab = this.state.selectedTab || this.props.selectedTab;

    if (!show || _.isEmpty(order)) return null;

    if (error) return <Error full={true} text={error}/>;

    let selectElement;
    switch(selectedTab){
    default:
      selectElement = <OrderPaymentsList
        order={order} property={property} fetchPOSOrderData={this.props.fetchPOSOrderData}
      />;
    }


    return (
      <Modal
        {...this.props}
        size={'lg'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Order Details #{order.ref_no}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectElement}
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          <div className={'row'}>
            <div className={'col'}>
              <Link
                rel='noopener noreferrer' target={'_blank'}
                to={`/order/${order.hash}`} className={'btn btn-outline-primary btn-lg btn-block'}
              >
                <FontAwesomeIcon className={'mr-2'} icon={faExternalLinkAlt} size={'sm'}/> Order Details
              </Link>
            </div>
            <div className={'col'}>
              <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      disabledLink: true,
      error: false,
      init: true,
      order: {},
    };
  }
}

/* Export Component ==================================================================== */
export default OrderDetailsModel;
