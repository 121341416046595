/**
 * Manage Rate Constrains
 *  manages seasonal rates constrains
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import Moment from 'moment';
import * as Yup from 'yup';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
// Components
import {Alerts} from '../../../components/ui';
import DateRangePicker from 'react-daterange-picker';
import Loading from '../../../components/general/Loading';
import {faFile, faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Checkbox, Form, Input, Select, SubmitBtn} from 'react-formik-ui';
import {Strings} from '../../../constants';

const moment = require('moment');

/* Component ==================================================================== */

class AvailabilityBulkUpdate extends Component {
  static componentName = 'AvailabilityBulkUpdate';

  static propTypes = {
    match: PropTypes.object,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    property: PropTypes.object,
    roomTypes: PropTypes.array,
    rateUpdate: PropTypes.bool,
    channelList: PropTypes.array,
    updateResponse: PropTypes.func,
    roomTypeSelect: PropTypes.number,
    restrictionList: PropTypes.array,
    roomTypePackageSelect: PropTypes.number,
    restrictionListOptions: PropTypes.array,
  };


  static defaultProps = {
    minDate: Moment().startOf('day'),
    maxDate: Moment().add(30, 'days'),
  }


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        loading: true,
        channelList: this.props.channelList,
        minDate: Moment(this.props.minDate),
        maxDate: this.props.maxDate ? Moment(this.props.maxDate) : null,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  rateBulkUpdate = (credentials) => {
    const {channelList, restrictionList} = this.props;
    if (credentials) {

      let payload = {
        'room_type_package': credentials.roomTypePackage,
        'end_date': moment(this.state.end).format('YYYY-MM-DD'),
        'start_date': moment(this.state.start).format('YYYY-MM-DD'),
      };

      if (credentials.basePrice) {
        payload['base_price'] = credentials.basePrice;
      } else {
        restrictionList.forEach((data) => {
          if (channelList.includes('booking_engine')) {
            if (credentials[`booking_engine_${data}`] !== null) {
              payload[`booking_engine_${data}`] = credentials[`booking_engine_${data}`];
            }
          }

          if (channelList.includes('channel_manager')) {
            if (credentials[`channel_manager_${data}`] !== null) {
              payload[`channel_manager_${data}`] = credentials[`channel_manager_${data}`];
            }
          }

          if (channelList.includes('internal_engine')) {
            if (credentials[`internal_engine_${data}`] !== null) {
              payload[`internal_engine_${data}`] =credentials[`internal_engine_${data}`];
            }
          }
        });
      }

      if (!_.isEmpty(payload)) {
        this.setState({resultMsg: {status: 'One moment...'}}, () => {
          AppAPI.rateapi.post('rate-update/', payload)
            .then(() => {
              this.setState({resultMsg: {success: 'Success'}},
                () => {
                  setTimeout(() => {
                    this.setState({resultMsg: {success: ''}});
                    if (this.props.updateResponse) {
                      this.props.updateResponse();
                    }
                    this.props.onHide();
                  }, 500);
                });
            })
            .catch((err) => {
              const error = AppAPI.handleError(err);
              this.setState({resultMsg: {error}});
            });
        });
      }
    }
  };


  preFillData = () => {
    const {roomTypes, restrictionList, channelList} = this.props;
    let roomTypesData = [], roomTypePackageData = {}, roomTypePackageTaxData = {};
    if (roomTypes && roomTypes.length) {
      roomTypes.forEach(data => {
        roomTypesData.push({
          'value': data.id,
          'label': data.name,
        });

        roomTypePackageData[parseInt(data.id)] = [];
        if (!_.isEmpty(data.packages)){
          data.packages.forEach(packageData => {
            roomTypePackageTaxData[packageData.id] = packageData.tax_cache;
            roomTypePackageData[parseInt(data.id)].push({
              'value': packageData.id,
              'label': packageData.name
            });
          });
          roomTypePackageData[parseInt(data.id)] = roomTypePackageData[parseInt(data.id)].reverse();
        }

      });
      roomTypesData = roomTypesData.reverse();
    }

    let validation = {
      roomType: Yup.number().required(),
      roomTypePackage: Yup.number().required(),
    };

    let initialValues = {
      roomType: this.props.roomTypeSelect || '',
      roomTypePackage: this.props.roomTypePackageSelect || '',
    };

    const lengthOfStayValidation = Yup.number().min(1, 'Must be 1 or greater').max(28, 'Must be 28 or less').required().integer();
    channelList.forEach((data) => {
      if (restrictionList.includes('restriction')){
        initialValues[`${data}_restriction`] = false;
      }

      if (restrictionList.includes('closed_for_checkin')){
        initialValues[`${data}_closed_for_checkin`] = false;
      }

      if (restrictionList.includes('closed_for_checkout')){
        initialValues[`${data}_closed_for_checkout`] = false;
      }

      if (restrictionList.includes('min_length_of_stay')){
        initialValues[`${data}_min_length_of_stay`] = '1';
        validation[`${data}_min_length_of_stay`] = lengthOfStayValidation;
      }

      if (restrictionList.includes('max_length_of_stay')){
        initialValues[`${data}_max_length_of_stay`] = '28';
        validation[`${data}_max_length_of_stay`] = lengthOfStayValidation;
      }

      if (restrictionList.includes('min_length_of_stay') && restrictionList.includes('max_length_of_stay')) {
        validation[`${data}_max_length_of_stay`] = lengthOfStayValidation.moreThan(Yup.ref(`${data}_min_length_of_stay`), 'Must be greater than min length.');
      }
    });


    this.setState(
      {
        roomTypesData,
        roomTypePackageData,
        roomTypePackageTaxData,
        initialValues: initialValues,
        formValidation: Yup.object().shape(validation),
        init: false,
        loading: false,
        start: this.props.minDate,
        end: this.props.minDate,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      },
    );
  };

  onSelect = (date) => {
    const {selectionType} = this.state;
    if (selectionType === 'range') {
      this.setState({
        start: date.start,
        end: date.end,
      });
    } else {
      this.setState({start: date, end: date});
    }
  };

  render = () => {

    const {loading, init, resultMsg, roomTypesData, roomTypePackageData, start, end, formValidation, initialValues, selectionType, channelList, roomTypePackageTaxData} = this.state;
    const {show, restrictionListOptions, restrictionList, rateUpdate} = this.props;

    if (!show) return <React.Fragment/>;

    if (show && init) {
      this.preFillData();
    }

    if (loading) return null;

    return (
      <Modal
        {...this.props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Bulk Update Constrains
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={formValidation}
          onSubmit={values => this.rateBulkUpdate(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                {loading ? <Loading heightMatch={false}/>
                  :
                  <Form>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                        <div className="btn-group btn-group-sm w-100 mb-2" role="group">
                          <button
                            type="button"
                            disabled={true}
                            className={'btn btn-outline-secondary'}
                          >
                            Date Selection
                          </button>
                          <button
                            type="button"
                            className={`btn ${selectionType === 'single' ? 'btn-primary' : 'btn-outline-secondary'}`}
                            onClick={() => this.setState({selectionType: 'single', endDate: null})}
                          >
                            Single
                          </button>
                          <button
                            type="button"
                            className={`btn ${selectionType === 'range' ? 'btn-primary' : 'btn-outline-secondary'}`}
                            onClick={() => this.setState({selectionType: 'range'})}
                          >
                            Range
                          </button>
                        </div>
                        <div>
                          <div className="bg-white">
                            <div className="text-center mt-2">
                              <DateRangePicker
                                className={'w-100'}
                                selectionType={selectionType}
                                minimumDate={this.state.minDate}
                                maximumDate={this.state.maxDate}
                                value={start && (selectionType === 'single' ? start : Moment.range(start, end))}
                                onSelect={this.onSelect}
                                numberOfCalendars={2}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                        {rateUpdate &&
                        <div className="form-row">
                          <div className="col-lg-6 col-md-12 form-group">
                            <Input
                              type={'number'}
                              name={'basePrice'}
                              label={'Base Price for the package.'}
                              hint={'Price without tax.'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-lg-6 col-md-12 form-group">
                            <Input
                              disabled={true}
                              value={(formikProps.values.basePrice && formikProps.values.roomTypePackage && roomTypePackageTaxData[formikProps.values.roomTypePackage]) ? (parseFloat(formikProps.values.basePrice) + parseFloat(AppUtil.calculateTax(roomTypePackageTaxData[formikProps.values.roomTypePackage], formikProps.values.basePrice, 1))).toFixed(2) : '...'}
                              type={'number'}
                              label={'Base Price + Tax'}
                              hint={`${roomTypePackageTaxData[formikProps.values.roomTypePackage] ? `Tax Classes : ${roomTypePackageTaxData[formikProps.values.roomTypePackage].description}` : 'N.A'}`}
                              name={'basePriceEffective'}
                              className={'form-control'}
                            />
                          </div>
                        </div>
                        }
                        {(!rateUpdate) &&
                          <table className={'table table-bordered text-center'}>
                            <thead>
                              <tr>
                                <th>Constrain</th>
                                <th className={`${channelList.includes('booking_engine') ? '' : 'd-none'}`}>Booking
                                  Engine
                                </th>
                                <th className={`${channelList.includes('channel_manager') ? '' : 'd-none'}`}>Channel
                                  Manager
                                </th>
                                <th className={`${channelList.includes('internal_engine') ? '' : 'd-none'}`}>Internal
                                  Engine
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {(_.isEmpty(restrictionList) || _.isEmpty(channelList)) ?
                                <tr>
                                  <td colSpan={1 + Object.keys(channelList).length}>
                                    <div className={'text-center py-5'}>
                                      <h6><FontAwesomeIcon icon={faFile}/> No Channel Selected </h6>
                                      {Strings.channelOptionSelectionEmpty.map((data, i) => <p key={i}
                                        className={'text-muted'}>{data}</p>)}
                                    </div>
                                  </td>
                                </tr>
                                :
                                <React.Fragment>
                                  {restrictionListOptions.filter(item => (item.input === 'bool' && item.editable !== false)).map((bookData, i) => (
                                    <tr key={i} className={`${restrictionList.includes(bookData.key) ? '' : 'd-none'}`}>
                                      <td className={'text-left align-middle'}>{bookData.name}</td>
                                      <td
                                        className={`${channelList.includes('booking_engine') ? 'booking_engine-box' : 'd-none'}`}>
                                        <Checkbox
                                          className={'mr-2'}
                                          name={`booking_engine_${bookData.key}`}
                                        />
                                      </td>
                                      <td
                                        className={`${channelList.includes('channel_manager') ? 'channel_manager-box' : 'd-none'}`}>
                                        <Checkbox
                                          className={'mr-2'}
                                          name={`channel_manager_${bookData.key}`}
                                        />
                                      </td>
                                      <td
                                        className={`${channelList.includes('internal_engine') ? 'internal_engine-box' : 'd-none'}`}>
                                        <Checkbox
                                          className={'mr-2'}
                                          name={`internal_engine_${bookData.key}`}
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                  {restrictionListOptions.filter(item => (item.input === 'number' && item.editable !== false)).map((bookData, i) => (
                                    <tr key={i} className={`${restrictionList.includes(bookData.key) ? '' : 'd-none'}`}>
                                      <td className={'text-left align-middle'}>{bookData.name}</td>
                                      <td
                                        className={`${channelList.includes('booking_engine') ? 'booking_engine-box' : 'd-none'}`}>
                                        <Input
                                          type={'number'}
                                          name={`booking_engine_${bookData.key}`}
                                          className={'form-control'}
                                        />
                                      </td>
                                      <td
                                        className={`${channelList.includes('channel_manager') ? 'channel_manager-box' : 'd-none'}`}>
                                        <Input
                                          type={'number'}
                                          name={`channel_manager_${bookData.key}`}
                                          className={'form-control'}
                                        />
                                      </td>
                                      <td
                                        className={`${channelList.includes('internal_engine') ? 'internal_engine-box' : 'd-none'}`}>
                                        <Input
                                          type={'number'}
                                          name={`internal_engine_${bookData.key}`}
                                          className={'form-control'}
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </React.Fragment>
                              }

                            </tbody>
                          </table>
                        }
                        <div className="form-row form-group">
                          <div className="col">
                            <Select
                              name='roomType'
                              className={'form-control'}
                              label='Room Type'
                              placeholder='Select an Option'
                              options={roomTypesData}
                              hint={'Select room type on to which the bulk update is to be applied to.'}
                              required
                            />
                          </div>
                          <div className="col">
                            <Select
                              disabled={!formikProps.values.roomType}
                              name='roomTypePackage'
                              className={'form-control'}
                              label='Package'
                              placeholder='Select an Option'
                              options={(formikProps.values && formikProps.values.roomType) ? roomTypePackageData[parseInt(formikProps.values.roomType)]: []}
                              hint={'Select package on to which the bulk update is to be applied to.'}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                }
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className="px-3">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <SubmitBtn
                      disabled={(!start || !end) || (!rateUpdate && (_.isEmpty(restrictionList) || _.isEmpty(channelList)))}
                      type="submit" className={'btn btn-success btn-lg btn-block'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/>
                      Update Restriction
                    </SubmitBtn>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: true,
      channelList: [],
      selectionType: 'range',
      initialValues: {
      },
    };
  }
}

/* Export Component ==================================================================== */
export default AvailabilityBulkUpdate;
