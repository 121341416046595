import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Route, Switch} from 'react-router-dom';
// Components
import RefundList from './RefundList';
import PageHeader from '../../components/dashboard/PageHeader';


class RefundListIndex extends Component {
  static propTypes = {
    match: PropTypes.object,
    hideHeader: PropTypes.bool,
    baseUrl: PropTypes.string,
    guestId: PropTypes.number,
    corporateId: PropTypes.number,
    pos: PropTypes.object,
    event: PropTypes.object,
  };


  static defaultProps = {
    baseUrl: '/payment',
  };

  render = () => {
    const {baseUrl, hideHeader, guestId, corporateId, pos, event} = this.props;

    return (
      <div>
        {!hideHeader &&
         <PageHeader
           history={this.props.history} title={'Refund\'s'}
           description={'All refunds made in the property. Choose category to filter refunds.'}
         />
        }

        <div className={'row'}>
          <div className={'col-12'}>
            <Switch>
              <Route
                path={`${baseUrl}/refund/list/`} exact
                render={(props) =>
                  <RefundList
                    guestId={guestId} corporateId={corporateId}
                    pos={pos} event={event}
                    {...props}
                  />
                }
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default RefundListIndex;