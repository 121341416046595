/**
 * Profile Records add or manage
 */
import _ from 'lodash';
import moment from 'moment';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import { Img } from 'react-image';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants} from '../../../constants';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
import { Checkbox, Datepicker, DropZone, Form, Input, Select, SubmitBtn, Textarea } from 'react-formik-ui';
import {Alerts} from '../../../components/ui';
import Loading from '../../../components/general/Loading';
// Forms
import * as Yup from 'yup';
import {Formik} from 'formik';

/* Component ==================================================================== */

class ProfileRecordManage extends Component {
  static componentName = 'ProfileRecordManage';

  static propTypes = {
    data: PropTypes.object,
    profile: PropTypes.object.isRequired,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        loading: true,
        record: null,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
        initialValues: {
          'record_type': '',
          'record_image': [],
          'file_type': 1,
          'record_number': '',
          'record_issue_date': null,
          'record_expiry_date': null,
          'description': '',
          'notes': '',
        },
      });
    }
    return true;
  }


  preFillData = () => {
    if (this.props.data) {
      AppAPI.profilerecords.get(this.props.data.hash + '/?profile=' + this.props.profile.id)
        .then((res) => {
          this.setState({
            record: res, init: false, loading: false, initialValues: {
              'notes': res.notes,
              'record_type': res.record_type,
              'description': res.description,
              'record_number': res.record_number,
              'record_issue_date': res.record_issue_date,
              'record_expiry_date': res.record_expiry_date,
              'record_image': [],
            },
          });
        },
        )
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}, init: false, loading: false});
        });
    } else {
      this.setState({init: false, loading: false});
    }
  };


  addProfileRecord = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});
      const formData = new FormData();
      formData.append('record_type', credentials.record_type);
      formData.append('notes', credentials.notes);
      formData.append('description', credentials.description);
      if (credentials.record_number) {
        formData.append('record_number', credentials.record_number);
      }
      if (credentials.record_expiry_date) {
        formData.append('record_expiry_date', credentials.record_expiry_date ? moment(credentials.record_expiry_date).format('YYYY-MM-DD') : null);
      }
      if (credentials.record_issue_date) {
        formData.append('record_issue_date', credentials.record_issue_date ? moment(credentials.record_issue_date).format('YYYY-MM-DD') : null);
      }
      formData.append('record_image', credentials.record_image ? credentials.record_image[0] : null);
      AppAPI.profilerecords.post('?profile=' + this.props.profile.id, formData, true)
        .then(() => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                if (this.props.onSave) { this.props.onSave(); }
                if (this.props.reloadData && !this.props.profile.record_exist) { this.props.reloadData(); }
                this.props.onHide();
              }, 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  updateProfileRecord = (credentials) => {
    const {record} = this.state;
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});
      const formData = new FormData();
      formData.append('record_type', credentials.record_type);
      formData.append('notes', credentials.notes);
      formData.append('description', credentials.description);
      if (credentials.record_expiry_date) {
        formData.append('record_expiry_date', credentials.record_expiry_date ? moment(credentials.record_expiry_date).format('YYYY-MM-DD') : null);
      }
      if (credentials.record_issue_date) {
        formData.append('record_issue_date', credentials.record_issue_date ? moment(credentials.record_issue_date).format('YYYY-MM-DD') : null);
      }
      if (credentials.record_image && credentials.record_image[0]) {
        formData.append('record_image', credentials.record_image[0]);
      }

      if (credentials.remove_file) {
        formData.append('record_image', new File([], ''));
      }

      AppAPI.profilerecords.patch(`${record.hash}/?profile=` + this.props.profile.id, formData, true)
        .then(() => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                if (this.props.onSave) {
                  this.props.onSave();
                }
                this.props.onHide();
              }, 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  render = () => {

    const {init, resultMsg, initialValues, record, loading} = this.state;
    const {show, profile, property} = this.props;
    const defaultCountry = property && property.country ? property.country.code : '';

    if (show && init) {
      this.preFillData();
    }

    if (!show) return null;

    const SUPPORTED_IMAGE_FORMATS = ['image/png', 'image/jpeg', 'image/jpg'];
    const FILE_SIZE = 4 * 1024 * 1024;
    const validation = {
      record_type: Yup.number().required('Select type of record.'),
      record_image: Yup.mixed()
        .test(
          'fileRequired',
          'Record file is required',
          value => (record ? true : value && value[0]),
        )
        .test(
          'fileFormat',
          'Unsupported Format',
          value => value && value[0] ? SUPPORTED_IMAGE_FORMATS.includes(value[0].type) : true,
        )
        .test('fileSize', 'File is too large < 4Mb', value => {
          return value && value[0] ? value[0].size <= FILE_SIZE : true;
        })
    };
    if (!_.isEmpty(property.config) && property.config.profile && property.config.profile.profile_advance_details) {
      validation['record_number'] = Yup.string().required('Required.');
      validation['record_expiry_date'] = Yup.string()
        .nullable()
        .when('record_type', {
          is: 2,
          then: Yup.string().required('Expiry date is required for the record.').nullable(),
        });
    }
    const formValidation = Yup.object().shape(validation);


    return (
      <Modal
        {...this.props}
        size={'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Profile Record Management
          </Modal.Title>
        </Modal.Header>
        {loading ? <Loading heightMatch={false}/> :
          <Formik
            onSubmit={(values) => (record ? this.updateProfileRecord(values) : this.addProfileRecord(values))}
            validationSchema={formValidation}
            initialValues={initialValues}>
            {({values}) => (
              <React.Fragment>
                <Modal.Body>
                  <Form>
                    <div className={'row'}>
                      <div className="col-6 form-group">
                        <Select
                          required
                          name='record_type'
                          label={'Record Type'}
                          className={'form-control'}
                          placeholder='Select an Option'
                          options={
                            profile.profile_type === Constants.PROFILE_TYPE.VENDOR
                              ? Constants.VENDOR_FILE_RECORD_TYPE
                              : defaultCountry === 'IN' ? Constants.FILE_RECORD_TYPE_INDIA : Constants.FILE_RECORD_TYPE
                          }
                        />
                      </div>
                      <div className="col-6 form-group">
                        <Input
                          name='description'
                          label={'Description'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    {(!_.isEmpty(property.config) && property.config.profile && property.config.profile.profile_advance_details) && (
                      <>
                        <div className={'row'}>
                          <div className="col-12 form-group">
                            <Input
                              required
                              name='record_number'
                              label={'Document Number'}
                              className={'form-control'}
                            />
                          </div>
                        </div>
                        <div className={'row'}>
                          <div className="col-6 form-group">
                            <p className={'mb-1'}>Document Issue Date</p>
                            <Datepicker
                              name='record_issue_date'
                              className={'form-control'}
                              hint={'Date format DD/MM/YYYY'}
                            />
                          </div>
                          <div className="col-6 form-group">
                            <p className={'mb-1'}>Document Expiry Date</p>
                            <Datepicker
                              name='record_expiry_date'
                              className={'form-control'}
                              minDate={values.record_issue_date}
                              hint={'Date format DD/MM/YYYY'}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className={'row'}>
                      <div className="col-12 form-group">
                        <Textarea
                          name='notes'
                          label={'Notes'}
                          className={'form-control'}
                        />
                      </div>
                    </div>

                    {record && record.record_image_signed && record.record_image_signed.record_2x &&
                      <div>
                        <div className={'record-container text-center'}>
                          <Img
                            className={'border p-1'}
                            src={record.record_image_signed.record_2x}
                            loader={<Loading heightMatch={false}/>}
                          />
                        </div>
                        <Checkbox
                          className={'mr-2'}
                          name='remove_file'
                          text='Check to delete image.'
                        />
                        <hr/>
                      </div>
                    }

                    <div className={'row'}>
                      <div className={'col-sm-12 form-group'}>
                        <DropZone
                          name='record_image'
                          label='File upload'
                          placeholder='Drop files here, or click to select files. ( jpeg or png files only < 4Mb )'
                          multiple={false}
                          withClearButton
                        />
                      </div>
                    </div>
                  </Form>

                </Modal.Body>
                <Modal.Footer className={'d-block'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <div className={'row'}>
                    <div className={'col-6'}>
                      <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                      </button>
                    </div>
                    <div className={'col-6'}>
                      <SubmitBtn className={'btn btn-success btn-lg btn-block'}>
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={record ? faSave : faPlus} size={'sm'}/>
                        {record ? 'Save' : 'Add'} Record
                      </SubmitBtn>
                    </div>
                  </div>
                </Modal.Footer>
              </React.Fragment>
            )}
          </Formik>
        }
      </Modal>
    );
  };


  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: false,
      record: null,
    };
  }
}

/* Export Component ==================================================================== */
export default ProfileRecordManage;
