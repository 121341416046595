/**
 *
 * Booking Channel Manager Logs
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
// Consts and Libs
import AppUtil from '../../../lib/util';
import AppAPI from '../../../lib/api';
import {Strings} from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageSubHeader from '../../../components/dashboard/PageSubHeader';
import BookingChannelLogDetails from '../models/BookingChannelLogDetails';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBookOpen, faCheckCircle, faReceipt, faSync, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
// Actions

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  booking: state.booking.booking,
});

// Any actions to map to the component?
const mapDispatchToProps = {};


/* Component ==================================================================== */
class BookingChannelLogs extends Component {
  static componentName = 'BookingChannelLogs';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    this.setState({loading: true});
    let appApi = AppAPI.booking.get(`${this.props.match.params.bookingId}/related-details/?key=booking-logs`);

    appApi.then((res) => {
      this.setState({
        response: res,
        loading: false,
      });
    })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };

  render = () => {

    const {loading, error, response, orderOpen} = this.state;
    const {booking} = this.props;


    if (loading || _.isEmpty(booking) || _.isEmpty(response)) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    const {booking_logs} = response;


    let bookingLogsList = booking_logs;

    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Channel Log(s)</title>
        </Helmet>

        <PageSubHeader
          icon={faBookOpen} title={'Channel Log(s)'} description={'Booking update log(s) from channel manager.'}
        />


        <div className={'row'}>
          <div className={'col-12 col-sm-8'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button
                  onClick={() => this.fetchInitData()}
                  className={`btn btn-sm btn-outline-${orderOpen ? 'primary' : 'secondary'}`}
                >
                  <FontAwesomeIcon icon={faSync} className={'mr-2'} size={'sm'}/> Refresh
                </button>
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-4 text-center text-sm-right'}>
          </div>
        </div>

        <div className="table-responsive border">
          <table className={'table table-hover mb-0 format-table'}>
            <thead>
              <tr>
                <th>Reference</th>
                <th className={'text-left'}>Type</th>
                <th className={'text-left'}>Update Type</th>
                <th>Status</th>
                <th>Time</th>
                <th/>
              </tr>
            </thead>
            <tbody>
              {!_.isEmpty(bookingLogsList) ?
                <React.Fragment>
                  {bookingLogsList.map((data, i) => {
                    return (
                      <tr className={'tr-align-middle'} key={i}>
                        <td className={'data-table-cell-lg'}>
                          {data.ref_no}
                        </td>
                        <td className={'data-table-cell-sm text-left'}>
                          <span className={`badge mx-1 ${AppUtil.bookingLogTypeColor(data.type)}`}>
                            {data.type_display}
                          </span>
                        </td>
                        <td className={'data-table-cell-lg text-left'}>
                          {data.update_type_display}
                        </td>
                        <td className={'data-table-cell-md'}>
                          <FontAwesomeIcon
                            icon={data.log_status ? faCheckCircle : faTimesCircle}
                            className={data.log_status ? 'green-cl' : 'red-cl'}
                          />
                        </td>
                        <td className={'data-table-cell-md'}>
                          <p className={'small mb-0'}>{AppUtil.formatDateTime(data.created)}</p>
                        </td>
                        <td className={'data-table-cell-xl'}>
                          <button
                            className={'btn btn-sm btn-outline-primary'}
                            onClick={()=>this.setState({
                              selectBookingChannelLog: data, showBookingChannelLogDetails: true
                            })}
                          >Log Details</button>
                        </td>
                      </tr>
                    );
                  })}
                </React.Fragment>
                :
                <tr>
                  <td colSpan={9}>
                    <div className={'text-center table-list-empty'}>
                      <h6><FontAwesomeIcon icon={faReceipt} className={'mr-2'}/> No Update Logs </h6>
                      <p className={'text-muted'}>{Strings.bookingLogsEmpty}</p>
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
        <BookingChannelLogDetails
          booking={booking}
          property={this.props.property}
          bookingLog={this.state.selectBookingChannelLog}
          show={this.state.showBookingChannelLogDetails}
          onHide={()=>this.setState({showBookingChannelLogDetails: false, selectBookingChannelLog: null})}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      orderOpen: false,
      orderSource: null,
      orderStatus: null,
      showPayment: false,
      orderCancelled: false,
      selectBookingChannelLog: null,
      showBookingChannelLogDetails: false,
      response: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(BookingChannelLogs);