/**
 *
 * Booking Details
 */
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import {Constants, Strings} from '../../../constants';
// Components
import NotesManager from '../../notes/NotesManager';
import BookingSummary from '../models/BookingSummary';
import Error from '../../../components/general/Error';
import GuestListView from '../../guest/GuestListView';
import NotesListView from '../../notes/NotesListView';
import {Alerts, History} from '../../../components/ui';
import Loading from '../../../components/general/Loading';
import MessagingManager from '../../messaging/MessagingManager';
import {confirmAlert} from '../../../components/general/ConfirmAlert';
import DateFormatted from '../../../components/general/DateFormatted';
import BookingRoomListView from '../../bookingroom/BookingRoomListView';
import ProfileDetailsOverview from '../../guest/model/ProfileDetailsOverview';
import BookingEdit from '../models/BookingEdit';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowsAltH,
  faCheckCircle,
  faFile, faFileDownload, faFilePdf,
  faLongArrowAltRight,
  faPaperPlane,
  faPen, faPencil,
  faPlus,
  faStickyNote,
  faTimesCircle,
  faUserClock,
} from '@fortawesome/free-solid-svg-icons';
import BookingEditMenu from '../models/BookingEditMenu';
import BookingConfirm from '../models/BookingConfirm';
import GuestQuickManage from '../../guest/GuestManageQuick';
import ProfileQuickSearch from '../../guest/ProfileQuickSearch';
import GuestDetailsOverView from '../../guest/GuestDetailsOverView';
import TimeLineList from '../../../components/dashboard/TimeLineList';
// Actions
import * as BookingActions from '../../../redux/booking/actions';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  booking: state.booking.booking,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setBooking: BookingActions.setBooking,
  setLookup: BookingActions.setLookup,
};

/* Component ==================================================================== */
class BookingOverView extends Component {
  static componentName = 'BookingOverView';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    if (this.props.match.params.bookingId) {
      this.fetchInitData();
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };


  fetchInitData = () => {
    if (this.props.firstLoad) {
      this.setState({
        loading: false,
      });
    } else {
      this.setState({
        loading: true,
      }, () => {
        this.props.setBooking(this.props.match.params.bookingId)
          .then(() => {
            this.setState({
              loading: false,
            });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({
              loading: false,
              error,
            });
          });
      });
    }
  };

  cacheReset = (type = 'all', callback=null) => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.bookingapi
        .post(`booking/${this.props.booking.hash}/cache-reset/?type=${type}`, {})
        .then(res => {
          this.props.setBooking(res);
          this.setState({resultMsg: {success: 'Success'}}, () => {
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
              if (callback) { callback(); }
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            resultMsg: {error},
          });
        });
    });
  };


  updateBooking = (payload) => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.booking
          .patch(this.props.booking.hash + '/', payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({
                  showProfileDetails: false,
                  showGuestManagement: false,
                  showProfileDetailsOverview: false,
                  showProfileSearch: false,
                  addProfile: false,
                  resultMsg: {success: ''},
                });
                this.props.setBooking(res);
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  regenerateOrder = (payload) => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.booking
          .post(`${this.props.booking.hash}/regenerate-order/`, payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.props.history.push(`/order/${res.hash}`);
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  bookingAction = (action) => {
    const {booking} = this.props;
    if (action === 'cancel' && booking) {
      this.props.history.push(`/booking/${booking.hash}/action/cancel/`);
    } else if (action === 'no-show') {
      this.props.history.push(`/booking/${booking.hash}/action/no-show/`);
    }
  };

  bookingEdit = (modType) => {
    this.setState({modType: modType, showEditMenu: true});
  };

  showProfileDetails = (guestId, add_guest = false) => {
    this.setState({profileDetailsData: guestId, showProfileDetails: true, addProfile: add_guest});
  };

  profileActions = (profile) => {
    if (profile) {
      if (profile.profile_type === Constants.PROFILE_TYPE.GUEST) {
        this.updateBooking({guest_data: this.state.addProfile ? profile.id : null});
      } else {
        this.updateBooking({corporate_data: this.state.addProfile ? profile.id : null});
      }
    }
  };

  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {booking} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const timeLine= [
      {
        'title': 'Created',
        'iconClass': 'green-cl',
        'description': AppUtil.formatDateTime(booking.created),
      },
      {
        'title': 'Confirm',
        'iconClass': booking.confirm_status ? 'green-cl' : 'grey-cl',
        'description': booking.booking_confirm_time ? AppUtil.formatDateTime(booking.booking_confirm_time) : '...'
      },
    ];

    if (booking.booking_active_time) {
      timeLine.push({
        'title': 'Checkin',
        'iconClass': 'green-cl',
        'description': booking.booking_active_time ? AppUtil.formatDateTime(booking.booking_active_time) : '...',
      });
    }

    if (booking.booking_active_time) {
      timeLine.push({
        'title': 'Complete',
        'iconClass': 'green-cl',
        'description': booking.booking_complete_time ? AppUtil.formatDateTime(booking.booking_complete_time) : '...',
      });
    }

    if (booking.booking_cancel_time) {
      timeLine.push({
        'title': 'Cancel',
        'iconClass': 'green-cl',
        'description': booking.booking_cancel_time ? AppUtil.formatDateTime(booking.booking_cancel_time) : '...',
      });
    }

    if (booking.booking_no_show_time) {
      timeLine.push({
        'title': 'No Show',
        'iconClass': 'green-cl',
        'description': booking.booking_no_show_time ? AppUtil.formatDateTime(booking.booking_no_show_time) : '...',
      });
    }

    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Booking Details</title>
        </Helmet>

        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />

            {!booking.confirm_status && booking.booking_active && (
              <div className={'mb-3'}><Alerts info={Strings.bookingConfirmationText}/></div>
            )}

            <div className={'row'}>
              <div className={'col-12 col-lg-7'}>
                <ul className={'list-inline d-inline-flex list-inline-formatted'}>
                  <li className={'list-inline-item d-inline-flex align-items-center'}>
                    <DateFormatted date={booking.checkin} year={true}/>
                    <p className={'ml-3'}>
                      <strong>Checkin</strong> <br/>
                      {AppUtil.formatDateTime(booking.checkin, 'day')}
                    </p>
                  </li>
                  <li className={'mx-3 list-inline-item d-inline-flex align-items-center'}>
                    <div className={'text-center'}>
                      <FontAwesomeIcon icon={faLongArrowAltRight} size={'2x'}/>
                      {booking.checkin && booking.checkout &&
                      <p className={'text-muted text-center mb-0 small'}>
                        {`${AppUtil.diffDateTime(booking.checkin, booking.checkout)} Night(s)`}
                      </p>
                      }
                    </div>
                  </li>
                  <li className={'list-inline-item d-inline-flex align-items-center'}>
                    <DateFormatted date={booking.checkout} year={true}/>
                    <p className={'ml-3'}>
                      <strong>Checkout</strong> <br/>
                      {AppUtil.formatDateTime(booking.checkout, 'day')}
                    </p>
                  </li>
                </ul>


              </div>
              <div className={'col-12 col-lg-5'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Confirmation Status : </strong>
                    <span className={'badge ' + (booking.confirm_status ? 'badge-success' : 'badge-warning')}>
                      {booking.confirm_status ? 'Confirmed' : 'Not Confirmed'}
                    </span>
                  </li>
                  <li className="list-group-item">
                    <strong>Booking Status : </strong>
                    <span className={'badge ' + (AppUtil.bookingStatusColor(booking.booking_status))}>
                      {booking.booking_status_display}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <hr/>
            <div className={'row'}>
              <div className={'col-lg-6 col-md-12'}>
                <table className={'table table-borderless table-sm mb-3'}>
                  <tbody>
                    {booking.booking_id &&
                    <tr>
                      <td className={'w-50 text-muted'}>Booking ID</td>
                      <td><strong>{booking.booking_id}</strong></td>
                    </tr>
                    }
                    {booking.booking_agent_booking_id &&
                    <tr>
                      <td className={'w-50 text-muted'}>Agent Booking ID</td>
                      <td><strong>{booking.booking_agent_booking_id}</strong></td>
                    </tr>
                    }

                    {booking.booking_channel_manager_booking_id &&
                    <tr>
                      <td className={'w-50 text-muted'}>Channel Manager ID</td>
                      <td>{booking.booking_channel_manager_booking_id}</td>
                    </tr>
                    }
                    {booking.booking_channel_manager_second_id &&
                    <tr>
                      <td className={'w-50 text-muted'}>Channel Booking ID</td>
                      <td>{booking.booking_channel_manager_second_id}</td>
                    </tr>
                    }
                    <tr>
                      <td className={'w-50 text-muted'}>Source</td>
                      <td>{booking.booking_source_display}</td>
                    </tr>

                    {booking.booking_remarks &&
                    <tr>
                      <td className={'w-50 text-muted'}>Remarks</td>
                      <td>{booking.booking_remarks}</td>
                    </tr>
                    }
                    <tr>
                      <td className={'w-50 text-muted'}>
                        Visit Type
                      </td>
                      <td>
                        <div>
                          {booking.booking_visit_purpose_display}
                          {(booking.booking_active  && booking.booking_visit_purpose === 0)  && (
                            <button
                              type="button"
                              className={'btn btn-link m-0 p-0 ml-2 btn-sm'}
                              disabled={!booking.booking_active}
                              onClick={()=>this.setState({showBookingEdit: true})}
                            >
                              <FontAwesomeIcon icon={faPencil} size={'sm'}/> Edit
                            </button>
                          )}
                        </div>
                        {booking.booking_visit_purpose_description && (
                          <div className={'mt-1 small text-muted'}>({booking.booking_visit_purpose_description})</div>
                        )}
                      </td>
                    </tr>

                    {booking.booking_reference !== 0 &&
                    <tr>
                      <td className={'w-50 text-muted'}>Booking Reference</td>
                      <td>
                        <div>{booking.booking_reference_display}</div>
                        {booking.booking_reference_description && (
                          <div className={'mt-1 small text-muted'}>({booking.booking_reference_description})</div>
                        )}
                      </td>
                    </tr>
                    }
                  </tbody>
                </table>
              </div>

              <div className={'col-lg-6 col-md-12'}>
                <table className={'table table-borderless table-sm mb-3'}>
                  <tbody>
                    {!_.isEmpty(booking.attributes) &&
                    <React.Fragment>
                      <tr>
                        <td className={'w-50 text-muted'}>Created By</td>
                        <td>{booking.attributes.created_user || 'N.A'}</td>
                      </tr>
                    </React.Fragment>
                    }
                    {(booking.booking_channel_manager !== 0) &&
                    <tr>
                      <td className={'w-50 text-muted'}>Channel Manager</td>
                      <td>{booking.booking_channel_manager_display}</td>
                    </tr>
                    }

                    {(booking.booking_additional_info && booking.booking_additional_info.source) &&
                    <React.Fragment>
                      {booking.booking_additional_info.source.map((data, i) => (
                        <React.Fragment key={i}>
                          {data.company_name &&
                          <tr>
                            <td className={'w-50 text-muted'}>Company</td>
                            <td>
                              {data.company_name}
                              {data.primary && <span className={'ml-2 badge badge-warning'}>Primary</span>}
                            </td>
                          </tr>
                          }
                          {data.source_type &&
                          <tr>
                            <td className={'w-50 text-muted'}>Source</td>
                            <td>
                              {data.source_type}
                              {data.primary && <span className={'ml-2 badge badge-warning'}>Primary</span>}
                            </td>
                          </tr>
                          }
                        </React.Fragment>
                      ))}

                    </React.Fragment>
                    }

                    {booking.booking_source_created_time &&
                    <tr>
                      <td className={'w-50 text-muted'}>Created ( Source )</td>
                      <td>{AppUtil.formatDateTime(booking.booking_source_created_time)}</td>
                    </tr>
                    }

                    {booking.booking_source_update_time &&
                    <tr>
                      <td className={'w-50 text-muted'}>Edited ( Source )</td>
                      <td>{AppUtil.formatDateTime(booking.booking_source_update_time)}</td>
                    </tr>
                    }

                    {booking.attributes && booking.attributes.booking_ota_name &&
                    <tr>
                      <td className={'w-50 text-muted'}>OTA</td>
                      <td>{booking.attributes.booking_ota_name}</td>
                    </tr>
                    }

                  </tbody>
                </table>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                <p className={'text-muted mb-1'}>{booking.corporate ? 'Booked for' : 'Booked by'}</p>
                {booking.guest ?
                  <GuestListView
                    data={booking.guest}
                    action={() => booking.guest.id ? this.setState({showProfileDetailsOverview: true, showProfileDetailsOverviewId: booking.guest.id}) : null}
                  />
                  :
                  <React.Fragment>
                    {booking.booking_active &&
                    <button
                      type="button" className="btn btn-link m-0 p-0" data-toggle="modal"
                      onClick={() => this.setState({showGuestManagement: true, addProfile: true})}
                    >
                      Add Guest
                    </button>
                    }
                  </React.Fragment>
                }
              </div>
              <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                {booking.corporate ?
                  <React.Fragment>
                    <p className={'text-muted mb-1'}>{booking.booking_source_display}</p>
                    <GuestListView
                      data={booking.corporate}
                      action={() => booking.corporate.id ? this.showProfileDetails(booking.corporate.id) : null}
                    />
                  </React.Fragment>
                  :
                  <React.Fragment>
                    {(booking.booking_active && (parseInt(booking.booking_source) === Constants.BOOKING_SOURCE.CORPORATE || parseInt(booking.booking_source) === Constants.BOOKING_SOURCE.AGENT)) &&
                    <React.Fragment>
                      <h6>{booking.booking_source_display}</h6>
                      <button
                        type="button" className="btn btn-link m-0 p-0" data-toggle="modal"
                        onClick={() => this.setState({showProfileSearch: true})}
                      >
                        Add {booking.booking_source_display}
                      </button>
                    </React.Fragment>
                    }
                  </React.Fragment>
                }
              </div>
            </div>
            <div className={'spacer-20'}/>
            <TimeLineList timeLine={timeLine}/>
            <div className={'spacer-20'}/>
            <div className={'row'}>
              <div className={'col-lg-12 col-md-12 mb-3'}>
                <h5>Rooms</h5>
                {!_.isEmpty(booking.booking_rooms) ?
                  <div className="list-group">
                    {booking.booking_rooms.map((data, key) => (
                      <BookingRoomListView
                        data={data}
                        key={key}
                        title={`Room ${data.room_number}`}
                        history={this.props.history}
                        booking={booking}
                      />
                    ))}
                  </div> :
                  <div>
                    <p className={'small'}>{Strings.bookingBookingRoomEmpty}</p>
                  </div>
                }
              </div>
            </div>

            <div className={'spacer-20'}/>
            <div className={'row mb-3'}>
              <div className={'col'}>
                <p>
                  <Link to={`/booking/${booking.hash}/booking-order/`}>
                    View Order(s) <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
                  </Link>
                </p>
              </div>
            </div>

            <div>
              {!_.isEmpty(booking.notes) &&
              <div>
                <div className={'spacer-20'}/>
                <div className={'row'}>
                  <div className={'col-lg-6 col-md-12'}>
                    <h6>Notes</h6>
                    <div className="list-group">
                      {booking.notes.map((data, key) => (
                        <NotesListView
                          data={data}
                          key={key}
                          successAction={() => this.fetchInitData()}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              }
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group mb-5">
              <li className="list-group-item  align-items-center">
                <button onClick={() => this.setState({showNotesManager: true})} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faStickyNote} size={'sm'} className={'mr-2'}/> Add Note
                </button>
              </li>
              <li className="list-group-item  align-items-center">
                <button onClick={() => this.setState({showMessagingManager: true})} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faPaperPlane} size={'sm'} className={'mr-2'}/> Notification ( E-Mail / SMS )
                </button>
              </li>
              <li className="list-group-item">
                <a
                  href={booking.resources_link} rel='noopener noreferrer' target={'_blank'}
                  className={'btn btn-link m-0 p-0 '}>
                  <FontAwesomeIcon icon={faFilePdf} size={'sm'} className={'mr-2'}/> Booking Summary PDF
                </a>
              </li>
              <li className="list-group-item">
                <button
                  type="button" className={'btn btn-link m-0 p-0'}
                  onClick={() => this.setState({showBookingSummary: true})}
                >
                  <FontAwesomeIcon icon={faFileDownload} size={'sm'} className={'mr-2'}/> Booking Folio
                </button>
              </li>
            </ul>

            <ul className="list-group mb-5">
              <li className="list-group-item  align-items-center" onClick={() => this.cacheReset()}>
                <small>Booking Actions</small>
              </li>
              <li className="list-group-item">
                <button
                  type="button"
                  className={'btn btn-link m-0 p-0 green-cl'}
                  disabled={!booking.booking_active}
                  onClick={()=>this.setState({showBookingEdit: true})}
                >
                  <FontAwesomeIcon icon={faPencil} size={'sm'} className={'mr-2'}/> Edit Booking Info
                </button>
                <p className={'small text-muted mb-0'}>{Strings.bookingMetaEditText}</p>
              </li>
              <li className="list-group-item">
                <button
                  type="button"
                  className={'btn btn-link m-0 p-0 ' + (booking.confirm_status ? 'red-cl' : ' green-cl')}
                  disabled={!(booking.booking_status === Constants.BOOKING.UPCOMING)}
                  onClick={()=>this.setState({showBookingConfirm: true})}
                >
                  <FontAwesomeIcon
                    icon={(booking.confirm_status ? faTimesCircle : faCheckCircle)} size={'sm'}
                    className={'mr-2'}/> {booking.confirm_status ? 'Un-confirm Booking' : 'Confirm Booking'}
                </button>
                <p className={'small text-muted mb-0'}>
                  {!booking.confirm_status ? Strings.bookingConfirmationConfirmText : Strings.bookingConfirmationUnConfirmText}
                </p>
              </li>
              <li className="list-group-item">
                <button
                  type="button"
                  className={'btn btn-link m-0 p-0 red-cl'}
                  disabled={!booking.booking_active}
                  onClick={() => {
                    this.bookingAction('cancel');
                  }}
                >
                  <FontAwesomeIcon icon={faTimesCircle} size={'sm'} className={'mr-2'}/> Cancel Booking
                </button>
                <p className={'small text-muted mb-0'}>{Strings.bookingCancelText}</p>
              </li>
              <li className="list-group-item">
                <button
                  type="button"
                  className={'btn btn-link m-0 p-0 orange-cl'}
                  disabled={!booking.booking_active}
                  onClick={() => {
                    this.bookingAction('no-show');
                  }}
                >
                  <FontAwesomeIcon icon={faUserClock} size={'sm'} className={'mr-2'}/> No Show Booking
                </button>
                <p className={'small text-muted mb-0'}>{Strings.bookingNoShowText}</p>
              </li>
              <li className="list-group-item">
                <button
                  type="button"
                  className={'btn btn-link m-0 p-0 green-cl'}
                  disabled={!booking.booking_active}
                  onClick={() => {
                    this.bookingEdit(Constants.BOOKING_MODIFICATION_TYPES.ADD_ROOMS);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Room
                </button>
                <p className={'small text-muted mb-0'}>{Strings.bookingAddRoomsText}</p>
              </li>
              <li className="list-group-item">
                <button
                  type="button"
                  className={'btn btn-link m-0 p-0 blue-cl'}
                  disabled={!booking.booking_active}
                  onClick={() => {
                    this.bookingEdit(Constants.BOOKING_MODIFICATION_TYPES.EDIT_DATE);
                  }}
                >
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Change Booking Dates
                </button>
                <p className={'small text-muted mb-0'}>{Strings.bookingChangeDatesText}</p>
              </li>
              <li className="list-group-item">
                <button
                  type="button"
                  className={'btn btn-link m-0 p-0 green-cl'}
                  disabled={!(booking.booking_status === Constants.BOOKING.ACTIVE)}
                  onClick={() => {
                    this.bookingEdit(Constants.BOOKING_MODIFICATION_TYPES.EXTEND_DATE);
                  }}
                >
                  <FontAwesomeIcon icon={faArrowsAltH} size={'sm'} className={'mr-2'}/> Extend/Shrink Dates
                </button>
                <p className={'small text-muted mb-0'}>{Strings.bookingExtentDatesText}</p>
              </li>
              <li className="list-group-item">
                <button
                  type="button"
                  className={'btn btn-link m-0 p-0 green-cl'}
                  onClick={() => {
                    confirmAlert({
                      title: 'Regenerate Booking Order',
                      message: Strings.bookingRegenerateOrderConfirmText,
                      buttons: [
                        {
                          className: 'btn-secondary',
                          label: 'No',
                        },
                        {
                          className: 'btn-success',
                          label: 'Generate Order',
                          onClick: () => this.regenerateOrder({commit: true})
                        },
                      ],
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFile} size={'sm'}
                    className={'mr-2'}/> Regenerate Booking Order
                </button>
                <p
                  className={'small text-muted mb-0'}>{Strings.bookingRegenerateOrder}</p>
              </li>
            </ul>
          </div>
        </div>

        {/*  Booking Edit */}
        <BookingEditMenu
          booking={booking}
          modType={this.state.modType}
          onHide={() => this.setState({showEditMenu: false})}
          show={this.state.showEditMenu}
          history={this.props.history}
          bookingRooms={booking.booking_rooms}
          setLookup={this.props.setLookup}
        />

        {/* Profile Details */}
        <GuestDetailsOverView
          onHide={() => this.setState({showProfileDetails: false})}
          guestId={this.state.profileDetailsData}
          show={this.state.showProfileDetails}
          addGuestFunction={this.state.addProfile}
          action={this.profileActions}
          resultMsg={resultMsg}
        />

        {/*  Profile Overview */}
        {/* This is only used for viewing guest profile so few values are hard embedded. */}
        <ProfileDetailsOverview
          property={this.props.property}
          show={this.state.showProfileDetailsOverview}
          guestId={this.state.showProfileDetailsOverviewId}
          onHide={()=>this.setState({showProfileDetailsOverview: false, showProfileDetailsOverviewId: null})}
          showRecords={true}
          resultMsg={resultMsg}
          profileActionType={'-'}
          editControl={booking.booking_active}
          profileAction={booking.booking_active ? this.profileActions : null}
          cacheReset={booking.booking_active ? (callback) => this.cacheReset('guest', callback) : null}
        />

        {/* Profile Manage */}
        <GuestQuickManage
          onHide={() => this.setState({showGuestManagement: false})}
          show={this.state.showGuestManagement}
          property={this.props.property}
          showGuestDetails={this.showProfileDetails}
          guestAddAction={this.profileActions}
        />

        {/* Profile Search */}
        <ProfileQuickSearch
          onHide={() => this.setState({showProfileSearch: false})}
          show={this.state.showProfileSearch}
          showProfileDetails={this.showProfileDetails}
          isCorporate={parseInt(booking.booking_source) === Constants.BOOKING_SOURCE.CORPORATE}
          isAgent={parseInt(booking.booking_source) === Constants.BOOKING_SOURCE.AGENT}
          corporateBooking={true}
        />

        {/* Messaging */}
        <MessagingManager
          onHide={() => this.setState({showMessagingManager: false})}
          show={this.state.showMessagingManager}
          property={this.props.property}
          guests={booking.guest ? [booking.guest] : null}
          preFetchData={true}
          booking={booking}
        />

        {/* Booking confirmation */}
        <BookingConfirm
          onHide={()=>this.setState({showBookingConfirm: false})}
          show={this.state.showBookingConfirm}
          booking={booking}
          successAction={this.props.setBooking}
        />

        {/* Notes */}
        <NotesManager
          booking={booking}
          show={this.state.showNotesManager}
          successAction={() => this.props.setBooking(booking.id)}
          onHide={() => this.setState({showNotesManager: false})}
        />

        {/* BookingSummary */}
        <BookingSummary
          booking={booking}
          property={this.props.property}
          show={this.state.showBookingSummary}
          onHide={()=>this.setState({showBookingSummary: false})}
        />

        {/* Booking Edit */}
        <BookingEdit
          booking={this.props.booking}
          property={this.props.property}
          show={this.state.showBookingEdit}
          onHide={()=>this.setState({showBookingEdit: false})}
          updateResponse={this.props.setBooking}
        />

        <History object={booking}/>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      showEditMenu: false,
      showBookingSummary: false,
      modType: null,
      showGuestManagement: false,
      showNotesManager: false,
      showProfileDetails: false,
      profileDetailsData: null,
      showMessagingManager: false,
      addProfile: false,
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(BookingOverView);
