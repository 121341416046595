/**
 *  POS Management
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Actions
import * as POSActions from '../../../redux/pos/actions';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants, ErrorMessages, Strings} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faSave} from '@fortawesome/free-solid-svg-icons';
// Form
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Checkbox, Form, Input, Select, SubmitBtn} from 'react-formik-ui';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pos: state.pos.pos,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPOS: POSActions.setPOS,
};

/* Component ==================================================================== */

class POSManage extends Component {
  static componentName = 'POSManage';

  static propTypes = {
    match: PropTypes.object,
  };

  componentDidMount = () => {
    if (this.props.match.params.POSId) {
      this.props.setPOS(this.props.match.params.POSId)
        .then(() => {
          this.fetchInitData();
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.fetchInitData();
    }
  };

  addPOS = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {

        AppAPI.posapi.post('pos-settings/',
          {
            'name': credentials.name,
            'pos_type': credentials.pos_type,
            'operation_type': credentials.operation_type,
            'operation_end_duration': credentials.operation_end_duration,
            'operation_start_time': credentials.operation_start_time,
            'operation_end_time': credentials.operation_end_time,


            'pos_has_table': credentials.pos_has_table,
            'order_table_required': credentials.order_table_required,

            'pos_has_room': credentials.pos_has_room,
            'pos_order_booking_room_required': credentials.pos_order_booking_room_required,

            'pos_has_service_charge': credentials.pos_has_service_charge,
            'pos_service_charge_value': credentials.pos_service_charge_value,

            'pos_has_custom_item': credentials.pos_has_custom_item
          })
          .then((res) => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.setPOS(res);
                  this.props.history.replace(this.props.location.pathname.replace(/[^/]*$/, `${res.hash}/`));
                }, 500);
              },
            );
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  savePOS = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.posapi.patch(`pos-settings/${this.props.match.params.POSId}/`,
          {
            'name': credentials.name,
            'pos_type': credentials.pos_type,
            'operation_type': credentials.operation_type,
            'operation_end_duration': credentials.operation_end_duration,
            'operation_start_time': credentials.operation_start_time,
            'operation_end_time': credentials.operation_end_time,

            'pos_has_table': credentials.pos_has_table,
            'order_table_required': credentials.order_table_required,

            'pos_kot_force_print': credentials.pos_kot_force_print,
            'pos_order_force_print': credentials.pos_order_force_print,

            'pos_prompts_required': credentials.pos_prompts_required,

            'pos_has_room': credentials.pos_has_room,
            'pos_order_booking_room_required': credentials.pos_order_booking_room_required,

            'pos_has_service_charge': credentials.pos_has_service_charge,
            'pos_service_charge_value': credentials.pos_service_charge_value,

            'pos_has_custom_item': credentials.pos_has_custom_item,
            'pos_discount_before_tax': credentials.pos_discount_before_tax,
          })
          .then((posData) => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.setPOS(posData);
                  this.props.history.goBack();
                }, 500);
              },
            );
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  fetchInitData = () => {
    const {pos} = this.props;
    let initialValues = {
      'name': '',
      'pos_type': '',
      'operation_type': '',
    };
    if (this.props.match.params.POSId) {
      initialValues = {
        'name': pos.name,
        'pos_type': pos.pos_type,
        'operation_type': pos.operation_type,
        'operation_end_duration': pos.operation_end_duration,
        'operation_start_time': pos.operation_start_time,
        'operation_end_time': pos.operation_end_time,

        'pos_has_table': pos.pos_has_table,
        'order_table_required': pos.order_table_required,

        'pos_kot_force_print': pos.pos_kot_force_print,
        'pos_order_force_print': pos.pos_order_force_print,

        'pos_prompts_required': pos.pos_prompts_required,

        'pos_has_room': pos.pos_has_room,
        'pos_order_booking_room_required': pos.pos_order_booking_room_required,

        'pos_has_service_charge': pos.pos_has_service_charge,
        'pos_service_charge_value': pos.pos_service_charge_value,

        'pos_has_custom_item': pos.pos_has_custom_item,
        'pos_discount_before_tax': pos.pos_discount_before_tax,
      };
    }
    let formValidation = Yup.object().shape({
      name: Yup.string().min(2, 'Too Short!').required('Required'),
      pos_type: Yup.number().required('Required'),
    });
    this.setState({
      initialValues,
      formValidation,
      loading: false,
    });
  };

  render = () => {

    const {pos} = this.props;

    const {loading, error, initialValues, formValidation, resultMsg} = this.state;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    return (

      <div className="room-type-list-manage screen-container ">
        <Helmet>
          <title>POS Manage</title>
        </Helmet>
        <PageHeader
          subHeader={true} history={this.props.history}
          title={(this.props.match.params.POSId ? 'Manage' : 'Add') + ' Point of Sales'}
          description={this.props.match.params.POSId ? `Edit point of sale ${pos.name}` : 'Add new point of sales'}
        />

        <Formik
          onSubmit={(values) => this.props.match.params.POSId ? this.savePOS(values) : this.addPOS(values)}
          validationSchema={formValidation}
          initialValues={initialValues}>
          {(formikProps) => (
            <Form>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Point of Sale Information
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Input
                          required
                          name='name'
                          label={'Point of Sale Name'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-6 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='pos_type'
                          label={'Point of Sale Type'}
                          placeholder='Select an Option'
                          options={Constants.POS_TYPE_INPUT}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                  <p className={''}><strong>Name : </strong> {Strings.posName}</p>
                  <p className={''}><strong>Type : </strong> {Strings.posType}</p>
                </div>
              </div>


              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Point of Sales Settings
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-6 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='operation_type'
                          label={'Operation Type'}
                          placeholder='Select an Option'
                          options={Constants.POS_OPERATION_TYPE}
                        />
                      </div>
                    </div>
                    <hr/>
                    <div className="form-row">
                      <div className="col-6 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='pos_has_table'
                          label='Table'
                          text={'Provides table functionality in the POS.'}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <Checkbox
                          disabled={!formikProps.values.pos_has_table}
                          className={'mr-2'}
                          name='order_table_required'
                          label='Table Required'
                          text={'Will enforce new order to have a table attached.'}
                        />
                      </div>
                    </div>
                    <hr/>
                    <div className="form-row">
                      <div className="col-6 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='pos_has_room'
                          label='Room'
                          text={'Provides access to active rooms.'}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <Checkbox
                          disabled={!formikProps.values.pos_has_room}
                          className={'mr-2'}
                          name='pos_order_booking_room_required'
                          label='Room Required'
                          text={'Will enforce new order to have a booking attached.'}
                        />
                      </div>
                    </div>
                    <hr/>
                    <div className="form-row">
                      <div className="col-6 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='pos_order_force_print'
                          label='Auto Print Billing Order'
                          text={'Auto billing order print post generation.'}
                        />
                      </div>
                      {pos && pos.pos_has_kot &&
                        <div className="col-6 form-group">
                          <Checkbox
                            className={'mr-2'}
                            name='pos_kot_force_print'
                            label='Auto Print KOT'
                            text={'Auto KOT print post generation.'}
                          />
                        </div>
                      }
                    </div>
                    <hr/>
                    <div className="form-row">
                      <div className="col-12 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='pos_prompts_required'
                          label='POS Action Prompts'
                          text={'Shows additional prompt before non reversible actions in POS operation.'}
                        />
                      </div>
                    </div>
                    <hr/>
                    <div className="form-row">
                      <div className="col-6 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='pos_has_service_charge'
                          label='Service Charge'
                          text={'Enable service charge in the POS.'}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <Input
                          required
                          disabled={!formikProps.values.pos_has_service_charge}
                          name='pos_service_charge_value'
                          label={'Default Service Charge (%)'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <hr/>
                    <div className="form-row">
                      <div className="col-12 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='pos_discount_before_tax'
                          label='Discount Position'
                          text={'Check to add discount before tax.'}
                          hint={Strings.posDiscountSettings}
                        />
                      </div>
                    </div>
                    <hr/>
                    <div className="form-row">
                      <div className="col-6 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='pos_has_custom_item'
                          label='Custom Item'
                          text={'Allow custom item to be added in order ?'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                  <p className={''}><strong>Operation Type : </strong> {Strings.posOperationType}</p>
                </div>
              </div>


              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 mt-2">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn className={'btn btn-success btn-block btn-lg mt-2'}>
                    <FontAwesomeIcon
                      className={'white-cl mr-2'}
                      icon={this.props.match.params.POSId ? faSave : faPlus} size={'sm'}
                    /> {this.props.match.params.POSId ? 'Save' : 'Add'}
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>


      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      initialValues: {},
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(POSManage);
