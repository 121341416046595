/**
 *
 * Room Service Request List
 * List Room Service Request
 */

import _ from 'lodash';
import Moment from 'moment';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import React, {Component} from 'react';
import queryString from 'query-string';
import {connect} from 'react-redux';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import AppWebUtil from '../../lib/webUtils';
import {Constants, Filters, Strings} from '../../constants';
// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import SearchBar from '../../components/forms/SearchBar';
import FilterButton from '../../components/dashboard/FilterButton';
import FilterDate from '../../components/dashboard/FilterDate';
import ExportButton from '../../components/dashboard/ExportButton';
import PageHeader from '../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFile, faLongArrowAltRight, faSync} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class RoomServiceRequestList extends Component {
  static componentName = 'RoomServiceRequestList';

  static propTypes = {
    serviceType: PropTypes.number,
    room: PropTypes.object,
    showHeader: PropTypes.bool,
  };

  static defaultProps = {
    showHeader: true
  };


  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };

  componentDidMount = () => {
    let urlParams = {}, startDate = null, endDate = null;
    switch (this.getQueryParams('service_type')) {
    case 'amenity':
      urlParams['service_type'] = Constants.ROOM_SERVICE_TYPE.AMENITY;
      break;
    case 'maintenance':
      urlParams['service_type'] = Constants.ROOM_SERVICE_TYPE.MAINTENANCE;
      break;
    case 'service':
      urlParams['service_type'] = Constants.ROOM_SERVICE_TYPE.SERVICE;
      break;
    default:
      break;
    }

    switch (this.getQueryParams('request_status')) {
    case 'requested':
      urlParams['request_status'] = Constants.ROOM_SERVICE_REQUEST_STATUS.REQUESTED;
      break;
    case 'in-progress':
      urlParams['request_status'] = Constants.ROOM_SERVICE_REQUEST_STATUS.IN_PROGRESS;
      break;
    case 'complete':
      urlParams['request_status'] = Constants.ROOM_SERVICE_REQUEST_STATUS.COMPLETE;
      break;
    case 'cannot-serve':
      urlParams['request_status'] = Constants.ROOM_SERVICE_REQUEST_STATUS.CANNOT_SERVE;
      break;
    case 'cancelled':
      urlParams['request_status'] = Constants.ROOM_SERVICE_REQUEST_STATUS.CANCELLED;
      break;
    default:
      break;
    }

    if (this.getQueryParams('start_date')) {
      startDate = Moment(String(this.getQueryParams('start_date')));
    }
    if (this.getQueryParams('end_date')) {
      endDate = Moment(String(this.getQueryParams('end_date')));
    }

    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('start_date'), this.getQueryParams('end_date'), 'created_date');

    if (this.getQueryParams('serving_user')) {
      urlParams['serving_user'] = this.getQueryParams('serving_user');
    }

    if (this.getQueryParams('created_user')) {
      urlParams['created_user'] = this.getQueryParams('created_user');
    }


    if (this.props.serviceType) {
      urlParams['service_type'] = this.props.serviceType;
    }

    if (this.props.room) {
      urlParams['room'] = this.props.room.id;
    }

    this.setState({loading: false, urlParams, startDate, endDate});
  };

  fetchData = (page = 1, callback, searchText, download) => {
    const urlParams = {...this.state.urlParams};
    let baseUrl = 'room-service-request';
    urlParams['page'] = page;

    if (searchText) {
      baseUrl = 'room-service-request-search';
      urlParams['search'] = searchText;
      this.setState({searching: true, clear: true});
    }

    if (download) {
      urlParams['download'] = download.file;
      urlParams['all_data'] = true;
      this.setState({fileDownload: true});
    }

    AppAPI.roomapi.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (download) {
          let fileName = `Room-Service-Report-${AppUtil.formatDateTime(Moment(), 'short')}.${download.file}`;
          AppWebUtil.downloadFile(res, fileName);
        } else {
          if (res.results.length > 0) {
            callback(res.results, {allLoaded: !res.next});
          } else {
            callback();
          }
        }
        this.setState({
          searching: false,
          clear: false,
          refresh: false,
          loading: false,
          fileDownload: false,
          page: page,
        });
      })
      .catch((err) => {
        if (callback) {
          callback([], {allLoaded: true});
        }
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, refresh: false, loading: false, fileDownload: false, error});
      });
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={7}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faFile}/> No Service Request </h4>
            {Strings.roomServiceRequestListEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };


  tableHeader = () => {
    return (
      <tr>
        <th className={'data-table-cell-sm'}>Reference</th>
        <th className={'data-table-cell-md'}>Request Type</th>
        <th className={'data-table-cell-md'}/>
        <th className={'data-table-cell-sm'}>Room</th>
        <th className={'data-table-cell-sm'}>Booking</th>
        <th className={'data-table-cell-sm'}>Created</th>
        <th className={'data-table-cell-md'}/>
      </tr>
    );
  };

  renderRow = (data, key) => {
    const attributes = _.isEmpty(data.attributes) ? {} : data.attributes;
    return (
      <tr key={key}>
        <td><small>{data.ref_no}</small></td>
        <td className={'data-table-cell-md'}>
          <span className={`badge ${AppUtil.roomServiceTypeColor(data.service_type)}`}>
            {data.service_type_display}
          </span>
        </td>
        <td className={'data-table-cell-md'}>
          <span className={`ml-2 badge ${AppUtil.roomServiceRequestStatusColor(data.request_status)}`}>
            {data.request_status_display}
          </span>
        </td>
        <td>
          {attributes ? attributes.room : '...'}
        </td>
        <td>
          {attributes ?
            <Link
              to={`/booking/${attributes.booking_hash}/booking-room/${attributes.booking_room_hash}`}
              rel='noopener noreferrer' target={'_blank'}>
              {attributes.booking_room_ref_no}
            </Link>
            :
            '...'
          }
        </td>
        <td>
          <small>{AppUtil.formatDateTime(data.created)}</small> <br/>
          <small>{attributes ? attributes.created_user : '...'}</small>
        </td>
        <td>
          <Link to={`/house-keeping/service-request/${data.hash}`}>
            Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
          </Link>
        </td>
      </tr>
    );
  };


  render = () => {
    const {loading, error, clear, searching, results, fileDownload, refresh} = this.state;
    const {showHeader} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="order-list screen-container">
        <Helmet>
          <title>Service Request List</title>
        </Helmet>

        {showHeader &&
          <PageHeader
            history={this.props.history} title={'Service Request'}
            description={'All service request list.'}
          />
        }

        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-9'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.setState({refresh: true})}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                </button>
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Request Type'}
                  data={Filters.roomServiceType}
                  selectKey={this.getQueryParams('service_type')}
                  action={(data) => this.setQueryParams([{key: 'service_type', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Request Status'}
                  data={Filters.roomServiceRequestType}
                  selectKey={this.getQueryParams('request_status')}
                  action={(data) => this.setQueryParams([{key: 'request_status', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data))}
                  size={'sm'}
                  maxDate={Moment().add(1, 'days')}
                />
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-3 text-center text-sm-right'}>
            <ExportButton
              size={'sm'}
              files={['csv']}
              action={(key) => this.fetchData(this.state.page, null, null, {file: key})}
              loading={fileDownload}
              /*disabled={!this.state.startDate}*/
              disabled={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <SearchBar
              clear={clear}
              refresh={refresh}
              results={results}
              searching={searching}
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
              /*searchFunction={this.fetchData}*/
              colSpan={7}
              placeHolder="Reference ID"
            />
          </div>
        </div>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      searching: false,
      clear: false,
      results: [],
      urlParams: {},
      page: 1,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomServiceRequestList);
