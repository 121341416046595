/**
 *
 * User Dashboard
 */
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import _ from 'lodash';
import {Strings} from '../../constants';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faCampground,
  faCashRegister,
  faCreditCard,
  faDoorOpen,
  faExclamationCircle,
  faListAlt,
  faLongArrowAltRight,
  faMoneyBillWave,
  faReceipt,
  faSadCry,
  faTable,
  faUser
} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  user: state.user,
  property: state.property.property
});

// Any actions to map to the component?
const mapDispatchToProps = {};


/* Component ==================================================================== */
class DashboardUser extends Component {
  static componentName = 'DashboardUser';

  static propTypes = {
    data: PropTypes.object,
  };


  render = () => {
    const {loading, error} = this.state;
    const {user, property, data} = this.props;
    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const modulesData = [];
    const subModules = [
      {
        title: 'Orders',
        icon: faReceipt,
        description: 'Billing system for all sale points in the system.',
        link: '/order',
      },
      {
        title: 'Payments',
        icon: faCreditCard,
        description: 'Manage payments across different instruments.',
        link: '/payment',
      },
      {
        title: 'Refunds',
        icon: faCreditCard,
        description: 'Manage refunds across different instruments.',
        link: '/payment/refund',
      },
      {
        title: 'Reports',
        icon: faListAlt,
        description: 'Provides detailed insights and analytics for your property.',
        link: '/reports',
      }
    ];

    if (!_.isEmpty(data.modules)) {
      if (data.modules.indexOf('booking') > -1) {
        modulesData.push(
          {
            title: 'Frontdesk',
            icon: faTable,
            description: 'Manage room(s) inventory activities such as creating, managing & modify booking(s), distribution, rate plans etc.',
            link: '/booking',
          },
          {
            title: 'House Keeping',
            icon: faDoorOpen,
            description: 'Manage room(s) with real-time status updates, daily maintenance tasks, manage in-room request(s) of maintenance, amenities, etc.',
            link: '/house-keeping',
          }
        );
      }

      if (data.modules.indexOf('pos') > -1) {
        modulesData.push({
          title: 'Point of Sale',
          icon: faCashRegister,
          description: 'Manage operation for sale outlet(s) like Restaurant, Bar, Spa, Gift shop, etc.',
          link: '/point-of-sale',
        });
      }

      if (data.modules.indexOf('expense') > -1) {
        modulesData.push(
          {
            title: 'Expense',
            icon: faMoneyBillWave,
            description: 'Manage and track day to day expense(s) and procurement of items, vendor and more.',
            link: '/expense',
          }
        );
      }

      if (data.modules.indexOf('event') > -1) {
        modulesData.push(
          {
            title: 'Event',
            icon: faCampground,
            description: 'Manage your event(s) with full scale system to manage ticketing, customer(s), guest list, billing, etc.',
            link: '/event',
          }
        );
      }
    }

    return (
      <div className="dashboard screen-container">
        <Helmet>
          <title>Let's get started</title>
        </Helmet>

        <div className="container ml-0">
          <div className={'spacer-20'}/>
          <h1>Let's Get Started</h1>
          <p className={'text-muted'}>{property.name}</p>
          <hr/>
          <div className={'row'}>
            <div className={'col-lg-8 col-12'}>
              <div className={''}>
                {_.isEmpty(modulesData) ?
                  <div className={'text-center table-list-empty'}>
                    <h4><FontAwesomeIcon icon={faSadCry} className={'mr-2'}/> No Active Modules </h4>
                    <p className={'text-muted'}>{Strings.dashboardEmptyModules}</p>
                  </div>
                  :
                  <div className={'row'}>
                    {modulesData.map((data, i) => (
                      <div className={'col-12 col-md-6 mb-3'} key={i}>
                        <div className={'mb-4'}>
                          <h4 className={'mb-3'}>
                            {data.icon && <FontAwesomeIcon icon={data.icon} className={'mr-2'}/>} {data.title}
                          </h4>
                          <p>{data.description}</p>
                          {data.link &&
                          <Link to={data.link}>
                            Explore <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
                          </Link>
                          }
                          {!_.isEmpty(data.links) &&
                          <div className={'mt-3'}>
                            <p className={'mb-2 small text-muted'}>Other Modules</p>
                            <ul className={'list-unstyled'}>
                              {data.links.map((linkData, i) => (
                                <li key={i}>
                                  <Link to={linkData.to}>
                                    <FontAwesomeIcon icon={faArrowRight} className={'mr-2'}/> {linkData.title}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                          }
                        </div>
                      </div>
                    ))}
                  </div>
                }
              </div>
              {!_.isEmpty(modulesData) &&
              <div className={''}>
                <div className={'row'}>
                  {subModules.map((data, i) => (
                    <div className={'col-12 col-md-6 mb-3'} key={i}>
                      <Link to={data.link}>
                        <p className={'mb-1 black-cl'}><strong>
                          {data.icon && <FontAwesomeIcon icon={data.icon} className={'mr-2'}/>} {data.title}
                          <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
                        </strong></p>
                      </Link>
                      <p className={'text-muted'}>{data.description}</p>
                    </div>
                  ))}
                </div>
              </div>
              }
            </div>
            <div className={'col-lg-4 col-12'}>
              <ul className={'list-group list-group-flush'}>
                {!user.first_name &&
                <li className={'list-group-item'}>
                  <Link to={'/user/account'}>
                    <FontAwesomeIcon icon={faExclamationCircle} className={'red-cl mr-2'}/> Complete your Profile
                    <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
                  </Link>
                  <p className={'mb-0 small'}>Your user profile requires few more information</p>
                </li>
                }
                <li className={'list-group-item'}>
                  <Link to={'/user/account'}>
                    <FontAwesomeIcon icon={faUser} className={'mr-2'}/> Edit / Manage Profile
                    <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(DashboardUser);
