/**
 * Manage Consolidated order Edit
 *  manages order edit
 */
import _ from 'lodash';
import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Input, Select, Textarea } from 'react-formik-ui';
import { confirmAlert } from '../../../components/general/ConfirmAlert';
import Loading from '../../../components/general/Loading';
// Components
import { Alerts } from '../../../components/ui';
import { Constants, Strings } from '../../../constants';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
// Forms & Icons
import { Form, Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faCircle,
  faInfo,
  faLongArrowAltRight,
  faPercentage,
  faTimes
} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */

class OrderConsolidatedEdit extends React.Component {
  static componentName = 'OrderConsolidatedEdit';

  static propTypes = {
    orders: PropTypes.object,
    property: PropTypes.object,
    updateResponse: PropTypes.func,
  };


  updateOrder = (credentials) => {
    const {orders} = this.props;
    const {selectedOrder, orderValues} = this.state;
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});


      const orderData = [];
      orders.forEach(data => {
        if (selectedOrder.includes(data.id)){
          orderData.push({
            order_id: data.id,
            discount: orderValues[`item_${data.id}_discount`]
          });
        }
      });
      const payload = {
        order_data: orderData,
        order_discount_post_payment_type: credentials.discount_type,
        order_discount_post_payment_description: credentials.discount_description,
      };

      AppAPI.orderapi
        .post('order-consolidated-update/', payload)
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
              if (this.props.updateResponse) {
                this.props.updateResponse(res);
              }
              this.props.onHide();
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        selectedOrder: [],
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  prefillData = () => {
    const {orders, property} = this.props;
    const initialValues = {percentage: 'true', discount_type: 0, discount_description: ''};
    const orderValues = {}, formValidation = {};

    if (!_.isEmpty(orders)) {
      orders.forEach(data => {
        initialValues[`item_${data.id}_discount`] = 0;
        initialValues[`item_${data.id}_discount_percent`] =  0;
        formValidation[`item_${data.id}_discount`] = Yup.number().max(
          parseFloat(data.total_unpaid_payment), `Cannot exceed ${property.currency} ${data.total_unpaid_payment}`
        ).required('Value is required');
        formValidation[`item_${data.id}_discount_percent`] = Yup.number().max(100, 'Cannot exceed 100%').required(
          'Value is required'
        );
        orderValues[`item_${data.id}_total`] = data.total;
        orderValues[`item_${data.id}_total_unpaid_payment`] = data.total_unpaid_payment;
        orderValues[`item_${data.id}_discount`] =  parseFloat(initialValues[`item_${data.id}_discount`]).toFixed(2);
        orderValues[`item_${data.id}_net`] = parseFloat(data.total - initialValues[`item_${data.id}_discount`]).toFixed(2);
      });
    }

    this.setState({
      init: false,
      orderValues,
      initialValues,
      formValidation: Yup.object().shape(formValidation)
    });
  };

  selectOrder = (formikProps, item) => {
    const {orderValues, selectedOrder} = this.state;

    // If item remove reset the values
    if (selectedOrder.includes(item.id)) {
      formikProps.setFieldValue(`item_${item.id}_discount`, 0.00);
      formikProps.setFieldValue(`item_${item.id}_discount_percent`, 0.00);
      orderValues[`item_${item.id}_discount`] = 0.00;
      orderValues[`item_${item.id}_net`] = orderValues[`item_${item.id}_total`];
    }
    const selectedOrderUpdated = AppUtil.insertOrRemoveArray(selectedOrder, item.id);
    if (selectedOrderUpdated.length < 6){
      this.setState({
        orderValues,
        selectedOrder: selectedOrderUpdated
      });
    }
  };

  reCalculateDiscount = (orderId, value, percentage = 'false') => {
    const {orderValues} = this.state;
    let discount = value ? value : 0;
    if (percentage === 'true') {
      discount = parseFloat((value * orderValues[`item_${orderId}_total_unpaid_payment`]) / 100).toFixed(2);
    }
    orderValues[`item_${orderId}_discount`] = parseFloat(discount).toFixed(2);
    orderValues[`item_${orderId}_net`] = parseFloat(orderValues[`item_${orderId}_total`] - discount).toFixed(2);
    this.setState({orderValues});
  };

  resetValues = (formikProps) => {
    const {orders} = this.props;
    const {orderValues} = this.state;
    if (!_.isEmpty(orders)) {
      orders.forEach(data => {
        formikProps.setFieldValue(`item_${data.id}_discount`, 0.00);
        formikProps.setFieldValue(`item_${data.id}_discount_percent`, 0.00);
        orderValues[`item_${data.id}_discount`] = parseFloat('0.00').toFixed(2);
        orderValues[`item_${data.id}_net`] = orderValues[`item_${data.id}_total`];
      });
      this.setState({orderValues});
    }
  }

  render = () => {

    const {property, show, orders} = this.props;
    const {loading, formValidation, initialValues, resultMsg, selectedOrder, init, orderValues} = this.state;

    if (loading) return <Loading/>;

    if (init && show) {
      this.prefillData();
    }

    if (!show || init) return <React.Fragment/>;

    const orderList = [];
    if (!_.isEmpty(orders)) {
      orders.forEach((data) => {
        if (parseFloat(data.total_unpaid_payment) > 0 && data.lock) {
          orderList.push(data);
        }
      });
    }

    return (
      <Modal
        {...this.props}
        size={'lg'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Order Post Payment Discount
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={formValidation}
          onSubmit={values => this.updateOrder(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <Form className={'form-group'}>
                  <div className="row">
                    <div className={'col-lg-6 col-md-12 col-sm-12 mx-auto'}>
                      <div className="form-row">
                        <div className="col-lg-6 col-md-6 form-group">
                          <Select
                            required
                            name="discount_type"
                            label={'Discount Type'}
                            className={'form-control'}
                            options={Constants.ORDER_DISCOUNT_TYPE}
                            hint={'Not visible to customer.'}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 form-group">
                          <Select
                            name="percentage"
                            label={'Discount Type'}
                            className={'form-control'}
                            options={[
                              {
                                'value': 'false',
                                'label': property.currency,
                              },
                              {
                                'value': 'true',
                                'label': '% value',
                              },
                            ]}
                            onChange={(value) => {
                              formikProps.handleChange(value);
                              this.resetValues(formikProps);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={'col-lg-6 col-md-12 col-sm-12 mx-auto'}>
                      <div className="form-row">
                        <div className="col-lg-12 form-group">
                          <Textarea
                            name="discount_description"
                            label={'Discount Description'}
                            hint={'Will be visible to customer on order PDF.'}
                            className={'form-control'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <div className={''}>
                    <p className={'mb-1 text-muted small'}>
                      Select order(s) to be edited for discount (Max 5 at time).
                    </p>
                    <div className="table-responsive border rounded mb-3">
                      <table className={'table table-sm mb-0'}>
                        <thead>
                          <tr>
                            <td/>
                            <th className={'small'}>Reference</th>
                            <th className={'text-center small'}>Source</th>
                            <th className={'text-left small'}>Total</th>
                            <th className={'text-left small'}>Remaining</th>
                            <th className={'text-left small'}>Existing Discount</th>
                            <th className={'text-left small'}>
                              {formikProps.values.percentage === 'false' ? property.currency : '% of Remaining'}
                            </th>
                            <th className={'text-left small'}>New Discount</th>
                            <th className={'text-left small'}>Net</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!_.isEmpty(orderList)
                            ? (
                              <React.Fragment>
                                {orderList.map((data, i) => (
                                  <tr
                                    key={i}
                                    className={
                                      `tr-align-middle ${selectedOrder.includes(data.id) ? 'lime-bg' : (selectedOrder.length > 4 ? 'table-secondary': '')}`
                                    }
                                  >
                                    <td
                                      className={'a-class '}
                                      onClick={() => this.selectOrder(formikProps, data)}
                                    >
                                      <FontAwesomeIcon
                                        icon={selectedOrder.includes(data.id) ? faCheckCircle : faCircle}
                                        className={`${selectedOrder.includes(data.id) ? 'green-cl' : 'grey-cl'} mr-2`}
                                      />
                                    </td>
                                    <td className={'data-table-cell-sm small'}>
                                      {data.ref_no}
                                      <span className={'ml-2 badge ' + (AppUtil.paymentStatusColor(data.order_status))}>
                                        {data.order_status_display}
                                      </span>
                                    </td>
                                    <td className={'data-table-cell-md text-center small'}>
                                      {data.order_source_display}
                                      {!_.isEmpty(data.attributes) &&
                                      <React.Fragment>
                                        {data.attributes.pos_name &&
                                        <p className={'mb-0 small text-muted'}>{data.attributes.pos_name}</p>}
                                        {data.attributes.event_name &&
                                        <p className={' mb-0 small text-muted'}>{data.attributes.event_name}</p>}
                                        {data.attributes.booking_ota &&
                                        <p className={'mb-0 small text-muted'}>{data.attributes.booking_ota}</p>}
                                      </React.Fragment>
                                      }
                                    </td>
                                    <td className={'data-table-cell-sm text-left small'}>
                                      {`${data.currency} ${data.total}`}
                                    </td>
                                    <td className={'data-table-cell-sm text-left small'}>
                                      {`${data.currency} ${data.total_unpaid_payment}`}
                                    </td>
                                    <td className={'data-table-cell-sm text-left small'}>
                                      {`${data.currency} ${data.order_discount_post_payment}`}
                                    </td>
                                    <td className={'data-table-cell-sm text-left'}>
                                      <Input
                                        required
                                        type={'number'}
                                        className={`form-control ${formikProps.values.percentage === 'false' ? '' : 'd-none'}`}
                                        name={`item_${data.id}_discount`}
                                        disabled={!selectedOrder.includes(data.id)}
                                        onChange={(value) => {
                                          formikProps.handleChange(value);
                                          this.reCalculateDiscount(data.id, value.target.value, formikProps.values.percentage);
                                        }}
                                      />
                                      <Input
                                        required
                                        type={'number'}
                                        className={`form-control ${formikProps.values.percentage === 'true' ? '' : 'd-none'}`}
                                        name={`item_${data.id}_discount_percent`}
                                        disabled={!selectedOrder.includes(data.id)}
                                        onChange={(value) => {
                                          formikProps.handleChange(value);
                                          this.reCalculateDiscount(data.id, value.target.value, formikProps.values.percentage);
                                        }}
                                      />
                                    </td>
                                    <td className={'data-table-cell-md text-left'}>
                                      {`${data.currency} ${orderValues[`item_${data.id}_discount`]}`}
                                    </td>
                                    <td className={'data-table-cell-md text-center small'}>
                                      {`${data.currency} ${orderValues[`item_${data.id}_net`]}`}
                                      {parseFloat(orderValues[`item_${data.id}_discount`]) === parseFloat(data.total_unpaid_payment) && (
                                        <><br/><span className={'ml-2 badge badge-success'}>Paid</span></>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                                {!_.isEmpty(orderList) && (
                                  <tr>
                                    <td colSpan={3}>
                                      <p className={'text-muted text-right mb-0'}>
                                        Total of selected orders <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-3'}/>
                                      </p>
                                    </td>
                                    <td>{`${property.currency} ${orders.reduce((a, b) => parseFloat(a) + (selectedOrder.includes(b.id) ? parseFloat(b.total) : 0), 0)}`}</td>
                                    <td>{`${property.currency} ${orders.reduce((a, b) => parseFloat(a) + (selectedOrder.includes(b.id) ? parseFloat(b.total_unpaid_payment) : 0), 0)}`}</td>
                                    <td>{`${property.currency} ${orders.reduce((a, b) => parseFloat(a) + (selectedOrder.includes(b.id) ? parseFloat(b.order_discount_post_payment) : 0), 0)}`}</td>
                                    <td/>
                                    <td>{`${property.currency} ${orders.reduce((a, b) => parseFloat(a) + (selectedOrder.includes(b.id) ? parseFloat(orderValues[`item_${b.id}_discount`]) : 0), 0).toFixed(2)}`}</td>
                                    <td>{`${property.currency} ${orders.reduce((a, b) => parseFloat(a) + (selectedOrder.includes(b.id) ? parseFloat(orderValues[`item_${b.id}_net`]) : 0), 0).toFixed(2)}`}</td>
                                  </tr>
                                )}
                              </React.Fragment>
                            )
                            : (
                              <React.Fragment>
                                <tr>
                                  <td colSpan={10} className={'text-center py-3'}>
                                    <FontAwesomeIcon icon={faInfo}/>
                                    <p className={'text-muted'}>No Orders to select</p>
                                  </td>
                                </tr>
                              </React.Fragment>
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <button
                      type="submit"
                      onClick={() => {
                        confirmAlert({
                          title: 'Confirm Discount',
                          message: Strings.orderPostPaymentDiscount,
                          buttons: [
                            {
                              className: 'btn-success',
                              label: 'Confirm Discount',
                              onClick: formikProps.handleSubmit,
                            },
                            {
                              className: 'btn-secondary',
                              label: 'Exit',
                            },
                          ],
                        });
                      }}
                      disabled={_.isEmpty(selectedOrder)}
                      className={'btn btn-success btn-lg btn-block'}
                    >
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faPercentage} size={'sm'}/> Add Discount
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      init: true,
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      orderValues: {},
      initialValues: {},
      formValidation: Yup.object().shape({}),
      selectedOrder: [],
    };
  }
}

/* Export Component ==================================================================== */
export default OrderConsolidatedEdit;
