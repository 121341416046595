/**
 * PTerminalInfo
 *  Payment Terminal transaction info
 *
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// Consts and Libs
import AppUtil from '../../../../lib/util';
import {Constants} from '../../../../constants';
// Components
import TimeLineList from '../../../../components/dashboard/TimeLineList';

/* Component ==================================================================== */
class PTerminalInfo extends Component {
  static componentName = 'PTerminalInfo';

  static propTypes = {
    pterminal: PropTypes.object.isRequired,
  };

  static defaultProps = {
  }

  render = () => {
    const {pterminal} = this.props;
    const timeLine= [
      {
        'title': 'Created',
        'iconClass': 'green-cl',
        'description': AppUtil.formatDateTime(pterminal.created),
      },
      {
        'title': 'Payment',
        'iconClass': pterminal.payment_status === Constants.PTERMINAL_STATUS.PAID ? 'green-cl' : 'grey-cl',
        'description': pterminal.payment_time ? AppUtil.formatDateTime(pterminal.payment_time) : pterminal.payment_status_display
      }
    ];
    if (pterminal.refund_status !== Constants.PAYMENT_STATUS.NOTPAID) {
      timeLine.push({
        'title': 'Refund',
        'iconClass': pterminal.refund_status !== Constants.PTERMINAL_STATUS.NOTPAID ? 'green-cl' : 'grey-cl',
        'description': pterminal.refund_time ? AppUtil.formatDateTime(pterminal.refund_time) : pterminal.refund_status_display
      });
    }

    return (
      <React.Fragment>
        <div className={'row'}>
          <div className={'col-md-6 col-12'}>
            <table className={'table table-borderless table-sm mb-0'}>
              <tbody>
                <tr>
                  <td className={'w-50 text-muted'}>Total</td>
                  <td><strong>{`${pterminal.currency} ${pterminal.total}`}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={'col-md-6 col-12'}>
            <table className={'table table-borderless table-sm mb-0'}>
              <tbody>
                <tr>
                  <td className={'w-50 text-muted'}>Payment ID</td>
                  <td><strong>{pterminal.ref_no}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-md-6 col-12'}>
            <table className={'table table-borderless table-sm mb-3'}>
              <tbody>
                <tr>
                  <td className={'w-50 text-muted'}>Vendor</td>
                  <td>{pterminal.terminal_provider_display || '...'}</td>
                </tr>
                {!_.isEmpty(pterminal.attributes) &&
                <React.Fragment>
                  <tr>
                    <td className={'w-50 text-muted'}>Device Serial</td>
                    <td>{pterminal.attributes.pterminal_device_serial}</td>
                  </tr>
                </React.Fragment>
                }
              </tbody>
            </table>
          </div>
          <div className={'col-md-6 col-12'}>
            <table className={'table table-borderless table-sm mb-3'}>
              <tbody>
                <tr>
                  <td className={'w-50 text-muted'}>Payment Mode</td>
                  <td>{pterminal.payment_mode_display || 'Default'}</td>
                </tr>
                <tr>
                  <td className={'w-50 text-muted'}>Payment Status</td>
                  <td>
                    <span className={`badge ${AppUtil.pTerminalStatusColor(pterminal.payment_status)}`}>
                      {pterminal.payment_status_display}
                    </span>
                  </td>
                </tr>
                {pterminal.refund_status !== Constants.PTERMINAL_STATUS.INITIAL &&
                  <tr>
                    <td className={'w-50 text-muted'}>Refund Status</td>
                    <td>
                      <span className={`badge ${AppUtil.pTerminalStatusColor(pterminal.refund_status)}`}>
                        {pterminal.refund_status_display}
                      </span>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <TimeLineList timeLine={timeLine}/>
          {pterminal.refund_time_limit &&
            <p className={'small text-muted'}>Max time for refund {AppUtil.formatDateTime(pterminal.refund_time_limit)}</p>
          }
        </div>
      </React.Fragment>
    );
  };
}

/* Export Component ==================================================================== */
export default (PTerminalInfo);

