/**
 *
 * Order Refund Details
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
// Consts and Libs
import AppUtil from '../../../lib/util';
import AppAPI from '../../../lib/api';
import {Constants, Filters, Strings} from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import PaymentAdd from '../../payment/models/PaymentAdd';
import Loading from '../../../components/general/Loading';
import PaymentAction from '../../payment/models/PaymentAction';
import FilterButton from '../../../components/dashboard/FilterButton';
import PaymentDetailsModel from '../../payment/models/PaymentDetailsModel';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCashRegister, faPlusCircle, faSync} from '@fortawesome/free-solid-svg-icons';


/* Component ==================================================================== */
class OrderPaymentsList extends Component {
  static componentName = 'OrderPaymentsList';

  static propTypes = {
    order: PropTypes.object.isRequired,
    property: PropTypes.object.isRequired,
    fetchPOSOrderData: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {order} = this.props;
    this.setState({loading: true});

    AppAPI.order.get(`${order.hash}/related-details/?key=payment`)
      .then((res) => {
        this.setState({
          response: res,
          loading: false,
          showPayment: _.isEmpty(res.payments),
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };

  fetchPaymentData = (paymentData) => {
    this.setState({
      loading: false,
    }, () => {
      AppAPI.payment.get(`${paymentData}/`)
        .then((res) => {
          const actionDetail =(res.payment_status === Constants.PAYMENT_STATUS.NOTPAID && res.allow_payment_method);
          this.setState({
            loading: false,
            selectPaymentData: res,
            showPaymentAction: actionDetail,
            showPaymentDetailsModel: !actionDetail
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    });
  };


  render = () => {

    const {loading, error, response, paymentStatus, paymentMethod} = this.state;
    const {order} = this.props;


    if (loading || _.isEmpty(order) || _.isEmpty(response)) return <Loading heightMatch={false}/>;
    if (error) return <Error full={true} text={error}/>;
    const {payments} = response;
    let itemList = payments;
    if (paymentStatus || paymentStatus === 0) {
      itemList = itemList.filter(item => (item.payment_status === paymentStatus));
    }

    if (paymentMethod) {
      itemList = itemList.filter(item => (item.payment_method === paymentMethod));
    }
    return (
      <div className="">
        <div className={'row mb-3'}>
          <div className={'col-md-6 col-12'}>
            <table className={'table table-borderless table-sm mb-3'}>
              <tbody>
                <tr>
                  <td className={'w-50 text-muted'}>Order Total</td>
                  <td><strong>{`${order.currency} ${order.total}`}</strong></td>
                </tr>
                <tr>
                  <td className={'w-50 text-muted'}>Total Paid</td>
                  <td><strong>{`${order.currency} ${order.total_paid}`}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={'col-md-6 col-12'}>
            <table className={'table table-borderless table-sm mb-3'}>
              <tbody>
                <tr>
                  <td className={'w-50 text-muted'}>Pending Payment</td>
                  <td><strong>{`${order.currency} ${order.total_unpaid_payment}`}</strong></td>
                </tr>
                <tr>
                  <td className={'w-50 text-muted'}>Total Settled</td>
                  <td><strong>{`${order.currency} ${order.total_settled}`}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={'row'}>
          <div className={'col-12 col-sm-8'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.fetchInitData()}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                </button>
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Payment Method'}
                  selectKey={paymentMethod}
                  data={Filters.paymentMethodValue}
                  action={(data) => this.setState({paymentMethod: data.key})}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Payment Status'}
                  selectKey={paymentStatus}
                  data={Filters.paymentStatusValue}
                  action={(data) => this.setState({paymentStatus: data.key})}
                  size={'sm'}
                />
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-4 text-center text-sm-right'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button
                  onClick={() => this.setState({showPayment: true})}
                  className={'btn btn-outline-success btn-sm'}
                  disabled={!order.allow_payment}
                >
                  <FontAwesomeIcon icon={faPlusCircle} size={'sm'} className={'mr-2'}/> Add Payment
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div className="table-responsive table-sm border format-table mh-200">
          <table className={`table ${!_.isEmpty(itemList) && 'table-hover'} mb-0`}>
            <thead>
              <tr>
                <th>Reference</th>
                <th />
                <th>Method</th>
                <th className={'text-left'}>Total</th>
                <th className={'text-left green-cl'}>Settled</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {!_.isEmpty(itemList) ?
                <React.Fragment>
                  {itemList.map((data, i) => {
                    return (
                      <tr className={'tr-align-middle'} key={i}>
                        <td className={'data-table-cell-lg'}>
                          {data.ref_no}<br/>
                          <p className={'mb-0 text-muted small'}>{AppUtil.formatDateTime(data.created)}</p>
                        </td>
                        <td className={'data-table-cell-md text-left'}>
                          <span className={'badge mr-2 ' + (AppUtil.paymentStatusColor(data.payment_status))}>
                            {data.payment_status_display}
                          </span>
                          {data.payment_status === Constants.PAYMENT_STATUS.PAID &&
                            <span className={'mr-2 badge ' + (AppUtil.settlementStatusColor(data.settlement_status))}>
                              {data.settlement_status_display}
                            </span>
                          }
                          {data.refund_status !== Constants.REFUND_STATUS.NOTPAID &&
                            <span className={'mr-2 badge ' + (AppUtil.paymentStatusColor(data.refund_status))}>
                              {data.refund_status_display}
                            </span>
                          }
                          {data.payment_mode !== Constants.PAYMENT_MODE.PM_STANDARD &&
                        <span className={`mr-2 badge ${AppUtil.paymentModeColor(data.payment_mode)}`}>
                          {data.payment_mode_display}
                        </span>
                          }
                        </td>
                        <td className={'text-center data-table-cell-lg'}>
                          {data.payment_method_display}
                          {
                            (!_.isEmpty(data.attributes) && data.attributes.payment_terminal) &&
                          <React.Fragment>
                            <br/><p className={'small mb-0 text-muted'}>{data.attributes.payment_terminal}</p>
                          </React.Fragment>
                          }
                        </td>
                        <td className={'data-table-cell-md text-left'}><strong>{`${data.currency} ${data.total}`}</strong></td>
                        <td className={'data-table-cell-md green-cl text-left'}><strong>{`${data.currency} ${data.total_settled}`}</strong></td>
                        <td className={'data-table-cell-md data-table-cell-lg'}>
                          <button
                            className={`btn btn-sm btn-${data.allow_payment ? (data.allow_payment_method ? 'success' : 'primary') : 'primary'} btn-block`}
                            onClick={()=>this.fetchPaymentData(data.hash)}
                          >
                            {data.allow_payment ? (data.allow_payment_method ? 'Mark Paid' : 'Details') : 'View'}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </React.Fragment>
                :
                <tr>
                  <td colSpan={9}>
                    <div className={'text-center table-list-empty-sm'}>
                      <h6><FontAwesomeIcon icon={faCashRegister} className={'mr-2'}/> No Payment(s) </h6>
                      <p className={'text-muted'}>{Strings.orderPaymentEmpty}</p>
                      <p className={'mb-0'}>
                        <button
                          onClick={() => this.setState({showPayment: true})}
                          className={'btn btn-outline-success btn-sm'}
                          disabled={!order.allow_payment}
                        >
                          <FontAwesomeIcon icon={faPlusCircle} size={'sm'} className={'mr-2'}/> Add Payment
                        </button>
                      </p>
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>

        {/* Payment Details */}
        <PaymentDetailsModel
          property={this.props.property}
          show={this.state.showPaymentDetailsModel}
          payment={this.state.selectPaymentData}
          onHide={()=>this.setState({showPaymentDetailsModel: false})}
          fetchPaymentData={(data, parent=false) => {
            this.fetchPaymentData(data.hash);
            if (parent){
              this.props.fetchPOSOrderData();
            }
          }}
        />

        <PaymentAction
          show={this.state.showPaymentAction}
          payment={this.state.selectPaymentData}
          onHide={() => this.setState({showPaymentAction: false})}
          updateResponse={() => {
            this.fetchInitData();
            this.props.fetchPOSOrderData();
          }}
        />

        {/* Add Payment */}
        <PaymentAdd
          updateResponse={(data) => {
            this.fetchInitData();
            this.fetchPaymentData(data.hash);
          }}
          onHide={() => this.setState({showPayment: false})}
          show={this.state.showPayment}
          order={order}
          guestSettings={false}
          property={this.props.property}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      response: {},
      loading: true,
      showPayment: false,
      selectPaymentData: {},
      showPaymentDetailsModel: false,
    };
  }
}


export default OrderPaymentsList;
