/**
 * Room Inventory List
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
// Constants and Libs
import Strings from '../../constants/strings';
// Components
import {confirmAlert} from '../general/ConfirmAlert';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faDoorOpen,
  faLink,
  faTimesCircle,
  faUnlink
} from '@fortawesome/free-solid-svg-icons';
import AppAPI from '../../lib/api';
import {Alerts} from '../ui';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class RoomInventoryList extends Component {

  static propTypes = {
    roomTypes: PropTypes.array.isRequired,
    refreshContent: PropTypes.func.isRequired,
    channelManager: PropTypes.bool,
    bookingEngine: PropTypes.bool,
  };

  static defaultProps = {
    channelManager: true,
    bookingEngine: true,
  };

  updateRoomType = (roomType, payload) => {
    if (roomType && payload) {
      this.setState({resultMsg: {status: 'One moment...'}});
      AppAPI.roomapi.patch(`room-type-distribution/${roomType}/`, payload)
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}});
          if (this.props.refreshContent) {
            this.props.refreshContent(res);
          }
          setTimeout(() => {
            this.setState({
              resultMsg: {success: ''},
            });
          }, 500);
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  updateRoomTypePackage = (roomTypePackage, payload) => {
    if (roomTypePackage && payload) {
      this.setState({resultMsg: {status: 'One moment...'}});
      AppAPI.roomapi.patch(`room-type-package-distribution/${roomTypePackage}/`, payload)
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}});
          if (this.props.refreshContent) {
            this.props.refreshContent(res);
          }
          setTimeout(() => {
            this.setState({
              resultMsg: {success: ''},
            });
          }, 500);
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };


  render = () => {
    const {roomTypes, channelManager, bookingEngine, property} = this.props;
    const {resultMsg} = this.state;
    return (
      <React.Fragment>
        {roomTypes && roomTypes.length > 0 ?
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <Alerts
                status={resultMsg.status}
                success={resultMsg.success}
                error={resultMsg.error}
              />
              <div className="table-responsive">
                <table className="table table-sm table-bordered">
                  <thead className={' bg-dark text-light text-center'}>
                    <tr>
                      <th colSpan={2}>Type & Package</th>
                      {channelManager && <th colSpan={5}>Channel Manager</th>}
                      {bookingEngine && <th colSpan={4}>Booking Engine</th>}
                    </tr>
                    <tr>
                      <td>Room Type</td>
                      <td>Package</td>
                      {channelManager &&
                       <React.Fragment>
                         <td>Code</td>
                         <td>Rate</td>
                         <td>Connection</td>
                         <td colSpan={2}>Distribution Status</td>
                       </React.Fragment>
                      }
                      {bookingEngine &&
                       <React.Fragment>
                         <td>Connection</td>
                         <td colSpan={2}>Distribution Status</td>
                       </React.Fragment>
                      }

                    </tr>
                  </thead>
                  <tbody className={'text-center'}>
                    {roomTypes.map((room_type, i) => (
                      <React.Fragment key={i}>
                        <tr className={'table-info'}>
                          <td className={'align-middle text-left'} rowSpan={room_type.room_type_packages.length + 1}>
                            <Link to={`/settings/inventory/room-type/${room_type.id}/`}>
                              <FontAwesomeIcon icon={faLink} size={'sm'} className={'mr-2'}/>
                            </Link>{room_type.name}
                          </td>
                          <td className={'text-left'}>
                            {(!_.isEmpty(room_type.counts)) &&
                             <React.Fragment>
                               <Link to={`/settings/inventory/room-type/${room_type.id}/room/`}>
                                 <FontAwesomeIcon icon={faLink} size={'sm'} className={'mr-2'}/>
                               </Link>
                               <FontAwesomeIcon icon={faDoorOpen} size={'sm'} className={'mr-2'}/> Rooms
                               : {room_type.counts.rooms}
                             </React.Fragment>
                            }
                          </td>
                          {channelManager &&
                           <React.Fragment>
                             <td>{room_type.channel_manager_room_type_id}</td>
                             <td/>
                             <td>
                               <FontAwesomeIcon
                                 icon={room_type.channel_manager_ok ? faLink : faUnlink}
                                 className={room_type.channel_manager_ok ? 'green-cl' : 'red-cl'}
                               />
                             </td>
                             <td>
                               <FontAwesomeIcon
                                 icon={room_type.allow_channel_manager_distribution ? faCheckCircle : faTimesCircle}
                                 className={room_type.allow_channel_manager_distribution ? 'green-cl' : 'red-cl'}
                               />
                             </td>
                             <td>
                               <div className="custom-control custom-switch">
                                 <input
                                   type="checkbox" className="custom-control-input" id={`allow_channel_manager_distribution_room_type${room_type.id}`}
                                   checked={room_type.allow_channel_manager_distribution}
                                   onClick={() => {
                                     confirmAlert({
                                       title: (room_type.allow_channel_manager_distribution ? 'Disable' : ' Enabled') + ' Distribution',
                                       message: room_type.allow_channel_manager_distribution ? Strings.channelManagerSectionDistributionDisable : Strings.channelManagerSectionDistributionEnable,
                                       buttons: [
                                         {
                                           className: (room_type.allow_channel_manager_distribution ? 'btn-danger' : ' btn-success'),
                                           label: 'Yes',
                                           onClick: () => this.updateRoomType(room_type.id,{allow_channel_manager_distribution: !room_type.allow_channel_manager_distribution}),
                                         },
                                         {
                                           className: 'btn-secondary',
                                           label: 'No',
                                         },
                                       ],
                                     });
                                   }}
                                 />
                                 <label className="custom-control-label small pt-1 text-muted" htmlFor={`allow_channel_manager_distribution_room_type${room_type.id}`}>
                                   {room_type.allow_channel_manager_distribution ? 'Open' : 'Closed'}
                                 </label>
                               </div>
                             </td>
                           </React.Fragment>
                          }

                          {bookingEngine &&
                           <React.Fragment>
                             <td>
                               <FontAwesomeIcon
                                 icon={room_type.booking_engine_ok ? faLink : faUnlink}
                                 className={room_type.booking_engine_ok ? 'green-cl' : 'red-cl'}
                               />
                             </td>
                             <td>
                               <FontAwesomeIcon
                                 icon={room_type.allow_booking_engine_distribution ? faCheckCircle : faTimesCircle}
                                 className={room_type.allow_booking_engine_distribution ? 'green-cl' : 'red-cl'}
                               />
                             </td>
                             <td>
                               <div className="custom-control custom-switch">
                                 <input
                                   type="checkbox" className="custom-control-input" id={`allow_booking_engine_distribution_room_type${room_type.id}`}
                                   checked={room_type.allow_booking_engine_distribution}
                                   onClick={() => {
                                     confirmAlert({
                                       title: (room_type.allow_booking_engine_distribution ? 'Disable' : ' Enabled') + ' Distribution',
                                       message: room_type.allow_booking_engine_distribution ? Strings.bookingEngineSectionDistributionDisable : Strings.bookingEngineSectionDistributionEnable,
                                       buttons: [
                                         {
                                           className: (room_type.allow_booking_engine_distribution ? 'btn-danger' : ' btn-success'),
                                           label: 'Yes',
                                           onClick: () => this.updateRoomType(room_type.id,{allow_booking_engine_distribution: !room_type.allow_booking_engine_distribution}),
                                         },
                                         {
                                           className: 'btn-secondary',
                                           label: 'No',
                                         },
                                       ],
                                     });
                                   }}
                                 />
                                 <label className="custom-control-label small pt-1 text-muted" htmlFor={`allow_booking_engine_distribution_room_type${room_type.id}`}>
                                   {room_type.allow_booking_engine_distribution ? 'Open' : 'Closed'}
                                 </label>
                               </div>
                             </td>
                           </React.Fragment>
                          }
                        </tr>
                        {room_type.room_type_packages &&
                         <React.Fragment>
                           {room_type.room_type_packages.map((room_type_package, j) => (
                             <tr key={j}>
                               <td className={'text-left'}>
                                 <Link
                                   to={`/settings/inventory/room-type/${room_type.id}/package/${room_type_package.id}/`}>
                                   <FontAwesomeIcon icon={faLink} size={'sm'} className={'mr-2'}/>
                                 </Link>
                                 {room_type_package.name}
                               </td>
                               {channelManager &&
                                <React.Fragment>
                                  <td>{room_type_package.channel_manager_package_id}</td>
                                  <td>{property.channel_manager_rate_tax_inclusive ? room_type_package.base_price_effective : room_type_package.base_price}</td>
                                  <td>
                                    <FontAwesomeIcon
                                      icon={room_type_package.channel_manager_ok ? faLink : faUnlink}
                                      className={room_type_package.channel_manager_ok ? 'green-cl' : 'red-cl'}
                                    />
                                  </td>
                                  <td>
                                    <FontAwesomeIcon
                                      icon={room_type_package.allow_channel_manager_distribution ? faCheckCircle : faTimesCircle}
                                      className={room_type_package.allow_channel_manager_distribution ? 'green-cl' : 'red-cl'}
                                    />
                                  </td>
                                  <td>
                                    <div className="custom-control custom-switch">
                                      <input
                                        type="checkbox" className="custom-control-input" id={`allow_channel_manager_distribution${room_type_package.id}`}
                                        checked={room_type_package.allow_channel_manager_distribution}
                                        onClick={() => {
                                          confirmAlert({
                                            title: (room_type_package.allow_channel_manager_distribution ? 'Disable' : ' Enabled') + ' Distribution',
                                            message: room_type_package.allow_channel_manager_distribution ? Strings.channelManagerSectionDistributionDisable : Strings.channelManagerSectionDistributionEnable,
                                            buttons: [
                                              {
                                                className: (room_type_package.allow_channel_manager_distribution ? 'btn-danger' : ' btn-success'),
                                                label: 'Yes',
                                                onClick: () => this.updateRoomTypePackage(room_type_package.id,{allow_channel_manager_distribution: !room_type_package.allow_channel_manager_distribution}),
                                              },
                                              {
                                                className: 'btn-secondary',
                                                label: 'No',
                                              },
                                            ],
                                          });
                                        }}
                                      />
                                      <label className="custom-control-label small pt-1 text-muted" htmlFor={`allow_channel_manager_distribution${room_type_package.id}`}>
                                        {room_type_package.allow_channel_manager_distribution ? 'Open' : 'Closed'}
                                      </label>
                                    </div>
                                  </td>
                                </React.Fragment>
                               }

                               {bookingEngine &&
                                <React.Fragment>
                                  <td>
                                    <FontAwesomeIcon
                                      icon={room_type_package.booking_engine_ok ? faLink : faUnlink}
                                      className={room_type_package.booking_engine_ok ? 'green-cl' : 'red-cl'}
                                    />
                                  </td>
                                  <td>
                                    <FontAwesomeIcon
                                      icon={room_type_package.allow_booking_engine_distribution ? faCheckCircle : faTimesCircle}
                                      className={room_type_package.allow_booking_engine_distribution ? 'green-cl' : 'red-cl'}
                                    />
                                  </td>
                                  <td>
                                    <div className="custom-control custom-switch">
                                      <input
                                        type="checkbox" className="custom-control-input" id={`allow_booking_engine_distribution${room_type_package.id}`}
                                        checked={room_type_package.allow_booking_engine_distribution}
                                        onClick={() => {
                                          confirmAlert({
                                            title: (room_type_package.allow_booking_engine_distribution ? 'Disable' : ' Enabled') + ' Distribution',
                                            message: room_type_package.allow_booking_engine_distribution ? Strings.bookingEngineSectionDistributionDisable : Strings.bookingEngineSectionDistributionEnable,
                                            buttons: [
                                              {
                                                className: (room_type_package.allow_booking_engine_distribution ? 'btn-danger' : ' btn-success'),
                                                label: 'Yes',
                                                onClick: () => this.updateRoomTypePackage(room_type_package.id,{allow_booking_engine_distribution: !room_type_package.allow_booking_engine_distribution}),
                                              },
                                              {
                                                className: 'btn-secondary',
                                                label: 'No',
                                              },
                                            ],
                                          });
                                        }}
                                      />
                                      <label className="custom-control-label small pt-1 text-muted" htmlFor={`allow_booking_engine_distribution${room_type_package.id}`}>
                                        {room_type_package.allow_booking_engine_distribution ? 'Open' : 'Closed'}
                                      </label>
                                    </div>
                                  </td>
                                </React.Fragment>
                               }
                             </tr>
                           ))}
                         </React.Fragment>
                        }

                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              <ul className={'list-unstyled'}>
                <li><strong>Configured</strong> : {Strings.roomDistributionConfigured}</li>
                <li><strong>Distribution</strong> : {Strings.roomDistributionDistribution} </li>
              </ul>
              <p className={'text-muted'}>
                {Strings.propertyDistributionThrottled}
              </p>
            </div>
          </div>
          :
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className={'text-center py-4'}>
                <h4><FontAwesomeIcon icon={faDoorOpen}/> No Inventory Connected </h4>
                <p>{Strings.roomDistributionEmpty}</p>
              </div>
            </div>
          </div>
        }
      </React.Fragment>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RoomInventoryList));
