/**
 *
 * Payment Details
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import {Constants, Strings} from '../../../constants';
// Components
import {Alerts, History} from '../../../components/ui';
import PaymentLink from '../Components/PaymentLink';
import RefundAdd from '../../refund/models/RefundAdd';
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import GuestListView from '../../guest/GuestListView';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faFilePdf,
  faLongArrowAltRight,
  faPaperPlane, faPen,
  faPlusCircle,
  faShareSquare,
  faStickyNote,
} from '@fortawesome/free-solid-svg-icons';
import PaymentAction from '../models/PaymentAction';
import PaymentLockedEdit from '../models/PaymentLockedEdit';
import GuestQuickManage from '../../guest/GuestManageQuick';
import PTerminalDevices from '../Components/PTerminalDevices';
import PaymentReferenceManage from '../PaymentReferenceManage';
import ProfileQuickSearch from '../../guest/ProfileQuickSearch';
import MessagingManager from '../../messaging/MessagingManager';
import RecordView from '../../../components/dashboard/RecordView';
import GuestDetailsOverView from '../../guest/GuestDetailsOverView';
import PTerminalAction from '../PTerminal/Components/PTerminalAction';
import TimeLineList from '../../../components/dashboard/TimeLineList';
import {confirmAlert} from '../../../components/general/ConfirmAlert';
// Actions
import * as PaymentActions from '../../../redux/payment/actions';
import * as PropertyActions from '../../../redux/property/actions';
import * as MessagingActions from '../../../redux/messaging/actions';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  payment: state.payment.payment,
  property: state.property.property,
  paymentTerminals: state.property.paymentTerminals,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPayment: PaymentActions.setPayment,
  setPaymentTerminals: PropertyActions.setPaymentTerminals,
  setMessagingParameter: MessagingActions.setMessagingParameter,
};

/* Component ==================================================================== */
class PaymentOverView extends Component {
  static componentName = 'PaymentOverView';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    if (this.props.firstLoad) {
      this.setState({loading: false});
    } else {
      this.setState({
        loading: true,
      }, () => {
        this.props.setPayment(this.props.match.params.paymentId)
          .then(() => this.setState({loading: false}))
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({loading: false, error,});
          });
      });
    }
  };


  updatePayment = payload => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.payment
          .patch(this.props.payment.hash + '/', payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({
                  showProfileDetails: false,
                  showGuestManagement: false,
                  showProfileSearch: false,
                  resultMsg: {success: ''},
                });
                this.props.setPayment(res);
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  cacheReset = () => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.payment
        .post(`${this.props.payment.hash}/cache-reset/`, {})
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            this.props.setPayment(res);
            setTimeout(() => (this.setState({resultMsg: {success: ''}})), 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({loading: false, resultMsg: {error}});
        });
    });
  };

  showProfileDetails = (guestId, add_guest = false) => {
    this.setState({profileDetailsData: guestId, showProfileDetails: true, addProfile: add_guest});
  };

  profileActions = (profile) => {
    if (profile) {
      if (profile.profile_type === Constants.PROFILE_TYPE.GUEST) {
        this.updatePayment({guest_data: this.state.addProfile ? profile.id : null});
      } else {
        this.updatePayment({corporate_data: this.state.addProfile ? profile.id : null});
      }
    }
  };


  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {payment} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const timeLine= [
      {
        'title': 'Created',
        'iconClass': 'green-cl',
        'description': AppUtil.formatDateTime(payment.created),
      },
      {
        'title': 'Payment',
        'iconClass': payment.payment_status === Constants.PAYMENT_STATUS.PAID ? 'green-cl' : 'grey-cl',
        'description': payment.payment_time ? AppUtil.formatDateTime(payment.payment_time) : payment.payment_status_display
      }
    ];

    if (payment.payment_status === Constants.PAYMENT_STATUS.PAID){
      timeLine.push({
        'title': 'Settlement',
        'iconClass': payment.settlement_status === Constants.SETTLEMENT_STATUS.SETTLED ? 'green-cl' : 'grey-cl',
        'description': payment.settled_time ? AppUtil.formatDateTime(payment.settled_time) : payment.settlement_status_display
      });
    }

    if (payment.refund_status !== Constants.PAYMENT_STATUS.NOTPAID) {
      timeLine.push({
        'title': 'Refund',
        'iconClass': payment.refund_status !== Constants.PAYMENT_STATUS.NOTPAID ? 'green-cl' : 'grey-cl',
        'description': payment.refund_time ? AppUtil.formatDateTime(payment.refund_time) : payment.refund_status_display
      });
    }

    return (
      <div className="payment-details screen-container ">
        <Helmet>
          <title>Payment Details : {payment.ref_no}</title>
        </Helmet>

        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">

            <div className={'row'}>
              <div className={'col'}>
                <p className={'mb-0 text-muted'}>Total</p>
                <div className={'d-inline-flex align-items-baseline'}>
                  <h2>
                    {payment.currency + ' ' + payment.total}
                  </h2>
                  <div className={'align-items-baseline'}>
                    <span className={'ml-2 badge ' + (AppUtil.paymentStatusColor(payment.payment_status))}>
                      {payment.payment_status_display}
                    </span>
                    {payment.payment_status === Constants.PAYMENT_STATUS.PAID &&
                    <span className={'ml-2 badge ' + (AppUtil.settlementStatusColor(payment.settlement_status))}>
                      {payment.settlement_status_display}
                    </span>
                    }
                    {payment.payment_mode !== Constants.PAYMENT_MODE.PM_STANDARD &&
                      <span className={`ml-2 badge ${AppUtil.paymentModeColor(payment.payment_mode)}`}>
                        {payment.payment_mode_display}
                      </span>
                    }
                    {payment.refund_status !== Constants.REFUND_STATUS.NOTPAID &&
                    <span className={'ml-2 badge ' + (AppUtil.paymentStatusColor(payment.refund_status))}>
                      {payment.refund_status_display}
                    </span>
                    }
                  </div>
                </div>
              </div>
            </div>
            <hr/>
            <div className={'row mb-3'}>
              <div className={'col-lg-6 col-md-12'}>
                <table className={'table table-borderless table-sm mb-0'}>
                  <tbody>
                    {payment.payment_id &&
                    <tr>
                      <td className={'w-50 text-muted'}>Payment ID</td>
                      <td><strong>{payment.payment_id}</strong></td>
                    </tr>
                    }
                    <tr>
                      <td className={'w-50 text-muted'}>Payment Method</td>
                      <td>{payment.payment_method_display}</td>
                    </tr>
                    <tr>
                      <td className={'w-50 text-muted'}>Payment Type</td>
                      <td>{payment.payment_type_display}</td>
                    </tr>
                    <tr>
                      <td className={'w-50 text-muted'}>Payment Mode</td>
                      <td>{payment.payment_mode_display}</td>
                    </tr>
                    {payment.due_date &&
                    <tr>
                      <td className={'w-50 text-muted'}>Expire on</td>
                      <td>{AppUtil.formatDateTime(payment.due_date)}</td>
                    </tr>
                    }
                  </tbody>
                </table>
              </div>
              <div className={'col-lg-6 col-md-12'}>
                <table className={'table table-borderless table-sm mb-0'}>
                  <tbody>
                    {payment.payment_settlement_id &&
                    <tr>
                      <td className={'w-50 text-muted'}>Settlement ID</td>
                      <td>{payment.payment_settlement_id}</td>
                    </tr>
                    }
                    {!_.isEmpty(payment.attributes) &&
                    <React.Fragment>
                      {payment.attributes.order_hash &&
                      <tr>
                        <td className={'w-50 text-muted'}>Order</td>
                        <td>
                          <Link to={`/order/${payment.attributes.order_hash}/`}>
                            {payment.attributes.order_ref_no || 'Order'}
                          </Link>
                        </td>
                      </tr>
                      }
                      {payment.attributes.consolidated_payment_hash &&
                      <tr>
                        <td className={'w-50 text-muted'}>Consolidated Payment</td>
                        <td>
                          <Link to={`/payment/${payment.attributes.consolidated_payment_hash}/`}>
                            {payment.attributes.consolidated_payment_ref_no || 'Payment'}
                          </Link>
                        </td>
                      </tr>
                      }
                      {payment.attributes.payment_terminal &&
                      <tr>
                        <td className={'w-50 text-muted'}>Terminal</td>
                        <td>{payment.attributes.payment_terminal}</td>
                      </tr>
                      }
                      <tr>
                        <td className={'w-50 text-muted'}>Created By</td>
                        <td>{payment.attributes.created_user || 'N.A'}</td>
                      </tr>
                      {payment.attributes.paid_user &&
                      <tr>
                        <td className={'w-50 text-muted'}>Paid by</td>
                        <td>{payment.attributes.paid_user}</td>
                      </tr>
                      }
                      {payment.attributes.settled_user &&
                      <tr>
                        <td className={'w-50 text-muted'}>Settled by</td>
                        <td>{payment.attributes.settled_user}</td>
                      </tr>
                      }
                      {payment.attributes.attached_user &&
                      <tr>
                        <td className={'w-50 text-muted'}>Attached by</td>
                        <td>{payment.attributes.attached_user}</td>
                      </tr>
                      }
                      {payment.attributes.booking_ota &&
                      <tr>
                        <td className={'w-50 text-muted'}>OTA</td>
                        <td>
                          {payment.attributes.booking_ota}
                          <span className={'small'}>({payment.attributes.booking_ota_code})</span>
                        </td>
                      </tr>
                      }
                      {payment.attributes.pos_name &&
                      <tr>
                        <td className={'w-50 text-muted'}>Point of Sale</td>
                        <td>
                          <Link to={`/point-of-sale/${payment.attributes.pos_hash}`}>
                            {payment.attributes.pos_name}
                          </Link>
                        </td>
                      </tr>
                      }
                      {payment.attributes.event_name &&
                      <tr>
                        <td className={'w-50 text-muted'}>Event</td>
                        <td>
                          <Link to={`/point-of-sale/${payment.attributes.event_hash}`}>
                            {payment.attributes.event_name}
                          </Link>
                        </td>
                      </tr>
                      }
                    </React.Fragment>
                    }
                  </tbody>
                </table>
              </div>
              <div className={'col-12'}>
                {payment.description &&
                  <p className={'p-1'}><span className={'text-muted'}>Description</span> : {payment.description}</p>
                }
              </div>
            </div>


            {(payment.guest || payment.corporate) &&
            <div className={'mb-3'}>
              <div className={'row'}>
                {(payment.guest) &&
                <div className={'col-md-6 col-12 mb-3'}>
                  <p className={'mb-1 text-muted'}>Customer</p>
                  <GuestListView
                    data={payment.guest}
                    action={() => payment.guest.id ? this.showProfileDetails(payment.guest.id) : null}
                  />
                </div>
                }

                {(payment.corporate) &&
                <div className={'col-md-6 col-12 mb-3'}>
                  <p className={'mb-1 text-muted'}>Corporate / Agent</p>
                  <GuestListView
                    data={payment.corporate}
                    action={() => payment.corporate.id ? this.showProfileDetails(payment.corporate.id) : null}
                  />
                </div>
                }
              </div>
            </div>
            }

            <div className={'mb-3'}>
              <TimeLineList timeLine={timeLine}/>
            </div>

            {payment.payment_link && <PaymentLink paymentLink={payment.payment_link}/>}

            
            {(payment.payment_method === Constants.PAYMENT_METHODS.PAYMENT_TERMINAL) &&
              <React.Fragment>
                {(payment.payment_status === Constants.PAYMENT_STATUS.NOTPAID && _.isEmpty(payment.pterminal_data)) &&
                  <PTerminalDevices
                    payment={payment}
                    PTerminalDevices={payment.payment_terminal_devices}
                    action={() => this.props.setPayment(this.props.match.params.paymentId)}
                  />
                }
                {!_.isEmpty(payment.pterminal_data) &&
                  <PTerminalAction
                    allowVoid={false}
                    pterminal={payment.pterminal_data}
                    action={(data)=> data.payment_status === Constants.PTERMINAL_STATUS.CANCELLED || data.payment_status === Constants.PTERMINAL_STATUS.PAID ? this.props.setPayment(this.props.match.params.paymentId) : null}
                  />
                }
              </React.Fragment>
            }

            <div className={'row mb-3'}>
              <div className={'col-12'}>
                <p><strong>Payment Breakdown</strong></p>
                <hr/>
                <table className={'table table-borderless table-sm'}>
                  <tbody>
                    <tr>
                      <td className={'w-25'}>Payment</td>
                      <td>{payment.currency + ' ' + payment.total}</td>
                      <td>
                        {payment.reference_id &&
                      <React.Fragment>
                        <FontAwesomeIcon icon={faStickyNote} className={'mr-2'}/> {payment.reference_id}
                      </React.Fragment>
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className={'w-25'}>Settled</td>
                      <td
                        className={'w-25'}>{payment.currency + ' ' + payment.total_settled} {payment.settlement_status === Constants.SETTLEMENT_STATUS.NOT_SETTLED && payment.get_settlement_status_display}</td>
                      <td>
                        {payment.settlement_id &&
                      <React.Fragment>
                        <FontAwesomeIcon icon={faStickyNote} className={'mr-2'}/> {payment.settlement_id}
                      </React.Fragment>
                        }
                      </td>
                    </tr>
                    {parseFloat(payment.settlement_difference) > 0 &&
                  <tr>
                    <td className={'w-25'}>{payment.settlement_fee_type_display}</td>
                    <td className={'w-25'}>
                      - {payment.currency + ' ' + payment.settlement_difference}
                      {payment.settlement_fee_tax > 0 &&
                      <React.Fragment> ( Tax {payment.settlement_fee_tax} )</React.Fragment>
                      }
                    </td>
                    <td>
                      {payment.settlement_fee_description &&
                      <React.Fragment>
                        <FontAwesomeIcon icon={faStickyNote} className={'mr-2'}/>
                        {payment.settlement_fee_description}
                      </React.Fragment>
                      }
                    </td>
                  </tr>
                    }

                    {parseFloat(payment.total_refunded) > 0 &&
                  <tr>
                    <td className={'w-25'}>Refunded</td>
                    <td className={'w-25'}>{payment.currency + ' ' + payment.total_refunded}</td>
                    <td/>
                  </tr>
                    }
                    {(payment.settlement_status === Constants.SETTLEMENT_STATUS.SETTLED || parseFloat(payment.total_refunded) > 0) &&
                  <tr>
                    <td className={'w-25'}><strong>Net Payment</strong></td>
                    <td className={'w-25'}><strong>{payment.currency + ' ' + payment.total_available_fund}</strong>
                    </td>
                    <td/>
                  </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>

            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
            {(!_.isEmpty(payment.attributes) && payment.attributes.consolidated_payment_hash) &&
            <React.Fragment>
              <div className={'row mb-3'}>
                <div className={'col-lg-12 col-md-12 col-sm-12 mb-3'}>
                  <Link
                    className={'btn btn-lg btn-block btn-success'}
                    to={`/payment/${payment.attributes.consolidated_payment_hash}/`}
                  >
                    Consolidated Payment <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
                  </Link>
                </div>
              </div>
            </React.Fragment>
            }

            {payment.payment_mode !== Constants.PAYMENT_MODE.PM_SUB &&
              <React.Fragment>
                {payment.payment_status === Constants.PAYMENT_STATUS.NOTPAID &&
                <React.Fragment>
                  <hr/>
                  <div className={'row mb-3'}>
                    <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                      <button
                        disabled={payment.payment_mode === Constants.PAYMENT_MODE.PM_SUB}
                        className={'btn btn-lg btn-block btn-secondary'}
                        onClick={() => {
                          confirmAlert({
                            title: 'Cancel Payment',
                            message: Strings.paymentCancelText,
                            buttons: [
                              {
                                className: 'btn-danger',
                                label: 'Cancel Payment',
                                onClick: () => this.updatePayment({void_payment: true}),
                              },
                              {
                                className: 'btn-secondary',
                                label: 'Exit',
                              },
                            ],
                          });
                        }}
                      >
                        Cancel Payment
                      </button>
                    </div>
                    <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                      <button
                        className={'btn btn-lg btn-block btn-success'}
                        disabled={payment.payment_method === Constants.PAYMENT_METHODS.PAYMENT_TERMINAL || payment.payment_method === Constants.PAYMENT_METHODS.ONLINE_PAYMENT || !payment.allow_payment}
                        onClick={() => this.setState({showPaymentAction: true})}
                      >
                        Confirm Payment
                      </button>
                    </div>
                  </div>
                </React.Fragment>
                }
                {(payment.payment_status === Constants.PAYMENT_STATUS.PAID && payment.settlement_status === Constants.SETTLEMENT_STATUS.NOT_SETTLED) &&
                <React.Fragment>
                  <hr/>
                  <div className={'row mb-3'}>
                    <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                      <button
                        className={'btn btn-lg btn-block btn-success'}
                        disabled={payment.pgateway || payment.payment_mode === Constants.PAYMENT_MODE.PM_SUB}
                        onClick={() => this.setState({showPaymentAction: true})}
                      >
                        Confirm Settlement
                      </button>
                    </div>
                  </div>
                </React.Fragment>
                }
              </React.Fragment>
            }

            <RecordView record={payment.scanned_record_1_signed}/>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group mb-5">
              <li className="list-group-item align-items-center">
                <button onClick={() => this.setState({showMessagingManager: true})} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faPaperPlane} size={'sm'} className={'mr-2'}/> Notification ( E-Mail / SMS )
                </button>
              </li>
              <li className="list-group-item">
                <a
                  href={payment.lock ? payment.resources_link : '#'} rel="noopener noreferrer" target={'_blank'}
                  className={'btn btn-link m-0 p-0 ' + (payment.lock && payment.payment_auto_generate_sequence_id ? '' : 'disabled')}>
                  <FontAwesomeIcon icon={faFilePdf} size={'sm'} className={'mr-2'}/> Payment Receipt PDF
                </a>
              </li>
            </ul>

            <ul className="list-group mb-5">
              <li
                className="list-group-item d-flex justify-content-between align-items-center"
                onClick={() => this.cacheReset()}
              >
                <small>Payment Actions</small>
              </li>
              <li className="list-group-item">
                <button
                  disabled={payment.void || payment.payment_method === Constants.PAYMENT_METHODS.ONLINE_PAYMENT}
                  className="btn btn-link m-0 p-0"
                  onClick={() => this.setState({
                    showPaymentLockedEdit: true,
                  })}
                >
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit Payment
                </button>
                <p className={'small text-muted mb-0'}>Edit payment information.</p>
              </li>
              <li className="list-group-item">
                <button
                  className="btn btn-link red-cl m-0 p-0"
                  disabled={!payment.allow_refund || payment.payment_method === Constants.PAYMENT_METHODS.ONLINE_PAYMENT}
                  onClick={() => this.setState({showRefund: true})}>
                  <FontAwesomeIcon icon={faShareSquare} size={'sm'} className={'mr-2'}/> Issue Refund
                </button>
                <p className={'mb-0 small text-muted'}>Require Manager Permission</p>
              </li>
              <li className="list-group-item">
                <button
                  type="button" className="btn btn-link m-0 p-0" data-toggle="modal"
                  data-target="#orderDiscountModel"
                  onClick={() => this.setState({showGuestManagement: true, addProfile: true})}
                  disabled={((payment.guest && payment.guest.id) || payment.lock)}
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle} size={'sm'} className={'mr-2'}/>
                  Add Guest
                </button>
                <p className={'small text-muted mb-0'}>Attach a guest profile to payment.</p>
              </li>
              <li className="list-group-item">
                <button
                  type="button" className="btn btn-link m-0 p-0" data-toggle="modal"
                  data-target="#orderDiscountModel"
                  onClick={() => this.setState({
                    showProfileSearch: true,
                    profileSource: Constants.PROFILE_TYPE.CORPORATE,
                  })}
                  disabled={((payment.corporate && payment.corporate.id) || payment.lock)}
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle} size={'sm'} className={'mr-2'}/>
                  Add Corporate
                </button>
                <p className={'small text-muted mb-0'}>Attach a corporate profile to payment.</p>
              </li>
              <li className="list-group-item">
                <button
                  type="button" className="btn btn-link m-0 p-0" data-toggle="modal"
                  data-target="#orderDiscountModel"
                  onClick={() => this.setState({showProfileSearch: true, profileSource: Constants.PROFILE_TYPE.AGENT})}
                  disabled={((payment.corporate && payment.corporate.id) || payment.lock)}
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle} size={'sm'} className={'mr-2'}/>
                  Add Agent
                </button>
                <p className={'small text-muted mb-0'}>Attach an agent profile to payment.</p>
              </li>
              <li className="list-group-item">
                <button
                  type="button" className="btn btn-link m-0 p-0" data-toggle="modal"
                  data-target="#orderDiscountModel"
                  onClick={() => this.setState({
                    showPaymentReferenceManage: true,
                  })}
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle} size={'sm'} className={'mr-2'}/>
                  {(payment.scanned_record_1_signed && payment.scanned_record_1_signed.record_2x) ? 'Edit ' : 'Add '} Reference
                </button>
                <p className={'small text-muted mb-0'}>Attach reference document.</p>
              </li>
            </ul>
          </div>
        </div>

        <PaymentAction
          show={this.state.showPaymentAction}
          onHide={() => this.setState({showPaymentAction: false})}
          payment={payment}
          paymentTerminals={payment.payment_terminals}
          updateResponse={(data) => this.props.setPayment(data)}
        />

        <PaymentReferenceManage
          show={this.state.showPaymentReferenceManage}
          onHide={() => this.setState({showPaymentReferenceManage: false})}
          data={payment}
          type={'payment'}
          updateResponse={(data) => this.props.setPayment(data)}
        />

        <GuestDetailsOverView
          onHide={() => this.setState({showProfileDetails: false})}
          guestId={this.state.profileDetailsData}
          show={this.state.showProfileDetails}
          addGuestFunction={this.state.addProfile}
          action={this.profileActions}
          resultMsg={resultMsg}
        />

        <GuestQuickManage
          onHide={() => this.setState({showGuestManagement: false})}
          show={this.state.showGuestManagement}
          property={this.props.property}
          showGuestDetails={this.showProfileDetails}
          guestAddAction={this.profileActions}
        />

        <ProfileQuickSearch
          onHide={() => this.setState({showProfileSearch: false})}
          show={this.state.showProfileSearch}
          showProfileDetails={this.showProfileDetails}
          isCorporate={this.state.profileSource === Constants.PROFILE_TYPE.CORPORATE}
          isAgent={this.state.profileSource === Constants.PROFILE_TYPE.AGENT}
        />

        <RefundAdd
          updateResponse={(data) => this.props.history.push(`/payment/refund/${data.hash}`)}
          property={this.props.property}
          payment={payment}
          onHide={() => this.setState({showRefund: false})}
          show={this.state.showRefund}
        />

        {/* Messaging */}
        <MessagingManager
          onHide={() => this.setState({showMessagingManager: false})}
          show={this.state.showMessagingManager}
          property={this.props.property}
          guests={payment.guest ? [payment.guest] : null}
          payment={payment}
        />

        {/* Payment locked edit */}
        <PaymentLockedEdit
          payment={payment}
          show={this.state.showPaymentLockedEdit}
          onHide={() => this.setState({showPaymentLockedEdit: false})}
          updateResponse={(data) => this.props.setPayment(data)}
        />
        <History object={payment}/>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      profileDetailsData: null,
      showProfileDetails: false,
      showGuestManagement: false,
      showPaymentAction: false,
      showPaymentReferenceManage: false,
      profileSource: Constants.PROFILE_TYPE.CORPORATE,
      showProfileSearch: false,
      addProfile: false,
      showRefund: false,
      showMessagingManager: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOverView);