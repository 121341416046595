/**
 * Room ListView
 */
import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import queryString from 'query-string';

// Consts and Libs
import AppAPI from '../../../lib/api';
import {Strings} from '../../../constants';

// Components
import {Alerts} from '../../../components/ui';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDoorOpen, faSync, faTimes} from '@fortawesome/free-solid-svg-icons';
import POSRoomListView from '../components/POSRoomListView';

/* Component ==================================================================== */

class POSRoomSelectListView extends Component {
  static componentName = 'RoomListView';

  static propTypes = {
    data: PropTypes.object,
    posDetails: PropTypes.object,
    showProfileDetails: PropTypes.func,
    availabilityStatus: PropTypes.number,
    resultMsg: PropTypes.object,
    action: PropTypes.func,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
        loading: true,
      });
      this.fetchInitData();
    }
    return true;
  }


  preFillData = () => {
    this.setState(
      {
        results: [],
        init: false,
        clear: false,
        loading: false,
        searching: false,
      },
    );
  };

  fetchInitData = () => {

    const {availabilityStatus, posDetails} = this.props;
    const urlParams = {};

    if (availabilityStatus !== null) {
      urlParams['availability_status'] = availabilityStatus;
    }

    let apiHeader = AppAPI.posapi.get(`pos-room-list/?pos=${posDetails.hash}&${queryString.stringify(urlParams)}`);

    apiHeader.then(res => {
      if (res.actions !== '') {
        this.setState({
          loading: false,
          rooms: res,
          error: null,
        });
      } else {
        this.setState({
          loading: false,
          error: true,
        });
      }
    })
      .catch(err => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: error,
          resultMsg: {error},
        });
      });
  };

  render = () => {

    const {rooms, loading} = this.state;
    const {show, resultMsg} = this.props;

    if (!show) {
      return null;
    }

    return (
      <Modal
        {...this.props}
        size={'xl'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Room(s) List
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? <Loading heightMatch={false}/> :
            <React.Fragment>
              <div className={'row'}>
                <div className={'col-12 col-sm-9'}>
                  <ul className="list-inline mb-1">
                    <li className="list-inline-item">
                      <li className="list-inline-item">
                        <button
                          className={'btn btn-outline-secondary btn-sm'}
                          onClick={() => this.setState({loading: true}, () => this.fetchInitData())}>
                          <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                        </button>
                      </li>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={'table-responsive mt-4'}>
                <table className={'table border'}>
                  <thead className={'text-center'}>
                    <tr>
                      <th className={'data-table-cell-md'}>Room No</th>
                      <th className={'w-50'} colSpan={3}>Room Details</th>
                      <th className="data-table-cell-md center-text">Guest</th>
                      <th className={'data-table-cell-sm'}/>
                    </tr>
                  </thead>
                  <tbody>
                    {_.isEmpty(rooms) ?
                      <React.Fragment>
                        <tr>
                          <td colSpan={6}>
                            <div className={'text-center table-list-empty'}>
                              <h4><FontAwesomeIcon icon={faDoorOpen}/> No Active Bookings </h4>
                              {Strings.posRoomListEmptyText.map((data, i) => <p key={i}>{data}</p>)}
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                      :
                      <React.Fragment>
                        {rooms && rooms.map((data, i) => {
                          return (
                            <React.Fragment key={i}>
                              <POSRoomListView data={data} action={(data) => this.props.action({'room': data.id})}/>
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    }
                  </tbody>
                </table>
              </div>
            </React.Fragment>
          }
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          <Alerts
            status={resultMsg.status}
            success={resultMsg.success}
            error={resultMsg.error}
          />
          <div className={'row'}>
            <div className={'col'}>
              <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: true,
      clear: false,
      results: [],
      searching: false,
      urlParams: {},
    };
  }
}

/* Export Component ==================================================================== */
export default POSRoomSelectListView;
