/**
 * Manage Booking
 *  manages booking updates ( Discount, Order Additional Information)
 */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import * as Yup from 'yup';
// Consts and Libs
import AppAPI from '../../../lib/api';
import { Constants, ErrorMessages, Strings } from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
// Forms
import {Form, Formik} from 'formik';
import { Checkbox, Input, Select } from 'react-formik-ui';


/* Component ==================================================================== */

class BookingEdit extends React.Component {
  static componentName = 'BookingEdit';

  static propTypes = {
    match: PropTypes.object,
    booking: PropTypes.object,
    property: PropTypes.object,
    updateResponse: PropTypes.func,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        loading: false,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  updateOrder = (credentials = null) => {

    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.booking
        .patch(`${this.props.booking.hash}/`, {
          booking_remarks: credentials.booking_remarks,
          booking_reference: credentials.booking_reference,
          booking_order_mask: credentials.booking_order_mask,
          booking_visit_purpose: credentials.booking_visit_purpose,
          booking_reference_description: credentials.booking_reference_description,
          booking_visit_purpose_description: credentials.booking_visit_purpose_description,
        })
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
              this.props.updateResponse(res);
              this.props.onHide();
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    });
  };

  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {booking, show, property} = this.props;

    if (!show) return <React.Fragment/>;

    if (loading) return <Loading heightMatch={true}/>;
    if (!loading && error) return <Error full={true} text={ErrorMessages.initData}/>;

    let required_booking_fields = [];
    const validation = {
      booking_visit_purpose: Yup.number().required(),
      booking_remarks: Yup.string().max(200, 'Too Long!').nullable(),
    };

    if (!_.isEmpty(property.config) && property.config.profile) {
      const profile_fields = property.config.profile.profile_advance_fields;
      if (!_.isEmpty(profile_fields) && !_.isEmpty(profile_fields.required_booking_fields)) {
        required_booking_fields = profile_fields.required_booking_fields;
        if (required_booking_fields.includes('booking_visit_purpose')){
          // 0 is default so anything above 0 is valid one.
          validation['booking_visit_purpose'] = Yup.number().min(1, 'Required').required('Required');
        }
      }
    }
    const formValidation = Yup.object().shape(validation);

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Booking
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            booking_remarks: booking.booking_remarks,
            booking_reference: booking.booking_reference,
            booking_order_mask: booking.booking_order_mask,
            booking_visit_purpose: booking.booking_visit_purpose,
            booking_reference_description: booking.booking_reference_description,
            booking_visit_purpose_description: booking.booking_visit_purpose_description,
          }}
          validationSchema={formValidation}
          onSubmit={values => this.updateOrder(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <Form className={'form-group'}>
                      <div className="mt-2">
                        <div className="form-row">
                          <div className="col-12 form-group">
                            <Input
                              name='booking_remarks'
                              label={'Booking Remarks'}
                              className={'form-control'}
                              hint={'Additional information / remarks for the booking'}
                            />
                          </div>
                        </div>
                        <hr/>
                        <div className="form-row">
                          <div className="col-12 col-md-6 form-group">
                            <div className="form-row">
                              <div className="col-12 form-group">
                                <Select
                                  label={'Visit Type'}
                                  name='booking_visit_purpose'
                                  className={'form-control'}
                                  options={Constants.BOOKING_PURPOSE_OF_VISIT}
                                  hint={'Guest visit Type.'}
                                  required={required_booking_fields.includes('booking_visit_purpose')}
                                />
                              </div>
                              {(String(formikProps.values.booking_visit_purpose) === '7') && (
                                <div className="col-12 form-group">
                                  <Input
                                    label={'Visit Type'}
                                    className={'form-control'}
                                    disabled={parseInt(formikProps.values.booking_visit_purpose) !== 7}
                                    name="booking_visit_purpose_description"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-md-6 form-group">
                            <div className="form-row">
                              <div className="col-12 form-group">
                                <Select
                                  label={'Booking Reference'}
                                  name='booking_reference'
                                  className={'form-control'}
                                  options={Constants.BOOKING_REFERENCE}
                                  hint={'Reference for the booking if any.'}
                                />
                              </div>

                              {(String(formikProps.values.booking_reference) !== '0') && (
                                <div className="col-12 form-group">
                                  <Input
                                    label={'Reference Description'}
                                    className={'form-control'}
                                    name='booking_reference_description'
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                        </div>
                        <hr/>
                        <div className="form-row">
                          <div className="col-12 form-group">
                            <Checkbox
                              className={'mr-2'}
                              name='booking_order_mask'
                              text='Mask Booking Order'
                              title='Order Visibility'
                              hint={Strings.bookingOrderMaskText}
                            />
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <button
                      type="submit" onClick={formikProps.handleSubmit} className={'btn btn-success btn-lg btn-block'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default BookingEdit;
