/**
 *
 * Property List
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../lib/api';
import Strings from '../../constants/strings';
// Components
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import SearchBar from '../../components/forms/SearchBar';
import placeholder from '../../images/resources/place-holder-bg.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faLongArrowAltRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
// Actions
import * as PropertyActions from '../../redux/property/actions';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  user: state.user.user,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setProperty: PropertyActions.setProperty,
};

/* Component ==================================================================== */
class PropertyList extends Component {
  static componentName = 'PropertyList';

  static propTypes = {
    changeProperty: PropTypes.bool,
  };

  fetchData = (page = 1, callback, searchText) => {
    const urlParams = {...this.state.urlParams};
    let baseUrl='property';
    urlParams['page'] = page;
    if (searchText) {
      this.setState({searching: true, clear: true});
      baseUrl = 'property-search';
      urlParams['search'] = searchText;
    }
    const queryString = Object.keys(urlParams).map(key => key + '=' + urlParams[key]).join('&');
    AppAPI.propertyapi.get(`${baseUrl}/?${queryString}`)
      .then((res) => {
        if (res.results.length > 0) {
          callback(res.results, {allLoaded: !res.next});
        } else {
          callback();
        }
        this.setState({searching: false, clear: false, loading: false,});
      })
      .catch((err) => {
        callback([], {allLoaded: true});
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, loading: false, error,});
      });
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={7}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faBuilding}/> {Strings.propertyListEmptyTitle} </h4>
            {Strings.propertyListEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };


  tableHeader = () => {
    return (
      <tr>
      </tr>
    );
  };

  /**
   * Select property function
   */
  selectProperty = (property) => {
    this.setState({innerLoading: true, propertySelection: property.hash});
    this.props.setProperty(property.hash)
      .then(()=>this.props.history.push('/dashboard'))
      .catch(()=>this.setState({innerLoading: false}));
  };

  renderRow = (data, key) => {
    return (
      <tr key={key} className={'a-class'}>
        <td className={'data-table-cell-xl text-left'}>
          <div className={'row align-middle'}>
            <div className={'col-2'}>
              <div className={'border align-items-center rounded property-image-list'}>
                {(!_.isEmpty(data.logo) && data.logo.logo_square)
                  ? <img src={data.logo.logo_square} alt={data.name} className={'w-100 h-100'}/>
                  : <img src={placeholder} alt={data.name} className={'w-100 h-100 grey-bg'}/>
                }
              </div>
            </div>
            <div className={'col'}>
              <h5 className="mb-1">{data.name}</h5>
              <p className="mb-1 text-muted small">{data.full_address}</p>
            </div>
          </div>
        </td>
        <td className={'data-table-cell-sm align-middle '}>
          <button disabled={this.state.innerLoading} className={'btn btn-outline-primary'} onClick={() => this.selectProperty(data)}>
            Select
            <FontAwesomeIcon
              className={'ml-2'}
              icon={this.state.propertySelection === data.hash ? faSpinner : faLongArrowAltRight}
              spin={this.state.propertySelection === data.hash}
            />
          </button>
        </td>
      </tr>
    );
  }

  render = () => {

    const {loading, error, clear, searching, results} = this.state;
    const {user} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="property-list screen-container ">
        <Helmet>
          <title>Property List</title>
        </Helmet>

        <div className="page-header">
          <h1>Property List</h1>
          <p>Hi {user.name ? user.name : user.email}, choose a property to continue.</p>
        </div>


        <div className="row">
          <div className="col-lg-7 col-md-12 col-sm-12 table-hover">
            <SearchBar
              clear={clear}
              results={results}
              searching={searching}
              placeHolder={'Property Name'}
              colSpan={5}
              tableHeader={this.tableHeader}
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              searchFunction={this.fetchData}
            />
            <div className="spacer-20"/>
            <div className="text-center">
              <Link to={'/property/create'} className="btn btn-success"><span className="fa fa-plus"/> Add
                Property</Link>
            </div>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      clear: false,
      loading: false,
      searching: false,
      innerLoading: false,
      propertySelection: null,
      results: [],
      urlParams: {},
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyList);
