/**
 * Slider image add or manage
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import { Img } from 'react-image';
import * as Yup from 'yup';
// Consts and Libs
import AppAPI from '../../../lib/api';
// Components
import {Formik} from 'formik';
import {Alerts} from '../../../components/ui';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
import {Checkbox, DropZone, Form, Input, Textarea, SubmitBtn} from 'react-formik-ui';

/* Component ==================================================================== */

class PropertySliderImageManage extends Component {
  static componentName = 'PropertySliderImageManage';

  static propTypes = {
    data: PropTypes.object,
    event: PropTypes.object,
    gallery: PropTypes.bool,
    roomType: PropTypes.object,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      let url, urlApi;

      if (this.props.roomType) {
        urlApi = AppAPI.roomapi;
        url = 'roomtype-slider-image';
      }

      else if (this.props.event) {
        urlApi = AppAPI.eventapi;
        url = 'event-slider-image';
      }
      else if (this.props.gallery) {
        urlApi = AppAPI.propertyapi;
        url = 'property-gallery-image';
      }
      else {
        url = 'property-slider-image';
        urlApi = AppAPI.propertyapi;
      }


      this.setState({
        url,
        urlApi,
        init: true,
        loading: true,
        record: null,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  preFillData = () => {
    const {urlApi, url } = this.state;
    if (this.props.data) {
      urlApi.get(`${url}/${this.props.data.id}/`)
        .then((res) => {
          this.setState({
            record: res,
            init: false,
            loading: false,
            initialValues: {
              'title': res.title,
              'description': res.description,
              'active': res.active,
              'position': res.position,
              'image': [],
            },
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}, init: false, loading: false});
        });
    } else {
      this.setState({
        init: false,
        loading: false,
        initialValues: {
          'description': '',
          'position': 1,
          'active': true,
          'image': [],
          'title': '',
        },
      });
    }
  };


  addProfileRecord = (credentials) => {
    const {urlApi, url } = this.state;
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});
      const formData = new FormData();
      formData.append('title', credentials.title);
      formData.append('description', credentials.description);
      formData.append('active', true);
      formData.append('position', credentials.position);
      formData.append('image', credentials.image ? credentials.image[0] : null);
      if (this.props.roomType) {
        formData.append('room_type', this.props.roomType.id);
      }
      if (this.props.event) {
        formData.append('event', this.props.event.id);
      }
      urlApi.post(`${url}/`, formData, true)
        .then(() => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                if (this.props.onSave) {
                  this.props.onSave();
                }
                this.props.onHide();
              }, 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  updateProfileRecord = (credentials) => {
    const {urlApi, url } = this.state;
    const {record} = this.state;
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});
      const formData = new FormData();
      formData.append('title', credentials.title);
      formData.append('description', credentials.description);
      formData.append('active', !!credentials.active);
      formData.append('position', credentials.position);

      if (credentials.image && credentials.image[0]) {
        formData.append('image', credentials.image ? credentials.image[0] : null);
      }

      if (credentials.remove_file) {
        formData.append('record_image', new File([], ''));
      }

      urlApi.patch(`${url}/${record.id}/`, formData, true)
        .then(() => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                if (this.props.onSave) {
                  this.props.onSave();
                }
                this.props.onHide();
              }, 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  render = () => {

    const {init, resultMsg, initialValues, record, loading} = this.state;
    const {show} = this.props;

    if (show && init) {
      this.preFillData();
    }

    if (!show) return null;

    const SUPPORTED_IMAGE_FORMATS = ['image/png', 'image/jpeg', 'image/jpg'];
    const FILE_SIZE = 2 * 1024 * 1024;
    const formValidation = Yup.object().shape({
      position: Yup.number().positive('Enter a valid position.').required('Required.'),
      image: Yup.mixed()
        .test(
          'fileRequired',
          'Record file is required',
          value => (record ? true : value && value[0]),
        )
        .test(
          'fileFormat',
          'Unsupported Format',
          value => value && value[0] ? SUPPORTED_IMAGE_FORMATS.includes(value[0].type) : true,
        )
        .test('fileSize', 'File is too large < 4Mb', value => {
          return value && value[0] ? value[0].size <= FILE_SIZE : true;
        }),
    });


    return (
      <Modal
        {...this.props}
        size={'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.props.gallery ? 'Gallery' : 'Slider'} Image
          </Modal.Title>
        </Modal.Header>
        {loading ? <Loading heightMatch={false}/> :
          <Formik
            onSubmit={(values) => (record ? this.updateProfileRecord(values) : this.addProfileRecord(values))}
            validationSchema={formValidation}
            initialValues={initialValues}>
            {() => (
              <React.Fragment>
                <Modal.Body>
                  <Form>

                    {record && record.image &&
                     <div>
                       <Img
                         className={'border p-1 w-100'}
                         src={record.image.slider}
                         loader={<Loading heightMatch={false}/>}
                       />
                       <hr/>
                     </div>
                    }

                    <div className={'row mt-2'}>
                      <div className={'col-sm-12 form-group'}>
                        <p className={'text-muted'}>
                          Uploaded image will be cropped into two resolutions <code>960 x 540</code> and <code>480 x
                          270</code>. Upload image with aspect ratio of <code>16:9</code> for better appearance.
                        </p>
                        <DropZone
                          name='image'
                          label='File upload'
                          placeholder='Drop files here, or click to select files. ( jpeg or png files only < 4Mb )'
                          multiple={false}
                          withClearButton
                        />
                      </div>
                    </div>
                    <div className={'row'}>
                      <div className="col-12 form-group">
                        <Input
                          required
                          className={'form-control'}
                          name='title'
                          label='Image Title'
                        />
                      </div>
                    </div>
                    <div className={'row'}>
                      {record && record.image &&
                       <div className="col-6 form-group">
                         <Checkbox
                           className={'mr-2'}
                           name='active'
                           label='Image Active'
                           text='Un-check to hide image.'
                         />
                       </div>
                      }
                      <div className="col-6 form-group">
                        <Input
                          name='position'
                          label={'Image Position'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <div className={'row'}>
                      <div className="col-12 form-group">
                        <Textarea
                          name='description'
                          label={'Description'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                  </Form>

                </Modal.Body>
                <Modal.Footer className={'d-block'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <div className={'row'}>
                    <div className={'col-6'}>
                      <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                      </button>
                    </div>
                    <div className={'col-6'}>
                      <SubmitBtn className={'btn btn-success btn-lg btn-block'}>
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={record ? faSave : faPlus} size={'sm'}/>
                        {record ? 'Save' : 'Add'} Record
                      </SubmitBtn>
                    </div>
                  </div>
                </Modal.Footer>
              </React.Fragment>
            )}
          </Formik>
        }
      </Modal>
    );
  };


  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: false,
      record: null,
      initialValues: {},
    };
  }
}

/* Export Component ==================================================================== */
export default PropertySliderImageManage;
