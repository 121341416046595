/**
 *
 * Event Details
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
// Consts and Libs
import {Constants, Strings} from '../../constants';
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
// Components
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import PageHeader from '../../components/dashboard/PageHeader';
import {Alerts, History} from '../../components/ui';
import GuestListView from '../guest/GuestListView';
import OrderListView from '../order/OrderListView';
import GuestDetailsOverView from '../guest/GuestDetailsOverView';
import GuestQuickManage from '../guest/GuestManageQuick';
import ProfileQuickSearch from '../guest/ProfileQuickSearch';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faChevronCircleDown, faFilePdf,
  faPaperPlane, faStickyNote,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import TicketDetailsView from './TicketDetailsView';
import NotesListView from '../notes/NotesListView';
import {confirmAlert} from '../../components/general/ConfirmAlert';
import MessagingManager from '../messaging/MessagingManager';
import NotesManager from '../notes/NotesManager';
// Actions
import * as EventActions from '../../redux/event/actions';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  event: state.event.event,
  ticketBooking: state.event.ticketbooking,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setTicketBooking: EventActions.setTicketBooking,
};

/* Component ==================================================================== */
class TicketBookingDetails extends Component {
  static componentName = 'TicketBookingDetails';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    if (this.props.match.params.ticketBookingId) {
      this.fetchInitData();
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };

  fetchInitData = () => {
    this.setState({
      loading: true,
    }, () => {
      this.props.setTicketBooking(this.props.match.params.ticketBookingId)
        .then(() => {
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    });
  };

  cacheReset = () => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.eventapi
        .post('ticket-booking/' + this.props.ticketBooking.id + '/cache-reset/', {})
        .then(res => {
          this.setState({resultMsg: {success: ''}});
          this.props.setTicketBooking(res);
          this.setState({resultMsg: {success: 'Success'}}, () => {
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            resultMsg: {error},
          });
        });
    });
  };


  ticketBookingUpdate = (payload) => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.eventapi
          .patch(`ticket-booking/${this.props.ticketBooking.id}/`, payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({
                  showProfileDetails: false,
                  showGuestManagement: false,
                  showProfileSearch: false,
                  resultMsg: {success: ''},
                });
                this.props.setTicketBooking(res);
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  showProfileDetails = (guestId, add_guest = false) => {
    this.setState({profileDetailsData: guestId, showProfileDetails: true, addProfile: add_guest});
  };

  profileActions = (profile) => {
    if (profile) {
      if (profile.profile_type === Constants.PROFILE_TYPE.GUEST) {
        this.ticketBookingUpdate({guest_data: this.state.addProfile ? profile.id : null});
      } else {
        this.ticketBookingUpdate({corporate_data: this.state.addProfile ? profile.id : null});
      }
    }
  };

  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {ticketBooking} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="guest-details screen-container ">
        <Helmet>
          <title>Ticket Booking Details</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={'Ticket Booking Details'}
          description={'Reference number : ' + ticketBooking.ref_no}
          history={this.props.history}
        />

        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />

            {ticketBooking.ticket_booking_status === Constants.TICKET_BOOKING_STATUS.PROVISIONAL && (
              <div className={'mb-3'}>
                <Alerts info={ `${Strings.eventTicketBookingConfirmationText } Expires at ${AppUtil.formatDateTime(ticketBooking.ticket_booking_time_out)}.`}/>
              </div>
            )}
            <div className={'row'}>
              <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>

                <div className={'dates dates-wrapper'}>
                  <div className="row">
                    <div className="col-8">
                      <div className="border text-center rounded">
                        <small><span className="fa fa-sign-in"/> Event Date</small>
                        <h5>{AppUtil.formatDateTime(ticketBooking.ticket_date, 'datef')}</h5>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="border text-center rounded">
                        <small><span className="fa fa-moon-o"/> Quantity</small>
                        <h5>{ticketBooking.quantity}</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <ul className="list-group mt-3">
                  <li className="list-group-item">
                    <strong>Ticket Booking ID :</strong>
                    {ticketBooking.ticket_booking_id ? ticketBooking.ticket_booking_id : (ticketBooking.ticket_booking_status === Constants.TICKET_BOOKING_STATUS.BOOKED ?
                      <small>(Processing)</small> : <small>(Generated Post confirmation)</small>)}
                  </li>

                  <li className="list-group-item small">
                    <strong>Reference :</strong>{ticketBooking.ref_no}
                  </li>

                  {ticketBooking.remarks &&
                   <li className="list-group-item">
                     <strong>Description :</strong> {ticketBooking.remarks}
                   </li>
                  }
                </ul>
              </div>
              <div className={'col-lg-6 col-md-12 col-sm-12'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Source : </strong>
                    <span className={'badge badge-info'}>{ticketBooking.ticket_booking_source_display}</span>
                  </li>
                  <li className="list-group-item">
                    <strong>Status : </strong>
                    <span className={'badge ' + (AppUtil.ticketBookingStatusColor(ticketBooking.ticket_booking_status))}>
                      {ticketBooking.ticket_booking_status_display}
                    </span>
                  </li>
                  <li className="list-group-item">
                    <strong>Claim Status : </strong>
                    <span className={'badge ' + (AppUtil.ticketBookingClaimColor(ticketBooking.ticket_claim_status))}>
                      {ticketBooking.ticket_claim_status_display}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="mb-3">
              <div
                className={'border p-2 text-center anchor'} data-toggle="collapse" href="#Actions"
                role="button" aria-expanded="false" aria-controls="Actions">
                <h5 className={'mb-0'}>
                  <FontAwesomeIcon
                    onClick={() => this.cacheReset()} icon={faChevronCircleDown} size={'sm'}
                    className={'mx-2 green-cl float-left mt-1'}/>
                  More Information
                  <FontAwesomeIcon icon={faChevronCircleDown} size={'sm'} className={'mx-2 green-cl float-right mt-1'}/>
                </h5>
              </div>
              <div className={'py-2 border collapse show p-2'} id={'Actions'}>
                <div className={'row'}>
                  <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                    <ul className="list-group">
                      <li className="list-group-item">
                        <strong>Event : </strong> {ticketBooking.attributes.event}
                      </li>
                    </ul>
                  </div>
                  <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                    <ul className="list-group">
                      <li className="list-group-item">
                        <strong>Ticket : </strong>
                        <button className={'btn btn-link p-0 m-0'} onClick={()=>this.setState({showTicketDetailsView: true, showTicketDetailsViewId: ticketBooking.ticket_id})}>
                          {ticketBooking.ticket.name}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <hr/>
            <div className={'row'}>
              <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                <h6>{ticketBooking.corporate ? 'Booked for' : 'Guest'}</h6>
                {ticketBooking.guest ?
                  <GuestListView
                    data={ticketBooking.guest}
                    action={() => ticketBooking.guest.id ? this.showProfileDetails(ticketBooking.guest.id) : null}
                  />
                  :
                  <React.Fragment>
                    {ticketBooking.ticket_claim_status === Constants.TICKET_BOOKING_CLAIM_STATUS.NOT_CLAIMED &&
                     <button
                       type="button" className="btn btn-link m-0 p-0" data-toggle="modal"
                       onClick={() => this.setState({showGuestManagement: true, addProfile: true})}
                     >
                       Add Guest
                     </button>
                    }
                  </React.Fragment>
                }
              </div>


              <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                {ticketBooking.corporate &&
                 <React.Fragment>
                   <h6>{ticketBooking.booking_source_display}</h6>
                   <GuestListView
                     data={ticketBooking.corporate}
                     action={() => ticketBooking.corporate.id ? this.showProfileDetails(ticketBooking.corporate.id) : null}
                   />
                 </React.Fragment>
                }
              </div>
            </div>

            <hr/>

            <div className={'row'}>
              <div className={'col-lg-6 col-md-12'}>
                <h6>Orders</h6>
                <p className={'small'}>Orders linked with this booking.</p>
                {(ticketBooking.orders && ticketBooking.orders.length) ?
                  <div className="list-group">
                    {ticketBooking.orders.map((data, key) => (
                      <OrderListView
                        data={data}
                        key={key}
                        history={this.props.history}
                      />
                    ))}
                  </div> : <div>
                    <p className={'small'}>No order in the booking.</p>
                  </div>
                }
              </div>
              {(ticketBooking.notes && ticketBooking.notes.length > 0) &&
               <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                 <h6>Notes</h6>
                 <p className={'small'}>Notes linked with this booking.</p>
                 <div className="list-group">
                   {ticketBooking.notes.map((data, key) => (
                     <NotesListView
                       data={data}
                       key={key}
                       successAction={()=>this.fetchInitData()}
                     />
                   ))}
                 </div>
               </div>
              }
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group mb-5">
              <li className="list-group-item  align-items-center">
                <button onClick={() => this.setState({showNotesManager: true})} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faStickyNote} size={'sm'} className={'mr-2'}/> Add Note
                </button>
              </li>
              <li className="list-group-item align-items-center">
                <button onClick={() => this.setState({showMessagingManager: true})} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faPaperPlane} size={'sm'} className={'mr-2'}/> Notification ( E-Mail / SMS )
                </button>
              </li>
              <li className="list-group-item">
                <a
                  href={ticketBooking.resources_link} rel='noopener noreferrer' target={'_blank'}
                  className={'btn btn-link m-0 p-0 '}>
                  <FontAwesomeIcon icon={faFilePdf} size={'sm'} className={'mr-2'}/> Ticket Summary PDF
                </a>
              </li>
            </ul>

            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center small">
                Booking Actions
              </li>
              <li className="list-group-item">
                <button
                  type="button"
                  className={'btn btn-link m-0 p-0 green-cl'}
                  disabled={!(ticketBooking.ticket_booking_status === Constants.TICKET_BOOKING_STATUS.PROVISIONAL)}
                  onClick={() => {
                    confirmAlert({
                      title: 'Confirm Ticket',
                      message: Strings.eventTicketBookingConfirmationWarning,
                      buttons: [
                        {
                          className: ('btn-success'),
                          label: 'Yes',
                          onClick: () => this.ticketBookingUpdate({ticket_booking_status: Constants.TICKET_BOOKING_STATUS.BOOKED}),
                        },
                        {
                          className: 'btn-secondary',
                          label: 'No',
                        },
                      ],
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCheckCircle} size={'sm'}
                    className={'mr-2'}/> Confirm Ticket Booking
                </button>
                <p className={'small text-muted mb-0'}>{Strings.eventTicketBookingConfirmationConfirmText}</p>
              </li>
              <li className="list-group-item">
                <button
                  type="button"
                  className={'btn btn-link m-0 p-0 red-cl'}
                  disabled={ticketBooking.ticket_booking_status === Constants.TICKET_BOOKING_STATUS.CANCELLED}
                  onClick={() => {
                    confirmAlert({
                      title: 'Confirm Ticket',
                      message: Strings.eventTicketBookingCancellationWarning,
                      buttons: [
                        {
                          className: ('btn-danger'),
                          label: 'Yes',
                          onClick: () => this.ticketBookingUpdate({ticket_booking_status: Constants.TICKET_BOOKING_STATUS.CANCELLED}),
                        },
                        {
                          className: 'btn-secondary',
                          label: 'No',
                        },
                      ],
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faTimesCircle} size={'sm'} className={'mr-2'}/> Cancel Ticket Booking
                </button>
                <p className={'small text-muted mb-0'}>{Strings.eventTicketBookingCancelText}</p>
              </li>
            </ul>
          </div>
        </div>

        {/*  Profile Details */}
        <GuestDetailsOverView
          onHide={() => this.setState({showProfileDetails: false})}
          guestId={this.state.profileDetailsData}
          show={this.state.showProfileDetails}
          addGuestFunction={this.state.addProfile}
          action={this.profileActions}
          resultMsg={resultMsg}
        />

        <GuestQuickManage
          onHide={() => this.setState({showGuestManagement: false})}
          show={this.state.showGuestManagement}
          property={this.props.property}
          showGuestDetails={this.showProfileDetails}
          guestAddAction={this.profileActions}
        />

        <ProfileQuickSearch
          onHide={() => this.setState({showProfileSearch: false})}
          show={this.state.showProfileSearch}
          showProfileDetails={this.showProfileDetails}
          isCorporate={this.state.profileSource === Constants.PROFILE_TYPE.CORPORATE}
          isAgent={this.state.profileSource === Constants.PROFILE_TYPE.AGENT}
        />
        <TicketDetailsView
          show={this.state.showTicketDetailsView}
          ticketId={this.state.showTicketDetailsViewId}
          onHide={()=>this.setState({showTicketDetailsView: false})}
          property={this.props.property}
        />
        {/* Messaging */}
        <MessagingManager
          onHide={()=>this.setState({showMessagingManager: false})}
          show={this.state.showMessagingManager}
          property={this.props.property}
          ticketBooking={ticketBooking}
          guests={ticketBooking.guest ? [ticketBooking.guest] : null}
          preFetchData={true}
        />
        {/* Notes */}
        <NotesManager
          onHide={()=>this.setState({showNotesManager: false})}
          show={this.state.showNotesManager}
          successAction={()=>this.fetchInitData()}
          ticketBooking={ticketBooking}
        />
        <History object={ticketBooking}/>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      profileDetailsData: null,
      showNotesManager: false,
      showProfileDetails: false,
      showGuestManagement: false,
      showMessagingManager: false,
      showTicketDetailsView: false,
      showTicketDetailsViewId: false,
      profileSource: Constants.PROFILE_TYPE.CORPORATE,
      showProfileSearch: false,
      addProfile: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketBookingDetails);
