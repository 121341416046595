/**
 *
 * Booking Engine Rules
 * property booking engine rules settings.
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {FieldArray, Formik} from 'formik';
// Consts and Libs
import AppAPI from '../../../lib/api';
// Components
import Alerts from '../../../components/ui/Alerts';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import {Form, Input, SubmitBtn, Textarea} from 'react-formik-ui';
import {faChevronDown, faChevronUp, faMinus, faPlus, faSave} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// Actions
import * as PropertyActions from '../../../redux/property/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetPropertyContent: PropertyActions.isSetPropertyContent,
};

/* Component ==================================================================== */
class PropertyBookingEngineRulesManage extends Component {
  static componentName = 'PropertyBookingEngineRulesManage';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    AppAPI.property.get(`${this.props.property.id}/content-settings/`)
      .then((res) => {
        this.setState({
          loading: false,
          initialValues: {
            bengine_faq: res.bengine_faq,
            bengine_room_booking_rules: res.bengine_room_booking_rules,
            bengine_event_booking_rules: res.bengine_event_booking_rules,
          },
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: true,
          resultMsg: {error},
        });
      });
  };

  savePropertyContent = (values) => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.property.patch(`${this.props.property.id}/content-settings/`, {
        'bengine_faq': values.bengine_faq,
        'bengine_room_booking_rules': values.bengine_room_booking_rules,
        'bengine_event_booking_rules': values.bengine_event_booking_rules,
      })
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                this.props.isSetPropertyContent(res)
                  .then(() => {
                    this.props.history.goBack();
                  });
              }, 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    });
  };

  render = () => {
    const {loading, error, initialValues, resultMsg} = this.state;
    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const contentValidation = Yup.object().shape({
      title: Yup.string().required('Required'),
    });
    const defaultFormValidation = Yup.object().shape({
      bengine_faq: Yup.array().of(contentValidation).nullable(),
      bengine_room_booking_rules: Yup.array().of(contentValidation).nullable(),
      bengine_event_booking_rules: Yup.array().of(contentValidation).nullable(),
    });

    return (
      <div className="bank-details screen-container ">
        <Helmet>
          <title>Booking Engine Rules Settings</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'Property Rules Settings'}
          description={'Rules and Regulations for property.'}
        />
        <Formik
          onSubmit={(values) => (this.savePropertyContent(values))}
          validationSchema={defaultFormValidation}
          initialValues={initialValues}>
          {({values}) => (
            <Form>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12 mb-5">
                  <div
                    className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                    role="button" aria-expanded="false" aria-controls="orderActions">
                    <h6 className={'mb-0'}>
                      Frequently Asked Questions
                    </h6>
                  </div>
                  <div className={'border p-2'} id={'primaryAction'}>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-12 form-group">
                        <FieldArray
                          name="bengine_faq"
                          render={arrayHelpers => (
                            <div>
                              {!_.isEmpty(values.bengine_faq) ? (
                                <div>{values.bengine_faq.map((public_link, index) => (
                                  <div key={index} className={`form-row ${index > 0 && 'border-top pt-3'}`}>
                                    <div className="col-10">
                                      <div className={'form-group'}>
                                        <Input
                                          required
                                          name={`bengine_faq.${index}.title`}
                                          label={'Question'}
                                          className={'form-control'}
                                        />
                                      </div>
                                      <div className={'form-group'}>
                                        <Textarea
                                          required
                                          name={`bengine_faq.${index}.description`}
                                          label={'Answer'}
                                          className={'form-control'}
                                        />
                                      </div>
                                    </div>
                                    <div className={'col-2 text-center'}>
                                      <div className="btn-group btn-group-sm mt-4" role="group">
                                        <button
                                          onClick={() => arrayHelpers.remove(index)}
                                          type="button" className="btn btn-outline-danger">
                                          <FontAwesomeIcon className={'mr-2 d-none d-md-inline-block'} icon={faMinus} size={'sm'}/> Remove
                                        </button>
                                      </div>
                                      <div className="btn-group btn-group-sm mt-4 text-center" role="group">
                                        <button
                                          disabled={index<0}
                                          onClick={() => arrayHelpers.move(index, index-1)}
                                          type="button" className="btn btn-outline-info">
                                          <FontAwesomeIcon className={'d-none d-md-inline-block'} icon={faChevronUp} size={'sm'}/>
                                        </button>
                                        <button
                                          onClick={() => arrayHelpers.move(index, index+1)}
                                          type="button" className="btn btn-outline-info">
                                          <FontAwesomeIcon className={'d-none d-md-inline-block'} icon={faChevronDown} size={'sm'}/>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                <div className={'pt-3 border-top'}>
                                  <button
                                    className={'btn btn-success'} type="button"
                                    onClick={() => arrayHelpers.push('')}>
                                    <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add a
                                      Rule
                                  </button>
                                </div>
                                </div>
                              ) : (
                                <div className={'text-center'}>
                                  <p>No Rules.</p>
                                  <button
                                    className={'btn btn-success'} type="button"
                                    onClick={() => arrayHelpers.push('')}>
                                    <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add a Rule
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12 mb-5">
                  <div
                    className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                    role="button" aria-expanded="false" aria-controls="orderActions">
                    <h6 className={'mb-0'}>
                      Room Booking Rules
                    </h6>
                  </div>
                  <div className={'border p-2'} id={'primaryAction'}>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-12 form-group">
                        <FieldArray
                          name="bengine_room_booking_rules"
                          render={arrayHelpers => (
                            <div>
                              {!_.isEmpty(values.bengine_room_booking_rules) ? (
                                <div>{values.bengine_room_booking_rules.map((public_link, index) => (
                                  <div key={index} className={`form-row ${index > 0 && 'border-top pt-3'}`}>
                                    <div className="col-10">
                                      <div className={'form-group'}>
                                        <Input
                                          required
                                          name={`bengine_room_booking_rules.${index}.title`}
                                          label={'Rule'}
                                          className={'form-control'}
                                        />
                                      </div>
                                      <div className={'form-group'}>
                                        <Textarea
                                          name={`bengine_room_booking_rules.${index}.description`}
                                          label={'Description'}
                                          className={'form-control'}
                                        />
                                      </div>
                                    </div>
                                    <div className={'col-2 text-center'}>
                                      <div className="btn-group btn-group-sm mt-4" role="group">
                                        <button
                                          onClick={() => arrayHelpers.remove(index)}
                                          type="button" className="btn btn-outline-danger">
                                          <FontAwesomeIcon className={'mr-2 d-none d-md-inline-block'} icon={faMinus} size={'sm'}/> Remove
                                        </button>
                                      </div>
                                      <div className="btn-group btn-group-sm mt-4 text-center" role="group">
                                        <button
                                          disabled={index<0}
                                          onClick={() => arrayHelpers.move(index, index-1)}
                                          type="button" className="btn btn-outline-info">
                                          <FontAwesomeIcon className={'d-none d-md-inline-block'} icon={faChevronUp} size={'sm'}/>
                                        </button>
                                        <button
                                          onClick={() => arrayHelpers.move(index, index+1)}
                                          type="button" className="btn btn-outline-info">
                                          <FontAwesomeIcon className={'d-none d-md-inline-block'} icon={faChevronDown} size={'sm'}/>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                <div className={'pt-3 border-top'}>
                                  <button
                                    className={'btn btn-success'} type="button"
                                    onClick={() => arrayHelpers.push('')}>
                                    <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add a
                                      Rule
                                  </button>
                                </div>
                                </div>
                              ) : (
                                <div className={'text-center'}>
                                  <p>No Rules.</p>
                                  <button
                                    className={'btn btn-success'} type="button"
                                    onClick={() => arrayHelpers.push('')}>
                                    <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add a Rule
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12 mb-5">
                  <div
                    className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                    role="button" aria-expanded="false" aria-controls="orderActions">
                    <h6 className={'mb-0'}>
                      Event Booking Rules
                    </h6>
                  </div>
                  <div className={'border p-2'} id={'primaryAction'}>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-12 form-group">
                        <FieldArray
                          name="bengine_event_booking_rules"
                          render={arrayHelpers => (
                            <div>
                              {!_.isEmpty(values.bengine_event_booking_rules) ? (
                                <div>
                                  {values.bengine_event_booking_rules.map((public_link, index) => (
                                    <div key={index} className={`form-row ${index > 0 && 'border-top pt-3'}`}>
                                      <div className="col-10">
                                        <div className={'form-group'}>
                                          <Input
                                            required
                                            name={`bengine_event_booking_rules.${index}.title`}
                                            label={'Rule Title'}
                                            className={'form-control'}
                                          />
                                        </div>
                                        <div className={'form-group'}>
                                          <Textarea
                                            name={`bengine_event_booking_rules.${index}.description`}
                                            label={'Description'}
                                            className={'form-control'}
                                          />
                                        </div>
                                      </div>
                                      <div className={'col-2 text-center'}>
                                        <div className="btn-group btn-group-sm mt-4" role="group">
                                          <button
                                            onClick={() => arrayHelpers.remove(index)}
                                            type="button" className="btn btn-outline-danger">
                                            <FontAwesomeIcon className={'mr-2 d-none d-md-inline-block'} icon={faMinus} size={'sm'}/> Remove
                                          </button>
                                        </div>
                                        <div className="btn-group btn-group-sm mt-4 text-center" role="group">
                                          <button
                                            disabled={index<0}
                                            onClick={() => arrayHelpers.move(index, index-1)}
                                            type="button" className="btn btn-outline-info">
                                            <FontAwesomeIcon className={'d-none d-md-inline-block'} icon={faChevronUp} size={'sm'}/>
                                          </button>
                                          <button
                                            onClick={() => arrayHelpers.move(index, index+1)}
                                            type="button" className="btn btn-outline-info">
                                            <FontAwesomeIcon className={'d-none d-md-inline-block'} icon={faChevronDown} size={'sm'}/>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                  <div className={'pt-3 border-top'}>
                                    <button
                                      className={'btn btn-success'} type="button"
                                      onClick={() => arrayHelpers.push('')}>
                                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add a
                                      Rule
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div className={'text-center'}>
                                  <p>No Rules.</p>
                                  <button
                                    className={'btn btn-success'} type="button"
                                    onClick={() => arrayHelpers.push('')}>
                                    <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add a Rule
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className={'row mb-5'}>
                <div className="col-lg-8 col-md-8 col-sm-12 mb-5">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn className={'btn btn-md btn-success btn-lg btn-block'}>
                    <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      initialValues: {
        footer_notes: '',
        property_links: [],
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyBookingEngineRulesManage);