/**
 *
 * POS List
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {Link} from 'react-router-dom';

// Consts and Libs
import AppAPI from '../../../lib/api';
import Strings from '../../../constants/strings';

// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCashRegister, faLongArrowAltRight, faPlus} from '@fortawesome/free-solid-svg-icons';
import SearchBar from '../../../components/forms/SearchBar';
import PageHeader from '../../../components/dashboard/PageHeader';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class POSList extends Component {
  static componentName = 'POSList';

  componentDidMount = () => {
  };

  fetchData = (page = 1, callback) => {
    AppAPI.posapi.get('pos-settings/?page=' + page)
      .then((res) => {
        this.setState({
          loading: false,
          error: null,
        });
        if (res.results.length > 0) {
          if (res.next) {
            callback(res.results);
          } else {
            callback(res.results, {
              allLoaded: true, // the end of the list is reached
            });
          }
        } else {
          callback();
        }
      })
      .catch((err) => {
        callback(null, {
          allLoaded: true, // the end of the list is reached
        });
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };
  renderRow = (data, key) => {
    return (
      <tr key={key}>
        <td>{data.name}</td>
        <td>{data.pos_type_display}</td>
        <td className={'align-middle'}>
          <Link to={`/settings/pos/${data.hash}/`}>
            Details / Manage <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
          </Link>
        </td>
      </tr>
    );
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={6}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faCashRegister}/> Point of Sales </h4>
            {Strings.POSListEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };

  tableHeader = () => {
    return (
      <tr>
        <th className={'data-table-cell-lg'}>Name</th>
        <th className={'data-table-cell-lg'}>Type</th>
        <th className={'data-table-cell-xm'}/>
      </tr>
    );
  };

  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="pos-list screen-container ">
        <Helmet>
          <title>Point of Sales</title>
        </Helmet>

        <PageHeader
          subHeader={true} history={this.props.history} title={'Point of Sales(s)'}
        />

        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12 mb-5">
            <SearchBar
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
              colSpan={4}
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className={'btn btn-link p-0'} to={'/settings/pos/new'}>
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add POS
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(POSList);