/**
 *
 * Room Report
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import Moment from 'moment';
// Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PageHeader from '../../../components/dashboard/PageHeader';
import DateRangePicker from 'react-daterange-picker';
import 'react-daterange-picker/dist/css/react-calendar.css';
import {
  faCaretDown, faCircleNotch,
  faExternalLinkAlt,
  faFileCsv,
  faFilePdf,
  faLongArrowAltLeft, faLongArrowAltRight,
  faSync
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class BookingRoomReports extends Component {
  static componentName = 'BookingRoomReports';

  componentDidMount = () => {
    if (this.props.match.params.date) {
      this.setState({
        date: Moment(String(this.props.match.params.date)),
      }, () => this.fetchInitData());
    } else {
      this.fetchInitData();
    }
  };


  fetchInitData = () => {
    this.setState({
      loading_inner: true,
      error: false,
    });

    let url = 'booking-room-report/';

    if (this.state.date) {
      url = `${url}${Moment(this.state.date).format('YYYY-MM-DD')}/`;
    }

    if (this.state.selector) {
      url = `${url}?active=${this.state.selector}`;
    }

    AppAPI.reportapi
      .get(url)
      .then(res => {
        if (res) {
          this.setState({
            date: Moment(res.date),
            data: res,
            loading: false,
            fileDownload: false,
            loading_inner: false,
            error: false,
          });
        } else {
          this.setState({
            loading: false,
            error: true,
          });
        }
      })
      .catch(err => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: error,
          resultMsg: {error},
        });
      });
  };

  onSelect = (date) => {
    this.setState({date: date, showDatePicker: false});
  };

  toggleDatePicker = () => {
    this.setState({showDatePicker: !this.state.showDatePicker});
  };

  performLookup = () => {
    let date = Moment(this.state.date).format('YYYY-MM-DD');
    this.props.history.replace('/reports/booking-room-report/' + date + '/');
  };

  renderData = (data, sectionHeading = null, sectionClass = 'table-active') => {
    return (
      <React.Fragment>
        {sectionHeading &&
         <tr className={sectionClass}>
           <td className={'text-center'} colSpan={10}><h6 className={'text-center m-0'}>{sectionHeading}</h6></td>
         </tr>
        }
        {data && data.length ?
          <React.Fragment>
            {data.map((booking_room_data, i) => (
              <React.Fragment>
                <tr key={i} className={'text-break'}>
                  <td>
                    <p className={'mb-0 small'}>Reference : {booking_room_data.ref_no}</p>
                    <p className={'mb-0 small'}>Booking Ref : {booking_room_data.attributes && booking_room_data.attributes.booking_ref_no}</p>
                    <p className={'mb-0 small text-muted'}>Created : {AppUtil.formatDateTime(booking_room_data.created)}</p>
                    <span
                      className={'badge ' + (AppUtil.bookingStatusColor(booking_room_data.booking_status))}>
                      {booking_room_data.booking_status_display}
                    </span>
                  </td>
                  <td>
                    <p className={'mb-0 small'}>{AppUtil.formatDateTime(booking_room_data.checkin, 'datef')}</p>
                    {booking_room_data.actual_checkin &&
                    <React.Fragment>
                      <hr/>
                      <p className={'mb-0 small'}>Actual : {AppUtil.formatDateTime(booking_room_data.actual_checkin)}</p>
                    </React.Fragment>
                    }
                  </td>
                  <td>
                    <p className={'mb-0 small'}>{AppUtil.formatDateTime(booking_room_data.checkout, 'datef')}</p>
                    {booking_room_data.actual_checkout &&
                    <React.Fragment>
                      <hr/>
                      <p className={'mb-0 small'}>Actual : {AppUtil.formatDateTime(booking_room_data.actual_checkout)}</p>
                    </React.Fragment>
                    }
                  </td>
                  <td>
                    <p className={'small mb-0'}>{booking_room_data.attributes && booking_room_data.attributes.room_name}</p>
                  </td>
                  <td>
                    <p className={'small mb-0'}>{booking_room_data.attributes && booking_room_data.attributes.room_type_name}</p>
                    {!_.isEmpty(booking_room_data.room_type_package_attributes) &&
                      <React.Fragment>
                        <hr/>
                        <p className={'small mb-0'}> Package :
                          {booking_room_data.room_type_package_attributes.map((data, i) => (
                            <React.Fragment key={i}>
                              {i>0 && ', '} {data.room_type_package.name}
                            </React.Fragment>
                          ))}
                        </p>
                      </React.Fragment>
                    }
                  </td>
                  <td className={'w-15'}>
                    {!_.isEmpty(booking_room_data.guests) &&
                    <ol>
                      {booking_room_data.guests.map((data, i) => (
                        <li key={i} className={'small text-left'}>
                          {data.name}
                        </li>
                      ))}
                    </ol>
                    }
                  </td>
                  <td>
                    <p className={'small mb-0'}>Adult : {booking_room_data.no_of_guest}</p>
                    <p className={'small mb-0'}>Child : {booking_room_data.no_of_children}</p>
                    <p className={'small mb-0'}>Infant : {booking_room_data.no_of_infant}</p>
                  </td>
                  <td>
                    <p className={'mb-0 small'}>
                      <Link to={`/booking/${booking_room_data.attributes && booking_room_data.attributes.booking_hash ? booking_room_data.attributes.booking_hash : booking_room_data.booking_id}`} rel='noopener noreferrer' target={'_blank'}>
                        <FontAwesomeIcon
                          icon={faExternalLinkAlt} size={'sm'} /> Booking
                      </Link>
                    </p>
                    <p className={'mb-0 small'}>
                      <Link
                        to={`/booking/${booking_room_data.attributes && booking_room_data.attributes.booking_hash ? booking_room_data.attributes.booking_hash : booking_room_data.booking_id}/booking-room/${booking_room_data.hash}`}
                        rel='noopener noreferrer' target={'_blank'}>
                        <FontAwesomeIcon
                          icon={faExternalLinkAlt} size={'sm'} className={'mr-2'}/> Room
                      </Link>
                    </p>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </React.Fragment>
          :
          <tr>
            <td colSpan={10} className={'text-center'}>
              <p className={'text-center mb-0'}>
                <FontAwesomeIcon icon={faLongArrowAltLeft} className={'mx-2'}/>
                No Bookings
                <FontAwesomeIcon icon={faLongArrowAltRight} className={'mx-2'}/>
              </p>
            </td>
          </tr>
        }
      </React.Fragment>
    );
  };

  exportReport = (type = 'pdf') => {
    let url = 'booking-room-report';
    this.setState({fileDownload: true});

    if (this.state.date) {
      url = `${url}/${Moment(this.state.date).format('YYYY-MM-DD')}`;
    }

    if (this.state.selector) {
      url = `${url}/?active=${this.state.selector}`;
    } else {
      url = `${url}/?`;
    }

    let fileName = `Booking-Room-Report-${Moment(this.state.date).format('YYYY-MM-DD')}.${type}`;

    AppAPI.reportapi.get(url + `&download=${type}`)
      .then((response) => {
        response.blob().then((blob) => {
          // 2. Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          this.setState({fileDownload: false});
        });
      });
  };

  render = () => {
    const {loading, loading_inner, error, date, data, showDatePicker, selector, fileDownload} = this.state;
    const {property} = this.props;

    if (!property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="dashboard screen-container">
        <Helmet>
          <title>Booking Report</title>
        </Helmet>

        <PageHeader
          history={this.props.history} title={'Room Report'} description={'Occupied room and guest information.'}/>

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className={'row'}>
              <div className={'col-md-6 col-sm-12 mb-5'}>
                <div className="btn-group btn-group-sm" role="group">
                  <button type="button" className="btn btn-outline-secondary" onClick={this.toggleDatePicker}>
                    {AppUtil.formatDateTime(date, 'datef')}
                    <FontAwesomeIcon icon={faCaretDown} size={'lg'} className={'mx-2'}/>
                  </button>
                  <button
                    type="button" onClick={this.performLookup}
                    className={'btn ' + (Moment(data.date).isSame(date) ? 'btn-secondary' : 'btn-success')}>
                    <FontAwesomeIcon icon={faSync} size={'sm'} className={'mx-2'}/>
                    {Moment(data.date).isSame(date) ? 'Refresh' : 'Update'}
                  </button>
                </div>
                <div
                  className={(showDatePicker ? 'collapse.show' : 'collapse') + ' position-absolute bg-white'}
                  id="datePicker">
                  <DateRangePicker
                    className={'mx-auto'}
                    selectionType={'single'}
                    value={date}
                    onSelect={this.onSelect}
                  />
                </div>
              </div>
              <div className={'col-md-6 col-sm-12 mb-5 text-right'}>
                <div className="btn-group btn-group-sm" role="group">
                  <button className="btn btn-outline-secondary" onClick={() => this.exportReport('csv')}>
                    <FontAwesomeIcon icon={fileDownload ?  faCircleNotch : faFileCsv} size={'lg'} className={'mx-2'} spin={fileDownload}/> Export CSV
                  </button>
                  <button className="btn btn-outline-secondary" onClick={() => this.exportReport('pdf')}>
                    <FontAwesomeIcon icon={fileDownload ?  faCircleNotch : faFilePdf} size={'lg'} className={'mx-2'} spin={fileDownload}/> Export PDF
                  </button>
                </div>
              </div>
            </div>

            <div className={'mb-5'}>
              <div className="btn-group">
                <button
                  disabled={true}
                  type="button" className={'btn btn-outline-secondary'}>
                  Filters
                </button>
                <button
                  onClick={() => this.setState({selector: null}, () => this.fetchInitData())}
                  className={'btn ' +  (!selector ? 'btn-primary' : 'btn-outline-secondary') }>
                  All Room
                </button>
                <button
                  type="button"
                  onClick={() => this.setState({selector: 'active'}, () => this.fetchInitData())}
                  className={'btn ' +  (selector === 'active' ? 'btn-primary' : 'btn-outline-secondary') }>
                  Active Room
                </button>
              </div>
            </div>

            {loading_inner ?
              <Loading heightMatch={false}/> :
              <div className={'row'}>
                <div className={'col-xl-12 col-md-12 col-12 mb-3'}>
                  <div className="table-responsive">
                    <table className={'table table-hover table-bordered format-table'}>
                      <thead className="bg-dark text-light">
                        <tr>
                          <th className="data-table-cell-lg">Booking Ref / ID</th>
                          <th className="data-table-cell-sm">Checkin</th>
                          <th className="data-table-cell-sm">Checkout</th>
                          <th className="data-table-cell-sm">Room No</th>
                          <th className="data-table-cell-md">Room Type</th>
                          <th className="data-table-cell-xl">Guest (Primary)</th>
                          <th className="data-table-cell-md">Guest(s)</th>
                          <th className="data-table-cell-md">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.checkin_bookings && data.checkin_bookings.length > 0 &&
                         this.renderData(data.checkin_bookings, 'Checkin Bookings')
                        }
                        {data.checkout_bookings && data.checkout_bookings.length > 0 &&
                         this.renderData(data.checkout_bookings, 'Checkout Bookings')
                        }
                        {data.active_bookings && data.active_bookings.length > 0 &&
                         this.renderData(data.active_bookings, 'Active Bookings')
                        }
                        {data.other_bookings && data.other_bookings.length > 0 &&
                         this.renderData(data.other_bookings, 'Other Bookings')
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                {data.count < 1 &&
                 <div className={'col-xl-12 col-md-12 col-12 mb-3 text-center'}>
                   <h4>No Bookings</h4>
                 </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      fileDownload: false,
      loading_inner: true,
      showDatePicker: false,
      error: null,
      data: [],
      selector: null,
      date: Moment().startOf('day'),
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingRoomReports);
