/**
 * Manage RoomType Items
 *  manages RoomType items add and edit
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import FormValidation from 'tcomb-form';

// Actions
import * as PropertyActions from '../../../redux/property/actions';
import * as RoomTypeActions from '../../../redux/roomtype/actions';

// Consts and Libs
import AppAPI from '../../../lib/api';
import {ErrorMessages} from '../../../constants';

// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import FormView from '../../../components/general/FormView';
import {Link} from 'react-router-dom';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  roomType: state.roomType.roomType
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetProperty: PropertyActions.isSetProperty,
  updateRoomType: RoomTypeActions.updateRoomType
};

/* Component ==================================================================== */

class RoomTypeItemManage extends Component {
  static componentName = 'RoomTypeItemManage';

  static propTypes = {
    match: PropTypes.object.isRequired
  };
  componentDidMount = () => {
    if (!this.props.roomType.name) {
      this.props.updateRoomType(this.props.match.params.roomTypeId);
    }
    this.setState({settings: (this.props.match.params && this.props.match.params.roomTypeItemId && this.props.match.params.roomTypeItemId)});
    this.fetchInitData();

  };
  addRoomTypeItem = (credentials) => {
    if (credentials) {
      this.setState({form_values: credentials}, () => {
        this.setState({resultMsg: {status: 'One moment...'}});

        AppAPI.roomtypeitem.post('',
          {
            'name': credentials.Name,
            'active': credentials.Active,
            'room_type': this.props.match.params.roomTypeId
          })
          .then((res) => {
            // update the roomtype data in redux
            const roomType = this.props.roomType;
            roomType.room_type_items.push(res);
            this.props.updateRoomType(roomType);
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.history.replace(this.props.location.pathname.replace(/[^/]*$/, `${res.id}/`));
                }, 500);
              });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };
  saveRoomTypeItem = (credentials) => {
    if (credentials) {
      this.setState({form_values: credentials}, () => {
        this.setState({resultMsg: {status: 'One moment...'}});
        AppAPI.roomtypeitem.patch(this.props.match.params.roomTypeItemId + '/',
          {
            'id': this.props.match.params.roomTypeItemId,
            'name': credentials.Name,
            'active': credentials.Active
          })
          .then((res) => {
            // update the roomtype data in redux
            const roomType = this.props.roomType;
            this.props.roomType.room_type_items.map((item, i) => {
              if (item.id === res.id) {
                return roomType.room_type_items[i] = res;
              }
              return null;
            });
            this.props.updateRoomType(roomType);

            this.props.isSetProperty()
              .then(() => {
                this.setState({resultMsg: {success: 'Success'}},
                  () => {
                    setTimeout(() => {
                      this.props.history.goBack();
                    }, 500);
                  });
              });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };
  fetchInitData = () => {
    AppAPI.roomtypeitem.options()
      .then((res) => {
        if (res.actions !== '') {
          this.setState({
            loading: false,
            form_fields: FormValidation.struct({
              Name: FormValidation.String,
              Active: FormValidation.Boolean
            }),
            form_values: {
              Active: true
            },
            options: {}
          });
        } else {
          this.setState({
            loading: false,
            error: true
          });
        }
      })
      .then(() => {
        /**
         * To get pre-fill value for POS edit.`
         */
        if (this.state.settings) {
          AppAPI.roomtypeitem.get(this.props.match.params.roomTypeItemId + '/?room_type=' + this.props.match.params.roomTypeId)
            .then((res) => {
              this.setState({
                form_values: {
                  Name: res.name,
                  Active: res.active
                }
              });
            });
        }
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: true,
          resultMsg: {error}
        });
      });
  };
  render = () => {

    const {loading, form_fields, form_values, options, error, resultMsg} = this.state;
    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    return (
      <div className="roomtype-items-list-manage screen-container ">
        <Helmet>
          <title>Room Types Items Manage</title>
        </Helmet>

        <PageHeader
          subHeader={true} history={this.props.history} title={(this.state.settings ? 'Manage': 'Add') + ' Room Type Items'}
        />
        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12 mb-5">
            <FormView
              formFields={form_fields}
              formValues={form_values}
              formOptions={options}
              formAction={this.state.settings ? this.saveRoomTypeItem : this.addRoomTypeItem}
              formActionName={this.state.settings ? 'Save' : 'Add Item'}
              statusMsg={resultMsg}
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              {!this.state.settings &&
               <li className="list-group-item">
                 <Link
                   to={'/settings/inventory/room-type/' + this.props.roomType.id + '/item/multiple'}
                   className={'btn btn-link p-0'}
                 >
                   <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Multiple Item's
                 </Link>
                 <p className={'text-secondary small mb-0'}>Add multiple items at once ? </p>
               </li>
              }
            </ul>
          </div>
        </div>
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: ''
      },
      form_fields: FormValidation.struct({
        Name: FormValidation.String
      }),
      form_values: {},
      options: {}
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(RoomTypeItemManage);
