/**
 *
 * App Dashboard
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Consts and Libs
import AppAPI from '../../lib/api';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import DashboardUser from './DashboardUser';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = () => ({});

// Any actions to map to the component?
const mapDispatchToProps = {};


/* Component ==================================================================== */
class Dashboard extends Component {
  static componentName = 'Dashboard';

  componentDidMount = () => {
    AppAPI.dashboardapi.get()
      .then((res) => {
        this.setState({loading: false, data: res});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({loading: false, error,});
      });
  };

  render = () => {
    const {loading, error, data} = this.state;
    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className={''}>
        <DashboardUser data={data}/>
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true, error: null, data: {}
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
