import _ from 'lodash';
import {Countries, Currency, CountryStates} from './index';

const SelectList = {
  stateList: (country) => {
    const statesList = [];
    CountryStates.forEach((data)=>{
      if ((data.iso2 === country || data.iso3 === country) && !_.isEmpty(data.states)) {
        data.states.forEach((stateData)=>{
          statesList.push({
            'value': stateData.name,
            'label': stateData.name,
          });
        });
      }
    });
    return statesList;
  },
  countryList: () => {
    return Countries.map((data) => ({
      'value': data.code,
      'label': data.name,
    }));
  },
  phoneCountryCode: () => {
    return Countries.map((data) => ({
      'value': data.phone,
      'label': data.name + ' (' + data.phone + ')',
    }));
  },
  currencyList: () => {
    return Currency.map((data) => ({
      'value': data.code,
      'label': data.code + ' (' + data.country + ')',
    }));
  },
};

export default SelectList;
