/**
 *
 * Room Type Details
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

// Consts and Libs
import AppAPI from '../../../lib/api';

// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen} from '@fortawesome/free-solid-svg-icons';

// Actions
import * as RoomTypeActions from '../../../redux/roomtype/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  roomType: state.roomType.roomType,
  roomTypeItem: state.roomType.roomTypeItem
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setRoomTypeItem: RoomTypeActions.setRoomTypeItem
};

/* Component ==================================================================== */
class RoomTypeDetails extends Component {
  static componentName = 'RoomTypeDetails';
  static propTypes = {
    match: PropTypes.object.isRequired
  };
  componentDidMount = () => {
    if (this.props.match.params.roomTypeId && this.props.match.params.roomTypeItemId) {
      this.props.setRoomTypeItem(this.props.match.params.roomTypeItemId, this.props.match.params.roomTypeId)
        .then(() => {
          this.setState({
            loading: false
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error
          });
        });
    } else {
      this.setState({
        loading: false,
        error: true
      });
    }
  };
  render = () => {

    const {loading, error} = this.state;
    const {roomType, roomTypeItem} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    return (
      <div className="room-type-details screen-container ">
        <Helmet>
          <title>Room Types Item Settings</title>
        </Helmet>
        <PageHeader
          subHeader={true} history={this.props.history} title={'Room Type Item Details'}
          description={`Item reference number ${roomType.ref_no}`}
        />
        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12 mb-5">
            <div className="row mb-5">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item"><strong>Name</strong> : {roomTypeItem.name}</li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Ref</strong> : {roomTypeItem.ref_no}</li>
                </ul>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Status</strong> :
                    <span className={'badge ' + (roomTypeItem.active === true ? 'badge-success' : 'badge-danger')}>
                      {(roomTypeItem.active === true ? 'Active' : 'De-Active')}
                    </span></li>
                </ul>
              </div>
              <div className="col">
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link
                  to={`/settings/inventory/room-type/${this.props.match.params.roomTypeId}/item/${roomTypeItem.id}/edit`}
                  className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomTypeDetails);