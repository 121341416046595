/**
 * Export Button
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';

/* Component ==================================================================== */
class NavigationTabs extends Component {
  static componentName = 'NavigationTabs';

  static propTypes = {
    activeKey: PropTypes.string,
    classNameNav: PropTypes.string,
    actionFunction: PropTypes.func,
    navigationType: PropTypes.string,
    classNameLinks: PropTypes.string,
    classNameActive: PropTypes.string,
    classNameContainer: PropTypes.string,
    navItems: PropTypes.array.isRequired,
  };

  static defaultProps = {
    classNameNav: '',
    classNameLinks: '',
    classNameActive: '',
    classNameContainer: '',
    navigationType: 'nav-tabs',
  };


  render = () => {
    const {
      navItems, classNameContainer, classNameLinks, classNameNav, classNameActive, navigationType, activeKey
    } = this.props;
    return (
      <ul className={`nav ${navigationType} ${classNameContainer}`}>
        {
          navItems.map((data, i) => (
            <li className={`nav-item ${classNameLinks}`} key={`menu-item-${i}`}>
              {this.props.actionFunction ?
                <div
                  onClick={() => this.props.actionFunction(data.key)}
                  className={`nav-link rounded-0 a-class ${data.key === activeKey && 'active'}`}
                >{data.title}</div>
                :
                <NavLink
                  to={{pathname: data.link}}
                  exact={data.isExact}
                  className={`nav-link rounded-0 ${classNameNav} ${data.disabled && 'disabled text-muted'}`}
                  activeClassName={`selected ${classNameActive}`}
                >{data.title}</NavLink>

              }
            </li>
          ))
        }
      </ul>
    );
  };
}

/* Export Component ==================================================================== */
export default NavigationTabs;