/**
 *
 * Booking Room Update
 * booking Room Update
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Moment from 'moment';
// Components
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import BookingRoomListView from './BookingRoomListView';
import PageHeader from '../../components/dashboard/PageHeader';
import LookupResults from '../booking/components/LookupResults';
// Actions
import * as BookingActions from '../../redux/booking/actions';

import 'react-daterange-picker/dist/css/react-calendar.css';
import AppUtil from '../../lib/util';
import {Constants, Strings} from '../../constants';
import AppAPI from '../../lib/api';
import {Alerts} from '../../components/ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faChevronCircleDown, faCircle} from '@fortawesome/free-solid-svg-icons';
import {Form, Input} from 'react-formik-ui';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {Collapse} from 'react-bootstrap';

/* Redux ======================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  booking: state.booking,
  bookingroom: state.bookingroom.bookingroom,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setLookup: BookingActions.setLookup,
  setSelectionData: BookingActions.setSelectionData,
  unSetLookup: BookingActions.unSetLookup,
};

/* Component ==================================================================== */
class BookingRoomUpdate extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    AppAPI.bookingapi
      .post(`booking-room/${this.props.match.params.bookingRoomId}/update-room-type/`, {})
      .then(res => {
        let checkbox = [];
        if (!_.isEmpty(res.messages)) {
          res.messages.forEach((data, i) => checkbox[i] = false);
        }
        this.setState({checkbox, loading: false, lookupResponse: res});
      })
      .catch(err => this.setState({error: AppAPI.handleError(err), loading: false}));
  };

  bookingRoomUpdate = (selectiondata) => {
    const {lookupResponse} = this.state;
    const rooms = [];
    let total = parseFloat('0.0');

    if (selectiondata && Object.keys(lookupResponse).length > 0) {
      Object.keys(selectiondata).forEach((data) => {
        if (selectiondata[data] && selectiondata[data].selected_rooms > 0) {
          let roomTypeData = selectiondata[data];
          Object.keys(selectiondata[data].package).forEach(packageId => {
            let no_of_room = 0;
            let packageData = selectiondata[data].package[packageId];

            while (no_of_room < packageData.selected_rooms) {
              let roomData = {
                guest: lookupResponse.booking_room.no_of_guest,
                child: lookupResponse.booking_room.no_of_children,
                infant: lookupResponse.booking_room.no_of_infant,
                extra_bed: lookupResponse.booking_room.no_of_extra_bed,
                room_type: roomTypeData,
                occupancy_data: roomTypeData.occupancy_data,
                package: packageData.data,
                total: 0,
                order_items: lookupResponse.order_items,
              };
              no_of_room = no_of_room + 1;
              roomData = AppUtil.calculateRoomTotals(roomData);
              rooms.push(roomData);
              total = parseFloat(roomData.total) + parseFloat(total);
            }
          });
        }
      });
    }

    this.setState({
      total: total,
      rooms: rooms,
      base_total: total,
      sub_total: total,
    });
  };

  updateBooking = (values) => {
    const {lookupResponse} = this.state;

    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      let room_type = null, room_type_package = null, baseUrl = 'update-room-type';
      this.state.rooms.forEach(data => {
        room_type = data.room_type.room_type;
        room_type_package = data.package.room_type_package;
      });

      let payload = {
        checkin: lookupResponse.checkin,
        checkout: lookupResponse.checkout,
        save_changes: true,
        room_type: room_type,
        room_type_package: room_type_package,
        update_type: this.state.updateType
      };

      if (this.state.updateType !== Constants.BOOKING_UPDATE_TYPE.STANDARD) {
        // Any update other than BOOKING_UPDATE_TYPE.STANDARD should go via esclated permission
        baseUrl = 'update-room-type-escalated';
      }

      if (this.state.updateType === Constants.BOOKING_UPDATE_TYPE.FLAT_RATE) {
        payload['update_price'] = values.update_price;
        payload['tax_classes'] = this.state.selectedTaxClasses;
        payload['update_description'] = values.update_description;
      }


      AppAPI.bookingapi
        .post(`booking-room/${lookupResponse.booking_room.hash}/${baseUrl}/`, payload)
        .then(() => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
              this.props.history.goBack();
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    });
  };

  selectTax = tax => {
    let {selectedTaxClasses} = this.state;
    selectedTaxClasses = AppUtil.insertOrRemoveArray(selectedTaxClasses, tax);
    this.setState({selectedTaxClasses});
  };

  render = () => {
    const {property, bookingroom} = this.props;
    const {loading, error, rooms, total, checkbox, resultMsg, updateType, selectedTaxClasses, lookupResponse} = this.state;
    if (loading) return <Loading/>;

    let validations = {};

    if (updateType === Constants.BOOKING_UPDATE_TYPE.FLAT_RATE) {
      validations = {
        update_price: Yup.number().positive().required(),
        update_description: Yup.string().max(200, 'Too Long!').required(),
      };
    }

    const formValidation = Yup.object().shape(validations);

    return (
      <div className="booking-view screen-container">
        <Helmet>
          <title>Booking Room Update</title>
        </Helmet>

        <PageHeader
          history={this.props.history}
          title={'Booking Room Update'}
          description={`Reference number : ${_.isEmpty(bookingroom) ? '...' : bookingroom.ref_no}`}
        />

        {!_.isEmpty(error) && <Error full={true} text={error}/>}

        <div className={'lookup-box-container mt-2'}>
          <div className={'row'}>
            <div className={'col-lg-6 col-md-12'}>
              {!_.isEmpty(lookupResponse) &&
                <LookupResults lookupResponse={lookupResponse} action={this.bookingRoomUpdate}/>
              }
            </div>
            <div className={'col-lg-6 col-md-12'}>
              {!_.isEmpty(lookupResponse) && lookupResponse.booking_room &&
                <div className={'mb-3'}>
                  <h6>Booking Room</h6>
                  <BookingRoomListView
                    data={lookupResponse.booking_room}
                    disabled={true}
                    key={'1'}
                  />
                </div>
              }
              {!_.isEmpty(rooms) &&
                <div>
                  <h6>Room Details</h6>
                  <table className={'table border'}>
                    <tbody>
                      {rooms.map((data, i) => (
                        <React.Fragment key={i}>
                          <tr key={i}>
                            <td className={'w-75'}>
                              <h6>Room : {data.room_number} [{data.room_type.name}]</h6>
                              <p className={'mb-0 small'}>Package : {data.package.data.name}</p>
                            </td>
                            <td className={'text-center'}>
                              <p><strong>{lookupResponse.currency} {data.total}</strong></p>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2} className={'border-0'}>
                              <div
                                className={'border py-2 text-center anchor'}
                                data-toggle="collapse" href={`#id${i}`}
                                role="button" aria-expanded="false"
                                aria-controls="orderActions">
                                <h6 className={'mb-0'}>
                                  <FontAwesomeIcon
                                    icon={faChevronCircleDown} size={'sm'}
                                    className={'mx-2 green-cl float-left mt-1'}/>
                                  More Information
                                  <FontAwesomeIcon
                                    icon={faChevronCircleDown} size={'sm'}
                                    className={'mx-2 green-cl float-right mt-1'}/>
                                </h6>
                              </div>
                              <div className={'p-2 border collapse'} id={`id${i}`}>
                                <div className={'row'}>
                                  <div className={'col-12 m-2'}>
                                    <h6>New Billing</h6>
                                    <p className={'small mb-0 text-secondary'}>
                                      Room Pricing for
                                      {AppUtil.formatDateTime(data.package.start_date, 'date')} - {AppUtil.formatDateTime(data.package.end_date, 'date')},
                                      {data.package.no_of_nights} night(s).
                                    </p>
                                    <table className={'table'}>
                                      <tbody>
                                        <tr>
                                          <td className={'w-75'}>Base Price
                                          for {data.package.no_of_nights} night(s)
                                          </td>
                                          <td className={'text-right'}>
                                            <strong>{lookupResponse.currency} {data.package.base_price_effective_total.toString()}</strong>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className={'w-75'}>Child Price
                                          for {data.package.no_of_nights} night(s)
                                          </td>
                                          <td className={'text-right'}>
                                            <strong>{lookupResponse.currency} {data.package.child_price_effective_total.toString()}</strong>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className={'w-75'}>Infant Price
                                          for {data.package.no_of_nights} night(s)
                                          </td>
                                          <td className={'text-right'}>
                                            <strong>{lookupResponse.currency} {data.package.infant_price_effective_total.toString()}</strong>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                      <tr className={''}>
                        <td><p className={'mb-0 border-0'}><strong>Total</strong></p></td>
                        <td><p className={'mb-0 text-right'}><strong>
                          {`${lookupResponse.currency} ${total.toFixed(2)}`}
                        </strong></p></td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          <p className={'text-secondary small mb-0'}>{Strings.bookingTotalWarningText}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <ul className="list-group mb-4">
                    <li className="list-group-item  align-items-center">
                      <small>Update Type</small>
                    </li>
                    <li className="list-group-item a-class"
                      onClick={() => this.setState({updateType: Constants.BOOKING_UPDATE_TYPE.STANDARD})}>
                      <p className={`${updateType !== Constants.BOOKING_UPDATE_TYPE.STANDARD && 'text-muted'} mb-0`}>
                        <FontAwesomeIcon
                          icon={updateType === Constants.BOOKING_UPDATE_TYPE.STANDARD ? faCheckCircle : faCircle}
                          className={`mr-2 ${updateType === Constants.BOOKING_UPDATE_TYPE.STANDARD ? 'green-cl' : 'grey-cl'}`}
                        />
                        <strong>Standard Update : </strong> {Strings.standardChange}</p>
                    </li>
                    <li className="list-group-item a-class"
                      onClick={() => this.setState({updateType: Constants.BOOKING_UPDATE_TYPE.COMPLIMENTARY})}>
                      <p className={`${updateType !== Constants.BOOKING_UPDATE_TYPE.COMPLIMENTARY && 'text-muted'} mb-0`}>
                        <FontAwesomeIcon
                          icon={updateType === Constants.BOOKING_UPDATE_TYPE.COMPLIMENTARY ? faCheckCircle : faCircle}
                          className={`mr-2 ${updateType === Constants.BOOKING_UPDATE_TYPE.COMPLIMENTARY ? 'green-cl' : 'grey-cl'}`}
                        />
                        <strong>Complimentary Update : </strong> {Strings.complimentaryChange}
                      </p>
                      <p className={'small text-muted mb-0'}>Requires Manager Permission.</p>
                    </li>
                    <li className="list-group-item a-class"
                      onClick={() => this.setState({updateType: Constants.BOOKING_UPDATE_TYPE.FLAT_RATE})}>
                      <p className={`${updateType !== Constants.BOOKING_UPDATE_TYPE.FLAT_RATE && 'text-muted'} mb-0`}>
                        <FontAwesomeIcon
                          icon={updateType === Constants.BOOKING_UPDATE_TYPE.FLAT_RATE ? faCheckCircle : faCircle}
                          className={`mr-2 ${updateType === Constants.BOOKING_UPDATE_TYPE.FLAT_RATE ? 'green-cl' : 'grey-cl'}`}
                        />
                        <strong>Flat Rate Update : </strong> {Strings.flatRateChange}
                      </p>
                      <p className={'small text-muted mb-0'}>Requires Manager Permission.</p>
                    </li>
                  </ul>

                  {!_.isEmpty(lookupResponse.messages) &&
                  <div className={'mb-3'}>
                    <h6>Attention</h6>
                    <p className={'small mb-0'}>{Strings.actionReviewDescriptionText}</p>
                    <ul className="list-group mt-3">
                      {
                        lookupResponse.messages.map((data, i) => (
                          <li className="list-group-item a-class" key={i}>
                            <div className={'custom-control custom-switch'}>
                              <input
                                id={`e${i}`}
                                type="checkbox"
                                className={'custom-control-input mr-3'}
                                checked={checkbox[i]}
                                onClick={() => {
                                  checkbox[i] = !checkbox[i];
                                  this.setState({checkbox});
                                }}
                              />
                              <label className="custom-control-label" htmlFor={`e${i}`}>
                                {data}
                              </label>
                            </div>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                  }
                  <Formik
                    onSubmit={(values) => this.updateBooking(values)}
                    validationSchema={formValidation}
                    initialValues={{
                      'update_price': '',
                      'update_description': ''
                    }}>
                    {(formikProps) => (
                      <Form>
                        {updateType === Constants.BOOKING_UPDATE_TYPE.FLAT_RATE &&
                          <React.Fragment>
                            <div className={'p-3 border rounded mb-3'}>
                              <div className="form-row">
                                <div className="col-6 form-group">
                                  <Input
                                    name='update_price'
                                    label={'Rate/Night (' + property.currency + ') *'}
                                    className={'form-control'}
                                  />
                                </div>
                                <div className="col-3 form-group text-left">
                                  <p className={''}><strong>x</strong> Length of Stay</p>
                                  <p className={'small'}>
                                    <strong>
                                      {AppUtil.diffDateTime(lookupResponse.checkin, lookupResponse.checkout)} Night(s)
                                    </strong>
                                  </p>
                                </div>
                                <div className="col-3 form-group text-right">
                                  <p className={''}>Total</p>
                                  <p className={'small'}>
                                    <strong>{`${property.currency} ${AppUtil.formatPrice(formikProps.values.update_price * parseInt(AppUtil.diffDateTime(lookupResponse.checkin, lookupResponse.checkout)))}`}</strong>
                                  </p>
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="col-lg-12 form-group">
                                  <Input
                                    name='update_description'
                                    label={'Description *'}
                                    className={'form-control'}
                                  />
                                </div>
                              </div>

                              {!_.isEmpty(lookupResponse.tax_class) &&
                              <div className={'row mb-2'}>
                                <div className={'col-12'}>
                                  <div
                                    className={'border p-2 text-center anchor'}
                                    onClick={() => this.setState({taxDetails: !this.state.taxDetails})}
                                  >
                                    <h6 className={'mb-0'}>
                                      <FontAwesomeIcon
                                        icon={faChevronCircleDown} size={'sm'}
                                        className={'mx-2 green-cl float-left mt-1'}/>
                                      Tax Information
                                      {selectedTaxClasses.length > 0 &&
                                      <span
                                        className={'ml-3 badge badge-success'}>{selectedTaxClasses.length} Tax applied</span>
                                      }
                                      <FontAwesomeIcon
                                        icon={faChevronCircleDown} size={'sm'}
                                        className={'mx-2 green-cl float-right mt-1'}/>
                                    </h6>
                                  </div>
                                  <Collapse in={this.state.taxDetails}>
                                    <div className={'py-2 border p-2'} id={'taxitems'}>
                                      <ul className="list-group mt-3 list-inline">
                                        {
                                          lookupResponse.tax_class.map((data, i) => (
                                            <li
                                              className="list-group-item list-inline-item a-class"
                                              key={i}
                                              onClick={() => this.selectTax(data.id)}
                                            >
                                              <input
                                                className={'mr-2'}
                                                type="checkbox"
                                                checked={selectedTaxClasses.includes(data.id)}/>
                                              {data.name} : {data.description}
                                            </li>
                                          ))
                                        }
                                      </ul>
                                    </div>
                                  </Collapse>
                                </div>
                              </div>
                              }
                              <p className={'text-secondary small mb-0'}>{Strings.bookingTotalWarningText}</p>
                            </div>
                          </React.Fragment>
                        }
                        <div>
                          <Alerts
                            status={resultMsg.status}
                            success={resultMsg.success}
                            error={resultMsg.error}
                          />

                          <button
                            className={'btn btn-block btn-lg btn-success'}
                            disabled={String(checkbox).includes('false')}
                            type="submit" onClick={formikProps.handleSubmit}
                          > Update Booking
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      updateSearch: true,
      showDatePicker: true,
      checkin: Moment().startOf('day'),
      checkout: Moment().add(1, 'days'),
      taxDetails: false,
      complimentaryUpgrade: false,
      updateType: Constants.BOOKING_UPDATE_TYPE.STANDARD,
      selectedTaxClasses: [],
      lookupResponse: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      results: [],
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingRoomUpdate);
