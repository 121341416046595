/**
 *
 * Event Booking Engine Settings
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Consts and Libs
import {Strings} from '../../../../constants';
import AppAPI from '../../../../lib/api';
// Components
import {Alerts} from '../../../../components/ui';
import Error from '../../../../components/general/Error';
import Loading from '../../../../components/general/Loading';
import {confirmAlert} from '../../../../components/general/ConfirmAlert';
import EventInventoryList from '../../../../components/functional/EventInventoryList';
// Actions
import * as EventActions from '../../../../redux/event/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  event: state.event.event,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setEvent: EventActions.setEvent,
};

/* Component ==================================================================== */
class EventBookingEngineOverview extends Component {
  static componentName = 'EventBookingEngineOverview';

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {property, event} = this.props;
    if (property.booking_engine_ok) {
      AppAPI.eventapi.get(`event/${event.id}/?tickets=True`)
        .then((res) => {
          this.setState({
            loading: false,
            error: null,
            events: [res],
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  };


  updateEvent = (eventId, payload) => {
    if (eventId && payload) {
      this.setState({resultMsg: {status: 'One moment...'}});
      AppAPI.eventapi.patch(`event-distribution/${eventId}/`,payload)
        .then((res) => {
          this.props.setEvent(res);
          this.setState({resultMsg: {success: 'Success'}});
          setTimeout(() => {
            this.setState({
              resultMsg: {success: ''},
            });
          }, 500);
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  render = () => {
    const {loading, error, resultMsg, events} = this.state;
    const {event} = this.props;
    if (!this.props.property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const {property} = this.props;

    return (
      <div className="channel-manager-settings screen-container">
        <Helmet>
          <title>Booking Engine Settings</title>
        </Helmet>
        <Alerts
          status={resultMsg.status}
          success={resultMsg.success}
          error={resultMsg.error}
        />
        <div className={'row'}>
          <div className="col-lg-12 col-md-12 col-sm-12 mb-5">
            <div className={'row'}>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <table className={'table border'}>
                  <thead>
                    <tr>
                      <td>Level</td>
                      <td>Connection</td>
                      <td>Distribution</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><strong>Property Level</strong></td>
                      <td>
                        <span className={'badge ' + (property.booking_engine_ok ? 'badge-success' : 'badge-secondary')}>
                          {property.booking_engine_ok ? 'Connected' : 'Not Connected'}
                        </span>
                      </td>
                      <td>
                        <span
                          className={'badge ' + (property.allow_booking_engine_distribution ? 'badge-success' : 'badge-secondary')}>
                          {property.allow_booking_engine_distribution ? 'Enabled' : 'Disabled'}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Event Booking </strong></td>
                      <td/>
                      <td>
                        <span
                          className={'badge ' + (property.allow_event_booking_engine ? 'badge-success' : 'badge-secondary')}>
                          {property.allow_event_booking_engine ? 'Enabled' : 'Disabled'}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Event Level</strong></td>
                      <td>
                        <span className={'badge ' + (event.booking_engine_ok ? 'badge-success' : 'badge-secondary')}>
                          {event.booking_engine_ok ? 'Connected' : 'Not Connected'}
                        </span>
                      </td>
                      <td>
                        <span
                          className={'badge ' + (event.allow_booking_engine_distribution ? 'badge-success' : 'badge-secondary')}>
                          {event.allow_booking_engine_distribution ? 'Enabled' : 'Disabled'}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {property.booking_engine_ok &&
               <div className="col-lg-6 col-md-6 col-sm-12">
                 <ul className="list-group">
                   <li className="list-group-item">
                     <button
                       onClick={() => {
                         confirmAlert({
                           title: (event.allow_booking_engine_distribution ? 'Disable' : ' Enabled') + ' Distribution',
                           message: event.allow_booking_engine_distribution ? Strings.bookingEngineSectionDistributionDisable : Strings.bookingEngineSectionDistributionEnable,
                           buttons: [
                             {
                               className: (event.allow_booking_engine_distribution ? 'btn-danger' : ' btn-success'),
                               label: 'Yes',
                               onClick: () => this.updateEvent(event.id, {allow_booking_engine_distribution: !event.allow_booking_engine_distribution}),
                             },
                             {
                               className: 'btn-secondary',
                               label: 'No',
                             },
                           ],
                         });
                       }}
                       className={'btn btn-block ' + (event.allow_booking_engine_distribution ? 'btn-danger' : ' btn-success')}>
                       {(event.allow_booking_engine_distribution ? 'Disable' : 'Enable') + ' Distribution'}
                     </button>
                   </li>
                   <li className="list-group-item">
                     {
                       Strings.bookingEngineSectionDistribution.map((data, i) =>
                         <p className={'sm mb-2 text-secondary'} key={i}>{data}</p>)
                     }
                   </li>
                 </ul>
               </div>
              }
            </div>
          </div>
        </div>
        {property.booking_engine_ok &&
         <div>
           <hr/>
           <h5>Event and Tickets</h5>
           <p>Event and tickets connected to booking engine.</p>
           <EventInventoryList refreshContent={this.fetchInitData} events={events} />
         </div>
        }
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      events: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventBookingEngineOverview);
