/**
 *
 * Property Payment Gateway Settings
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {Formik} from 'formik';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../../lib/api';
// Components
import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {faSave} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PageHeader from '../../../components/dashboard/PageHeader';
// Forms
import * as Yup from 'yup';
import {Form, Input, SubmitBtn} from 'react-formik-ui';
// Actions
import * as PropertyActions from '../../../redux/property/actions';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetPropertyDetails: PropertyActions.isSetPropertyDetails,
};

/* Component ==================================================================== */
class PropertySequenceSettings extends Component {
  static componentName = 'PropertySequenceSettings';

  componentDidMount = () => {
    const {property} = this.props;
    if (property) {
      const {sequence_settings} = property;
      const initialValues = {};
      if (!_.isEmpty(sequence_settings)) {
        Object.keys(sequence_settings).forEach((key) => {
          initialValues[`${key}_prefix_code`] = sequence_settings[key].prefix_code;
          initialValues[`${key}_prefix_date`] = sequence_settings[key].prefix_date;
          initialValues[`${key}_prefix_delimiter`] = sequence_settings[key].prefix_delimiter;
          initialValues[`${key}_prefix_zero_fill`] = sequence_settings[key].prefix_zero_fill;
        });
      }
      this.setState({
        loading: false,
        initialValues: initialValues,
      });
    }
  };

  saveSettings = (values) => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.propertydetails.patch(`${this.props.property.id}/`, {
        'sequence_settings': {
          'booking': {
            'prefix_code': values.booking_prefix_code,
            'prefix_date': values.booking_prefix_date,
            'prefix_delimiter': values.booking_prefix_delimiter,
            'prefix_zero_fill': values.booking_prefix_zero_fill,
          },
          'order': {
            'prefix_code': values.order_prefix_code,
            'prefix_date': values.order_prefix_date,
            'prefix_delimiter': values.order_prefix_delimiter,
            'prefix_zero_fill': values.order_prefix_zero_fill,
          },
          'payment': {
            'prefix_code': values.payment_prefix_code,
            'prefix_date': values.payment_prefix_date,
            'prefix_delimiter': values.payment_prefix_delimiter,
            'prefix_zero_fill': values.payment_prefix_zero_fill,
          },
          'refund': {
            'prefix_code': values.refund_prefix_code,
            'prefix_date': values.refund_prefix_date,
            'prefix_delimiter': values.refund_prefix_delimiter,
            'prefix_zero_fill': values.refund_prefix_zero_fill,
          },
          'expense': {
            'prefix_code': values.expense_prefix_code,
            'prefix_date': values.expense_prefix_date,
            'prefix_delimiter': values.expense_prefix_delimiter,
            'prefix_zero_fill': values.expense_prefix_zero_fill,
          },
          'ticket': {
            'prefix_code': values.ticket_prefix_code,
            'prefix_date': values.ticket_prefix_date,
            'prefix_delimiter': values.ticket_prefix_delimiter,
            'prefix_zero_fill': values.ticket_prefix_zero_fill,
          }
        }
      })
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                this.props.isSetPropertyDetails(res)
                  .then(() => {
                    this.props.history.goBack();
                  });
              }, 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    });
  };

  render = () => {
    const {loading, error, resultMsg, initialValues} = this.state;

    if (!this.props.property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const defaultFormValidation = Yup.object().shape({});

    return (
      <div className="sequence-settings screen-container">
        <Helmet>
          <title>Sequence Settings</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'Sequence Settings'}
          description={'Sequence number and sequence prefix for unique entries issued in the property'}
        />

        <Alerts
          status={resultMsg.status}
          success={resultMsg.success}
          error={resultMsg.error}
        />

        <Formik
          onSubmit={(values) => this.saveSettings(values)}
          validationSchema={defaultFormValidation}
          initialValues={initialValues}>
          {() => (
            <Form>
              <div className={'row'}>
                <div className={'col-lg-8 col-md-8 col-sm-12'}>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div
                        className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                        role="button" aria-expanded="false" aria-controls="orderActions">
                        <h6 className={'mb-0'}>
                          Booking Prefix
                        </h6>
                      </div>
                      <div className={'border p-2'} id={'primaryAction'}>
                        <div className="form-row">
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='booking_prefix_code'
                              label={'Prefix Code'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='booking_prefix_date'
                              label={'Date'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='booking_prefix_delimiter'
                              label={'Delimiter'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='booking_prefix_zero_fill'
                              label={'Zero Fills'}
                              className={'form-control'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div
                        className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                        role="button" aria-expanded="false" aria-controls="orderActions">
                        <h6 className={'mb-0'}>
                          Refund Prefix
                        </h6>
                      </div>
                      <div className={'border p-2'} id={'primaryAction'}>
                        <div className="form-row">
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='refund_prefix_code'
                              label={'Refund Prefix'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='refund_prefix_date'
                              label={'Date'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='refund_prefix_delimiter'
                              label={'Delimiter'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='refund_prefix_zero_fill'
                              label={'Zero Fills'}
                              className={'form-control'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div
                        className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                        role="button" aria-expanded="false" aria-controls="orderActions">
                        <h6 className={'mb-0'}>
                          Payment Prefix
                        </h6>
                      </div>
                      <div className={'border p-2'} id={'primaryAction'}>
                        <div className="form-row">
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='payment_prefix_code'
                              label={'Payment Prefix '}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='payment_prefix_date'
                              label={'Date'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='payment_prefix_delimiter'
                              label={'Delimiter'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='payment_prefix_zero_fill'
                              label={'Zero Fills'}
                              className={'form-control'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div
                        className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                        role="button" aria-expanded="false" aria-controls="orderActions">
                        <h6 className={'mb-0'}>
                          Order Prefix
                        </h6>
                      </div>
                      <div className={'border p-2'} id={'primaryAction'}>
                        <div className="form-row">
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='order_prefix_code'
                              label={'Prefix Code'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='order_prefix_date'
                              label={'Date'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='order_prefix_delimiter'
                              label={'Delimiter'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='order_prefix_zero_fill'
                              label={'Zero Fills'}
                              className={'form-control'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div
                        className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                        role="button" aria-expanded="false" aria-controls="orderActions">
                        <h6 className={'mb-0'}>
                          Expense Prefix
                        </h6>
                      </div>
                      <div className={'border p-2'} id={'primaryAction'}>
                        <div className="form-row">
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='expense_prefix_code'
                              label={'Prefix Code'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='expense_prefix_date'
                              label={'Date'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='expense_prefix_delimiter'
                              label={'Delimiter'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='expense_prefix_zero_fill'
                              label={'Zero Fills'}
                              className={'form-control'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div
                        className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                        role="button" aria-expanded="false" aria-controls="orderActions">
                        <h6 className={'mb-0'}>
                          Ticket Prefix
                        </h6>
                      </div>
                      <div className={'border p-2'} id={'primaryAction'}>
                        <div className="form-row">
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='ticket_prefix_code'
                              label={'Prefix Code'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='ticket_prefix_date'
                              label={'Date'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='ticket_prefix_delimiter'
                              label={'Delimiter'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-3 col-sm-3 form-group">
                            <Input
                              name='ticket_prefix_zero_fill'
                              label={'Zero Fills'}
                              className={'form-control'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                      <Alerts
                        status={resultMsg.status}
                        success={resultMsg.success}
                        error={resultMsg.error}
                      />
                      <SubmitBtn className={'btn btn-success btn-block btn-lg mt-2'}>
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                      </SubmitBtn>
                    </div>
                  </div>
                </div>
                <div className={'col-lg-4 col-md-4 col-sm-12'}>
                  <ul className="list-unstyled">
                    <li>Directive</li>
                    <li><p>%a - abbreviated weekday name</p></li>
                    <li><p>%A - full weekday name</p></li>
                    <li><p>%b - abbreviated month name</p></li>
                    <li><p>%B - full month name</p></li>
                    <li><p>%c - preferred date and time representation</p></li>
                    <li><p>%C - century number (the year divided by 100, range 00 to 99)</p></li>
                    <li><p>%d - day of the month (01 to 31)</p></li>
                    <li><p>%D - same as %m/%d/%y</p></li>
                    <li><p>%e - day of the month (1 to 31)</p></li>
                    <li><p>%g - like %G, but without the century</p></li>
                    <li><p>%G - 4-digit year corresponding to the ISO week number (see %V).</p></li>
                    <li><p>%h - same as %b</p></li>
                    <li><p>%H - hour, using a 24-hour clock (00 to 23)</p></li>
                    <li><p>%I - hour, using a 12-hour clock (01 to 12)</p></li>
                    <li><p>%j - day of the year (001 to 366)</p></li>
                    <li><p>%m - month (01 to 12)</p></li>
                    <li><p>%M - minute</p></li>
                    <li><p>%n - newline character</p></li>
                    <li><p>%p - either am or pm according to the given time value</p></li>
                    <li><p>%r - time in a.m. and p.m. notation</p></li>
                    <li><p>%R - time in 24 hour notation</p></li>
                    <li><p>%S - second</p></li>
                    <li><p>%t - tab character</p></li>
                    <li><p>%T - current time, equal to %H:%M:%S</p></li>
                    <li><p>%u - weekday as a number (1 to 7), Monday=1. Warning: In Sun Solaris Sunday=1</p></li>
                    <li><p>%U - week number of the current year, starting with the first Sunday as the first day of the
                      first week</p></li>
                    <li><p>%V - The ISO 8601 week number of the current year (01 to 53), where week 1 is the first week
                      that has at least 4 days in the current year, and with Monday as the first day of the week</p>
                    </li>
                    <li><p>%W - week number of the current year, starting with the first Monday as the first day of the
                      first week</p></li>
                    <li><p>%w - day of the week as a decimal, Sunday=0</p></li>
                    <li><p>%x - preferred date representation without the time</p></li>
                    <li><p>%X - preferred time representation without the date</p></li>
                    <li><p>%y - year without a century (range 00 to 99)</p></li>
                    <li><p>%Y - year including the century</p></li>
                    <li><p>%Z or %z - time zone or name or abbreviation</p></li>
                    <li><p>%% - a literal % character</p></li>
                  </ul>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      sequenceData: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertySequenceSettings);
