/**
 *
 * Room Type Details
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

// Consts and Libs
import AppAPI from '../../lib/api';
import {Constants, Strings} from '../../constants';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import PageHeader from '../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDoorOpen, faLink, faUnlink} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class OverView extends Component {
  static componentName = 'RoomTypeOverView';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };
  componentDidMount = () => {
    AppAPI.roomapi.get('room-type-overview/')
      .then((res) => {
        this.setState({
          loading: false,
          error: null,
          roomTypes: res.room_types,
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };

  render = () => {
    const {property} = this.props;
    const {loading, error, roomTypes} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    return (
      <div className="room-type-details screen-container ">
        <Helmet>
          <title>Inventory Overview</title>
        </Helmet>


        <PageHeader
          subHeader={true} history={this.props.history} title={'Inventory Overview'}
          description={`Overview of all inventory and their status at ${property.name} `}
        />

        {roomTypes && roomTypes.length > 0 ?
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="table-responsive">
                <table className="table table-sm table-bordered">
                  <thead className={' bg-dark text-light text-center'}>
                    <tr>
                      <th colSpan={2}>Type & Package</th>
                      <th colSpan={5}>Guests Occupancy / Rate</th>
                      <th colSpan={3}>Connection</th>
                    </tr>
                    <tr>
                      <td>Room Type</td>
                      <td>Package</td>
                      <td>Base</td>
                      <td>Min</td>
                      <td>Max</td>
                      <td>Child</td>
                      <td>Infant</td>
                      <td>Code</td>
                      <td>CM<sup>*</sup></td>
                      <td>BE<sup>*</sup></td>
                    </tr>
                  </thead>
                  <tbody className={'text-center'}>
                    {roomTypes.map((room_type, i) => (
                      <React.Fragment key={i}>
                        <tr className={room_type.active ? 'table-info' : 'table-secondary'}>
                          <td className={'align-middle text-left'} rowSpan={room_type.room_type_packages.length + 1}>
                            <Link to={`/settings/inventory/room-type/${room_type.id}/`}>
                              <FontAwesomeIcon icon={faLink} size={'sm'} className={'mr-2'}/>
                            </Link>{room_type.name}
                          </td>
                          <td className={'text-left'}>
                            {(room_type.no_of_rooms > 0) &&
                             <React.Fragment>
                               <Link to={`/settings/inventory/room-type/${room_type.id}/room/`}>
                                 <FontAwesomeIcon icon={faLink} size={'sm'} className={'mr-2'}/>
                               </Link>
                               <FontAwesomeIcon icon={faDoorOpen} size={'sm'} className={'mr-2'}/> Rooms
                               : {room_type.no_of_rooms}
                             </React.Fragment>
                            }
                          </td>
                          <td>{room_type.base_occupancy}</td>
                          <td>{room_type.min_occupancy}</td>
                          <td>{room_type.max_occupancy}</td>
                          <td>{room_type.child_occupancy}</td>
                          <td>{room_type.infant_occupancy}</td>
                          <td>{room_type.channel_manager_room_type_id}</td>
                          <td>
                            <FontAwesomeIcon
                              icon={room_type.channel_manager_ok ? faLink : faUnlink}
                              className={room_type.channel_manager_ok ? 'green-cl' : 'red-cl'}
                            />
                          </td>
                          <td>
                            <FontAwesomeIcon
                              icon={room_type.booking_engine_ok ? faLink : faUnlink}
                              className={room_type.booking_engine_ok ? 'green-cl' : 'red-cl'}
                            />
                          </td>
                        </tr>
                        {room_type.room_type_packages &&
                         <React.Fragment>
                           {room_type.room_type_packages.map((room_type_package, j) => (
                             <tr
                               className={(room_type.active && room_type_package.active) ? ' ' : 'table-secondary'}
                               key={j}
                             >
                               <td className={'text-left'}>
                                 <Link
                                   to={`/settings/inventory/room-type/${room_type.id}/package/${room_type_package.id}/`}>
                                   <FontAwesomeIcon icon={faLink} size={'sm'} className={'mr-2'}/>
                                 </Link>
                                 {room_type_package.name}
                               </td>
                               <td>{property.currency} {room_type_package.base_price_effective}</td>
                               <td>{room_type.discount_per_guest} {room_type.discount_type === Constants.TAX_TYPE.FIXED ? property.currency : '%'}</td>
                               <td>{property.currency} {room_type_package.guest_price_effective}</td>
                               <td>{property.currency} {room_type_package.child_price_effective}</td>
                               <td>{property.currency} {room_type_package.infant_price_effective}</td>
                               <td>{room_type_package.channel_manager_package_id}</td>
                               <td>
                                 <FontAwesomeIcon
                                   icon={room_type_package.channel_manager_ok ? faLink : faUnlink}
                                   className={room_type_package.channel_manager_ok ? 'green-cl' : 'red-cl'}
                                 />
                               </td>
                               <td>
                                 <FontAwesomeIcon
                                   icon={room_type_package.booking_engine_ok ? faLink : faUnlink}
                                   className={room_type_package.booking_engine_ok ? 'green-cl' : 'red-cl'}
                                 />
                               </td>
                             </tr>
                           ))}
                         </React.Fragment>
                        }

                      </React.Fragment>
                    ))}
                    <tr>
                      <td colSpan={10}>
                        <small>{Strings.roomTypePriceListAbbreviation}</small>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <ul className={'list-inline'}>
                <li className={'list-inline-item'}>CM : Channel Manager connection status.</li>
                <li className={'list-inline-item'}>BE : Booking Engine connection status.</li>
              </ul>
            </div>
          </div>
          :
          <div className="row">
            <div className="col-lg-7 col-md-12 col-sm-12">
              <div className="empty text-center">
                <div className="mx-auto featured-image">
                  <img className={'w-100'} alt={'Welcome'} src={require('../../images/drawings/Initial-Setup.png')}/>
                </div>
                <h3>{Strings.onBoardingTitle}</h3>
              </div>
              {
                Strings.roomTypeListEmptyText.map((data, i) => <p key={i}>{data}</p>)
              }
              <div className="spacer-20"/>
              <div className="text-center">
                <Link to={'/settings/inventory/room-type'} className="btn btn-success"><span className="fa fa-gear"/>
                  Setup Room Type</Link>
              </div>
            </div>
          </div>
        }
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      roomTypes: [],
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverView);