/**
 *
 * Order Details
 */

import _ from 'lodash';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import React, {Component} from 'react';
import {Collapse} from 'react-bootstrap';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import {Colors, Constants, Strings} from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import NotesListView from '../../notes/NotesListView';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faClipboardList,
  faExclamationTriangle,
  faFilePdf,
  faLockOpen,
  faLongArrowAltRight,
  faMoneyBillWave,
  faPaperPlane,
  faPen, faPercentage,
  faPlus,
  faPlusCircle,
  faStickyNote,
} from '@fortawesome/free-solid-svg-icons';
import {Alerts, History} from '../../../components/ui';
import ModelPDFView from '../../../components/general/ModelPDFView';
import {confirmAlert} from '../../../components/general/ConfirmAlert';

import OrderEdit from '../models/OrderEdit';
import NotesManager from '../../notes/NotesManager';
import OrderItemListView from '../OrderItemListView';
import GuestListView from '../../guest/GuestListView';
import OrderLockedEdit from '../models/OrderLockedEdit';
import PaymentAdd from '../../payment/models/PaymentAdd';
import OrderItemDetailsView from '../OrderItemDetailsView';
import GuestQuickManage from '../../guest/GuestManageQuick';
import OrderItemsBulkEdit from '../models/OrderItemsBulkEdit';
import MessagingManager from '../../messaging/MessagingManager';
import ProfileQuickSearch from '../../guest/ProfileQuickSearch';
import OrderPostPaymentEdit from '../models/OrderPostPaymentEdit';
import GuestDetailsOverView from '../../guest/GuestDetailsOverView';
import OrderEscalatedPermissionEdit from '../OrderEscalatedPermissionEdit';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';
// Actions
import * as OrderActions from '../../../redux/order/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  order: state.order.order,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setOrder: OrderActions.setOrder,
  setOrderItem: OrderActions.setOrderItem,
};


/* Component ==================================================================== */
class OrderDetails extends Component {
  static componentName = 'OrderDetails';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  cacheReset = () => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.order
        .post(`${this.props.order.hash}/cache-reset/`, {})
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            this.props.setOrder(res);
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            resultMsg: {error},
          });
        });
    });
  };

  escalatedPermissionUpdated = (payload) => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.order
          .post(`${this.props.order.hash}/escalated-permission/`, payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              this.props.setOrder(res);
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  updateOrder = (payload) => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.order
          .patch(`${this.props.order.hash}/`, payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              this.props.setOrder(res);
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  additionalUpdateOrder = (payload) => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.order
          .post(`${this.props.order.hash}/additional-details/`, payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({
                  showProfileDetails: false,
                  showGuestManagement: false,
                  showProfileSearch: false,
                  resultMsg: {success: ''},
                });
                this.props.setOrder(res);
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  updateLockedOrder = (payload) => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.order
          .post(`${this.props.order.hash}/locked-order/`, payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({
                  resultMsg: {success: ''},
                });
                this.props.setOrder(res);
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    if (this.props.firstLoad) {
      this.setState({loading: false});
    } else {
      this.setState({
        loading: true,
      }, () => {
        this.props.setOrder(this.props.match.params.orderId)
          .then(() => this.setState({loading: false}))
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({loading: false, error,});
          });
      });
    }
  };

  showProfileDetails = (guestId, add_guest = false) => {
    this.setState({profileDetailsData: guestId, showProfileDetails: true, addProfile: add_guest});
  };

  profileActions = (profile) => {
    if (profile) {
      if (profile.profile_type === Constants.PROFILE_TYPE.GUEST) {
        this.additionalUpdateOrder({guest_data: this.state.addProfile ? profile.id : null});
      } else {
        this.additionalUpdateOrder({corporate_data: this.state.addProfile ? profile.id : null});
      }
    }
  };

  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {order, property} = this.props;
    const {config} = property;

    if (loading || !order) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const data = [];

    if (parseFloat(order.total_unpaid_payment) > 0) {
      data.push({
        name: 'Pending', value: parseFloat(order.total_unpaid_payment), color: Colors.red
      });
    }

    if (parseFloat(order.total_paid) > 0) {
      data.push({
        name: 'Paid', value: parseFloat(order.total_paid), color: Colors.green
      });
    }

    if (parseFloat(order.settlement_difference) > 0) {
      data.push({
        name: 'Fee', value: parseFloat(order.settlement_difference), color: Colors.orange
      });
    }

    return (
      <div className="order-details screen-container ">
        <Helmet>
          <title>Order Details : {order.ref_no}</title>
        </Helmet>

        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
            <div className={'row'}>
              <div className={'col'}>
                <p className={'mb-0 text-muted'}>Total</p>
                <div className={'d-inline-flex align-items-baseline'}>
                  <h2>
                    {order.currency + ' ' + order.total}
                  </h2>
                  <div className={'align-items-baseline'}>
                    <span className={`ml-2 badge ${AppUtil.paymentStatusColor(order.order_status)}`}>
                      {order.order_status_display}
                    </span>
                    {!order.lock && <span className={'ml-2 badge badge-warning'}>Open Order</span>}
                  </div>
                </div>
              </div>
            </div>
            <hr/>
            <div className={'row'}>
              <div className={'col-lg-6 col-md-12'}>
                <table className={'table table-borderless table-sm mb-3'}>
                  <tbody>
                    {order.order_id &&
                    <tr>
                      <td className={'w-50 text-muted'}>Order ID</td>
                      <td><strong>{order.order_id}</strong></td>
                    </tr>
                    }
                    {(!order.order_id && !order.order_auto_generate_sequence_id) &&
                    <tr>
                      <td className={'w-50 text-muted'}>Order ID</td>
                      <td>
                        <button
                          disabled={order.void}
                          onClick={() => {
                            confirmAlert({
                              title: 'Generate Sequence ID',
                              message: Strings.orderGenerateSequenceId,
                              buttons: [
                                {
                                  className: 'btn-warning',
                                  label: 'Generate',
                                  onClick: () => this.updateLockedOrder({
                                    'order_auto_generate_sequence_id': true,
                                  }),
                                },
                                {
                                  className: 'btn-secondary',
                                  label: 'Exit',
                                },
                              ],
                            });
                          }}
                          className={'btn btn-link btn-sm m-0 p-0'}>
                          Click to generate.
                        </button>
                        <p className={'small text-muted mb-0'}>Sequence disabled for order.</p>
                      </td>
                    </tr>
                    }
                    <tr>
                      <td className={'w-50 text-muted'}>Order Source</td>
                      <td>{order.order_source_display}</td>
                    </tr>
                    {(order.order_type !== 1) &&
                      <tr>
                        <td className={'w-50 text-muted'}>Order Type</td>
                        <td>{order.order_type_display}</td>
                      </tr>
                    }
                    {(order.order_source === Constants.ORDER_SOURCE.EXTERNAL) && (
                      <tr>
                        <td className={'w-50 text-muted'}>Source</td>
                        <td>{order.order_source_provider_display}</td>
                      </tr>
                    )}

                    {(order.attributes) &&
                    <React.Fragment>

                      {(order.attributes.pos_hash) &&
                      <tr>
                        <td className={'w-50 text-muted'}>Point of Sale</td>
                        <td>
                          <Link to={`/point-of-sale/${order.attributes.pos_hash}`}>
                            {order.attributes.pos_name || 'Point of Sale'}
                          </Link>
                        </td>
                      </tr>
                      }

                      {(order.attributes.pos_name && order.attributes.pos_order_hash) &&
                      <tr>
                        <td className={'w-50 text-muted'}>Order / Tab</td>
                        <td>
                          <Link
                            to={`/point-of-sale/${order.attributes.pos_hash}/pos-order/${order.attributes.pos_order_hash}/`}>
                            {order.attributes.pos_order_ref_no}
                          </Link>
                        </td>
                      </tr>
                      }

                      {(order.ticket_booking_hash && order.attributes.event_hash) &&
                      <tr>
                        <td className={'w-50 text-muted'}>Ticket</td>
                        <td>
                          <Link
                            to={`/event/${order.attributes.event_hash}/ticket-booking/booking/${order.attributes.ticket_booking_hash}`}>
                            {order.attributes.ticket_booking_ref_no || 'Ticket Booking'}
                          </Link>
                        </td>
                      </tr>
                      }
                      {(order.attributes.event_name) &&
                      <tr>
                        <td className={'w-50 text-muted'}>Event</td>
                        <td>
                          <Link to={`/event/${order.attributes.event_hash}/`}>
                            {order.attributes.event_name || 'Event'}
                          </Link>
                        </td>
                      </tr>
                      }

                      {order.attributes.booking_hash &&
                      <tr>
                        <td className={'w-50 text-muted'}>Booking</td>
                        <td>
                          <Link to={`/booking/${order.attributes.booking_hash}/`}>
                            {order.attributes.booking_ref_no || 'Booking'}
                          </Link>
                        </td>
                      </tr>
                      }

                      {order.attributes.ticket_booking_hash &&
                      <tr>
                        <td className={'w-50 text-muted'}>Ticket Booking</td>
                        <td>
                          <Link to={`/event/${order.attributes.event_hash}/ticket-booking/booking/${order.attributes.ticket_booking_hash}/`}>
                            {order.attributes.ticket_booking_ref_no || 'Ticket Booking'}
                          </Link>
                        </td>
                      </tr>
                      }

                      {(order.attributes.booking_amendment_request_hash && order.attributes.booking_hash) &&
                      <tr>
                        <td className={'w-50 text-muted'}>Amendment Request</td>
                        <td>
                          <Link
                            to={`/booking/${order.attributes.booking_hash}/amendment-request/${order.attributes.booking_amendment_request_hash}/`}>
                            {order.attributes.booking_amendment_request_ref_no || 'N.A'}
                          </Link>
                        </td>
                      </tr>
                      }

                      {(!_.isEmpty(order.booking_rooms_attributes)) &&
                      <tr>
                        <td className={'w-50 text-muted align-middle'}>Booking Rooms</td>
                        <td>
                          <ul className="list-inline list-item-mb-1 mb-0">
                            {order.booking_rooms_attributes.map((data, i) => (
                              <li className="list-inline-item" key={i}>
                                <Link to={`/booking/${order.attributes.booking_hash}/booking-room/${data.hash}`}>
                                  Room {data.room_number}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </td>
                      </tr>
                      }
                    </React.Fragment>
                    }
                  </tbody>
                </table>
              </div>
              <div className={'col-lg-6 col-md-12'}>
                <table className={'table table-borderless table-sm mb-3'}>
                  <tbody>
                    <tr>
                      <td className={'w-50 text-muted'}>Tax
                        Number {property && property.tax_number_type && `(${property.tax_number_type})`}</td>
                      <td>{order.tax_number || '...'}</td>
                    </tr>
                    <tr>
                      <td className={'w-50 text-muted'}>Tax Company</td>
                      <td>{order.tax_company || '...'}</td>
                    </tr>
                    {parseFloat(order.total_unpaid_payment) > 0 && (
                      <tr>
                        <td className={'w-50 text-muted'}>Pending Payment</td>
                        <td><strong>{order.currency} {order.total_unpaid_payment}</strong></td>
                      </tr>
                    )}

                    {parseFloat(order.total_refunded) > 0 && (
                      <React.Fragment>
                        <tr>
                          <td className={'w-50 text-muted'}>Refunded Payment</td>
                          <td><strong>{order.currency} {order.total_refunded}</strong></td>
                        </tr>
                        <tr>
                          <td className={'w-50 text-muted'}>Net Payment</td>
                          <td><strong>{order.currency} {order.total_available_fund}</strong></td>
                        </tr>
                      </React.Fragment>
                    )}

                    {!_.isEmpty(order.attributes) &&
                    <React.Fragment>
                      {order.attributes.created_user &&
                      <tr>
                        <td className={'w-50 text-muted'}>Created By</td>
                        <td>{order.attributes.created_user || 'N.A'}</td>
                      </tr>
                      }

                      {order.attributes.booking_ota &&
                      <tr>
                        <td className={'w-50 text-muted'}>OTA</td>
                        <td>
                          {order.attributes.booking_ota}
                          <span className={'small'}>({order.attributes.booking_ota_code})</span>
                        </td>
                      </tr>
                      }

                    </React.Fragment>
                    }
                    {order.payment_time && (
                      <tr>
                        <td className={'w-50 text-muted'}>Payment Time</td>
                        <td>{AppUtil.formatDateTime(order.payment_time)}</td>
                      </tr>
                    )}

                    {order.refund_time && (
                      <tr>
                        <td className={'w-50 text-muted'}>Refund Time</td>
                        <td>{AppUtil.formatDateTime(order.refund_time)}</td>
                      </tr>
                    )}

                  </tbody>
                </table>
              </div>
            </div>

            <div className={'row'}>
              {!_.isEmpty(order.guest) &&
              <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                <p className={'mb-1 text-muted'}>Guest</p>
                <GuestListView
                  data={order.guest}
                  action={() => order.guest.id ? this.showProfileDetails(order.guest.id) : null}
                />
              </div>
              }

              {!_.isEmpty(order.corporate) &&
              <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                <p className={'mb-1 text-muted'}>Corporate / Agent</p>
                <GuestListView
                  data={order.corporate}
                  action={() => order.corporate.id ? this.showProfileDetails(order.corporate.id) : null}
                />
              </div>
              }
            </div>

            <div className={'spacer-20'}/>

            <table className="table border">
              <tbody>
                {!_.isEmpty(order.order_items) ?
                  <React.Fragment>
                    {order.order_items.map((data, i) => {
                      if (data.status) {
                        return (
                          <OrderItemListView
                            key={i}
                            currency={order.currency}
                            lock={order.lock}
                            data={data}
                            onClick={(data) => this.setState({showOrderItem: true, orderItem: data})}
                          />
                        );
                      }
                      return null;
                    })
                    }
                  </React.Fragment>
                  :
                  <tr>
                    <td colSpan={3}>
                      <div className="text-center">
                        <FontAwesomeIcon icon={faExclamationTriangle} size={'2x'} className={'mt-3 red-cl'}/>
                        <p className={'text-secondary small'}>No items in this order</p>
                      </div>
                    </td>
                  </tr>
                }
                {!order.lock &&
                <tr>
                  <td colSpan={3} className={'text-center'}>
                    <button
                      type="button" className="btn btn-link btn-sm mx-2" data-toggle="modal"
                      onClick={() => this.setState({
                        showOrderItem: true,
                        orderItem: null,
                      })}><FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> New item
                    </button>
                    <button
                      type="button" className="btn btn-link btn-sm mx-2" data-toggle="modal"
                      onClick={()=>this.setState({showOrderItemsBulkEdit: true})}
                    ><FontAwesomeIcon icon={faPen} size={'sm'}  className={'mr-2'}/> Edit item(s)
                    </button>

                    <button
                      type="button" className="btn btn-link btn-sm mx-2" data-toggle="modal"
                      onClick={() => this.setState({
                        showOrderEdit: true,
                        showOrderEditType: 'bulkDiscount',
                      })}
                    ><FontAwesomeIcon icon={faPercentage} size={'sm'}  className={'mr-2'}/> Discount item(s)
                    </button>

                  </td>
                </tr>
                }
              </tbody>
            </table>

            <table className="table border mb-5">
              <tbody>
                <tr>
                  <td className="text-left">
                    Sub Total
                    {!order.lock &&
                    <button
                      type="button" className="btn btn-link p-0 mx-1 btn-sm"
                      onClick={() => this.setState({
                        showOrderEdit: true,
                        showOrderEditType: 'totalOverRide',
                      })}
                    >
                      (Edit)
                    </button>
                    }
                  </td>
                  <td className="text-right">{order.currency} {order.sub_total}</td>
                </tr>
                {parseFloat(order.complimentary_total) > 0 &&
                <tr>
                  <td className="text-left">Complimentary</td>
                  <td className="text-right">{order.currency} {order.complimentary_total}</td>
                </tr>
                }
                {parseFloat(order.no_charge_total) > 0 &&
                <tr>
                  <td className="text-left">No Charge</td>
                  <td className="text-right">{order.currency} {order.no_charge_total}</td>
                </tr>
                }
                {parseFloat(order.discount_total) > 0 &&
                <tr>
                  <td className="text-left">
                    Discount
                    <button
                      className={'btn btn-sm btn-link p-0 m-0 ml-2'}
                      onClick={() => this.setState({discountDetails: !this.state.discountDetails})}
                    >
                      Discount Information
                    </button>
                    <Collapse in={this.state.discountDetails}>
                      <table className={'table table-sm small text-secondary border mt-2'}>
                        <tbody>
                          <tr>
                            <td className={'small'}>Discount Pre Tax</td>
                            <td className={'text-right small'}>{order.currency} {order.discount_total_pre_tax}</td>
                          </tr>
                          <tr>
                            <td className={'small'}>Discount Post Tax</td>
                            <td className={'text-right small'}>{order.currency} {order.discount_total_post_tax}</td>
                          </tr>
                        </tbody>
                      </table>
                    </Collapse>
                  </td>
                  <td className="text-right">- {order.currency} {order.discount_total}</td>
                </tr>
                }
                <tr>
                  <td className="text-left">
                    Tax
                    {order.tax_expanded && order.tax_expanded.tax_data && order.tax_expanded.tax_data.length > 0 &&
                    <React.Fragment>
                      <button
                        className={'btn btn-sm btn-link p-0 m-0 ml-2'}
                        onClick={() => this.setState({taxDetails: !this.state.taxDetails})}
                      >
                        Tax Information
                      </button>
                      <Collapse in={this.state.taxDetails}>
                        <table className={'table table-sm small text-secondary format-table border mt-2'}>
                          <thead>
                            <tr>
                              <th className={'small text-dark w-50'}>Tax Name</th>
                              <th className={'small text-dark'}>Pre Tax</th>
                              <th className={'text-dark small'}>Tax Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {order.tax_expanded.tax_data.map((data, i) => (
                              <tr key={i}>
                                <td className={'small'}>{data.name}</td>
                                <td className={'small'}>
                                  {data.pre_total ? `${order.currency} ${data.pre_total}` : '...'}
                                </td>
                                <td className={'small'}>{`${order.currency} ${data.value}`}</td>
                              </tr>
                            ))}
                            <tr>
                              <td/>
                              <td/>
                              <td className={'small'}>{`${order.currency} ${order.tax_total}`}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Collapse>
                    </React.Fragment>
                    }
                  </td>
                  <td className="text-right">{order.currency} {order.tax_total}</td>
                </tr>
                {(parseFloat(order.order_tax) > 0 || order.order_source === Constants.ORDER_SOURCE.EXTERNAL) &&
                <tr>
                  <td className="text-left">
                    Tax
                    {!order.lock &&
                    <button
                      type="button" className="btn btn-link mx-1 btn-sm" data-toggle="modal"
                      onClick={() => this.setState({
                        showOrderEdit: true,
                        showOrderEditType: 'orderTax',
                      })}
                    >(Edit)</button>
                    }
                    {order.order_tax_description &&
                    <p className={'text-secondary small mb-0'}>{order.order_tax_description}</p>
                    }
                  </td>
                  <td className="text-right">{order.currency} {order.order_tax}</td>
                </tr>
                }
                <tr>
                  <td className="text-left">
                    Discount
                    {!order.lock &&
                    <button
                      type="button" className="btn btn-link mx-1 btn-sm" data-toggle="modal"
                      onClick={() => this.setState({
                        showOrderEdit: true,
                        showOrderEditType: 'discount',
                      })}
                    >(Edit)</button>
                    }
                    {parseFloat(order.order_discount) !== 0 &&
                      <p className={'mb-0 small text-muted'}>
                        ({order.order_discount_type_display}) {order.order_discount_description}
                      </p>
                    }
                  </td>
                  <td className="text-right">{order.currency} {order.order_discount}</td>
                </tr>
                {parseFloat(order.order_discount_post_payment) > 0 &&
                  <tr>
                    <td>
                      <strong>Discount ( Post Payment )</strong>
                      {order.order_discount_post_payment_description &&
                        <p className={'mb-0 small text-muted'}>
                          ({order.order_discount_post_payment_type_display}) {order.order_discount_post_payment_description}
                        </p>
                      }
                    </td>
                    <td className="text-right">{`${order.currency} ${order.order_discount_post_payment}`}</td>
                  </tr>
                }
                {parseFloat(order.service_charge) !== 0 &&
                <tr>
                  <td className="text-left">Service Charge</td>
                  <td className="text-right">{order.currency} {order.service_charge}</td>
                </tr>
                }
                {parseFloat(order.total_rounding) !== 0 &&
                <tr>
                  <td className="text-left">
                    Rounding
                  </td>
                  <td className="text-right">{order.currency} {order.total_rounding}</td>
                </tr>
                }
                <tr>
                  <td className="text-left">
                    <strong>Total</strong>
                  </td>
                  <td className="text-right">
                    <strong>{order.currency} {order.total}</strong></td>
                </tr>
              </tbody>
            </table>

            {(order.order_source === Constants.ORDER_SOURCE.EXTERNAL) &&
            <table className="table border mb-5 grey-light-bg">
              <tbody>
                {(parseFloat(order.order_external_tax) > 0) && (
                  <tr>
                    <td className="text-left">
                      Tax (External)
                      <p className={'text-secondary small mb-0'}>{order.order_external_tax_description}</p>
                    </td>
                    <td className="text-right">
                      {order.currency} {order.order_external_tax}
                    </td>
                  </tr>
                )}
                {(parseFloat(order.order_external_total) > 0) && (
                  <tr>
                    <td className="text-left">
                      <strong>Total (External)</strong>
                      <p className={'text-secondary small mb-0'}>Total received from external source</p>
                    </td>
                    <td className="text-right">
                      <strong>{order.currency} {order.order_external_total}</strong></td>
                  </tr>
                )}
                {(parseFloat(order.order_external_commission) > 0) && (
                  <tr>
                    <td className="text-left">
                      Commission
                      {order.order_external_commission_description &&
                        <p className={'text-secondary small mb-0'}>{order.order_external_commission_description}</p>
                      }
                    </td>
                    <td className="text-right">
                      {order.currency} {order.order_external_commission}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            }
            {!order.lock &&
            <div>
              <Alerts
                status={resultMsg.status}
                success={resultMsg.success}
                error={resultMsg.error}
              />

              <div className={'row'}>
                <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                  <button
                    className={'btn btn-lg btn-block btn-secondary'}
                    onClick={() => {
                      confirmAlert({
                        title: 'Cancel Order',
                        message: Strings.orderCancellation,
                        buttons: [
                          {
                            className: 'btn-danger',
                            label: 'Cancel Order',
                            onClick: () => this.updateOrder({lock_order: true, void_order: true}),
                          },
                          {
                            className: 'btn-secondary',
                            label: 'Close',
                          },
                        ],
                      });
                    }}
                  >
                    Cancel Order
                  </button>
                </div>
                <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                  <button
                    className={'btn btn-lg btn-block btn-success'}
                    onClick={() => {
                      confirmAlert({
                        title: 'Confirm Order',
                        message: Strings.orderConfirmation,
                        buttons: [
                          {
                            className: 'btn btn-success',
                            label: 'Confirm Order',
                            onClick: () => this.updateOrder({lock_order: true}),
                          },
                          {
                            className: 'btn-secondary',
                            label: 'Close',
                          },
                        ],
                      });
                    }}
                  >
                    Confirm Order
                  </button>
                </div>
              </div>
              <p className={'text-muted text-center'}>{Strings.orderConfirmationMessage}</p>
              <div className={'spacer-20'}/>
            </div>
            }

            {order.lock &&
            <div className={'row mb-3'}>
              <div className={'col-12'}>
                <h5>Payment Details</h5>
                <hr/>
                <div className={'row'}>
                  <div className={'col-lg-5 col-md-12'}>
                    <table className={'table table-borderless format-table table-sm'}>
                      <tbody>
                        <tr>
                          <td className={'w-50'}>Total</td>
                          <td>{order.currency + ' ' + order.total}</td>
                        </tr>
                        {parseFloat(order.total_paid) > 0 &&
                        <tr>
                          <td className={'w-50'}>Paid</td>
                          <td className={'w-50 green-cl'}>{order.currency + ' ' + order.total_paid}</td>
                        </tr>
                        }
                        {parseFloat(order.total_unpaid_payment) > 0 &&
                        <tr>
                          <td className={'w-50'}>Pending</td>
                          <td className={'w-50 red-cl'}>{order.currency + ' ' + order.total_unpaid_payment}</td>
                        </tr>
                        }
                        {(parseFloat(order.settlement_difference) > 0) &&
                        <tr>
                          <td className={'w-50'}>Fee/Charges</td>
                          <td className={'w-50 orange-cl'}><strong>- {order.currency + ' ' + order.settlement_difference}</strong>
                          </td>
                        </tr>
                        }
                        {(parseFloat(order.total_settled) > 0) &&
                        <tr>
                          <td className={'w-50'}><strong>Settled Payment</strong></td>
                          <td className={'w-50'}><strong>{order.currency + ' ' + order.total_settled}</strong></td>
                        </tr>
                        }
                        {parseFloat(order.total_refunded) > 0 &&
                        <tr>
                          <td className={'w-50'}>Refunded</td>
                          <td className={'w-50'}>{order.currency + ' ' + order.total_refunded}</td>
                        </tr>
                        }
                        {(parseFloat(order.total_refunded) > 0) &&
                        <tr>
                          <td className={'w-50'}><strong>Net</strong></td>
                          <td className={'w-50'}><strong>{order.currency + ' ' + order.total_available_fund}</strong>
                          </td>
                        </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className={'col-lg-7 col-md-12'}>
                    <ResponsiveContainer width="100%" height={150}>
                      <PieChart width={400} height={150}>
                        <Pie
                          data={data}
                          cx="50%"
                          cy="50%"
                          outerRadius={55}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {data.map((data) => (
                            <Cell key={data.name} fill={data.color}/>
                          ))}
                        </Pie>
                        <Tooltip/>
                        <Legend onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}/>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <div className={'spacer-20'}/>
              </div>
            </div>
            }

            {order.allow_payment &&
            <div className={'mb-3'}>
              <ul className="list-inline list-item-mb-1">
                <li className="list-inline-item">
                  <button
                    onClick={() => this.setState({showPayment: true})}
                    className={'btn btn-success'}>
                    <FontAwesomeIcon icon={faPlusCircle} size={'sm'} className={'mr-2'}/> Add Payment
                  </button>
                </li>
                <li className="list-inline-item">
                  <button
                    className={'btn btn-success'}
                    onClick={() => this.setState({showOrderPostPaymentEdit: true})}
                    disabled={!(!_.isEmpty(config) && config.order && config.order.discount_post_payment)}
                  >
                    <FontAwesomeIcon icon={faPercentage} size={'sm'} className={'mr-2'}/> Add Discount
                  </button>
                </li>
              </ul>
            </div>
            }

            {(order.lock) &&
            <div className={'row mb-3'}>
              <div className={'col'}>
                <p>
                  <Link to={`/order/${order.hash}/order-payment/`}>
                    View Payment(s) <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
                  </Link>
                </p>
                {(parseFloat(order.total_refunded) > 0) &&
                <p>
                  <Link to={`/order/${order.hash}/order-refund/`}>
                    View Refund(s) <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
                  </Link>
                </p>
                }
              </div>
            </div>
            }

            <div className={'row'}>
              {(!_.isEmpty(order.notes)) &&
              <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                <h6>Notes</h6>
                <p className={'small'}>Notes on this order.</p>
                <div className="list-group">
                  {order.notes.map((data, key) => (
                    <NotesListView
                      data={data}
                      key={key}
                      successAction={() => this.fetchInitData()}
                    />
                  ))}
                </div>
              </div>
              }
              {(order.signature_record_signed && order.signature_record_signed.record) &&
              <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                <p className={'mb-2'}>Signature record</p>
                <figure className="figure border p-2">
                  <img
                    src={order.signature_record_signed.record} className="figure-img img-fluid rounded"
                    alt="Signature Record 1"
                  />
                  <figcaption className="figure-caption">Signature Record.</figcaption>
                </figure>
              </div>
              }
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group mb-5">
              <li className="list-group-item  align-items-center">
                <button onClick={() => this.setState({showNotesManager: true})} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faStickyNote} size={'sm'} className={'mr-2'}/> Add Note
                </button>
              </li>
              <li className="list-group-item  align-items-center">
                <button onClick={() => this.setState({showMessagingManager: true})} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faPaperPlane} size={'sm'} className={'mr-2'}/> Notification ( E-Mail / SMS )
                </button>
              </li>
              <li className="list-group-item">
                <a
                  href={order.lock ? order.resources_link : '#head'} rel="noopener noreferrer" target={'_blank'}
                  className={'btn btn-link m-0 p-0 ' + (order.lock && order.order_auto_generate_sequence_id ? '' : 'disabled')}>
                  <FontAwesomeIcon icon={faFilePdf} size={'sm'} className={'mr-2'}/> Order PDF
                </a>
                {!order.order_auto_generate_sequence_id &&
                <p className={'small text-muted mb-0'}>Sequence ID disabled.</p>
                }
              </li>
              {order.resources_link_pos &&
                <li className="list-group-item">
                  <button
                    type="button"
                    className={'btn btn-link m-0 p-0'}
                    disabled={!order.resources_link_pos}
                    onClick={() => this.setState({
                      showModelPDFView: true,
                      urlModelPDFView: order.resources_link_pos
                    })}
                  >
                    <FontAwesomeIcon icon={faClipboardList} size={'sm'} className={'mr-2'}/>Order Receipt
                  </button>
                </li>
              }

            </ul>

            <ul className="list-group mb-5">
              <li className="list-group-item  align-items-center" onClick={() => this.cacheReset()}>
                <small>Order Actions</small>
              </li>
              <li className="list-group-item">
                <button
                  className="btn btn-link red-cl m-0 p-0"
                  disabled={!order.allow_unlock}
                  onClick={() => {
                    confirmAlert({
                      title: 'Unlock Order',
                      message: Strings.orderUnlockText,
                      buttons: [
                        {
                          className: 'btn-warning',
                          label: 'Unlock Order',
                          onClick: () => this.escalatedPermissionUpdated({unlock_order: true}),
                        },
                        {
                          className: 'btn-secondary',
                          label: 'Close',
                        },
                      ],
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faLockOpen} size={'sm'} className={'mr-2'}/> Unlock Order
                </button>
                <p className={'small text-muted mb-0'}>Requires Manager Permission.</p>
              </li>
              <li className="list-group-item">
                <button
                  disabled={order.void}
                  className="btn btn-link m-0 p-0"
                  onClick={() => this.setState({
                    showOrderLockedEdit: true,
                  })}
                >
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit Order
                </button>
                <p className={'small text-muted mb-0'}>Order tax information.</p>
              </li>
              <li className="list-group-item">
                <button
                  disabled={((order.guest && order.guest.id) || order.lock)}
                  type="button" className="btn btn-link m-0 p-0" data-toggle="modal"
                  onClick={() => this.setState({showGuestManagement: true, addProfile: true})}
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle} size={'sm'} className={'mr-2'}/>
                  Add Guest
                </button>
                <p className={'small text-muted mb-0'}>Attach a guest profile to order.</p>
              </li>
              <li className="list-group-item">
                <button
                  disabled={((order.corporate && order.corporate.id) || order.lock)}
                  type="button" className="btn btn-link m-0 p-0" data-toggle="modal"
                  onClick={() => this.setState({
                    showProfileSearch: true,
                    profileSource: Constants.PROFILE_TYPE.CORPORATE,
                  })}
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle} size={'sm'} className={'mr-2'}/>
                  Add Corporate
                </button>
                <p className={'small text-muted mb-0'}>Attach a corporate profile to order.</p>
              </li>
              <li className="list-group-item">
                <button
                  disabled={((order.corporate && order.corporate.id) || order.lock)}
                  type="button" className="btn btn-link m-0 p-0" data-toggle="modal"
                  onClick={() => this.setState({showProfileSearch: true, profileSource: Constants.PROFILE_TYPE.AGENT})}
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle} size={'sm'} className={'mr-2'}/>
                  Add Agent
                </button>
                <p className={'small text-muted mb-0'}>Attach an agent profile to order.</p>
              </li>
              <li className="list-group-item">
                <button
                  className="btn btn-link orange-cl m-0 p-0"
                  disabled={order.lock}
                  onClick={() => this.setState({
                    showOrderEscalatedPermissionEdit: true,
                    showOrderEscalatedPermissionEditType: 'complimentary',
                  })}
                >
                  <FontAwesomeIcon icon={faMoneyBillWave} size={'sm'} className={'mr-2'}/> Complimentary Order
                </button>
                <p className={'small text-muted mb-0'}>Requires Manager Permission.</p>
              </li>
            </ul>
          </div>
        </div>

        {/* Add Payment */}
        <PaymentAdd
          updateResponse={(data) => this.props.history.push(`/payment/${data.hash}`)}
          onHide={() => this.setState({showPayment: false})}
          show={this.state.showPayment}
          order={order}
          property={this.props.property}
        />
        {/*  Order Edit */}
        <OrderEdit
          updateResponse={this.props.setOrder}
          onHide={() => this.setState({showOrderEdit: false})}
          show={this.state.showOrderEdit}
          order={order}
          property={this.props.property}
          editType={this.state.showOrderEditType}
        />
        {/*  Order Edit */}
        <OrderPostPaymentEdit
          updateResponse={this.props.setOrder}
          onHide={() => this.setState({showOrderPostPaymentEdit: false})}
          show={this.state.showOrderPostPaymentEdit}
          order={order}
          property={this.props.property}
          editType={this.state.showOrderPostPaymentEditType}
        />
        {/*  Order Edit */}
        <OrderLockedEdit
          updateResponse={this.props.setOrder}
          onHide={() => this.setState({showOrderLockedEdit: false})}
          show={this.state.showOrderLockedEdit}
          order={order}
          property={this.props.property}
        />

        {/*  Order Escalated Edit */}
        <OrderEscalatedPermissionEdit
          updateResponse={this.props.setOrder}
          onHide={() => this.setState({showOrderEscalatedPermissionEdit: false})}
          show={this.state.showOrderEscalatedPermissionEdit}
          order={order}
          property={this.props.property}
          editType={this.state.showOrderEscalatedPermissionEditType}
        />


        {/*  Profile Details */}
        <GuestDetailsOverView
          onHide={() => this.setState({showProfileDetails: false})}
          guestId={this.state.profileDetailsData}
          show={this.state.showProfileDetails}
          addGuestFunction={this.state.addProfile}
          action={this.profileActions}
          resultMsg={resultMsg}
        />

        {/*  Profile Manage */}
        <GuestQuickManage
          onHide={() => this.setState({showGuestManagement: false})}
          show={this.state.showGuestManagement}
          property={this.props.property}
          showGuestDetails={this.showProfileDetails}
          guestAddAction={this.profileActions}
        />

        {/*  Profile Search */}
        <ProfileQuickSearch
          onHide={() => this.setState({showProfileSearch: false})}
          show={this.state.showProfileSearch}
          showProfileDetails={this.showProfileDetails}
          isCorporate={this.state.profileSource === Constants.PROFILE_TYPE.CORPORATE}
          isAgent={this.state.profileSource === Constants.PROFILE_TYPE.AGENT}
        />

        {/*  Order Item*/}
        <OrderItemDetailsView
          updateResponse={this.props.setOrder}
          onHide={() => this.setState({showOrderItem: false})}
          show={this.state.showOrderItem}
          order={order}
          orderItem={this.state.orderItem}
          property={this.props.property}
          history={this.props.history}
        />

        {/* Messaging */}
        <MessagingManager
          onHide={() => this.setState({showMessagingManager: false})}
          show={this.state.showMessagingManager}
          property={this.props.property}
          guests={order.guest ? [order.guest] : null}
          order={order}
        />

        {/* Notes */}
        <NotesManager
          order={order}
          show={this.state.showNotesManager}
          successAction={() => this.props.setOrder(order.id)}
          onHide={() => this.setState({showNotesManager: false})}
        />

        {/* OrderItemsBulkEdit */}
        <OrderItemsBulkEdit
          order={order}
          updateResponse={this.props.setOrder}
          show={this.state.showOrderItemsBulkEdit}
          onHide={() => this.setState({showOrderItemsBulkEdit: false})}
        />

        {/* PDF View */}
        <ModelPDFView
          title={'Print Order'}
          onHide={() => this.setState({showModelPDFView: false, urlModelPDFView: null})}
          show={this.state.showModelPDFView}
          pdfUrl={this.state.urlModelPDFView}
        />

        <History object={order}/>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      taxDetails: false,
      discountDetails: false,
      showModelPDFView: false,
      profileDetailsData: null,
      showProfileDetails: false,
      showGuestManagement: false,
      showOrderLockedEdit: false,
      profileSource: Constants.PROFILE_TYPE.CORPORATE,
      showProfileSearch: false,
      addProfile: false,
      BookingRoomList: false,
      showPayment: false,
      showOrderEdit: false,
      showNotesManager: false,
      showOrderEditType: 'order',
      showOrderEscalatedPermissionEditType: 'order',
      showOrderItem: false,
      showMessagingManager: false,
      orderItem: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
