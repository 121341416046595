import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Route, Switch} from 'react-router-dom';
// Components
import TicketBookingList from './TicketBookingList';
import PageHeader from '../../components/dashboard/PageHeader';


class TicketBookingListIndex extends Component {
  static propTypes = {
    match: PropTypes.object,
    event: PropTypes.object,
    hideHeader: PropTypes.bool,
    baseUrl: PropTypes.string,
    eventId: PropTypes.number,
    guestId: PropTypes.number,
    corporateId: PropTypes.number,
  };


  static defaultProps = {
    baseUrl: '',
  };

  componentDidMount = () => {
  };

  render = () => {
    const {hideHeader, guestId, corporateId, eventId} = this.props;

    return (
      <div>
        {!hideHeader &&
         <PageHeader
           history={this.props.history} title={'Ticket Bookings\'s'}
           description={'All tickets booked made for this event. Choose category to filter bookings.'}
         />
        }
        <div className={'row'}>
          <div className={'col-12'}>
            <Switch>
              <Route
                render={(props) => <TicketBookingList
                  guestId={guestId} corporateId={corporateId} eventId={eventId} {...props}
                />}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  };
}

export default TicketBookingListIndex;