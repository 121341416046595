/**
 * ReviewListView
 *  View for Review Listing
 *
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import AppUtil from '../../lib/util';

// Actions
import * as ReviewActions from '../../redux/review/actions';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setReview: ReviewActions.setReview
};

/* Component ==================================================================== */
class ReviewListView extends Component {
  static componentName = 'ReviewListView';

  static propTypes = {
    data: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  action = (data) => {
    this.props.setReview(data);
    this.props.history.push('/review/' + data.id);
  };

  render = () => {
    const data = this.props.data;
    return (
      <div
        role="presentation"
        onKeyPress={this.handleKeyPress}
        onClick={() => this.action(data)}
        className="list-group-item list-group-item-action flex-column align-items-start a-class">
        <div className="d-flex w-100 justify-content-between">
          <h5 className="mb-1">{data.ref_no}</h5>
          <span
            className={'badge ' + AppUtil.reviewStatusColor(data.review_status)}>{data.review_status_display}</span>
        </div>
        {data.booking_id && <p className="mb-0 small">{'Booking ID: ' + data.booking_id}</p> }
        <p>
          <small className="text-muted">{AppUtil.formatDateTime(data.created)}</small>
        </p>
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(ReviewListView);

