/**
 * Manage Update functions
 *  manages order updates ( Cover, Serving User )
 */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import * as Yup from 'yup';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {ErrorMessages} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
// Forms
import {Form, Formik} from 'formik';
import {Input, Select} from 'react-formik-ui';


/* Component ==================================================================== */

class POSOrderEdit extends React.Component {
  static componentName = 'POSOrderEdit';

  static propTypes = {
    match: PropTypes.object,
    posOrder: PropTypes.object,
    posDetails: PropTypes.object,
    updateResponse: PropTypes.func,
    editType: PropTypes.string,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        loading: false,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  updateOrder = (credentials = null) => {
    const {editType, posDetails} = this.props;

    let payload = {};
    if (credentials) {
      switch (editType) {
      case 'edit':
      default:
        payload.occupancy = credentials.occupancy;
        payload.serving_user = credentials.serving_user;
      }
    }

    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.posapi
          .patch(`pos-order/${this.props.posOrder.hash}/?pos=${posDetails.hash}`, payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                this.props.updateResponse(res, posDetails.hash);
                this.props.onHide();
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };


  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {show, editType, posOrder, posDetails} = this.props;
    if (loading) return <Loading heightMatch={true}/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    if (!show) {
      return <React.Fragment/>;
    }

    let formValidation, formInputData;

    const SERVICE_USER = [];
    if (!_.isEmpty(posDetails.membership)) {
      posDetails.membership.forEach((data) => {
        if (!_.isEmpty(data.user)) {
          SERVICE_USER.push({
            value: String(data.user.id),
            label: data.user.display_name,
          });
        }
      });
    }


    switch (editType) {
    case 'edit':
    default:
      formValidation = Yup.object().shape({
        occupancy: Yup.number().required(),
      });
      formInputData = () =>
        <React.Fragment>
          <div className="form-row">
            <div className="col-lg-6 col-md-6 form-group">
              <Input
                name='occupancy'
                label={'Covers'}
                className={'form-control'}
              />
            </div>
            {!_.isEmpty(SERVICE_USER) &&
            <div className="col-lg-6 col-md-6 form-group">
              <Select
                className={'form-control rounded-right-0'}
                name='serving_user'
                label={'Serving User'}
                placeholder='Select an Option'
                options={SERVICE_USER}
              />
            </div>
            }
          </div>
        </React.Fragment>;
      break;
    }

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Order
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            occupancy: posOrder.occupancy,
            serving_user: posOrder.serving_user_id,
          }}
          validationSchema={formValidation}
          onSubmit={values => this.updateOrder(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <Form className={'form-group'}>
                      <div className="mt-2">
                        {formInputData(formikProps)}
                      </div>
                    </Form>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <button
                      type="submit" onClick={formikProps.handleSubmit} className={'btn btn-success btn-lg btn-block'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default POSOrderEdit;
