/**
 *
 * Ticket List
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../../lib/api';
import Strings from '../../../constants/strings';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import ListView from '../../../components/general/ListView';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faTicketAlt} from '@fortawesome/free-solid-svg-icons';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  event: state.event.event,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class TicketList extends Component {
  static componentName = 'TicketList';
  static propTypes = {
    refreshable: PropTypes.bool,
    roomSettings: PropTypes.bool,
    match: PropTypes.object.isRequired,
  };


  fetchRoomType = (page = 1, callback) => {
    const {event} = this.props;
    AppAPI.eventapi.get('ticket/?page=' + page + ((this.props.match.params.eventId && event.id ? '&event=' + event.id : '')))
      .then((res) => {
        this.setState({
          loading: false,
          error: null,
        });
        if (res.results.length > 0) {
          if (res.next) {
            callback(res.results);
          } else {
            callback(res.results, {
              allLoaded: true, // the end of the list is reached
            });
          }
        } else {
          callback();
        }
      })
      .catch((err) => {
        callback(null, {
          allLoaded: true, // the end of the list is reached
        });
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={4}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faTicketAlt}/> No Tickets </h4>
            {Strings.eventTicketsEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };

  renderRow = (data, key) => {
    const {event} = this.props;
    return (
      <tr key={key}>
        <td>
          <Link to={`/settings/event/${event.hash}/ticket/${data.hash}/`}>
            {data.name}
          </Link>
        </td>
        <td>
          {data.ticket_quantity}
        </td>
        <td>{`${data.currency} ${data.base_price_effective}`}</td>
        <td>
          <span
            className={'badge ' + (data.active ? ' badge-success' : 'badge-secondary')}>{data.active ? 'Active' : 'De-active'}</span>
        </td>
      </tr>
    );
  };

  render = () => {

    const {loading, error} = this.state;
    const {event} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="room-type-list screen-container ">
        <Helmet>
          <title>Event Tickets</title>
        </Helmet>
        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12 mb-5">
            <table className={'table border'}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <ListView
                  rowView={this.renderRow}
                  onFetch={this.fetchRoomType}
                  firstLoader={true}
                  pagination
                  emptyView={this.emptyView}
                  class={'list-group'}
                  colSpan={4}
                />
              </tbody>
            </table>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link
                  to={`/settings/event/${event.hash}/ticket/new`}
                  className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Ticket
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketList);