/**
 * Manage Guest Screen
 *  manages guest add and edit
 */
import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Actions
import * as GuestActions from '../../redux/guest/actions';
// Consts and Libs
import AppAPI from '../../lib/api';
import { Constants, ErrorMessages, SelectList } from '../../constants';
// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import {Alerts} from '../../components/ui';
import PageHeader from '../../components/dashboard/PageHeader';
import {Checkbox, Datepicker, Form, Input, Select, SubmitBtn} from 'react-formik-ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faSave} from '@fortawesome/free-solid-svg-icons';
import * as Yup from 'yup';
import {Formik} from 'formik';

// Additional packages
const moment = require('moment');
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  guest: state.guest.guest,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setGuest: GuestActions.setGuest,
  unsetGuest: GuestActions.unSetGuest,
};

/* Component ==================================================================== */

class ProfileManage extends Component {
  static componentName = 'ProfileManage';

  static propTypes = {
    match: PropTypes.object,
    isGuest: PropTypes.bool,
    isAgent: PropTypes.bool,
    isVendor: PropTypes.bool,
    hideHeader: PropTypes.bool,
    isCorporate: PropTypes.bool,
  };

  componentDidMount = () => {
    let type = 'Guest', profile_type = Constants.PROFILE_TYPE.GUEST;
    if (this.props.isCorporate) {
      type = 'Corporate';
      profile_type = Constants.PROFILE_TYPE.CORPORATE;
    } else if (this.props.isAgent) {
      type = 'Agent';
      profile_type = Constants.PROFILE_TYPE.AGENT;
    } else if (this.props.isVendor) {
      type = 'Vendor';
      profile_type = Constants.PROFILE_TYPE.VENDOR;
    }

    const {guest} = this.props;

    if (!this.props.match.params.guestId || guest.hash !== this.props.match.params.guestId) {
      this.props.unsetGuest();
    }

    this.setState({type: type, profile_type: profile_type});
    this.fetchInitData();
  };

  addGuest = (credentials) => {
    if (credentials) {
      this.setState({form_values: credentials}, () => {
        this.setState({resultMsg: {status: 'One moment...'}});

        AppAPI.guest.post('',
          {
            'profile_type': this.state.profile_type,

            'entity_name': credentials.entity_name,
            'iata_code': credentials.iata_code,
            'profile_pos': credentials.profile_pos,
            'profile_booking': credentials.profile_booking,

            'email': credentials.email,
            'phone': credentials.phone ? credentials.code + credentials.phone : credentials.phone,

            'salutation': credentials.salutation,
            'first_name': credentials.first_name,
            'last_name': credentials.last_name,
            'date_of_birth': credentials.date_of_birth ? moment(credentials.date_of_birth).format('YYYY-MM-DD') : null,
            'anniversary_date': credentials.anniversary_date ? moment(credentials.anniversary_date).format('YYYY-MM-DD') : null,
            'age': credentials.age,
            'gender': credentials.gender,
            'nationality': credentials.nationality ? credentials.nationality : '',

            'address_1': credentials.address_1,
            'address_2': credentials.address_2,
            'state': credentials.state,
            'country': credentials.country ? credentials.country : '',
            'pin_code': credentials.pin_code,

            'tax_company': credentials.tax_company,
            'tax_number': credentials.tax_number,

            'bank_account_number': credentials.bank_account_number,
            'bank_account_code': credentials.bank_account_code,
            'bank_account_name': credentials.bank_account_name,
            'bank_account_address': credentials.bank_account_address,

            'subscribe_promotions': credentials.subscribe_promotions,
            'subscribe_transactions': credentials.subscribe_transactions,
          })
          .then((res) => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.setGuest(res);
                  this.props.history.replace(this.props.location.pathname.replace(/[^/]*$/, `${res.hash}/`));
                }, 500);
              });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  saveGuest = (credentials) => {
    if (credentials) {
      this.setState({form_values: credentials}, () => {
        this.setState({resultMsg: {status: 'One moment...'}});
        AppAPI.guest.patch(this.props.match.params.guestId + '/',
          {
            'entity_name': credentials.entity_name,
            'iata_code': credentials.iata_code,
            'profile_pos': credentials.profile_pos,
            'profile_booking': credentials.profile_booking,

            'email': credentials.email,
            'phone': credentials.phone ? credentials.code + credentials.phone : credentials.phone,

            'salutation': credentials.salutation,
            'first_name': credentials.first_name,
            'last_name': credentials.last_name,
            'date_of_birth': credentials.date_of_birth ? moment(credentials.date_of_birth).format('YYYY-MM-DD') : null,
            'anniversary_date': credentials.anniversary_date ? moment(credentials.anniversary_date).format('YYYY-MM-DD') : null,
            'age': credentials.age,
            'gender': credentials.gender,
            'nationality': credentials.nationality ? credentials.nationality : '',

            'address_1': credentials.address_1,
            'address_2': credentials.address_2,
            'state': credentials.state,
            'country': credentials.country ? credentials.country : '',
            'pin_code': credentials.pin_code,

            'tax_company': credentials.tax_company,
            'tax_number': credentials.tax_number,

            'bank_account_number': credentials.bank_account_number,
            'bank_account_code': credentials.bank_account_code,
            'bank_account_name': credentials.bank_account_name,
            'bank_account_address': credentials.bank_account_address,

            'subscribe_promotions': credentials.subscribe_promotions,
            'subscribe_transactions': credentials.subscribe_transactions,

          })
          .then((res) => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.setGuest(res);
                  this.props.history.goBack();
                }, 500);
              });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  fetchInitData = () => {
    const {guest, property} = this.props;
    let defaultCountry = null;
    if (property.country) {
      defaultCountry = property.country.code;
    }

    if (this.props.match.params.guestId && guest && guest.hash === this.props.match.params.guestId) {
      let number = null;
      try {
        number = phoneUtil.parse(guest.phone) ? phoneUtil.parseAndKeepRawInput(guest.phone) : null;
      } catch (e) {
        number = null;
      }

      this.setState({
        loading: false,
        initialValues: {
          'entity_name': guest.entity_name,
          'iata_code': guest.iata_code,
          'email': guest.email,
          'code': number ? '+' + String(number.getCountryCode()) : this.state.initialValues.code,
          'phone': number ? number.getNationalNumber() : '',

          'profile_pos': guest.profile_pos,
          'profile_booking': guest.profile_booking,

          'salutation': guest.salutation,
          'first_name': guest.first_name,
          'last_name': guest.last_name,
          'date_of_birth': guest.date_of_birth,
          'age': guest.age || '',
          'anniversary_date': guest.anniversary_date,
          'gender': guest.gender,
          'nationality': guest.nationality ? guest.nationality.code : defaultCountry,

          'address_1': guest.address_1 || '',
          'address_2': guest.address_2 || '',
          'state': guest.state || '',
          'country': guest.country ? guest.country.code : defaultCountry,
          'pin_code': guest.pin_code,

          'tax_company': guest.tax_company,
          'tax_number': guest.tax_number,

          'bank_account_number': guest.bank_account_number,
          'bank_account_code': guest.bank_account_code,
          'bank_account_name': guest.bank_account_name,
          'bank_account_address': guest.bank_account_address,

          'subscribe_promotions': guest.subscribe_promotions,
          'subscribe_transactions': guest.subscribe_transactions,
        },
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  render = () => {
    const {guest, hideHeader, property} = this.props;
    const {loading, error, resultMsg, initialValues, type, profile_type} = this.state;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    let required_profile_fields = [];
    const validation = {
      first_name: Yup.string().min(2, 'Too Short!').required('Required'),
      salutation: Yup.string().required('Required').nullable(),
      last_name: Yup.string().nullable(),
      email: Yup.string().email('Invalid email').nullable(),
      phone: Yup.number(),
    };

    if (!_.isEmpty(property.config) && property.config.profile) {
      const profile_fields = property.config.profile.profile_advance_fields;
      if (!_.isEmpty(profile_fields) && !_.isEmpty(profile_fields.required_profile_fields)) {
        required_profile_fields = profile_fields.required_profile_fields;
        if (required_profile_fields.includes('age')){
          validation['age'] = Yup.number().min(1, 'Min age is 1').max(120, 'Max age is 120').required('Required');
        }
        if (required_profile_fields.includes('gender')) {
          validation['gender'] = Yup.string().required('Required');
        }
        if (required_profile_fields.includes('address_1')) {
          validation['address_1'] = Yup.string().required('Required');
        }
        if (required_profile_fields.includes('address_2')) {
          validation['address_2'] = Yup.string().required('Required');
        }
        if (required_profile_fields.includes('nationality')) {
          validation['nationality'] = Yup.string().required('Required');
        }
        if (required_profile_fields.includes('state')) {
          validation['state'] = Yup.string().required('Required');
        }
        if (required_profile_fields.includes('country')) {
          validation['country'] = Yup.string().required('Required');
        }
      }
    }

    const defaultFormValidation = Yup.object().shape(validation);

    const guestEdit = (guest && guest.id);

    return (
      <div className="guest-manage screen-container ">
        <Helmet>
          <title>{type} Profile Manage</title>
        </Helmet>

        {!hideHeader &&
         <PageHeader
           history={this.props.history} title={((guestEdit) ? 'Manage' : 'Add') + ' ' + type}
           description={(guestEdit && (type + ' reference : ' + guest.ref_no))}
           subHeader={!(profile_type === Constants.PROFILE_TYPE.GUEST)}
         />
        }

        <Formik
          onSubmit={(values) => (guestEdit ? this.saveGuest(values) : this.addGuest(values))}
          validationSchema={defaultFormValidation}
          initialValues={initialValues}>
          {(formikProps) => (
            <Form>
              <div className="row">
                {(profile_type !== Constants.PROFILE_TYPE.GUEST) &&
                 <div className="col-lg-6 col-md-12 col-sm-12">
                   <div
                     className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                     role="button" aria-expanded="false" aria-controls="orderActions">
                     <h6 className={'mb-0'}>
                       Entity Information
                     </h6>
                   </div>
                   <div className={'border p-2'} id={'primaryAction'}>
                     <div className="form-row">
                       <div className="col-lg-6 col-md-6 form-group">
                         <Input
                           required
                           name='entity_name'
                           label={'Entity Name'}
                           className={'form-control'}
                         />
                       </div>
                       <div className="col-lg-6 col-md-6 ">
                         <Select
                           required
                           className={'form-control rounded-right-0'}
                           name='entity_type'
                           label={'Entity Type'}
                           placeholder='Select an Option'
                           options={Constants.ENTITY_TYPE_INPUT}
                         />
                       </div>
                     </div>
                     <div className="form-row">
                       <div className="col-lg-6 col-md-6 form-group">
                         <Input
                           name='entity_number'
                           label={'Entity Number'}
                           hint={'Company Registration Number'}
                           className={'form-control'}
                         />
                       </div>
                       <div className="col-lg-6 col-md-6 ">
                         <Input
                           name='iata_code'
                           label={'IATA Code'}
                           className={'form-control'}
                         />
                       </div>
                     </div>
                     <div className="form-row">
                       <div className="col-lg-6 col-md-6 form-group">
                         <Checkbox
                           name='profile_booking'
                           label={'Active in Booking'}
                           hint={'Profile will be available in booking.'}
                         />
                       </div>
                       <div className="col-lg-6 col-md-6 ">
                         <Checkbox
                           name='profile_pos'
                           label={'Active in Point of Sales'}
                           hint={'Profile will be available in POS.'}
                         />
                       </div>
                     </div>
                   </div>
                 </div>
                }
                <div className={`${profile_type !== Constants.PROFILE_TYPE.GUEST ? 'col-lg-6' : 'col-lg-12'} col-md-12 col-sm-12`}>
                  <div className={'row'}>
                    <div className={`${profile_type !== Constants.PROFILE_TYPE.GUEST ? 'col-lg-12' : 'col-lg-6'} col-md-12 col-sm-12`}>
                      <div
                        className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                        role="button" aria-expanded="false" aria-controls="orderActions">
                        <h6 className={'mb-0'}>
                          Contact Information
                        </h6>
                      </div>
                      <div className={'border p-2'} id={'primaryAction'}>
                        <div className="form-row">
                          <div className="col-lg-6 col-md-6 form-group">
                            <Input
                              name='email'
                              type={'email'}
                              label={'E-mail'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 ">
                            <div className={'row'}>
                              <div className={'col-4 pr-0 form-group'}>
                                <Select
                                  className={'form-control rounded-right-0'}
                                  name='code'
                                  label={'Country'}
                                  placeholder='Select an Option'
                                  options={SelectList.phoneCountryCode()}
                                />
                              </div>
                              <div className={'col-8 pl-0 form-group'}>
                                <Input
                                  type={'tel'}
                                  name='phone'
                                  label={'Phone'}
                                  className={'form-control rounded-left-0'}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${profile_type !== Constants.PROFILE_TYPE.GUEST ? 'col-lg-12' : 'col-lg-6'} col-md-12 col-sm-12`}>
                      <div
                        className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                        role="button" aria-expanded="false" aria-controls="orderActions">
                        <h6 className={'mb-0'}>
                          Tax Information
                        </h6>
                      </div>
                      <div className={'border p-2'} id={'primaryAction'}>
                        <div className="form-row">
                          <div className="col-md-6 col-sm-12 form-group">
                            <Input
                              name='tax_company'
                              label={'Company Name'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-md-6 col-sm-12 form-group">
                            <Input
                              name='tax_number'
                              label={'Tax Number'}
                              className={'form-control'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div
                    className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                    role="button" aria-expanded="false" aria-controls="orderActions">
                    <h6 className={'mb-0'}>
                      Personal Information
                    </h6>
                  </div>
                  <div className={'border p-2'} id={'primaryAction'}>
                    <div className="form-row">
                      <div className="col-md-7 form-group">
                        <div className={'form-row'}>
                          <div className="col-4 ">
                            <Select
                              name='salutation'
                              label={'Salutation'}
                              className={'form-control'}
                              placeholder='Select an Option'
                              options={Constants.SALUTATION}
                            />
                          </div>
                          <div className="col-8">
                            <Input
                              name='first_name'
                              label={'First Name'}
                              className={'form-control'}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 form-group">
                        <Input
                          name='last_name'
                          label={'Last Name'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <div className={'form-row'}>
                      <div className="col-lg-6 col-md-6 form-group">
                        <Select
                          className={'form-control rounded-right-0'}
                          name='gender'
                          label={'Gender'}
                          placeholder='Select an Option'
                          options={Constants.GENDER}
                          required={required_profile_fields.includes('gender')}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 form-group">
                        <Select
                          className={'form-control rounded-right-0'}
                          name='nationality'
                          label={'Nationality'}
                          placeholder='Select an Option'
                          options={SelectList.countryList()}
                          required={required_profile_fields.includes('nationality')}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-6 form-group">
                        <p className={'mb-1'}>Date of Birth</p>
                        <Datepicker
                          name='date_of_birth'
                          className={'form-control'}
                          hint={'Date format DD/MM/YYYY'}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <p className={'mb-1'}>Anniversary</p>
                        <Datepicker
                          name='anniversary_date'
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-6 form-group">
                        <p className={'mb-1'}>Age</p>
                        <Input
                          name='age' className={'form-control'}
                          required={required_profile_fields.includes('age')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div
                    className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                    role="button" aria-expanded="false" aria-controls="orderActions">
                    <h6 className={'mb-0'}>
                      Contact Information
                    </h6>
                  </div>
                  <div className={'border p-2'} id={'primaryAction'}>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Input
                          name='address_1'
                          label={'Address Line 1'}
                          className={'form-control'}
                          placeholder={'House No / Name'}
                          required={required_profile_fields.includes('address_1')}
                        />
                      </div>
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Input
                          name='address_2'
                          label={'Address Line 2'}
                          className={'form-control'}
                          placeholder={'District'}
                          required={required_profile_fields.includes('address_2')}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-lg-4 col-md-4 col-sm-6 form-group">
                        {formikProps.values.country && !_.isEmpty(SelectList.stateList(formikProps.values.country))
                          ? (
                            <Select
                              className={'form-control rounded-right-0'}
                              name='state'
                              label={'State'}
                              placeholder='Select an Option'
                              options={SelectList.stateList(formikProps.values.country)}
                              required={required_profile_fields.includes('state')}
                            />
                          ) : (
                            <Input
                              name='state'
                              label={'State'}
                              className={'form-control'}
                              disabled={!formikProps.values.country}
                              required={required_profile_fields.includes('state')}
                            />
                          )}
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 form-group">
                        <Select
                          className={'form-control rounded-right-0'}
                          name='country'
                          label={'Country'}
                          placeholder='Select an Option'
                          options={SelectList.countryList()}
                          required={required_profile_fields.includes('country')}
                        />
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 form-group">
                        <Input
                          name='pin_code'
                          label={'Pin Code'}
                          className={'form-control'}
                          type={'number'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div
                    className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                    role="button" aria-expanded="false" aria-controls="orderActions">
                    <h6 className={'mb-0'}>
                      Bank Account Information
                    </h6>
                  </div>
                  <div className={'border p-2'} id={'primaryAction'}>
                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <Input
                          name='bank_account_number'
                          label={'Account Number'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <Input
                          name='bank_account_code'
                          label={'Bank Code'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                        <Input
                          name='bank_account_name'
                          label={'Bank Name'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                        <Input
                          name='bank_account_address'
                          label={'Bank Address'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div
                    className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                    role="button" aria-expanded="false" aria-controls="orderActions">
                    <h6 className={'mb-0'}>
                      Notification Information
                    </h6>
                  </div>
                  <div className={'border p-2'} id={'primaryAction'}>
                    <div className="form-row">
                      <div className="col-6 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='subscribe_transactions'
                          label='Transaction Messages'
                          text="Subscribe for Transaction Messages ?"
                        />
                      </div>
                      <div className="col-6 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='subscribe_promotions'
                          label='Promotional Messages'
                          text="Subscribe for Promotional Messages ?"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn className={'btn btn-success btn-block btn-lg mt-2'}>
                    <FontAwesomeIcon className={'white-cl mr-2'} icon={(guestEdit ? faSave : faPlus)} size={'sm'}/>
                    {(guestEdit ? 'Save' : 'Add') + ' ' + type}
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };


  constructor(props) {
    super(props);

    const {property} = this.props;

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      form_values: {},
      options: {},
      type: 'Guest',
      profile_type: 1,
      initialValues: {
        'email': '',
        'first_name': '',
        'code': property && property.phone_number_code ? property.phone_number_code : '',
        'nationality': property && property.country ? property.country.code : '',
        'country': property && property.country ? property.country.code : '',
        'subscribe_transactions': true,
        'profile_booking': true,
        'profile_pos': true,
        'gender': '',
        'address_1': '',
        'address_2': '',
        'state': '',
        'age': '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(ProfileManage);
