/**
 *
 * POS Order KOT List
 * List POS KOT Order
 */
import React, {Component} from 'react';
import queryString from 'query-string';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import Moment from 'moment';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import AppWebUtil from '../../lib/webUtils';
import {Constants, Filters, Strings} from '../../constants';
// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import SearchBar from '../../components/forms/SearchBar';
import FilterButton from '../../components/dashboard/FilterButton';
import ExportButton from '../../components/dashboard/ExportButton';
import FilterDate from '../../components/dashboard/FilterDate';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFile, faLongArrowAltRight, faSync, faUtensilSpoon} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';
import _ from 'lodash';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  posDetails: state.pos.posDetails,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class POSOrderKOTList extends Component {
  static componentName = 'POSOrderKOTList';

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };

  componentDidMount = () => {
    let urlParams = {}, startDate = null, endDate = null;
    switch (this.getQueryParams('kot_status')) {
    case 'original':
      urlParams['kot_status'] = Constants.POS_KOT_STATUS.ORIGINAL;
      break;
    case 'modified':
      urlParams['kot_status'] = Constants.POS_KOT_STATUS.MODIFIED;
      break;
    case 'canceled':
      urlParams['kot_status'] = Constants.POS_KOT_STATUS.CANCELLED;
      break;
    default:
      break;
    }
    if (this.getQueryParams('start_date')) {
      startDate = Moment(String(this.getQueryParams('start_date')));
    }
    if (this.getQueryParams('end_date')) {
      endDate = Moment(String(this.getQueryParams('end_date')));
    }

    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('start_date'), this.getQueryParams('end_date'), 'created_date');

    if (this.getQueryParams('created_user')) {
      urlParams['created_user'] = this.getQueryParams('created_user');
    }

    this.setState({loading: false, urlParams, startDate, endDate});
  };

  fetchData = (page = null, callback, searchText, download) => {
    const {posDetails} = this.props;

    const urlParams = {...this.state.urlParams};
    let baseUrl = 'pos-order-kot-v2';
    if (page) {
      urlParams['cursor'] = page;
    }
    urlParams['pos'] = posDetails.hash;

    if (searchText) {
      baseUrl = 'pos-order-kot-search';
      urlParams['search'] = searchText;
      this.setState({searching: true, clear: true});
      if (page){
        urlParams['page'] = page;
      }
    }

    if (download) {
      urlParams['download'] = download.file;
      urlParams['all_data'] = true;
      this.setState({fileDownload: true});
    }

    AppAPI.posapi.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (download) {
          let fileName = `POS-Order-KOT-Report-${AppUtil.formatDateTime(Moment(), 'short')}.${download.file}`;
          AppWebUtil.downloadFile(res, fileName);
        } else {
          if (res.results.length > 0) {
            callback(
              res.results,
              {
                allLoaded: !res.next,
                nextParam: res.count ? AppUtil.getURLParam(res.next, 'page') : AppUtil.getURLParam(res.next, 'cursor')
              }
            );
          } else {
            callback();
          }
        }
        this.setState({
          searching: false,
          clear: false,
          refresh: false,
          loading: false,
          fileDownload: false,
          page: page,
        });
      })
      .catch((err) => {
        if (callback) {
          callback([], {allLoaded: true});
        }
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, refresh: false, loading: false, fileDownload: false, error});
      });
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={7}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faFile}/> No KOT </h4>
            {Strings.POSOrderKOTListEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };


  tableHeader = () => {
    return (
      <tr>
        <th className={'data-table-cell-sm'}>Reference</th>
        <th className={'data-table-cell-md'}>KOT ID</th>
        <th className={'data-table-cell-xl'}/>
        <th className={'data-table-cell-xl'}>Created By</th>
        <th className={'data-table-cell-sm'}>Order</th>
        <th className={'data-table-cell-sm'}>Date</th>
        <th className={'data-table-cell-md'}/>
      </tr>
    );
  };


  renderRow = (data, key) => {
    const {posDetails} = this.props;
    return (
      <tr key={key}>
        <td><small>{data.ref_no}</small></td>
        <td><strong>{data.kot_id}</strong></td>
        <td className={'data-table-cell-xl'}>
          <span className={'ml-2 badge ' + (AppUtil.posOrderKotStatusColor(data.kot_status))}>
            {data.kot_status_display}
          </span>
        </td>
        <td>
          {!_.isEmpty(data.attributes) &&
          <small>{(data.attributes.created_user) ? data.attributes.created_user : '...'}</small>}
          {!_.isEmpty(data.created_user) &&
          <small>{(data.created_user.display_name) ? data.created_user.display_name : '...'}</small>}
        </td>
        <td>
          {!_.isEmpty(data.attributes) &&
          <small>{(data.attributes.order_ref_no) ?
            <Link
              to={`/point-of-sale/${posDetails.hash}/pos-order/${data.attributes.order_hash}`}>{data.attributes.order_ref_no}</Link> : '...'}
          </small>
          }
          {!_.isEmpty(data.order) && <small>
            <Link to={`/point-of-sale/${posDetails.hash}/pos-order/${data.order.hash}`}>{data.order.ref_no}</Link>
          </small>
          }
        </td>
        <td><small>{AppUtil.formatDateTime(data.created)}</small></td>
        <td>
          <Link to={`/point-of-sale/${posDetails.hash}/pos-order-kot/${data.hash}`}>
            Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
          </Link>
        </td>
      </tr>
    );
  };


  render = () => {
    const {loading, error, clear, searching, results, refresh, fileDownload} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;


    return (
      <div className="order-list screen-container">
        <Helmet>
          <title>Point of Sale | Order List</title>
        </Helmet>

        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-9'}>
            <h4 className={'mb-4'}><FontAwesomeIcon icon={faUtensilSpoon} className={'mr-2'} size={'sm'}/> Kitchen Order Ticket(s)</h4>

            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.setState({refresh: true})}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                </button>
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'KOT Status'}
                  data={Filters.posOrderKOTFilter}
                  selectKey={this.getQueryParams('kot_status')}
                  action={(data) => this.setQueryParams([{key: 'kot_status', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data))}
                  size={'sm'}
                  maxDate={Moment().add(1, 'days')}
                />
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-3 text-center text-sm-right'}>
            <ExportButton
              size={'sm'}
              files={['csv']}
              action={(key) => this.fetchData(this.state.page, null, null, {file: key})}
              loading={fileDownload}
              disabled={!this.state.startDate}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <SearchBar
              clear={clear}
              refresh={refresh}
              results={results}
              searching={searching}
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
              searchFunction={this.fetchData}
              colSpan={7}
              listVersion={'V2'}
              placeHolder="Reference ID or KOT ID or Order Reference ID"
            />
          </div>
        </div>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      searching: false,
      clear: false,
      results: [],
      urlParams: {},
      page: 1,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(POSOrderKOTList);
