/**
 * Booking Edit Menu
 *  Manage date select and booking & order select
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import Moment from 'moment';
// Consts and Libs
// Components
import DateRangePicker from 'react-daterange-picker';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight, faTimes} from '@fortawesome/free-solid-svg-icons';


/* Component ==================================================================== */

class ReportsDateSelect extends Component {
  static componentName = 'ReportsDateSelect';

  static propTypes = {
    property: PropTypes.object,
    show: PropTypes.bool,
    action: PropTypes.func,
  };

  static defaultProps = {
    minDate: Moment().subtract(1, 'days'),
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  onSelect = (date) => {
    this.setState({
      checkin: date.start,
      checkout: date.end,
    });
  };

  render = () => {

    const {checkin, checkout} = this.state;

    return (
      <Modal
        {...this.props}
        size={'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            Date Select
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-4">
            <div className={'col-md-12 col-12-sm col-lg-12 text-center'}>
              <DateRangePicker
                className={'w-100'}
                selectionType={'range'}
                minimumDate={this.props.minDate}
                maximumDate={this.props.maxDate}
                value={Moment.range(checkin, checkout)}
                onSelect={this.onSelect}
                numberOfCalendars={2}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          <div className={'row'}>
            <div className={'col'}>
              <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
              </button>
            </div>
            <div className={'col'}>
              <button className="btn btn-success btn-block btn-lg" onClick={() => this.props.action(checkin, checkout)}>
                Set Date <FontAwesomeIcon className={'white-cl'} icon={faArrowRight} size={'sm'}/>
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: true,
      checkin: Moment().startOf('day'),
      checkout: Moment().add(1, 'days'),
    };
  }
}

/* Export Component ==================================================================== */
export default ReportsDateSelect;
