/**
 *
 * BookingRoomList
 * List Bookings
 */
import React, {Component} from 'react';
import queryString from 'query-string';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Moment from 'moment';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import AppWebUtil from '../../lib/webUtils';
import {Constants, Filters, Strings} from '../../constants';
// Components
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import SearchBar from '../../components/forms/SearchBar';
import FilterDate from '../../components/dashboard/FilterDate';
import ExportButton from '../../components/dashboard/ExportButton';
import FilterButton from '../../components/dashboard/FilterButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBaby, faChild, faDoorOpen, faLongArrowAltRight, faSync, faUser} from '@fortawesome/free-solid-svg-icons';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class BookingRoomList extends Component {
  static componentName = 'BookingRoomList';

  static propTypes = {
    guestId: PropTypes.number,
    corporateId: PropTypes.number,
    roomTypeId: PropTypes.number,
    disableFilters: PropTypes.bool,
    bookingDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  };

  static defaultProps = {
    disableFilters: false,
  }

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };

  componentDidMount = () => {
    let urlParams = {}, startDate = null, endDate = null, bookingCheckinStartDate = null, bookingCheckinEndDate = null,
      bookingCheckoutStartDate = null, bookingCheckoutEndDate = null, bookingDate =  null;

    switch (this.getQueryParams('booking_status')) {
    case 'upcoming':
      urlParams['booking_status'] = Constants.BOOKING.UPCOMING;
      break;
    case 'active':
      urlParams['booking_status'] = Constants.BOOKING.ACTIVE;
      break;
    case 'completed':
      urlParams['booking_status'] = Constants.BOOKING.COMPLETED;
      break;
    case 'no_show':
      urlParams['booking_status'] = Constants.BOOKING.NOSHOW;
      break;
    case 'cancelled':
      urlParams['booking_status'] = Constants.BOOKING.CANCELED;
      break;
    default:
      break;
    }


    if (this.getQueryParams('start_date')) {
      startDate = Moment(String(this.getQueryParams('start_date')));
    }
    if (this.getQueryParams('end_date')) {
      endDate = Moment(String(this.getQueryParams('end_date')));
    }
    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('start_date'), this.getQueryParams('end_date'), 'created_date');

    if (this.getQueryParams('booking_checkin_start_date')) {
      bookingCheckinStartDate = Moment(String(this.getQueryParams('booking_checkin_start_date')));
    }
    if (this.getQueryParams('booking_checkin_end_date')) {
      bookingCheckinEndDate = Moment(String(this.getQueryParams('booking_checkin_end_date')));
    }
    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('booking_checkin_start_date'), this.getQueryParams('booking_checkin_end_date'), 'booking_checkin');

    if (this.getQueryParams('booking_checkout_start_date')) {
      bookingCheckoutStartDate = Moment(String(this.getQueryParams('booking_checkout_start_date')));
    }
    if (this.getQueryParams('booking_checkout_end_date')) {
      bookingCheckoutEndDate = Moment(String(this.getQueryParams('booking_checkout_end_date')));
    }
    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('booking_checkout_start_date'), this.getQueryParams('booking_checkout_end_date'), 'booking_checkout');

    if (this.getQueryParams('booking_date_start_date')) {
      bookingDate = Moment(String(this.getQueryParams('booking_date_start_date')));
      urlParams['booking_date'] = bookingDate.format('YYYY-MM-DD');
    }

    if (this.props.bookingDate){
      bookingDate = Moment(this.props.bookingDate);
      urlParams['booking_date'] = bookingDate.format('YYYY-MM-DD');
    }


    if (this.props.guestId) {
      urlParams['guests_data'] = this.props.guestId;
    }

    if (this.props.corporateId) {
      urlParams['corporate_data'] = this.props.corporateId;
    }


    if (this.props.roomTypeId) {
      urlParams['room_type'] = this.props.roomTypeId;
    }

    this.setState({
      loading: false,
      urlParams,
      startDate,
      endDate,
      bookingDate,
      bookingCheckinStartDate,
      bookingCheckinEndDate,
      bookingCheckoutStartDate,
      bookingCheckoutEndDate,
    });
  };

  fetchData = (page = null, callback, searchText, download) => {
    const urlParams = {...this.state.urlParams};
    let baseUrl = 'booking-room-v2';
    if (page) {
      urlParams['cursor'] = page;
    }


    if (searchText) {
      this.setState({searching: true, clear: true});
      baseUrl = 'booking-room';
      urlParams['q'] = searchText;
    }

    if (download) {
      urlParams['download'] = download.file;
      urlParams['all_data'] = true;
      if (page){
        urlParams['page'] = page;
      }
      this.setState({fileDownload: true});
    }

    AppAPI.bookingapi.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (download) {
          let fileName = `Booking-Room-Report-${AppUtil.formatDateTime(Moment(), 'short')}.${download.file}`;
          AppWebUtil.downloadFile(res, fileName);
        } else {
          if (res.results.length > 0) {
            callback(
              res.results,
              {
                allLoaded: !res.next,
                nextParam: res.count ? AppUtil.getURLParam(res.next, 'page') : AppUtil.getURLParam(res.next, 'cursor')
              }
            );
          } else {
            callback();
          }
        }
        this.setState({
          searching: false,
          clear: false,
          refresh: false,
          loading: false,
          fileDownload: false,
          page: page,
        });
      })
      .catch((err) => {
        if (callback) {
          callback([], {allLoaded: true});
        }
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, refresh: false, loading: false, fileDownload: false, error});
      });
  };


  renderRow = (data, key) => {
    const attributes = _.isEmpty(data.attributes) ? {} : data.attributes;
    return (
      <tr key={key}>
        <td>
          {attributes.booking_hash &&
            <Link to={`/booking/${attributes.booking_hash}/booking-room/${data.hash}`}>
              {data.ref_no}
            </Link>
          }
          <p className="mb-0 small">
            <FontAwesomeIcon icon={faUser} size={'sm'}/> {data.no_of_guest}
            <FontAwesomeIcon icon={faChild} size={'sm'} className={'ml-2'}/> {data.no_of_children}
            <FontAwesomeIcon icon={faBaby} size={'sm'} className={'ml-2'}/> {data.no_of_infant}
          </p>
        </td>
        <td>
          <Link to={`/booking/${attributes.booking_hash}/`}>
            {attributes.booking_ref_no}
          </Link>
          {(attributes.booking_ota_name) && <p className={'small text-muted mb-0'}>({attributes.booking_ota_name})</p>}
        </td>
        <td>
          <span className={'badge ' + (AppUtil.bookingStatusColor(data.booking_status))}>
            {data.booking_status_display}
          </span>
        </td>
        <td>
          {`${AppUtil.formatDateTime(data.checkin, 'date')} - ${AppUtil.formatDateTime(data.checkout, 'date')}`}
          <p className={'small text-muted'}>{AppUtil.formatDateTime(data.created)}</p>
        </td>
        <td>
          {!_.isEmpty(attributes.room_type_name) ? attributes.room_type_name : '...'}
          {!_.isEmpty(attributes.room_name) && <p className={'small text-muted mb-0'}>Room : {attributes.room_name}</p>}
        </td>
        <td>
          <p className={'small text-muted'}>
            {
              !_.isEmpty(data.room_type_package_attributes) ?
                <React.Fragment>
                  {data.room_type_package_attributes.map((packageData, i) => (
                    ` ${i > 1 ? ', ' : ''}${packageData.room_type_package ? packageData.room_type_package.name : '..'}`
                  ))}
                </React.Fragment>
                :
                '...'
            }
          </p>
        </td>
        <td>
          {attributes.booking_hash &&
          <React.Fragment>
            <Link to={`/booking/${attributes.booking_hash}/booking-room/${data.hash}`}>
              Details
              <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
            </Link>
          </React.Fragment>
          }
        </td>
      </tr>
    );
  };


  emptyView = () => {
    return (
      <tr>
        <td colSpan={8}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faDoorOpen}/> No Booking </h4>
            {Strings.bookingRoomListEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };


  tableHeader = () => {
    return (
      <tr>
        <th className={'data-table-cell-md'}>Reference</th>
        <th className={'data-table-cell-md'}>Booking</th>
        <th className={'data-table-cell-md'}/>
        <th className={'data-table-cell-md'}>Date</th>
        <th className={'data-table-cell-md'}>Room Type</th>
        <th className={'data-table-cell-md'}>Package</th>
        <th className={'data-table-cell-sm'}/>
      </tr>
    );
  };


  render = () => {
    const {loading, error, clear, searching, results, fileDownload, refresh} = this.state;
    const {disableFilters} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="booking-list screen-container">
        <Helmet>
          <title>Booking List</title>
        </Helmet>
        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-9'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.setState({refresh: true})}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                </button>
              </li>
              {!disableFilters &&
              <React.Fragment>
                <li className="list-inline-item">
                  <FilterButton
                    title={'Booking Status'}
                    data={Filters.bookingFilter}
                    selectKey={this.getQueryParams('booking_status')}
                    action={(data) => this.setQueryParams([{key: 'booking_status', value: data.key}])}
                    size={'sm'}
                  />
                </li>
                <li className="list-inline-item">
                  <FilterDate
                    startDate={this.state.bookingDate}
                    action={(data) => this.setQueryParams(AppWebUtil.processDate(data, 'booking_date_'))}
                    size={'sm'}
                    title={'Booking Date'}
                    disableSelection={true}
                    selectionType={'single'}
                  />
                </li>
                <li className="list-inline-item">
                  <FilterDate
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    action={(data) => this.setQueryParams(AppWebUtil.processDate(data))}
                    size={'sm'}
                    maxDate={Moment().add(1, 'days')}
                    title={'Created'}
                  />
                </li>
                <li className="list-inline-item">
                  <FilterDate
                    startDate={this.state.bookingCheckinStartDate}
                    endDate={this.state.bookingCheckinEndDate}
                    action={(data) => this.setQueryParams(AppWebUtil.processDate(data, 'booking_checkin_'))}
                    size={'sm'}
                    title={'Checkin'}
                  />
                </li>
                <li className="list-inline-item">
                  <FilterDate
                    startDate={this.state.bookingCheckoutStartDate}
                    endDate={this.state.bookingCheckoutEndDate}
                    action={(data) => this.setQueryParams(AppWebUtil.processDate(data, 'booking_checkout_'))}
                    size={'sm'}
                    title={'Checkout'}
                  />
                </li>
              </React.Fragment>
              }
            </ul>
          </div>
          <div className={'col-12 col-sm-3 text-center text-sm-right'}>
            <ExportButton
              size={'sm'}
              files={['csv']}
              action={(key) => this.fetchData(this.state.page, null, null, {file: key})}
              loading={fileDownload}
              disabled={!(this.state.startDate || this.state.bookingCheckoutStartDate || this.state.bookingCheckinStartDate || this.state.bookingDate)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <SearchBar
              clear={clear}
              refresh={refresh}
              results={results}
              searching={searching}
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
              listVersion={'V2'}
              colSpan={7}
            />
          </div>
        </div>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      searching: false,
      clear: false,
      results: [],
      urlParams: {},
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingRoomList);
