/**
 * Add POS Order
 *  Add POS Order
 */
import _ from 'lodash';
import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';
import {Modal} from 'react-bootstrap';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants, ErrorMessages} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import {Input, Select} from 'react-formik-ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faTimes} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */

class POSOrderAdd extends React.Component {
  static componentName = 'POSOrderAdd';

  static propTypes = {
    match: PropTypes.object,
    user: PropTypes.object,
    posTable: PropTypes.object,
    posRoom: PropTypes.object,
    property: PropTypes.object,
    posDetails: PropTypes.object,
    updateResponse: PropTypes.func,
  };

  addOrder = (credentials) => {
    const {posDetails} = this.props;
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});

      const payload = {
        occupancy: credentials.occupancy,
        serving_user: credentials.serving_user,
      };

      if (credentials.pos_table) {
        payload.table_list = [credentials.pos_table];
      }


      if (credentials.pos_room) {
        payload.room = credentials.pos_room;
      }

      AppAPI.posapi.post(`pos-order/?pos=${posDetails.hash}`, payload)
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
              if (this.props.updateResponse) {
                this.props.updateResponse(res);
              }
              this.props.onHide();
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {show, posTable, posDetails, posRoom, user} = this.props;
    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    if (!show) return <React.Fragment/>;

    const formValidation = Yup.object().shape({
      occupancy: Yup.number().required(),
    });

    let selectUser;

    const TABLES = [], ROOMS = [];

    if (posDetails.pos_has_table && !_.isEmpty(posDetails.tables)) {
      posDetails.tables.forEach((data) => {
        if (parseInt(data.availability_status) === Constants.ROOM.AVAILABLE) {
          TABLES.push({
            value: String(data.id),
            label: data.name,
          });
        }
      });
    }

    if (posDetails.pos_has_room && !_.isEmpty(posDetails.rooms)){
      posDetails.rooms.forEach((data)=>{
        if (!_.isEmpty(data.booking_room_data)) {
          ROOMS.push({
            value: String(data.id),
            label: `${data.name} : ${data.attributes.room_type_name} : ${data.booking_room_data.attributes.booking_ref_no}`,
          });
        }
      });
    }

    const SERVICE_USER = [];
    if (!_.isEmpty(posDetails.membership)) {
      posDetails.membership.forEach((data) => {
        if (!_.isEmpty(data.user)) {
          if (user && (data.user.hash === user.hash)) {
            selectUser = data.user.id;
          }
          SERVICE_USER.push({
            value: String(data.user.id),
            label: data.user.display_name,
          });
        }
      });
    }

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create New Tab
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            occupancy: posTable ? posTable.occupancy : '1',
            pos_table: posTable ? posTable.id : '',
            pos_room: posRoom ? posRoom.id : '',
            serving_user: selectUser ? selectUser : '',
          }}
          validationSchema={formValidation}
          onSubmit={values => this.addOrder(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <Form className={'form-group'}>
                      <div className="form-row">
                        <div className="col-lg-6 col-md-6 form-group">
                          <Input
                            name='occupancy'
                            label={'Covers'}
                            className={'form-control'}
                          />
                        </div>
                        {!_.isEmpty(SERVICE_USER) &&
                         <div className="col-lg-6 col-md-6 form-group">
                           <Select
                             className={'form-control rounded-right-0'}
                             name='serving_user'
                             label={'Serving User'}
                             placeholder='Select an Option'
                             options={SERVICE_USER}
                           />
                         </div>
                        }
                      </div>
                      <div className="form-row">
                        {posDetails.pos_has_table &&
                         <div className="col form-group">
                           <Select
                             className={'form-control rounded-right-0'}
                             name='pos_table'
                             label={'Table'}
                             placeholder='Select an Option'
                             options={TABLES}
                           />
                         </div>
                        }
                        {(posDetails.pos_has_room) &&
                        <div className="col form-group">
                          <Select
                            className={'form-control rounded-right-0'}
                            name='pos_room'
                            label={'Room'}
                            placeholder='Select an Option'
                            options={ROOMS}
                          />
                        </div>
                        }
                      </div>
                    </Form>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <Alerts
                  status={resultMsg.status}
                  success={resultMsg.success}
                  error={resultMsg.error}
                />
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <button
                      disabled={(resultMsg && resultMsg.status)}
                      type="submit" onClick={formikProps.handleSubmit} className={'btn btn-success btn-lg btn-block'}
                    >
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add Order
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default POSOrderAdd;
