import React from 'react';
import { Route, Switch } from 'react-router-dom';



import NoAccess from './NoAccess';

const OrderRoute = () => (
  <div>
    
    <div className="container">
      <Switch>
        <Route path="/others/noaccess" exact component={NoAccess}/>
      </Switch>
    </div>
    
  </div>
);

export default OrderRoute;