/**
 *
 * Property public display settings
 * public details management
 */

import {FieldArray, Formik} from 'formik';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import * as Yup from 'yup';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants} from '../../../constants';

// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import { Form, Input, Select, SubmitBtn, Textarea } from 'react-formik-ui';
import {faMinus, faPlus, faSave} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// Actions
import * as PropertyActions from '../../../redux/property/actions';
import Alerts from '../../../components/ui/Alerts';
import {Strings} from '../../../constants';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetPropertyContent: PropertyActions.isSetPropertyContent,
};

/* Component ==================================================================== */
class PropertyContentManage extends Component {
  static componentName = 'PropertyPublicSettingsManage';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    AppAPI.property.get(`${this.props.property.id}/content-settings/`)
      .then((res) => {
        const printContent = res.print_content || {};
        this.setState({
          loading: false,
          initialValues: {
            policy_links: res.policy_links,
            booking_header_title: _.isEmpty(printContent.booking) ? '' : printContent.booking.header_title,
            booking_header_sub_title: _.isEmpty(printContent.booking) ? '' : printContent.booking.header_sub_title,
            booking_print_footer_notes: _.isEmpty(printContent.booking) ? '' : printContent.booking.footer_notes,
            financial_print_footer_notes: _.isEmpty(printContent.financial) ? '' : printContent.financial.footer_notes,
            financial_header_title: _.isEmpty(printContent.financial) ? '' : printContent.financial.header_title,
            financial_header_sub_title: _.isEmpty(printContent.financial) ? '' : printContent.financial.header_sub_title,
            financial_order_date_config: _.isEmpty(printContent.financial) ? '' : printContent.financial.order_date_config,
            order_date_title: _.isEmpty(printContent.financial) ? '' : printContent.financial.order_date_title,
          },
          propertyContent: res,
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: error,
          resultMsg: {error},
        });
      });
  };

  saveSettings = (values) => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {

      const printContent = {
        'financial': {
          'header_title': values.financial_header_title,
          'header_sub_title': values.financial_header_sub_title,
          'footer_notes': values.financial_print_footer_notes,
          'order_date_config': values.financial_order_date_config,
          'order_date_title': values.order_date_title,
        },
        'booking': {
          'header_title': values.booking_header_title,
          'header_sub_title': values.booking_header_sub_title,
          'footer_notes': values.booking_print_footer_notes,
        }
      };

      AppAPI.property.patch(`${this.props.property.id}/content-settings/`, {
        'policy_links': values.policy_links,
        'print_content': printContent,
      })
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                this.props.isSetPropertyContent(res)
                  .then(()=>{
                    this.props.history.goBack();
                  });
              }, 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    });
  };

  render = () => {

    const {property} = this.props;
    const {loading, error, initialValues, resultMsg} = this.state;
    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const defaultFormValidation = Yup.object().shape({
      policy_links: Yup.array()
        .of(
          Yup.object().shape({
            title: Yup.string()
              .min(1, 'too short')
              .required('Required'), // these constraints take precedence
            link: Yup.string()
              .url('Please enter a valid URL, including scheme')
              .required('Required'), // these constraints take precedence
          }),
        ),
    });

    return (
      <div className="bank-details screen-container ">
        <Helmet>
          <title>Policy Link Settings</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'External Links'}
          description={'Link your important pages  policies of property.'}
        />
        <Formik
          onSubmit={(values) => (this.saveSettings(values))}
          validationSchema={defaultFormValidation}
          initialValues={initialValues}>
          {({values}) => (
            <Form>
              <div className={'row'}>
                <div className="col-lg-10 col-md-10 col-sm-12 mb-5">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Public Links
                    </h6>
                  </div>
                  <div className={'border p-2 mb-3'}>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-12 form-group">
                        <FieldArray
                          name="policy_links"
                          render={arrayHelpers => (
                            <div>
                              {values.policy_links && values.policy_links.length > 0 ? (
                                values.policy_links.map((public_link, index) => (
                                  <div key={index} className="form-row">
                                    <div className="col-5 form-group">
                                      <Input
                                        required
                                        name={`policy_links.${index}.title`}
                                        label={'Title'}
                                        className={'form-control'}
                                      />
                                    </div>
                                    <div className="col-5 form-group">
                                      <Input
                                        required
                                        hint={'http:// or https://'}
                                        name={`policy_links.${index}.link`}
                                        label={'URL/Link'}
                                        className={'form-control'}
                                      />
                                    </div>
                                    <div className={'col-2'}>
                                      <div className="btn-group mt-4" role="group">
                                        <button
                                          onClick={() => arrayHelpers.remove(index)}
                                          type="button" className="btn btn-secondary btn-warning">
                                          <FontAwesomeIcon className={'white-cl'} icon={faMinus} size={'sm'}/>
                                        </button>
                                        <button
                                          onClick={() => arrayHelpers.insert(index, '')}
                                          type="button" className="btn btn-secondary btn-success">
                                          <FontAwesomeIcon className={'white-cl'} icon={faPlus} size={'sm'}/>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className={'text-center'}>
                                  <p>No links.</p>
                                  <p className={'text-secondary'}>
                                    This is where your privacy policy, terms and conditions can be linked. These are displayed on
                                    booking engine, payments page and other pages made for your property.
                                  </p>
                                  <button
                                    className={'btn btn-success'} type="button"
                                    onClick={() => arrayHelpers.push('')}>
                                    <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add a Link
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>


                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Order/Payment Footer Notes
                    </h6>
                  </div>
                  <div className={'border p-2 mb-3'}>
                    <div className={'row'}>
                      <div className={'col-2'}>
                        <img
                          width={75}
                          src={property.logo.logo} alt={property.name} className="img-thumbnail"
                        />
                      </div>
                      <div className={'col-10'}>
                        <div className="form-row">
                          <div className="col-lg-12 col-sm-12 form-group">
                            <Input
                              required
                              name={'financial_header_title'}
                              hint={Strings.financialHeaderTitle}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-lg-12 col-sm-12 form-group">
                            <Input
                              required
                              name={'financial_header_sub_title'}
                              hint={Strings.financialHeaderSubTitle}
                              className={'form-control'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr/>

                    <div className={'bg-light py-5 text-center'}>
                      <p className={'text-muted'}>Order/Payment/Refund Content</p>
                    </div>

                    <hr/>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Textarea
                          name='financial_print_footer_notes'
                          label={'Footer Notes'}
                          hint={Strings.financialFooterNotes}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <hr/>
                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <Input
                          label={'Date Title'}
                          name={'order_date_title'}
                          hint={'Order date title [booking orders only]'}
                          className={'form-control'}
                          disabled={!values.financial_order_date_config}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <Select
                          label={'Order Date'}
                          placeholder='Select an Option'
                          name='financial_order_date_config'
                          options={Constants.ORDER_PRINT_DATE_TYPE}
                          className={'form-control rounded-right-0'}
                          hint={'Only applicable on booking orders'}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Booking
                    </h6>
                  </div>
                  <div className={'border p-2 mb-3'}>
                    <div className={'row'}>
                      <div className={'col-2'}>
                        <img
                          width={75}
                          src={property.logo.logo} alt={property.name} className="img-thumbnail"
                        />
                      </div>
                      <div className={'col-10'}>
                        <div className="form-row">
                          <div className="col-lg-12 col-sm-12 form-group">
                            <Input
                              required
                              name={'booking_header_title'}
                              hint={Strings.financialHeaderTitle}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-lg-12 col-sm-12 form-group">
                            <Input
                              required
                              name={'booking_header_sub_title'}
                              hint={Strings.financialHeaderSubTitle}
                              className={'form-control'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr/>

                    <div className={'bg-light py-5 text-center'}>
                      <p className={'text-muted'}>Booking Content</p>
                    </div>

                    <hr/>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Textarea
                          name='booking_print_footer_notes'
                          label={'Footer Notes'}
                          hint={Strings.bookingFooterNotes}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                  </div>


                </div>
              </div>
              <div className={'row mb-5'}>
                <div className="col-lg-10 col-md-10 col-sm-12 mb-5">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn className={'btn btn-md btn-success btn-lg btn-block'}>
                    <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      initialValues: {
        footer_notes: '',
        property_links: [],
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyContentManage);
