/**
 *
 * Expense Department List
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../../../lib/api';
import Strings from '../../../../constants/strings';
// Components
import Loading from '../../../../components/general/Loading';
import Error from '../../../../components/general/Error';
import ListView from '../../../../components/general/ListView';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PageHeader from '../../../../components/dashboard/PageHeader';
import {faPlus, faShoppingBasket} from '@fortawesome/free-solid-svg-icons';
import ExpenseDepartmentManage from './ExpenseDepartmentManage';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class ExpenseDepartmentList extends Component {
  static componentName = 'ExpenseDepartment';
  static propTypes = {
    refreshable: PropTypes.bool,
    roomSettings: PropTypes.bool,
  };

  componentDidMount = () => {
  };

  fetchData = (page = 1, callback) => {
    AppAPI.expenseapi.get('department/?page=' + page)
      .then((res) => {
        this.setState({
          loading: false,
          error: null,
        });
        if (res.results.length > 0) {
          if (res.next) {
            callback(res.results);
          } else {
            callback(res.results, {
              allLoaded: true, // the end of the list is reached
            });
          }
        } else {
          callback();
        }
      })
      .catch((err) => {
        callback(null, {
          allLoaded: true, // the end of the list is reached
        });
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={3}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faShoppingBasket}/> No Department </h4>
            {Strings.expenseDepartmentEmptyText.map((data, i) => <p className={'mb-1'} key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };

  renderRow = (data, key) => {
    return (
      <tr key={key}>
        <td>
          <Link to={`/settings/expense/department/${data.hash}`}>{data.name}</Link>
        </td>
        <td>
          <span className={'badge ' + (data.active ? 'badge-success' : 'badge-secondary')}>
            {data.active ? 'Active' : 'De-active'}
          </span>
        </td>
      </tr>
    );
  };
  render = () => {

    const {loading, error} = this.state;
    const {property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="room-type-list screen-container ">
        <Helmet>
          <title>Expense Department</title>
        </Helmet>

        <PageHeader
          history={this.props.history}
          title={'Expense Department'}
          description={`Expense Department in ${property.name}`}
          subHeader={true}
        />
        <div className="row">
          <div className="col-lg-9 col-sm-12 col-md-9 mb-5">
            <table className={'table border'}>
              <thead>
                <tr>
                  <th className={'w-75'}>Name</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <ListView
                  rowView={this.renderRow}
                  onFetch={this.fetchData}
                  firstLoader={true}
                  pagination
                  emptyView={this.emptyView}
                  class={'list-group'}
                  colSpan={2}
                />
              </tbody>
            </table>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <button
                  onClick={() => this.setState({showExpenseDepartmentManage: true})}
                  className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Department
                </button>
              </li>
            </ul>
          </div>
        </div>
        <ExpenseDepartmentManage
          show={this.state.showExpenseDepartmentManage}
          onHide={() => this.setState({showExpenseDepartmentManage: false})}
          updateResponse={(data) => this.props.history.replace(`/settings/expense/department/${data.hash}`)}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      showExpenseDepartmentManage: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseDepartmentList);