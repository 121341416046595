/**
 *
 * Order analytics
 */
import React, {Component} from 'react';
import queryString from 'query-string';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import Moment from 'moment';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Filters} from '../../../constants';
import AppUtil from '../../../lib/util';
import AppWebUtil from '../../../lib/webUtils';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import FilterDate from '../../../components/dashboard/FilterDate';
import FilterButton from '../../../components/dashboard/FilterButton';
import ExportButton from '../../../components/dashboard/ExportButton';

import OrderAnalyticsOverView from './OrderAnalyticsOverView';
import OrderAnalyticsDateView from './OrderAnalyticsDateView';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */

class OrderAnalytics extends Component {
  static componentName = 'OrderAnalytics';

  componentDidMount = () => {
    this.fetchInitData();
  };

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };


  fetchInitData = (download) => {
    let startDate = Moment(), endDate = null, urlParams = {};

    if (this.getQueryParams('start_date')) {
      startDate = Moment(String(this.getQueryParams('start_date')));
    }
    if (this.getQueryParams('end_date')) {
      endDate = Moment(String(this.getQueryParams('end_date')));
    }

    urlParams['order_date_type'] = 'created';
    if (this.getQueryParams('order_date_type')) {
      urlParams['order_date_type'] = this.getQueryParams('order_date_type');
    }

    if (startDate) {
      urlParams['start_date'] = startDate.format('YYYY-MM-DD');
    }

    if (endDate) {
      urlParams['end_date'] = endDate.format('YYYY-MM-DD');
    }

    if (download) {
      urlParams['download'] = true;
      this.setState({fileDownload: true});
    }

    AppAPI.analyticsapi.get(`order-analytics/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (download) {
          this.setState({fileDownload: false});
          let fileName = `${this.props.property.name}-Order-Report-${AppUtil.formatDateTime(Moment(), 'short')}.xlsx`;
          AppWebUtil.downloadFile(res, fileName);
        } else{
          this.setState({
            fileDownload: false,
            loading: false,
            responseData: res,
            urlParams,
            endDate: res.end_date ? Moment(res.end_date) : null,
            startDate: res.start_date ? Moment(res.start_date) : null,
          });
        }
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({refresh: false, loading: false, fileDownload: false, error});
      });
  }


  render = () => {

    const {loading, error, responseData, analyticsView, fileDownload} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    const menu = [
      {
        title: 'Over View',
        key: 'overview',
      },
      {
        title: 'Date Breakdown',
        key: 'date',
      },
      // {
      //   title: 'Order(s)',
      //   key: 'order',
      // },
    ];

    let menuItems = [];
    menu.forEach((item) => {
      const {title, key} = item;
      menuItems.push(
        <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
          <div
            className={`nav-link rounded-0 btn btn-link text-left ${analyticsView.includes(key) && 'active'}`}
            onClick={() => this.setState({analyticsView: key})}
          >
            {title}
          </div>
        </li>,
      );
    });

    let mainBlock;

    switch (analyticsView) {
    case 'date':
      mainBlock = <OrderAnalyticsDateView responseData={responseData} {...this.props}/>;
      break;
    case 'overview':
    default:
      mainBlock = <OrderAnalyticsOverView responseData={responseData} {...this.props}/>;
    }

    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Order Analytics</title>
        </Helmet>

        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-9'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <FilterButton
                  title={'Date Type'}
                  data={Filters.orderDateType}
                  selectKey={this.getQueryParams('order_date_type') || 'created'}
                  action={(data) => this.setQueryParams([{key: 'order_date_type', value: data.key}])}
                  size={'md'}
                  removeFilter={false}
                />
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data))}
                  size={'md'}
                  title={'Date'}
                />
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-3 text-center text-sm-right d-none'}>
            <ExportButton
              size={'md'}
              files={['xlsx']}
              action={() => this.fetchInitData(true)}
              loading={fileDownload}
            />
          </div>
        </div>

        <div className={'row'}>
          <div className={'col-lg-2 col-md-3 col-sm-12 mb-5'}>
            <ul className="nav nav-pills flex-md-column flex-sm-row border rounded mb-3">
              {menuItems}
            </ul>
          </div>
          <div className={'col-lg-10 col-md-9 col-sm-12'}>
            {mainBlock}
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      fileDownload: false,
      analyticsView: 'overview',
      error: null,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderAnalytics);
