/**
 * BookingroomListView
 *  View for Bookingroom Listing
 *
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
// Consts and Libs
import AppUtil from '../../lib/util';
import {Constants} from '../../constants';
// Actions
import * as BookingRoomActions from '../../redux/bookingroom/actions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBaby, faCheckSquare, faChild, faSquare, faUser} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setBookingRoom: BookingRoomActions.setBookingRoom,
};

/* Component ==================================================================== */
class BookingroomListView extends Component {
  static componentName = 'BookingroomListView';

  static propTypes = {
    data: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    title: PropTypes.string,
    booking: PropTypes.object,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    selectable: PropTypes.bool,
  };

  action = (data) => {
    if (this.props.action) {
      this.props.action(data);
    } else if (!this.props.disabled) {
      let booking_id = null;
      if (this.props.booking) {
        booking_id = this.props.booking.id;
      }

      if (data.booking_id) {
        booking_id = data.booking_id;
      }

      if (booking_id) {
        this.props.history.push(`/booking/${(data.attributes && data.attributes.booking_hash) ? data.attributes.booking_hash : data.booking_id}/booking-room/${data.hash}`);
      }
    }
  };

  render = () => {
    const {data, selected, selectable, title} = this.props;

    const preCalculatedTitle = data.room_number ? `Room : ${data.room_number}` : data.ref_no;
    return (
      <div
        role="presentation"
        onKeyPress={this.handleKeyPress}
        onClick={() => this.action(data)}
        className={'list-group-item flex-column align-items-start a-class ' + (selected ? 'lime-bg' : 'list-group-item-action ')}>
        <div className="d-flex w-100 justify-content-between">
          <div>
            <h5 className="mb-1">
              {selectable &&
               <FontAwesomeIcon className={'mr-1'} icon={selected ? faCheckSquare : faSquare} size={'sm'}/>
              }
              {title ? title : preCalculatedTitle}
              {(data.attributes && data.attributes.room_type_name) &&
               <span className={'small ml-2'}>
                 [{data.attributes.room_type_name} ]
               </span>
              }
            </h5>
            <p className="mb-0 small">
              {AppUtil.formatDateTime(data.checkin, 'datef')} - {AppUtil.formatDateTime(data.checkout, 'datef')}
            </p>
            <p className="mb-0 small">
              <FontAwesomeIcon icon={faUser} size={'sm'}/> {data.no_of_guest}
              <FontAwesomeIcon icon={faChild} size={'sm'} className={'ml-2'}/> {data.no_of_children}
              <FontAwesomeIcon icon={faBaby} size={'sm'} className={'ml-2'}/> {data.no_of_infant}
            </p>
          </div>
          <div className={'text-center'}>
            <span
              className={'badge ' + (AppUtil.bookingStatusColor(data.booking_status))}>{data.booking_status_display}</span>
            {data.booking_action &&
              <React.Fragment>
                <br/>
                <span className={'badge badge-light'}>
                  {data.booking_status === Constants.BOOKING.ACTIVE ? 'Checkout Ready' : 'Checkin Ready'}
                </span>
              </React.Fragment>
            }
            {data.attributes && data.attributes.room_name &&
             <React.Fragment>
               <br/>
               <span className={'badge badge-info'}>Room : {data.attributes.room_name}</span>
             </React.Fragment>
            }
          </div>
        </div>
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(BookingroomListView);

