/**
 * Event Inventory List
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// Constants and Libs
import Strings from '../../constants/strings';
// Components
import {confirmAlert} from '../general/ConfirmAlert';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faDoorOpen, faLink, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import AppAPI from '../../lib/api';
import {Alerts} from '../ui';

/* Component ==================================================================== */
class EventInventoryList extends Component {

  static propTypes = {
    events: PropTypes.object.isRequired,
    refreshContent: PropTypes.func.isRequired,
    channelManager: PropTypes.bool,
    bookingEngine: PropTypes.bool,
  };

  static defaultProps = {
    channelManager: true,
    bookingEngine: true,
  };

  updateTicket = (ticketId, payload) => {
    if (ticketId && payload) {
      this.setState({resultMsg: {status: 'One moment...'}});
      AppAPI.eventapi.patch(`ticket-distribution/${ticketId}/`, payload)
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}});
          if (this.props.refreshContent) {
            this.props.refreshContent(res);
          }
          setTimeout(() => {
            this.setState({
              resultMsg: {success: ''},
            });
          }, 500);
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  updateEvent = (eventId, payload) => {
    if (eventId && payload) {
      this.setState({resultMsg: {status: 'One moment...'}});
      AppAPI.eventapi.patch(`event-distribution/${eventId}/`, payload)
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}});
          if (this.props.refreshContent) {
            this.props.refreshContent(res);
          }
          setTimeout(() => {
            this.setState({
              resultMsg: {success: ''},
            });
          }, 500);
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };


  render = () => {
    const {events} = this.props;
    const {resultMsg} = this.state;
    return (
      <React.Fragment>
        {events && events.length > 0 ?
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <Alerts
                status={resultMsg.status}
                success={resultMsg.success}
                error={resultMsg.error}
              />
              <div className="table-responsive">
                <table className="table table-sm table-bordered">
                  <thead className={' bg-dark text-light text-center'}>
                    <tr>
                      <th colSpan={2}>Type & Package</th>
                      <th colSpan={4}>Booking Engine</th>
                    </tr>
                    <tr>
                      <td>Event</td>
                      <td>Ticket(s)</td>
                      <React.Fragment>
                        <td>Configured</td>
                        <td colSpan={2}>Distribution Status</td>
                      </React.Fragment>
                    </tr>
                  </thead>
                  <tbody className={'text-center'}>
                    {events.map((event, i) => (
                      <React.Fragment key={i}>
                        <tr className={'table-info'}>
                          <td className={'align-middle text-left'} rowSpan={event.tickets.length + 1}>
                            <Link to={`/settings/inventory/event/${event.hash}/`}>
                              <FontAwesomeIcon icon={faLink} size={'sm'} className={'mr-2'}/>
                            </Link>{event.name}
                          </td>
                          <td className={'text-left'}>
                            <Link to={`/settings/inventory/event/${event.hash}/ticket/`}>
                              <FontAwesomeIcon icon={faLink} size={'sm'} className={'mr-2'}/>
                            </Link>
                            <FontAwesomeIcon icon={faDoorOpen} size={'sm'} className={'mr-2'}/> Tickets
                          </td>
                          <React.Fragment>
                            <td>
                              <FontAwesomeIcon
                                icon={event.booking_engine_ok ? faCheckCircle : faTimesCircle}
                                className={event.booking_engine_ok ? 'green-cl' : 'red-cl'}
                              />
                            </td>
                            <td>
                              <FontAwesomeIcon
                                icon={event.allow_booking_engine_distribution ? faCheckCircle : faTimesCircle}
                                className={event.allow_booking_engine_distribution ? 'green-cl' : 'red-cl'}
                              />
                            </td>
                            <td>
                              <button
                                onClick={() => {
                                  confirmAlert({
                                    title: (event.allow_booking_engine_distribution ? 'Disabled' : 'Enable') + ' Distribution',
                                    message: event.allow_booking_engine_distribution ? Strings.bookingEngineSectionDistributionDisable : Strings.bookingEngineSectionDistributionEnable,
                                    buttons: [
                                      {
                                        className: (event.allow_booking_engine_distribution ? 'btn-danger' : ' btn-success'),
                                        label: 'Yes',
                                        onClick: () => this.updateEvent(event.id, {allow_booking_engine_distribution: !event.allow_booking_engine_distribution}),
                                      },
                                      {
                                        className: 'btn-secondary',
                                        label: 'No',
                                      },
                                    ],
                                  });
                                }}
                                className={'btn btn-sm ' + (event.allow_booking_engine_distribution ? 'btn-danger' : ' btn-success')}>
                                {(!event.allow_booking_engine_distribution ? 'Enable' : ' Disable')}
                              </button>
                            </td>
                          </React.Fragment>
                        </tr>
                        {event.tickets &&
                         <React.Fragment>
                           {event.tickets.map((ticket, j) => (
                             <tr key={j}>
                               <td className={'text-left'}>
                                 <Link
                                   to={`/settings/inventory/event/${event.hash}/ticket/${ticket.hash}/`}>
                                   <FontAwesomeIcon icon={faLink} size={'sm'} className={'mr-2'}/>
                                 </Link>
                                 {ticket.name}
                               </td>

                               <React.Fragment>
                                 <td>
                                   <FontAwesomeIcon
                                     icon={ticket.booking_engine_ok ? faCheckCircle : faTimesCircle}
                                     className={ticket.booking_engine_ok ? 'green-cl' : 'red-cl'}
                                   />
                                 </td>
                                 <td>
                                   <FontAwesomeIcon
                                     icon={ticket.allow_booking_engine_distribution ? faCheckCircle : faTimesCircle}
                                     className={ticket.allow_booking_engine_distribution ? 'green-cl' : 'red-cl'}
                                   />
                                 </td>
                                 <td>
                                   <button
                                     onClick={() => {
                                       confirmAlert({
                                         title: (ticket.allow_booking_engine_distribution ? 'Disable' : ' Enabled') + ' Distribution',
                                         message: ticket.allow_booking_engine_distribution ? Strings.channelManagerSectionDistributionDisable : Strings.channelManagerSectionDistributionEnable,
                                         buttons: [
                                           {
                                             className: (ticket.allow_booking_engine_distribution ? 'btn-danger' : ' btn-success'),
                                             label: 'Yes',
                                             onClick: () => this.updateTicket(ticket.id, {allow_booking_engine_distribution: !ticket.allow_booking_engine_distribution}),
                                           },
                                           {
                                             className: 'btn-secondary',
                                             label: 'No',
                                           },
                                         ],
                                       });
                                     }}
                                     className={'btn btn-sm ' + (ticket.allow_booking_engine_distribution ? 'btn-danger' : ' btn-success')}>
                                     {(!ticket.allow_booking_engine_distribution ? 'Enable' : ' Disable')}
                                   </button>
                                 </td>
                               </React.Fragment>
                             </tr>
                           ))}
                         </React.Fragment>
                        }

                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              <ul className={'list-unstyled'}>
                <li><strong>Configured</strong> : {Strings.eventDistributionConfigured}</li>
                <li><strong>Distribution</strong> : {Strings.eventDistributionDistribution}</li>
              </ul>
              <p className={'text-muted'}>
                {Strings.propertyDistributionThrottled}
              </p>
            </div>
          </div>
          :
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="empty text-center mt-5">
                <h4>No Event's to configure</h4>
              </div>
            </div>
          </div>
        }
      </React.Fragment>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default EventInventoryList;
