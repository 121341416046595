/**
 *
 * Event Settings
 * event information
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppUtil from '../../../lib/util';
import {Constants} from '../../../constants';
// Components
import {History} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as EventActions from '../../../redux/event/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  event: state.event.event,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setEvent: EventActions.setEvent,
};

/* Component ==================================================================== */
class EventDetails extends Component {
  static componentName = 'EventDetails';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    if (this.props.match.params.eventId) {
      this.setState({
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };

  render = () => {

    const {loading, error} = this.state;
    const {event} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="pos-details screen-container ">
        <Helmet>
          <title>Event Details</title>
        </Helmet>

        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                <ul className="list-group">
                  <li className="list-group-item"><strong>Reference Number</strong> : {event.ref_no}</li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Event Status</strong> :
                    <span className={'badge ' + (AppUtil.bookingStatusColor(event.event_status))}>
                      {event.event_status_display}
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 mb-5 mb-md-3">
                <ul className="list-group">
                  <li className="list-group-item"><strong>Name</strong> : {event.name}</li>
                  <li className="list-group-item"><strong>Event Type</strong> : {event.event_type_display}</li>
                  {event.event_type === Constants.EVENT_TYPE.RECURRING &&
                   <React.Fragment>
                     <li className="list-group-item">
                       <strong>Event {event.event_recurring_type === Constants.EVENT_RECURRING_TYPE.LIMITED ? 'End ' : ' Booking End'} Date </strong> : {AppUtil.formatDateTime(event.event_end_date, 'date')}
                     </li>
                     <li className="list-group-item">
                       <strong>Week Days</strong> : {AppUtil.formatWeekDays(event.event_recurring_week_days)}
                     </li>
                   </React.Fragment>
                  }
                  <li className="list-group-item">
                    <strong>
                      {event.event_recurring_type === Constants.EVENT_RECURRING_TYPE.LIMITED ? 'Length of event ' : 'Advanced booking '} :
                    </strong>
                    {event.allow_advance_booking_days} days
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mb-5 mb-md-3">
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Event {event.event_type === Constants.EVENT_TYPE.RECURRING && 'Start'} Date </strong> : {AppUtil.formatDateTime(event.event_start_date, 'datef')}
                  </li>
                  {event.event_type === Constants.EVENT_TYPE.RECURRING &&
                   <li className="list-group-item">
                     <strong>Event {event.event_recurring_type === Constants.EVENT_RECURRING_TYPE.LIMITED ? 'End ' : ' Booking End'} Date </strong> : {AppUtil.formatDateTime(event.event_end_date, 'datef')}
                   </li>
                  }
                  <li className="list-group-item">
                    <strong>Event Schedule
                      Start</strong> : {AppUtil.formatDateTime(event.event_schedule_start_time, 'time')}
                  </li>

                  <li className="list-group-item">
                    <strong>Event Schedule
                      End</strong> : {parseInt(event.event_schedule_length) > 1 && ` + ${event.event_schedule_length} day(s)`} {AppUtil.formatDateTime(event.event_schedule_end_time, 'time')}
                  </li>
                  <li className="list-group-item">
                    <strong>Booking Ends : </strong>
                    {event.event_booking_end_duration ?
                      `${AppUtil.formatDuration(event.event_booking_end_duration)} from start time.`
                      :
                      'At start time.'
                    }
                  </li>
                </ul>
              </div>
            </div>

            <div className={'row'}>
              <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Status</strong> :
                    <span className={'badge ' + (event.active === true ? 'badge-success' : 'badge-danger')}>
                      {(event.active === true ? 'Active' : 'Deactivated')}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Booking Summary PDF</strong> :
                    <span className={'badge ' + (event.booking_summary_pdf === true ? 'badge-success' : 'badge-danger')}>
                      {(event.booking_summary_pdf === true ? 'Included' : 'Not Included')}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item">
                <Link to={`/settings/event/${event.hash}/edit/`} className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <History object={event}/>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventDetails);
