/**
 * Notes Actions
 *
 */

import AppAPI from '../../lib/api';

/**
 * Set Notes
 */
export const setNotes = (notesData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof notesData === 'object') {
    dispatch({
      type: 'SET_NOTES',
      data: notesData,
    });
    return resolve(notesData);
  } else if (notesData) {
    return AppAPI.notes.get(notesData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_NOTES',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Notes
 */
export const updateNotes = (notesData) => setNotes(notesData);

/**
 * Remove Notes
 */
export function unSetNotes () {
  return (dispatch) => {
    dispatch({
      type: 'SET_NOTES',
      data: null,
    });
  };
}

/**
 * Set Parameters for Notes
 */
export const setNotesParameter = (notesData) => (dispatch) => new Promise(async (resolve, reject) => {

  let data = {
    'order': null,
    'booking': null,
    'bookingroom': null,
    'room': null,
    'expense': null,
  };

  // Reset to null
  dispatch({
    type: 'SET_NOTES_PARAMETER',
    data: data,
  });

  if (typeof notesData === 'object') {

    if (notesData.order) {
      data['order'] = notesData.order;
    }

    if (notesData.booking) {
      data['booking'] = notesData.booking;
    }

    if (notesData.bookingroom) {
      data['bookingroom'] = notesData.bookingroom;
    }

    if (notesData.room) {
      data['room'] = notesData.room;
    }

    if (notesData.expense) {
      data['expense'] = notesData.expense;
    }

    dispatch({
      type: 'SET_NOTES_PARAMETER',
      data: data,
    });
    return resolve(notesData);
  }
  return reject();
});

/**
 * UnSet Notes
 */
export function unSetNotesParameter () {

  let data = {
    'order': null,
    'booking': null,
    'bookingroom': null,
    'room': null,
    'expense': null,
  };

  return (dispatch) => {
    dispatch({
      type: 'SET_NOTES_PARAMETER',
      data: data,
    });
  };
}