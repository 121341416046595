import {
  faBath,
  faBicycle,
  faBug,
  faBuilding,
  faBusinessTime,
  faCar,
  faChair,
  faChargingStation,
  faChessKing,
  faChild,
  faClock,
  faCoffee,
  faConciergeBell,
  faCookieBite,
  faCouch,
  faDesktop,
  faDumbbell,
  faEgg,
  faFire,
  faGift,
  faGlassMartini,
  faHotTub,
  faLock,
  faMicrophone,
  faMitten,
  faMoneyBillAlt,
  faMoneyBillWave,
  faMotorcycle,
  faMusic,
  faNewspaper,
  faParking,
  faPrint,
  faRing,
  faShoePrints,
  faShuttleVan,
  faSmog,
  faSmoking,
  faSmokingBan,
  faSnowflake,
  faSpa,
  faStar,
  faStore,
  faStoreAlt,
  faSuitcase,
  faSwimmingPool,
  faToiletPaper,
  faTshirt,
  faTv,
  faUserClock,
  faUsers,
  faUtensils,
  faVolumeDown,
  faWater,
  faWaveSquare,
  faWheelchair,
  faWifi,
  faWind,
  faWineBottle,
} from '@fortawesome/free-solid-svg-icons';
import {faPagelines} from '@fortawesome/free-brands-svg-icons';

export default {
  CATEGORY: [
    {id: 1, name: 'Food & Drink'},
    {id: 2, name: 'Pool & Wellness'},
    {id: 3, name: 'Parking'},
    {id: 4, name: 'Transport'},
    {id: 5, name: 'Reception Service'},
    {id: 6, name: 'Common areas'},
    {id: 7, name: 'Business facilities'},
    {id: 8, name: 'Cleaning services'},
    {id: 9, name: 'Shops'},
    {id: 10, name: 'Miscellaneous'},
    {id: 11, name: 'Room Amenities'},
  ],
  AMENITY_LIST: [
    // Food & Drink
    {id: 1, category: 1, name: 'Restaurant', icon: faUtensils},
    {id: 2, category: 1, name: 'Restaurant (à la carte)', icon: faUtensils},
    {id: 3, category: 1, name: 'Restaurant (buffet)', icon: faUtensils},
    {id: 4, category: 1, name: 'Bar', icon: faGlassMartini},
    {id: 5, category: 1, name: 'Snack Bar', icon: faCookieBite},

    {id: 6, category: 1, name: 'Packed lunches', icon: faUtensils},
    {id: 7, category: 1, name: 'BBQ Facilities', icon: faFire},
    {id: 8, category: 1, name: 'Vending Machine (Drinks)', icon: faConciergeBell},
    {id: 9, category: 1, name: 'Vending Machine (Snacks)', icon: faConciergeBell},
    {id: 10, category: 1, name: 'Special Diet Menu (On Request)', icon: faConciergeBell},
    {id: 11, category: 1, name: 'Room Service', icon: faConciergeBell},
    {id: 12, category: 1, name: 'Breakfast in Room', icon: faEgg},
    {id: 13, category: 1, name: 'Continental Breakfast', icon: faEgg},


    // Pool & Wellness

    {id: 14, category: 2, name: 'Swimming Pool', icon: faSwimmingPool},
    {id: 15, category: 2, name: 'Spa and Wellness Center', icon: faSpa},
    {id: 16, category: 2, name: 'Hot Tub/Jacuzzi', icon: faHotTub},
    {id: 17, category: 2, name: 'Fitness Center', icon: faDumbbell},
    {id: 18, category: 2, name: 'Hot Spring Bath', icon: faWater},

    // Parking

    {id: 19, category: 3, name: 'Valet Parking', icon: faConciergeBell},
    {id: 20, category: 3, name: 'Free Parking', icon: faParking},
    {id: 21, category: 3, name: 'Off-site Parking', icon: faParking},
    {id: 22, category: 3, name: 'Outdoor Parking', icon: faParking},
    {id: 23, category: 3, name: 'Secured Parking', icon: faParking},
    {id: 24, category: 3, name: 'Permit Parking', icon: faParking},
    {id: 25, category: 3, name: 'EV - Charging Point', icon: faChargingStation},

    // Transport
    {id: 26, category: 4, name: 'Bike Rental', icon: faMotorcycle},
    {id: 27, category: 4, name: 'Bicycle Rental', icon: faBicycle},
    {id: 28, category: 4, name: 'Car Hire', icon: faCar},
    {id: 29, category: 4, name: 'Airport Shuttle', icon: faShuttleVan},
    {id: 30, category: 4, name: 'Shuttle Service', icon: faShuttleVan},


    // Reception
    {id: 31, category: 5, name: '24-hour Front Desk', icon: faDesktop},
    {id: 32, category: 5, name: 'Private check-in/check-out', icon: faUserClock},
    {id: 33, category: 5, name: 'Private check-in/check-out', icon: faClock},
    {id: 34, category: 5, name: 'Concierge service', icon: faConciergeBell},
    {id: 35, category: 5, name: 'Travel desk', icon: faConciergeBell},
    {id: 36, category: 5, name: 'Currency exchange', icon: faMoneyBillAlt},
    {id: 37, category: 5, name: 'ATM/cash machine on site', icon: faMoneyBillWave},
    {id: 38, category: 5, name: 'Luggage storage', icon: faSuitcase},
    {id: 39, category: 5, name: 'Lockers', icon: faLock},
    {id: 40, category: 5, name: 'Safety deposit box', icon: faLock},
    {id: 41, category: 5, name: 'Newspapers', icon: faNewspaper},

    // Common Areas
    {id: 42, category: 6, name: 'Night Club/DJ', icon: faMusic},
    {id: 43, category: 6, name: 'Casino', icon: faChessKing},
    {id: 44, category: 6, name: 'Karaoke', icon: faMicrophone},
    {id: 45, category: 6, name: 'Kid\'s Club', icon: faChild},
    {id: 46, category: 6, name: 'Children\'s PlayGround', icon: faChild},
    {id: 47, category: 6, name: 'Babysitting/Child Care', icon: faChild},


    // Business Facilities
    {id: 48, category: 7, name: 'Meeting/Banquet Facility', icon: faPagelines},
    {id: 49, category: 7, name: 'Business Center', icon: faBusinessTime},
    {id: 50, category: 7, name: 'Fax/Photocopying', icon: faPrint},

    // Cleaning Service
    {id: 51, category: 8, name: 'Dry Cleaning', icon: faMitten},
    {id: 52, category: 8, name: 'Ironing Service', icon: faTshirt},
    {id: 53, category: 8, name: 'Laundry', icon: faTshirt},
    {id: 54, category: 8, name: 'Shoeshines', icon: faShoePrints},

    // Shops
    {id: 55, category: 9, name: 'Shops (on site)', icon: faStore},
    {id: 56, category: 9, name: 'Mini Market (on site)', icon: faStoreAlt},
    {id: 57, category: 9, name: 'Beauty Salon', icon: faChair},
    {id: 58, category: 9, name: 'Gift Shop', icon: faGift},

    // Shops
    {id: 59, category: 10, name: 'Allergy Free Room', icon: faBug},
    {id: 61, category: 10, name: 'Non Smoking Through', icon: faSmokingBan},
    {id: 62, category: 10, name: 'Designated Smoking Area', icon: faSmoking},
    {id: 63, category: 10, name: 'Non-Smoking Rooms', icon: faSmokingBan},
    {id: 64, category: 10, name: 'Wheelchair Friendly', icon: faWheelchair},
    {id: 65, category: 10, name: 'Elevator', icon: faBuilding},
    {id: 66, category: 10, name: 'Family Rooms', icon: faUsers},
    {id: 67, category: 10, name: 'Sound Proof Rooms', icon: faVolumeDown},
    {id: 68, category: 10, name: 'Bridal Suite', icon: faRing},
    {id: 69, category: 10, name: 'VIP Room', icon: faCouch},
    {id: 70, category: 10, name: 'Heating', icon: faFire},

    // Room Amenities
    {id: 71, category: 11, name: 'Wi-Fi', icon: faWifi},
    {id: 72, category: 11, name: 'Air Conditioning', icon: faWind},
    {id: 73, category: 11, name: 'Television', icon: faTv},
    {id: 74, category: 11, name: 'Iron & Iron Board', icon: faTshirt},
    {id: 75, category: 11, name: 'Mini Bar', icon: faGlassMartini},
    {id: 76, category: 11, name: 'Bathtub', icon: faBath},
    {id: 77, category: 11, name: 'Heating', icon: faFire},
    {id: 78, category: 11, name: 'Washing Machine', icon: faTshirt},
    {id: 79, category: 11, name: 'Hot Water', icon: faHotTub},
    {id: 80, category: 11, name: 'Coffee/Tea Maker', icon: faCoffee},
    {id: 81, category: 11, name: 'Bottle Water (Complimentary)', icon: faWineBottle},
    {id: 82, category: 11, name: 'Bathrobe', icon: faToiletPaper},
    {id: 83, category: 11, name: 'Alarm Clock', icon: faClock},
    {id: 84, category: 11, name: 'Refrigerator', icon: faSnowflake},
    {id: 85, category: 11, name: 'Microwave', icon: faWaveSquare},
    {id: 86, category: 11, name: 'Hair Dryer', icon: faStar},
    {id: 87, category: 11, name: 'Essentials', icon: faStar},
    {id: 88, category: 11, name: 'Smoke Alarm', icon: faSmog},
    {id: 89, category: 11, name: 'Kitchen', icon: faUtensils},
  ],
};