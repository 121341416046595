/**
 *
 * Payment Sub Payment Details
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
// Consts and Libs
import AppUtil from '../../../lib/util';
import AppAPI from '../../../lib/api';
import {Constants, Filters, Strings} from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FilterButton from '../../../components/dashboard/FilterButton';
import PageSubHeader from '../../../components/dashboard/PageSubHeader';
import {faCashRegister} from '@fortawesome/free-solid-svg-icons';
// Actions

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  payment: state.payment.payment,
});

// Any actions to map to the component?
const mapDispatchToProps = {};


/* Component ==================================================================== */
class PaymentSubPaymentsDetails extends Component {
  static componentName = 'PaymentSubPaymentsDetails';

  static propTypes = {
    match: PropTypes.object.isRequired,
    selectKey: PropTypes.string,
  };

  static defaultProps = {
    selectKey: 'sub-payments'
  }

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {selectKey} = this.props;
    this.setState({loading: true});

    AppAPI.payment.get(`${this.props.match.params.paymentId}/related-details/?key=${selectKey}`)
      .then((res) => {
        this.setState({
          response: res,
          loading: false,
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };


  render = () => {

    const {loading, error, response, refundStatus} = this.state;
    const {payment, property} = this.props;


    if (loading || _.isEmpty(payment) || _.isEmpty(response)) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    const {payments} = response;


    let itemList = payments;

    if (refundStatus || refundStatus === 0) {
      itemList = itemList.filter(item => (item.refund_status === refundStatus));
    }

    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Payment Sub Payments</title>
        </Helmet>

        <PageSubHeader icon={faCashRegister} title={'Sub Payment(s)'} description={'Sub payment(s) for the payment.'}/>

        <div className={'row'}>
          <div className={'col-md-4 col-12'}>
            <table className={'table table-borderless table-sm mb-3'}>
              <tbody>
                <tr>
                  <td className={'w-50 text-muted'}>Payment Total</td>
                  <td><strong>{`${payment.currency} ${payment.total}`}</strong></td>
                </tr>
                <tr>
                  <td className={'w-50 text-muted'}>Total Settled</td>
                  <td><strong>{`${payment.currency} ${payment.total_settled}`}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={'col-md-4 col-12'}>
            <table className={'table table-borderless table-sm mb-3'}>
              <tbody>
                <tr>
                  <td className={'w-50 text-muted'}>Net Payment</td>
                  <td><strong>{`${payment.currency} ${payment.total_available_fund}`}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={'col-md-4 col-12'}>
            <table className={'table table-borderless table-sm mb-3'}>
              <tbody>
              </tbody>
            </table>
          </div>
        </div>

        <div className={'spacer-20'}/>

        <div className={'row'}>
          <div className={'col-12 col-sm-9'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <FilterButton
                  title={'Refund Status'}
                  selectKey={refundStatus}
                  data={Filters.refundStatusValue}
                  action={(data) => this.setState({refundStatus: data.key})}
                  size={'sm'}
                />
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-3 text-center text-sm-right'}/>
        </div>

        <div className="table-responsive border">
          <table className={`table ${!_.isEmpty(itemList) && 'table-hover'} mb-0`}>
            <thead>
              <tr>
                <th className={'text-left'}>Reference</th>
                <th/>
                <th>Method</th>
                <th>Order</th>
                <th className={'text-left'}>Total</th>
                <th className={'text-left green-cl'}>Settled</th>
                <th className={'text-left'}>Fee</th>
                <th className={'text-left text-muted'}>Tax</th>
                <th className={'text-left blue-cl'}>Refunded</th>
              </tr>
            </thead>
            <tbody>
              {!_.isEmpty(itemList) ?
                <React.Fragment>
                  {itemList.map((data, i) => {
                    return (
                      <tr className={'tr-align-middle'} key={i}>
                        <td className={'data-table-cell-md'}>
                          <Link to={`/payment/${data.hash}`}>{data.ref_no}</Link><br/>
                          <p className={'small mb-0 text-muted'}>{AppUtil.formatDateTime(data.created)}</p>
                        </td>
                        <td className={'text-left data-table-cell-md'}>
                          <span className={`badge mr-2 ${AppUtil.paymentStatusColor(data.payment_status)}`}>
                            {data.payment_status_display}
                          </span>
                          <span className={`mr-2 badge ${AppUtil.settlementStatusColor(data.settlement_status)}`}>
                            {data.settlement_status_display}
                          </span>
                          {data.refund_status !== Constants.REFUND_STATUS.NOTPAID &&
                          <span className={`mr-2 badge ${AppUtil.paymentStatusColor(data.refund_status)}`}>
                            {data.refund_status_display}
                          </span>
                          }
                        </td>
                        <td className={'text-center data-table-cell-md'}>
                          {data.payment_method_display}
                          {!_.isEmpty(data.attributes) &&
                          <React.Fragment>
                            {data.attributes.pos_name && <p className={'mb-0 small text-muted'}>{data.attributes.pos_name}</p>}
                            {data.attributes.event_name && <p className={' mb-0 small text-muted'}>{data.attributes.event_name}</p>}
                            {data.attributes.booking_ota && <p className={'mb-0 small text-muted'}>{data.attributes.booking_ota}</p>}
                          </React.Fragment>
                          }
                        </td>
                        <td className={'text-center data-table-cell-md'}>
                          {(!_.isEmpty(data.attributes) && data.attributes.order_hash )&&
                          <Link to={`/order/${data.attributes.order_hash}/`}>
                            {data.attributes.order_ref_no}
                          </Link>
                          }
                        </td>
                        <td className={'text-left data-table-cell-md'}><strong>{`${data.currency} ${data.total}`}</strong></td>
                        <td className={'text-left data-table-cell-md green-cl'}><strong>{`${data.currency} ${data.total_settled}`}</strong></td>
                        <td className={'text-left data-table-cell-md'}><strong>{`${data.currency} ${data.settlement_difference}`}</strong></td>
                        <td className={'text-left data-table-cell-sm text-muted'}><strong>{`${data.settlement_fee_tax}`}</strong></td>
                        <td className={'text-left data-table-cell-md blue-cl'}><strong>{`${data.currency} ${data.total_refunded}`}</strong></td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={4}/>
                    <td className={'text-left'}>
                      <strong>{`${property.currency} ${itemList.reduce((a, b) => parseFloat(a) + parseFloat(b.total), 0).toFixed(2)}`}</strong>
                    </td>
                    <td className={'text-left green-cl'}>
                      <strong>{`${property.currency} ${itemList.reduce((a, b) => parseFloat(a) + parseFloat(b.total_settled), 0).toFixed(2)}`}</strong>
                    </td>
                    <td className={'text-left'}>
                      <strong>{`${property.currency} ${itemList.reduce((a, b) => parseFloat(a) + parseFloat(b.settlement_difference), 0).toFixed(2)}`}</strong>
                    </td>
                    <td className={'text-left text-muted'}>
                      <strong>{`${itemList.reduce((a, b) => parseFloat(a) + parseFloat(b.settlement_fee_tax), 0).toFixed(2)}`}</strong>
                    </td>
                    <td className={'text-left blue-cl'}>
                      <strong>{`${property.currency} ${itemList.reduce((a, b) => parseFloat(a) + parseFloat(b.total_refunded), 0).toFixed(2)}`}</strong>
                    </td>
                  </tr>
                </React.Fragment>
                :
                <tr>
                  <td colSpan={9}>
                    <div className={'text-center table-list-empty'}>
                      <h6><FontAwesomeIcon icon={faCashRegister} className={'mr-2'}/> No Sub Payment(s)</h6>
                      <p className={'text-muted'}>{Strings.paymentSubPaymentEmpty}</p>
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      response: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PaymentSubPaymentsDetails);