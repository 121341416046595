/**
 *
 * Payment Analytics : POS
 */
import _ from 'lodash';
import Moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Helmet from 'react-helmet';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import { Constants, Strings } from '../../../constants';
// Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
// Components
import Error from '../../../components/general/Error';
import PAOverview from './components/PAOverview';
import PAOverviewModel from './models/PAOverviewModel';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquare, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PaymentAnalyticsTerminals extends Component {
  static componentName = 'PaymentAnalyticsTerminals';

  static propTypes = {
    endDate: PropTypes.object,
    startDate: PropTypes.object
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {startDate, endDate, dateType} = this.props;
    this.setState({loading: true});
    const queryParams = {
      'date_type': dateType,
      'report_type': 'point_of_sales',
      'start_date': (startDate ? startDate : Moment()).format('YYYY-MM-DD'),
      'end_date' : endDate ? endDate.format('YYYY-MM-DD') : null
    };
    AppAPI.paymentapi.get(`payment-analytics/?${queryString.stringify(queryParams)}`)
      .then((res) => {
        this.setState({loading: false, response: res});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({loading: false, error: error});
      });
  };

  render = () => {
    const {loading, error, response} = this.state;
    const {property} = this.props;
    let pos = [];

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const paymentData  = {
      paymentStatusCount : {},
      paymentStatusTotal : {},
      settlementStatusCount: {},

      settlementStatusTotalPaid: {},
      settlementStatusTotalSettled: {},
      paidPaymentMethodCount: {},
      paidPaymentMethodTotal: {},
      paidPaymentMethodSettled: {},
      paidPaymentMethodSettledTotal: {},
      paidPaymentMethodNotSettled: {},
      paidPercentage: 0,
      settledPercentage: 0
    };

    const posData = {};
    const globalPaymentData = _.cloneDeep(paymentData);
    // Setting up container for Payment terminal level counts.
    if (!_.isEmpty(response) && !_.isEmpty(response.meta_data) ) {
      pos = response.meta_data.pos || [];
      if (!_.isEmpty(pos)) {
        pos.forEach((data)=>{
          posData[data.id] = _.cloneDeep(paymentData);
        });
      }
    }


    if (!_.isEmpty(response) && !_.isEmpty(response.data) ) {
      const responseData = response.data;
      // Payment Status
      if (!_.isEmpty(responseData.payment_status_pos_data)) {
        responseData.payment_status_pos_data.forEach((data)=>{
          // Check if terminal entry exist, if so enter value. [ Null value might not exist ]
          if (data.pos && posData[data.pos]){
            posData[data.pos].paymentStatusCount[data.payment_status] = data.count;
            posData[data.pos].paymentStatusTotal[data.payment_status] = data.total.toFixed(2);
          }
          AppUtil.insertOrAddObject(globalPaymentData.paymentStatusCount, data.payment_status, data.count);
          AppUtil.insertOrAddObject(globalPaymentData.paymentStatusTotal, data.payment_status, data.total, 'float');
        });
      }

      // Settlement Data
      if (!_.isEmpty(responseData.payment_paid_settlement_method_pos_data)) {
        responseData.payment_paid_settlement_method_pos_data.forEach((data)=>{
          // Check if pos entry exist, if so enter value. [ No case of null entry exist but in case ]
          if (data.pos && posData[data.pos]){

            AppUtil.insertOrAddObject(posData[data.pos].settlementStatusCount, data.settlement_status, data.count);
            AppUtil.insertOrAddObject(posData[data.pos].settlementStatusTotalPaid, data.settlement_status, data.total, 'float');
            AppUtil.insertOrAddObject(posData[data.pos].settlementStatusTotalSettled, data.settlement_status, data.total_settled, 'float');

            AppUtil.insertOrAddObject(posData[data.pos].paidPaymentMethodCount, data.payment_method, data.count);
            AppUtil.insertOrAddObject(posData[data.pos].paidPaymentMethodTotal, data.payment_method, data.total, 'float');
            if (data.settlement_status === Constants.SETTLEMENT_STATUS.SETTLED) {
              AppUtil.insertOrAddObject(posData[data.pos].paidPaymentMethodSettledTotal, data.payment_method, data.total, 'float');
              AppUtil.insertOrAddObject(posData[data.pos].paidPaymentMethodSettled, data.payment_method, data.total_settled, 'float');
            } else {
              AppUtil.insertOrAddObject(posData[data.pos].paidPaymentMethodNotSettled, data.payment_method, data.total_settled, 'float');
            }
          }

          // Global Data collection
          AppUtil.insertOrAddObject(globalPaymentData.settlementStatusCount, data.settlement_status, data.count);
          AppUtil.insertOrAddObject(globalPaymentData.settlementStatusTotalPaid, data.settlement_status, data.total, 'float');
          AppUtil.insertOrAddObject(globalPaymentData.settlementStatusTotalSettled, data.settlement_status, data.total_settled, 'float');

          AppUtil.insertOrAddObject(globalPaymentData.paidPaymentMethodCount, data.payment_method, data.count);
          AppUtil.insertOrAddObject(globalPaymentData.paidPaymentMethodTotal, data.payment_method, data.total, 'float');
          if (data.settlement_status === Constants.SETTLEMENT_STATUS.SETTLED) {
            AppUtil.insertOrAddObject(globalPaymentData.paidPaymentMethodSettledTotal, data.payment_method, data.total, 'float');
            AppUtil.insertOrAddObject(globalPaymentData.paidPaymentMethodSettled, data.payment_method, data.total_settled, 'float');
          } else {
            AppUtil.insertOrAddObject(globalPaymentData.paidPaymentMethodNotSettled, data.payment_method, data.total_settled, 'float');
          }
        });
      }
    }

    // Payment status contains cancelled payments also so subtract it to get active payments.
    const paidPayments = (AppUtil.sumArray(Object.values(globalPaymentData.paymentStatusTotal), 'float') - (globalPaymentData.paymentStatusTotal[Constants.PAYMENT_STATUS.CANCELLED] || 0)).toFixed(2);
    const paidPaymentsCount = AppUtil.sumArray(Object.values(globalPaymentData.paymentStatusCount)) - (globalPaymentData.paymentStatusCount[Constants.PAYMENT_STATUS.CANCELLED] || 0);

    const paidPaymentPercentage = AppUtil.calculatePercentage(
      (globalPaymentData.paymentStatusTotal[Constants.PAYMENT_STATUS.PAID] || 0),
      paidPayments
    );

    return (
      <div className="screen-container">
        <Helmet>
          <title>Payment Analytics : Point of Sales</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={'Point of Sales'}
          description={'Incoming payments & bifurcation based on point of sales.'}
        />

        <div className={'row'}>
          <div className={'col-12 col-lg-6'}>
            <div className={'card mb-5'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col-6'}>
                    <p className={'text-muted mb-1'}>Total Active Payment(s)</p>
                    <h4 className={'card-title mb-2'}>
                      {`${property.currency} ${paidPayments}`}
                    </h4>
                    <p className={'text-muted mb-3'}>
                      {paidPaymentsCount} payment entries.
                    </p>
                  </div>
                  <div className={'col-6'}>
                    <p className={'text-muted mb-1'}>Cancelled</p>
                    <h4 className={'grey-cl mb-2'}>
                      {`${property.currency} ${globalPaymentData.paymentStatusTotal[Constants.PAYMENT_STATUS.CANCELLED] || 0}`}
                    </h4>
                    <p className={'text-muted mb-3'}>
                      {globalPaymentData.paymentStatusCount[Constants.PAYMENT_STATUS.CANCELLED] || 0} payment entries.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'col-12 col-lg-6'}>
            <div className={'card mb-5'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col '}>
                    <p className={'text-muted mb-1'}>Total Paid</p>
                    <h5 className={'card-title mb-0 pb-0 green-cl'}>
                      {`${property.currency} ${globalPaymentData.paymentStatusTotal[Constants.PAYMENT_STATUS.PAID] || 0}`}
                    </h5>
                  </div>
                  <div className={'col'}>
                    <p className={'text-muted mb-1'}>Pending Payment</p>
                    <h5 className={'card-title mb-0 pb-0 red-cl'}>
                      {`${property.currency} ${globalPaymentData.paymentStatusTotal[Constants.PAYMENT_STATUS.NOTPAID] || 0}`}
                    </h5>
                  </div>
                </div>
                <div className="progress mt-3 red-bg">
                  <div
                    className="progress-bar green-bg"
                    style={{width: `${paidPaymentPercentage}%`}}
                    aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"/>
                </div>
                <div className={'row'}>
                  <div className={'col-8'}>
                    <p className={'text-muted mb-0 mt-1 small'}>
                      Count - Paid : {globalPaymentData.paymentStatusCount[Constants.PAYMENT_STATUS.PAID] || 0} |
                      Pending : {globalPaymentData.paymentStatusCount[Constants.PAYMENT_STATUS.NOTPAID] || 0}
                    </p>
                  </div>
                  <div className={'text-right col'}>
                    <p className={'text-muted mb-0 mt-1 small'}>{paidPaymentPercentage}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="table-responsive">
          <table className={'table table-bordered mb-3'}>
            <thead>
              <tr>
                <th>Point of Sales</th>
                <th>Count</th>
                <th>Total <small>({property.currency})</small></th>
                <th>Pending <small>({property.currency})</small></th>
                <th>Paid <small>({property.currency})</small></th>
                <th>Settled <small>({property.currency})</small></th>
                <th>Pending Sett <small><small>({property.currency})</small></small></th>
              </tr>
            </thead>
            <tbody>
              {_.isEmpty(pos)
                ? (
                  <tr>
                    <td colSpan={8}>
                      <div className={'text-center table-list-empty'}>
                        <h4><FontAwesomeIcon icon={faMoneyBillWave}/> No Point of Sales(s) </h4>
                        {Strings.paymentPOSListEmpty}
                      </div>
                    </td>
                  </tr>
                )
                : (
                  <>
                    {pos.map((data, i)=>(
                      <tr className={'tr-align-middle'} key={i}>
                        <td className={'data-table-cell-lg'}>
                          <p className={'mb-0'}>
                            <FontAwesomeIcon
                              onClick={()=>this.setState({
                                title: `Payment Overview : ${data.name}`,
                                showPAOverview: true,
                                showPAOverviewData:posData[data.id]
                              })}
                              icon={faExternalLinkSquare}
                              className={'mr-2 a-class'}
                            />
                            <strong>{data.name}</strong>
                          </p>
                        </td>
                        <td className={'data-table-cell-xs text-center'}>
                          {AppUtil.sumArray(Object.values(posData[data.id].paymentStatusCount)) - (posData[data.id].paymentStatusCount[Constants.PAYMENT_STATUS.CANCELLED] || 0) }
                        </td>
                        <td className={'data-table-cell-md text-center'}>
                          {(AppUtil.sumArray(Object.values(posData[data.id].paymentStatusTotal)) - (posData[data.id].paymentStatusTotal[Constants.PAYMENT_STATUS.CANCELLED] || 0)).toFixed('2')}
                        </td>
                        <td className={'data-table-cell-md text-center'}>
                          {posData[data.id].paymentStatusTotal[Constants.PAYMENT_STATUS.NOTPAID] || 0}
                        </td>
                        <td className={'data-table-cell-md text-center'}>
                          {posData[data.id].paymentStatusTotal[Constants.PAYMENT_STATUS.PAID] || 0}
                        </td>
                        <td className={'data-table-cell-md text-center'}>
                          {posData[data.id].settlementStatusTotalSettled[Constants.SETTLEMENT_STATUS.SETTLED] || 0}
                        </td>
                        <td className={'data-table-cell-md text-center'}>
                          {posData[data.id].settlementStatusTotalSettled[Constants.SETTLEMENT_STATUS.NOT_SETTLED] || 0}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td><strong>Total</strong></td>
                      <td className={'text-center'}><strong>
                        {AppUtil.sumArray(Object.values(globalPaymentData.paymentStatusCount)) - (globalPaymentData.paymentStatusCount[Constants.PAYMENT_STATUS.CANCELLED] || 0 )}
                      </strong></td>
                      <td className={'text-center'}><strong>
                        {(AppUtil.sumArray(Object.values(globalPaymentData.paymentStatusTotal)) - (globalPaymentData.paymentStatusTotal[Constants.PAYMENT_STATUS.CANCELLED] || 0 )).toFixed(2)}
                      </strong></td>
                      <td className={'text-center'}><strong>
                        {globalPaymentData.paymentStatusTotal[Constants.PAYMENT_STATUS.NOTPAID] || 0}
                      </strong></td>
                      <td className={'text-center'}><strong>
                        {globalPaymentData.paymentStatusTotal[Constants.PAYMENT_STATUS.PAID] || 0}
                      </strong></td>
                      <td className={'text-center'}><strong>
                        {globalPaymentData.settlementStatusTotalSettled[Constants.SETTLEMENT_STATUS.SETTLED] || 0}
                      </strong></td>
                      <td className={'text-center'}><strong>
                        {globalPaymentData.settlementStatusTotalSettled[Constants.SETTLEMENT_STATUS.NOT_SETTLED] || 0}
                      </strong></td>
                    </tr>
                  </>
                )
              }
            </tbody>
          </table>
        </div>

        <hr className={'mt-3'}/>

        <PAOverview property={property} paymentData={globalPaymentData}/>


        <PAOverviewModel
          property={property}
          title={this.state.title}
          show={this.state.showPAOverview}
          showPAOverview={this.state.showPAOverview}
          paymentData={this.state.showPAOverviewData}
          onHide={()=>this.setState({showPAOverview: false, showPAOverviewData: {}, title: null})}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      showPAOverview: false,
      showPAOverviewData: {},
      startDate: Moment().startOf('day'),
      endDate: Moment().add(30, 'days'),
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentAnalyticsTerminals);
