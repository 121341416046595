/**
 *
 * Payment Link Details
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
// Consts and Libs
import AppUtil from '../../../lib/util';
import AppAPI from '../../../lib/api';
import {Constants, Strings} from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {
  faCreditCard,
  faExternalLinkAlt,
  faHourglassHalf,
  faPaperPlane,
  faSync
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PGatewayInfo from '../PGateway/Components/PGatewayInfo';
import PGatewayAction from '../PGateway/Components/PGatewayAction';
import PageSubHeader from '../../../components/dashboard/PageSubHeader';
import {Alerts} from '../../../components/ui';
import PGatewayRefund from '../models/PGatewayRefund';
import MessagingManager from '../../messaging/MessagingManager';
import PGatewayPaymentUpdate from '../models/PGatewayPaymentUpdate';
import PGatewayRefundUpdate from '../models/PGatewayRefundUpdate';
// Actions
import * as PaymentActions from '../../../redux/payment/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  payment: state.payment.payment,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPayment: PaymentActions.setPayment,
};


/* Component ==================================================================== */
class PaymentLinkDetails extends Component {
  static componentName = 'PaymentLinkDetails';

  static propTypes = {
    match: PropTypes.object.isRequired,
    selectKey: PropTypes.string,
  };

  static defaultProps = {
    selectKey: 'pgateway'
  }

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = (reloadPayment = false) => {
    const {selectKey} = this.props;
    this.setState({loading: true});

    AppAPI.payment.get(`${this.props.match.params.paymentId}/related-details/?key=${selectKey}`)
      .then((res) => {
        this.setState({response: res, loading: false});
        if (reloadPayment) {
          this.props.setPayment(this.props.payment.hash);
        }
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };

  cacheReset = (pgateway) => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.pgateway
        .post(`${pgateway}/cache-reset/`, {})
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}, response: {pgateway: res}}, () => {
            setTimeout(() => (this.setState({resultMsg: {success: ''}})), 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({loading: false, resultMsg: {error}});
        });
    });
  };

  render = () => {

    const {loading, error, response, resultMsg} = this.state;
    const {payment} = this.props;


    if (loading || _.isEmpty(payment)) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    const {pgateway} = response;


    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Payment Link</title>
        </Helmet>

        <PageSubHeader
          icon={faExternalLinkAlt} title={'Payment Link'}
          description={'Online Payment link for the payment.'}
        />

        {_.isEmpty(response) ?
          <table className={'table border'}>
            <tbody>
              <tr>
                <td colSpan={9}>
                  <div className={'text-center table-list-empty'}>
                    <h6>
                      <FontAwesomeIcon icon={faHourglassHalf} className={'mr-2'}/> Payment Link :
                      <span className={`ml-2 badge ${AppUtil.fetchStatusColor(payment.pgateway_status)}`}>
                        {payment.pgateway_status_display}
                      </span>
                    </h6>
                    <p className={'text-muted'}>
                      {payment.pgateway_status === Constants.FETCH_STATUS.FAILED ? Strings.paymentLinkError : Strings.paymentLinkLoading}
                      {payment.pgateway_status_message && ` ${payment.pgateway_status_message}`}
                    </p>
                    <p>
                      <button
                        onClick={() => this.fetchInitData()}
                        className={'btn btn-outline-success btn-sm'}
                        disabled={payment.pgateway_status === Constants.FETCH_STATUS.FAILED}
                      >
                        <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                      </button>
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          :
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <PGatewayInfo pgateway={pgateway}/>
              <PGatewayAction pgateway={pgateway} action={(data)=>this.setState({response: {pgateway: data}})}/>
              <Alerts
                status={resultMsg.status}
                success={resultMsg.success}
                error={resultMsg.error}
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <ul className="list-group mb-5">
                <li className="list-group-item align-items-center">
                  <button onClick={() => this.setState({showMessagingManager: true})} className="btn btn-link m-0 p-0">
                    <FontAwesomeIcon icon={faPaperPlane} size={'sm'} className={'mr-2'}/> Notification ( E-Mail / SMS )
                  </button>
                </li>
              </ul>
              <ul className="list-group mb-5">
                <li
                  className="list-group-item d-flex justify-content-between align-items-center"
                  onClick={() => this.cacheReset(pgateway.hash)}
                >
                  <small>Payment Actions</small>
                </li>
                <li className="list-group-item align-items-center">
                  <button
                    disabled={pgateway.gateway_unique_id}
                    onClick={() => this.setState({showPGatewayPaymentUpdate: true})}
                    className="btn btn-link m-0 p-0"
                  >
                    <FontAwesomeIcon icon={faCreditCard} size={'sm'} className={'mr-2'}/> Manual Update Payment
                  </button>
                  <p className={'small text-muted mb-0'}>Admin permission required.</p>
                </li>
                <li className="list-group-item align-items-center">
                  <button
                    disabled={!pgateway.allow_refund}
                    onClick={() => this.setState({showPGatewayRefund: true})}
                    className="btn btn-link m-0 p-0"
                  >
                    <FontAwesomeIcon icon={faCreditCard} size={'sm'} className={'mr-2'}/> Issue Refund
                  </button>
                  <p className={'small text-muted mb-0'}>Manager permission required.</p>
                </li>
                {pgateway.allow_refund &&
                <li className="list-group-item align-items-center">
                  <button
                    onClick={() => this.setState({showPGatewayRefundUpdate: true})}
                    className="btn btn-link m-0 p-0"
                  >
                    <FontAwesomeIcon icon={faCreditCard} size={'sm'} className={'mr-2'}/> Manual Update Refund
                  </button>
                  <p className={'small text-muted mb-0'}>Admin permission required.</p>
                </li>
                }
              </ul>
            </div>
          </div>
        }
        {/* Payment Update */}
        <PGatewayPaymentUpdate
          payment={payment}
          pgateway={pgateway}
          show={this.state.showPGatewayPaymentUpdate}
          onHide={() => this.setState({showPGatewayPaymentUpdate: false})}
          updateResponse={() => this.fetchInitData(true)}
        />
        {/* Refund Update */}
        <PGatewayRefundUpdate
          payment={payment}
          pgateway={pgateway}
          show={this.state.showPGatewayRefundUpdate}
          onHide={() => this.setState({showPGatewayRefundUpdate: false})}
          updateResponse={() => this.fetchInitData(true)}
        />
        {/* PGatewayRefund */}
        <PGatewayRefund
          payment={payment}
          pgateway={pgateway}
          show={this.state.showPGatewayRefund}
          onHide={() => this.setState({showPGatewayRefund: false})}
          updateResponse={() => this.fetchInitData(true)}
        />
        {/* Messaging */}
        <MessagingManager
          onHide={() => this.setState({showMessagingManager: false})}
          show={this.state.showMessagingManager}
          property={this.props.property}
          guests={payment.guest ? [payment.guest] : null}
          payment={payment}
        />
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      showPGatewayRefund: false,
      showPGatewayPaymentUpdate: false,
      response: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PaymentLinkDetails);