/**
 * Order Service Charge
 *  Order Service charge Edit
 *
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Consts and Libs
import AppUtil from '../../../lib/util';
// Actions
import {Input} from 'react-formik-ui';


/* Component ==================================================================== */
class OrderServiceChargeEdit extends Component {
  static componentName = 'OrderServiceChargeEdit';

  static propTypes = {
    formikProps: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
  };

  static defaultProps = {
    title: '',
  }

  render = () => {
    const {formikProps, order}  = this.props;
    return (
      <React.Fragment>
        <div className="form-row">
          <div className="col-6">
            <Input
              name="order_service_charge_value"
              label={'Service Charge (%)'}
              className={'form-control'}
            />
          </div>
          <div className="col-6">
            <Input
              name="service_charge"
              label={`Value (${order.currency})`}
              className={'form-control'}
              value={AppUtil.calculateXPercentage(order.sub_total_billed, formikProps.values.order_service_charge_value)}
              disabled={true}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };
}

/* Export Component ==================================================================== */
export default (OrderServiceChargeEdit);

