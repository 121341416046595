/**
 *
 * Guest Details
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link, Redirect, Route, Switch} from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants} from '../../../constants';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PageHeader from '../../../components/dashboard/PageHeader';
import ProfileDetails from './ProfileDetails';
import ProfileManage from '../ProfileManage';
import NotFound from '../../NotFound';
import OrderListIndex from '../../order/OrderListIndex';
import RefundListIndex from '../../refund/RefundListIndex';
import ExpenseListIndex from '../../expense/ExpenseListIndex';
import PaymentListIndex from '../../payment/PaymentListIndex';
import BookingListIndex from '../../booking/BookingListIndex';
import BookingRoomListIndex from '../../bookingroom/BookingRoomListIndex';
import ProfileDocumentList from './ProfileDocumentList';
import NavigationTabs from '../../../components/dashboard/NavigationTabs';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import POSOrderListIndex from '../../pos/POSOrderListIndex';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// Actions
import * as GuestActions from '../../../redux/guest/actions';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  guest: state.guest.guest,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setGuest: GuestActions.setGuest,
};

/* Component ==================================================================== */
class GuestDetails extends Component {
  static componentName = 'GuestDetails';
  static propTypes = {
    isGuest: PropTypes.bool,
    isAgent: PropTypes.bool,
    isVendor: PropTypes.bool,
    settings: PropTypes.bool,
    isCorporate: PropTypes.bool,
    match: PropTypes.object.isRequired,
  };


  static defaultProps = {
    settings: false,
  };

  componentDidMount = () => {
    this.setState({
      loading: false,
    });
  };

  fetchInitData = () => {
    this.setState({
      loading: true,
    }, () => {
      this.props.setGuest(this.props.match.params.guestId)
        .then(() => {
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    });

  };

  render = () => {

    const {loading, error} = this.state;
    const {guest, settings, property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    let baseUrl;
    let title = 'Guest', profile_type = Constants.PROFILE_TYPE.GUEST;

    if (guest.profile_type === Constants.PROFILE_TYPE.CORPORATE) {
      title = 'Corporate';
      profile_type = Constants.PROFILE_TYPE.CORPORATE;
      baseUrl = '/customer/corporate';
    } else if (guest.profile_type === Constants.PROFILE_TYPE.AGENT) {
      title = 'Agent';
      profile_type = Constants.PROFILE_TYPE.AGENT;
      baseUrl = '/customer/agent';
    } else if (guest.profile_type === Constants.PROFILE_TYPE.VENDOR) {
      title = 'Vendor';
      profile_type = Constants.PROFILE_TYPE.AGENT;
      baseUrl = '/settings/expense/vendor';
    } else {
      baseUrl = '/customer/guest';
    }

    if (settings) {
      baseUrl = `/settings/${baseUrl}/`;
    }


    let absBaseUrl = `${baseUrl}/${guest.hash}`;
    let subHeader = false;
    const menu = [
      {
        title: 'Profile Details',
        link: `${absBaseUrl}/`,
        isExact: true,
      },
      {
        title: 'Documents',
        link: `${absBaseUrl}/documents/`,
      },
    ];

    if (guest.profile_type !== Constants.PROFILE_TYPE.VENDOR) {
      menu.push(
        {
          title: 'Bookings',
          link: `${absBaseUrl}/booking/list/`,
        },
        {
          title: 'Orders',
          link: `${absBaseUrl}/order/list/`,
        },
        {
          title: 'Payments',
          link: `${absBaseUrl}/payment/list/`,
        },
        {
          title: 'Refunds',
          link: `${absBaseUrl}/refund/list/`,
        },
      );
    }

    if (guest.profile_type === Constants.PROFILE_TYPE.GUEST) {
      menu.push(
        {
          title: 'Previous Stays',
          link: `${absBaseUrl}/booking-room/list/`,
        },
      );
    }

    if (guest.profile_type === Constants.PROFILE_TYPE.VENDOR) {
      menu.push(
        {
          title: 'Expenses',
          link: `${absBaseUrl}/expense/list/`,
        },
      );
      subHeader= true;
    }

    // if (guest.profile_type === Constants.PROFILE_TYPE.CORPORATE && guest.profile_pos) {
    //   menu.push(
    //     {
    //       title: 'POS Order',
    //       link: `${absBaseUrl}/pos-order/list/`,
    //     },
    //   );
    // }

    return (
      <div className="guest-details screen-container ">
        <Helmet>
          <title>{guest.name} | Details</title>
        </Helmet>

        {baseUrl &&
         <Link to={baseUrl} className={'btn btn-outline-dark mr-2 btn-sm mt-2'}>
           <FontAwesomeIcon icon={faArrowLeft} size={'sm'}/> {`${title} List`}
         </Link>
        }

        <PageHeader
          title={`${title} Details`}
          description={`Settings & Configuration for ${title} :  ${guest.name}`}
          history={this.props.history}
          subHeader={subHeader}
          border={false}
        />

        <NavigationTabs navItems={menu} classNameContainer={'mb-4'}/>

        <Switch>

          <Route
            path={`${baseUrl}/:guestId/`} exact
            render={(props) =>
              <ProfileDetails
                {...props}
                guest={guest}
                editControl={true}
                property={property}
                baseUrl={absBaseUrl}
                reloadData={this.fetchInitData}
              />}
          />

          {/*Edit Views*/}
          <Route
            path={`${baseUrl}/:guestId/edit/`}
            render={(props) =>
              <ProfileManage
                {...props}
                isAgent={profile_type === Constants.PROFILE_TYPE.AGENT}
                isCorporate={profile_type === Constants.PROFILE_TYPE.CORPORATE}
                isGuest={profile_type === Constants.PROFILE_TYPE.GUEST}
                isVendor={profile_type === Constants.PROFILE_TYPE.VENDOR}
                hideHeader={true}
              />
            }
          />

          {/* Booking List */}
          <Route
            exact
            path={`${baseUrl}/:guestId/documents/`}
            render={(props) =>
              <ProfileDocumentList
                {...props}
                guest={guest}
                editControl={true}
                reloadData={this.fetchInitData}
              />
            }
          />


          {/* Booking List */}
          <Route
            exact
            path={`${baseUrl}/:guestId/booking-room/`}
            render={() => (<Redirect to={`${absBaseUrl}/booking-room/list`}/>)}
          />
          <Route
            path={`${baseUrl}/:guestId/booking-room/list`}
            render={(props) =>
              <BookingRoomListIndex
                {...props}
                hideHeader={true}
                baseUrl={absBaseUrl}
                guestId={profile_type === Constants.PROFILE_TYPE.GUEST ? guest.id : null}
                corporateId={profile_type !== Constants.PROFILE_TYPE.GUEST ? guest.id : null}
              />}
          />


          {/* Booking List */}
          <Route
            exact
            path={`${baseUrl}/:guestId/booking/`}
            render={() => (<Redirect to={`${absBaseUrl}/booking/list`}/>)}
          />
          <Route
            path={`${baseUrl}/:guestId/booking/list`}
            render={(props) =>
              <BookingListIndex
                {...props}
                hideHeader={true}
                baseUrl={absBaseUrl}
                guestId={profile_type === Constants.PROFILE_TYPE.GUEST ? guest.id : null}
                corporateId={profile_type !== Constants.PROFILE_TYPE.GUEST ? guest.id : null}
              />}
          />


          {/* Order List */}
          <Route
            exact
            path={`${baseUrl}/:guestId/order/`}
            render={() => (<Redirect to={`${absBaseUrl}/order/list`}/>)}
          />
          <Route
            path={`${baseUrl}/:guestId/order/list`}
            render={(props) =>
              <OrderListIndex
                {...props}
                hideHeader={true}
                baseUrl={absBaseUrl}
                guestId={profile_type === Constants.PROFILE_TYPE.GUEST ? guest.id : null}
                corporateId={profile_type !== Constants.PROFILE_TYPE.GUEST ? guest.id : null}
              />}
          />


          {/* Payments List */}
          <Route
            exact
            path={`${baseUrl}/:guestId/payment/`}
            render={() => (<Redirect to={`${absBaseUrl}/payment/list`}/>)}
          />
          <Route
            path={`${baseUrl}/:guestId/payment/list`}
            render={(props) =>
              <PaymentListIndex
                {...props}
                hideHeader={true}
                baseUrl={absBaseUrl}
                guestId={profile_type === Constants.PROFILE_TYPE.GUEST ? guest.id : null}
                corporateId={profile_type !== Constants.PROFILE_TYPE.GUEST ? guest.id : null}
              />}
          />


          {/* Refund List */}
          <Route
            exact
            path={`${baseUrl}/:guestId/refund/`}
            render={() => (<Redirect to={`${absBaseUrl}/refund/list`}/>)}
          />
          <Route
            path={`${baseUrl}/:guestId/refund/list`}
            render={(props) =>
              <RefundListIndex
                {...props}
                hideHeader={true}
                baseUrl={absBaseUrl}
                guestId={profile_type === Constants.PROFILE_TYPE.GUEST ? guest.id : null}
                corporateId={profile_type !== Constants.PROFILE_TYPE.GUEST ? guest.id : null}
              />}
          />

          {/* Expense List */}
          <Route
            exact
            path={`${baseUrl}/:guestId/expense/`}
            render={() => (<Redirect to={`${absBaseUrl}/expense/list`}/>)}
          />
          <Route
            path={`${baseUrl}/:guestId/expense/list`}
            render={(props) =>
              <ExpenseListIndex
                {...props}
                hideHeader={true}
                baseUrl={absBaseUrl}
                vendorId={guest.id}
              />}
          />

          {/* POS Order */}
          <Route
            exact
            path={`${baseUrl}/:guestId/pos-order/`}
            render={() => (<Redirect to={`${absBaseUrl}/pos-order/list`}/>)}
          />
          <Route
            path={`${absBaseUrl}/pos-order/list`}
            render={(props) =>
              <POSOrderListIndex
                {...props}
                hideHeader={true}
                baseUrl={absBaseUrl}
                corporateId={guest.id}
              />
            }
          />
          <Route exact component={NotFound}/>
        </Switch>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuestDetails);