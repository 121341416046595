/**
 *
 * Room Type Packages List
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../../../lib/api';
import Strings from '../../../../constants/strings';
// Components
import Loading from '../../../../components/general/Loading';
import Error from '../../../../components/general/Error';
import ListView from '../../../../components/general/ListView';
import PageHeader from '../../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faPlus, faShoppingBasket, faTimesCircle} from '@fortawesome/free-solid-svg-icons';

// Actions

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  roomType: state.roomType.roomType,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class RoomTypeList extends Component {
  static componentName = 'RoomList';
  static propTypes = {
    refreshable: PropTypes.bool,
    roomSettings: PropTypes.bool,
    match: PropTypes.object.isRequired,
  };


  fetchRoomType = (page = 1, callback) => {
    AppAPI.roomtypepackage.get('?page=' + page + ((this.props.match.params.roomTypeId ? '&room_type=' + this.props.match.params.roomTypeId : '')))
      .then((res) => {
        this.setState({
          loading: false,
          error: null,
        });
        if (res.results.length > 0) {
          if (res.next) {
            callback(res.results);
          } else {
            callback(res.results, {
              allLoaded: true, // the end of the list is reached
            });
          }
        } else {
          callback();
        }
      })
      .catch((err) => {
        callback(null, {
          allLoaded: true, // the end of the list is reached
        });
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={9}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faShoppingBasket}/> No Packages </h4>
            {Strings.roomTypePackageEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };

  renderRow = (data, key) => {
    return (
      <tr key={key}>
        <td>
          <Link to={`/settings/inventory/room-type/${data.room_type_id}/package/${data.id}/`}>
            {data.name}
          </Link>
        </td>
        <td>{data.attributes ? data.attributes.room_type_name : 'Nil'}</td>
        <td>{data.currency} {data.base_price_effective}</td>
        <td>{data.child_price_effective}</td>
        <td>{data.infant_price_effective}</td>
        <td>{data.channel_manager_package_id}</td>
        <td>
          <FontAwesomeIcon
            icon={data.channel_manager_ok ? faCheckCircle : faTimesCircle}
            className={data.channel_manager_ok ? 'green-cl' : 'red-cl'}
          />
        </td>
        <td>
          <FontAwesomeIcon
            icon={data.booking_engine_ok ? faCheckCircle : faTimesCircle}
            className={data.booking_engine_ok ? 'green-cl' : 'red-cl'}
          />
        </td>
        <td>
          <FontAwesomeIcon
            icon={data.active ? faCheckCircle : faTimesCircle}
            className={data.active ? 'green-cl' : 'red-cl'}
          />
        </td>
      </tr>
    );
  };
  render = () => {

    const {loading, error} = this.state;
    const {property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="room-type-list screen-container ">
        <Helmet>
          <title>Room Types Packages</title>
        </Helmet>
        {!this.props.match.params.roomTypeId &&
         <PageHeader
           subHeader={true} history={this.props.history} title={'Room Type Package(s)'}
           description={`Packages in ${property.name}`}
         />
        }
        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12 mb-5">
            <table className={'table border'}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Room Type</th>
                  <th>Base</th>
                  <th>Child</th>
                  <th>Infant</th>
                  <th>Code</th>
                  <th>CM<sup>*</sup></th>
                  <th>BE<sup>*</sup></th>
                  <th>Active</th>
                </tr>
              </thead>
              <tbody>
                <ListView
                  rowView={this.renderRow}
                  onFetch={this.fetchRoomType}
                  firstLoader={true}
                  pagination
                  emptyView={this.emptyView}
                  class={'list-group'}
                  colSpan={9}
                />
              </tbody>
            </table>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className={'list-inline'}>
              <li className={'list-inline-item'}><strong>CM</strong> : Channel Manager</li>
              <li className={'list-inline-item'}><strong>BE</strong> : Booking Engine</li>
            </ul>

            <ul className="list-group">
              {this.props.match.params.roomTypeId &&
               <li className="list-group-item d-flex justify-content-between align-items-center">
                 <Link
                   to={'/settings/inventory/room-type/' + this.props.match.params.roomTypeId + '/package/new'}
                   className={'btn btn-link p-0'}>
                   <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Package
                 </Link>
               </li>
              }
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomTypeList);