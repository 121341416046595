/**
 * POS Order Item Details View
 *  manages order items
 */
import _ from 'lodash';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import { ErrorMessages, Strings } from '../../../constants';
// Components
import {Formik} from 'formik';
import { Form, Input, Textarea } from 'react-formik-ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from '../../../components/general/ConfirmAlert';

import OrderItemDetails from '../../order/components/OrderItemDetails';
import OrderDiscountEdit from '../../order/components/OrderDiscountEdit';
import OrderNoChargeEdit from '../../order/components/OrderNoChargeEdit';
import SelectorButton from '../../../components/dashboard/SelectorButton';
import OrderComplimentaryEdit from '../../order/components/OrderComplimentaryEdit';

/* Component ==================================================================== */

class POSOrderItemDetailsView extends Component {
  static componentName = 'POSOrderItemDetailsView';

  static propTypes = {
    posOrder: PropTypes.object,
    posDetails: PropTypes.object,
    POSOrderItem: PropTypes.object,
    updateResponse: PropTypes.func,
    onHide: PropTypes.func.isRequired,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
        editViewType: 'quantity',
      });
    }
    return true;
  }

  updatePosOrder = (data) => {
    const {posDetails, posOrder, POSOrderItem} = this.props;

    let baseUrl = 'pos-order-item-escalated';
    let payload = {};

    switch (this.state.editViewType) {
    case 'discount':
      payload = {
        discount: data.discount,
        discount_type: data.discount_type,
        discount_description: data.discount_description,
      };
      if (data.percentage === 'true') {
        payload.discount = parseFloat((data.discount * POSOrderItem.total) / 100).toFixed(2);
      }
      break;
    case 'nocharge':
      payload = {
        no_charge_description: data.no_charge_description,
        no_charge_type: data.no_charge_type,
        no_charge: data.no_charge,
      };
      break;
    case 'complimentary':
      payload = {
        complimentary_description: data.complimentary_description,
        complimentary_type: data.complimentary_type,
        complimentary: data.complimentary,
      };
      break;
    default:
      payload = {
        'quantity': data.quantity,
        'sub_quantity': data.sub_quantity,
        'additional_description': data.additional_description,
      };
      baseUrl = 'pos-order-item';
    }

    if (!_.isEmpty(POSOrderItem) && POSOrderItem.lock) {
      baseUrl = 'pos-order-item-escalated';
    }

    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.posapi.patch(`${baseUrl}/${POSOrderItem.id}/?pos=${posDetails.hash}&order=${posOrder.hash}`, payload)
          .then(() => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              this.props.updateResponse(posOrder.hash, posDetails.hash);
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                this.props.onHide();
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  }

  render = () => {

    const {loading, error, resultMsg, editViewType} = this.state;
    const {posOrder, POSOrderItem, show} = this.props;

    if (!loading && error) return <Error full={true} text={ErrorMessages.initData}/>;

    if (!show) return <React.Fragment/>;

    const viewButtonList = [
      {
        title: 'Quantity',
        key: 'quantity',
      },
      {
        title: 'Discount',
        key: 'discount',
      },
      {
        title: 'Complimentary',
        key: 'complimentary',
      },
      {
        title: 'No Charge',
        key: 'nocharge',
      },
    ];

    let quickForm, validationRules;

    switch (editViewType) {
    case 'nocharge':
      validationRules = {
        no_charge_description: Yup.string().max(200, 'Too Long!').nullable(),
        no_charge_type: Yup.number().required(),
      };
      quickForm = (formikProps) => <OrderNoChargeEdit order={posOrder} formikProps={formikProps}/>;
      break;
    case 'discount':
      validationRules = {
        discount: Yup.number().required(),
        discount_description: Yup.string().max(200, 'Too Long!').nullable(),
        discount_type: Yup.number().required(),
      };
      quickForm = (formikProps) => <OrderDiscountEdit escalatedPermission={true} order={posOrder} formikProps={formikProps}/>;
      break;
    case 'complimentary':
      validationRules = {
        complimentary_description: Yup.string().max(200, 'Too Long!').nullable(),
        complimentary_type: Yup.number().required(),
      };
      quickForm = (formikProps) => <OrderComplimentaryEdit order={posOrder} formikProps={formikProps}/>;
      break;
    default:
      validationRules = {
        quantity: Yup.number().positive().required(),
        sub_quantity: Yup.number().required(),
      };
      quickForm = (formikProps) => <React.Fragment>
        {POSOrderItem.lock &&
        <p className={'text-muted small'}>
          Order Item is locked, Requires Manager Permission.
        </p>
        }
        <div className="form-row">
          <div className="col-lg-6 col-md-6">
            <Input
              name='quantity'
              label={'Quantity'}
              className={'form-control'}
            />
          </div>

          <div className="col-lg-6 col-md-6 text-right">
            <p className={''}>Total</p>
            <p className={'small'}>
              <strong>{posOrder.currency + ' ' + AppUtil.formatPrice(parseFloat(POSOrderItem.price) * formikProps.values.quantity * formikProps.values.sub_quantity)}</strong>
            </p>
          </div>
        </div>
        <div className="form-row">
          <div className="col-12 ">
            <Textarea
              name='additional_description'
              label={'Description'}
              className={'form-control'}
            />
          </div>
        </div>
      </React.Fragment>;
    }

    const validationSchema = Yup.object().shape(validationRules);

    return (
      <Modal
        {...this.props}
        size={posOrder.lock ? 'md' : 'xl'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Item Details
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            no_charge: POSOrderItem ? POSOrderItem.no_charge : false,
            no_charge_type: POSOrderItem ? POSOrderItem.no_charge_type : '0',
            no_charge_description: POSOrderItem ? POSOrderItem.no_charge_description : '',

            discount: POSOrderItem ? POSOrderItem.discount : '0',
            discount_type: POSOrderItem ? POSOrderItem.discount_type : '0',
            discount_description: POSOrderItem ? POSOrderItem.discount_description : '',

            complimentary: POSOrderItem ? POSOrderItem.complimentary : false,
            complimentary_type: POSOrderItem ? POSOrderItem.complimentary_type : 0,
            complimentary_description: POSOrderItem ? POSOrderItem.complimentary_description : '',

            percentage: 'false',
            quantity: POSOrderItem ? POSOrderItem.quantity : '1',
            sub_quantity: POSOrderItem ? POSOrderItem.sub_quantity : '1',
            additional_description: POSOrderItem ? POSOrderItem.additional_description : '',
          }}
          validationSchema={validationSchema}
          onSubmit={(data) => this.updatePosOrder(data)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <Form className={'form-group'}>
                  <div className={'row'}>
                    <div className={`col-12 ${!posOrder.lock && 'col-lg-6'}`}>
                      <OrderItemDetails order={posOrder} orderItem={POSOrderItem}/>
                    </div>
                    <div className={'col-12 col-lg-6'}>
                      {!posOrder.lock &&
                      <React.Fragment>
                        <div className={'mb-3'}>
                          <SelectorButton
                            buttonList={viewButtonList}
                            selectButtonKey={editViewType}
                            action={(key) => this.setState({editViewType: key})}
                          />
                        </div>
                        <div className={'border p-2 mb-3'}>
                          {quickForm(formikProps)}
                        </div>

                        <div>
                          <button
                            type={'button'} className={'btn btn-sm btn-outline-secondary'}
                            onClick={()=>confirmAlert({
                              title: 'Void Item',
                              message: Strings.posVoidItem,
                              buttons: [
                                {
                                  className: 'btn-danger',
                                  label: 'Void Item',
                                  onClick: () => {
                                    this.setState({editViewType: 'quantity'});
                                    this.updatePosOrder({quantity: 0});
                                  },
                                },
                                {
                                  className: 'btn-secondary',
                                  label: 'Exit',
                                },
                              ],
                            })}
                          >
                            <FontAwesomeIcon icon={faTimes} size={'sm'} className={'mr-2'}/> Void Item
                          </button>
                        </div>
                      </React.Fragment>
                      }
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  {!posOrder.lock &&
                  <div className={'col'}>
                    <button
                      type="submit" onClick={formikProps.handleSubmit} className={'btn btn-success btn-lg btn-block'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                    </button>
                  </div>
                  }
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      editViewType: 'quantity',
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      initialValues: {
        quantity: 1,
        sub_quantity: 1,
        price: 0,
        discount: 0,
      },
    };
  }
}

/* Export Component ==================================================================== */
export default POSOrderItemDetailsView;
