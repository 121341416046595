/**
 *
 * Booking Analytics : Source
 */
import _ from 'lodash';
import Moment from 'moment';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import React, { Component } from 'react';
import PageHeader from '../../../components/dashboard/PageHeader';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
// Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import {AnalyticConstants, Constants} from '../../../constants';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class BookingAnalyticsOverview extends Component {
  static componentName = 'BookingAnalyticsOverview';

  static propTypes = {
    pos: PropTypes.string,
    endDate: PropTypes.object,
    startDate: PropTypes.object
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {startDate, endDate, dateType} = this.props;
    this.setState({loading: true});
    const queryParams = {
      'date_type': dateType,
      'report_type': 'booking_overview',
      'start_date': (startDate ? startDate : Moment()).format('YYYY-MM-DD'),
      'end_date': endDate ? endDate.format('YYYY-MM-DD') : null
    };
    AppAPI.bookingapi.get(`booking-analytics/?${queryString.stringify(queryParams)}`)
      .then((res) => {
        this.setState({loading: false, response: res});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({loading: false, error: error});
      });
  };

  render = () => {
    const {loading, error, response} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    // Initialize with empty array for order data set
    const bookingDataSet = {};
    const bookingValueSet = {};
    Object.keys(AnalyticConstants.bookingAnalytics).forEach((data)=>{
      bookingDataSet[data] = {};
      bookingValueSet[data] = 0;
    });



    // Containers for order status data & order source data
    const bookingData = {
      bookingStatusData: _.cloneDeep(bookingDataSet),
      bookingSourceData: {},
    };

    Object.values(Constants.BOOKING_SOURCES_INPUT).forEach((data)=>{
      bookingData.bookingSourceData[data.value] = _.cloneDeep(bookingDataSet);
    });

    if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
      const responseData = response.data;
      if (!_.isEmpty(responseData.booking_status_source_data)) {
        responseData.booking_status_source_data.forEach((data) => {
          Object.entries(AnalyticConstants.bookingAnalytics).forEach(([key, value])=>{
            if (data[value]) {
              AppUtil.insertOrAddObject(bookingData.bookingStatusData[key], data.booking_status, data[value]);
              AppUtil.insertOrAddObject(bookingData.bookingSourceData[data.booking_source][key], data.booking_status, data[value]);
            }
          });
        });
      }
    }

    return (
      <div className="screen-container">
        <Helmet>
          <title>Booking Analytics : Source Bifurcation</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={'Booking Analytics : Booking Source'}
          description={'Booking bifurcation based on Source and its related details in the selected range.'}

        />

        <div className={'row mb-2'}>
          <div className={'col-12 col-lg-12'}>
            <table className={'table table-bordered'}>
              <thead>
                <tr>
                  <th className={'data-table-cell-md'}>Source</th>
                  {Object.values(Constants.BOOKING_STATUS_INPUT).map((data, i) => (
                    <th colSpan={2} key={i}>{data.label}</th>
                  ))}
                </tr>
                <tr>
                  <th/>
                  {Object.values(Constants.BOOKING_STATUS_INPUT).map((data, i) => (
                    <React.Fragment key={i}>
                      <th>Nos</th>
                      <th>Night</th>
                    </React.Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.values(Constants.BOOKING_SOURCES_INPUT).map((data, key) => {
                  return (
                    <tr key={key}>
                      <td className={'data-table-cell-md'}>
                        {data.label}
                      </td>
                      {Object.values(Constants.BOOKING_STATUS_INPUT).map((bStatus, i) => (
                        <React.Fragment key={i}>
                          <td className={'text-center'}>
                            {bookingData.bookingSourceData[parseInt(data.value)].bookingCount[parseInt(bStatus.value)] || 0}
                          </td>
                          <td className={'text-center'}>
                            {bookingData.bookingSourceData[parseInt(data.value)].bookingNights[parseInt(bStatus.value)] || 0}
                          </td>
                        </React.Fragment>
                      ))}
                    </tr>
                  );
                })}
                <tr>
                  <td><strong>Total</strong></td>
                  {Object.values(Constants.BOOKING_STATUS_INPUT).map((data, i) => (
                    <React.Fragment key={i}>
                      <td className={'text-center'}>
                        <strong>{(bookingData.bookingStatusData.bookingCount[parseInt(data.value)] || 0)}</strong>
                      </td>
                      <td className={'text-center'}>
                        <strong>{(bookingData.bookingStatusData.bookingNights[parseInt(data.value)] || 0)}</strong>
                      </td>
                    </React.Fragment>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      showPAOverview: false,
      showPAOrderData: {},
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingAnalyticsOverview);
