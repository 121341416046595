/**
 * Property Logo update
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import * as Yup from 'yup';
// Consts and Libs
import AppAPI from '../../lib/api';
// Components
import {Formik} from 'formik';
import {Alerts} from '../../components/ui';
import Loading from '../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
import {DropZone, Form, SubmitBtn} from 'react-formik-ui';

/* Component ==================================================================== */

class PropertyLogoManage extends Component {
  static componentName = 'PropertyLogoManage';

  static propTypes = {
    data: PropTypes.object,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        loading: true,
        record: null,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  preFillData = () => {
    this.setState({
      init: false,
      loading: false,
      initialValues: {
        'logo': [],
      },
    });
  };


  updateProperty = (credentials) => {
    const {data} = this.props;
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});
      const formData = new FormData();

      if (credentials.logo && credentials.logo[0]) {
        formData.append('logo', credentials.logo ? credentials.logo[0] : null);
      }

      if (credentials.remove_file) {
        formData.append('document');
      }

      AppAPI.propertydetails.patch(`${data.id}/`, formData, true)
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                if (this.props.onSave) {
                  this.props.onSave(res);
                }
                this.props.onHide(res);
              }, 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  render = () => {

    const {init, resultMsg, initialValues, record, loading} = this.state;
    const {show} = this.props;

    if (show && init) {
      this.preFillData();
    }

    if (!show) return null;

    const SUPPORTED_IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
    const FILE_SIZE = 2 * 1024 * 1024;
    const formValidation = Yup.object().shape({
      logo: Yup.mixed()
        .test(
          'fileRequired',
          'Record file is required',
          value => (record ? true : value && value[0]),
        )
        .test(
          'fileFormat',
          'Unsupported Format',
          value => value && value[0] ? SUPPORTED_IMAGE_FORMATS.includes(value[0].type) : true,
        )
        .test('fileSize', 'File is too large < 4Mb', value => {
          return value && value[0] ? value[0].size <= FILE_SIZE : true;
        })
        .required(),
    });


    return (
      <Modal
        {...this.props}
        size={'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Property Logo
          </Modal.Title>
        </Modal.Header>
        {loading ? <Loading heightMatch={false}/> :
          <Formik
            onSubmit={(values) => this.updateProperty(values)}
            validationSchema={formValidation}
            initialValues={initialValues}>
            {() => (
              <React.Fragment>
                <Modal.Body>
                  <Form>
                    <div className={'row'}>
                      <div className={'col-sm-12 form-group'}>
                        <p className={'text-muted'}>
                          Uploaded image will be cropped into two resolutions <code>150 x 75</code> and <code>64 x 64</code>.
                        </p>
                        <DropZone
                          name='logo'
                          label='File upload'
                          placeholder='Drop files here, or click to select files.'
                          hint={'Upload a jpg / jpeg / png file with a min dimension of 200 x 200 px and size < 5MB'}
                          multiple={false}
                        />
                      </div>
                    </div>
                  </Form>

                </Modal.Body>
                <Modal.Footer className={'d-block'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <div className={'row'}>
                    <div className={'col-6'}>
                      <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                      </button>
                    </div>
                    <div className={'col-6'}>
                      <SubmitBtn className={'btn btn-success btn-lg btn-block'}>
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/>
                        Save
                      </SubmitBtn>
                    </div>
                  </div>
                </Modal.Footer>
              </React.Fragment>
            )}
          </Formik>
        }
      </Modal>
    );
  };


  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: false,
      record: null,
      initialValues: {},
    };
  }
}

/* Export Component ==================================================================== */
export default PropertyLogoManage;
