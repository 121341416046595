/**
 *
 * POS Order KOT History
 */
import _ from 'lodash';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import queryString from 'query-string';
import React, {Component} from 'react';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
// Components
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import SearchBar from '../../components/forms/SearchBar';
import PageHeader from '../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faExclamationTriangle, faHistory, faInfoCircle} from '@fortawesome/free-solid-svg-icons';

// Actions

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  posOrderKOT: state.pos.posOrderKOT,
  posDetails: state.pos.posDetails,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class POSOrderKOTHistoryHistory extends Component {
  static componentName = 'POSOrderKOTHistoryHistory';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };
  componentDidMount = () => {
  };

  fetchData = (page = 1, callback) => {
    const urlParams = {
      'page': page,
    };

    const {posDetails} = this.props;

    if (posDetails.hash) {
      urlParams['pos'] = posDetails.hash;
    }

    urlParams['pos_kot'] = this.props.match.params.POSOrderKOTId;

    let baseUrl = 'pos-order-kot-history';
    AppAPI.posapi.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        this.setState({
          loading: false,
          error: null,
        });
        if (res.results.length > 0) {
          if (res.next) {
            callback(res.results);
          } else {
            callback(res.results, {
              allLoaded: true, // the end of the list is reached
            });
          }
        } else {
          callback();
        }
      })
      .catch((err) => {
        callback(null, {
          allLoaded: true, // the end of the list is reached
        });
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };

  renderRow = (data, key) => {
    return (
      <tr key={key}>
        <td className={'data-table-cell-sm'}>{AppUtil.formatDateTime(data.time)}</td>
        <td className={'data-table-cell-sm'}>
          <span className={'ml-2 badge ' + (AppUtil.posOrderKotStatusColor(data.kot_status))}>
            {data.kot_status_display}
          </span>
        </td>
        <td className={'bg-light'}>
          <table className={'table table-sm border format-table'}>
            <thead>
              <tr>
                <th className={'data-table-cell-md'}>Name</th>
                <th className={'data-table-cell-sm'}>Status</th>
                <th className={'data-table-cell-sm'}>Qty</th>
              </tr>
            </thead>
            <tbody>
              {_.isEmpty(data.items_cache) ?
                <tr>
                  <td colSpan={3}>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faExclamationTriangle} size={'2x'}
                        className={'mt-3 red-cl'}
                      />
                      <p className={'text-secondary small'}>No item in this KOT</p>
                    </div>
                  </td>
                </tr>
                :
                <React.Fragment>
                  {data.items_cache.map((itemData, k) => (
                    <tr
                      key={k}
                      className={`${!itemData.status && 'table-dark'}`}
                    >
                      <td>
                        <strong>{itemData.description}</strong>
                        <p className={'text-muted small mb-1'}>
                          {itemData.additional_description}
                        </p>
                        {itemData.item_edited &&
                        <p className={'text-muted small mb-1'}>
                          Modified at {AppUtil.formatDateTime(itemData.time)}
                        </p>
                        }
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={itemData.item_edited ? faInfoCircle : faCheckCircle}
                          className={`mr-2 ${itemData.item_edited ? 'red-cl' : 'green-cl'}`}
                        />
                        {itemData.item_edited ? 'Modified' : 'Original'}
                      </td>
                      <td>
                        {itemData.quantity} {itemData.quantity_type_display}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              }
            </tbody>
          </table>
        </td>
      </tr>
    );
  };
  emptyView = () => {
    return (
      <tr>
        <td colSpan={3}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faHistory}/> No History </h4>
          </div>
        </td>
      </tr>
    );
  };


  tableHeader = () => {
    return (
      <tr>
        <th className={'data-table-cell-sm'}>Time</th>
        <th className={'data-table-cell-sm'}>KOT Status</th>
        <th className={'data-table-cell-xl text-center'}>Items</th>
      </tr>
    );
  };

  render = () => {

    const {loading, error} = this.state;
    const {posOrderKOT} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="room-list screen-container ">
        <Helmet>
          <title>Maintenance History Rooms</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'KOT History'}
          description={`Maintenance history for KOT ${posOrderKOT.kot_id}`}
        />

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 mb-5">
            <SearchBar
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
              colSpan={3}
            />
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(POSOrderKOTHistoryHistory);