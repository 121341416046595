/**
 *
 * Membership List
 */
import Moment from 'moment';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import queryString from 'query-string';
import ExportButton from '../../../components/dashboard/ExportButton';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import AppWebUtil from '../../../lib/webUtils';
import {Constants, Filters, Strings} from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import SearchBar from '../../../components/forms/SearchBar';
import BadgeStatus from '../../../components/general/BadgeStatus';
import PageHeader from '../../../components/dashboard/PageHeader';
import FilterButton from '../../../components/dashboard/FilterButton';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLongArrowAltRight, faPlus, faSync, faUser} from '@fortawesome/free-solid-svg-icons';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PropertyMembershipList extends Component {
  static componentName = 'PropertyMembershipList';
  static propTypes = {
    refreshable: PropTypes.bool,
    roomSettings: PropTypes.bool,
  };

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };

  componentDidMount = () => {
    let urlParams = {};
    switch (this.getQueryParams('membership_type')) {
    case 'standard':
      urlParams['type'] = Constants.MEMBERSHIP_TYPE.STANDARD_USER;
      break;
    case 'administrator':
      urlParams['type'] = Constants.MEMBERSHIP_TYPE.ADMINISTRATOR;
      break;
    default:
      break;
    }

    switch (this.getQueryParams('membership_status')) {
    case 'active':
      urlParams['access_status'] = true;
      break;
    case 'suspended':
      urlParams['access_status'] = false;
      break;
    default:
      break;
    }
    this.setState({loading: false, urlParams});
  }

  fetchData = (page = 1, callback, searchText, download) => {
    const urlParams = {...this.state.urlParams};
    urlParams['page'] = page;

    if (download) {
      urlParams['download'] = download.file;
      urlParams['all_data'] = true;
      this.setState({fileDownload: true});
    }

    AppAPI.membership.get(`?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (download) {
          let fileName = `Membership-List-${AppUtil.formatDateTime(Moment(), 'short')}.${download.file}`;
          AppWebUtil.downloadFile(res, fileName);
        } else {
          if (res.results.length > 0) {
            callback(res.results, {allLoaded: !res.next});
          } else {
            callback();
          }
        }
        this.setState({
          searching: false,
          clear: false,
          refresh: false,
          loading: false,
          fileDownload: false,
          page: page,
        });
      })
      .catch((err) => {
        if (callback) {
          callback([], {allLoaded: true});
        }
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, refresh: false, loading: false, fileDownload: false, error});
      });
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={5}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faUser}/> No User Accounts </h4>
            {Strings.propertyMembershipListEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };

  renderRow = (data, key) => {
    return (
      <tr key={key}>
        <td className={'data-table-cell-lg'}>
          {data.user_profile ? data.user_profile.display_name : 'Account'}
          <br/>
          <p className={'small mb-0 text-muted'}>{data.user_profile ? data.user_profile.email : 'E-Mail'}</p>
        </td>
        <td className={'data-table-cell-lg'}>
          <Link to={`/settings/property/accounts/${data.id}/`}><small>{data.ref_no}</small></Link>
        </td>
        <td className={'data-table-cell-md'}>{data.type_display}</td>
        <td className={'data-table-cell-md'}>
          <BadgeStatus
            successText={'Active'} failText={'Suspended'} className={'ml-2'}
            status={data.access_status}
          />
        </td>
        <td>
          <Link to={`/settings/property/accounts/${data.id}/`}>
            Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
          </Link>
        </td>
      </tr>
    );
  };

  tableHeader = () => {
    return (
      <tr>
        <th>User</th>
        <th>Reference</th>
        <th>Type</th>
        <th/>
        <th/>
      </tr>
    );
  }

  render = () => {

    const {loading, error, refresh, fileDownload} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="room-type-list screen-container ">
        <Helmet>
          <title>Account Memberships</title>
        </Helmet>

        <PageHeader
          subHeader={true} history={this.props.history} title={'User accounts'}
          description={'Manage user accounts and permissions'}
        />
        <div className="row">
          <div className="col-lg-9 col-md-8 col-sm-12">
            <div className={'row mb-3'}>
              <div className={'col-12 col-sm-9'}>
                <ul className="list-inline list-item-mb-1">
                  <li className="list-inline-item">
                    <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.setState({refresh: true})}>
                      <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                    </button>
                  </li>
                  <li className="list-inline-item">
                    <FilterButton
                      title={'Membership Type'}
                      data={Filters.membershipType}
                      selectKey={this.getQueryParams('membership_type')}
                      action={(data) => this.setQueryParams([{key: 'membership_type', value: data.key}])}
                      size={'sm'}
                    />
                  </li>
                  <li className="list-inline-item">
                    <FilterButton
                      title={'Status'}
                      data={Filters.membershipStatus}
                      selectKey={this.getQueryParams('membership_status')}
                      action={(data) => this.setQueryParams([{key: 'membership_status', value: data.key}])}
                      size={'sm'}
                    />
                  </li>
                </ul>
              </div>
              <div className={'col-12 col-sm-3 text-center text-sm-right'}>
                <ExportButton
                  size={'sm'}
                  files={['csv']}
                  action={(key) => this.fetchData(this.state.page, null, null, {file: key})}
                  loading={fileDownload}
                />
              </div>
            </div>
            <SearchBar
              refresh={refresh}
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
              colSpan={5}
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item">
                <Link to={'/settings/property/accounts/new'} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add User
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      refresh: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyMembershipList);
