/**
 * Manage Order
 *  manages order updates ( Discount, Order Additional Information)
 */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import * as Yup from 'yup';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {ErrorMessages} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import OrderTaxEdit from '../components/OrderTaxEdit';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
import OrderDiscountEdit from '../components/OrderDiscountEdit';
// Forms
import {Form, Formik} from 'formik';
import {Input} from 'react-formik-ui';


/* Component ==================================================================== */

class OrderEdit extends React.Component {
  static componentName = 'OrderManageView';

  static propTypes = {
    match: PropTypes.object,
    order: PropTypes.object,
    property: PropTypes.object,
    updateResponse: PropTypes.func,
    editType: PropTypes.string,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        loading: false,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  updateOrder = (credentials = null) => {
    const {editType} = this.props;

    let payload = {};
    if (credentials) {
      switch (editType) {
      case 'bulkDiscount':
        payload.order_item_discount = credentials.discount;
        payload.order_discount_type = credentials.discount_type;
        payload.order_item_discount_percent = credentials.percentage;
        payload.discount_before_tax = credentials.discount_before_tax;
        payload.order_discount_description = credentials.discount_description;
        break;
      case 'discount':
        payload.order_discount = credentials.discount;
        payload.order_discount_type = credentials.discount_type;
        payload.order_discount_description = credentials.discount_description;
        if (credentials.percentage === 'true') {
          payload.order_discount = parseFloat((credentials.discount * this.props.order.absolute_total) / 100).toFixed(2);
        }
        break;
      case 'orderTax':
        payload.order_tax = credentials.order_tax;
        payload.order_tax_description = credentials.order_tax_description;
        break;
      case 'totalOverRide':
        payload.over_ride_total = credentials.total;
        // payload.tax_inclusive = credentials.tax_inclusive;
        break;
      default:
        payload.tax_number = credentials.tax_number;
        payload.tax_company = credentials.tax_company;
      }
    }

    if (!_.isEmpty(payload)) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.order
          .patch(`${this.props.order.hash}/`, payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                this.props.updateResponse(res);
                this.props.onHide();
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {order, show, editType, property} = this.props;

    const {config} = property;

    if (loading) return <Loading heightMatch={true}/>;
    if (!loading && error) return <Error full={true} text={ErrorMessages.initData}/>;
    if (!show) return <React.Fragment/>;

    let formValidation, formInputData;

    switch (editType) {
    case 'discount':
      formValidation = Yup.object().shape({
        discount: Yup.number().required(),
        discount_type: Yup.number().required(),
        discount_description: Yup.string().max(200, 'Too Long!').nullable(),
      });
      formInputData = (formikProps) => <OrderDiscountEdit order={order} formikProps={formikProps}/>;
      break;
    case 'bulkDiscount':
      formValidation = Yup.object().shape({
        discount: Yup.number().required(),
        discount_type: Yup.number().required(),
        discount_description: Yup.string().max(200, 'Too Long!').nullable(),
      });
      formInputData = (formikProps) => <OrderDiscountEdit order={order} formikProps={formikProps} discountPosition={true}/>;
      break;
    case 'orderTax':
      formValidation = Yup.object().shape({
        order_tax: Yup.number().required(),
        order_tax_description: Yup.string().max(200, 'Too Long!').nullable(),
      });
      formInputData = (formikProps) => <OrderTaxEdit order={order} formikProps={formikProps}/>;
      break;
    case 'totalOverRide':
      formValidation = Yup.object().shape({
        total: Yup.number().positive().required(),
      });
      formInputData = () => (
        <React.Fragment>
          <div className="form-row">
            <div className="col-8 mx-auto form-group">
              <Input
                required
                name='total'
                type={'number'}
                label={`New Total (${order.currency})`}
                className={'form-control'}
              />
            </div>
          </div>
        </React.Fragment>
      );
      break;
    default:
      formValidation = Yup.object().shape({
        tax_number: Yup.string().nullable(),
        tax_company: Yup.string().nullable(),
      });
      formInputData = () => (
        <React.Fragment>
          <div className="form-row">
            <div className="col-lg-6 col-md-12 form-group">
              <Input
                required
                name='tax_company'
                label={'Tax Company Name'}
                className={'form-control'}
              />
            </div>
            <div className="col-lg-6 col-md-12 form-group">
              <Input
                required
                name='tax_number'
                label={`Tax Number ${property && property.tax_number_type && `(${property.tax_number_type})`}`}
                className={'form-control'}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Order
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            percentage: 'false',
            discount: editType === 'bulkDiscount' ? 0 : order.order_discount,
            discount_type: editType === 'bulkDiscount' ? 0 : order.order_discount_type,
            discount_description: editType === 'bulkDiscount' ? '' : order.order_discount_description,

            tax_number: order.tax_number,
            tax_company: order.tax_company,

            order_tax: order.order_tax,
            order_tax_description: order.order_tax_description,

            discount_before_tax: !_.isEmpty(config) && config.order && config.order.order_discount_pre_tax,

            total: order.sub_total,
          }}
          validationSchema={formValidation}
          onSubmit={values => this.updateOrder(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <Form className={'form-group'}>
                      <div className="mt-2">
                        {formInputData(formikProps)}
                      </div>
                    </Form>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <button
                      type="submit" onClick={formikProps.handleSubmit} className={'btn btn-success btn-lg btn-block'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default OrderEdit;
