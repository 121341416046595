/**
 *
 * OrderList
 * List Orders
 */
import _ from 'lodash';
import React, {Component} from 'react';
import queryString from 'query-string';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import Moment from 'moment';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import AppWebUtil from '../../lib/webUtils';
import {Constants, Filters, Strings} from '../../constants';
// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import SearchBar from '../../components/forms/SearchBar';
import FilterButton from '../../components/dashboard/FilterButton';
import FilterDate from '../../components/dashboard/FilterDate';
import ExportButton from '../../components/dashboard/ExportButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFile, faLongArrowAltRight, faSync} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class OrderList extends Component {
  static componentName = 'OrderList';

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };

  componentDidMount = () => {
    let urlParams = {}, startDate = null, endDate = null, paymentStartDate, paymentEndDate, refundStartDate, refundEndDate;;
    switch (this.getQueryParams('order_status')) {
    case 'paid':
      urlParams['order_status'] = Constants.PAYMENT_STATUS.PAID;
      break;
    case 'part-paid':
      urlParams['order_status'] = Constants.PAYMENT_STATUS.PARTPAID;
      break;
    case 'refund':
      urlParams['order_status'] = Constants.PAYMENT_STATUS.REFUNDED;
      break;
    case 'part-refund':
      urlParams['order_status'] = Constants.PAYMENT_STATUS.PART_REFUNDED;
      break;
    case 'not-paid':
      urlParams['order_status'] = Constants.PAYMENT_STATUS.NOTPAID;
      urlParams['lock'] = true;
      urlParams['void'] = false;
      break;
    case 'canceled':
      urlParams['order_status'] = Constants.PAYMENT_STATUS.CANCELLED;
      break;
    case 'open':
      urlParams['lock'] = false;
      break;
    default:
      break;
    }

    switch (this.getQueryParams('order_source')) {
    case 'booking':
      urlParams['order_source'] = Constants.ORDER_SOURCE.BOOKING;
      break;
    case 'pos':
      urlParams['order_source'] = Constants.ORDER_SOURCE.POINT_OF_SALES;
      break;
    case 'amendments':
      urlParams['order_source'] = Constants.ORDER_SOURCE.AMENDMENT;
      break;
    case 'external':
      urlParams['order_source'] = Constants.ORDER_SOURCE.EXTERNAL;
      break;
    case 'event':
      urlParams['order_source'] = Constants.ORDER_SOURCE.EVENT;
      break;
    case 'user':
      urlParams['order_source'] = Constants.ORDER_SOURCE.USER;
      break;
    default:
      break;
    }

    if (this.getQueryParams('start_date')) {
      startDate = Moment(String(this.getQueryParams('start_date')));
    }
    if (this.getQueryParams('end_date')) {
      endDate = Moment(String(this.getQueryParams('end_date')));
    }

    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('start_date'), this.getQueryParams('end_date'), 'created_date');


    if (this.getQueryParams('payment_start_date')) {
      paymentStartDate = Moment(String(this.getQueryParams('payment_start_date')));
    }
    if (this.getQueryParams('payment_end_date')) {
      paymentEndDate = Moment(String(this.getQueryParams('payment_end_date')));
    }
    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('payment_start_date'), this.getQueryParams('payment_end_date'), 'payment_date');


    if (this.getQueryParams('refund_start_date')) {
      refundStartDate = Moment(String(this.getQueryParams('refund_start_date')));
    }
    if (this.getQueryParams('refund_end_date')) {
      refundEndDate = Moment(String(this.getQueryParams('refund_end_date')));
    }
    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('refund_start_date'), this.getQueryParams('refund_end_date'), 'refund_date');

    if (this.props.guestId) {
      urlParams['guest_data'] = this.props.guestId;
    }

    if (this.props.corporateId) {
      urlParams['corporate_data'] = this.props.corporateId;
    }

    this.setState({loading: false, urlParams, startDate, endDate, paymentStartDate, paymentEndDate, refundStartDate, refundEndDate});
  };

  fetchData = (page = null, callback, searchText, download) => {
    const {pos, event, taxData} = this.props;
    const urlParams = {...this.state.urlParams};
    let baseUrl = 'order-v2', apiUrl;
    if (page) {
      urlParams['cursor'] = page;
    }

    if (taxData){
      baseUrl = 'order-tax-list';
    }

    if (searchText) {
      baseUrl = 'order-search';
      urlParams['search'] = searchText;
      if (page){
        urlParams['page'] = page;
      }
      this.setState({searching: true, clear: true});
    }

    if (download) {
      urlParams['download'] = download.file;
      urlParams['all_data'] = true;
      this.setState({fileDownload: true});
    }


    if (pos){
      apiUrl = AppAPI.posapi;
      baseUrl = 'pos-billing-order-v2';
      urlParams['pos'] = pos.hash;
      if (searchText) { baseUrl = 'pos-billing-order-search';}
    } else if (event) {
      apiUrl = AppAPI.eventapi;
      baseUrl = 'event-billing-order';
      urlParams['event'] = event.hash;
      if (searchText) { baseUrl = 'event-billing-order-search';}
    } else {
      apiUrl = AppAPI.orderapi;
    }

    apiUrl.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (download) {
          let fileName = `Order-Report-${AppUtil.formatDateTime(Moment(), 'short')}.${download.file}`;
          AppWebUtil.downloadFile(res, fileName);
        } else {
          if (res.results.length > 0) {
            callback(
              res.results,
              {
                allLoaded: !res.next,
                nextParam: res.count ? AppUtil.getURLParam(res.next, 'page') : AppUtil.getURLParam(res.next, 'cursor')
              }
            );
          } else {
            callback();
          }
        }
        this.setState({
          searching: false,
          clear: false,
          refresh: false,
          loading: false,
          fileDownload: false,
          page: page,
          taxes: res.taxes,
        });
      })
      .catch((err) => {
        if (callback) {
          callback([], {allLoaded: true});
        }
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, refresh: false, loading: false, fileDownload: false, error});
      });
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={7}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faFile}/> No Order </h4>
            {Strings.orderListEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };


  tableHeader = () => {
    const {taxes} = this.state;
    return (
      <tr>
        <th className={'data-table-cell-sm'}>Reference</th>
        <th className={'data-table-cell-lg text-left'}>Amount</th>
        <th className={'data-table-cell-lg'}/>
        <th className={'data-table-cell-lg'}>Source</th>
        <th className={'data-table-cell-md'}>Date</th>
        {!_.isEmpty(taxes) > 0 &&
          <React.Fragment>
            {taxes.map((data, i) => (<th className={'data-table-cell-xl small'} key={i}>{data.description}</th>))}
          </React.Fragment>
        }
        <th/>
      </tr>
    );
  };

  renderRow = (data, key) => {
    return (
      <tr className={'tr-align-middle'} key={key}>
        <td className={'data-table-cell-md'}>
          <Link to={`/order/${data.hash}`}><small>{data.ref_no}</small></Link>
          <p className={'small text-muted mb-0'}>{data.order_type_display}</p>
        </td>
        <td className={'data-table-cell-md text-left'}>
          <strong>{`${data.currency} ${data.total}`}</strong>
          {(parseFloat(data.complimentary_total) > 0) &&
            <p className={'mb-0 small text-muted'}>{`Comp : ${data.currency}  ${data.complimentary_total}`}</p>
          }
          {(parseFloat(data.no_charge_total) > 0) &&
            <p className={'mb-0 small text-muted'}>{`NC : ${data.currency}  ${data.no_charge_total}`}</p>
          }
          {(parseFloat(data.total_discount) > 0) &&
            <p className={'mb-0 small text-muted'}>{`Discount : ${data.currency}  ${data.total_discount}`}</p>
          }
        </td>
        <td className={'data-table-cell-md text-left'}>
          <span className={'ml-2 badge ' + (AppUtil.paymentStatusColor(data.order_status))}>
            {data.order_status_display}
          </span>
          {!data.lock && <span className={'badge badge-warning small ml-2'}>Open Order</span>}
        </td>
        <td className={'data-table-cell-md'}>
          {data.order_source_display}
          {!_.isEmpty(data.attributes) &&
          <React.Fragment>
            {data.attributes.pos_name && <p className={'mb-0 small text-muted'}>{data.attributes.pos_name}</p>}
            {data.attributes.event_name && <p className={' mb-0 small text-muted'}>{data.attributes.event_name}</p>}
            {data.attributes.booking_ota && <p className={'mb-0 small text-muted'}>{data.attributes.booking_ota}</p>}
          </React.Fragment>
          }
          {!_.isEmpty(data.booking_ota) && <p className={'mb-0 small text-muted'}>{data.booking_ota.name}</p>}
          {!_.isEmpty(data.event) && <p className={'mb-0 small text-muted'}>{data.event.name}</p>}
          {!_.isEmpty(data.pos) && <p className={'mb-0 small text-muted'}>{data.pos.name}</p>}
        </td>
        <td className={'data-table-cell-md'}><small>{AppUtil.formatDateTime(data.created)}</small></td>
        {!_.isEmpty(data.tax_breakup) &&
          <React.Fragment>
            {data.tax_breakup.map((tax_data, i) => (<th key={i}>{tax_data.total}</th>))}
          </React.Fragment>
        }
        <td className={'data-table-cell-md'}>
          <Link to={`/order/${data.hash}`}>
            Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
          </Link>
        </td>
      </tr>
    );
  };


  render = () => {
    const {loading, error, clear, searching, results, fileDownload, refresh, taxes} = this.state;
    const {guestId, corporateId, taxData} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="order-list screen-container">
        <Helmet>
          <title>Order List</title>
        </Helmet>

        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-9'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.setState({refresh: true})}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                </button>
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Order Type'}
                  data={Filters.orderFilter}
                  selectKey={this.getQueryParams('order_status')}
                  action={(data) => this.setQueryParams([{key: 'order_status', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Order Source'}
                  data={Filters.orderSource}
                  selectKey={this.getQueryParams('order_source')}
                  action={(data) => this.setQueryParams([{key: 'order_source', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data))}
                  size={'sm'}
                  maxDate={Moment().add(1, 'days')}
                />
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.paymentStartDate}
                  endDate={this.state.paymentEndDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data, 'payment_'))}
                  size={'sm'}
                  maxDate={Moment().add(1, 'days')}
                  title={'Payment Date'}
                />
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.refundStartDate}
                  endDate={this.state.refundEndDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data, 'refund_'))}
                  maxDate={Moment().add(1, 'days')}
                  size={'sm'}
                  title={'Refund Date'}
                />
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-3 text-center text-sm-right'}>
            <ExportButton
              size={'sm'}
              files={['csv']}
              action={(key) => this.fetchData(this.state.page, null, null, {file: key})}
              loading={fileDownload}
              disabled={!this.state.startDate}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <SearchBar
              clear={clear}
              refresh={refresh}
              results={results}
              searching={searching}
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
              colSpan={7 + (!_.isEmpty(taxes) ? taxes.length : 0 )}
              searchFunction={(guestId || corporateId || taxData) ? null : this.fetchData}
              placeHolder="Order ID"
              listVersion={'V2'}
            />
          </div>
        </div>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      searching: false,
      clear: false,
      results: [],
      urlParams: {},
      page: 1,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
