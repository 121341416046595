/**
 *
 * Guest Details
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppUtil from '../../../lib/util';
import {Constants} from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import BadgeStatus from '../../../components/general/BadgeStatus';
import ProfileDetailsEditShort from '../model/ProfileDetailsEditShort';
import ProfileVerification from '../../account/profile/ProfileVerification';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope, faMobile, faPen, faPencilAlt} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */
class ProfileDetails extends Component {
  static componentName = 'ProfileOverView';
  static propTypes = {
    reloadData: PropTypes.func,
    property: PropTypes.object,
    baseUrl: PropTypes.string,
    editControl: PropTypes.bool,
    showSideBar: PropTypes.bool,
    additionalContent: PropTypes.bool,
    guest: PropTypes.object.isRequired,
  };

  static defaultProps = {
    title: 'Guest',
    showSideBar: true,
    editControl: false,
    additionalContent: true,
    profile_type: Constants.PROFILE_TYPE.GUEST,
  };

  editControl = (expandEdit=false) => (
    this.props.editControl
      ?  (
        <button
          onClick={()=>this.setState({showProfileDetailsEditShort: true, expandEdit: expandEdit})}
          className={'btn btn-sm btn-link p-0 m-0 ml-2'}
        >
          <FontAwesomeIcon icon={faPencilAlt}/>
        </button>
      )
      : (<></>)
  );

  render = () => {

    const {loading, error} = this.state;
    const {guest, baseUrl, showSideBar, additionalContent, editControl} = this.props;

    const contactElements = [
      {
        'title': 'Email',
        'type': 'email',
        'icon': faEnvelope,
        'data': guest.email,
        'verified': guest.email_verified,
        'verified_time': guest.email_verified_time,
      },
      {
        'title': 'Phone',
        'type': 'phone',
        'icon': faMobile,
        'data': guest.phone,
        'verified': guest.phone_verified,
        'verified_time': guest.phone_verified_time,
      },
    ];

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="guest-details screen-container ">
        <div className="row">
          <div className={`col-lg-${showSideBar ? '9' : '12'} col-md-12 col-sm-12`}>
            <div className={'row'}>
              <div className={'col-md-12 col-lg-6'}>
                <table className={'table table-borderless table-sm'}>
                  <tbody>
                    <tr>
                      <td className={'w-50 text-muted'}>Reference Number</td>
                      <td>{guest.ref_no}</td>
                    </tr>
                    <tr>
                      <td className={'text-muted'}>Record Document</td>
                      <td>
                        <BadgeStatus
                          failClass={'badge-warning'} successText={'Record'} failText={'Records'}
                          status={guest.record_exist}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className={'w-25 text-muted'}>Profile Type</td>
                      <td>{guest.profile_type_display}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={'col-md-12 col-lg-6'}>
                <table className={'table table-borderless table-sm'}>
                  <tbody>
                    <tr>
                      <td className={'w-50 text-muted'}>Created</td>
                      <td>{AppUtil.formatDateTime(guest.created)}</td>
                    </tr>
                    <tr>
                      <td className={'w-50 text-muted'}>Last modified</td>
                      <td>{AppUtil.formatDateTime(guest.time)}</td>
                    </tr>
                    <tr>
                      <td className={'w-25 text-muted'}>Profile Source</td>
                      <td>{guest.profile_source_display}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>


            {(guest.profile_type !== Constants.PROFILE_TYPE.GUEST) &&
              <React.Fragment>
                <p><strong>{guest.profile_type_display} Details</strong></p>
                <hr/>
                <div className={'row'}>
                  <div className={'col-md-12 col-lg-6'}>
                    <table className={'table table-borderless table-sm'}>
                      <tbody>
                        <tr>
                          <td className={'w-50 text-muted'}>Entity Name</td>
                          <td>{guest.entity_name}</td>
                        </tr>
                        <tr>
                          <td className={'text-muted'}>Entity Number</td>
                          <td>{guest.entity_number || '...'}</td>
                        </tr>
                        <tr>
                          <td className={'text-muted'}>Entity Type</td>
                          <td>{guest.entity_type_display}</td>
                        </tr>
                        {guest.iata_code &&
                        <tr>
                          <td className={'text-muted'}>IATA Code</td>
                          <td>{guest.iata_code}</td>
                        </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className={'col-md-12 col-lg-6'}>
                    <table className={'table table-borderless table-sm'}>
                      <tbody>
                        <tr>
                          <td className={'text-muted'}>Tax Company</td>
                          <td>{guest.tax_company || '...'}</td>
                        </tr>
                        <tr>
                          <td className={'text-muted'}>Tax Number</td>
                          <td>{guest.tax_number || '...'}</td>
                        </tr>
                        <tr>
                          <td className={'text-muted'}>Booking </td>
                          <td>
                            <BadgeStatus successText={'Enabled'} failText={'Disabled'} status={guest.profile_booking}/>
                          </td>
                        </tr>
                        <tr>
                          <td className={'text-muted'}>Point of Sale</td>
                          <td>
                            <BadgeStatus successText={'Enabled'} failText={'Disabled'} status={guest.profile_pos}/>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </React.Fragment>
            }
            <p><strong>Account Details</strong></p>
            <hr/>
            <div className={'row'}>
              <div className={'col-md-12 col-lg-6'}>
                <table className={'table table-borderless table-sm'}>
                  <tbody>
                    <tr>
                      <td className={'w-50 text-muted'}>Name {this.editControl()}</td>
                      <td><strong>{guest.name}</strong></td>
                    </tr>
                    <tr>
                      <td className={'w-50 text-muted'}>Nationality</td>
                      <td>{guest.nationality ? guest.nationality.name : '...'}</td>
                    </tr>
                    <tr>
                      <td className={'w-50 text-muted'}>Gender</td>
                      <td>{guest.gender_display}</td>
                    </tr>
                    {guest.date_of_birth &&
                     <tr>
                       <td className={'w-50 text-muted'}>Date of birth</td>
                       <td>{guest.date_of_birth || '...'}</td>
                     </tr>
                    }
                    {guest.age &&
                    <tr>
                      <td className={'w-50'}>Age</td>
                      <td>{guest.age || '...'}</td>
                    </tr>
                    }
                    {guest.anniversary_date &&
                     <tr>
                       <td className={'w-50 text-muted'}>Anniversary Date</td>
                       <td>{guest.anniversary_date || '...'}</td>
                     </tr>
                    }
                  </tbody>
                </table>
              </div>
              <div className={'col-md-12 col-lg-6'}>
                <table className={'table table-borderless table-sm'}>
                  <tbody>
                    <tr>
                      <td className={'w-50 text-muted'}>Address {this.editControl(true)}</td>
                      <td>{guest.address || '...'}</td>
                    </tr>
                    <tr>
                      <td className={'w-50 text-muted'}>State</td>
                      <td>{guest.state || '...'} {guest.pincode && `, Pin : ${guest.pincode}`}</td>
                    </tr>
                    <tr>
                      <td className={'w-50 text-muted'}>Country</td>
                      <td>{guest.country ? guest.country.name : '...'}</td>
                    </tr>
                    {(guest.profile_type === Constants.PROFILE_TYPE.GUEST) &&
                      <React.Fragment>
                        <tr>
                          <td className={'text-muted'}>Tax Company</td>
                          <td>{guest.tax_company || '...'}</td>
                        </tr>
                        <tr>
                          <td className={'text-muted'}>Tax Number</td>
                          <td>{guest.tax_number || '...'}</td>
                        </tr>
                      </React.Fragment>
                    }
                  </tbody>
                </table>
              </div>
            </div>

            <p><strong>Contact</strong></p>
            <div className="row mb-3">
              {contactElements.map((data, i)=>(
                <div className={'col-lg-6 col-md-12 '}>
                  <div className="p-3 border rounded d-flex" key={i}>
                    <div><FontAwesomeIcon icon={data.icon} className={'mr-2'}/></div>
                    <div className={'w-100'}>
                      <strong>{data.title} :</strong> {data.data || '...'} {this.editControl()}
                      <BadgeStatus
                        className={'ml-2 float-right'}
                        successText={'Verified'}
                        failText={'Unverified'}
                        status={data.verified}
                        failClass={'badge-warning'}
                      />
                      <br/>
                      <React.Fragment>
                        {data.verified ?
                          <p className={'sm mb-1 text-secondary small'}>
                            Verified on {AppUtil.formatDateTime(data.verified_time)}
                          </p>
                          :
                          <button
                            disabled={!editControl || !data.data}
                            onClick={() => this.setState({
                              showProfileVerification: true,
                              profileVerificationType: data.type,
                            })}
                            className={'btn btn-link p-0 m-0 btn-sm'}
                          >Click to Verify</button>
                        }
                      </React.Fragment>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className={'row'}>
              <div className={'col-12'}>
                <table className={'table table-borderless table-sm'}>
                  <tbody>
                    <tr>
                      <td className={'w-25 text-muted'}>Transaction Notification</td>
                      <td>
                        <BadgeStatus successText={'Enabled'} failText={'Disabled'} status={guest.subscribe_transactions}/>
                      </td>
                    </tr>

                    <tr>
                      <td className={'w-25 text-muted'}>Promotional Notification</td>
                      <td>
                        <BadgeStatus successText={'Enabled'} failText={'Disabled'} status={guest.subscribe_promotions}/>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {additionalContent &&
              <React.Fragment>
                <p><strong>Bank Account</strong></p>
                <hr/>
                <table className={'table table-borderless table-sm'}>
                  <tbody>
                    <tr>
                      <td className={'w-25 text-muted'}>Account Number</td>
                      <td>{guest.bank_account_number || '...'}</td>
                    </tr>
                    <tr>
                      <td className={'w-25 text-muted'}>Bank Name</td>
                      <td>{guest.bank_account_name || '...'} {guest.bank_account_code && `, Code : ${guest.bank_account_code}`}</td>
                    </tr>
                    <tr>
                      <td className={'w-25 text-muted'}>Address</td>
                      <td>{guest.headerShown ? guest.bank_account_address : '...'}</td>
                    </tr>
                  </tbody>
                </table>
              </React.Fragment>
            }
          </div>
          {showSideBar &&
            <div className="col-lg-3 col-md-4 col-sm-12 mb-5">
              <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  <Link to={`${baseUrl}/edit`} className="btn btn-link m-0 p-0">
                    <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit
                  </Link>
                </li>
              </ul>
            </div>
          }
        </div>

        <ProfileVerification
          profile={guest}
          type={this.state.profileVerificationType}
          show={this.state.showProfileVerification}
          onHide={() => this.setState({showProfileVerification: false})}
          updateResponse={this.props.reloadData}
        />

        <ProfileDetailsEditShort
          guest={guest}
          property={this.props.property}
          expandEdit={this.state.expandEdit}
          updateResponse={this.props.reloadData}
          show={this.state.showProfileDetailsEditShort}
          onHide={()=>this.setState({showProfileDetailsEditShort: false})}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: false,
      showProfileVerification: false,
      profileVerificationType: 'email',
    };
  }
}

export default ProfileDetails;
