/**
 * Booking Lookup
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Moment from 'moment';
// Consts and Libs
import AppAPI from '../../lib/api';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';

import LookupResults from './components/LookupResults';

import DatesView from '../../components/dashboard/DatesView';
import Error from '../../components/general/Error';
import DateRangePicker from 'react-daterange-picker';
import Loading from '../../components/general/Loading';
import 'react-daterange-picker/dist/css/react-calendar.css';
// Actions
import * as BookingActions from '../../redux/booking/actions';
import PageHeader from '../../components/dashboard/PageHeader';
import BookingListView from './BookingListView';

/* Redux ======================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  booking: state.booking,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setLookup: BookingActions.setLookup,
  unSetLookup: BookingActions.unSetLookup,
  setSelectionData: BookingActions.setSelectionData,
};

/* Component ==================================================================== */
class BookingLookUpResults extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    if (this.props.match.params.bookingId && Object.keys(this.props.booking.booking).length === 0) {
      this.props.history.replace('/booking/' + this.props.match.params.bookingId + '/');
    }
    if (this.props.match.params.checkin || this.props.match.params.checkout) {
      let checkin = Moment(String(this.props.match.params.checkin));
      let checkout = Moment(String(this.props.match.params.checkout));
      this.setState({
        checkin: checkin,
        checkout: checkout,
        loading: false,
      });
      this.lookup(checkin.format('YYYY-MM-DD'), checkout.format('YYYY-MM-DD'));
    } else {
      this.props.unSetLookup();
    }
  };

  performLookup = () => {
    let checkin = Moment(this.state.checkin).format('YYYY-MM-DD');
    let checkout = Moment(this.state.checkout).format('YYYY-MM-DD');

    if (this.props.match.params.bookingId && Object.keys(this.props.booking.booking).length !== 0) {
      this.props.history.replace(`/booking/${this.props.match.params.bookingId}/lookup/${checkin}/to/${checkout}/`);
    } else {
      this.props.history.replace(`/booking/lookup/${checkin}/to/${checkout}/`);
    }

  };

  lookup = (checkin, checkout) => {
    let url = 'init/';
    if (this.props.match.params.bookingId && Object.keys(this.props.booking.booking).length !== 0) {
      url = `booking/${this.props.match.params.bookingId}/init/`;
    }
    if (checkin && checkout) {
      this.setState({loadingInner: true, results: []});
      AppAPI.bookingapi.post(url, {
        checkin: checkin,
        checkout: checkout,
        property: this.props.property.id,
      })
        .then(res => {
          this.setState({resultMsg: {success: ''}}, () => {
            this.props.setLookup(res);
            setTimeout(() => {
              this.setState({
                resultMsg: {error: ''},
                loadingInner: false,
                loading: false,
                updateSearch: false,
                checkin: Moment(res.checkin),
                checkout: Moment(res.checkout),
              });
            }, 500);
          });
        })
        .catch(err => {
          this.props.unSetLookup();
          const error = AppAPI.handleError(err);
          this.setState({loadingInner: false, loading: false, error: error});
        });
    }
  };

  onSelect = (date) => {
    this.setState({
      checkin: date.start,
      checkout: date.end,
      updateSearch: true,
    });
  };

  toggleDatePicker = () => {
    this.setState({showDatePicker: !this.state.showDatePicker});
  };

  finishBooking = (selectionData) => {
    if (selectionData) {
      this.props.setSelectionData(selectionData);
      if (this.props.match.params.bookingId && Object.keys(this.props.booking.booking).length !== 0) {
        this.props.history.push(`/booking/${this.props.match.params.bookingId}/add`);
      } else {
        this.props.history.push('/booking/add');
      }
    }
  };

  render = () => {
    const {lookupresponse} = this.props.booking;
    const {showDatePicker, loading, checkin, checkout, updateSearch, loadingInner, error} = this.state;
    if (loading) return <Loading/>;

    const title = (lookupresponse && !_.isEmpty(lookupresponse.booking)) ? 'Add Room' : 'Add Booking';
    return (
      <div className="booking-view screen-container">
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <PageHeader history={this.props.history} title={title}/>


        <div className={'lookup-box-container mt-2'}>
          <div className={'row'}>
            <div className={'col-lg-5 col-md-12'}>
              <div className="date-selector ">
                <DatesView checkout={checkout} checkin={checkin} onClick={this.toggleDatePicker}/>
                <div className={(showDatePicker ? 'collapse.show' : 'collapse') + ' w-100'} id="datePicker">
                  <div className="bg-white">
                    <div className="text-center mt-2">
                      <DateRangePicker
                        className={'w-100'}
                        numberOfCalendars={2}
                        selectionType={'range'}
                        onSelect={this.onSelect}
                        value={Moment.range(checkin, checkout)}
                        minimumDate={Moment().subtract(14, 'hours')}
                      />
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-success btn-lg btn-block my-3"
                  onClick={() => this.performLookup()}
                  disabled={!updateSearch}
                >
                  <h5 className={'m-0 p-0'}>
                    {lookupresponse ? 'Update Search' : 'Lookup Availability'}
                    <FontAwesomeIcon className={'white-cl ml-2'} icon={faArrowRight} size={'sm'}/>
                  </h5>
                </button>

                {(lookupresponse && !_.isEmpty(lookupresponse.booking)) &&
                 <div>
                   <h6>Booking</h6>
                   <BookingListView
                     data={lookupresponse.booking}
                     disabled={true}
                   />
                 </div>
                }
              </div>
            </div>
            <div className={'col-lg-7 col-md-12'}>
              {loadingInner ?
                <Loading content={true}/>
                :
                <div>
                  {!_.isEmpty(lookupresponse) ?
                    <div>
                      <div className={'row'}>
                        <div className={'col-sm-12 col-md-12 col-lg-12'}>
                          <LookupResults lookupResponse={lookupresponse} action={this.finishBooking}/>
                        </div>
                      </div>
                    </div>
                    :
                    <div className="empty">
                      {error && <Error full={false} text={error}/> }
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      updateSearch: true,
      showDatePicker: true,
      checkin: Moment().startOf('day'),
      checkout: Moment().add(1, 'days'),
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      results: [],
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingLookUpResults);
