/**
 *
 * Booking Actions
 * No Show / Cancel
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import {Strings} from '../../constants';
import {Alerts} from '../../components/ui';
import Error from '../../components/general/Error';
import PageHeader from '../../components/dashboard/PageHeader';
import Loading from '../../components/general/Loading';
import 'react-daterange-picker/dist/css/react-calendar.css';
// Actions
import * as BookingActions from '../../redux/booking/actions';
import BookingRoomListView from '../bookingroom/BookingRoomListView';
import OrderListView from '../order/OrderListView';
// Components

/* Redux ======================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  booking: state.booking.booking,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setBooking: BookingActions.setBooking,
};

/* Component ==================================================================== */
class BookingLookUpResults extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };


  componentDidMount = () => {
    if (this.props.match.params.bookingId) {
      if (Object.keys(this.props.booking).length === 0) {
        this.props.history.push('/booking/' + this.props.match.params.bookingId);
      } else {
        this.fetchInitData();
      }
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };


  fetchInitData = () => {
    const checkbox = [],
      selectedRooms = [];
    let i;
    if (this.props.bookingRoom) {
      selectedRooms.push(this.props.bookingRoom);
    }

    let actionType = 'Cancel';
    if (String(this.props.match.params.bookingAction) === 'no-show') {
      actionType = 'No Show';
    }

    AppAPI.bookingapi
      .get('booking/' + this.props.booking.id + '/change/')
      .then(res => {
        if (res.actions !== '') {
          for (i in res.messages) {
            checkbox[i] = false;
          }
          this.setState({
            loading: false,
            messages: res.messages,
            booking_room: res.booking_room,
            order: res.order,
            checkbox,
            selectedRooms,
            actionType: actionType,
          });
        } else {
          this.setState({
            loading: false,
            error: true,
          });
        }
      })
      .catch(err => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: error,
          resultMsg: {error},
        });
      });
  };

  action = () => {

    let payload = {
      rooms_selected: this.state.selectedRooms,
      orders_selected: this.state.selectedOrders,
    };
    if (String(this.props.match.params.bookingAction) === 'cancel') {
      payload.booking_cancel = true;
    }

    if (String(this.props.match.params.bookingAction) === 'no-show') {
      payload.booking_no_show = true;
    }

    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.bookingapi
        .post('booking/' + this.props.booking.id + '/change/', payload)
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
              this.props.setBooking(res);
              this.props.history.replace('/booking/' + res.hash);
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    });
  };

  selectRoom = room => {
    let {selectedRooms} = this.state;
    selectedRooms = AppUtil.insertOrRemoveArray(selectedRooms, room.id);
    this.setState({selectedRooms});
  };

  selectOrder = order => {
    let {selectedOrders} = this.state;
    selectedOrders = AppUtil.insertOrRemoveArray(selectedOrders, order.id);
    this.setState({selectedOrders});
  };

  render = () => {
    const {resultMsg, checkbox, loading, error, messages, booking_room, order, actionType, selectedOrders, selectedRooms} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;


    return (
      <div className="booking-view screen-container">
        <Helmet>
          <title>Booking Action</title>
        </Helmet>

        <PageHeader
          history={this.props.history} title={'Booking ' + actionType}
          description={'Change booking status to ' + String(actionType).toLowerCase()}/>

        <div className={'booking-details-container'}>
          <div className={'container'}>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className={'row'}>
                  {booking_room && booking_room.length > 0 &&
                   <div className={'col-lg-4 col-md-6 col-sm-12 mb-3'}>
                     <h6>Bookings</h6>
                     <p className={'small'}>Select the booking(s) to {actionType}</p>
                     {booking_room.map((data, key) => (
                       <BookingRoomListView
                         data={data}
                         key={key}
                         history={this.props.history}
                         selectable={true}
                         selected={selectedRooms.includes(data.id)}
                         disabled={true}
                         action={this.selectRoom}
                       />
                     ))}
                   </div>
                  }
                  {order && order.length > 0&&
                   <div className={'col-lg-4 col-md-6 col-sm-12 mb-3'}>
                     <h6>Orders [Optional]</h6>
                     <p className={'small'}>Select the order(s) to Cancel.</p>
                     {order.map((data, key) => (
                       <OrderListView
                         data={data}
                         key={key}
                         selectable={true}
                         disabled={true}
                         selected={selectedOrders.includes(data.id)}
                         action={this.selectOrder}
                         history={this.props.history}
                       />
                     ))}
                   </div>
                  }
                </div>
                <div className={'row'}>
                  {
                    (messages && messages.length > 0) &&
                    <div className={'col-md-6 col-lg-4 col-sm-12 mb-3'}>
                      <h6>Attention</h6>
                      {Strings.actionReviewDescription.map((data, i) => (
                        <p className={'small mb-0'} key={i}>{data}</p>
                      ))}

                      <ul className="list-group mt-3">
                        {
                          messages.map((data, i) => (
                            <li
                              className="list-group-item a-class"
                              key={i}
                            >
                              <div className={'custom-control custom-switch'}>
                                <input
                                  id={`e${i}`}
                                  type="checkbox"
                                  className={'custom-control-input mr-3'}
                                  checked={checkbox[i]}
                                  onClick={() => {
                                    checkbox[i] = !checkbox[i];
                                    this.setState({checkbox});
                                  }}
                                />
                                <label className="custom-control-label" htmlFor={`e${i}`}>
                                  {data}
                                </label>
                              </div>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                  }
                </div>
                <div className={'row'}>
                  <div className={'col-md-4 col-sm-12 mb-3'}>
                    <Alerts
                      status={resultMsg.status}
                      success={resultMsg.success}
                      error={resultMsg.error}
                    />

                    <p className={'small'}>Select at least one room and check the actions.</p>
                    <button
                      className={'btn btn-block btn-lg ' + (String(actionType).toLowerCase() === 'cancel' ? 'btn-danger' : 'btn-warning')}
                      disabled={selectedRooms.length < 1 || String(checkbox).includes('false')}
                      onClick={() => this.action()}
                    >
                      {actionType}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      messages: [],
      booking_room: [],
      order: [],
      selectedRooms: [],
      selectedOrders: [],
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      actionType: 'Cancel',
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingLookUpResults);
