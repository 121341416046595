/**
 * Global App Config
 *
 */

import AppAPI from './api';

let environment = 'production';
if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
  environment = process.env.REACT_APP_ENVIRONMENT;
}


export default {
  // App Details
  appName: 'Renzo',
  APITokenIssuer: 'Renzo Inc API',
  DEV: (environment !== 'production' && environment !== 'staging'),
  environment: environment,
  endpoint: AppAPI.hostname,
  version: process.env.REACT_APP_VERSION,
  buildTime: process.env.REACT_APP_BUILD_TIME,
  hostname: '//renzo.in',
  dashboardHostname: process.env.REACT_APP_HOST_NAME,
  localStorageKeys: {
    RELEASE_VERSION: 'system/version',
    USER_TOKEN: 'user/token',
    PROPERTY_ID: 'property/token',
    PROPERTY_TOKEN: 'property/token',
  },
  permissions: [
    {
      'value': 'pointofsales',
      'name': 'Point of Sales',
      'action' : '/pos'
    },
    {
      'value': 'frontdesk',
      'name': 'Front Desk',
      'action' : '/booking'
    },
    {
      'value': 'housekeeping',
      'name': 'House Keeping',
      'action' : '/house-keeping'
    },
  ],
  regXValidation: {
    phoneRegex: /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  }
};
