import React from 'react';
import {Route, Switch} from 'react-router-dom';

import POSList from './POSList';
import POSDetails from './POSDetails';
import NotFound from '../NotFound';

import posDetailsRequired from '../../components/decorators/posDetailsRequired';

const POSRoute = () => (
  <div>

    <div className="container">
      <Switch>
        <Route path="/point-of-sale/" exact component={POSList}/>
        <Route path="/point-of-sale/:POSId" component={posDetailsRequired(POSDetails)}/>
        <Route exact component={NotFound}/>
      </Switch>
    </div>

  </div>
);

export default POSRoute;