/**
 * Date View
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */
class FilterButton extends Component {
  static componentName = 'FilterButton';

  static propTypes = {
    data: PropTypes.array,
    size: PropTypes.string,
    action: PropTypes.func,
    title: PropTypes.string,
    selectKey: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    removeFilter: PropTypes.bool,
    classNameActive: PropTypes.string,
  };

  static defaultProps = {
    className: 'btn-outline-secondary',
    classNameActive: 'btn-outline-primary',
    size: 'md',
    selection: null,
    removeFilter: true,
    disabled: false,
  };

  action = (data) => {
    if (this.props.action) {
      this.props.action(data);
    }
  };

  render = () => {
    const {title, size, data, className, classNameActive, selectKey, removeFilter, disabled} = this.props;
    let selection = null, selectionData;
    if (data){
      selectionData = data.map((item, i) => {
        let active = (selectKey && selectKey === item.key);
        if (active && selection !== item.title) {
          selection = item.title;
        }
        return (
          <div
            className={`dropdown-item ${item.className} ${active && ' active '} a-class`} key={i}
            onClick={() => (typeof item.action === 'function' ? item.action(item) : this.action(item))}
          >
            {item.icon && <FontAwesomeIcon icon={item.icon} size={'sm'} className={'mr-2'}/>} {item.title}
          </div>
        );
      });
    }

    return (
      <div className="btn-group">
        <button
          disabled={disabled}
          className={`btn ${selection ? classNameActive : className} btn-${size}`}
          type="button"
        >
          {title && title} {selection && ` : ${selection}`}
        </button>
        <button
          disabled={disabled}
          type="button"
          className={`btn btn-${size} ${selection ? classNameActive : className} dropdown-toggle dropdown-toggle-split`}
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span className="sr-only">Toggle Dropdown</span>
        </button>
        <div className="dropdown-menu">
          {selectionData}
          {removeFilter &&
           <React.Fragment>
             <div className="dropdown-divider"/>
             <div className={'dropdown-item red-cl a-class'} onClick={() => this.action({title: 'remove'})}>
               <FontAwesomeIcon icon={faTimes} size={'sm'} className={'mr-2'}/> Remove
             </div>
           </React.Fragment>
          }
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      selection: null,
    };
  }
}

/* Export Component ==================================================================== */
export default FilterButton;