import React from 'react';
import {Redirect, NavLink, Route, Switch} from 'react-router-dom';


import NotFound from '../NotFound';
import GuestList from '../guest/GuestList';
import ProfileManage from '../guest/ProfileManage';
import profileRequired from '../../components/decorators/profileRequired';
import GuestDetails from '../guest/GuestDetails';

const menu = [
  {
    title: 'Guest',
    link: '/settings/customer/guest',
    isExact: false,
  },
  {
    title: 'Agent',
    link: '/settings/customer/agent',
    isExact: false,
  },
  {
    title: 'Corporate',
    link: '/settings/customer/corporate',
    isExact: false,
  },
];
const menuItems = [];

menu.map((item) => {
  const {title, link, isExact} = item;
  return menuItems.push(
    <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
      <NavLink
        to={{
          pathname: link,
          state: {fromDashboard: true},
        }}
        exact={isExact}
        className="nav-link rounded-0"
        activeClassName="selected">
        {title}
      </NavLink>
    </li>,
  );
});


const guestDetails = () => (
  <div>
    <Switch>
      <Route render={(props) => <GuestDetails {...props} settings={true} isAgent={true}/>}/>
    </Switch>
  </div>
);

const ProfileRoute = () => (
  <div>
    <div className={'row'}>
      <div className={'col col-lg-2 col-md-3 col-sm-12 mb-5'}>
        <ul className="nav nav-pills flex-md-column flex-sm-row border rounded mb-3">
          {menuItems}
        </ul>
      </div>
      <div className={'col-lg-10 col-md-9 col-sm-12'}>
        <Switch>
          <Route exact path="/settings/customer/" render={() => (<Redirect to="/settings/profile/guest"/>)}/>
          <Route
            path="/settings/customer/guest" exact
            render={(props) => <GuestList {...props} subHeader={true} isGuest={true}/>}/>
          <Route
            path="/settings/customer/guest/new" exact
            render={(props) => <ProfileManage {...props} subHeader={true} isGuest={true}/>}/>
          <Route path="/settings/customer/guest/:guestId/" component={profileRequired(guestDetails)}/>


          <Route
            path="/settings/customer/agent" exact
            render={(props) => <GuestList {...props} subHeader={true} isAgent={true}/>}/>
          <Route
            path="/settings/customer/agent/new" exact
            render={(props) => <ProfileManage {...props} subHeader={true} isAgent={true}/>}/>
          <Route path="/settings/profile/agent/:guestId/" component={profileRequired(GuestDetails)}/>

          <Route
            path="/settings/customer/corporate" exact
            render={(props) => <GuestList {...props} subHeader={true} isCorporate={true}/>}/>
          <Route
            path="/settings/customer/corporate/new" exact
            render={(props) => <ProfileManage {...props} subHeader={true} isCorporate={true}/>}/>
          <Route path="/settings/customer/corporate/:guestId/" component={profileRequired(GuestDetails)}/>

          <Route exact component={NotFound}/>
        </Switch>
      </div>
    </div>
  </div>
);

export default ProfileRoute;