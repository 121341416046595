/**
 *
 * Room Type Details
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// Consts and Libs
import {Constants, Strings} from '../../../constants';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faLink, faPen, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as RoomTypeActions from '../../../redux/roomtype/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  roomType: state.roomType.roomType,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setRoomType: RoomTypeActions.setRoomType,
};

/* Component ==================================================================== */
class RoomTypeDetails extends Component {
  static componentName = 'RoomTypeDetails';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };
  componentDidMount = () => {
    if (this.props.match.params.roomTypeId) {
      this.setState({
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };
  render = () => {

    const {loading, error} = this.state;
    const {roomType, property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    return (
      <div className="room-type-details screen-container ">
        <Helmet>
          <title>{roomType.name} | Details</title>
        </Helmet>

        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12">
            <div className="row ">
              <div className="col mb-3">
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Reference</strong> : {roomType.ref_no}
                  </li>
                  <li className="list-group-item"><strong>Name</strong> : {roomType.name}</li>
                  <li className="list-group-item">
                    <strong>Room Type Code</strong> : {roomType.channel_manager_room_type_id || 'N.A'}
                  </li>

                </ul>
              </div>
              <div className="col mb-3">
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Status</strong> :
                    <span
                      className={'badge ' + (roomType.active ? 'badge-success' : 'badge-danger')}>
                      {(roomType.active ? 'Active' : 'Deactivated')}
                    </span>
                  </li>
                  <li className="list-group-item">
                    <strong>Booking Engine</strong> :
                    <span
                      className={'badge ' + (roomType.booking_engine_ok ? 'badge-success' : 'badge-danger')}>
                      {(roomType.booking_engine_ok ? 'Connected' : 'Not Connected')}
                    </span>
                  </li>

                  <li className="list-group-item">
                    <strong>Channel Manager</strong> :
                    <span
                      className={'badge ' + (roomType.channel_manager_ok ? 'badge-success' : 'badge-danger')}>
                      {(roomType.channel_manager_ok ? 'Connected' : 'Not Connected')}
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className={'col-12 mb-3'}>
                <div className={'table-responsive'}>
                  <table className={'table border table-sm'}>
                    <thead>
                      <tr className={' bg-dark text-light text-center'}>
                        <th colSpan={5} className={'text-center'}>Occupancy</th>
                      </tr>
                      <tr className={'text-center table-active'}>
                        <td>Base Guest</td>
                        <td>Max Guest</td>
                        <td>Child</td>
                        <td>Infant</td>
                        <td>Min Guest</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className={'text-center'}>{roomType.base_occupancy}</td>
                        <td className={'text-center'}>{roomType.max_occupancy}</td>
                        <td className={'text-center'}>{roomType.child_occupancy}</td>
                        <td className={'text-center'}>{roomType.infant_occupancy}</td>
                        <td
                          className={'text-center'}>{roomType.sell_below_min_occupancy ? roomType.min_occupancy : 'N.A'}</td>
                      </tr>
                      <tr>
                        <td colSpan={3} className={'text-center'}>
                          <strong>Sell below Min Occupancy</strong> :
                          <span
                            className={'badge ' + (roomType.sell_below_min_occupancy ? 'badge-success' : 'badge-danger')}>
                            {(roomType.sell_below_min_occupancy ? 'yes' : 'No')}
                          </span>
                        </td>
                        <td colSpan={2}>
                          {roomType.sell_below_min_occupancy &&
                           <React.Fragment>
                             <strong>Discount Per
                               Guest</strong> : {roomType.discount_per_guest} {roomType.discount_type === Constants.TAX_TYPE.FIXED ? property.currency : '%'}
                           </React.Fragment>
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row">
              <div className={'col-12 mb-3'}>
                <div className={'table-responsive'}>
                  <table className={'table border table-sm'}>
                    <thead>
                      <tr className={' bg-dark text-light text-center'}>
                        <th colSpan={10} className={'text-center'}>Packages</th>
                      </tr>
                      <tr className={'table-active'}>
                        <td>Name</td>
                        <td>Base</td>
                        <td> &lt; Min</td>
                        <td>Additional</td>
                        <td>Child</td>
                        <td>Infant</td>
                        <td>Code</td>
                        <td>CM<sup>*</sup></td>
                        <td>BE<sup>*</sup></td>
                      </tr>
                    </thead>
                    <tbody>
                      {(roomType.room_type_packages && roomType.room_type_packages.length > 0) ?
                        <React.Fragment>
                          {roomType.room_type_packages.map((room_type_package, j) => (
                            <tr className={room_type_package.active ? ' ' : 'table-secondary'} key={j}>
                              <td>
                                <Link
                                  to={`/settings/inventory/room-type/${roomType.id}/package/${room_type_package.id}/`}>
                                  <FontAwesomeIcon icon={faLink} size={'sm'} className={'mr-2'}/>
                                </Link>
                                {room_type_package.name}</td>
                              <td>{property.currency} {room_type_package.base_price_effective}</td>
                              <td>{roomType.discount_per_guest} {roomType.discount_type === Constants.TAX_TYPE.FIXED ? property.currency : '%'}</td>
                              <td>{property.currency} {room_type_package.guest_price_effective}</td>
                              <td>{property.currency} {room_type_package.child_price_effective}</td>
                              <td>{property.currency} {room_type_package.infant_price_effective}</td>
                              <td>{room_type_package.channel_manager_package_id || 'Nil'}</td>
                              <td>
                                <FontAwesomeIcon
                                  icon={room_type_package.channel_manager_ok ? faCheckCircle : faTimesCircle}
                                  className={room_type_package.channel_manager_ok ? 'green-cl' : 'red-cl'}
                                />
                              </td>
                              <td>
                                <FontAwesomeIcon
                                  icon={room_type_package.booking_engine_ok ? faCheckCircle : faTimesCircle}
                                  className={room_type_package.booking_engine_ok ? 'green-cl' : 'red-cl'}
                                />
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan={10}>
                              <small>{Strings.roomTypePriceListAbbreviation}</small>
                            </td>
                          </tr>
                        </React.Fragment>
                        :
                        <tr>
                          <td colSpan={10} className={'text-center py-3'}>
                            <h6>No Packages</h6>
                            {
                              Strings.roomTypePackageEmptyText.map((data, i) => <p key={i} className={'small mb-1'}>{data}</p>)
                            }
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
                <ul className={'list-inline'}>
                  <li className={'list-inline-item'}>CM : Channel Manager</li>
                  <li className={'list-inline-item'}>BE : Booking Engine</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item">
                <Link
                  to={'/settings/inventory/room-type/' + roomType.id + '/edit'}
                  className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit
                </Link>
              </li>
              <li className="list-group-item">
                <Link
                  to={'/settings/inventory/room-type/' + roomType.id + '/package/'}
                  className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Packages
                </Link>
                <p className={'mb-0 text-muted small'}>Manage room type packages</p>
              </li>
              <li className="list-group-item">
                <Link
                  to={'/settings/inventory/room-type/' + roomType.id + '/room/'}
                  className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Rooms
                </Link>
                <p className={'mb-0 text-muted small'}>Manage rooms</p>
              </li>

              <li className="list-group-item">
                <Link
                  to={'/settings/inventory/room-type/' + roomType.id + '/booking-engine/slider-images/'}
                  className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Slider Images
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomTypeDetails);