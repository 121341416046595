/**
 *
 * Property public display settings
 * public details management
 */

import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import _ from 'lodash';
// Consts and Libs
import {Strings} from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  propertyContent: state.property.content,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PropertyContent extends Component {
  static componentName = 'PropertyPublic';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
  };


  render = () => {

    const {loading, error} = this.state;
    const {propertyContent, property} = this.props;
    const printContent = propertyContent.print_content || {};
    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="bank-details screen-container ">
        <Helmet>
          <title>Policy Links</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'Contents'}
          description={'Descriptions, footer notes and other links.'}
        />
        <div className={'row'}>
          <div className="col-lg-9 col-md-8 col-sm-12 mb-5">
            <div className={'border p-2 text-center anchor w-100'}>
              <h6 className={'mb-0'}>
                Policy Links
              </h6>
            </div>
            <div className={'border p-2 mb-3'} id={'primaryAction'}>
              <p className={'text-muted small'}>
                {Strings.propertyPolicyLinks}
              </p>
              <hr/>
              {propertyContent.policy_links ?
                <ul className="list-group list-group-horizontal-sm">
                  {propertyContent.policy_links && propertyContent.policy_links.map((data, i) => (
                    <li className="list-group-item" key={i}>
                      {data.title} : {data.link}
                    </li>
                  ))}
                </ul>
                :
                <p>
                  No Policy links
                </p>
              }
            </div>

            <hr/>


            <div className={'border p-2 text-center anchor w-100'}>
              <h6 className={'mb-0'}>
                Order/Payment/Refund Invoice Settings
              </h6>
            </div>
            <div className={'border p-2 mb-3'} id={'primaryAction'}>
              <div className={'row'}>
                <div className={'col-2'}>
                  <img
                    width={75}
                    src={property.logo.logo} alt={property.name} className="img-thumbnail"
                  />
                </div>
                <div className={'col-10 text-center'}>
                  {_.isEmpty(printContent.financial) ?
                    <p className={'text-muted text-center'}>No Contents Set</p>
                    :
                    <p>
                      <strong>{printContent.financial.header_title || 'N.A'}</strong> <br/>
                      {printContent.financial.header_sub_title || 'N.A'}
                    </p>
                  }
                </div>
              </div>
              <hr/>

              <div className={'bg-light py-5 text-center'}>
                <p className={'text-muted'}>Order/Payment/Refund Content</p>
              </div>

              <hr/>
              {_.isEmpty(printContent.financial) ?
                <p className={'text-muted text-center'}>No Contents Set</p>
                :
                <p className="mb-1">
                  <pre className={'w-100'}>{printContent.financial.footer_notes || 'N.A'}</pre>
                </p>
              }
              {!_.isEmpty(printContent.financial)  && (
                <>
                  <hr/>
                  <p className={'mb-1 text-muted small'}>Additional Configuration : </p>
                  <div className={'row'}>
                    <div className={'col-6'}>
                      <p className="mb-1">
                        Date Title : {_.startCase(printContent.financial.order_date_title || 'Default')}
                      </p>
                    </div>
                    <div className={'col-6'}>
                      <p className="mb-1">
                        Order Date : {_.startCase(printContent.financial.order_date_config || 'Default')}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>


            <hr/>

            <div className={'border p-2 text-center anchor w-100'}>
              <h6 className={'mb-0'}>
                Booking Settings
              </h6>
            </div>
            <div className={'border p-2 mb-3'} id={'primaryAction'}>
              <div className={'row'}>
                <div className={'col-2'}>
                  <img
                    width={75}
                    src={property.logo.logo} alt={property.name} className="img-thumbnail"
                  />
                </div>
                <div className={'col-10 text-center'}>
                  {_.isEmpty(printContent.booking) ?
                    <p className={'text-muted text-center'}>No Contents Set</p>
                    :
                    <p>
                      <strong>{printContent.booking.header_title || 'N.A'}</strong> <br/>
                      {printContent.booking.header_sub_title || 'N.A'}
                    </p>
                  }

                </div>
              </div>
              <hr/>

              <div className={'bg-light py-5 text-center'}>
                <p className={'text-muted'}>Booking Content</p>
              </div>

              <hr/>
              {_.isEmpty(printContent.booking) ?
                <p className={'text-muted text-center'}>No Contents Set</p>
                :
                <p className="mb-1">
                  <pre className={'w-100'}>{printContent.booking.footer_notes || 'N.A'}</pre>
                </p>
              }
            </div>

            {/*<div className={'border p-2 text-center anchor w-100'}>*/}
            {/*  <h6 className={'mb-0'}>*/}
            {/*    Booking/Ticket Footer Notes*/}
            {/*  </h6>*/}
            {/*</div>*/}
            {/*<div className={'border p-2 mb-3'} id={'primaryAction'}>*/}
            {/*  <p className={'text-muted small'}>*/}
            {/*    {Strings.ticketFooterNotes}*/}
            {/*  </p>*/}
            {/*  <hr/>*/}
            {/*  <p className="mb-1">*/}
            {/*    <pre>{propertyContent.booking_print_footer_notes || 'N.A'}</pre>*/}
            {/*  </p>*/}
            {/*</div>*/}

          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 mb-5">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link to={'property-content/manage'} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      propertyContent: [],
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyContent);
