/**
 * Loading
 *
 */
import React from 'react';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */
class Loading extends React.PureComponent {
  static componentName = 'Loading';

  static propTypes = {
    size: PropTypes.string,
    message: PropTypes.string,
    heightMatch: PropTypes.bool,
  };

  static defaultProps = {
    size: '2x',
    heightMatch: true,
    message: 'Loading',
  };

  componentDidMount = () => {
    if (document && document.body){
      this.setState({height: document.body.scrollHeight * 0.7});
    }
  };

  render = () => {
    const {heightMatch, size, message} = this.props;
    return (
      <div
        className="d-flex justify-content-center"
        style={{height: (this.state.height && heightMatch ? this.state.height + 'px' : 'auto')}}
      >
        <div className="align-self-center text-center">
          <FontAwesomeIcon icon={faSpinner} size={size} pulse/>
          {message && <p className="mt-4 small text-muted">{message}</p>}
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      height: null,
    };
  }
}

/* Export Component ==================================================================== */
export default Loading;
