/**
 * ExpenseListView
 *  View for Expense Listing
 *
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
// Consts and Libs
import AppUtil from '../../lib/util';
// Actions
import * as ExpenseActions from '../../redux/expense/actions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckSquare, faSquare} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setExpense: ExpenseActions.setExpense,
};

/* Component ==================================================================== */
class ExpenseListView extends Component {
  static componentName = 'ExpenseListView';

  static propTypes = {
    data: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  action = (data) => {
    this.props.setExpense(data);
    this.props.history.push('/expense/' + data.hash);
  };

  render = () => {
    const {data, selected, selectable} = this.props;
    return (
      <div
        role="presentation"
        onKeyPress={this.handleKeyPress}
        onClick={() => this.action(data)}
        key={this.props.id}
        className="list-group-item list-group-item-action flex-column align-items-start a-class">
        <div className="d-flex w-100 justify-content-between">
          <div>
            <h5 className="mb-1">
              {selectable &&
               <FontAwesomeIcon className={'mr-1'} icon={selected ? faCheckSquare : faSquare} size={'sm'}/>
              }
              {data.ref_no}
            </h5>
            {(data.attributes && data.attributes.department) &&
             <p className="mb-0 small">Department : {data.attributes.department}</p>
            }
            <p className="mb-0 small text-muted">{AppUtil.formatDateTime(data.created)}</p>
          </div>

          <div className={'text-right float-right'}>
            <span
              className={'badge badge-light'}>
              {data.currency + ' ' + data.total.toString()}
            </span>
            <br/>
            {(!data.lock) ?
              <span className={'badge badge-warning small'}>Open</span>
              :
              <React.Fragment>
                <span className={'badge ' + AppUtil.paymentStatusColor(data.order_status)}>
                  {data.order_status_display}
                </span>
              </React.Fragment>
            }
          </div>
        </div>
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(ExpenseListView);

