/**
 *
 * Features Booking
 * *
 */

import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

// Components
import Loading from '../components/general/Loading';
import Error from '../components/general/Error';

/* Component ==================================================================== */
class Index extends Component {

  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error text={error}/>;

    return (
      <div className="index screen-container">
        <Helmet>
          <title>Its a 404!</title>
          <meta name="description" content="The content you are looking for is not found."/>
        </Helmet>

        <div className={'p-3 mt-3 mb-0 text-center'}>
          <div className={'col-md-12 p-lg-2'}>
            <h1><span className={'fa fa-times-circle fa-1x red-cl'}/> 404 Error !</h1>
            <p className={'lead'}>Hmmm, that page doesn't exist.</p>
            <p>You are seeing this page because the URL you requested does not exist.</p>
            <p>Please <Link to={'/contact'}>report to us</Link> if you think this is an error.</p>
            <Link className={'btn btn-success'} to={'/'}>Back to Renzo</Link>
          </div>
        </div>
      </div>
    );
  };

  constructor (props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}

export default Index;