/**
 * Order item deductions overview
 *
 */

import PropTypes from 'prop-types';
import React, {Component} from 'react';
// Consts and Libs
import AppUtil from '../../../../lib/util';
import { Constants } from '../../../../constants';
// Components
import PageHeader from '../../../../components/dashboard/PageHeader';

/* Component ==================================================================== */
class OAItemDeduction extends Component {
  static componentName = 'OAItemDeduction';

  static propTypes = {
    property: PropTypes.object.isRequired,
    orderData: PropTypes.object.isRequired,
    deductionKey: PropTypes.string.isRequired,
    deductionType: PropTypes.string.isRequired,
    deductionTypeShort: PropTypes.string.isRequired,
  };


  render = () => {
    const {orderData, property, deductionType, deductionTypeShort, deductionKey} = this.props;

    let otherDeductionGlobal =0, taxSumGlobal = 0;

    const deductionPercentage = AppUtil.calculatePercentage(
      AppUtil.sumArray(Object.values(orderData[deductionKey]), 'float'),
      AppUtil.sumArray(Object.values(orderData.orderSubTotal), 'float')
    );

    return (
      <>
        <PageHeader
          subHeader={true}
          title={deductionType}
          description={`${deductionType} orders bifurcation based on source.`}
        />

        <div className={'row mb-3'}>
          <div className={'col'}>
            <div className={'card mb-5'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col-6'}>
                    <p className={'text-muted mb-1'}>Total Order(s)</p>
                    <h4 className={'card-title mb-3'}>
                      {`${property.currency} ${AppUtil.sumArray(Object.values(orderData.orderTotal), 'float')}`}
                    </h4>
                  </div>
                  <div className={'col-6'}>
                    <p className={'text-muted mb-1'}>{deductionType}</p>
                    <h4 className={'blue-cl mb-3'}>
                      {`${property.currency} ${AppUtil.sumArray(Object.values(orderData[deductionKey]), 'float')}`}
                    </h4>
                  </div>
                </div>
                <p className={'text-muted mb-2'}>
                  {AppUtil.sumArray(Object.values(orderData.orderCount))} entries with {deductionType.toLowerCase()} items.
                </p>
              </div>
            </div>
          </div>
          <div className={'col'}>
            <div className={'card'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col '}>
                    <p className={'text-muted mb-1'}>Sub Total</p>
                    <h5 className={'card-title mb-0 pb-0'}>
                      {`${property.currency} ${AppUtil.sumArray(Object.values(orderData.orderSubTotal), 'float')}`}
                    </h5>
                  </div>
                  <div className={'col'}>
                    <p className={'text-muted mb-1'}>{deductionType}</p>
                    <h5 className={'card-title mb-0 pb-0 blue-cl'}>
                      {`${property.currency} ${AppUtil.sumArray(Object.values(orderData[deductionKey]), 'float')}`}
                    </h5>
                  </div>
                </div>
                <div className="progress mt-3 blue-bg">
                  <div
                    className="progress-bar green-bg"
                    style={{width: `${100-deductionPercentage}%`}}
                    aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"/>
                </div>
                <div className={'row'}>
                  <div className={'col-12 text-right'}>
                    <p className={'text-muted mb-0 mt-1 small'}>{deductionPercentage}% of sub total</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={'row'}>
          <div className={'col-12 col-lg-12 mb-3'}>
            <div className="table-responsive">
              <table className={'table table-bordered'}>
                <thead>
                  <tr>
                    <th>Order Source</th>
                    <th>Count</th>
                    <th>
                      Sub Total
                      <br/><small>(A)</small>
                    </th>
                    <th>
                      {deductionTypeShort}
                      <br/><small>(B)</small>
                    </th>
                    <th>
                      Deduction
                      <br/><small>(C)</small>
                    </th>
                    <th>
                      Tax
                      <br/><small>(D)</small>
                    </th>
                    <th>
                      Total
                      <br/><small>(A - B - C + D)</small>
                    </th>
                    <th>Paid</th>
                    <th>Pending</th>
                  </tr>
                </thead>
                <tbody>
                  {Constants.ORDER_SOURCES_INPUT.map((data, i) => {
                    const otherDeduction = (
                      (orderData.orderComplimentaryTotal[data.value] || 0) +
                      (orderData.orderNoChargeTotal[data.value] || 0) +
                      (orderData.orderDiscountTotal[data.value] || 0)  +
                      (orderData.orderDiscount[data.value] || 0)  +
                      (orderData.orderDiscountPostPayment[data.value] || 0) -
                      (orderData[deductionKey][data.value] || 0)
                    );
                    otherDeductionGlobal += otherDeduction;
                    const taxSum = (
                      (orderData.orderTax[data.value] || 0) +
                    (orderData.orderTaxTotal[data.value] || 0) +
                    (orderData.orderServiceCharge[data.value] || 0)
                    );
                    taxSumGlobal += taxSum;
                    return (
                      <tr className={'tr-align-middle'} key={i}>
                        <td className={'data-table-cell-lg'}>
                          {data.label}
                        </td>
                        <td className={'data-table-cell-xs text-center'}>
                          {orderData.orderCount[data.value] || 0 }
                        </td>
                        <td className={'data-table-cell-md text-center'}>
                          {(orderData.orderSubTotal[data.value] || 0).toFixed(2)}
                        </td>
                        <td className={'data-table-cell-md text-center lime-bg'}>
                          {(orderData[deductionKey][data.value] || 0).toFixed(2)}
                        </td>
                        <td className={'data-table-cell-md text-center'}>
                          {(otherDeduction).toFixed(2)}
                        </td>
                        <td className={'data-table-cell-md text-center'}>
                          {(taxSum).toFixed(2)}
                        </td>
                        <td className={'data-table-cell-md text-center'}>
                          {(orderData.orderTotal[data.value] || 0).toFixed(2)}
                        </td>
                        <td className={'data-table-cell-md text-center'}>
                          {(orderData.orderPaidPayment[data.value] || 0).toFixed(2)}
                        </td>
                        <td className={'data-table-cell-md text-center'}>
                          {((orderData.orderTotal[data.value] || 0 )-
                          (orderData.orderPaidPayment[data.value] || 0)).toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td><strong>Total</strong></td>
                    <td className={'data-table-cell-xs text-center'}>
                      <strong>{AppUtil.sumArray(Object.values(orderData.orderCount))}</strong>
                    </td>
                    <td className={'data-table-cell-md text-center'}>
                      <strong>{AppUtil.sumArray(Object.values(orderData.orderSubTotal), 'float')}</strong>
                    </td>
                    <td className={'data-table-cell-md text-center lime-bg'}>
                      <strong>{AppUtil.sumArray(Object.values(orderData[deductionKey]), 'float')}</strong>
                    </td>
                    <td className={'data-table-cell-md text-center'}>
                      <strong>{otherDeductionGlobal.toFixed(2)}</strong>
                    </td>
                    <td className={'data-table-cell-md text-center'}>
                      <strong>{taxSumGlobal.toFixed(2)}</strong>
                    </td>
                    <td className={'data-table-cell-md text-center'}>
                      <strong>{AppUtil.sumArray(Object.values(orderData.orderTotal), 'float')}</strong>
                    </td>
                    <td className={'data-table-cell-md text-center green-cl'}>
                      <strong>{AppUtil.sumArray(Object.values(orderData.orderPaidPayment), 'float')}</strong>
                    </td>
                    <td className={'data-table-cell-md text-center red-cl'}>
                      <strong>{(
                        AppUtil.sumArray(Object.values(orderData.orderTotal), 'float') -
                      AppUtil.sumArray(Object.values(orderData.orderPaidPayment), 'float')
                      ).toFixed(2)}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className={'mb-2 text-muted small'}>All values except count in {property.currency}</p>

              <p className={'mb-1 text-muted'}>Deduction : Includes sum of any other deduction in form of deductions like discounts etc excluding {deductionType.toLowerCase()}.</p>
              <p className={'mb-1 text-muted'}>Tax : Includes sum of tax on order level and item level.</p>
            </div>
          </div>
        </div>

      </>
    );
  };
}

/* Export Component ==================================================================== */
export default OAItemDeduction;

