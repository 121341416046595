/**
 *
 * Calender Occupancy
 */
import { faSync, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* eslint no-eval: 0 */
import _ from 'lodash';

import Moment from 'moment';
import queryString from 'query-string';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import FilterDate from '../../components/dashboard/FilterDate';
import PageHeader from '../../components/dashboard/PageHeader';
import Error from '../../components/general/Error';
// Components
import Loading from '../../components/general/Loading';
// Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import AppWebUtil from '../../lib/webUtils';
import AvailabilityDateDetails from './models/AvailabilityDateDetails';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class OccupancyChart extends Component {
  static componentName = 'OccupancyChart';

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = (silentLoad = false) => {
    this.setState({
      loading_inner: !silentLoad,
    });
    let startDate = Moment().subtract(7, 'days'), urlParams = {};
    if (this.getQueryParams('availability_start_date')) {
      startDate = Moment(String(this.getQueryParams('availability_start_date')));
    }
    if (this.getQueryParams('availability_end_date')) {
      let endDate = Moment(String(this.getQueryParams('availability_end_date')));
      urlParams['end_date'] = endDate.format('YYYY-MM-DD');
    }

    if (startDate) {
      urlParams['start_date'] = startDate.format('YYYY-MM-DD');
    }

    AppAPI.availabilityapi
      .get(`availability-chart/?${queryString.stringify(urlParams)}`)
      .then(res => {
        if (res) {
          this.setState({
            startDate: Moment(res.start_date),
            endDate: Moment(res.end_date),
            room_types: res.room_types,
            loading: false,
            loading_inner: false,
          });
          this.processAvailabilityChartData(res);
        } else {
          this.setState({
            loading: false,
            loading_inner: false,
            error: true,
          });
        }
      })
      .catch(err => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          loading_inner: false,
          error: error,
          resultMsg: {error},
        });
      });
  };

  processAvailabilityChartData = res => {
    let tableHead = [], tableBody = [], roomTypeData = {};

    if (!_.isEmpty(res.room_types)) {
      res.room_types.forEach((data) => {
        tableBody[data.room_type_id] = [];
        tableBody[data.room_type_id].push({'print': data.name, 'data': data});
        roomTypeData[data.room_type_id] = data;

      });
      if (!_.isEmpty(res.availability_data)) {
        res.availability_data.forEach((availabilityData) => {
          tableHead.push({'date': availabilityData.date, 'future': availabilityData.future});

          if (!_.isEmpty(availabilityData.data)) {
            availabilityData.data.forEach((dayData) => {
              tableBody[dayData.room_type_id].push({'print': dayData.available, 'data': dayData});
            });
          }
        });
      }
    }

    this.setState({
      error: false,
      loading: false,
      loading_inner: false,
      roomTypeData,
      tableHead,
      tableBody,
    });

  };

  selectRestrictionList = (type) => {
    if (type) {
      this.setState({
        restrictionList: AppUtil.insertOrRemoveArray(this.state.restrictionList, type),
      });
    }
  };

  render = () => {
    const {
      loading,
      error,
      tableHead,
      tableBody,
      loading_inner,
      restrictionList,
      displayList
    } = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="dashboard screen-container">
        <Helmet>
          <title>Occupancy Calendar</title>
        </Helmet>

        <PageHeader
          history={this.props.history} title={'Occupancy Calendar'}
          description={'Date wise occupancy information.'}
        />

        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-12 col-md-4'}>
            <p className={'small text-muted'}>Date Range</p>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                {loading_inner ?
                  <button className={'btn btn-outline-secondary px-5'}>
                    ....Loading....
                  </button>
                  :
                  <FilterDate
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    maxDate={Moment()}
                    action={(data) => this.setQueryParams(AppWebUtil.processDate(data, 'availability_'))}
                    title={'Date Range'}
                    selectionType={'range'}
                    disableSelection={true}
                  />
                }
              </li>
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary'} onClick={() => this.fetchInitData()}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={''}/>
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            {loading_inner ?
              <Loading/>
              :
              <React.Fragment>
                {(_.isEmpty(tableHead) || _.isEmpty(tableBody)) ?
                  <div/>
                  :
                  <div className={'table-responsive border mb-3'}>
                    <table
                      className={'table table-hover table-bordered border-0 table-sm text-center fixed-table availability-table mb-0'}>
                      <thead className="align-middle">
                        <tr>
                          <th className={'data-table-cell-lg bg-dark align-middle'}/>
                          <th className={'data-table-cell-xl bg-dark align-middle fixed-2'}/>
                          {tableHead.map((data, i) => (
                            <th className={'data-table-cell-sm bg-dark text-light'} key={i}>
                              <strong>{AppUtil.formatDateTime(data.date, 'date')}</strong> <br/>
                              <small>{AppUtil.formatDateTime(data.date, 'day')}</small>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {tableBody.map((rowData, i) => (
                          <React.Fragment key={i}>
                            <tr key={i}>
                              {rowData.map((colData, k) => (
                                <React.Fragment key={k}>
                                  {k === 0 ?
                                    <React.Fragment>
                                      <td
                                        rowSpan={1 + Object.keys(restrictionList).length + Object.keys(displayList).length}
                                        className={'bg-dark text-light text-left align-middle'}
                                        key={k}
                                      >
                                        <strong>{colData.print}</strong>
                                      </td>
                                      <td
                                        className={(Object.keys(restrictionList).length + Object.keys(displayList).length) > 1 ? 'bg-dark text-light' : 'd-none'}/>
                                    </React.Fragment>
                                    :
                                    <td
                                      className={(Object.keys(restrictionList).length + Object.keys(displayList).length) > 1 ? 'bg-dark text-light' : 'd-none'}/>
                                  }
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr className={`${displayList.includes('availability') ? '' : 'd-none'}`}>
                              {rowData.map((colData, k) => {
                                return (
                                  <React.Fragment key={k}>
                                    {k === 0 ?
                                      <td className={'bg-light fixed-2 text-left m-0 p-0'}>
                                        <table className={'table table m-0 p-0'}>
                                          <tbody>
                                            <tr>
                                              <td className={'bg-light'} colSpan={4}>Occupancy</td>
                                            </tr>
                                            <tr>
                                              <td className={'bg-light'} colSpan={4}>
                                                &nbsp;
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      :
                                      <td
                                        className={'align-middle a-class'}
                                        onClick={() => this.setState({
                                          showAvailabilityDetails: true,
                                          availabilityDetails: colData.data,
                                        })}
                                      >
                                        <p
                                          className={`mb-0 ${colData.data && AppUtil.occupancyStatusColor(colData.data.occupancy_percent)}`}>
                                          <strong>{colData.data ? colData.data.complete : 'N.A'}</strong>
                                        </p>

                                      </td>
                                    }
                                  </React.Fragment>
                                );
                              }
                              )}
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                }
              </React.Fragment>
            }
          </div>
        </div>
        <AvailabilityDateDetails
          {...this.props}
          show={this.state.showAvailabilityDetails}
          onHide={() =>
            this.setState({
              showAvailabilityDetails: false,
              availabilityDetails: null,
              selectedTab: 'overview'
            })
          }
          property={this.props.property}
          availabilityDetails={this.state.availabilityDetails}
          updateResponse={(res) => {
            if (!_.isEmpty(res)) {
              this.setState({availabilityDetails: res});
            }
            this.fetchInitData(true);
          }}
          selectedTab={this.state.selectedTab}
          roomTypeData={this.state.roomTypeData}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loading_inner: true,
      error: null,
      restrictionList: [],
      restrictionSelectList: null,
      roomTypeSelect: null,
      channelList: ['channel_manager'],
      startDate: Moment().subtract(30, 'days'),
      endDate: Moment().startOf('day'),
      showAvailabilityDetails: false,
      availabilityDetails: null,
      showAvailabilityManage: false,
      displayList: ['availability'],
      displayListOptions: [
        {
          'key': 'availability',
          'name': 'Availability',
          'input': 'number',
        },
      ],
      valueList: [
        {
          'name': 'Booked',
          'key': 'booked'
        },
        {
          'name': 'Cancelled',
          'key': 'cancelled'
        },
        {
          'name': 'No Show',
          'key': 'noshow'
        },
      ]
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OccupancyChart);
