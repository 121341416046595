/**
 *
 * Property Booking Engine Rules
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Consts and Libs
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {faPen} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// Actions
import * as PropertyActions from '../../../redux/property/actions';
import PageHeader from '../../../components/dashboard/PageHeader';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  propertyContent: state.property.content,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetPropertyContent: PropertyActions.isSetPropertyContent,
};

/* Component ==================================================================== */
class PropertyBookingEngineRules extends Component {
  static componentName = 'PropertyBookingEngineRules';

  componentDidMount = () => {
  };

  render = () => {
    const {loading, error} = this.state;
    const {propertyContent} = this.props;

    if (!this.props.property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="channel-manager-settings screen-container">
        <Helmet>
          <title>Booking Engine Rules</title>
        </Helmet>
        <PageHeader
          subHeader={true}
          title={'Rules / FAQ'}
          description={'General FAQ, booking rules, event booking rules.'}
        />

        <div className={'row'}>
          <div className="col-lg-10 col-md-12 col-sm-12 mb-5">
            <div
              className={'border p-2 text-center anchor w-100'} data-toggle="collapse"
              role="button" aria-expanded="false" aria-controls="orderActions">
              <h6 className={'mb-0'}>
                Frequently Asked Questions
              </h6>
            </div>
            <div className={'border p-2 mb-5'} id={'primaryAction'}>
              {!_.isEmpty(propertyContent.bengine_faq) ?
                <ul className="list-group list-group-sm">
                  {propertyContent.bengine_faq.map((data, i) => (
                    <li className="list-group-item" key={i}>
                      <p className={'mb-1'}><strong>{data.title}</strong></p>
                      <p className={'mb-0'}>{data.description}</p>
                    </li>
                  ))}
                </ul>
                :
                <div>
                  <p className={'text-muted'}>No Frequently Asked Questions.</p>
                </div>
              }
            </div>

            <div
              className={'border p-2 text-center anchor w-100'} data-toggle="collapse"
              role="button" aria-expanded="false" aria-controls="orderActions">
              <h6 className={'mb-0'}>
                Room Booking Rules
              </h6>
            </div>
            <div className={'border p-2  mb-5'} id={'primaryAction'}>
              {!_.isEmpty(propertyContent.bengine_room_booking_rules) ?
                <ul className="list-group list-group-sm">
                  {propertyContent.bengine_room_booking_rules.map((data, i) => (
                    <li className="list-group-item" key={i}>
                      <p className={'mb-1'}><strong>{data.title}</strong></p>
                      <p className={'mb-0'}>{data.description}</p>
                    </li>
                  ))}
                </ul>
                :
                <div>
                  <p className={'text-muted'}>No Room Booking Rules.</p>
                </div>
              }
            </div>

            <div
              className={'border p-2 text-center anchor w-100'} data-toggle="collapse"
              role="button" aria-expanded="false" aria-controls="orderActions">
              <h6 className={'mb-0'}>
                Event Booking Rules
              </h6>
            </div>
            <div className={'border p-2 mb-5'} id={'primaryAction'}>
              {!_.isEmpty(propertyContent.bengine_event_booking_rules) ?
                <ul className="list-group list-group-sm">
                  {propertyContent.bengine_event_booking_rules.map((data, i) => (
                    <li className="list-group-item" key={i}>
                      <p className={'mb-1'}><strong>{data.title}</strong></p>
                      <p className={'mb-0'}>{data.description}</p>
                    </li>
                  ))}
                </ul>
                :
                <div>
                  <p className={'text-muted'}>No Event Booking Rules.</p>
                </div>
              }
            </div>


          </div>
          <div className="col-lg-2 col-md-4 col-sm-12 mb-5">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link to={'manage'} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyBookingEngineRules);
