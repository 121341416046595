/**
 *
 * PTerminal Details
 */
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../../lib/api';
// Components
import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import PTerminalInfo from './Components/PTerminalInfo';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLongArrowAltRight} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as PaymentActions from '../../../redux/payment/actions';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pterminal: state.payment.pterminal,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPTerminal: PaymentActions.setPTerminal,
};

/* Component ==================================================================== */
class PTerminalDetails extends Component {
  static componentName = 'PTerminalDetails';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    this.fetchInitData();
  };


  cacheReset = () => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.pterminal
        .post(`${this.props.pterminal.hash}/cache-reset/`, {})
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            this.props.setPTerminal(res);
            setTimeout(() => (this.setState({resultMsg: {success: ''}})), 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({loading: false, resultMsg: {error}});
        });
    });
  };

  statusCheck = (pterminal) => {
    this.setState({resultMsg: {status: 'One moment...'}});
    AppAPI.pterminal.post(`${pterminal.hash}/status`, {})
      .then(res => {
        this.setState({resultMsg: {success: 'Success'}});
        this.props.setPTerminal(res);
        setTimeout(() => (this.setState({resultMsg: {success: ''}})), 500);
      })
      .catch(err => {
        this.setState({resultMsg: {error: AppAPI.handleError(err)}, loading: false});
      });
  };

  fetchInitData = () => {
    this.setState({loading: true},
      () => {
        this.props.setPTerminal(this.props.match.params.pterminalId)
          .then(() => this.setState({loading: false}))
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({loading: false, error});
          });
      });
  };

  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {pterminal} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    return (
      <div className="payment-details screen-container ">
        <Helmet>
          <title>Payment Details : {pterminal.ref_no}</title>
        </Helmet>


        <PageHeader
          history={this.props.history} title={'Payment Terminal'}
          description={`Reference number : ${pterminal.ref_no}`}/>

        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <PTerminalInfo pterminal={pterminal} statusCheck={this.statusCheck} />
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
            <hr/>
            {!_.isEmpty(pterminal.attributes) &&
              <Link to={`/payment/${pterminal.attributes.payment_hash}/`} className="btn btn-success btn-lg btn-block">
                Payment <FontAwesomeIcon icon={faLongArrowAltRight} size={'sm'} className={'ml-2'}/>
              </Link>
            }

          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group mb-5">
              <li
                className="list-group-item d-flex justify-content-between align-items-center"
                onClick={() => this.cacheReset()}
              >
                <small>Payment Actions</small>
              </li>
              {!_.isEmpty(pterminal.attributes) &&
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  <Link className="" to={`/payment/${pterminal.attributes.payment_hash}/`}>
                    Payment <FontAwesomeIcon icon={faLongArrowAltRight} size={'sm'} className={'ml-2'}/>
                  </Link>
                </li>
              }
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      showRefund: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PTerminalDetails);