/**
 *
 * Expense Department Details
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
// Consts and Libs
import AppAPI from '../../../../lib/api';
// Components
import Loading from '../../../../components/general/Loading';
import Error from '../../../../components/general/Error';
import PageHeader from '../../../../components/dashboard/PageHeader';
import ExpenseDepartmentManage from './ExpenseDepartmentManage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as ExpenseActions from '../../../../redux/expense/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  department: state.expense.department,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setExpenseDepartment: ExpenseActions.setExpenseDepartment,
};

/* Component ==================================================================== */
class ExpenseDepartmentSettings extends Component {
  static componentName = 'ExpenseDepartmentDetails';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    if (this.props.match.params.departmentId) {
      this.props.setExpenseDepartment(this.props.match.params.departmentId)
        .then(() => {
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };


  render = () => {

    const {loading, error} = this.state;
    const {department} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    return (
      <div className="room-type-details screen-container ">
        <Helmet>
          <title>Expense Department</title>
        </Helmet>


        <PageHeader
          subHeader={true} history={this.props.history} title={'Department Details'}
          description={`Settings & Configuration for event : ${department.name}`}
        />

        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="row mb-5">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item"><span className="fa folder"/>
                    <strong>Name</strong> : {department.name}</li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item"><span className="fa folder"/>
                    <strong>Status</strong> : <span
                      className={'badge ' + (department.active ? 'badge-success' : 'badge-secondary')}>{department.active ? 'Active' : 'De-active'}</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item"><span className="fa folder"/>
                    <strong>Description</strong> : {department.description ? department.description : 'N.A'}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <button
                  onClick={() => this.setState({showExpenseDepartmentManage: true})}
                  className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit Department
                </button>
              </li>
            </ul>
          </div>
        </div>
        <ExpenseDepartmentManage
          department={department}
          show={this.state.showExpenseDepartmentManage}
          onHide={() => this.setState({showExpenseDepartmentManage: false})}
          updateResponse={(data) => this.props.setExpenseDepartment(data)}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      showExpenseDepartmentManage: false,
      loading: true,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseDepartmentSettings);