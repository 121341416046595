/**
 *
 * AvailabilityHistoryList
 * List Availability data history
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import { Strings } from '../../constants';
// Components
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import SearchBar from '../../components/forms/SearchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorOpen, faExternalLinkAlt, faSync } from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class AvailabilityHistoryList extends Component {
  static componentName = 'AvailabilityHistoryList';

  static propTypes = {
    availabilityHash: PropTypes.string,
  };

  fetchData = (page = 1, callback) => {
    const urlParams = {};
    const baseUrl = 'availability-history';
    urlParams['availability'] = this.props.availabilityHash;
    urlParams['page'] = page;

    AppAPI.availabilityapi.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (res.results.length > 0) {
          callback(res.results, {allLoaded: !res.next});
        } else {
          callback();
        }
        this.setState({refresh: false, loading: false, page: page});
      })
      .catch((err) => {
        if (callback) {
          callback([], {allLoaded: true});
        }
        const error = AppAPI.handleError(err);
        this.setState({refresh: false, loading: false, error});
      });
  };


  renderRow = (data, key) => {
    const attributes = (data.booking && !_.isEmpty(data.booking.attributes)) ? data.booking.attributes  : {};
    const availability = parseInt(data.total) - (parseInt(data.maintenance) + parseInt(data.all_channel_block) + parseInt(data.booked) + parseInt(data.buffer));
    const availability_percent = AppUtil.calculatePercentage(availability, data.total);
    return (
      <tr key={key} className={'tr-align-middle'}>
        <td className={'data-table-cell-lg'}>{AppUtil.formatDateTime(data.time)}</td>
        <td className={'data-table-cell-sm'}>{data.total}</td>
        <td className={`data-table-cell-md ${AppUtil.availabilityStatusColor(availability_percent)}`}>
          <strong>{availability}</strong> ( {availability_percent} % )
        </td>
        <td className={'data-table-cell-sm'}>{data.booked}</td>
        <td className={'data-table-cell-sm'}>{data.blocked}</td>
        <td className={'data-table-cell-sm'}>{data.cancelled}</td>
        <td className={'data-table-cell-sm'}>{data.maintenance}</td>
        <td className={'data-table-cell-sm'}>{data.buffer}</td>
        <td className={'data-table-cell-sm'}>{data.all_channel_block}</td>
        <td className={'data-table-cell-lg'}>
          {
            _.isEmpty(data.booking)
              ? (<p className={'text-mute small text-center'}>...</p>)
              : (
                <>
                  <Link to={`/booking/${data.booking.hash}`} rel='noopener noreferrer' target={'_blank'}>
                    <FontAwesomeIcon icon={faExternalLinkAlt} size={'sm'} /> {data.booking.ref_no}
                  </Link>
                  {!_.isEmpty(attributes) && (
                    <>
                      {(attributes.created_user) && <div className={'ml-2 small'}>({attributes.created_user})</div>}
                      {(attributes.booking_ota) && <div className={'ml-2 small'}>({attributes.booking_ota})</div>}
                    </>
                  )}
                </>
              )
          }
        </td>
      </tr>
    );
  };


  emptyView = () => {
    return (
      <tr>
        <td colSpan={10}>
          <div className={'text-center table-list-empty-sm'}>
            <h4><FontAwesomeIcon icon={faDoorOpen}/> No Availability History </h4>
            <p>{Strings.availabilityListEmptyText}</p>
          </div>
        </td>
      </tr>
    );
  };


  tableHeader = () => {
    return (
      <tr>
        <th className={'data-table-cell-lg'}>Update Time</th>
        <th className={'data-table-cell-sm'}>Total</th>
        <th className={'data-table-cell-sm'}>Available</th>
        <th className={'data-table-cell-sm'}>Booked</th>
        <th className={'data-table-cell-sm'}>Provisional</th>
        <th className={'data-table-cell-sm'}>Cancelled</th>
        <th className={'data-table-cell-sm'}>Maintenance</th>
        <th className={'data-table-cell-sm'}>Buffer</th>
        <th className={'data-table-cell-sm'}>Blocked</th>
        <th className={'data-table-cell-lg'}>Booking</th>
      </tr>
    );
  };


  render = () => {
    const {loading, error, results, refresh} = this.state;

    if (loading) return <Loading heightMatch={false}/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="booking-list screen-container">
        <Helmet>
          <title>Availability History</title>
        </Helmet>
        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-9'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.setState({refresh: true})}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                </button>
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-3 text-center text-sm-right'}>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <SearchBar
              colSpan={10}
              refresh={refresh}
              results={results}
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
            />
          </div>
        </div>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      results: [],
      urlParams: {},
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityHistoryList);
