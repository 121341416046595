/**
 * Order Actions
 *
 */
import AppAPI from '../../lib/api';

/**
 * Set Order
 */
export const setBillingOrder = (orderData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof orderData === 'object') {
    dispatch({
      type: 'SET_BILLING_ORDER',
      data: orderData,
    });
    return resolve(orderData);
  } else if (orderData) {
    // Fetch Order Data
    return AppAPI.billingapi.get('billingorder/' + orderData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_BILLING_ORDER',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Order
 */
export const updateBillingOrder = (orderData) => setBillingOrder(orderData);

/**
 * Remove Order
 */
export function unSetBillingOrder () {
  return (dispatch) => {
    dispatch({
      type: 'SET_BILLING_ORDER',
      data: null,
    });
  };
}

/**
 * Set plan data for completing billing address.
 */

export const setSelectPlan = (orderData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof orderData === 'object') {
    dispatch({
      type: 'SET_BILLING_PLAN_DATA',
      data: orderData,
    });
    return resolve(orderData);
  }

  return reject();
});

/**
 * Remove plan data
 */
export function unSetSelectPlan () {
  return (dispatch) => {
    dispatch({
      type: 'SET_BILLING_PLAN_DATA',
      data: null,
    });
  };
}

/**
 * Set Billing Payment
 */
export const setBillingPayment = (paymentData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof paymentData === 'object') {
    dispatch({
      type: 'SET_BILLING_PAYMENT',
      data: paymentData,
    });
    return resolve(paymentData);
  } else if (paymentData) {
    // Fetch Order Data
    return AppAPI.pgatewayapi.get('api/' + paymentData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_BILLING_PAYMENT',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Remove Billing data
 */
export function unsetBillingPayment () {
  return (dispatch) => {
    dispatch({
      type: 'SET_BILLING_PAYMENT',
      data: null,
    });
  };
}

/**
 * Set Billing Subscription
 */
export const setBillingPurchase = (paymentData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof paymentData === 'object') {
    dispatch({
      type: 'SET_BILLING_PURCHASE',
      data: paymentData,
    });
    return resolve(paymentData);
  } else if (paymentData) {
    // Fetch Order Data
    return AppAPI.billingapi.get('purchasedplans/' + paymentData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_BILLING_PURCHASE',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Remove Subscription data
 */
export function unsetsetBillingPurchase () {
  return (dispatch) => {
    dispatch({
      type: 'SET_BILLING_PURCHASE',
      data: null,
    });
  };
}