/**
 *
 * Order Payment Details
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
// Consts and Libs
import AppUtil from '../../../lib/util';
import AppAPI from '../../../lib/api';
import {Constants, Filters, Strings} from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import PaymentAdd from '../../payment/models/PaymentAdd';
import Loading from '../../../components/general/Loading';
import OrderPaymentSelector from '../models/OrderPaymentSelector';
import FilterButton from '../../../components/dashboard/FilterButton';
import PageSubHeader from '../../../components/dashboard/PageSubHeader';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCashRegister, faFileUpload, faPlusCircle} from '@fortawesome/free-solid-svg-icons';

// Actions
import * as OrderActions from '../../../redux/order/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  order: state.order.order,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setOrder: OrderActions.setOrder,
};


/* Component ==================================================================== */
class OrderPaymentDetails extends Component {
  static componentName = 'OrderPaymentDetails';

  static propTypes = {
    match: PropTypes.object.isRequired,
    selectKey: PropTypes.string,
  };

  static defaultProps = {
    selectKey: 'payment'
  }

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {selectKey, order} = this.props;
    this.setState({loading: true});

    AppAPI.order.get(`${order.hash}/related-details/?key=${selectKey}`)
      .then((res) => {
        this.setState({
          response: res,
          loading: false,
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };


  render = () => {

    const {loading, error, response, paymentType, paymentStatus, paymentMethod, refundStatus} = this.state;
    const {order, property} = this.props;


    if (loading || _.isEmpty(order) || _.isEmpty(response)) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    const {payments} = response;


    let itemList = payments;

    if (paymentType) {
      itemList = itemList.filter(item => (item.payment_type === paymentType));
    }

    if (paymentStatus || paymentStatus === 0) {
      itemList = itemList.filter(item => (item.payment_status === paymentStatus));
    }

    if (paymentMethod) {
      itemList = itemList.filter(item => (item.payment_method === paymentMethod));
    }

    if (refundStatus || refundStatus === 0) {
      itemList = itemList.filter(item => (item.refund_status === refundStatus));
    }

    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Order Payments</title>
        </Helmet>

        <PageSubHeader icon={faCashRegister} title={'Payment(s)'} description={'Payments for the order.'}/>

        <div className={'row'}>
          <div className={'col-md-4 col-12'}>
            <table className={'table table-borderless table-sm mb-3'}>
              <tbody>
                <tr>
                  <td className={'w-50 text-muted'}>Order Total</td>
                  <td><strong>{`${order.currency} ${order.total}`}</strong></td>
                </tr>
                <tr>
                  <td className={'w-50 text-muted'}>Total Paid</td>
                  <td><strong>{`${order.currency} ${order.total_paid}`}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={'col-md-4 col-12'}>
            <table className={'table table-borderless table-sm mb-3'}>
              <tbody>
                <tr>
                  <td className={'w-50 text-muted'}>Pending Payment</td>
                  <td><strong>{`${order.currency} ${order.total_unpaid_payment}`}</strong></td>
                </tr>
                <tr>
                  <td className={'w-50 text-muted'}>Total Settled</td>
                  <td><strong>{`${order.currency} ${order.total_settled}`}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={'col-md-4 col-12'}>
            <table className={'table table-borderless table-sm mb-3'}>
              <tbody>
                <tr>
                  <td className={'w-50 text-muted'}>Paid Payments</td>
                  <td>
                    <strong>{!_.isEmpty(payments) ? payments.filter(item => (item.payment_status === Constants.PAYMENT_STATUS.PAID)).length : 0}</strong>
                  </td>
                </tr>
                <tr>
                  <td className={'w-50 text-muted'}>Other Payments</td>
                  <td>
                    <strong>{!_.isEmpty(payments) ? payments.filter(item => (item.payment_status === Constants.ORDER_STATUS.CANCELLED || item.payment_status === Constants.ORDER_STATUS.NOTPAID)).length : 0}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={'spacer-20'}/>

        <div className={'row'}>
          <div className={'col-12 col-sm-8'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <FilterButton
                  title={'Payment Method'}
                  selectKey={paymentMethod}
                  data={Filters.paymentMethodValue}
                  action={(data) => this.setState({paymentMethod: data.key})}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Payment Status'}
                  selectKey={paymentStatus}
                  data={Filters.paymentStatusValue}
                  action={(data) => this.setState({paymentStatus: data.key})}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Payment Type'}
                  selectKey={paymentType}
                  data={Filters.paymentTypeValue}
                  action={(data) => this.setState({paymentType: data.key})}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Refund Status'}
                  selectKey={refundStatus}
                  data={Filters.refundStatusValue}
                  action={(data) => this.setState({refundStatus: data.key})}
                  size={'sm'}
                />
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-4 text-center text-sm-right'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button
                  onClick={() => this.setState({showPayment: true})}
                  className={'btn btn-outline-success btn-sm'}
                  disabled={!order.allow_payment}
                >
                  <FontAwesomeIcon icon={faPlusCircle} size={'sm'} className={'mr-2'}/> Add Payment
                </button>
              </li>
              <li className="list-inline-item">
                <button
                  onClick={() => this.setState({showOrderPaymentSelector: true})}
                  className={'btn btn-outline-success btn-sm'}
                  disabled={!order.allow_payment}
                >
                  <FontAwesomeIcon icon={faFileUpload} size={'sm'} className={'mr-2'}/> Attach Payment
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div className="table-responsive border">
          <table className={`table ${!_.isEmpty(itemList) && 'table-hover'} mb-0`}>
            <thead>
              <tr>
                <th>Reference</th>
                <th />
                <th>Method</th>
                <th className={'text-left'}>Total</th>
                <th className={'text-left green-cl'}>Settled</th>
                <th className={'text-left orange-cl'}>Fee</th>
                <th className={'text-left blue-cl'}>Refunded</th>
                <th className={'text-left'}>Net</th>
              </tr>
            </thead>
            <tbody>
              {!_.isEmpty(itemList) ?
                <React.Fragment>
                  {itemList.map((data, i) => {
                    return (
                      <tr className={'tr-align-middle'} key={i}>
                        <td className={'data-table-cell-lg'}>
                          <Link to={`/payment/${data.hash}`}>{data.ref_no}</Link><br/>
                          <p className={'mb-0 text-muted small'}>{AppUtil.formatDateTime(data.created)}</p>
                        </td>
                        <td className={'data-table-cell-xl text-left'}>
                          <span className={'badge mr-2 ' + (AppUtil.paymentStatusColor(data.payment_status))}>
                            {data.payment_status_display}
                          </span>
                          <span className={'mr-2 badge ' + (AppUtil.settlementStatusColor(data.settlement_status))}>
                            {data.settlement_status_display}
                          </span>
                          {data.refund_status !== Constants.REFUND_STATUS.NOTPAID &&
                          <span className={'mr-2 badge ' + (AppUtil.paymentStatusColor(data.refund_status))}>
                            {data.refund_status_display}
                          </span>
                          }
                          {data.payment_mode !== Constants.PAYMENT_MODE.PM_STANDARD &&
                          <span className={`mr-2 badge ${AppUtil.paymentModeColor(data.payment_mode)}`}>
                            {data.payment_mode_display}
                          </span>
                          }
                        </td>
                        <td className={'text-center data-table-cell-lg'}>
                          {data.payment_method_display}
                          {
                            (!_.isEmpty(data.attributes) && data.attributes.payment_terminal) &&
                            <React.Fragment>
                              <br/><p className={'small mb-0 text-muted'}>{data.attributes.payment_terminal}</p>
                            </React.Fragment>
                          }
                        </td>
                        <td className={'data-table-cell-md'}><strong>{`${data.currency} ${data.total}`}</strong></td>
                        <td className={'data-table-cell-md green-cl'}><strong>{`${data.currency} ${data.total_settled}`}</strong></td>
                        <td className={'data-table-cell-md orange-cl'}><strong>{`${data.currency} ${data.settlement_difference}`}</strong>
                        </td>
                        <td className={'data-table-cell-md blue-cl'}><strong>{`${data.currency} ${data.total_refunded}`}</strong></td>
                        <td className={'data-table-cell-md'}><strong>{`${data.currency} ${data.total_available_fund}`}</strong></td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={3}/>
                    <td className={'text-left'}>
                      <strong>{`${property.currency} ${itemList.reduce((a, b) => parseFloat(a) + parseFloat(b.total), 0).toFixed(2)}`}</strong>
                    </td>
                    <td className={'text-left green-cl'}>
                      <strong>{`${property.currency} ${itemList.reduce((a, b) => parseFloat(a) + parseFloat(b.total_settled), 0).toFixed(2)}`}</strong>
                    </td>
                    <td className={'text-left orange-cl'}>
                      <strong>{`${property.currency} ${itemList.reduce((a, b) => parseFloat(a) + parseFloat(b.settlement_difference), 0).toFixed(2)}`}</strong>
                    </td>
                    <td className={'text-left blue-cl'}>
                      <strong>{`${property.currency} ${itemList.reduce((a, b) => parseFloat(a) + parseFloat(b.total_refunded), 0).toFixed(2)}`}</strong>
                    </td>
                    <td className={'text-left'}>
                      <strong>{`${property.currency} ${itemList.reduce((a, b) => parseFloat(a) + parseFloat(b.total_available_fund), 0).toFixed(2)}`}</strong>
                    </td>
                  </tr>
                </React.Fragment>
                :
                <tr>
                  <td colSpan={9}>
                    <div className={'text-center table-list-empty'}>
                      <h6><FontAwesomeIcon icon={faCashRegister} className={'mr-2'}/> No Payment(s) </h6>
                      <p className={'text-muted'}>{Strings.orderPaymentEmpty}</p>
                      <p>
                        <button
                          onClick={() => this.setState({showPayment: true})}
                          className={'btn btn-outline-success btn-sm'}
                          disabled={!order.allow_payment}
                        >
                          <FontAwesomeIcon icon={faPlusCircle} size={'sm'} className={'mr-2'}/> Add Payment
                        </button>
                      </p>
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>

        {/* Add Payment */}
        <PaymentAdd
          updateResponse={(data) => this.props.history.push(`/payment/${data.hash}`)}
          onHide={() => this.setState({showPayment: false})}
          show={this.state.showPayment}
          order={order}
          property={this.props.property}
        />

        {/* Attach Payment */}
        <OrderPaymentSelector
          order={order}
          property={this.props.property}
          updateOrder={this.props.setOrder}
          updatePayments={this.fetchInitData}
          show={this.state.showOrderPaymentSelector}
          onHide={() => this.setState({showOrderPaymentSelector: false})}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      showPayment: false,
      showOrderPaymentSelector: false,
      response: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(OrderPaymentDetails);