/**
 *
 * Booking Room Analytics : Guest
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Moment from 'moment';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import React, { Component } from 'react';
import PageHeader from '../../../components/dashboard/PageHeader';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {faUsers} from '@fortawesome/free-solid-svg-icons';
// Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import {AnalyticConstants, Constants} from '../../../constants';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class BookingRoomAnalyticsGuest extends Component {
  static componentName = 'BookingRoomAnalyticsGuest';

  static propTypes = {
    pos: PropTypes.string,
    endDate: PropTypes.object,
    startDate: PropTypes.object
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {startDate, endDate, dateType} = this.props;
    this.setState({loading: true});
    const queryParams = {
      'date_type': dateType,
      'report_type': 'booking_room_guest',
      'start_date': (startDate ? startDate : Moment()).format('YYYY-MM-DD'),
      'end_date': endDate ? endDate.format('YYYY-MM-DD') : null
    };
    AppAPI.bookingapi.get(`booking-analytics/?${queryString.stringify(queryParams)}`)
      .then((res) => {
        this.setState({loading: false, response: res});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({loading: false, error: error});
      });
  };

  render = () => {
    const {loading, error, response} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    // Initialize with empty array for order data set
    const guestDataSet = {};

    Object.keys(AnalyticConstants.bookingGuestAnalytics).forEach((data)=>{
      guestDataSet[data] = 0;
    });

    // Containers for order status data & order source data
    const bookingData = {
      guestData:_.cloneDeep(guestDataSet),
      bookingSourceData: {},
    };

    Object.values(Constants.BOOKING_SOURCES_INPUT).forEach((data)=>{
      bookingData.bookingSourceData[data.value] = _.cloneDeep(guestDataSet);
    });

    if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
      const responseData = response.data;
      if (!_.isEmpty(responseData.booking_room_guest_source_data)) {
        responseData.booking_room_guest_source_data.forEach((data) => {
          Object.entries(AnalyticConstants.bookingGuestAnalytics).forEach(([key, value])=>{
            if (data[value]) {
              AppUtil.insertOrAddObject(bookingData.guestData, key, data[value]);
              AppUtil.insertOrAddObject(bookingData.bookingSourceData[data.booking__booking_source], key,data[value]);
            }
          });
        });
      }
    }

    return (
      <div className="screen-container">
        <Helmet>
          <title>Booking Room Analytics : Guest</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={'Booking Room Analytics : Guests'}
          description={'Booking guests bifurcation and its related details in the selected range.'}

        />

        <div className={'row mb-3'}>
          <div className={'col-12 col-lg-6 mb-3'}>
            <div className={'card'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col-3 text-center'}>
                    <h3 className={'card-title mb-0 pb-0'}>
                      <FontAwesomeIcon icon={faUsers} className={'mt-2'} size={'xl'}/>
                    </h3>
                  </div>
                  <div className={'col'}>
                    <p className={'text-muted mb-1'}>Total Guest(s)</p>
                    <h3 className={'card-title mb-0 pb-0'}>
                      {AppUtil.sumArray(Object.values(bookingData.guestData))}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'col-12 col-md-6 mb-3'}>
            <div className={'card '}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col-4 col-lg-4'}>
                    <p className={'text-muted mb-1'}>Adult</p>
                    <h5 className={'card-title mb-2 pb-0'}>
                      {bookingData.guestData.adult}
                    </h5>
                  </div>
                  <div className={'col-4 col-lg-4'}>
                    <p className={'text-muted mb-1'}>Child</p>
                    <h5 className={'card-title mb-2 pb-0'}>
                      {bookingData.guestData.child}
                    </h5>
                  </div>
                  <div className={'col-4 col-lg-4'}>
                    <p className={'text-muted mb-1'}>Infant</p>
                    <h5 className={'card-title mb-2 pb-0'}>
                      {bookingData.guestData.infant}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={'row mb-2'}>
          <div className={'col-12 col-lg-12'}>
            <table className={'table table-bordered'}>
              <thead>
                <tr>
                  <th>Source</th>
                  <th>Adult</th>
                  <th>Child</th>
                  <th>Infant</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(Constants.BOOKING_SOURCES_INPUT).map((data, key) => {
                  return (
                    <tr key={key}>
                      <td className={'data-table-cell-sm'}>
                        {data.label}
                      </td>
                      <td className={'text-center'}>{bookingData.bookingSourceData[parseInt(data.value)].adult}</td>
                      <td className={'text-center'}>{bookingData.bookingSourceData[parseInt(data.value)].child}</td>
                      <td className={'text-center'}>{bookingData.bookingSourceData[parseInt(data.value)].infant}</td>
                      <td className={'text-center'}>
                        <strong>{AppUtil.sumArray(Object.values(bookingData.bookingSourceData[parseInt(data.value)]))}</strong>
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td><strong>Total</strong></td>
                  <td className={'text-center'}>
                    <strong>{bookingData.guestData.adult}</strong>
                  </td>
                  <td className={'text-center'}>
                    <strong>{bookingData.guestData.child}</strong>
                  </td>
                  <td className={'text-center'}>
                    <strong>{bookingData.guestData.infant}</strong>
                  </td>
                  <td className={'text-center'}>
                    <strong>{AppUtil.sumArray(Object.values(bookingData.guestData))}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingRoomAnalyticsGuest);
