/**
 *
 * RefundList
 * List Refunds
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';

// Consts and Libs
import AppAPI from '../../lib/api';
import Strings from '../../constants/strings';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import ListView from '../../components/general/ListView';
import ReviewListView from './ReviewListView';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class ReviewList extends Component {
  static componentName = 'ReviewList';
  componentDidMount = () => {
    this.setState({loading: false});
  };

  fetchData = (page = 1, callback) => {

    let params = '';

    switch (this.props.match.params.reviewType) {
    case 'complete':
      params = '&review_status=1';
      break;
    case 'pending':
      params = '&review_status=0';
      break;
    case 'cancelled':
      params = '&review_status=2';
      break;
    default:
      params = '';
    }

    AppAPI.reviewapi.get('review/?page=' + page + params)
      .then((res) => {
        this.setState({
          loading: false,
          error: null
        });
        if (res.results.length > 0) {
          if (res.next) {
            callback(res.results);
          } else {
            callback(res.results, {
              allLoaded: true // the end of the list is reached
            });
          }
        } else {
          callback();
        }
      })
      .catch((err) => {
        callback(null, {
          allLoaded: true // the end of the list is reached
        });
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error
        });
      });
  };

  renderRow = (data, key) => {
    return (
      <ReviewListView
        data={data}
        key={key}
        history={this.props.history}
      />
    );
  };

  emptyView = () => {
    return (
      <div className="empty">
        <div className="mx-auto featured-image">
          <img className={'w-100'} alt={'Order List'} src={require('../../images/drawings/Reviews.png')}/>
        </div>
        <div className="spacer-20"/>
        {
          Strings.reviewListEmptyText.map((data, i) => <p key={i}>{data}</p>)
        }
      </div>
    );
  };

  searchData = (searchText) => {
    if (searchText !== '') {
      this.setState({
        searching: true,
        clear: true
      });

      AppAPI.reviewapi.get('review-search/?search=' + searchText.target.value)
        .then((res) => {
          if (res.results.length > 0) {
            this.setState({
              reviews: res.results,
              searching: false
            });
          } else {
            this.setState({
              reviews: [],
              searching: false
            });
          }

        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error
          });
        });
    }
  };

  render = () => {
    const {loading, error, clear, searching, reviews} = this.state;


    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="review-list screen-container">
        <Helmet>
          <title>Review's List</title>
        </Helmet>

        <div className="row">
          <div className="col-lg-7 col-md-12 col-sm-12">
            <form>
              <div className="form-row align-items-center mb-4">
                <div className={searching ? 'col-sm-11' : 'col-sm-12'}>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Review Ref no or booking ID"
                    name="q"
                    onChange={(text) => this.searchData(text)}
                  />
                </div>
                <div className="col-auto">
                  {searching && <i className="fa fa-spinner"/>}
                </div>
              </div>
            </form>

            {
              (reviews.length > 0 || clear) ?
                reviews.map((data, i) => this.renderRow(data, i))
                :
                <ListView
                  rowView={this.renderRow}
                  onFetch={this.fetchData}
                  firstLoader={true}
                  pagination
                  emptyView={this.emptyView}
                  class={'list-group'}
                />
            }

            {(reviews.length === 0 && clear) &&
             <div className="empty">
               <div className="mx-auto featured-image">
                 <img className={'w-100'} alt={'Guest List'} src={require('../../images/drawings/Reviews.png')}/>
               </div>
               <div className="spacer-20"/>
               <h2>{Strings.searchReviewListEmptyTitle}</h2>
               {
                 Strings.searchReviewListEmptyText.map((data, i) => <p key={i}>{data}</p>)
               }
             </div>
            }
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      searching: false,
      clear: false,
      reviews: []
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewList);
