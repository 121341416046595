/**
 *
 * Order Analytics Date view
 */
/* eslint no-eval: 0 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Consts and Libs
import AppUtil from '../../../lib/util';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PropTypes from 'prop-types';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};


const ORDER_VALUE_KEYS = {
  'order_items_count': 'Items Count',

  'sub_total': 'Sub Total',
  'tax_total': 'Tax Total',
  'items_total': 'Item Total',
  'discount_total_pre_tax': 'Discount (Pre Tax)',
  'discount_total_post_tax': 'Discount (Post Tax)',
  'discount_total': 'Discount',
  'complimentary_total': 'Complimentary',
  'no_charge_total': 'No Charge',
  'total_rounding': 'Rounding',

  'order_discount': 'Order Discount',
  'order_tax': 'Order tax',

  'total_tax': 'Tax Total',
  'total_discount': 'Total Discount',

  'total': 'Total',

  'order_external_tax': 'External Tax',
  'order_external_total': 'External Total',
};

const PAYMENT_VALUE_KEYS = {
  'total_paid': 'Paid',
  'total_settled': 'Settled',
  'total_refunded':'Refunded',
  'total_unpaid_payment': 'Pending',
  'total_available_fund': 'Net Available',
};

/* Component ==================================================================== */

class OrderAnalyticsDateView extends Component {
  static componentName = 'OrderAnalyticsDateView';

  static propTypes = {
    responseData: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
  };

  printCol = (value, key) => {
    return (
      <td
        className={`align-middle ${parseInt(value) < 1 ? 'grey-light-bg' : 'white-bg'} `}
        key={key}
      >
        {value}
      </td>
    );
  }


  render = () => {
    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const {date_data, currency} = this.props.responseData;

    return (
      <div className="dashboard screen-container">
        <Helmet>
          <title>Order Analytics | Date Overview</title>
        </Helmet>
        <div className="table-responsive border">
          <table className={'table table-bordered table-hover table-sm fixed-table text-center border'}>
            <thead className={'bg-dark text-light'}>
              <tr>
                <th className={'data-table-cell-lg bg-dark text-light'}>Date</th>
                <th className={'align-middle yellow-bg text-dark'} colSpan={2}>Open Order</th>
                <th className={'align-middle grey-bg'} colSpan={2}>Void Order</th>
                <th className={'align-middle blue-bg'} colSpan={Object.keys(ORDER_VALUE_KEYS).length + 1}>Order Information</th>
                <th className={'align-middle green-bg'}  colSpan={Object.keys(PAYMENT_VALUE_KEYS).length}>Payment Information ({currency})</th>
              </tr>
              <tr>
                <th className={'data-table-cell-lg bg-dark text-light'}/>
                <th className={'data-table-cell-lg bg-dark text-light'}>Count</th>
                <th className={'data-table-cell-lg bg-dark text-light'}>Value ({currency})</th>
                <th className={'data-table-cell-lg bg-dark text-light'}>Count</th>
                <th className={'data-table-cell-lg bg-dark text-light'}>Value ({currency})</th>
                <th className={'data-table-cell-lg bg-dark text-light'}>Count</th>
                {Object.keys(ORDER_VALUE_KEYS).map((data, i) => (
                  <th key={i} className={'data-table-cell-lg bg-dark text-light'}>
                    {ORDER_VALUE_KEYS[data]}
                  </th>
                ))}
                {Object.keys(PAYMENT_VALUE_KEYS).map((data, i) => (
                  <th key={i} className={'data-table-cell-lg bg-dark text-light'}>
                    {PAYMENT_VALUE_KEYS[data]}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <React.Fragment>
                {
                  date_data.map((data, i) => (
                    <tr key={i}>
                      <td className={'bg-dark text-light'}>
                        <strong>{AppUtil.formatDateTime(data.date, 'datef')}</strong><br/>
                        <small>{AppUtil.formatDateTime(data.date, 'day')}</small>
                      </td>
                      {this.printCol(data['order_count_open'])}
                      {this.printCol(data['order_value_open'])}
                      {this.printCol(data['order_count_void'])}
                      {this.printCol(data['order_value_void'])}
                      {this.printCol(data['order_count_total'])}
                      {Object.keys(ORDER_VALUE_KEYS).map((valueKeys, i) => this.printCol(data['order_value'][valueKeys], i))}
                      {Object.keys(PAYMENT_VALUE_KEYS).map((valueKeys, i) => this.printCol(data['order_value'][valueKeys], i))}
                    </tr>
                  ))
                }
              </React.Fragment>
            </tbody>
          </table>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loading_inner: false,
      error: null,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderAnalyticsDateView);
