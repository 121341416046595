/**
 *
 * Booking Engine Content Pages manage
 * property booking engine content pages settings.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {Formik} from 'formik';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants} from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import {Checkbox, Form, Input, Select, SubmitBtn, Textarea} from 'react-formik-ui';
import {faPlus, faSave} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// Actions
import Alerts from '../../../components/ui/Alerts';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PropertyBookingEngineContentPageManage extends Component {
  static componentName = 'PropertyBookingEngineContentPagesManage';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    if (this.props.match.params.pageId) {
      AppAPI.propertyapi.get(`property-content-page/${this.props.match.params.pageId}/`)
        .then((res) => {
          this.setState({
            loading: false,
            page: res,
            initialValues: {
              title: res.title,
              short_content: res.short_content,
              content: res.content,
              active: res.active,
              featured: res.featured,
              layout_type: res.layout_type,
            },
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error: true,
            resultMsg: {error},
          });
        });
    } else {
      this.setState({loading: false});
    }
  };

  addPage = (values) => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.propertyapi.post('property-content-page/', {
        title: values.title,
        active: values.active,
        content: values.content,
        featured: values.featured,
        layout_type: values.layout_type,
        short_content: values.short_content,
      })
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                this.props.history.replace(this.props.location.pathname.replace(/[^/]*$/, `${res.id}/`));
              });
            }, 500);
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    });
  };


  savePage = (values) => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.propertyapi.patch(`property-content-page/${this.props.match.params.pageId}/`, {
        title: values.title,
        active: values.active,
        content: values.content,
        featured: values.featured,
        layout_type: values.layout_type,
        short_content: values.short_content,
      })
        .then(() => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                this.props.history.goBack();
              });
            }, 500);
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    });
  };

  render = () => {
    const {loading, error, initialValues, resultMsg, page} = this.state;
    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;


    const defaultFormValidation = Yup.object().shape({
      title: Yup.string().max(255, 'Too Long!').required('Required'),
      layout_type: Yup.string().max(255, 'Too Long!').required('Required'),
    });

    return (
      <div className="bank-details screen-container ">
        <Helmet>
          <title>{page ? 'Manage Content Page' : 'Add New Page'}</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'Content Page Settings'}
          description={'Manage the contents of page.'}
        />
        <Formik
          onSubmit={(values) => (page ? this.savePage(values) : this.addPage(values))}
          validationSchema={defaultFormValidation}
          initialValues={initialValues}>
          {() => (
            <Form>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12 mb-5">
                  <div
                    className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                    role="button" aria-expanded="false" aria-controls="orderActions">
                    <h6 className={'mb-0'}>
                      Content
                    </h6>
                  </div>
                  <div className={'border p-2'} id={'primaryAction'}>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Input
                          required
                          name='title'
                          label={'Title'}
                          className={'form-control'}
                          hint={'Title on the booking engine homepage. (Max 255)'}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Textarea
                          name='short_content'
                          label={'Short Description'}
                          hint={'Short content appears above the featured image in specif layouts.'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <hr/>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Textarea
                          name='content'
                          label={'Page Content'}
                          hint={'Page content.'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12 mb-5">
                  <div
                    className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                    role="button" aria-expanded="false" aria-controls="orderActions">
                    <h6 className={'mb-0'}>
                      Settings
                    </h6>
                  </div>
                  <div className={'border p-2'} id={'primaryAction'}>
                    <div className={'row'}>
                      <div className="col-6 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='active'
                          label='Page Status'
                          text={'Page active for public ?'}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='featured'
                          label='Featured'
                          text={'Will be listed in booking engine.'}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-12 col-md-6 form-group">
                        <Select
                          className={'form-control rounded-right-0'}
                          required
                          name={'layout_type'}
                          label={'Layout'}
                          placeholder='Select an Option'
                          options={Constants.PAGE_LAYOUT_INPUT}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'row mb-5'}>
                <div className="col-lg-8 col-md-8 col-sm-12 mb-5">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn className={'btn btn-md btn-success btn-lg btn-block'}>
                    <FontAwesomeIcon className={'white-cl mr-2'} icon={page ? faSave : faPlus} size={'sm'}/>
                    {page ? 'Save' : 'Add'} Page
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      page: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      initialValues: {
        active: true,
        layout_type: 1,
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyBookingEngineContentPageManage);