/**
 * Profile Records View
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import { Img } from 'react-image';
// Consts and Libs
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLinkSquareAlt, faTimes} from '@fortawesome/free-solid-svg-icons';
import AppAPI from '../../../lib/api';
import Loading from '../../../components/general/Loading';
import {Alerts} from '../../../components/ui';

/* Component ==================================================================== */
class ProfileRecordDetails extends Component {
  static componentName = 'ProfileRecordDetails';

  static propTypes = {
    data: PropTypes.object,
    profile: PropTypes.object,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        loading: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  preFillData = () => {
    if (this.props.data) {
      AppAPI.profilerecords.get(this.props.data.hash + '/?profile=' + this.props.profile.id)
        .then((res) => {
          this.setState({record: res, init: false, loading: false});
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}, init: false, loading: false});
        });
    }
  };


  render = () => {

    const {init, loading, record, resultMsg} = this.state;
    const {show} = this.props;

    if (show && init) {
      this.preFillData();
    }

    if (!show) return null;

    return (
      <Modal
        {...this.props}
        size={'lg'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Profile Record
          </Modal.Title>
        </Modal.Header>
        <React.Fragment>
          {loading ? <Loading heightMatch={false}/>
            :
            <Modal.Body>
              <Alerts
                status={resultMsg.status}
                success={resultMsg.success}
                error={resultMsg.error}
              />
              <div className="row">
                <div className={'col-md-6 col-sm-12 mb-3'}>
                  {record.record_image_signed &&
                   <Img
                     className={'w-100'}
                     src={record.record_image_signed.record_2x}
                     loader={<Loading heightMatch={false}/>}
                   />
                  }
                </div>
                <div className={'col-md-6 col-sm-12 mb-3'}>
                  <table className={'table table-borderless table-sm'}>
                    <tbody>
                      <tr>
                        <td className={'w-50 text-muted'}>Document Type</td>
                        <td><strong>{record.record_type_display}</strong></td>
                      </tr>
                      {record.record_number && (
                        <tr>
                          <td className={'w-50 text-muted'}>Document Number</td>
                          <td><strong>{record.record_number}</strong></td>
                        </tr>
                      )}
                      {record.record_issue_date && (
                        <tr>
                          <td className={'w-50 text-muted'}>Document Issue</td>
                          <td>{record.record_issue_date}</td>
                        </tr>
                      )}
                      {record.record_expiry_date && (
                        <tr>
                          <td className={'w-50 text-muted'}>Document Expiry</td>
                          <td>{record.record_expiry_date}</td>
                        </tr>
                      )}
                      <tr>
                        <td className={'w-50 text-muted'}>Description</td>
                        <td>{record.description || 'N.A'}</td>
                      </tr>
                      <tr>
                        <td className={'w-50 text-muted'}>Notes</td>
                        <td>{record.notes || 'N.A'}</td>
                      </tr>
                    </tbody>
                  </table>

                  {record.record_image_signed &&
                   <a
                     target={'blank'} className={'btn btn-outline-primary mt-3'} rel='noopener noreferrer'
                     href={record.record_image_signed.record_original}>
                     <FontAwesomeIcon icon={faExternalLinkSquareAlt} className={'mr-2'}/>View Original
                   </a>
                  }
                </div>
              </div>
            </Modal.Body>
          }
          <Modal.Footer className={'d-block'}>
            <div className={'row'}>
              <div className={'col-12'}>
                <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                  <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                </button>
              </div>
            </div>
          </Modal.Footer>
        </React.Fragment>
      </Modal>
    );
  };


  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      record: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: false,
    };
  }
}

/* Export Component ==================================================================== */
export default ProfileRecordDetails;
