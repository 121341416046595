/**
 *
 * Property Slider Image
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Img } from 'react-image';
// Consts and Libs
import AppAPI from '../../../lib/api';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import ListView from '../../../components/general/ListView';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faPlusCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import PropertySliderImageManage from './PropertySliderImageManage';
import PageHeader from '../../../components/dashboard/PageHeader';
import CopyToClipboard from '../../../components/general/CopyToClipboard';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  roomType: state.roomType.roomType,
  event: state.event.event,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PropertySliderImageList extends Component {
  static componentName = 'PropertySliderImageList';

  static propTypes = {
    isEvent: PropTypes.bool,
    isGallery: PropTypes.bool,
    isRoomType: PropTypes.bool,
    hideHeader: PropTypes.bool,
  };


  static defaultProps = {
    isRoomType: false,
    isEvent: false,
    hideHeader: false,
  };


  fetchData = (page = 1, callback) => {

    let urlApi;

    if (this.props.roomType && this.props.isRoomType) {
      urlApi = AppAPI.roomapi.get(`room-type-slider-image/?room_type=${this.props.roomType.id}&page=${page}`);
    } else if (this.props.event && this.props.isEvent) {
      urlApi = AppAPI.eventapi.get(`event-slider-image/?event=${this.props.event.id}&page=${page}`);
    } else if (this.props.isGallery) {
      urlApi = AppAPI.propertyapi.get(`property-gallery-image/?page=${page}`);
    } else {
      urlApi = AppAPI.propertyapi.get(`property-slider-image/?page=${page}`);
    }

    urlApi.then((res) => {
      this.setState({
        loading: false,
        error: null,
        refresh: false,
      });
      if (res.results.length > 0) {
        if (res.next) {
          callback(res.results);
        } else {
          callback(res.results, {
            allLoaded: true, // the end of the list is reached
          });
        }
      } else {
        callback();
      }
    })
      .catch((err) => {
        callback(null, {
          allLoaded: true, // the end of the list is reached
        });
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };

  renderRow = (data, key) => {
    return (
      <React.Fragment key={key}>
        <tr>
          <td className={'w-25 text-left'}>
            {data.image &&
             <Img
               className={'border w-100 p-1'}
               src={data.image.slider}
               loader={<Loading heightMatch={false}/>}
             />
            }
          </td>
          <td className={'w-50'}>
            <p className={'mb-0'}><strong>Title</strong> : {data.title}</p>
            <p className={'mb-0'}><strong>Position</strong> : {data.position}</p>
          </td>
          <td>
            <FontAwesomeIcon
              className={data.active ? 'green-cl' : 'red-cl'}
              icon={data.active ? faCheckCircle : faTimesCircle} size={'sm'}
            />
          </td>
          <td>
            <button
              className={'btn btm-sm btn-link'}
              onClick={() => this.setState({
                showSliderImageManage: true,
                sliderImageDetails: data,
              })}>
              Edit
            </button>
          </td>
        </tr>
        <tr>
          {data.description && <td colSpan={4} className={'border-0'}>
            <p className={'small mb-0 text-muted'}><strong>Description</strong> : {data.description}</p>
          </td> }
        </tr>
      </React.Fragment>
    );
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={5} className={'text-center'}>
          <div className="text-center">
            <h4>{this.props.isGallery ? 'Gallery' : 'Slider'} Image</h4>
          </div>
          <p className={'small'}>
            No images in {this.props.isGallery ? 'Gallery' : 'Slider'}.
          </p>
          <button
            onClick={() => this.setState({showSliderImageManage: true})}
            className="btn btn-success">
            <FontAwesomeIcon icon={faPlusCircle} size={'sm'} className={'mr-2'}/> Add image
          </button>
        </td>
      </tr>
    );
  };
  render = () => {

    const {loading, error} = this.state;
    const {roomType, event, property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    let title = 'Property Slider Image';

    if (roomType && this.props.isRoomType) {
      title = 'Room Type Slider Image';
    }

    if (event && this.props.isEvent) {
      title = 'Event Slider Image';
    }

    if (this.props.isGallery) {
      title = 'Property Gallery Image';
    }

    return (
      <div className="tax-list screen-container ">
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={title}
        />

        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12">
            {this.props.isGallery &&
             <div className={'alert alert-info'}>
               Permanent link to gallery section is : {property.booking_engine_url}/gallery
               <span className={'ml-2'}><CopyToClipboard content={`${property.booking_engine_url}/gallery`}/></span>
             </div>
            }
            <table className={'table'}>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Information</th>
                  <th>Status</th>
                  <th/>
                </tr>
              </thead>
              <tbody>
                <ListView
                  rowView={this.renderRow}
                  onFetch={this.fetchData}
                  firstLoader={true}
                  pagination
                  emptyView={this.emptyView}
                  class={'list-group'}
                  refresh={this.state.refresh}
                  colSpan={4}
                />
              </tbody>
            </table>
          </div>

          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item">
                <button onClick={() => this.setState({showSliderImageManage: true})} className="btn btn-link p-0">
                  <FontAwesomeIcon icon={faPlusCircle} size={'sm'} className={'mr-2'}/> Add Image
                </button>
              </li>
            </ul>
          </div>
        </div>

        <PropertySliderImageManage
          show={this.state.showSliderImageManage}
          data={this.state.sliderImageDetails}
          onHide={() => this.setState({showSliderImageManage: false, sliderImageDetails: null})}
          onSave={() => this.setState({refresh: true})}
          gallery={this.props.isGallery}
          event={this.props.isEvent && this.props.event ? this.props.event : null}
          roomType={this.props.roomType && this.props.isRoomType ? this.props.roomType : null}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: false,
      sliderImageDetails: null,
      showSliderImageManage: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertySliderImageList);
