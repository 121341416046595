/**
 *
 * Booking Room Details
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// Consts and Libs
import {Constants, Strings} from '../../../constants';
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
// Components
import RoomListView from '../../room/RoomListView';
import NotesListView from '../../notes/NotesListView';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import GuestQuickManage from '../../guest/GuestManageQuick';
import TimeLineList from '../../../components/dashboard/TimeLineList';
import ProfileDetailsOverview from '../../guest/model/ProfileDetailsOverview';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowsAltV,
  faCheckCircle,
  faChevronCircleDown,
  faEye,
  faLongArrowAltRight, faPaperPlane,
  faPen,
  faPlusCircle,
  faPuzzlePiece,
  faSignInAlt,
  faSignOutAlt,
  faStickyNote,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

import NotesManager from '../../notes/NotesManager';
import {Alerts, History} from '../../../components/ui';
import MessagingManager from '../../messaging/MessagingManager';
import DateFormatted from '../../../components/general/DateFormatted';
import OrderSetListView from '../../order/components/OrderSetListView';
import BookingRoomGuestCountEdit from '../model/BookingRoomGuestCountEdit';
import BookingAmendmentSelector from '../model/BookingAmendmentSelector.js';

// Actions
import * as BookingRoomActions from '../../../redux/bookingroom/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  booking: state.booking.booking,
  bookingroom: state.bookingroom.bookingroom,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setBookingRoom: BookingRoomActions.setBookingRoom,
};

/* Component ==================================================================== */
class BookingRoomDetails extends Component {
  static componentName = 'BookingRoomDetails';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  bookingAction = () => {
    this.props.history.push(`/booking/${this.props.bookingroom.booking_id}/booking-room/${this.props.bookingroom.id}/action/`);
  };

  componentDidMount = () => {
    if (this.props.match.params.bookingRoomId) {
      this.fetchInitData();
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };


  fetchInitData = () => {
    if (this.props.firstLoad) {
      this.setState({
        loading: false,
      });
    } else {
      this.setState({
        loading: true,
      }, () => {
        this.props.setBookingRoom(this.props.match.params.bookingRoomId)
          .then(() => {
            this.setState({
              loading: false,
            });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({
              loading: false,
              error,
            });
          });
      });
    }
  };


  cacheReset = (type = 'all', callback = null) => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.bookingapi
        .post(`booking-room/${this.props.bookingroom.hash}/cache-reset/?type=${type}`, {})
        .then(res => {
          this.props.setBookingRoom(res);
          this.setState({resultMsg: {success: 'Success'}}, () => {
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
              if (callback) { callback(); }
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({ loading: false, resultMsg: {error},});
        });
    });
  };


  updateBookingRoom = (payload, callback = null) => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.bookingroom
          .patch(this.props.bookingroom.hash + '/', payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.props.setBookingRoom(res);
                this.setState({
                  resultMsg: {success: ''},
                  showGuestManagement: false,
                });
                if (callback) { callback(); }
              }, 500);
            });

          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };


  showGuestDetails = (guestId, add_guest = false) => {
    this.setState({showProfileDetailsOverviewId: guestId, showProfileDetailsOverview: true, addGuestFunction: add_guest});
  };

  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {bookingroom, booking} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const timeLine= [
      {
        'title': 'Created',
        'iconClass': 'green-cl',
        'description': AppUtil.formatDateTime(bookingroom.created),
      }
    ];

    if (bookingroom.actual_checkin) {
      timeLine.push({
        'title': 'Checkin',
        'iconClass': 'green-cl',
        'description': bookingroom.actual_checkin ? AppUtil.formatDateTime(bookingroom.actual_checkin) : '...',
      });
    }

    if (bookingroom.actual_checkout) {
      timeLine.push({
        'title': 'Checkout',
        'iconClass': 'green-cl',
        'description': bookingroom.actual_checkout ? AppUtil.formatDateTime(bookingroom.actual_checkout) : '...',
      });
    }

    if (bookingroom.booking_cancel_time) {
      timeLine.push({
        'title': 'Cancel',
        'iconClass': 'green-cl',
        'description': bookingroom.booking_cancel_time ? AppUtil.formatDateTime(bookingroom.booking_cancel_time) : '...',
      });
    }

    if (bookingroom.booking_no_show_time) {
      timeLine.push({
        'title': 'No Show',
        'iconClass': 'green-cl',
        'description': bookingroom.booking_no_show_time ? AppUtil.formatDateTime(bookingroom.booking_no_show_time) : '...',
      });
    }

    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Booking Details : {bookingroom.ref_no}</title>
        </Helmet>

        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12">
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
            <div className={'row mb-3'}>
              <div className={'col-12 col-lg-6'}>
                <ul className={'list-inline d-inline-flex list-inline-formatted'}>
                  <li className={'list-inline-item d-inline-flex align-items-center'}>
                    <DateFormatted date={bookingroom.checkin} year={true}/>
                    <p className={'ml-3'}>
                      <strong>Checkin</strong> <br/>
                      {AppUtil.formatDateTime(bookingroom.checkin, 'day')}
                    </p>
                  </li>
                  <li className={'mx-3 list-inline-item d-inline-flex align-items-center'}>
                    <div className={'text-center'}>
                      <FontAwesomeIcon icon={faLongArrowAltRight} size={'2x'}/>
                      {bookingroom.checkin && bookingroom.checkout &&
                      <p className={'text-muted text-center mb-0 small'}>
                        {`${AppUtil.diffDateTime(bookingroom.checkin, bookingroom.checkout)} Night(s)`}
                      </p>
                      }
                    </div>
                  </li>
                  <li className={'list-inline-item d-inline-flex align-items-center'}>
                    <DateFormatted date={bookingroom.checkout} year={true}/>
                    <p className={'ml-3'}>
                      <strong>Checkout</strong> <br/>
                      {AppUtil.formatDateTime(bookingroom.checkout, 'day')}
                    </p>
                  </li>
                </ul>
              </div>
              <div className={'col-12 col-lg-6'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Booking Status : </strong>
                    <span className={'badge ' + (AppUtil.bookingStatusColor(bookingroom.booking_status))}>
                      {bookingroom.booking_status_display}
                    </span>
                  </li>
                  <li className="list-group-item">
                    <strong>Room Type
                      : </strong> {!_.isEmpty(bookingroom.attributes.room_type_name) ? bookingroom.attributes.room_type_name : '...'}
                  </li>
                </ul>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-12 col-lg-6'}>
                <table className={'table table-sm table-borderless'}>
                  <tbody>
                    {!_.isEmpty(bookingroom.attributes) &&
                    <React.Fragment>
                      {bookingroom.attributes.booking_hash &&
                      <tr>
                        <td className={'w-50 text-muted'}>Booking</td>
                        <td>
                          <Link to={`/booking/${bookingroom.attributes.booking_hash}/`}>
                            {bookingroom.attributes.booking_ref_no}
                          </Link>
                        </td>
                      </tr>
                      }
                      <tr>
                        <td className={'w-50 text-muted'}>Room Type</td>
                        <td>
                          {!_.isEmpty(bookingroom.attributes.room_type_name) ? bookingroom.attributes.room_type_name : '...'}
                        </td>
                      </tr>
                    </React.Fragment>
                    }
                    <tr>
                      <td className={'w-50 text-muted'}>Package</td>
                      <td>
                        {
                          !_.isEmpty(bookingroom.room_type_package_attributes) ?
                            <React.Fragment>
                              {bookingroom.room_type_package_attributes.map((packageData, i) => (
                                ` ${i > 1 ? ', ' : ''}${packageData.room_type_package ? packageData.room_type_package.name : '..'}`
                              ))}
                            </React.Fragment>
                            :
                            '...'
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={'col-12 col-lg-6'}>
                <table className={'table table-sm table-borderless'}>
                  <tbody>
                    {!_.isEmpty(bookingroom.attributes) &&
                    <React.Fragment>
                      <tr>
                        <td className={'w-50 text-muted'}>Created By</td>
                        <td>{bookingroom.attributes.created_user || '...'}</td>
                      </tr>
                      {bookingroom.attributes.checkin_user &&
                      <tr>
                        <td className={'w-50 text-muted'}>Checkin By</td>
                        <td>{bookingroom.attributes.checkin_user || '...'}</td>
                      </tr>
                      }
                      {bookingroom.attributes.checkout_user &&
                      <tr>
                        <td className={'w-50 text-muted'}>Checkout By</td>
                        <td>{bookingroom.attributes.checkout_user || '...'}</td>
                      </tr>
                      }
                      {bookingroom.attributes.action_user &&
                      <tr>
                        <td className={'w-50 text-muted'}>{bookingroom.booking_status_display} By</td>
                        <td>{bookingroom.attributes.action_user || '...'}</td>
                      </tr>
                      }
                    </React.Fragment>
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <ul className="list-group list-group-horizontal text-center mb-4">
              <li className="list-group-item col-3 text-left">
                <strong>Occupancy</strong>
              </li>
              <li className={`list-group-item col-3 ${parseInt(bookingroom.no_of_guest) < 1 && 'grey-light-bg'}`}>
                Adult : {bookingroom.no_of_guest}
              </li>
              <li className={`list-group-item col-3 ${parseInt(bookingroom.no_of_children) < 1 && 'grey-light-bg'}`}>
                Child : {bookingroom.no_of_children}
              </li>
              <li className={`list-group-item col-3 ${parseInt(bookingroom.no_of_infant) < 1 && 'grey-light-bg'}`}>
                Infant : {bookingroom.no_of_infant}
              </li>
            </ul>

            <div className="mb-5">
              <div
                className={'border rounded p-2 text-center anchor'} data-toggle="collapse" href="#Actions"
                role="button" aria-expanded="false" aria-controls="orderActions">
                <h6 className={'mb-0'}>
                  <FontAwesomeIcon icon={faChevronCircleDown} size={'sm'} className={'mx-2 green-cl float-left mt-1'}/>
                  Booking Package Information
                  <FontAwesomeIcon icon={faChevronCircleDown} size={'sm'} className={'mx-2 green-cl float-right mt-1'}/>
                </h6>
              </div>
              <div className={'py-2 border collapse p-2'} id={'Actions'}>
                {(bookingroom.room_type_package_attributes && bookingroom.room_type_package_attributes.length > 0) &&
                <div className={'row'}>
                  <div className={'col-lg-12 col-md-12 col-sm-12'}>
                    <table className={'table table-sm border format-table mb-0'}>
                      <thead>
                        <tr className={'text-center'}>
                          <th width={'20%'}>Start Date</th>
                          <th width={'20%'}>End Date</th>
                          <th width={'20%'}>Package Name</th>
                          <th width={'20%'}>Cancellation</th>
                          <th width={'20%'}>Inclusions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookingroom.room_type_package_attributes.map((data, i) => (
                          <tr key={i} className={'text-center'}>
                            <td>{AppUtil.formatDateTime(data.start, 'datef')}</td>
                            <td>{AppUtil.formatDateTime(data.end, 'datef')}</td>
                            <td>{data.room_type_package && data.room_type_package.name}</td>
                            <td>{data.room_type_package && data.room_type_package.cancellation_policy_display}</td>
                            <td>
                              <ul className={'list-inline'}>
                                {data.room_type_package.inclusion_break_fast &&
                                <li className={'list-inline-item'}>
                                  <p className={'mb-0 small'}>
                                    <FontAwesomeIcon icon={faCheckCircle} className={'green-cl mr-1'}/>
                                    Breakfast Inclusive
                                  </p>
                                </li>
                                }

                                {data.room_type_package.inclusion_lunch &&
                                <li className={'list-inline-item'}>
                                  <p className={'mb-0 small'}>
                                    <FontAwesomeIcon icon={faCheckCircle} className={'green-cl mr-1'}/>
                                    Lunch Inclusive
                                  </p>
                                </li>
                                }

                                {data.room_type_package.inclusion_dinner &&
                                <li className={'list-inline-item'}>
                                  <p className={'mb-0 small'}>
                                    <FontAwesomeIcon icon={faCheckCircle} className={'green-cl mr-1'}/>
                                    Dinner Inclusive
                                  </p>
                                </li>
                                }
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                }
              </div>
            </div>

            <div className={'mb-3'}>
              <TimeLineList timeLine={timeLine}/>
            </div>

            <div className={'row mb-5'}>
              <div className={'col-md-6 col-sm-12'}>
                {bookingroom.room &&
                <div>
                  <h5>Room</h5>
                  <div className="list-group">
                    <RoomListView data={bookingroom.room} id={'1'} history={this.props.history}/>
                  </div>
                </div>
                }
                {bookingroom.booking_status === Constants.BOOKING.ACTIVE && (
                  <div className={'text-center mt-2'}>
                    <Link
                      to={`/booking/${booking.hash}/booking-room/${bookingroom.hash}/update/`}
                      className="btn btn-link btn-sm"
                    >
                      <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Change Room
                    </Link>
                  </div>
                )}
              </div>
            </div>

            <div className={'mb-5'}>
              <h5>Room Guests</h5>
              <table className={`table border table-sm ${!_.isEmpty(bookingroom.guests) && 'format-table'}`}>
                <thead>
                  <tr>
                    <th className={'data-table-cell-xl'}>Name</th>
                    <th className={'data-table-cell-md'} />
                    <th className={'data-table-cell-xl'} />
                    <th/>
                  </tr>
                </thead>
                <tbody>
                  {_.isEmpty(bookingroom.guests) ?
                    <tr>
                      <td colSpan={5} className={'text-center py-5'}>
                        <h6><FontAwesomeIcon icon={faUser} className={'mr-2'}/> No Guests </h6>
                        <p className={'text-muted'}>{Strings.bookingRoomGuestEmptyText}</p>
                        {booking && bookingroom.booking_active && booking.guest && booking.guest.id && (
                          <button
                            onClick={() => this.showGuestDetails(booking.guest.id, true)}
                            className={'btn btn-link mt-1 p-0'}>
                            <FontAwesomeIcon
                              icon={faPlusCircle} size={'sm'} className={'mr-2'}/> Add ( {booking.guest.name} )
                          </button>
                        )}
                      </td>
                    </tr>
                    :
                    <React.Fragment>
                      {bookingroom.guests.map((data, key) => (
                        <tr key={key}>
                          <td>
                            <strong>{data.entity_name ? data.entity_name : (data.name || '...') }</strong>
                            {(data.email || data.phone) &&
                              <p className={'mb-0 small text-muted'}>
                                {data.email && `Email : ${data.email}. `} {data.phone && `Phone : ${data.phone}`}
                              </p>
                            }
                          </td>

                          <td className={'align-middle'}>

                            <span className={`ml-2 badge ${data.record_exist ? 'badge-success' : 'badge-warning'}`}>
                              {data.record_exist ? 'Document' : 'No Document'}
                            </span>
                          </td>
                          <td className={'align-middle'}>
                            <button
                              className={'btn btn-outline-primary btn-sm mr-2'}
                              disabled={!(data.email || data.phone)}
                              onClick={() => this.setState({
                                messagingPhone: data.phone,
                                messagingEmail: data.email,
                                showMessagingManager: true
                              })}
                            >
                              <FontAwesomeIcon icon={faPaperPlane} size={'sm'} className={'mr-2'}/> Notification
                            </button>

                            <button className={'btn btn-outline-primary btn-sm'} onClick={() => this.showGuestDetails(data.id)}>
                              Details <FontAwesomeIcon icon={faEye} className={'ml-2'}/>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  }
                  {bookingroom.booking_active &&
                  <tr>
                    <td colSpan={5}>
                      <div className={'text-center'}>
                        <button
                          onClick={() => this.setState({showGuestManagement: true})}
                          className={'btn btn-link m-0 p-0 mx-auto'}>
                          <FontAwesomeIcon icon={faPlusCircle} size={'sm'} className={'mr-2'}/> Add Guest
                        </button>
                      </div>
                    </td>
                  </tr>
                  }
                </tbody>
              </table>
            </div>

            {bookingroom.booking_active &&
            <div>
              <hr/>
              <div className={'row'}>
                <div className={'col-lg-6 col-md-12 col-sm-12'}>
                  <button
                    onClick={() => this.bookingAction()}
                    className={'btn btn-lg btn-block ' + (bookingroom.booking_status === Constants.BOOKING.UPCOMING ? 'btn-success' : 'btn-warning')}>
                    <FontAwesomeIcon
                      icon={bookingroom.booking_status === Constants.BOOKING.UPCOMING ? faSignInAlt : faSignOutAlt}
                      size={'sm'} className={'mr-2'}/>
                    {bookingroom.booking_status === Constants.BOOKING.UPCOMING ? 'Checkin' : 'Checkout'}
                  </button>
                </div>
              </div>
              <hr/>
            </div>
            }

            <div className={'row'}>
              {!_.isEmpty(bookingroom.orders) &&
              <div className={'col-lg-12 col-md-12'}>
                <h5>Orders</h5>
                <OrderSetListView data={bookingroom.orders}/>
              </div>
              }
            </div>

            {(bookingroom.notes && bookingroom.notes.length > 0) &&
            <div>
              <div className={'spacer-20'}/>
              <div className={'row'}>
                <div className={'col-lg-6 col-md-12'}>
                  <h6>Notes</h6>
                  <div className="list-group">
                    {bookingroom.notes.map((data, key) => (
                      <NotesListView
                        data={data}
                        key={key}
                        successAction={() => this.fetchInitData()}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            }

          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group mb-5">
              <li className="list-group-item  align-items-center">
                <button onClick={() => this.setState({showNotesManager: true})} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faStickyNote} size={'sm'} className={'mr-2'}/> Add Note
                </button>
              </li>
              <li className="list-group-item  align-items-center">
                <button onClick={() => this.setState({showMessagingManager: true})} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faPaperPlane} size={'sm'} className={'mr-2'}/> Notification ( E-Mail / SMS )
                </button>
              </li>
            </ul>
            <ul className="list-group">
              <li className="list-group-item  align-items-center" onClick={() => this.cacheReset()}>
                <small>Booking Actions</small>
              </li>
              <li className="list-group-item">
                <button
                  onClick={() => this.setState({showBookingRoomGuestCountEdit: true})}
                  disabled={!bookingroom.booking_active}
                  className={'btn btn-link m-0 p-0 blue-cl'}>
                  <FontAwesomeIcon icon={faUser} size={'sm'} className={'mr-2'}/>
                  Guest Occupancy
                </button>
                <p className={'small text-muted mb-0'}>Edit booking guest occupancy</p>
              </li>
              <li className="list-group-item">
                <button
                  onClick={() => this.bookingAction()}
                  disabled={!bookingroom.booking_active}
                  className={'btn btn-link m-0 p-0 ' + (bookingroom.booking_status === Constants.BOOKING.UPCOMING ? 'green-cl' : 'red-cl')}>
                  <FontAwesomeIcon
                    icon={bookingroom.booking_status === Constants.BOOKING.UPCOMING ? faSignInAlt : faSignOutAlt}
                    size={'sm'} className={'mr-2'}/>
                  {bookingroom.booking_status === Constants.BOOKING.UPCOMING ? 'Checkin' : 'Checkout'}
                </button>
                <p
                  className={'small text-muted mb-0'}>{bookingroom.booking_status === Constants.BOOKING.UPCOMING ? Strings.bookingRoomCheckinText : Strings.bookingRoomCheckoutText}</p>
              </li>
              <li className="list-group-item">
                <Link
                  type="button"
                  className={`btn btn-link m-0 p-0 orange-cl ${!bookingroom.booking_active && 'disabled'}`}
                  to={`/booking/${this.props.booking.hash}/booking-room/${this.props.bookingroom.hash}/update-room-type`}
                >
                  <FontAwesomeIcon icon={faArrowsAltV} size={'sm'} className={'mr-2'}/> Update Package or Room Type
                </Link>
                <p className={'small text-muted mb-0'}>{Strings.bookingRoomUpdateText}</p>
              </li>
              <li className="list-group-item">
                <button
                  onClick={() => this.setState({showBookingAmendmentSelector: true})}
                  disabled={!bookingroom.booking_active}
                  className="btn btn-link m-0 p-0"
                >
                  <FontAwesomeIcon icon={faPuzzlePiece} size={'sm'} className={'mr-2'}/> Amendment
                </button>
                <p className={'small text-muted mb-0'}>{Strings.bookingAmendmentText}</p>
              </li>
            </ul>
          </div>
        </div>

        {/*  Profile Manage */}
        <GuestQuickManage
          onHide={() => this.setState({showGuestManagement: false})}
          show={this.state.showGuestManagement}
          history={this.props.history}
          property={this.props.property}
          showGuestDetails={this.showGuestDetails}
          guestAddAction={(data) => this.updateBookingRoom({guests: [data.id]})}
        />

        {/*  Profile Overview */}
        <ProfileDetailsOverview
          property={this.props.property}
          show={this.state.showProfileDetailsOverview}
          guestId={this.state.showProfileDetailsOverviewId}
          onHide={()=>this.setState({showProfileDetailsOverview: false, showProfileDetailsOverviewId: null})}

          resultMsg={resultMsg}
          showRecords={!this.state.addGuestFunction}
          profileActionType={this.state.addGuestFunction ? '+': '-'}
          editControl={bookingroom.booking_active && !this.state.addGuestFunction}
          profileAction={bookingroom.booking_active ? this.guestAddOrRemote : null}
          cacheReset={bookingroom.booking_active ? (callback) => this.cacheReset('guest', callback) : null}
        />

        {/* Booking Amendments */}
        <BookingAmendmentSelector
          property={this.props.property}
          onHide={() => this.setState({showBookingAmendmentSelector: false})}
          show={this.state.showBookingAmendmentSelector}
          bookingId={bookingroom.booking_id}
          bookingRoomId={bookingroom.id}
          postAction={()=>this.props.history.push(`/booking/${booking.hash}/booking-room/${bookingroom.hash}/booking-amendment/`)}
        />

        {/* Notes */}
        <NotesManager
          bookingRoom={bookingroom}
          show={this.state.showNotesManager}
          onHide={() => this.setState({showNotesManager: false})}
          successAction={() => this.props.setBookingRoom(bookingroom.id)}
        />

        {/* Guest Count Edit */}
        <BookingRoomGuestCountEdit
          show={this.state.showBookingRoomGuestCountEdit}
          onHide={() => this.setState({showBookingRoomGuestCountEdit: false})}
          updateBookingRoom={this.updateBookingRoom}
          bookingRoom={bookingroom}
        />

        {/* Messaging */}
        <MessagingManager
          onHide={() => this.setState({showMessagingManager: false, messagingPhone: null, messagingEmail: null})}
          show={this.state.showMessagingManager}
          property={this.props.property}
          bookingRoom={bookingroom}
          guests={bookingroom.guests}
        />

        <History object={bookingroom}/>
      </div>
    );
  };

  guestAddOrRemote = (data, callback) => {
    if (this.state.addGuestFunction) {
      this.updateBookingRoom({guests: [data.id]}, callback);
    } else {
      this.updateBookingRoom({guests_to_remove: [data.id]}, callback);
    }
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      showBookingRoomGuestCountEdit: false,
      showBookingAmendmentSelector: false,
      showProfileDetailsOverview: false,
      showProfileDetailsOverviewId: null,
      showGuestManagement: false,
      addGuestFunction: false,
      showNotesManager: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingRoomDetails);
