/**
 * Manage Agent
 *  manages agent add and edit
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import {ErrorMessages} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faKey, faPencilAlt, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import ProfileVerification from './ProfileVerification';
// Actions
import * as UserActions from '../../../redux/user/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  user: state.user.user,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setUser: UserActions.setUser,
};

/* Component ==================================================================== */

class Profile extends Component {
  static componentName = 'Profile';

  componentDidMount() {
    this.fetchInitData();
  };

  fetchInitData = () => {
    AppAPI.account.get('')
      .then((res) => {
        this.setState({
          user: res,
          loading: false,
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: error,
        });
      });
  };

  updateUser = () => {
    this.fetchInitData();
    this.props.setUser();
  };

  render = () => {

    const {loading, error, resultMsg, user} = this.state;
    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    return (
      <div className="agent-manage screen-container ">
        <Helmet>
          <title>Profile</title>
        </Helmet>
        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'User Profile'}
          description={'User profile information'}
        />
        <div className="row">
          <div className="col-lg-9 col-md-8 col-sm-12">
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                <ul className="list-group mb-2">
                  <li className="list-group-item">
                    <strong>First Name</strong> : {user.first_name}
                  </li>
                  <li className="list-group-item">
                    <strong>Last Name</strong> : {user.last_name}
                  </li>
                  <li className="list-group-item">
                    <strong>Designation</strong> : {user.designation}
                  </li>
                </ul>

                <ul className="list-group mb-2">
                  <li className="list-group-item">
                    <strong>Email</strong> :
                    {user.email ?
                      <React.Fragment>
                        {user.email}
                        <div className={'float-right'}>
                          {!user.email_verified ?
                            <button
                              onClick={() => this.setState({
                                showProfileVerification: true,
                                profileVerificationType: 'email',
                              })}
                              className={'btn btn-sm btn-warning'}
                            >Verify</button>
                            :
                            <FontAwesomeIcon
                              icon={user.email_verified ? faCheckCircle : faTimesCircle} size={'sm'}
                              className={`mx-2 ${user.email_verified ? 'green-cl' : 'red-cl'} mt-1`}
                            />
                          }
                        </div>
                      </React.Fragment> : 'Nil'}
                  </li>

                  <li className="list-group-item">
                    <strong>Phone</strong> :
                    {user.phone ?
                      <React.Fragment>
                        {user.phone}
                        <div className={'float-right'}>
                          {!user.phone_verified ?
                            <button
                              onClick={() => this.setState({
                                showProfileVerification: true,
                                profileVerificationType: 'phone',
                              })}
                              className={'btn btn-sm btn-warning'}
                            >Verify</button>
                            :
                            <FontAwesomeIcon
                              icon={user.phone_verified ? faCheckCircle : faTimesCircle} size={'sm'}
                              className={`mx-2 ${user.phone_verified ? 'green-cl' : 'red-cl'} mt-1`}
                            />
                          }
                        </div>
                      </React.Fragment> : 'Nil'}
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Created on </strong> : {AppUtil.formatDateTime(user.created)}
                  </li>
                  <li className="list-group-item">
                    <strong>Account type</strong> : {user.account_type_display}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item">
                <Link to={'/user/account/profile/edit/'} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faPencilAlt} size={'sm'} className={'mr-2'}/> Edit profile
                </Link>
              </li>
              <li className="list-group-item">
                <Link to={'/user/account/profile/update-password/'} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faKey} size={'sm'} className={'mr-2'}/> Update Password
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <ProfileVerification
          profile={this.props.user}
          type={this.state.profileVerificationType}
          show={this.state.showProfileVerification}
          onHide={() => this.setState({showProfileVerification: false})}
          updateResponse={() => this.updateUser()}
        />
      </div>

    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      form_values: {},
      options: {},
      showProfileVerification: false,
      profileVerificationType: 'email',
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
