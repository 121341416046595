/**
 * Expense Payment Details
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
// Consts and Libs
import AppAPI from '../../lib/api';
// Components
import AppUtil from '../../lib/util';
import {Alerts} from '../../components/ui';
import {Constants, Strings} from '../../constants';
import Loading from '../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {confirmAlert} from '../../components/general/ConfirmAlert';
import {faCheckCircle, faLongArrowAltRight, faStickyNote, faTimes} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */
class ExpensePaymentDetails extends Component {
  static componentName = 'ExpensePaymentDetails';

  static propTypes = {
    data: PropTypes.object,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        loading: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  preFillData = () => {
    AppAPI.expenseapi.get(`expense-payment/${this.props.data.hash}/`)
      .then((res) => {
        this.setState({payment: res, init: false, loading: false});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({resultMsg: {error}, init: false, loading: false});
      });
  };

  updatePayment = payload => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.expenseapi
          .patch(`expense-payment/${this.props.data.hash}/`, payload)
          .then(res => {

            this.setState({resultMsg: {success: 'Success'}, payment: res}, () => {
              setTimeout(() => {
                this.setState({
                  resultMsg: {success: ''},
                });
                this.props.onHide();
                this.props.updateResponse();
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  cacheReset = () => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.expenseapi
        .post(`expense/expense-payment/${this.props.data.hash}/cache-reset/`, {})
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}, payment: res}, () => {
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            resultMsg: {error},
          });
        });
    });
  };


  render = () => {

    const {init, loading, payment, resultMsg} = this.state;
    const {show} = this.props;

    if (show && init) {
      this.preFillData();
    }

    if (!show) return null;

    return (
      <Modal
        {...this.props}
        size={'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Payment Details
          </Modal.Title>
        </Modal.Header>
        <React.Fragment>
          {loading ? <Modal.Body><Loading heightMatch={false}/></Modal.Body>
            :
            <React.Fragment>
              <Modal.Body>
                <div className={'row'}>
                  <div className={'col'}>
                    <p className={'mb-0 text-muted'} onClick={() => this.cacheReset()}>Total</p>
                    <div className={'d-inline-flex align-items-baseline'}>
                      <h2>
                        {payment.currency + ' ' + payment.total}
                      </h2>
                      <div className={'align-items-baseline'}>
                        <span className={'ml-2 badge ' + (AppUtil.paymentStatusColor(payment.payment_status))}>
                          {payment.payment_status_display}
                        </span>
                        {payment.refund_status !== Constants.REFUND_STATUS.NOTPAID &&
                         <span className={'ml-2 badge ' + (AppUtil.paymentStatusColor(payment.refund_status))}>
                           {payment.refund_status_display}
                         </span>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div className={'row'}>
                  <div className={'col-lg-12 col-md-12'}>
                    <table className={'table table-borderless table-sm mb-3'}>
                      <tbody>
                        <tr>
                          <td className={'w-50 text-muted'}>Ref No</td>
                          <td><strong>{payment.ref_no}</strong></td>
                        </tr>
                        {payment.payment_id &&
                         <tr>
                           <td className={'w-50 text-muted'}>Payment ID</td>
                           <td><strong>{payment.payment_id}</strong></td>
                         </tr>
                        }
                        <tr>
                          <td className={'w-50 text-muted'}>Payment Method</td>
                          <td>{payment.payment_method_display}</td>
                        </tr>
                        {payment.description &&
                         <tr>
                           <td className={'w-50 text-muted'}>Description</td>
                           <td><strong>{payment.description}</strong></td>
                         </tr>
                        }
                        {payment.payment_settlement_id &&
                         <tr>
                           <td className={'w-50 text-muted'}>Settlement ID</td>
                           <td>{payment.payment_settlement_id}</td>
                         </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className={'mb-3'}>
                  <h5>Payment Timeline</h5>
                  <hr/>
                  <ul className={'list-inline d-inline-flex list-inline-formatted'}>
                    <li className={'list-inline-item align-items-center'}>
                      <p className={'mb-0'}>
                        <FontAwesomeIcon icon={faCheckCircle} size={'sm'} className={'green-cl mr-2'}/>
                        <strong>Created</strong>
                      </p>
                      <p className={'text-muted small'}>{AppUtil.formatDateTime(payment.created)}</p>
                    </li>
                    <li className={'mx-3 list-inline-item d-inline-flex align-items-center'}>
                      <FontAwesomeIcon icon={faLongArrowAltRight}/>
                    </li>
                    <li className={'list-inline-item align-items-center'}>
                      <p className={'mb-0'}>
                        <FontAwesomeIcon
                          icon={faCheckCircle} size={'sm'}
                          className={`mr-2 ${payment.payment_status === Constants.PAYMENT_STATUS.PAID ? 'green-cl' : 'grey-cl'}`}
                        />
                        <strong>Payment</strong>
                      </p>
                      <p className={'text-muted small'}>
                        {payment.payment_time ? AppUtil.formatDateTime(payment.payment_time) : payment.payment_status_display}
                      </p>
                    </li>
                    {payment.refund_time &&
                     <React.Fragment>
                       <li className={'mx-3 list-inline-item d-inline-flex align-items-center'}>
                         <FontAwesomeIcon icon={faLongArrowAltRight}/>
                       </li>
                       <li className={'list-inline-item align-items-center'}>
                         <p className={'mb-0'}>
                           <FontAwesomeIcon
                             icon={faCheckCircle} size={'sm'}
                             className={`mr-2 ${payment.refund_time ? 'green-cl' : 'grey-cl'}`}
                           />
                           <strong>Refunded</strong>
                         </p>
                         <p className={'text-muted small'}>
                           {payment.refund_time ? AppUtil.formatDateTime(payment.refund_time) : payment.refund_status_display}
                         </p>
                       </li>
                     </React.Fragment>
                    }
                  </ul>
                </div>

                <div className={'row mb-3'}>
                  <div className={'col-12'}>
                    <h5>Payment Details</h5>
                    <hr/>
                    <table className={'table table-borderless table-sm'}>
                      <tbody>
                        <tr>
                          <td className={'w-25'}>Payment</td>
                          <td>{payment.currency + ' ' + payment.total}</td>
                          <td>
                            {payment.reference_id &&
                             <React.Fragment>
                               <FontAwesomeIcon icon={faStickyNote} className={'mr-2'}/> {payment.reference_id}
                             </React.Fragment>
                            }
                          </td>
                        </tr>
                        {parseFloat(payment.total_refunded) > 0 &&
                         <tr>
                           <td className={'w-25'}>Refunded</td>
                           <td className={'w-25'}>{payment.currency + ' ' + payment.total_refunded}</td>
                           <td/>
                         </tr>
                        }
                        {(payment.settlement_status === Constants.SETTLEMENT_STATUS.SETTLED || parseFloat(payment.total_refunded) > 0) &&
                         <tr>
                           <td className={'w-25'}><strong>Net Payment</strong></td>
                           <td className={'w-25'}>
                             <strong>{payment.currency + ' ' + payment.total_available_fund}</strong>
                           </td>
                           <td/>
                         </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>

                {(!_.isEmpty(payment.refunds)) &&
                 <div className={'row mb-3'}>
                   <div className={'col-12'}>
                     <h5>Refunds</h5>
                     <table className={'table table-sm border'}>
                       <thead>
                         <tr>
                           <th>Amount</th>
                           <th/>
                           <th>Reference</th>
                           <th>Method</th>
                           <th/>
                         </tr>
                       </thead>
                       <tbody>
                         {payment.refunds.map((data, key) => (
                           <tr key={key}>
                             <td><strong>{`${data.currency} ${data.total}`}</strong></td>
                             <td>
                               <span className={'badge ' + (AppUtil.paymentStatusColor(data.payment_status))}>
                                 {data.payment_status_display}
                               </span>
                             </td>
                             <td><small>{data.ref_no}</small></td>
                             <td>{data.payment_method_display}</td>
                             <td>
                               <button
                                 className={'btn btn-link p-0 m-0'}
                                 onClick={() => this.props.showRefund(data)}
                               >
                                 Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
                               </button>
                             </td>
                           </tr>
                         ))}
                       </tbody>
                     </table>
                   </div>
                 </div>
                }
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <Alerts
                  status={resultMsg.status}
                  success={resultMsg.success}
                  error={resultMsg.error}
                />
                <div className={'row'}>
                  {payment.payment_status === Constants.PAYMENT_STATUS.NOTPAID ?
                    <React.Fragment>
                      <div className={'col'}>
                        <button
                          className={'btn btn-lg btn-block btn-secondary'}
                          onClick={() => {
                            confirmAlert({
                              title: 'Cancel Payment',
                              message: Strings.paymentCancelText,
                              buttons: [
                                {
                                  className: 'btn-danger',
                                  label: 'Cancel Payment',
                                  onClick: () => this.updatePayment({void_payment: true}),
                                },
                                {
                                  className: 'btn-secondary',
                                  label: 'Exit',
                                },
                              ],
                            });
                          }}
                        >
                          Cancel Payment
                        </button>
                      </div>
                      <div className={'col'}>
                        <button
                          className={'btn btn-lg btn-block btn-success'}
                          onClick={() => {
                            confirmAlert({
                              title: 'Confirm Payment',
                              message: Strings.paymentConfirmText,
                              buttons: [
                                {
                                  className: 'btn-success',
                                  label: 'Confirm Payment',
                                  onClick: () => this.updatePayment({mark_payment: true}),
                                },
                                {
                                  className: 'btn-secondary',
                                  label: 'Exit',
                                },
                              ],
                            });
                          }}
                        >
                          Confirm Payment
                        </button>
                      </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      {payment.allow_refund &&
                       <div className={'col'}>
                         <button className={'btn btn-lg btn-block btn-success'} onClick={() => this.props.addRefund(payment)}>
                           Refund Payment
                         </button>
                       </div>
                      }
                      <div className={'col'}>
                        <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                          <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                        </button>
                      </div>
                    </React.Fragment>
                  }
                </div>
              </Modal.Footer>
            </React.Fragment>
          }
        </React.Fragment>
      </Modal>
    );
  };


  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      record: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: false,
    };
  }
}

/* Export Component ==================================================================== */
export default ExpensePaymentDetails;
