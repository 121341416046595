import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';


import Profile from './profile/Profile';
import ProfileEdit from './profile/ProfileEdit';
import SessionList from './session/SessionsList';
import ChangePassword from './profile/ProfilePasswordUpdate';
import NotFound from '../NotFound';
import NavigationTabs from '../../components/dashboard/NavigationTabs';

const menu = [
  {
    title: 'Account',
    link: '/user/account/profile',
  },
  {
    title: 'Login Session',
    link: '/user/account/session',
  },
];

const Account = () => (
  <div>
    <div className={'row'}>
      <div className={'col-lg-2 col-md-3 col-sm-12 mb-5'}>
        <NavigationTabs
          navigationType={'nav-pills'} navItems={menu}
          classNameContainer={'mb-4 flex-md-column flex-sm-row border rounded'}
        />
      </div>
      <div className={'col-lg-10 col-md-9 col-sm-12'}>
        <Switch>
          <Route exact path={'/user/account/'} render={() => (<Redirect to={'/user/account/profile'}/>)}/>
          <Route path="/user/account/profile" exact component={Profile}/>
          <Route path="/user/account/profile/edit" exact component={ProfileEdit}/>
          <Route path="/user/account/profile/update-password" component={ChangePassword}/>


          <Route path="/user/account/session" component={SessionList}/>

          <Route exact component={NotFound}/>
        </Switch>
      </div>
    </div>
  </div>
);

export default Account;