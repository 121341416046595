/**
 * Booking Room Row View
 *  View for Booking Listing
 *
 */

import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppUtil from '../../../lib/util';

// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBaby,
  faChild,
  faLongArrowAltRight,
  faUser
} from '@fortawesome/free-solid-svg-icons';


/* Component ==================================================================== */
class BookingRoomRowView extends Component {
  static componentName = 'BookingRoomRowView';

  static propTypes = {
    roomData: PropTypes.object.isRequired,
    key: PropTypes.string,
    history: PropTypes.object,
    disabled: PropTypes.bool,
    borderTop: PropTypes.bool,
    paddingTd: PropTypes.bool,
    showRoomNumber: PropTypes.bool,
    showCreatedDate: PropTypes.bool,
  };
  
  static defaultProps = {
    roomExpanded: false,
    borderTop: false,
    paddingTd: false,
    showRoomNumber: false,
    showCreatedDate: true,
  }

  render = () => {
    const { key, roomData, borderTop, paddingTd, showCreatedDate, showRoomNumber} = this.props;
    const attributes = _.isEmpty(roomData.attributes) ? {} : roomData.attributes;
    return (
      <React.Fragment key={key}>
        <tr className={borderTop && 'border-top'}>
          {paddingTd ?
            <td className={'data-table-cell-xm table-secondary'} rowSpan={!_.isEmpty(attributes) ? 2 : 1}/>
            :
            <td
              rowSpan={!_.isEmpty(attributes) ? 2 : 1}
              className={`align-middle ${!_.isEmpty(attributes) && 'border-right'}`}
            >
              {attributes.booking_ref_no}
              {(attributes.booking_ota_name) && <div className={'small text-muted'}>({attributes.booking_ota_name})</div>}
            </td>
          }
          <td className={'align-middle text-left'}>
            <p className={'small mb-0 text-muted'}>Dates</p>
            {`${AppUtil.formatDateTime(roomData.checkin, 'date')} - ${AppUtil.formatDateTime(roomData.checkout, 'date')}`}
          </td>
          <td className={'align-middle text-left'}>
            <p className={'small mb-0 text-muted'}>Occupancy</p>
            <p className="mb-0 small">
              <FontAwesomeIcon icon={faUser} size={'sm'}/> {roomData.no_of_guest}
              <FontAwesomeIcon icon={faChild} size={'sm'} className={'ml-2'}/> {roomData.no_of_children}
              <FontAwesomeIcon icon={faBaby} size={'sm'} className={'ml-2'}/> {roomData.no_of_infant}
            </p>
          </td>
          <td className={'align-middle text-left'}>
            <p className={'small mb-0 text-muted text-left'}>Status</p>
            <span className={'badge ' + (AppUtil.bookingStatusColor(roomData.booking_status))}>
              {roomData.booking_status_display}
            </span>
          </td>
          <td
            rowSpan={2}
            className={`align-middle text-right ${(!_.isEmpty(attributes) )&& 'border-left'}`}
          >
            {attributes.booking_hash &&
              <React.Fragment>
                <Link to={`/booking/${attributes.booking_hash}/booking-room/${roomData.hash}`}>
                  {showRoomNumber ? `Room ${roomData.room_number}` : roomData.ref_no }
                  <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
                </Link>
                {showCreatedDate &&
                <p className={'small mb-0'}>{roomData.created ? AppUtil.formatDateTime(roomData.created) : '...'}</p>
                }
              </React.Fragment>
            }
          </td>
        </tr>
        {!_.isEmpty(attributes) &&
        <tr className={''}>
          <td className={'align-middle text-left'}>
            <p className={'small mb-0 text-muted'}>Package</p>
            <p className={'mb-0'}>
              {
                !_.isEmpty(roomData.room_type_package_attributes) ?
                  <React.Fragment>
                    {roomData.room_type_package_attributes.map((packageData, i) => (
                      ` ${i > 1 ? ', ' : ''}${packageData.room_type_package ? packageData.room_type_package.name : '..'}`
                    ))}
                  </React.Fragment>
                  :
                  '...'
              }
            </p>
          </td>
          <td className={'align-middle text-left'}>
            <p className={'small mb-0 text-muted'}>Room Type</p>
            {!_.isEmpty(attributes.room_type_name) ? attributes.room_type_name : '...'}
          </td>
          <td className={'align-middle text-left'}>
            <p className={'text-left small mb-0 text-muted'}>Room</p>
            <p className={'small text-left mb-0'}>
              {!_.isEmpty(attributes.room_name) ? attributes.room_name : '...'}
            </p>
          </td>
        </tr>
        }
      </React.Fragment>
    );
  };
}

/* Export Component ==================================================================== */
export default BookingRoomRowView;

