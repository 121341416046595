export default {
  roomStatusType: [
    {
      title: 'Occupied',
      key: 'occupied',
    },
    {
      title: 'Empty',
      key: 'empty',
    },
  ],
  tableStatusType: [
    {
      title: 'Occupied',
      key: 'occupied',
    },
    {
      title: 'Empty',
      key: 'empty',
    },
  ],
  tableViewSelector: [
    {
      title: 'Grid View',
      key: 'grid',
    },
    {
      title: 'Table View',
      key: 'table',
    },
  ],
  orderSource: [
    {
      title: 'Booking',
      key: 'booking',
    },
    {
      title: 'Point Of Sales',
      key: 'pos',
    },
    {
      title: 'Booking Amendments',
      key: 'amendments',
    },
    {
      title: 'External',
      key: 'external',
    },
    {
      title: 'Event',
      key: 'event',
    },
    {
      title: 'User',
      key: 'user',
    },
  ],
  orderSourceValueFilter: [
    {
      title: 'Booking',
      key: 1,
    },
    {
      title: 'Point Of Sales',
      key: 2,
    },
    {
      title: 'Booking Amendments',
      key: 3,
    },
    {
      title: 'External',
      key: 4,
    },
    {
      title: 'User',
      key: 5,
    },
    {
      title: 'Event',
      key: 6,
    },
  ],
  orderFilter: [
    {
      title: 'Open',
      key: 'open',
    },
    {
      title: 'Paid',
      key: 'paid',
    },
    {
      title: 'Part Paid',
      key: 'part-paid',
    },
    {
      title: 'Refunded',
      key: 'refund',
    },
    {
      title: 'Part Refunded',
      key: 'part-refund',
    },
    {
      title: 'Not Paid',
      key: 'not-paid',
    },
    {
      title: 'Canceled',
      key: 'canceled',
    },
  ],
  orderStatusValueFilter: [
    {
      title: 'Paid',
      key: 1,
    },
    {
      title: 'Part Paid',
      key: 2,
    },
    {
      title: 'Refunded',
      key: 4,
    },
    {
      title: 'Part Refunded',
      key: 3,
    },
    {
      title: 'Not Paid',
      key: 0,
    },
  ],
  notificationChannel: [
    {
      title: 'E-Mail',
      key: 'email',
    },
    {
      title: 'Text (SMS)',
      key: 'text',
    },
  ],
  userSessionStatusFilter: [
    {
      key: 'active',
      title: 'Active'
    },
    {
      key: 'system-expired',
      title: 'System Expired'
    },
    {
      key: 'timeout',
      title: 'Time Out'
    },
    {
      key: 'user-logout',
      title: 'User Logout'
    },
    {
      key: 'user-expired',
      title: 'User Expired'
    },
  ],
  userSessionDeviceFilter: [
    {value: 'renzo-dashboard', title: 'Renzo Dashboard'},
    {value: 'renzo-app', title: 'Renzo App'},
    {value: 'others', title: 'Other'},
  ],
  bookingFilter: [
    {
      title: 'Up Coming',
      key: 'upcoming',
    },
    {
      title: 'Active',
      key: 'active',
    },
    {
      title: 'Complete',
      key: 'completed',
    },
    {
      title: 'No Show',
      key: 'no_show',
    },
    {
      title: 'Cancelled',
      key: 'cancelled',
    },
  ],
  bookingType: [
    {
      title: 'Confirmed',
      key: 'confirmed',
    },
    {
      title: 'Provisional',
      key: 'provisional',
    },
  ],
  bookingAmendmentFilter: [
    {
      title: 'Requested',
      key: 'requested',
    },
    {
      title: 'Complete',
      key: 'completed',
    },
    {
      title: 'Cancelled',
      key: 'cancelled',
    },
  ],
  bookingAmendmentValueFilter: [
    {
      title: 'Requested',
      key: 1,
    },
    {
      title: 'Complete',
      key: 2,
    },
    {
      title: 'Cancelled',
      key: 0,
    },
  ],
  bookingSource: [
    {
      title: 'Agent',
      key: 'agent',
    },
    {
      title: 'Email',
      key: 'email',
    },
    {
      title: 'Walk In',
      key: 'walkin',
    },
    {
      title: 'Phone',
      key: 'phone',
    },
    {
      title: 'Channel Manager',
      key: 'channel_manager',
    },
    {
      title: 'Booking Engine',
      key: 'booking_engine',
    },
    {
      title: 'Corporate',
      key: 'corporate',
    },
    {
      title: 'OTA',
      key: 'ota',
    },
  ],
  orderStatus: [
    {
      title: 'Not Paid',
      key: 'not-paid',
    },
    {
      title: 'Paid',
      key: 'paid',
    },
    {
      title: 'Part Paid',
      key: 'part-paid',
    },
    {
      title: 'Part Refunded',
      key: 'part-refunded',
    },
    {
      title: 'Refunded',
      key: 'refunded',
    },
    {
      title: 'Cancelled',
      key: 'cancelled',
    },
  ],
  paymentType: [
    {
      title: 'Direct',
      key: 'direct',
    },
    {
      title: 'Indirect',
      key: 'indirect',
    },
  ],
  paymentTypeValue: [
    {
      title: 'Direct',
      key: 1,
    },
    {
      title: 'Indirect',
      key: 2,
    },
  ],
  settlementStatus: [
    {
      title: 'Not Settled',
      key: 'not-settled',
    },
    {
      title: 'Settled',
      key: 'settled',
    },
  ],
  paymentStatus: [
    {
      title: 'Not Paid',
      key: 'not-paid',
    },
    {
      title: 'Paid',
      key: 'paid',
    },
    {
      title: 'Part Paid',
      key: 'part-paid',
    },
    {
      title: 'Cancelled',
      key: 'cancelled',
    },
  ],
  paymentStatusValue: [
    {
      title: 'Not Paid',
      key: 0,
    },
    {
      title: 'Paid',
      key: 1,
    },
    {
      title: 'Part Paid',
      key: 2,
    },
    {
      title: 'Cancelled',
      key: 6,
    },
  ],
  paymentMethod: [
    {
      title: 'Cash',
      key: 'cash',
    },
    {
      title: 'Cheque',
      key: 'cheque',
    },
    {
      title: 'Card',
      key: 'card',
    },
    {
      title: 'Online Payment',
      key: 'online-payment',
    },
    {
      title: 'Bank Transfer',
      key: 'bank-transfer',
    },
    {
      title: 'Online (External)',
      key: 'online-external',
    },
    {
      title: 'Payment Terminal',
      key: 'payment-terminal',
    },
    {
      title: 'Channel Collect',
      key: 'channel-collect',
    },
  ],
  paymentMode: [
    {
      title: 'Standard',
      key: 'standard',
    },
    {
      title: 'Consolidated',
      key: 'consolidated',
    },
    {
      title: 'Sub Payment',
      key: 'sub-payment',
    },
  ],
  paymentMethodValue: [
    {
      title: 'Cash',
      key: 1,
    },
    {
      title: 'Cheque',
      key: 2,
    },
    {
      title: 'Card',
      key: 5,
    },
    {
      title: 'Online Payment',
      key: 4,
    },
    {
      title: 'Bank Transfer',
      key: 3,
    },
    {
      title: 'Other',
      key: 6,
    },
    {
      title: 'Online (External)',
      key: 7,
    },
  ],
  refundStatus: [
    {
      title: 'Not Refunded',
      key: 'not-refunded',
    },
    {
      title: 'Refunded',
      key: 'refunded',
    },
    {
      title: 'Part Refunded',
      key: 'part-refunded',
    },
    {
      title: 'Cancelled',
      key: 'cancelled',
    },
  ],
  refundStatusValue: [
    {
      title: 'Not Refunded',
      key: 0,
    },
    {
      title: 'Refunded',
      key: 4,
    },
    {
      title: 'Part Refunded',
      key: 3,
    },
    {
      title: 'Cancelled',
      key: 6,
    },
  ],
  pGatewayStatus: [
    {
      title: 'Paid',
      key: 'paid',
    },
    {
      title: 'Not Paid',
      key: 'not-paid',
    },
    {
      title: 'Cancelled',
      key: 'cancelled',
    },
    {
      title: 'Failed',
      key: 'failed',
    },
    {
      title: 'Pending',
      key: 'pending',
    },
    {
      title: 'Action',
      key: 'action',
    },
    {
      title: 'Waiting',
      key: 'waiting',
    },
    {
      title: 'Aborted',
      key: 'aborted',
    },
  ],
  pTerminalStatus: [
    {
      title: 'Paid',
      key: 'paid',
    },
    {
      title: 'Not Paid',
      key: 'not-paid',
    },
    {
      title: 'Cancelled',
      key: 'cancelled',
    },
    {
      title: 'Failed',
      key: 'failed',
    },
    {
      title: 'Initial',
      key: 'initial',
    },
    {
      title: 'Action',
      key: 'action',
    },
    {
      title: 'Awaiting Terminal',
      key: 'waiting',
    },
  ],
  ticketBookingStatus: [
    {
      title: 'In Booking',
      key: 'provisional',
    },
    {
      title: 'Booked',
      key: 'booked',
    },
    {
      title: 'Cancelled',
      key: 'cancelled',
    },
    {
      title: 'Claimed',
      key: 'claimed',
    },
    {
      title: 'Not Claimed',
      key: 'not-claimed',
    },
  ],
  ticketBookingSource: [
    {
      title: 'Agent',
      key: 'agent',
    },
    {
      title: 'Email',
      key: 'email',
    },
    {
      title: 'Walk In',
      key: 'walkin',
    },
    {
      title: 'Phone',
      key: 'phone',
    },
    {
      title: 'Booking Engine',
      key: 'booking_engine',
    },
    {
      title: 'Corporate',
      key: 'corporate',
    },
  ],
  paymentDateType: [
    {
      title: 'Created',
      key: 'created',
      value: 1,
    },
    {
      title: 'Payment Date',
      key: 'payment',
      value: 4
    },
  ],
  orderDateType: [
    {
      title: 'Created',
      key: 'created',
      value: 1,
    },
    {
      title: 'Payment Date',
      key: 'payment',
      value: 4
    },
    {
      title: 'Refund Date',
      key: 'refund',
      value: 5
    },
  ],
  bookingDateType: [
    {
      title: 'Created',
      key: 'created',
      value: 1
    },
    {
      title: 'Check In',
      key: 'checkin',
      value: 2,
    },
    {
      title: 'Check Out',
      key: 'checkout',
      value: 3,
    },
  ],
  posOrderStatusFilter: [
    {
      title: 'Paid',
      key: 'paid',
    },
    {
      title: 'Part Paid',
      key: 'part-paid',
    },
    {
      title: 'Refunded',
      key: 'refund',
    },
    {
      title: 'Part Refunded',
      key: 'part-refund',
    },
    {
      title: 'Not Paid',
      key: 'not-paid',
    },
  ],
  posOrderFilter: [
    {
      title: 'Open',
      key: 'open',
    },
    {
      title: 'Hold',
      key: 'hold',
    },
    {
      title: 'Completed',
      key: 'completed',
    },
    {
      title: 'Canceled',
      key: 'canceled',
    },
  ],
  posOrderKOTFilter: [
    {
      title: 'Original',
      key: 'original',
    },
    {
      title: 'Modified',
      key: 'modified',
    },
    {
      title: 'Canceled',
      key: 'canceled',
    },
  ],
  foodType: [
    {
      title: 'Drink',
      key: 1
    },
    {
      title: 'Food',
      key: 2
    },
    {
      title: 'Tobacco',
      key: 3
    },
    {
      title: 'Other',
      key: 6
    },
  ],
  drinkContentType: [
    {
      title: 'Alcoholic',
      key: 1
    },
    {
      title: 'Non Alcoholic',
      key: 2
    },
    {
      title: 'Soft Drinks',
      key: 3
    },
    {
      title: 'Other',
      key: 6
    },
  ],
  foodContentType: [
    {
      title: 'Vegetarian',
      key: 1
    },
    {
      title: 'Non Vegetarian',
      key: 2
    },
    {
      title: 'Red Meat',
      key: 3
    },
    {
      title: 'Egg',
      key: 4
    },
    {
      title: 'Fish',
      key: 5
    },
    {
      title: 'Other',
      key: 6
    },
  ],
  roomServiceType: [
    {
      title: 'Service',
      key: 'service',
    },
    {
      title: 'Amenity',
      key: 'amenity',
    },
    {
      title: 'Maintenance',
      key: 'maintenance',
    }
  ],
  roomServiceRequestType: [
    {
      title: 'Requested',
      key: 'requested',
    },
    {
      title: 'In Progress',
      key: 'in-progress',
    },
    {
      title: 'Complete',
      key: 'complete',
    },
    {
      title: 'Cannot Serve',
      key: 'cannot-serve',
    },
    {
      title: 'Cancelled',
      key: 'cancelled',
    }
  ],
  createdType: [
    {
      title: 'User Created',
      key: 'user-crated',
    },
    {
      title: 'System Created',
      key: 'system-created',
    }
  ],
  membershipType: [
    {
      title: 'Administrator',
      key: 'administrator',
    },
    {
      title: 'Standard User',
      key: 'standard',
    }
  ],
  membershipStatus: [
    {
      title: 'Active',
      key: 'active',
    },
    {
      title: 'Suspended',
      key: 'suspended',
    }
  ],
};
