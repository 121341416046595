/**
 * Manage Payment Actions
 *  manages payment Settle / Confirm
 */
import _ from 'lodash';
import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';
import {Collapse, Modal} from 'react-bootstrap';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants, ErrorMessages, Strings} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import {Checkbox, Input, Select, Textarea} from 'react-formik-ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {confirmAlert} from '../../../components/general/ConfirmAlert';
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */

class PaymentAction extends React.Component {
  static componentName = 'PaymentAction';

  static propTypes = {
    match: PropTypes.object,
    payment: PropTypes.object,
    updateResponse: PropTypes.func,
    paymentTerminals: PropTypes.object,
  };

  updatePayment = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});

      const payload = {
        mark_payment: true,
        reference_id: credentials.reference_id,
        total_settled: credentials.total_settled,
        settle_payment: credentials.settle_payment,
        payment_terminal: credentials.payment_terminal,
        settlement_id: credentials.settlement_id,
        settlement_fee_tax: credentials.settlement_fee_tax,
        settlement_fee_type: credentials.settlement_fee_type,
        settlement_fee_description: credentials.settlement_fee_description,
      };
      AppAPI.payment
        .patch(this.props.payment.hash + '/', payload)
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            setTimeout(() => {
              if (this.props.updateResponse) {
                this.props.updateResponse(res);
              }
              this.setState({resultMsg: {success: ''}});
              this.props.onHide();
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {payment, show, paymentTerminals} = this.props;
    if (loading) return <Loading heightMatch={false}/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    if (!show || !payment) return <React.Fragment/>;

    const nonSettlePaymentTypes = [
      Constants.PAYMENT_METHODS.CARD, Constants.PAYMENT_METHODS.ONLINE_EXTERNAL,
      Constants.PAYMENT_METHODS.BANK_TRANSFER, Constants.PAYMENT_METHODS.CHANNEL_COLLECT
    ];

    const settlePayment = (payment.payment_type === Constants.PAYMENT_TYPE.DIRECT && !nonSettlePaymentTypes.includes(payment.payment_method));

    const formValidation = Yup.object().shape({
      reference_id: Yup.string().max(250, 'Max 250').nullable(),
      total_settled: Yup.number().max(parseFloat(payment.total)).required(),
      settlement_fee_tax: Yup.number().test({
        name: 'max',
        exclusive: false,
        message: 'Cannot exceed settlement charge',
        test: function (value) {
          return value <= (parseFloat(payment.total) - parseFloat(this.parent.total_settled));
        },
      }).nullable(),
      settlement_id: Yup.string().max(250, 'Max 250').nullable(),
      settlement_fee_description: Yup.string().max(250, 'Max 250').nullable(),
    });


    const paymentTerminalControls = (formikProps) => {
      const payment_terminals = paymentTerminals;
      if(!_.isEmpty(payment_terminals)) {
        let payment_terminals_list = payment_terminals.filter(item => (item.payment_allowed === true && item.active === true));
        payment_terminals_list = payment_terminals_list.filter(item => (parseInt(item.payment_method) === parseInt(formikProps.values.payment_method)));

        const TERMINAL_OPTIONS =  payment_terminals_list.map((data)=> ({'value': data.id, 'label': `${data.name} ${data.short_description ? `(${data.short_description})` : ''}`}));

        if (!_.isEmpty(payment_terminals_list)) {
          return (
            <div className="col-6 form-group">
              <Select
                className={'form-control rounded-right-0'}
                name='payment_terminal'
                placeholder={'Select payment location/terminal'}
                label={'Payment Terminal'}
                options={TERMINAL_OPTIONS}
              />
            </div>
          );
        }
      }
    };

    const settlementControls = (formikProps) => <div>
      <hr/>
      <h6>Settlement Information</h6>
      <p className={'text-muted small'}>{Strings.paymentSettledAmountText}</p>
      <div className="form-row">
        <div className="col-6 form-group">
          <Input
            required
            className={'form-control'}
            name='total_settled'
            label={`Settled Amount (${payment.currency})`}
            hint={'Final amount settled to property'}
          />
        </div>
        <div className="col-6 form-group">
          <Input
            className={'form-control'}
            name='settlement_id'
            label={'Settlement Reference'}
            hint={'Settlement ID or Comment.'}
          />
        </div>
      </div>
      {parseFloat(formikProps.values.total_settled) < parseFloat(payment.total) &&
       <div className="form-row">
         <div className="col-4 form-group">
           <Input
             label={`Settlement fee (${payment.currency})`}
             name='diff_fee'
             className={'form-control'}
             disabled={true}
             hint={'Settlement fee'}
             value={(payment.total - formikProps.values.total_settled - formikProps.values.settlement_fee_tax).toFixed(2)}
           />
         </div>
         <div className="col-4 form-group">
           <Input
             label={`Tax (${payment.currency})`}
             name='settlement_fee_tax'
             className={'form-control'}
             hint={'Tax component of fee'}
           />
         </div>
         <div className="col-4 form-group">
           <Input
             label={`Total (${payment.currency})`}
             name='diff'
             className={'form-control'}
             disabled={true}
             hint={'Settlement fee total'}
             value={(payment.total - formikProps.values.total_settled).toFixed(2)}
           />
         </div>
         <div className="col-12 form-group">
           <Select
             className={'form-control rounded-right-0'}
             name='settlement_fee_type'
             label={'Fee Type'}
             options={Constants.SETTLEMENT_FEE_TYPE_INPUT}
             hint={'Category of settlement fee'}
           />
         </div>
         <div className="col-12 form-group">
           <Textarea
             className={'form-control rounded-right-0'}
             name='settlement_fee_description'
             label={'Settlement Notes'}
           />
         </div>
       </div>
      }
      <div className="form-row alert alert-info px-1 py-2">
        <div className="col-12 form-group">
          <div className={''}>
            <Checkbox
              className={'mr-2'}
              name='settle_payment'
              text='Settle Payment'
              hint={Strings.paymentSettledText}
            />
          </div>
        </div>
      </div>
    </div>;

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {payment.payment_status === Constants.PAYMENT_STATUS.PAID ? 'Payment Settlement' : 'Confirm Payment'}
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            description: payment.description || '',
            reference_id: payment.reference_id || '',
            total_settled: payment.total_settled ? payment.total_settled : payment.total,
            settlement_id: payment.settlement_id || '',
            settle_payment: settlePayment,
            payment_method: payment.payment_method,
            settlement_fee_tax: payment.settlement_fee_tax,
            settlement_fee_type: payment.settlement_fee_type,
            settlement_fee_description: payment.settlement_fee_description,
          }}
          validationSchema={formValidation}
          onSubmit={values => this.updatePayment(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <Form className={'form-group'}>
                      <div className={'border rounded p-2 mb-3'}>
                        <div className={'row'}>
                          <div className="col">
                            <p className={'mb-0 text-muted'}>Total</p>
                            <p className={'mb-0'}><strong>{`${payment.currency} ${payment.total}`}</strong></p>
                          </div>
                          <div className="col">
                            <p className={'mb-0 text-muted'}>Payment Type</p>
                            <p className={'mb-0'}><strong>{payment.payment_type_display}</strong></p>
                          </div>
                          <div className="col">
                            <p className={'mb-0 text-muted'}>Payment Method</p>
                            <p className={'mb-0'}><strong>{payment.payment_method_display}</strong></p>
                          </div>
                        </div>
                      </div>

                      {payment.payment_status === Constants.PAYMENT_STATUS.NOTPAID &&
                       <React.Fragment>
                         <div className="form-row">
                           <div className="col form-group">
                             <Input
                               name='reference_id'
                               label={'Payment Reference'}
                               hint={'Transaction ID or Comment.'}
                               className={'form-control'}
                             />
                           </div>
                           {paymentTerminalControls(formikProps)}
                         </div>
                         <button
                           type="button"
                           onClick={() => this.setState({advanceSettings: !this.state.advanceSettings})}
                           className={'btn btn-link p-0 m-0'}
                         >
                           Advance Settings
                         </button>
                         <Collapse in={this.state.advanceSettings} appear={false}>
                           {settlementControls(formikProps)}
                         </Collapse>
                       </React.Fragment>
                      }

                      {payment.payment_status === Constants.PAYMENT_STATUS.PAID && !payment.payment_settled &&
                       <React.Fragment>
                         {settlementControls(formikProps)}
                       </React.Fragment>
                      }

                    </Form>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  {payment.payment_status === Constants.PAYMENT_STATUS.PAID && payment.settlement_status === Constants.SETTLEMENT_STATUS.NOT_SETTLED &&
                   <div className={'col'}>
                     <button
                       type="button"
                       onClick={() =>
                         formikProps.values.settle_payment ? confirmAlert({
                           title: 'Confirm Settlement',
                           message: 'The action cannot be reversed.',
                           buttons: [
                             {
                               className: 'btn-secondary',
                               label: 'Exit',
                             },
                             {
                               className: 'btn-success',
                               label: 'Confirm Settlement',
                               onClick: formikProps.handleSubmit,
                             },
                           ],
                         }) : formikProps.handleSubmit()
                       }
                       className={'btn btn-success btn-lg btn-block'}>
                       <FontAwesomeIcon className={'white-cl mr-2'} icon={faCheck} size={'sm'}/>
                       {`${formikProps.values.settle_payment ? ' Confirm Settle ' : 'Save'}`}
                     </button>
                   </div>
                  }
                  {payment.payment_status === Constants.PAYMENT_STATUS.NOTPAID &&
                   <div className={'col'}>
                     <button
                       type="button"
                       onClick={() => {
                         confirmAlert({
                           title: 'Confirm Payment',
                           message: 'The action cannot be reversed.',
                           buttons: [
                             {
                               className: 'btn-secondary',
                               label: 'Exit',
                             },
                             {
                               className: 'btn-success',
                               label: `Confirm ${formikProps.values.settle_payment ? ' & Settle ' : 'Payment'}`,
                               onClick: formikProps.handleSubmit,
                             },
                           ],
                         });
                       }}
                       className={'btn btn-success btn-block btn-lg'}>
                       <FontAwesomeIcon className={'white-cl mr-2'} icon={faCheck} size={'sm'}/>
                       Confirm {formikProps.values.settle_payment ? ' & Settle ' : 'Payment'}
                     </button>
                   </div>
                  }
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      advanceSettings: false,
    };
  }
}

/* Export Component ==================================================================== */
export default PaymentAction;
