/**
 * PaymentListView
 *  View for Review Listing
 *
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
// Actions
import * as RefundActions from '../../redux/payment/actions';
import AppUtil from '../../lib/util';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setRefund: RefundActions.setRefund,
};

/* Component ==================================================================== */
class RefundListView extends Component {
  static componentName = 'RefundListView';

  static propTypes = {
    data: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  action = (data) => {
    this.props.setRefund(data);
    this.props.history.push(`/payment/refund/${data.hash}`);
  };

  render = () => {
    const data = this.props.data;
    return (
      <div
        role="presentation"
        onKeyPress={this.handleKeyPress}
        onClick={() => this.action(data)}
        className="list-group-item list-group-item-action flex-column align-items-start a-class">
        <div className="d-flex w-100 justify-content-between">
          <div>
            <h5 className="mb-1 text-break">{data.ref_no}</h5>
            {data.refund_id && <p className="mb-0 small">{'Refund ID : ' + data.refund_id}</p>}

            {(data.corporate_name) && <p className="mb-0 small"><strong>Billed to</strong> : {data.corporate_name} </p>}
            <small className="text-muted">{AppUtil.formatDateTime(data.created)}</small>
          </div>
          <div className={'text-center'}>
            <span className={'badge badge-light'}>{data.currency + ' ' + data.total}</span><br/>
            {data.void ?
              <span className={'badge badge-dark mb-3'}>Cancelled</span>
              :
              <span className={'badge ' + (AppUtil.paymentStatusColor(data.payment_status))}>
                {data.payment_status_display}
              </span>
            }
          </div>
        </div>

      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(RefundListView);

