/**
 * PlanView
 *  manages plans render
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

//Components
import {Alerts} from '../ui';

/* Component ==================================================================== */
class PlanView extends Component {

  static componentName = 'PlanView';

  static propTypes = {
    onSelect: PropTypes.func.isRequired,
    planData: PropTypes.array,
    statusMsg: PropTypes.object
  };

  clickAction = (planId) => {
    if (this.props.onSelect) {
      this.props.onSelect(planId, this.state.priceType);
    }
  };
  managePriceType = (priceType) => {
    if (priceType === null) {
      priceType = 'month';
    }
    this.setState({priceType: priceType});
  };
  render = () => {
    const {planData, statusMsg} = this.props;
    const {priceType} = this.state;

    if (!planData) {
      return null;
    }

    return (
      <div>

        <Alerts
          status={statusMsg.status}
          success={statusMsg.success}
          error={statusMsg.error}
        />

        <div className={'text-center'}>
          <div className={'btn-group'} role="group" >
            <button
              onClick={() => this.managePriceType('month')}
              className={'a btn btn-lg ' + (priceType === 'month' ? 'btn-success' : 'btn-outline-success')}
            >Monthly
            </button>
            <button
              onClick={() => this.managePriceType('year')}
              className={'a btn ' + (priceType === 'year' ? 'btn-success' : 'btn-outline-success')}
            >Annually <br/>
              <small>20% discount</small>
            </button>
          </div>
        </div>
        <div className={'spacer-20'}/>

        {planData.length > 0 &&
         <div className={'card-deck mb-3 text-center'}>
           {planData.map((data, i) => (
             <div key={i} className={'card mb-4 box-shadow '}>
               <div className={'card-body'}>
                 <div className={'text-center'}>
                   <h6 className={'text-uppercase'}>{data.name}</h6>
                   <h1
                     className={'display-4 d-inline-block'}>{priceType === 'month' ? data.price_30 : data.price_365} </h1>
                   <p className={'d-inline-block'}>{data.currency}
                     <small className={'text-muted'}>/{priceType}</small>
                   </p>
                 </div>
                 <hr/>
                 <button
                   onClick={() => this.clickAction(data.id)}
                   className={'btn btn-lg btn-block ' + (data.special ? 'btn-success' : 'btn-outline-success')}>
                   Change
                 </button>
                 <div className={'my-5'}>
                   {data.previous_plan_name &&
                    <div>
                      <h4 className={'text-center mb-1 h4'}>{data.previous_plan_name}</h4>
                      <p className={'text-center'}>+</p>
                    </div>
                   }
                   {(data.feature_list && data.feature_list.feature_list) &&
                    <ul className={'list-unstyled text-left'}>
                      {data.feature_list.feature_list.map((feature, i) =>
                        (
                          feature !== '' ?
                            <li key={i}>
                              <p className={'text-capitalize'}>
                                <span
                                  className={'far fa-dot-circle mr-2 ' + (data.featured ? 'green-cl' : 'grey-dark-cl')}/>
                                {feature}
                              </p>
                            </li>
                            :
                            <li key={i}><p>&nbsp;</p></li>
                        )
                      )}
                    </ul>
                   }
                 </div>
                 <button
                   onClick={() => this.clickAction(data.id)}
                   className={'btn btn-lg btn-block ' + (data.special ? 'btn-success' : 'btn-outline-success')}>
                   Change
                 </button>
               </div>
             </div>)
           )}
         </div>
        }
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      priceType: 'month'
    };
  };
}

/* Export Component ==================================================================== */
export default PlanView;
