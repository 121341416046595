/**
 * String database
 *
 */

export default {
  // Validation
  phoneNumberValidation: 'Please enter a valid contact number',
  // Initial Loading
  initialLoading: 'Setup things....',
  // Auth
  authPasswordReset: 'Enter your email address, We will send we\'ll send you an email with instructions to get you back into your account',
  authPasswordResetComplete: 'If you have registered an account with us, we have sent an email to it. Please check the email for further instructions.',
  authPasswordSet: 'Enter new password for your account, this will update your login password and logout all active sessions.',
  authPasswordSetComplete: 'Your account password reset is completed, you can access your account with the new password.',

  // Change Password
  changePasswordForce: 'This is a mandatory password change and will log out all active sessions.',

  // PropertyUpdate.js
  propertyUpdated: 'You have changed the property selection in another tab or window, click proceed to go to new property dashboard.',

  // SessionsList.js
  sessionListEmptyText : [
    'Either user  have not created a session or no session for the current selection found',
  ],
  sessionKillSession: 'The following action will kill the selected session and log out the account from that device.',

  // Error Reporting
  errorReportingTitle: 'Oops, Looks like something is broken.',
  errorReportingSubTitle: 'We apologise for the inconvenience caused. It would help our team speed up the fixing, if you could inform us what exactly happened.',
  errorReportingSubTitle2: '',
  errorReportingSuccess: 'Thank you for your valuable feedback, our team is working to fix the problem.',

  // RecordView.js
  expiredLinkText: 'Secure link to file expired, to ensure the attached file is secure, link to file expires 30 seconds post generation. Refresh the page to generate a new link.',
  // PropertyListEmpty.js
  propertyListEmptyTitle: 'Welcome to Renzo.',
  propertyListEmptyText: [
    'This is where your choose the property to manage, but it\'s empty at the moment.',
    'Let\'s add your first one by tapping the button below and get things started.',
  ],

  searchPropertyListEmptyTitle: 'Oops...',
  searchPropertyListEmptyText: ['No results found, try with a different name.'],

  // RoomTypeListEmpty.js
  roomTypeListEmptyTitle: 'Room Types',
  roomTypeListEmptyText: [
    'Room types are set of rent-able units, they are used to group rent-able units with similar characteristics for ease of price management, providing room service and availability reports.',
    'Typically used names are Deluxe, Queen, Suite etc.',
    'Each room type can have different rate package with in it like with american standard, european standard etc.',
  ],

  // RoomTypeManage.js
  roomTypeManageName: 'Suitable identifier for the room type. Commonly used names are : Double Room, Deluxe Room, Standard Room, King Suit',
  roomOccupancy: 'Information about number of people that can stay in a room of this type.',
  roomTypeManageBaseOccupancy: 'Base guest(s) for the room, your base price and meal plan will be applicable for these number of guests. We recommend this to be 2 adults (double occupancy)',
  roomTypeManageMaxOccupancy: 'Maximum guest(s) allowed in the room. ( excluding child and infants )',
  roomTypeManageChildOccupancy: 'Number of children age between 6-12 years.',
  roomTypeManageInfantOccupancy: 'Number of infants age between 0-5 years.',
  roomTypeManageExtraBed: 'Select a number if you offer extra bed else leave it as zero.',
  roomTypeManageSellBelowMinOccupancy: 'Do you offer a lower rate when the guest occupancy is less than base occupancy?',
  roomTypeManageSellBelowMinOccupancyHint: 'Offering lower rates for groups of less than minimum occupancy makes your property more attractive to potential guests. (Supported by booking engine)',
  roomTypeManageMinOccupancy: 'Defaults to base occupancy, but if you want to sell rooms to guest below base occupancy select min number of guest you want to sell this room.',
  roomTypeManageDiscountPerGuest: 'Discount amount per guest below base occupancy.',
  roomTypeManageDiscountType: 'Discount type. Fixed or % of the base occupancy price.',
  roomTypeManageDescription: 'A brief description about this room type, will be shown in booking engine.',
  roomTypeSaleStatus: 'Designates whether room type is available for sale on booking engine.',
  roomTypeFeaturedStatus: 'Designates whether room type is featured and to be highlighted on booking engine.',
  roomTypeMaxQuantity: 'Maximum quantity allowed per booking in booking engine.',

  // RoomTypePackageListEmpty.js
  roomTypePackageEmptyTitle: 'Packages',
  roomTypePackageEmptyText: [
    'Packages allow you to set different rates for a Room type based on services offered.',
    'Eg : With Breakfast, Without Breakfast, American Standard, European Standard, etc',
  ],
  roomTypePackageEmptyTextAdd: [
    'If you need to add tax rates on the package you are about to create add a tax class.',
    'You can add it later on as well.',
  ],

  roomTypePriceListAbbreviation: 'all prices denoted above is per each guest, except for base price which is for base guests and &lt; min price is discount per guest below base occupancy.',

  // RoomTypePackageManage
  roomTypePackageManageBasePrice: 'Base fare for the package, this will be the far for base guests.',
  roomTypePackageManageAdditionalGuestPrice: 'Extra guest fare for the package.',
  roomTypePackageManageChildPrice: 'Child fare for the package.',
  roomTypePackageManageInfantPrice: 'Infant fare for the package.',
  roomTypePackageManageExtraBedPrice: 'Extra bed fare for the package.',
  roomTypePackageManageCancellationPolicy: 'Cancellation policy for the package.',
  roomTypePackageManageDescription: 'A brief description about this package, will be used in booking engine.',

  roomTypePackageManageName: 'Suitable identifier for the package. Commonly used names are : European Plan, Continental Plan, American Plan etc.',
  roomTypePackageManagePrice : 'Rate configuration for the package.',
  roomTypePackageManagePolicy : 'Package policy and inclusions in the plan.',
  //RoomTypeItemListEmpty.js
  roomTypeItemEmptyTitle: 'Items',
  roomTypeItemEmptyText: [
    'These are tasks/products in room which are to be inspected/cleaned during room service.',
    'These items will appear as a checklist during room service.',
  ],


  // RoomListEmpty.js
  roomListEmptyTitle: 'Rooms',
  roomListEmptyText: [
    'Rooms are any individual of rent-able unit, depending on your property it can be Rooms, Apartments, Villas etc.',
    'Rooms can be named on number or any text depending on your property.',
    'Eg : Villa 1, Villa 2... or Room 100, Room 101... etc',
  ],
  roomListEmptySettingsText: [
    'Rooms are any individual of rent-able unit, depending on your property it can be Rooms, Apartments, Villas etc.',
    'Rooms can be named on number or any text depending on your property.',
    'Eg : Villa 1, Villa 2... or Room 100, Room 101... etc',
  ],

  // ProfileQuickSearch.js
  corporateListEmptyTitle: 'Corporates',
  corporateListEmptyText: [
    'There is no corporate accounts in your property.',
  ],

  agentListEmptyTitle: 'Agents',
  agentListEmptyText: [
    'There is no agent accounts in your property.',
  ],

  vendorListEmptyTitle: 'Vendors',
  vendorListEmptyText: [
    'There is no vendor accounts in your property.',
  ],

  profileRecordListEmptyText: 'No Profile Records exist in your property',

  searchCorporateListEmptyTitle: 'Oops...',
  searchCorporateListEmptyText: [
    'No results found, try with a different name.',
  ],

  // POSListEmpty.js # Settings
  POSListEmptyTitle: 'Point of Sales',
  POSListEmptyText: [
    'Point of Sales allows you to manage the additional services offed in your property like Restaurant, Laundry, Spa etc to the system and manage its orders as well.',
  ],

  POSUserListEmptyText: [
    'Your account does not have access to any Point of Sales in the property.'
  ],

  // POSTableListEmpty.js
  POSTableEmptyTitle: 'Tables',
  POSTableEmptyText: [
    'Tables are physical units of seat location in the Point of sales. These can be used to group orders and track them at ease.',
  ],

  //POSItemListEmpty.js
  POSItemEmptyTitle: 'Menu Items',
  POSItemEmptyText: [
    'Menu items are the products sold in the Point of Sales, add and define taxes for the items ( individual tax and common tax for all).',
  ],

  //POSCategoryListEmpty.js
  POSCategoryEmptyTitle: 'Categories',
  POSCategoryEmptyText: [
    'Categories allow to classify items in your Point of Sales menu for ease of use, you don\'t have any category now.',
    'Eg: Starter, Main Course, Dessert etc',
  ],

  //PaymentListEmpty.js
  paymentsEmptyTitle: 'Payments',
  paymentsEmptyText: [
    'Either you don\'t have any payments added to the system or no payment for the current selection found',
  ],

  searchPaymentsListEmptyTitle: 'Oops...',
  searchPaymentsListEmptyText: ['No results found, try with a different ID.'],

  //PGatewayListEmpty.js
  pGatewayEmptyTitle: 'Payment Links',
  pGatewayEmptyText: [
    'Either you don\'t have any payments links added to the system or no payment links for the current selection found',
  ],

  //PTerminalList.js
  pTerminalEmptyTitle: 'Payment Terminal Transactions',
  pTerminalEmptyText: [
    'Either you don\'t have any payment terminal transactions added to the system or no payment links for the current selection found',
  ],

  //RefundListEmpty.js
  refundsEmptyTitle: 'Refunds',
  refundsEmptyText: [
    'Either you don\'t have any refunds added to the system or no refund for the current selection found',
  ],

  // RefundDetails.js
  refundCancelText: 'This will cancel and close the refund.',

  searchRefundsListEmptyTitle: 'Oops...',
  searchRefundsListEmptyText: ['No results found, try with a different ID.'],

  // AmendmentManage.js
  bookingAmendmentManageName: 'Suitable identifier for the amendment.',
  bookingAmendmentManageType: 'Amendment quantity & sub quantity types. No of option that can be selected in one go. ',
  bookingAmendmentManageRateInfo: 'Amendment rate and tax information.',
  bookingAmendmentManagePrice: 'Base price for the amendment.',

  // BookingAmendmentRequestDetails.js
  bookingAmendmentConfirmation: 'This will confirm amendment request.',
  bookingAmendmentCancellation: 'This will cancel the amendment request.',
  bookingAmendmentGenerateOrder: 'This action will generate bill for the amendment request.',

  // BookingConfirm.js
  bookingUnConfirmationMessage: 'Convert booking to provisional and release availability locked for the booking.',
  bookingConfirmationMessage: 'Convert booking to confirmed state and lock availability.',

  // BookingDetailsView
  bookingConfirmationText:
    'The following is not a confirmed booking, It will be confirmed only post payment or manually.',
  bookingConfirmationConfirmText: 'Convert to confirmed booking.',
  bookingConfirmationUnConfirmText: 'Convert to provisional booking.',
  bookingCancelWarning:
    'This will cancel all the rooms and orders associated with this booking.',
  bookingCancelText: 'Cancel booking / room(s) in booking.',
  bookingNoShowWarning:
    'This will mark the booking as No show, the order will remain as it is.',
  bookingMetaEditText: 'Edit booking meta informations.',
  bookingNoShowText: 'Mark booking / room(s) as no show.',
  bookingChangeDatesText: 'Update booking / room(s) dates.',
  bookingExtentDatesText: 'Extent booking / room(s) length. (Active Bookings)',
  bookingAddRoomsText: 'Add room(s) to booking.',
  bookingBookingRoomEmpty: 'No Rooms in the booking.',
  bookingLogsEmpty: 'No Booking update logs found for current selection.',
  bookingOrderEmpty: 'No Orders found for current selection in booking.',
  bookingOrderMasked: 'Booking related order(s) are masked to prevent accidental disclosing to customer/guest.',
  bookingAmendmentEmpty: 'No Amendments found for current selection in booking.',
  bookingRegenerateOrder: 'Generate a new order for the booking.',
  bookingRegenerateOrderConfirmText: 'This will generate a new order for the booking with current booking configuration and current rates.',
  // BookingDetailsView
  bookingRoomGuestEmptyText: 'No guests allotted to the room.',
  bookingRoomUpdateText: 'Update room type or package',
  bookingRoomCheckinText: 'Perform room checkin',
  bookingRoomCheckoutText: 'Perform room checkout',
  // BookingRoomAmendmentListEmpty.js
  bookingRoomAmendmentsListEmptyTitle: 'Booking Amendments',
  bookingRoomAmendmentsListEmptyText: [
    'Amendments are the extra package you can add to an existing booking. Its empty now.',
    'Commonly used amendments are Early Checkin, Late Checkout, Airport Pickup etc.',
  ],
  // BookingSummary.js
  bookingSummaryOrderSelection: 'Select order(s) to be included in the folio, only confirmed orders will be shown.',
  bookingSummaryOrderExpand: 'Expand selected order(s) along with booking folio/summary.',
  bookingSummaryOrderEmpty: 'No eligible order(s) to select, only confirmed orders can be selected for folio/summary.',
  // RoomServiceItemList.js
  roomServiceItemAmenityListEmptyText: [
    'Add Amenities provided in your property for ease of management in handling requests from guest'
  ],

  roomServiceItemServiceListEmptyText: [
    'Add Services/ Tasks to be performed during a room service to help out the staffs and to maintain consistency.'
  ],

  roomServiceItemMaintenanceListEmptyText: [
    'Add Possible maintenance requests that you can have in your rooms.'
  ],

  // RoomDetailsSettings.js
  roomServiceStatus: 'Indicates whether room condition is dirty, clean, cleaning requested etc.',
  roomAvailabilityStatus: 'Indicates whether room is available or occupied.',
  roomOperationStatus: 'Indicates whether rooms operation status like maintenance, operational etc.',
  roomBufferStatus: 'Indicates whether rooms is marked as buffer room or not. Buffer rooms are not included in inventor count hence not available during lookups but can be assigned to guests during checkin. Generally used to reserve rooms from getting over booked.',

  roomSetMaintenance: 'This will set room to maintenance, room will be reduced from inventory and will not be available to check.',
  roomSetAvailable: 'This will set room to available, room will be available to checkin and in inventory.',

  roomSetBuffer: 'This will set room as buffer, room will be reduced from inventory but will be available for checkin.',
  roomRemoveBuffer: 'This will remove room from buffer, room will be added in inventory.',

  // RoomManage.js
  roomManageName: 'Suitable identifier for the room. Commonly used names are numbers, name or combination of both.',
  roomManageNameShort: 'Suitable identifier for room.',

  bookingAmendmentText: 'Additional chargeable items.',

  // CalendarEmpty.js
  calendarEmptyTitle: 'Calender',
  calendarEmptyText: [
    'Property configurations does not exist to create Calender.',
    'Complete configuration.',
  ],

  searchBookingRoomAmendmentsListEmptyTitle: 'Oops...',
  searchBookingRoomAmendmentsListEmptyText: [
    'No results found, try with a different name.',
  ],

  // PropertyTaxClassListEmpty.js
  propertyTaxClassesListEmptyTitle: 'Tax Classes',
  propertyTaxClassesListEmptyText: [
    'Tax Class allows define a tax percent depending on tax in your locality, and these classes can be applied to items in Room Type Package, Point of Sales, Bookings etc. Eg : IGST @ 9%, CGST @ 9% etc.',
    'A tax class should only have on type of tax, list if items need to be charged IGST and CGST you need to have multiple tax class like one for CGST, IGST, SGST etc and apply more than one class for the item.',
  ],

  // PaymentTerminalList.js
  paymentTerminalListEmptyTitle: 'Payment Terminal',
  paymentTerminalListEmptyText: [
    'Payment terminals are payment collection points in your organization, payment terminal can be selected at the time of recording payment.',
    'There is no payment terminals configured in your property, add a payment terminal to get started.'
  ],

  // PropertyTaxClassManage.js
  propertyTaxClassAddMessage: 'Tax classes once created cannot be modified. Please add new tax class in case of any changes.',
  // GuestListEmpty.js
  guestListEmptyTitle: 'Guests',
  guestListEmptyText: [
    'There is no guest accounts in your property',
  ],

  guestSearchOrAddText: [
    'Fill guest information, the system will search the combination in your guest register. If guest exist select to proceed if not add new guest.',
  ],

  guestSearchOrAddTextEmpty: [
    'No guest found in database, fill the information and create new guest.',
  ],

  searchGuestEmptyTitle: 'Oops....',
  searchGuestEmptyText: [
    'No results found, try with a different name or create a new guest.',
  ],

  // GuestDetailsView
  guestRemoveOrUnlinkText: [
    'Use this option if you need to change/ remove the guest linked and add a new guest.',
  ],

  // OrderListEmpty.js
  orderListEmptyTitle: 'Orders',
  orderListEmptyText: [
    'Either you don\'t have any order added to the system or no order for the current selection found',
  ],

  searchOrderListEmptyTitle: 'Oops...',
  searchOrderListEmptyText: [
    'No results found, try with a different name or ID.',
  ],

  //OrderItemManage.js
  orderItemTaxClassSelectText: [
    'Select the tax classes you would like to apply for this item.',
  ],

  // OrderDetails.js
  orderGenerateSequenceId: [
    'This will generate a unique sequence ID for this order post complete payment, the action cannot be reversed.',
  ],
  orderUnlockText: 'This will open the order for further edits, un paid payments will be cancelled.',
  orderComplimentaryText: 'This will mark all items in order as complimentary item and remove any discount applied to order.',
  orderPaymentAdd: 'Adding new payment will cancel all the unpaid payments added to this order.',
  orderConfirmationMessage: 'Once order is finalized press confirm to proceed and add payment.',
  orderConfirmation: 'This will confirm and lock the order from further edits.',
  orderCancellation: 'This will cancel and close the order, the action cannot be reversed.',

  orderPostPaymentDiscount : 'Order will be marked as paid if pending amount is discounted completely.',

  // OrderPaymentSelector.js
  orderPaymentAttachmentAcknowledge: 'I confirm that I have verified all the information for linking the selected payment to the order and I acknowledge this action is irreversible.',
  orderPaymentAttachmentConfirmation : 'This action will attach the selected payment to order and cancel all the unpaid payments in the order. This action cannot be reversed.',
  orderPaymentAttachmentWarning: 'Total calculated is for reference only, for final amount refer to the order post attachment.',

  // OrderPaymentDetails.js
  orderPaymentEmpty: 'Either you don\'t have any payment added to the order or no payment for the current selection found',

  // OrderRefundDetails.js
  orderRefundEmpty: 'Either you don\'t have any refund added to the order or no refund for the current selection found',

  // OrderItemDetailsView.js
  orderItemAddComplimentaryText: 'This will mark item as complimentary.',
  orderItemRemoveComplimentaryText: 'This will remove item from complimentary.',

  // PaymentDetails.js
  generatePaymentSequenceId: [
    'This will generate a unique sequence ID for this payment, the action cannot be reversed.',
  ],
  paymentCancelText: 'This will cancel and close the payment.',
  paymentConfirmText: 'This will confirm and mark payment as paid.',
  paymentSettledAmountText: 'Settled amount is the final amount settled to the property account post commissions or other charges',
  paymentSettledText: 'Will confirm and settle payment immediately, to enter settled amount at later stage uncheck the option.',

  // PTerminalDevices.js
  PTerminalDevicesListEmpty: 'No Payment terminal devices found in the property.',

  //PaymentRefundDetails.js
  paymentRefundEmpty: 'Either you don\'t have any refund added to the payment or no refund for the current selection found',

  // PaymentSubPaymentsDetails.js
  paymentSubPaymentEmpty: 'Either you don\'t have any sub payment added to the payment or no sub payment for the current selection found',

  // PaymentTerminalList.js
  paymentTerminalEmpty: 'Either you don\'t have any terminal transaction added to the payment or no transaction for the current selection found',

  //PaymentLinkDetails.js
  paymentLinkLoading: 'Please wait we are generating the payment link.',
  paymentLinkError: 'Creating payment link failed.',

  // PaymentAdd.js
  paymentTypeText: 'Payment settlement method.',
  paymentExpiry: 'Payment expiry date & time, post the selected time payment will be canceled if not paid.',
  paymentTypeTextDirect: 'Direct Payments are payments settled directly to property.',
  paymentTypeTextInDirect: 'Indirect Payments are payments done to a 3rd party like Agent, OTA etc and settled to '
    + 'property at a later time. This method will provide more options to track payment until '
    + 'its settled to property.',
  paymentRefundAdd:
    'Adding new refund will cancel all the unpaid refunds added to this payment.',
  // BookingListEmpty.js
  bookingListEmptyTitle: 'Bookings',
  bookingListEmptyText: [
    'Either you don\'t have any booking added to the system or no booking for the current selection found',
  ],

  // PaymentLockedEdit.js
  paymentMethodEdit: 'Changing payment type will mark payment as not settled.',

  // BookingAmendmentRequestList.js
  bookingAmendmentListEmptyTitle: 'Bookings',
  bookingAmendmentListEmptyText: [
    'Either you don\'t have any booking amendment added to the system or no booking amendment for the current selection found',
  ],

  searchBookingListEmptyTitle: 'Oops...',
  searchBookingListEmptyText: ['No results found, try with a different name.'],

  // BookingRoomListEmpty.js
  bookingRoomListEmptyTitle: 'Guest Rooms',
  bookingRoomListEmptyText: [
    'Either you don\'t have any booking added to the system or no booking for the current selection found',
  ],

  // BookingRoomGuestCountEdit.js
  bookingRoomGuestCountEdit: [
    'Updating guest count does not update/amend billing, please use booking amendments or re-generate order to update billing with new guest counts.'
  ],

  // GuestSearch.js
  searchGuestTitle: 'Search your guest\'s',
  searchGuestText: [
    'You can search through your guest database using the guests name, email or phone number, or add a new guest.',
  ],

  //BillingOrders.js
  billingOrdersEmptyTitle: 'Invoices',
  billingOrdersEmptyText: ['No Invoices found in this section.'],

  //onBoardingNotAdmin.js
  onBoardingNotAdminTitle: 'What\'s next?',
  onBoardingNotAdminSubTitle: 'Keep calm and wait.',
  onBoardingNotAdminText: [
    'It\'s great to see you here, but before we can do anything your employer or administrator has to setup the property.',
  ],

  //DashboardUser.js
  dashboardEmptyModules: 'There is no active modules in your property account to use now.',
  dashboardUserTitle: 'Hey There!',
  dashboardUserSubTitle: 'Just some news.',
  dashboardUserText: [
    'It\'s great to see you here, but you do not have permissions to access dashboard.',
    'Here is what you can do.',
  ],

  dashboardUserText_2: [
    'You don\'t have any permissions, so your account cannot access any functions for this property.',
    'Contact your manager for assistance.',
  ],

  //Billing.js
  billingEmptyTitle: 'Ops',
  billingEmptyText: [
    'You don\'t have any plans on your account so far.',
    'It can be that you haven\'t completed the signup or you are using an employee account.',
  ],

  // NotesList.js
  notesListEmptyTitle: 'Notes',
  notesListEmptyText: [
    'This is where you can view the notes\'s added on your property. It\'s empty as of now.',
    'A note entry is added while you add a note to booking, order or room.',
  ],
  confirmNotes: 'This will set task as completed. This action cannot be reversed.',

  searchNoteListEmptyTitle: 'Notes',
  searchNoteListEmptyText: [
    'No results found, try with a different name or ID.',
  ],

  //ExpenseCategoryListEmpty.js
  expenseCategoryEmptyTitle: 'Category',
  expenseCategoryEmptyText: [
    'Category allows you to mark expenses and group them for easy of tracking.',
    'This is where you can view the expense categories added on your property. It\'s empty now.',
  ],

  //ExpenseDepartmentListEmpty.js
  expenseDepartmentEmptyTitle: 'Departments',
  expenseDepartmentEmptyText: [
    'Departments allows you to group expenses for easy of tracking.',
    'This is where you can view the departments added on your property. It\'s empty now.',
  ],

  //ExpenseOnBoarding.js
  ExpenseOnBoardingTitle: 'Expense Management',
  ExpenseOnBoardingText: [
    'Expense management allows you to keep track of your spending.',
    'To keep you informed of everything about your expense, from anywhere.',
  ],
  ExpenseOnBoardingSubText: [
    'Before we can get started, you need to configure departments and categories so that the expense can be organized.',
  ],

  //ExpenseVendorListEmpty.js
  expenseVendorEmptyTitle: 'Vendors',
  expenseVendorEmptyText: [
    'Vendors allows you to mark expenses for easy of tracking.',
    'This is where you can view the expenses added on your property. It\'s empty now.',
  ],

  searchExpenseVendorListEmptyTitle: 'Oops...',
  searchExpenseVendorListEmptyText: [
    'No results found, try with a different ID.',
  ],

  // expenseListEmpty.js
  expenseListEmptyTitle: 'Expenses',
  expenseListEmptyText: [
    'Either you don\'t have any expense added to the system or no expense for the current selection found',
  ],

  searchExpenseListEmptyTitle: 'Oops...',
  searchExpenseListEmptyText: [
    'No results found, try with a different name or ID.',
  ],

  // ExpenseDetails.js
  expenseGenerateSequenceId: [
    'This will generate a unique sequence ID for this expense post complete payment, the action cannot be reversed.',
  ],
  expenseUnlockText: 'This will open the expense for further edits, un paid payments will be cancelled.',
  expenseComplimentaryText: 'This will mark all items in expense as complimentary item.',
  expensePaymentAdd: 'Adding new payment will cancel all the unpaid payments added to this expense.',
  expenseConfirmationMessage: 'Once expense is finalized press confirm to proceed and add payment.',
  expenseConfirmation: 'This will confirm and lock the expense from further edits.',
  expenseCancellation: 'This will cancel and close the expense, the action cannot be reversed.',


  // ReviewListEmpty.js
  reviewListEmptyTitle: 'Reviews',
  reviewListEmptyText: [
    'This is where you can view the review\'s from your guest\'s you hosted at your property. It\'s empty as of now.',
    'Review are generated once guest fill in the review form after a booking is checked out',
  ],

  // ReviewList.js
  searchReviewListEmptyTitle: 'Reviews',
  searchReviewListEmptyText: [
    'No results found, try with a different name or ID.',
  ],

  // PropertyNotificationSettings.js
  notificationSettingsText: [
    'Notification Email and Text messages are sent to your customer(s) automatically upon completion of the tasks. '
    + 'Edit the actions to control when notifications are send, you\'re in complete control of property notifications.',
  ],
  notificationSettingsNoEmailTitle: 'No Emails to configure',
  notificationSettingsNoEmailText: [
    'No email configurations are available for you to configure.',
  ],
  notificationSettingsNoTextTitle: 'No Text Messages to configure',
  notificationSettingsNoTextText: [
    'No Text message configurations are available for you to configure.',
  ],
  notificationAutoTextMasterControlEnable: 'Activate text auto notifications for this property. Text messages are send to your customer(s) automatically upon completion of actions/tasks.',
  notificationAutoTextMasterControlDisable: 'Disables all text auto notifications for this property. Notifications will have to be send manually.',

  notificationAutoEmailMasterControlEnable: 'Activate email auto notifications for this property. Email messages are send to your customer(s) automatically upon completion of actions/tasks.',
  notificationAutoEmailMasterControlDisable: 'Disables all email auto notifications for this property. Notifications will have to be send manually.',

  //RoomTypeSettings.js
  roomTypePackageDescriptions: [
    'Packages facilitates different rates for a Room Type. Eg : With breakfast, without breakfast etc. Package selection can be done at the time of booking',
    'At least 1 Package is needed to make booking on a room type.',
  ],

  roomTypeItemsDescriptions: [
    'These are tasks/products in the room which are to be inspected/cleaned during room service. These items will appear as a checklist for room service.',
  ],

  roomTypeActiveDescriptions: [
    'Room type is active if it has 1 Package and 1 Room in it. Bookings can only be made on active room type',
  ],

  roomTypeRoomDescriptions: [
    'Manage rooms in this room type. At least 1 room is needed to make booking on a room type.',
  ],

  //ReviewDetailsView.js
  reviewInformationString: [
    'The review link is send to guest\'s contact. To resend it click notification tab.',
  ],

  // Billing.js
  billingConsumptionEmptyTitle: 'Get Started with your property',
  billingConsumptionEmptyDescription: [
    'Add your first property to get started.',
  ],

  //LookupResults.js
  bookingLookupNoAvailabilityBlock: [
    'No availability blocked by bookings for the selected dates.',
  ],

  // AvailabilityHistoryList.js
  availabilityListEmptyText: 'No history data exist for current date availability.',

  // BookingModifyLookupResult.js
  bookingModifyLookupSelectBookings: 'Select the bookings to perform an availability lookup for new dates.',
  bookingModifyEmpty: 'No room available for modification / extension.',

  // Constants
  actionReviewTitle: 'Actions',
  actionReviewDescriptionText: 'These items need your attention, to proceed check each of the message.',
  actionReviewDescription: [
    'These items need your attention, to proceed check each of the message.',
  ],

  //BookingAdd.js
  bookingAddConfirmed: [
    'Confirmed booking will lock availability and reduce inventory.',
  ],
  bookingAddProvisional: [
    'Provisional booking will not lock or reduce availability. Provisional booking will be ' +
    'confirm post payment or manually.',
  ],
  bookingOrderMaskText: 'Will mask the booking related orders to prevent accidental disclosing to the customer/guest.',
  bookingTotalWarning: [
    'Total calculated is for reference only, for final amount refer to the order generated post booking.',
  ],
  bookingTotalWarningText: 'Total calculated is for reference only, for final amount refer to the order generated post booking.',

  // BookingRoomUpdate.js
  standardChange: 'New order will be generated for the update, balance adjustments if any have to be done manually.',
  complimentaryChange: 'Update/Change room type without creating a billing order.',
  flatRateChange: 'Update/Change room type with a flat per night rate.',

  //HousekeepingDetails.js
  unlinkBookingText: 'This will only unlink room from booking, will not checkout the booking.',

  houseKeepingRequestCleaningText: 'Request cleaning for this room.',
  houseKeepingCleanText: 'Mark room as cleaned.',
  houseKeepingDirtyText: 'Mark room as dirty.',
  houseKeepingRequireAttentionText: 'Attention Required for this room.',

  houseKeepingRequestMaintenanceText: 'Request maintenance for the room.',
  houseKeepingSetOperationalText: 'Set room as operational.',

  // PropertyChannelManagerSettings.js
  internalEngineDistribution: [
    'Internal engine distribution settings controls whether the inventory will be distributed over internal engine.',
  ],
  internalEngineDistributionDisable: [
    'Internal engine will not distribute inventory, no bookings will be accepted',
  ],
  internalEngineDistributionEnable: [
    'Internal engine will distribute the live inventory and accept bookings',
  ],

  // PropertyChannelManagerSettings.js
  channelManagerConnectionVendor: 'Channel manager vendor /  partner the property is connected to for distribution.',
  channelManagerConnectionSettings: 'Channel manager connection status indicates whether the property is connected to channel manager or not.',
  channelManagerDistribution: 'Channel Manager distribution settings controls whether the inventory will be distributed over channel manager.',
  channelManagerDistributionDisable:'Channel Manager will not distribute inventory, no bookings will be accepted over connected OTA\'s',
  channelManagerDistributionEnable: 'Channel Manager will distribute the live inventory and accept bookings from OTA\'s',

  channelManagerAutoTaxParsing: 'Tax parsing settings controls whether system will try to determine if tax data received is inclusive or exclusive in total received from OTA by internal calculation or use with data received. If parsed by system the tax description will contain tag [Parsed].',
  channelManagerAutoTaxParsingDisable:'System will not perform calculations to parse tax value from received order data, the data set will be stored as per data received from OTA.',
  channelManagerAutoTaxParsingEnable: 'System will perform calculations to parse data to determine if tax value is included in received total from OTA or not',

  channelManagerAutoSequenceNumber: 'Sequence settings controls whether system will issue sequence number for order(s) received from OTA.',
  channelManagerAutoSequenceNumberDisable:'System will not issue sequence number for order(s) received from OTA.',
  channelManagerAutoSequenceNumberEnable: 'System will issue issue sequence number for order(s) received from OTA.',

  channelManagerTaxInclusion: 'Channel Manager tax settings controls whether the rates pushed to channel manager will be inclusive of or exclusive of taxes.',
  channelManagerTaxInclusionDisable: 'Rates pushed to channel manger will not include taxes.',
  channelManagerTaxInclusionEnable: 'Rates pushed to channel manger will include taxes.',

  channelManagerInventoryConnection: 'Inventory connection determines whether inventory data is synced.',
  channelManagerRateConnection: 'Rate connection determines whether rate data is synced.',
  channelManagerRestrictionConnection: 'Restriction connection determines whether restriction data is synced.',
  channelManagerBookingConnection: 'Booking connection determines whether booking data is accepted.',
  channelManagerDailySync: 'Daily sync is used to sync data between Renzo and Channel manger and to push availability information to nth + 1 day for distribution.',
  channelManagerLastSyncTime: 'Last sync time from Renzo to channel manager.',

  // Front Desk settings
  frontDeskCheckinValidation: 'Guest profile records & required fields will be validated for records before the booking is allowed to checkin.',
  frontDeskCheckinValidationDisable: 'Guest profiles will not be validated before checkin.',
  frontDeskCheckinValidationEnable: 'Guest profiles will be validated before checkin.',

  frontDeskCheckoutValidation: 'Guest profile records & required fields will be validated for records before the booking is allowed to checkout.',
  frontDeskCheckoutValidationDisable: 'Guest profiles will not be validated before checkout.',
  frontDeskCheckoutValidationEnable: 'Guest profiles will be validated before checkout.',

  frontDeskGuestCountValidation: 'Guest count will be validated to ensure minimum of 1 guest in added to booking during checkin / checkout.',
  frontDeskGuestCountValidationDisable: 'Guest count will not be validated before checkin / checkout.',
  frontDeskGuestCountValidationEnable: 'Guest count will be validated before checkin / checkout.',


  // RateChart
  channelManagerConnected: 'Connected to channel manager.',
  channelManagerDisconnected: 'Disconnected from channel manager.',

  bookingEngineConnected: 'Connected to booking engine',
  bookingEngineDisconnected: 'Disconnected from booking engine.',

  bookingEngineDistributionEnabled: 'Booking engine distribution enabled.',
  bookingEngineDistributionDisabled: 'Booking engine distribution disabled.',


  // Used only in RateChart
  channelManagerDistributionRateChart: [
    'Channel Manager distribution settings controls whether the inventory will be distributed over channel manager.'
  ],

  channelManagerSectionDistribution: [
    'Channel Manager distribution settings controls whether the inventory in this section  will be distributed over channel manager.',
  ],
  channelManagerSectionDistributionDisable: [
    'Channel Manager will not distribute inventory in this section, no bookings will be accepted over connected OTA\'s',
  ],
  channelManagerSectionDistributionEnable: [
    'Channel Manager will distribute the live inventory in this section and accept bookings from OTA\'s',
  ],
  // PropertyBookingEngineSettings
  bookingEngineConnectionSettings: [
    'Booking engine connection status indicates whether the property is connected to booking engine or not.'
  ],
  bookingEngineDistribution: [
    'Booking Engine distribution settings controls whether the inventory will to be distributed over booking engine or not.',
  ],
  bookingEngineDistributionDisable: [
    'Booking Engine will not distribute inventory, no bookings will be accepted over booking engine',
  ],
  bookingEngineDistributionEnable: [
    'Booking Engine will distribute the live inventory and accept bookings',
  ],

  bookingEngineEventDistribution: [
    'Controls whether the event inventory will be distributed over booking engine or not.',
  ],
  bookingEngineRoomDistribution: [
    'Controls whether the room inventory will be distributed over booking engine or not.',
  ],
  bookingEngineProfileAddress: [
    'Controls whether guest address information will be requested in booking engine or not.',
  ],

  // PaymentGatewaySettings.js
  paymentGatewayConnectionSettings: [
    'Payment Gateway connection status indicates whether the property is connected to payment gateway or not.'
  ],

  paymentGatewayType: [
    'Payment gateway type defines the type of integration done with the vendor / payment partner.'
  ],

  paymentGatewayProvider: [
    'Payment gateway vendor / partner.'
  ],

  bookingEngineSectionDistribution: [
    'Booking Engine distribution settings controls whether the inventory in this section will be distributed by booking engine.',
  ],
  bookingEngineSectionDistributionDisable: [
    'Booking Engine will not distribute inventory in this section, no bookings will be accepted for this section.',
  ],
  bookingEngineSectionDistributionEnable: [
    'Booking Engine will distribute the live inventory in this section and accept bookings for this section',
  ],

  bookingEngineProfileAddressDisable: [
    'Booking Engine will not request guest address when accepting bookings.',
  ],
  bookingEngineProfileAddressEnable: [
    'Booking Engine will request guest address when accepting bookings.',
  ],

  // EventList.js
  eventListEmptyText: [
    'Either you don\'t have any event added to the system or no event for the current selection found',
  ],
  // EventSettings.js
  eventTicketsDescriptions: [
    'Tickets are any type of sell able units in an event.',
  ],
  // TicketList.js
  eventTicketsEmptyText: [
    'Either you don\'t have any ticket added to the event',
  ],
  // TicketBookingList.js

  ticketBookingListEmptyText: [
    'Either you don\'t have any booking added to the system or no booking for the current selection found',
  ],
  // TicketBookingDetails.js
  eventTicketBookingConfirmationConfirmText: 'If everything is okay ? confirm ticket.',
  eventTicketBookingConfirmationText: 'The following is a provisional booking, it is temporarily on hold awaiting payment from user. If not paid in time booking will be cancelled.',
  eventTicketBookingConfirmationWarning: 'This will confirm the ticket and block availability for the dates. This action cannot be reversed.',
  eventTicketBookingCancelWarning: 'This will cancel all the ticket and order associated with it.',
  eventTicketBookingCancellationWarning: 'This will cancel the ticket. This action cannot be reversed.',
  eventTicketBookingCancelText: 'Change in plans ? cancel ticket booking.',

  //PropertyContent.js
  propertyPolicyLinks: 'This is where your privacy policy, terms and conditions can be linked. These are displayed on booking engine, payments page and other pages made for your property',
  financialHeaderTitle: 'Title, company full name.',
  financialHeaderSubTitle: 'Sub title, company address information.',
  financialFooterNotes: 'Footer settings, company contact number, email address, identification number etc. (Max lines 4)',
  ticketFooterNotes: 'Footer notes appear on booking and event ticket documents downloaded/printed from the system.',
  bookingFooterNotes: 'Footer notes appear on booking documents downloaded/printed from the system.',

  //propertyDistribution.js
  propertyBookingLength: 'Number of rolling days in future upto which inventory will be available for booking.',
  propertyDistributionRestrictions: 'Inventory restrictions based on dates, inventory will only be available on the selected date range.',
  propertyDistributionThrottled: 'To ensure changes are propagated to channel manager / booking engine correctly user is restricted to 4 actions / 60 seconds, post 4 action request will be throttled for the next 60 seconds.',

  internalEngineDateDistributionEnable: ['Internal Engine will only distribute inventory for the selected date range.'],
  internalEngineDateDistributionDisable: ['Internal Engine will distribute the live inventory and accept bookings for the entire distribution length.'],

  bookingEngineDateDistributionEnable: ['Booking Engine will only distribute inventory for the selected date range.'],
  bookingEngineDateDistributionDisable: ['Booking Engine will distribute the live inventory and accept bookings for the entire distribution length.'],

  channelManagerDateDistributionEnable: ['Channel Manager will only distribute inventory for the selected date range.'],
  channelManagerDateDistributionDisable: ['Channel Manager will distribute the live inventory and accept bookings for the entire distribution length.'],
  // RoomInventoryList.js
  roomDistributionConfigured: 'Designates whether Room Type or Package is connected to Channel Manager/Booking Engine',
  roomDistributionDistribution: 'Designates whether Room Type or Package is being distributed for sale in Channel Manager/Booking Engine',
  roomDistributionEmpty: 'No room type / package connected to the following channel to configure.',
  // EventInventoryList.js
  eventDistributionConfigured: 'Designates whether Event or Ticket is connected to Booking Engine.',
  eventDistributionDistribution: 'Designates whether Event or Ticket is being distributed for sale.',
  // ProfileVerification.js
  emailVerification: 'Enter the verification code received on e-mail address.',
  phoneVerification: 'Enter the verification code received on Phone number.',

  // PropertyDetails.js
  propertyLogoDescription: 'Resized logo set, these will be used across the system.',

  // PropertyEdit.js
  propertyNameDescription: 'This will be the name appearing as the title of your listing across our system. This will be used on email, text send from your account, anyone visiting your payment page, booking engine etc, so don\'t include your address in the name.',
  propertyCompanyDescription: 'Legal name or the registered name of the property, used in billing, billing related PDF generated across the system for order, payment etc. If empty property name will used.',
  propertyTaxTypeDescription: ' The type of tax system to be used. Eg : GST, VAT etc.',
  propertyTaxNumberDescription: 'Tax registration number for the company, used in billing, billing related PDF generated across the system for order, payment etc.',
  propertyAddressDescription: 'Property physical address, information will be shared to guest in the confirmation emails and text messages and public pages.',
  propertyContactDescription: 'Used for internal communications from renzo, never shared to public or your guest. All System level warnings and error if detected will be informed to this contact.',
  propertySalesContactDescription: 'Shown as property contact address in booking engine, reviews, email etc. These information will be accessible to public.',
  propertySupportContactDescription: 'Shown as property contact address in all transactional messages from your property. This contacts will be revealed to guests that has made a transaction with your property.',
  propertySupportContactWarning: 'Transactional message will not be processed from the system if support contacts are not provided.',
  propertyGoogleMapsUrl: 'IFrame embed URL of the property. Search Property on Google Maps -> Share -> Embed a map -> Copy the src section of Iframe. ',
  propertyCheckinCheckoutTimes: 'In and Out time for bookings',

  propertyStatusEmailNotificationCustomContentEnabled: 'Email custom content is enabled.',
  propertyStatusEmailNotificationCustomContentDisabled: 'Email custom content is disabled.',

  propertySupportContactsComplete: 'Support contact are configured.',
  propertySupportContactsInComplete: 'Support contact not configured. Email and Text notification are disabled disabled.',

  propertySupportContactEmail: 'Support contact email will be displayed on and be set as default reply to address on emails send form the system.',
  propertySupportContactPhone: 'Support contact phone will be displayed on all text notifications send from system. ',

  propertyNotificationCustomContent: 'Custom content allows addition of customized text to emails send from system.',

  // PropertyMembershipList.js

  propertyMembershipListEmptyText: [
    'Either you don\'t have any member added to the system or no member for the current selection found',
  ],

  // PropertyMembershipManage.js
  membershipUserType: 'Permission settings to control users access to property settings in renzo system. Administrator users are provided access to property settings',
  // PropertyMembershipDetails.js
  membershipPermissionTitle: 'Permission',
  membershipPermissionDescription: [
    'Permissions granted for this account, users can only use the functions listed under these permissions.',
  ],
  membershipPermissionManageDescription: [
    'Check the boxes to which user should be granted access to.',
  ],
  membershipUserEmail: 'Email address of new user.',
  membershipUserPasswordResetTitle: 'Reset Password',
  membershipUserPasswordResetDescription: 'Will send a reset password link along with instruction to the user e-mail address.',
  membershipUserPermissionEmpty: 'No permission assigned for the user.',

  membershipUserSuspendDescription: 'This action suspend user and prevent them from accessing the property functions. This action not remove the user from property.',
  membershipUserActivateDescription: 'This will activate the user account and allow user to access the property functions.',

  // POSMembershipDetails.js
  POSMembershipUserSuspendDescription: 'This will suspend user and prevent the user from accessing the Point of Sale. This will not remove the user from Point of Sale, suspension will only stop access to the Point of Sale.',
  POSMembershipUserActivateDescription: 'This will enable the user account and allow user to access the Point of Sale.',

  // EventManage.js
  eventName: 'Name of the event',
  eventType: 'Type of event: Recurring/Repeating event or Onetime/Single event.',
  eventDescription: 'Description about the event for booking engine.',
  eventBookingDescription: 'Description to be send in email & in booking details pdf containing the event / ticket. This information will not be visible in booking engine or internal engine.',
  eventRecurringType: 'If Recurring event, frequency of event. Limited for a fixed no of repetitions, Daily/Weekly for repeated events ',
  eventLength: 'No of repetitions or no of days event will be recurred.',
  eventLengthBooking: 'No of days ahead of current date / Event Start date (if start date is future), event will be active and accept booking.',
  eventWeekDay: 'Day(s) of week on which event is available.',
  eventDate: 'Event date in case of single event, event start date in case of recurring event. End date will be calculated from Length.',
  eventTicketSaleEnd: 'Ticket sale ends at event start time, if ticket sale is to stopped earlier set the appropriate duration from event start.',
  eventScheduleTime: 'Start and End time of event.',
  eventScheduleLength: 'Length of single event in days, event scheduled end time will be calculated based on this.',
  eventAddress: 'Event address and contacts if it is different from the property data.',
  eventAddressDescription: 'Event location, information will be shared to guest in the confirmation emails and text messages and public pages.',
  eventSalesContactDescription: 'Shown as event contact address in booking engine, email etc. These information will be accessible to public.',
  eventSupportContactDescription: 'Shown as event contact address in all transactional messages related to this event. This contacts will be revealed to guests that has made a transaction with this event.',
  eventSupportContactWarning: 'If no information is provided, corresponding data in property will be used.',
  eventGoogleMapsUrl: 'IFrame embed URL of the property. Search Property on Google Maps -> Share -> Embed a map -> Copy the src section of Iframe. ',
  eventBookingSummaryPDF: 'Whether to include link to download booking details PDF in booking summary emails.',
  // PropertyNotificationList.js
  propertyNotificationListEmptyTitle: 'Notifications',
  propertyNotificationListEmptyText: [
    'Either you don\'t have any notification sent from your property or no notifications for the current selection found'
  ],

  // NotificationConfigBCCList.js
  propertyNotificationConfigBCCListEmptyText: 'There is no template / config available to edit.',
  propertyNotificationDescription: 'Adding Email address or Phone number to BCC list will send a copy of the every notification sent using the respective to the address mentioned in the list, charges applicable as per usage & consumption of the notification type & no of addresses in the list.',
  //NotificationContentList.js
  propertyNotificationContentListEmptyText: 'There is no template / content available to edit.',

  //NotificationContentDetails.js
  propertyNotificationContentDetailsWarning: 'This is an illustration of email template to provide context only, the styling will differ from  actual email which depend on the email-client, device etc on which the email is viewed on.',

  // Availability Manager
  channelOptionSelectionEmpty: [
    'No channel constrains selected, please select a channel constrains to continue'
  ],

  //PaymentTerminalManage.js
  paymentTerminalName : 'Name of the payment terminal.',

  // POSManage.js
  posName: 'Suitable identifier for the point of sale.',
  posType: 'Type of Point of Sale',
  posOperationType: 'How point of sale is operated accordance to time frame. Limited time operation, 24 x 7 Operation etc.',
  posDiscountSettings: 'Designates the position of discount on the POS items, whether its before tax calculation or after tax calculation.',

  // POSCategoryManage.js
  posCategoryName: 'Suitable identifier for the category in point of sale.',

  // POSTableManage.js
  posTableName: 'Suitable identifier for the table in point of sale.',
  posTableNote: 'Any important information related to table, will be highlighted in POS order View.',
  posTableOccupancy: 'No of person table can serve.',
  posTableShape: 'Geometric shape of the table.',
  posMaintenanceStatus: 'Maintenance/ Operational status of table.',
  posServiceStatus: 'Service status of table.',

  // POSItemManage.js
  posItemName: 'Suitable identifier for the item in point of sale.',
  posItemCode: 'Item Identifier code for ease of access.',
  posNoteNote: 'Any important information related to item, will be highlighted in POS order View.',

  //POSMembershipList.js
  POSMembershipText: [
    'No user accounts linked to this Point of Sale. Add an account.',
  ],
  POSMembershipUserPermissionEmpty: 'No permission assigned for the user.',

  // POSOverView.js
  POSOverViewEmptyTable: [
    'There is no table available in the Point of Sale'
  ],
  POSOverViewEmptyItems: [
    'There is no items available in the selection of Point of Sale'
  ],

  // POSOrderListEmpty.js
  posOrderListEmptyTitle: 'Orders',
  posOrderListEmptyText: [
    'Either you don\'t have any order added to the point of sale or no order for the current selection found',
  ],

  // POSOrderKOTListEmptyText
  POSOrderKOTListEmptyTitle: 'Kitchen Order Ticket',
  POSOrderKOTListEmptyText: [
    'Either you don\'t have any KOT added to the point of sale or no KOT for the current selection found',
  ],

  // POSOrderManage.js
  posClearStagedItems: 'This action will clear all staged items, save items will not be cleared.',
  posVoidItem: 'This action will void the item in the order.',
  posCloseOrder: 'This action will close / void the order.',
  posHoldOrder: 'This action will move the order to hold.',
  posOpenOrder: 'This action will move the order from hold to open status.',
  posConfirmItems: 'This action will lock the items in order from further edits.',
  posGenerateKOT: 'This action will lock the items in order from further edits and generate KOT.',
  posConfirmOrder: 'This action will lock the order from further edits and generate bill.',
  posOrderConfirmationMessageDisabled: 'Confirm each individual items in the order, to proceed.',
  posOrderConfirmationMessage: 'Once order is finalized press confirm to proceed and generate bill/payment.',
  posOrderUnlockText: 'This will open the order for further edits, all bills associated with this order will be cancelled.',
  posOrderGenerateOrder: 'This action will generate bill for the point of sale order.',
  posOrderUnlinkRoom: 'This will remove the room and its associated booking from the order.',

  // POSRoomSelectListView.js
  roomQuickSelectListViewEmptyText: 'There is no room available in the current selection',

  // POSOrderSplitBill.js
  posSplitBillWarning: 'Total calculated is for reference purposes and only includes the value of items, order level discounts and service charge is not included in the calculation for final amounts refer to the generated order(s) .',

  // POSTableDetails.js
  posTableRequestMaintenanceText: 'Request maintenance for the table.',
  posTableSetOperationalText: 'Set table as operational.',

  unlinkPOSOrderText: 'This will only POS Order / Tab room from table, will not cancel or confirm the order.',

  // POSRoomSelectListView.js
  posRoomListEmptyText: [
    'No checked in room(s) available in the property now.'
  ],

  // POSOrderKOTDetails.js
  cancelKOT: 'This action will Cancel the Kitchen Order Ticket and items in the KOT.',

  // POSTableSelector.js
  posOrderTableAdd: 'This action will attach new table to the order.',
  posOrderTableShift: 'This action will shift the current order to new table and clear existing tables.',

  // RoomServiceItemManage.js
  serviceTitle: 'Name of amenity or service.',
  serviceAutoSelect: 'Determines whether the selected service / amenity will be auto added to service request when room is checked out or service is requested.',
  serviceDefaultQuantity: 'Default quantity value of amenity when service requested is created by system post room checkout.',

  // RoomServiceRequestList.js
  roomServiceRequestListEmptyTitle: 'Service',
  roomServiceRequestListEmptyText: [
    'Either you don\'t have any service request in system or no service request for the current selection found',
  ],
  //RoomServiceRequestDetails.js
  roomServiceRequestCancellation: 'This will cancel request and items, the action cannot be reversed.',
  roomServiceRequestDeny: 'This will mark request and items as denied, the action cannot be reversed.',
  roomServiceRequestConfirm: 'This will Complete and close the service request, the action cannot be reversed.',
  roomServiceRequestConfirmationMessage: 'Complete all tasks in requests to complete request.',

  // ReportsList.js.js
  reportListEmptyTitle: 'Orders',
  reportListEmptyText: [
    'Either you don\'t have any report created or no report for the current selection found',
  ],

  // PropertySequenceSettings.js
  sequenceListEmpty: [
    'Sequence Settings not configured, system will use default sequence settings.'
  ],

  // Restrictions
  constrainStopSell: 'Stop inventory sales / reservations for the date, regardless of the inventory availability.',
  constrainCTA: 'Prevent reservations from arriving on the date. Reservations can stay through the date.',
  constrainCTD: 'Prevent reservations from departing on the date. Reservations can stay through the date.',
  constrainMAXStay: 'Set a minimum length of stay required for reservations starting on the date.',
  constrainMINStay: 'Set a maximum length of stay required for reservations starting on the date.',

  // ProfileDetailsEditShort
  profileEditShort: 'Quick edit only offers option to update profile name, email and phone number, to edit further details please proceed to profile details.',

  // MessagingManager.js
  messagingBookingOrderSelection: 'Select order(s) to be included in the email, please note only confirmed orders can be send to guest / users.',
  messagingBookingOrderInclusion: 'Include order PDF link in email. (Only orders where print option is enabled will be available to download)',

  messagingBookingSummary: 'Include booking folio/summary in the email.',
  messagingBookingSummaryExpand: 'Expand selected order(s) in folio/summary.',

  // PaymentAnalyticsTerminals.js
  paymentTerminalListEmpty: 'There is no payment terminals configured in this property.',
  paymentPOSListEmpty: 'There is no point of sales configured in the property.',
  paymentOTAListEmpty: 'There is no ota payment for selected configuration in this property.',
  paymentNonTerminalEntry: 'Payments without any terminals attached.',

  orderPOSListEmpty: 'There is no point of sales configured in the property.',
  orderOTAListEmpty: 'There is no ota orders for selected configuration in this property.',

  // BookingAnalytics
  bookingOTAListEmpty: 'There is no ota booking(s) for selected configuration in this property.',
  bookingRoomTypeListEmpty: 'There is no room type configuration in this property.',

  // Analytics
  otaAnalyticsDisclaimer: 'please verify the values & settings of your property in OTA before using it for reference. Hotelforce/Renzo is only representing the data received for respective sources.',

  // Digest BookingGuestDigest.js
  bookingGuestProfileCountMessage: 'Guest count is the number of guest profiles added in each booking room, not the guest count [ numeric ] values entered for the rooms.',
  bookingGuestStatsCountMessage: 'Guest count is the number of guest count [ numeric ] entered in each booking room, not the guest profiled added to the rooms.'
};
