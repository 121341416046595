/**
 *
 * POS Item Details
 * point of sales item information
 */

import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

// Consts and Libs

// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen} from '@fortawesome/free-solid-svg-icons';
import AppAPI from '../../../lib/api';

// Actions
import * as POSActions from '../../../redux/pos/actions';
import PageHeader from '../../../components/dashboard/PageHeader';
import {Constants} from '../../../constants';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pos: state.pos.pos,
  item: state.pos.item,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPOSItem: POSActions.setPOSItem,
};

/* Component ==================================================================== */
class POSItemDetails extends Component {
  static componentName = 'POSItemDetails';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    if (this.props.match.params.POSItemId) {
      this.props.setPOSItem(this.props.match.params.POSItemId, this.props.match.params.POSId)
        .then(() => {
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  }


  render = () => {

    const {loading, error} = this.state;
    const {pos, item} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="pos-item-details screen-container ">
        <Helmet>
          <title>Point of Sales | Item </title>
        </Helmet>

        <PageHeader
          subHeader={true} history={this.props.history} title={'Item Details'}
          description={`Settings & Configuration for item : ${item.name}`}
        />

        <div className={'row'}>
          <div className={'col-lg-9 col-md-9 col-sm-12'}>
            <div className={'row'}>
              <div className={'col-12 col-md-6 mb-3'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Reference</strong> : {item.ref_no}
                  </li>
                </ul>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-md-6 col-sm-12 mb-3'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Name :</strong> {item.name}
                  </li>
                  <li className="list-group-item">
                    <strong>Quantity Type :</strong>
                    {parseInt(item.sub_quantity_type) !== Constants.QUANTITY_TYPE.NOS && ` ${item.sub_quantity_type_display} / ` }
                    {item.quantity_type_display}
                  </li>

                  {(!_.isEmpty(item.attributes) && item.attributes.category_name) &&
                   <li className="list-group-item">
                     <strong>Category :</strong> {item.attributes.category_name}
                   </li>
                  }
                </ul>
              </div>
              <div className={'col-md-6 col-sm-12 mb-3'}>
                <ul className="list-group">
                  {/*<li className="list-group-item">*/}
                  {/*  <strong>Allow Multiple : </strong>*/}
                  {/*  <span className={'badge ' + (item.multiple ? 'badge-success' : 'badge-warning')}>*/}
                  {/*    {item.multiple ? 'Yes' : 'No'}*/}
                  {/*  </span>*/}
                  {/*</li>*/}
                  <li className="list-group-item">
                    <strong>Item Code : </strong>
                    {item.item_code || '...'}
                  </li>
                  <li className="list-group-item">
                    <strong>Status : </strong>
                    <span className={'badge ' + (item.active ? 'badge-success' : 'badge-warning')}>
                      {item.active ? 'Active' : 'De-Active'}
                    </span>
                  </li>
                  <li className="list-group-item">
                    <strong>Item Status : </strong>
                    {item.item_status_display}
                  </li>
                </ul>
              </div>
            </div>

            <div className={'table-responsive'}>
              <table className={'table border'}>
                <thead>
                  <tr>
                    <th className={'w-50'}/>
                    <th className={'text-right'}>Price</th>
                    <th className={'text-right'}>Price (Inc Tax)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong> {item.name}  / </strong> {parseInt(item.sub_quantity_type) !== Constants.QUANTITY_TYPE.NOS && ` ${item.sub_quantity_type_display} / ` }
                      {item.quantity_type_display}
                    </td>
                    <td className={'text-right'}>{item.currency} {item.price}</td>
                    <td className={'text-right'}>{item.currency} {item.price_effective}</td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <strong>Tax :</strong> {item.tax_description || 'N.A'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {[Constants.POS_TYPE.RESTRO_BAR, Constants.POS_TYPE.RESTAURANT, Constants.POS_TYPE.BAR].includes(parseInt(pos.pos_type)) &&
             <div className={'row'}>
               <div className={'col-md-6 col-sm-12 mb-3'}>
                 <ul className="list-group">
                   <li className="list-group-item">
                     <strong>Food Type</strong> : {item.food_type_display}
                   </li>
                 </ul>
               </div>
               <div className={'col-md-6 col-sm-12 mb-3'}>
                 <ul className="list-group">
                   <li className="list-group-item">
                     <strong>Food Content Type</strong> : {item.food_content_display}
                   </li>
                   <li className="list-group-item">
                     <strong>Drink Content Type</strong> : {item.drink_content_display}
                   </li>
                 </ul>
               </div>
             </div>
            }

            <div className={'row'}>
              <div className={'col-12 col-md-12 mb-3'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Item Note</strong> : {item.short_description || 'N.A'}
                  </li>
                  <li className="list-group-item">
                    <strong>Description</strong> : {item.description || 'N.A'}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item">
                <Link
                  to={`/settings/pos/${pos.hash}/item/${item.id}/edit`}
                  className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit
                </Link>
              </li>
            </ul>
          </div>
        </div>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(POSItemDetails);