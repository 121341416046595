import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../lib/api';
// Components
import Error from '../general/Error';
import Loading from '../general/Loading';
// Actions
import * as PropertyActions from '../../redux/property/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  user: state.user,
  property: state.property.property
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetPropertyDetails: PropertyActions.isSetPropertyDetails,
  unSetProperty: PropertyActions.unSetProperty,
};

export default function (ComposedComponent) {
  class PropertyRequired extends Component {
    static contextTypes = {
      router: PropTypes.object
    };

    componentDidMount () {
      const {property} = this.props;
      if (_.isEmpty(property) || !property.property_slug) {
        this.props.isSetPropertyDetails()
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({error});
          });
      }
    }

    render () {
      const {property} = this.props;
      if (this.state.error) return <Error full={false} text={this.state.error}/>;
      if (_.isEmpty(property) || !property.property_slug) return <Loading/>;
      return <ComposedComponent {...this.props} />;
    }

    constructor(props) {
      super(props);
      this.firstLoad = false;
      this.state = { error: null };
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(PropertyRequired);
}