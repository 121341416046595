/**
 *
 * Booking Details
 */
import {Link, Route, Switch} from 'react-router-dom';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import _ from 'lodash';
// Consts and Libs
import {Constants} from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PageHeader from '../../../components/dashboard/PageHeader';
import NavigationTabs from '../../../components/dashboard/NavigationTabs';

import BookingNotes from './BookingNotes';
import BookingOverView from './BookingOverView';
import BookingGuestDetails from './BookingGuestDetails';
import BookingOrderDetails from './BookingOrderDetails';
import BookingAmendmentDetails from './BookingAmendmentDetails';
import BookingChannelManagerLogs from './BookingChannelLogs.js';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  booking: state.booking.booking,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class BookingDetails extends Component {
  static componentName = 'BookingDetails';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
  };

  fetchInitData = () => {
  };

  render = () => {

    const {loading, error} = this.state;
    const {booking} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    let baseUrl = '/booking';
    let absBaseUrl = `${baseUrl}/${this.props.match.params.bookingId}`;
    const menu = [
      {
        title: 'Booking Details',
        link: `${absBaseUrl}/`,
        isExact: true,
      },
      {
        title: 'Guest(s)',
        link: `${absBaseUrl}/booking-guest/`,
      },
      {
        title: 'Booking Order(s)',
        link: `${absBaseUrl}/booking-order/`,
      },
      {
        title: 'Booking Amendment(s)',
        link: `${absBaseUrl}/booking-amendment/`,
      },
      {
        title: 'Booking Note(s)',
        link: `${absBaseUrl}/booking-notes/`,
      },
    ];

    if (booking.booking_source === Constants.BOOKING_SOURCE.CHANNEL_MANAGER) {
      menu.push(
        {
          title: 'Channel Log(s)',
          link: `${absBaseUrl}/booking-channel-logs/`,
        }
      );
    }


    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Booking Details</title>
        </Helmet>

        <Link to={'/booking/list'} className={'btn btn-outline-dark mr-2 btn-sm mt-2'}>
          <FontAwesomeIcon icon={faArrowLeft} size={'sm'}/> {'Booking List'}
        </Link>

        <PageHeader
          border={false}
          history={this.props.history}
          title={'Booking details'}
          description={`Reference number : ${_.isEmpty(booking) ? '...' : booking.ref_no}`}
        />

        <NavigationTabs navItems={menu} classNameContainer={'mb-4'}/>

        <Switch>
          <Route
            path={absBaseUrl} exact
            render={() => <BookingOverView {...this.props}/>}
          />
          <Route
            path={`${absBaseUrl}/booking-guest/`} exact
            render={() => <BookingGuestDetails {...this.props}/>}
          />
          <Route
            path={`${absBaseUrl}/booking-order/`} exact
            render={() => <BookingOrderDetails {...this.props}/>}
          />
          <Route
            path={`${absBaseUrl}/booking-amendment/`} exact
            render={() => <BookingAmendmentDetails {...this.props}/>}
          />
          <Route
            path={`${absBaseUrl}/booking-channel-logs/`} exact
            render={() => <BookingChannelManagerLogs {...this.props}/>}
          />
          <Route
            path={`${absBaseUrl}/booking-notes/`} exact
            render={() => <BookingNotes {...this.props}/>}
          />
        </Switch>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(BookingDetails);
