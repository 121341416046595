/**
 *  POS Item Manage
 *
 */
import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Actions
import * as POSActions from '../../../redux/pos/actions';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants, ErrorMessages, Strings} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleDown, faPlus, faSave} from '@fortawesome/free-solid-svg-icons';
// Form
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Checkbox, Form, Input, Select, SubmitBtn, Textarea} from 'react-formik-ui';
import AppUtil from '../../../lib/util';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pos: state.pos.pos,
  item: state.pos.item,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPOSItem: POSActions.setPOSItem,
};

/* Component ==================================================================== */

class POSItemManage extends Component {
  static componentName = 'POSItemManage';

  static propTypes = {
    match: PropTypes.object,
  };

  componentDidMount = () => {
    if (this.props.match.params.POSItemId) {
      this.props.setPOSItem(this.props.match.params.POSItemId, this.props.match.params.POSId)
        .then(() => {
          this.fetchInitData();
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.fetchInitData();
    }
  };

  addItem = (credentials) => {
    const {pos} = this.props;
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.posapi.post(`pos-item-settings/?pos=${pos.hash}`,
          {
            'name': credentials.name,
            'price': credentials.price,
            'active': credentials.active,
            'multiple': credentials.multiple,
            'category': credentials.category,
            'item_code': credentials.item_code,
            'food_type': credentials.food_type,
            'item_status': credentials.item_status,
            'description': credentials.description,
            'food_content': credentials.food_content,
            'drink_content': credentials.drink_content,
            'quantity_type': credentials.quantity_type,
            'sub_quantity_type': credentials.sub_quantity_type,
            'short_description': credentials.short_description,
            'tax_class': this.state.selectedTaxClasses
          })
          .then((res) => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.setPOSItem(res);
                  this.props.history.replace(this.props.location.pathname.replace(/[^/]*$/, `${res.id}/`));
                }, 500);
              },
            );
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  saveItem = (credentials) => {
    const {pos} = this.props;
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.posapi.patch(`pos-item-settings/${this.props.match.params.POSItemId}/?pos=${pos.hash}`,
          {
            'name': credentials.name,
            'price': credentials.price,
            'active': credentials.active,
            'multiple': credentials.multiple,
            'category': credentials.category,
            'item_code': credentials.item_code,
            'food_type': credentials.food_type,
            'description': credentials.description,
            'item_status': credentials.item_status,
            'food_content': credentials.food_content,
            'drink_content': credentials.drink_content,
            'quantity_type': credentials.quantity_type,
            'sub_quantity_type': credentials.sub_quantity_type,
            'short_description': credentials.short_description,
            'tax_class': this.state.selectedTaxClasses
          })
          .then((res) => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.setPOSItem(res);
                  this.props.history.goBack();
                }, 500);
              },
            );
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  fetchInitData = () => {
    const {item, pos} = this.props;
    let initialValues = {
        'name': '',
        'price': '0',
        'active': true,
        'item_code': '',
        'multiple': true,
        'item_status': '1',
        'quantity_type': '1',
        'food_type': '0',
        'drink_content': '0',
        'food_content': '0',
        'sub_quantity_type': '1',
      }, selectedTaxClasses = [];
    let taxClass = [], categoryClass = [];
    AppAPI.posapi.get(`pos-category-tax-list/?pos=${pos.hash}`)
      .then((res) => {
        if (!_.isEmpty(res.tax_data)) {
          res.tax_data.forEach(data => {
            taxClass.push(data);
          });
        }

        if (!_.isEmpty(res.category_data)) {
          res.category_data.forEach(data => {
            categoryClass.push(
              {'value': data.id.toString(), 'label': data.name.toString()}
            );
          });
        }

        if (this.props.match.params.POSItemId) {
          initialValues = {
            'name': item.name,
            'price': item.price,
            'active': item.active,
            'multiple': item.multiple,
            'food_type': item.food_type,
            'item_code': item.item_code,
            'item_status': item.item_status,
            'food_content': item.food_content,
            'drink_content': item.drink_content,
            'category': item.category_id,
            'description': item.description,
            'quantity_type': item.quantity_type,
            'sub_quantity_type': item.sub_quantity_type,
            'short_description': item.short_description,
          };
          selectedTaxClasses = item.tax_class;
        }
        let formValidation = Yup.object().shape({
          name: Yup.string().min(2, 'Too Short!').required('Required'),
          price: Yup.number().required('Required'),
          sub_quantity_type: Yup.number().required('Required'),
          quantity_type: Yup.number().required('Required'),
        });
        this.setState({
          taxClass,
          initialValues,
          categoryClass,
          formValidation,
          selectedTaxClasses,
          loading: false,

        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: true,
          resultMsg: {error}
        });
      });
  };

  selectTax = tax => {
    let {selectedTaxClasses} = this.state;
    selectedTaxClasses = AppUtil.insertOrRemoveArray(selectedTaxClasses, tax);
    this.setState({selectedTaxClasses});
  };

  render = () => {

    const {item, property, pos} = this.props;
    const {
      loading,
      error,
      initialValues,
      formValidation,
      resultMsg,
      selectedTaxClasses,
      taxClass,
      categoryClass
    } = this.state;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    return (

      <div className="room-type-list-manage screen-container ">
        <Helmet>
          <title>POS Item Manage</title>
        </Helmet>
        <PageHeader
          subHeader={true} history={this.props.history}
          title={(this.props.match.params.POSItemId ? 'Manage' : 'Add') + ' Item'}
          description={this.props.match.params.POSItemId ? `Edit item ${item.name}` : 'Add new item'}
        />

        <Formik
          onSubmit={(values) => this.props.match.params.POSItemId ? this.saveItem(values) : this.addItem(values)}
          validationSchema={formValidation}
          initialValues={initialValues}>
          {(formikProps) => (
            <Form>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Item Information
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-6 form-group">
                        <Input
                          required
                          name='name'
                          label={'Item Name'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <Input
                          name='item_code'
                          label={'Item Code'}
                          className={'form-control'}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-6 form-group">
                        <Select
                          className={'form-control rounded-right-0'}
                          name='category'
                          label={'Category Type'}
                          placeholder='Select an Option'
                          options={categoryClass}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='item_status'
                          label={'Item Status'}
                          placeholder='Select an Option'
                          options={Constants.ITEM_STATUS_INPUT}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 form-group">
                        <Input
                          required
                          type={'number'}
                          name='price'
                          label={'Price ' + property.currency}
                          className={'form-control'}
                          hint={'Base price without tax'}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='active'
                          label='Item Status'
                          text={'Enable sale of this item ?'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                  <p className={''}><strong>Name : </strong> {Strings.posItemName}</p>
                  <p className={''}><strong>Item Code : </strong> {Strings.posItemCode}</p>
                </div>
              </div>

              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div
                    className={'border p-2 text-center anchor'} data-toggle="collapse" href="#taxitems"
                    role="button" aria-expanded="false" aria-controls="taxitems"
                  >
                    <h6 className={'mb-0'}>
                      <FontAwesomeIcon
                        icon={faChevronCircleDown} size={'sm'}
                        className={'mx-2 green-cl float-left mt-1'}/>
                      Tax Information
                      {selectedTaxClasses.length > 0 &&
                      <span className={'ml-3 badge badge-success'}>{selectedTaxClasses.length} Tax applied</span>
                      }
                      <FontAwesomeIcon
                        icon={faChevronCircleDown} size={'sm'}
                        className={'mx-2 green-cl float-right mt-1'}/>
                    </h6>
                  </div>
                  <div className={'py-2 border collapse p-2'} id={'taxitems'}>
                    {this.props.match.params.ticketId &&
                    <Alerts
                      status={'Changes will only be effective on future orders.'}
                    />
                    }
                    <ul className="list-group mt-3 list-inline">
                      {
                        taxClass.map((data, i) => (
                          <li
                            className="list-group-item list-inline-item a-class"
                            key={i}
                            onClick={() => this.selectTax(data.id)}
                          >
                            <input
                              className={'mr-2'} type="checkbox"
                              checked={selectedTaxClasses.includes(data.id)}/>
                            {data.name} : {data.description}
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
              </div>

              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Item Settings
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-6 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='quantity_type'
                          label={'Quantity Type'}
                          placeholder='Select an Option'
                          options={Constants.ORDER_QUANTITY_TYPE_EXPENSE}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='sub_quantity_type'
                          label={'Sub Quantity Type'}
                          placeholder='Select an Option'
                          options={Constants.ORDER_QUANTITY_TYPE_EXPENSE}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-6 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='multiple'
                          label='Multiple Selection'
                          text={'Enable multiple entry in single order ?'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                </div>
              </div>

              {[Constants.POS_TYPE.RESTRO_BAR, Constants.POS_TYPE.RESTAURANT, Constants.POS_TYPE.BAR].includes(parseInt(pos.pos_type)) &&
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Item Content Settings
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-6 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='food_type'
                          label={'Item Type'}
                          placeholder='Select an Option'
                          options={Constants.POS_FOOD_TYPE_INPUT}
                        />
                      </div>
                      {formikProps.values.food_type.toString() === Constants.POS_FOOD_TYPE.DRINK.toString() &&
                      <div className="col-6 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='drink_content'
                          label={'Drink Type'}
                          placeholder='Select an Option'
                          options={Constants.DRINK_CONTENT_TYPE_INPUT}
                        />
                      </div>
                      }
                      {formikProps.values.food_type.toString() === Constants.POS_FOOD_TYPE.FOOD.toString() &&
                      <div className="col-6 form-group">
                        <Select
                          required
                          className={'form-control rounded-right-0'}
                          name='food_content'
                          label={'Food Content Type'}
                          placeholder='Select an Option'
                          options={Constants.FOOD_CONTENT_TYPE_INPUT}
                        />
                      </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              }

              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Item Description
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-12 form-group">
                        <Textarea
                          name='short_description'
                          label={'Item Note'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-12 form-group">
                        <Textarea
                          name='description'
                          label={'Description'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                  <p className={''}><strong>Item Note : </strong> {Strings.posNoteNote}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 mt-2">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn className={'btn btn-success btn-block btn-lg mt-2'}>
                    <FontAwesomeIcon
                      className={'white-cl mr-2'}
                      icon={this.props.match.params.POSItemId ? faSave : faPlus} size={'sm'}
                    /> {this.props.match.params.POSItemId ? 'Save' : 'Add'}
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>


      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      initialValues: {},
      taxClass: [],
      categoryClass: [],
      selectedTaxClasses: [],
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(POSItemManage);
