/**
 * Header
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Link} from 'react-router-dom';

import APPUtil from '../../lib/util';

import logoSvg from '../../images/logo.svg';

//Actions
import * as UserActions from '../../redux/user/actions';
import * as PropertyActions from '../../redux/property/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  user: state.user,
  property: state.property.property
});

// Any actions to map to the component?
const mapDispatchToProps = {
  logout: UserActions.logout,
  unSetProperty: PropertyActions.unSetProperty
};


/* Component ==================================================================== */
class Header extends Component {
  static componentName = 'Header';

  static propTypes = {
    disableLinks: PropTypes.bool
  };

  logout = () => {
    this.props.logout();
    this.props.history.push('/auth/login');
  };

  render = () => {
    const {property, user} = this.props;
    let dropdown = '';
    if (property.name) {
      dropdown = (
        <li className="nav-item dropdown navbar-right">
          <div
            className="a-class nav-link dropdown-toggle dropdown-menu-head" id="dropdown01" data-toggle="dropdown"
            role="button" aria-haspopup="true" aria-expanded="false">
            {this.props.user.level && <span className={'red-cl'}>(Admin) </span>}
            {APPUtil.limitChars(user.display_name, 15)}
            <span className="caret"/>
          </div>
          <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown01">
            <div className="dropdown-header">{APPUtil.limitChars(property.name, 12)}</div>
            <Link className="dropdown-item" to="/settings">Settings</Link>
            <hr className="my-1"/>
            <Link className="dropdown-item" to="/property">Switch Property</Link>
            <hr className="my-1"/>
            <Link className="dropdown-item" to="/user/">Account Settings</Link>
            <div
              className="a-class dropdown-item" role="presentation" onKeyPress={this.handleKeyPress}
              onClick={()=>this.logout()}>Logout
            </div>
          </div>
        </li>
      );
    } else {
      dropdown = (
        <li className="nav-item dropdown">
          <div
            className="a-class nav-link dropdown-toggle dropdown-menu-head dropdown-menu-right" id="dropdown01" data-toggle="dropdown"
            role="button" aria-haspopup="true" aria-expanded="false">
            {this.props.user.level && <span className={'red-cl'}>(Admin) </span>}
            {String(this.props.user.display_name).substring(0, 15)}
            <span className="caret"/>
          </div>
          <div className="dropdown-menu" aria-labelledby="dropdown01">
            <Link className="dropdown-item" to="/property">Select Property</Link>
            <hr className="my-1"/>
            <Link className="dropdown-item" to="/user/">Account Settings</Link>
            <div
              className="a-class dropdown-item" role="presentation" onKeyPress={this.handleKeyPress}
              onClick={this.logout}>Logout
            </div>
          </div>
        </li>
      );
    }

    return (
      <div className="header sticky-top border border-top-0 border-right-0 border-left-0">
        <div>
          <nav className="navbar navbar-expand-md navbar-light bg-light">
            <button
              className="navbar-toggler" type="button" data-toggle="collapse"
              data-target="#renzoSidebar" aria-controls="renzoSidebar"
              aria-expanded="true" aria-label="Toggle Sidebar">
              <span className="navbar-toggler-icon"/>
            </button>
            <Link className="navbar-brand" to="/dashboard">
              <img alt="Brand" src={logoSvg}/>
            </Link>

            <button
              className="navbar-toggler" type="button" data-toggle="collapse"
              data-target="#renzoHeaderNavbar" aria-controls="renzoHeaderNavbar"
              aria-expanded="true" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"/>
            </button>
            {!this.props.disableLinks &&
             <div className="collapse navbar-collapse" id="renzoHeaderNavbar">
               <ul className="navbar-nav ml-auto">
                 <div className="d-inline my-2 my-lg-0">
                   {!user.first_name && <Link className="btn btn-outline-danger" to="/user/account/profile/edit">Complete Profile</Link>}
                 </div>
                 {dropdown}
               </ul>
             </div>
            }
          </nav>
        </div>
      </div>
    );
  };
}


/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
