/**
 * Manage Room Type Package
 * Manage room rate plan
 */
import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import AppUtil from '../../../../lib/util';
// Consts and Libs
import AppAPI from '../../../../lib/api';
import { Constants, ErrorMessages, Strings } from '../../../../constants';
// Components
import {Alerts} from '../../../../components/ui';
import Error from '../../../../components/general/Error';
import Loading from '../../../../components/general/Loading';
import PageHeader from '../../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faSave, faPlus, faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
// Form
import * as Yup from 'yup';
import { Checkbox, Form, Input, Select, SubmitBtn, Textarea } from 'react-formik-ui';
import {Formik} from 'formik';
// Actions
import * as RoomTypeActions from '../../../../redux/roomtype/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  roomType: state.roomType.roomType,
  roomTypePackage: state.roomType.roomTypePackage,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setRoomType: RoomTypeActions.setRoomType,
  setRoomTypePackage: RoomTypeActions.setRoomTypePackage,
};

/* Component ==================================================================== */

class RoomTypePackageManage extends Component {
  static componentName = 'RoomTypePackageManage';

  static propTypes = {
    match: PropTypes.object,
  };

  componentDidMount = () => {
    if (this.props.match.params.roomTypeId && this.props.match.params.roomTypePackageId) {
      this.props.setRoomTypePackage(this.props.match.params.roomTypePackageId, this.props.match.params.roomTypeId)
        .then(() => {
          this.fetchInitData();
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.fetchInitData();
    }
  };

  addRoomTypePackage = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});
      AppAPI.roomtypepackage.post('',
        {
          'name': credentials.name,
          'base_price': credentials.base_price,
          'guest_price': credentials.guest_price,
          'child_price': credentials.child_price,
          'infant_price': credentials.infant_price,
          'extra_bed_price': credentials.extra_bed_price,
          'cancellation_policy': credentials.cancellation_policy,

          'inclusion_break_fast': credentials.inclusion_break_fast,
          'inclusion_lunch': credentials.inclusion_lunch,
          'inclusion_dinner': credentials.inclusion_dinner,

          'description': credentials.description,

          'tax_class': this.state.selectedTaxClasses,

          'active': credentials.active,
          'room_type': this.props.match.params.roomTypeId,
        })
        .then((roomTypePackage) => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                this.props.history.push(
                  `/settings/inventory/room-type/${this.props.match.params.roomTypeId}/package/${roomTypePackage.id}/`
                );
              }, 500);
            },
          );
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  saveRoomTypePackage = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});
      AppAPI.roomtypepackage.patch(this.props.match.params.roomTypePackageId + '/',
        {
          'id': this.props.match.params.roomTypePackageId,
          'name': credentials.name,
          'base_price': credentials.base_price,
          'guest_price': credentials.guest_price,
          'child_price': credentials.child_price,
          'infant_price': credentials.infant_price,
          'extra_bed_price': credentials.extra_bed_price,
          'cancellation_policy': credentials.cancellation_policy,

          'inclusion_break_fast': credentials.inclusion_break_fast,
          'inclusion_lunch': credentials.inclusion_lunch,
          'inclusion_dinner': credentials.inclusion_dinner,

          'description': credentials.description,

          'tax_class': this.state.selectedTaxClasses,

          'active': credentials.active,
        })
        .then(() => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                this.props.history.goBack();
              }, 500);
            },
          );
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  fetchInitData = () => {
    const {roomTypePackage} = this.props;
    let initialValues = {
      name: '',
      base_price: '0',
      guest_price: '0',
      child_price: '0',
      infant_price: '0',
      extra_bed_price: '0',
      cancellation_policy: '',
      inclusion_break_fast: false,
      inclusion_lunch: false,
      inclusion_dinner: false,
      active: true,
      description: '',
    };
    let taxClass = [], selectedTaxClasses = [];

    let formValidation = Yup.object().shape({
      name: Yup.string().min(2, 'Too Short!').required('Required'),
      base_price: Yup.number().positive().required(),
      guest_price: Yup.number().required(),
      child_price: Yup.number().required(),
      infant_price: Yup.number().required(),
      extra_bed_price: Yup.number().required(),
      cancellation_policy: Yup.number().required(),
    });

    AppAPI.propertyapi.get('tax-classes-list/')
      .then((res) => {

        if (!_.isEmpty(res)) {
          res.forEach(data => {
            taxClass.push(data);
          });
        }

        if (!_.isEmpty(roomTypePackage) && this.props.match.params.roomTypePackageId) {
          initialValues = {
            name: roomTypePackage.name,
            base_price: roomTypePackage.base_price,
            guest_price: roomTypePackage.guest_price,
            child_price: roomTypePackage.child_price,
            infant_price: roomTypePackage.infant_price,
            extra_bed_price: roomTypePackage.extra_bed_price,
            cancellation_policy: roomTypePackage.cancellation_policy,
            inclusion_break_fast: roomTypePackage.inclusion_break_fast,
            inclusion_lunch: roomTypePackage.inclusion_lunch,
            inclusion_dinner: roomTypePackage.inclusion_dinner,
            active: roomTypePackage.active,
            description: roomTypePackage.description,
          };
          selectedTaxClasses = roomTypePackage.tax_class;
        }

        this.setState({
          taxClass,
          initialValues,
          formValidation,
          selectedTaxClasses,
          loading: false,
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: true,
          resultMsg: {error}
        });
      });
  };

  selectTax = tax => {
    let {selectedTaxClasses} = this.state;
    selectedTaxClasses = AppUtil.insertOrRemoveArray(selectedTaxClasses, tax);
    this.setState({selectedTaxClasses});
  };

  render = () => {

    const {roomType, property} = this.props;
    const {loading, error, initialValues, formValidation, resultMsg, selectedTaxClasses, taxClass} = this.state;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    return (

      <div className="room-type-list-manage screen-container ">
        <Helmet>
          <title>Manage Room Type Packages</title>
        </Helmet>
        <PageHeader
          subHeader={true} history={this.props.history}
          title={(!this.props.match.params.roomTypePackageId ? 'Add' : 'Manage') + ' Room Type Packages'}
        />

        <Formik
          initialValues={initialValues}
          validationSchema={formValidation}
          onSubmit={
            (values) => !this.props.match.params.roomTypePackageId
              ? this.addRoomTypePackage(values)
              : this.saveRoomTypePackage(values)
          }
        >
          {() => (
            <Form>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center w-100'}>
                    <h6 className={'mb-0'}>
                      Package Information
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Input
                          required
                          name='name'
                          label={'Package Name'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                  <p><strong>Name : </strong>{Strings.roomTypePackageManageName}</p>
                </div>
              </div>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center w-100'}>
                    <h6 className={'mb-0'}>
                      Rate Information [{property.currency}]
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <Input
                          required
                          name='base_price'
                          label={'Base Price'}
                          className={'form-control'}
                          hint={Strings.roomTypePackageManageBasePrice}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <Input
                          required
                          name='guest_price'
                          label={'Additional Guest'}
                          className={'form-control'}
                          hint={Strings.roomTypePackageManageAdditionalGuestPrice}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <Input
                          required
                          name='child_price'
                          label={'Child'}
                          className={'form-control'}
                          hint={Strings.roomTypePackageManageChildPrice}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <Input
                          required
                          name='infant_price'
                          label={'Infant'}
                          className={'form-control'}
                          hint={Strings.roomTypePackageManageInfantPrice}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <Input
                          required
                          name='extra_bed_price'
                          label={'Extra Bed'}
                          className={'form-control'}
                          hint={Strings.roomTypePackageManageExtraBedPrice}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                  <p><strong>Rate Information: </strong>{Strings.roomTypePackageManagePrice}</p>
                </div>
              </div>

              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div
                    className={'border p-2 text-center anchor'} data-toggle="collapse" href="#taxitems"
                    role="button" aria-expanded="true" aria-controls="taxitems"
                  >
                    <h6 className={'mb-0'}>
                      <FontAwesomeIcon
                        icon={faChevronCircleDown} size={'sm'}
                        className={'mx-2 green-cl float-left mt-1'}/>
                      Tax Information
                      {selectedTaxClasses.length > 0 &&
                      <span className={'ml-3 badge badge-success'}>{selectedTaxClasses.length} Tax applied</span>
                      }
                      <FontAwesomeIcon
                        icon={faChevronCircleDown} size={'sm'}
                        className={'mx-2 green-cl float-right mt-1'}/>
                    </h6>
                  </div>
                  <div className={'py-2 border collapse p-2'} id={'taxitems'}>
                    <ul className="list-group mt-3 list-inline">
                      {
                        taxClass.map((data, i) => (
                          <li
                            className="list-group-item list-inline-item a-class"
                            key={i}
                            onClick={() => this.selectTax(data.id)}
                          >
                            <input
                              className={'mr-2'} type="checkbox"
                              checked={selectedTaxClasses.includes(data.id)}/>
                            {data.name} : {data.description}
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
              </div>

              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center w-100'}>
                    <h6 className={'mb-0'}>
                      Policy & Inclusions
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 form-group">
                        <Select
                          className={'form-control rounded-right-0'}
                          name='cancellation_policy'
                          label={'Cancellation Policy *'}
                          placeholder='Select an Option'
                          options={Constants.ROOM_TYPE_PACKAGE_CANCELLATION_INPUT}
                          hint={Strings.roomTypePackageManageExtraBedPrice}
                        />
                      </div>
                    </div>

                    <hr/>

                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='inclusion_break_fast'
                          label='Inclusion Breakfast'
                          text={'Does the base price include breakfast.'}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='inclusion_lunch'
                          label='Inclusion Lunch'
                          text={'Does the base price include lunch.'}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='inclusion_dinner'
                          label='Inclusion Dinner'
                          text={'Does the base price include dinner.'}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='active'
                          label='Item Status'
                          text={'Enable sale of this package'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                  <p><strong>Policy : </strong>{Strings.roomTypePackageManagePolicy}</p>
                </div>
              </div>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center w-100'}>
                    <h6 className={'mb-0'}>
                      Booking Engine Distribution Settings
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-12 form-group">
                        <Textarea
                          name='description'
                          label={'Description (optional)'}
                          className={'form-control'}
                          hint={Strings.roomTypePackageManageDescription}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-12 mt-2">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn className={'btn btn-success btn-block btn-lg mt-2'}>
                    <FontAwesomeIcon
                      className={'white-cl mr-2'}
                      icon={_.isEmpty(roomType) ? faPlus : faSave} size={'sm'}
                    /> {_.isEmpty(roomType) ? 'Add' : 'Save'}
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>


      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      initialValues: {},
      taxClass: [],
      selectedTaxClasses: [],
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(RoomTypePackageManage);
