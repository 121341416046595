/**
 * Model for Rate Date update
 */
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';
import {Modal} from 'react-bootstrap';
import React, {Component} from 'react';
// Consts and Libs
import AppUtil from '../../../lib/util';
import Strings from '../../../constants/strings';
// Components
import {Input, SubmitBtn} from 'react-formik-ui';
import {faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

/* Component ==================================================================== */

class RateDateUpdate extends Component {
  static componentName = 'RateDateUpdate';

  static propTypes = {
    editType: PropTypes.object,
    channelType: PropTypes.string,
    roomTypeData: PropTypes.object,
    updateRateDate: PropTypes.func,
    rateDateDetails: PropTypes.object,
  };

  static defaultProps = {
    editType: 'basePrice',
    channelType: 'channel_manager'
  }


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        searchText: '',
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  updateRateDate = (formData) => {
    const payload = {};
    const {editType, channelType} = this.props;
    switch (editType) {
    case 'minLengthOfStay':
      payload[`${channelType}_min_length_of_stay`] = formData.minLengthOfStay;
      break;
    case 'maxLengthOfStay':
      payload[`${channelType}_max_length_of_stay`] = formData.maxLengthOfStay;
      break;
    default:
      payload.base_price = formData.basePrice;
    }
    this.props.updateRateDate(payload);
  }


  render = () => {

    const {show, editType, roomTypeData, rateDateDetails, channelType} = this.props;

    let formValidation, formInputData, modelTitle;
    if (!show) return <React.Fragment/>;

    const initialValues = {
      basePrice: rateDateDetails.base_price,
      minLengthOfStay: 1,
      maxLengthOfStay: 28,
    };

    if (channelType) {
      if (rateDateDetails[`${channelType}_min_length_of_stay`]){
        initialValues.minLengthOfStay = rateDateDetails[`${channelType}_min_length_of_stay`];
      }

      if (rateDateDetails[`${channelType}_max_length_of_stay`]){
        initialValues.maxLengthOfStay = rateDateDetails[`${channelType}_max_length_of_stay`];
      }
    }

    switch (editType) {
    case 'maxLengthOfStay':
      modelTitle = 'Maximum Length of Stay';
      formValidation = Yup.object().shape({
        maxLengthOfStay: Yup.number()
          .min(initialValues.minLengthOfStay, `Must be ${initialValues.minLengthOfStay} or greater`)
          .max(999, 'Must be 999 or less').required().integer(),
      });
      formInputData = () => (
        <React.Fragment>
          <div className="form-row">
            <div className="col-8 mx-auto form-group">
              <Input
                required
                name="maxLengthOfStay"
                type={'number'}
                label={'Maximum length of stay'}
                hint={Strings.constrainMAXStay}
                className={'form-control'}
              />
            </div>
          </div>
        </React.Fragment>
      );
      break;
    case 'minLengthOfStay':
      modelTitle = 'Minimum Length of Stay';
      formValidation = Yup.object().shape({
        minLengthOfStay: Yup.number().min(1, 'Must be 1 or greater')
          .max(initialValues.maxLengthOfStay, `Must be ${initialValues.maxLengthOfStay} or less`).required().integer(),
      });
      formInputData = () => (
        <React.Fragment>
          <div className="form-row">
            <div className="col-8 mx-auto form-group">
              <Input
                required
                name="minLengthOfStay"
                type={'number'}
                label={'Minimum length of stay'}
                hint={Strings.constrainMINStay}
                className={'form-control'}
              />
            </div>
          </div>
        </React.Fragment>
      );
      break;
    default:
      modelTitle = 'Base Price';
      formValidation = Yup.object().shape({
        basePrice: Yup.number().required(),
      });
      formInputData = (formikProps) => (
        <React.Fragment>
          <div className="form-row">
            <div className="col-lg-6 col-md-12 form-group">
              <Input
                label={'Base Price.'}
                hint={'Price without tax.'}
                type={'number'}
                name={'basePrice'}
                className={'form-control'}
              />
            </div>
            <div className="col-lg-6 col-md-12 form-group">
              <Input
                disabled={true}
                label={'Base Price + Tax'}
                value={(formikProps.values.basePrice && roomTypeData) ? (parseFloat(formikProps.values.basePrice) + parseFloat(AppUtil.calculateTax(roomTypeData.tax_cache, formikProps.values.basePrice, 1))).toFixed(2) : '...'}
                hint={`${roomTypeData ? `Tax Classes : ${roomTypeData.description}` : 'N.A'}`}
                type={'number'}
                name={'basePriceEffective'}
                className={'form-control'}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }


    return (
      <Modal
        {...this.props}
        size={'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {modelTitle} for {AppUtil.formatDateTime(rateDateDetails.date, 'datef')}
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={formValidation}
          onSubmit={values => this.updateRateDate(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <Form className={'form-group'}>
                      <div className="mt-2">
                        {formInputData(formikProps)}
                      </div>
                    </Form>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary  btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <SubmitBtn type="submit" className={'btn btn-success  btn-block'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                    </SubmitBtn>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      searching: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: true,
    };
  }
}

/* Export Component ==================================================================== */
export default RateDateUpdate;
