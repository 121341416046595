/**
 *
 * Refund Details
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import {Constants, Strings} from '../../constants';
// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import GuestListView from '../guest/GuestListView';
import PageHeader from '../../components/dashboard/PageHeader';
import {confirmAlert} from '../../components/general/ConfirmAlert';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBan, faCashRegister,
  faComment,
  faFilePdf,
  faPaperPlane,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import {Alerts, History} from '../../components/ui';
import RefundAction from './models/RefundAction';
import MessagingManager from '../messaging/MessagingManager';
import RecordView from '../../components/dashboard/RecordView';
import GuestDetailsOverView from '../guest/GuestDetailsOverView';
import TimeLineList from '../../components/dashboard/TimeLineList';
import PaymentReferenceManage from '../payment/PaymentReferenceManage';
// Actions
import * as RefundActions from '../../redux/payment/actions';
import * as PropertyActions from '../../redux/property/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  paymentTerminals: state.property.paymentTerminals,
  property: state.property.property,
  refund: state.payment.refund,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setRefund: RefundActions.setRefund,
  setPaymentTerminals: PropertyActions.setPaymentTerminals,
};

/* Component ==================================================================== */
class RefundDetails extends Component {
  static componentName = 'RefundDetails';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };
  componentDidMount = () => {
    if (this.props.match.params.refundId) {
      if (_.isEmpty(this.props.paymentTerminals)) {
        this.props.setPaymentTerminals();
      }
      this.props.setRefund(this.props.match.params.refundId)
        .then(() => {
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };

  updateRefund = payload => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.refund
          .patch(this.props.refund.hash + '/', payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                this.props.setRefund(res);
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  cacheReset = () => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.refund
        .post(`${this.props.refund.hash}/cache-reset/`, {})
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            this.props.setRefund(res);
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            resultMsg: {error},
          });
        });
    });
  };


  showProfileDetails = (guestId, add_guest = false) => {
    this.setState({profileDetailsData: guestId, showProfileDetails: true, addProfile: add_guest});
  };

  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {refund, paymentTerminals} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const timeLine= [
      {
        'title': 'Created',
        'iconClass': 'green-cl',
        'description': AppUtil.formatDateTime(refund.created),
      },
      {
        'title': 'Refund',
        'iconClass': refund.payment_status === Constants.PAYMENT_STATUS.PAID ? 'green-cl' : 'grey-cl',
        'description': refund.payment_time ? AppUtil.formatDateTime(refund.payment_time) : refund.payment_status_display
      }
    ];

    return (
      <div className="refund-details screen-container ">
        <Helmet>
          <title>Refund Details : {refund.ref_no}</title>
        </Helmet>

        {!_.isEmpty(refund.attributes) &&
        <React.Fragment>
          {refund.attributes.payment_hash &&
          <Link
            to={`/payment/${refund.attributes.payment_hash}/`}
            className={'btn btn-outline-dark mr-2 btn-sm mt-2'}
          >
            <FontAwesomeIcon icon={faCashRegister} className={'mr-2'} size={'sm'}/>
            {refund.attributes.payment_ref_no || 'Payment'}
          </Link>
          }
        </React.Fragment>
        }

        <PageHeader
          history={this.props.history} title={'Refund details'}
          description={'Reference number : ' + refund.ref_no}
        />

        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className={'row'}>
              <div className={'col'}>
                <p className={'mb-0 text-muted'}>Total</p>
                <div className={'d-inline-flex align-items-baseline'}>
                  <h2>
                    {refund.currency + ' ' + refund.total}
                  </h2>
                  <div className={'align-items-baseline'}>
                    <span className={'ml-2 badge ' + (AppUtil.paymentStatusColor(refund.payment_status))}>
                      {refund.payment_status_display}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <hr/>

            <div className={'row'}>
              <div className={'col-lg-6 col-md-12'}>
                <table className={'table table-borderless table-sm mb-3'}>
                  <tbody>
                    {refund.payment_id &&
                    <tr>
                      <td className={'w-50 text-muted'}>Payment ID</td>
                      <td><strong>{refund.payment_id}</strong></td>
                    </tr>
                    }
                    <tr>
                      <td className={'w-50 text-muted'}>Payment Method</td>
                      <td>{refund.payment_method_display}</td>
                    </tr>

                    {refund.description &&
                    <tr>
                      <td className={'w-50 text-muted'}>Description</td>
                      <td>{refund.description}</td>
                    </tr>
                    }
                  </tbody>
                </table>
              </div>
              <div className={'col-lg-6 col-md-12'}>
                <table className={'table table-borderless table-sm mb-3'}>
                  <tbody>
                    {!_.isEmpty(refund.attributes) &&
                    <React.Fragment>

                      <tr>
                        <td className={'w-50 text-muted'}>Created By</td>
                        <td>{refund.attributes.created_user || 'N.A'}</td>
                      </tr>

                      {refund.attributes.paid_user &&
                      <tr>
                        <td className={'w-50 text-muted'}>Paid by</td>
                        <td>{refund.attributes.paid_user}</td>
                      </tr>
                      }

                      {refund.attributes.payment_terminal &&
                      <tr>
                        <td className={'w-50 text-muted'}>Terminal</td>
                        <td>{refund.attributes.payment_terminal}</td>
                      </tr>
                      }

                      {refund.attributes.order_hash &&
                      <tr>
                        <td className={'w-50 text-muted'}>Order</td>
                        <td>
                          <Link to={`/order/${refund.attributes.order_hash}`}>
                            {refund.attributes.order_ref_no}
                          </Link>
                        </td>
                      </tr>
                      }

                      {refund.attributes.consolidated_payment_hash &&
                      <tr>
                        <td className={'w-50 text-muted'}>Consolidated Payment</td>
                        <td>
                          <Link to={`/payment/${refund.attributes.consolidated_payment_hash}/`}>
                            {refund.attributes.consolidated_payment_ref_no || 'Payment'}
                          </Link>
                        </td>
                      </tr>
                      }

                      {refund.attributes.payment_hash &&
                      <tr>
                        <td className={'w-50 text-muted'}>Payment</td>
                        <td>
                          <Link to={`/payment/${refund.attributes.payment_hash}`}>
                            {refund.attributes.payment_ref_no}
                          </Link>
                        </td>
                      </tr>
                      }

                      {refund.attributes.pos_name &&
                      <tr>
                        <td className={'w-50 text-muted'}>Point of Sale</td>
                        <td>
                          <Link to={`/point-of-sale/${refund.attributes.pos_hash}`}>
                            {refund.attributes.pos_name}
                          </Link>
                        </td>
                      </tr>
                      }
                      {refund.attributes.event_name &&
                      <tr>
                        <td className={'w-50 text-muted'}>Event</td>
                        <td>
                          <Link to={`/point-of-sale/${refund.attributes.event_hash}`}>
                            {refund.attributes.event_name}
                          </Link>
                        </td>
                      </tr>
                      }
                    </React.Fragment>
                    }
                  </tbody>
                </table>
              </div>
            </div>
            {(refund.guest || refund.corporate) &&
            <div className={'mb-3'}>
              <div className={'row'}>
                {(refund.guest) &&
                <div className={'col-md-6 col-12 mb-3'}>
                  <p className={'mb-1 text-muted'}>Customer</p>
                  <GuestListView
                    data={refund.guest}
                    action={() => refund.guest.id ? this.showProfileDetails(refund.guest.id) : null}
                  />
                </div>
                }

                {(refund.corporate) &&
                <div className={'col-md-6 col-12 mb-3'}>
                  <p className={'mb-1 text-muted'}>Corporate / Agent</p>
                  <GuestListView
                    data={refund.corporate}
                    action={() => refund.corporate.id ? this.showProfileDetails(refund.corporate.id) : null}
                  />
                </div>
                }
              </div>
            </div>
            }

            <div className={'mb-3'}>
              <TimeLineList timeLine={timeLine}/>
            </div>

            <div className={'row mb-3'}>
              <div className={'col-12'}>
                <p><strong>Refund Breakdown</strong></p>
                <hr/>
                <table className={'table table-borderless table-sm'}>
                  <tbody>
                    <tr>
                      <td className={'w-25'}>Refund</td>
                      <td>{refund.currency + ' ' + refund.total}</td>
                      <td>
                        {refund.reference_id &&
                        <React.Fragment>
                          <FontAwesomeIcon icon={faComment} className={'mr-2'}/> {refund.reference_id}
                        </React.Fragment>
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
            {!refund.lock &&
            <div>
              <hr/>
              <div className={'row mb-3'}>
                <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                  <button
                    className={'btn btn-lg btn-block btn-secondary'}
                    onClick={() => {
                      confirmAlert({
                        title: 'Cancel Refund',
                        message: Strings.refundCancelText,
                        buttons: [
                          {
                            className: 'btn-danger',
                            label: 'Cancel Refund',
                            onClick: () => this.updateRefund({void_payment: true}),
                          },
                          {
                            className: 'btn-secondary',
                            label: 'Exit',
                          },
                        ],
                      });
                    }}
                  >
                    Cancel Refund
                  </button>
                </div>
                <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                  <button
                    className={'btn btn-lg btn-block btn-success'}
                    onClick={() => this.setState({showRefundAction: true})}
                  >
                    Confirm Refund
                  </button>
                </div>
              </div>
            </div>
            }

            <RecordView record={refund.scanned_record_1_signed}/>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group mb-5">
              <li className="list-group-item  align-items-center">
                <button onClick={() => this.setState({showMessagingManager: true})} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faPaperPlane} size={'sm'} className={'mr-2'}/> Notification ( E-Mail / SMS )
                </button>
              </li>
              <li className="list-group-item">
                <a
                  href={refund.lock ? refund.resources_link : '#'} rel="noopener noreferrer" target={'_blank'}
                  className={'btn btn-link m-0 p-0 ' + (refund.lock ? '' : 'disabled')}>
                  <FontAwesomeIcon icon={faFilePdf} size={'sm'} className={'mr-2'}/> Refund Receipt PDF
                </a>
              </li>
            </ul>
            <ul className="list-group mb-5">
              <li
                className="list-group-item d-flex justify-content-between align-items-center"
                onClick={() => this.cacheReset()}
              >
                <small>Refund Actions</small>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <button
                  className="btn btn-link red-cl m-0 p-0"
                  disabled={refund.lock}
                  onClick={() => {
                    confirmAlert({
                      title: 'Cancel Refund',
                      message: 'This will cancel and close the refund.',
                      buttons: [
                        {
                          className: 'btn-danger',
                          label: 'Cancel Refund',
                          onClick: () => this.updateRefund({void_payment: true}),
                        },
                        {
                          className: 'btn-secondary',
                          label: 'Exit',
                        },
                      ],
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faBan} size={'sm'} className={'mr-2'}/> Cancel Refund
                </button>
              </li>
              <li className="list-group-item">
                <button
                  type="button" className="btn btn-link m-0 p-0" data-toggle="modal"
                  data-target="#orderDiscountModel"
                  onClick={() => this.setState({
                    showPaymentReferenceManage: true,
                  })}
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle} size={'sm'} className={'mr-2'}/>
                  {(refund.scanned_record_1_signed && refund.scanned_record_1_signed.record_2x) ? 'Edit ' : 'Add '} Reference
                </button>
                <p className={'small text-muted mb-0'}>Attach reference document.</p>
              </li>
            </ul>
          </div>
        </div>
        {/* Refund Reference */}
        <PaymentReferenceManage
          show={this.state.showPaymentReferenceManage}
          onHide={() => this.setState({showPaymentReferenceManage: false})}
          data={refund}
          updateResponse={(data) => this.props.setRefund(data)}
        />

        {/* Refund Actions */}
        <RefundAction
          refund={refund}
          paymentTerminals={paymentTerminals}
          onHide={() => this.setState({showRefundAction: false})}
          show={this.state.showRefundAction}
          updateResponse={(data) => this.props.setRefund(data)}
        />
        {/*  Profile Details */}
        <GuestDetailsOverView
          onHide={() => this.setState({showProfileDetails: false})}
          guestId={this.state.profileDetailsData}
          show={this.state.showProfileDetails}
          resultMsg={resultMsg}
        />
        {/* Messaging */}
        <MessagingManager
          onHide={() => this.setState({showMessagingManager: false})}
          show={this.state.showMessagingManager}
          property={this.props.property}
          refund={refund}
          guests={refund.guest ? [refund.guest] : null}
        />
        <History object={refund}/>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      showRefundAction: false,
      profileDetailsData: null,
      showProfileDetails: false,
      showMessagingManager: false,
      showPaymentReferenceManage: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RefundDetails);