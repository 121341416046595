/**
 *
 * Payment Terminal
 * overview of Payment Terminal
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {Link} from 'react-router-dom';
import Moment from 'moment';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import {Constants} from '../../../constants';
import Colors from '../../../theme/colors';
// Components
import PaymentAdd from '../models/PaymentAdd';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faList, faPlus} from '@fortawesome/free-solid-svg-icons';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PTerminalView extends Component {
  static componentName = 'PTerminalView';
  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = (no_cache) => {
    this.setState({loading: true});
    AppAPI.pterminalapi.get('pterminal-status/' + (no_cache ? '?no_cache=true' : ''))
      .then((res) => {
        if (res.actions !== '') {
          this.setState({
            loading: false,
            currency: res.currency,
            time: res.time,
            response: res,
          });
        } else {
          this.setState({loading: false, error: true});
        }
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({loading: false, error: error, resultMsg: {error}});
      });
  };


  render = () => {
    const {loading, error, response, currency, chartValueType} = this.state;
    const {property} = this.props;
    const {last_updated} = this.state.response;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const chartCount = [], chartValue = [];

    const PTerminalData = {
      'notPaidPayment': {
        'count': 0,
        'total': 0,
      },
      'datePaymentPaid': {
        'count': 0,
        'total': 0,
      },
      'datePaymentNotPaid': {
        'count': 0,
        'total': 0,
      }
    };

    if (!_.isEmpty(response)) {
      const {pterminal_date_data, pterminal_not_paid} = response;
      if (!_.isEmpty(pterminal_date_data)){
        pterminal_date_data.forEach((data) => {
          switch (data.payment_status) {
          case Constants.PTERMINAL_STATUS.INITIAL:
            chartCount.push({name: 'Initial', value: data.count, color: Colors.info});
            chartValue.push({name: 'Initial', value: data.total, color: Colors.info});
            break;
          case Constants.PTERMINAL_STATUS.TERMINAL_RESPONSE:
            chartCount.push({name: 'Terminal Response', value: data.count, color: Colors.blue});
            chartValue.push({name: 'Terminal Response', value: data.total, color: Colors.blue});
            break;
          case Constants.PTERMINAL_STATUS.NOT_PAID:
            chartCount.push({name: 'Not Paid', value: data.count, color: Colors.red});
            chartValue.push({name: 'Not Paid', value: data.total, color: Colors.red});
            break;
          case Constants.PTERMINAL_STATUS.PAID:
            chartCount.push({name: 'Paid', value: data.count, color: Colors.green});
            chartValue.push({name: 'Paid', value: data.total, color: Colors.green});
            break;
          case Constants.PTERMINAL_STATUS.ACTION:
            chartCount.push({name: 'Action', value: data.count, color: Colors.info});
            chartValue.push({name: 'Action', value: data.total, color: Colors.info});
            break;
          case Constants.PTERMINAL_STATUS.CANCELLED:
            chartCount.push({name: 'Cancelled', value: data.count, color: Colors.secondary});
            chartValue.push({name: 'Cancelled', value: data.total, color: Colors.secondary});
            break;
          case Constants.PTERMINAL_STATUS.FAILED:
            chartCount.push({name: 'Failed', value: data.count, color: Colors.secondary});
            chartValue.push({name: 'Failed', value: data.total, color: Colors.secondary});
            break;
          default:
            break;
          }
        });
      }

      if (!_.isEmpty(pterminal_date_data)) {
        pterminal_date_data.forEach((data) => {
          if (data.payment_status === Constants.PTERMINAL_STATUS.PAID) {
            PTerminalData['datePaymentPaid']['count'] = data['count'];
            PTerminalData['datePaymentPaid']['total'] = data['total'];
          }

          if (data.payment_status === Constants.PTERMINAL_STATUS.NOTPAID) {
            PTerminalData['datePaymentNotPaid']['count'] = data['count'];
            PTerminalData['datePaymentNotPaid']['total'] = data['total'];
          }
        });
      }

      if (!_.isEmpty(pterminal_not_paid)) {
        pterminal_not_paid.forEach((data) => {
          PTerminalData['notPaidPayment']['count'] = data['count'];
          PTerminalData['notPaidPayment']['total'] = data['total'];
        });
      }

    }

    let chartData = (chartValueType === 'count' ? chartCount : chartValue);

    return (
      <div className="payment-view screen-container">
        <Helmet>
          <title>Payment Terminal Overview</title>
        </Helmet>

        <PageHeader
          history={this.props.history} title={'Payment Terminal'}
          description={`${property.name} payment terminal status at ${AppUtil.formatDateTime(Moment(), 'datetime')}.`}
        />

        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12 mb-5">
            <div className={'row'}>
              <div className={'col-lg-6 col-sm-12 mb-3'}>
                <h6 className={'small text-uppercase text-muted'}>Day Overview ({currency})</h6>
                <div className={'card mb-3 a-class'}>
                  <div className="card-body">
                    <div className={'row'}>
                      <div
                        className={'col a-class'}
                        onClick={() => this.props.history.push(`/payment/payment-terminal/list?payment_status=paid&start_date=${AppUtil.formatDateTime(Moment(), 'date-iso')}`)}
                      >
                        <small>Paid Payment(s)</small>
                        <h5 className={'card-title mb-0 pb-0 green-cl'}>
                          {(!_.isEmpty(PTerminalData) && !_.isEmpty(PTerminalData.datePaymentPaid)) ? PTerminalData.datePaymentPaid.total : '...'}
                        </h5>
                      </div>
                      <div
                        className={'col a-class'}
                        onClick={() => this.props.history.push(`/payment/payment-terminal/list?payment_status=not-paid&start_date=${AppUtil.formatDateTime(Moment(), 'date-iso')}`)}
                      >
                        <small>Unpaid Payment(s)</small>
                        <h6 className={'card-title mb-0 pb-0 red-cl'}>
                          {(!_.isEmpty(PTerminalData) && !_.isEmpty(PTerminalData.datePaymentNotPaid)) ? PTerminalData.datePaymentNotPaid.total : '...'}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={'row'}>
                    <div
                      className={'col a-class'}
                      onClick={() => this.props.history.push('/payment/payment-terminal/list?payment_status=not-paid')}
                    >
                      <h6 className={'small text-uppercase text-muted'}>Overall ({currency})</h6>
                      <div className={'card bg-danger text-light a-class'}>
                        <div className="card-body">
                          <small>Unpaid Payment(s)</small>
                          <h6 className={'card-title mb-0 pb-0'}>
                            {(!_.isEmpty(PTerminalData) && !_.isEmpty(PTerminalData.notPaidPayment)) ? PTerminalData.notPaidPayment.total : '...'}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className={'col'}/>
                  </div>
                </div>
              </div>
              {!_.isEmpty(chartData) ?
                <div className={'col-lg-6 col-md-12 mb-3'}>
                  <ResponsiveContainer height={210}>
                    <PieChart onMouseEnter={this.onPieEnter}>
                      <Pie
                        data={chartData}
                        innerRadius={0}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {chartCount.map((data, i)=>(<Cell fill={data.color} key={i}/>))}
                      </Pie>
                      <Tooltip/>
                      <Legend onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}/>
                    </PieChart>
                  </ResponsiveContainer>
                  <div className={'text-center mt-2'}>
                    <div className="btn-group btn-group-sm" role="group">
                      <button disabled={true} className={'btn btn-outline-secondary'}>Day Overview</button>
                      <button
                        type="button"
                        onClick={() => this.setState({chartValueType: 'count'})}
                        className={`btn ${chartValueType === 'count' ? 'btn-success' : 'btn-outline-secondary'}`}>
                        Count (Nos)
                      </button>
                      <button
                        type="button"
                        onClick={() => this.setState({chartValueType: 'value'})}
                        className={`btn ${chartValueType === 'value' ? 'btn-success' : 'btn-outline-secondary'}`}>
                        Value ({currency})
                      </button>
                    </div>
                  </div>
                </div>
                :
                <div className={'col-lg-6 col-md-12 mb-3 d-flex border rounded'}>
                  <div className="align-self-center text-center mx-auto">
                    <FontAwesomeIcon icon={faCheckCircle} size={'2x'} className={'green-cl'}/>
                    <p className={'small'}>No payments today.</p>
                  </div>
                </div>
              }
            </div>
            {last_updated &&
            <p className={'small text-secondary'}>
              last updated at {AppUtil.formatDateTime(last_updated)}.
            </p>
            }
            <div className="spacer-20"/>
            <div className={'row'}>
              <div className={'col-6'}>
                <Link className="btn btn-success btn-lg btn-block" to="/payment/payment-terminal/list">
                  <FontAwesomeIcon icon={faList} size={'sm'} className={'mr-2'}/> Payment Terminal(s)
                </Link>
              </div>
              <div className={'col-6'}>
                <button onClick={() => this.setState({showPayment: true})} className="btn btn-success btn-lg btn-block">
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> New Payment
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 mb-5">
            <ul className="list-group">
              <li className="list-group-item" onClick={() => this.fetchInitData(true)}>
                <small>Payment Options(s)</small>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <button onClick={() => this.setState({showPayment: true})} className="btn btn-link p-0">
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Payment
                </button>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className="" to="/payment/payment-terminal/list">
                  <FontAwesomeIcon icon={faList} size={'sm'} className={'mr-2'}/> Payment Terminal
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <PaymentAdd
          updateResponse={(data) => this.props.history.push(`/payment/${data.hash}/`)}
          onHide={() => this.setState({showPayment: false})}
          show={this.state.showPayment}
          property={this.props.property}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      response: {},
      showPayment: false,
      chartValueType: 'count'
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PTerminalView);
