/**
 *
 * POS Category List
 */
import Moment from 'moment';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import queryString from 'query-string';

// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import AppWebUtil from '../../../lib/webUtils';
import Strings from '../../../constants/strings';

// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import SearchBar from '../../../components/forms/SearchBar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faCircleNotch, faDownload, faList, faLongArrowAltRight, faPlus } from '@fortawesome/free-solid-svg-icons';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pos: state.pos.pos,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class POSCategoryList extends Component {
  static componentName = 'POSCategoryList';
  static propTypes = {
    refreshable: PropTypes.bool,
    match: PropTypes.object.isRequired,
  };


  fetchData = (page = 1, callback, searchText, download) => {
    const {pos} = this.props;

    const urlParams = {...this.state.urlParams};
    let baseUrl = 'pos-category-settings';
    urlParams['page'] = page;
    urlParams['pos'] = pos.hash;


    if (download) {
      urlParams['download'] = download.file;
      urlParams['all_data'] = true;
      this.setState({fileDownload: true});
    }

    AppAPI.posapi.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (download) {
          let fileName = `POS-Category-${AppUtil.formatDateTime(Moment(), 'short')}.${download.file}`;
          AppWebUtil.downloadFile(res, fileName);
        } else {
          if (res.results.length > 0) {
            callback(res.results, {allLoaded: !res.next});
          } else {
            callback();
          }
        }
        this.setState({
          searching: false,
          clear: false,
          refresh: false,
          loading: false,
          fileDownload: false,
          page: page,
        });
      })
      .catch((err) => {
        if (callback) {
          callback([], {allLoaded: true});
        }
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, refresh: false, loading: false, fileDownload: false, error});
      });
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={8}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faList}/> Category </h4>
            {Strings.POSCategoryEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };

  renderRow = (data, key) => {
    const {pos} = this.props;
    return (
      <tr key={key}>
        <td>{data.name}</td>
        <td>{data.short_description || '...'}</td>
        <td>
          <span className={'badge ' + (data.active ? 'badge-success' : 'badge-secondary')}>
            {data.active ? 'Active' : 'De-active'}
          </span>
        </td>
        <td className={'align-middle'}>
          <Link to={`/settings/pos/${pos.hash}/category/${data.id}/`}>
            Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
          </Link>
        </td>
      </tr>
    );
  };

  tableHeader = () => {
    return (
      <tr>
        <th className={'data-table-cell-md'}>Name</th>
        <th className={'data-table-cell-lg'}>Description</th>
        <th className={'data-table-cell-xm'}>Status</th>
        <th className={'data-table-cell-xm'}/>
      </tr>
    );
  };

  render = () => {

    const {loading, error, fileDownload} = this.state;
    const {pos} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="pos-item-list screen-container ">
        <Helmet>
          <title>POS Categories</title>
        </Helmet>

        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12 mb-5">
            <SearchBar
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
              colSpan={4}
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className={'btn btn-link p-0'} to={`/settings/pos/${pos.hash}/category/new`}>
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Category
                </Link>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link className={'btn btn-link p-0'} to={`/settings/pos/${pos.hash}/category/new-multiple`}>
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Category (Multiple)
                </Link>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <button
                  className={'btn btn-link p-0'}
                  onClick={() => this.fetchData(1, null, null, {file: 'csv'})}
                >
                  <FontAwesomeIcon icon={fileDownload ? faCircleNotch :faDownload} size={'sm'} className={'mr-2'} spin={fileDownload}/> Download List
                </button>
              </li>
            </ul>
          </div>
        </div>


      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      fileDownload: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(POSCategoryList);
