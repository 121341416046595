/**
 * Property CoverImage / Page Cover Image update
 */
import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Img } from 'react-image';
import * as Yup from 'yup';
// Consts and Libs
import AppAPI from '../../../lib/api';
// Components
import {Formik} from 'formik';
import {Alerts} from '../../../components/ui';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
import {Checkbox, DropZone, Form, SubmitBtn} from 'react-formik-ui';

/* Component ==================================================================== */

class PropertyBookingEngineCoverImageManage extends Component {
  static componentName = 'PropertyBookingEngineCoverImageManage';

  static propTypes = {
    property: PropTypes.object,
    propertyContent: PropTypes.object,
    pageContent: PropTypes.object,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        record: null,
        loading: false,
        initialValues: {
          'bengine_cover_image': [],
        },
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  updatePage = (credentials) => {
    if (credentials && this.props.pageContent) {
      this.setState({resultMsg: {status: 'One moment...'}});
      const formData = new FormData();

      if (credentials.bengine_cover_image && credentials.bengine_cover_image[0]) {
        formData.append('image', credentials.bengine_cover_image ? credentials.bengine_cover_image[0] : null);
      }

      if (credentials.remove_file) {
        formData.append('image', new File([], ''));
      }

      AppAPI.propertyapi.patch(`property-content-page/${this.props.pageContent.id}/`, formData, true)
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                if (this.props.onSave) {
                  this.props.onSave(res);
                }
                this.props.onHide(res);
              }, 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  updateProperty = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});
      const formData = new FormData();

      if (credentials.bengine_cover_image && credentials.bengine_cover_image[0]) {
        formData.append('bengine_cover_image', credentials.bengine_cover_image ? credentials.bengine_cover_image[0] : null);
      }

      if (credentials.remove_file) {
        formData.append('bengine_cover_image', new File([], ''));
      }

      AppAPI.property.patch(`${this.props.property.id}/content-settings/`, formData, true)
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                if (this.props.onSave) {
                  this.props.onSave(res);
                }
                this.props.onHide(res);
              }, 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };


  render = () => {

    const {resultMsg, initialValues, loading} = this.state;
    const {show, propertyContent, pageContent} = this.props;

    if (!show) return null;

    const SUPPORTED_IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
    const FILE_SIZE = 5 * 1024 * 1024;

    let formValidation = Yup.object().shape({
      'bengine_cover_image': Yup.mixed()
        .test(
          'fileFormat',
          'Unsupported Format',
          value => value && value[0] ? SUPPORTED_IMAGE_FORMATS.includes(value[0].type) : true,
        )
        .test('fileSize', 'File is too large < 4Mb', value => {
          return value && value[0] ? value[0].size <= FILE_SIZE : true;
        }),
    });

    return (
      <Modal
        {...this.props}
        size={'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {pageContent ? 'Page Cover Image' : 'Property Booking Engine Cover'}
          </Modal.Title>
        </Modal.Header>
        {loading ? <Loading heightMatch={false}/> :
          <Formik
            onSubmit={(values) => pageContent ? this.updatePage(values) : this.updateProperty(values)}
            validationSchema={formValidation}
            initialValues={initialValues}>
            {() => (
              <React.Fragment>
                <Modal.Body>
                  <Form>
                    <div>
                      {propertyContent && propertyContent.bengine_cover_image && propertyContent.bengine_cover_image.cover &&
                       <div>
                         <Img
                           className={'border p-1 w-100'}
                           src={propertyContent.bengine_cover_image.cover}
                           loader={<Loading heightMatch={false}/>}
                         />
                         <hr/>
                         <Checkbox
                           className={'mr-2'}
                           name='remove_file'
                           label='Remove Image'
                           text='Check to delete image.'
                         />
                         <hr/>
                       </div>
                      }
                      {pageContent && pageContent.image && pageContent.image.cover &&
                       <div>
                         <Img
                           className={'border p-1 w-100'}
                           src={pageContent.image.cover}
                           loader={<Loading heightMatch={false}/>}
                         />
                         <hr/>
                         <Checkbox
                           className={'mr-2'}
                           name='remove_file'
                           label='Remove Image'
                           text='Check to delete image.'
                         />
                         <hr/>
                       </div>
                      }
                    </div>
                    <div className={'row'}>
                      <div className={'col-sm-12 form-group'}>
                        <p className={'text-muted'}>
                          Uploaded image will be converted into two resolutions <code>1920 x 1080</code> and <code>960 x
                          540</code>. Upload image with aspect ratio of <code>16:9</code> for better appearance.
                        </p>
                        <DropZone
                          name='bengine_cover_image'
                          label='File upload'
                          placeholder='Drop files here, or click to select files.'
                          hint={'Upload a jpg / jpeg / png file with a min dimension of 1920 x 1080 px and size < 5MB'}
                          multiple={false}
                        />
                      </div>
                    </div>
                  </Form>

                </Modal.Body>
                <Modal.Footer className={'d-block'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <div className={'row'}>
                    <div className={'col-6'}>
                      <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                      </button>
                    </div>
                    <div className={'col-6'}>
                      <SubmitBtn className={'btn btn-success btn-lg btn-block'}>
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/>
                        Save
                      </SubmitBtn>
                    </div>
                  </div>
                </Modal.Footer>
              </React.Fragment>
            )}
          </Formik>
        }
      </Modal>
    );
  };


  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: false,
      record: null,
      initialValues: {},
    };
  }
}

/* Export Component ==================================================================== */
export default PropertyBookingEngineCoverImageManage;
