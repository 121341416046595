/**
 * Guest Reducer
 *
 */

// Set initial state
const initialState = {
  guest: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
  case 'SET_GUEST': {
    let guest = [];
    if (action.data && typeof action.data === 'object') {
      guest = action.data;
    }
    return {
      ...state,
      guest,
    };
  }
  default:
    return state;
  }
};
