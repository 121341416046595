/**
 * Pos
 *  availability details
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import queryString from 'query-string';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants, Strings} from '../../../constants';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFile, faSync, faTimes} from '@fortawesome/free-solid-svg-icons';
import POSActiveOrdersListView from '../components/POSActiveOrdersListView';
import ListView from '../../../components/general/ListView';

/* Component ==================================================================== */

class POSActiveOrdersView extends Component {
  static componentName = 'POSActiveOrdersView';

  static propTypes = {
    posDetails: PropTypes.object.isRequired,
    posOrderStatus: PropTypes.number,
    OrderStatus: PropTypes.number,
    history: PropTypes.object.isRequired
  };

  static defaultProps = {
    posOrderStatus: Constants.POS_ORDER_STATUS.OPEN,
    OrderStatus: null
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  renderRow = (data, key) => {
    const {posDetails} = this.props;
    return (
      <div key={key} className={'col-sm-4 col-md-3 col-6 p-3'}>
        <POSActiveOrdersListView
          data={data}
          key={key}
          action={()=>this.props.history.push(`/point-of-sale/${posDetails.hash}/pos-order/${data.hash}/`)}
        />
      </div>
    );
  };

  emptyView = () => {
    return (
      <div className={'table-list-empty'}>
        <h4><FontAwesomeIcon icon={faFile}/> No Order </h4>
        {Strings.posOrderListEmptyText.map((data, i) => <p key={i}>{data}</p>)}
      </div>
    );
  };

  fetchData = (page = 1, callback) => {
    const {posDetails, posOrderStatus, OrderStatus} = this.props;

    const urlParams = {};
    let baseUrl = 'pos-order';
    urlParams['page'] = page;
    urlParams['pos'] = posDetails.hash;
    urlParams['pos_order_status'] = posOrderStatus;
    if (OrderStatus !== null) {
      urlParams['order_status'] = OrderStatus;
    }

    AppAPI.posapi.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (res.results.length > 0) {
          callback(res.results, {allLoaded: !res.next});
        } else {
          callback();
        }
        this.setState({
          searching: false,
          clear: false,
          refresh: false,
          loading: false,
          fileDownload: false,
          page: page,
        });
      })
      .catch((err) => {
        if (callback) {
          callback([], {allLoaded: true});
        }
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, refresh: false, loading: false, fileDownload: false, error});
      });
  };


  render = () => {
    const {show, posOrderStatus} = this.props;
    if (!show) return <React.Fragment/>;

    return (
      <Modal
        {...this.props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {posOrderStatus === Constants.POS_ORDER_STATUS.OPEN ? 'Open / Active' : 'Hold'}  Tab(s)
          </Modal.Title>
        </Modal.Header>
        <React.Fragment>
          <Modal.Body>
            <div className={'row mb-3'}>
              <div className={'col-12 col-sm-9'}>
                <ul className="list-inline list-item-mb-1">
                  <li className="list-inline-item">
                    <button
                      className={'btn btn-outline-secondary btn-sm'}
                      onClick={() => this.setState({refresh: true})}
                    >
                      <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className={'row'}>
                  <ListView
                    rowView={this.renderRow}
                    refresh={this.state.refresh}
                    onFetch={this.fetchData}
                    firstLoader={true}
                    pagination
                    emptyView={this.emptyView}
                    elementClass={'col-12 w-100'}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className={'d-block'}>
            <div className={'row'}>
              <div className={'col'}>
                <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                  <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                </button>
              </div>
            </div>
          </Modal.Footer>
        </React.Fragment>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: true,
    };
  }
}

/* Export Component ==================================================================== */
export default POSActiveOrdersView;
