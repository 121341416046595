/**
 * Order analytics overview
 *  Order analytics overview
 *
 */

import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
// Components
import { AnalyticsCard } from '../../../../components/analytics';
import PageHeader from '../../../../components/dashboard/PageHeader';
import { Constants, Strings } from '../../../../constants';
// Consts and Libs
import AppUtil from '../../../../lib/util';

/* Component ==================================================================== */
class OAOverview extends Component {
  static componentName = 'OAOverview';

  static propTypes = {
    property: PropTypes.object.isRequired,
    orderData: PropTypes.object.isRequired,
  };


  render = () => {
    const {orderData, property} = this.props;

    const totalOrders = (AppUtil.sumArray(Object.values(orderData.orderTotal), 'float') - (orderData.orderTotal[Constants.ORDER_STATUS.CANCELLED] || 0)).toFixed(2);
    const totalOrdersCount = AppUtil.sumArray(Object.values(orderData.orderCount)) - (orderData.orderCount[Constants.ORDER_STATUS.CANCELLED] || 0);

    const paidOrdersPercentage = AppUtil.calculatePercentage(
      AppUtil.sumArray(Object.values(orderData.orderPaidPayment), 'float'),
      totalOrders
    );

    const settlementPercentage = AppUtil.calculatePercentage(
      AppUtil.sumArray(Object.values(orderData.orderSettledPayment), 'float'),
      AppUtil.sumArray(Object.values(orderData.orderPaidPayment), 'float'),
    );

    const itemDiscountTotal = (
      AppUtil.sumArray(Object.values(orderData.orderDiscountTotal), 'float') -
      (orderData.orderDiscountTotal[Constants.ORDER_STATUS.CANCELLED] || 0)
    );

    const orderLevelDiscountTotal = (
      (AppUtil.sumArray(Object.values(orderData.orderDiscount), 'float') - (orderData.orderDiscount[Constants.ORDER_STATUS.CANCELLED] || 0)) +
      (AppUtil.sumArray(Object.values(orderData.orderDiscountPostPayment), 'float') - (orderData.orderDiscountPostPayment[Constants.ORDER_STATUS.CANCELLED] || 0))
    );

    const orderNCCompTotal = (
      (AppUtil.sumArray(Object.values(orderData.orderNoChargeTotal), 'float') - (orderData.orderNoChargeTotal[Constants.ORDER_STATUS.CANCELLED] || 0)) +
      (AppUtil.sumArray(Object.values(orderData.orderComplimentaryTotal), 'float') - (orderData.orderComplimentaryTotal[Constants.ORDER_STATUS.CANCELLED] || 0))
    );

    const orderExternalTotal = (
      ((AppUtil.sumArray(Object.values(orderData.orderExternalTotal), 'float') - (orderData.orderExternalTotal[Constants.ORDER_STATUS.CANCELLED] || 0))) +
      (AppUtil.sumArray(Object.values(orderData.orderExternalTax), 'float') - (orderData.orderExternalTax[Constants.ORDER_STATUS.CANCELLED] || 0))
    );

    const orderComponents = [
      {
        'icon': '+',
        'name': 'Sub Total',
        'key': 'orderSubTotal',
      },
      {
        'icon': '-',
        'name': 'No Charge',
        'key': 'orderNoChargeTotal',
      },
      {
        'icon': '-',
        'name': 'Complimentary',
        'key': 'orderComplimentaryTotal',
      },
      {
        'icon': '-',
        'name': 'Discount ( Pre Tax )',
        'key': 'orderDiscountTotalPreTax',
        'description': 'Discount applied before tax on item',
      },
      {
        'icon': '+',
        'name': 'Tax',
        'key': 'orderTaxTotal',
        'description': 'Tax applied on items.'
      },
      {
        'icon': '-',
        'name': 'Discount ( Post Tax )',
        'key': 'orderDiscountTotalPostTax',
        'description': 'Discount applied post tax on item',
      },
      {
        'icon': '-',
        'name': 'Discount ( Order Level )',
        'key': 'orderDiscount',
        'description': 'Discount applied order',
      },
      {
        'icon': '-',
        'name': 'Discount ( Post Payment )',
        'key': 'orderDiscountPostPayment',
        'description': 'Discount applied on order post payment or lock',
      },
      {
        'icon': '+',
        'name': 'Tax (Order Level)',
        'key': 'orderTax',
        'description': 'Tax applied on order directly rather than item level, Channel manager / OTA',
      },
      {
        'icon': '+',
        'name': 'Service Charge',
        'key': 'orderServiceCharge',
      },
      {
        'name': 'Rounding',
        'key': 'orderTotalRounding',
      },
      // Total hard coded for custom styling
      // {
      //   'name': 'Total',
      //   'key': 'orderTotal',
      // },
    ];

    return (
      <>
        <PageHeader
          subHeader={true}
          title={'Sales Overview'}
          description={'Total Sales and its related details in the selected range.'}
        />

        <div className={'row mb-2'}>
          <div className={'col-12 col-lg-6 mb-3'}>
            <div className={'card'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col '}>
                    <p className={'text-muted mb-1'}>Total Active Order(s)</p>
                    <h3 className={'card-title mb-0 pb-0'}>
                      {`${property.currency} ${totalOrders}`}
                    </h3>
                    <p className={'text-muted mb-1'}>
                      {totalOrdersCount} active entries.
                    </p>
                  </div>
                </div>
                <hr/>
                <div className={'row'}>
                  <div className={'col '}>
                    <p className={'text-muted mb-1'}>Total Paid</p>
                    <h5 className={'card-title mb-0 pb-0 green-cl'}>
                      {`${property.currency} ${AppUtil.sumArray(Object.values(orderData.orderPaidPayment), 'float')}`}
                    </h5>
                  </div>
                  <div className={'col'}>
                    <p className={'text-muted mb-1'}>Pending Payment</p>
                    <h5 className={'card-title mb-0 pb-0 red-cl'}>
                      {`${property.currency} ${(totalOrders - AppUtil.sumArray(Object.values(orderData.orderPaidPayment), 'float')).toFixed(2)}`}
                    </h5>
                  </div>
                </div>
                <div className="progress mt-3 red-bg">
                  <div
                    className="progress-bar green-bg"
                    style={{width: `${paidOrdersPercentage}%`}}
                    aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"/>
                </div>
                <div className={'row'}>
                  <div className={'col-8'}>
                    <p className={'text-muted mb-0 mt-1 small'}>
                      Count - Paid : {totalOrdersCount - (orderData.orderCount[Constants.ORDER_STATUS.NOTPAID] || 0)} |
                      Pending : {(orderData.orderCount[Constants.ORDER_STATUS.NOTPAID] || 0)}
                    </p>
                  </div>
                  <div className={'text-right col'}>
                    <p className={'text-muted mb-0 mt-1 small'}>{paidOrdersPercentage}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={'col-12 col-lg-6 mb-3'}>
            <div className={'card'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col '}>
                    <p className={'text-muted mb-1'}>Total Paid Order(s)</p>
                    <h3 className={'card-title mb-0 pb-0 green-cl'}>
                      {`${property.currency} ${AppUtil.sumArray(Object.values(orderData.orderPaidPayment), 'float')}`}
                    </h3>
                    <p className={'text-muted mb-1'}>
                      {totalOrdersCount - (orderData.orderCount[Constants.ORDER_STATUS.NOTPAID] || 0)} paid entries.
                    </p>
                  </div>
                </div>
                <hr/>
                <div className={'row'}>
                  <div className={'col '}>
                    <p className={'text-muted mb-1'}>Settled</p>
                    <h5 className={'card-title mb-0 pb-0 green-cl'}>
                      {`${property.currency} ${AppUtil.sumArray(Object.values(orderData.orderSettledPayment), 'float')}`}
                    </h5>
                  </div>
                  <div className={'col'}>
                    <p className={'text-muted mb-1'}>Pending Settlement</p>
                    <h5 className={'card-title mb-0 pb-0 yellow-cl'}>
                      {`${property.currency} ${(AppUtil.sumArray(Object.values(orderData.orderPaidPayment), 'float') - AppUtil.sumArray(Object.values(orderData.orderSettledPayment))).toFixed(2)}`}
                    </h5>
                  </div>
                </div>
                <div className="progress mt-3 yellow-bg">
                  <div
                    className="progress-bar green-bg"
                    style={{width: `${settlementPercentage}%`}}
                    aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"/>
                </div>
                <div className={'row'}>
                  <div className={'col-8'}>
                    <p className={'text-muted mb-0 mt-1 small'}>

                    </p>
                  </div>
                  <div className={'text-right col'}>
                    <p className={'text-muted mb-0 mt-1 small'}>{settlementPercentage}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={'row mb-2'}>
          <div className={'col-12 col-lg-6 mb-3'}>
            <div className={'row'}>
              <div className={'col'}>
                <div className={'card '}>
                  <div className="card-body ">
                    <p className={'mb-1 text-muted'}>Overall</p>
                    <h5>
                      {AppUtil.sumArray(Object.values(orderData.orderCount))}
                    </h5>
                    <p className={'text-muted mb-1'}>
                      order entries.
                    </p>
                  </div>
                </div>
              </div>
              <div className={'col'}>
                <div className={'card '}>
                  <div className="card-body ">
                    <p className={'text-muted mb-1'}>Cancelled</p>
                    <h5 className={'grey-cl'}>
                      {`${property.currency} ${orderData.orderTotal[Constants.ORDER_STATUS.CANCELLED] || 0}`}
                    </h5>
                    <p className={'text-muted mb-1'}>
                      {orderData.orderCount[Constants.ORDER_STATUS.CANCELLED] || 0} order entries.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={'col-12 col-lg-6 mb-3'}>
            <div className={'row'}>
              <div className={'col'}>
                <div className={'card '}>
                  <div className="card-body ">
                    <p className={'mb-1 text-muted'}>Partial Paid</p>
                    <h5 className={'orange-cl'}>
                      {`${property.currency} ${orderData.orderTotal[Constants.ORDER_STATUS.PARTPAID] || 0}`}
                    </h5>
                    <p className={'text-muted mb-1'}>
                      {orderData.orderCount[Constants.ORDER_STATUS.PARTPAID] || 0} order entries.
                    </p>
                  </div>
                </div>
              </div>
              <div className={'col'}>
                <div className={'card '}>
                  <div className="card-body ">
                    <p className={'text-muted mb-1'}>Refunded</p>
                    <h5 className={'purple-dark-cl'}>
                      {`${property.currency} ${AppUtil.sumArray(Object.values(orderData.orderRefundedPayment), 'float')}`}
                    </h5>
                    <p className={'text-muted mb-1'}>
                      {orderData.orderCount[Constants.ORDER_STATUS.PART_REFUNDED] || 0} Partial
                      / {orderData.orderCount[Constants.ORDER_STATUS.REFUNDED] || 0} Full.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h6>Discount Details</h6>
        <div className={'row mb-2'}>
          <div className={'col-12 col-lg-6 mb-3'}>
            <AnalyticsCard
              mainCard={{
                className: 'green-light-cl',
                title: 'Discount ( Item Level )',
                value: `${property.currency} ${itemDiscountTotal}`
              }}
              subCardRight={{
                title: 'Discount Pre Tax',
                value: `${property.currency} ${(AppUtil.sumArray(Object.values(orderData.orderDiscountTotalPreTax), 'float') - (orderData.orderDiscountTotalPreTax[Constants.ORDER_STATUS.CANCELLED] || 0)).toFixed(2)}`
              }}
              subCardLeft={{
                title: 'Discount Post Tax',
                value: `${property.currency} ${(AppUtil.sumArray(Object.values(orderData.orderDiscountTotalPostTax), 'float') - (orderData.orderDiscountTotalPostTax[Constants.ORDER_STATUS.CANCELLED] || 0)).toFixed(2)}`
              }}
            />
          </div>

          <div className={'col-12 col-lg-6 mb-3'}>
            <AnalyticsCard
              mainCard={{
                className: 'green-light-cl',
                title: 'Discount ( Order Level )',
                value: `${property.currency} ${(orderLevelDiscountTotal).toFixed(2)}`
              }}
              subCardRight={{
                title: 'Order Discount',
                value: `${property.currency} ${(AppUtil.sumArray(Object.values(orderData.orderDiscount), 'float') - (orderData.orderDiscount[Constants.ORDER_STATUS.CANCELLED] || 0)).toFixed(2)}`
              }}
              subCardLeft={{
                title: 'Discount Post Payment',
                value: `${property.currency} ${(AppUtil.sumArray(Object.values(orderData.orderDiscountPostPayment), 'float') - (orderData.orderDiscountPostPayment[Constants.ORDER_STATUS.CANCELLED] || 0)).toFixed(2)}`
              }}
            />
          </div>
        </div>

        <h6>NC / Comp Details</h6>
        <div className={'row mb-2'}>
          <div className={'col-12 col-lg-6 mb-3'}>
            <AnalyticsCard
              mainCard={{
                title: 'Total ( NC/Comp )',
                className: 'blue-cl',
                value: `${property.currency} ${(orderNCCompTotal).toFixed(2)}`
              }}
              subCardRight={{
                title: 'Complimentary',
                value: `${property.currency} ${(AppUtil.sumArray(Object.values(orderData.orderComplimentaryTotal), 'float') - (orderData.orderComplimentaryTotal[Constants.ORDER_STATUS.CANCELLED] || 0)).toFixed(2)}`
              }}
              subCardLeft={{
                title: 'No Charge',
                value: `${property.currency} ${(AppUtil.sumArray(Object.values(orderData.orderNoChargeTotal), 'float') - (orderData.orderNoChargeTotal[Constants.ORDER_STATUS.CANCELLED] || 0)).toFixed(2)}`
              }}
            />
          </div>
        </div>

        <h6>Tax & Service Charges</h6>
        <div className={'row mb-2'}>
          <div className={'col-12 col-md-9 mb-3'}>
            <div className={'card '}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col-6 col-lg-3'}>
                    <p className={'text-muted mb-1'}>Item Tax</p>
                    <h5 className={'card-title mb-0 pb-0'}>
                      {`${property.currency} ${(AppUtil.sumArray(Object.values(orderData.orderTaxTotal), 'float') - (orderData.orderTaxTotal[Constants.ORDER_STATUS.CANCELLED] || 0)).toFixed(2)}`}
                    </h5>
                  </div>
                  <div className={'col-6 col-lg-3'}>
                    <p className={'text-muted mb-1'}>Order Tax</p>
                    <h5 className={'card-title mb-0 pb-0'}>
                      {`${property.currency} ${(AppUtil.sumArray(Object.values(orderData.orderTax), 'float') - (orderData.orderTax[Constants.ORDER_STATUS.CANCELLED] || 0)).toFixed(2)}`}
                    </h5>
                  </div>
                  <div className={'col-6 col-lg-3'}>
                    <p className={'text-muted mb-1'}>Service Charge</p>
                    <h5 className={'card-title mb-0 pb-0'}>
                      {`${property.currency} ${(AppUtil.sumArray(Object.values(orderData.orderServiceCharge), 'float') - (orderData.orderServiceCharge[Constants.ORDER_STATUS.CANCELLED] || 0)).toFixed(2)}`}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h6>External Data Sources ( OTA / Channel Manager )</h6>
        <div className={'row mb-2'}>
          <div className={'col-12 col-md-9 mb-3'}>
            <div className={'card '}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col-6 col-lg-3'}>
                    <p className={'text-muted mb-1'}>Total</p>
                    <h5 className={'card-title mb-0 pb-0'}>
                      {`${property.currency} ${(AppUtil.sumArray(Object.values(orderData.orderExternalTotal), 'float') - (orderData.orderExternalTotal[Constants.ORDER_STATUS.CANCELLED] || 0)).toFixed(2)}`}
                    </h5>
                  </div>
                  <div className={'col-6 col-lg-3'}>
                    <p className={'text-muted mb-1'}>Commission</p>
                    <h5 className={'card-title mb-0 pb-0'}>
                      {`${property.currency} ${(AppUtil.sumArray(Object.values(orderData.orderExternalCommission), 'float') - (orderData.orderExternalCommission[Constants.ORDER_STATUS.CANCELLED] || 0)).toFixed(2)}`}
                    </h5>
                  </div>
                  <div className={'col-6 col-lg-3'}>
                    <p className={'text-muted mb-1'}>Tax</p>
                    <h5 className={'card-title mb-0 pb-0'}>
                      {`${property.currency} ${(AppUtil.sumArray(Object.values(orderData.orderExternalTax), 'float') - (orderData.orderExternalTax[Constants.ORDER_STATUS.CANCELLED] || 0)).toFixed(2)}`}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={'row mb-2'}>
          <div className={'col-12 col-lg-6'}>
            <h6>Order Bifurcation</h6>
            <table className={'table table-bordered'}>
              <thead>
                <tr>
                  <th>Component</th>
                  <th>Value <small>({property.currency})</small></th>
                </tr>
              </thead>
              <tbody>
                {orderComponents.map((data, i) => (
                  <tr className={'tr-align-middle'} key={i}>
                    <td className={'data-table-cell-lg'}>
                      {data.name}
                      {data.description && <p className={'text-muted small mb-0'}>{data.description}</p>}
                    </td>
                    <td className={'text-center'}>
                      {(AppUtil.sumArray(Object.values(orderData[data.key]), 'float') - (orderData[data.key][Constants.ORDER_STATUS.CANCELLED] || 0)).toFixed(2)}
                      {data.icon && (
                        <FontAwesomeIcon
                          className={`float-right ${data.icon === '+' ? 'green-cl' : 'red-cl'}`}
                          icon={data.icon === '+' ? faPlus : faMinus}
                        />
                      )}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td><strong>Total</strong></td>
                  <td className={'text-center'}><strong>{totalOrders}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={'col-12 col-lg-6'}>
            <h6>Paid Payment Bifurcation</h6>
            <table className={'table table-bordered mb-5'}>
              <thead>
                <tr>
                  <th>Component</th>
                  <th>Value <small>({property.currency})</small></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={'data-table-cell-md'}>
                  Pending ( A )
                  </td>
                  <td className={'text-center red-cl'}>
                    {(totalOrders - AppUtil.sumArray(Object.values(orderData.orderPaidPayment), 'float')).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className={'data-table-cell-md'}>
                  Paid ( B )
                  </td>
                  <td className={'text-center green-cl'}>
                    {AppUtil.sumArray(Object.values(orderData.orderPaidPayment), 'float')}
                  </td>
                </tr>
                <tr>
                  <td><strong>Total</strong> (A + B)</td>
                  <td className={'text-center'}><strong>{totalOrders}</strong></td>
                </tr>
              </tbody>
            </table>

            <h6>Payment Bifurcation</h6>
            <table className={'table table-bordered'}>
              <thead>
                <tr>
                  <th>Component</th>
                  <th>Value <small>({property.currency})</small></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={'data-table-cell-md'}>
                  Paid (A)
                  </td>
                  <td className={'text-center green-cl'}>
                    {AppUtil.sumArray(Object.values(orderData.orderPaidPayment), 'float')}
                  </td>
                </tr>
                <tr>
                  <td className={'data-table-cell-md'}>
                  Pending Settlement (B)
                  </td>
                  <td className={'text-center yellow-cl'}>
                    {(AppUtil.sumArray(Object.values(orderData.orderPaidPayment), 'float') - AppUtil.sumArray(Object.values(orderData.orderSettledPayment))).toFixed(2)}
                    <FontAwesomeIcon className={'float-right red-cl'} icon={faMinus}/>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className={'text-center'}>Settled Payments</td>
                </tr>
                <tr>
                  <td className={'data-table-cell-md'}>
                  Settled (A - B)
                  </td>
                  <td className={'text-center green-cl'}>
                    {AppUtil.sumArray(Object.values(orderData.orderSettledPayment), 'float')}
                  </td>
                </tr>
                <tr>
                  <td className={'data-table-cell-md'}>
                  Refunded (C)
                  </td>
                  <td className={'text-center purple-dark-cl'}>
                    {AppUtil.sumArray(Object.values(orderData.orderRefundedPayment), 'float')}
                    <FontAwesomeIcon className={'float-right red-cl'} icon={faMinus}/>
                  </td>
                </tr>
                <tr>
                  <td className={'data-table-cell-md'}>
                    <strong>Net Received</strong> (A - B - C)
                  </td>
                  <td className={'text-center'}>
                    <strong>
                      {(AppUtil.sumArray(Object.values(orderData.orderSettledPayment), 'float') - AppUtil.sumArray(Object.values(orderData.orderRefundedPayment), 'float')).toFixed(2)}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={'row mb-2'}>
          <div className={'col-12 col-lg-6'}>
            <h6>External Data Bifurcation</h6>
            <table className={'table table-bordered'}>
              <thead>
                <tr>
                  <th>Component</th>
                  <th>Value <small>({property.currency})</small></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={'data-table-cell-md'}>
                  Total (A)
                  </td>
                  <td className={'text-center'}>
                    {`${property.currency} ${(AppUtil.sumArray(Object.values(orderData.orderExternalTotal), 'float') - (orderData.orderExternalTotal[Constants.ORDER_STATUS.CANCELLED] || 0)).toFixed(2)}`}
                  </td>
                </tr>
                <tr>
                  <td className={'data-table-cell-md'}>
                  Tax (B)
                  </td>
                  <td className={'text-center'}>
                    {`${property.currency} ${(AppUtil.sumArray(Object.values(orderData.orderExternalTax), 'float') - (orderData.orderExternalTax[Constants.ORDER_STATUS.CANCELLED] || 0)).toFixed(2)}`}
                  </td>
                </tr>
                <tr>
                  <td className={'data-table-cell-md'}>
                    <strong>Net ( A + B )</strong>
                  </td>
                  <td className={'text-center'}>
                    <strong>
                      {`${property.currency} ${orderExternalTotal.toFixed(2)}`}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className={'data-table-cell-md'}>
                  Commission (C)
                  </td>
                  <td className={'text-center'}>
                    {`${property.currency} ${(AppUtil.sumArray(Object.values(orderData.orderExternalCommission), 'float') - (orderData.orderExternalCommission[Constants.ORDER_STATUS.CANCELLED] || 0)).toFixed(2)}`}
                  </td>
                </tr>
                <tr>
                  <td className={'data-table-cell-md'}>
                    <strong>Net ( A + B - C )</strong>
                  </td>
                  <td className={'text-center'}>
                    <strong>
                      {`${property.currency} ${(orderExternalTotal - (AppUtil.sumArray(Object.values(orderData.orderExternalCommission), 'float') - (orderData.orderExternalCommission[Constants.ORDER_STATUS.CANCELLED] || 0))).toFixed(2)}`}
                    </strong>
                  </td>
                </tr>
                <tr className={'text-muted'}>
                  <td className={'data-table-cell-md small'}>
                  Net ( A - C )
                  </td>
                  <td className={'text-center small'}>
                    {`${property.currency} ${(
                      (AppUtil.sumArray(Object.values(orderData.orderExternalTotal), 'float') - (orderData.orderExternalTotal[Constants.ORDER_STATUS.CANCELLED] || 0)) -
                    (AppUtil.sumArray(Object.values(orderData.orderExternalCommission), 'float') - (orderData.orderExternalCommission[Constants.ORDER_STATUS.CANCELLED] || 0))
                    ).toFixed(2)}`}
                  </td>
                </tr>
              </tbody>
            </table>
            <p className={'small text-muted'}>{Strings.otaAnalyticsDisclaimer}</p>
          </div>
        </div>
      </>
    );
  };
}

/* Export Component ==================================================================== */
export default OAOverview;

