/**
 * PTerminalInfo
 *  Payment Terminal transaction actions
 *
 */

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
// Consts and Libs
import AppAPI from '../../../../lib/api';
import AppUtil from '../../../../lib/util';
import {Constants} from '../../../../constants';
// Components
import {Alerts} from '../../../../components/ui';
import {confirmAlert} from '../../../../components/general/ConfirmAlert';
import TimeCounterView from '../../../../components/dashboard/TimeCounterView';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClock, faSync} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */
class PTerminalAction extends Component {
  static componentName = 'PTerminalAction';

  static propTypes = {
    pterminal: PropTypes.object.isRequired,
    allowVoid: PropTypes.bool,
    action: PropTypes.func,
  };

  static defaultProps = {
    allowVoid: true
  }

  componentDidMount() {
    const {pterminal} = this.props;

    if (pterminal.refund_status === Constants.PTERMINAL_STATUS.TERMINAL_RESPONSE || pterminal.payment_status === Constants.PTERMINAL_STATUS.TERMINAL_RESPONSE) {
      this.interval = setInterval(() => {
        this.statusCheckCache();
      }, 10000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }


  statusCheckCache = () => {
    const {pterminal} = this.props;
    this.setState({cacheCheckLoading: true});
    AppAPI.pterminal.get(`${pterminal.hash}/cache`)
      .then(res => {
        this.setState({cacheCheckLoading: false});
        if (res.refund_status !== pterminal.refund_status || res.payment_status !== pterminal.payment_status) {
          if (this.props.action) {
            this.props.action(res);
          }
        }
      })
      .catch(() => this.setState({cacheCheckLoading: false}));
  }

  statusCheck = (pterminal) => {
    this.setState({resultMsg: {status: 'One moment...'}});
    AppAPI.pterminal.post(`${pterminal.hash}/status`, {})
      .then(res => {
        this.setState({resultMsg: {success: 'Success'}});
        if (this.props.action) {
          this.props.action(res);
        }
        setTimeout(() => this.setState({resultMsg: {success: ''}}), 500);
      })
      .catch(err => {
        this.setState({resultMsg: {error: AppAPI.handleError(err)}, loading: false});
      });
  };

  updateTransaction = (pterminal, payload) => {
    this.setState({resultMsg: {status: 'One moment...'}});
    AppAPI.pterminal.patch(`${pterminal.hash}/`, payload)
      .then(res => {
        let response = {pterminal: res};
        if (res.payment_status === Constants.PTERMINAL_STATUS.CANCELLED) {
          response = [];
        }
        this.setState({resultMsg: {success: 'Success'}});
        if (this.props.action) {
          this.props.action(res);
        }
        setTimeout(() => this.setState({response, resultMsg: {success: ''}}), 500);
      })
      .catch(err => {
        this.setState({resultMsg: {error: AppAPI.handleError(err)}, loading: false});
      });
  };

  escalatedUpdateTransaction = (pterminal, payload) => {
    this.setState({resultMsg: {status: 'One moment...'}});
    AppAPI.pterminalapi.patch(`pterminal-escalated/${pterminal.hash}/`, payload)
      .then(res => {
        this.setState({resultMsg: {success: 'Success'}});
        if (this.props.action) {
          this.props.action(res);
        }
        setTimeout(() => this.setState({resultMsg: {success: ''}}), 500);
      })
      .catch(err => {
        this.setState({resultMsg: {error: AppAPI.handleError(err)}, loading: false});
      });
  };

  render = () => {
    const {pterminal, allowVoid} = this.props;
    const {resultMsg, cacheCheckLoading} = this.state;


    return (
      <div className={'mb-3'}>
        <div className={'mb-3'}>
          <p><strong>Payment Transaction Details</strong></p>
          <hr/>
          <table className={'table table-borderless table-sm'}>
            <tbody>
              {pterminal.invoice_no_add &&
            <tr>
              <td className={'w-25 text-muted'}>Invoice Postfix</td>
              <td>{pterminal.invoice_no_add || '...'}</td>
              <td/>
            </tr>
              }
              {pterminal.gateway_secondary_id &&
            <tr>
              <td className={'w-25 text-muted'}>Gateway Transaction No</td>
              <td>{pterminal.gateway_secondary_id}</td>
              <td/>
            </tr>
              }
              <tr>
                <td className={'w-25'}><strong>Transaction ID</strong></td>
                <td>{pterminal.gateway_unique_id || '...'}</td>
                <td className={'w-25 text-right'}>
                  <button
                    className="btn btn-link m-0 p-0"
                    disabled={!pterminal.gateway_unique_id}
                    onClick={() => {
                      confirmAlert({
                        title: 'Check Payment Status',
                        message: 'This will recheck the payment status with gateway.',
                        buttons: [
                          {
                            className: 'btn-success',
                            label: 'Check',
                            onClick: () => this.statusCheck(pterminal),
                          },
                          {
                            className: 'btn-secondary',
                            label: 'Exit',
                          },
                        ],
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faSync} className={'mr-2'}/> Check Payment Status
                  </button>
                  {pterminal.payment_status_time &&
                  <p className={'small text-muted mb-0 position-absolute'}>
                    Last update : {AppUtil.formatDateTime(pterminal.payment_status_time)}
                  </p>
                  }
                </td>
              </tr>
              <tr>
                <td className={'w-25'}><strong>Total</strong></td>
                <td><strong>{`${pterminal.currency} ${pterminal.total}`}</strong></td>
                <td/>
              </tr>
              {parseFloat(pterminal.total_settled) > 0 &&
            <tr>
              <td className={'w-25'}><strong>Total Settled</strong></td>
              <td><strong>{`${pterminal.currency} ${pterminal.total_settled}`}</strong></td>
              <td/>
            </tr>
              }
              {(!_.isEmpty(pterminal.attributes) && pterminal.attributes.settled_user) &&
            <tr>
              <td className={'w-25'}>Settled by</td>
              <td><strong>{pterminal.attributes.settled_user}</strong></td>
              <td/>
            </tr>
              }
              {pterminal.payment_message &&
            <tr>
              <td className={'w-25'}>Gateway Message</td>
              <td>{pterminal.payment_message}</td>
              <td/>
            </tr>
              }
            </tbody>
          </table>
        </div>
        {(pterminal.refund_status !== Constants.PTERMINAL_STATUS.INITIAL) &&
        <React.Fragment>
          <div className={'mb-3'}>
            <p><strong>Refund Transaction Details</strong></p>
            <hr/>
            <table className={'table table-borderless table-sm'}>
              <tbody>
                <tr>
                  <td className={'w-25'}><strong>Transaction ID</strong></td>
                  <td>{pterminal.gateway_refund_id || '...'}</td>
                  <td className={'w-25 text-right'}>
                    <button
                      className="btn btn-link m-0 p-0"
                      disabled={!pterminal.gateway_unique_id}
                      onClick={() => {
                        confirmAlert({
                          title: 'Check Refund Status',
                          message: 'This will recheck the refund status with gateway.',
                          buttons: [
                            {
                              className: 'btn-success',
                              label: 'Check',
                              onClick: () => this.statusCheck(pterminal),
                            },
                            {
                              className: 'btn-secondary',
                              label: 'Exit',
                            },
                          ],
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faSync} className={'mr-2'}/> Check Refund Status
                    </button>
                    {pterminal.refund_status_time &&
                    <p className={'small text-muted mb-0 position-absolute'}>
                      Last update : {AppUtil.formatDateTime(pterminal.refund_status_time)}
                    </p>
                    }
                  </td>
                </tr>
                <tr>
                  <td className={'w-25'}><strong>Total Refunded</strong></td>
                  <td><strong>{`${pterminal.currency} ${pterminal.total_refunded}`}</strong></td>
                  <td/>
                </tr>
                {(!_.isEmpty(pterminal.attributes) && pterminal.attributes.refunded_user) &&
              <tr>
                <td className={'w-25'}>Refunded by</td>
                <td><strong>{pterminal.attributes.refunded_user}</strong></td>
                <td/>
              </tr>
                }
                {pterminal.refund_message &&
              <tr>
                <td className={'w-25'}>Gateway Message</td>
                <td>{pterminal.refund_message}</td>
                <td/>
              </tr>
                }
              </tbody>
            </table>
          </div>
        </React.Fragment>
        }
        <Alerts
          status={resultMsg.status}
          success={resultMsg.success}
          error={resultMsg.error}
        />
        {(pterminal.refund_status === Constants.PTERMINAL_STATUS.TERMINAL_RESPONSE || pterminal.payment_status === Constants.PTERMINAL_STATUS.TERMINAL_RESPONSE) &&
        <div className={'border rounded p-3 mt-3'}>
          <div className={'row'}>
            <div className={'col-1 text-center'}>
              <FontAwesomeIcon icon={faClock} size={'3x'} className={'green-cl'}/>
            </div>
            <div className={'col'}>
              <h5 className={'mb-1'}>Awaiting Terminal Response</h5>
              <p className={'mb-1 text-muted'}>Please complete the next steps on payment terminal.</p>
            </div>
          </div>
          <hr/>
          <div className={'row'}>
            <div className={'col-12 col-md-6'}>
              <p className={'text-muted mb-0'}>Instructions</p>
              <ol className={'pl-4'}>
                <li>Open the payment terminal <strong>
                  {!_.isEmpty(pterminal.attributes) && `${pterminal.attributes.pterminal_device_name} [${pterminal.attributes.pterminal_device_serial} ]` }.</strong>
                </li>
                <li>Select cloud transaction with Transaction ID <strong>{pterminal.gateway_refund_id || pterminal.gateway_unique_id}</strong>.</li>
                <li>Complete the transaction following instruction on the device.</li>
                <li>Check status to update transaction status on renzo.</li>
              </ol>
            </div>
            <div className={'col-12 col-md-6 text-center d-flex'}>
              <div className={'align-self-center w-100'}>
                <p className={'text-center'}><strong>
                  Timeout : <TimeCounterView inverse={true} timeInterval={1000} time={pterminal.due_date}/>
                </strong></p>
                <button
                  disabled={cacheCheckLoading}
                  onClick={() => this.statusCheck(pterminal)}
                  className={'btn btn-lg btn-block btn-success my-2'}
                >
                  <FontAwesomeIcon icon={faSync} spin={cacheCheckLoading} className={'mr-4 small'}/>
                  Check Status
                </button>
                <p className={'text-muted small'}>Please complete the payment before it times out</p>
              </div>
            </div>
          </div>
          {(pterminal.payment_status === Constants.PTERMINAL_STATUS.TERMINAL_RESPONSE || pterminal.refund_status === Constants.PTERMINAL_STATUS.TERMINAL_RESPONSE) &&
          <p className={'text-muted mb-0'}>
            If you do not want to proceed with transaction <button
              className={'btn btn-link m-0 p-0'}
              onClick={() => {
                confirmAlert({
                  title: 'Cancel Transaction',
                  message: 'This action will cancel the transaction initiated to the POS device',
                  buttons: [
                    {
                      className: 'btn-secondary',
                      label: 'Exit',
                    },
                    {
                      className: 'btn-danger',
                      label: 'Cancel Transaction',
                      onClick: () => this.updateTransaction(
                        pterminal, {
                          cancel_payment: pterminal.payment_status === Constants.PTERMINAL_STATUS.TERMINAL_RESPONSE,
                          cancel_refund: pterminal.refund_status === Constants.PTERMINAL_STATUS.TERMINAL_RESPONSE,
                        }
                      ),
                    },
                  ],
                });
              }}
            >
            cancel transaction
            </button>.
          </p>
          }
        </div>
        }
        {(pterminal.allow_void && allowVoid) &&
        <React.Fragment>
          <hr/>
          <div className={'row mb-3'}>
            <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
              <button
                className={'btn btn-lg btn-block btn-secondary'}
                onClick={() => {
                  confirmAlert({
                    title: 'Void Transaction',
                    message: 'This action will void the transaction and refund payment to original medium.',
                    buttons: [
                      {
                        className: 'btn-secondary',
                        label: 'Exit',
                      },
                      {
                        className: 'btn-danger',
                        label: 'Void Transaction',
                        onClick: () => this.escalatedUpdateTransaction(pterminal, {void_payment: true}),
                      },
                    ],
                  });
                }}
              >
                Void Payment
              </button>
            </div>
          </div>
        </React.Fragment>
        }
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      cacheCheckLoading: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default (PTerminalAction);

