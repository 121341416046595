/**
 *
 * Property Record
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Consts and Libs
import AppAPI from '../../../lib/api';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import ListView from '../../../components/general/ListView';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDownload, faFile, faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import PropertyRecordsManage from './PropertyRecordsManage';
import AppUtil from '../../../lib/util';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PropertyRecordList extends Component {
  static componentName = 'PropertyRecordList';

  fetchData = (page = 1, callback) => {
    AppAPI.propertyapi.get('property-record/?page=' + page)
      .then((res) => {
        this.setState({
          loading: false,
          error: null,
        });
        if (res.results.length > 0) {
          if (res.next) {
            callback(res.results);
          } else {
            callback(res.results, {
              allLoaded: true, // the end of the list is reached
            });
          }
        } else {
          callback();
        }
      })
      .catch((err) => {
        callback(null, {
          allLoaded: true, // the end of the list is reached
        });
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };

  renderRow = (data, key) => {
    return (
      <tr key={key}>
        <td>{data.description}</td>
        <td><small>{AppUtil.formatDateTime(data.created)}</small></td>
        <td className={'text-right'}>
          <a rel='noopener noreferrer' target={'_blank'} href={data.document_signed_url}>
            <FontAwesomeIcon icon={faDownload} className={'mr-2'}/> Download
          </a>
        </td>
      </tr>
    );
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={3}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faFile}/> No Records </h4>
            <p>No records added so far.</p>
          </div>
        </td>
      </tr>
    );
  };


  render = () => {

    const {loading, error} = this.state;
    const {property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="tax-list screen-container ">
        <Helmet>
          <title>Records</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'Records'}
          description={`Records on file for ${property.name}.`}
        />


        <div className="row">
          <div className="col-lg-9 col-md-8 col-sm-12">
            <table className={'table border format-table'}>
              <thead>
                <tr>
                  <th className={'data-table-cell-xl'}>Record Name</th>
                  <th className={'data-table-cell-xm'}>Created</th>
                  <th className={'data-table-cell-lg'}/>
                </tr>
              </thead>
              <tbody>
                <ListView
                  rowView={this.renderRow}
                  onFetch={this.fetchData}
                  firstLoader={true}
                  pagination
                  emptyView={this.emptyView}
                  class={'list-group'}
                  colSpan={3}
                />
              </tbody>
            </table>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item">
                <button onClick={() => this.setState({showPropertyRecordsManage: true})} className="btn btn-link p-0">
                  <FontAwesomeIcon icon={faPlusCircle} size={'sm'} className={'mr-2'}/> Add Record
                </button>
              </li>
            </ul>
          </div>
        </div>

        <PropertyRecordsManage
          show={this.state.showPropertyRecordsManage}
          data={this.state.propertyRecordsManageDetails}
          onHide={() => this.setState({showPropertyRecordsManage: false, propertyRecordsManageDetails: null})}
          onSave={this.fetchInitData}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: false,
      showPropertyRecordsManage: false,
      propertyRecordsManageDetails: null,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyRecordList);