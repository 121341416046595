/**
 * Notes Reducer
 *
 */

// Set initial state
const initialState = {
  notes: {},
  notesparameter: {
    'order': null,
    'booking': null,
    'bookingroom': null,
    'room': null,
    'expense': null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
  case 'SET_NOTES': {
    let notes = [];
    if (action.data && typeof action.data === 'object') {
      notes = action.data;
    }

    return {
      ...state,
      notes,
    };
  }
  case 'SET_NOTES_PARAMETER': {
    let notesparameter = [];
    if (action.data && typeof action.data === 'object') {
      notesparameter = action.data;
    }

    return {
      ...state,
      notesparameter,
    };
  }
  default:
    return state;
  }
};
