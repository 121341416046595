/**
 * Messaging Reducer
 *
 */

// Set initial state
const initialState = {
  messaging: {},
  messagingparameter: {
    'order': null,
    'booking': null,
    'bookingroom': null,
    'room': null,
    'expense': null,
    'review': null,
    'payment': null
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
  case 'SET_MESSAGING': {
    let messaging = [];
    if (action.data && typeof action.data === 'object') {
      messaging = action.data;
    }

    return {
      ...state,
      messaging,
    };
  }
  case 'SET_MESSAGING_PARAMETER': {
    let messagingparameter = [];
    if (action.data && typeof action.data === 'object') {
      messagingparameter = action.data;
    }

    return {
      ...state,
      messagingparameter,
    };
  }
  default:
    return state;
  }
};
