/**
 * Dashboard Index Controller
 *
 */
import {connect} from 'react-redux';
import React, {Component} from 'react';

// Component
import Dashboard from './Dashboard';

// What data from the store shall we send to the component?
const mapStateToProps = state => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class DashboardView extends Component {
  static componentName = 'DashboardView';

  render = () => {
    return <Dashboard/>;
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView);
