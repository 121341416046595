/**
 *
 * ExpenseView
 * overview of expenses
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Consts and Libs
import AppAPI from '../../lib/api';
import {Constants} from '../../constants';
// Components
import Alerts from '../../components/ui/Alerts';
import Error from '../../components/general/Error';
import GuestListView from '../guest/GuestListView';
import Loading from '../../components/general/Loading';
import ProfileQuickSearch from '../guest/ProfileQuickSearch';
import PageHeader from '../../components/dashboard/PageHeader';
import GuestDetailsOverView from '../guest/GuestDetailsOverView';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp, faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
// Forms
import * as Yup from 'yup';
import {Input, Select} from 'react-formik-ui';
import {FieldArray, Form, Formik} from 'formik';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class ExpenseAdd extends Component {
  static componentName = 'ExpenseAdd';
  componentDidMount = () => {
    this.fetchInitData();
  };

  addExpense = credentials => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {

        const order_items = [];
        credentials.order_items.forEach((data) => {
          order_items.push({
            price: data.price,
            quantity: data.quantity,
            quantity_type: data.quantity_type,
            custom_description: data.custom_description,
          });
        });

        AppAPI.expense
          .post('', {
            department: credentials.department,
            tax_number: credentials.tax_number,
            tax_company: credentials.tax_company,
            expense_order_number: credentials.expense_order_number,
            quantity_type: credentials.quantity_type,
            order_discount: credentials.order_discount,
            order_discount_description: credentials.order_discount_description,
            order_items: order_items,
            vendor_data: this.state.vendorData ? this.state.vendorData.id : null,
          })
          .then((res) => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.props.history.replace(this.props.location.pathname.replace(/[^/]*$/, `${res.hash}/`));
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  fetchInitData = () => {
    AppAPI.expenseapi.get('init/')
      .then((res) => {
        if (res.actions !== '') {
          this.setState({
            loading: false,
            departments: _.isEmpty(res.departments) ? [] : res.departments,
          });
        } else {
          this.setState({
            loading: false,
            error: true,
          });
        }
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: error,
          resultMsg: {error},
        });
      });
  };

  showProfileDetails = (guestId, add_guest = false) => {
    this.setState({profileDetailsData: guestId, showProfileDetails: true, addProfile: add_guest});
  };


  render = () => {
    const {loading, error, departments, resultMsg, vendorData} = this.state;
    const {property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const contentValidation = Yup.object().shape({
      custom_description: Yup.string().min(2, 'Too Short!').required(),
      quantity: Yup.number().required(),
      price: Yup.number().required(),
    });

    const formValidation = Yup.object().shape({
      department: Yup.string().required(),
      order_discount: Yup.number(),
      order_items: Yup.array().of(contentValidation).nullable(),
    });

    const orderItemValue = {
      quantity: 1,
      quantity_type: 1,
      price: 0,
    };

    const currency = property.currency;

    return (
      <div className="expense-view screen-container">
        <Helmet>
          <title>Add Expense</title>
        </Helmet>

        <PageHeader
          history={this.props.history} title={'Add Expense'}
          description={''}
        />

        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <Formik
              initialValues={{
                department: '',
                order_items: [orderItemValue],
                order_discount: 0,
              }}
              validationSchema={formValidation}
              onSubmit={values => this.addExpense(values)}
            >
              {({values, handleSubmit}) => (
                <Form>
                  <div className={'row align-content-bottom mb-5'}>
                    <div className={'col-6 col-md-4'}>
                      <Select
                        required
                        className={'form-control rounded-right-0'}
                        name='department'
                        label={'Department'}
                        placeholder='Select an Option'
                        options={
                          departments.map((data) => ({
                            'value': data.id,
                            'label': data.name,
                          }))
                        }
                      />
                    </div>
                    <div className={'col-6'}>
                      <p className={'mb-0'}>Vendor</p>
                      {vendorData ?
                        <React.Fragment>
                          <GuestListView
                            data={vendorData}
                            action={() => this.showProfileDetails(vendorData, false)}
                          />
                        </React.Fragment>
                        :
                        <React.Fragment>
                          <button
                            type="button" className="btn btn-link m-0 p-0"
                            onClick={() => this.setState({
                              showProfileSearch: true,
                              profileSource: Constants.PROFILE_TYPE.VENDOR,
                            })}
                          > Add Vendor
                          </button>
                        </React.Fragment>
                      }
                    </div>
                  </div>

                  <div className={'row align-content-bottom mb-3'}>
                    <div className={'col-6 col-md-4'}>
                      <Input
                        name={'expense_order_number'}
                        label={'Expense Document Number'}
                        className={'form-control'}
                      />
                    </div>
                    <div className={'col-6 col-md-4'}>
                      <Input
                        name={'tax_number'}
                        label={'Vendor Tax Number'}
                        className={'form-control'}
                      />
                    </div>
                    <div className={'col-6 col-md-4'}>
                      <Input
                        name={'tax_company'}
                        label={'Vendor Company Name'}
                        className={'form-control'}
                      />
                    </div>
                  </div>
                  <div className={'spacer-20'}/>
                  <h5>Expense Items</h5>
                  <hr/>

                  <div className="form-row mb-3">
                    <div className="col-lg-12 col-md-12 form-group">
                      <FieldArray
                        name="order_items"
                        render={arrayHelpers => (
                          <ul className="list-group">
                            {!_.isEmpty(values.order_items) ? (
                              <React.Fragment>
                                {values.order_items.map((data, index) => (
                                  <li key={index} className={'list-group-item p-2'}>
                                    <div key={index} className={'form-row'}>
                                      <div className="col-10">
                                        <div className="form-row">
                                          <div className={'col-6'}>
                                            <Input
                                              required
                                              name={`order_items.${index}.custom_description`}
                                              label={'Description'}
                                              className={'form-control'}
                                            />
                                          </div>
                                          <div className={'col-2'}>
                                            <Input
                                              required
                                              name={`order_items.${index}.price`}
                                              label={`Price (${currency})`}
                                              type={'number'}
                                              className={'form-control'}
                                            />
                                          </div>
                                          <div className={'col-4'}>
                                            <div className={'form-row'}>
                                              <div className="col-4 form-group">
                                                <Input
                                                  required
                                                  name={`order_items.${index}.quantity`}
                                                  label={'Qty'}
                                                  type={'number'}
                                                  className={'form-control'}
                                                />
                                              </div>
                                              <div className="col-6 form-group">
                                                <Select
                                                  required
                                                  name={`order_items.${index}.quantity_type`}
                                                  label={'Type'}
                                                  className={'form-control'}
                                                  options={Constants.ORDER_QUANTITY_TYPE_EXPENSE}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className={'col-2 text-center'}>
                                        <div className="btn-group btn-group-sm mt-4" role="group">
                                        </div>
                                        <div className="btn-group btn-group-sm mt-4 text-center" role="group">
                                          <button
                                            disabled={index < 0}
                                            onClick={() => arrayHelpers.move(index, index - 1)}
                                            type="button" className="btn btn-outline-info">
                                            <FontAwesomeIcon
                                              className={'d-none d-md-inline-block'} icon={faChevronUp} size={'sm'}/>
                                          </button>
                                          <button
                                            onClick={() => arrayHelpers.move(index, index + 1)}
                                            type="button" className="btn btn-outline-info">
                                            <FontAwesomeIcon
                                              className={'d-none d-md-inline-block'} icon={faChevronDown} size={'sm'}/>
                                          </button>
                                          <button
                                            onClick={() => arrayHelpers.remove(index)}
                                            type="button" className="btn btn-outline-danger">
                                            <FontAwesomeIcon
                                              className={'d-none d-md-inline-block'} icon={faMinus} size={'sm'}/>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                                <div className={'pt-3 text-center'}>
                                  <button
                                    className={'btn btn-link'} type="button"
                                    onClick={() => arrayHelpers.push(orderItemValue)}>
                                    <FontAwesomeIcon className={'mr-2'} icon={faPlus} size={'sm'}/> Add Item
                                  </button>
                                </div>
                              </React.Fragment>
                            ) : (
                              <div className={'text-center border p-3 rounded'}>
                                <p>No Items.</p>
                                <button
                                  className={'btn btn-success'} type="button"
                                  onClick={() => arrayHelpers.push(orderItemValue)}>
                                  <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add Item
                                </button>
                              </div>
                            )}
                          </ul>
                        )}
                      />
                    </div>
                  </div>

                  <table className="table border">
                    <tbody>
                      <tr>
                        <td className="text-left w-75">Sub Total</td>
                        <td className="text-right">
                          {currency} {values.order_items.reduce(function (prev, cur) {
                            return prev + (parseFloat(cur.price) * parseFloat(cur.quantity));
                          }, 0)}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left w-75">
                          <Input
                            name={'order_discount_description'}
                            label={'Discount Description'}
                            className={'form-control'}
                          />
                        </td>
                        <td className="text-right">
                          <Input
                            type={'number'}
                            name={'order_discount'}
                            label={`Discount (${currency})`}
                            className={'form-control'}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left w-75"><strong>Total</strong></td>
                        <td className="text-right">
                          <strong>
                            {currency} {parseFloat(values.order_items.reduce(function (prev, cur) {
                              return prev + (parseFloat(cur.price) * parseFloat(cur.quantity));
                            }, 0)) - parseFloat(values.order_discount)}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className={'spacer-20'}/>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <button
                    type="submit" onClick={handleSubmit} className={'btn btn-success btn-lg btn-block'}>
                    <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add Expense
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        {/*  Profile Details */}
        <GuestDetailsOverView
          onHide={() => this.setState({showProfileDetails: false})}
          guestId={this.state.profileDetailsData}
          show={this.state.showProfileDetails}
          addGuestFunction={this.state.addProfile}
          action={this.addProfileAction}
          resultMsg={resultMsg}
        />


        {/*  Profile Search */}
        <ProfileQuickSearch
          onHide={() => this.setState({showProfileSearch: false})}
          show={this.state.showProfileSearch}
          showProfileDetails={this.showProfileDetails}
          isVendor={this.state.profileSource === Constants.PROFILE_TYPE.VENDOR}
        />

      </div>
    );
  };

  addProfileAction = (data) => {
    if (data) {
      if (this.state.showProfileSearch) {
        this.setState({vendorData: data, showProfileSearch: false, showProfileDetails: false});
      } else {
        this.setState({vendorData: null, showProfileDetails: false});
      }
    }
  };


  constructor(props) {
    super(props);

    this.state = {
      departments: [],
      vendorData: null,
      loading: true,
      error: null,
      profileDetailsData: null,
      showProfileDetails: false,
      addProfile: false,
      profileSource: Constants.PROFILE_TYPE.VENDOR,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseAdd);
