/**
 * Availability details
 *  availability details
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
// Consts and Libs
import AppUtil from '../../../lib/util';
import Colors from '../../../theme/colors';
// Components
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */

class EventAvailabilityDetails extends Component {
  static componentName = 'EventAvailabilityDetails';

  static propTypes = {
    match: PropTypes.object,
    property: PropTypes.object,
    availabilityDetails: PropTypes.object,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  printRestrictionStatus = (status) => {
    if (status === false) {
      return <span className={'badge badge-light'}>No Restriction</span>;
    } else if (status === true) {
      return <span className={'badge badge-warning'}>Restriction Enabled</span>;
    } else {
      return <span className={'badge badge-light'}>No Restriction</span>;
    }
  };


  preFillData = () => {
    const {availabilityDetails} = this.props;
    const availabilityChartData = [];

    if (availabilityDetails.future) {
      availabilityChartData.push(
        {name: 'Available', value: availabilityDetails.available, color: Colors.blue},
        {name: 'Booked', value: availabilityDetails.booked, color: Colors.green},
        {name: 'Provisional Booked', value: availabilityDetails.blocked, color: Colors.yellow},
      );
    } else {
      availabilityChartData.push(
        {name: 'Un Sold', value: availabilityDetails.un_sold, color: Colors.purple},
        {name: 'Claimed', value: availabilityDetails.complete, color: Colors.green},
        {name: 'Not Claimed', value: availabilityDetails.no_show, color: Colors.teal},
      );
    }
    availabilityChartData.push({name: 'Cancelled', value: availabilityDetails.cancelled, color: Colors.pink});
    this.setState(
      {
        init: false,
        loading: false,
        availabilityChartData,
      },
    );
  };

  render = () => {

    const {loading, init, availabilityChartData} = this.state;
    const {show, availabilityDetails} = this.props;

    if (show && init) {
      this.preFillData();
    }

    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {(availabilityDetails && availabilityDetails.future)? 'Availability' : 'Occupancy'} Details
            of {availabilityDetails ? availabilityDetails.ticket_name + ' on ' + AppUtil.formatDateTime(availabilityDetails.date, 'date') : ''}
          </Modal.Title>
        </Modal.Header>
        <React.Fragment>
          <Modal.Body>
            {loading || !availabilityDetails ? <Loading heightMatch={false}/>
              :
              <React.Fragment>
                <div className={'row'}>
                  <div className={'col-md-12 col-lg-6'}>
                    <ResponsiveContainer>
                      <PieChart>
                        <Pie
                          data={availabilityChartData}
                          labelLine={false}
                          label={AppUtil.renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {availabilityChartData.map((entry, index) =>
                            <Cell key={`cell-${index}`} fill={entry.color}/>,
                          )}
                        </Pie>
                        <Tooltip/>
                        <Legend onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}/>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                  <div className={'col-md-12 col-lg-6'}>
                    <table className={'table table-sm'}>
                      <thead>
                        <tr className={'bg-dark text-light'}>
                          <th>Type</th>
                          <th>Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className={'table-info'}>
                          <td>Total Tickets(s)</td>
                          <td>{availabilityDetails.total}</td>
                        </tr>
                        <tr className={!availabilityDetails.future ? 'table-secondary' : 'table-success'}>
                          <td>Available Ticket(s)</td>
                          <td>{availabilityDetails.available}</td>
                        </tr>
                        <tr className={!availabilityDetails.future ? 'table-secondary' : 'table-success'}>
                          <td>Confirm Tickets</td>
                          <td>{availabilityDetails.booked}</td>
                        </tr>
                        <tr className={!availabilityDetails.future ? 'table-secondary' : 'table-success'}>
                          <td>Provisional Tickets</td>
                          <td>{availabilityDetails.blocked}</td>
                        </tr>
                        <tr>
                          <td>Cancelled Tickets</td>
                          <td>{availabilityDetails.cancelled}</td>
                        </tr>
                        <tr className={availabilityDetails.future ? 'table-secondary' : 'table-success'}>
                          <td>No Claimed Tickets</td>
                          <td>{availabilityDetails.no_show}</td>
                        </tr>
                        <tr className={availabilityDetails.future ? 'table-secondary' : 'table-success'}>
                          <td>Claimed Tickets</td>
                          <td>{availabilityDetails.complete}</td>
                        </tr>
                        <tr className={availabilityDetails.future ? 'table-secondary' : 'table-success'}>
                          <td>Un Sold</td>
                          <td>{availabilityDetails.un_sold}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {availabilityDetails.time && <p className={'small'}>Updated
                  at {AppUtil.formatDateTime(availabilityDetails.time)}</p>}
              </React.Fragment>
            }
          </Modal.Body>
          <Modal.Footer className={'d-block'}>
            <div className={'row'}>
              <div className={'col text-center'}>
                {availabilityDetails &&
                 <h4>{availabilityDetails.future ? `Availability : ${availabilityDetails.availability_percent}%` : `Occupancy : ${availabilityDetails.occupancy_percent}%`}</h4>
                }
              </div>
              <div className={'col'}>
                <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                  <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                </button>
              </div>
            </div>
          </Modal.Footer>
        </React.Fragment>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: true,
      availabilityChartData: [],
    };
  }
}

/* Export Component ==================================================================== */
export default EventAvailabilityDetails;
