/**
 *
 * Property Notification Template Manage
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../../lib/api';

// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';

import {faChevronDown, faChevronUp, faMinus, faPlus, faSave} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import * as Yup from 'yup';
import {FieldArray, Formik} from 'formik';
import {Form, Input, SubmitBtn, Textarea} from 'react-formik-ui';
import Alerts from '../../../components/ui/Alerts';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class NotificationContentManage extends Component {
  static componentName = 'NotificationContentManage';

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    AppAPI.property.get(`${this.props.property.id}/notification/email-content/?email_template=${this.props.match.params.contentId}`)
      .then((res) => {
        const initialValues = {};

        if(!_.isEmpty(res.content)){
          initialValues.qa_content = res.content.qa_content;
          initialValues.pre_qa_content = res.content.pre_qa_content;
          initialValues.post_qa_content = res.content.post_qa_content;
        }

        this.setState({
          initialValues,
          loading: false,
          templateData: res,
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: true,
          resultMsg: {error},
        });
      });
  }

  saveData = (values) => {
    if (values) {
      this.setState({resultMsg: {status: 'One moment...'}});
      AppAPI.property.post(`${this.props.property.id}/notification/email-content/?email_template=${this.props.match.params.contentId}`, {
        'qa_content': values.qa_content,
        'pre_qa_content': values.pre_qa_content,
        'post_qa_content': values.post_qa_content,
      })
        .then(() => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                this.props.history.goBack();
              }, 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            resultMsg: {error},
          });
        });
    }
  }

  render = () => {
    const {loading, error, templateData, initialValues, resultMsg} = this.state;
    const {property} = this.props;


    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const contentValidation = Yup.object().shape({
      title: Yup.string().required('Required'),
    });
    const defaultFormValidation = Yup.object().shape({
      qa_content: Yup.array().of(contentValidation).nullable()
    });

    return (
      <div className="channel-manager-settings screen-container">
        <Helmet>
          <title>Notification Contents</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={`Edit Content : ${templateData.name}`}
          description={`Edit the contents for ${templateData.name} email`}
        />

        <div className={'row'}>
          <div className="col-lg-9 col-md-8 col-sm-12 mb-5">
            <Formik
              onSubmit={(values) => (this.saveData(values))}
              validationSchema={defaultFormValidation}
              initialValues={initialValues}>
              {({values}) => (
                <Form>
                  <div className={'border p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>Email Content</h6>
                  </div>
                  <div className={'border p-2 mb-3'} id={'primaryAction'}>
                    <div className={'row'}>
                      <div className={'col-12 text-center'}>
                        <img
                          width={75}
                          src={property.logo.logo} alt={property.name} className="img-thumbnail mx-auto"
                        />
                      </div>
                    </div>
                    <hr/>

                    <div className={'bg-light py-5 text-center mb-3'}>
                      <p className={'text-muted'}>Email Content for {templateData.name}</p>
                    </div>

                    <div className="form-row mb-3">
                      <div className="col-lg-12 col-sm-12 form-group">
                        <Textarea
                          name={'pre_qa_content'}
                          label={'Pre Q & A Content'}
                          className={'form-control'}
                        />
                      </div>
                    </div>

                    <div className="form-row mb-3 border p-2">
                      <div className="col-lg-12 col-md-12 form-group">
                        <FieldArray
                          name="qa_content"
                          render={arrayHelpers => (
                            <div>
                              {!_.isEmpty(values.qa_content) ? (
                                <div>{values.qa_content.map((public_link, index) => (
                                  <div key={index} className={`form-row ${index > 0 && 'border-top pt-3'}`}>
                                    <div className="col-10">
                                      <div className={'form-group'}>
                                        <Input
                                          required
                                          name={`qa_content.${index}.title`}
                                          label={'Question'}
                                          className={'form-control'}
                                        />
                                      </div>
                                      <div className={'form-group'}>
                                        <Textarea
                                          name={`qa_content.${index}.description`}
                                          label={'Answer'}
                                          className={'form-control'}
                                        />
                                      </div>
                                    </div>
                                    <div className={'col-2 text-center'}>
                                      <div className="btn-group btn-group-sm mt-4" role="group">
                                        <button
                                          onClick={() => arrayHelpers.remove(index)}
                                          type="button" className="btn btn-outline-danger">
                                          <FontAwesomeIcon className={'mr-2 d-none d-md-inline-block'} icon={faMinus} size={'sm'}/> Remove
                                        </button>
                                      </div>
                                      <div className="btn-group btn-group-sm mt-4 text-center" role="group">
                                        <button
                                          disabled={index<0}
                                          onClick={() => arrayHelpers.move(index, index-1)}
                                          type="button" className="btn btn-outline-info">
                                          <FontAwesomeIcon className={'d-none d-md-inline-block'} icon={faChevronUp} size={'sm'}/>
                                        </button>
                                        <button
                                          onClick={() => arrayHelpers.move(index, index+1)}
                                          type="button" className="btn btn-outline-info">
                                          <FontAwesomeIcon className={'d-none d-md-inline-block'} icon={faChevronDown} size={'sm'}/>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                <div className={'pt-3 border-top'}>
                                  <button
                                    className={'btn btn-success'} type="button"
                                    onClick={() => arrayHelpers.push('')}>
                                    <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add a
                                    Q & A
                                  </button>
                                </div>
                                </div>
                              ) : (
                                <div className={'text-center'}>
                                  <p>No Q & A.</p>
                                  <button
                                    className={'btn btn-success'} type="button"
                                    onClick={() => arrayHelpers.push('')}>
                                    <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add a Q & A
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-lg-12 col-sm-12 form-group">
                        <Textarea
                          name={'post_qa_content'}
                          label={'Post Q & A Content'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <hr/>
                    <div className={'bg-light py-3 text-center'}>
                      <p className={'text-muted mb-0'}>Footer Content</p>
                    </div>
                  </div>

                  <div className={''}>
                    <Alerts
                      status={resultMsg.status}
                      success={resultMsg.success}
                      error={resultMsg.error}
                    />
                    <SubmitBtn className={'btn btn-md btn-success btn-lg btn-block'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                    </SubmitBtn>
                  </div>

                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      templateData: {},
      initialValues: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContentManage);
