/**
 *
 * POS Table Details
 * point of sales table information
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

// Consts and Libs
import AppAPI from '../../lib/api';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faChevronCircleDown,
  faExclamationCircle,
  faUnlock, faUserClock,
} from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../../components/dashboard/PageHeader';

// Actions
import * as POSActions from '../../redux/pos/actions';
import {confirmAlert} from '../../components/general/ConfirmAlert';
import {Constants, Strings} from '../../constants';
import {Alerts} from '../../components/ui';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  table: state.pos.table,
  posDetails: state.pos.posDetails,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPOSDetails: POSActions.setPOSDetails,
  setPOSTable: POSActions.setPOSTable,
};

/* Component ==================================================================== */
class POSTableDetails extends Component {
  static componentName = 'POSTableDetails';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    if (this.props.match.params.POSTableId) {
      this.props.setPOSTable(this.props.match.params.POSTableId, this.props.match.params.POSId, false)
        .then(() => {
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  }

  updatePOSTable = (payload) => {
    const {posDetails, table} = this.props;
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.posapi.patch(`pos-table/${table.hash}/?pos=${posDetails.hash}`, payload)
          .then(res => {
            this.props.setPOSTable(res);
            this.props.setPOSDetails(posDetails.hash, true, true);
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  updatePOSTableEscalatedPermission = (payload) => {
    const {posDetails, table} = this.props;
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.posapi.patch(`pos-table-escalated/${table.hash}/?pos=${posDetails.hash}`, payload)
          .then(res => {
            this.props.setPOSTable(res);
            this.props.setPOSDetails(posDetails.hash, true, true);
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };


  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {posDetails, table} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="pos-table-details screen-container ">
        <Helmet>
          <title>Point of Sales | Table</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history} title={'Table Details'}
          description={`Table : ${table.name}`}
        />

        <div className={'row'}>
          <div className={'col-lg-9 col-md-9 col-sm-12'}>
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
            <div className={'row'}>
              <div className={'col-12 col-md-6 mb-3'}>
                <ul className="list-group">
                  <li className="list-group-item"><strong>Name</strong> : {table.name}</li>
                  <li className="list-group-item"><strong>Occupancy</strong> : {table.occupancy} Pax</li>
                  <li className="list-group-item"><strong>Table Geometry</strong> : {table.table_shape_display}</li>
                </ul>
              </div>
              <div className={'col-12 col-md-6 mb-3'}>
                <ul className="list-group">
                  {/*<li className="list-group-item">*/}
                  {/*  <strong>Service Status</strong> :*/}
                  {/*  <span className={'badge ' + (table.service_status === 1 ? 'badge-success' : 'badge-danger')}>*/}
                  {/*    {table.service_status_display}*/}
                  {/*  </span>*/}
                  {/*</li>*/}
                  <li className="list-group-item"><span className="glyphicon glyphicon-user"/>
                    <strong>Availability Status</strong> : <span
                      className={'badge ' + (table.availability_status === -1 ? 'badge-danger' : table.availability_status === 1 ? 'badge-success' : 'badge-info')}>
                      {table.availability_status_display}</span>
                  </li>
                  <li className="list-group-item">
                    <strong>Operation Status</strong> :
                    <span className={'badge ' + (table.maintenance_status === 1 ? 'badge-success' : 'badge-danger')}>
                      {table.maintenance_status_display}
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className={'row'}>
              <div className={'col-12 col-md-12 mb-3'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Short Description</strong> : {table.table_note || 'N.A'}
                  </li>
                </ul>
              </div>
            </div>

            <div className={'row'}>
              <div className="col-lg-12 col-md-12 col-sm-12 mb-5">
                <div
                  className={'border p-2 text-center anchor'} data-toggle="collapse" href="#maintinanceActions"
                  role="button" aria-expanded="false" aria-controls="maintinanceActions">
                  <h5 className={'mb-0'}>
                    <FontAwesomeIcon
                      icon={faChevronCircleDown} size={'sm'}
                      className={'mx-2 green-cl float-left mt-1'}/>
                    Maintenance Actions
                    <FontAwesomeIcon
                      icon={faChevronCircleDown} size={'sm'}
                      className={'mx-2 green-cl float-right mt-1'}/>
                  </h5>
                </div>
                <div className={'py-2 border collapse'} id={'maintinanceActions'}>
                  <div className={'row'}>
                    <div className={'col-3 text-center p-2'}>
                      <FontAwesomeIcon
                        icon={faExclamationCircle} size={'3x'}
                        onClick={() => confirmAlert({
                          title: 'Confirm Action',
                          message: Strings.posTableRequestMaintenanceText,
                          buttons: [
                            {
                              className: 'btn-warning',
                              label: 'Confirm',
                              onClick: () => table.maintenance_status !== Constants.ROOM.MAINTENANCE_REQUESTED ? this.updatePOSTable({'maintenance_status': Constants.ROOM.MAINTENANCE_REQUESTED}) : null,
                            },
                            {
                              className: 'btn btn-secondary',
                              label: 'Cancel',
                            },
                          ],
                        })}
                        className={'mx-2 anchor ' + (table.maintenance_status === Constants.ROOM.MAINTENANCE_REQUESTED ? 'grey-cl' : 'orange-cl')}/>
                      <p className={'small mb-0'}>Request Maintenance</p>
                    </div>
                    <div className={'col-3 text-center p-2'}>
                      <FontAwesomeIcon
                        icon={faCheckCircle} size={'3x'}
                        onClick={() => confirmAlert({
                          title: 'Confirm Action',
                          message: Strings.posTableSetOperationalText,
                          buttons: [
                            {
                              className: 'btn-success',
                              label: 'Confirm',
                              onClick: () => table.maintenance_status !== Constants.ROOM.NORMAL ? this.updatePOSTable({'maintenance_status': Constants.ROOM.NORMAL}) : null,
                            },
                            {
                              className: 'btn btn-secondary',
                              label: 'Cancel',
                            },
                          ],
                        })}
                        className={'mx-2 anchor ' + (table.maintenance_status === Constants.ROOM.NORMAL ? 'grey-cl' : 'green-cl')}/>
                      <p className={'small mb-0'}>Operational</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item">
                <Link to={`/point-of-sale/${posDetails.hash}/tables/${table.hash}/history`}>
                  <FontAwesomeIcon icon={faUserClock} size={'sm'} className={'mr-2'}/> Maintenance History
                </Link>
                <p className={'small text-muted mb-0'}>Previous actions / updates on the table.</p>
              </li>
              <li className="list-group-item">
                <button
                  className="btn btn-link orange-cl m-0 p-0"
                  disabled={!table.pos_order_id}
                  onClick={() =>
                    confirmAlert({
                      title: 'Confirm Action',
                      message: Strings.unlinkPOSOrderText,
                      buttons: [
                        {
                          className: 'btn-success',
                          label: 'Unlink POS Order',
                          onClick: () => this.updatePOSTableEscalatedPermission({unlink_pos_order: true}),
                        },
                        {
                          className: 'btn btn-secondary',
                          label: 'Cancel',
                        },
                      ],
                    })}
                >
                  <FontAwesomeIcon icon={faUnlock} size={'sm'} className={'mr-2'}/> Unlink POS Order
                </button>
                <p className={'small text-muted mb-0'}>Requires Manager Permission.</p>
              </li>
            </ul>
          </div>
        </div>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(POSTableDetails);