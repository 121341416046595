/**
 * POSRoomListView
 *  List view for POS Room
 *
 */

import _ from 'lodash';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Consts and Libs
import AppUtil from '../../../lib/util';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBaby, faChild, faUser} from '@fortawesome/free-solid-svg-icons';


/* Component ==================================================================== */
class POSRoomListView extends Component {
  static componentName = 'POSRoomListView';

  static propTypes = {
    data: PropTypes.object.isRequired,
    action: PropTypes.func,
    disabled: PropTypes.bool,
    bgClass: PropTypes.string,
    actionText: PropTypes.string,
  };

  static defaultProps = {
    bgClass: '',
    disabled: false,
    actionText: 'Select Room'
  }

  action = (data) => {
    if (this.props.disabled !== true) {
      if (this.props.action) {
        this.props.action(data);
      }
    }
  };

  render = () => {
    const {data} = this.props;
    const colSpan = !_.isEmpty(data.booking_room_data) ? 2 : 1;
    const booking_room_data = data.booking_room_data;
    return (
      <React.Fragment>
        <tr>
          <td className={`align-middle data-table-cell-md ${colSpan > 1 && 'border-right'}`} rowSpan={colSpan}>
            <p className={'text-center mb-0'}><strong>{data.name}</strong></p>
          </td>
          {!_.isEmpty(booking_room_data) ?
            <React.Fragment>
              <td className={'align-middle text-left'}>
                <p className={'small mb-0 text-muted'}>Dates</p>
                {`${AppUtil.formatDateTime(booking_room_data.checkin, 'date')} - ${AppUtil.formatDateTime(booking_room_data.checkout, 'date')}`}
              </td>
              <td className={'align-middle text-left'}>
                <p className={'small mb-0 text-muted'}>Occupancy</p>
                <p className="mb-0 small">
                  <FontAwesomeIcon icon={faUser} size={'sm'}/> {booking_room_data.no_of_guest}
                  <FontAwesomeIcon icon={faChild} size={'sm'} className={'ml-2'}/> {booking_room_data.no_of_children}
                  <FontAwesomeIcon icon={faBaby} size={'sm'} className={'ml-2'}/> {booking_room_data.no_of_infant}
                </p>
              </td>
              <td className={'align-middle text-left'}>
                <p className={'small mb-0 text-muted'}>Booking Ref</p>
                {booking_room_data.attributes.booking_ref_no}
              </td>
            </React.Fragment>
            :
            <td colSpan={3} className={'text-center border'}>....</td>
          }
          <td rowSpan={colSpan} className={` text-left align-middle ${colSpan > 1 && 'border-left border-right'}`}>
            {booking_room_data && !_.isEmpty(booking_room_data.guests) ?
              <ol className={'text-left pl-3'}>
                {booking_room_data.guests.map((guest, k) => (
                  <li key={k}>{guest.name}</li>
                ))}
              </ol>
              :
              <React.Fragment>
                <p className={'small text-muted text-center'}>No Guest Information</p>
              </React.Fragment>
            }
          </td>
          <td rowSpan={colSpan} className={'align-middle text-center'}>
            {this.props.action &&
              <button
                className={'btn btn-primary'}
                disabled={!this.props.action}
                onClick={() => this.props.action(data)}
              >
                {this.props.actionText}
              </button>
            }
          </td>
        </tr>
        {!_.isEmpty(booking_room_data) &&
        <tr className={''}>
          <td className={'align-middle text-left'}>
            <p className={'small mb-0 text-muted'}>Package</p>
            <p className={'mb-0'}>
              {
                !_.isEmpty(booking_room_data.room_type_package_attributes) ?
                  <React.Fragment>
                    {booking_room_data.room_type_package_attributes.map((packageData, i) => (
                      ` ${i > 1 ? ', ' : ''}${packageData.room_type_package ? packageData.room_type_package.name : '..'}`
                    ))}
                  </React.Fragment>
                  :
                  '...'
              }
            </p>
          </td>
          <td className={'align-middle text-left'}>
            <p className={'small mb-0 text-muted'}>Room Type</p>
            {!_.isEmpty(booking_room_data.attributes.room_type_name) ? booking_room_data.attributes.room_type_name : '...'}
          </td>
          <td className={'align-middle text-left'}>
            <p className={'small mb-0 text-muted text-left'}>OTA</p>
            <p className={'small text-muted mb-0 text-left'}>
              {booking_room_data.attributes.booking_ota_name || '...'}
            </p>
          </td>
        </tr>
        }
      </React.Fragment>
    );
  };
}

/* Export Component ==================================================================== */
export default (POSRoomListView);

