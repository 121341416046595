import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';


import Login from './Login';
import ResetPassword from './ResetPassword';
import ResetPasswordConform from './ResetPasswordConfirm';
import NotFound from '../NotFound';

const Auth = () => (
  <div>
    <div className="container pt-5">
      <div className={'logo-container mb-3 mx-auto'}>
        <img alt="Renzo" src={require('../../images/logo.png')}/>
      </div>
      <Switch>
        <Route exact path={'/auth'} render={() => (<Redirect to={'/auth/login'}/>)}/>
        <Route path="/auth/login" exact component={Login}/>
        <Route path="/auth/reset-password" exact component={ResetPassword}/>
        <Route path="/auth/reset-password/confirm/:uid/:token" component={ResetPasswordConform}/>
        {/* To remove */}
        <Route path="/auth/forgot-password/confirm/:uid/:token" component={ResetPasswordConform}/>
        <Route exact component={NotFound}/>
      </Switch>
    </div>
  </div>
);

export default Auth;