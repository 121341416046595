/**
 * Payment/Refund
 */
import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Img } from 'react-image';
import * as Yup from 'yup';
// Consts and Libs
import AppAPI from '../../lib/api';
// Components
import {Formik} from 'formik';
import {Alerts} from '../../components/ui';
import Loading from '../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
import {Checkbox, DropZone, Form, SubmitBtn} from 'react-formik-ui';

/* Component ==================================================================== */

class PaymentReferenceManage extends Component {
  static componentName = 'PaymentReferenceImageManage';

  static propTypes = {
    data: PropTypes.object,
    type: PropTypes.string,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        record: null,
        loading: false,
        initialValues: {
          'scanned_record_1': [],
        },
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  updatePage = (credentials) => {
    const {data, type} = this.props;
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});
      const formData = new FormData();

      if (credentials.scanned_record_1 && credentials.scanned_record_1[0]) {
        formData.append('scanned_record_1', credentials.scanned_record_1 ? credentials.scanned_record_1[0] : null);
        formData.append('record_image', credentials.scanned_record_1 ? credentials.scanned_record_1[0] : null);
      }

      if (credentials.remove_file) {
        formData.append('scanned_record_1', new File([], ''));
        formData.append('record_image', new File([], ''));
      }

      let Api;

      switch (type) {
      case 'expense':
        Api = AppAPI.expense;
        break;
      case 'payment':
        Api = AppAPI.payment;
        break;
      case 'refund':
        Api = AppAPI.payment;
        break;
      default:
        Api = AppAPI.refund;
      }

      Api.patch(`${data.hash}/`, formData, true)
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                if (this.props.onSave) {
                  this.props.onSave(res);
                }
                if (this.props.updateResponse) {
                  this.props.updateResponse(res);
                }
                this.props.onHide(res);
              }, 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };


  render = () => {

    const {resultMsg, initialValues, loading} = this.state;
    const {show, data} = this.props;

    if (!show) return null;

    const SUPPORTED_IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
    const FILE_SIZE = 5 * 1024 * 1024;

    let formValidation = Yup.object().shape({
      'scanned_record_1': Yup.mixed()
        .test(
          'fileFormat',
          'Unsupported Format',
          value => value && value[0] ? SUPPORTED_IMAGE_FORMATS.includes(value[0].type) : true,
        )
        .test('fileSize', 'File is too large < 4Mb', value => {
          return value && value[0] ? value[0].size <= FILE_SIZE : true;
        }),
    });

    return (
      <Modal
        {...this.props}
        size={'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Reference Image
          </Modal.Title>
        </Modal.Header>
        {loading ? <Loading heightMatch={false}/> :
          <Formik
            onSubmit={(values) => this.updatePage(values)}
            validationSchema={formValidation}
            initialValues={initialValues}>
            {() => (
              <React.Fragment>
                <Modal.Body>
                  <Form>
                    <div className={'row'}>
                      <div className={'col-sm-12 form-group'}>
                        {data && data.scanned_record_1_signed && data.scanned_record_1_signed.record_2x &&
                         <div>
                           <Img
                             className={'border p-1 w-100'}
                             src={data.scanned_record_1_signed.record_2x}
                             loader={<Loading heightMatch={false}/>}
                           />
                           <hr/>
                           <Checkbox
                             className={'mr-2'}
                             name='remove_file'
                             label='Remove Image'
                             text='Check to delete image.'
                           />
                           <hr/>
                         </div>
                        }
                        {data && data.record_image_signed && data.record_image_signed.record_2x &&
                         <div>
                           <Img
                             className={'border p-1 w-100'}
                             src={data.record_image_signed.record_2x}
                             loader={<Loading heightMatch={false}/>}
                           />
                           <hr/>
                           <Checkbox
                             className={'mr-2'}
                             name='remove_file'
                             label='Remove Image'
                             text='Check to delete image.'
                           />
                           <hr/>
                         </div>
                        }
                      </div>
                    </div>
                    <div className={'row'}>
                      <div className={'col-sm-12 form-group'}>
                        <p className={'text-muted'}>
                          Uploaded scanned image or photo of payment reference.
                        </p>
                        <DropZone
                          name='scanned_record_1'
                          label='File upload'
                          placeholder='Drop files here, or click to select files.'
                          hint={'Upload a jpg / jpeg / png file of size < 5MB'}
                          multiple={false}
                        />
                      </div>
                    </div>
                  </Form>

                </Modal.Body>
                <Modal.Footer className={'d-block'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <div className={'row'}>
                    <div className={'col-6'}>
                      <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                      </button>
                    </div>
                    <div className={'col-6'}>
                      <SubmitBtn className={'btn btn-success btn-lg btn-block'}>
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/>
                        Save
                      </SubmitBtn>
                    </div>
                  </div>
                </Modal.Footer>
              </React.Fragment>
            )}
          </Formik>
        }
      </Modal>
    );
  };


  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: false,
      record: null,
      initialValues: {},
    };
  }
}

/* Export Component ==================================================================== */
export default PaymentReferenceManage;
