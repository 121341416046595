/**
 *
 * Booking Room Digest
 */
import _ from 'lodash';
import Moment from 'moment';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
// Constants
import {Constants} from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import {
  faBaby,
  faChild,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class BookingRoomDigest extends Component {
  static componentName = 'BookingRoomDigest';

  static propTypes = {
    startDate: PropTypes.object
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {startDate} = this.props;
    this.setState({loading: true});
    const date = (startDate ? startDate : Moment()).format('YYYY-MM-DD');
    const queryParams = {
      'date': date,
    };

    AppAPI.bookingapi.get(`booking-room-digest/?${queryString.stringify(queryParams)}`)
      .then((res) => {
        this.setState({loading: false, response: res, date:date});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({loading: false, error: error});
      });
  };

  renderData = (data) => {
    return (
      <React.Fragment>
        {!_.isEmpty(data) ?
          <React.Fragment>
            {data.map((bdata, i) => (
              <React.Fragment key={i}>
                <tr className={(i % 2 === 0) ? 'grey-light-bg' : ''}>
                  <td rowSpan={2} className={'border-right align-middle text-center'}>
                    <Link
                      to={`/booking/${bdata.attributes && bdata.attributes.booking_hash ? bdata.attributes.booking_hash : bdata.booking_id}/booking-room/${bdata.hash}`}
                      rel='noopener noreferrer' target={'_blank'}>
                      {bdata.ref_no}
                    </Link><br/>
                    <Link to={`/booking/${bdata.attributes && bdata.attributes.booking_hash ? bdata.attributes.booking_hash : bdata.booking_id}`} rel='noopener noreferrer' target={'_blank'} className={'small'}>
                      B : {bdata.attributes.booking_ref_no}
                    </Link>
                  </td>
                  <td className={'border-right'}>{AppUtil.formatDateTime(bdata.checkin, 'datef')}</td>
                  <td>{AppUtil.formatDateTime(bdata.checkout, 'datef')}</td>
                  <td className={'border-right border-left  data-table-cell-lg'} rowSpan={2}>
                    {!_.isEmpty(bdata.guests) &&
                    <ol className={'pl-3'}>
                      {bdata.guests.map((data, i) => (
                        <li key={i} className={'small text-left'}>
                          {data.name}
                        </li>
                      ))}
                    </ol>
                    }
                  </td>
                  <td className={'border-right data-table-cell-md'}>
                    <p className={'mb-0 text-center'}>
                      <strong>{(bdata.attributes && bdata.attributes.room_name) ? bdata.attributes.room_name : '...'}</strong>
                    </p>
                  </td>
                  <td colSpan={2} className={'text-center'}>
                    <span className={`badge ${AppUtil.bookingStatusColor(bdata.booking_status)}`}>
                      {bdata.booking_status_display}
                    </span>
                  </td>
                </tr>
                <tr className={(i % 2 === 0) ? 'grey-light-bg' : ''}>
                  <td colSpan={2} className={'align-middle'}>
                    {!_.isEmpty(bdata.room_type_package_attributes) &&
                    <React.Fragment>
                      <p className={'small mb-0'}> Package :
                        {bdata.room_type_package_attributes.map((data, i) => (
                          <React.Fragment key={i}>
                            {i>0 && ', '} {data.room_type_package.name}
                          </React.Fragment>
                        ))}
                      </p>
                    </React.Fragment>
                    }
                  </td>
                  <td className={'border-right'}>
                    <p className={'mb-0 text-center'}>{bdata.attributes && bdata.attributes.room_type_name}</p>
                  </td>
                  <td className={'data-table-cell-md text-center border-right'} colSpan={2}>
                    <p className="mb-0 small">
                      <FontAwesomeIcon icon={faUser} size={'sm'}/> {bdata.no_of_guest}
                      <FontAwesomeIcon icon={faChild} size={'sm'} className={'ml-2'}/> {bdata.no_of_children}
                      <FontAwesomeIcon icon={faBaby} size={'sm'} className={'ml-2'}/> {bdata.no_of_infant}
                    </p>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </React.Fragment>
          :
          <tr>
            <td colSpan={6} className={'text-center'}>
              <p className={'text-center my-4'}>
                <FontAwesomeIcon icon={faLongArrowAltLeft} className={'mx-2'}/>
                No Bookings
                <FontAwesomeIcon icon={faLongArrowAltRight} className={'mx-2'}/>
              </p>
            </td>
          </tr>
        }
      </React.Fragment>
    );
  };

  render = () => {
    const {loading, error, response, date, bookingFilter} = this.state;
    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const activeBookings = response.filter(data =>  ![Constants.BOOKING.CANCELED,Constants.BOOKING.NOSHOW].includes(data.booking_status));

    let bookingList = response;

    const bookingStatusData = [
      {
        title: 'Arrival',
        color: 'green-cl',
        data: activeBookings.filter(data => data.checkin === date)
      },
      {
        title: 'Departure',
        color: 'red-cl',
        data: activeBookings.filter(data => data.checkout === date)
      },
      {
        title: 'Active',
        color: 'yellow-cl',
        data: activeBookings.filter(data => data.booking_status === Constants.BOOKING.ACTIVE)
      },
      {
        title: 'Other',
        color: 'blue-cl',
        data: response.filter(
          data => !([Constants.BOOKING.ACTIVE, Constants.BOOKING.CANCELED, Constants.BOOKING.NOSHOW].includes(data.booking_status) || data.checkin === date || data.checkout === date)
        )
      },
      {
        title: 'Cancelled',
        color: 'text-muted',
        data: response.filter(data => data.booking_status === Constants.BOOKING.CANCELED)
      },
      {
        title: 'No Show',
        color: 'grey-dark-cl',
        data: response.filter(data => data.booking_status === Constants.BOOKING.NOSHOW)
      },
    ];

    if (bookingFilter && bookingStatusData.find(x => x.title === bookingFilter)) {
      bookingList = bookingStatusData.find(x => x.title === bookingFilter).data;
    }

    return (
      <div className="screen-container">
        <Helmet>
          <title>Booking Room Digest</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={'Booking Room'}
          description={'List of bookings rooms and its information for the selected date.'}
        />

        <div className={'row'}>
          <div className={'col-12 col-lg-12 mb-3'}>

            <div className={'row'}>
              <div className={'col-12 col-lg-12 mb-3'}>
                <div className={'row mb-3'}>
                  {bookingStatusData.map((data, i)=>(
                    <div className={'col-4 col-md-2 mb-3 align-items-center'} key={i}>
                      <div className={`card ${data.title === bookingFilter && 'lime-bg'}`}>
                        <div className="card-body ">
                          <p className={'mb-1 text-muted'}>{data.title}</p>
                          <h5 className={data.color}>{data.data.length}</h5>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>


                <ul className="list-inline list-item-mb-1">
                  <li className="list-inline-item">
                    <button className={'btn btn-sm btn-link'} disabled={true}>
                      Filter <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-1'}/>
                    </button>
                  </li>
                  {bookingStatusData.map((data, i)=>(
                    <li key={i} className="list-inline-item mr-3">
                      <button
                        className={`btn ${data.title === bookingFilter ? 'btn-primary' : 'btn-outline-secondary' } btn-sm`}
                        onClick={() => this.setState({
                          bookingFilter: data.title === bookingFilter ? null : data.title
                        })}
                      >
                        {data.title} ({data.data.length})
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className={'table-responsive'}>
              <table className={'table table border'}>
                <thead>
                  <tr>
                    <th className="data-table-cell-md">Booking Ref</th>
                    <th className="data-table-cell-xl" colSpan={2}>Booking Date</th>
                    <th className="data-table-cell-lg">Guest</th>
                    <th className="data-table-cell-md">Room</th>
                    <th className="data-table-cell-lg" colSpan={2}>Meta Data</th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderData(bookingList)}
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      showPAOverview: false,
      showPAOrderData: {},
      response: [],
      bookingFilter: null,
      date: Moment().format('YYYY-MM-DD')
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingRoomDigest);
