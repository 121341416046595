/**
 *  POS Category Manage
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Actions
import * as POSActions from '../../../redux/pos/actions';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {ErrorMessages, Strings} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faSave} from '@fortawesome/free-solid-svg-icons';
// Form
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Form, Input, SubmitBtn, Textarea} from 'react-formik-ui';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pos: state.pos.pos,
  category: state.pos.category,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPOSCategory: POSActions.setPOSCategory,
};

/* Component ==================================================================== */

class POSCategoryManage extends Component {
  static componentName = 'POSCategoryManage';

  static propTypes = {
    match: PropTypes.object,
  };

  componentDidMount = () => {
    if (this.props.match.params.POSCategoryId) {
      this.props.setPOSCategory(this.props.match.params.POSCategoryId, this.props.match.params.POSId)
        .then(() => {
          this.fetchInitData();
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.fetchInitData();
    }
  };

  addCategory = (credentials) => {
    const {pos} = this.props;
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.posapi.post(`pos-category-settings/?pos=${pos.hash}`,
          {
            'name': credentials.name,
            'short_description': credentials.short_description,
            'description': credentials.description,
          })
          .then((res) => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.setPOSCategory(res);
                  this.props.history.goBack();
                  // this.props.history.replace(this.props.location.pathname.replace(/[^/]*$/, `${res.hash}/`));
                }, 500);
              },
            );
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  saveCategory = (credentials) => {
    const {pos} = this.props;
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.posapi.patch(`pos-category-settings/${this.props.match.params.POSCategoryId}/?pos=${pos.hash}`,
          {
            'name': credentials.name,
            'short_description': credentials.short_description,
            'description': credentials.description,
          })
          .then((res) => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.setPOSCategory(res);
                  this.props.history.goBack();
                }, 500);
              },
            );
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  fetchInitData = () => {
    const {category} = this.props;
    let initialValues = {
      'name': '',
      'short_description': '',
      'description': '',
    };
    if (this.props.match.params.POSCategoryId) {
      initialValues = {
        'name': category.name,
        'short_description': category.short_description,
        'description': category.description,
      };
    }
    let formValidation = Yup.object().shape({
      name: Yup.string().min(2, 'Too Short!').required('Required'),
    });
    this.setState({
      initialValues,
      formValidation,
      loading: false,
    });
  };

  render = () => {

    const {category} = this.props;
    const {loading, error, initialValues, formValidation, resultMsg} = this.state;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    return (

      <div className="room-type-list-manage screen-container ">
        <Helmet>
          <title>POS Category Manage</title>
        </Helmet>
        <PageHeader
          subHeader={true} history={this.props.history}
          title={(this.props.match.params.POSCategoryId ? 'Manage' : 'Add') + ' Category'}
          description={this.props.match.params.POSCategoryId ? `Edit category ${category.name}` : 'Add new category'}
        />

        <Formik
          onSubmit={(values) => this.props.match.params.POSCategoryId ? this.saveCategory(values) : this.addCategory(values)}
          validationSchema={formValidation}
          initialValues={initialValues}>
          {() => (
            <Form>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Category Information
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Input
                          required
                          name='name'
                          label={'Category Name'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-12 form-group">
                        <Input
                          name='short_description'
                          label={'Short Description'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-12 form-group">
                        <Textarea
                          name='description'
                          label={'Description'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                  <p className={''}><strong>Name : </strong> {Strings.posCategoryName}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 mt-2">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn className={'btn btn-success btn-block btn-lg mt-2'}>
                    <FontAwesomeIcon
                      className={'white-cl mr-2'}
                      icon={this.props.match.params.POSCategoryId ? faSave : faPlus} size={'sm'}
                    /> {this.props.match.params.POSCategoryId ? 'Save' : 'Add'}
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>


      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      initialValues: {},
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(POSCategoryManage);
