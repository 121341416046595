/**
 * POSKOTList
 *  List view for POS KOT items
 *
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// Const & Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import printerAPI from '../../../lib/printerAPI';
// Components
import ModelPDFView from '../../../components/general/ModelPDFView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight, faPrint, faReceipt } from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */
class POSKOTList extends Component {
  static componentName = 'POSKOTList';

  static propTypes = {
    kots: PropTypes.object.isRequired,
    posDetails: PropTypes.object.isRequired,
  };

  static defaultProps = {
    disabled: false,
  };

  triggerPrint = (printURL) => {
    const {posDetails} = this.props;
    this.setState({error: null});
    if (posDetails.print_allow_LPRP) {
      printerAPI(this.props.posDetails, 'post', 'printers/print', {
        url: printURL
      })
        .catch((error) => {
          console.log(error);
          this.setState({
            error: AppAPI.handleError(error),
            showModelPDFView: true,
            urlModelPDFView: printURL
          });
        });
    } else {
      this.setState({showModelPDFView: true, urlModelPDFView: printURL});
    }
  };

  render = () => {
    const {kots, posDetails} = this.props;
    return (
      <div>
        {!_.isEmpty(kots) &&
          <div>
            <h5 className={'mb-4'}>
              <FontAwesomeIcon icon={faReceipt} className={'mr-2'} size={'sm'}/> KOT(s)
            </h5>
            <div className="table-responsive border format-table">
              <table className={'table table-sm'}>
                <thead>
                  <tr>
                    <th className={'data-table-cell-md'}>KOT #</th>
                    <th className={'data-table-cell-sm'}/>
                    <th className={'data-table-cell-sm'}>User</th>
                    <th className={'data-table-cell-xm'}>Date</th>
                    <th/>
                  </tr>
                </thead>
                <tbody>
                  {kots.map((data, key) => (
                    <tr key={key}>
                      <td><strong>{data.kot_id}</strong></td>
                      <td>
                        <span className={`badge ${AppUtil.posOrderKotStatusColor(data.kot_status)}`}>
                          {data.kot_status_display}
                        </span>
                      </td>
                      <td>{!_.isEmpty(data.attributes) ? data.attributes.created_user : '...'}</td>
                      <td><small>{AppUtil.formatDateTime(data.created)}</small></td>
                      <td className={'text-center'}>
                        <button
                          className={'btn btn-sm btn-outline-primary mb-0'}
                          onClick={() => this.triggerPrint(data.resources_link)}
                        >
                          <FontAwesomeIcon icon={faPrint} size={'sm'} className={'mr-2'}/> Print
                        </button>
                        <Link to={`/point-of-sale/${posDetails.hash}/pos-order-kot/${data.hash}`}
                          className={'btn btn-sm btn-outline-primary ml-2 mb-0'}>
                        Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        }
        <ModelPDFView
          title={'Print KOT'}
          error={this.state.error}
          show={this.state.showModelPDFView}
          pdfUrl={this.state.urlModelPDFView}
          onHide={() => this.setState({showModelPDFView: false, urlModelPDFView: null})}
        />
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      urlModelPDFView: null,
      showModelPDFView: false,
    };
  }
}

/* Export Component ==================================================================== */
export default (POSKOTList);

