/**
 *
 * PaymentList
 * List Payments
 */
import queryString from 'query-string';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import Moment from 'moment';
import _ from 'lodash';
// Consts and Libs
import AppWebUtil from '../../../lib/webUtils';
import AppUtil from '../../../lib/util';
import AppAPI from '../../../lib/api';
import {Constants, Filters, Strings} from '../../../constants';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import SearchBar from '../../../components/forms/SearchBar';
import FilterButton from '../../../components/dashboard/FilterButton';
import FilterDate from '../../../components/dashboard/FilterDate';
import PageHeader from '../../../components/dashboard/PageHeader';
import ExportButton from '../../../components/dashboard/ExportButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLink, faLongArrowAltRight, faSync} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PGatewayList extends Component {
  static componentName = 'PGatewayList';

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };

  componentDidMount = () => {
    let urlParams = {}, startDate = null, endDate = null;
    switch (this.getQueryParams('payment_status')) {
    case 'paid':
      urlParams['payment_status'] = Constants.PGATEWAY_STATUS.PAID;
      break;
    case 'cancelled':
      urlParams['payment_status'] = Constants.PGATEWAY_STATUS.CANCELLED;
      break;
    case 'not-paid':
      urlParams['payment_status'] = Constants.PGATEWAY_STATUS.NOTPAID;
      break;
    case 'failed':
      urlParams['payment_status'] = Constants.PGATEWAY_STATUS.FAILED;
      break;
    case 'pending':
      urlParams['payment_status'] = Constants.PGATEWAY_STATUS.PENDING;
      break;
    case 'action':
      urlParams['payment_status'] = Constants.PGATEWAY_STATUS.ACTION;
      break;
    case 'waiting':
      urlParams['payment_status'] = Constants.PGATEWAY_STATUS.WAITING;
      break;
    case 'aborted':
      urlParams['payment_status'] = Constants.PGATEWAY_STATUS.ABORTED;
      break;
    default:
      urlParams = {};
    }

    if (this.getQueryParams('start_date')) {
      startDate = Moment(String(this.getQueryParams('start_date')));
    }
    if (this.getQueryParams('end_date')) {
      endDate = Moment(String(this.getQueryParams('end_date')));
    }

    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('start_date'), this.getQueryParams('end_date'), 'created_date');

    if (this.props.guestId) {
      urlParams += `&guest_data=${this.props.guestId}`;
    }

    if (this.props.corporateId) {
      urlParams += `&corporate_data=${this.props.corporateId}`;
    }

    this.setState({loading: false, urlParams, startDate, endDate});
  };

  fetchData = (page = 1, callback, searchText, download) => {
    const urlParams = {...this.state.urlParams};
    let baseUrl = 'pgateway';
    urlParams['page'] = page;

    if (searchText) {
      this.setState({searching: true, clear: true});
      baseUrl = 'pgateway-search';
      urlParams['search'] = searchText;
    }

    if (download) {
      urlParams['download'] = download.file;
      urlParams['all_data'] = true;
      this.setState({fileDownload: true});
    }

    AppAPI.pgatewayapi.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (download) {
          let fileName = `Payment-Links-Report-${AppUtil.formatDateTime(Moment(), 'short')}.${download.file}`;
          AppWebUtil.downloadFile(res, fileName);
        } else {
          if (res.results.length > 0) {
            callback(res.results, {allLoaded: !res.next});
          } else {
            callback();
          }
        }
        this.setState({
          searching: false,
          clear: false,
          refresh: false,
          loading: false,
          fileDownload: false,
          page: page,
        });
      })
      .catch((err) => {
        if (callback) {
          callback([], {allLoaded: true});
        }
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, refresh: false, loading: false, fileDownload: false, error});
      });
  };

  renderRow = (data, key) => {

    let link;

    if (!_.isEmpty(data.attributes) && data.attributes.payment_hash) {
      link = `/payment/${data.attributes.payment_hash}/payment-link/`;
    } else if (!_.isEmpty(data.payment) && data.payment.hash) {
      link = `/payment/${data.payment.hash}/payment-link/`;
    } else {
      link = `/payment/payment-gateway/${data.hash}`;
    }

    return (
      <tr className={'tr-align-middle'} key={key}>
        <td className={'data-table-cell-md'}><Link to={link}><small>{data.invoice_no}</small></Link></td>
        <td className={'data-table-cell-md text-left'}><strong>{`${data.currency} ${data.total}`}</strong></td>
        <td className={'data-table-cell-md text-left'}>
          <span className={'badge ' + (AppUtil.pGatewayStatusColor(data.payment_status))}>
            {data.payment_status_display}
          </span>
        </td>
        <td className={'data-table-cell-md'}>{data.payment_gateway ? data.payment_gateway_display : ''}</td>
        <td className={'data-table-cell-md'}>
          <small>{data.payment_time ? AppUtil.formatDateTime(data.payment_time) : ''}</small>
        </td>
        <td className={'data-table-cell-md'}><small>{AppUtil.formatDateTime(data.created)}</small></td>
        <td className={'data-table-cell-md'}>
          <Link to={link}>
            Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
          </Link>
        </td>
      </tr>
    );
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={7}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faLink}/> No Payment Links </h4>
            {Strings.pGatewayEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };

  tableHead = () => {
    return (
      <tr>
        <th>Invoice ID</th>
        <th className={'text-left'}>Amount</th>
        <th />
        <th>Gateway</th>
        <th>Payment Time</th>
        <th>Date</th>
        <th/>
      </tr>
    );
  };


  render = () => {
    const {loading, error, results, fileDownload, refresh} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="payment-list screen-container">
        <Helmet>
          <title>Payment Links List</title>
        </Helmet>

        <PageHeader
          history={this.props.history} title={'Payment Link\'s'}
          description={'All payments links in the property. Choose category to filter payments.'}
        />

        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-9'}>
            <ul className="list-inline">
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.setState({refresh: true})}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                </button>
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Payment Status'}
                  data={Filters.pGatewayStatus}
                  selectKey={this.getQueryParams('payment_status')}
                  action={(data) => this.setQueryParams([{key: 'payment_status', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data))}
                  size={'sm'}
                />
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-3 text-center text-sm-right'}>
            <ExportButton
              size={'sm'}
              files={['csv']}
              action={(key) => this.fetchData(this.state.page, null, null, {file: key})}
              loading={fileDownload}
              disabled={!this.state.startDate}
            />
          </div>
        </div>


        <div className="row">
          <div className="col-12">
            <SearchBar
              refresh={refresh}
              results={results}
              tableHeader={this.tableHead}
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              searchFunction={this.fetchData}
              placeHolder="Payment Reference or Payment Link Reference"
              colSpan={7}
            />
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      searching: false,
      clear: false,
      results: [],
      urlParams: {},
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PGatewayList);
