/**
 *
 * Booking Details view
 */
/* eslint no-eval: 0 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Consts and Libs
import {Constants} from '../../../constants';
import AppUtil from '../../../lib/util';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */

const BOOKING_VALUES = {
  BOOKED: Constants.BOOKING.BOOKED,
  NOSHOW: Constants.BOOKING.NOSHOW,
  CANCELED: Constants.BOOKING.CANCELED,
};

class BookingAnalyticsDetailsView extends Component {
  static componentName = 'BookingAnalyticsDetailsView';

  static propTypes = {
    responseData: PropTypes.object.isRequired,
  };


  componentDidMount = () => {
  };


  processBookingStatusData = (data, dataSourceKey) => {
    const tabledata = [];
    let total = 0;

    Object.keys(BOOKING_VALUES).forEach((key) => {
      const value = parseInt(eval(`data.${dataSourceKey}[${BOOKING_VALUES[key]}]`));
      total += value;
      tabledata.push(
        <td
          className={`align-middle ${value < 1 ? 'grey-light-bg' : 'white-bg'} `}
          key={key}
        >
          {value}
        </td>,
      );
    });
    return (
      <React.Fragment>
        {tabledata}
        <td className={'align-middle'}><strong>{total}</strong></td>
      </React.Fragment>
    );
  }

  render = () => {
    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const {booking_data} = this.props.responseData;

    const roomStatus = <React.Fragment>
      <th className={'data-table-cell-md'}>Booked</th>
      <th className={'data-table-cell-md'}>Cancelled</th>
      <th className={'data-table-cell-md'}>No Show</th>
      <th className={'data-table-cell-md'}>Total</th>
    </React.Fragment>;
    return (
      <div className="dashboard screen-container">
        <Helmet>
          <title>Booking Analytics</title>
        </Helmet>
        <div className="table-responsive border">
          <table className={'table table-bordered fixed-table text-center border'}>
            <thead className={'bg-dark text-light'}>
              <tr>
                <th className={'data-table-cell-xl bg-dark text-light'}/>
                <th className={'align-middle blue-bg text-light'} colSpan={9}>Booking Information</th>
                <th className={'align-middle green-bg text-light'} colSpan={4}>Room(s)</th>
                <th className={'align-middle orange-bg text-light'} colSpan={4}>Room Night(s)</th>
                <th className={'align-middle yellow-bg text-dark'} colSpan={3}>Revenue</th>
              </tr>
              <tr>
                <th className={'data-table-cell-xl bg-dark text-light'}>Booking Ref</th>
                <th className={'data-table-cell-lg'}>Booking ID</th>
                <th className={'data-table-cell-md'}>Status</th>
                <th className={'data-table-cell-xl'}>Name</th>
                <th className={'data-table-cell-lg'}>Check In</th>
                <th className={'data-table-cell-lg'}>Check Out</th>
                <th className={'data-table-cell-lg'}>Source</th>
                <th className={'data-table-cell-lg'}>OTA</th>
                <th className={'data-table-cell-lg'}>Created by</th>
                <th className={'data-table-cell-lg'}>Created on</th>
                {roomStatus}
                {roomStatus}
                <th className={'data-table-cell-md'}>Paid</th>
                <th className={'data-table-cell-md'}>Refunded</th>
                <th className={'data-table-cell-md'}>Pending</th>
              </tr>
            </thead>
            <tbody>
              {_.isEmpty(booking_data) ?
                <React.Fragment>
                  <tr>
                    <td className={'data-table-cell-md bg-dark text-light'}/>
                    <td colSpan={11} className={'text-center'}>
                      <p>No Data</p>
                    </td>
                  </tr>
                </React.Fragment>
                :
                <React.Fragment>
                  {booking_data.map((data, i) => (
                    <tr key={i}>
                      <td className={'bg-dark text-light text-left'}>
                        {data.booking_data.ref_no}
                        <Link to={`/booking/${data.booking_data.hash}`} rel='noopener noreferrer' target={'_blank'}>
                          <FontAwesomeIcon icon={faExternalLinkAlt} size={'sm'} className={'ml-2'}/>
                        </Link>
                      </td>
                      <td>{data.booking_data.booking_id}</td>
                      <td className={'text-center'}>
                        <span className={'badge ' + (AppUtil.bookingStatusColor(data.booking_data.booking_status))}>
                          {data.booking_data.booking_status_display}
                        </span>
                      </td>
                      <td>{!_.isEmpty(data.booking_data.guest) ? data.booking_data.guest.name : '...'}</td>
                      <td>{AppUtil.formatDateTime(data.booking_data.checkin, 'datef')}</td>
                      <td>{AppUtil.formatDateTime(data.booking_data.checkout, 'datef')}</td>
                      <td>{data.booking_data.booking_source_display}</td>
                      <td>
                        {(data.booking_data.attributes && data.booking_data.attributes.booking_ota_name) ? data.booking_data.attributes.booking_ota_name : '...'}
                      </td>
                      <td>{!_.isEmpty(data.booking_data.created_user) ? data.booking_data.created_user.display_name : '...'}</td>
                      <td><small>{AppUtil.formatDateTime(data.booking_data.created)}</small></td>
                      {this.processBookingStatusData(data, 'booking_room_count')}
                      {this.processBookingStatusData(data, 'booking_room_night_count')}
                      <td>
                        {data.order_value[Constants.ORDER_STATUS.TOTAL_PAID]}
                      </td>
                      <td>
                        {data.order_value[Constants.ORDER_STATUS.TOTAL_REFUNDED]}
                      </td>
                      <td>
                        {data.order_value[Constants.ORDER_STATUS.NOTPAID]}
                      </td>
                    </tr>
                  ))
                  }

                </React.Fragment>
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loading_inner: false,
      error: null,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingAnalyticsDetailsView);
