/**
 *
 * Property Booking Engine Settings
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {NavLink, Route, Switch} from 'react-router-dom';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import PropertyBookingEngineOverview from './PropertyBookingEngineOverview';
import PropertyBookingEngineContent from './PropertyBookingEngineContent';
import PropertySliderImageList from './PropertySliderImageList';
import PropertyBookingEngineContentManage from './PropertyBookingEngineContentManage';
import PropertyBookingEngineRules from './PropertyBookingEngineRules';
import PropertyBookingEngineRulesManage from './PropertyBookingEngineRulesManage';
import PropertyBookingEngineInventoryConfig from './PropertyBookingEngineInventoryConfig';

import PropertyBookingEngineAmenities from './PropertyBookingEngineAmenities';

import PropertyBookingEngineContentPageList from './PropertyBookingEngineContentPageList';
import PropertyBookingEngineContentPageManage from './PropertyBookingEngineContentPageManage';
import PropertyBookingEngineContentPageDetails from './PropertyBookingEngineContentPageDetails';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PropertyBookingEngine extends Component {
  static componentName = 'PropertyBookingEngineSettings';

  componentDidMount = () => {
  };


  render = () => {
    const {loading, error} = this.state;

    if (!this.props.property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;


    let baseUrl = '/settings/property/booking-engine';
    const menu = [
      {
        title: 'Overview',
        link: `${baseUrl}/`,
        isExact: true,
      },
      {
        title: 'Inventory Config',
        link: `${baseUrl}/inventory-config`,
        isExact: true,
      },
      {
        title: 'Content',
        link: `${baseUrl}/content/`,
      },
      {
        title: 'Rules/FAQ',
        link: `${baseUrl}/rules/`,
      },
      {
        title: 'Gallery',
        link: `${baseUrl}/gallery-images/`,
      },
      {
        title: 'Pages',
        link: `${baseUrl}/pages/`,
      },
      {
        title: 'Amenities',
        link: `${baseUrl}/amenities/`,
      },
    ];

    let menuItems = [];
    menu.map((item) => {
      const {title, link, isExact} = item;
      return menuItems.push(
        <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
          <NavLink
            to={{
              pathname: link,
              state: {fromDashboard: true},
            }}
            exact={isExact}
            className="nav-link rounded-0"
            activeClassName="selected">
            {title}
          </NavLink>
        </li>,
      );
    });

    return (
      <div className="channel-manager-settings screen-container">
        <Helmet>
          <title>Booking Engine Settings</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'Booking Engine Settings'}
        />

        <ul className="nav nav-tabs mb-3">
          {menuItems}
        </ul>
        <Switch>
          <Route path={baseUrl} exact component={PropertyBookingEngineOverview}/>
          <Route path={`${baseUrl}/inventory-config`} exact component={PropertyBookingEngineInventoryConfig}/>
          <Route path={`${baseUrl}/amenities`} exact component={PropertyBookingEngineAmenities}/>
          <Route path={`${baseUrl}/content`} exact component={PropertyBookingEngineContent}/>
          <Route path={`${baseUrl}/content/manage`} exact component={PropertyBookingEngineContentManage}/>
          <Route path={`${baseUrl}/pages`} exact component={PropertyBookingEngineContentPageList}/>
          <Route path={`${baseUrl}/pages/new`} exact component={PropertyBookingEngineContentPageManage}/>
          <Route path={`${baseUrl}/pages/:pageId/`} exact component={PropertyBookingEngineContentPageDetails}/>
          <Route path={`${baseUrl}/pages/:pageId/manage`} exact component={PropertyBookingEngineContentPageManage}/>


          <Route path={`${baseUrl}/rules`} exact component={PropertyBookingEngineRules}/>
          <Route path={`${baseUrl}/rules/manage`} exact component={PropertyBookingEngineRulesManage}/>
          <Route
            path={`${baseUrl}/content/property-slider-images/`} exact
            render={(props) => <PropertySliderImageList {...props} hideHeader={true}/>}
          />
          <Route
            path={`${baseUrl}/gallery-images/`} exact
            render={(props) => <PropertySliderImageList {...props} isGallery={true} hideHeader={true}/>}
          />
        </Switch>


      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyBookingEngine);
