import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
// Consts and Libs
import AppAPI from '../../lib/api';
// Components
import Error from '../general/Error';
import Loading from '../general/Loading';
// Actions
import * as BookingActions from '../../redux/booking/actions';
import * as BookingRoomActions from '../../redux/bookingroom/actions';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  user: state.user,
  booking: state.booking.booking,
  bookingRoom: state.bookingroom.bookingroom,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setBooking: BookingActions.setBooking,
  setBookingRoom: BookingRoomActions.setBookingRoom,
};

export default function (ComposedComponent) {
  class BookingRoomRequired extends Component {
    static contextTypes = {
      router: PropTypes.object,
    };

    componentDidMount() {
      if ((_.isEmpty(this.props.booking) && this.props.match.params.bookingId) || this.props.match.params.bookingId !== this.props.booking.hash) {
        this.firstLoad = true;
        this.props.setBooking(this.props.match.params.bookingId)
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({error});
          });
      }

      if ((_.isEmpty(this.props.bookingRoom) && this.props.match.params.bookingRoomId) || this.props.match.params.bookingRoomId !== this.props.bookingRoom.hash) {
        this.firstLoad = true;
        this.props.setBookingRoom(this.props.match.params.bookingRoomId)
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({error});
          });
      }
    }

    render() {
      if (this.state.error) return <Error full={false} text={this.state.error}/>;
      if (_.isEmpty(this.props.bookingRoom) || this.props.bookingRoom.hash !== this.props.match.params.bookingRoomId) return <Loading/>;
      return <ComposedComponent {...this.props} firstLoad={this.firstLoad}/>;
    }


    constructor(props) {
      super(props);
      this.firstLoad = false;
      this.state = { error: null };
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(BookingRoomRequired);
}