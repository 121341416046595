/**
 *
 * Expense Details
 */
import _ from 'lodash';
import {Collapse} from 'react-bootstrap';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import {Constants, Strings} from '../../constants';
// Components
import ExpenseEdit from './ExpenseEdit';
import {Alerts, History} from '../../components/ui';
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import OrderItemListView from '../order/OrderItemListView';
import PageHeader from '../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faLockOpen,
  faLongArrowAltRight,
  faPen,
  faPlus,
  faPlusCircle,
  faStickyNote,
} from '@fortawesome/free-solid-svg-icons';
import NotesManager from '../notes/NotesManager';
import GuestListView from '../guest/GuestListView';
import NotesListView from '../notes/NotesListView';
import ExpensePaymentAdd from './ExpensePaymentAdd';
import ProfileQuickSearch from '../guest/ProfileQuickSearch';
import RecordView from '../../components/dashboard/RecordView';
import OrderItemDetailsView from '../order/OrderItemDetailsView';
import GuestDetailsOverView from '../guest/GuestDetailsOverView';
import {confirmAlert} from '../../components/general/ConfirmAlert';
import PaymentReferenceManage from '../payment/PaymentReferenceManage';
import ExpensePaymentDetails from './ExpensePaymentDetails';
import ExpenseRefundAdd from './ExpenseRefundAdd';
import ExpenseRefundDetails from './ExpenseRefundDetails';
// Actions
import * as ExpenseActions from '../../redux/expense/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  expense: state.expense.expense,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setExpense: ExpenseActions.setExpense,
};


/* Component ==================================================================== */
class ExpenseDetails extends Component {
  static componentName = 'ExpenseDetails';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  cacheReset = () => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.expense
        .post(`${this.props.expense.hash}/cache-reset/`, {})
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            this.props.setExpense(res);
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            resultMsg: {error},
          });
        });
    });
  };

  escalatedPermissionUpdated = (payload) => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.expense
          .post(`${this.props.expense.hash}/escalated-permission/`, payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              this.props.setExpense(res);
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  updateOrder = (payload) => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.expense
          .patch(`${this.props.expense.hash}/`, payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              this.props.setExpense(res);
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  additionalUpdateOrder = (payload) => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.expense
          .post(`${this.props.expense.hash}/additional-details/`, payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({
                  showProfileDetails: false,
                  showGuestManagement: false,
                  showProfileSearch: false,
                  resultMsg: {success: ''},
                });
                this.props.setExpense(res);
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  componentDidMount = () => {
    if (this.props.match.params.expenseOrderId) {
      this.fetchInitData();
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };

  fetchInitData = () => {
    this.setState({
      loading: true,
    }, () => {
      this.props.setExpense(this.props.match.params.expenseOrderId)
        .then(() => {
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    });
  };

  showProfileDetails = (guestId, add_guest = false) => {
    this.setState({profileDetailsData: guestId, showProfileDetails: true, addProfile: add_guest});
  };

  profileActions = (profile) => {
    this.additionalUpdateOrder({vendor_data: this.state.addProfile ? profile.id : null});
  };

  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {expense, property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    return (
      <div className="expense-details screen-container ">
        <Helmet>
          <title>Expense Details : {expense.ref_no}</title>
        </Helmet>

        <PageHeader
          history={this.props.history}
          title={'Expense details'}
          description={'Reference number : ' + expense.ref_no}
        />

        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
            <div className={'row'}>
              <div className={'col'}>
                <p className={'mb-0 text-muted'}>Total</p>
                <div className={'d-inline-flex align-items-baseline'}>
                  <h2>
                    {expense.currency + ' ' + expense.total}
                  </h2>
                  <div className={'align-items-baseline'}>
                    <span className={`ml-2 badge ${AppUtil.paymentStatusColor(expense.order_status)}`}>
                      {expense.order_status_display}
                    </span>
                    {!expense.lock && <span className={'ml-2 badge badge-warning'}>Open Expense</span>}
                  </div>
                </div>
              </div>
            </div>
            <hr/>
            <div className={'row'}>
              <div className={'col-lg-6 col-md-12'}>
                <table className={'table table-borderless table-sm mb-3'}>
                  <tbody>
                    {expense.order_id &&
                     <tr>
                       <td className={'w-50 text-muted'}>Expense ID</td>
                       <td><strong>{expense.order_id}</strong></td>
                     </tr>
                    }
                    {expense.attributes && expense.attributes.category &&
                     <tr>
                       <td className={'w-50 text-muted'}>Category</td>
                       <td>{expense.attributes.category}</td>
                     </tr>
                    }
                    {expense.attributes && expense.attributes.department &&
                     <tr>
                       <td className={'w-50 text-muted'}>Department</td>
                       <td>{expense.attributes.department}</td>
                     </tr>
                    }
                    <tr>
                      <td className={'w-50 text-muted'}>Document Number</td>
                      <td>{expense.expense_order_number || '...'}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={'col-lg-6 col-md-12'}>
                <table className={'table table-borderless table-sm mb-3'}>
                  <tbody>
                    <tr>
                      <td className={'w-50 text-muted'}>Tax
                        Number {property && property.tax_number_type && `(${property.tax_number_type})`}</td>
                      <td>{expense.tax_number || '...'}</td>
                    </tr>
                    <tr>
                      <td className={'w-50 text-muted'}>Tax Company</td>
                      <td>{expense.tax_company || '...'}</td>
                    </tr>
                    {parseFloat(expense.total_unpaid_payment) > 0 && (
                      <tr>
                        <td className={'w-50 text-muted'}>Pending Payment</td>
                        <td><strong>{expense.currency} {expense.total_unpaid_payment}</strong></td>
                      </tr>
                    )}

                    {parseFloat(expense.total_refunded) > 0 && (
                      <React.Fragment>
                        <tr>
                          <td className={'w-50 text-muted'}>Refunded Payment</td>
                          <td><strong>{expense.currency} {expense.total_refunded}</strong></td>
                        </tr>
                        <tr>
                          <td className={'w-50 text-muted'}>Net Payment</td>
                          <td><strong>{expense.currency} {expense.total_available_fund}</strong></td>
                        </tr>
                      </React.Fragment>
                    )}
                  </tbody>
                </table>
              </div>
            </div>


            {expense.vendor && expense.vendor.id &&
             <div className={'row'}>
               <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                 <h6>Vendor</h6>
                 <GuestListView
                   data={expense.vendor}
                   action={() => expense.vendor.id ? this.showProfileDetails(expense.vendor.id) : null}
                 />
               </div>
             </div>
            }
            <div className={'spacer-20'}/>

            <div className={'mb-3'}>
              <table className="table border">
                <tbody>
                  {(!_.isEmpty(expense.order_items)) ?
                    <React.Fragment>
                      {expense.order_items.map((data, i) => {
                        if (data.status) {
                          return (
                            <OrderItemListView
                              key={i}
                              currency={expense.currency}
                              lock={expense.lock}
                              data={data}
                              onClick={(data) => this.setState({showOrderItem: true, orderItem: data})}
                            />
                          );
                        }
                        return null;
                      })
                      }
                    </React.Fragment>
                    :
                    <tr>
                      <td colSpan={3}>
                        <div className="text-center">
                          <FontAwesomeIcon icon={faExclamationTriangle} size={'2x'} className={'mt-3 red-cl'}/>
                          <p className={'text-secondary small'}>No items in this order</p>
                        </div>
                      </td>
                    </tr>
                  }
                  {!expense.lock &&
                   <tr>
                     <td colSpan={3} className={'text-center'}>
                       <button
                         type="button" className="btn btn-link btn-sm m-0 p-0" data-toggle="modal"
                         onClick={() => this.setState({
                           showOrderItem: true,
                           orderItem: null,
                         })}><FontAwesomeIcon icon={faPlus} size={'sm'}/> Add new item
                       </button>
                     </td>
                   </tr>
                  }
                </tbody>
              </table>

              <table className="table border">
                <tbody>
                  <tr>
                    <td className="text-left">Sub Total</td>
                    <td className="text-right">{expense.currency} {expense.sub_total}</td>
                  </tr>
                  {parseFloat(expense.complimentary_total) > 0 &&
                   <tr>
                     <td className="text-left">Complimentary</td>
                     <td className="text-right">{expense.currency} {expense.complimentary_total}</td>
                   </tr>
                  }

                  {parseFloat(expense.discount_total) > 0 &&
                   <tr>
                     <td className="text-left">
                       Discount
                       <button
                         className={'btn btn-sm btn-link p-0 m-0 ml-2'}
                         onClick={() => this.setState({discountDetails: !this.state.discountDetails})}
                       >
                         Discount Information
                       </button>
                       <Collapse in={this.state.discountDetails}>
                         <table className={'table table-sm small text-secondary border mt-2'}>
                           <tbody>
                             <tr>
                               <td className={'small'}>Discount Pre Tax</td>
                               <td className={'text-right small'}>{expense.currency} {expense.discount_total_pre_tax}</td>
                             </tr>
                             <tr>
                               <td className={'small'}>Discount Post Tax</td>
                               <td className={'text-right small'}>{expense.currency} {expense.discount_total_post_tax}</td>
                             </tr>
                           </tbody>
                         </table>
                       </Collapse>
                     </td>
                     <td className="text-right">- {expense.currency} {expense.discount_total}</td>
                   </tr>
                  }

                  <tr>
                    <td className="text-left">
                      Tax
                      {expense.tax_expanded && expense.tax_expanded.tax_data && expense.tax_expanded.tax_data.length > 0 &&
                       <React.Fragment>
                         <button
                           className={'btn btn-sm btn-link p-0 m-0 ml-2'}
                           onClick={() => this.setState({taxDetails: !this.state.taxDetails})}>
                           Tax Information
                         </button>
                         <Collapse in={this.state.taxDetails}>
                           <table className={'table table-sm small text-secondary border mt-2'}>
                             <thead>
                               <tr>
                                 <th className={'small text-dark'}>Tax Name</th>
                                 <th className={'text-right text-dark small'}>Tax Value</th>
                               </tr>
                             </thead>
                             <tbody>
                               {expense.tax_expanded.tax_data.map((data, i) => (
                                 <tr key={i}>
                                   <td className={'small'}>{data.name}</td>
                                   <td className={'text-right small'}>{expense.currency} {data.value}</td>
                                 </tr>
                               ))}
                             </tbody>
                           </table>
                         </Collapse>
                       </React.Fragment>
                      }
                    </td>
                    <td className="text-right">{expense.currency} {expense.tax_total}</td>
                  </tr>

                  <tr>
                    <td className="text-left">
                      Discount
                      {!expense.lock &&
                       <button
                         type="button" className="btn btn-link mx-1 btn-sm" data-toggle="modal"
                         onClick={() => this.setState({
                           showOrderEdit: true,
                           showOrderEditType: 'discount',
                         })}
                       >(Edit)</button>
                      }
                      {expense.order_discount_description &&
                       <p className={'mb-0 small text-muted'}>{expense.order_discount_description}</p>
                      }
                    </td>
                    <td className="text-right">{expense.currency} {expense.order_discount}</td>
                  </tr>
                  {parseFloat(expense.total_rounding) !== 0 &&
                   <tr>
                     <td className="text-left">
                       Rounding
                     </td>
                     <td className="text-right">{expense.currency} {expense.total_rounding}</td>
                   </tr>
                  }
                  <tr>
                    <td className="text-left">
                      <strong>Total</strong>
                    </td>
                    <td className="text-right">
                      <strong>{expense.currency} {expense.total}</strong></td>
                  </tr>
                </tbody>
              </table>
              <div className={'spacer-20'}/>
              {!expense.lock &&
               <div>
                 <Alerts
                   status={resultMsg.status}
                   success={resultMsg.success}
                   error={resultMsg.error}
                 />
                 <div className={'my-5'}>
                   <div className={'row'}>
                     <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                       <button
                         className={'btn btn-lg btn-block btn-secondary'}
                         onClick={() => {
                           confirmAlert({
                             title: 'Cancel Expense',
                             message: Strings.expenseCancellation,
                             buttons: [
                               {
                                 className: 'btn-danger',
                                 label: 'Cancel Expense',
                                 onClick: () => this.updateOrder({lock_order: true, void_order: true}),
                               },
                               {
                                 className: 'btn-secondary',
                                 label: 'Close',
                               },
                             ],
                           });
                         }}
                       >
                         Cancel Expense
                       </button>
                     </div>
                     <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                       <button
                         className={'btn btn-lg btn-block btn-success'}
                         onClick={() => {
                           confirmAlert({
                             title: 'Confirm Expense',
                             message: Strings.expenseConfirmation,
                             buttons: [
                               {
                                 className: 'btn btn-success',
                                 label: 'Confirm Expense',
                                 onClick: () => this.updateOrder({lock_order: true}),
                               },
                               {
                                 className: 'btn-secondary',
                                 label: 'Close',
                               },
                             ],
                           });
                         }}
                       >
                         Confirm Expense
                       </button>
                     </div>
                   </div>
                   <p className={'text-muted text-center'}>{Strings.expenseConfirmationMessage}</p>
                   <div className={'spacer-20'}/>
                 </div>
               </div>
              }


              {expense.lock &&
               <div className={'row'}>
                 <div className={'col-12'}>
                   <h5>Payment Details</h5>
                   <hr/>
                   <div className={'row'}>
                     <div className={'col-lg-6 col-md-12'}>
                       <table className={'table table-borderless table-sm'}>
                         <tbody>
                           <tr>
                             <td className={'w-50'}>Total</td>
                             <td>{expense.currency + ' ' + expense.total}</td>
                             <td>
                             </td>
                           </tr>
                           {parseFloat(expense.total_paid) > 0 &&
                            <tr>
                              <td className={'w-50'}>Paid</td>
                              <td
                                className={'w-50'}>{expense.currency + ' ' + expense.total_paid}</td>
                              <td>
                              </td>
                            </tr>
                           }
                           {parseFloat(expense.total_unpaid_payment) > 0 &&
                            <tr>
                              <td className={'w-50'}>Pending</td>
                              <td
                                className={'w-50'}>{expense.currency + ' ' + expense.total_unpaid_payment}</td>
                              <td>
                              </td>
                            </tr>
                           }

                           {parseFloat(expense.total_refunded) > 0 &&
                            <tr>
                              <td className={'w-50'}>Refunded</td>
                              <td className={'w-50'}>{expense.currency + ' ' + expense.total_refunded}</td>
                              <td/>
                            </tr>
                           }
                           {(parseFloat(expense.total_refunded) > 0) &&
                            <tr>
                              <td className={'w-50'}><strong>Net</strong></td>
                              <td className={'w-50'}><strong>{expense.currency + ' ' + expense.total_available_fund}</strong>
                              </td>
                              <td/>
                            </tr>
                           }
                         </tbody>
                       </table>
                     </div>
                   </div>
                   <div className={'spacer-20'}/>
                 </div>
               </div>
              }


              {(!_.isEmpty(expense.payments) || expense.lock) &&
               <div className={'row mb-3'}>
                 <div className={'col-12'}>
                   <h5 className={'mb-3'}>Payments</h5>
                   <table className={'table table-sm border'}>
                     <thead>
                       <tr>
                         <th>Amount</th>
                         <th/>
                         <th>Reference</th>
                         <th>Method</th>
                         <th className={'data-table-cell-xm'}>Date</th>
                         <th/>
                       </tr>
                     </thead>
                     <tbody>
                       {
                         (!_.isEmpty(expense.payments)) ?
                           <React.Fragment>
                             {expense.payments.map((data, key) => (
                               <tr key={key}>
                                 <td><strong>{`${data.currency} ${data.total}`}</strong></td>
                                 <td>
                                   <span className={'badge ' + (AppUtil.paymentStatusColor(data.payment_status))}>
                                     {data.payment_status_display}
                                   </span>
                                   {data.refund_status !== Constants.REFUND_STATUS.NOTPAID &&
                                    <span className={'ml-2 badge ' + (AppUtil.paymentStatusColor(data.refund_status))}>
                                      {data.refund_status_display}
                                    </span>
                                   }
                                 </td>
                                 <td><small>{data.ref_no}</small></td>
                                 <td>{data.payment_method_display}</td>
                                 <td><small>{AppUtil.formatDateTime(data.created)}</small></td>
                                 <td>
                                   <button
                                     className={'btn btn-link p-0 m-0'}
                                     onClick={() => this.setState({
                                       paymentData: data,
                                       showPaymentData: true,
                                     })}
                                   >
                                     Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
                                   </button>
                                 </td>
                               </tr>
                             ))}
                           </React.Fragment>
                           :
                           <React.Fragment>
                             <tr>
                               <td colSpan={6} className={'text-center py-3'}>
                                 <h5>No Payments</h5>
                                 <p className={'small'}>No Payments added to this order.</p>
                               </td>
                             </tr>
                           </React.Fragment>
                       }
                     </tbody>
                   </table>

                   {expense.allow_payment &&
                    <div className={'text-center'}>
                      <button
                        onClick={() => this.setState({showPayment: true})}
                        className={`btn ${!_.isEmpty(expense.payments) ? 'btn-link mt-3 p-0' : 'btn-success'}`}>
                        <FontAwesomeIcon icon={faPlusCircle} size={'sm'} className={'mr-2'}/> Add Payment
                      </button>
                    </div>
                   }
                   <div className={'spacer-20'}/>
                 </div>
               </div>
              }


              {(!_.isEmpty(expense.refunds)) &&
               <div className={'row'}>
                 <div className={'col-12'}>
                   <h5 className={'mb-3'}>Refunds</h5>
                   <table className={'table table-sm border'}>
                     <thead>
                       <tr>
                         <th>Amount</th>
                         <th/>
                         <th>Reference</th>
                         <th>Method</th>
                         <th className={'data-table-cell-xm'}>Date</th>
                         <th/>
                       </tr>
                     </thead>
                     <tbody>
                       {expense.refunds.map((data, key) => (
                         <tr key={key}>
                           <td><strong>{`${data.currency} ${data.total}`}</strong></td>
                           <td>
                             <span className={'badge ' + (AppUtil.paymentStatusColor(data.payment_status))}>
                               {data.payment_status_display}
                             </span>
                           </td>
                           <td><small>{data.ref_no}</small></td>
                           <td>{data.payment_method_display}</td>
                           <td><small>{AppUtil.formatDateTime(data.created)}</small></td>
                           <td>
                             <button
                               className={'btn btn-link p-0 m-0'}
                               onClick={() => this.setState({
                                 refundData: data,
                                 showRefundData: true,
                               })}
                             >
                               Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
                             </button>
                           </td>
                         </tr>
                       ))}
                     </tbody>
                   </table>
                   <div className={'spacer-20'}/>
                 </div>
               </div>
              }


              <div className={'row'}>
                {(!_.isEmpty(expense.notes)) &&
                 <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                   <h6>Notes</h6>
                   <p className={'small'}>Notes on this order.</p>
                   <div className="list-group">
                     {expense.notes.map((data, key) => (
                       <NotesListView
                         data={data}
                         key={key}
                         successAction={() => this.fetchInitData()}
                       />
                     ))}
                   </div>
                 </div>
                }
                {(expense.signature_record_signed && expense.signature_record_signed.record) &&
                 <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                   <p className={'mb-2'}>Signature record</p>
                   <figure className="figure border p-2">
                     <img
                       src={expense.signature_record_signed.record} className="figure-img img-fluid rounded"
                       alt="Signature Record 1"
                     />
                     <figcaption className="figure-caption">Signature Record.</figcaption>
                   </figure>
                 </div>
                }
              </div>

            </div>
            <RecordView record={expense.record_image_signed}/>
            <History object={expense}/>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group mb-5">
              <li className="list-group-item  align-items-center">
                <button onClick={() => this.setState({showNotesManager: true})} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faStickyNote} size={'sm'} className={'mr-2'}/> Add Note
                </button>
              </li>
            </ul>

            <ul className="list-group mb-5">
              <li
                className="list-group-item d-flex justify-content-between align-items-center"
                onClick={() => this.cacheReset()}
              >
                <small>Expense Actions</small>
              </li>
              <li className="list-group-item">
                <button
                  className="btn btn-link red-cl m-0 p-0"
                  disabled={!(expense.lock && (expense.order_status === Constants.ORDER_STATUS.NOTPAID || parseFloat(expense.total) === 0))}
                  onClick={() => {
                    confirmAlert({
                      title: 'Unlock Order',
                      message: Strings.expenseUnlockText,
                      buttons: [
                        {
                          className: 'btn-warning',
                          label: 'Unlock Expense',
                          onClick: () => this.escalatedPermissionUpdated({unlock_order: true}),
                        },
                        {
                          className: 'btn-secondary',
                          label: 'Close',
                        },
                      ],
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faLockOpen} size={'sm'} className={'mr-2'}/> Unlock Expense
                </button>
                <p className={'small text-muted mb-0'}>Requires Manager Permission.</p>
              </li>
              <li className="list-group-item">
                <button
                  className="btn btn-link m-0 p-0"
                  disabled={expense.lock}
                  onClick={() => this.setState({
                    showOrderEdit: true,
                    showOrderEditType: 'rest',
                  })}
                >
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit Expense
                </button>
                <p className={'small text-muted mb-0'}>Update Expense parameters.</p>
              </li>
              <li className="list-group-item">
                <button
                  disabled={((expense.vendor && expense.vendor.id) || expense.lock)}
                  type="button" className="btn btn-link m-0 p-0" data-toggle="modal"
                  data-target="#orderDiscountModel"
                  onClick={() => this.setState({
                    showProfileSearch: true,
                    profileSource: Constants.PROFILE_TYPE.VENDOR,
                  })}
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle} size={'sm'} className={'mr-2'}/>
                  Add Vendor
                </button>
                <p className={'small text-muted mb-0'}>Attach a vendor profile to order.</p>
              </li>
              <li className="list-group-item">
                <button
                  type="button" className="btn btn-link m-0 p-0" data-toggle="modal"
                  data-target="#orderDiscountModel"
                  disabled={expense.lock}
                  onClick={() => this.setState({
                    showPaymentReferenceManage: true,
                  })}
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle} size={'sm'}
                    className={'mr-2'}
                  />
                  {(expense.record_image_signed && expense.record_image_signed.record_2x) ? 'Edit ' : 'Add '}Reference
                </button>
                <p className={'small text-muted mb-0'}>Attach reference document.</p>
              </li>
            </ul>
          </div>
        </div>

        {/*  Payment Add */}
        <ExpensePaymentAdd
          updateResponse={() => this.fetchInitData()}
          onHide={() => this.setState({showPayment: false})}
          show={this.state.showPayment}
          order={expense}
          property={this.props.property}
        />

        {/*  Refund Add */}
        <ExpenseRefundAdd
          updateResponse={() => this.fetchInitData()}
          onHide={() => this.setState({showRefund: false})}
          onCompleteHide={() => this.setState({showPaymentData: false, showRefund: false})}
          show={this.state.showRefund}
          order={expense}
          payment={this.state.paymentData}
          property={this.props.property}
        />

        {/*  Refund Details */}
        <ExpenseRefundDetails
          show={this.state.showRefundData}
          data={this.state.refundData}
          onHide={() => this.setState({showRefundData: false})}
          onCompleteHide={() => this.setState({showPaymentData: false, showRefundData: false})}
          property={this.props.property}
          updateResponse={this.fetchInitData}
        />
        {/*  Payment Details */}
        <ExpensePaymentDetails
          show={this.state.showPaymentData}
          data={this.state.paymentData}
          onHide={() => this.setState({showPaymentData: false})}
          property={this.props.property}
          updateResponse={this.fetchInitData}
          addRefund={(data) => this.setState({showRefund: true, paymentData: data})}
          showRefund={(data) => this.setState({refundData: data, showRefundData: true})}
        />

        {/*  Profile Details */}
        <GuestDetailsOverView
          onHide={() => this.setState({showProfileDetails: false})}
          guestId={this.state.profileDetailsData}
          show={this.state.showProfileDetails}
          addGuestFunction={this.state.addProfile}
          action={this.profileActions}
          resultMsg={resultMsg}
        />


        {/*  Profile Search */}
        <ProfileQuickSearch
          onHide={() => this.setState({showProfileSearch: false})}
          show={this.state.showProfileSearch}
          showProfileDetails={this.showProfileDetails}
          isVendor={this.state.profileSource === Constants.PROFILE_TYPE.VENDOR}
        />

        {/*  Order Item*/}
        <OrderItemDetailsView
          updateResponse={this.props.setExpense}
          onHide={() => this.setState({showOrderItem: false})}
          show={this.state.showOrderItem}
          order={expense}
          type={'expense'}
          orderItem={this.state.orderItem}
          property={this.props.property}
        />

        {/* Expense Edit */}
        <ExpenseEdit
          updateResponse={this.props.setExpense}
          onHide={() => this.setState({showOrderEdit: false})}
          show={this.state.showOrderEdit}
          expense={expense}
          property={this.props.property}
          editType={this.state.showOrderEditType}
        />

        {/* Expense Reference */}
        <PaymentReferenceManage
          show={this.state.showPaymentReferenceManage}
          onHide={() => this.setState({showPaymentReferenceManage: false})}
          data={expense}
          type={'expense'}
          updateResponse={(data) => this.props.setExpense(data)}
        />

        {/* Notes */}
        <NotesManager
          expense={expense}
          show={this.state.showNotesManager}
          successAction={() => this.fetchInitData()}
          onHide={() => this.setState({showNotesManager: false})}
        />

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      taxDetails: false,
      discountDetails: false,
      orderItem: null,
      showPayment: false,
      showOrderItem: false,
      showOrderEdit: false,
      showNotesManager: false,
      profileDetailsData: null,
      showProfileDetails: false,
      showOrderEditType: 'order',
      showPaymentReferenceManage: false,
      paymentData: null,
      showPaymentData: false,
      showRefund: false,
      showRefundData: false,
      refundData: null,
      profileSource: Constants.PROFILE_TYPE.VENDOR,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ExpenseDetails);