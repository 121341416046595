/**
 * Booking Amendment Selector
 */
import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
// Consts and Libs
import AppUtil from '../../../lib/util';
import {Constants, Strings} from '../../../constants';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faBed, faPlus, faTimes} from '@fortawesome/free-solid-svg-icons';
import AppAPI from '../../../lib/api';
import SearchBar from '../../../components/forms/SearchBar';
import {Form, Input, SubmitBtn} from 'react-formik-ui';
import {Alerts} from '../../../components/ui';

/* Component ==================================================================== */

class BookingAmendmentSelector extends Component {
  static componentName = 'BookingAmendmentSelector';

  static propTypes = {
    history: PropTypes.object,
    postAction: PropTypes.func,
    bookingId: PropTypes.number,
    bookingRoomId: PropTypes.number,
    property: PropTypes.object.isRequired,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
        loading: true,
        item: null,
      });
    }
    return true;
  }


  preFillData = () => {
    this.setState(
      {
        init: false,
        loading: false,
      },
    );
  };

  addAmendmentRequest = (credentials) => {
    if (credentials && this.state.item && this.props.bookingId) {
      this.setState({resultMsg: {status: 'One moment...'}});
      const {item} = this.state;
      AppAPI.bookingapi
        .post('booking-amendment-request/', {
          booking: this.props.bookingId,
          booking_room: this.props.bookingRoomId,
          property: this.props.property.id,
          booking_amendment: item.id,
          quantity: credentials.quantity,
          sub_quantity: credentials.sub_quantity,
          confirm_status: this.state.amendmentConfirmationStatus
        })
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            if (this.props.postAction){
              this.props.postAction(res);
            }
            this.props.onHide();
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  fetchData = (page = 1, callback, searchText) => {
    const urlParams = {...this.state.urlParams};
    urlParams['page'] = page;
    if (searchText) {
      this.setState({searching: true, clear: true});
      urlParams['q'] = searchText;
    }
    const queryString = Object.keys(urlParams).map(key => key + '=' + urlParams[key]).join('&');
    AppAPI.bookingapi.get(`booking-amendment/?${queryString}`)
      .then((res) => {
        if (res.results.length > 0) {
          callback(res.results, {allLoaded: !res.next});
        } else {
          callback();
        }
        this.setState({searching: false, clear: false, loading: false,});
      })
      .catch((err) => {
        callback([], {allLoaded: true});
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, loading: false, error,});
      });
  };


  renderRow = (data, key) => {
    return (
      <div key={key} className="list-group-item">
        <div className={'row'}>
          <div className={'col-8'}>
            <h5 className="mb-1">{data.name}</h5>
            <p className="mb-1">
              Price : {`${data.currency} ${data.price_effective}`}
              {parseInt(data.sub_quantity_type) !== Constants.QUANTITY_TYPE.NOS && ` / ${data.sub_quantity_type_display} ` }
              {parseInt(data.quantity_type) !== Constants.QUANTITY_TYPE.NOS && ` / ${data.quantity_type_display} ` }
            </p>
          </div>
          <div className={'col-4 text-right'}>
            <button
              className={'btn btn-success mr-0'}
              onClick={()=>this.setState({item: data})}
            >Add</button>
          </div>
        </div>
      </div>
    );
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={8}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faBed}/> Booking Amendments </h4>
            {Strings.bookingRoomAmendmentsListEmptyText.map((data, i) => <p className={'text-left'} key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };


  render = () => {

    const {init, item, resultMsg} = this.state;
    const {show} = this.props;

    if (show && init) {
      this.preFillData();
    }
    if (!show) {
      return null;
    }

    const validationSchema = Yup.object().shape({
      quantity: Yup.number().positive().required(),
      sub_quantity: Yup.number().positive().required(),
    });

    return (
      <Modal
        {...this.props}
        size={'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {item &&
              <button className={'btn btn-outline-secondary btn-sm mr-2'} onClick={() => this.setState({item: null})}>
                <FontAwesomeIcon icon={faArrowLeft}/>
              </button>
            }
            Amendment {item ? 'Details' : 'List'}
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            quantity: 1,
            sub_quantity: 1,
          }}
          validationSchema={validationSchema}
          onSubmit={(values)=>(this.addAmendmentRequest(values))}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                {item ?
                  <div>
                    <Form className={'form-group mt-3'}>
                      <div className="">
                        <table className={'table border'}>
                          <tbody>
                            <tr>
                              <td colSpan={2}>
                                <p className={'text-muted mb-0 small'}>Amendment</p>
                                <h4 className={'mb-1'}>{item.name}</h4>
                              </td>
                            </tr>
                            {!_.isEmpty(item.tax_description) &&
                              <tr>
                                <td className={'w-25'}><strong>Tax</strong></td>
                                <td>{item.tax_description}</td>
                              </tr>
                            }
                            {
                              item.multiple === false &&
                              <tr>
                                <td colSpan={2}>
                                  <p className={'text-secondary small mb-0 text-center'}>
                                    Single quantity per order, no customization allowed.
                                  </p>
                                </td>
                              </tr>
                            }
                            <tr>
                              <td colSpan={2}>
                                <div className="form-row">
                                  <div className="col-3 form-group">
                                    <p className={''}>Price</p>
                                    <p className={'small'}>
                                      <strong>{item.currency + ' ' + item.price_effective}</strong>
                                    </p>
                                  </div>
                                  <div className="col-3 form-group">
                                    <Input
                                      required
                                      disabled={parseInt(item.sub_quantity_type) === Constants.QUANTITY_TYPE.NOS || !item.multiple}
                                      name='sub_quantity'
                                      label={item.sub_quantity_type_display}
                                      className={'form-control'}
                                    />
                                  </div>
                                  <div className="col-3 form-group">
                                    <Input
                                      required
                                      disabled={!item.multiple}
                                      name='quantity'
                                      label={item.quantity_type_display}
                                      className={'form-control'}
                                    />
                                  </div>
                                  <div className="col-3 form-group">
                                    <p className={''}>Total</p>
                                    <p className={'small'}>
                                      <strong>{item.currency + ' ' + AppUtil.formatPrice(item.price_effective * formikProps.values.quantity * formikProps.values.sub_quantity)}</strong>
                                    </p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Form>
                  </div>
                  :
                  <SearchBar
                    renderRow={this.renderRow}
                    fetchData={this.fetchData}
                    emptyView={this.emptyView}
                  />
                }
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  {item ?
                    <React.Fragment>
                      <div className={'col'}>
                        <button className={'btn btn-secondary btn-lg btn-block'} onClick={() => this.setState({item: null})}>
                          <FontAwesomeIcon className={'white-cl mr-2'} icon={faArrowLeft} size={'sm'}/> Back
                        </button>
                      </div>
                      <div className={'col'}>
                        <SubmitBtn className={'btn btn-success btn-block btn-lg'}>
                          <FontAwesomeIcon className={'white-cl mr-2'} icon={faPlus} size={'sm'}/> Add Amendment
                        </SubmitBtn>
                      </div>
                    </React.Fragment>
                    :
                    <div className={'col'}>
                      <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                      </button>
                    </div>
                  }
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      amendmentConfirmationStatus: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      item: null,
      init: true,
      urlParams: {}
    };
  }
}

/* Export Component ==================================================================== */
export default BookingAmendmentSelector;
