/**
 *
 * Booking analytics
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Consts and Libs
import AppUtil from '../../../lib/util';
import {Constants} from '../../../constants';
import Colors from '../../../constants/colors';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChartArea} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */

class BookingAnalyticsOverView extends Component {
  static componentName = 'BookingAnalyticsOverView';

  static propTypes = {
    responseData: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
  };


  renderBookingSourceRow = (dataSet, key, title) => {
    return (
      <tr>
        <td className={'text-left'}>{title}</td>
        <td>{dataSet.booking_source_count[key][Constants.BOOKING.BOOKED]}</td>
        <td>{dataSet.booking_source_room_count[key][Constants.BOOKING.BOOKED]}</td>
        <td>{dataSet.booking_source_room_night_count[key][Constants.BOOKING.BOOKED]}</td>
        <td>{dataSet.booking_source_count[key][Constants.BOOKING.NOSHOW]}</td>
        <td>{dataSet.booking_source_count[key][Constants.BOOKING.CANCELED]}</td>
        <td>{dataSet.booking_source_revenue_value[key][Constants.ORDER_STATUS.TOTAL_PAID]}</td>
        <td>{dataSet.booking_source_revenue_value[key][Constants.ORDER_STATUS.TOTAL_REFUNDED]}</td>
        <td>{dataSet.booking_source_revenue_value[key][Constants.ORDER_STATUS.NOTPAID]}</td>
      </tr>
    );
  }
  renderOtaData = (dataSet, title) => {
    const {currency} = this.props.property;
    return (
      <React.Fragment>
        <table className={'table table-bordered table-sm text-center'}>
          <thead>
            <tr>
              <th className={'text-left data-table-cell-lg'} rowSpan={2}>{title}</th>
              <th colSpan={5}>Booking(s)</th>
              <th colSpan={3}>Revenue ({currency})</th>
            </tr>
            <tr>
              <th className={'data-table-cell-sm'}>Booking(s)</th>
              <th className={'data-table-cell-sm'}>Room(s)</th>
              <th className={'data-table-cell-sm'}>Nights(s)</th>
              <th className={'data-table-cell-sm'}>No Show</th>
              <th>Canceled</th>

              <th className={'data-table-cell-sm'}>Paid</th>
              <th className={'data-table-cell-sm'}>Refunded</th>
              <th className={'data-table-cell-sm'}>Pending</th>
            </tr>
          </thead>
          <tbody>
            {_.isEmpty(dataSet) ?
              <tr>
                <td colSpan={9} className={'py-5 text-center'}>
                  <h4><FontAwesomeIcon icon={faChartArea} size={'sm'}/> .... </h4>
                </td>
              </tr>
              :
              <React.Fragment>
                {dataSet.map((data, i) => (
                  <tr key={i}>
                    <td className={'text-left'}>
                      {data.name}
                    </td>
                    <td>{data.booking_count[Constants.BOOKING.BOOKED]}</td>
                    <td>{data.booking_room_count[Constants.BOOKING.BOOKED]}</td>
                    <td>{data.booking_room_night_count[Constants.BOOKING.BOOKED]}</td>
                    <td>{data.booking_count[Constants.BOOKING.NOSHOW]}</td>
                    <td>{data.booking_count[Constants.BOOKING.CANCELED]}</td>
                    <td>{data.booking_revenue_value[Constants.ORDER_STATUS.TOTAL_PAID]}</td>
                    <td>{data.booking_revenue_value[Constants.ORDER_STATUS.TOTAL_REFUNDED]}</td>
                    <td>{data.booking_revenue_value[Constants.ORDER_STATUS.NOTPAID]}</td>
                  </tr>
                ))}
              </React.Fragment>
            }
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  render = () => {
    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const {currency, aggregate_data} = this.props.responseData;

    let bookingChartData = [], bookingRoomChartData = [], revenueChartData = [], bookingSourceChart = [],
      bookingSourceRevenueChart = [], bookingRevenueChartData = [];

    if (aggregate_data.booking_count) {
      bookingChartData.push(
        {name: 'Completed', value: aggregate_data.booking_count[Constants.BOOKING.COMPLETED], color: Colors.green},
        {name: 'Active', value: aggregate_data.booking_count[Constants.BOOKING.ACTIVE], color: Colors.yellow},
        {name: 'Upcoming', value: aggregate_data.booking_count[Constants.BOOKING.UPCOMING], color: Colors.blue},
        {name: 'Canceled', value: aggregate_data.booking_count[Constants.BOOKING.CANCELED], color: Colors.grey},
        {name: 'No Show', value: aggregate_data.booking_count[Constants.BOOKING.NOSHOW], color: Colors.greyDark},
      );

      bookingRoomChartData.push(
        {name: 'Completed', value: aggregate_data.booking_room_count[Constants.BOOKING.COMPLETED], color: Colors.green},
        {name: 'Active', value: aggregate_data.booking_room_count[Constants.BOOKING.ACTIVE], color: Colors.yellow},
        {name: 'Upcoming', value: aggregate_data.booking_room_count[Constants.BOOKING.UPCOMING], color: Colors.blue},
        {name: 'Canceled', value: aggregate_data.booking_room_count[Constants.BOOKING.CANCELED], color: Colors.grey},
        {name: 'No Show', value: aggregate_data.booking_room_count[Constants.BOOKING.NOSHOW], color: Colors.greyDark},
      );

      bookingRevenueChartData.push(
        {
          name: 'Completed',
          value: aggregate_data.booking_revenue_value[Constants.BOOKING.COMPLETED][Constants.ORDER_STATUS.PAID],
          color: Colors.green,
        },
        {
          name: 'Active',
          value: aggregate_data.booking_revenue_value[Constants.BOOKING.ACTIVE][Constants.ORDER_STATUS.PAID],
          color: Colors.yellow,
        },
        {
          name: 'Upcoming',
          value: aggregate_data.booking_revenue_value[Constants.BOOKING.UPCOMING][Constants.ORDER_STATUS.PAID],
          color: Colors.blue,
        },
        {
          name: 'Canceled',
          value: aggregate_data.booking_revenue_value[Constants.BOOKING.CANCELED][Constants.ORDER_STATUS.PAID],
          color: Colors.grey,
        },
        {
          name: 'No Show',
          value: aggregate_data.booking_revenue_value[Constants.BOOKING.NOSHOW][Constants.ORDER_STATUS.PAID],
          color: Colors.greyDark,
        },
      );

      revenueChartData.push(
        {name: 'Not Paid', value: aggregate_data.order_value[Constants.ORDER_STATUS.NOTPAID], color: Colors.red},
        {name: 'Paid', value: aggregate_data.order_value[Constants.ORDER_STATUS.PAID], color: Colors.green},
        {name: 'Part Paid', value: aggregate_data.order_value[Constants.ORDER_STATUS.PARTPAID], color: Colors.orange},
        {
          name: 'Part Refunded',
          value: aggregate_data.order_value[Constants.ORDER_STATUS.PART_REFUNDED],
          color: Colors.blue,
        },
        {name: 'Refunded', value: aggregate_data.order_value[Constants.ORDER_STATUS.REFUNDED], color: Colors.purple},
        {name: 'Canceled', value: aggregate_data.order_value[Constants.ORDER_STATUS.CANCELLED], color: Colors.grey},
      );

      bookingSourceChart.push(
        {
          name: 'Agent',
          value: aggregate_data.booking_source_count[Constants.BOOKING_SOURCE.AGENT][Constants.BOOKING.BOOKED],
          color: Colors.green,
        },
        {
          name: 'Email',
          value: aggregate_data.booking_source_count[Constants.BOOKING_SOURCE.EMAIL][Constants.BOOKING.BOOKED],
          color: Colors.blue,
        },
        {
          name: 'Walk In',
          value: aggregate_data.booking_source_count[Constants.BOOKING_SOURCE.WALKIN][Constants.BOOKING.BOOKED],
          color: Colors.purple,
        },
        {
          name: 'Phone',
          value: aggregate_data.booking_source_count[Constants.BOOKING_SOURCE.PHONE][Constants.BOOKING.BOOKED],
          color: Colors.yellow,
        },
        {
          name: 'Channel Manager',
          value: aggregate_data.booking_source_count[Constants.BOOKING_SOURCE.CHANNEL_MANAGER][Constants.BOOKING.BOOKED],
          color: Colors.orange,
        },
        {
          name: 'Booking Engine',
          value: aggregate_data.booking_source_count[Constants.BOOKING_SOURCE.BOOKING_ENGINE][Constants.BOOKING.BOOKED],
          color: Colors.lime,
        },
        {
          name: 'Corporate',
          value: aggregate_data.booking_source_count[Constants.BOOKING_SOURCE.CORPORATE][Constants.BOOKING.BOOKED],
          color: Colors.greenLight,
        },
        {
          name: 'Agent',
          value: aggregate_data.booking_source_count[Constants.BOOKING_SOURCE.OTA][Constants.BOOKING.BOOKED],
          color: Colors.grey,
        },
      );

      bookingSourceRevenueChart.push(
        {
          name: 'Agent',
          value: aggregate_data.booking_source_revenue_value[Constants.BOOKING_SOURCE.AGENT][Constants.ORDER_STATUS.TOTAL_PAID],
          color: Colors.green,
        },
        {
          name: 'Email',
          value: aggregate_data.booking_source_revenue_value[Constants.BOOKING_SOURCE.EMAIL][Constants.ORDER_STATUS.TOTAL_PAID],
          color: Colors.blue,
        },
        {
          name: 'Walk In',
          value: aggregate_data.booking_source_revenue_value[Constants.BOOKING_SOURCE.WALKIN][Constants.ORDER_STATUS.TOTAL_PAID],
          color: Colors.purple,
        },
        {
          name: 'Phone',
          value: aggregate_data.booking_source_revenue_value[Constants.BOOKING_SOURCE.PHONE][Constants.ORDER_STATUS.TOTAL_PAID],
          color: Colors.yellow,
        },
        {
          name: 'Channel Manager',
          value: aggregate_data.booking_source_revenue_value[Constants.BOOKING_SOURCE.CHANNEL_MANAGER][Constants.ORDER_STATUS.TOTAL_PAID],
          color: Colors.orange,
        },
        {
          name: 'Booking Engine',
          value: aggregate_data.booking_source_revenue_value[Constants.BOOKING_SOURCE.BOOKING_ENGINE][Constants.ORDER_STATUS.TOTAL_PAID],
          color: Colors.lime,
        },
        {
          name: 'Corporate',
          value: aggregate_data.booking_source_revenue_value[Constants.BOOKING_SOURCE.CORPORATE][Constants.ORDER_STATUS.TOTAL_PAID],
          color: Colors.greenLight,
        },
        {
          name: 'Agent',
          value: aggregate_data.booking_source_revenue_value[Constants.BOOKING_SOURCE.OTA][Constants.ORDER_STATUS.TOTAL_PAID],
          color: Colors.grey,
        },
      );

    }
    return (
      <div className="dashboard screen-container">
        <Helmet>
          <title>Booking Analytics</title>
        </Helmet>

        <div className={'row'}>
          <div className={'col-xl-4 col-md-4 col-sm-12 mb-3'}>
            <h6>Booking(s)</h6>
            <div className={'card mb-3'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col'}>
                    <small>Confirmed</small>
                    <h4 className={'card-title mb-0 pb-0 green-cl'}>
                      {aggregate_data.booking_count[Constants.BOOKING.BOOKED]}
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            {!_.isEmpty(bookingChartData) &&
             <div className={'mb-3'}>
               <ResponsiveContainer height={300}>
                 <PieChart>
                   <Pie
                     data={bookingChartData}
                     labelLine={false}
                     label={AppUtil.renderCustomizedLabel}
                     outerRadius={100}
                     fill="#8884d8"
                     dataKey="value"
                   >
                     {bookingChartData.map((entry, index) =>
                       <Cell key={`cell-${index}`} fill={entry.color}/>,
                     )}
                   </Pie>
                   <Tooltip/>
                   <Legend onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}/>
                 </PieChart>
               </ResponsiveContainer>
             </div>
            }
          </div>
          <div className={'col-xl-4 col-md-4 col-sm-12 mb-3'}>
            <h6>Room(s) Booked</h6>
            <div className={'card mb-3'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col'}>
                    <small>Confirmed</small>
                    <h4 className={'card-title mb-0 pb-0 green-cl'}>
                      {aggregate_data.booking_room_count[Constants.BOOKING.BOOKED]}
                    </h4>
                  </div>
                  <div className={'col'}>
                    <small>Room Night(s)</small>
                    <h4 className={'card-title mb-0 pb-0 yellow-cl'}>
                      {aggregate_data.booking_room_night_count[[Constants.BOOKING.BOOKED]]}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            {!_.isEmpty(bookingRoomChartData) &&
             <div className={'mb-3'}>
               <ResponsiveContainer height={300}>
                 <PieChart>
                   <Pie
                     data={bookingRoomChartData}
                     labelLine={false}
                     label={AppUtil.renderCustomizedLabel}
                     outerRadius={100}
                     fill="#8884d8"
                     dataKey="value"
                   >
                     {bookingRoomChartData.map((entry, index) =>
                       <Cell key={`cell-${index}`} fill={entry.color}/>,
                     )}
                   </Pie>
                   <Tooltip/>
                   <Legend onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}/>
                 </PieChart>
               </ResponsiveContainer>
             </div>
            }
          </div>
          <div className={'col-xl-4 col-md-4 col-sm-12 mb-3'}>
            <h6>Revenue</h6>
            <div className={'card mb-3'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col'}>
                    <small>Revenue</small>
                    <h4 className={'card-title mb-0 pb-0 green-cl'}>
                      {`${currency} ${aggregate_data.order_value[Constants.ORDER_STATUS.TOTAL_PAID]}`}
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            {!_.isEmpty(bookingRevenueChartData) &&
             <div className={'mb-3'}>
               <ResponsiveContainer height={300}>
                 <PieChart>
                   <Pie
                     data={bookingRevenueChartData}
                     labelLine={false}
                     label={AppUtil.renderCustomizedLabel}
                     outerRadius={100}
                     fill="#8884d8"
                     dataKey="value"
                   >
                     {bookingRevenueChartData.map((entry, index) =>
                       <Cell key={`cell-${index}`} fill={entry.color}/>,
                     )}
                   </Pie>
                   <Tooltip/>
                   <Legend onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}/>
                 </PieChart>
               </ResponsiveContainer>
             </div>
            }
          </div>
        </div>

        <div className={'row'}>
          <div className={'col-md-12 col-sm-12 mb-3'}>
            <table className={'table table-bordered table-sm text-center'}>
              <thead>
                <tr>
                  <th className={'text-left data-table-cell-md'} rowSpan={2}>Booking Status</th>
                  <th colSpan={3}>Booking(s)</th>
                  <th colSpan={3}>Revenue ({currency})</th>
                </tr>
                <tr>
                  <th>Booking(s)</th>
                  <th>Room(s)</th>
                  <th>Nights(s)</th>
                  <th>Paid</th>
                  <th>Refunded</th>
                  <th>Pending</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={'text-left'}>Active</td>
                  <td>{aggregate_data.booking_count[Constants.BOOKING.ACTIVE]}</td>
                  <td>{aggregate_data.booking_room_count[Constants.BOOKING.ACTIVE]}</td>
                  <td>{aggregate_data.booking_room_night_count[Constants.BOOKING.ACTIVE]}</td>
                  <td>{aggregate_data.booking_revenue_value[Constants.BOOKING.ACTIVE][Constants.ORDER_STATUS.TOTAL_PAID]}</td>
                  <td>{aggregate_data.booking_revenue_value[Constants.BOOKING.ACTIVE][Constants.ORDER_STATUS.TOTAL_REFUNDED]}</td>
                  <td>{aggregate_data.booking_revenue_value[Constants.BOOKING.ACTIVE][Constants.ORDER_STATUS.NOTPAID]}</td>
                </tr>
                <tr>
                  <td className={'text-left'}>Upcoming</td>
                  <td>{aggregate_data.booking_count[Constants.BOOKING.UPCOMING]}</td>
                  <td>{aggregate_data.booking_room_count[Constants.BOOKING.UPCOMING]}</td>
                  <td>{aggregate_data.booking_room_night_count[Constants.BOOKING.UPCOMING]}</td>
                  <td>{aggregate_data.booking_revenue_value[Constants.BOOKING.UPCOMING][Constants.ORDER_STATUS.TOTAL_PAID]}</td>
                  <td>{aggregate_data.booking_revenue_value[Constants.BOOKING.UPCOMING][Constants.ORDER_STATUS.TOTAL_REFUNDED]}</td>
                  <td>{aggregate_data.booking_revenue_value[Constants.BOOKING.UPCOMING][Constants.ORDER_STATUS.NOTPAID]}</td>
                </tr>
                <tr>
                  <td className={'text-left'}>Complete</td>
                  <td>{aggregate_data.booking_count[Constants.BOOKING.COMPLETED]}</td>
                  <td>{aggregate_data.booking_room_count[Constants.BOOKING.COMPLETED]}</td>
                  <td>{aggregate_data.booking_room_night_count[Constants.BOOKING.COMPLETED]}</td>
                  <td>{aggregate_data.booking_revenue_value[Constants.BOOKING.COMPLETED][Constants.ORDER_STATUS.TOTAL_PAID]}</td>
                  <td>{aggregate_data.booking_revenue_value[Constants.BOOKING.COMPLETED][Constants.ORDER_STATUS.TOTAL_REFUNDED]}</td>
                  <td>{aggregate_data.booking_revenue_value[Constants.BOOKING.COMPLETED][Constants.ORDER_STATUS.NOTPAID]}</td>
                </tr>
                <tr>
                  <td className={'text-left'}>Canceled</td>
                  <td>{aggregate_data.booking_count[Constants.BOOKING.CANCELED]}</td>
                  <td>{aggregate_data.booking_room_count[Constants.BOOKING.CANCELED]}</td>
                  <td>{aggregate_data.booking_room_night_count[Constants.BOOKING.CANCELED]}</td>
                  <td>{aggregate_data.booking_revenue_value[Constants.BOOKING.CANCELED][Constants.ORDER_STATUS.TOTAL_PAID]}</td>
                  <td>{aggregate_data.booking_revenue_value[Constants.BOOKING.CANCELED][Constants.ORDER_STATUS.TOTAL_REFUNDED]}</td>
                  <td>{aggregate_data.booking_revenue_value[Constants.BOOKING.CANCELED][Constants.ORDER_STATUS.NOTPAID]}</td>
                </tr>
                <tr>
                  <td className={'text-left'}>No Show</td>
                  <td>{aggregate_data.booking_count[Constants.BOOKING.NOSHOW]}</td>
                  <td>{aggregate_data.booking_room_count[Constants.BOOKING.NOSHOW]}</td>
                  <td>{aggregate_data.booking_room_night_count[Constants.BOOKING.NOSHOW]}</td>
                  <td>{aggregate_data.booking_revenue_value[Constants.BOOKING.NOSHOW][Constants.ORDER_STATUS.TOTAL_PAID]}</td>
                  <td>{aggregate_data.booking_revenue_value[Constants.BOOKING.NOSHOW][Constants.ORDER_STATUS.TOTAL_REFUNDED]}</td>
                  <td>{aggregate_data.booking_revenue_value[Constants.BOOKING.NOSHOW][Constants.ORDER_STATUS.NOTPAID]}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <hr className={'mb-4'}/>
        <div className={'row'}>
          <div className={'col-md-6 col-sm-12 mb-3'}>
            <h6>Booking Source</h6>
            {!_.isEmpty(bookingSourceChart) &&
             <div className={'mb-3'}>
               <ResponsiveContainer height={300}>
                 <PieChart>
                   <Pie
                     data={bookingSourceChart}
                     labelLine={false}
                     label={AppUtil.renderCustomizedLabel}
                     outerRadius={100}
                     fill="#8884d8"
                     dataKey="value"
                   >
                     {bookingSourceChart.map((entry, index) =>
                       <Cell key={`cell-${index}`} fill={entry.color}/>,
                     )}
                   </Pie>
                   <Tooltip/>
                   <Legend onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}/>
                 </PieChart>
               </ResponsiveContainer>
             </div>
            }
          </div>
          <div className={'col-md-6 col-sm-12 mb-3'}>
            <h6>Booking Source Revenue</h6>
            {!_.isEmpty(bookingSourceRevenueChart) &&
             <div className={'mb-3'}>
               <ResponsiveContainer height={300}>
                 <PieChart>
                   <Pie
                     data={bookingSourceRevenueChart}
                     labelLine={false}
                     label={AppUtil.renderCustomizedLabel}
                     outerRadius={100}
                     fill="#8884d8"
                     dataKey="value"
                   >
                     {bookingSourceRevenueChart.map((entry, index) =>
                       <Cell key={`cell-${index}`} fill={entry.color}/>,
                     )}
                   </Pie>
                   <Tooltip/>
                   <Legend onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}/>
                 </PieChart>
               </ResponsiveContainer>
             </div>
            }
          </div>
        </div>
        <hr className={'mb-4'}/>
        <div className={'row'}>
          <div className={'col-md-12 col-sm-12 mb-3'}>
            <table className={'table table-bordered table-sm text-center'}>
              <thead>
                <tr>
                  <th className={'text-left data-table-cell-lg'} rowSpan={2}>Source</th>
                  <th colSpan={5}>Booking(s)</th>
                  <th colSpan={3}>Revenue ({currency})</th>
                </tr>
                <tr>
                  <th className={'data-table-cell-sm'}>Booking(s)</th>
                  <th className={'data-table-cell-sm'}>Room(s)</th>
                  <th className={'data-table-cell-sm'}>Nights(s)</th>
                  <th className={'data-table-cell-sm'}>No Show</th>
                  <th className={'data-table-cell-sm'}>Canceled</th>

                  <th className={'data-table-cell-sm'}>Paid</th>
                  <th className={'data-table-cell-sm'}>Refunded</th>
                  <th className={'data-table-cell-sm'}>Pending</th>
                </tr>
              </thead>
              <tbody>
                {this.renderBookingSourceRow(aggregate_data, Constants.BOOKING_SOURCE.AGENT, 'Agent')}
                {this.renderBookingSourceRow(aggregate_data, Constants.BOOKING_SOURCE.EMAIL, 'Email')}
                {this.renderBookingSourceRow(aggregate_data, Constants.BOOKING_SOURCE.WALKIN, 'Walk In')}
                {this.renderBookingSourceRow(aggregate_data, Constants.BOOKING_SOURCE.PHONE, 'Phone')}
                {this.renderBookingSourceRow(aggregate_data, Constants.BOOKING_SOURCE.CHANNEL_MANAGER, 'Channel Manager')}
                {this.renderBookingSourceRow(aggregate_data, Constants.BOOKING_SOURCE.BOOKING_ENGINE, 'Booking Engine')}
                {this.renderBookingSourceRow(aggregate_data, Constants.BOOKING_SOURCE.CORPORATE, 'Corporate')}
                {this.renderBookingSourceRow(aggregate_data, Constants.BOOKING_SOURCE.OTA, 'OTA')}
              </tbody>

            </table>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-md-12 col-sm-12 mb-3'}>
            <h6 className={'mb-3'}>OTA Data</h6>
            {this.renderOtaData(aggregate_data.ota_data_set, 'Online Travel Agent')}
          </div>
        </div>

        <div className={'row'}>
          <div className={'col-md-12 col-sm-12 mb-3'}>
            <h6 className={'mb-3'}>User Data</h6>
            {this.renderOtaData(aggregate_data.user_data_set, 'User')}
          </div>
        </div>

        <hr className={'mb-4'}/>
        <div className={'row'}>
          <div className={'col-md-6 col-sm-12 mb-3'}>
            <h6>Revenue</h6>
            {!_.isEmpty(revenueChartData) &&
             <div className={'mb-3'}>
               <ResponsiveContainer height={300}>
                 <PieChart>
                   <Pie
                     data={revenueChartData}
                     labelLine={false}
                     label={AppUtil.renderCustomizedLabel}
                     outerRadius={100}
                     fill="#8884d8"
                     dataKey="value"
                   >
                     {revenueChartData.map((entry, index) =>
                       <Cell key={`cell-${index}`} fill={entry.color}/>,
                     )}
                   </Pie>
                   <Tooltip/>
                   <Legend onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}/>
                 </PieChart>
               </ResponsiveContainer>
             </div>
            }
          </div>
          <div className={'col-md-6 col-sm-12 mb-3'}>
            <table className={'table table-sm format-table border'}>
              <thead>
                <th className={'w-50'}>Order Status</th>
                <th>Order(s)</th>
                <th className={'w-50'}>Price ({currency})</th>
              </thead>
              <tbody>
                <tr>
                  <td>Paid</td>
                  <td>{aggregate_data.order_count[Constants.ORDER_STATUS.PAID]}</td>
                  <td>{aggregate_data.order_value[Constants.ORDER_STATUS.PAID]}</td>
                </tr>
                <tr>
                  <td>Part Paid</td>
                  <td>{aggregate_data.order_count[Constants.ORDER_STATUS.PARTPAID]}</td>
                  <td>{aggregate_data.order_value[Constants.ORDER_STATUS.PARTPAID]}</td>
                </tr>
                <tr>
                  <td>Part Refunded</td>
                  <td>{aggregate_data.order_count[Constants.ORDER_STATUS.PART_REFUNDED]}</td>
                  <td>{aggregate_data.order_value[Constants.ORDER_STATUS.PART_REFUNDED]}</td>
                </tr>
                <tr>
                  <td>Refunded</td>
                  <td>{aggregate_data.order_count[Constants.ORDER_STATUS.REFUNDED]}</td>
                  <td>{aggregate_data.order_value[Constants.ORDER_STATUS.REFUNDED]}</td>
                </tr>
                <tr>
                  <td>Not Paid</td>
                  <td>{aggregate_data.order_count[Constants.ORDER_STATUS.NOTPAID]}</td>
                  <td>{aggregate_data.order_value[Constants.ORDER_STATUS.NOTPAID]}</td>
                </tr>
                <tr>
                  <td>Canceled</td>
                  <td>{aggregate_data.order_count[Constants.ORDER_STATUS.CANCELLED]}</td>
                  <td>{aggregate_data.order_value[Constants.ORDER_STATUS.CANCELLED]}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loading_inner: false,
      error: null,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingAnalyticsOverView);
