/**
 * POS Reducer
 *  point of sales
 *
 */

// Set initial state
const initialState = {
  pos: {},
  item: {},
  table: {},
  category: {},
  posOrder: {},
  posDetails: {},
  posOrderKOT: {},
  posMembership: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
  case 'SET_POS_ORDER':
    let posOrder = [];
    if (action.data && typeof action.data === 'object') {
      posOrder = action.data;
    }

    return {
      ...state,
      posOrder,
    };

  case 'SET_POS_ORDER_KOT':
    let posOrderKOT = [];
    if (action.data && typeof action.data === 'object') {
      posOrderKOT = action.data;
    }

    return {
      ...state,
      posOrderKOT,
    };

  case 'SET_POS_MEMBERSHIP':
    let posMembership = [];
    if (action.data && typeof action.data === 'object') {
      posMembership = action.data;
    }

    return {
      ...state,
      posMembership,
    };

  case 'SET_POS_DETAILS':
    let posDetails = [];
    if (action.data && typeof action.data === 'object') {
      posDetails = action.data;
    }

    return {
      ...state,
      posDetails,
    };

  case 'SET_POS':
    let pos = [];
    if (action.data && typeof action.data === 'object') {
      pos = action.data;
    }

    return {
      ...state,
      pos,
    };

  case 'SET_POS_ITEM':
    let item = [];
    if (action.data && typeof action.data === 'object') {
      item = action.data;
    }

    return {
      ...state,
      item,
    };

  case 'SET_POS_TABLE':
    let table = [];
    if (action.data && typeof action.data === 'object') {
      table = action.data;
    }

    return {
      ...state,
      table,
    };

  case 'SET_POS_CATEGORY':
    let category = [];
    if (action.data && typeof action.data === 'object') {
      category = action.data;
    }

    return {
      ...state,
      category,
    };

  default:
    return state;
  }
};
