/**
 * API Functions
 *
 * React Native Starter App
 * https://github.com/mcnamee/react-native-starter-app
 */
/* global fetch console */
import * as Sentry from '@sentry/react';
import JWT from './api.jwt';
// Consts and Libs
import {APIConfig, AppConfig, ErrorMessages} from '../constants';

// We'll use JWT for API Authentication
// const Token = {};
const Token = new JWT();

// Config
const HOSTNAME = APIConfig.hostname;
const ENDPOINTS = APIConfig.endpoints;

// Enable debug output when in Debug mode
const DEBUG_MODE = AppConfig.DEV;

// Number each API request (used for debugging)
let requestCounter = 0;

/* Helper Functions ==================================================================== */
/**
 * Debug or not to debug
 */
function debug(str, title) {
  if (DEBUG_MODE && (title || str)) {
    if (title) {
      console.log(`=== DEBUG: ${title} ===========================`);
    }
    if (str) {
      console.log(str);
      console.log('%c ...', 'color: #CCC');
    }
  }
}

/**
 * Sends requests to the API
 */
function handleError(err) {
  let error = [];
  if (!err) {
    return ErrorMessages.default;
  }

  if (typeof err === 'string') error = err;
  else if (err.statusText) error = err.statusText;
  else if (err.message) error = err.message;
  else if (err.non_field_errors) error = err.non_field_errors.toString();
  else if (err.detail) error = err.detail;
  else if (typeof err === 'object') {
    for (let i in err) {
      if(!parseInt(i)) {
        error.push(i + ' : ' + err[i]);
      } else {
        error.push(err[i]);
      }
    }
  }
  else error = ErrorMessages.default;

  return error;
}

/**
 * Convert param object into query string
 * eg.
 *   {foo: 'hi there', bar: { blah: 123, quux: [1, 2, 3] }}
 *   foo=hi there&bar[blah]=123&bar[quux][0]=1&bar[quux][1]=2&bar[quux][2]=3
 */
function serialize(obj, prefix) {
  const str = [];

  Object.keys(obj).forEach((p) => {
    const k = prefix ? `${prefix}[${p}]` : p;
    const v = obj[p];

    str.push((v !== null && typeof v === 'object')
      ? serialize(v, k)
      : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
  });

  return str.join('&');
}

/**
 * Sends requests to the API
 */
function fetcher(method, endpoint, params, body, formData) {
  return new Promise(async (resolve, reject) => {
    requestCounter += 1;
    const requestNum = requestCounter;

    // After x seconds, let's call it a day!
    const timeoutAfter = 12;
    const apiTimedOut = setTimeout(() => (
      reject(ErrorMessages.timeout)
    ), timeoutAfter * 3000);

    if (!method || !endpoint) return reject('Missing params (AppAPI.fetcher).');

    // Build request
    const req = {
      method: method.toUpperCase(),
      headers: formData ?
        {
          'Accept': 'application/json'
        }
        : {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
    };

    // Add Endpoint Params
    let urlParams = '';
    if (params) {
      // Object - eg. /property?title=this&cat=2
      if (typeof params === 'object') {
        urlParams = `?${serialize(params)}`;

        // String or Number - eg. /property/23
      } else if (typeof params === 'string' || typeof params === 'number') {
        urlParams = `/${params}`;

        // Something else? Just log an error
      } else {
        debug('You provided params, but it wasn\'t an object!', HOSTNAME + endpoint + urlParams);
      }
    } else {
      urlParams = '/';
    }

    if (urlParams !== '/login/' && urlParams !== '/registration/') {
      const apiToken = Token.getStoredToken();
      if (apiToken) {
        req.headers.Authorization = `JWT ${apiToken}`;
      }
    }

    // Add Property as GET parameter
    // Add only if property is set
    const selectedPropertyId = localStorage.getItem(AppConfig.localStorageKeys.PROPERTY_ID);

    if (selectedPropertyId) {
      urlParams += (urlParams.indexOf('?') === -1 ? '?' : '&') + 'property=' + selectedPropertyId;
      // add slash
      urlParams = (urlParams.indexOf('/') === -1 ? '/' + urlParams : urlParams);
    }

    // Add Body
    if (body) {
      if (formData) {
        req.body = body;
      } else {
        req.body = JSON.stringify(body);
      }
    }

    const thisUrl = HOSTNAME + endpoint + urlParams;
    debug('', `API Request #${requestNum} to ${thisUrl}`);
    const invalidJSONError = {message: ErrorMessages.invalidJson};
    const sessionExpiredError = {message: ErrorMessages.userSessionExpired};
    // Make the request
    return fetch(thisUrl, req)
      .then(async (rawRes) => {
        // API got back to us, clear the timeout
        clearTimeout(apiTimedOut);

        let jsonRes = {};
        // Only continue if the header is successful
        if (rawRes) {
          // Its a download request so return raw response
          if (thisUrl.includes('download')){
            jsonRes= rawRes;
          } else {
            try {
              jsonRes = await rawRes.json();
            } catch (error) {
              throw(invalidJSONError);
            }
          }
          if ((rawRes.status === 200 || rawRes.status === 201)){
            return jsonRes;
          } else {
            // Capture exception for all error over in 5xx
            if (rawRes.status >= 500) {
              Sentry.captureException(jsonRes, {
                level: 'warning',
                tags: {
                  status: '5xx',
                }
              });
            }
            if (rawRes.status === 401) {
              // User permission denied so clear the token to prompt user to relogin
              const storageEvent = new StorageEvent('storage', {
                key: AppConfig.localStorageKeys.USER_TOKEN,
                newValue: null
              });
              localStorage.removeItem(AppConfig.localStorageKeys.USER_TOKEN);
              window.dispatchEvent(storageEvent);
              throw sessionExpiredError;
            }
            throw jsonRes;
          }
        }
        throw(invalidJSONError);
      })
      .then((res) => {
        debug(res, `API Response #${requestNum} from ${thisUrl}`);
        return resolve(res);
      })
      .catch((err) => {
        // API got back to us, clear the timeout
        clearTimeout(apiTimedOut);
        debug(err, HOSTNAME + endpoint + urlParams);
        return reject(err);
      });
  });
}

/* Create the API Export ==================================================================== */
/**
 * Build services from Endpoints
 * - So we can call AppAPI.recipes.get() for example
 */
const AppAPI = {
  handleError,
  getToken: Token.getToken,
  deleteToken: Token.deleteToken
};

ENDPOINTS.forEach((endpoint, key) => {
  AppAPI[key] = {
    get: (params, payload) => fetcher('GET', endpoint, params, payload),
    post: (params, payload, formData) => fetcher('POST', endpoint, params, payload, formData),
    patch: (params, payload, formData) => fetcher('PATCH', endpoint, params, payload, formData),
    put: (params, payload, formData) => fetcher('PUT', endpoint, params, payload, formData),
    options: (params, payload, formData) => fetcher('OPTIONS', endpoint, params, payload, formData),
    delete: (params, payload, formData) => fetcher('DELETE', endpoint, params, payload, formData)
  };
});

/* Export ==================================================================== */
export default AppAPI;
