/**
 *
 * OrderView
 * overview of orders
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class OrderView extends Component {
  static componentName = 'OrderView';
  componentDidMount = () => {
    this.fetchInitData();
  };
  fetchInitData = () => {

  };
  render = () => {
    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="order-view screen-container">
        <Helmet>
          <title>Access Denied</title>
        </Helmet>

        <div className="d-flex justify-content-center">
          <div className="row align-self-center mt-5">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="mx-auto text-center p-3">
                <h1><span className="fa fa-exclamation fa-1x red-cl"/> No Access.</h1>
                <p>You do not have permission to access this section.</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  };

  constructor (props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderView);
