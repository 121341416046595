/**
 *
 * Event add
 * Final event step
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Moment from 'moment';
import * as Yup from 'yup';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import {Constants} from '../../constants';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faPlusCircle} from '@fortawesome/free-solid-svg-icons';

import {Alerts} from '../../components/ui';
import Error from '../../components/general/Error';
import PageHeader from '../../components/dashboard/PageHeader';
import Loading from '../../components/general/Loading';

import 'react-daterange-picker/dist/css/react-calendar.css';
import GuestListView from '../guest/GuestListView';
import GuestQuickManage from '../guest/GuestManageQuick';
import GuestDetailsOverView from '../guest/GuestDetailsOverView';

import ProfileQuickSearch from '../guest/ProfileQuickSearch';

/* Redux ======================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  event: state.event.event,
  lookupResponse: state.event.lookupresponse,
  selectionData: state.event.selectiondata,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class TicketBookingAdd extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };


  componentDidMount = () => {
    const {event} = this.props;
    if (Object.keys(this.props.selectionData).length === 0 || Object.keys(this.props.lookupResponse).length === 0) {
      this.props.history.replace(`/event/${event.hash}/lookup/`);
    } else {
      this.fetchInitData();
    }
    this.fetchInitData();
  };

  addBooking = () => {
    // Get new credentials and update
    const {guestData} = this.state;
    const {lookupResponse, selectionData, event, property} = this.props;

    let guest = {};
    if (guestData) {
      guest = {
        profile_id: guestData.id,
        first_name: guestData.first_name,
        last_name: guestData.last_name,
        email: guestData.email,
        phone: guestData.code && guestData.phone ? guestData.code + guestData.phone : guestData.phone,
        gender: guestData.gender,
      };

      if (guestData.nationality && guestData.nationality.code) {
        guest.nationality = guestData.nationality.code;
      } else if (guestData.nationality) {
        guest.nationality = guestData.nationality;
      }
    }

    this.setState({
      resultMsg: {status: 'One moment...'},
    });

    AppAPI.eventapi.post(`${event.id}/ticket/create`, {
      ticket_date: Moment(lookupResponse.date).format('YYYY-MM-DD'),
      event_id: event.id,
      ticket_id: selectionData.ticket_id,
      ticket_quantity: selectionData.selected_ticket,
      ticket_property_id: property.id,
      ticket_guest: guest
    })
      .then(res => {
        if (res.id) {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            setTimeout(() => {
              this.props.history.replace(`/event/${event.hash}/ticket-booking/booking/${res.id}`);
            }, 500);
          });
        } else {
          this.setState({resultMsg: {error: 'Booking Failed.'}});
        }

      })
      .catch(err => {
        const error = AppAPI.handleError(err);
        this.setState({resultMsg: {error}});
      });
  };

  fetchInitData = () => {
    this.setState({
      loading: false,
    });
  };


  showGuestDetails = (guestId, add_guest = false) => {
    this.setState({showGuestDetailsId: guestId, showGuestDetails: true, addGuestToRoom: add_guest});
  };

  render = () => {
    const {resultMsg, loading, error, guestData} = this.state;
    const {event, selectionData, lookupResponse} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;


    const formValidation = Yup.object().shape({
      first_name: Yup.string().min(2, 'Too Short!').required('Required'),
      email: Yup.string().email('Invalid email'),
      phone: Yup.number().required('Required'),
    });

    return (
      <div className="booking-view screen-container">
        <Helmet>
          <title>Ticket Information</title>
        </Helmet>

        <PageHeader
          history={this.props.history}
          title={'Book Ticket'}
          subHeader={true}
        />

        <div className={'booking-details-container'}>
          <div className={'container mt-5'}>
            <div className="row">
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className={'lookup-box-container mt-2'}>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-5 mb-md-3">
                      <ul className="list-group">
                        <li className="list-group-item"><strong>Name</strong> : {event.name}</li>
                        <li className="list-group-item">
                          <strong>Booking Date</strong> :
                          <span className={'badge badge-success'}>
                            {AppUtil.formatDateTime(lookupResponse.date, 'datef')}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-5 mb-md-3">
                      <ul className="list-group">
                        <li className="list-group-item">
                          Availability :
                          <span className={'badge badge-info'}>
                            {(selectionData.ticket && selectionData.ticket.ticket_quantity_type === Constants.EVENT_TICKET_TYPE.LIMITED) ?
                              <React.Fragment>{selectionData.available_tickets} / {selectionData.total_tickets}</React.Fragment> :
                              'Unlimited'
                            }
                          </span>
                        </li>
                        <li className="list-group-item">
                          <p className={'small text-secondary mb-0'}>Availability at time of lookup, confirm to lock the booking.</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <h6>Ticket Detail</h6>
                  <table className={'table'}>
                    <thead>
                      <tr>
                        <th>Ticket</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{selectionData.ticket.name}</td>
                        <td>{selectionData.selected_ticket}</td>
                        <td>{lookupResponse.currency} {selectionData.price}</td>
                        <td>{lookupResponse.currency} {selectionData.total}</td>
                      </tr>
                    </tbody>
                  </table>
                  <React.Fragment>
                    <hr/>
                    <div className="row">
                      <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                        <h6>Guest Details</h6>
                        {guestData && guestData.first_name ?
                          <GuestListView
                            data={guestData}
                            hideStatus={true}
                            action={() => this.showGuestDetails(guestData, false)}
                          />
                          :
                          <div>
                            <button
                              onClick={() => this.setState({showGuestManagement: true})}
                              className={'btn btn-success btn-block mt-3'}>
                              <FontAwesomeIcon icon={faPlusCircle} size={'sm'} className={'mr-2'}/> Add Guest
                            </button>
                          </div>
                        }
                      </div>
                    </div>
                    <hr/>
                    <div className="spacer-20"/>
                    <div className={'row'}>
                      <div className={'col-lg-12 col-md-12 col-sm-12 mb-3'}>
                        <div className="text-center">
                          <Alerts
                            status={resultMsg.status}
                            success={resultMsg.success}
                            error={resultMsg.error}
                          />
                          {(this.state.guestData === null && this.state.corporateData === null) &&
                           <p className={'text-secondary'}>Select guest to continue.</p>
                          }
                          <button
                            disabled={(this.state.guestData === null && this.state.corporateData === null) || (resultMsg && resultMsg.status)}
                            onClick={() => this.addBooking()}
                            className={'a btn btn-success btn-block btn-lg'}>
                            <FontAwesomeIcon icon={faPlus} size={'lg'} className={'white-cl mr-2'}/> Add Booking
                          </button>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GuestQuickManage
          onHide={() => this.setState({showGuestManagement: false})}
          show={this.state.showGuestManagement}
          property={this.props.property}
          showGuestDetails={this.showGuestDetails}
          guestAddAction={this.updateBookingRoom}
          formValidation={formValidation}
          addGuestFunction={(value) => this.setState({guestData: value})}
        />
        <GuestDetailsOverView
          guestId={this.state.showGuestDetailsId}
          onHide={() => this.setState({showGuestDetails: false})}
          show={this.state.showGuestDetails}
          addGuestFunction={this.state.addGuestToRoom}
          resultMsg={this.state.resultMsg}
          action={this.addProfileAction}
        />
        <ProfileQuickSearch
          onHide={() => this.setState({showProfileSearch: false})}
          show={this.state.showProfileSearch}
          showProfileDetails={this.showGuestDetails}
          isCorporate={this.state.bookingSelectedSource === Constants.BOOKING_SOURCE.CORPORATE}
          isAgent={this.state.bookingSelectedSource === Constants.BOOKING_SOURCE.AGENT}
        />
      </div>
    );
  };

  addProfileAction = (data) => {
    if (data) {
      if (this.state.showGuestManagement) {
        this.setState({guestData: data, showGuestManagement: false, showGuestDetails: false});
      } else if (this.state.showProfileSearch) {
        this.setState({corporateData: data, showProfileSearch: false, showGuestDetails: false});
      } else {
        if (parseInt(data.profile_type) === Constants.PROFILE_TYPE.AGENT || parseInt(data.profile_type) === Constants.PROFILE_TYPE.CORPORATE) {
          this.setState({corporateData: null, showGuestDetails: false});
        } else {
          this.setState({guestData: null, showGuestDetails: false});
        }
      }
    }
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      minDate: Moment().startOf('day'),
      maxDate: Moment().subtract(2, 'days'),
      bookingActionDate: Moment().startOf('day'),
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      results: [],
      bookingConfirmationStatus: false,
      bookingSelectedSource: null,
      bookingAction: false,
      showGuestManagement: false,
      showGuestDetails: false,
      showGuestDetailsId: null,
      addGuestToRoom: false,
      showProfileSearch: false,
      guestData: null,
      corporateData: null,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketBookingAdd);
