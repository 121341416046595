/**
 *
 * ListView
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from './Loading';

/* Component ==================================================================== */
class ListView extends Component {
  static componentName = 'PropertyList';

  static propTypes = {
    rowView: PropTypes.func.isRequired,
    onFetch: PropTypes.func.isRequired,
    pagination: PropTypes.bool,
    firstLoader: PropTypes.bool,
    colSpan: PropTypes.number,

    refresh: PropTypes.bool,
    searchParams: PropTypes.string,
    emptyView: PropTypes.func,
    loadingView: PropTypes.func,
    endListView: PropTypes.func,
    loadMoreView: PropTypes.func,

    element: PropTypes.string,
    class: PropTypes.string,
    elementClass: PropTypes.string,
  };

  static defaultProps = {
    elementClass: '',
  };

  componentDidMount = () => {
    this._mounted = true;
    if (this.props.firstLoader) {
      this.props.onFetch(this._getPage(), this._postRefresh, this.props.searchParams);
    } else {
      this.setState({paginationStatus: null});
    }
  };

  componentDidUpdate(nextProps) {
    if (nextProps.searchParams !== this.props.searchParams) {
      this._setPage(1);
      this._setRows([]);
      this.props.onFetch(this._getPage(), this._postRefresh, this.props.searchParams);
    }
    if (this.state.paginationStatus !== 'fetching' && this.props.refresh === true){
      this._setPage(1);
      this.setState({
        paginationStatus: 'fetching',
        data: [],
      });
      this.props.onFetch(this._getPage(), this._postRefresh, this.props.searchParams);
    }
  }

  paginationFetchingView = () => {
    if (this.props.loadingView) {
      return this.props.loadingView();
    }

    if (this.props.colSpan) {
      return (
        <tr>
          <td className={'my-5'} colSpan={this.props.colSpan}>
            <Loading heightMatch={false}/>
          </td>
        </tr>
      );
    }

    return (
      <div className={`my-5 ${this.props.elementClass}`}>
        <Loading heightMatch={false}/>
      </div>
    );
  };
  paginationAllLoadedView = () => {

    if (this.state.paginationStatus === 'allLoaded') {
      if (this.props.endListView) {
        return this.props.endListView();
      }

      if (this.props.colSpan) {
        return (
          <tr className={'table-control-row'}>
            <td className={'text-center my-5'} colSpan={this.props.colSpan}>
              <i className="fa fa-ellipsis-h fa-2x"/>
            </td>
          </tr>
        );
      }

      return (
        <div className={`center-block text-center ${this.props.elementClass}`}>
          <div className="spacer-20"/>
          <i className="fa fa-ellipsis-h fa-2x"/>
        </div>
      );
    }
  };
  paginationWaitingView = () => {

    if (!this.props.pagination) {
      return null;
    }

    if (this.props.loadMoreView) {
      return this.props.loadMoreView();
    }

    if (this.props.colSpan) {
      return (
        <tr className={'table-control-row'}>
          <td colSpan={this.props.colSpan}>
            <button className="btn btn-default" onClick={() => this._onPaginate()}>
              <i className="fa fa-chevron-down"/> Load More
            </button>
          </td>
        </tr>
      );
    }

    return (
      <div className={`center-block text-center ${this.props.elementClass}`}>
        <div className="spacer-20"/>
        <button className="btn btn-default" onClick={() => this._onPaginate()}>
          <i className="fa fa-chevron-down"/> Load More
        </button>
      </div>
    );
  };
  _postPaginate = (rows = [], options = {}) => {
    this._setPage(this._getPage() + 1);
    const mergedRows = this._getRows().concat(rows);

    this._updateRows(mergedRows, options);
  };
  _updateRows = (rows = [], options = {}) => {
    if (rows && rows.length > 0) {
      this._setRows(rows);
      this.setState({
        data: rows,
        isRefreshing: false,
        paginationStatus: (options.allLoaded === true ? 'allLoaded' : 'waiting'),
      });
    } else {
      this.setState({
        data: [],
        isRefreshing: false,
        paginationStatus: 'allLoaded',
      });
    }
  };
  _onPaginate = () => {
    if (this.state.paginationStatus === 'allLoaded') {
      return null;
    }

    this.setState({
      paginationStatus: 'fetching',
    });

    this.props.onFetch(this._getPage() + 1, this._postPaginate, this.props.searchParams);
  };
  _postRefresh = (rows = [], options = {}) => {
    if (this._mounted) {
      this._updateRows(rows, options);
    }
  };
  _renderPaginationView = () => {
    if ((this.state.paginationStatus === 'fetching' && this.props.pagination === true) || (this.state.paginationStatus === 'firstLoad' && this.props.firstLoader === true)) {
      return this.paginationFetchingView();
    } else if (this.state.paginationStatus === 'waiting' && this.props.pagination === true && (this.props.withSections === true || this._getRows().length > 0)) {
      return this.paginationWaitingView();
    } else if (this.state.paginationStatus === 'allLoaded' && this.props.pagination === true && this._getRows().length > 0) {
      return this.paginationAllLoadedView();
    } else if (this._getRows().length === 0 && this.state.paginationStatus === 'allLoaded') {
      return this.emptyView();
    }
    return null;

  };
  _renderData = (data, renderFunc) => {
    if (data) {
      return (
        Object.keys(data).map(function (keyName, keyIndex) {
          return (renderFunc(data[keyName], keyIndex));
        })
      );
    }
  };
  render = () => {
    const {data} = this.state;
    return (
      <React.Fragment>
        {this._renderData(data, this.props.rowView)}
        {this._renderPaginationView()}
      </React.Fragment>
    );
  };

  constructor (props) {
    super(props);

    this.state = {
      paginationStatus: 'fetching',
    };
    this._setPage(1);
    this._setRows([]);
  }

  _setRows (rows) {
    this._rows = rows;
  };

  _getRows () {
    return this._rows;
  };

  _setPage (page) {
    this._page = page;
  };

  _getPage () {
    return this._page;
  };

  emptyView () {



    if (this.props.searchParams) {

      if (this.props.colSpan) {
        return (
          <tr className={'table-control-row'}>
            <td className={'my-5'} colSpan={this.props.colSpan}>
              <p className={'text-secondary'}>End of Search.</p>
            </td>
          </tr>
        );
      }


      return(
        <div className={'mt-5'}>
          <p className={'text-secondary text-center'}>End of Search.</p>
        </div>
      );
    }

    if (this.props.emptyView) {
      return this.props.emptyView();
    }

    return (
      <div>
        <h3>No contents to display.</h3>
      </div>
    );
  };
}

export default ListView;