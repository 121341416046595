/**
 *
 * Payment Overview
 */
import _ from 'lodash';
import Moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import { Constants } from '../../../constants';
// Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
// Components
import PAOverview from './components/PAOverview';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PaymentAnalyticsOverview extends Component {
  static componentName = 'PaymentAnalyticsOverview';

  static propTypes = {
    pos: PropTypes.string,
    endDate: PropTypes.object,
    startDate: PropTypes.object
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {startDate, endDate, dateType, pos} = this.props;
    this.setState({loading: true});
    const queryParams = {
      'date_type': dateType,
      'pos': pos ? pos : null,
      'report_type': 'overview',
      'start_date': (startDate ? startDate : Moment()).format('YYYY-MM-DD'),
      'end_date': endDate ? endDate.format('YYYY-MM-DD') : null
    };
    AppAPI[pos ? 'posapi' : 'paymentapi'].get(`payment-analytics/?${queryString.stringify(queryParams)}`)
      .then((res) => {
        this.setState({loading: false, response: res});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({loading: false, error: error});
      });
  };

  render = () => {
    const {loading, error, response} = this.state;
    const {property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const paymentData = {
      paymentStatusCount: {},
      paymentStatusTotal: {},
      settlementStatusCount: {},
      settlementStatusTotalPaid: {},
      settlementStatusTotalSettled: {},
      paidPaymentMethodCount: {},
      paidPaymentMethodTotal: {},
      paidPaymentMethodSettled: {},
      paidPaymentMethodSettledTotal: {},
      paidPaymentMethodNotSettled: {},
      paidPercentage: 0,
      settledPercentage: 0
    };

    if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
      const responseData = response.data;
      if (!_.isEmpty(responseData.payment_status_data)) {
        responseData.payment_status_data.forEach((data) => {
          paymentData.paymentStatusCount[data.payment_status] = data.count;
          paymentData.paymentStatusTotal[data.payment_status] = data.total;
        });
      }

      if (!_.isEmpty(responseData.payment_paid_settlement_method_data)) {
        responseData.payment_paid_settlement_method_data.forEach((data) => {
          AppUtil.insertOrAddObject(paymentData.settlementStatusCount, data.settlement_status, data.count);
          AppUtil.insertOrAddObject(paymentData.settlementStatusTotalPaid, data.settlement_status, data.total, 'float');
          AppUtil.insertOrAddObject(paymentData.settlementStatusTotalSettled, data.settlement_status, data.total_settled, 'float');

          AppUtil.insertOrAddObject(paymentData.paidPaymentMethodCount, data.payment_method, data.count);
          AppUtil.insertOrAddObject(paymentData.paidPaymentMethodTotal, data.payment_method, data.total, 'float');
          if (data.settlement_status === Constants.SETTLEMENT_STATUS.SETTLED) {
            AppUtil.insertOrAddObject(paymentData.paidPaymentMethodSettledTotal, data.payment_method, data.total, 'float');
            AppUtil.insertOrAddObject(paymentData.paidPaymentMethodSettled, data.payment_method, data.total_settled, 'float');
          } else {
            AppUtil.insertOrAddObject(paymentData.paidPaymentMethodNotSettled, data.payment_method, data.total_settled, 'float');
          }
        });
      }
    }


    return (
      <div className="screen-container">
        <Helmet>
          <title>Payment Overview</title>
        </Helmet>
        <PAOverview property={property} paymentData={paymentData}/>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      startDate: Moment().startOf('day'),
      endDate: Moment().add(30, 'days'),
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentAnalyticsOverview);
