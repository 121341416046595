/**
 * Header
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Link} from 'react-router-dom';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  user: state.user,
  property: state.property.property
});

// Any actions to map to the component?
const mapDispatchToProps = {};


/* Component ==================================================================== */
class Header extends Component {
  static componentName = 'Header';

  render = () => {
    return (
      <div className="header">
        <nav className="navbar navbar-expand-md navbar-light">
          <div className="container">
            <Link className="navbar-brand" to="/dashboard">
              <img alt="Brand" src={require('../../images/logo.png')}/>
            </Link>
          </div>
        </nav>
      </div>
    );
  };
}


/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
