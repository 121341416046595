import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Route, Switch} from 'react-router-dom';
// Components
import POSOrderList from './POSOrderList';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faList} from '@fortawesome/free-solid-svg-icons';

class OrderListIndex extends Component {
  static propTypes = {
    match: PropTypes.object,
    hideHeader: PropTypes.bool,
    baseUrl: PropTypes.string,
    guestId: PropTypes.number,
    corporateId: PropTypes.number,
  };


  static defaultProps = {
    baseUrl: '',
  };

  render = () => {
    const {baseUrl, hideHeader, guestId, corporateId} = this.props;
    return (
      <div>
        {!hideHeader &&
        <h4 className={'mb-4'}><FontAwesomeIcon icon={faList} className={'mr-2'} size={'sm'}/> Order/Tab(s)</h4>
        }

        <div className={'row'}>
          <div className={'col-lg-12 col-md-12 col-sm-12'}>
            <Switch>
              <Route
                path={`${baseUrl}/pos-order/list/`} exact
                render={(props) => <POSOrderList guestId={guestId} corporateId={corporateId} {...props}/>}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}


export default OrderListIndex;