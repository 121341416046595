/**
 *
 * Session List
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFile, faSync} from '@fortawesome/free-solid-svg-icons';
import SessionDetails from './SessionDetails';
import queryString from 'query-string';
import Moment from 'moment';
import AppWebUtil from '../../../lib/webUtils';
import FilterButton from '../../../components/dashboard/FilterButton';
import {Constants, Filters, Strings} from '../../../constants';
import SearchBar from '../../../components/forms/SearchBar';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class SessionList extends Component {
  static componentName = 'SessionList';

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };

  componentDidMount = () => {
    let urlParams = {}, startDate = null, endDate = null;


    switch (this.getQueryParams('session_status')) {
    case 'active':
      urlParams['session_status'] = Constants.USER_SESSION_STATUS.ACTIVE;
      break;
    case 'system-expired':
      urlParams['session_status'] = Constants.USER_SESSION_STATUS.SYSTEM_EXPIRED;
      break;
    case 'timeout':
      urlParams['session_status'] = Constants.USER_SESSION_STATUS.TIMEOUT;
      break;
    case 'user-logout':
      urlParams['session_status'] = Constants.USER_SESSION_STATUS.USER_LOGOUT;
      break;
    case 'user-expired':
      urlParams['session_status'] = Constants.USER_SESSION_STATUS.USER_EXPIRED;
      break;
    default:
      break;
    }

    switch (this.getQueryParams('session_device')) {
    case 'renzo-dashboard':
      urlParams['session_device'] = Constants.USER_SESSION_DEVICES.RENZO_DASHBOARD;
      break;
    case 'renzo-app':
      urlParams['session_device'] = Constants.USER_SESSION_DEVICES.RENZO_APP;
      break;
    case 'other':
      urlParams['session_device'] = Constants.USER_SESSION_DEVICES.OTHER;
      break;
    default:
      break;
    }

    if (this.getQueryParams('start_date')) {
      startDate = Moment(String(this.getQueryParams('start_date')));
    }
    if (this.getQueryParams('end_date')) {
      endDate = Moment(String(this.getQueryParams('end_date')));
    }
    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('start_date'), this.getQueryParams('end_date'), 'created_date');

    this.setState({
      loading: false,
      urlParams,
      startDate,
      endDate,
    });

  };


  fetchData = (page = 1, callback, searchText, download) => {
    const urlParams = {...this.state.urlParams};
    let baseUrl = 'sessions';
    urlParams['page'] = page;

    AppAPI.account.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (download) {
          let fileName = `Booking-Report-${AppUtil.formatDateTime(Moment(), 'short')}.${download.file}`;
          AppWebUtil.downloadFile(res, fileName);
        } else {
          if (res.results.length > 0) {
            callback(res.results, {allLoaded: !res.next});
          } else {
            callback();
          }
        }
        this.setState({
          searching: false,
          clear: false,
          refresh: false,
          loading: false,
          fileDownload: false,
          page: page,
        });
      })
      .catch((err) => {
        if (callback) {
          callback([], {allLoaded: true});
        }
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, refresh: false, loading: false, fileDownload: false, error});
      });
  };

  renderRow = (data, key) => {
    let sessionInformation = [];
    if (!_.isEmpty(data.session_ip_information)){
      const sessionData = data.session_ip_information;
      if (sessionData.city) {sessionInformation.push(sessionData.city);}
      if (sessionData.region) {sessionInformation.push(sessionData.region);}
      if (sessionData.country_name) {sessionInformation.push(sessionData.country_name);}
    }

    return (
      <tr key={key}>
        <td>
          <strong>{data.session_device_display}</strong>
          {data.session_current &&
            <span className={'badge badge-info small ml-2'}>
              Current Session
            </span>
          }
          <br/>
          {!_.isEmpty(sessionInformation) && <p className={'small mb-0'}>{sessionInformation.join(' / ')}</p>}
          <p className={'small mb-0'}>IP : {data.session_ip} / (<small>{data.session_agent}</small>)</p>
        </td>
        <td className={'align-middle'}><small>{AppUtil.formatDateTime(data.created)}</small></td>
        <td className={'align-middle'}><small>{data.end_time ? AppUtil.formatDateTime(data.end_time) : '...'}</small></td>
        <td className={'align-middle'}>
          <span className={`badge ${data.session_active ? 'badge-warning' : 'badge-success'} small ml-2`}>
            {data.session_status_display}
          </span>
        </td>
        <td className={'align-middle'}>
          <button
            onClick={() => this.setState({selectSessionDetails: data, showSessionDetails: true})}
            className={'btn btn-sm btn-success'}
          >
            Details
          </button>
        </td>
      </tr>
    );
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={5}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faFile}/> No Sessions </h4>
            {Strings.sessionListEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };


  tableHeader = () => {
    return (
      <tr>
        <th className={'data-table-cell-xl'}>Session Details</th>
        <th className={'data-table-cell-md'}>Start</th>
        <th className={'data-table-cell-md'}>End</th>
        <th className={'data-table-cell-sm'}>Status</th>
        <th className={'data-table-cell-md'}/>
      </tr>
    );
  };


  render = () => {

    const {loading, error, clear, searching, results, refresh} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="tax-list screen-container ">
        <Helmet>
          <title>Login Sessions</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'User Sessions'}
          description={'User login activity.'}
        />

        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-9'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.setState({refresh: true})}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                </button>
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Session Status'}
                  data={Filters.userSessionStatusFilter}
                  selectKey={this.getQueryParams('session_status')}
                  action={(data) => this.setQueryParams([{key: 'session_status', value: data.key}])}
                  size={'sm'}
                />
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-3 text-center text-sm-right'}/>
        </div>
        <div className="row">
          <div className="col-12">
            <SearchBar
              clear={clear}
              refresh={refresh}
              results={results}
              searching={searching}
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
              searchFunction={null}
              colSpan={5}
            />
          </div>
        </div>
        <SessionDetails
          show={this.state.showSessionDetails}
          data={this.state.selectSessionDetails}
          onAction={() => this.setState({refresh: true})}
          onHide={() => this.setState({showSessionDetails: false, selectSessionDetails: null})}
          onSave={this.fetchInitData}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      showSessionDetails: false,
      selectSessionDetails: null,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionList);