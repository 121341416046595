import React from 'react';
import {NavLink, Redirect, Route, Switch} from 'react-router-dom';

import PropertyDetails from '../property/PropertyDetails';
import PropertyEdit from '../property/PropertyEdit';
import PropertyContent from '../property/settings/PropertyContent';
import PropertyNotifications from '../property/notificationsSettings';
import PropertyContentManage from '../property/settings/PropertyContentManage';

import PropertyDistribution from '../property/PropertyDistribution';

import PropertyTaxClassList from '../property/taxSettings/PropertyTaxClassList';
import PropertyTaxClassManage from '../property/taxSettings/PropertyTaxClassManage';
import PropertyTaxClassSettings from '../property/taxSettings/PropertyTaxClassSettings';

import PropertyFrontDesk from '../property/frontDesk';
import PropertyChannel from '../property/channelManager';
import PropertyBookingEngine from '../property/bookingEngine';
import paymentSettings from '../property/paymentSettings';

import PropertyRecordList from '../property/settings/PropertyRecordList';

import PropertySequenceSettingsManage from '../property/settings/PropertySequenceSettingsManage';
import PropertySequenceSettings from '../property/settings/PropertySequenceSettings';

import MembershipList from '../property/membershipSettings/PropertyMembershipList';
import MembershipManage from '../property/membershipSettings/PropertyMembershipManage';
import MembershipDetails from '../property/membershipSettings/PropertyMembershipDetails';

import NotFound from '../NotFound';

import propertyContentRequired from '../../components/decorators/propertyContentRequired';

const menu = [
  {
    title: 'General Information',
    link: '/settings/property/info',
    isExact: false,
  },
  {
    title: 'Accounts',
    link: '/settings/property/accounts',
    isExact: false,
  },
  {
    title: 'Tax Class',
    link: '/settings/property/tax',
    isExact: false,
  },
  {
    title: 'Notifications',
    link: '/settings/property/notification-settings',
    isExact: false,
  },
  {
    title: 'Distribution',
    link: '/settings/property/distribution',
    isExact: false,
  },
  {
    title: 'Front Desk',
    link: '/settings/property/front-desk',
    isExact: false,
  },
  {
    title: 'Channel Manager',
    link: '/settings/property/channel-manager',
    isExact: false,
  },
  {
    title: 'Booking Engine',
    link: '/settings/property/booking-engine',
    isExact: false,
  },
  {
    title: 'Payment',
    link: '/settings/property/payment-settings',
    isExact: false,
  },
  {
    title: 'Content',
    link: '/settings/property/property-content',
    isExact: false,
  },
  {
    title: 'Sequence',
    link: '/settings/property/sequence-settings',
    isExact: false,
  },
  {
    title: 'Records',
    link: '/settings/property/records',
    isExact: false,
  },
];
const menuItems = [];

menu.map((item) => {
  const {title, link, isExact} = item;
  return menuItems.push(
    <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
      <NavLink
        to={{
          pathname: link,
          state: {fromDashboard: true},
        }}
        exact={isExact}
        className="nav-link rounded-0"
        activeClassName="selected">
        {title}
      </NavLink>
    </li>,
  );
});

const PropertyRoute = () => (
  <div>
    <div className={'row'}>
      <div className={'col-lg-2 col-md-3 col-sm-12 mb-5'}>
        <ul className="nav nav-pills flex-md-column flex-sm-row border rounded mb-3">
          {menuItems}
        </ul>
      </div>
      <div className={'col-lg-10 col-md-9 col-sm-12'}>
        <Switch>
          <Route exact path="/settings/property/" render={() => (<Redirect to="/settings/property/info"/>)}/>
          <Route path="/settings/property/info" exact component={PropertyDetails}/>
          <Route path="/settings/property/info/manage/" exact component={PropertyEdit}/>

          <Route path="/settings/property/distribution" exact component={PropertyDistribution}/>

          <Route path="/settings/property/records" exact component={PropertyRecordList}/>

          <Route path="/settings/property/property-content" exact component={propertyContentRequired(PropertyContent)}/>
          <Route
            path="/settings/property/property-content/manage" exact
            component={propertyContentRequired(PropertyContentManage)}
          />

          <Route path="/settings/property/notification-settings" component={PropertyNotifications}/>

          <Route path="/settings/property/tax" exact component={PropertyTaxClassList}/>
          <Route path="/settings/property/tax/new" exact component={PropertyTaxClassManage}/>
          <Route path="/settings/property/tax/:propertyTaxClassId" exact component={PropertyTaxClassSettings}/>
          <Route path="/settings/property/tax/:propertyTaxClassId/edit" exact component={PropertyTaxClassManage}/>


          <Route path="/settings/property/front-desk" component={PropertyFrontDesk}/>
          <Route path="/settings/property/channel-manager" component={PropertyChannel}/>
          <Route path="/settings/property/booking-engine" component={propertyContentRequired(PropertyBookingEngine)}/>
          <Route path="/settings/property/sequence-settings" exact component={PropertySequenceSettings}/>
          <Route path="/settings/property/payment-settings" component={paymentSettings}/>
          <Route path="/settings/property/sequence-settings/manage/" exact component={PropertySequenceSettingsManage}/>

          <Route path="/settings/property/accounts" exact component={MembershipList}/>
          <Route path="/settings/property/accounts/new" exact component={MembershipManage}/>
          <Route path="/settings/property/accounts/:membershipId" exact component={MembershipDetails}/>
          <Route path="/settings/property/accounts/:membershipId/manage/" exact component={MembershipManage}/>

          <Route exact component={NotFound}/>
        </Switch>
      </div>
    </div>
  </div>
);

export default PropertyRoute;
