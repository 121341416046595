import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
// Consts and Libs
import {AppConfig} from '../../constants';
// Components
import Loading from '../general/Loading';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = () => ({});

// Any actions to map to the component?
const mapDispatchToProps = {
};

export default function (ComposedComponent) {
  class Authentication extends Component {
    static contextTypes = {
      router: PropTypes.object
    };

    componentWillUnmount() {
      window.removeEventListener('storage', this.noAuthManager);
    }

    componentDidMount() {
      if (localStorage.getItem(AppConfig.localStorageKeys.USER_TOKEN)) {
        this.props.history.push('/dashboard');
      } else {
        window.addEventListener('storage', this.noAuthManager);
        this.setState({loading: false});
      }
    }

    render() {
      if (this.state.loading) return <Loading/>;
      return <ComposedComponent {...this.props} />;
    }

    noAuthManager = (e) => {
      if (e.key === AppConfig.localStorageKeys.USER_TOKEN && e.newValue) {
        this.props.history.push('/dashboard');
      }
    }

    constructor(props) {
      super(props);

      this.state = {
        loading: true,
      };
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(Authentication);
}