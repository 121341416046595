/**
 * Manage Booking confirm action
 */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import {Constants, Strings} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faLongArrowAltRight,
  faSearch,
  faSquare,
  faTimes,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */

class BookingConfirm extends React.Component {
  static componentName = 'BookingConfirm';

  static propTypes = {
    booking: PropTypes.object.isRequired,
    updateResponse: PropTypes.func,
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        loading: false,
        result: {},
        checkbox: [],
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  bookingConfirmation = (payload) => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.booking.post(`${this.props.booking.hash}/confirm/`, payload)
          .then(res => {
            this.setState({result: res, resultMsg: {success: ''}});
            if (!_.isEmpty(res.booking)) {
              if (this.props.successAction){
                this.props.successAction(res.booking);
              }
              this.props.onHide();
            }
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };


  render = () => {

    const {result, resultMsg, checkbox} = this.state;
    const {booking, show} = this.props;

    if (!show || !booking) return <React.Fragment/>;

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {booking.confirm_status  ? 'Un-Confirm' : 'Confirm'} Booking
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {booking.booking_status === Constants.BOOKING.UPCOMING ?
            <div>
              {!_.isEmpty(result) ?
                <div>
                  {!_.isEmpty(result.booking_availability_data) &&
                    <React.Fragment>
                      <p><strong>Availability Data : </strong> {`${AppUtil.formatDateTime(booking.checkin, 'date')} - ${AppUtil.formatDateTime(booking.checkout, 'date')}`}</p>
                      {Object.keys(result.booking_availability_data).map((key, i)=>{
                        const data = result.booking_availability_data[key];
                        return (
                          <div key={i}>
                            <table className={'table table-sm table-bordered'}>
                              <thead>
                                <tr className={'bg-dark text-light'}>
                                  <th className={'p-1'} colSpan={3}>{data.name}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className={'data-table-cell-md'}>
                                    <p className={'mb-0 text-muted'}>Available</p>
                                    <h5>{data.available_rooms}</h5>
                                  </td>
                                  <td className={'data-table-cell-md'}>
                                    <p className={'mb-0 text-muted'}>{booking.confirm_status ? 'Used' : 'Required'}</p>
                                    <h5>{data.required_rooms}</h5>
                                  </td>
                                  <td className={`data-table-cell-md table-${data.post_available_rooms < 0 ? 'danger' : 'success'}`}>
                                    <p className={'mb-0 text-muted'}>{booking.confirm_status ? 'New Availability' : 'Remaining'}</p>
                                    <h5>{data.post_available_rooms}</h5>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        );
                      })}
                    </React.Fragment>
                  }
                  <hr/>
                  {!_.isEmpty(result.messages) ?
                    <ul className="list-group">
                      <li className="list-group-item">
                        <p className={'mb-0 text-muted small'}>These items need your attention, please review to proceed.</p>
                      </li>
                      {result.messages.map((data, i) => (
                        <li
                          key={i}
                          className="list-group-item align-items-center a-class d-flex"
                          onClick={()=>this.setState({checkbox: AppUtil.insertOrRemoveArray(checkbox, i+1)})}
                        >
                          <div className={'mr-2'}>
                            <FontAwesomeIcon
                              size={'1x'}
                              icon={checkbox.includes(i+1) ? faCheckSquare : faSquare}
                              className={`${checkbox.includes(i+1) ? 'green-cl' : 'grey-cl'} mr-2`}
                            />
                          </div>
                          <div>{data}</div>
                        </li>
                      ))}
                    </ul>
                    :
                    <div className={'border p-3 text-center'}>
                      <FontAwesomeIcon icon={faCheckCircle} size={'2x'} className={'my-2 green-cl'} />
                      <p className={'mb-0 text-muted small'}>Ready to confirm.</p>
                    </div>
                  }
                </div>
                :
                <div>
                  <ul className="list-inline text-center my-4">
                    <li className={'list-inline-item'}>
                      <FontAwesomeIcon icon={faCheckCircle} size={'3x'} className={`${booking.confirm_status ? 'green' : 'yellow'}-cl`} />
                    </li>
                    <li className={'list-inline-item'}><FontAwesomeIcon icon={faLongArrowAltRight} size={'2x'} className={'mx-4'} /></li>
                    <li className={'list-inline-item'}>
                      <FontAwesomeIcon icon={faCheckCircle}  size={'3x'} className={`${booking.confirm_status ? 'yellow' : 'green'}-cl`} />
                    </li>
                  </ul>

                  <p>{booking.confirm_status ? Strings.bookingUnConfirmationMessage : Strings.bookingConfirmationMessage}</p>

                  {booking.confirm_status &&
                    <button className={'btn btn-link px-0 btn-sm'} onClick={()=>this.bookingConfirmation({commit: false})}>
                      Availability lookup
                    </button>
                  }
                </div>
              }

            </div>
            :
            <div className={'text-center'}>
              <h4><FontAwesomeIcon icon={faTimesCircle} className={'red-cl mr-2'} />Not allowed</h4>
              <p>Inventory release or confirm can only be performed on upcoming bookings.</p>
            </div>
          }
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          <div className={'px-3'}>
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
          </div>
          <div className={'row'}>
            <div className={'col'}>
              <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
              </button>
            </div>
            {booking.booking_status === Constants.BOOKING.UPCOMING &&
              <React.Fragment>
                {booking.confirm_status ?
                  <div className={'col'}>
                    <button
                      className={'btn btn-warning btn-lg btn-block'}
                      onClick={()=>this.bookingConfirmation({commit: true})}
                    >
                      <FontAwesomeIcon className={'mr-2'} icon={faTimes} size={'sm'}/> <small>Un-Confirm Booking</small>
                    </button>
                  </div>
                  :
                  <div className={'col'}>
                    <button
                      className={`btn btn-lg btn-block btn-${!_.isEmpty(result.booking_availability_data)? 'success' : 'primary'}`}
                      onClick={()=>this.bookingConfirmation({commit: !_.isEmpty(result.booking_availability_data)})}
                      disabled={(_.isEmpty(checkbox) && !_.isEmpty(result.messages))}
                    >
                      <FontAwesomeIcon
                        className={'white-cl mr-2'} icon={!_.isEmpty(result.booking_availability_data) ? faCheck : faSearch} size={'sm'}
                      />
                      {!_.isEmpty(result.booking_availability_data) ? 'Confirm Booking' : 'Lookup Availability'}
                    </button>
                  </div>
                }

              </React.Fragment>
            }
          </div>
        </Modal.Footer>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      checkbox: [],
      result: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      advanceSettings: false,
    };
  }
}

/* Export Component ==================================================================== */
export default BookingConfirm;
