/**
 * POSBillingOrderList
 *  List view for POS Billing order items
 *
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AppAPI from '../../../lib/api';
// Consts & Libs
import AppUtil from '../../../lib/util';
import { Constants } from '../../../constants';
import printerAPI from '../../../lib/printerAPI';
// Components
import ModelPDFView from '../../../components/general/ModelPDFView';
import OrderDetailsModel from '../../order/models/OrderDetailsModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faLongArrowAltRight, faPrint, faReceipt } from '@fortawesome/free-solid-svg-icons';


/* Component ==================================================================== */
class POSBillingOrderList extends Component {
  static componentName = 'POSBillingOrderList';

  static propTypes = {
    orders: PropTypes.object.isRequired,
    property: PropTypes.object.isRequired,
    posDetails: PropTypes.object.isRequired,
    fetchPOSOrderData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
  };

  triggerPrint = (printURL) => {
    const {posDetails} = this.props;
    this.setState({error: null});
    if (posDetails.print_allow_LPRP) {
      printerAPI(this.props.posDetails, 'post', 'printers/print', {
        url: printURL
      })
        .catch((error) => {
          console.log(error);
          this.setState({
            error: AppAPI.handleError(error),
            showModelPDFView: true,
            urlModelPDFView: printURL
          });
        });
    } else {
      this.setState({showModelPDFView: true, urlModelPDFView: printURL});
    }
  };

  render = () => {
    const {orders} = this.props;
    return (
      <div>
        {!_.isEmpty(orders) &&
          <div>
            <h5 className={'mb-4'}>
              <FontAwesomeIcon icon={faReceipt} className={'mr-2'} size={'sm'}/> Billing Order(s)
            </h5>
            <div className="table-responsive border format-table">
              <table className={'table table-sm'}>
                <thead>
                  <tr>
                    <th className={'data-table-cell-md'}>Reference</th>
                    <th className={'data-table-cell-md'}>Amount</th>
                    <th className={'data-table-cell-md'}/>
                    <th className={'data-table-cell-md'}>Date</th>
                    <th/>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((data, key) => (
                    <tr className={'tr-align-middle'} key={key}>
                      <td className={'data-table-cell-md'}>
                        <small>{data.ref_no}</small>
                        <p className={'small text-muted mb-0'}>{data.order_type_display}</p>
                      </td>
                      <td className={'data-table-cell-md'}><strong>{`${data.currency} ${data.total}`}</strong></td>
                      <td className={'data-table-cell-md'}>
                        <span className={'badge ' + (AppUtil.paymentStatusColor(data.order_status))}>
                          {data.order_status_display}
                        </span>
                        {!data.lock && <span className={'badge badge-warning small ml-2'}>Open Order</span>}
                      </td>
                      <td className={'data-table-cell-md'}><small>{AppUtil.formatDateTime(data.created)}</small></td>
                      <td className={'text-center'}>
                        <button
                          disabled={!data.lock || data.void}
                          className={'btn btn-sm btn-outline-primary mb-1'}
                          onClick={() => this.triggerPrint(data.resources_link_pos)}
                        >
                          <FontAwesomeIcon icon={faPrint} size={'sm'} className={'mr-2'}/> Print
                        </button>
                        <Link to={`/order/${data.hash}`} className={'btn btn-sm btn-outline-primary ml-2 mb-1'}>
                        Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
                        </Link> <br/>
                        <button
                          disabled={!data.lock || data.void}
                          className={'btn btn-sm btn-outline-primary mb-0'}
                          onClick={() => this.setState({
                            showOrderPaymentDetails: true,
                            selectOrderPaymentDetails: data
                          })}
                        >
                          <FontAwesomeIcon icon={faCashRegister} size={'sm'} className={'mr-2'}/>
                          {`${data.order_status === Constants.ORDER_STATUS.NOTPAID ? 'Add' : 'View'}`} Payment
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        }
        <ModelPDFView
          title={'Print Order'}
          error={this.state.error}
          show={this.state.showModelPDFView}
          pdfUrl={this.state.urlModelPDFView}
          onHide={() => this.setState({showModelPDFView: false, urlModelPDFView: null})}
        />

        {/* Order Payment Details */}
        <OrderDetailsModel
          property={this.props.property}
          show={this.state.showOrderPaymentDetails}
          order={this.state.selectOrderPaymentDetails}
          fetchPOSOrderData={this.props.fetchPOSOrderData}
          onHide={() => this.setState({showOrderPaymentDetails: false, selectOrderPaymentDetails: null})}
        />
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      urlModelPDFView: null,
      showModelPDFView: false,
      showOrderPaymentDetails: false,
      selectOrderPaymentDetails: null,
    };
  }
}

/* Export Component ==================================================================== */
export default (POSBillingOrderList);

