/**
 *
 * Property Payment Gateway Settings
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Strings} from '../../../constants';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PageHeader from '../../../components/dashboard/PageHeader';
import {Alerts} from '../../../components/ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight, faDownload, faPen, faSortNumericDown} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as PropertyActions from '../../../redux/property/actions';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetPropertyDetails: PropertyActions.isSetPropertyDetails,
};

/* Component ==================================================================== */
class PropertySequenceSettings extends Component {
  static componentName = 'PropertySequenceSettings';

  componentDidMount = () => {
    this.setState({loading: false});
  };

  fetchSequenceData = () => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.property.get(`${this.props.property.id}/sequence-details/`)
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}, sequenceData: res},
            () => {
              setTimeout(() => this.setState({resultMsg: {success: ''}}), 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    });
  };

  render = () => {
    const {loading, error, resultMsg, sequenceData} = this.state;
    const {property} = this.props;

    if (_.isEmpty(property) || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const {sequence_settings, sequence_prefix} = property;
    return (
      <div className="sequence-settings screen-container">
        <Helmet>
          <title>Sequence Settings</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'Sequence Settings'}
          description={'Sequence number and sequence prefix for different entries in the property.'}
        />

        <div className="row">
          <div className="col-lg-9 col-md-8 col-sm-12">
            <table className="table table-bordered">
              <thead className={'text-center'}>
                <tr>
                  <th className={'data-table-cell'}>Package</th>
                  <th className={'data-table-cell-sm'}>Identifier</th>
                  <th className={'data-table-cell'}>Date</th>
                  <th className={'data-table-cell-sm'}>Delimiter</th>
                  <th className={'data-table-cell-sm'}>Zero fills</th>
                  <th className={'data-table-cell-sm'}>Prefix</th>
                </tr>
              </thead>
              <tbody>
                {_.isEmpty(sequence_settings) ?
                  <React.Fragment>
                    <tr>
                      <td colSpan={8}>
                        <div className={'text-center table-list-empty table-list-empty-sm'}>
                          <h4><FontAwesomeIcon icon={faSortNumericDown}/> Sequences </h4>
                          {Strings.sequenceListEmpty.map((data, i) => <p key={i}>{data}</p>)}
                          <p>
                            <Link to={'/settings/property/sequence-settings/manage'} className="btn btn-info btn-sm">
                              Configure Sequence Settings
                              <FontAwesomeIcon icon={faArrowRight} size={'sm'} className={'ml-2'}/>
                            </Link>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    {Object.keys(sequence_settings).map((key, i) => (
                      <tr key={i}>
                        <td className={'text-capitalize'}>{key}</td>
                        <td>{sequence_settings[key].prefix_code || ''}</td>
                        <td>{sequence_settings[key].prefix_date || ''}</td>
                        <td>{sequence_settings[key].prefix_delimiter || ''}</td>
                        <td>{sequence_settings[key].prefix_zero_fill || ''}</td>
                        <td>{!_.isEmpty(sequence_prefix) ? sequence_prefix[key] || '' : '..'}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                }
              </tbody>
            </table>
            <hr/>
            <h5>Last Issued Sequence</h5>
            <p className={'small'}>Details of last issued sequence number and prefix combinations for each package.</p>

            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />

            <table className="table table-bordered format-table">
              <thead className={'text-center'}>
                <tr>
                  <th className={'data-table-cell-lg'}>Package</th>
                  <th className={'data-table-cell-md'}>Sequence</th>
                  <th className={'data-table-cell-md'}>Number</th>
                </tr>
              </thead>
              <tbody>
                {_.isEmpty(sequenceData) ?
                  <React.Fragment>
                    <tr>
                      <td colSpan={8}>
                        <div className={'text-center table-list-empty table-list-empty-sm'}>
                          <h4><FontAwesomeIcon icon={faSortNumericDown}/> Sequences </h4>
                          <p>
                            <button onClick={() => this.fetchSequenceData()} className="btn btn-info">
                              <FontAwesomeIcon icon={faDownload} size={'sm'} className={'ml-2'}/> Fetch Last issued
                              Sequence
                            </button>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    {Object.keys(sequenceData).map((key, i) => (
                      <tr key={i}>
                        <td className={'text-capitalize'}>{key}</td>
                        <td>{sequenceData[key].sequence || ''}</td>
                        <td className={'text-center'}>{sequenceData[key].value || ''}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                }

              </tbody>
            </table>
            {!_.isEmpty(sequenceData) &&
            <button className={'btn btn-success'} onClick={() => this.fetchSequenceData()}>
              Fetch Last issued Sequence
            </button>
            }
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item">
                <Link to={'/settings/property/sequence-settings/manage'} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      sequenceData: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertySequenceSettings);
