/**
 * Manage Event
 *  Event add and edit
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {Formik} from 'formik';
import * as Yup from 'yup';

// Consts and Libs
import AppAPI from '../../../lib/api';
import {ErrorMessages, Strings} from '../../../constants';

// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PageHeader from '../../../components/dashboard/PageHeader';
import {Checkbox, Form, Input, SubmitBtn, Textarea} from 'react-formik-ui';
import {Alerts} from '../../../components/ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleDown, faPlus, faSave} from '@fortawesome/free-solid-svg-icons';

// Actions
import * as EventActions from '../../../redux/event/actions';
import AppUtil from '../../../lib/util';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  event: state.event.event
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setTicket: EventActions.setTicket
};


/* Component ==================================================================== */

class TicketManage extends Component {
  static componentName = 'TicketManage';

  static propTypes = {
    match: PropTypes.object
  };

  componentDidMount = () => {
    this.setState({settings: (this.props.match.params && this.props.match.params.ticketId)}, ()=>this.fetchInitData());
  };

  addTicket = (credentials) => {
    const {event} = this.props;
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});
      AppAPI.eventapi.post('ticket/',
        {
          'name': credentials.name,
          'description': credentials.description,
          'alert_description': credentials.alert_description,
          'booking_description': credentials.booking_description,
          'event_booking_end_time': credentials.event_booking_end_time,
          'quantity': credentials.quantity,
          'max_quantity': credentials.max_quantity,
          'base_price': credentials.base_price,
          'allow_booking_engine': credentials.allow_booking_engine,
          'active': credentials.active,
          'tax_class': this.state.selectedTaxClasses,
          'event': event.id,
        })
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                this.props.history.replace(this.props.location.pathname.replace(/[^/]*$/, `${res.hash}/`));
              }, 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  saveTicket = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});
      AppAPI.eventapi.patch(`ticket/${this.props.match.params.ticketId}/`,
        {
          'name': credentials.name,
          'description': credentials.description,
          'alert_description': credentials.alert_description,
          'booking_description': credentials.booking_description,
          'event_booking_end_time': credentials.event_booking_end_time,
          'quantity': credentials.quantity,
          'max_quantity': credentials.max_quantity,
          'base_price': credentials.base_price,
          'allow_booking_engine': credentials.allow_booking_engine,
          'active': credentials.active,
          'tax_class': this.state.selectedTaxClasses
        })
        .then(() => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                this.props.history.goBack();
              }, 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  fetchInitData = () => {
    let tax_class = [];
    AppAPI.propertyapi
      .get('tax-classes-list/')
      .then((res) => {
        res.forEach(data => {
          tax_class[data.id.toString()] = data;
        });
        this.setState({
          loading: false,
          tax_class: tax_class,
        });
      })
      .then(() => {
        /**
         * To get pre-fill value for Event edit.`
         */
        if (this.props.match.params.ticketId) {
          this.setState({loading: true});
          AppAPI.eventapi.get('ticket/' + this.props.match.params.ticketId + '/')
            .then((res) => {
              this.setState({
                loading: false,
                initialValues: {
                  'name': res.name,
                  'description': res.description,
                  'alert_description': res.alert_description,
                  'booking_description': res.booking_description,
                  'event_booking_end_time': res.event_booking_end_time,
                  'quantity': res.quantity,
                  'max_quantity': res.max_quantity,
                  'base_price': res.base_price,
                  'allow_booking_engine': res.allow_booking_engine,
                  'active': res.active,
                },
                selectedTaxClasses: res.tax_class
              });
            });
        }
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: true,
          resultMsg: {error}
        });
      });
  };

  selectTax = tax => {
    let {selectedTaxClasses} = this.state;
    selectedTaxClasses = AppUtil.insertOrRemoveArray(selectedTaxClasses, tax);
    this.setState({selectedTaxClasses});
  };

  render = () => {

    const {loading, error, resultMsg, settings, initialValues, tax_class, selectedTaxClasses} = this.state;

    const {event, property} = this.props;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    const formValidation = Yup.object().shape({
      name: Yup.string().min(2, 'Too Short!').required('Required'),
      quantity: Yup.number().required('Required'),
      max_quantity: Yup.number().required('Required'),
      base_price: Yup.number().required('Required'),
    });


    return (
      <div className="agent-manage screen-container ">
        <Helmet>
          <title>Event Manage</title>
        </Helmet>
        <PageHeader
          subHeader={true} history={this.props.history}
          title={(this.props.match.params.ticketId ? 'Manage': 'Add') + ' Ticket'}
          description={`Tickets in ${event.name}`}
        />
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <Formik
              onSubmit={(values) => (this.props.match.params.ticketId ? this.saveTicket(values) : this.addTicket(values))}
              validationSchema={formValidation}
              initialValues={initialValues}>
              {() => (
                <Form>
                  <div className={'row'}>
                    <div className="col-lg-8 col-md-8 col-sm-12">
                      <div
                        className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                        role="button" aria-expanded="false" aria-controls="orderActions">
                        <h6 className={'mb-0'}>
                          Event Information
                        </h6>
                      </div>
                      <div className={'border p-2'} id={'primaryAction'}>
                        <div className="form-row">
                          <div className="col-12 form-group">
                            <Input
                              required
                              name='name'
                              label={'Ticket Name'}
                              className={'form-control'}
                            />
                          </div>

                        </div>

                        <div className="form-row">
                          <div className="col-lg-6 col-md-6 form-group">
                            <Input
                              required
                              name='quantity'
                              label={'Quantity'}
                              hint={'Quantity of ticket available per event. Enter 0 for unlimited.'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 form-group">
                            <Input
                              required
                              type={'number'}
                              name='max_quantity'
                              label={'Max Quantity'}
                              className={'form-control'}
                              hint={'Maximum ticket allowed in one single booking from booking engine. Recommended value 10'}
                            />
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="col-lg-6 col-md-6 form-group">
                            <Input
                              required
                              type={'number'}
                              name='base_price'
                              label={'Price ' + property.currency}
                              className={'form-control'}
                            />
                          </div>
                        </div>


                        <div className="form-row">
                          <div className="col-6 form-group">
                            <Checkbox
                              className={'mr-2'}
                              name='active'
                              label='Ticket Status'
                              text={'Enable ticket sale ?'}
                            />
                          </div>
                          <div className="col-6 form-group">

                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className="col-lg-8 col-md-8 col-sm-12">
                      <div
                        className={'border p-2 text-center anchor'} data-toggle="collapse" href="#taxitems"
                        role="button" aria-expanded="false" aria-controls="taxitems"
                      >
                        <h6 className={'mb-0'}>
                          <FontAwesomeIcon
                            icon={faChevronCircleDown} size={'sm'}
                            className={'mx-2 green-cl float-left mt-1'}/>
                          Tax Information
                          {selectedTaxClasses.length > 0 &&
                           <span className={'ml-3 badge badge-success'}>{selectedTaxClasses.length} Tax applied</span>
                          }
                          <FontAwesomeIcon
                            icon={faChevronCircleDown} size={'sm'}
                            className={'mx-2 green-cl float-right mt-1'}/>
                        </h6>
                      </div>
                      <div className={'py-2 border collapse p-2'} id={'taxitems'}>
                        {this.props.match.params.ticketId &&
                         <Alerts
                           status={'Changes will only be effective on future orders.'}
                         />
                        }
                        <ul className="list-group mt-3 list-inline">
                          {
                            tax_class.map((data, i) => (
                              <li
                                className="list-group-item list-inline-item a-class"
                                key={i}
                                onClick={() => this.selectTax(data.id)}
                              >
                                <input
                                  className={'mr-2'} type="checkbox"
                                  checked={selectedTaxClasses.includes(data.id)}/>
                                {data.name} : {data.description}
                              </li>
                            ))
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className="col-lg-8 col-md-8 col-sm-12">
                      <div
                        className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                        role="button" aria-expanded="false" aria-controls="orderActions">
                        <h6 className={'mb-0'}>
                          Event Descriptions
                        </h6>
                      </div>
                      <div className={'border p-2'} id={'primaryAction'}>

                        <div className="form-row">
                          <div className="col-lg-12 col-md-12 form-group">
                            <Textarea
                              name='description'
                              label={'Description'}
                              hint={'Ticket description'}
                              className={'form-control'}
                            />
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="col-lg-12 col-md-12 form-group">
                            <Textarea
                              name='alert_description'
                              label={'Additional ticket information'}
                              hint={'Important information for the ticket, will be highlighted on booking engine, email & pdf.'}
                              className={'form-control'}
                            />
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="col-lg-12 col-md-12 form-group">
                            <Textarea
                              name='booking_description'
                              label={'Booking Description'}
                              hint={Strings.eventBookingDescription}
                              className={'form-control'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-12 mt-2 mb-5">
                      <Alerts
                        status={resultMsg.status}
                        success={resultMsg.success}
                        error={resultMsg.error}
                      />
                      <SubmitBtn className={'btn btn-success btn-block btn-lg mt-2'}>
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={(settings ? faSave : faPlus)} size={'sm'}/>
                        {(settings ? 'Save' : 'Add')} Ticket
                      </SubmitBtn>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>

    );
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: ''
      },
      initialValues: {
        name: '',
        quantity: '',
        base_price: '',
        max_quantity: 10,
        active: true
      },
      tax_class: [],
      selectedTaxClasses: [],
      settings: false
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(TicketManage);
