import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import {Constants} from '../../constants';

import NotFound from '../NotFound';
import BookingAdd from './BookingAdd';
import FrontDesk from './FrontDesk';
import BookingLookUp from './BookingLookUp';
import BookingAnalytics from './Analytics';
import BookingActions from './BookingActions';
import BookingDetails from './bookingDetails';
import BookingListIndex from './BookingListIndex';
import BookingModifyResults from './BookingModifyResults';

import BookingRoomDetails from '../bookingroom/BookingRoomDetails';
import BookingRoomActions from '../bookingroom/BookingRoomActions';
import BookingRoomManage from '../bookingroom/BookingRoomManage';
import BookingRoomUpdate from '../bookingroom/BookingRoomUpdate';

import BookingRoomListIndex from '../bookingroom/BookingRoomListIndex';

import BookingCalenderView from './BookingCalenderView';
import BookingAmendmentRequestList from './BookingAmendmentRequestList';
import BookingAmendmentRequestDetails from './BookingAmendmentRequestDetails';

import AvailabilityChart from '../availability/AvailabilityChart';
import OccupancyChart from '../availability/OccupancyChart';

import BookingRequired from '../../components/decorators/bookingRequired';
import bookingRoomRequired from '../../components/decorators/bookingRoomRequired';
import RateChart from '../rate/RateChart';
import HousekeepingRoomList from '../room/HousekeepingRoomList';
import ReportsListIndex from '../reports/ReportsListIndex';

import BookingDigest from './digest';

const BookingRoute = () => (
  <div>

    <div className="container">
      <Switch>
        <Route path="/booking/" exact component={FrontDesk}/>
        <Route path="/booking/analytics/" component={BookingAnalytics}/>
        <Route path="/booking/booking-calender-view/" exact component={BookingCalenderView}/>

        <Route path="/booking/availability-chart" exact component={AvailabilityChart}/>
        <Route path="/booking/occupancy-chart" exact component={OccupancyChart}/>

        <Route path="/booking/rate-chart/" exact component={RateChart}/>

        <Route
          exact
          path={'/booking/reports/'}
          render={() => (<Redirect to={'/booking/reports/list'}/>)}
        />

        <Route
          path={'/booking/reports/list'} exact
          render={(props) =>
            <ReportsListIndex
              {...props}
              baseUrl={'/booking'}
              headerTitle={'Booking Report\'s'}
              headerSubTitle={'Consolidated booking reports.'}
              source={Constants.REPORT_SOURCE.BOOKING}
              reportOptions={Constants.REPORTS_BOOKING}
              dateType={Constants.REPORTS_DATE_TYPE_BOOKING}
            />
          }
        />

        <Route path="/booking/add" exact component={BookingAdd}/>
        <Route path='/booking/lookup/' exact component={BookingLookUp}/>
        <Route path='/booking/lookup/:checkin/to/:checkout/' exact component={BookingLookUp}/>

        <Route path="/booking/room-list" exact component={HousekeepingRoomList}/>


        <Route path="/booking/list" component={BookingListIndex}/>
        <Route path="/booking/booking-room/" exact render={() => (<Redirect to="/booking/booking-room/list"/>)}/>
        <Route path="/booking/booking-room/list" component={BookingRoomListIndex}/>

        <Route path='/booking/:bookingId/add/' exact component={BookingRequired(BookingAdd)}/>
        <Route
          path='/booking/:bookingId/lookup/:checkin/to/:checkout/' exact
          component={BookingRequired(BookingLookUp)}/>
        <Route
          path='/booking/:bookingId/modify/:checkin/to/:checkout/' exact
          component={BookingRequired(BookingModifyResults)}/>

        <Route path="/booking/:bookingId/action/:bookingAction/" exact component={BookingRequired(BookingActions)}/>

        <Route path="/booking/:bookingId/booking-room/:bookingRoomId/action/" exact component={BookingRoomActions}/>
        <Route path="/booking/:bookingId/booking-room/:bookingRoomId/update/" exact component={BookingRoomManage}/>
        <Route
          path="/booking/:bookingId/booking-room/:bookingRoomId/update-room-type/" exact
          component={BookingRoomUpdate}
        />
        <Route
          path="/booking/:bookingId/booking-room/:bookingRoomId/"
          component={bookingRoomRequired(BookingRoomDetails)}
        />


        <Route path="/booking/amendment-request/" exact render={() => (<Redirect to="/booking/amendment-request/list"/>)}/>
        <Route path="/booking/amendment-request/list" component={BookingAmendmentRequestList}/>
        <Route path="/booking/:bookingId/amendment-request/:bookingAmendmentRequestId/" component={BookingAmendmentRequestDetails}/>

        <Route path="/booking/digest/" component={BookingDigest}/>
        <Route path="/booking/:bookingId" component={BookingRequired(BookingDetails)}/>

        <Route exact component={NotFound}/>
      </Switch>
    </div>

  </div>
);

export default BookingRoute;
