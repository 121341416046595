/**
 *
 * Booking Order Details
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
// Consts and Libs
import AppUtil from '../../../lib/util';
import AppAPI from '../../../lib/api';
import {Constants, Filters, Strings} from '../../../constants';
// Components
import BookingSummary from '../models/BookingSummary';
import Error from '../../../components/general/Error';
import PaymentAdd from '../../payment/models/PaymentAdd';
import Loading from '../../../components/general/Loading';
import FilterButton from '../../../components/dashboard/FilterButton';
import PageSubHeader from '../../../components/dashboard/PageSubHeader';
import OrderConsolidatedEdit from '../../order/models/OrderConsolidatedEdit';
// Actions
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faFileDownload, faPercentage, faPlusCircle, faReceipt, faSync } from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  booking: state.booking.booking,
});

// Any actions to map to the component?
const mapDispatchToProps = {};


/* Component ==================================================================== */
class BookingOrderDetails extends Component {
  static componentName = 'BookingOrderDetails';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    this.setState({loading: true});
    let appApi = AppAPI.booking.get(`${this.props.match.params.bookingId}/related-details/?key=order`);

    if (this.props.match.params.bookingRoomId) {
      appApi = AppAPI.bookingapi.get(`booking-room/${this.props.match.params.bookingRoomId}/related-details/?key=order`);
    }

    appApi.then((res) => {
      this.setState({
        response: res,
        loading: false,
      });
    })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };

  render = () => {

    const {loading, error, response, orderSource, orderStatus, orderOpen, orderCancelled} = this.state;
    const {booking, property} = this.props;

    const {config} = property;

    if (loading || _.isEmpty(booking) || _.isEmpty(response)) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    const {orders} = response;


    let orderList = orders;

    if (orderOpen) {
      orderList = orderList.filter(item => (item.lock === false));
    }

    orderList = orderList.filter(item => (item.void === orderCancelled));

    if (orderStatus || orderStatus === 0) {
      orderList = orderList.filter(item => (item.order_status === orderStatus));
    }

    if (orderSource || orderSource === 0) {
      orderList = orderList.filter(item => (item.order_source === orderSource));
    }

    const pendingOrders = !_.isEmpty(orders) ? orders.filter(item => (item.order_status === Constants.ORDER_STATUS.NOTPAID || item.order_status === Constants.ORDER_STATUS.PARTPAID)).length : 0;

    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Booking Order List</title>
        </Helmet>

        <PageSubHeader icon={faReceipt} title={'Order(s)'} description={'Booking related orders.'}/>

        <div className={'row'}>
          <div className={'col-md-4 col-12'}>
            <table className={'table table-borderless table-sm mb-3'}>
              <tbody>
                <tr>
                  <td className={'w-50 text-muted'}>Total Orders</td>
                  <td><strong>{!_.isEmpty(orders) ? orders.length : 0}</strong></td>
                </tr>
                <tr>
                  <td className={'w-50 text-muted'}>Cancelled/Void Orders</td>
                  <td><strong>{!_.isEmpty(orders) ? orders.filter(item => (item.void === true)).length : 0}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={'col-md-4 col-12'}>
            <table className={'table table-borderless table-sm mb-3'}>
              <tbody>
                <tr>
                  <td className={'w-50 text-muted'}>Paid Orders</td>
                  <td>
                    <strong>{!_.isEmpty(orders) ? orders.filter(item => (item.order_status === Constants.ORDER_STATUS.PAID)).length : 0}</strong>
                  </td>
                </tr>
                <tr>
                  <td className={'w-50 text-muted'}>Pending Orders</td>
                  <td><strong>{pendingOrders}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={'spacer-20'}/>

        <div className={'row'}>
          <div className={'col-12 col-sm-8'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button
                  onClick={() => this.fetchInitData()}
                  className={`btn btn-sm btn-outline-${orderOpen ? 'primary' : 'secondary'}`}
                >
                  <FontAwesomeIcon icon={faSync} className={'mr-2'} size={'sm'}/> Refresh
                </button>
              </li>
              <li className="list-inline-item">
                <button
                  onClick={() => this.setState({orderOpen: !orderOpen, orderCancelled: false})}
                  className={`btn btn-sm btn-outline-${orderOpen ? 'primary' : 'secondary'}`}
                >
                Open Order
                </button>
              </li>
              <li className="list-inline-item">
                <button
                  onClick={() => this.setState({orderCancelled: !orderCancelled, orderOpen: false})}
                  className={`btn btn-sm btn-outline-${orderCancelled ? 'primary' : 'secondary'}`}
                >
                Void Order
                </button>
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Order Source'}
                  data={Filters.orderSourceValueFilter}
                  selectKey={orderSource}
                  action={(data) => this.setState({orderSource: data.key})}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Payment Status'}
                  data={Filters.orderStatusValueFilter}
                  selectKey={orderStatus}
                  action={(data) => this.setState({orderStatus: data.key})}
                  size={'sm'}
                />
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-4 text-center text-sm-right'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button
                  onClick={() => this.setState({showBookingSummary: true})}
                  className={'btn btn-outline-primary btn-sm'}
                >
                  <FontAwesomeIcon icon={faFileDownload} size={'sm'} className={'mr-2'}/>Booking Folio
                </button>
              </li>
              <li className="list-inline-item">
                <button
                  onClick={() => this.setState({showPayment: true})}
                  className={'btn btn-outline-success btn-sm'}
                  disabled={(pendingOrders < 1)}
                >
                  <FontAwesomeIcon icon={faPlusCircle} size={'sm'} className={'mr-2'}/> Add Consolidated Payment
                </button>
              </li>
            </ul>
          </div>
        </div>

        {this.props.booking.booking_order_mask && (
          <div className={'mb-3'}>
            <p className={'text-muted'}>
              {Strings.bookingOrderMasked} Click to
              <button className={'btn btn-link px-1'} onClick={()=>this.setState({bookingOrderMask: !this.state.bookingOrderMask})}>
                {this.state.bookingOrderMask ? 'Show' : 'Mask'} booking order.
              </button>.
            </p>
          </div>
        )}

        <div className="table-responsive border">
          <table className={'table table-hover mb-0'}>
            <thead>
              <tr>
                <th>Reference</th>
                <th/>
                <th className={'text-left'}>Room</th>
                <th>Source</th>
                <th className={'text-left'}>Total</th>
                <th className={'text-left'}>Pending</th>
                <th className={'text-left'}>Paid</th>
                <th className={'text-left'}>Refunded</th>
              </tr>
            </thead>
            <tbody>
              {!_.isEmpty(orderList) ?
                <React.Fragment>
                  {orderList.map((data, i) => {
                    return (
                      <tr className={'tr-align-middle'} key={i}>
                        <td className={'data-table-cell-md'}>
                          <Link to={`/order/${data.hash}`}>{data.ref_no}</Link>
                          <p className={'small text-muted mb-0'}>{AppUtil.formatDateTime(data.created)}</p>
                        </td>
                        <td className={'data-table-cell-md text-left'}>
                          <span className={`badge mx-1 ${AppUtil.paymentStatusColor(data.order_status)}`}>
                            {data.order_status_display}
                          </span>
                          {!data.lock && <span className={'badge badge-warning small mx-1'}>Open Order</span>}
                        </td>
                        <td className={'data-table-cell-lg text-left'}>
                          {!_.isEmpty(data.booking_rooms_attributes) &&
                          <ul className="list-inline list-item-mb-1 mb-0">
                            {data.booking_rooms_attributes.map((roomData, i) => (
                              <li className="list-inline-item p-0 m-0 mr-2" key={i}>
                                <Link to={`/booking/${data.attributes.booking_hash}/booking-room/${roomData.hash}`}>
                                  Room {roomData.room_number}
                                </Link>
                              </li>
                            ))}
                          </ul>
                          }
                          <p className={'small text-muted mb-0'}>{data.order_type_display}</p>
                        </td>
                        <td className={'data-table-cell-md text-center'}>
                          {data.order_source_display}
                          {!_.isEmpty(data.attributes) &&
                          <React.Fragment>
                            {data.attributes.pos_name &&
                            <p className={'small mb-0 text-muted'}>{data.attributes.pos_name}</p>}
                            {data.attributes.event_name &&
                            <p className={'small mb-0 text-muted'}>{data.attributes.event_name}</p>}
                            {data.attributes.booking_ota &&
                            <p className={'small mb-0 text-muted'}>{data.attributes.booking_ota}</p>}
                          </React.Fragment>
                          }
                        </td>
                        <td className={'data-table-cell-md text-left'}>
                          {(this.state.bookingOrderMask && data.order_source === Constants.ORDER_SOURCE.BOOKING)
                            ? (<strong>***</strong>)
                            : (<strong>{`${data.currency} ${data.total}`}</strong>)
                          }
                        </td>
                        <td className={'data-table-cell-md text-left red-cl'}><strong>{`${data.currency} ${data.total_unpaid_payment}`}</strong></td>
                        <td className={'data-table-cell-md text-left green-cl'}><strong>{`${data.currency} ${data.total_paid}`}</strong></td>
                        <td className={'data-table-cell-md text-left blue-cl'}><strong>{`${data.currency} ${data.total_refunded}`}</strong></td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={4}/>
                    <td className={'text-left'}><strong>{`${property.currency} ${orderList.reduce((a, b) => parseFloat(a) + parseFloat(b.total), 0).toFixed(2)}`}</strong></td>
                    <td className={'text-left red-cl'}><strong>{`${property.currency} ${orderList.reduce((a, b) => parseFloat(a) + parseFloat(b.total_unpaid_payment), 0).toFixed(2)}`}</strong></td>
                    <td className={'text-left green-cl'}><strong>{`${property.currency} ${orderList.reduce((a, b) => parseFloat(a) + parseFloat(b.total_paid), 0).toFixed(2)}`}</strong></td>
                    <td className={'text-left blue-cl'}><strong>{`${property.currency} ${orderList.reduce((a, b) => parseFloat(a) + parseFloat(b.total_refunded), 0).toFixed(2)}`}</strong></td>
                  </tr>
                </React.Fragment>
                :
                <tr>
                  <td colSpan={9}>
                    <div className={'text-center table-list-empty'}>
                      <h6><FontAwesomeIcon icon={faReceipt} className={'mr-2'}/> No Order </h6>
                      <p className={'text-muted'}>{Strings.bookingOrderEmpty}</p>
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>

        <div className={'mt-3'}>
          <ul className="list-inline list-item-mb-1">
            {!_.isEmpty(config) && config.booking && config.booking.order_discount_post_payment_consolidated && (
              <li className="list-inline-item">
                <button
                  disabled={(pendingOrders < 1)}
                  className={'btn btn-outline-success btn-sm'}
                  onClick={() => this.setState({showOrderConsolidatedEdit: true})}
                >
                  <FontAwesomeIcon icon={faPercentage} size={'sm'} className={'mr-2'}/> Discount Post Payment
                </button>
              </li>
            )}
          </ul>
        </div>

        {/* Consolidated Order Edit */}
        <OrderConsolidatedEdit
          orders={orders}
          property={property}
          show={this.state.showOrderConsolidatedEdit}
          updateResponse={()=>this.fetchInitData()}
          onHide={() => this.setState({showOrderConsolidatedEdit: false})}
        />

        {/* Payment Add */}
        <PaymentAdd
          updateResponse={(data) => this.props.history.push(`/payment/${data.hash}/`)}
          onHide={() => this.setState({showPayment: false})}
          consolidated={true}
          orders={orders}
          guestData={booking.guest}
          show={this.state.showPayment}
          property={this.props.property}
        />

        {/* BookingSummary */}
        <BookingSummary
          booking={booking}
          property={this.props.property}
          show={this.state.showBookingSummary}
          onHide={()=>this.setState({showBookingSummary: false})}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      orderOpen: false,
      orderCancelled: false,
      showBookingSummary: false,
      showOrderConsolidatedEdit: false,
      bookingOrderMask: this.props.booking.booking_order_mask || false,
      orderSource: null,
      orderStatus: null,
      showPayment: false,
      response: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(BookingOrderDetails);
