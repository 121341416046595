/**
 * Error
 */
import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListView from '../general/ListView';
import ListViewV2 from '../general/ListViewV2';

/* Component ==================================================================== */
class SearchBar extends Component {
  static componentName = 'SearchBar';

  static propTypes = {
    searchFunction: PropTypes.func,
    placeHolder: PropTypes.string,
    refresh: PropTypes.bool,
    renderRow: PropTypes.func,
    fetchData: PropTypes.func,
    emptyView: PropTypes.func,
    tableHeader: PropTypes.func,
    colSpan: PropTypes.number,
    listVersion: PropTypes.oneOf(['V1', 'V2']) // V1 for number and V2 cursor pagination
  };

  static defaultProps = {
    searching: false,
    fetchData: () => null,
    listVersion: 'V1'
  };

  handleInputChange = (value) => {
    this.setState({value: value, searching: true});
    this.onChangeDebounced(value);
  };

  onChangeDebounced = (value) => {
    this.setState({searchText: value, searching: false});
  };

  render = () => {
    const {placeHolder} = this.props;
    const {searchText, searching} = this.state;

    const ListComponent = this.props.listVersion === 'V2' ? ListViewV2: ListView;
    return (
      <div className="search-bar">
        {this.props.searchFunction &&
         <>
           <div className="form-row align-items-center mb-4">
             <div className={searching ? 'col-sm-11' : 'col-sm-12'}>
               <input
                 type="input"
                 className="form-control"
                 placeholder={placeHolder}
                 autoComplete={'off'}
                 onChange={(data) => this.handleInputChange(data.target.value)}
               />
             </div>
             <div className="col-auto">
               {searching && <i className="fa fa-spinner"/>}
             </div>
           </div>
         </>
        }
        <div>
          {this.props.tableHeader ?
            <div className="table-responsive border">
              <table className={'table format-table'}>
                <thead>{this.props.tableHeader()}</thead>
                <tbody>
                  <ListComponent
                    rowView={this.props.renderRow}
                    refresh={this.props.refresh}
                    onFetch={searchText !== '' ? this.props.searchFunction : this.props.fetchData}
                    searchParams={searchText}
                    firstLoader={true}
                    pagination
                    emptyView={this.props.emptyView}
                    class={'list-group'}
                    colSpan={this.props.colSpan}
                  />
                </tbody>
              </table>
            </div>
            :
            <React.Fragment>
              <ListComponent
                rowView={this.props.renderRow}
                refresh={this.props.refresh}
                onFetch={searchText !== '' ? this.props.searchFunction : this.props.fetchData}
                searchParams={searchText}
                firstLoader={true}
                pagination
                emptyView={this.props.emptyView}
                class={'list-group'}
              />
            </React.Fragment>
          }
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);
    this.onChangeDebounced = _.debounce(this.onChangeDebounced, 600, {});
    this.state = {
      loading: false,
      error: null,
      value: '',
      searchText: '',
    };
  }
}

/* Export Component ==================================================================== */
export default SearchBar;
