/**
 *
 * Property Settings
 */
import React, {Component} from 'react';
import {Formik} from 'formik';
import Helmet from 'react-helmet';
import {connect} from 'react-redux';
import {Datepicker, Form, Input, Select, SubmitBtn} from 'react-formik-ui';
import * as Yup from 'yup';
import Moment from 'moment';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import {Constants, SelectList, Strings} from '../../constants';
// Components
import {Alerts} from '../../components/ui';
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import PageHeader from '../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as PropertyActions from '../../redux/property/actions';



/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetPropertyDetails: PropertyActions.isSetPropertyDetails,
};

/* Component ==================================================================== */
class PropertyEdit extends Component {
  static componentName = 'PropertySettings';
  componentDidMount = () => {
    this.fetchInitData();
  };

  saveProperty = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.propertydetails.patch(this.props.property.id + '/',
          {
            'name': credentials.name,
            'tax_company': credentials.tax_company,
            'tax_number': credentials.tax_number,
            'tax_number_type': credentials.tax_number_type,
            'website_url': credentials.website_url,
            'google_maps_url': credentials.google_maps_url,

            'address_1': credentials.address_1,
            'address_2': credentials.address_2,
            'state': credentials.state,
            'country': credentials.country,
            'pin_code': credentials.pin_code,

            'email': credentials.email,
            'phone': credentials.phone ? credentials.code + credentials.phone : null,

            'sales_email': credentials.sales_email,
            'sales_phone': credentials.sales_phone ? credentials.sales_code + credentials.sales_phone : null,

            'support_email': credentials.support_email,
            'support_phone': credentials.support_phone ? credentials.support_code + credentials.support_phone : null,

            'checkout_time': Moment(credentials.checkout_time).format('HH:mm'),
            'checkin_time': Moment(credentials.checkin_time).format('HH:mm'),
          })
          .then((res) => {
            this.props.isSetPropertyDetails(res);
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.setState({
                    resultMsg: '',
                  });
                }, 500);
                this.props.history.goBack();
              });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  fetchInitData = () => {
    const {property} = this.props;
    let phone = AppUtil.processPhoneNumber(property.phone);
    let sales_phone = AppUtil.processPhoneNumber(property.sales_phone);
    let support_phone = AppUtil.processPhoneNumber(property.support_phone);
    this.setState({
      loading: false,
      initialValues: {
        'name': property.name,
        'tax_company': property.tax_company,
        'tax_number': property.tax_number,
        'tax_number_type': property.tax_number_type,

        'checkout_time': Moment(property.checkout_time, 'HH:mm:ss'),
        'checkin_time': Moment(property.checkin_time, 'HH:mm:ss'),


        'website_url': property.website_url,
        'google_maps_url': property.google_maps_url,

        'address_1': property.address_1 || '',
        'address_2': property.address_2,
        'state': property.state || '',
        'country': property.country ? property.country.code : '',
        'pin_code': property.pin_code || '',

        'email': property.email || '',
        'code': phone ? '+' + String(phone.getCountryCode()) : '+' +  property.phone_number_code,
        'phone': phone ? phone.getNationalNumber() : '',


        'sales_email': property.sales_email || '',
        'sales_code': sales_phone ? '+' + String(sales_phone.getCountryCode()) : '+' + property.phone_number_code,
        'sales_phone': sales_phone ? sales_phone.getNationalNumber() : '',

        'support_email': property.support_email || '',
        'support_code': support_phone ? '+' + String(support_phone.getCountryCode()) : '+' + property.phone_number_code,
        'support_phone': support_phone ? support_phone.getNationalNumber() : '',
      },
    });
  };

  render = () => {
    const {loading, error, initialValues, resultMsg} = this.state;
    const {property} = this.props;

    if (!property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;


    const defaultFormValidation = Yup.object().shape({
      name: Yup.string().min(2, 'Too Short!').required('Required'),
      website_url: Yup.string().url().nullable(),
      google_maps_url: Yup.string().url().nullable(),
      address_1: Yup.string().required('Required'),
      state: Yup.string().required('Required'),
      country: Yup.string().required('Required'),
      pin_code: Yup.number().required('Required'),

      sales_email: Yup.string().email('Invalid email').required('Required'),
      sales_phone: Yup.number().required('Required'),
      support_email: Yup.string().email('Invalid email').required('Required'),
      support_phone: Yup.number().required('Required'),

      email: Yup.string().email('Invalid email').required('Required'),
      phone: Yup.number().required('Required'),

      checkin_time: Yup.date().required('Required'),
      checkout_time: Yup.date().required('Required'),
    });


    return (
      <div className="dashboard screen-container">
        <Helmet>
          <title>Settings Overview</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'Property Details'}
        />

        <Formik
          onSubmit={(values)=>this.saveProperty(values)}
          validationSchema={defaultFormValidation}
          initialValues={initialValues}>
          {() => (
            <Form>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div
                    className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                    role="button" aria-expanded="false" aria-controls="orderActions">
                    <h6 className={'mb-0'}>
                      Property Information
                    </h6>
                  </div>
                  <div className={'border p-2'} id={'primaryAction'}>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Input
                          required
                          name='name'
                          label={'Property Name'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Input
                          name='tax_company'
                          label={'Company Name'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 form-group">
                        <Select
                          className={'form-control rounded-right-0'}
                          name='tax_number_type'
                          label={'Tax Type'}
                          placeholder='Select an Option'
                          options={Constants.TAX_NUMBER_TYPE}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 form-group">
                        <Input
                          name='tax_number'
                          label={'Tax Number'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 form-group">
                        <Input
                          name='website_url'
                          label={'Property Website'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 form-group">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                  <p>
                    <strong>Property Name : </strong> {Strings.propertyNameDescription}
                  </p>

                  <p>
                    <strong>Company Name : </strong> {Strings.propertyCompanyDescription}
                  </p>

                  <p>
                    <strong>Tax Type : </strong> {Strings.propertyTaxTypeDescription}
                  </p>

                  <p>
                    <strong>Tax Number : </strong> {Strings.propertyTaxNumberDescription}
                  </p>

                </div>
              </div>
              <hr/>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div
                    className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                    role="button" aria-expanded="false" aria-controls="orderActions">
                    <h6 className={'mb-0'}>
                      Property Address
                    </h6>
                  </div>
                  <div className={'border p-2'} id={'primaryAction'}>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Input
                          name='address_1'
                          label={'Address Line 1'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Input
                          name='address_2'
                          label={'Address Line 2'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-lg-4 col-md-4 col-sm-6 form-group">
                        <Input
                          name='state'
                          label={'State'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 form-group">
                        <Select
                          className={'form-control rounded-right-0'}
                          name='country'
                          label={'Country'}
                          placeholder='Select an Option'
                          options={SelectList.countryList()}
                        />
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 form-group">
                        <Input
                          name='pin_code'
                          label={'Pin Code'}
                          className={'form-control'}
                          type={'number'}
                        />
                      </div>
                    </div>
                    <hr/>
                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 form-group">
                        <Input
                          required
                          name='sales_email'
                          type={'email'}
                          label={'Sales E-mail'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 ">
                        <div className={'row'}>
                          <div className={'col-4 pr-0 form-group'}>
                            <Select
                              className={'form-control rounded-right-0'}
                              name='sales_code'
                              label={'Country'}
                              placeholder='Select an Option'
                              options={SelectList.phoneCountryCode()}
                            />
                          </div>
                          <div className={'col-8 pl-0 form-group'}>
                            <Input
                              required
                              type={'tel'}
                              name='sales_phone'
                              label={'Sales Phone'}
                              className={'form-control rounded-left-0'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr/>
                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 form-group">
                        <Input
                          required
                          name='support_email'
                          type={'email'}
                          label={'Support E-mail'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 ">
                        <div className={'row'}>
                          <div className={'col-4 pr-0 form-group'}>
                            <Select
                              className={'form-control rounded-right-0'}
                              name='support_code'
                              label={'Country'}
                              placeholder='Select an Option'
                              options={SelectList.phoneCountryCode()}
                            />
                          </div>
                          <div className={'col-8 pl-0 form-group'}>
                            <Input
                              required
                              type={'tel'}
                              name='support_phone'
                              label={'Support Phone'}
                              className={'form-control rounded-left-0'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr/>
                    <div className={'form-row'}>
                      <div className={'col-12'}>
                        <Input
                          name={'google_maps_url'}
                          label={'Google Maps URL'}
                          className={'form-control rounded-left-0'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                  <p>
                    <strong>Address : </strong> {Strings.propertyAddressDescription}
                  </p>
                  <p className={''}>
                    <strong>Sales contact : </strong> {Strings.propertySalesContactDescription}
                  </p>
                  <p className={''}>
                    <strong>Support contact : </strong> {Strings.propertySupportContactDescription}
                  </p>
                  <div className={'alert alert-primary'}>
                    <p><strong>Note : </strong>{Strings.propertySupportContactWarning}</p>
                  </div>
                  <p className={''}>
                    <strong>Google Maps : </strong> {Strings.propertyGoogleMapsUrl}
                  </p>

                </div>
              </div>
              <hr/>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div
                    className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                    role="button" aria-expanded="false" aria-controls="orderActions">
                    <h6 className={'mb-0'}>
                      Property Contact
                    </h6>
                  </div>
                  <div className={'border p-2'} id={'primaryAction'}>
                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 form-group">
                        <Input
                          required
                          name='email'
                          type={'email'}
                          label={'E-mail'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 ">
                        <div className={'row'}>
                          <div className={'col-4 pr-0 form-group'}>
                            <Select
                              className={'form-control rounded-right-0'}
                              name='code'
                              label={'Country'}
                              placeholder='Select an Option'
                              options={SelectList.phoneCountryCode()}
                            />
                          </div>
                          <div className={'col-8 pl-0 form-group'}>
                            <Input
                              required
                              type={'tel'}
                              name='phone'
                              label={'Phone'}
                              className={'form-control rounded-left-0'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                  <p className={''}>
                    <strong>Property contact : </strong>  {Strings.propertyContactDescription}
                  </p>
                </div>
              </div>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Checkin / Checkout Timing
                    </h6>
                  </div>
                  <div className={'border p-2'} id={'primaryAction'}>
                    <div className="form-row">
                      <div className="col-6 form-group time_only">
                        <p className={'mb-1'}>Checkin Time *</p>
                        <Datepicker
                          required
                          name='checkin_time'
                          className={'form-control'}
                          showTimeSelect
                          dateFormat="hh:mm aa"
                          timeFormat="HH:mm"
                        />
                      </div>
                      <div className="col-6 form-group time_only">
                        <p className={'mb-1'}>Checkout Time *</p>
                        <Datepicker
                          required
                          name='checkout_time'
                          className={'form-control time_only'}
                          showDateSelect={false}
                          showTimeSelect
                          dateFormat="hh:mm aa"
                          timeFormat="HH:mm"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                  <p>
                    <strong>Checkin / Checkout : </strong> {Strings.propertyCheckinCheckoutTimes}
                  </p>
                </div>

              </div>
              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 mt-2">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn className={'btn btn-success btn-block btn-lg mt-2'}>
                    <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyEdit);
