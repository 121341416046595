import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
// Consts and Libs
import AppAPI from '../../lib/api';
// Components
import Error from '../general/Error';
import Loading from '../general/Loading';
// Actions
import * as PaymentActions from '../../redux/payment/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  user: state.user,
  payment: state.payment.payment,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPayment: PaymentActions.setPayment,
};

export default function (ComposedComponent) {
  class PaymentRequired extends Component {
    static contextTypes = {
      router: PropTypes.object,
    };

    componentDidMount() {
      const {paymentId} = this.props.match.params;
      if (_.isEmpty(this.props.payment) || paymentId !== this.props.payment.hash) {
        this.firstLoad = true;
        this.props.setPayment(paymentId)
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({error});
          });
      }
    }

    render() {
      if (this.state.error) return <Error full={false} text={this.state.error}/>;
      if (_.isEmpty(this.props.payment) || this.props.payment.hash !== this.props.match.params.paymentId) return <Loading/>;
      return <ComposedComponent {...this.props} firstLoad={this.firstLoad}/>;
    }


    constructor(props) {
      super(props);
      this.firstLoad = false;
      this.state = { error: null };
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(PaymentRequired);
}