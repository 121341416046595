/**
 * Analytics Card
 *
 */
import PropTypes from 'prop-types';
import React from 'react';

/* Component ==================================================================== */
class AnalyticsCard extends React.PureComponent {
  static componentName = 'AnalyticsCard';

  static propTypes = {
    barData: PropTypes.object,
    mainCard: PropTypes.object,
    subCardLeft: PropTypes.object,
    subCardRight: PropTypes.object,
  };

  render = () => {
    const {mainCard, subCardRight, subCardLeft, barData} = this.props;
    return (
      <div>
        <div className={'card'}>
          <div className="card-body">
            <div className={'row'}>
              <div className={'col '}>
                <p className={'text-muted mb-1'}>{mainCard.title}</p>
                <h3 className={`card-title mb-0 pb-0 ${mainCard.className}`}>
                  {mainCard.value}
                </h3>
                {mainCard.description && (<p className={'text-muted mb-1'}>{mainCard.description}</p>)}
              </div>
            </div>
            <hr/>
            <div className={'row'}>
              {subCardLeft && (
                <div className={'col'}>
                  <p className={'text-muted mb-1'}>{subCardLeft.title}</p>
                  <h5 className={`card-title mb-0 pb-0 ${subCardLeft.className}`}>
                    {subCardLeft.value}
                  </h5>
                </div>
              )}
              {subCardRight && (
                <div className={'col '}>
                  <p className={'text-muted mb-1'}>{subCardRight.title}</p>
                  <h5 className={`card-title mb-0 pb-0 ${subCardRight.className}`}>
                    {subCardRight.value}
                  </h5>
                </div>
              )}
            </div>
            {barData && (
              <>
                <div className={`progress mt-3 ${barData.barContainerClass}`}>
                  <div
                    className={`progress-bar ${barData.barClass}`}
                    style={{width: `${barData.percentage}%`}}
                    aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                  />
                </div>
                <div className={'row'}>
                  {barData.descriptionLeft && (
                    <div className={'col-8'}>
                      <p className={'text-muted mb-0 mt-1 small'}>{barData.descriptionLeft}</p>
                    </div>
                  )}
                  {barData.descriptionRight && (
                    <div className={'col-8 text-right'}>
                      <p className={'text-muted mb-0 mt-1 small'}>{barData.descriptionRight}</p>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default AnalyticsCard;
