/**
 * Rate details
 *  Rate details
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import React, {Component} from 'react';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
// Components
import {Alerts} from '../../../components/ui';
import RateDateUpdate from './RateDateUpdate';
import {faSync, faTimes} from '@fortawesome/free-solid-svg-icons';
import RateDateOverView from '../components/RateDateOverView';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import RateDateConstrainsView from '../components/RateDateConstrainsView';
/* Component ==================================================================== */

class RateDateDetails extends Component {
  static componentName = 'RateDateDetails';

  static propTypes = {
    match: PropTypes.object,
    property: PropTypes.object,
    selectedTab: PropTypes.string,
    roomTypeData: PropTypes.object,
    roomTypePackageData: PropTypes.object,
    updateResponse: PropTypes.func,
    rateDateDetails: PropTypes.object,
  };

  static defaultProps = {
    selectedTab: 'overview',
  }


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        selectedTab: null,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  preFillData = () => {
    const {roomTypes, rateDateDetails} = this.props;
    let roomType, roomTypePackage;
    if (!_.isEmpty(roomTypes)) {
      roomTypes.forEach(data => {
        if (parseInt(data.id) === parseInt(rateDateDetails.room_type_id)) {
          roomType = data;
          if (!_.isEmpty(data.packages)) {
            data.packages.forEach(packageData => {
              if (parseInt(packageData.id) === parseInt(rateDateDetails.room_type_package_id)) {
                roomTypePackage = packageData;
              }
            });
          }
        }
      });
    }
    this.setState({
      roomType,
      roomTypePackage,
      init: false,
    });
  }

  fetchData = () => {
    const {rateDateDetails} = this.props;
    if (!rateDateDetails.default_rate){
      this.setState({spin: true});
      AppAPI.rateapi.get(`rate-package/${rateDateDetails.hash}/`)
        .then((res) => {
          this.setState({spin: false});
          if (this.props.updateResponse) {
            this.props.updateResponse(res, false);
          }
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  }

  updateRateDate = (payload) => {
    const {rateDateDetails} = this.props;
    if (payload){
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        payload.date = rateDateDetails.date;
        payload.room_type = rateDateDetails.room_type_id;
        payload.room_type_package = rateDateDetails.room_type_package_id;

        let appApi;

        if (!rateDateDetails.default_rate){
          appApi = AppAPI.rateapi.patch(`rate-package/${rateDateDetails.hash}/`, payload);
        } else {
          appApi = AppAPI.rateapi.post('rate-package/', payload);
        }

        appApi.then((res) => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              if (this.props.updateResponse) {
                this.props.updateResponse(res);
              }
              setTimeout(() => {
                this.setState({showRateDateUpdate: false, resultMsg: {success: ''}});
              }, 300);
            });
        })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  }

  rateDateEdit = (editType, channelType='channel_manager') => {
    this.setState({showRateDateUpdate: true, rateDateUpdateEditType: editType, rateDateUpdateChannelType:channelType});
  }

  render = () => {
    const {show, distributionSettings, rateDateDetails, property} = this.props;
    const {roomType, roomTypePackage, init, resultMsg, spin} = this.state;
    const currentSelectedTab = this.state.selectedTab || this.props.selectedTab;
    if (!show) return <React.Fragment/>;

    if (show && init) {
      this.preFillData();
    }

    const menu = [
      {
        title: 'Overview',
        key: 'overview',
      },
      {
        title: 'Constrains',
        key: 'constrains',
      }
    ];

    let menuItems = [];
    menu.map((item) => {
      const {title, key} = item;
      return menuItems.push(
        <li className={'nav-item'} role="presentation" key={`menu-item-${title}`}>
          <div
            onClick={() => this.setState({selectedTab: key})}
            className={`nav-link rounded-0 a-class ${key === currentSelectedTab && 'active'}`}
          >
            {title}
          </div>
        </li>
      );
    });


    let content;

    switch (currentSelectedTab) {
    case 'constrains':
      content = <RateDateConstrainsView
        rateDateEdit={this.rateDateEdit}
        rateDateDetails={rateDateDetails}
        updateRateDate={this.updateRateDate}
        distributionSettings={distributionSettings}
      />;
      break;
    case 'overview':
    default:
      content = <RateDateOverView
        property={property}
        roomTypeData={roomType}
        rateDateEdit={this.rateDateEdit}
        rateDateDetails={rateDateDetails}
        roomTypePackageData={roomTypePackage}
      />;
    }

    return (
      <Modal
        {...this.props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <button className={`btn btn-sm mr-2 ${spin && 'btn-outline-secondary'}`} onClick={()=>this.fetchData()}>
              <FontAwesomeIcon spin={spin} icon={faSync} size={'sm'}/>
            </button>
            Rate Details {(!_.isEmpty(roomType) && !_.isEmpty(roomTypePackage)) && `for ${roomTypePackage.name} on ${AppUtil.formatDateTime(rateDateDetails.date, 'datef')}`}
          </Modal.Title>
        </Modal.Header>
        <React.Fragment>
          <Modal.Body>
            <ul className="nav nav-tabs mb-4">
              {menuItems}
            </ul>
            {content}
            {rateDateDetails.time &&
              <p className={'small text-muted mb-0'}>Last updated at {AppUtil.formatDateTime(rateDateDetails.time)} / {rateDateDetails.hash}</p>
            }
          </Modal.Body>
          <Modal.Footer className={'d-block'}>
            <div className={'px-3'}>
              <Alerts
                status={resultMsg.status}
                success={resultMsg.success}
                error={resultMsg.error}
              />
            </div>
            <div className={'row'}>
              <div className={'col'}>
                <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                  <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                </button>
              </div>
            </div>
          </Modal.Footer>
        </React.Fragment>
        <RateDateUpdate
          show={this.state.showRateDateUpdate}
          editType={this.state.rateDateUpdateEditType}
          channelType={this.state.rateDateUpdateChannelType}
          roomTypeData={roomTypePackage}
          rateDateDetails={rateDateDetails}
          updateRateDate={this.updateRateDate}
          onHide={()=>this.setState({
            showRateDateUpdate: false,
          })}
        />
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      spin: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: true,
      showRateDateUpdate: false,
    };
  }
}

/* Export Component ==================================================================== */
export default RateDateDetails;
