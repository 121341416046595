/**
 *
 * POS Category Details
 * point of sales category information
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

// Consts and Libs

// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen} from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../../../components/dashboard/PageHeader';

// Actions
import * as POSActions from '../../../redux/pos/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pos: state.pos.pos,
  category: state.pos.category,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPOSCategory: POSActions.setPOSCategory,
};

/* Component ==================================================================== */
class POSCategoryDetails extends Component {
  static componentName = 'POSCategoryDetails';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    this.setState({
      loading: false,
    });
  }


  render = () => {

    const {loading, error} = this.state;
    const {pos, category} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="pos-details screen-container ">
        <Helmet>
          <title>Point of Sales | Category</title>
        </Helmet>

        <PageHeader
          subHeader={true} history={this.props.history} title={'Category Details'}
          description={`Settings & Configuration for category : ${category.name}`}
        />

        <div className={'row'}>
          <div className={'col-lg-9 col-md-9 col-sm-12'}>
            <div className={'row'}>
              <div className={'col-12 col-md-6 mb-3'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Reference</strong> : {category.ref_no}
                  </li>
                  <li className="list-group-item"><strong>Name</strong> : {category.name}</li>
                </ul>
              </div>
              <div className={'col-12 col-md-6 mb-3'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Status</strong> :
                    <span className={'badge ' + (category.active ? 'badge-success' : 'badge-danger')}>
                      {(category.active ? 'Yes' : 'No')}
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className={'row'}>
              <div className={'col-12 col-md-12 mb-3'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Short Description</strong> : {category.short_description || 'N.A'}
                  </li>
                  <li className="list-group-item">
                    <strong>Description</strong> : {category.description || 'N.A'}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item">
                <Link
                  to={`/settings/pos/${pos.hash}/category/${category.id}/edit`}
                  className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit
                </Link>
              </li>
            </ul>
          </div>
        </div>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(POSCategoryDetails);
