import React from 'react';
import {Route, Switch} from 'react-router-dom';



import RefundView from './RefundView';
import RefundDetails from './RefundDetails';
import RefundListIndex from './RefundListIndex';
import NotFound from '../NotFound';

const PaymentRoute = () => (
  <div>
    
    <div className="container">
      <Switch>
        <Route path="/refund/" exact component={RefundView}/>
        <Route path="/refund/list" component={RefundListIndex}/>
        <Route path="/refund/:refundId" exact component={RefundDetails}/>
        <Route exact component={NotFound}/>
      </Switch>
    </div>
    
  </div>
);

export default PaymentRoute;