/**
 *  Room Type Content Management
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {Formik} from 'formik';
import * as Yup from 'yup';
// Consts and Libs
import AppAPI from '../../../../lib/api';
import {ErrorMessages} from '../../../../constants';
// Components
import Loading from '../../../../components/general/Loading';
import Error from '../../../../components/general/Error';
import PageHeader from '../../../../components/dashboard/PageHeader';
import {Form, SubmitBtn, Textarea} from 'react-formik-ui';
import {Alerts} from '../../../../components/ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as EventActions from '../../../../redux/event/actions';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  event: state.event.event,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setEvent: EventActions.setEvent,
};


/* Component ==================================================================== */

class EventManage extends Component {
  static componentName = 'EventManage';

  static propTypes = {
    match: PropTypes.object,
  };

  componentDidMount = () => {
    this.setState({settings: (this.props.match.params && this.props.match.params.eventId)}, () => this.fetchInitData());
  };

  saveEvent = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});
      AppAPI.eventapi.patch(`event/${this.props.match.params.eventId}/`,
        {
          'description': credentials.description,
          'slider_description': credentials.slider_description,
        })
        .then(() => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                this.props.history.goBack();
              }, 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  fetchInitData = () => {
    const {event} = this.props;
    this.setState({
      loading: false,
      initialValues: {
        'description': event.description,
        'slider_description': event.slider_description,
      },
    });
  };

  render = () => {

    const {event} = this.props;
    const {loading, error, resultMsg, initialValues} = this.state;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    const formValidation = Yup.object().shape({});


    return (
      <div className="agent-manage screen-container ">
        <Helmet>
          <title>Event Manage</title>
        </Helmet>
        <PageHeader
          subHeader={true} history={this.props.history}
          title={'Event Content'}
          description={`Manage content settings for event ${event.name}`}
        />
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <Formik
              onSubmit={(values) => this.saveEvent(values)}
              validationSchema={formValidation}
              initialValues={initialValues}>
              {() => (
                <Form>
                  <div className={'row'}>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div
                        className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                        role="button" aria-expanded="false" aria-controls="orderActions">
                        <h6 className={'mb-0'}>
                          Event Information
                        </h6>
                      </div>
                      <div className={'border p-2'} id={'primaryAction'}>
                        <div className="form-row">
                          <div className="col-lg-12 col-md-12 form-group">
                            <Textarea
                              name='slider_description'
                              label={'Short Description'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-lg-12 col-md-12 form-group">
                            <table className={'w-100 h-100 bg-dark'}>
                              <tbody>
                                <tr>
                                  <td className="align-middle">
                                    <h1 className={'text-white text-center align-middle'}>Slider</h1>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-lg-12 col-md-12 form-group">
                            <Textarea
                              name='description'
                              label={'Description'}
                              help_text={'Event description'}
                              className={'form-control'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                      <Alerts
                        status={resultMsg.status}
                        success={resultMsg.success}
                        error={resultMsg.error}
                      />
                      <SubmitBtn className={'btn btn-success btn-block btn-lg mt-2'}>
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/>
                        Save Event
                      </SubmitBtn>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>

    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      initialValues: {
        name: '',
        event_start_date: '',
        allow_advance_booking_days: '0',
        event_schedule_start_time: '',
        event_schedule_end_time: '',
        event_type: '',
      },
      settings: false,
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(EventManage);
