/**
 * Manage Agent
 *  manages agent add and edit
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import * as Yup from 'yup';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import {ErrorMessages, SelectList} from '../../../constants';
// Components
import {Formik} from 'formik';

import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {faSave} from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../../../components/dashboard/PageHeader';
import {Form, Input, Select, SubmitBtn} from 'react-formik-ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


// Actions
import * as UserActions from '../../../redux/user/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  user: state.user.user,
  plandata: state.billing.plandata,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setUser: UserActions.setUser
};
/* Component ==================================================================== */

class Profile extends Component {
  static componentName = 'Profile';
  componentDidMount = async () => {
    this.fetchInitData();
  };

  saveAccount = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.account.patch('',
          {
            'first_name': credentials.first_name,
            'last_name': credentials.last_name,
            'designation': credentials.designation,
            'phone': credentials.phone ? credentials.code + credentials.phone : null,
          })
          .then(() => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.setState({
                    resultMsg: '',
                  });
                }, 500);
                this.props.setUser();
                this.props.history.goBack();
              });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  fetchInitData = () => {
    AppAPI.account.get('')
      .then((user) => {
        let phone = AppUtil.processPhoneNumber(user.phone);
        this.setState({
          loading: false,
          initialValues: {
            'first_name': user.first_name,
            'last_name': user.last_name,
            'designation': user.designation,
            'code': phone ? '+' + String(phone.getCountryCode()) : '',
            'phone': phone ? phone.getNationalNumber() : '',
          },
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: true,
          resultMsg: {error},
        });
      });
  };

  render = () => {

    const {loading, initialValues, error, resultMsg} = this.state;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    const defaultFormValidation = Yup.object().shape({
      first_name: Yup.string().min(2, 'Too Short!').required('Required'),
      last_name: Yup.string().min(1, 'Too Short!').required('Required'),
    });

    return (
      <div className="agent-manage screen-container ">
        <Helmet>
          <title>Profile</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'User Profile'}
          description={'User profile information'}
        />

        <Formik
          onSubmit={(values) => this.saveAccount(values)}
          validationSchema={defaultFormValidation}
          initialValues={initialValues}>
          {() => (
            <Form>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div
                    className={'border mt-4 p-2 text-center anchor w-100'} data-toggle="collapse"
                    role="button" aria-expanded="false" aria-controls="orderActions">
                    <h6 className={'mb-0'}>
                      User Information
                    </h6>
                  </div>
                  <div className={'border p-2'} id={'primaryAction'}>

                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 form-group">
                        <Input
                          required
                          name='first_name'
                          label={'First Name'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 form-group">
                        <Input
                          required
                          name='last_name'
                          label={'Last Name'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 ">
                        <Input
                          name='designation'
                          label={'Designation'}
                          className={'form-control'}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 ">
                        <div className={'row'}>
                          <div className={'col-4 pr-0 form-group'}>
                            <Select
                              className={'form-control rounded-right-0'}
                              name='code'
                              label={'Country'}
                              placeholder='Select an Option'
                              options={SelectList.phoneCountryCode()}
                            />
                          </div>
                          <div className={'col-8 pl-0 form-group'}>
                            <Input
                              type={'tel'}
                              name='phone'
                              label={'Phone'}
                              className={'form-control rounded-left-0'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                </div>
              </div>

              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-12 mt-2">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn className={'btn btn-success btn-block btn-lg mt-2'}>
                    <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                  </SubmitBtn>
                </div>
              </div>

            </Form>
          )}
        </Formik>
      </div>

    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
