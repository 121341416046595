import React from 'react';
import {Route, Switch} from 'react-router-dom';


import PropertyList from './PropertyList';
import PropertyAdd from './PropertyAdd';
import NotFound from '../NotFound';

const PropertyRoute = () => (
  <div>

    <div className="container">
      <Switch>
        <Route path="/property/" exact component={PropertyList}/>
        <Route path="/property/create" component={PropertyAdd}/>
        <Route exact component={NotFound}/>
      </Switch>
    </div>

  </div>
);

export default PropertyRoute;