/**
 *
 * Membership Details
 */
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import {Constants, Strings} from '../../../constants';

// Components
import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import BadgeStatus from '../../../components/general/BadgeStatus';
import {confirmAlert} from '../../../components/general/ConfirmAlert';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBan, faCheckCircle, faInfoCircle, faPen} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as PropertyActions from '../../../redux/property/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  membership: state.property.membership,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setMembership: PropertyActions.setMembership,
};

/* Component ==================================================================== */
class PropertyMembershipDetails extends Component {
  static componentName = 'PropertyMembershipDetails';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  resetPassword = (membership) => {
    this.setState({resultMsg: {status: 'One moment...'}});
    AppAPI.propertyapi.patch(`membership-pw-reset/${membership.id}/`, {password_reset: true})
      .then((res) => {
        this.setState(
          {resultMsg: {success: res.detail}},
          () => setTimeout(() => this.setState({resultMsg: ''}), 2000)
        );
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({resultMsg: {error}});
      });
  };


  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    this.props
      .setMembership(this.props.match.params.membershipId)
      .then(() => {
        this.setState({
          loading: false,
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: true,
          resultMsg: {error},
        });
      });
  };

  updateMembership = (payload) => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.membership
          .patch(this.props.membership.id + '/', payload)
          .then((res) => {
            this.props.setMembership(res);
            this.setState(
              {resultMsg: {success: 'Success'}},
              () => setTimeout(() => this.setState({resultMsg: ''}), 500)
            );
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {membership} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    return (

      <div className="room-details screen-container ">
        <Helmet>
          <title>Membership Details</title>
        </Helmet>

        <PageHeader
          subHeader={true} history={this.props.history} title={'Account Details'}
          description={`Account information for ${membership.user_profile.display_name}.`}
        />

        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12">
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
            <div className={'row'}>
              <div className={'col-lg-6 col-md-12 col-sm-12'}>
                <table className={'table table-sm table-borderless'}>
                  <tbody>
                    <tr>
                      <td className={'w-50 text-muted'}>Name</td>
                      <td>{membership.user_profile.display_name}</td>
                    </tr>
                    <tr>
                      <td className={'w-50 text-muted'}>Email</td>
                      <td>{membership.user_profile ? membership.user_profile.email : 'N.A'}</td>
                    </tr>
                    <tr>
                      <td className={'w-50 text-muted'}>Type</td>
                      <td>{membership.type_display}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={'col-lg-6 col-md-12 col-sm-12'}>
                <table className={'table table-sm table-borderless'}>
                  <tbody>
                    <tr>
                      <td className={'w-50 text-muted'}>Account Status</td>
                      <td>
                        <BadgeStatus successText={'Active'} failText={'Suspended'} status={membership.access_status}/>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                <table className={'table table-sm table-borderless'}>
                  <tbody>
                    <tr>
                      <td className={'w-50 text-muted'}>Created at</td>
                      <td>{AppUtil.formatDateTime(membership.created)}</td>
                    </tr>
                    <tr>
                      <td className={'w-50 text-muted'}>Last Modified</td>
                      <td>{AppUtil.formatDateTime(membership.time)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                {!_.isEmpty(membership.attributes) &&
                  <table className={'table table-sm table-borderless'}>
                    <tbody>
                      {!_.isEmpty(membership.attributes.created_user) &&
                        <tr>
                          <td className={'w-50 text-muted'}>Created by</td>
                          <td>{membership.attributes.created_user}</td>
                        </tr>
                      }
                      {!_.isEmpty(membership.attributes.last_modified_user) &&
                        <tr>
                          <td className={'w-50 text-muted'}>Modified by</td>
                          <td>{membership.attributes.last_modified_user}</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                }
              </div>
            </div>

            <hr/>

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h5>{Strings.membershipPermissionTitle}</h5>
                {Strings.membershipPermissionDescription.map((data, i) => (
                  <p className={'mb-0 small'} key={i}>{data}</p>
                ))}

                <table className={'mt-2 table border'}>
                  <thead>
                    <tr>
                      <td colSpan={2}>Permission</td>
                      <td>Description</td>
                    </tr>
                  </thead>
                  <tbody>
                    {!_.isEmpty(membership.permission_data) ? (
                      <React.Fragment>
                        {/* eslint-disable-next-line array-callback-return */}
                        {Constants.MEMBERSHIP_PERMISSIONS.map((data, i) => {
                          if (membership.permission_data.includes(data.key)) {
                            return (
                              <tr key={i}>
                                <td><FontAwesomeIcon icon={faCheckCircle} size={'sm'} className={'green-cl mr-1'}/></td>
                                <td className={'w-30'}>
                                  <strong>
                                    {data.name}
                                  </strong>
                                </td>
                                <td>
                                  <p>{data.description}</p>
                                </td>
                              </tr>
                            );
                          }
                        })}
                      </React.Fragment>
                    ) :
                      <tr>
                        <td className={'text-center'} colSpan={4}>
                          <FontAwesomeIcon icon={faInfoCircle} size={'2x'} className={'mr-2 grey-cl'}/>
                          <p>{Strings.membershipUserPermissionEmpty}</p>
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>

              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item">
                <Link to={`/settings/property/accounts/${membership.id}/manage/`} className="btn btn-link m-0 p-0">
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit
                </Link>
              </li>
              <li className="list-group-item">
                <button
                  type="button"
                  className={'btn btn-link m-0 p-0 red-cl'}
                  onClick={() => {
                    confirmAlert({
                      title: Strings.membershipUserPasswordResetTitle,
                      message: `${Strings.membershipUserPasswordResetDescription} : ${membership.user_profile.email}`,
                      buttons: [
                        {
                          className: ' btn-success',
                          label: 'Confirm',
                          onClick: () => this.resetPassword(membership),
                        },
                        {
                          className: 'btn-secondary',
                          label: 'Cancel',
                        },
                      ],
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Reset Password
                </button>
              </li>
              <li className="list-group-item">
                <button
                  type="button"
                  className={`btn btn-link m-0 p-0 ${membership.access_status ? 'red-cl' :  'green-cl'}`}
                  onClick={() => {
                    confirmAlert({
                      title: `${membership.access_status ? 'Suspend' :  'Activate'} User`,
                      message: membership.access_status ? Strings.membershipUserSuspendDescription : Strings.membershipUserActivateDescription,
                      buttons: [
                        {
                          className: 'btn-secondary',
                          label: 'Cancel',
                        },
                        {
                          className: membership.access_status ? 'btn-danger' : 'btn-success',
                          label: membership.access_status ? 'Suspend User' : 'Activate User',
                          onClick: () => this.updateMembership({access_status : !membership.access_status}),
                        },
                      ],
                    });
                  }}
                >
                  <FontAwesomeIcon icon={membership.access_status ? faBan : faCheckCircle} size={'sm'} className={'mr-2'}/>
                  {membership.access_status ? 'Suspend' :  'Activate'} User
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      groups: [],
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyMembershipDetails);