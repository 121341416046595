/**
 *
 * Property Booking Engine Amenities
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import {Amenities} from '../../../constants';
// Components
import Alerts from '../../../components/ui/Alerts';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {faCheckCircle, faCircle, faMousePointer, faSave} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CopyToClipboard from '../../../components/general/CopyToClipboard';
// Actions
import * as PropertyActions from '../../../redux/property/actions';
import * as RoomTypeActions from '../../../redux/roomtype/actions';
import PageHeader from '../../../components/dashboard/PageHeader';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  propertyContent: state.property.content,
  roomType: state.roomType.roomType,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setRoomType: RoomTypeActions.setRoomType,
  isSetPropertyContent: PropertyActions.isSetPropertyContent,
};

/* Component ==================================================================== */
class PropertyBookingEngineAmenities extends Component {
  static componentName = 'PropertyBookingEngineAmenities';

  static propTypes = {
    roomTypeAmenities: PropTypes.bool,
  };

  componentDidMount = () => {
    const {propertyContent, roomType} = this.props;
    let selectedAmenityList = propertyContent && propertyContent.bengine_amenities_list ? propertyContent.bengine_amenities_list.split(',') : [];
    if (this.props.roomTypeAmenities) {
      selectedAmenityList = roomType && roomType.amenities_list ? roomType.amenities_list.split(',') : [];
    }

    this.setState({
      selectedAmenityList,
      loading: false,
    });
  };

  checkAmenity = (amenityData) => {
    let selectedAmenityList = AppUtil.insertOrRemoveArray(this.state.selectedAmenityList, String(amenityData.id));
    this.setState({selectedAmenityList, modified: true});
  };

  bulkSelectAmenity = (amenityDataList) => {
    let {selectedAmenityList} = this.state;
    let amenityList = [];
    amenityDataList.forEach((data) => amenityList.push(String(data.id)));
    selectedAmenityList = selectedAmenityList.concat(amenityList);
    this.setState({selectedAmenityList, modified: true});
  };

  saveRoomType = () => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.roomtype.patch(this.props.match.params.roomTypeId + '/',
        {
          'amenities_list': this.state.selectedAmenityList.join(','),
        })
        .then((roomType) => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                this.props.setRoomType(roomType);
                this.setState({resultMsg: {success: ''}, modified: false});
              }, 500);
            },
          );
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    });
  };

  savePropertyContent = () => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.property.patch(`${this.props.property.id}/content-settings/`, {
        'bengine_amenities_list': this.state.selectedAmenityList.join(','),
      })
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                this.props.isSetPropertyContent(res)
                  .then(() => {
                    this.setState({resultMsg: {success: ''}, modified: false});
                  });
              }, 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    });
  };

  render = () => {
    const {property} = this.props;
    const {loading, error, selectedAmenityList, resultMsg, modified} = this.state;

    if (!this.props.property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="channel-manager-settings screen-container">
        <Helmet>
          <title>Booking Engine Settings</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={'Amenities'}
          description={'Property amenities list.'}
        />

        <div className={'row'}>
          <div className="col-lg-8 col-md-8 col-sm-12 mb-5">
            <div className={'alert alert-info'}>
              Permanent link to amenities section is : {property.booking_engine_url}/amenities
              <span className={'ml-2'}><CopyToClipboard content={`${property.booking_engine_url}/amenities`}/></span>
            </div>

            {Amenities.CATEGORY.map((data, i) => (
              <div className={'mb-5'} key={i}>
                <button
                  onClick={() => this.bulkSelectAmenity(Amenities.AMENITY_LIST.filter(item => (item.category === data.id)))}
                  className="btn btn-link mb-2 float-right"
                >
                  <FontAwesomeIcon icon={faMousePointer} size={'sm'} className={'mr-2'}/> Select All
                </button>
                <div
                  className={'border p-2 text-center anchor w-100'} data-toggle="collapse"
                  role="button" aria-expanded="false" aria-controls="orderActions">
                  <h6 className={'mb-0'}>
                    {data.name}
                  </h6>
                </div>
                <div className={'border p-2'} id={'primaryAction'}>
                  {
                    !_.isEmpty(Amenities.AMENITY_LIST.filter(item => (item.category === data.id))) ?
                      <div className={'row w-100'}>
                        {Amenities.AMENITY_LIST.filter(item => (item.category === data.id)).map(((amenityData, j) => (
                          <div className={'align-items-center col-6 anchor d-inline-flex mb-2'} key={j}
                            onClick={() => this.checkAmenity(amenityData)}>
                            <FontAwesomeIcon
                              icon={selectedAmenityList && selectedAmenityList.includes(String(amenityData.id)) ? faCheckCircle : faCircle}
                              className={`${selectedAmenityList && selectedAmenityList.includes(String(amenityData.id)) ? 'green-cl' : 'grey-light-cl'} mr-2`}
                            />
                            <div className={'p-2 border w-100'}>
                              <FontAwesomeIcon icon={amenityData.icon} className={'mr-2'}/>
                              {amenityData.name}
                            </div>
                          </div>
                        )))}
                      </div>
                      :
                      <React.Fragment>
                        <p className={'text-center'}>No Amenity in this Category</p>
                      </React.Fragment>
                  }
                </div>
              </div>
            ))}
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 pt-5">
            <div className={'sticky-top pt-5'}>
              <div className={'p-3 rounded border white-bg mt-5'}>
                <p className={'text-muted'}>Amenities Updated, save changes.</p>
                <Alerts
                  status={resultMsg.status}
                  success={resultMsg.success}
                  error={resultMsg.error}
                />
                <button disabled={!modified} onClick={() => this.props.roomTypeAmenities ? this.saveRoomType() : this.savePropertyContent()}
                  className={'btn btn-md btn-success btn-lg btn-block'}>
                  <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      modified: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      selectedAmenityList: [],
      showPropertyBookingEngineCoverImageManage: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyBookingEngineAmenities);
