import React from 'react';
import {Route, Switch} from 'react-router-dom';
//scenes
import Auth from './containers/auth';
import Analytics from './containers/analytics';

import Billing from './containers/billing';
import Booking from './containers/booking';
import Dashboard from './containers/dashboard';
import Customer from './containers/customer';
import Expense from './containers/expense';
import Event from './containers/event';
import HouseKeeping from './containers/room';
import Launch from './containers/launch/Launch';
import NotFound from './containers/NotFound';
import Order from './containers/order';
import Property from './containers/property';
import Payment from './containers/payment';
import POS from './containers/pos';
import Refund from './containers/refund';
import Review from './containers/review';
import Reports from './containers/reports';
import Settings from './containers/settings';
import UserSettings from './containers/usersettings';

import Others from './containers/others';
//Decorators
import ScrollToTop from './components/decorators/scrollToTop';
import RequireAuth from './components/decorators/auth';
import NoAuth from './components/decorators/noAuth';
import PropertyRequired from './components/decorators/propertyRequired';
import PropertyDetailsRequired from './components/decorators/propertyDetailsRequired';
// Components
import EnvironmentWarning from './components/general/EnvironmentWarning';
import {Footer, Header, Sidebar} from './components/ui';
import UpdateCheck from './components/general/UpdateCheck';

const navigator = () => (
  <div className={'root-container'}>
    <EnvironmentWarning/>
    <UpdateCheck/>
    <ScrollToTop>
      <Switch>
        <Route path="/" exact component={Launch}/>
        <Route path="/auth" component={NoAuth(Auth)}/>
        <Route path="/" component={RequireAuth(navigatorWithAuth)}/>
        <Route exact component={NotFound}/>
      </Switch>
    </ScrollToTop>
  </div>
);

const navigatorWithAuth = () => (
  <div>
    <Header/>
    <Sidebar/>
    <div className={'main-container mb-5'}>
      <Switch>
        <Route path="/user" component={UserSettings}/>
        <Route path="/billing" component={Billing}/>
        <Route path="/others" component={PropertyRequired(Others)}/>
        <Route path="/analytics" component={PropertyRequired(Analytics)}/>
        <Route path="/booking" component={PropertyRequired(Booking)}/>
        <Route path="/dashboard" component={(PropertyRequired(Dashboard))}/>
        <Route path="/customer" component={(PropertyRequired(Customer))}/>
        <Route path="/expense" component={(PropertyRequired(Expense))}/>
        <Route path="/event" component={(PropertyRequired(Event))}/>
        <Route path="/house-keeping" component={PropertyRequired(HouseKeeping)}/>
        <Route path="/order" component={PropertyRequired(Order)}/>
        <Route path="/property" component={Property}/>
        <Route path="/payment" component={PropertyRequired(Payment)}/>
        <Route path="/point-of-sale" component={PropertyRequired(POS)}/>
        <Route path="/refund" component={PropertyRequired(Refund)}/>
        <Route path="/review" component={PropertyRequired(Review)}/>
        <Route path="/reports" component={(PropertyRequired(Reports))}/>
        <Route path="/settings" component={(PropertyDetailsRequired(Settings))}/>
        <Route exact component={NotFound}/>
      </Switch>
    </div>
    <Footer/>
  </div>
);

export default navigator;