/**
 *
 * Payment Details
 */

import Moment from 'moment';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import queryString from 'query-string';
import React, { Component } from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
// Consts and Libs
import AppUtil from '../../../lib/util';
import AppWebUtil from '../../../lib/webUtils';
// Components
import { Filters } from '../../../constants';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import FilterDate from '../../../components/dashboard/FilterDate';
import PageHeader from '../../../components/dashboard/PageHeader';
import FilterButton from '../../../components/dashboard/FilterButton';

import PaymentAnalyticsPOS from './PaymentAnalyticsPOS';
import PaymentAnalyticsOTA from './PaymentAnalyticsOTA';
import PaymentAnalyticsOverview from './PaymentAnalyticsOverview';
import PaymentAnalyticsTerminals from './PaymentAnalyticsTerminals';
import PaymentAnalyticsSettlement from './PaymentAnalyticsSettlement';

import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PaymentAnalytics extends Component {
  static componentName = 'PaymentAnalytics';

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key, defaultValue=false) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return defaultValue;
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    let startDate = Moment(), endDate = null;
    if (this.getQueryParams('start_date')) {
      startDate = Moment(String(this.getQueryParams('start_date')));
    }
    if (this.getQueryParams('end_date')) {
      endDate = Moment(String(this.getQueryParams('end_date')));
    }

    const urlParams = {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate ? endDate.format('YYYY-MM-DD') : null
    };

    if (this.getQueryParams('date_type')) {
      urlParams['date_type'] = this.getQueryParams('date_type');
    }

    this.setState({
      loading: false,
      endDate: endDate,
      startDate: startDate,
      selectionType: endDate ? 'range' : 'single',
      URLQueryParams: urlParams
    });
  };

  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    const baseUrl = '/payment/analytics';
    const menu = [
      {
        title: 'Overview',
        link: `${baseUrl}/overview`,
        isExact: false,
        component: (props) => <PaymentAnalyticsOverview {...props} />
      },
      {
        title: 'Payment Terminal',
        link: `${baseUrl}/payment-terminal`,
        isExact: false,
        component: (props) => <PaymentAnalyticsTerminals {...props} />
      },
      {
        title: 'Point of Sale',
        link: `${baseUrl}/point-of-sale`,
        isExact: true,
        component: (props) => <PaymentAnalyticsPOS {...props} />
      },
      {
        title: 'OTA',
        link: `${baseUrl}/ota`,
        isExact: true,
        component: (props) => <PaymentAnalyticsOTA {...props} />
      },
      {
        title: 'Settlement',
        link: `${baseUrl}/settlement`,
        isExact: true,
        component: (props) => <PaymentAnalyticsSettlement {...props} />
      },
    ];

    const commonProps = {
      ...this.props,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      dateType: AppUtil.valueFromList(
        Filters.paymentDateType, 'key', this.getQueryParams('date_type'), 'value'
      )
    };


    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Payment Overview</title>
        </Helmet>

        <PageHeader
          border={false}
          title={'Payment Analytics'}
          history={this.props.history}
          description={
            `Payment summary and activity for ${AppUtil.formatDateTime(this.state.startDate, 'date')}
            ${this.state.endDate ? ` to ${AppUtil.formatDateTime(this.state.endDate, 'date')}. ` : '.'}`
          }
        />

        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-12 col-lg-8'}>
            <ul className="list-inline list-item-mb-1 rounded">
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  maxDate={Moment()}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data))}
                  title={''}
                  selectionType={this.state.selectionType}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Date Type'}
                  removeFilter={false}
                  data={Filters.paymentDateType}
                  selectKey={this.getQueryParams('date_type', 'created')}
                  action={(data) => this.setQueryParams([{key: 'date_type', value: data.key}])}
                />
              </li>
              <li className="list-inline-item">
                <button
                  className={'btn btn-outline-secondary'}
                  onClick={() => this.setState({loading: true}, () => this.fetchInitData())}
                >
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={''}/>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <hr/>
        <div className={'row'}>
          <div className={'col-lg-2 col-12 mb-5'}>
            <ul className="nav nav-pills flex-md-column flex-sm-row border rounded mb-3">
              {menu.map((data, i) => (
                <li className="nav-item" role="presentation" key={i}>
                  <NavLink
                    to={{
                      pathname: data.link,
                      search: queryString.stringify(this.state.URLQueryParams),
                    }}
                    className={`nav-link rounded-0 ${!data.component && 'disabled'}`}
                    activeClassName="selected">
                    {data.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>

          <div className={'col-lg-10 col-12'}>
            <Switch>
              <Route
                exact
                path={baseUrl}
                render={() => (<Redirect to={`${baseUrl}/overview`}/>)}
              />
              {menu.map((data, i) => (
                data.component ?
                  <Route
                    key={i}
                    path={data.link} exact
                    render={() => data.component(commonProps)}
                  />
                  :
                  <></>
              ))}
            </Switch>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      startDate: Moment().startOf('day'),
      selectionType: 'single',
      endDate: null,
      URLQueryParams: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PaymentAnalytics);
