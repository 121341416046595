/**
 * Expense
 *  manages expense department add and edit
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
// Actions
import * as PropertyActions from '../../../../redux/property/actions';
import * as ExpenseActions from '../../../../redux/expense/actions';
// Consts and Libs
import AppAPI from '../../../../lib/api';
import {ErrorMessages} from '../../../../constants';
// Components
import {Modal} from 'react-bootstrap';
import Loading from '../../../../components/general/Loading';
import Error from '../../../../components/general/Error';
import {Alerts} from '../../../../components/ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
// Forms
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Checkbox, Form, Input, SubmitBtn, Textarea} from 'react-formik-ui';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetProperty: PropertyActions.isSetProperty,
  setExpenseDepartment: ExpenseActions.setExpenseDepartment,
};

/* Component ==================================================================== */

class ExpenseDepartmentManage extends Component {
  static componentName = 'ExpenseDepartmentManage';

  static propTypes = {
    department: PropTypes.object,
  };

  addExpenseDepartment = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.expenseapi.post('department/',
          {
            'name': credentials.name,
            'description': credentials.description,
            'active': credentials.active,
          })
          .then((res) => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  if (this.props.updateResponse) {
                    this.props.updateResponse(res);
                  }
                  this.setState({resultMsg: {success: ''}});
                  this.props.onHide();
                }, 500);
              });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  saveExpenseDepartment = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.expenseapi.patch(`department/${this.props.department.hash}/`,
          {
            'name': credentials.name,
            'description': credentials.description,
            'active': credentials.active,
          })
          .then((res) => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  if (this.props.updateResponse) {
                    this.props.updateResponse(res);
                  }
                  this.setState({resultMsg: {success: ''}});
                  this.props.onHide();
                }, 500);
              });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }



  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {department, show} = this.props;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    if (!show) return <React.Fragment/>;

    const formValidation = Yup.object().shape({
      name: Yup.string().min(2, 'Too Short!').required('Required'),
    });

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Payment
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            name: department ? department.name : '',
            description: department ? department.description : '',
            active: department ? department.active : true,
          }}
          validationSchema={formValidation}
          onSubmit={values => department ? this.saveExpenseDepartment(values) : this.addExpenseDepartment(values)}
        >
          {() => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <Form className={'form-group'}>
                      <div className="form-row">
                        <div className="col-lg-12 form-group">
                          <Input
                            required
                            name='name'
                            label={'Department Name'}
                            className={'form-control'}
                          />
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-lg-12 col-md-12 form-group">
                          <Textarea
                            name='description'
                            label={'Description'}
                            className={'form-control'}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-12 form-group">
                          <Checkbox
                            className={'mr-2'}
                            name='active'
                            text='Department Status'
                          />
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <Alerts
                  status={resultMsg.status}
                  success={resultMsg.success}
                  error={resultMsg.error}
                />
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary  btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <SubmitBtn className={'btn btn-block  btn-success'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={department ? faSave : faPlus} size={'sm'}/>
                      {`${department ? 'Save' : 'Add'} Department`}
                    </SubmitBtn>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(ExpenseDepartmentManage);
