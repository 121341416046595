/**
 *
 * Booking View
 * shows an overview of bookings.
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import Moment from 'moment';
import queryString from 'query-string';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import AppColors from '../../theme/colors';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSync,} from '@fortawesome/free-solid-svg-icons';
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import PageHeader from '../../components/dashboard/PageHeader';
import Timeline, {DateHeader, SidebarHeader, TimelineHeaders} from 'react-calendar-timeline/lib';
// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css';
import {Constants, Filters} from '../../constants';
import FilterButton from '../../components/dashboard/FilterButton';
import FilterDate from '../../components/dashboard/FilterDate';
import AppWebUtil from '../../lib/webUtils';
import ModelTableView from '../../components/general/ModelTableView';
import BookingRowView from './components/BookingRowView';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class BookingCalenderView extends Component {
  static componentName = 'BookingCalenderView';

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };


  componentDidMount = () => {
    this.fetchInitData();
  };


  fetchInitData = () => {
    this.setState({loading: true});

    let startDate = Moment(), urlParams = {};
    if (this.getQueryParams('start_date')) {
      startDate = Moment(String(this.getQueryParams('start_date')));
    }
    if (this.getQueryParams('end_date')) {
      let endDate = Moment(String(this.getQueryParams('end_date')));
      urlParams['end_date'] = endDate.format('YYYY-MM-DD');
    }

    if (startDate) {
      urlParams['start_date'] = startDate.format('YYYY-MM-DD');
    }

    AppAPI.bookingapi.get(`booking-calendar-view/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (res.actions !== '') {
          this.setState({
            loading: false,
            response: res,
            startDate: Moment(res.start_date),
            endDate: Moment(res.end_date),
          });
        } else {
          this.setState({
            loading: false,
            error: true,
          });
        }
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: error,
          resultMsg: {error},
        });
      });
  };


  bookingStatusColor = (status) => {
    switch (status) {
    case 1:
      return AppColors.blue;
    case 2:
      return AppColors.yellow;
    case 3:
      return AppColors.green;
    case 4:
      return AppColors.graydark;
    default:
      return AppColors.gray;
    }
  }


  render = () => {
    const {loading, error, response, bookingStatus, bookingSource, bookingType} = this.state;
    const {property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    var keys = {
      groupIdKey: 'id',
      groupTitleKey: 'title',
      itemIdKey: 'id',
      itemTitleKey: 'title',
      itemDivTitleKey: 'title',
      itemGroupKey: 'group',
      itemTimeStartKey: 'start_time',
      itemTimeEndKey: 'end_time',
      groupLabelKey: 'title'
    };

    let groups = [];
    let items = [];

    let visibleTimeStart = Moment().startOf('day');
    let visibleTimeEnd = Moment().add(5, 'day');

    if (!_.isEmpty(response)) {

      visibleTimeStart = Moment(response.start_date);
      visibleTimeEnd = Moment(response.end_date);

      if (!_.isEmpty(response.room_types)) {
        response.room_types.forEach((data) => {
          let i = 1;
          while (i <= data.no_of_rooms) {
            groups.push({
              'id': `${data.id}_${i}`,
              'title': `${data.name} ${i}`,
              'rightTitle': `${data.name} Right`,
              'height': 30,
            });
            i++;
          }
        });
      }

      if (!_.isEmpty(response.bookings)) {
        let bookingsData = response.bookings, bookingsDataValue = null;

        switch (bookingStatus) {
        case 'active':
          bookingsDataValue = Constants.BOOKING.ACTIVE;
          break;
        case 'completed':
          bookingsDataValue = Constants.BOOKING.COMPLETED;
          break;
        case 'no_show':
          bookingsDataValue = Constants.BOOKING.NOSHOW;
          break;
        case 'cancelled':
          bookingsDataValue = Constants.BOOKING.CANCELED;
          break;
        case 'upcoming':
          bookingsDataValue = Constants.BOOKING.UPCOMING;
          break;
        default:
          break;
        }

        if (bookingsDataValue !== null) {
          bookingsData = bookingsData.filter(item => (
            item.booking_status === bookingsDataValue
          ));
        } else {
          bookingsData = bookingsData.filter(item => (
            item.booking_status === Constants.BOOKING.UPCOMING || item.booking_status === Constants.BOOKING.ACTIVE
          ));
        }

        if (bookingSource) {
          let bookingSourceValue = null;
          switch (bookingSource) {
          case 'agent':
            bookingSourceValue =  Constants.BOOKING_SOURCE.AGENT;
            break;
          case 'email':
            bookingSourceValue =  Constants.BOOKING_SOURCE.EMAIL;
            break;
          case 'walkin':
            bookingSourceValue =  Constants.BOOKING_SOURCE.WALKIN;
            break;
          case 'phone':
            bookingSourceValue =  Constants.BOOKING_SOURCE.PHONE;
            break;
          case 'channel_manager':
            bookingSourceValue =  Constants.BOOKING_SOURCE.CHANNEL_MANAGER;
            break;
          case 'booking_engine':
            bookingSourceValue =  Constants.BOOKING_SOURCE.BOOKING_ENGINE;
            break;
          case 'corporate':
            bookingSourceValue =  Constants.BOOKING_SOURCE.CORPORATE;
            break;
          case 'ota':
            bookingSourceValue =  Constants.BOOKING_SOURCE.OTA;
            break;
          default:
            break;
          }
          if (bookingSourceValue !== null) {
            bookingsData = bookingsData.filter(item => (item.booking_source === bookingSourceValue));
          }
        }

        if (bookingType) {
          bookingsData = bookingsData.filter(item => (item.confirm_status === (bookingType === 'confirmed')));
        }

        bookingsData.forEach((bookingData) => {
          if (!_.isEmpty(bookingData.booking_rooms)) {
            bookingData.booking_rooms.forEach((bookingRoomData) => {
              items.push({
                id: bookingRoomData.id,
                group: `${bookingRoomData.room_type_id}_1`,
                bookingData: bookingData,
                bookingRoomData: bookingRoomData,
                title: `${(bookingData.guest && bookingData.guest.name) ? bookingData.guest.name : bookingData.ref_no} : ${bookingRoomData.attributes.room_type_name}`,
                start_time: Moment(bookingRoomData.checkin) < visibleTimeStart ? visibleTimeStart : Moment(bookingRoomData.checkin).add('12', 'hours'),
                end_time: Moment(bookingRoomData.checkout) > visibleTimeEnd ? visibleTimeEnd : Moment(bookingRoomData.checkout).add('10', 'hours'),
                bgColor: this.bookingStatusColor(bookingRoomData.booking_status),
                color: bookingRoomData.booking_status === Constants.BOOKING.ACTIVE ? 'black' : 'white',
                itemProps: {
                  'data-tip': `Booking Room Ref ${bookingRoomData.ref_no}`
                }
              });
            });
          }
        });
      }
    }


    const itemRenderer = ({item, itemContext, getItemProps}) => {
      const backgroundColor = itemContext.selected ? AppColors.purple : item.bgColor;
      const borderColor = item.color;
      return (
        <div
          {...getItemProps({
            style: {
              backgroundColor,
              color: item.color,
              borderColor,
              borderStyle: 'solid',
              borderWidth: 1,
              borderRadius: 4,
              borderLeftWidth: itemContext.selected ? 3 : 1,
              borderRightWidth: itemContext.selected ? 3 : 1,
            },
          })}
          onClick={() => this.setState({
            InstantListView: [item.bookingData],
            showInstantListView: true,
          })}
        >

          <div
            style={{
              height: itemContext.dimensions.height,
              overflow: 'hidden',
              paddingLeft: 3,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {itemContext.title} <br/>
            {itemContext.title} <br/>
          </div>

        </div>
      );
    };
    return (
      <div className="booking-view screen-container">
        <Helmet>
          <title>Booking Overview</title>
        </Helmet>

        <PageHeader
          history={this.props.history} title={'Booking Calendar view'}
          description={property.name + ' booking(s) status at ' + AppUtil.formatDateTime(Moment(), 'datetime')}/>

        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-12 col-md-4'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  minDate={Moment()}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data))}
                  title={'Date Range'}
                  selectionType={'range'}
                />
              </li>
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary'} onClick={() => this.fetchInitData(visibleTimeStart)}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={''}/>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-12 col-md-8'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <FilterButton
                  title={'Booking Status'}
                  data={Filters.bookingFilter}
                  selectKey={bookingStatus}
                  action={(data) => this.setState({bookingStatus: data.key})}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Booking Source'}
                  data={Filters.bookingSource}
                  selectKey={bookingSource}
                  action={(data) => this.setState({bookingSource: data.key})}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Booking Type'}
                  data={Filters.bookingType}
                  selectKey={bookingType}
                  action={(data) => this.setState({bookingType: data.key})}
                  size={'sm'}
                />
              </li>
            </ul>
          </div>
        </div>
        <Timeline
          groups={groups}
          items={items}
          keys={keys}
          itemTouchSendsClick={false}
          stackItems
          itemHeightRatio={1}
          canMove={false}
          canResize={false}
          minResizeWidth={50}
          sidebarWidth={200}
          itemRenderer={itemRenderer}
          defaultTimeStart={this.state.startDate}
          defaultTimeEnd={this.state.endDate}
          visibleTimeStart={visibleTimeStart}
          visibleTimeEnd={visibleTimeEnd}
        >
          <TimelineHeaders>
            <SidebarHeader>
              {({getRootProps}) => {
                return <div className={'d-flex text-white bg-dark '} {...getRootProps()}>
                  <div className={'align-self-center w-100 text-center text-light'}>Room Types</div>
                </div>;
              }}
            </SidebarHeader>
            <DateHeader className={'text-white bg-dark'} unit="primaryHeader"/>
            <DateHeader unit={'day'}/>
          </TimelineHeaders>
        </Timeline>


        <ModelTableView
          size={'lg'}
          tableFormatted={false}
          listData={this.state.InstantListView}
          show={this.state.showInstantListView}
          title={this.state.InstantListViewTitle}
          onHide={() => this.setState({showInstantListView: false})}
          renderRow={this.renderRow}
          emptyView={()=><React.Fragment/>}
        />

      </div>
    );
  };

  renderRow = (data, key) => {
    return (
      <React.Fragment key={key}>
        <BookingRowView
          data={data}
          roomExpanded={true}
          history={this.props.history}
        />
      </React.Fragment>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      response: {},
      bookingKey: null,
      openGroups: [],
      bookingStatus: null,
      bookingSource: null,
      startDate: Moment(),
      endDate: Moment().add(7, 'days'),
      bookingType: 'confirmed',
      InstantListView: [],
      showInstantListView: false,
      InstantListViewTitle: 'Booking Details',
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingCalenderView);
