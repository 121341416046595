/**
 *
 * Property Payment Gateway Settings
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Consts and Libs
import {Constants, Strings} from '../../../constants';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import {Alerts} from '../../../components/ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight, faCreditCard, faLink, faRoute, faUnlink} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as PropertyActions from '../../../redux/property/actions';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetPropertyDetails: PropertyActions.isSetPropertyDetails,
};

/* Component ==================================================================== */
class PaymentGatewaySettings extends Component {
  static componentName = 'PropertyNotificationSentList';

  componentDidMount = () => {
    this.setState({loading: false});
  };


  render = () => {
    const {loading, error, resultMsg} = this.state;

    if (!this.props.property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const {property} = this.props;

    return (
      <div className="channel-manager-settings screen-container">
        <Helmet>
          <title>Payment Gateway Settings</title>
        </Helmet>
        <Alerts
          status={resultMsg.status}
          success={resultMsg.success}
          error={resultMsg.error}
        />

        <div className={'row'}>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item d-flex">
                <div>
                  <FontAwesomeIcon
                    icon={property.accept_online_payment ? faLink : faUnlink}
                    className={`${property.accept_online_payment ? 'green-cl' : 'red-cl'} mr-2`}
                  />
                </div>
                <div>
                  <strong>Payment Gateway Status :</strong>
                  <span
                    className={'ml-2 badge ' + (property.accept_online_payment ? 'badge-success' : 'badge-secondary')}>
                    {property.accept_online_payment ? 'Connected' : 'Not Connected'}
                  </span>
                  <br/>
                  {
                    Strings.paymentGatewayConnectionSettings.map((data, i) =>
                      <p className={'sm mb-0 text-secondary small'} key={i}>{data}</p>
                    )
                  }
                </div>
              </li>
              <li className="list-group-item d-flex">
                <div>
                  <FontAwesomeIcon
                    icon={faRoute}
                    className={'green-cl mr-2'}
                  />
                </div>
                <div>
                  <strong>Payment Type : </strong> {property.online_payment_type_display}
                  <br/>
                  {Strings.paymentGatewayType.map((data, i) =>
                    <p className={'sm mb-0 text-secondary small'} key={i}>{data}</p>
                  )}
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            {Constants.PAYMENT_GATEWAY_TYPE.DIRECT === property.online_payment_type &&
            <ul className="list-group">
              <li className="list-group-item d-flex">
                <div>
                  <FontAwesomeIcon
                    icon={faCreditCard}
                    className={'green-cl mr-2'}
                  />
                </div>
                <div>
                  <strong>Payment Processor </strong>
                  <br/>
                  {
                    Strings.paymentGatewayProvider.map((data, i) =>
                      <p className={'sm mb-0 text-secondary small'} key={i}>{data}</p>
                    )
                  }
                </div>
              </li>
              {property.accept_stripe &&
              <li className="list-group-item">
                <FontAwesomeIcon icon={faArrowRight} className={'green-cl mr-2'}/>
                Stripe:
                <span className={'ml-2 badge ' + (property.accept_stripe ? 'badge-success' : 'badge-secondary')}>
                  {property.accept_stripe ? 'Enabled' : 'Disabled'}
                </span>
              </li>
              }
              {property.accept_razorpay &&
              <li className="list-group-item">
                <FontAwesomeIcon icon={faArrowRight} className={'green-cl mr-2'}/>
                Razor Pay:
                <span className={'ml-2 badge ' + (property.accept_razorpay ? 'badge-success' : 'badge-secondary')}>
                  {property.accept_razorpay ? 'Enabled' : 'Disabled'}
                </span>
              </li>
              }
              {property.accept_paytm &&
              <li className="list-group-item">
                <FontAwesomeIcon icon={faArrowRight} className={'green-cl mr-2'}/>
                PayTM:
                <span className={'ml-2 badge ' + (property.accept_paytm ? 'badge-success' : 'badge-secondary')}>
                  {property.accept_paytm ? 'Enabled' : 'Disabled'}
                </span>
              </li>
              }
              {property.accept_cc_avenue &&
              <li className="list-group-item">
                <FontAwesomeIcon icon={faArrowRight} className={'green-cl mr-2'}/>
                CC Avenue:
                <span className={'ml-2 badge ' + (property.accept_cc_avenue ? 'badge-success' : 'badge-secondary')}>
                  {property.accept_cc_avenue ? 'Enabled' : 'Disabled'}
                </span>
              </li>
              }
            </ul>
            }
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentGatewaySettings);
