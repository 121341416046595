/**
 *
 * Room List
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../../../lib/api';
import Strings from '../../../../constants/strings';
// Components
import Loading from '../../../../components/general/Loading';
import Error from '../../../../components/general/Error';
import ListView from '../../../../components/general/ListView';
import PageHeader from '../../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBroom, faKey, faPlus} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  roomType: state.roomType.roomType,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PropertyList extends Component {
  static componentName = 'RoomList';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
  };
  fetchRoom = (page = 1, callback) => {
    AppAPI.room.get('?page=' + page + ((this.props.match.params.roomTypeId ? '&room_type=' + this.props.match.params.roomTypeId : '')))
      .then((res) => {
        this.setState({
          loading: false,
          error: null,
        });
        if (res.results.length > 0) {
          if (res.next) {
            callback(res.results);
          } else {
            callback(res.results, {
              allLoaded: true, // the end of the list is reached
            });
          }
        } else {
          callback();
        }
      })
      .catch((err) => {
        callback(null, {
          allLoaded: true, // the end of the list is reached
        });
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };
  renderRow = (data, key) => {
    return (
      <tr key={key}>
        <td>
          <Link to={`/settings/inventory/room-type/${data.room_type_id}/room/${data.id}`}>{data.name}</Link> <br/>
          <small>{data.attributes ? data.attributes.room_type_name : 'Nil'}</small>
        </td>
        <td>
          <span
            className={'badge ' + (data.availability_status === -1 ? 'badge-danger' : data.availability_status === 1 ? 'badge-success' : 'badge-info')}>
            {data.availability_status_display}
          </span>
        </td>
        <td>
          <span
            className={'badge ' + (data.service_status === 1 ? 'badge-success' : 'badge-danger')}>
            {data.service_status_display}
          </span>
        </td>
        <td>
          <span className={'badge ' + (data.maintenance_status === 1 ? 'badge-success' : 'badge-danger')}>
            {data.maintenance_status_display}
          </span>
        </td>
        <td>
          <span className={`badge ${data.buffer ? 'badge-warning' : 'badge-success'}`}>
            {data.buffer ? 'Yes' : 'No'}
          </span>
        </td>
      </tr>
    );
  };
  emptyView = () => {
    return (
      <tr>
        <td colSpan={6}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faKey}/> No Rooms </h4>
            {Strings.roomListEmptySettingsText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };
  render = () => {

    const {loading, error} = this.state;
    const {property, roomType} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="room-list screen-container ">
        <Helmet>
          <title>Rooms</title>
        </Helmet>
        {!this.props.match.params.roomTypeId &&
         <PageHeader
           subHeader={true} history={this.props.history} title={'Rooms'}
           description={roomType && this.props.match.params.roomTypeId ? `Rooms in ${roomType.name}` : `Rooms in ${property.name}`}
         />
        }
        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12 mb-5">
            <table className={'table border'}>
              <thead>
                <tr>
                  <th>Room No</th>
                  <th>Availability</th>
                  <th>Usability</th>
                  <th>Operational</th>
                  <th>Buffer</th>
                </tr>
              </thead>
              <tbody>
                <ListView
                  rowView={this.renderRow}
                  onFetch={this.fetchRoom}
                  firstLoader={true}
                  pagination
                  emptyView={this.emptyView}
                  class={'list-group'}
                  colSpan={6}
                />
              </tbody>
            </table>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              {this.props.match.params.roomTypeId &&
               <React.Fragment>
                 <li className="list-group-item d-flex justify-content-between align-items-center">
                   <Link to={`/settings/inventory/room-type/${roomType.id}/room/new`} className={'btn btn-link p-0'}>
                     <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Room
                   </Link>
                 </li>
                 <li className="list-group-item d-flex justify-content-between align-items-center">
                   <Link to={`/settings/inventory/room-type/${roomType.id}/room/new-multiple`} className={'btn btn-link p-0'}>
                     <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Room (Multiple)
                   </Link>
                 </li>
               </React.Fragment>
              }
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link to={'/settings/inventory/room/service-items'} className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faBroom} size={'sm'} className={'mr-2'}/> Service Items
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyList);