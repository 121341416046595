/**
 *
 * Booking Details
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
// Consts and Libs
// Components
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import PageHeader from '../../components/dashboard/PageHeader';
// Actions
import {NavLink, Redirect, Route, Switch} from 'react-router-dom';
import OrderAnalytics from './order';
import BookingAnalytics from './booking';
import PaymentAnalytics from './PaymentAnalytics';
import ExpenseAnalytics from './ExpenseAnalytics';
import ExpensePaymentAnalytics from './ExpensePaymentAnalytics';
import NotFound from '../NotFound';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class BookingDetails extends Component {
  static componentName = 'BookingDetails';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
  };

  fetchInitData = () => {
  };

  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const menu = [
      {
        title: 'Booking',
        link: '/analytics/booking',
      },
      // {
      //   title: 'Order',
      //   link: '/analytics/order',
      // },
      // {
      //   title: 'Order',
      //   link: '/analytics/order'
      // },
      // {
      //   title: 'Payment',
      //   link: '/analytics/payment'
      // },
      // {
      //   title: 'Expense',
      //   link: '/analytics/expense'
      // },
      // {
      //   title: 'Expense Payment',
      //   link: '/analytics/expense-payment'
      // }
    ];


    let menuItems = [];
    menu.map((item) => {
      const {title, link, isExact} = item;
      return menuItems.push(
        <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
          <NavLink
            to={{
              pathname: link,
              state: {fromDashboard: true},
            }}
            exact={isExact}
            className="nav-link rounded-0"
            activeClassName="selected">
            {title}
          </NavLink>
        </li>,
      );
    });

    return (
      <div>
        <div className="container">
          <Helmet>
            <title>Analytics</title>
          </Helmet>


          <PageHeader
            border={false}
            history={this.props.history} title={'Analytics'}
          />

          <ul className="nav nav-tabs mb-4">
            {menuItems}
          </ul>

          <Switch>
            <Route
              exact
              path={'/analytics'}
              render={() => (<Redirect to={'/analytics/booking'}/>)}
            />
            <Route path="/analytics/order" exact component={OrderAnalytics}/>
            <Route path="/analytics/booking" exact component={BookingAnalytics}/>
            <Route path="/analytics/payment" exact component={PaymentAnalytics}/>
            <Route path="/analytics/expense" exact component={ExpenseAnalytics}/>
            <Route path="/analytics/expense-payment" exact component={ExpensePaymentAnalytics}/>

            <Route exact component={NotFound}/>
          </Switch>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      showEditMenu: false,
      modType: null,
      showGuestManagement: false,
      showNotesManager: false,
      showProfileDetails: false,
      profileDetailsData: null,
      showMessagingManager: false,
      addProfile: false,
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(BookingDetails);