/**
 * History
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Consts and Libs
import AppUtil from '../../lib/util';


/* Component ==================================================================== */
class History extends Component {

  static propTypes = {
    object: PropTypes.object,
  };

  render = () => {
    const {object} = this.props;
    return (
      (object && object.created) &&
      <div className={'mt-5'}>
        <p className={'small text-secondary'}>
          Created at {AppUtil.formatDateTime(object.created)}.
          {object.time && <React.Fragment> Last modified at {AppUtil.formatDateTime(object.time)}</React.Fragment>}
        </p>
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default History;
