/**
 * Manage Order
 *  manages order updates ( Order Over Ride)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
// Consts and Libs
import AppAPI from '../../lib/api';
import {ErrorMessages} from '../../constants';
// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */

class TicketDetailsView extends Component {
  static componentName = 'TicketDetailsView';

  static propTypes = {
    match: PropTypes.object,
    property: PropTypes.object,
    ticketId: PropTypes.number,
    onHide: PropTypes.func.isRequired,
  };


  componentDidUpdate() {

  }


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({loading: true}, () => this.fetchEventData());
    }
    return true;
  }


  fetchEventData = () => {
    AppAPI.eventapi.get(`ticket/${this.props.ticketId}/`)
      .then((res) => {
        this.setState({ticket: res, loading: false});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };


  render = () => {
    const {property, show} = this.props;
    const {loading, error, ticket} = this.state;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    if (!show) {
      return null;
    }


    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Ticket Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ?
            <Loading heightMatch={false}/>
            :
            <div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 mb-5 mb-md-3">
                  <ul className="list-group">
                    <li className="list-group-item"><strong>Reference Number</strong> : {ticket.ref_no}</li>
                    <li className="list-group-item"><strong>Name</strong> : {ticket.name}</li>
                    <li className="list-group-item"><strong>Quantity</strong> : {ticket.ticket_quantity}</li>
                    <li className="list-group-item"><strong>Type</strong> : {ticket.ticket_quantity_type_display}</li>
                  </ul>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mb-5 mb-md-3">
                  <ul className="list-group">
                    <li className="list-group-item">
                      <strong>Price</strong> : {property.currency} {ticket.base_price_effective}
                    </li>
                    <li className="list-group-item">
                      <strong>Tax</strong> : {ticket.tax_description || 'N.A'}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 mb-5">
                  <ul className="list-group">
                    <li className="list-group-item"><strong>Description</strong> : {ticket.description || 'N.A'}</li>
                    <li className="list-group-item">
                      <strong>Important information</strong> : {ticket.alert_description || 'N.A'}
                    </li>
                  </ul>
                </div>
              </div>

              <div className={'row'}>
                <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                  <ul className="list-group">
                    <li className="list-group-item">
                      <strong>Status</strong> :
                      <span className={'badge ' + (ticket.active === true ? 'badge-success' : 'badge-danger')}>
                        {(ticket.active === true ? 'Active' : 'Deactivated')}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                  <ul className="list-group">
                    <li className="list-group-item">
                      <strong>Booking Engine</strong> :
                      <span
                        className={'badge ' + (ticket.allow_booking_engine === true ? 'badge-success' : 'badge-danger')}>
                        {(ticket.allow_booking_engine === true ? 'Enabled' : 'Disabled')}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          }

        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          <div className={'row'}>
            <div className={'col'}>
              <button className={'btn btn-secondary btn-lg btn-block'} onClick={() => this.props.onHide()}>
                <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      ticket: null,
    };
  }
}

/* Export Component ==================================================================== */
export default TicketDetailsView;
