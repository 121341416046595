/**
 * Launch Screen
 *  - Shows a nice loading screen whilst:
 *  - Checking if user is logged in, and redirects from there
 *
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Consts and Libs
import {Strings, AppConfig} from '../../constants';

// Components
import Loading from '../../components/general/Loading';

// Actions
import * as UserActions from '../../redux/user/actions';

// What data from the store shall we send to the component?
const mapStateToProps = state => ({
  user: state.user,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setUser: UserActions.setUser,
};

/* Component ==================================================================== */
class AppLaunch extends Component {
  static componentName = 'AppLaunch';

  componentDidMount = () => {
    if (localStorage.getItem(AppConfig.localStorageKeys.USER_TOKEN)) {
      this.props.setUser()
        .then(() => this.props.history.push('/dashboard'))
        .catch(() => this.props.history.push('/auth'));
    } else {
      this.props.history.push('/auth');
    }
  };

  render = () => (
    <div className="launch screen-container">
      <Loading message={Strings.initialLoading} size={'4x'}/>
    </div>
  );
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(AppLaunch);
