/**
 *
 * Payment Gateway Details
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
// Consts and Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
// Components
import {Alerts, History} from '../../../components/ui';
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
// Actions
import * as PaymentActions from '../../../redux/payment/actions';

import PageHeader from '../../../components/dashboard/PageHeader';
import {confirmAlert} from '../../../components/general/ConfirmAlert';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  route: state.payment.route,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setRoute: PaymentActions.setRoute,
};

/* Component ==================================================================== */
class PGatewayTransferDetails extends Component {
  static componentName = 'PGatewaytTransferDetails';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    if (this.props.match.params.transferId) {
      this.fetchInitData();
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };

  fetchInitData = () => {
    this.setState({loading: true},
      () => {
        this.props.setRoute(this.props.match.params.transferId)
          .then(() => {
            this.setState({
              loading: false,
            });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({
              loading: false,
              error,
            });
          });
      });
  };

  checkStatus = () => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.pgatewayapi
        .post(`route/${this.props.route.hash}/status`, {})
        .then(() => {
          this.setState({resultMsg: {success: 'Success'}});
          setTimeout(() => this.setState({resultMsg: {success: ''}}), 500);
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    });
  };

  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {route} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    return (
      <div className="payment-details screen-container ">
        <Helmet>
          <title>Transfer Details : {route.ref_no}</title>
        </Helmet>


        <PageHeader
          history={this.props.history} title={'Transfer Details'}
          description={'Transfer reference number : ' + route.ref_no}/>

        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <h3 className={'mb-3'}>Total : {route.currency + ' ' + route.total}</h3>
            <div className="row">
              <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                <ul className="list-group">
                  <li className="list-group-item small">
                    <strong>Transfer ID</strong> : {route.ref_no}
                  </li>
                  <li className="list-group-item small">
                    <strong>Account ID</strong> : {route.route_account_key}
                  </li>
                  {route.gateway_transfer_id && (
                    <li className="list-group-item small">
                      <strong>Transfer reference</strong> : {route.gateway_transfer_id}
                    </li>
                  )}

                  {route.gateway_settlement_id && (
                    <li className="list-group-item small">
                      <strong>Settlement ID</strong> : {route.gateway_settlement_id}
                    </li>
                  )}


                </ul>
              </div>
              <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Transfer Status </strong> :
                    <span
                      className={'badge ' + (AppUtil.transferStatusColor(route.transfer_status))}>
                      {route.transfer_status_display}
                    </span>
                  </li>
                  <li className="list-group-item">
                    <strong>Fee : </strong> {route.currency + ' ' + route.renzo_fee} ({route.renzo_fee_description})
                  </li>
                  {parseFloat(route.renzo_fee_tax) > 0 && (
                    <li className="list-group-item">
                      <strong>Tax : </strong> {route.currency + ' ' + route.renzo_fee_tax} ({route.tax_description})
                    </li>
                  )}
                </ul>
              </div>
            </div>

            <div className={'row'}>
              <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                <Alerts
                  status={resultMsg.status}
                  success={resultMsg.success}
                  error={resultMsg.error}
                />
                <button
                  className="btn btn-success btn-lg btn-block"
                  onClick={() => {
                    confirmAlert({
                      title: 'Refresh Transfer Status',
                      message: 'This will recheck the transfer status with gateway.',
                      buttons: [
                        {
                          className: 'btn-success',
                          label: 'Check',
                          onClick: () => this.checkStatus(),
                        },
                        {
                          className: 'btn-secondary',
                          label: 'Exit',
                        },
                      ],
                    });
                  }}
                >
                  Check Transfer Status
                </button>
              </div>
              <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                <ul className="list-group">

                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">

          </div>
        </div>
        <History object={route}/>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      showRefund: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PGatewayTransferDetails);