const ANALYTIC_CONSTANTS = {
  // Map of front end constants to backend keys
  bookingAnalytics: {
    bookingCount: 'count',
    bookingNights: 'nights',
  },
  bookingGuestAnalytics: {
    adult: 'no_of_guest',
    child: 'no_of_children',
    infant: 'no_of_infant',
    extraBed: 'no_of_extra_bed',
  },
  paymentAnalytics: {
    paymentCount: 'count',
    paymentTotal: 'total',
    paymentRefund: 'refunded',
    paymentSettled: 'total_settled',
    paymentSettledFeeTax: 'settlement_fee_tax'
  },
  orderAnalytics: {
    orderCount: 'count',
    orderItemsCount: 'order_items_count',

    orderTotal: 'total',
    orderSubTotal: 'sub_total',
    orderTaxTotal: 'tax_total',
    orderTaxOriginalTotal: 'tax_original_total',
    orderSubTotalBilled: 'sub_total_billed',
    orderGrossTotal: 'gross_total',
    orderItemsTotal: 'items_total',

    orderDiscountTotalPreTax: 'discount_total_pre_tax',
    orderDiscountTotalPostTax: 'discount_total_post_tax',
    // Sum above discounts
    orderDiscountTotal: 'discount_total',
    orderDiscountPostPayment: 'order_discount_post_payment',

    orderComplimentaryTotal: 'complimentary_total',
    orderNoChargeTotal: 'no_charge_total',
    orderTotalRounding: 'total_rounding',

    orderTax: 'order_tax',
    orderDiscount: 'order_discount',
    orderServiceCharge: 'service_charge',
    orderExternalCommission: 'order_external_commission',
    orderExternalTotal: 'order_external_total',
    orderExternalTax: 'order_external_tax',

    orderPaidPayment: 'paid_payment',
    orderRefundedPayment: 'refunded_payment',
    orderSettledPayment: 'settled_payment',
    orderSettledPaymentDifference: 'settled_payment_difference',
    orderSettledPaymentTax: 'settled_payment_tax'
  }
};

export default ANALYTIC_CONSTANTS;
