/**
 *
 * Booking Lookup
 * booking lookup
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Moment from 'moment';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import {Strings} from '../../constants';
import {Alerts} from '../../components/ui';
import Error from '../../components/general/Error';
import PageHeader from '../../components/dashboard/PageHeader';
import Loading from '../../components/general/Loading';
import DatesView from '../../components/dashboard/DatesView';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleDown} from '@fortawesome/free-solid-svg-icons';
// Components
import BookingListView from './BookingListView';
import OrderItemListView from '../order/OrderItemListView';

import 'react-daterange-picker/dist/css/react-calendar.css';

/* Redux ======================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  booking: state.booking,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class BookingModifyResults extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };


  componentDidMount = () => {
    if (this.props.match.params.bookingId) {
      if (Object.keys(this.props.booking.booking).length !== 0 || Object.keys(this.props.booking.lookupresponse).length !== 0) {
        this.fetchInitData();
      } else {
        this.props.history.replace('/booking/' + this.props.match.params.bookingId + '/');
      }
    } else {
      this.props.history.replace('/booking/');
    }
  };

  addBooking = () => {
    const {lookupresponse} = this.props.booking;
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.bookingapi
        .post(`booking/${lookupresponse.booking.hash}/modify/`, {
          checkin: lookupresponse.checkin,
          checkout: lookupresponse.checkout,
          rooms_selected: lookupresponse.rooms_selected,
          save_changes: true,
          booking_extension: lookupresponse.booking_extension,
        })
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}}, () => {
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
              this.props.history.replace('/booking/' + res.hash);
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    });
  };

  fetchInitData = () => {
    const checkbox = [],
      rooms = [];
    let i,
      total = 0;
    const {lookupresponse} = this.props.booking;
    for (i in lookupresponse.messages) {
      checkbox[i] = false;
    }

    if (lookupresponse.booking_rooms && lookupresponse.booking_rooms.length) {
      lookupresponse.booking_rooms.map(data => {
        // same structure used in bookingadd.js
        let roomData = {
          booking_room: data.booking_room,
          order_items: data.order_items,
          package: data.package,
          room_type: data.packages,
          occupancy_data: data.packages.occupancy_data,
          guest: data.booking_room.no_of_guest,
          child: data.booking_room.no_of_children,
          infant: data.booking_room.no_of_infant,
          extra_bed: data.booking_room.no_of_extra_bed,
          total: 0,
        };
        roomData = AppUtil.calculateRoomTotals(roomData);
        rooms.push(roomData);
        total = parseFloat(roomData.total) + parseFloat(total);

        return total;
      });
    }

    this.setState({
      rooms,
      loading: false,
      checkbox,
      total,
    });
  };


  render = () => {
    const {resultMsg, loading, rooms, total, error, checkbox} = this.state;
    const {lookupresponse} = this.props.booking;
    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;


    return (
      <div className="booking-view screen-container">
        <Helmet>
          <title>Booking Information</title>
        </Helmet>

        <PageHeader history={this.props.history} title={'Modify Booking'}/>

        <div className={'booking-details-container'}>
          <div className={'container'}>
            <div className="row mb-5">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <h6>New Dates</h6>
                <div className={'lookup-box-container mt-2'}>
                  <DatesView checkout={lookupresponse.checkout} checkin={lookupresponse.checkin}/>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className={'lookup-box-container mt-2'}>
                  {lookupresponse.booking &&
                   <div>
                     <h6>Booking</h6>
                     <BookingListView
                       data={lookupresponse.booking}
                       disabled={true}
                     />
                   </div>
                  }
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className={'lookup-box-container mt-2'}>
                  <h6>Room Details</h6>
                  {!_.isEmpty(rooms) &&
                   <table className={'table'}>
                     <tbody>
                       {rooms.map((data, i) => (
                         <React.Fragment key={i}>
                           <tr>
                             <td>
                               <h6>Room : {data.room_number} [{data.room_type.name}]</h6>
                               <p className={'mb-0 small'}>Package : {data.package.data.name}</p>
                               <p className={'small mb-0'}>Reference : {data.booking_room.ref_no}</p>
                             </td>
                             <td>
                               <div className={'row text-center'}>
                                 <div className={'col-12 col-sm-4'}>
                                   <div className="btn-group btn-group-sm" role="group" >
                                     <button type="button" className="btn  border red-cl" disabled={true}>-</button>
                                     <button type="button" className="btn border" disabled={true}>{data.guest}</button>
                                     <button type="button" className="btn border green-cl" disabled={true}>+</button>
                                   </div>
                                   <p className={'small mb-0'}>Adult(s)</p>
                                 </div>
                                 <div className={'col-12 col-sm-4'}>
                                   <div className="btn-group btn-group-sm" role="group" >
                                     <button type="button" className="btn  border red-cl" disabled={true}>-</button>
                                     <button type="button" className="btn border" disabled={true}>{data.child}</button>
                                     <button type="button" className="btn border green-cl" disabled={true}>+</button>
                                   </div>
                                   <p className={'small mb-0'}>Child(s)</p>
                                 </div>
                                 <div className={'col-12 col-sm-4'}>
                                   <div className="btn-group btn-group-sm" role="group" >
                                     <button type="button" className="btn  border red-cl" disabled={true}>-</button>
                                     <button type="button" className="btn border" disabled={true}>{data.infant}</button>
                                     <button type="button" className="btn border green-cl" disabled={true}>+</button>
                                   </div>
                                   <p className={'small mb-0'}>Infant(s)</p>
                                 </div>
                               </div>
                             </td>
                             <td className={'text-center'}>
                               <p><strong>{lookupresponse.currency} {data.total}</strong></p>
                             </td>
                           </tr>
                           <tr>
                             <td colSpan={4} className={'border-0'}>
                               <div
                                 className={'border py-2 text-center anchor'} data-toggle="collapse" href={`#id${i}`}
                                 role="button" aria-expanded="false" aria-controls="orderActions">
                                 <h6 className={'mb-0'}>
                                   <FontAwesomeIcon
                                     icon={faChevronCircleDown} size={'sm'}
                                     className={'mx-2 green-cl float-left mt-1'}/>
                                   More Information
                                   <FontAwesomeIcon
                                     icon={faChevronCircleDown} size={'sm'}
                                     className={'mx-2 green-cl float-right mt-1'}/>
                                 </h6>
                               </div>
                               <div className={'p-2 border collapse'} id={`id${i}`}>
                                 <div className={'row'}>
                                   <div className={'col-12 m-2'}>
                                     <h6>New Billing</h6>
                                     <p className={'small mb-0 text-secondary'}>
                                       Room Pricing for
                                       {AppUtil.formatDateTime(data.package.start_date, 'date')} - {AppUtil.formatDateTime(data.package.end_date, 'date')},
                                       {data.package.no_of_nights} night(s).
                                     </p>
                                     <table className={'table 2-100'}>
                                       <tbody>
                                         <tr>
                                           <td className={'w-75'}>Base Price for {data.package.no_of_nights} night(s)
                                           </td>
                                           <td className={'text-right'}>
                                             <strong>{lookupresponse.currency} {data.package.base_price_effective_total.toString()}</strong>
                                           </td>
                                         </tr>
                                         <tr>
                                           <td className={'w-75'}>Child Price for {data.package.no_of_nights} night(s)
                                           </td>
                                           <td className={'text-right'}>
                                             <strong>{lookupresponse.currency} {data.package.child_price_effective_total.toString()}</strong>
                                           </td>
                                         </tr>
                                         <tr>
                                           <td className={'w-75'}>Infant Price
                                             for {data.package.no_of_nights} night(s)
                                           </td>
                                           <td className={'text-right'}>
                                             <strong>{lookupresponse.currency} {data.package.infant_price_effective_total.toString()}</strong>
                                           </td>
                                         </tr>
                                       </tbody>
                                     </table>
                                   </div>
                                   {data.order_items && data.order_items.length > 0 && (
                                     <div className={'col-12 m-2'}>
                                       <h6>Pre-existing billing for this room.</h6>
                                       <table className={'table 2-100'}>
                                         <tbody>
                                           {data.order_items.map((orderData, i) => (
                                             <OrderItemListView
                                               key={i}
                                               currency={lookupresponse.currency}
                                               data={orderData}
                                             />
                                           ))}
                                         </tbody>
                                       </table>
                                     </div>
                                   )}
                                 </div>
                               </div>
                             </td>
                           </tr>
                         </React.Fragment>
                       ))}
                       <tr>
                         <td colSpan={2}><p><strong>Total</strong></p></td>
                         <td className={'text-center'}><p><strong>{lookupresponse.currency} {total.toFixed(2)}</strong>
                         </p></td>
                       </tr>
                       <tr>
                         <td colSpan={3}>
                           {Strings.bookingTotalWarning.map((data, i) => (
                             <p className={'text-secondary small'} key={i}>{data}</p>
                           ))}
                         </td>
                       </tr>
                     </tbody>
                   </table>
                  }
                </div>
              </div>

              <div className={'col-md-12 col-lg-6 col-sm-12 mb-3'}>
                {
                  (lookupresponse.messages && lookupresponse.messages.length > 0) &&
                  <div>
                    <h6>Attention</h6>
                    {Strings.actionReviewDescription.map((data, i) => (
                      <p className={'small mb-0'} key={i}>{data}</p>
                    ))}

                    <ul className="list-group mt-3">
                      {
                        lookupresponse.messages.map((data, i) => (
                          <li
                            className="list-group-item a-class"
                            key={i}
                          >
                            <div className={'custom-control custom-switch'}>
                              <input
                                id={`e${i}`}
                                type="checkbox"
                                className={'custom-control-input mr-3'}
                                checked={checkbox[i]}
                                onClick={() => {
                                  checkbox[i] = !checkbox[i];
                                  this.setState({checkbox});
                                }}
                              />
                              <label className="custom-control-label" htmlFor={`e${i}`}>
                                {data}
                              </label>
                            </div>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                }
                <div className={'mt-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />

                  <p className={'small'}>Check the actions and confirm the new pricing to proceed.</p>
                  <button
                    disabled={String(checkbox).includes('false')}
                    onClick={() => this.addBooking()}
                    className={'btn btn-success btn-block btn-lg'}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      checkin: Moment().startOf('day'),
      checkout: Moment().add(1, 'days'),
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      results: [],
      booking_confirm_status: false,
      bookingSource: [],
      checkbox: [],
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingModifyResults);
