/**
 *
 * Booking Report
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import Moment from 'moment';
import {Link} from 'react-router-dom';
// Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PageHeader from '../../../components/dashboard/PageHeader';
import DateRangePicker from 'react-daterange-picker';
import 'react-daterange-picker/dist/css/react-calendar.css';
import {
  faCaretDown,
  faExternalLinkAlt,
  faFileCsv,
  faFilePdf,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faSync
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class BookingReport extends Component {
  static componentName = 'BookingReport';

  componentDidMount = () => {
    if (this.props.match.params.date) {
      this.setState({
        date: Moment(String(this.props.match.params.date)),
      }, () => this.fetchInitData());
    } else {
      this.fetchInitData();
    }
  };

  fetchInitData = () => {
    this.setState({
      loading_inner: true,
      error: false,
    });

    let url = 'booking-report/';

    if (this.state.date) {
      url = `${url}${Moment(this.state.date).format('YYYY-MM-DD')}/`;
    }

    if (this.state.selector) {
      url = `${url}?filter=${this.state.selector}`;
    }

    AppAPI.reportapi.get(url)
      .then(res => {
        if (res) {
          this.setState({
            date: Moment(res.date),
            data: res,
            loading: false,
            loading_inner: false,
            error: false,
          });
        } else {
          this.setState({
            loading: false,
            error: true,
          });
        }
      })
      .catch(err => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: error,
          resultMsg: {error},
        });
      });
  };

  onSelect = (date) => {
    this.setState({date: date, showDatePicker: false});
  };

  toggleDatePicker = () => {
    this.setState({showDatePicker: !this.state.showDatePicker});
  };

  performLookup = () => {
    let date = Moment(this.state.date).format('YYYY-MM-DD');
    this.props.history.replace('/reports/booking-report/' + date + '/');
  };

  renderData = (data, sectionHeading = null, sectionClass = 'table-active') => {
    return (
      <React.Fragment>
        {sectionHeading &&
        <tr className={sectionClass}>
          <td className={'text-center'} colSpan={10}><h6 className={'text-center m-0'}>{sectionHeading}</h6></td>
        </tr>
        }
        {(data && data.length > 0) ?
          <React.Fragment>
            {data.map((bdata, i) => (
              <tr key={i} className={'text-break'}>
                <td>
                  <p className={'mb-0 small'}>{bdata.ref_no}</p>
                  {bdata.booking_id && <p className={'mb-0 small'}>ID : {bdata.booking_id}</p>}
                </td>
                <td>
                  <p className={'mb-0 small'}>{AppUtil.formatDateTime(bdata.checkin, 'datef')}</p>
                </td>
                <td>
                  <p className={'mb-0 small'}>{AppUtil.formatDateTime(bdata.checkout, 'datef')}</p>
                </td>
                <td className={'w-15'}>
                  {bdata.guest ? bdata.guest.name : 'N.A'}
                  {bdata.guest && <React.Fragment><p className={'mb-0 text-muted small'}>{bdata.guest.phone}</p>
                  </React.Fragment>}
                  {bdata.guest && <React.Fragment><p className={'mb-0 text-muted small'}>{bdata.guest.email}</p>
                  </React.Fragment>}
                </td>
                <td className={'text-center'}>{bdata.no_of_rooms_count}</td>
                <td className={'text-center'}>
                  <span className={'badge ' + (bdata.confirm_status ? 'badge-success' : 'badge-warning')}>
                    {bdata.confirm_status ? 'Confirmed' : 'Not Confirmed'}
                  </span> <br/>
                  <span className={'badge ' + (AppUtil.bookingStatusColor(bdata.booking_status))}>
                    {bdata.booking_status_display}
                  </span>
                </td>
                <td>
                  <p className={'mb-0 small'}>{bdata.booking_source_display}</p>
                  <p className={'mb-0 small'}>
                    {(bdata.attributes && bdata.attributes.booking_ota_name) &&
                    <div className={'ml-2 small'}>({bdata.attributes.booking_ota_name})</div>}
                    {(bdata.booking_ota && bdata.booking_ota.name) &&
                    <div className={'ml-2 small'}>({bdata.booking_ota.name})</div>}
                  </p>
                </td>
                <td className={'text-center'}>
                  <Link to={`/booking/${bdata.hash}`} rel='noopener noreferrer' target={'_blank'}>
                    <FontAwesomeIcon icon={faExternalLinkAlt} size={'sm'} className={'mr-2'}/> Booking
                  </Link>
                </td>
              </tr>
            ))}
          </React.Fragment>
          :
          <tr>
            <td colSpan={10} className={'text-center'}>
              <p className={'text-center mb-0'}>
                <FontAwesomeIcon icon={faLongArrowAltLeft} className={'mx-2'}/>
                No Bookings
                <FontAwesomeIcon icon={faLongArrowAltRight} className={'mx-2'}/>
              </p>
            </td>
          </tr>
        }
      </React.Fragment>
    );
  };

  exportReport = (type = 'pdf') => {
    let url = 'booking-report';
    if (this.state.date) {
      url = `${url}/${Moment(this.state.date).format('YYYY-MM-DD')}`;
    }

    if (this.state.selector) {
      url = `${url}/?filter=${this.state.selector}`;
    } else {
      url = `${url}/?`;
    }

    let fileName = `Booking-Report-${Moment(this.state.date).format('YYYY-MM-DD')}.${type}`;

    AppAPI.reportapi.get(url + `&download=${type}`)
      .then((response) => {
        response.blob().then((blob) => {
          // 2. Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        });
      });
  };

  render = () => {
    const {loading, loading_inner, error, date, data, showDatePicker, selector} = this.state;
    const {property} = this.props;

    if (!property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="dashboard screen-container">
        <Helmet>
          <title>Booking Report</title>
        </Helmet>

        <PageHeader history={this.props.history} title={'Booking Report'} description={'Property Booking Report.'}/>

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className={'row'}>
              <div className={'col-md-6 col-sm-12 mb-5'}>
                <div className="btn-group btn-group-sm" role="group">
                  <button type="button" className="btn btn-outline-secondary" onClick={this.toggleDatePicker}>
                    {AppUtil.formatDateTime(date, 'datef')}
                    <FontAwesomeIcon icon={faCaretDown} size={'lg'} className={'mx-2'}/>
                  </button>
                  <button
                    type="button" onClick={this.performLookup}
                    className={'btn ' + (Moment(data.date).isSame(date) ? 'btn-secondary' : 'btn-success')}>
                    <FontAwesomeIcon icon={faSync} size={'sm'} className={'mx-2'}/>
                    {Moment(data.date).isSame(date) ? 'Refresh' : 'Update'}
                  </button>
                </div>
                <div
                  className={(showDatePicker ? 'collapse.show' : 'collapse') + ' position-absolute bg-white'}
                  id="datePicker">
                  <DateRangePicker
                    className={'mx-auto'}
                    selectionType={'single'}
                    value={date}
                    onSelect={this.onSelect}
                  />
                </div>
              </div>
              <div className={'col-md-6 col-sm-12 mb-5 text-right'}>
                <div className="btn-group btn-group-sm" role="group">
                  <button className="btn btn-outline-secondary" onClick={() => this.exportReport('csv')}>
                    <FontAwesomeIcon icon={faFileCsv} size={'lg'} className={'mx-2'}/> Export CSV
                  </button>
                  <button className="btn btn-outline-secondary" onClick={() => this.exportReport('pdf')}>
                    <FontAwesomeIcon icon={faFilePdf} size={'lg'} className={'mx-2'}/> Export PDF
                  </button>
                </div>
              </div>
            </div>
            <div className={'mb-5'}>
              <div className="btn-group">
                <button
                  disabled={true}
                  type="button" className={'btn btn-outline-secondary'}>
                  Filters
                </button>
                <button
                  onClick={() => this.setState({selector: null}, () => this.fetchInitData())}
                  type="button" className={'btn ' + (!selector ? 'btn-primary' : 'btn-outline-secondary')}>
                  All
                </button>
                <button
                  type="button"
                  onClick={() => this.setState({selector: 'checkin'}, () => this.fetchInitData())}
                  className={'btn ' + (selector === 'checkin' ? 'btn-primary' : 'btn-outline-secondary')}>
                  Checkin
                </button>
                <button
                  type="button"
                  onClick={() => this.setState({selector: 'checkout'}, () => this.fetchInitData())}
                  className={'btn ' + (selector === 'checkout' ? 'btn-primary' : 'btn-outline-secondary')}>
                  Checkout
                </button>
                <button
                  type="button"
                  onClick={() => this.setState({selector: 'active'}, () => this.fetchInitData())}
                  className={'btn ' + (selector === 'active' ? 'btn-primary' : 'btn-outline-secondary')}>
                  Active
                </button>
                <button
                  type="button"
                  onClick={() => this.setState({selector: 'other'}, () => this.fetchInitData())}
                  className={'btn ' + (selector === 'other' ? 'btn-primary' : 'btn-outline-secondary')}>
                  Other
                </button>
              </div>
            </div>

            {loading_inner ?
              <Loading heightMatch={false}/> :
              <div className={'row'}>
                <div className={'col-xl-12 col-md-12 col-12 mb-3'}>
                  <div className="table-responsive">
                    <table className={'table table-hover format-table table-bordered'}>
                      <thead className="bg-dark text-light">
                        <tr>
                          <th className="data-table-cell-lg">Reference No/ID</th>
                          <th className="data-table-cell-md">Check in</th>
                          <th className="data-table-cell-md">Check out</th>
                          <th className="data-table-cell-xl">Guest</th>
                          <th className="data-table-cell-sm">Room(s)</th>
                          <th className="data-table-cell-sm">Status</th>
                          <th className="data-table-cell-lg">Source</th>
                          <th className="data-table-cell-sm">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(!selector || selector === 'checkin') &&
                        this.renderData(data.checkin_bookings, 'Checkin\'s')
                        }
                        {(!selector || selector === 'checkout') &&
                        this.renderData(data.checkout_bookings, 'Checkout\'s')
                        }
                        {(!selector || selector === 'active') &&
                        this.renderData(data.active_bookings, 'Active Bookings')
                        }
                        {(!selector || selector === 'other') &&
                        this.renderData(data.other_bookings, 'Other Bookings')
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                {data.count < 1 &&
                <div className={'col-xl-12 col-md-12 col-12 mb-3'}>
                  <h4>No Bookings</h4>
                </div>
                }
              </div>
            }
          </div>
        </div>


      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loading_inner: true,
      showDatePicker: false,
      error: null,
      data: [],
      selector: null,
      date: Moment().startOf('day'),
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingReport);
