/**
 * Expense Refund Details
 */
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
// Consts and Libs
import AppAPI from '../../lib/api';
// Components
import AppUtil from '../../lib/util';
import {Alerts} from '../../components/ui';
import {Constants, Strings} from '../../constants';
import Loading from '../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {confirmAlert} from '../../components/general/ConfirmAlert';
import {faCheckCircle, faLongArrowAltRight, faStickyNote, faTimes} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */
class ExpenseRefundDetails extends Component {
  static componentName = 'ExpenseRefundDetails';

  static propTypes = {
    data: PropTypes.object,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        loading: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  preFillData = () => {
    AppAPI.expenseapi.get(`expense-refund/${this.props.data.hash}/`)
      .then((res) => {
        this.setState({refund: res, init: false, loading: false});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({resultMsg: {error}, init: false, loading: false});
      });
  };

  updatePayment = payload => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.expenseapi
          .patch(`expense-refund/${this.props.data.hash}/`, payload)
          .then(res => {

            this.setState({resultMsg: {success: 'Success'}, refund: res}, () => {
              setTimeout(() => {
                this.setState({
                  resultMsg: {success: ''},
                });
                this.props.onCompleteHide();
                this.props.updateResponse();
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  cacheReset = () => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.expenseapi
        .post(`expense/expense-refund/${this.props.data.hash}/cache-reset/`, {})
        .then(res => {
          this.setState({resultMsg: {success: 'Success'}, refund: res}, () => {
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            resultMsg: {error},
          });
        });
    });
  };


  render = () => {

    const {init, loading, refund, resultMsg} = this.state;
    const {show} = this.props;

    if (show && init) {
      this.preFillData();
    }

    if (!show) return null;

    return (
      <Modal
        {...this.props}
        size={'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Refund Details
          </Modal.Title>
        </Modal.Header>
        <React.Fragment>
          {loading ? <Modal.Body><Loading heightMatch={false}/></Modal.Body>
            :
            <React.Fragment>
              <Modal.Body>
                <div className={'row'}>
                  <div className={'col'}>
                    <p className={'mb-0 text-muted'} onClick={() => this.cacheReset()}>Total</p>
                    <div className={'d-inline-flex align-items-baseline'}>
                      <h2>
                        {refund.currency + ' ' + refund.total}
                      </h2>
                      <div className={'align-items-baseline'}>
                        <span className={'ml-2 badge ' + (AppUtil.paymentStatusColor(refund.payment_status))}>
                          {refund.payment_status_display}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div className={'row'}>
                  <div className={'col-lg-12 col-md-12'}>
                    <table className={'table table-borderless table-sm mb-3'}>
                      <tbody>
                        {refund.payment_id &&
                         <tr>
                           <td className={'w-50 text-muted'}>Payment ID</td>
                           <td><strong>{refund.payment_id}</strong></td>
                         </tr>
                        }
                        <tr>
                          <td className={'w-50 text-muted'}>Payment Method</td>
                          <td>{refund.payment_method_display}</td>
                        </tr>
                        {refund.description &&
                         <tr>
                           <td className={'w-50 text-muted'}>Description</td>
                           <td><strong>{refund.description}</strong></td>
                         </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className={'mb-3'}>
                  <h5>Payment Timeline</h5>
                  <hr/>
                  <ul className={'list-inline d-inline-flex list-inline-formatted'}>
                    <li className={'list-inline-item align-items-center'}>
                      <p className={'mb-0'}>
                        <FontAwesomeIcon icon={faCheckCircle} size={'sm'} className={'green-cl mr-2'}/>
                        <strong>Created</strong>
                      </p>
                      <p className={'text-muted small'}>{AppUtil.formatDateTime(refund.created)}</p>
                    </li>
                    <li className={'mx-3 list-inline-item d-inline-flex align-items-center'}>
                      <FontAwesomeIcon icon={faLongArrowAltRight}/>
                    </li>
                    <li className={'list-inline-item align-items-center'}>
                      <p className={'mb-0'}>
                        <FontAwesomeIcon
                          icon={faCheckCircle} size={'sm'}
                          className={`mr-2 ${refund.payment_status === Constants.PAYMENT_STATUS.PAID ? 'green-cl' : 'grey-cl'}`}
                        />
                        <strong>Payment</strong>
                      </p>
                      <p className={'text-muted small'}>
                        {refund.payment_time ? AppUtil.formatDateTime(refund.payment_time) : refund.payment_status_display}
                      </p>
                    </li>
                  </ul>
                </div>

                <div className={'row mb-3'}>
                  <div className={'col-12'}>
                    <h5>Payment Details</h5>
                    <hr/>
                    <table className={'table table-borderless table-sm'}>
                      <tbody>
                        <tr>
                          <td className={'w-25'}>Payment</td>
                          <td>{refund.currency + ' ' + refund.total}</td>
                          <td>
                            {refund.reference_id &&
                             <React.Fragment>
                               <FontAwesomeIcon icon={faStickyNote} className={'mr-2'}/> {refund.reference_id}
                             </React.Fragment>
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <Alerts
                  status={resultMsg.status}
                  success={resultMsg.success}
                  error={resultMsg.error}
                />
                <div className={'row'}>
                  {refund.payment_status === Constants.PAYMENT_STATUS.NOTPAID ?
                    <React.Fragment>
                      <div className={'col'}>
                        <button
                          className={'btn btn-lg btn-block btn-secondary'}
                          onClick={() => {
                            confirmAlert({
                              title: 'Cancel Refund',
                              message: Strings.paymentCancelText,
                              buttons: [
                                {
                                  className: 'btn-danger',
                                  label: 'Cancel Payment',
                                  onClick: () => this.updatePayment({void_payment: true}),
                                },
                                {
                                  className: 'btn-secondary',
                                  label: 'Exit',
                                },
                              ],
                            });
                          }}
                        >
                          Cancel Refund
                        </button>
                      </div>
                      <div className={'col'}>
                        <button
                          className={'btn btn-lg btn-block btn-success'}
                          onClick={() => {
                            confirmAlert({
                              title: 'Confirm Refund',
                              message: Strings.paymentConfirmText,
                              buttons: [
                                {
                                  className: 'btn-success',
                                  label: 'Confirm Refund',
                                  onClick: () => this.updatePayment({mark_payment: true}),
                                },
                                {
                                  className: 'btn-secondary',
                                  label: 'Exit',
                                },
                              ],
                            });
                          }}
                        >
                          Confirm Refund
                        </button>
                      </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <div className={'col'}>
                        <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                          <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                        </button>
                      </div>
                    </React.Fragment>
                  }
                </div>
              </Modal.Footer>
            </React.Fragment>
          }
        </React.Fragment>
      </Modal>
    );
  };


  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      record: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: false,
    };
  }
}

/* Export Component ==================================================================== */
export default ExpenseRefundDetails;
