/**
 *
 * Ticket Settings
 * ticket information
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../../lib/api';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PageHeader from '../../../components/dashboard/PageHeader';
import {History} from '../../../components/ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as EventActions from '../../../redux/event/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  event: state.event.event,
  ticket: state.event.ticket,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setTicket: EventActions.setTicket,
};

/* Component ==================================================================== */
class TicketDetails extends Component {
  static componentName = 'TicketSettings';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    if (this.props.match.params.ticketId) {
      this.props.setTicket(this.props.match.params.ticketId)
        .then(() => {
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };

  render = () => {

    const {loading, error} = this.state;
    const {ticket, event} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="pos-details screen-container ">
        <Helmet>
          <title>Ticket Details</title>
        </Helmet>

        <PageHeader
          history={this.props.history}
          subHeader={true}
          title={'Ticket details'} description={'Reference number : ' + ticket.ref_no}/>

        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                <ul className="list-group">
                  <li className="list-group-item"><strong>Reference Number</strong> : {ticket.ref_no}</li>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 mb-5 mb-md-3">
                <ul className="list-group">
                  <li className="list-group-item"><strong>Name</strong> : {ticket.name}</li>
                  <li className="list-group-item"><strong>Quantity</strong> : {ticket.ticket_quantity}</li>
                  <li className="list-group-item"><strong>Type</strong> : {ticket.ticket_quantity_type_display}</li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mb-5 mb-md-3">
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Price</strong> : {ticket.currency} {ticket.base_price}
                  </li>
                  <li className="list-group-item">
                    <strong>With Tax</strong> : {ticket.currency} {ticket.base_price_effective}
                  </li>
                  <li className="list-group-item">
                    <strong>Tax</strong> : {ticket.tax_description || 'N.A'}
                  </li>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 mb-5">
                <ul className="list-group">
                  <li className="list-group-item"><strong>Description</strong> : {ticket.description || 'N.A'}</li>
                  <li className="list-group-item">
                    <strong>Important information</strong> : {ticket.alert_description || 'N.A'}
                  </li>
                  <li className="list-group-item">
                    <strong>Booking Description</strong> : {ticket.booking_description || 'N.A'}
                    <p className={'small text-muted mb-0'}>Booking description will be used in email / pdf summary for the ticket</p>
                  </li>
                </ul>
              </div>
            </div>

            <div className={'row'}>
              <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Status</strong> :
                    <span className={'badge ' + (ticket.active === true ? 'badge-success' : 'badge-danger')}>
                      {(ticket.active === true ? 'Active' : 'Deactivated')}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Booking Engine</strong> :
                    <span
                      className={'badge ' + (ticket.allow_booking_engine === true ? 'badge-success' : 'badge-danger')}>
                      {(ticket.allow_booking_engine === true ? 'Enabled' : 'Disabled')}
                    </span>
                  </li>
                </ul>
              </div>
            </div>

          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link to={`/settings/event/${event.hash}/ticket/${ticket.hash}/edit/`} className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <History object={ticket}/>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketDetails);
