/**
 *
 * Order Details
 */
import {Link, Route, Switch} from 'react-router-dom';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import _ from 'lodash';
// Consts and Libs
import {Constants} from '../../../constants';

// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import NavigationTabs from '../../../components/dashboard/NavigationTabs';

import PaymentOverView from './PaymentOverView';
import PaymentLinkDetails from './PaymentLinkDetails';
import PaymentTerminalList from './PaymentTerminalList';
import PaymentRefundDetails from './PaymentRefundDetails';
import PaymentTerminalDetails from './PaymentTerminalDetails';
import PaymentSubPaymentsDetails from './PaymentSubPaymentsDetails';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faReceipt} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  payment: state.payment.payment,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PaymentDetails extends Component {
  static componentName = 'PaymentDetails';

  static propTypes = {};

  componentDidMount = () => {
  };

  fetchInitData = () => {
  };

  render = () => {

    const {loading, error} = this.state;
    const {payment} = this.props;

    if (loading || _.isEmpty(payment)) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    let baseUrl = '/payment';
    let absBaseUrl = `${baseUrl}/${payment.hash}`;
    const menu = [
      {
        title: 'Payment Details',
        link: `${absBaseUrl}/`,
        isExact: true,
      }
    ];

    if (payment.payment_mode === Constants.PAYMENT_MODE.PM_CONSOLIDATED) {
      menu.push(
        {
          title: 'Sub Payment(s)',
          link: `${absBaseUrl}/payment-sub-payment/`,
          isExact: true,
        },
      );

      menu.push(
        {
          title: 'Sub Refund(s)',
          link: `${absBaseUrl}/payment-sub-refund/`,
          isExact: true,
          disabled: payment.payment_status !== Constants.PAYMENT_STATUS.PAID,
        },
      );

    } else {
      menu.push({
        isExact: true,
        title: 'Payment Refunds(s)',
        link: `${absBaseUrl}/payment-refund/`,
        disabled: payment.payment_status !== Constants.PAYMENT_STATUS.PAID,
      });
    }

    if (payment.payment_mode !== Constants.PAYMENT_MODE.PM_SUB) {
      if (payment.payment_method === Constants.PAYMENT_METHODS.ONLINE_PAYMENT) {
        menu.push(
          {
            isExact: true,
            title: 'Payment Link',
            link: `${absBaseUrl}/payment-link/`,
          },
        );
      }

      if (payment.payment_method === Constants.PAYMENT_METHODS.PAYMENT_TERMINAL) {
        menu.push(
          {
            title: 'Payment Terminal',
            link: `${absBaseUrl}/payment-terminal/`,
          },
        );
      }

    }

    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Payment Details</title>
        </Helmet>

        <Link to={'/payment/list'} className={'btn btn-outline-dark mr-2 btn-sm mt-2'}>
          <FontAwesomeIcon icon={faArrowLeft} size={'sm'}/> {'Payment List'}
        </Link>

        {!_.isEmpty(payment.attributes) &&
        <React.Fragment>
          {payment.attributes.order_hash &&
          <Link
            to={`/order/${payment.attributes.order_hash}/`}
            className={'btn btn-outline-dark mr-2 btn-sm mt-2'}
          >
            <FontAwesomeIcon icon={faReceipt} className={'mr-2'} size={'sm'}/>
            {payment.attributes.order_ref_no || 'Order'}
          </Link>
          }
        </React.Fragment>
        }

        <PageHeader
          border={false}
          history={this.props.history} title={'Payment details'}
          description={`Reference number : ${_.isEmpty(payment) ? '...' : payment.ref_no}`}
        />

        <NavigationTabs navItems={menu} classNameContainer={'mb-4'}/>

        <Switch>
          <Route
            path={absBaseUrl} exact
            render={() => <PaymentOverView {...this.props}/>}
          />
          <Route
            path={`${absBaseUrl}/payment-refund/`} exact
            render={() => <PaymentRefundDetails {...this.props}/>}
          />
          <Route
            path={`${absBaseUrl}/payment-link/`} exact
            render={() => <PaymentLinkDetails {...this.props}/>}
          />
          <Route
            path={`${absBaseUrl}/payment-terminal/`} exact
            render={() => <PaymentTerminalDetails {...this.props}/>}
          />
          <Route
            path={`${absBaseUrl}/payment-terminal/list`} exact
            render={() => <PaymentTerminalList {...this.props}/>}
          />
          <Route
            path={`${absBaseUrl}/payment-sub-payment/`} exact
            render={() => <PaymentSubPaymentsDetails {...this.props}/>}
          />
          <Route
            path={`${absBaseUrl}/payment-sub-refund/`} exact
            render={() => <PaymentRefundDetails selectKey={'sub-refunds'} {...this.props}/>}
          />
        </Switch>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);
