/**
 *
 * Room Details
 */
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
// Consts and Libs
import AppAPI from '../../../../lib/api';
import {Strings, Constants} from '../../../../constants';
// Components
import Loading from '../../../../components/general/Loading';
import Error from '../../../../components/general/Error';
import PageHeader from '../../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDoorOpen, faExternalLinkAlt, faHistory, faPen} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as RoomActions from '../../../../redux/room/actions';
import {Alerts} from '../../../../components/ui';
import {confirmAlert} from '../../../../components/general/ConfirmAlert';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  room: state.room.room,
  roomType: state.roomType.roomType,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setRoom: RoomActions.setRoom,
};

/* Component ==================================================================== */
class RoomDetails extends Component {
  static componentName = 'RoomDetails';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    if (this.props.match.params.roomId) {
      this.props.setRoom(this.props.match.params.roomId)
        .then(() => {
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };

  updateRoom = (payload) => {
    if (payload) {
      this.setState({resultMsg: {status: 'One moment...'}});
      AppAPI.room.patch(`${this.props.match.params.roomId}/`,payload)
        .then((data) => {
          this.props.setRoom(data);
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
              }, 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {room} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    return (
      <div className="room-details screen-container ">
        <Helmet>
          <title>Room Details</title>
        </Helmet>

        <PageHeader
          subHeader={true} history={this.props.history} title={'Room Details'}
          description={`Settings & Configuration for room : ${room.name}`}
        />

        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12 mb-5">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                <ul className="list-group">
                  <li className="list-group-item"><span className="glyphicon glyphicon-user"/>
                    <strong>Reference</strong> : {room.ref_no}
                  </li>
                  <li className="list-group-item"><span className="glyphicon glyphicon-user"/>
                    <strong>Room Name</strong> : {room.name}
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                <ul className="list-group">
                  <li className="list-group-item"><span className="glyphicon glyphicon-user"/>
                    <strong>Room Type</strong> : {room.attributes && room.attributes.room_type_name}
                  </li>
                  <li className="list-group-item"><strong>Booking Room </strong> :
                    {(room.booking_room_data && room.booking_room_id) ? room.booking_room_data.ref_no : 'N.A'}
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Bed Type</strong> : {room.bed_type ? room.bed_type_display : 'N.A'}</li>
                  <li className="list-group-item">
                    <strong>View</strong> : {room.view_type ? room.view_type_display : 'N.A'}</li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Floor Level </strong> : {room.floor_level !== null ? room.floor_level : 'N.A'}
                  </li>
                  <li className="list-group-item">
                    <strong>Smoking </strong> : {room.smoking_type ? room.smoking_type_display : 'N.A'}</li>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-sm-12 mb-5">
                <ul className="list-group">
                  <li className="list-group-item">
                    <strong>Description</strong> : {room.description || 'N.A'}
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className={'col-12 mb-3'}>
                <div className={'table-responsive'}>
                  <table className={'table border'}>
                    <thead>
                      <tr className={'bg-dark text-light text-center'}>
                        <td className={'data-table-cell-lg'}/>
                        <td className={'data-table-cell-lg text-center'}>Status</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>Service Status</strong>
                          <p className={'mb-0 text-muted small'}>{Strings.roomServiceStatus}</p>
                        </td>
                        <td className={'text-center'}>
                          <span className={`badge ${room.service_status === 1 ? 'badge-success' : 'badge-danger'}`}>
                            {room.service_status_display}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Availability Status</strong>
                          <p className={'mb-0 text-muted small'}>{Strings.roomServiceStatus}</p>
                        </td>
                        <td className={'text-center'}>
                          <span className={`badge ${room.availability_status === -1 ? 'badge-danger' : room.availability_status === 1 ? 'badge-success' : 'badge-info'}`}>
                            {room.availability_status_display}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Operation Status</strong>
                          <p className={'mb-0 text-muted small'}>{Strings.roomOperationStatus}</p>
                        </td>
                        <td className={'text-center'}>
                          <span className={`badge ${room.maintenance_status === 1 ? 'badge-success' : 'badge-danger'}`}>
                            {room.maintenance_status_display}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Buffer Status</strong>
                          <p className={'mb-0 text-muted small'}>{Strings.roomBufferStatus}</p>
                        </td>
                        <td className={'text-center'}>
                          <span className={`badge ${room.buffer ? 'badge-warning' : 'badge-success'}`}>
                            {room.buffer ? 'Yes' : 'No'}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />

            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                <button
                  onClick={() => confirmAlert({
                    title: 'Confirm Action',
                    message: room.maintenance_status === Constants.ROOM.IN_MAINTENANCE ? Strings.roomSetAvailable : Strings.roomSetMaintenance,
                    buttons: [
                      {
                        className: 'btn-danger',
                        label: 'Confirm',
                        onClick: () => this.updateRoom({
                          'maintenance_status': room.maintenance_status === Constants.ROOM.IN_MAINTENANCE ? Constants.ROOM.AVAILABLE : Constants.ROOM.IN_MAINTENANCE
                        }),
                      },
                      {
                        className: 'btn btn-secondary',
                        label: 'Cancel',
                      },
                    ],
                  })}
                  className={`btn btn-block ${room.maintenance_status === Constants.ROOM.IN_MAINTENANCE ? 'btn-outline-danger' :'btn-danger'}`}
                >
                  {room.maintenance_status === Constants.ROOM.IN_MAINTENANCE ? 'Remove Maintenance' : 'Set to Maintenance'}
                </button>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                <button
                  disabled={room.maintenance_status === Constants.ROOM.IN_MAINTENANCE}
                  onClick={() => confirmAlert({
                    title: 'Confirm Action',
                    message: room.buffer ? Strings.roomRemoveBuffer : Strings.roomSetBuffer,
                    buttons: [
                      {
                        className: 'btn-warning',
                        label: 'Confirm',
                        onClick: () => this.updateRoom({
                          'buffer': !room.buffer
                        }),
                      },
                      {
                        className: 'btn btn-secondary',
                        label: 'Cancel',
                      },
                    ],
                  })}
                  className={`btn btn-block ${room.buffer ? 'btn-outline-warning' : 'btn-warning'}`}
                >
                  {room.buffer ? 'Remove from Buffer' : 'Set to Buffer'}
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item">
                <Link
                  to={`/settings/inventory/room-type/${room.room_type_id}/room/${room.id}/edit`}
                  className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit
                </Link>
              </li>
              <li className="list-group-item">
                <Link
                  to={`/settings/inventory/room-type/${room.room_type_id}/room/${room.id}/maintenance`}
                  className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faHistory} size={'sm'} className={'mr-2'}/> Maintenance History
                </Link>
              </li>
              <li className="list-group-item">
                <Link
                  target={'_blank'}
                  to={`/housekeeping/room/${room.hash}/`}
                  className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faDoorOpen} size={'sm'} className={'mr-2'}/> Housekeeping View
                  <FontAwesomeIcon icon={faExternalLinkAlt} size={'sm'} className={'ml-2'}/>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: ''
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomDetails);