/**
 * POS Item details
 */
import _ from 'lodash';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import React, {Component} from 'react';
import {Form, Formik} from 'formik';

// Consts and Libs
import {Constants} from '../../../constants';
import AppUtil from '../../../lib/util';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
import PosItemTypeDetailsView from '../../../components/dashboard/PosItemTypeDetailsView';
import {SubmitBtn, Textarea, Input} from 'react-formik-ui';

/* Component ==================================================================== */

class POSItemDetails extends Component {
  static componentName = 'POSItemDetails';

  static propTypes = {
    itemData: PropTypes.object,
    posOrderAction: PropTypes.func,
    posDetails: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
        loading: false,
        item: null,
      });
    }
    return true;
  }


  preFillData = () => {
    this.setState(
      {
        init: false,
        loading: false,
      },
    );
  };

  addItem = (credentials) => {
    const {item} = this.props;
    if (credentials){
      let payload = item;

      payload = {
        ...payload,
        'item': item.id,
        'quantity': credentials.quantity,
        'sub_quantity': credentials.sub_quantity,
        'additional_description': credentials.additional_description,
      };

      if (this.props.posOrderAction){
        this.props.posOrderAction(payload);
      }

      this.props.onHide();
    }

  }

  render = () => {
    const {show, item, posDetails, itemData} = this.props;

    if (!show) {
      return null;
    }

    let initialValues = {
      quantity: '1',
      sub_quantity: '1',
    };

    if (itemData) {
      initialValues['quantity'] = itemData.quantity;
      initialValues['sub_quantity'] = itemData.sub_quantity;
      initialValues['additional_description'] = itemData.additional_description;
    }

    const formValidation = Yup.object().shape({
      quantity: Yup.number().positive().required(),
    });

    return (
      <Modal
        {...this.props}
        size={'lg'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {item.name} Details
            <p className={'mb-0'}><small>Reference {item.ref_no}</small></p>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={formValidation}
          onSubmit={values => this.props.posOrderAction ? this.addItem(values): null}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <div className={'row'}>
                  <div className={'col-12 col-lg-6'}>
                    <table className={'table border'}>
                      <tr>
                        <td className={'w-25'}><strong>Name</strong></td>
                        <td className={'flex-col'}>
                          {item.name}
                          {[Constants.POS_TYPE.RESTRO_BAR, Constants.POS_TYPE.RESTAURANT, Constants.POS_TYPE.BAR].includes(parseInt(posDetails.pos_type)) &&
                           <span className={'ml-2'}><PosItemTypeDetailsView item={item}/></span>
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className={'w-25'}><strong>Quantity</strong></td>
                        <td>
                          {parseInt(item.sub_quantity_type) !== Constants.QUANTITY_TYPE.NOS && ` ${item.sub_quantity_type_display} / `}
                          {item.quantity_type_display}
                        </td>
                      </tr>
                      
                      <tr>
                        <td className={'w-25'}><strong>Category</strong></td>
                        <td>
                          {!_.isEmpty(item.attributes) ? item.attributes.category_name : '...'}
                        </td>
                      </tr>

                    </table>
                  </div>
                  <div className={'col-12 col-lg-6'}>
                    <div className={'table-responsive'}>
                      <table className={'table border format-table'}>
                        <thead>
                          <tr>
                            <th className={'text-right'}>Price</th>
                            <th className={'text-right'}>Price (Inc Tax)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className={'text-right'}>{item.currency} {item.price}</td>
                            <td className={'text-right'}>{item.currency} {item.price_effective}</td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <strong>Tax :</strong> {item.tax_description || 'N.A'}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {!this.props.posOrderAction &&
                 <div className={'row'}>
                   <div className={'col-12 col-md-12 mb-3'}>
                     <ul className="list-group">
                       <li className="list-group-item">
                         <strong>Item Note</strong> : {item.short_description || 'N.A'}
                       </li>
                       <li className="list-group-item">
                         <strong>Description</strong> : {item.description || 'N.A'}
                       </li>
                     </ul>
                   </div>
                 </div>
                }
                {this.props.posOrderAction &&
                 <Form className={'form-group'}>
                   <div className={'row'}>
                     <div className="col-lg-6 col-md-6 col-sm-12">
                       <div className="form-row">
                         <div className="col form-group">
                           <Input
                             name='quantity'
                             label={'Quantity'}
                             className={'form-control'}
                           />
                         </div>
                         <div className={`col form-group ${!(this.props.posOrderAction && itemData) && 'text-right'}`}>
                           <p className={''}>Total</p>
                           <p className={'small'}>
                             <strong>{item.currency + ' ' + AppUtil.formatPrice(parseFloat(item.price_effective) * formikProps.values.quantity * formikProps.values.sub_quantity)}</strong>
                           </p>
                         </div>
                         {(this.props.posOrderAction && itemData) &&
                         <div className="col-4 form-group text-right">
                           <p className={''}>Remove</p>
                           <button className={'btn btn-block btn-danger'} onClick={()=>{this.props.posOrderAction(item, true); this.props.onHide();}}>
                             Remove
                           </button>
                         </div>
                         }
                       </div>
                     </div>
                     <div className="col-lg-6 col-md-6 col-sm-12">
                       <div className="form-row">
                         <div className="col form-group">
                           <Textarea
                             name='additional_description'
                             label={'Description'}
                             className={'form-control'}
                           />
                         </div>
                       </div>
                     </div>
                   </div>
                 </Form>
                }
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  {this.props.posOrderAction &&
                   <div className={'col'}>
                     <SubmitBtn className={'btn btn-success btn-block btn-lg'}>
                       <FontAwesomeIcon className={'white-cl mr-2'} icon={itemData ? faSave : faPlus} size={'sm'}/>
                       {itemData ? 'Update' : 'Add Item'}
                     </SubmitBtn>
                   </div>
                  }
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      init: true,
      urlParams: {},
    };
  }
}

/* Export Component ==================================================================== */
export default POSItemDetails;
