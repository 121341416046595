/**
 *
 * Booking Guest Digest
 */
import _ from 'lodash';
import Moment from 'moment';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Alerts } from '../../../components/ui';
// Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
// Constants
import { Constants, Strings } from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class BookingGuestDigest extends Component {
  static componentName = 'BookingGuestDigest';

  static propTypes = {
    startDate: PropTypes.object
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {startDate} = this.props;
    this.setState({loading: true});
    const date = (startDate ? startDate : Moment()).format('YYYY-MM-DD');
    const queryParams = {
      'date': date,
    };

    AppAPI.bookingapi.get(`booking-room-digest/?${queryString.stringify(queryParams)}`)
      .then((res) => {
        this.setState({loading: false, response: res, date:date});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({loading: false, error: error});
      });
  };

  renderData = (data) => {
    return (
      <React.Fragment>
        {!_.isEmpty(data) ?
          <React.Fragment>
            {data.map((bdata, i) => (
              <React.Fragment key={i}>
                <tr className={(i % 2 === 0) ? 'grey-light-bg' : ''}>
                  <td rowSpan={1} className={'border-right align-middle text-center'}>
                    <Link
                      to={`/booking/${bdata.attributes && bdata.attributes.booking_hash ? bdata.attributes.booking_hash : bdata.booking_id}/booking-room/${bdata.hash}`}
                      rel='noopener noreferrer' target={'_blank'}>{bdata.ref_no}</Link><br/>
                    <Link to={`/booking/${bdata.attributes && bdata.attributes.booking_hash ? bdata.attributes.booking_hash : bdata.booking_id}`} rel='noopener noreferrer' target={'_blank'} className={'small'}>
                      B : {bdata.attributes.booking_ref_no}
                    </Link>
                  </td>
                  <td className={'border-right align-middle'}>{AppUtil.formatDateTime(bdata.checkin, 'datef')}</td>
                  <td className={'align-middle'}>{AppUtil.formatDateTime(bdata.checkout, 'datef')}</td>
                  <td className={'border-right border-left  data-table-cell-xl'} rowSpan={1}>
                    {!_.isEmpty(bdata.guests) &&
                    <ol className={'pl-3'}>
                      {bdata.guests.map((data, i) => (
                        <li key={i} className={'text-left'}>
                          {data.name}
                        </li>
                      ))}
                    </ol>
                    }
                  </td>
                  <td className={'border-right data-table-cell-md align-middle text-center'}>
                    <strong>{(bdata.attributes && bdata.attributes.room_name) ? bdata.attributes.room_name : '...'}</strong>
                  </td>
                  <td colSpan={1} className={'text-center'}>
                    <span className={`badge ${AppUtil.bookingStatusColor(bdata.booking_status)}`}>
                      {bdata.booking_status_display}
                    </span>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </React.Fragment>
          :
          <tr>
            <td colSpan={6} className={'text-center'}>
              <p className={'text-center my-4'}>
                <FontAwesomeIcon icon={faLongArrowAltLeft} className={'mx-2'}/>
                No Bookings with guests exist in property for the selection.
                <FontAwesomeIcon icon={faLongArrowAltRight} className={'mx-2'}/>
              </p>
            </td>
          </tr>
        }
      </React.Fragment>
    );
  };

  render = () => {
    const {loading, error, response, date, bookingFilter} = this.state;
    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const activeResponse = response.filter(data=>!_.isEmpty(data.guests));
    const activeBookings = activeResponse.filter(data =>  ![Constants.BOOKING.CANCELED,Constants.BOOKING.NOSHOW].includes(data.booking_status));

    let bookingList = activeResponse;

    const bookingStatusData = [
      {
        title: 'Arrival',
        color: 'green-cl',
        data: activeBookings.filter(data => data.checkin === date)
      },
      {
        title: 'Departure',
        color: 'red-cl',
        data: activeBookings.filter(data => data.checkout === date)
      },
      {
        title: 'Active',
        color: 'yellow-cl',
        data: activeBookings.filter(data => data.booking_status === Constants.BOOKING.ACTIVE)
      }
    ];

    if (bookingFilter && bookingStatusData.find(x => x.title === bookingFilter)) {
      bookingList = bookingStatusData.find(x => x.title === bookingFilter).data;
    }

    return (
      <div className="screen-container">
        <Helmet>
          <title>Guest Digest</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={'Guest'}
          description={'List of Guests and the allotted room, booking information for the selected date.'}
        />

        <div className={'row'}>
          <div className={'col-12 col-lg-12 mb-3'}>

            <div className={'row'}>
              <div className={'col-12 col-lg-12 mb-3'}>
                <div className={'row mb-2'}>
                  {bookingStatusData.map((data, i)=>(
                    <div className={'col-4 col-md-4 mb-3 align-items-center'} key={i}>
                      <div className={`card ${data.title === bookingFilter && 'lime-bg'}`}>
                        <div className="card-body ">
                          <p className={'mb-1 text-muted'}>{data.title}</p>
                          <h5 className={data.color}>{data.data.reduce((a, b)=> (a + b.guests.length), 0)}</h5>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <ul className="list-inline list-item-mb-1">
                  <li className="list-inline-item">
                    <button className={'btn btn-sm btn-link'} disabled={true}>
                      Filter <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-1'}/>
                    </button>
                  </li>
                  {bookingStatusData.map((data, i)=>(
                    <li key={i} className="list-inline-item mr-3">
                      <button
                        className={`btn ${data.title === bookingFilter ? 'btn-primary' : 'btn-outline-secondary' } btn-sm`}
                        onClick={() => this.setState({
                          bookingFilter: data.title === bookingFilter ? null : data.title
                        })}
                      >
                        {data.title} ({data.data.reduce((a, b)=> (a + b.guests.length), 0)})
                      </button>
                    </li>
                  ))}
                </ul>

                <Alerts status={Strings.bookingGuestProfileCountMessage}/>

              </div>
            </div>

            <div className={'table-responsive'}>
              <table className={'table table border'}>
                <thead>
                  <tr>
                    <th className="data-table-cell-md">Booking Ref</th>
                    <th className="data-table-cell-xl" colSpan={2}>Booking Date</th>
                    <th className="data-table-cell-lg">Guest</th>
                    <th className="data-table-cell-md">Room</th>
                    <th className="data-table-cell-md" colSpan={1}>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderData(bookingList)}
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      showPAOverview: false,
      showPAOrderData: {},
      response: [],
      bookingFilter: null,
      date: Moment().format('YYYY-MM-DD')
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingGuestDigest);
