/**
 * BookingRowView
 *  View for Booking Listing
 *
 */

import _ from 'lodash';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import React, {Component} from 'react';
import {Collapse} from 'react-bootstrap';
// Consts and Libs
import AppUtil from '../../../lib/util';

// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleDown, faChevronCircleUp, faLongArrowAltRight} from '@fortawesome/free-solid-svg-icons';

import BookingRoomRowView from './BookingRoomRowView';

/* Component ==================================================================== */
class BookingRowView extends Component {
  static componentName = 'BookingRowView';

  static propTypes = {
    data: PropTypes.object.isRequired,
    key: PropTypes.string,
    history: PropTypes.object,
    disabled: PropTypes.bool,
    roomExpanded: PropTypes.bool,
    setRoomExpanded: PropTypes.func
  };

  static defaultProps = {
    roomExpanded: false
  }

  render = () => {
    const {key, data, roomExpanded} = this.props;
    return (
      <React.Fragment key={key}>
        <tr>
          <td className={'data-table-cell-lg align-middle'}>
            <p className={'small mb-0 text-muted'}>Reference</p>
            <Link to={`/booking/${data.hash}`}>{data.ref_no}</Link>
          </td>
          <td className={'data-table-cell-lg align-middle text-left'}>
            <p className={'small mb-0 text-muted'}>Primary Guest</p>
            <strong>{!_.isEmpty(data.guest) ? data.guest.name : '...'}</strong>
            {data.remarks && <p className={'small mb-0 text-muted'}>{data.remarks}</p>}
          </td>
          <td className={'text-left align-middle data-table-cell-sm'}>
            <span className={`badge ${data.confirm_status ? 'badge-success' : 'badge-warning'} small`}>
              {data.confirm_status ? 'Confirmed' : 'Provisional'}
            </span>
            <span className={'badge ' + (AppUtil.bookingStatusColor(data.booking_status))}>
              {data.booking_status_display}
            </span>
          </td>
          <td className={'align-middle text-left data-table-cell-lg'}>
            <p className={'small mb-0 text-muted'}>Source</p>
            {data.booking_source_display}
            {(data.attributes && data.attributes.booking_ota_name) &&
            <div className={'small'}>({data.attributes.booking_ota_name})</div>
            }
          </td>
          <td className={'align-middle data-table-cell-md text-right'}>
            <p className={'text-muted  small m-0 p-0 mb-1'}> {!_.isEmpty(data.booking_rooms) ? data.booking_rooms.length : 0 } Room(s)</p>
            {(!_.isEmpty(data.booking_rooms) && this.props.setRoomExpanded) ?
              <button
                className={`btn btn-sm btn-outline-${roomExpanded ? 'secondary' : 'primary'}`}
                onClick={() => this.props.setRoomExpanded(roomExpanded ? null : data.id)}
              >
                <FontAwesomeIcon
                  icon={roomExpanded ? faChevronCircleUp : faChevronCircleDown}/> {roomExpanded ? 'Collapse' : 'Expand'}
              </button>
              :
              <Link to={`/booking/${data.hash}`}>
                Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
              </Link>
            }
          </td>
        </tr>
        {(data.created && roomExpanded) &&
        <tr className={'border-0'}>
          <td colSpan={6} className={'border-0'}>
            <p className={'text-muted  small m-0 p-0'}>Created at {AppUtil.formatDateTime(data.created)}</p>
          </td>
        </tr>
        }
        {(!_.isEmpty(data.booking_rooms) && roomExpanded) &&
        <Collapse in={roomExpanded}>
          <tr className={'border-0 light-bg'}>
            <td colSpan={6} className={'bg-white'}>
              <table className={'table table-borderless table-sm border mb-0'}>
                <tbody>
                  {data.booking_rooms.map((roomData, i) => (
                    <React.Fragment key={i}>
                      <BookingRoomRowView
                        bookingData={data}
                        roomData={roomData}
                        borderTop={i > 0}
                        paddingTd={true}
                        showRoomNumber={true}
                        showCreatedDate={false}
                      />
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        </Collapse>
        }
      </React.Fragment>
    );
  };
}

/* Export Component ==================================================================== */
export default BookingRowView;

