/**
 *
 * Guest Report
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import Moment from 'moment';
// Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PageHeader from '../../../components/dashboard/PageHeader';
import DateRangePicker from 'react-daterange-picker';
import 'react-daterange-picker/dist/css/react-calendar.css';
import {faCaretDown, faExternalLinkAlt, faFileCsv, faFilePdf, faSync} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class GuestReports extends Component {
  static componentName = 'GuestReports';

  componentDidMount = () => {
    if (this.props.match.params.date) {
      this.setState({
        date: Moment(String(this.props.match.params.date)),
      }, () => this.fetchInitData());
    } else {
      this.fetchInitData();
    }
  };


  fetchInitData = () => {
    this.setState({
      loading_inner: true,
      error: false,
    });

    let url = 'guest-report/';

    if (this.state.date) {
      url = `${url}${Moment(this.state.date).format('YYYY-MM-DD')}/`;
    }

    if (this.state.selector) {
      url = `${url}?active=${this.state.selector}`;
    }

    AppAPI.reportapi
      .get(url)
      .then(res => {
        if (res) {
          this.setState({
            date: Moment(res.date),
            data: res,
            loading: false,
            loading_inner: false,
            error: false,
          });
        } else {
          this.setState({
            loading: false,
            error: true,
          });
        }
      })
      .catch(err => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: error,
          resultMsg: {error},
        });
      });
  };

  onSelect = (date) => {
    this.setState({date: date, showDatePicker: false});
  };

  toggleDatePicker = () => {
    this.setState({showDatePicker: !this.state.showDatePicker});
  };

  performLookup = () => {
    let date = Moment(this.state.date).format('YYYY-MM-DD');
    this.props.history.replace('/reports/guest-report/' + date + '/');
  };

  exportReport = (type = 'pdf') => {
    let url = 'guest-report';

    if (this.state.date) {
      url = `${url}/${Moment(this.state.date).format('YYYY-MM-DD')}`;
    }

    if (this.state.selector) {
      url = `${url}/?active=${this.state.selector}`;
    } else {
      url = `${url}/?`;
    }


    let fileName = `Guest-Report-${Moment(this.state.date).format('YYYY-MM-DD')}.${type}`;

    AppAPI.reportapi.get(url + `&download=${type}`)
      .then((response) => {
        response.blob().then((blob) => {
          // 2. Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        });
      });
  };

  render = () => {
    const {loading, loading_inner, error, date, data, showDatePicker, selector} = this.state;
    const {property} = this.props;

    if (!property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="dashboard screen-container">
        <Helmet>
          <title>Booking Report</title>
        </Helmet>

        <PageHeader
          history={this.props.history} title={'Guest Report'}
          description={'Guests staying in the property.'}/>

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className={'row'}>
              <div className={'col-md-6 col-sm-12 mb-5'}>
                <div className="btn-group btn-group-sm" role="group">
                  <button type="button" className="btn btn-outline-secondary" onClick={this.toggleDatePicker}>
                    {AppUtil.formatDateTime(date, 'datef')}
                    <FontAwesomeIcon icon={faCaretDown} size={'lg'} className={'mx-2'}/>
                  </button>
                  <button
                    type="button" onClick={this.performLookup}
                    className={'btn ' + (Moment(data.date).isSame(date) ? 'btn-secondary' : 'btn-success')}>
                    <FontAwesomeIcon icon={faSync} size={'sm'} className={'mx-2'}/>
                    {Moment(data.date).isSame(date) ? 'Refresh' : 'Update'}
                  </button>
                </div>
                <div
                  className={(showDatePicker ? 'collapse.show' : 'collapse') + ' position-absolute bg-white'}
                  id="datePicker">
                  <DateRangePicker
                    className={'mx-auto'}
                    selectionType={'single'}
                    value={date}
                    onSelect={this.onSelect}
                  />
                </div>
              </div>
              <div className={'col-md-6 col-sm-12 mb-5 text-right'}>
                <div className="btn-group btn-group-sm" role="group">
                  <button className="btn btn-outline-secondary" onClick={() => this.exportReport('csv')}>
                    <FontAwesomeIcon icon={faFileCsv} size={'lg'} className={'mx-2'}/> Export CSV
                  </button>
                  <button className="btn btn-outline-secondary" onClick={() => this.exportReport('pdf')}>
                    <FontAwesomeIcon icon={faFilePdf} size={'lg'} className={'mx-2'}/> Export PDF
                  </button>
                </div>
              </div>
            </div>

            <div className={'mb-5'}>
              <div className="btn-group">
                <button
                  disabled={true}
                  type="button" className={'btn btn-outline-secondary'}>
                  Filters
                </button>
                <button
                  onClick={() => this.setState({selector: null}, () => this.fetchInitData())}
                  className={'btn ' +  (!selector ? 'btn-primary' : 'btn-outline-secondary') }>
                  All Guest
                </button>
                <button
                  type="button"
                  onClick={() => this.setState({selector: 'active'}, () => this.fetchInitData())}
                  className={'btn ' +  (selector === 'active' ? 'btn-primary' : 'btn-outline-secondary') }>
                  Active Guest
                </button>
              </div>
            </div>

            {loading_inner ?
              <Loading heightMatch={false}/> :
              <div className={'row'}>
                <div className={'col-xl-12 col-md-12 col-12 mb-3'}>
                  <div className="table-responsive">
                    <table className={'table table-hover'}>
                      <thead className="bg-dark text-light">
                        <tr>
                          <th>Guest Name</th>
                          <th>Contact</th>
                          <th>Nationality</th>
                          <th>Room No</th>
                          <th>Room Type</th>
                          <th>Checkin</th>
                          <th>Checkout</th>
                          <th>Booking Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.booking_rooms && data.booking_rooms.length > 0 ?
                          <React.Fragment>
                            {data.booking_rooms.map((booking_room_data, i) => (
                              <React.Fragment>
                                {booking_room_data.guests && booking_room_data.guests.length > 0 && booking_room_data.guests.map((guest_data, j) => (
                                  <React.Fragment>
                                    <tr key={i + j} className={'text-break'}>
                                      <td className={'w-15'}>
                                        {guest_data.name ? guest_data.name : 'N.A'}
                                        <p className={'mb-0 text-muted small'}>{guest_data.gender_display}</p>
                                      </td>
                                      <td className={'w-15'}>
                                        {guest_data.phone && <React.Fragment><p
                                          className={'mb-0 text-muted small'}>Phone : {guest_data.phone}</p>
                                        </React.Fragment>}
                                        {guest_data.email && <React.Fragment><p
                                          className={'mb-0 text-muted small'}>Email : {guest_data.email}</p>
                                        </React.Fragment>}
                                      </td>
                                      <td>
                                        <p
                                          className={'mb-0 text-muted small'}>{guest_data.nationality ? guest_data.nationality.name : 'N.A'}</p>
                                      </td>
                                      <td>
                                        <p
                                          className={'small mb-0'}>{booking_room_data.attributes && booking_room_data.attributes.room_name}</p>
                                      </td>
                                      <td><p
                                        className={'small mb-0'}>{booking_room_data.attributes && booking_room_data.attributes.room_type_name}</p>
                                      </td>
                                      <td>
                                        <p
                                          className={'mb-0 small'}>{AppUtil.formatDateTime(booking_room_data.checkin, 'datef')}</p>
                                      </td>
                                      <td>
                                        <p
                                          className={'mb-0 small'}>{AppUtil.formatDateTime(booking_room_data.checkout, 'datef')}</p>
                                      </td>
                                      <td>
                                        <span
                                          className={'badge ' + (AppUtil.bookingStatusColor(booking_room_data.booking_status))}>
                                          {booking_room_data.booking_status_display}
                                        </span>
                                      </td>
                                      <td>
                                        <p className={'mb-0 small'}>
                                          <Link to={`/booking/${booking_room_data.attributes && booking_room_data.attributes.booking_hash ? booking_room_data.attributes.booking_hash : booking_room_data.booking_id}`} rel='noopener noreferrer' target={'_blank'}>
                                            <FontAwesomeIcon
                                              icon={faExternalLinkAlt} size={'sm'} className={'mr-2'}/> Booking
                                          </Link>
                                        </p>
                                        <p className={'mb-0 small'}>
                                          <Link
                                            to={`/booking/${booking_room_data.attributes && booking_room_data.attributes.booking_hash ? booking_room_data.attributes.booking_hash : booking_room_data.booking_id}/booking-room/${booking_room_data.hash}`}
                                            rel='noopener noreferrer' target={'_blank'}>
                                            <FontAwesomeIcon
                                              icon={faExternalLinkAlt} size={'sm'} className={'mr-2'}/> Room
                                          </Link>
                                        </p>
                                      </td>
                                    </tr>

                                  </React.Fragment>
                                ))}
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                          :
                          <tr>
                            <th colSpan={10} className={'text-center'}>No Guests</th>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>


      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loading_inner: true,
      showDatePicker: false,
      error: null,
      data: [],
      selector: null,
      date: Moment().startOf('day'),
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuestReports);
