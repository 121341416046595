/**
 *
 * Calender Availability
 */
/* eslint no-eval: 0 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import PageHeader from '../../components/dashboard/PageHeader';
import {
  faDoorOpen,
  faHourglass, faLongArrowAltRight,
  faSync,
  faToggleOff,
  faToggleOn,
  faToolbox,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import AvailabilityDateDetails from './models/AvailabilityDateDetails';
import AvailabilityBulkUpdate from './models/AvailabilityBulkUpdate';

import Moment from 'moment';
import FilterDate from '../../components/dashboard/FilterDate';
import AppWebUtil from '../../lib/webUtils';
import queryString from 'query-string';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class AvailabilityChart extends Component {
  static componentName = 'CalendarAvailability';

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = (silentLoad=false) => {
    this.setState({
      loading_inner: !silentLoad,
    });
    let startDate = Moment(), urlParams = {};
    if (this.getQueryParams('availability_start_date')) {
      startDate = Moment(String(this.getQueryParams('availability_start_date')));
    }
    if (this.getQueryParams('availability_end_date')) {
      let endDate = Moment(String(this.getQueryParams('availability_end_date')));
      urlParams['end_date'] = endDate.format('YYYY-MM-DD');
    }

    if (startDate) {
      urlParams['start_date'] = startDate.format('YYYY-MM-DD');
    }

    AppAPI.availabilityapi
      .get(`availability-chart/?${queryString.stringify(urlParams)}`)
      .then(res => {
        if (res) {
          this.setState({
            startDate: Moment(res.start_date),
            endDate: Moment(res.end_date),
            room_types: res.room_types,
            loading: false,
            loading_inner: false,
          });
          this.processAvailabilityChartData(res);
        } else {
          this.setState({
            loading: false,
            loading_inner: false,
            error: true,
          });
        }
      })
      .catch(err => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          loading_inner: false,
          error: error,
          resultMsg: {error},
        });
      });
  };

  processAvailabilityChartData = res => {
    let tableHead = [], tableBody = [], tableFooter = [], roomTypeData = {};

    let roomTotalData = {
      buffer_room: 0,
      maintenance_room: 0,
      total_room: 0
    };
    if (!_.isEmpty(res.room_types)) {
      res.room_types.forEach((data) => {
        tableBody[data.room_type_id] = [];
        tableBody[data.room_type_id].push({'print': data.name, 'data': data});
        roomTypeData[data.room_type_id] = data;
        if (data.total_room) {
          roomTotalData['total_room'] += data.total_room;
        }
        if (data.maintenance_room) {
          roomTotalData['maintenance_room'] += data.maintenance_room;
        }
        if (data.buffer_room) {
          roomTotalData['buffer_room'] += data.buffer_room;
        }

      });

      tableFooter.push({
        print: 'Total',
        data: roomTotalData
      });

      if (!_.isEmpty(res.availability_data)) {
        res.availability_data.forEach((availabilityData) => {

          // Initialization for day total
          let availabilityTotalData = {};
          this.state.restrictionListOptions.forEach((restrictionData)=>{
            availabilityTotalData[restrictionData.key] = 0;
          });
          this.state.displayListOptions.forEach((restrictionData)=>{
            availabilityTotalData[restrictionData.key] = 0;
          });

          // Data preparation
          tableHead.push({'date': availabilityData.date, 'future': availabilityData.future});
          if (!_.isEmpty(availabilityData.data)) {
            availabilityData.data.forEach((dayData) => {

              if (roomTypeData[dayData.room_type_id]) {
                dayData['availability'] = AppUtil.calculatePercentage(
                  dayData.available, roomTypeData[dayData.room_type_id].total_room
                );
                dayData['availability_net'] = AppUtil.calculatePercentage(
                  dayData.available,
                  (roomTypeData[dayData.room_type_id].total_room - roomTypeData[dayData.room_type_id].maintenance_room - roomTypeData[dayData.room_type_id].buffer_room)
                );
              }

              tableBody[dayData.room_type_id].push({'print': dayData.available, 'data': dayData});

              this.state.displayListOptions.forEach((restrictionData)=>{
                if (dayData[restrictionData.key]){
                  availabilityTotalData[restrictionData.key] += dayData[restrictionData.key];
                }
              });

              this.state.restrictionListOptions.forEach((restrictionData)=>{
                if (dayData[restrictionData.key]){
                  availabilityTotalData[restrictionData.key] += dayData[restrictionData.key];
                }
              });
            });

            availabilityTotalData['availability'] = AppUtil.calculatePercentage(
              availabilityTotalData.available, roomTotalData.total_room
            );
            availabilityTotalData['availability_net'] = AppUtil.calculatePercentage(
              availabilityTotalData.available,
              (roomTotalData.total_room - roomTotalData.maintenance_room - roomTotalData.buffer_room)
            );

            tableFooter.push({
              print: availabilityTotalData['available'],
              data: availabilityTotalData
            });
          }
        });
      }
    }

    this.setState({
      error: false,
      loading: false,
      loading_inner: false,
      roomTypeData,
      tableFooter,
      tableHead,
      tableBody,
    });

  }

  selectRestrictionList = (type) => {
    if (type) {
      this.setState({
        restrictionList: AppUtil.insertOrRemoveArray(this.state.restrictionList, type),
      });
    }
  }

  render = () => {
    const {
      loading,
      error,
      tableHead,
      tableBody,
      tableFooter,
      loading_inner,
      restrictionList,
      channelList,
      restrictionListOptions,
      displayList
    } = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="dashboard screen-container">
        <Helmet>
          <title>Availability Calendar</title>
        </Helmet>

        <PageHeader
          history={this.props.history} title={'Availability Calendar'}
          description={'Date wise availability information.'}
        />

        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-12 col-md-4'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                {loading_inner ?
                  <button className={'btn btn-outline-secondary px-5'}>
                    ....Loading....
                  </button>
                  :
                  <FilterDate
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    minDate={Moment()}
                    action={(data) => this.setQueryParams(AppWebUtil.processDate(data, 'availability_'))}
                    title={'Date Range'}
                    selectionType={'range'}
                    disableSelection={true}
                  />
                }
              </li>
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary'} onClick={() => this.fetchInitData()}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={''}/>
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div className={'row mb-2'}>
          <div className={'col-12 col-sm-12'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button className={'btn btn-sm btn-link'} disabled={true}>
                  Options <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-1'}/>
                </button>
              </li>
              {restrictionListOptions.map((data, i) => (
                <li className="list-inline-item" key={i}>
                  <button
                    onClick={() => this.selectRestrictionList(data.key)}
                    className={`btn btn-sm btn-outline-${restrictionList.includes(data.key) ? 'primary' : 'secondary'}`}
                  >{data.name}</button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            {loading_inner ?
              <Loading/>
              :
              <React.Fragment>
                {(_.isEmpty(tableHead) || _.isEmpty(tableBody)) ?
                  <div/>
                  :
                  <div className={'table-responsive border mb-3'}>
                    <table
                      className={'table table-hover table-bordered border-0 table-sm text-center fixed-table availability-table mb-0'}>
                      <thead className="align-middle">
                        <tr>
                          <th className={'data-table-cell-lg bg-dark align-middle'}/>
                          <th className={'data-table-cell-xl bg-dark align-middle fixed-2'}/>
                          {tableHead.map((data, i) => (
                            <th className={'data-table-cell-md bg-dark text-light'} key={i}>
                              <strong>{AppUtil.formatDateTime(data.date, 'date')}</strong> <br/>
                              <small>{AppUtil.formatDateTime(data.date, 'day')}</small>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {tableBody.map((rowData, i) => (
                          <React.Fragment key={i}>
                            <tr key={i}>
                              {rowData.map((colData, k) => (
                                <React.Fragment key={k}>
                                  {k === 0 ?
                                    <React.Fragment>
                                      <td
                                        rowSpan={1 + Object.keys(restrictionList).length + Object.keys(displayList).length}
                                        className={'bg-dark text-light text-left align-middle'}
                                        key={k}
                                      >
                                        <strong>{colData.print}</strong>
                                      </td>
                                      <td
                                        className={(Object.keys(restrictionList).length + Object.keys(displayList).length) > 1 ? 'bg-dark text-light' : 'd-none'}/>
                                    </React.Fragment>
                                    :
                                    <td
                                      className={(Object.keys(restrictionList).length + Object.keys(displayList).length) > 1 ? 'bg-dark text-light' : 'd-none'}/>
                                  }
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr className={`${displayList.includes('available') ? '' : 'd-none'}`}>
                              {rowData.map((colData, k) => {
                                return (
                                  <React.Fragment key={k}>
                                    {k === 0 ?
                                      <td className={'bg-light fixed-2 text-left m-0 p-0'}>
                                        <table className={'table table m-0 p-0'}>
                                          <tbody>
                                            <tr>
                                              <td className={'bg-light'} colSpan={4}>Availability</td>
                                            </tr>
                                            <tr>
                                              <td className={'bg-light w-20'}><small>Room(s)</small></td>
                                              <td className={'bg-light'}>
                                                <FontAwesomeIcon
                                                  icon={faDoorOpen} size={'sm'}
                                                  className={'green-cl mr-2'}
                                                />
                                                {colData.data.total_room}
                                              </td>
                                              {colData.data.maintenance_room > 0 &&
                                                <td className={'bg-light'}>
                                                  <FontAwesomeIcon
                                                    icon={faToolbox} size={'sm'}
                                                    className={'red-cl mr-2'}
                                                  />
                                                  {colData.data.maintenance_room}
                                                </td>
                                              }
                                              {colData.data.buffer_room > 0 &&
                                                <td className={'bg-light'}>
                                                  <FontAwesomeIcon
                                                    icon={faHourglass} size={'sm'}
                                                    className={'yellow-cl mr-2'}
                                                  />
                                                  {colData.data.buffer_room}
                                                </td>
                                              }
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      :
                                      <td
                                        className={'align-middle a-class'}
                                        onClick={() => this.setState({
                                          showAvailabilityDetails: true,
                                          availabilityDetails: colData.data,
                                        })}
                                      >
                                        <p
                                          className={`mb-0 ${colData.data && AppUtil.availabilityStatusColor(colData.data.availability)}`}>
                                          <strong>{colData.data ? colData.data.available : 'N.A'}</strong>
                                        </p>

                                      </td>
                                    }
                                  </React.Fragment>
                                );
                              }
                              )}
                            </tr>

                            {/*  ################ Restriction Rows ################ */}
                            {restrictionListOptions.filter(item => (item.input === 'bool')).map((bookData, i) => (
                              <tr key={i} className={`${restrictionList.includes(bookData.key) ? '' : 'd-none'}`}>
                                {rowData.map((colData, k) => (
                                  <React.Fragment key={k}>
                                    {k === 0 ?
                                      <td className={'bg-light fixed-2 text-left align-middle'}>
                                        {bookData.name}
                                        {(bookData.editable !== false) && (
                                          <button
                                            onClick={() => this.setState({
                                              showAvailabilityManage: true,
                                              restrictionSelectList: [bookData.key],
                                              roomTypeSelect: colData.data.room_type_id
                                            })}
                                            className="btn btn-sm btn-link p-0 mx-1 float-right">
                                            <FontAwesomeIcon icon={faUpload} size={'sm'}/>
                                          </button>
                                        )}
                                      </td>
                                      :
                                      <td
                                        className={'m-0 p-0 a-class'}
                                        onClick={() => this.setState({
                                          selectedTab: 'constrains',
                                          availabilityDetails: colData.data,
                                          showAvailabilityDetails: true,
                                        })}
                                      >
                                        {colData.data ?
                                          <table className={'table table m-0 p-0'}>
                                            <tbody>
                                              <tr>
                                                <td
                                                  className={`${channelList.includes('booking_engine') ? 'booking_engine-box' : 'd-none'}`}>
                                                  <FontAwesomeIcon
                                                    className={eval(`colData.data.booking_engine_${bookData.key}`) ? 'green-cl h5 m-0' : 'grey-cl h5 m-0'}
                                                    icon={eval(`colData.data.booking_engine_${bookData.key}`) ? faToggleOn : faToggleOff}
                                                  />
                                                </td>
                                                <td
                                                  className={`${channelList.includes('channel_manager') ? 'channel_manager-box' : 'd-none'}`}>
                                                  <FontAwesomeIcon
                                                    className={eval(`colData.data.channel_manager_${bookData.key}`) ? 'green-cl h5 m-0' : 'grey-cl h5 m-0'}
                                                    icon={eval(`colData.data.channel_manager_${bookData.key}`) ? faToggleOn : faToggleOff}
                                                  />
                                                </td>
                                                <td
                                                  className={`${channelList.includes('internal_engine') ? 'internal_engine-box' : 'd-none'}`}>
                                                  <FontAwesomeIcon
                                                    className={eval(`colData.data.internal_engine_${bookData.key}`) ? 'green-cl h5 m-0' : 'grey-cl h5 m-0'}
                                                    icon={eval(`colData.data.internal_engine_${bookData.key}`) ? faToggleOn : faToggleOff}
                                                  />
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          :
                                          'N.A'
                                        }
                                      </td>
                                    }
                                  </React.Fragment>
                                ))}
                              </tr>
                            ))}

                            {restrictionListOptions.filter(item => (item.input === 'number')).map((bookData, i) => (
                              <tr key={i} className={`${restrictionList.includes(bookData.key) ? '' : 'd-none'}`}>
                                {rowData.map((colData, k) => (
                                  <React.Fragment key={k}>
                                    {k === 0 ?
                                      <td className={'bg-light fixed-2 text-left'}>
                                        {bookData.name}
                                        {(bookData.editable !== false) && (
                                          <button
                                            onClick={() => this.setState({
                                              showAvailabilityManage: true,
                                              restrictionSelectList: [bookData.key],
                                              roomTypeSelect: colData.data.room_type_id
                                            })}
                                            className="btn btn-sm btn-link p-0 mx-1 float-right">
                                            <FontAwesomeIcon icon={faUpload} size={'sm'}/>
                                          </button>
                                        )}
                                      </td>
                                      :
                                      <td
                                        className={'a-class'}
                                        onClick={() => this.setState({
                                          availabilityDetails: colData.data,
                                          selectedTab: 'constrains',
                                          showAvailabilityDetails: true,
                                        })}
                                      >
                                        {colData.data ? (
                                          <>
                                            {bookData.global ? (
                                              <p className={'mb-0'}>
                                                {colData.data[bookData.key] ? colData.data[bookData.key] : '...'}
                                              </p>
                                            ) : (
                                              <table className={'table table m-0 p-0'}>
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      className={`${channelList.includes('booking_engine') ? 'booking_engine-box' : 'd-none'}`}>
                                                      {eval(`colData.data.booking_engine_${bookData.key}`) ? eval(`colData.data.booking_engine_${bookData.key}`) : '...'}
                                                    </td>
                                                    <td
                                                      className={`${channelList.includes('channel_manager') ? 'channel_manager-box' : 'd-none'}`}>
                                                      {eval(`colData.data.channel_manager_${bookData.key}`) ? eval(`colData.data.channel_manager_${bookData.key}`) : '...'}
                                                    </td>
                                                    <td
                                                      className={`${channelList.includes('internal_engine') ? 'internal_engine-box' : 'd-none'}`}>
                                                      {eval(`colData.data.internal_engine_${bookData.key}`) ? eval(`colData.data.internal_engine_${bookData.key}`) : '...'}
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            )
                                            }
                                          </>
                                        ) :
                                          'N.A'
                                        }
                                      </td>
                                    }
                                  </React.Fragment>
                                ))}
                              </tr>
                            ))}
                          </React.Fragment>
                        ))}

                        <tr>
                          <td colSpan={2} className={'bg-dark'}/>
                          <td colSpan={tableHead.length} className={'bg-dark'}/>
                        </tr>
                        {/* ################# Total Data ###################### */}
                        <tr>
                          {tableFooter.map((rowData, i)=> (
                            <React.Fragment key={i}>
                              {i === 0 && (
                                <React.Fragment>
                                  <td
                                    rowSpan={1 + Object.keys(restrictionList).length + Object.keys(displayList).length}
                                    className={'bg-dark text-light text-left align-middle'}
                                    key={i}
                                  >
                                    <strong>{rowData.print}</strong>
                                  </td>
                                </React.Fragment>
                              )}
                              {i === 0 ?
                                <td className={'bg-light fixed-2 text-left m-0 p-0'}>
                                  <table className={'table table m-0 p-0'}>
                                    <tbody>
                                      <tr>
                                        <td className={'bg-light'} colSpan={4}>Availability</td>
                                      </tr>
                                      <tr>
                                        <td className={'bg-light w-20'}><small>Room(s)</small></td>
                                        <td className={'bg-light'}>
                                          <FontAwesomeIcon
                                            icon={faDoorOpen} size={'sm'}
                                            className={'green-cl mr-2'}
                                          />
                                          {rowData.data.total_room}
                                        </td>
                                        {rowData.data.maintenance_room > 0 &&
                                          <td className={'bg-light'}>
                                            <FontAwesomeIcon
                                              icon={faToolbox} size={'sm'}
                                              className={'red-cl mr-2'}
                                            />
                                            {rowData.data.maintenance_room}
                                          </td>
                                        }
                                        {rowData.data.buffer_room > 0 &&
                                          <td className={'bg-light'}>
                                            <FontAwesomeIcon
                                              icon={faHourglass} size={'sm'}
                                              className={'yellow-cl mr-2'}
                                            />
                                            {rowData.data.buffer_room}
                                          </td>
                                        }
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                :
                                <td className={'align-middle'}>
                                  <p
                                    className={`mb-0 ${rowData.data && AppUtil.availabilityStatusColor(rowData.data.availability)}`}>
                                    <strong>{rowData.print}</strong>
                                  </p>
                                </td>
                              }
                            </React.Fragment>
                          ))}
                        </tr>
                        {/* Restriction Rows  */}
                        {restrictionListOptions.filter(item => (item.input === 'number')).map((bookData, i) => (
                          <tr key={i} className={`${restrictionList.includes(bookData.key) ? '' : 'd-none'}`}>
                            {tableFooter.map((colData, k) => (
                              <React.Fragment key={k}>
                                {k === 0 ?
                                  <td className={'bg-light fixed-2 text-left'}>
                                    {bookData.name}
                                  </td>
                                  :
                                  <td>
                                    {colData.data ? (
                                      <>
                                        {bookData.global ? (
                                          <p className={'mb-0'}>
                                            {colData.data[bookData.key] ? colData.data[bookData.key] : '...'}
                                          </p>
                                        ) : <p/>
                                        }
                                      </>
                                    ) :
                                      'N.A'
                                    }
                                  </td>
                                }
                              </React.Fragment>
                            ))}
                          </tr>
                        ))}

                      </tbody>
                    </table>
                  </div>
                }
              </React.Fragment>
            }
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <div className={'p-2'}>
                  <FontAwesomeIcon
                    icon={faDoorOpen} size={'sm'}
                    className={'green-cl mr-1'}
                  /> Total Room(s)
                </div>
              </li>
              <li className="list-inline-item">
                <div className={'p-2'}>
                  <FontAwesomeIcon
                    icon={faToolbox} size={'sm'}
                    className={'red-cl mr-1'}
                  /> Room(s) in Maintenance
                </div>
              </li>
              <li className="list-inline-item">
                <div className={'p-2'}>
                  <FontAwesomeIcon
                    icon={faHourglass} size={'sm'}
                    className={'yellow-cl mr-1'}
                  /> Buffer Rooms(s)
                </div>
              </li>
            </ul>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <div className={'p-2'}>
                  <strong>Availability (N)</strong> : Effective availability
                </div>
              </li>
              <li className="list-inline-item">
                <div className={'p-2'}>
                  <strong>Availability (A)</strong> : Total availability including Buffer & Maintenance rooms
                </div>
              </li>
            </ul>
          </div>
        </div>
        <AvailabilityDateDetails
          {...this.props}
          show={this.state.showAvailabilityDetails}
          onHide={() =>
            this.setState({
              showAvailabilityDetails: false,
              availabilityDetails: null,
              selectedTab: 'overview'
            })
          }
          property={this.props.property}
          availabilityDetails={this.state.availabilityDetails}
          updateResponse={(res)=>{
            if (!_.isEmpty(res)){
              this.setState({availabilityDetails: res});
            }
            this.fetchInitData(true);
          }}
          selectedTab={this.state.selectedTab}
          roomTypeData={this.state.roomTypeData}
        />
        <AvailabilityBulkUpdate
          show={this.state.showAvailabilityManage}
          onHide={() => this.setState({
            showAvailabilityManage: false,
            restrictionSelectList: null,
            roomTypeSelect: null
          })}
          roomTypes={this.state.room_types}
          roomTypeSelect={this.state.roomTypeSelect}
          updateResponse={this.fetchInitData}
          channelList={this.state.channelList}
          restrictionList={this.state.restrictionSelectList || this.state.restrictionList}
          restrictionListOptions={this.state.restrictionListOptions}
          minDate={this.state.startDate}
          maxDate={this.state.endDate}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loading_inner: true,
      error: null,
      restrictionList: [],
      restrictionSelectList: null,
      roomTypeSelect: null,
      channelList: [],
      startDate: Moment().startOf('day'),
      endDate: Moment().add(30, 'days'),
      showAvailabilityDetails: false,
      availabilityDetails: null,
      showAvailabilityManage: false,
      displayList: ['available'],
      displayListOptions: [
        {
          'key': 'available',
          'name': 'Availability',
          'input': 'number',
        },
      ],
      restrictionListOptions: [
        {
          'key': 'all_channel_block',
          'name': 'Inventory Block',
          'input': 'number',
          'global': true
        },
        {
          'key': 'availability',
          'name': 'Availability (N)',
          'input': 'number',
          'global': true,
          'editable': false
        },
        {
          'key': 'availability_net',
          'name': 'Availability (A)',
          'input': 'number',
          'global': true,
          'editable': false
        },
        {
          'key': 'booked',
          'name': 'Confirmed Bookings',
          'input': 'number',
          'global': true,
          'editable': false
        },
        {
          'key': 'blocked',
          'name': 'Provisional Bookings',
          'input': 'number',
          'global': true,
          'editable': false
        },
        {
          'key': 'cancelled',
          'name': 'Cancelled Bookings',
          'input': 'number',
          'global': true,
          'editable': false
        }
      ]
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityChart);
