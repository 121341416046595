/**
 * Payment Bifurcation Overview Model
 */

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
// Components
import { Alerts } from '../../../../components/ui';
import PABifurcationTable from '../components/PABifurcationTable';

/* Component ==================================================================== */

class PABifurcationViewModel extends Component {
  static componentName = 'PABifurcationViewModel';

  static propTypes = {
    property: PropTypes.object.isRequired,
    paymentData: PropTypes.object.isRequired,
    bifurcationData: PropTypes.object.isRequired,
    bifurcationTitle: PropTypes.string.isRequired,
  };

  static defaultProps = {
    title: 'Overview'
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  render = () => {

    const {resultMsg} = this.state;
    const {show, paymentData, property, bifurcationTitle, bifurcationData, title} = this.props;

    if (!show || _.isEmpty(paymentData)) {
      return null;
    }

    return (
      <Modal {...this.props} size={'xl'} aria-labelledby="contained-modal-title-vcenter" scrollable centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            {title} : {bifurcationTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PABifurcationTable
            property={property}
            bifurcationTitle={bifurcationTitle}
            paymentData={paymentData}
            bifurcationData={bifurcationData}
          />
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          <div className={'px-3'}>
            <Alerts
              error={resultMsg.error}
              status={resultMsg.status}
              success={resultMsg.success}
            />
          </div>
          <div className={'row'}>
            <div className={'col'}>
              <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      }
    };
  }
}

/* Export Component ==================================================================== */
export default PABifurcationViewModel;
