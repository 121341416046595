/**
 *
 * Property Booking Engine Settings
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Consts and Libs
import {Strings} from '../../../constants';
import AppAPI from '../../../lib/api';
// Components
import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLinkAlt, faLink, faUnlink} from '@fortawesome/free-solid-svg-icons';
import {confirmAlert} from '../../../components/general/ConfirmAlert';
import CopyToClipboard from '../../../components/general/CopyToClipboard';
// Actions
import * as PropertyActions from '../../../redux/property/actions';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  propertyContent: state.property.content,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  isSetPropertyDetails: PropertyActions.isSetPropertyDetails,
};

/* Component ==================================================================== */
class PropertyBookingEngine extends Component {
  static componentName = 'PropertyBookingEngineSettings';

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    this.setState({
      loading: false,
      error: null,
    });
  };

  updateProperty = (payload) => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.propertyapi.patch(`property-distribution/${this.props.property.id}/`, payload)
        .then((res) => {
          this.setState({resultMsg: {success: 'Success'}});
          this.props.isSetPropertyDetails(res).then(() => {
            setTimeout(() => {
              this.setState({
                resultMsg: {success: ''},
              });
            }, 500);
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    });
  };

  render = () => {
    const {loading, error, resultMsg} = this.state;

    if (!this.props.property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const {property} = this.props;

    return (
      <div className="channel-manager-settings screen-container">
        <Helmet>
          <title>Booking Engine Settings</title>
        </Helmet>
        <Alerts
          status={resultMsg.status}
          success={resultMsg.success}
          error={resultMsg.error}
        />
        <div className={'row'}>
          <div className="col-lg-12 col-md-12 col-sm-12 mb-5">
            <div className={'row'}>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <ul className="list-group mb-4">
                  <li className="list-group-item d-flex">
                    <div>
                      <FontAwesomeIcon
                        icon={property.booking_engine_ok ? faLink : faUnlink}
                        className={`${property.booking_engine_ok ? 'green-cl' : 'red-cl'} mr-2`}
                      />
                    </div>
                    <div>
                      <strong>Booking Engine Connection :</strong>
                      <span className={'ml-2 badge ' + (property.booking_engine_ok ? 'badge-success' : 'badge-secondary')}>
                        {property.booking_engine_ok ? 'Connected' : 'Not Connected'}
                      </span>
                      <br/>
                      {
                        Strings.bookingEngineConnectionSettings.map((data, i) =>
                          <p className={'sm mb-0 text-secondary small'} key={i}>{data}</p>
                        )
                      }
                    </div>
                  </li>
                  {property.booking_engine_ok &&
                   <li className="list-group-item">
                     <p className={'mb-1'}><strong><small>Booking Engine URL</small></strong></p>
                     <div className="input-group">
                       <input
                         type="text" value={property.booking_engine_url} disabled={true}
                         className={'form-control bg-light'}
                       />
                       <div className="input-group-prepend">
                         <CopyToClipboard content={property.booking_engine_url}/>
                       </div>
                       <div className="input-group-prepend">
                         <a
                           className={'btn btn-primary'} href={property.booking_engine_url}
                           rel='noopener noreferrer' target={'_blank'}
                         >
                           <FontAwesomeIcon icon={faExternalLinkAlt} size={'sm'}/>
                         </a>
                       </div>
                     </div>
                   </li>
                  }
                </ul>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <ul className="list-group mb-4">
                  <li className="list-group-item">
                    <div className="custom-control custom-switch">
                      <input
                        disabled={!property.booking_engine_ok}
                        type="checkbox" className="custom-control-input" id={'allow_booking_engine_distribution'}
                        checked={property.allow_booking_engine_distribution}
                        onClick={() => {
                          confirmAlert({
                            title: (property.allow_booking_engine_distribution ? 'Disable' : ' Enable') + ' Distribution',
                            message: property.allow_booking_engine_distribution ? Strings.bookingEngineDistributionDisable : Strings.bookingEngineDistributionEnable,
                            buttons: [
                              {
                                className: (property.allow_booking_engine_distribution ? 'btn-danger' : ' btn-success'),
                                label: 'Yes',
                                onClick: () => this.updateProperty({
                                  'allow_booking_engine_distribution': !property.allow_booking_engine_distribution,
                                }),
                              },
                              {
                                className: 'btn-secondary',
                                label: 'No',
                              },
                            ],
                          });
                        }}
                      />
                      <label className="custom-control-label" htmlFor={'allow_booking_engine_distribution'}>
                        <strong>Distribution Status : </strong>
                        <span
                          className={'badge ' + (property.allow_booking_engine_distribution ? 'badge-success' : 'badge-secondary')}>
                          {property.allow_booking_engine_distribution ? 'Enabled' : ' Disabled'}
                        </span>
                        <br/>
                        {
                          Strings.bookingEngineDistribution.map((data, i) =>
                            <p className={'sm mb-0 text-secondary small'} key={i}>{data}</p>
                          )
                        }
                      </label>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="custom-control custom-switch">
                      <input
                        disabled={!property.allow_booking_engine_distribution || !property.booking_engine_ok}
                        type="checkbox" className="custom-control-input" id={'allow_event_booking_engine'}
                        checked={property.allow_event_booking_engine}
                        onClick={() => {
                          confirmAlert({
                            title: (property.allow_event_booking_engine ? 'Disable' : ' Enable') + ' Distribution',
                            message: property.allow_event_booking_engine ? Strings.bookingEngineSectionDistributionDisable : Strings.bookingEngineSectionDistributionEnable,
                            buttons: [
                              {
                                className: (property.allow_event_booking_engine ? 'btn-danger' : ' btn-success'),
                                label: 'Yes',
                                onClick: () => this.updateProperty({
                                  'allow_event_booking_engine': !property.allow_event_booking_engine,
                                }),
                              },
                              {
                                className: 'btn-secondary',
                                label: 'No',
                              },
                            ],
                          });
                        }}
                      />
                      <label className="custom-control-label" htmlFor={'allow_event_booking_engine'}>
                        <strong>Event / Ticket Booking : </strong>
                        <span
                          className={'badge ' + (property.allow_event_booking_engine ? 'badge-success' : 'badge-secondary')}>
                          {property.allow_event_booking_engine ? 'Enabled' : ' Disabled'}
                        </span>
                        <br/>
                        {
                          Strings.bookingEngineEventDistribution.map((data, i) =>
                            <p className={'sm mb-0 text-secondary small'} key={i}>{data}</p>
                          )
                        }
                      </label>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="custom-control custom-switch">
                      <input
                        disabled={!property.allow_booking_engine_distribution || !property.booking_engine_ok}
                        type="checkbox" className="custom-control-input" id={'allow_room_booking_engine'}
                        checked={property.allow_room_booking_engine}
                        onClick={() => {
                          confirmAlert({
                            title: (property.allow_room_booking_engine ? 'Disable' : ' Enable') + ' Distribution',
                            message: property.allow_room_booking_engine ?  Strings.bookingEngineSectionDistributionDisable : Strings.bookingEngineSectionDistributionEnable,
                            buttons: [
                              {
                                className: (property.allow_room_booking_engine ? 'btn-danger' : ' btn-success'),
                                label: 'Yes',
                                onClick: () => this.updateProperty({
                                  'allow_room_booking_engine': !property.allow_room_booking_engine,
                                }),
                              },
                              {
                                className: 'btn-secondary',
                                label: 'No',
                              },
                            ],
                          });
                        }}
                      />
                      <label className="custom-control-label" htmlFor={'allow_room_booking_engine'}>
                        <strong>Room Booking : </strong>
                        <span
                          className={'badge ' + (property.allow_room_booking_engine ? 'badge-success' : 'badge-secondary')}>
                          {property.allow_room_booking_engine ? 'Enabled' : ' Disabled'}
                        </span>
                        <br/>
                        {
                          Strings.bookingEngineRoomDistribution.map((data, i) =>
                            <p className={'sm mb-0 text-secondary small'} key={i}>{data}</p>
                          )
                        }
                      </label>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="custom-control custom-switch">
                      <input
                        disabled={!property.booking_engine_ok}
                        type="checkbox" className="custom-control-input" id={'booking_engine_profile_address'}
                        checked={property.booking_engine_profile_address}
                        onClick={() => {
                          confirmAlert({
                            title: (property.booking_engine_profile_address ? 'Disable' : ' Enable') + ' Distribution',
                            message: property.booking_engine_profile_address ?  Strings.bookingEngineProfileAddressDisable : Strings.bookingEngineProfileAddressEnable,
                            buttons: [
                              {
                                className: (property.booking_engine_profile_address ? 'btn-danger' : ' btn-success'),
                                label: 'Yes',
                                onClick: () => this.updateProperty({
                                  'booking_engine_profile_address': !property.booking_engine_profile_address,
                                }),
                              },
                              {
                                className: 'btn-secondary',
                                label: 'No',
                              },
                            ],
                          });
                        }}
                      />
                      <label className="custom-control-label" htmlFor={'booking_engine_profile_address'}>
                        <strong>Guest Address : </strong>
                        <span
                          className={'badge ' + (property.booking_engine_profile_address ? 'badge-success' : 'badge-secondary')}>
                          {property.booking_engine_profile_address ? 'Enabled' : ' Disabled'}
                        </span>
                        <br/>
                        {
                          Strings.bookingEngineProfileAddress.map((data, i) =>
                            <p className={'sm mb-0 text-secondary small'} key={i}>{data}</p>
                          )
                        }
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      roomTypes: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyBookingEngine);
