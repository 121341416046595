/**
 *
 * POS Room List
 */
import _ from 'lodash';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import React, {Component} from 'react';
// Consts and Libs
import AppAPI from '../../lib/api';

// Components
import {Constants, Filters, Strings} from '../../constants';
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';

import {Alerts} from '../../components/ui';
import POSRoomListView from './components/POSRoomListView';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FilterButton from '../../components/dashboard/FilterButton';
import SelectorButton from '../../components/dashboard/SelectorButton';
import HousekeepingRoomListView from '../room/HousekeepingRoomListView';
import {faDoorOpen, faSync, faTable} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as POSActions from '../../redux/pos/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  posDetails: state.pos.posDetails,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPOSDetails: POSActions.setPOSDetails,
};

/* Component ==================================================================== */
class POSRoomList extends Component {
  static componentName = 'POSRoomList';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
  };

  fetchInitData = () => {
    this.setState({loading: true});
    this.props.setPOSDetails(this.props.match.params.POSId)
      .then(() => (
        this.setState({
          loading: false,
        })
      ))
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  }

  handleInputChange = (value) => {
    this.setState({value: value, searching: true});
    this.onChangeDebounced(value);
  };

  onChangeDebounced = (value) => {
    this.setState({searchText: value, searching: false});
  };


  render = () => {

    const {loading, error, resultMsg, tableViewType, roomStatusType, searchText, searching} = this.state;
    const {posDetails} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    let rooms = !_.isEmpty(posDetails.rooms) ? posDetails.rooms : [];

    if (roomStatusType === 'occupied') {
      rooms = rooms.filter(item => (item.availability_status === Constants.ROOM_STATUS.OCCUPIED));
    } else if (roomStatusType === 'empty') {
      rooms = rooms.filter(item => (item.availability_status === Constants.ROOM_STATUS.AVAILABLE));
    }

    if (searchText) {
      rooms = rooms.filter((el) => {
        let searchValue = el.name.toLowerCase();
        return searchValue.indexOf(searchText.toLowerCase()) !== -1;
      });
    }

    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Room(s) list</title>
        </Helmet>

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h4 className={'mb-4'}><FontAwesomeIcon icon={faTable} className={'mr-2'} size={'sm'}/> Table(s)</h4>

                <ul className="list-inline list-item-mb-1">
                  <li className="list-inline-item">
                    <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.fetchInitData()}>
                      <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                    </button>
                  </li>
                  <li className="list-inline-item">
                    <SelectorButton
                      title={'View Type'}
                      buttonList={Filters.tableViewSelector}
                      selectButtonKey={tableViewType}
                      action={(key) => this.setState({tableViewType: key})}
                    />
                  </li>
                  <li className="list-inline-item">
                    <FilterButton
                      title={'Room Status'}
                      data={Filters.roomStatusType}
                      selectKey={roomStatusType}
                      action={(data) => this.setState({roomStatusType: data.key})}
                      size={'sm'}
                    />
                  </li>
                </ul>

                <div className="form-row align-items-center mb-4">
                  <div className={searching ? 'col-sm-11' : 'col-sm-12'}>
                    <input
                      type="search"
                      className="form-control"
                      placeholder={'Search Room ( Room Name / Number )'}
                      name="q"
                      autoComplete={'off'}
                      onChange={(data) => this.handleInputChange(data.target.value)}
                    />
                  </div>
                  <div className="col-auto">
                    {searching && <i className="fa fa-spinner"/>}
                  </div>
                </div>

                {(tableViewType === 'grid' && !_.isEmpty(rooms)) ?
                  <div className={'row'}>
                    {rooms.map((data, i) => (
                      <div key={i} className={'col-sm-4 col-md-3 col-6 p-3'}>
                        <HousekeepingRoomListView
                          data={data}
                          serviceStatus={false}
                          action={false}
                        />
                      </div>
                    ))}
                  </div>
                  :
                  <React.Fragment>
                    <div className={'table-responsive mt-4'}>
                      <table className={'table border'}>
                        <thead className={'text-center'}>
                          <tr>
                            <th className={'data-table-cell-md'}>Room No</th>
                            <th className={'w-50'} colSpan={3}>Room Details</th>
                            <th className="data-table-cell-md center-text">Guest</th>
                            <th className={'data-table-cell-sm'}/>
                          </tr>
                        </thead>
                        <tbody>
                          {_.isEmpty(rooms) ?
                            <React.Fragment>
                              <tr>
                                <td colSpan={6}>
                                  <div className={'text-center table-list-empty'}>
                                    <h4><FontAwesomeIcon icon={faDoorOpen}/> No Active Bookings </h4>
                                    {Strings.posRoomListEmptyText.map((data, i) => <p key={i}>{data}</p>)}
                                  </div>
                                </td>
                              </tr>
                            </React.Fragment>
                            :
                            <React.Fragment>
                              {rooms.map((data, i) => {
                                return (
                                  <React.Fragment key={i}>
                                    <POSRoomListView data={data}/>
                                  </React.Fragment>
                                );
                              })}
                            </React.Fragment>
                          }
                        </tbody>
                      </table>
                    </div>
                  </React.Fragment>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.onChangeDebounced = _.debounce(this.onChangeDebounced, 10, {});

    this.state = {
      tableViewType: 'grid',
      error: false,
      loading: false,
      roomStatusType: 'occupied',
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(POSRoomList);
