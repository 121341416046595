/**
 *
 * Payment Analytics : Settlement Type
 */
import _ from 'lodash';
import Moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import PageHeader from '../../../components/dashboard/PageHeader';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import { AnalyticConstants, Constants } from '../../../constants';
// Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import PABifurcationTable from './components/PABifurcationTable';
import PABifurcationViewModel from './models/PABifurcationViewModel';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PaymentAnalyticsSettlement extends Component {
  static componentName = 'PaymentAnalyticsSettlement';

  static propTypes = {
    endDate: PropTypes.object,
    startDate: PropTypes.object
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {startDate, endDate, dateType} = this.props;
    this.setState({loading: true});
    const queryParams = {
      'date_type': dateType,
      'report_type': 'settlement_fee',
      'start_date': (startDate ? startDate : Moment()).format('YYYY-MM-DD'),
      'end_date': endDate ? endDate.format('YYYY-MM-DD') : null
    };
    AppAPI.paymentapi.get(`payment-analytics/?${queryString.stringify(queryParams)}`)
      .then((res) => {
        this.setState({loading: false, response: res});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({loading: false, error: error});
      });
  };

  render = () => {
    const {loading, error, response} = this.state;
    const {property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    // Initialize with empty array for order data set
    const paymentDataSet = {};
    Object.keys(AnalyticConstants.paymentAnalytics).forEach((data) => {
      paymentDataSet[data] = {};
    });

    const paymentData = {
      paymentSettlementFeeType: _.cloneDeep(paymentDataSet),
      paymentSettlementPaymentMethod: _.cloneDeep(paymentDataSet),
      paymentSettlementPaymentMethodFeeType: {}
    };

    Constants.PAYMENT_METHODS_INPUTS.forEach((data) => {
      paymentData.paymentSettlementPaymentMethodFeeType[data.value] = _.cloneDeep(paymentDataSet);
    });

    if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
      const responseData = response.data;
      // Payment Status
      if (!_.isEmpty(responseData.payment_paid_settled_fee_type_data)) {
        responseData.payment_paid_settled_fee_type_data.forEach((data) => {
          Object.entries(AnalyticConstants.paymentAnalytics).forEach(([key, value]) => {
            if (data[value]) {
              AppUtil.insertOrAddObject(paymentData.paymentSettlementFeeType[key], data.settlement_fee_type, data[value], 'float');
              AppUtil.insertOrAddObject(paymentData.paymentSettlementPaymentMethod[key], data.payment_method, data[value], 'float');
              AppUtil.insertOrAddObject(paymentData.paymentSettlementPaymentMethodFeeType[data.payment_method][key], data.settlement_fee_type, data[value], 'float');
            }
          });
        });
      }
    }


    const settlementPaymentPercentage = AppUtil.calculatePercentage(
      AppUtil.sumArray(Object.values(paymentData.paymentSettlementPaymentMethod.paymentSettled), 'float'),
      AppUtil.sumArray(Object.values(paymentData.paymentSettlementPaymentMethod.paymentTotal), 'float')
    );

    const settlementCharge = (
      AppUtil.sumArray(Object.values(paymentData.paymentSettlementPaymentMethod.paymentTotal), 'float') -
      AppUtil.sumArray(Object.values(paymentData.paymentSettlementPaymentMethod.paymentSettled), 'float')
    );

    const settlementChargeOnly = (
      settlementCharge -
      AppUtil.sumArray(Object.values(paymentData.paymentSettlementPaymentMethod.paymentSettledFeeTax), 'float')
    );

    const settlementChargePercentage = AppUtil.calculatePercentage(
      settlementChargeOnly, settlementCharge
    );

    return (
      <div className="screen-container">
        <Helmet>
          <title>Payment Terminal : Settlement Fee</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={'Payment Settlement'}
          description={'Payment settlement charge information with charge type & payment method bifurcation.'}
        />

        <div className={'row'}>
          <div className={'col-12 col-lg-6 mb-3'}>
            <div className={'card mb-3'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col '}>
                    <p className={'text-muted mb-1'}>Total Settled Payment(s)</p>
                    <h3 className={'card-title green-cl mb-0 pb-0'}>
                      {`${property.currency} ${AppUtil.sumArray(Object.values(paymentData.paymentSettlementPaymentMethod.paymentTotal), 'float')}`}
                    </h3>
                    <p className={'text-muted mb-1'}>
                      {AppUtil.sumArray(Object.values(paymentData.paymentSettlementFeeType.paymentCount), 'int')} settled
                      payment entries.
                    </p>
                  </div>
                </div>
                <hr/>
                <div className={'row'}>
                  <div className={'col '}>
                    <p className={'text-muted mb-1'}>Settled Payment</p>
                    <h5 className={'card-title mb-0 pb-0 green-cl'}>
                      {`${property.currency} ${AppUtil.sumArray(Object.values(paymentData.paymentSettlementPaymentMethod.paymentSettled), 'float')}`}
                    </h5>
                  </div>
                  <div className={'col'}>
                    <p className={'text-muted mb-1'}>Settlement Charge</p>
                    <h5 className={'card-title mb-0 pb-0 blue-cl'}>
                      {`${property.currency} ${settlementCharge.toFixed(2)}`}
                    </h5>
                  </div>
                </div>
                <div className="progress mt-3 blue-bg">
                  <div
                    className="progress-bar green-bg"
                    style={{width: `${settlementPaymentPercentage}%`}}
                    aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"/>
                </div>
                <div className={'row'}>
                  <div className={'col-8'}>
                    <p className={'text-muted mb-0 mt-1 small'}/>
                  </div>
                  <div className={'text-right col'}>
                    <p className={'text-muted mb-0 mt-1 small'}>{settlementPaymentPercentage}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'col-12 col-lg-6 mb-3'}>
            <div className={'card mb-3'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col '}>
                    <p className={'text-muted mb-1'}>Settlement Charge</p>
                    <h3 className={'card-title blue-cl mb-0 pb-0'}>
                      {`${property.currency} ${settlementCharge.toFixed(2)}`}
                    </h3>
                    <p className={'text-muted mb-1'}>
                      Total Settlement Fee or Charge inclusive of tax.
                    </p>
                  </div>
                </div>
                <hr/>
                <div className={'row'}>
                  <div className={'col '}>
                    <p className={'text-muted mb-1'}>Settlement Charge</p>
                    <h5 className={'card-title mb-0 pb-0 blue-cl'}>
                      {`${property.currency} ${settlementChargeOnly.toFixed(2)}`}
                    </h5>
                  </div>
                  <div className={'col'}>
                    <p className={'text-muted mb-1'}>Settlement Charge Tax</p>
                    <h5 className={'card-title mb-0 pb-0 orange-cl'}>
                      {`${property.currency} ${AppUtil.sumArray(Object.values(paymentData.paymentSettlementPaymentMethod.paymentSettledFeeTax), 'float')}`}
                    </h5>
                  </div>
                </div>
                <div className="progress mt-3 orange-bg">
                  <div
                    className="progress-bar blue-bg"
                    style={{width: `${settlementChargePercentage}%`}}
                    aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"/>
                </div>
                <div className={'row'}>
                  <div className={'col-8'}>
                    <p className={'text-muted mb-0 mt-1 small'}/>
                  </div>
                  <div className={'text-right col'}>
                    <p className={'text-muted mb-0 mt-1 small'}>{settlementChargePercentage}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <PABifurcationTable
          property={property}
          bifurcationTitle={'Settlement Fee Type'}
          paymentData={paymentData.paymentSettlementFeeType}
          bifurcationData={Constants.SETTLEMENT_FEE_TYPE_INPUT}
        />

        <PageHeader
          subHeader={true}
          title={'Settlement'}
          description={'Payment settlement bifurcation of payment method .'}
        />

        <PABifurcationTable
          property={property}
          bifurcationTitle={'Payment Method'}
          bifurcationData={Constants.PAYMENT_METHODS_INPUTS}
          paymentData={paymentData.paymentSettlementPaymentMethod}
          expandData={(data) => {
            this.setState({
              showPABifurcationViewModel: true,
              PABifurcationViewModelTitle: data.label,
              PABifurcationViewModelData: paymentData.paymentSettlementPaymentMethodFeeType[data.value]
            });
          }}
        />

        <PABifurcationViewModel
          property={property}
          bifurcationTitle={'Settlement Fee Type'}
          show={this.state.showPABifurcationViewModel}
          title={this.state.PABifurcationViewModelTitle}
          paymentData={this.state.PABifurcationViewModelData}
          bifurcationData={Constants.SETTLEMENT_FEE_TYPE_INPUT}
          onHide={() => this.setState({showPABifurcationViewModel: false, PABifurcationViewModelData: {}})}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      showPABifurcationViewModel: false,
      PABifurcationViewModelData: {},
      PABifurcationViewModelTitle: null,
      startDate: Moment().startOf('day'),
      endDate: Moment().add(30, 'days'),
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentAnalyticsSettlement);
