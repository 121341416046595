/**
 * Created by anush on 22/06/17.
 *
 * Combine All Reducers
 *
 */

import {combineReducers} from 'redux';

import user from './user/reducer';
import property from './property/reducer';
import expense from './expense/reducer';
import event from './event/reducer';
import roomType from './roomtype/reducer';
import pos from './pos/reducer';
import booking from './booking/reducer';
import bookingroom from './bookingroom/reducer';
import billing from './billing/reducer';
import order from './order/reducer';
import guest from './guest/reducer';
import payment from './payment/reducer';
import messaging from './messaging/reducer';
import room from './room/reducer';
import review from './review/reducer';
import notes from './notes/reducer';

// Combine all
const appReducer = combineReducers({
  user,
  property,
  roomType,
  expense,
  event,
  pos,
  booking,
  bookingroom,
  billing,
  order,
  room,
  messaging,
  guest,
  payment,
  notes,
  review,
});

// Setup root reducer
const rootReducer = (state, action) => {
  const newState = (action.type === 'RESET') ? undefined : state;
  return appReducer(newState, action);
};

export default rootReducer;

