/**
 *
 * Property Home
 */
import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Consts and Libs
import AppUtil from '../../../lib/util';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faBaby,
  faBed,
  faCheckCircle,
  faChild, faDoorClosed,
  faHome, faHourglass,
  faInfoCircle,
  faLock,
  faLockOpen,
  faSmoking,
  faSmokingBan, faToolbox,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { Img } from 'react-image';
import Loading from '../../../components/general/Loading';
import {Strings} from '../../../constants';

/* Component ==================================================================== */
class LookupResults extends Component {
  static componentName = 'BookingLookupResults';
  static propTypes = {
    lookupResponse: PropTypes.object.isRequired,
    action: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    if (this.props.lookupResponse) {
      this.calculateAvailabilityChart();
    }
  };

  finishBooking = () => {
    this.props.action(this.state.selectedChart);
  };

  calculateAvailabilityChart = () => {
    const {lookupResponse} = this.props;
    const availabilityChart = {};
    const selectedChart = {};


    if (!_.isEmpty(lookupResponse.results)) {
      lookupResponse.results.map(data => {
        let total_rooms = 0;
        let available_rooms = 0;
        let package_rooms = {};
        if (data.availability && data.availability.total_rooms) {
          total_rooms = data.availability.total_rooms;
          available_rooms = data.availability.available_rooms;
        }

        if (!_.isEmpty(data.packages.packages)) {
          data.packages.packages.map(packageData => (
            package_rooms[packageData.room_type_package] = {
              selected_rooms: 0,
              data: packageData,
            }
          ));
        }
        availabilityChart[data.room_type] = {
          total_rooms: total_rooms,
          available_rooms: available_rooms,
          selected_rooms: 0,
        };
        selectedChart[data.room_type] = {
          name: data.name,
          room_type: data.room_type,
          occupancy_data: data.packages.occupancy_data,
          package: package_rooms,
          total_rooms: total_rooms,
          available_rooms: available_rooms,
          selected_rooms: 0,
        };
        return true;
      });
      this.setState({
        availabilityChart: availabilityChart,
        selectedChart: selectedChart,
      });
    }

    return selectedChart;
  };

  updateIndex = (i, j, k) => {
    const {selectedChart, totalSelectedRoom, overBooking} = this.state;
    let totalSelectedRoomCount = totalSelectedRoom;
    if (i === 0) {
      // Subtract
      if (selectedChart[j].package[k].selected_rooms > 0) {
        selectedChart[j].selected_rooms =
          selectedChart[j].selected_rooms > 0
            ? selectedChart[j].selected_rooms - 1
            : 0;
        totalSelectedRoomCount =
          totalSelectedRoomCount > 0 ? totalSelectedRoom - 1 : 0;
      }

      selectedChart[j].package[k].selected_rooms =
        selectedChart[j].package[k].selected_rooms > 0
          ? selectedChart[j].package[k].selected_rooms - 1
          : 0;
    }

    if (i === 2) {
      // Add
      if (
        selectedChart[j].available_rooms > selectedChart[j].selected_rooms ||
        overBooking
      ) {
        selectedChart[j].package[k].selected_rooms =
          selectedChart[j].package[k].selected_rooms + 1;
        selectedChart[j].selected_rooms = selectedChart[j].selected_rooms + 1;
        totalSelectedRoomCount = totalSelectedRoomCount + 1;
      }
    }
    this.setState({
      selectedChart: selectedChart,
      totalSelectedRoom: totalSelectedRoomCount,
    });
  };

  selectRoom = (j, k) => {
    const selectedChart = this.calculateAvailabilityChart();
    const {overBooking} = this.state;
    if (
      selectedChart[j].available_rooms > selectedChart[j].selected_rooms ||
      overBooking
    ) {
      selectedChart[j].package[k].selected_rooms = 1;
      selectedChart[j].selected_rooms = 1;
      let totalSelectedRoomCount = 1;
      this.props.action(selectedChart);
      this.setState({
        selectedChart: selectedChart,
        totalSelectedRoom: totalSelectedRoomCount,
      });
    }
  };

  overBookingAction = () => {
    const {overBooking} = this.state;
    this.setState({
      overBooking: !overBooking,
    });
  };

  renderAvailabilityDataSet = (data) => (
    <div>
      {!_.isEmpty(data.availability.rooms_count) &&
      <React.Fragment>
        <p className={'mb-1'}>Room Data</p>
        <table className={'table small table-sm table-striped text-center rounded bg-white border'}>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon icon={faDoorClosed} size={'sm'} className={'mr-2'} />
                Total Rooms : {data.availability.rooms_count.rooms_total || 0}
              </td>
              <td>
                <FontAwesomeIcon icon={faToolbox} size={'sm'} className={'red-cl mr-2'} />
                Maintenance : {data.availability.rooms_count.rooms_maintenance || 0}
              </td>
              <td>
                <FontAwesomeIcon icon={faHourglass} size={'sm'} className={'yellow-cl mr-2'} />
                Buffer : {data.availability.rooms_count.rooms_buffer || 0}
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
      }
      <p className={'mb-1'}><strong>Availability Breakdown</strong></p>
      <table className={'table small table-sm table-striped text-center rounded bg-white border'}>
        <thead>
          <tr className={'tr-align-middle'}>
            <th>Date</th>
            <th>
              Booking
              <br/><small>(Provisional)</small>
            </th>
            <th>
              Booking
              <br/><small>(Confirmed)</small>
            </th>
            <th>Blocked</th>
            <th>Available</th>
          </tr>
        </thead>
        <tbody>
          {_.isEmpty(data.availability.availability_data_set) ?
            <React.Fragment>
              <tr>
                <td colSpan={5}>
                  <div className={'text-center py-3'}>
                    <p className={'mb-1'}>
                      <strong><FontAwesomeIcon icon={faCheckCircle} className={'green-cl'}/> Full Availability</strong>
                    </p>
                    {Strings.bookingLookupNoAvailabilityBlock.map((data, i) => <p className={'small'} key={i}>{data}</p>)}
                  </div>
                </td>
              </tr>
            </React.Fragment>
            :
            <React.Fragment>
              {data.availability.availability_data_set.map((availabilityData, j) => (
                <tr key={j}>
                  <td>{availabilityData.date}</td>
                  <td>{availabilityData.temporary_non_available}</td>
                  <td>{availabilityData.booked}</td>
                  <td>{availabilityData.all_channel_block || 0}</td>
                  <td>
                    {availabilityData.absolute_total ? availabilityData.available : (data.availability.total_rooms - availabilityData.booked - (availabilityData.all_channel_block || 0))}
                  </td>
                </tr>
              ))}
            </React.Fragment>
          }
        </tbody>
      </table>
    </div>
  );

  renderPricingDataSet = (packageData, lookupResponse) => (
    <div className={'row'}>
      <div className={'col-12'}>
        <p className={'mb-1'}>
          <strong>Price Breakdown</strong> <small>({lookupResponse.currency})</small>
        </p>
        <table className={'table border table-sm small table-striped text-center'}>
          <thead>
            <tr>
              <th scope="col">From</th>
              <th scope="col">To</th>
              <th scope="col">Night(s)</th>
              <th scope="col">Price/Night</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            {packageData.pricing_data_set.map((packagePriceDateData, k) => (
              <tr key={k}>
                <td>{packagePriceDateData.start_date}</td>
                <td>{packagePriceDateData.end_date}</td>
                <td>{packagePriceDateData.no_of_nights}</td>
                <td>{packagePriceDateData.base_price_effective}</td>
                <td>{packagePriceDateData.section_price_effective}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={'col-12'}>
        <p className={'mb-1'}><strong>Standard Price/Night</strong></p>
        <table
          className={'table border text-center table-sm small table-striped'}>
          <thead>
            <tr>
              <th scope="w-25">Guest</th>
              <th scope="w-25">Additional</th>
              <th scope="w-25">Child</th>
              <th scope="w-25">Infant</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{lookupResponse.currency} {packageData.data.base_price_effective}</td>
              <td>{lookupResponse.currency} {packageData.data.guest_price_effective}</td>
              <td>{lookupResponse.currency} {packageData.data.child_price_effective}</td>
              <td>{lookupResponse.currency} {packageData.data.infant_price_effective}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );

  renderMessages = (messages) => (
    <div className="alert alert-primary my-2" role="alert">
      <ul className={'mb-0 pl-2'}>
        {messages.map((msgData, i) => (
          <li key={i}>{msgData}</li>
        ))}
      </ul>
    </div>
  );

  renderRoomTypeData = (data) => (
    <div className={'mb-3'}>
      <ul className={'list-inline'}>
        <li className={'list-inline-item'}>
          <p className="mb-0 small">
            <FontAwesomeIcon
              icon={faUsers} size={'sm'} className={'mr-1'}/> Capacity :
            <FontAwesomeIcon
              icon={faUser} size={'sm'}
              className={'ml-2'}/> {data.packages.occupancy_data.base_occupancy}
            <FontAwesomeIcon
              icon={faChild} size={'sm'}
              className={'ml-2'}/> {data.packages.occupancy_data.child_occupancy}
            <FontAwesomeIcon
              icon={faBaby} size={'sm'}
              className={'ml-2'}/> {data.packages.occupancy_data.infant_occupancy}
          </p>
        </li>
      </ul>

      <ul className={'list-inline'}>
        {data.data.smoking_type !== 0 &&
        <li className={'list-inline-item'}>
          <p className={'mb-0 small'}>
            <FontAwesomeIcon
              icon={data.data.smoking_type === 2 ? faSmoking : faSmokingBan}
              className={'mr-1'}/>{data.data.smoking_type_display}
          </p>
        </li>
        }
        {data.data.view_type !== 0 &&
        <li className={'list-inline-item'}>
          <p className={'mb-0 small'}>
            <FontAwesomeIcon icon={faHome} className={'mr-1'}/>{data.data.view_type_display}
          </p>
        </li>
        }
        {data.data.bed_type !== 0 &&
        <li className={'list-inline-item'}>
          <p className={'mb-0 small'}>
            <FontAwesomeIcon icon={faBed} className={'mr-1'}/>{data.data.bed_type_display}
          </p>
        </li>
        }
      </ul>
    </div>
  )

  render = () => {
    const {lookupResponse} = this.props;
    const {selectedChart, totalSelectedRoom, overBooking} = this.state;
    return (
      <div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12-sm">
            {!_.isEmpty(lookupResponse.results) &&
             <ul className={'list-unstyled'}>
               {lookupResponse.results.map((data, i) => (
                 <li key={i} className={'mb-3 border rounded p-3 cream-bg'}>
                   <div className={'row mb-2'}>
                     <div className={'col-4'}>
                       {!_.isEmpty(data.data.room_type_cover_image) ?
                         <Img
                           className="w-100"
                           src={data.data.room_type_cover_image.cover_sm}
                           loader={<Loading heightMatch={false}/>}
                         />
                         :
                         <img
                           alt={'Empty Cover'} className={'bg-dark w-100'}
                           src={require('../../../images/resources/place-holder-bg.svg')}
                         />
                       }
                     </div>
                     <div className={'col-8'}>
                       {selectedChart && Object.keys(selectedChart).length > 0 && selectedChart[data.room_type].selected_rooms > 0 &&
                        <span
                          className="badge badge-pill badge-success float-right">Selected : {selectedChart[data.room_type].selected_rooms}</span>
                       }
                       <h5>{data.name}</h5>
                       <ul className={'list-inline'}>
                         <li className={'list-inline-item'}>
                           <p
                             className={`mb-0 small ${AppUtil.availabilityStatusColor(data.availability.availability_percentage)}`}
                           >
                             Availability : {data.availability.available_rooms} / {data.availability.total_rooms}
                           </p>
                         </li>
                         {!_.isEmpty(data.availability.rooms_count) &&
                         <React.Fragment>
                           {parseInt(data.availability.rooms_count.rooms_maintenance) > 0 &&
                             <li className={'list-inline-item'}>
                               <p className={'mb-0 small red-cl'}>
                                 | <FontAwesomeIcon
                                   icon={faToolbox} size={'sm'}
                                   className={'red-cl mx-2'}
                                 />
                                 Maintenance : {data.availability.rooms_count.rooms_maintenance}
                               </p>
                             </li>
                           }
                           {parseInt(data.availability.rooms_count.rooms_buffer) > 0 &&
                           <li className={'list-inline-item'}>
                             <p className={'mb-0 small yellow-cl'}>
                               | <FontAwesomeIcon
                                 icon={faHourglass} size={'sm'}
                                 className={'yellow-cl mx-2'}
                               />
                               Buffer : {data.availability.rooms_count.rooms_buffer}
                             </p>
                           </li>
                           }
                         </React.Fragment>
                         }
                       </ul>
                       {this.renderRoomTypeData(data)}
                       <div className="btn-group w-100" role="group">
                         <button
                           className={'btn btn-outline-secondary btn-sm btn-block p-1 my-1'}
                           data-toggle="collapse"
                           data-target={'#iddescritption' + String(i)} aria-expanded="false"
                           aria-controls={'#iddescritption' + String(i)}>
                           More Information
                         </button>
                         <button
                           className={'btn btn-outline-secondary btn-sm btn-block p-1 my-1'}
                           data-toggle="collapse"
                           data-target={'#idavailabilitybreakdown' + String(i)} aria-expanded="false"
                           aria-controls={'#idavailabilitybreakdown' + String(i)}>
                           Availability Breakdown
                         </button>
                       </div>
                     </div>
                   </div>
                   <div className={'row collapse'} id={'iddescritption' + String(i)}>
                     <div className={'col-12'}>
                       <p className={'mb-1'}><strong>Description</strong></p>
                       <p className={'small'}>{data.data.description || '....'}</p>
                     </div>
                   </div>
                   {!_.isEmpty(data.availability.messages) && this.renderMessages(data.availability.messages)}

                   <div className={'collapse'} id={'idavailabilitybreakdown' + String(i)}>
                     {this.renderAvailabilityDataSet(data)}
                   </div>

                   {!_.isEmpty(data.packages) &&
                    <div>
                      <p className={'mb-1'}><strong>Packages</strong></p>
                      {!_.isEmpty(data.packages.packages) ?
                        <ul className="list-group list-group-sm">
                          {data.packages.packages.map((packageData, j) => (
                            <li className="list-group-item bg-white" key={j}>
                              <div className={'row'}>
                                <div className={'col-8'}>
                                  <h6>{packageData.data.name}</h6>
                                  {packageData.data.cancellation_policy_display &&
                                   <p className={'mb-0 small'}>
                                     Cancellation Policy : {packageData.data.cancellation_policy_display}
                                   </p>
                                  }
                                  <ul className={'list-inline'}>
                                    {packageData.data.inclusion_break_fast &&
                                     <li className={'list-inline-item'}>
                                       <p className={'mb-0 small'}>
                                         <FontAwesomeIcon icon={faCheckCircle} className={'green-cl mr-1'}/>
                                         Breakfast Inclusive
                                       </p>
                                     </li>
                                    }

                                    {packageData.data.inclusion_lunch &&
                                     <li className={'list-inline-item'}>
                                       <p className={'mb-0 small'}>
                                         <FontAwesomeIcon icon={faCheckCircle} className={'green-cl mr-1'}/>
                                         Lunch Inclusive
                                       </p>
                                     </li>
                                    }

                                    {packageData.data.inclusion_dinner &&
                                     <li className={'list-inline-item'}>
                                       <p className={'mb-0 small'}>
                                         <FontAwesomeIcon icon={faCheckCircle} className={'green-cl mr-1'}/>
                                         Dinner Inclusive
                                       </p>
                                     </li>
                                    }
                                  </ul>
                                </div>
                                <div className={'col-4 text-right'}>
                                  <p className={'mb-0'}>
                                    <strong>{lookupResponse.currency} {packageData.base_price_effective_total}</strong>
                                  </p>
                                  <p className={'small'}>
                                    for {packageData.no_of_nights} night(s)
                                    <span
                                      className={'ml-1 a'}
                                      data-toggle="collapse"
                                      data-target={'#idpricebreakdown' + String(i) + String(j)} aria-expanded="false"
                                      aria-controls={'#idpricebreakdown' + String(i) + String(j)}>
                                      <FontAwesomeIcon icon={faInfoCircle} size={'sm'}/>
                                    </span></p>
                                  {packageData.block ?
                                    <button disabled={true} className={'btn btn-sm btn-block btn-warning'}>
                                      Not Available
                                    </button>
                                    :
                                    <div className={''}>
                                      {lookupResponse.single_select ?
                                        <React.Fragment>
                                          {!_.isEmpty(selectedChart) && selectedChart[data.room_type].package[packageData.room_type_package].selected_rooms > 0 ?
                                            <button className={'btn btn-sm btn-block btn-warning'}>Selected</button>
                                            :
                                            <button
                                              disabled={parseInt(data.availability.available_rooms) < 1 && !overBooking}
                                              className={'btn btn-sm btn-block btn-success'}
                                              onClick={() => this.selectRoom(data.room_type, packageData.room_type_package)}
                                            >Select</button>
                                          }
                                        </React.Fragment>
                                        :
                                        <div>
                                          <div className="btn-group btn-group-sm" role="group">
                                            <button
                                              type="button" className="btn border red-cl"
                                              onClick={() => this.updateIndex(0, data.room_type, packageData.room_type_package)}>-
                                            </button>
                                            <button type="button" className="btn border">
                                              {!_.isEmpty(selectedChart) ?
                                                selectedChart[data.room_type].package[packageData.room_type_package].selected_rooms
                                                : 0
                                              }
                                            </button>
                                            <button
                                              type="button" className="btn border green-cl"
                                              onClick={() => this.updateIndex(2, data.room_type, packageData.room_type_package)}>+
                                            </button>

                                          </div>
                                        </div>
                                      }
                                    </div>
                                  }
                                </div>
                              </div>
                              {!_.isEmpty(packageData.messages) && this.renderMessages(packageData.messages)}

                              {!_.isEmpty(packageData.pricing_data_set) &&
                               <div className={'collapse'} id={'idpricebreakdown' + String(i) + String(j)}>
                                 <hr/>
                                 {this.renderPricingDataSet(packageData, lookupResponse)}
                               </div>
                              }
                            </li>
                          ))}
                        </ul>
                        :
                        <div className={'border rounded p-2 bg-white'}>
                          <div className={'col-md-12 p-lg-2 text-center'}>
                            <h4><span className={'fa fa-times-circle fa-1x red-cl'}/> No Packages !</h4>
                            <p className={'text-muted small'}>No price configured for {data.name}.</p>
                          </div>
                        </div>
                      }
                    </div>
                   }
                   {!lookupResponse.single_select &&
                    <div className="mt-3">
                      {totalSelectedRoom > 0 ?
                        <button className={'btn btn-block btn-lg btn-success'} onClick={() => this.finishBooking()}>
                          <h5 className={'my-0 mt-1'}>
                            Book {totalSelectedRoom} {totalSelectedRoom > 1 ? 'Rooms' : 'Room'}
                            <FontAwesomeIcon icon={faArrowRight} className={'mx-2'} size={'sm'}/>
                          </h5>
                        </button>
                        :
                        <button className={'btn btn-block btn-lg btn-success'} disabled={true}>
                          <h5 className={'my-0 mt-1'}>Select Room to Proceed.</h5>
                        </button>
                      }
                    </div>
                   }
                 </li>
               ))}
             </ul>
            }
          </div>

          <div className={'mt-3 text-center'}>
            <button className={'btn btn-link'} disabled={overBooking} onClick={() => this.overBookingAction()}>
              <FontAwesomeIcon className={'white-cl'} icon={overBooking ? faLockOpen : faLock} size={'sm'}/>
              {overBooking ? 'Over Booking Open' : 'Unlock Over Booking'}
            </button>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      selectedRoom: [],
      selectedChart: [],
      availabilityChart: [],
      totalSelectedRoom: 0,
      overBooking: false,
    };
  }
}

export default LookupResults;
