/**
 *  POS Category Manage
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Actions
import * as POSActions from '../../../redux/pos/actions';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants, ErrorMessages, Strings} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faSave} from '@fortawesome/free-solid-svg-icons';
// Form
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Form, Input, SubmitBtn, Textarea, Checkbox} from 'react-formik-ui';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pos: state.pos.pos,
  category: state.pos.category,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPOSCategory: POSActions.setPOSCategory,
};

/* Component ==================================================================== */

class RoomServiceItemManage extends Component {
  static componentName = 'RoomServiceItemManage';

  static propTypes = {
    serviceType: PropTypes.object
  };

  static defaultProps = {
    serviceType: Constants.ROOM_SERVICE_TYPE.SERVICE
  }

  componentDidMount = () => {
    this.fetchInitData();
  };

  addServiceItem = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.roomapi.post('room-service-item-settings/',
          {
            'name': credentials.name,
            'description': credentials.description,
            'default_quantity': credentials.default_quantity,
            'auto_select': credentials.auto_select,
            'active': credentials.active,
            'service_type': this.props.serviceType,
          })
          .then(() => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.history.goBack();
                  // this.props.history.replace(this.props.location.pathname.replace(/[^/]*$/, `${res.hash}/`));
                }, 500);
              },
            );
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  saveServiceItem = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.roomapi.patch(`room-service-item-settings/${this.props.match.params.roomServiceItemId}/`,
          {
            'name': credentials.name,
            'description': credentials.description,
            'default_quantity': credentials.default_quantity,
            'auto_select': credentials.auto_select,
            'active': credentials.active,
          })
          .then(() => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.history.goBack();
                }, 500);
              },
            );
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  fetchInitData = () => {
    let initialValues = {
      'name': '',
      'short_description': '',
      'description': '',
      'active': true,
    };
    let formValidation = Yup.object().shape({
      name: Yup.string().min(2, 'Too Short!').max(200, 'Too long').required('Required'),
    });

    if (this.props.match.params.roomServiceItemId) {
      this.setState({loading: true});
      AppAPI.roomapi.get('room-service-item-settings/' + this.props.match.params.roomServiceItemId + '/')
        .then((res) => {
          initialValues = {
            'name': res.name,
            'description': res.description,
            'default_quantity': res.default_quantity,
            'auto_select': res.auto_select,
            'active': res.active,
          };
          this.setState({
            initialValues,
            formValidation,
            loading: false,
          });
        });
    } else {
      this.setState({
        initialValues,
        formValidation,
        loading: false,
      });
    }
  };

  render = () => {

    const {loading, error, initialValues, formValidation, resultMsg} = this.state;
    const {serviceType} = this.props;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }
    let title;
    switch (serviceType) {
    case Constants.ROOM_SERVICE_TYPE.MAINTENANCE:
      title = 'Maintenance';
      break;

    case Constants.ROOM_SERVICE_TYPE.AMENITY:
      title = 'Amenity';
      break;

    case Constants.ROOM_SERVICE_TYPE.SERVICE:
    default:
      title = 'Service';
    }

    return (

      <div className="room-type-list-manage screen-container ">
        <Helmet>
          <title>Room Service Item</title>
        </Helmet>
        <PageHeader
          subHeader={true} history={this.props.history}
          title={`${this.props.match.params.roomServiceItemId ? 'Manage' : 'Add'} ${title} Item`}
        />

        <Formik
          onSubmit={(values) => this.props.match.params.roomServiceItemId ? this.saveServiceItem(values) : this.addServiceItem(values)}
          validationSchema={formValidation}
          initialValues={initialValues}>
          {() => (
            <Form>
              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      {title} Information
                    </h6>
                  </div>
                  <div className={'border p-2'}>
                    <div className="form-row">
                      <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                        <Input
                          required
                          name='name'
                          label={`${title} Name`}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-12 form-group">
                        <Textarea
                          name='description'
                          label={'Description'}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                  <p className={''}><strong>Name : </strong> {Strings.serviceTitle}</p>
                </div>
              </div>


              {([Constants.ROOM_SERVICE_TYPE.AMENITY, Constants.ROOM_SERVICE_TYPE.SERVICE].includes(serviceType)) &&
                <div className={'row'}>
                  <div className="col-lg-8 col-md-8 col-sm-12">
                    <div className={'border mt-4 p-2 text-center anchor w-100'}>
                      <h6 className={'mb-0'}>
                        Selection Information
                      </h6>
                    </div>
                    <div className={'border p-2'}>
                      <div className="form-row">
                        <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                          <Checkbox
                            name='auto_select'
                            text={' Auto Select'}
                            className={'mr-2'}
                            hint={'Check to make the service auto select during service request.'}
                          />
                        </div>
                      </div>
                      {serviceType === Constants.ROOM_SERVICE_TYPE.AMENITY &&
                      <div className="form-row">
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                          <Input
                            name='default_quantity'
                            label={'Default Quantity'}
                            className={'form-control'}
                          />
                        </div>
                      </div>
                      }
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                    <p className={''}><strong>Auto Select : </strong> {Strings.serviceAutoSelect}</p>
                    {serviceType === Constants.ROOM_SERVICE_TYPE.AMENITY &&
                      <p className={''}><strong>Default Value : </strong> {Strings.serviceDefaultQuantity}</p>
                    }
                  </div>
                </div>
              }


              <div className={'row'}>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      {title} Status
                    </h6>
                  </div>
                  <div className={'border p-2'} id={'primaryAction'}>
                    <div className="form-row">
                      <div className="col-6 form-group">
                        <Checkbox
                          className={'mr-2'}
                          name='active'
                          label={`${title} Status`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 mt-2">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn className={'btn btn-success btn-block btn-lg mt-2'}>
                    <FontAwesomeIcon
                      className={'white-cl mr-2'}
                      icon={this.props.match.params.roomServiceItemId ? faSave : faPlus} size={'sm'}
                    /> {this.props.match.params.roomServiceItemId ? 'Save' : 'Add'}
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>


      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      initialValues: {},
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(RoomServiceItemManage);
