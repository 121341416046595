/**
 * Availability details
 *  availability details
 */
import PropTypes from 'prop-types';
import React, {Component} from 'react';
// Consts and Libs
import Strings from '../../../constants/strings';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {confirmAlert} from '../../../components/general/ConfirmAlert';
import {faToggleOff, faToggleOn} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */

class RateDateConstrainsView extends Component {
  static componentName = 'RateDateConstrainsView';

  static propTypes = {
    rateDateEdit: PropTypes.func,
    rateDateDetails: PropTypes.object.isRequired,
    distributionSettings: PropTypes.object.isRequired,
  };


  printRestrictionStatus = (status) => {
    return (
      <React.Fragment>
        <FontAwesomeIcon
          icon={status ? faToggleOn : faToggleOff}
          className={status ? 'green-cl h5 m-0' : 'grey-cl h5 m-0'}
        />
      </React.Fragment>
    );
  };

  onClickHandler = (status, updateKey, channel) => {
    const payload = {};
    payload[`${channel.key}_${updateKey}`] = !status;

    let enableMessage, disableMessage, constrainName;
    switch (updateKey) {
    case 'closed_for_checkin':
      constrainName = 'Closed for Checkin';
      enableMessage = Strings.constrainCTA;
      disableMessage = 'Removes close to arrival constrain.';
      break;
    case 'closed_for_checkout':
      constrainName = 'Closed for Checkout';
      enableMessage = Strings.constrainCTD;
      disableMessage = 'Removes close to departure constrain.';
      break;
    case 'restriction':
      constrainName = 'Stop Sell';
      enableMessage = Strings.constrainStopSell;
      disableMessage = 'Remove constrain and sell inventory.';
      break;
    default:
      constrainName = 'Constrain';
      enableMessage = 'Proceed with to enable the functions.';
      disableMessage = 'Proceed to disable the functions';
    }

    confirmAlert({
      title: `${status ? 'Disable' : ' Enable'} ${constrainName}`,
      message: status ? disableMessage : enableMessage,
      buttons: [
        {
          className: 'btn-secondary',
          label: 'Exit',
        },
        {
          className: (status ? 'btn-danger' : ' btn-success'),
          label: 'Proceed',
          onClick: () => this.props.updateRateDate(payload),
        },
      ],
    });
  }


  render = () => {
    // rateDateDetails is used in evan
    // eslint-disable-next-line no-unused-vars
    const {rateDateDetails} = this.props;
    const distributionChannels = [
      {
        'name': 'Booking Engine',
        'key': 'booking_engine',
      },
      {
        'name': 'Channel Manager',
        'key': 'channel_manager',
      },
      {
        'name': 'Internal Engine',
        'key': 'internal_engine',
      },
    ];

    return (
      <div>
        <div className={'row'}>
          <div className={'col-sm-12 col-md-7'}>
            <table className={'table table-hover table-bordered text-center'}>
              <thead>
                <tr className={'bg-dark text-light'}>
                  <th className={'data-table-cell-lg'}>Constrains</th>
                  <th>Booking Engine</th>
                  <th>Channel Manager</th>
                  <th>Internal Engine</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={'bg-light text-left'}>Stop Sell</td>
                  {distributionChannels.map((data, i) => (
                    <td
                      key={i} className={`${data.key}-box a-class`}
                      onClick={() => this.onClickHandler(rateDateDetails[`${data.key}_restriction`], 'restriction', data)}
                    >
                      {this.printRestrictionStatus(rateDateDetails[`${data.key}_restriction`])}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className={'bg-light text-left'}>Arrival Closed</td>
                  {distributionChannels.map((data, i) => (
                    <td
                      key={i} className={`${data.key}-box a-class`}
                      onClick={() => this.onClickHandler(rateDateDetails[`${data.key}_closed_for_checkin`], 'closed_for_checkin', data)}
                    >
                      {this.printRestrictionStatus(rateDateDetails[`${data.key}_closed_for_checkin`])}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className={'bg-light text-left'}>Departure Closed</td>
                  {distributionChannels.map((data, i) => (
                    <td
                      key={i} className={`${data.key}-box a-class`}
                      onClick={() => this.onClickHandler(rateDateDetails[`${data.key}_closed_for_checkout`], 'closed_for_checkout', data)}
                    >
                      {this.printRestrictionStatus(rateDateDetails[`${data.key}_closed_for_checkout`])}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className={'bg-light text-left'}>Min Length of Stay</td>
                  {distributionChannels.map((data, i) => (
                    <td key={i} className={'a-class'} onClick={() => this.props.rateDateEdit('minLengthOfStay', data.key)}>
                      {rateDateDetails[`${data.key}_min_length_of_stay`] ? rateDateDetails[`${data.key}_min_length_of_stay`] : '...'}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className={'bg-light text-left'}>Max Length of Stay</td>
                  {distributionChannels.map((data, i) => (
                    <td key={i} className={'a-class'} onClick={() => this.props.rateDateEdit('maxLengthOfStay', data.key)}>
                      {rateDateDetails[`${data.key}_max_length_of_stay`] ? rateDateDetails[`${data.key}_max_length_of_stay`] : '...'}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
          <div className={'col-sm-12 col-md-5'}>
            <p className={'small'}><strong>Stop Sell</strong> : {Strings.constrainStopSell}</p>
            <p className={'small'}><strong>Arrival Closed</strong> : {Strings.constrainCTA}</p>
            <p className={'small'}><strong>Departure Closed</strong> : {Strings.constrainCTD}</p>
            <p className={'small'}><strong>Min Length of Stay</strong> : {Strings.constrainMINStay}</p>
            <p className={'small'}><strong>Max Length of Stay</strong> : {Strings.constrainMAXStay}</p>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: true,
      availabilityChartData: [],
    };
  }
}

/* Export Component ==================================================================== */
export default RateDateConstrainsView;
