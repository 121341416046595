/**
 * Guest Profile Edit
 * Only essentials like email, phone, name
 */
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import React, {Component} from 'react';
// Consts and Libs
import AppAPI from '../../../lib/api';
import {Constants, SelectList, Strings} from '../../../constants';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faAddressCard, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

import {Alerts} from '../../../components/ui';

import * as Yup from 'yup';
import {Form, Formik} from 'formik';
import { Datepicker, Input, Select } from 'react-formik-ui';

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

/* Component ==================================================================== */

class ProfileDetailsEditShort extends Component {
  static componentName = 'ProfileDetailsEditShort';

  static propTypes = {
    guest: PropTypes.object,
    expandEdit: PropTypes.bool,
    property: PropTypes.object,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        expandEdit: nextProps.expandEdit || false,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  saveGuest = (credentials) => {
    if (credentials) {
      this.setState({resultMsg: {status: 'One moment...'}});
      AppAPI.guest.patch(this.props.guest.hash + '/',
        {
          'email': credentials.email,
          'phone': credentials.phone ? `${credentials.code}${credentials.phone}` : '',
          'salutation': credentials.salutation,
          'first_name': credentials.first_name,
          'last_name': credentials.last_name,

          'address_1': credentials.address_1,
          'address_2': credentials.address_2,
          'state': credentials.state,
          'country': credentials.country ? credentials.country : '',
          'pin_code': credentials.pin_code,

          'age': credentials.age ? credentials.age : null,
          'date_of_birth': credentials.date_of_birth ? moment(credentials.date_of_birth).format('YYYY-MM-DD') : null,

          'gender': credentials.gender ?  credentials.gender : null,
          'nationality': credentials.nationality ? credentials.nationality : '',

        })
        .then(() => {
          this.props.updateResponse();
          this.setState({resultMsg: {success: 'Success'}},
            () => {
              setTimeout(() => {
                this.props.onHide();
              }, 500);
            });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };


  render = () => {

    const {resultMsg, expandEdit} = this.state;
    const {show, guest, property} = this.props;

    if (!show) return null;


    let required_profile_fields = [];
    const validation = {
      first_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
      email: Yup.string().email('Invalid email'),
      phone: Yup.number(),
      age: Yup.number().min(1, 'Min age is 1').max(120, 'Max age is 120').nullable(),
    };

    if (!_.isEmpty(property.config) && property.config.profile) {
      const profile_fields = property.config.profile.profile_advance_fields;
      if (!_.isEmpty(profile_fields) && !_.isEmpty(profile_fields.required_profile_fields)) {
        required_profile_fields = profile_fields.required_profile_fields;
        if (required_profile_fields.includes('age')){
          validation['age'] = Yup.number().min(1, 'Min age is 1').max(120, 'Max age is 120').required('Required');
        }
        if (required_profile_fields.includes('gender')) {
          validation['gender'] = Yup.string().required('Required');
        }
        if (required_profile_fields.includes('address_1')) {
          validation['address_1'] = Yup.string().required('Required');
        }
        if (required_profile_fields.includes('address_2')) {
          validation['address_2'] = Yup.string().required('Required');
        }
        if (required_profile_fields.includes('nationality')) {
          validation['nationality'] = Yup.string().required('Required');
        }
        if (required_profile_fields.includes('country')) {
          validation['country'] = Yup.string().required('Required');
        }
        if (required_profile_fields.includes('state')) {
          validation['state'] = Yup.string().required('Required');
        }
      }
    }

    const formValidation = Yup.object().shape(validation);

    const defaultCode = property && property.phone_number_code ? property.phone_number_code : '';
    const defaultCountry = property && property.country ? property.country.code : '';
    let number;
    try {
      number = phoneUtil.parse(guest.phone) ? phoneUtil.parseAndKeepRawInput(guest.phone) : null;
    } catch (e) {
      number = null;
    }


    return (
      <Modal
        {...this.props}
        size={expandEdit ? 'lg' : 'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Details
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            salutation: guest.salutation,
            first_name: guest.first_name || '',
            last_name: guest.last_name || '',
            email: guest.email || '',
            code: number ? `+${String(number.getCountryCode())}` : defaultCode,
            phone: number ? number.getNationalNumber() : '',

            address_1: guest.address_1 || '',
            address_2: guest.address_2 || '',
            state: guest.state || '',
            country: guest.country ? guest.country.code : defaultCountry,
            pin_code: guest.pin_code,

            age: guest.age || '',
            date_of_birth: guest.date_of_birth,

            gender: guest.gender || '',
            nationality: guest.nationality ? guest.nationality.code : defaultCountry,

          }}
          validationSchema={formValidation}
          onSubmit={values => this.saveGuest(values)}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Body>
                <Form className={'form-group'}>
                  <div className={'row'}>
                    <div className={expandEdit ? 'col-12 col-md-6' : 'col-12'}>
                      <div className="form-row">
                        <div className="col-md-7 form-group">
                          <div className={'form-row'}>
                            <div className="col-4 ">
                              <Select
                                name="salutation"
                                label={'Salutation'}
                                className={'form-control'}
                                placeholder="Select an Option"
                                options={Constants.SALUTATION}
                              />
                            </div>
                            <div className="col-8">
                              <Input
                                name="first_name"
                                label={'First Name'}
                                className={'form-control'}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5 form-group">
                          <Input
                            name="last_name"
                            label={'Last Name'}
                            className={'form-control'}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-lg-6 col-md-6 form-group">
                          <Input
                            name="email"
                            label={'E-mail'}
                            className={'form-control'}
                            type={'email'}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 ">
                          <div className={'row'}>
                            <div className={'col-4 pr-0 form-group'}>
                              <Select
                                className={'form-control rounded-right-0'}
                                name="code"
                                label={'Country'}
                                placeholder="Select an Option"
                                options={SelectList.phoneCountryCode()}
                              />
                            </div>
                            <div className={'col-8 pl-0 form-group'}>
                              <Input
                                name="phone"
                                label={'Phone'}
                                className={'form-control rounded-left-0'}
                                type={'tel'}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className={'text-muted mb-3 small'}>{Strings.profileEditShort}</p>
                      <div className={'text-center'}>
                        <button
                          type={'button'}
                          disabled={expandEdit}
                          className={'btn btn-outline-secondary'}
                          onClick={()=>this.setState({expandEdit: true})}
                        >
                          <FontAwesomeIcon icon={faAddressCard} className={'mr-2'}/> Fill Guest Address Information
                        </button>
                      </div>
                    </div>
                    {expandEdit && (
                      <div className={'col-12 col-md-6 border-left'}>
                        <div className="form-row">
                          <div className="col-6 form-group">
                            <Input
                              name='address_1'
                              label={'Address Line 1'}
                              className={'form-control'}
                              placeholder={'House No / Name'}
                              required={required_profile_fields.includes('address_1')}
                            />
                          </div>
                          <div className="col-6 form-group">
                            <Input
                              name='address_2'
                              label={'Address Line 2'}
                              className={'form-control'}
                              placeholder={'District'}
                              required={required_profile_fields.includes('address_2')}
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-lg-4 col-md-4 col-sm-6 form-group">
                            {formikProps.values.country && !_.isEmpty(SelectList.stateList(formikProps.values.country))
                              ? (
                                <Select
                                  className={'form-control rounded-right-0'}
                                  name='state'
                                  label={'State'}
                                  placeholder='Select an Option'
                                  required={required_profile_fields.includes('state')}
                                  options={SelectList.stateList(formikProps.values.country)}
                                />
                              ) : (
                                <Input
                                  name='state'
                                  label={'State'}
                                  className={'form-control'}
                                  disabled={!formikProps.values.country}
                                />
                              )}
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-6 form-group">
                            <Select
                              className={'form-control rounded-right-0'}
                              name='country'
                              label={'Country'}
                              placeholder='Select an Option'
                              options={SelectList.countryList()}
                              required={required_profile_fields.includes('country')}
                            />
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-6 form-group">
                            <Input
                              name='pin_code'
                              label={'Pin Code'}
                              className={'form-control'}
                              type={'number'}
                            />
                          </div>
                        </div>
                        <hr/>
                        <div className={'form-row'}>
                          <div className="col-6 form-group">
                            <Select
                              className={'form-control rounded-right-0'}
                              name='gender'
                              label={'Gender'}
                              placeholder='Select an Option'
                              options={Constants.GENDER}
                              required={required_profile_fields.includes('gender')}
                            />
                          </div>
                          <div className="col-6 form-group">
                            <Select
                              className={'form-control rounded-right-0'}
                              name='nationality'
                              label={'Nationality'}
                              placeholder='Select an Option'
                              required={required_profile_fields.includes('nationality')}
                              options={SelectList.countryList()}
                            />
                          </div>
                        </div>
                        {(!_.isEmpty(property.config) && property.config.profile && property.config.profile.profile_advance_details) && (
                          <>
                            <hr/>
                            <div className={'form-row'}>
                              <div className="col-6 form-group">
                                <Input
                                  name='age'
                                  label={'Age'}
                                  className={'form-control'}
                                  required={required_profile_fields.includes('age')}
                                />
                              </div>
                              <div className="col-6 form-group">
                                <p className={'mb-1'}>Date of Birth</p>
                                <Datepicker
                                  name='date_of_birth'
                                  maxDate={moment.now()}
                                  className={'form-control'}
                                  hint={'Date format DD/MM/YYYY'}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer className={'d-block'}>
                <div className={'px-3'}>
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
                    </button>
                  </div>
                  <div className={'col'}>
                    <button
                      type="submit" onClick={formikProps.handleSubmit} className={'btn btn-success btn-lg btn-block'}>
                      <FontAwesomeIcon className={'white-cl mr-2'} icon={faSave} size={'sm'}/> Save
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      expandEdit: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default ProfileDetailsEditShort;
