/**
 *
 * Event Inventory Links
 * Houses sections related to event inventory
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link, NavLink, Switch, Route} from 'react-router-dom';
// Consts and Libs
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
// Components
import EventDetails from './EventDetails';
import EventManage from './EventManage';
import TicketList from './TicketList';
import TicketManage from './TicketManage';
import TicketDetails from './TicketDetails';
import EventBookingEngine from './bookingEngine';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  event: state.event.event,
});

// Any actions to map to the component?
const mapDispatchToProps = {
};

/* Component ==================================================================== */
class EventInventory extends Component {
  static componentName = 'EventInventory';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    if (this.props.match.params.eventId) {
      this.setState({
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };


  render = () => {

    const {loading, error} = this.state;
    const {event} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    let baseUrl = '/settings/event';
    let absBaseUrl = `${baseUrl}/${event.hash}`;
    const menu = [
      {
        title: 'Event Details',
        link: `${absBaseUrl}/`,
        isExact: true,
      },
      {
        title: 'Ticket(s)',
        link: `${absBaseUrl}/ticket/`,
      },
      {
        title: 'Booking Engine',
        link: `${absBaseUrl}/booking-engine/`,
      },
    ];

    let menuItems = [];
    menu.map((item) => {
      const {title, link, isExact} = item;
      return menuItems.push(
        <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
          <NavLink
            to={{
              pathname: link,
              state: {fromDashboard: true},
            }}
            exact={isExact}
            className="nav-link rounded-0"
            activeClassName="selected">
            {title}
          </NavLink>
        </li>,
      );
    });

    return (
      <div className="room-type-details screen-container ">
        <Helmet>
          <title>Event Settings</title>
        </Helmet>


        {baseUrl &&
         <Link to={baseUrl} className={'btn btn-outline-dark mr-2 btn-sm mt-2'}>
           <FontAwesomeIcon icon={faArrowLeft} size={'sm'}/> {'Event List'}
         </Link>
        }

        <PageHeader
          subHeader={true} history={this.props.history} title={'Event Details'}
          description={`Settings & Configuration for event : ${event.name}`}
        />


        <ul className="nav nav-tabs mb-3">
          {menuItems}
        </ul>

        <Switch>
          <Route path={`${baseUrl}/:eventId`} exact component={EventDetails} />
          <Route path={`${baseUrl}/:eventId/edit`} exact component={EventManage} />
          <Route path={`${baseUrl}/:eventId/booking-engine/`} component={(EventBookingEngine)}/>

          {/* Ticket */}
          <Route path={`${baseUrl}/:eventId/ticket`} exact component={TicketList} />
          <Route path={`${baseUrl}/:eventId/ticket/new`} exact component={TicketManage} />
          <Route path={`${baseUrl}/:eventId/ticket/:ticketId`} exact component={TicketDetails} />
          <Route path={`${baseUrl}/:eventId/ticket/:ticketId/edit`} exact component={TicketManage} />
        </Switch>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventInventory);