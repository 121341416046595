/**
 * POS Table List
 *  POS Table List
 */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
// Consts and Libs
import {Constants, ErrorMessages, Strings} from '../../../constants';
// Components
import {Alerts} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle, faTimes, faUsers} from '@fortawesome/free-solid-svg-icons';
import {confirmAlert} from '../../../components/general/ConfirmAlert';

/* Component ==================================================================== */

class POSTableSelector extends React.Component {
  static componentName = 'POSTableSelector';

  static propTypes = {
    posOrder: PropTypes.object,
    property: PropTypes.object,
    posDetails: PropTypes.object,
    updateResponse: PropTypes.func,
  };

  static defaultProps = {
    changeType: 'shift'
  }

  updatePosOrder = (tableData) => {
    const {changeType} = this.props;
    if (tableData) {
      this.setState({resultMsg: {status: 'One moment...'}});

      if (this.props.updatePosOrder) {
        this.props.updatePosOrder({
          'table_list': [tableData.id],
          'table_shift' : changeType === 'shift'
        });
      }
    }
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {show, posDetails, changeType} = this.props;
    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }

    if (!show) return <React.Fragment/>;


    const TABLES = [];

    if (posDetails.pos_has_table && !_.isEmpty(posDetails.tables)) {
      posDetails.tables.forEach((data) => {
        if (parseInt(data.availability_status) === Constants.ROOM.AVAILABLE) {
          TABLES.push(data);
        }
      });
    }

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {changeType === 'shift' ? 'Shift' : 'Add'} Table
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className={'text-muted'}>{changeType === 'shift' ? Strings.posOrderTableShift : Strings.posOrderTableAdd}</p>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
              <table className={'table table-sm border format-table'}>
                <thead>
                  <tr>
                    <th className={'w-50'}>Table</th>
                    <th className={''}><FontAwesomeIcon icon={faUsers}/></th>
                    <th>..</th>
                  </tr>
                </thead>
                <tbody>
                  {_.isEmpty(TABLES) ?
                    <React.Fragment>
                      <td colSpan={3}>
                        <div className="text-center">
                          <FontAwesomeIcon icon={faExclamationTriangle} size={'2x'} className={'mt-3 red-cl'}/>
                          <p className={'text-secondary small'}>No Table(s) available.</p>
                        </div>
                      </td>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      {TABLES.map((data, i) => (
                        <tr key={i}>
                          <td>
                            <strong>{data.name}</strong>
                          </td>
                          <td>{data.occupancy}</td>
                          <td>
                            <button
                              onClick={
                                ()=>confirmAlert({
                                  title: `${changeType === 'shift' ? 'Shift' : 'Add'} Table`,
                                  message: changeType === 'shift' ? Strings.posOrderTableShift : Strings.posOrderTableAdd,
                                  buttons: [
                                    {
                                      className: 'btn-warning',
                                      label: 'Confirm',
                                      onClick: () => this.updatePosOrder(data),
                                    },
                                    {
                                      className: 'btn-secondary',
                                      label: 'Close',
                                    },
                                  ],
                                })
                              }
                              className={'btn btn-success btn-sm'}
                            >
                              {changeType === 'shift' ? 'Shift' : 'Add'} Table
                            </button>
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          <Alerts
            status={resultMsg.status}
            success={resultMsg.success}
            error={resultMsg.error}
          />
          <div className={'row'}>
            <div className={'col'}>
              <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default POSTableSelector;
