/**
 * Selector Button View
 */
import PropTypes from 'prop-types';
import React, {Component} from 'react';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

/* Component ==================================================================== */
class SelectorButton extends Component {
  static componentName = 'SelectorButton';

  static propTypes = {
    buttonList: PropTypes.array,
    selectButtonKey: PropTypes.string,
    selectionBtn: PropTypes.string,
    defaultBtn: PropTypes.string,
    className: PropTypes.string,
    btnSize: PropTypes.string,
    title: PropTypes.string,
    action: PropTypes.func,
  };

  static defaultProps = {
    selectionBtn: 'primary',
    defaultBtn: 'secondary',
    btnSize: 'sm',
    className: '',
    title: 'Edit Type',
  };

  action = (data) => {
    if (this.props.action) {
      this.props.action(data);
    }
  };

  render = () => {
    const {title, buttonList, selectButtonKey, btnSize, className, selectionBtn, defaultBtn} = this.props;

    return (
      <div className={`btn-group ${className}`}>
        {title &&
          <button className={`btn btn-outline-${defaultBtn} btn-${btnSize}`} type="button" disabled={true}>
            {title}
          </button>
        }
        {buttonList.map((data, i) => (
          <button
            key={i}
            type="button"
            disabled={data.disabled}
            className={`btn ${selectButtonKey === data.key ? `btn-${selectionBtn}` : `btn-outline-${defaultBtn}`} btn-${btnSize}`}
            onClick={() => data.disabled ? null : this.action(data.key)}
          >
            {data.icon && <FontAwesomeIcon className={'mr-2'} icon={data.icon}/>}
            {data.title}
          </button>
        ))}
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default SelectorButton;