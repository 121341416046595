/**
 *  POS Category csv add
 *
 */
import { faBroom, faCog, faPlus, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import _ from 'lodash';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form, SubmitBtn, Textarea } from 'react-formik-ui';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
// Form
import * as Yup from 'yup';
import PageHeader from '../../../components/dashboard/PageHeader';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
// Components
import { Alerts } from '../../../components/ui';
import { ErrorMessages } from '../../../constants';
// Consts and Libs
import AppAPI from '../../../lib/api';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pos: state.pos.pos,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */

class POSCategoryCSVAdd extends Component {
  static componentName = 'POSCategoryCSVAdd';

  static propTypes = {
    match: PropTypes.object,
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  addItem = (credentials) => {
    const {pos} = this.props;
    if (credentials && !_.isEmpty(this.state.itemsData)) {

      const itemsData = [];

      this.state.itemsData.forEach((data) => {
        itemsData.push({
          'name': data.Name,
          'short_description': data.ShortCode,
          'description': data.Description,
        });
      });

      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.posapi.post(`pos-category-settings/?pos=${pos.hash}`, itemsData)
          .then(() => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.props.history.goBack();
                }, 500);
              },
            );
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };


  fetchInitData = () => {};

  parseInput = (rawData) => {
    Papa.parse(rawData, {
      header: true,
      skipEmptyLines: true,
      error: () => {
        this.setState({
          resultMsg: {
            error: 'Parsing CSV Failed'
          }
        });
      },
      complete: (results) => {
        this.setState({itemsData: results.data});
      },
    });
  };

  render = () => {

    const {
      loading,
      error,
      resultMsg,
    } = this.state;

    if (loading) return <Loading/>;

    if (!loading && error) {
      return <Error full={true} text={ErrorMessages.initData}/>;
    }


    const initialValues = {
      'import_data': 'Name,ShortCode,Description',
    };

    const formValidation = Yup.object().shape({
      import_data: Yup.string().min(2, 'Too Short!').required('Required'),
    });

    return (

      <div className="room-type-list-manage screen-container ">
        <Helmet>
          <title>POS Add Multiple Category</title>
        </Helmet>
        <PageHeader
          subHeader={true} history={this.props.history}
          title={'Add Category [CSV Import'}
          description={'Add category csv import to the point of sales'}
        />

        <Formik
          onSubmit={(values) => this.addItem(values)}
          validationSchema={formValidation}
          initialValues={initialValues}>
          {(formikProps) => (
            <Form>
              <div className={'row'}>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className={'border mt-4 p-2 text-center anchor w-100'}>
                    <h6 className={'mb-0'}>
                      Category Information
                    </h6>
                  </div>
                  <div className={'border p-2 mb-3'}>
                    <Textarea
                      name="import_data"
                      label={'Input Data CSV Format'}
                      className={'form-control'}
                    />

                    <div className={'my-3'}>
                      <button
                        disabled={_.isEmpty(formikProps.values.import_data)}
                        onClick={() => this.setState({itemsData: []})}
                        type={'button'} className={'btn btn-secondary btn-lg mr-2'}
                      >
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faBroom} size={'sm'}/>
                        Clear
                      </button>
                      <button
                        disabled={_.isEmpty(formikProps.values.import_data)}
                        onClick={() => this.parseInput(formikProps.values.import_data)}
                        type={'button'} className={'btn btn-info btn-lg'}
                      >
                        <FontAwesomeIcon className={'white-cl mr-2'} icon={faCog} size={'sm'}/>
                        Parse
                      </button>
                    </div>
                    {!_.isEmpty(this.state.itemsData) && (
                      <table className={'table table-sm table-bordered border format-table'}>
                        <tbody>
                          <tr>
                            <th className={'data-table-cell-md'}>Name</th>
                            <th className={'data-table-cell-md'}>Short Code</th>
                            <th className={'data-table-cell-lg'}>Description</th>
                          </tr>
                        </tbody>
                        <tbody>
                          {this.state.itemsData.map((data, i) => (
                            <tr key={i}>
                              <td>{data.Name}</td>
                              <td>{data.ShortCode}</td>
                              <td className={'data-table-cell-lg'}>{data.Description}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>


              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                  <Alerts
                    status={resultMsg.status}
                    success={resultMsg.success}
                    error={resultMsg.error}
                  />
                  <SubmitBtn
                    disabled={_.isEmpty(this.state.itemsData)}
                    className={'btn btn-success btn-block btn-lg'}
                  >
                    <FontAwesomeIcon
                      className={'white-cl mr-2'}
                      icon={faPlus} size={'sm'}
                    /> Add Multiple Categories
                  </SubmitBtn>
                </div>
              </div>
            </Form>
          )}
        </Formik>


      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      itemsData: {},
      initialValues: {},
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(POSCategoryCSVAdd);
