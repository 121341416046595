/**
 * Order/Order Item Discount Edit
 *  Order/Order Item Edit
 *
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Actions
import {Constants} from '../../../constants';
import {Input, Select, Checkbox, Textarea} from 'react-formik-ui';


/* Component ==================================================================== */
class OrderDiscountEdit extends Component {
  static componentName = 'OrderDiscountEdit';

  static propTypes = {
    discountPosition: PropTypes.bool,
    order: PropTypes.object.isRequired,
    escalatedPermission: PropTypes.bool,
    formikProps: PropTypes.object.isRequired,
    discountHint: PropTypes.string
  };

  static defaultProps = {
    title: '',
    discountHint: '',
    discountPosition: false,
    escalatedPermission: false,
  }

  render = () => {
    const {order, formikProps, discountPosition, escalatedPermission, discountHint} = this.props;
    return (
      <React.Fragment>
        {escalatedPermission && <p className={'small text-muted'}>Requires Manager Permission</p>}
        <div className="form-row">
          <div className="col-12 col-sm-12">
            <div className={'form-row'}>
              <div className="col-8 form-group">
                <Input
                  required
                  type={'number'}
                  name='discount'
                  label={`Discount ${formikProps.values.percentage === 'false' ? order.currency : '%'}`}
                  className={'form-control'}
                  hint={discountHint}
                />
              </div>
              <div className="col-4 form-group">
                <Select
                  name='percentage'
                  label={'Discount Type'}
                  className={'form-control'}
                  options={[
                    {
                      'value': 'false',
                      'label': order.currency,
                    },
                    {
                      'value': 'true',
                      'label': '% value',
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 form-group">
            <Select
              required
              name='discount_type'
              label={'Discount Type'}
              className={'form-control'}
              options={Constants.ORDER_DISCOUNT_TYPE}
              hint={'Not visible to customer.'}
            />
          </div>
        </div>
        <div className="form-row">
          {discountPosition &&
          <div className="col-12 form-group">
            <p className={'mb-1'}>Discount Position</p>
            <Checkbox
              className={'mr-2'}
              name='discount_before_tax'
              text={'Add discount before tax'}
            />
          </div>
          }
          <div className="col-12 form-group">
            <Textarea
              name='discount_description'
              label={'Discount Description'}
              hint={'Will be visible to customer on order PDF.'}
              className={'form-control'}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };
}

/* Export Component ==================================================================== */
export default (OrderDiscountEdit);

