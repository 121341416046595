import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Route, Switch} from 'react-router-dom';
// Components
import OrderList from './OrderList';
import PageHeader from '../../components/dashboard/PageHeader';

class OrderListIndex extends Component {
  static propTypes = {
    match: PropTypes.object,
    hideHeader: PropTypes.bool,
    baseUrl: PropTypes.string,
    guestId: PropTypes.number,
    corporateId: PropTypes.number,
    pos: PropTypes.object,
    event: PropTypes.object,
  };


  static defaultProps = {
    baseUrl: '',
  };

  render = () => {
    const {baseUrl, hideHeader, guestId, corporateId, pos, event} = this.props;

    return (
      <div>
        {!hideHeader &&
         <PageHeader
           history={this.props.history} title={'Order\'s'}
           description={'All order generated in the property. Choose category to filter order.'}
         />
        }

        <div className={'row'}>
          <div className={'col-lg-12 col-md-12 col-sm-12'}>
            <Switch>
              <Route
                path={`${baseUrl}/order/list/`} exact
                render={(props) =>
                  <OrderList
                    pos={pos} event={event}
                    guestId={guestId} corporateId={corporateId}
                    {...props}
                  />
                }
              />
              <Route
                path={`${baseUrl}/order/list-tax-data/`} exact
                render={(props) =>
                  <OrderList
                    taxData={true}
                    {...props}
                  />
                }
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}


export default OrderListIndex;