const DATE_TYPES = {
  REPORTS_DATE_TYPE: [
    {value: 1, label: 'Created Date'},
  ],
  REPORTS_DATE_TYPE_BOOKING: [
    {value: 1, label: 'Created Date'},
    {value: 2, label: 'Checkin Date', maxDate: null},
    {value: 3, label: 'Checkout Date', maxDate: null},
    {value: 6, label: 'Occupied on Date', maxDate: null},
    {value: 8, label: 'No Show Date'},
    {value: 9, label: 'Cancelled Date'},
  ],
  REPORTS_DATE_TYPE_LEDGER_BOOKING: [
    {value: 1, label: 'Created Date'},
    {value: 2, label: 'Checkin Date'},
    {value: 3, label: 'Checkout Date'},
  ],
  REPORTS_DATE_TYPE_ORDER: [
    {value: 1, label: 'Created Date'},
    {value: 4, label: 'Payment Date'},
    {value: 5, label: 'Refund Date'},
  ],
  REPORTS_DATE_TYPE_POS_ORDER: [
    {value: 1, label: 'Created Date'},
    {value: 7, label: 'Completed Date'},
  ],
  REPORTS_DATE_TYPE_REFUND: [
    {value: 1, label: 'Created Date'},
    {value: 4, label: 'Payment Date'},
  ],
};

const CONSTANT = {
  ORDER_PRINT_DATE_TYPE: [
    {value: 'created', label: 'Created'},
    {value: 'checkin', label: 'Checkin Date'},
    {value: 'checkout', label: 'Checkout Date'},
  ],
  BOOKING_STATUS_INPUT: [
    {value: '1', label: 'Up Coming'},
    {value: '2', label: 'Active'},
    {value: '3', label: 'Completed'},
    {value: '4', label: 'No Show'},
    {value: '0', label: 'Cancelled'},
  ],
  BOOKING_SOURCES_INPUT: [
    {value: '1', label: 'Agent'},
    {value: '2', label: 'Email'},
    {value: '3', label: 'Walk In'},
    {value: '4', label: 'Phone'},
    {value: '5', label: 'Channel Manager'},
    {value: '6', label: 'Booking Engine'},
    {value: '7', label: 'Corporate'},
    {value: '8', label: 'OTA'},
  ],
  BOOKING_REFERENCE: [
    {value: 0, label: 'N.A'},
    {value: 1, label: 'Management Reference'},
    {value: 2, label: 'Manager Reference'},
    {value: 3, label: 'Agent Reference'},
    {value: 4, label: 'Owner Reference'},
    {value: 5, label: 'Corporate Reference'},
    {value: 6, label: 'Staff Reference'},
    {value: 7, label: 'Other'},
  ],
  BOOKING_PURPOSE_OF_VISIT: [
    {value: 0, label: 'N.A'},
    {value: 8, label: 'Leisure'},
    {value: 1, label: 'Tourism'},
    {value: 2, label: 'Official'},
    {value: 3, label: 'Conference'},
    {value: 4, label: 'Study'},
    {value: 5, label: 'Pilgrimage'},
    {value: 5, label: 'Religious'},
    {value: 6, label: 'Wedding'},
    {value: 7, label: 'Other'},
  ],
  GENDER: [
    {value: 'M', label: 'Male'},
    {value: 'F', label: 'Female'},
    {value: 'O', label: 'Other'},
    {value: 'TH', label: 'Third Gender'},
    {value: 'N', label: 'N.A'},
  ],
  SALUTATION: [
    {value: 'Mr', label: 'Mr'},
    {value: 'Ms', label: 'Ms'},
    {value: 'Mrs', label: 'Mrs'},
  ],
  PAYMENT_METHODS_INPUTS: [
    {value: '1', label: 'Cash'},
    {value: '2', label: 'Cheque'},
    {value: '3', label: 'Bank Transfer'},
    {value: '4', label: 'Online Payment'},
    {value: '5', label: 'Card'},
    {value: '7', label: 'Online (External)'},
    {value: '8', label: 'Payment Terminal'},
    {value: '9', label: 'Channel Collect'},
  ],
  PAYMENT_METHODS_OFFLINE_INPUTS: [
    {value: '1', label: 'Cash'},
    {value: '2', label: 'Cheque'},
    {value: '3', label: 'Bank Transfer (IMPS/NEFT/SWIFT)'},
    {value: '5', label: 'Card / POS'},
    {value: '7', label: 'Online (External)'},
  ],
  PAYMENT_TYPE_INPUTS: [
    {value: '1', label: 'Direct'},
    {value: '2', label: 'Indirect / Agent / 3rd Party'},
  ],
  FILE_RECORD_TYPE: [
    {value: 7, label: 'Driving License'},
    {value: 8, label: 'Election Identity Document'},
    {value: 2, label: 'Passport'},
    {value: 1, label: 'Identification Card'},
    {value: 3, label: 'Document'},
    {value: 4, label: 'Other'},
  ],
  FILE_RECORD_TYPE_INDIA: [
    {value: 7, label: 'Driving License'},
    {value: 10, label: 'Voter ID'},
    {value: 2, label: 'Passport'},
    {value: 9, label: 'Aadhar Card'},
    {value: 1, label: 'Identification Card'},
    {value: 8, label: 'Election Identity Document'},
    {value: 3, label: 'Document'},
    {value: 4, label: 'Other'},
  ],
  VENDOR_FILE_RECORD_TYPE: [
    {value: 5, label: 'Company Registration Document'},
    {value: 6, label: 'Tax Registration Document'},
    {value: 4, label: 'Other Document'},
  ],
  ORDER_QUANTITY_TYPE: [
    {value: 1, label: 'Nos'},
    {value: 2, label: 'Night(s)'},
    {value: 3, label: 'Guest(s)'},
  ],
  ORDER_QUANTITY_TYPE_EXPENSE: [
    {value: 1, label: 'Nos'},
    {value: 4, label: 'Gram'},
    {value: 5, label: 'Kilogram'},
    {value: 6, label: 'Milliliters'},
    {value: 7, label: 'Liter'},
    {value: 8, label: 'Centimeter'},
    {value: 9, label: 'Meter'},
  ],
  ORDER_DISCOUNT_TYPE: [
    {value: 0, label: 'Discount'},
    {value: 1, label: 'Management Discount'},
    {value: 2, label: 'Marketing Discount'},
    {value: 3, label: 'Package Discount'},
    {value: 4, label: 'Agent Discount'},
    {value: 5, label: 'Corporate Discount'},
    {value: 6, label: 'Dispute Discount'},
    {value: 7, label: 'Staff Discount'},
    {value: 8, label: 'Other Discount'},
    {value: 9, label: 'Revenue Loss'},
  ],
  ORDER_COMPLIMENTARY_TYPE: [
    {value: 0, label: 'Complimentary'},
    {value: 1, label: 'Management Complimentary'},
    {value: 2, label: 'Marketing Complimentary'},
    {value: 3, label: 'Package Complimentary'},
    {value: 4, label: 'Agent Complimentary'},
    {value: 5, label: 'Corporate Complimentary'},
    {value: 6, label: 'Dispute Complimentary'},
    {value: 7, label: 'Staff Complimentary'},
    {value: 8, label: 'Other Complimentary'},
  ],
  ORDER_NO_CHARGE_TYPE: [
    {value: 0, label: 'No Charge'},
    {value: 1, label: 'MD/Owner NC'},
    {value: 2, label: 'Manager NC'},
    {value: 3, label: 'Dispute NC'},
    {value: 4, label: 'Staff NC'},
    {value: 5, label: 'Other NC'},
  ],
  NOTIFICATION_TYPE_INPUTS: [
    {value: 1, label: 'E-Mail'},
    {value: 2, label: 'Text Msg (SMS)'},
  ],
  DISCOUNT_TYPE_INPUTS: [
    {value: 1, label: '% Value'},
    {value: 2, label: 'Fixed Price'},
  ],
  NOTES_TYPE_INPUTS: [
    {value: 1, label: 'Note'},
    {value: 2, label: 'Action'},
    {value: 3, label: 'Other'},
  ],
  NOTES_TYPE: {
    NOTE: 1,
    ACTION: 2,
    OTHER: 3,
  },
  NOTES_SOURCE: {
    USER: 1,
    SYSTEM: 2,
    EXTERNAL: 3,
  },
  NOTIFICATION_TYPE: {
    EMAIL: 1,
    TEXT: 2,
  },
  BOOKING_SOURCE: {
    AGENT: 1,
    EMAIL: 2,
    WALKIN: 3,
    PHONE: 4,
    CHANNEL_MANAGER: 5,
    BOOKING_ENGINE: 6,
    CORPORATE: 7,
    OTA: 8,
  },
  PROFILE_TYPE: {
    GUEST: 1,
    CORPORATE: 2,
    AGENT: 3,
    VENDOR: 4,
  },
  BOOKING_UPDATE_TYPE: {
    STANDARD: 1,
    COMPLIMENTARY: 2,
    FLAT_RATE: 3,
  },
  BOOKING_MODIFICATION_TYPES: {
    EDIT_DATE: 1,
    EXTEND_DATE: 2,
    ADD_ROOMS: 3,
  },
  BOOKING_ROOM_MODIFICATION_TYPES: {
    UPDATE_ROOM_TYPE_OR_PACKAGE: 11,
  },
  BOOKING_LOG_TYPE: {
    NEW: 1,
    MODIFY: 2,
    CANCEL: 0
  },
  BOOKING: {
    CANCELED: 0,
    UPCOMING: 1,
    ACTIVE: 2,
    COMPLETED: 3,
    NOSHOW: 4,
    BOOKED: 111,
  },
  BOOKING_AMENDMENT: {
    CANCELED: 0,
    REQUESTED: 1,
    COMPLETED: 2,
  },
  ROOM: {
    AVAILABLE: 1,
    OCCUPIED: 0,

    IN_MAINTENANCE: 0,
    NORMAL: 1,
    MAINTENANCE_REQUESTED: 2,

    DIRTY: 0,
    CLEAN: 1,
    CLEANING_REQUESTED: 2,
    ATTENTION: 3,
  },
  PAYMENT_GATEWAY: {
    PAYTM: 1,
    RAZORPAY: 2,
    STRIPE: 3,
    CC_AVENUE: 4,
    PINELABS: 5,
    PINELABS_PLURAL: 6
  },
  PAYMENT_GATEWAY_TYPE: {
    ROUTE: 0,
    DIRECT: 1,
  },
  PTERMINAL_STATUS: {
    INITIAL: 0,
    TERMINAL_RESPONSE: 1,
    NOT_PAID: 2,
    PAID: 3,
    FAILED: 4,
    ACTION: 5,
    CANCELLED: 6,
  },
  PGATEWAY_STATUS: {
    NOTPAID: 0,
    PAID: 1,
    PENDING: 2,
    ACTION: 3,
    WAITING: 4,
    ABORTED: 5,
    CANCELLED: 6,
    FAILED: 7,
  },
  ORDER_STATUS: {
    NOTPAID: 0,
    PAID: 1,
    PARTPAID: 2,
    PART_REFUNDED: 3,
    REFUNDED: 4,
    PENDING: 5,
    CANCELLED: 6,
    // Status used for aggregate of Paid, Refunde
    TOTAL_PAID: 111,
    TOTAL_REFUNDED: 112,
  },
  ORDER_STATUS_INPUT: [
    {value: '0', label: 'Not Paid'},
    {value: '1', label: 'Paid'},
    {value: '2', label: 'Part Paid'},
    {value: '3', label: 'Part Refunded'},
    {value: '4', label: 'Refunded'},
    {value: '6', label: 'Cancelled'},
  ],
  PAYMENT_TYPE: {
    DIRECT: 1,
    INDIRECT: 2,
  },
  REFUND_STATUS_INPUT: [
    {value: '0', label: 'Not Refunded'},
    {value: '3', label: 'Part Refunded'},
    {value: '4', label: 'Refunded'},
  ],
  SETTLEMENT_STATUS: {
    NOT_SETTLED: 0,
    SETTLED: 1,
  },
  SETTLEMENT_STATUS_INPUT: [
    {value: '0', label: 'Not Settled'},
    {value: '1', label: 'Settled'},
  ],
  SETTLEMENT_FEE_TYPE_INPUT: [
    {value: '1', label: 'OTA Commission'},
    {value: '2', label: 'Agent Commission'},
    {value: '3', label: 'Transaction Fee'},
    {value: '4', label: 'Convenience Fee'},
    {value: '5', label: 'Bank Charges'},
    {value: '6', label: 'Handling Charges'},
    {value: '7', label: 'Other Charges/Fee'},
    {value: '8', label: 'PG Charges'},
  ],
  PAYMENT_MODE: {
    PM_STANDARD: 1,
    PM_CONSOLIDATED: 2,
    PM_SUB: 3
  },
  PAYMENT_STATUS: {
    NOTPAID: 0,
    PAID: 1,
    PARTPAID: 2,
    PART_REFUNDED: 3,
    REFUNDED: 4,
    PENDING: 5,
    CANCELLED: 6,
  },
  REFUND_STATUS: {
    NOTPAID: 0,
    PAID: 1,
    PARTPAID: 2,
    PART_REFUNDED: 3,
    REFUNDED: 4,
    PENDING: 5,
    CANCELLED: 6,
  },
  PAYMENT_METHODS: {
    CASH: 1,
    CHECK: 2,
    BANK_TRANSFER: 3,
    ONLINE_PAYMENT: 4,
    CARD: 5,
    OTHER: 6,
    ONLINE_EXTERNAL: 7,
    PAYMENT_TERMINAL: 8,
    CHANNEL_COLLECT: 9
  },
  ORDER_SOURCE: {
    SYSTEM: 0,
    BOOKING: 1,
    POINT_OF_SALES: 2,
    AMENDMENT: 3,
    EXTERNAL: 4,
    USER: 5,
    EVENT: 6,
  },
  REPORT_SOURCE: {
    BOOKING: 1,
    EVENT: 2,
    EXPENSE: 3,
    ORDER: 4,
    PAYMENT: 5,
    POINT_OF_SALE: 6,
  },
  QUANTITY_TYPE: {
    NOS: 1,
    NIGHT: 2,
    GUEST: 3,
  },
  EVENT_STATUS: {
    UPCOMING: 1,
    ACTIVE: 2,
    COMPLETED: 3,
  },
  EVENT_TYPE: {
    SINGLE: 1,
    RECURRING: 2,
  },
  EVENT_TYPE_INPUT: [
    {value: 1, label: 'Single'},
    {value: 2, label: 'Recurring'},
  ],
  EVENT_RECURRING_TYPE: {
    LIMITED: 1,
    DAILY: 2,
  },
  EVENT_RECURRING_TYPE_INPUT: [
    {value: 1, label: 'Limited'},
    {value: 2, label: 'Daily'},
  ],
  EVENT_TICKET_TYPE: {
    LIMITED: 1,
    UNLIMITED: 2,
  },
  TICKET_BOOKING_STATUS: {
    CANCELLED: 0,
    BOOKED: 1,
    PROVISIONAL: 2,
  },
  TICKET_BOOKING_CLAIM_STATUS: {
    CLAIMED: 4,
    NOT_CLAIMED: 5,
  },
  USER_ACCOUNT_TYPE: {
    USER: 0,
    EMPLOYEE: 1,
  },
  MEMBERSHIP_TYPE: {
    STANDARD_USER: 0,
    ADMINISTRATOR: 1
  },
  MEMBERSHIP_TYPE_INPUT: [
    {value: 0, label: 'Standard User'},
    {value: 1, label: 'Administrator'},
  ],
  DAY_OF_WEEK_INPUT: [
    {value: 'Sun', label: 'Sunday'},
    {value: 'Mon', label: 'Monday'},
    {value: 'Tue', label: 'Tuesday'},
    {value: 'Wed', label: 'Wednesday'},
    {value: 'Thu', label: 'Thursday'},
    {value: 'Fri', label: 'Friday'},
    {value: 'Sat', label: 'Saturday'},
  ],
  TAX_TYPE: {
    PERCENTAGE: 1,
    FIXED: 2,
  },
  TAX_SLAB: {
    FLAT: 1,
    SLAB: 2,
  },
  TAX_SLAB_INPUT: [
    {value: 1, label: 'Flat (Same rate for all values)'},
    {value: 2, label: 'Slab (Different for different range of values)'},
  ],
  TAX_TYPE_INPUT: [
    {value: 1, label: '% Percentage'},
    {value: 2, label: 'Fixed Value'},
  ],
  TAX_NUMBER_TYPE: [
    {value: 'GST', label: 'Goods & Services Tax'},
    {value: 'VAT', label: 'Value added Tax'},
    {value: 'TAX', label: 'Tax'},
  ],
  MEMBERSHIP_PERMISSIONS: [
    {
      id: 9,
      name: 'Customer Management',
      key: 'customer',
      description: 'Provides access to customer management.',
    },
    {
      id: 8,
      name: 'Reports Management',
      key: 'reports',
      description: 'Provides access to reports sections.',
    },
    {
      id: 7,
      name: 'Event Management',
      key: 'event',
      description: 'Provides access to event management.',
    },
    {
      id: 6,
      name: 'Section Manager',
      key: 'sectionmanager',
      description: 'Provides manager access to section they are allotted.',
    },
    {
      id: 5,
      name: 'Accounts and Billing',
      key: 'accounts',
      description: 'Access to Payments and order data.',
    },
    {
      id: 4,
      name: 'Point of Sales',
      key: 'pointofsale',
      description: 'Point of Sales access, sub level access is available in Point of Sale settings.',
    },
    {
      id: 3,
      name: 'House Keeping',
      key: 'housekeeping',
      description: 'House keeping tasks.',
    },
    {
      id: 2,
      name: 'Front Desk / Booking',
      key: 'frontdesk',
      description: 'Provides access to front desk and booking management functions.',
    },
    {
      id: 1,
      name: 'Manager',
      key: 'manager',
      description: 'Manager level permission user.',
    },
  ],
  ENTITY_TYPE_INPUT: [
    {value: '1', label: 'Proprietorship'},
    {value: '2', label: 'Limited'},
    {value: '3', label: 'Private Limited'},
    {value: '4', label: 'Public Limited'},
    {value: '5', label: 'Limited Liability Partnership'},
    {value: '6', label: 'Partnership'},
    {value: '7', label: 'Other'},
  ],
  SOCIAL_LINK_TYPE_INPUT: [
    {value: 'FB', label: 'Facebook'},
    {value: 'TW', label: 'Twitter'},
    {value: 'YT', label: 'Youtube'},
    {value: 'IG', label: 'Instagram'},
    {value: 'OT', label: 'Other'},
  ],
  PAGE_LAYOUT_INPUT: [
    {value: 1, label: 'Sidebar'},
    {value: 2, label: 'Sidebar - Cover Image'},
    {value: 3, label: 'No Sidebar - Center Aligned'},
    {value: 4, label: 'No Sidebar - Cover Image - Center Aligned'},
    {value: 5, label: 'Full Width'},
    {value: 6, label: 'Full Width - Cover Image'},
  ],
  PAGE_LAYOUT: {
    SIDEBAR: 1,
    SIDEBAR_COVER_IMAGE: 2,
    NO_SIDEBAR_CENTER_ALIGNED: 3,
    NO_SIDEBAR_COVER_IMAGE_CENTER_ALIGNED: 4,
    FULL_WIDTH: 5,
    FULL_WIDTH_COVER_IMAGE: 6,
  },
  ROOM_SERVICE_STATUS: {
    DIRTY: 0,
    CLEAN: 1,
    CLEANING_REQUESTED: 2,
    ATTENTION: 3,
  },
  ROOM_MAINTENANCE_STATUS: {
    IN_MAINTENANCE: 0,
    NORMAL: 1,
    MAINTENANCE_REQUESTED: 2,
  },
  ROOM_STATUS: {
    OCCUPIED: 0,
    AVAILABLE: 1,
  },
  POS_TYPE: {
    RESTAURANT: 1,
    BAR: 2,
    RESTRO_BAR: 3,
    SPA: 4,
    GIFT_STORE: 5,
    POS: 6,
  },
  POS_TYPE_INPUT: [
    {value: 1, label: 'Restaurant'},
    {value: 2, label: 'BAR'},
    {value: 3, label: 'Restro-Bar'},
    {value: 4, label: 'SPA'},
    {value: 5, label: 'Gift Store'},
    {value: 6, label: 'Point of Sale'},
  ],
  POS_OPERATION_TYPE: [
    {value: 1, label: '24 x 7'},
    {value: 3, label: 'Shifts'},
  ],
  POS_TABLE_STATUS: {
    OCCUPIED: 0,
    AVAILABLE: 1,
  },
  POS_TABLE_SHAPE: [
    {value: 1, label: 'Square'},
    {value: 2, label: 'Rectangle'},
    {value: 3, label: 'Circle'},
    {value: 4, label: 'Trapezoid'},
    {value: 5, label: 'In-Line Straight'},
  ],
  POS_SERVICE_STATUS: [
    {value: 0, label: 'Dirty'},
    {value: 1, label: 'Clean'},
    {value: 2, label: 'Cleaning Requested'},
    {value: 3, label: 'Attention Required'},
  ],
  POS_MAINTENANCE_STATUS: [
    {value: 0, label: 'In Maintenance (Not Available)'},
    {value: 1, label: 'Operational'},
    {value: 2, label: 'Maintenance Requested'},
  ],
  POS_FOOD_TYPE_INPUT: [
    {value: 0, label: 'N.A'},
    {value: 1, label: 'Drink'},
    {value: 2, label: 'Food'},
    {value: 3, label: 'Tobacco'},
    {value: 6, label: 'Other'},
  ],
  FOOD_CONTENT_TYPE_INPUT: [
    {value: 0, label: 'N.A'},
    {value: 1, label: 'Vegetarian'},
    {value: 2, label: 'Non Vegetarian'},
    {value: 3, label: 'Red Meat'},
    {value: 4, label: 'Egg'},
    {value: 5, label: 'Fish'},
    {value: 6, label: 'Other'},
  ],
  DRINK_CONTENT_TYPE_INPUT: [
    {value: 0, label: 'N.A'},
    {value: 1, label: 'Alcoholic'},
    {value: 2, label: 'Non Alcoholic'},
    {value: 3, label: 'Soft Drinks'},
    {value: 6, label: 'Other'},
  ],
  ITEM_STATUS_INPUT: [
    {value: 1, label: 'Available'},
    {value: 2, label: 'Not Available'},
    {value: 3, label: 'Limited Qty'},
    {value: 4, label: 'Inquire'},
  ],
  POS_FOOD_TYPE: {
    N_A: 0,
    DRINK: 1,
    FOOD: 2,
    TOBACCO: 3,
    OTHER: 6,
  },
  FOOD_CONTENT_TYPE: {
    N_A: 0,
    VEG: 1,
    NON_VEG: 2,
    RED_MEAT: 3,
    EGG: 4,
    FISH: 5,
    OTHER: 6,
  },
  DRINK_CONTENT_TYPE: {
    N_A: 0,
    ALCOHOL: 1,
    NON_ALCOHOL: 2,
    SOFT_DRINKS: 3,
    OTHER: 6,
  },
  ITEM_STATUS: {
    AVAILABLE: 1,
    NOT_AVAILABLE: 2,
    LIMITED_QTY: 3,
    INQUIRE: 4,
  },
  POS_ORDER_STATUS: {
    CANCELLED: 0,
    OPEN: 1,
    HOLD: 2,
    COMPLETED: 3,
  },
  POS_ORDER_STATUS_INPUT: [
    {value: '0', label: 'Canceled'},
    {value: '1', label: 'Open'},
    {value: '2', label: 'Hold'},
    {value: '3', label: 'Completed'},
  ],
  POS_ORDER_ITEM_STATUS: {
    ORDERED: 0,
    PREPARING: 0,
    READY: 0,
    DELIVERED: 0,
  },
  POS_KOT_STATUS: {
    CANCELLED: 0,
    ORIGINAL: 1,
    MODIFIED: 2,
  },
  POS_MEMBERSHIP_PERMISSIONS: [
    {
      id: 4,
      name: 'Section Manager',
      key: 'sectionmanager',
      description: 'Provides manager permission in sections user has access to.',
    },
    {
      id: 3,
      name: 'Kitchen',
      key: 'kitchen',
      description: 'Provides access to Kitchen/KOT sections. (Only applicable on Restaurant POS).',
    },
    {
      id: 2,
      name: 'Point of Sale',
      key: 'pointofsale',
      description:
        'Provides access to general Point of Sale management functions.',
    },
    {
      id: 1,
      name: 'Manager',
      key: 'manager',
      description: 'Manager level permission user.',
    },
  ],

  ROOM_SERVICE_TYPE: {
    SERVICE: 1,
    AMENITY: 2,
    MAINTENANCE: 3
  },
  ROOM_SERVICE_TYPE_INPUT: [
    {value: 1, label: 'Service'},
    {value: 2, label: 'Amenity'},
    {value: 3, label: 'Maintenance'},
  ],
  ROOM_SERVICE_REQUEST_STATUS: {
    REQUESTED: 1,
    IN_PROGRESS: 2,
    COMPLETE: 3,
    CANNOT_SERVE: 4,
    CANCELLED: 5
  },
  ROOM_SERVICE_REQUEST_STATUS_INPUT: [
    {value: 2, label: 'In Progress'},
    {value: 3, label: 'Complete'},
    {value: 4, label: 'Cannot Serve/Deny'},
    {value: 5, label: 'Cancel'},
  ],
  CREATED_TYPE: {
    USER_CREATED: 1,
    SYSTEM_CREATED: 2,
  },
  POS_ORDER_ITEM_TYPE: [
    {value: '0', label: 'All Items'},
    {value: '1', label: 'Discounted Items'},
    {value: '2', label: 'Complimentary Items'},
    {value: '3', label: 'NC Items'},
  ],
  REPORTS_POST: [
    {value: 'pos_order_items', label: 'POS Order Items', dateType: DATE_TYPES.REPORTS_DATE_TYPE_POS_ORDER},
    {value: 'pos_order_consol', label: 'POS Sales Consolidated', dateType: DATE_TYPES.REPORTS_DATE_TYPE_ORDER},
    {value: 'pos_order_tabs_consol', label: 'POS Tabs Consolidated', dateType: DATE_TYPES.REPORTS_DATE_TYPE_POS_ORDER},
    {
      value: 'pos_order_items_consol',
      label: 'POS Items Consolidated',
      dateType: DATE_TYPES.REPORTS_DATE_TYPE_POS_ORDER
    },
  ],
  REPORTS_DATE_TYPE: DATE_TYPES.REPORTS_DATE_TYPE,
  REPORTS_DATE_TYPE_BOOKING: DATE_TYPES.REPORTS_DATE_TYPE_BOOKING,
  REPORTS_DATE_TYPE_LEDGER_BOOKING: DATE_TYPES.REPORTS_DATE_TYPE_LEDGER_BOOKING,
  REPORTS_ESCALATED: [
    {
      value: 'bk_guest_consol',
      label: 'Booking Guest Consolidated',
      dateType: DATE_TYPES.REPORTS_DATE_TYPE_LEDGER_BOOKING
    },
  ],
  REPORTS_BOOKING: [
    {value: 'bk_consol', label: 'Booking Consolidated'},
    {value: 'bk_room_consol', label: 'Booking Room Consolidated'},
    {value: 'bk_amendment_consol', label: 'Booking Amendment Consolidated', dateType: DATE_TYPES.REPORTS_DATE_TYPE},
  ],
  REPORTS_ORDER: [
    {value: 'or_order_ledger', label: 'Ledger Export'},
    {value: 'or_booking_ledger', label: 'Booking Ledger Export', dateType: DATE_TYPES.REPORTS_DATE_TYPE_LEDGER_BOOKING},
    {value: 'or_booking_consol', label: 'Consolidated Booking Sales', dateType: DATE_TYPES.REPORTS_DATE_TYPE_LEDGER_BOOKING},
    {value: 'or_order_consol', label: 'Consolidated Sales', dateType: DATE_TYPES.REPORTS_DATE_TYPE_ORDER},
  ],
  REPORTS_EXPENSE: [
    {value: 'ex_expense_consol', label: 'Expense Consolidated', dateType: DATE_TYPES.REPORTS_DATE_TYPE_ORDER},
  ],
  REPORTS_PAYMENT: [
    {value: 'pa_payment_consol', label: 'Payment Consolidated', dateType: DATE_TYPES.REPORTS_DATE_TYPE_ORDER},
    {
      value: 'pa_payment_booking_consol',
      label: 'Payment - Booking Consolidated',
      dateType: DATE_TYPES.REPORTS_DATE_TYPE_ORDER
    },
    {value: 'pa_refund_consol', label: 'Refund Consolidated', dateType: DATE_TYPES.REPORTS_DATE_TYPE_REFUND},
  ],
  REPORT_ORDER_SOURCES: [
    {value: '1', label: 'Booking'},
    {value: '3', label: 'Booking Amendment'},
    {value: '4', label: 'Booking External'},
    {value: '2', label: 'Point of Sales'},
    {value: '5', label: 'User'},
    {value: '6', label: 'Event'},
  ],
  REPORT_BOOKING_SOURCES: [
    {value: '1', label: 'Agent'},
    {value: '3', label: 'Walk In'},
    {value: '2', label: 'E-Mail'},
    {value: '4', label: 'Phone'},
    {value: '7', label: 'Corporate'},
    {value: '5', label: 'Channel Manager'},
    {value: '6', label: 'Booking Engine'},
    {value: '8', label: 'OTA'},
  ],
  FETCH_STATUS: {
    FAILED: 99,
    INITIATED: 0,
    SUCCEEDED: 1,
    PROCESSING: 2,
    RETRY: 3,
    NOT_REQUIRED: 4,
  },
  USER_SESSION_STATUS: {
    ACTIVE: 1,
    SYSTEM_EXPIRED: 2,
    TIMEOUT: 3,
    USER_LOGOUT: 4,
    USER_EXPIRED: 5,
  },
  USER_SESSION_DEVICES: {
    RENZO_DASHBOARD: 1,
    RENZO_APP: 2,
    OTHER: 3,
  },
  ROOM_SMOKING_TYPE: [
    {value: '0', label: 'No Preference'},
    {value: '1', label: 'Non Smoking'},
    {value: '2', label: 'Smoking'}
  ],
  ROOM_BED_TYPE: [
    {value: '1', label: 'Single Bed'},
    {value: '2', label: 'Double Bed'},
    {value: '3', label: 'Large Bed'},
    {value: '4', label: 'Extra Large Bed'},
    {value: '5', label: 'Bunk Bed'},
    {value: '6', label: 'Sofa Bed'},
    {value: '7', label: 'Other'}
  ],
  ROOM_VIEW_TYPE: [
    {value: '1', label: 'None'},
    {value: '2', label: 'Sea view'},
    {value: '3', label: 'Valley view'},
    {value: '4', label: 'Hill view'},
    {value: '5', label: 'Pool view'},
    {value: '6', label: 'Garden view'},
    {value: '7', label: 'River view'},
    {value: '8', label: 'Lake view'},
    {value: '9', label: 'Palace view'},
    {value: '10', label: 'Bay view'},
    {value: '11', label: 'Jungle view'},
    {value: '12', label: 'City view'},
    {value: '13', label: 'Landmark view'},
    {value: '14', label: 'Terrace view'},
    {value: '15', label: 'Court yard view'},
    {value: '16', label: 'Desert view'},
    {value: '17', label: 'Golf course view'},
    {value: '18', label: 'Mountain View'},
    {value: '19', label: 'Ocean view'},
    {value: '20', label: 'Back water view'},
    {value: '21', label: 'Resort view'},
    {value: '22', label: 'Monument view'},
    {value: '23', label: 'Park view'},
    {value: '24', label: 'Lagoon view'},
    {value: '25', label: 'Forest view'},
    {value: '26', label: 'Beach view'},
    {value: '27', label: 'Airport view'}
  ],
  ROOM_TYPE_PACKAGE_CANCELLATION_INPUT: [
    {value: '0', label: 'N.A'},
    {value: '1', label: 'No Cancellation'},
    {value: '2', label: '24 Hour free Cancellation'},
    {value: '3', label: '48 Hour free Cancellation'},
    {value: '4', label: '72 Hour free Cancellation'},
  ],
  ORDER_SOURCES_INPUT: [
    {value: '1', label: 'Booking'},
    {value: '3', label: 'Booking Amendment'},
    {value: '4', label: 'Booking External'},
    {value: '2', label: 'Point of Sales'},
    {value: '5', label: 'User'},
    {value: '6', label: 'Event'},
    {value: '0', label: 'System'},
  ],
  ORDER_TYPE_INPUT: [
    {value: '0', label: 'Order'},
    {value: '1', label: 'Booking'},
    {value: '2', label: 'Booking Modify'},
    {value: '3', label: 'Booking Extend'},
    {value: '4', label: 'Booking Update (Room Addition)'},
    {value: '5', label: 'Booking (Regenerated)'},
    {value: '6', label: 'Booking Update'},
    {value: '7', label: 'Booking Package Update'},
    {value: '15', label: 'Booking Amendment'},
    {value: '16', label: 'Booking Amendment (Regenerated)'},
    {value: '50', label: 'Booking External'},
    {value: '51', label: 'Booking External Modify'},
    {value: '52', label: 'Booking External Updated'},
    {value: '40', label: 'Booking Engine'},
    {value: '41', label: 'Booking Engine Modify'},
    {value: '42', label: 'Booking Engine Updated'},
    {value: '20', label: 'POS'},
    {value: '21', label: 'POS Split'},
    {value: '22', label: 'POS (Regenerated)'},
    {value: '30', label: 'Event'},
    {value: '31', label: 'Event Update'},
    {value: '35', label: 'Event Booking Engine'},
    {value: '36', label: 'Event Booking Engine Updated'}
  ],
};

export default CONSTANT;
