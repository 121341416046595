/**
 *
 * POS Billing Order details
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {NavLink, Redirect, Route, Switch} from 'react-router-dom';
// Consts and Libs

// Components
import Error from '../../../components/general/Error';
import OrderListIndex from '../../order/OrderListIndex';
import Loading from '../../../components/general/Loading';
import RefundListIndex from '../../refund/RefundListIndex';
import PaymentListIndex from '../../payment/PaymentListIndex';

// Actions
import * as POSActions from '../../../redux/pos/actions';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  posDetails: state.pos.posDetails,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPOSDetails: POSActions.setPOSDetails,
};

/* Component ==================================================================== */
class POSBillingOrder extends Component {
  static componentName = 'POSBillingOrder';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
  };


  fetchInitData = () => {
  }

  render = () => {

    const {loading, error} = this.state;
    const {posDetails} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    let baseUrl = '/point-of-sale';
    let absBaseUrl = `${baseUrl}/${this.props.match.params.POSId}/pos-billing-order`;
    const menu = [
      {
        title: 'Billing Order',
        link: `${absBaseUrl}/order/list`,
        isExact: true,
      },
      {
        title: 'Payments',
        link: `${absBaseUrl}/payment/list`,
        isExact: true,
      },
      {
        title: 'Refunds',
        link: `${absBaseUrl}/refund/list`,
      },
    ];

    let menuItems = [];
    menu.map((item) => {
      const {title, link, isExact} = item;
      return menuItems.push(
        <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
          <NavLink
            to={{
              pathname: link,
              state: {fromDashboard: true},
            }}
            exact={isExact}
            className="nav-link rounded-0"
            activeClassName="selected">
            {title}
          </NavLink>
        </li>,
      );
    });

    return (
      <div className="booking-details screen-container ">
        <div className={'row'}>
          <div className={'col-lg-2 col-md-3 col-sm-12 mb-5'}>
            <ul className="nav nav-pills flex-md-column flex-sm-row border rounded mb-3">
              {menuItems}
            </ul>
          </div>
          <div className={'col-lg-10 col-md-9 col-sm-12'}>
            <Switch>
              <Route
                exact
                path={`${absBaseUrl}/`}
                render={() => (<Redirect to={`${absBaseUrl}/order/list`}/>)}
              />
              <Route
                exact
                path={`${absBaseUrl}/order/`}
                render={() => (<Redirect to={`${absBaseUrl}/order/list`}/>)}
              />
              <Route
                path={`${absBaseUrl}/order/list`}
                render={(props) =>
                  <OrderListIndex
                    {...props}
                    hideHeader={true}
                    baseUrl={absBaseUrl}
                    pos={posDetails}
                  />}
              />

              {/* Payments List */}
              <Route
                exact
                path={`${absBaseUrl}/payment/`}
                render={() => (<Redirect to={`${absBaseUrl}/payment/list`}/>)}
              />
              <Route
                path={`${absBaseUrl}/payment/list`}
                render={(props) =>
                  <PaymentListIndex
                    {...props}
                    pos={posDetails}
                    hideHeader={true}
                    baseUrl={absBaseUrl}
                  />}
              />


              {/* Refund List */}
              <Route
                exact
                path={`${absBaseUrl}/refund/`}
                render={() => (<Redirect to={`${absBaseUrl}/refund/list`}/>)}
              />
              <Route
                path={`${absBaseUrl}/refund/list`}
                render={(props) =>
                  <RefundListIndex
                    {...props}
                    pos={posDetails}
                    hideHeader={true}
                    baseUrl={absBaseUrl}
                  />}
              />

            </Switch>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(POSBillingOrder);