/**
 * Order/Order Item Complimentary Edit
 *  Order/Order Item Edit
 *
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Actions
import {Constants} from '../../../constants';
import {Checkbox, Input, Select} from 'react-formik-ui';


/* Component ==================================================================== */
class OrderComplimentaryEdit extends Component {
  static componentName = 'OrderComplimentaryEdit';

  static propTypes = {
    formikProps: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
  };

  static defaultProps = {
    title: '',
  }

  render = () => {
    return (
      <React.Fragment>
        <p className={'small text-muted'}>Requires Manager Permission</p>
        <div className="form-row">
          <div className="col-6 form-group">
            <p className={'mb-1'}>Complimentary Item</p>
            <Checkbox
              className={'mr-2'}
              name='complimentary'
              text={'Mark item as Complimentary'}
            />
          </div>
          <div className="col-6">
            <Select
              name='complimentary_type'
              label={'Complimentary Type'}
              className={'form-control'}
              options={Constants.ORDER_COMPLIMENTARY_TYPE}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-12">
            <Input
              name='complimentary_description'
              label={'Note'}
              className={'form-control'}
              hint={'Not visible to customer.'}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };
}

/* Export Component ==================================================================== */
export default (OrderComplimentaryEdit);

