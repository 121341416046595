/**
 * Footer
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// Components
import BuildInformation from './model/BuildInformation';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  user: state.user.user,
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
};

/* Component ==================================================================== */
class Footer extends Component {

  static propTypes = {
    message: PropTypes.string,
  };

  render = () => {
    const {property} = this.props;
    return (
      <div className="footer border border-bottom-0 border-right-0 border-left-0 ">
        <div className={'main-container'}>
          <div className="px-3 pt-3">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 text-lg-left text-center">
                {property.name && <p>Property : <span className="text-muted">{property.name}</span></p>}
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 text-lg-right text-center ">
                <p onClick={()=>this.setState({showBuildInformation: true})}>Renzo Solutions</p>
              </div>
            </div>
          </div>
        </div>
        <BuildInformation
          show={this.state.showBuildInformation}
          onHide={()=>this.setState({showBuildInformation: false})}
        />
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      showAuthLogin: false,
      showBuildInformation: false,
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Footer));
