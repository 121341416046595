/**
 *
 * Booking Amendment Request Details
 */
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import {Constants, Strings} from '../../constants';
// Components
import {Alerts} from '../../components/ui';
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import PageHeader from '../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {confirmAlert} from '../../components/general/ConfirmAlert';
import OrderSetListView from '../order/components/OrderSetListView';
import {faBan, faCheck, faCheckCircle, faLongArrowAltRight,} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as BookingActions from '../../redux/booking/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  bookingAmendmentRequest: state.booking.bookingAmendmentRequest,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setBookingAmendment: BookingActions.setBookingAmendment,
};

/* Component ==================================================================== */
class BookingAmendmentRequestDetails extends Component {
  static componentName = 'BookingAmendmentRequestDetails';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };


  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    this.setState({
      loading: true,
    }, () => {
      this.props.setBookingAmendment(this.props.match.params.bookingAmendmentRequestId)
        .then(() => {
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    });
  };

  updateAmendmentRequest = (payload, escalated = false) => {
    if (payload) {
      let baseUrl = 'booking-amendment-request';
      if (escalated) {
        baseUrl = 'booking-amendment-request-escalated';
      }
      this.setState({resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.bookingapi
          .patch(`${baseUrl}/${this.props.bookingAmendmentRequest.hash}/`, payload)
          .then(res => {
            this.setState({resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                this.setState({resultMsg: {success: ''}});
                this.props.setBookingAmendment(res);
              }, 500);
            });
          })
          .catch(err => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}});
          });
      });
    }
  };

  cacheReset = (type = 'all') => {
    this.setState({resultMsg: {status: 'One moment...'}}, () => {
      AppAPI.bookingapi
        .post(`booking-amendment-request/${this.props.bookingAmendmentRequest.hash}/cache-reset/?type=${type}`, {})
        .then(res => {
          this.props.setBookingAmendment(res);
          this.setState({resultMsg: {success: 'Success'}}, () => {
            setTimeout(() => {
              this.setState({resultMsg: {success: ''}});
            }, 500);
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            resultMsg: {error},
          });
        });
    });
  };


  render = () => {

    const {loading, error, resultMsg} = this.state;
    const {bookingAmendmentRequest} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Amendment Request Details </title>
        </Helmet>

        <PageHeader
          history={this.props.history}
          title={'Amendment Details'}
          description={`Reference number : ${_.isEmpty(bookingAmendmentRequest) ? '...' : bookingAmendmentRequest.ref_no}`}
        />

        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12">
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
            <div className={'row'}>
              <div className={'col-lg-6 col-md-12'}>
                <table className={'table table-borderless table-sm mb-3'}>
                  <tbody>
                    {!_.isEmpty(bookingAmendmentRequest.attributes) &&
                    <React.Fragment>
                      <tr>
                        <td className={'w-50 text-muted'}>Booking</td>
                        <td>
                          <Link to={`/booking/${bookingAmendmentRequest.attributes.booking_hash}/`}>
                            {bookingAmendmentRequest.attributes.booking_ref_no}
                          </Link>
                        </td>
                      </tr>
                      {bookingAmendmentRequest.attributes.booking_room_hash &&
                      <tr>
                        <td className={'w-50 text-muted'}>Booking Room</td>
                        <td>
                          <Link
                            to={`/booking/${bookingAmendmentRequest.attributes.booking_hash}/booking-room/${bookingAmendmentRequest.attributes.booking_room_hash}`}>
                            Room {bookingAmendmentRequest.attributes.booking_room_number}
                          </Link>
                          <br/>
                          <p
                            className={'text-muted mb-0 small'}>({bookingAmendmentRequest.attributes.booking_room_ref_no})</p>
                        </td>
                      </tr>
                      }
                      <tr>
                        <td className={'w-50 text-muted'}>Room</td>
                        <td>
                          {bookingAmendmentRequest.attributes.room_name || 'N.A'}
                        </td>
                      </tr>
                    </React.Fragment>
                    }
                  </tbody>
                </table>
              </div>
              <div className={'col-lg-6 col-md-12'}>
                <table className={'table table-borderless table-sm mb-3'}>
                  <tbody>
                    <tr>
                      <td className={'w-50 text-muted'}>Request Status</td>
                      <td>
                        <span
                          className={'badge ' + (AppUtil.bookingAmendmentStatusColor(bookingAmendmentRequest.booking_amendment_status))}>
                          {bookingAmendmentRequest.booking_amendment_status_display}
                        </span>
                      </td>
                    </tr>
                    {!_.isEmpty(bookingAmendmentRequest.attributes) &&
                    <React.Fragment>
                      <tr>
                        <td className={'w-50 text-muted'}>Created By</td>
                        <td>{bookingAmendmentRequest.attributes.created_user || 'N.A'}</td>
                      </tr>
                    </React.Fragment>
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className={'spacer-20'}/>

            <div className={''}>
              <ul className={'list-inline d-inline-flex list-inline-formatted mb-1'}>
                <li className={'list-inline-item align-items-center'}>
                  <p className={'mb-0'}>
                    <FontAwesomeIcon icon={faCheckCircle} size={'sm'} className={'green-cl mr-2'}/>
                    <strong>Created</strong>
                  </p>
                  <p className={'text-muted small'}>{AppUtil.formatDateTime(bookingAmendmentRequest.created)}</p>
                </li>
                {bookingAmendmentRequest.completed_time &&
                <React.Fragment>
                  <li className={'mx-3 list-inline-item d-inline-flex align-items-center'}>
                    <FontAwesomeIcon icon={faLongArrowAltRight}/>
                  </li>
                  <li className={'list-inline-item align-items-center'}>
                    <p className={'mb-0'}>
                      <FontAwesomeIcon
                        icon={faCheckCircle} size={'sm'}
                        className={`mr-2 ${bookingAmendmentRequest.completed_time ? 'green-cl' : 'grey-cl'}`}
                      />
                      <strong>Complete</strong>
                    </p>
                    <p className={'text-muted small'}>
                      {bookingAmendmentRequest.completed_time ? AppUtil.formatDateTime(bookingAmendmentRequest.completed_time) : '...'}
                    </p>
                  </li>
                </React.Fragment>
                }
                {bookingAmendmentRequest.cancelled_time &&
                <React.Fragment>
                  <li className={'mx-3 list-inline-item d-inline-flex align-items-center'}>
                    <FontAwesomeIcon icon={faLongArrowAltRight}/>
                  </li>
                  <li className={'list-inline-item align-items-center'}>
                    <p className={'mb-0'}>
                      <FontAwesomeIcon
                        icon={faCheckCircle} size={'sm'}
                        className={'mr-2 green-cl'}
                      />
                      <strong>Cancelled</strong>
                    </p>
                    <p className={'text-muted small'}>
                      {bookingAmendmentRequest.cancelled_time ? AppUtil.formatDateTime(bookingAmendmentRequest.cancelled_time) : '...'}
                    </p>
                  </li>
                </React.Fragment>
                }
              </ul>
            </div>

            <hr/>

            <div className={'row'}>
              <div className={'col-2'}>
                <p className={'small text-muted mb-0'}>{bookingAmendmentRequest.quantity_type_display}</p>
                <p>
                  <strong>
                    {bookingAmendmentRequest.quantity}
                    <FontAwesomeIcon icon={faTimes} className={'mx-2 small'}/>
                  </strong>
                </p>
              </div>
              {bookingAmendmentRequest.sub_quantity !== Constants.QUANTITY_TYPE.NOS &&
              <div className={'col-2'}>
                <p className={'small text-muted mb-0'}>{bookingAmendmentRequest.sub_quantity_type_display}</p>
                <p>
                  <strong>
                    {bookingAmendmentRequest.sub_quantity}
                    <FontAwesomeIcon icon={faTimes} className={'mx-2'}/>
                  </strong>
                </p>
              </div>
              }
              <div className={'col'}>
                <p className={'small text-muted mb-0'}>Amendment</p>
                <p><strong>{bookingAmendmentRequest.attributes.amendment}</strong></p>
              </div>
            </div>
            <div className={'spacer-20'}/>
            {bookingAmendmentRequest.amendment_active &&
            <div>
              <Alerts
                status={resultMsg.status}
                success={resultMsg.success}
                error={resultMsg.error}
              />

              <div className={'row'}>
                <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                  <button
                    className={'btn btn-lg btn-block btn-secondary'}
                    onClick={() => {
                      confirmAlert({
                        title: 'Cancel Request',
                        message: Strings.bookingAmendmentCancellation,
                        buttons: [
                          {
                            className: 'btn-danger',
                            label: 'Cancel Request',
                            onClick: () => this.updateAmendmentRequest({booking_amendment_status: Constants.BOOKING_AMENDMENT.CANCELED}),
                          },
                          {
                            className: 'btn-secondary',
                            label: 'Close',
                          },
                        ],
                      });
                    }}
                  >
                    Cancel Request
                  </button>
                </div>
                <div className={'col-lg-6 col-md-12 col-sm-12 mb-3'}>
                  <button
                    className={'btn btn-lg btn-block btn-success'}
                    onClick={() => {
                      confirmAlert({
                        title: 'Confirm Request',
                        message: Strings.bookingAmendmentConfirmation,
                        buttons: [
                          {
                            className: 'btn btn-success',
                            label: 'Confirm Request',
                            onClick: () => this.updateAmendmentRequest({booking_amendment_status: Constants.BOOKING_AMENDMENT.COMPLETED}),
                          },
                          {
                            className: 'btn-secondary',
                            label: 'Close',
                          },
                        ],
                      });
                    }}
                  >
                    Confirm Request
                  </button>
                </div>
              </div>
              <div className={'spacer-20'}/>
            </div>
            }

            <div className={'row'}>
              {!_.isEmpty(bookingAmendmentRequest.orders) &&
              <div className={'col-lg-12 col-md-12'}>
                <h5>Orders</h5>
                <OrderSetListView data={bookingAmendmentRequest.orders}/>
              </div>
              }
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <ul className="list-group mb-5">
              <li className="list-group-item  align-items-center" onClick={() => this.cacheReset()}>
                <small>Amendment Request Actions</small>
              </li>
              <li className="list-group-item">
                <button
                  className="btn btn-link green-cl m-0 p-0"
                  disabled={!bookingAmendmentRequest.amendment_active}
                  onClick={() => {
                    confirmAlert({
                      title: 'Confirm Request',
                      message: Strings.bookingAmendmentConfirmation,
                      buttons: [
                        {
                          className: 'btn-warning',
                          label: 'Confirm Request',
                          onClick: () => this.updateAmendmentRequest({booking_amendment_status: Constants.BOOKING_AMENDMENT.COMPLETED}),
                        },
                        {
                          className: 'btn-secondary',
                          label: 'Close',
                        },
                      ],
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} size={'sm'} className={'mr-2'}/> Confirm Request
                </button>
                <p className={'small text-muted mb-0'}>{Strings.bookingAmendmentConfirmation}</p>
              </li>
              <li className="list-group-item">
                <button
                  className="btn btn-link red-cl m-0 p-0"
                  disabled={!bookingAmendmentRequest.amendment_active}
                  onClick={() => {
                    confirmAlert({
                      title: 'Cancel Request',
                      message: Strings.bookingAmendmentCancellation,
                      buttons: [
                        {
                          className: 'btn-warning',
                          label: 'Cancel Request',
                          onClick: () => this.updateAmendmentRequest({booking_amendment_status: Constants.BOOKING_AMENDMENT.CANCELED}),
                        },
                        {
                          className: 'btn-secondary',
                          label: 'Close',
                        },
                      ],
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faBan} size={'sm'} className={'mr-2'}/> Cancel Request
                </button>
                <p className={'small text-muted mb-0'}>{Strings.bookingAmendmentCancellation}</p>
              </li>
            </ul>

            <ul className="list-group">
              <li className="list-group-item">
                <button
                  className="btn btn-link green-cl m-0 p-0"
                  disabled={!bookingAmendmentRequest.amendment_active}
                  onClick={() => {
                    confirmAlert({
                      title: 'Generate Bill',
                      message: Strings.bookingAmendmentGenerateOrder,
                      buttons: [
                        {
                          className: 'btn-secondary',
                          label: 'Close',
                        },
                        {
                          className: 'btn-success',
                          label: 'Generate Bill',
                          onClick: () => this.updateAmendmentRequest({generate_bill: true}, true),
                        },
                      ],
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faCheck} size={'sm'} className={'mr-2'}/> Regenerate Bill
                </button>
                <p className={'small text-muted mb-0'}>Requires Manager Permission.</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingAmendmentRequestDetails);