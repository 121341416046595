/**
 *
 * PaymentList
 * List Payments
 */
import queryString from 'query-string';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import Moment from 'moment';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import AppWebUtil from '../../lib/webUtils';
import {Constants, Filters, Strings} from '../../constants';
// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import SearchBar from '../../components/forms/SearchBar';
import FilterButton from '../../components/dashboard/FilterButton';
import FilterDate from '../../components/dashboard/FilterDate';
import ExportButton from '../../components/dashboard/ExportButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLongArrowAltRight, faMoneyBillWave, faSync} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PaymentList extends Component {
  static componentName = 'PaymentList';

  setQueryParams = (paramsData) => {
    if (paramsData && paramsData.length) {
      const queryParams = queryString.parse(this.props.location.search);
      paramsData.map((data) => (
        queryParams[data.key] = data.value
      ));
      this.props.history.replace(`${this.props.location.pathname}?${queryString.stringify(queryParams)}`);
    }
  };

  getQueryParams = (key) => {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && queryParams[key]) {
      return queryParams[key];
    }
    return false;
  };

  componentDidMount = () => {
    let urlParams = {}, startDate = null, endDate = null, paymentStartDate, paymentEndDate, settlementStartDate, settlementEndDate;

    switch (this.getQueryParams('payment_status')) {
    case 'paid':
      urlParams['payment_status'] = Constants.PAYMENT_STATUS.PAID;
      break;
    case 'not-paid':
      urlParams['payment_status'] = Constants.PAYMENT_STATUS.NOTPAID;
      urlParams['void'] = false;
      break;
    case 'part-paid':
      urlParams['payment_status'] = Constants.PAYMENT_STATUS.PARTPAID;
      break;
    case 'cancelled':
      urlParams['payment_status'] = Constants.PAYMENT_STATUS.CANCELLED;
      break;
    default:
      break;
    }

    switch (this.getQueryParams('settlement_status')) {
    case 'settled':
      urlParams['settlement_status'] = Constants.SETTLEMENT_STATUS.SETTLED;
      urlParams['payment_status'] = Constants.PAYMENT_STATUS.PAID;
      break;
    case 'not-settled':
      urlParams['settlement_status'] = Constants.SETTLEMENT_STATUS.NOT_SETTLED;
      urlParams['payment_status'] = Constants.PAYMENT_STATUS.PAID;
      break;
    default:
      break;
    }

    switch (this.getQueryParams('payment_mode')) {
    case 'standard':
      urlParams['payment_mode'] = Constants.PAYMENT_MODE.PM_STANDARD;
      break;
    case 'consolidated':
      urlParams['payment_mode'] = Constants.PAYMENT_MODE.PM_CONSOLIDATED;
      break;
    case 'sub-payment':
      urlParams['payment_mode'] = Constants.PAYMENT_MODE.PM_SUB;
      break;
    default:
      break;
    }

    switch (this.getQueryParams('payment_type')) {
    case 'direct':
      urlParams['payment_type'] = Constants.PAYMENT_TYPE.DIRECT;
      break;
    case 'indirect':
      urlParams['payment_type'] = Constants.PAYMENT_TYPE.INDIRECT;
      break;
    default:
      break;
    }

    switch (this.getQueryParams('refund_status')) {
    case 'not-refunded':
      urlParams['refund_status'] = Constants.REFUND_STATUS.NOTPAID;
      break;
    case 'part-refunded':
      urlParams['refund_status'] = Constants.REFUND_STATUS.PART_REFUNDED;
      break;
    case 'refunded':
      urlParams['refund_status'] = Constants.REFUND_STATUS.REFUNDED;
      break;
    case 'cancelled':
      urlParams['refund_status'] = Constants.REFUND_STATUS.CANCELLED;
      break;
    default:
      break;
    }

    switch (this.getQueryParams('payment_method')) {
    case 'cash':
      urlParams['payment_method'] = Constants.PAYMENT_METHODS.CASH;
      break;
    case 'cheque':
      urlParams['payment_method'] = Constants.PAYMENT_METHODS.CHECK;
      break;
    case 'card':
      urlParams['payment_method'] = Constants.PAYMENT_METHODS.CARD;
      break;
    case 'online-payment':
      urlParams['payment_method'] = Constants.PAYMENT_METHODS.ONLINE_PAYMENT;
      break;
    case 'bank-transfer':
      urlParams['payment_method'] = Constants.PAYMENT_METHODS.BANK_TRANSFER;
      break;
    case 'online-external':
      urlParams['payment_method'] = Constants.PAYMENT_METHODS.ONLINE_EXTERNAL;
      break;
    case 'payment-terminal':
      urlParams['payment_method'] = Constants.PAYMENT_METHODS.PAYMENT_TERMINAL;
      break;
    case 'channel-collect':
      urlParams['payment_method'] = Constants.PAYMENT_METHODS.CHANNEL_COLLECT;
      break;
    default:
      break;
    }

    if (this.getQueryParams('start_date')) {
      startDate = Moment(String(this.getQueryParams('start_date')));
    }
    if (this.getQueryParams('end_date')) {
      endDate = Moment(String(this.getQueryParams('end_date')));
    }

    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('start_date'), this.getQueryParams('end_date'), 'created_date');


    if (this.getQueryParams('payment_start_date')) {
      paymentStartDate = Moment(String(this.getQueryParams('payment_start_date')));
    }
    if (this.getQueryParams('payment_end_date')) {
      paymentEndDate = Moment(String(this.getQueryParams('payment_end_date')));
    }
    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('payment_start_date'), this.getQueryParams('payment_end_date'), 'payment_date');


    if (this.getQueryParams('settlement_start_date')) {
      settlementStartDate = Moment(String(this.getQueryParams('settlement_start_date')));
    }
    if (this.getQueryParams('settlement_end_date')) {
      settlementEndDate = Moment(String(this.getQueryParams('settlement_end_date')));
    }
    urlParams = AppWebUtil.setDateParams(urlParams, this.getQueryParams('settlement_start_date'), this.getQueryParams('settlement_end_date'), 'settled_date');



    if (this.props.guestId) {
      urlParams['guest_data'] = this.props.guestId;
    }

    if (this.props.corporateId) {
      urlParams['corporate_data'] = this.props.corporateId;
    }
    this.setState({loading: false, urlParams, startDate, endDate, paymentStartDate, paymentEndDate, settlementStartDate, settlementEndDate});
  };

  fetchData = (page = null, callback, searchText, download) => {
    const {pos, event} = this.props;

    const urlParams = {...this.state.urlParams};
    let baseUrl = 'payments-v2', apiUrl;
    if (page) {
      urlParams['cursor'] = page;
    }

    if (searchText) {
      this.setState({searching: true, clear: true});
      baseUrl = 'payment-search';
      urlParams['search'] = searchText;
      if (page){
        urlParams['page'] = page;
      }
    }

    if (download) {
      urlParams['all_data'] = true;
      urlParams['download'] = download.file;
      this.setState({fileDownload: true});
    }

    if (pos){
      apiUrl = AppAPI.posapi;
      baseUrl = 'pos-payment-v2';
      urlParams['pos'] = pos.hash;
      if (searchText) { baseUrl = 'pos-payment-search';}
    } else if (event) {
      apiUrl = AppAPI.eventapi;
      baseUrl = 'event-payment';
      urlParams['event'] = event.hash;
      if (searchText) { baseUrl = 'event-payment-search';}
    } else {
      apiUrl = AppAPI.paymentapi;
    }

    apiUrl.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (download) {
          let fileName = `Payment-Report-${AppUtil.formatDateTime(Moment(), 'short')}.${download.file}`;
          AppWebUtil.downloadFile(res, fileName);
        } else {
          if (res.results.length > 0) {
            callback(
              res.results,
              {
                allLoaded: !res.next,
                nextParam: res.count ? AppUtil.getURLParam(res.next, 'page') : AppUtil.getURLParam(res.next, 'cursor')
              }
            );
          } else {
            callback();
          }
        }
        this.setState({
          searching: false,
          clear: false,
          refresh: false,
          loading: false,
          fileDownload: false,
          page: page,
        });
      })
      .catch((err) => {
        if (callback) {
          callback([], {allLoaded: true});
        }
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, refresh: false, loading: false, fileDownload: false, error});
      });
  };

  renderRow = (data, key) => {
    return (
      <tr className={'tr-align-middle'} key={key}>
        <td className={'data-table-cell-md'}>
          <Link className={'small'} to={`/payment/${data.hash}`}>{data.ref_no}</Link>
        </td>
        <td className={'data-table-cell-md text-left'}><strong>{`${data.currency} ${data.total}`}</strong></td>
        <td className={'data-table-cell-xl text-left'}>
          <span className={`badge mr-2 ${AppUtil.paymentStatusColor(data.payment_status)}`}>
            {data.payment_status_display}
          </span>
          {data.payment_status !== Constants.PAYMENT_STATUS.CANCELLED &&
          <span className={`mr-2 badge ${AppUtil.settlementStatusColor(data.settlement_status)}`}>
            {data.settlement_status_display}
          </span>
          }
          {data.refund_status !== Constants.REFUND_STATUS.NOTPAID &&
           <span className={`mr-2 badge ${AppUtil.paymentStatusColor(data.refund_status)}`}>
             {data.refund_status_display}
           </span>
          }
          {data.payment_mode !== Constants.PAYMENT_MODE.PM_STANDARD &&
            <span className={`mr-2 badge ${AppUtil.paymentModeColor(data.payment_mode)}`}>
              {data.payment_mode_display}
            </span>
          }
        </td>
        <td className={'data-table-cell-lg'}>
          {data.payment_method_display}
          {!_.isEmpty(data.attributes) &&
            <React.Fragment>
              {data.attributes.pos_name && <p className={'mb-0 small text-muted'}>{data.attributes.pos_name}</p>}
              {data.attributes.event_name && <p className={' mb-0 small text-muted'}>{data.attributes.event_name}</p>}
              {data.attributes.booking_ota && <p className={'mb-0 small text-muted'}>{data.attributes.booking_ota}</p>}
            </React.Fragment>
          }
          {!_.isEmpty(data.booking_ota) && <p className={'mb-0 small text-muted'}>{data.booking_ota.name}</p>}
          {!_.isEmpty(data.event) && <p className={'mb-0 small text-muted'}>{data.event.name}</p>}
          {!_.isEmpty(data.pos) && <p className={'mb-0 small text-muted'}>{data.pos.name}</p>}
        </td>
        <td className={'data-table-cell-md'}>
          {(!_.isEmpty(data.attributes) && data.attributes.order_hash )&&
            <Link to={`/order/${data.attributes.order_hash}/`}>
              {data.attributes.order_ref_no}
            </Link>
          }
          {!_.isEmpty(data.order) &&
            <Link to={`/order/${data.order.hash}/`}>
              {data.order.ref_no}
            </Link>
          }
        </td>
        <td className={'data-table-cell-lg'}><small>{AppUtil.formatDateTime(data.created)}</small></td>
        <td className={'data-table-cell-md'}>
          <Link to={`/payment/${data.hash}`}>
            Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
          </Link>
        </td>
      </tr>
    );
  };

  emptyView = () => {
    return (
      <tr>
        <td colSpan={8}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faMoneyBillWave}/> No Payment(s) </h4>
            {Strings.paymentsEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };


  tableHeader = () => {
    return (
      <tr>
        <th>Reference</th>
        <th className={'text-left'}>Amount</th>
        <th/>
        <th>Method</th>
        <th>Order</th>
        <th>Date</th>
        <th/>
      </tr>
    );
  };


  render = () => {
    const {loading, error, clear, searching, results, fileDownload, refresh} = this.state;
    const {guestId, corporateId} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="payment-list screen-container">
        <Helmet>
          <title>Payment's List</title>
        </Helmet>

        <div className={'row mb-3'}>
          <div className={'col-12 col-sm-9'}>
            <ul className="list-inline list-item-mb-1">
              <li className="list-inline-item">
                <button className={'btn btn-outline-secondary btn-sm'} onClick={() => this.setState({refresh: true})}>
                  <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
                </button>
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Payment Status'}
                  data={Filters.paymentStatus}
                  selectKey={this.getQueryParams('payment_status')}
                  action={(data) => this.setQueryParams([{key: 'payment_status', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Refund Status'}
                  data={Filters.refundStatus}
                  selectKey={this.getQueryParams('refund_status')}
                  action={(data) => this.setQueryParams([{key: 'refund_status', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Payment Method'}
                  data={Filters.paymentMethod}
                  selectKey={this.getQueryParams('payment_method')}
                  action={(data) => this.setQueryParams([{key: 'payment_method', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Settlement Status'}
                  data={Filters.settlementStatus}
                  selectKey={this.getQueryParams('settlement_status')}
                  action={(data) => this.setQueryParams([{key: 'settlement_status', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Payment Type'}
                  data={Filters.paymentType}
                  selectKey={this.getQueryParams('payment_type')}
                  action={(data) => this.setQueryParams([{key: 'payment_type', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterButton
                  title={'Payment Mode'}
                  data={Filters.paymentMode}
                  selectKey={this.getQueryParams('payment_mode')}
                  action={(data) => this.setQueryParams([{key: 'payment_mode', value: data.key}])}
                  size={'sm'}
                />
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.paymentStartDate}
                  endDate={this.state.paymentEndDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data, 'payment_'))}
                  size={'sm'}
                  maxDate={Moment().add(1, 'days')}
                  title={'Payment Date'}
                />
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.settlementStartDate}
                  endDate={this.state.settlementEndDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data, 'settlement_'))}
                  size={'sm'}
                  maxDate={Moment().add(1, 'days')}
                  title={'Settlement Date'}
                />
              </li>
              <li className="list-inline-item">
                <FilterDate
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  action={(data) => this.setQueryParams(AppWebUtil.processDate(data))}
                  size={'sm'}
                  maxDate={Moment().add(1, 'days')}
                />
              </li>
            </ul>
          </div>
          <div className={'col-12 col-sm-3 text-center text-sm-right'}>
            <ExportButton
              size={'sm'}
              files={['csv']}
              action={(key) => this.fetchData(this.state.page, null, null, {file: key})}
              loading={fileDownload}
              disabled={!(this.state.startDate || this.state.paymentDate)}

            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <SearchBar
              clear={clear}
              refresh={refresh}
              results={results}
              searching={searching}
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              tableHeader={this.tableHeader}
              searchFunction={(guestId || corporateId) ? null : this.fetchData}
              placeHolder="Payment ID"
              listVersion={'V2'}
              colSpan={7}
            />
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      searching: false,
      clear: false,
      results: [],
      urlParams: {},
      page: 1,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentList);
