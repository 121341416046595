/**
 *
 * Booking Amendment Details
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
// Consts and Libs
import AppUtil from '../../../lib/util';
import AppAPI from '../../../lib/api';
import {Constants, Filters, Strings} from '../../../constants';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {faLongArrowAltRight, faReceipt, faSync} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FilterButton from '../../../components/dashboard/FilterButton';
import PageSubHeader from '../../../components/dashboard/PageSubHeader';
// Actions

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  booking: state.booking.booking,
});

// Any actions to map to the component?
const mapDispatchToProps = {};


/* Component ==================================================================== */
class BookingAmendmentDetails extends Component {
  static componentName = 'BookingAmendmentDetails';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    if (this.props.match.params.bookingId) {
      this.fetchInitData();
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };

  fetchInitData = () => {
    this.setState({loading: true});

    let appApi = AppAPI.booking.get(`${this.props.match.params.bookingId}/related-details/?key=amendment`);

    if (this.props.match.params.bookingRoomId) {
      appApi = AppAPI.bookingapi.get(`booking-room/${this.props.match.params.bookingRoomId}/related-details/?key=amendment`);
    }

    appApi.then((res) => {
      this.setState({
        response: res,
        loading: false,
      });
    })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };


  render = () => {

    const {loading, error, response, amendmentStatus} = this.state;
    const {booking} = this.props;


    if (loading || _.isEmpty(booking) || _.isEmpty(response)) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    const {booking_amendment_requests} = response;


    let bookingAmendmentRequests = booking_amendment_requests;

    if (amendmentStatus || amendmentStatus === 0) {
      bookingAmendmentRequests = bookingAmendmentRequests.filter(item => (item.booking_amendment_status === amendmentStatus));
    }

    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Amendment Request List</title>
        </Helmet>

        <PageSubHeader icon={faReceipt} title={'Amendment Request(s)'} description={'Amendment request orders.'}/>

        <div className={'row'}>
          <div className={'col-md-4 col-12'}>
            <table className={'table table-borderless table-sm mb-3'}>
              <tbody>
                <tr>
                  <td className={'w-50 text-muted'}>Total Amendments</td>
                  <td><strong>{!_.isEmpty(bookingAmendmentRequests) ? bookingAmendmentRequests.length : 0}</strong></td>
                </tr>
                <tr>
                  <td className={'w-50 text-muted'}>Cancelled Amendments</td>
                  <td><strong>{!_.isEmpty(bookingAmendmentRequests) ? bookingAmendmentRequests.filter(item => (item.booking_amendment_status === Constants.BOOKING_AMENDMENT.CANCELED)).length : 0}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={'col-md-4 col-12'}>
            <table className={'table table-borderless table-sm mb-3'}>
              <tbody>
                <tr>
                  <td className={'w-50 text-muted'}>Completed Amendments</td>
                  <td>
                    <strong>{!_.isEmpty(bookingAmendmentRequests) ? bookingAmendmentRequests.filter(item => (item.booking_amendment_status === Constants.BOOKING_AMENDMENT.COMPLETED)).length : 0}</strong>
                  </td>
                </tr>
                <tr>
                  <td className={'w-50 text-muted'}>Pending Amendments</td>
                  <td>
                    <strong>{!_.isEmpty(bookingAmendmentRequests) ? bookingAmendmentRequests.filter(item => (item.booking_amendment_status === Constants.BOOKING_AMENDMENT.REQUESTED)).length : 0}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={'spacer-20'}/>

        <ul className="list-inline list-item-mb-1">
          <li className="list-inline-item">
            <button onClick={() => this.fetchInitData()} className={'btn btn-sm btn-outline-secondary'}>
              <FontAwesomeIcon icon={faSync} className={'mr-2'} size={'sm'}/> Refresh
            </button>
          </li>
          <li className="list-inline-item">
            <FilterButton
              title={'Amendment Status'}
              data={Filters.bookingAmendmentValueFilter}
              selectKey={amendmentStatus}
              action={(data) => this.setState({amendmentStatus: data.key})}
              size={'sm'}
            />
          </li>
        </ul>

        <div className="table-responsive border">
          <table className={'table format-table table-hover mb-0'}>
            <thead>
              <tr>
                <th className={'data-table-cell-lg'}>Amendment</th>
                <th className={'data-table-cell-sm'}>Reference no</th>
                <th className={'data-table-cell-md'}/>
                <th className={'data-table-cell-md'}>Booking</th>
                <th className={'data-table-cell-md'}>Booking Room</th>
                <th className={'data-table-cell-xm'}>Date</th>
                <th className={'data-table-cell-xm'}/>
              </tr>
            </thead>
            <tbody>
              {!_.isEmpty(bookingAmendmentRequests) ?
                <React.Fragment>
                  {bookingAmendmentRequests.map((data, i)=>(
                    <tr key={i}>
                      <td className={'align-left'}>{!_.isEmpty(data.attributes) ? data.attributes.amendment : 'N.A' }</td>
                      <td className={'align-middle'}><small>{data.ref_no}</small></td>
                      <td className={'align-middle'}>
                        <span className={'badge ' + (AppUtil.bookingAmendmentStatusColor(data.booking_amendment_status))}>
                          {data.booking_amendment_status_display}
                        </span>
                      </td>
                      <td className={'align-middle'}>
                        {(!_.isEmpty(data.attributes) && data.attributes.booking_hash) ?
                          <Link to={`/booking/${data.attributes.booking_hash}`}>{data.attributes.booking_ref_no}</Link>
                          :
                          'N.A'
                        }
                      </td>
                      <td className={'align-middle'}>
                        {(!_.isEmpty(data.attributes) && data.attributes.booking_hash && data.attributes.booking_room_hash) ?
                          <Link to={`/booking/${data.attributes.booking_hash}/booking-room/${data.attributes.booking_room_hash}`}>
                            Room : {data.attributes.booking_room_number}
                          </Link>
                          :
                          'N.A'
                        }
                      </td>
                      <td className={'align-middle'}><small>{AppUtil.formatDateTime(data.created)}</small></td>
                      <td className={'align-middle'}>
                        <Link to={`/booking/${data.attributes.booking_hash}/amendment-request/${data.hash}`}>
                          Details <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
                        </Link>
                      </td>
                    </tr>
                  ))}

                </React.Fragment>
                :
                <tr>
                  <td colSpan={9}>
                    <div className={'text-center table-list-empty'}>
                      <h6><FontAwesomeIcon icon={faReceipt} className={'mr-2'}/> No Order </h6>
                      <p className={'text-muted'}>{Strings.bookingAmendmentEmpty}</p>
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      orderOpen: false,
      amendmentStatus: null,
      response: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(BookingAmendmentDetails);