/**
 * Date View
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import AppUtil from '../../lib/util';
import {Constants} from '../../constants';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBreadSlice,
  faGlassCheers,
  faGlassMartiniAlt,
  faStopCircle,
  faUtensils,
} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */
class PosItemTypeDetailsView extends Component {
  static componentName = 'PosItemTypeDetailsView';

  static propTypes = {
    item: PropTypes.object.isRequired,
    expand: PropTypes.bool,
    size: PropTypes.string,
  };

  static defaultProps = {
    expand: false,
    size: '',
  }

  render = () => {
    const {item, size} = this.props;

    let foodContent;

    switch (item.food_type) {
    case 1:
      foodContent = faGlassCheers;
      break;
    case 2:
      foodContent = faBreadSlice;
      break;
    default:
      foodContent = faUtensils;
    }

    return (
      <div className={'item-details item-detail-wrapper d-inline'}>
        <FontAwesomeIcon
          size={size}
          icon={foodContent}
          className={'mr-2 blue-cl'}
          title={item.food_type_display}
        />

        {(item.food_content !== Constants.FOOD_CONTENT_TYPE.N_A && item.food_type === Constants.POS_FOOD_TYPE.FOOD) &&
        <FontAwesomeIcon
          size={size}
          icon={faStopCircle}
          className={`mr-2 ${AppUtil.foodContentColor(item.food_content)}`}
          title={item.food_content_display}
        />
        }
        {(item.drink_content !== Constants.DRINK_CONTENT_TYPE.N_A && item.food_type === Constants.POS_FOOD_TYPE.DRINK) &&
        <FontAwesomeIcon
          size={size}
          icon={faGlassMartiniAlt}
          className={`mr-2 ${AppUtil.drinkContentColor(item.drink_content)}`}
          title={item.drink_content_display}
        />
        }
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default PosItemTypeDetailsView;