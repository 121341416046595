/**
 * CopyToClipboard
 * Copy to Clipboard button any data passed will be copied to clipboard on click
 */
import React from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import {faClipboard, faClipboardCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class CopyToClipboard extends React.PureComponent {
  static componentName = 'CopyToClipboard';

  static propTypes = {
    content: PropTypes.string,
    buttonClass: PropTypes.string,
    icon: PropTypes.bool,
    text: PropTypes.string,
  };

  static defaultProps = {
    content: '',
    icon: true,
    hiddenText: 'Click to Show',
    buttonClass: 'btn btn-outline-secondary',
    buttonClassCopied: 'btn btn-success',
  };

  copyText = () => {
    this.setState({copied: true}, () => {
      copy(this.props.content);
      setTimeout(() => this.setState({copied: false}), 1000);
    });
  };

  render = () => {
    const {copied} = this.state;
    const {text, buttonClass, buttonClassCopied, icon} = this.props;

    return (
      <div className={copied ? buttonClassCopied : buttonClass} onClick={() => this.copyText()}>
        {icon &&
          <FontAwesomeIcon
            icon={copied ? faClipboardCheck : faClipboard} size={'sm'}
            className={text && 'mr-2'}
          />
        }
        {text}
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      copied: false,
    };
  }
}

/* Export Component ==================================================================== */
export default CopyToClipboard;
