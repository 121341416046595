import { ErrorMessages } from '../constants';


const printerAPI = (posDetails, method, urlParams, body) => {

  const HOSTNAME = (posDetails.print_LRPR_address && posDetails.print_LRPR_port)
    ? `${posDetails.print_LRPR_address}:${posDetails.print_LRPR_port}`
    : 'http://127.0.0.1:50895';
  return new Promise(async (resolve, reject) => {

    const apiTimedOut = setTimeout(() => (reject(ErrorMessages.timeout)), 3000);

    // Build request
    const req = {
      method: method.toUpperCase(),
      headers:
        {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
    };

    // Add Body
    if (body) {
      req.body = JSON.stringify(body);
    }

    console.log(req);

    const thisUrl = `${HOSTNAME}/${urlParams || ''}`;
    const invalidJSONError = {message: ErrorMessages.invalidJson};
    // Make the request
    return fetch(thisUrl, req)
      .then(async (rawRes) => {
        // API got back to us, clear the timeout
        clearTimeout(apiTimedOut);
        let jsonRes = {};
        try {
          jsonRes = await rawRes.json();
        } catch (error) {
          return reject(invalidJSONError);
        }

        if ((rawRes.status === 200 || rawRes.status === 201)){
          return jsonRes;
        } else {
          throw jsonRes;
        }
      })
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => {
        console.log(err);
        clearTimeout(apiTimedOut);
        return reject(err);
      });
  });
};

export default printerAPI;
