/**
 * Profile search
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import queryString from 'query-string';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import {Constants, Strings} from '../../constants';
// Components
import Loading from '../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import GuestListView from './GuestListView';
import SearchBar from '../../components/forms/SearchBar';

/* Component ==================================================================== */

class ProfileQuickSearch extends Component {
  static componentName = 'ProfileQuickSearch';

  static propTypes = {
    search: PropTypes.bool,
    data: PropTypes.object,
    title: PropTypes.string,
    isGuest: PropTypes.bool,
    isAgent: PropTypes.bool,
    isVendor: PropTypes.bool,
    isCorporate: PropTypes.bool,
    corporatePOS: PropTypes.bool,
    corporateBooking: PropTypes.bool,
    showProfileDetails: PropTypes.func,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
        loading: true,
      });
    }
    return true;
  }


  preFillData = () => {
    let type = 'Guest', profile_type = Constants.PROFILE_TYPE.GUEST;
    if (this.props.isCorporate) {
      type = 'Corporate';
      profile_type = Constants.PROFILE_TYPE.CORPORATE;
    } else if (this.props.isAgent) {
      type = 'Agent';
      profile_type = Constants.PROFILE_TYPE.AGENT;
    } else if (this.props.isVendor) {
      type = 'Vendor';
      profile_type = Constants.PROFILE_TYPE.VENDOR;
    }
    this.setState(
      {
        type: type,
        results: [],
        init: false,
        clear: false,
        loading: false,
        searching: false,
        profile_type: profile_type
      },
    );
  };

  fetchData = (page = null, callback, searchText) => {
    const urlParams = {...this.state.urlParams};
    let baseUrl='guest';
    urlParams['profile_type'] = this.state.profile_type;
    if (page) {
      urlParams['cursor'] = page;
    }

    if (this.props.corporatePOS){
      urlParams['profile_pos'] = true;
    }

    if (this.props.corporateBooking){
      urlParams['profile_booking'] = true;
    }

    if (searchText) {
      this.setState({searching: true, clear: true});
      baseUrl = 'guest-search';
      urlParams['search'] = searchText;
      if (page){
        urlParams['page'] = page;
      }
    }

    AppAPI.guestapi.get(`${baseUrl}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        if (res.results.length > 0) {
          callback(
            res.results,
            {
              allLoaded: !res.next,
              nextParam: res.count ? AppUtil.getURLParam(res.next, 'page') : AppUtil.getURLParam(res.next, 'cursor')
            }
          );
        } else {
          callback();
        }
        this.setState({searching: false, clear: false, loading: false,});
      })
      .catch((err) => {
        callback([], {allLoaded: true});
        const error = AppAPI.handleError(err);
        this.setState({searching: false, clear: false, loading: false, error,});
      });
  };

  renderRow = (data, key) => {
    return (
      <GuestListView
        data={data}
        key={key}
        action={() => this.props.showProfileDetails(data.id, true)}
      />
    );
  };


  emptyView = () => {
    const {profile_type} = this.state;

    let image = require('../../images/drawings/Guests.png'),
      emptyText = Strings.guestListEmptyText;
    if (profile_type === Constants.PROFILE_TYPE.CORPORATE) {
      image = require('../../images/drawings/Corporates.png');
      emptyText = Strings.corporateListEmptyText;
    } else if (profile_type === Constants.PROFILE_TYPE.AGENT) {
      image = require('../../images/drawings/Corporates.png');
      emptyText = Strings.agentListEmptyText;
    } else if (profile_type === Constants.PROFILE_TYPE.VENDOR) {
      image = require('../../images/drawings/Corporates.png');
      emptyText = Strings.vendorListEmptyText;
    }


    return (
      <div className="empty">
        <div className="mx-auto featured-image">
          <img  className={'w-100'} alt={'Guest List'} src={image}/>
        </div>
        <div className="spacer-20"/>
        {emptyText.map((data, i) => <p key={i}>{data}</p>)}
      </div>
    );
  };

  render = () => {

    const {init, clear, searching, results, loading, type} = this.state;
    const {show} = this.props;

    if (show && init) {
      this.preFillData();
    }
    if (!show) {
      return null;
    }

    return (
      <Modal
        {...this.props}
        size={'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {type} Search
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? <Loading heightMatch={false}/>:
            <SearchBar
              clear={clear}
              results={results}
              searching={searching}
              renderRow={this.renderRow}
              fetchData={this.fetchData}
              emptyView={this.emptyView}
              searchFunction={this.fetchData}
              placeHolder="Name, phone or email"
              listVersion={'V2'}
            />
          }
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          <div className={'col'}>
          </div>
          <div className={'row'}>
            <div className={'col'}>
              <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
              </button>
            </div>

          </div>
        </Modal.Footer>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: true,
      clear: false,
      results: [],
      searching: false,
      urlParams: {}
    };
  }
}

/* Export Component ==================================================================== */
export default ProfileQuickSearch;
