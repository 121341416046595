/**
 *
 * Room List
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../../../lib/api';
import AppUtil from '../../../../lib/util';
import Strings from '../../../../constants/strings';
// Components
import Loading from '../../../../components/general/Loading';
import Error from '../../../../components/general/Error';
import ListView from '../../../../components/general/ListView';
import PropTypes from 'prop-types';
import PageHeader from '../../../../components/dashboard/PageHeader';

// Actions

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PropertyList extends Component {
  static componentName = 'RoomList';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };
  componentDidMount = () => {
    if (this.props.match.params.roomId) {
      this.setState({
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };
  fetchRoom = (page = 1, callback) => {
    AppAPI.roomapi.get('room-history/?page=' + page + '&room=' + this.props.match.params.roomId)
      .then((res) => {
        this.setState({
          loading: false,
          error: null,
        });
        if (res.results.length > 0) {
          if (res.next) {
            callback(res.results);
          } else {
            callback(res.results, {
              allLoaded: true, // the end of the list is reached
            });
          }
        } else {
          callback();
        }
      })
      .catch((err) => {
        callback(null, {
          allLoaded: true, // the end of the list is reached
        });
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };
  renderRow = (data, key) => {
    let booking_data = data.booking_room_data;
    return (
      <tr key={key}>
        <td>{AppUtil.formatDateTime(data.time)}</td>
        <td>
          <span
            className={'badge ' + (data.availability_status === -1 ? 'badge-danger' : data.availability_status === 1 ? 'badge-success' : 'badge-info')}>
            {data.availability_status_display}
          </span>
        </td>
        <td>
          <span className={'badge ' + (data.service_status === 1 ? 'badge-success' : 'badge-danger')}>
            {data.service_status_display}
          </span>
        </td>
        <td>
          <span className={'badge ' + (data.maintenance_status === 1 ? 'badge-success' : 'badge-danger')}>
            {data.maintenance_status_display}
          </span>
        </td>
        <td>
          {booking_data ?
            <Link
              to={'/booking/' + booking_data.booking_id + '/booking-room/' + booking_data.id}
              className={'btn btn-sm btn-link'}>
              View Booking
            </Link>
            : 'N.A'
          }
        </td>
      </tr>
    );
  };
  emptyView = () => {
    return (
      <tr>
        <td>
          <div className="empty">
            <div className="mx-auto featured-image">
              <img className={'w-100'} alt={'Property List'} src={require('../../../../images/drawings/Rooms.png')}/>
            </div>
            <div className="spacer-20"/>
            {
              Strings.roomListEmptyText.map((data, i) => <p key={i}>{data}</p>)
            }
          </div>
        </td>
      </tr>
    );
  };
  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="room-list screen-container ">
        <Helmet>
          <title>Maintenance History Rooms</title>
          <p>Maintenance records for the room.</p>
        </Helmet>


        <PageHeader
          subHeader={true} history={this.props.history} title={'Maintenance History'}
          description={'Room maintenance history'}
        />

        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className={'table-responsive'}>
              <table className={'table table-hover text-center'}>
                <thead>
                  <tr>
                    <td>Time</td>
                    <td>Availability</td>
                    <td>Service</td>
                    <td>Operation</td>
                    <td>Booking</td>
                  </tr>
                </thead>
                <tbody>
                  <ListView
                    rowView={this.renderRow}
                    onFetch={this.fetchRoom}
                    firstLoader={true}
                    pagination
                    emptyView={this.emptyView}
                    class={'list-group'}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyList);