/**
 *
 * Order Analytics Source Distribution
 */
import _ from 'lodash';
import Moment from 'moment';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import React, { Component } from 'react';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquare } from '@fortawesome/free-solid-svg-icons';
// Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import {AnalyticConstants, Constants} from '../../../constants';
import OAOverviewModel from './models/OAOverviewModel';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class OrderAnalyticsSourceDistribution extends Component {
  static componentName = 'OrderAnalyticsSourceDistribution';

  static propTypes = {
    pos: PropTypes.string,
    endDate: PropTypes.object,
    startDate: PropTypes.object
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {startDate, endDate, dateType, pos} = this.props;
    this.setState({loading: true});
    const queryParams = {
      'date_type': dateType,
      'pos': pos ? pos : null,
      'report_type': 'overview',
      'start_date': (startDate ? startDate : Moment()).format('YYYY-MM-DD'),
      'end_date': endDate ? endDate.format('YYYY-MM-DD') : null
    };
    AppAPI[pos ? 'posapi' : 'orderapi'].get(`order-analytics/?${queryString.stringify(queryParams)}`)
      .then((res) => {
        this.setState({loading: false, response: res});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({loading: false, error: error});
      });
  };

  render = () => {
    const {loading, error, response} = this.state;
    const {property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    // Initialize with empty array for order data set
    const orderDataSet = {};
    Object.keys(AnalyticConstants.orderAnalytics).forEach((data)=>{
      orderDataSet[data] = {};
    });

    // Containers for order status data & order source data
    const orderData = {
      orderStatusData: _.cloneDeep(orderDataSet),
      orderSourceData: {},
      orderTypeData: {}
    };

    Object.values(Constants.ORDER_SOURCES_INPUT).forEach((data)=>{
      orderData.orderSourceData[data.value] = _.cloneDeep(orderDataSet);
    });

    Object.values(Constants.ORDER_TYPE_INPUT).forEach((data)=>{
      orderData.orderTypeData[data.value] = _.cloneDeep(orderDataSet);
    });


    if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
      const responseData = response.data;
      if (!_.isEmpty(responseData.order_status_source_data)) {
        responseData.order_status_source_data.forEach((data) => {
          Object.entries(AnalyticConstants.orderAnalytics).forEach(([key, value])=>{
            if (data[value]) {
              AppUtil.insertOrAddObject(orderData.orderStatusData[key], data.order_status, data[value], 'float');
              AppUtil.insertOrAddObject(orderData.orderSourceData[data.order_source][key], data.order_status, data[value], 'float');
            }
          });
        });
      }

      if (!_.isEmpty(responseData.order_status_type_data)) {
        responseData.order_status_type_data.forEach((data) => {
          Object.entries(AnalyticConstants.orderAnalytics).forEach(([key, value])=>{
            if (data[value]) {
              AppUtil.insertOrAddObject(orderData.orderTypeData[data.order_type][key], data.order_status, data[value], 'float');
            }
          });
        });
      }
    }

    const orderTotalPayment = (
      AppUtil.sumArray(Object.values(orderData.orderStatusData.orderTotal), 'float') -
      (orderData.orderStatusData.orderTotal[Constants.ORDER_STATUS.CANCELLED] || 0)
    );

    const totalOrders = (AppUtil.sumArray(Object.values(orderData.orderStatusData.orderTotal), 'float')-(orderData.orderStatusData.orderTotal[Constants.ORDER_STATUS.CANCELLED] || 0)).toFixed(2);
    const totalOrdersCount = AppUtil.sumArray(Object.values(orderData.orderStatusData.orderCount)) - (orderData.orderStatusData.orderCount[Constants.ORDER_STATUS.CANCELLED] || 0);

    const paidOrdersPercentage = AppUtil.calculatePercentage(
      AppUtil.sumArray(Object.values(orderData.orderStatusData.orderPaidPayment), 'float'),
      totalOrders
    );

    return (
      <div className="screen-container">
        <Helmet>
          <title>Order Overview</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          title={'Source Overview'}
          description={'Sales bifurcation based on source.'}
        />

        <div className={'row mb-3'}>
          <div className={'col'}>
            <div className={'card mb-5'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col-6'}>
                    <p className={'text-muted mb-1'}>Total Active Order(s)</p>
                    <h4 className={'card-title mb-3'}>
                      {`${property.currency} ${orderTotalPayment.toFixed(2)}`}
                    </h4>
                    <p className={'text-muted mb-2'}>
                      {totalOrdersCount} order entries.
                    </p>
                  </div>
                  <div className={'col-6'}>
                    <p className={'text-muted mb-1'}>Cancelled</p>
                    <h4 className={'grey-cl mb-3'}>
                      {`${property.currency} ${orderData.orderStatusData.orderTotal[Constants.PAYMENT_STATUS.CANCELLED] || 0}`}
                    </h4>
                    <p className={'text-muted mb-2'}>
                      {orderData.orderStatusData.orderCount[Constants.PAYMENT_STATUS.CANCELLED] || 0} order entries.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'col'}>
            <div className={'card'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col '}>
                    <p className={'text-muted mb-1'}>Total Paid</p>
                    <h5 className={'card-title mb-0 pb-0 green-cl'}>
                      {`${property.currency} ${AppUtil.sumArray(Object.values(orderData.orderStatusData.orderPaidPayment), 'float')}`}
                    </h5>
                  </div>
                  <div className={'col'}>
                    <p className={'text-muted mb-1'}>Pending Payment</p>
                    <h5 className={'card-title mb-0 pb-0 red-cl'}>
                      {`${property.currency} ${(totalOrders - AppUtil.sumArray(Object.values(orderData.orderStatusData.orderPaidPayment), 'float')).toFixed(2)}`}
                    </h5>
                  </div>
                </div>
                <div className="progress mt-3 red-bg">
                  <div
                    className="progress-bar green-bg"
                    style={{width: `${paidOrdersPercentage}%`}}
                    aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"/>
                </div>
                <div className={'row'}>
                  <div className={'col-8'}>
                    <p className={'text-muted mb-0 mt-1 small'}>
                      Count - Paid : {totalOrdersCount - (orderData.orderStatusData.orderCount[Constants.ORDER_STATUS.NOTPAID] || 0)} |
                      Pending : {(orderData.orderStatusData.orderCount[Constants.ORDER_STATUS.NOTPAID] || 0)}
                    </p>
                  </div>
                  <div className={'text-right col'}>
                    <p className={'text-muted mb-0 mt-1 small'}>{paidOrdersPercentage}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={'row'}>
          <div className={'col-12 col-lg-12 mb-3'}>
            <div className="table-responsive">
              <table className={'table table-bordered'}>
                <thead>
                  <tr>
                    <th className={'data-table-cell-md'}>Order Source</th>
                    <th className={'data-table-cell-sm'}>Count</th>
                    <th className={'data-table-cell-md'}>Total <small>({property.currency})</small></th>
                    <th className={'data-table-cell-md'}>Pending <small>({property.currency})</small></th>
                    <th className={'data-table-cell-md'}>Paid <small>({property.currency})</small></th>
                    <th className={'data-table-cell-md'}>Refunded <small>({property.currency})</small></th>
                  </tr>
                </thead>
                <tbody>
                  {Constants.ORDER_SOURCES_INPUT.map((data, i) => {
                    const sourceTotalPayment = (
                      AppUtil.sumArray(Object.values(orderData.orderSourceData[data.value].orderTotal), 'float') -
                      (orderData.orderSourceData[data.value].orderTotal[Constants.ORDER_STATUS.CANCELLED] || 0)
                    );
                    const sourcePaidPayment = AppUtil.sumArray(Object.values(orderData.orderSourceData[data.value].orderPaidPayment, 'float'));

                    return(
                      <tr key={i}>
                        <td className={'data-table-cell-lg'}>
                          <FontAwesomeIcon
                            onClick={()=>this.setState({
                              title: `Sales Overview : ${data.label}`,
                              showPAOverview: true,
                              showPAOrderData: orderData.orderSourceData[data.value]
                            })}
                            icon={faExternalLinkSquare}
                            className={'mr-2 a-class'}
                          />
                          {data.label}
                        </td>
                        <td className={'data-table-cell-sm text-center'}>
                          {(AppUtil.sumArray(Object.values(orderData.orderSourceData[data.value].orderCount))-(orderData.orderSourceData[data.value].orderCount[Constants.ORDER_STATUS.CANCELLED] || 0))}
                        </td>
                        <td className={'data-table-cell-sm text-center'}>
                          {sourceTotalPayment.toFixed(2)}
                        </td>
                        <td className={'data-table-cell-md text-center'}>
                          {(sourceTotalPayment - sourcePaidPayment).toFixed(2)}
                        </td>
                        <td className={'data-table-cell-md text-center'}>
                          {sourcePaidPayment.toFixed(2)}
                        </td>
                        <td className={'data-table-cell-md text-center'}>
                          {AppUtil.sumArray(Object.values(orderData.orderSourceData[data.value].orderRefundedPayment), 'float')}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td><strong>Total</strong></td>
                    <td className={'text-center'}>
                      <strong>{AppUtil.sumArray(Object.values(orderData.orderStatusData.orderCount)) - (orderData.orderStatusData.orderCount[Constants.ORDER_STATUS.CANCELLED] || 0)}</strong>
                    </td>
                    <td className={'text-center'}>
                      <strong>
                        {orderTotalPayment.toFixed(2)}
                      </strong>
                    </td>
                    <td className={'text-center'}>
                      <strong>
                        {(orderTotalPayment - AppUtil.sumArray(Object.values(orderData.orderStatusData.orderPaidPayment), 'float')).toFixed(2)}
                      </strong>
                    </td>
                    <td className={'text-center'}>
                      <strong>
                        {AppUtil.sumArray(Object.values(orderData.orderStatusData.orderPaidPayment), 'float')}
                      </strong>
                    </td>
                    <td className={'text-center'}>
                      <strong>
                        {AppUtil.sumArray(Object.values(orderData.orderStatusData.orderRefundedPayment), 'float')}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <OAOverviewModel
          property={property}
          title={this.state.title}
          show={this.state.showPAOverview}
          showPAOverview={this.state.showPAOverview}
          orderData={this.state.showPAOrderData}
          onHide={()=>this.setState({showPAOverview: false, showPAOrderData: {}, title: null})}
        />

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      showPAOverview: false,
      showPAOrderData: {},
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderAnalyticsSourceDistribution);
