/**
 * Expense Actions
 *
 */

import AppAPI from '../../lib/api';

/**
 * Set Expense
 */
export const setExpense = (expenseData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof expenseData === 'object') {
    dispatch({
      type: 'SET_EXPENSE',
      data: expenseData
    });
    return resolve(expenseData);
  } else if (expenseData) {
    // Fetch Expense Data
    return AppAPI.expense.get(expenseData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_EXPENSE',
          data: res
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Expense
 */
export const updateExpense = (expenseData) => setExpense(expenseData);

/**
 * Remove Expense
 */
export function unSetExpense() {
  return (dispatch) => {
    dispatch({
      type: 'SET_EXPENSE',
      data: null
    });
  };
}


/**
 * Set Expense Payment
 */
export const setExpensePayment = (expensePaymentData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof expensePaymentData === 'object') {
    dispatch({
      type: 'SET_EXPENSE_PAYMENT',
      data: expensePaymentData
    });
    return resolve(expensePaymentData);
  } else if (expensePaymentData) {
    // Fetch Expense Data
    return AppAPI.expenseapi.get('payments/' + expensePaymentData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_EXPENSE_PAYMENT',
          data: res
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Expense Payment
 */
export const updateExpensePayment = (expensePaymentData) => setExpensePayment(expensePaymentData);

/**
 * Remove Expense
 */
export function unSetExpensePayment() {
  return (dispatch) => {
    dispatch({
      type: 'SET_EXPENSE_PAYMENT',
      data: null
    });
  };
}


/**
 * Set Expense Department
 */
export const setExpenseDepartment = (expenseDepartmentData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof expenseDepartmentData === 'object') {
    dispatch({
      type: 'SET_EXPENSE_DEPARTMENT',
      data: expenseDepartmentData
    });
    return resolve(expenseDepartmentData);
  } else if (expenseDepartmentData) {
    // Fetch Expense Data
    return AppAPI.expenseapi.get('department/' + expenseDepartmentData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_EXPENSE_DEPARTMENT',
          data: res
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Expense Department
 */
export const updateExpenseDepartment = (expenseDepartmentData) => setExpenseDepartment(expenseDepartmentData);

/**
 * Remove Department
 */
export function unSetExpenseDepartment() {
  return (dispatch) => {
    dispatch({
      type: 'SET_EXPENSE_DEPARTMENT',
      data: null
    });
  };
}


/**
 * Set Expense Category
 */
export const setExpenseCategory = (expenseCategoryData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof expenseCategoryData === 'object') {
    dispatch({
      type: 'SET_EXPENSE_CATEGORY',
      data: expenseCategoryData
    });
    return resolve(expenseCategoryData);
  } else if (expenseCategoryData) {
    // Fetch Expense Data
    return AppAPI.expenseapi.get('category/' + expenseCategoryData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_EXPENSE_CATEGORY',
          data: res
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Expense Category
 */
export const updateExpenseCategory = (expenseCategoryData) => setExpenseCategory(expenseCategoryData);

/**
 * Remove Category
 */
export function unSetExpenseCategory() {
  return (dispatch) => {
    dispatch({
      type: 'SET_EXPENSE_CATEGORY',
      data: null
    });
  };
}


/**
 * Set Expense Vendor
 */
export const setExpenseVendor = (expenseVendorData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof expenseVendorData === 'object') {
    dispatch({
      type: 'SET_EXPENSE_VENDOR',
      data: expenseVendorData
    });
    return resolve(expenseVendorData);
  } else if (expenseVendorData) {
    // Fetch Expense Data
    return AppAPI.expenseapi.get('vendor/' + expenseVendorData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_EXPENSE_VENDOR',
          data: res
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Expense Vendor
 */
export const updateExpenseVendor = (expenseVendorData) => setExpenseVendor(expenseVendorData);

/**
 * Remove Vendor
 */
export function unSetExpenseVendor() {
  return (dispatch) => {
    dispatch({
      type: 'SET_EXPENSE_VENDOR',
      data: null
    });
  };
}