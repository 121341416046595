/**
 *
 * Plan
 * overview of billing Plans
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';

// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import Strings from '../../constants/strings';
import {Link} from 'react-router-dom';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  user: state.user
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class Plans extends Component {
  static componentName = 'Plans';

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    AppAPI.account.get('subscription/')
      .then((res) => {
        this.setState({
          loading: false,
          subscription: res
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error: error,
          resultMsg: {error}
        });
      });
  };

  cancelPlan = () => {
    this.setState({loading: true}, () => {
      this.setState({resultMsg: {status: 'One moment...'}});

      AppAPI.account.post('subscription/cancel/')
        .then((res) => {
          this.setState({
            loading: false,
            subscription: res
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({loading: false, resultMsg: {error}});
        });
    });
  };

  render = () => {
    const {loading, error, subscription} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;


    return (
      <div className="room-view screen-container">
        <Helmet>
          <title>Subscription Summary</title>
        </Helmet>


        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="page-header">
              <h3>Subscription Summary</h3>
              <p className="mb-1">Your account subscription summary</p>
            </div>
            {
              (subscription && subscription.plan)
                ?
                <div>
                  <div className={'row'}>
                    <div className={'col-6'}>
                      <h3>{subscription.plan ? subscription.plan.name : 'N.A'}</h3>
                      <p className={'mb-1'}>
                        {subscription.plan_type === 0 ? subscription.plan.price_30 : subscription.plan.price_365} {subscription.plan.currency} / {subscription.get_plan_type_display}
                      </p>
                      {subscription.end !== null &&
                       <p className={'mb-1 text-secondary'}>Expires
                         on {subscription.end !== null ? AppUtil.formatDateTime(subscription.end, 'datef') : 'N.A'} </p>
                      }
                      <p className={'mb-1'}>
                        <span className={'badge ' + AppUtil.subscriptionStatusColor(subscription.subscription_status)}>
                          {subscription.get_subscription_status_display}
                        </span>
                      </p>
                      <p className={'mb-1'}>
                        <span className={'badge ' + (subscription.auto_renew ? 'badge-success' : 'badge-danger')}>
                          {subscription.auto_renew ? 'Auto Renew' : 'Cancel/Downgrade at end of subscription'}
                        </span>
                      </p>
                    </div>
                    <div className={'col-6'}>
                      {(subscription.stripe_payment_information && subscription.stripe_payment_information.card) &&
                       <div>
                         <h6>Payment Information</h6>
                         <p className={'mb-1'}>
                           {subscription.stripe_payment_information.card.brand} - {subscription.stripe_payment_information.card.last4}
                         </p>
                         <p className={'mb-1'}>
                           Expiry
                           : {subscription.stripe_payment_information.card.exp_month}/{subscription.stripe_payment_information.card.exp_year}
                         </p>
                       </div>
                      }
                    </div>
                  </div>
                  {(subscription.plan && subscription.plan.downgrade) &&
                   <div className={'mt-5'}>
                     <p>
                       <a
                         data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false"
                         aria-controls="collapseExample">
                         Cancel or Downgrade subscription
                       </a>

                     </p>
                     <div className="collapse" id="collapseExample">
                       <div className="card card-body">
                         {subscription.auto_renew ?
                           <div>
                             <p>Your subscription will be downgraded to free plan
                               {subscription.end !== null ?
                                 <span> on {AppUtil.formatDateTime(subscription.end, 'datef')}</span> : 'at the end of this subscription'}</p>
                             <button
                               className="btn btn-danger" type="button" data-toggle="collapse"
                               onClick={() => this.cancelPlan()}>
                               Cancel Subscription
                             </button>
                           </div>
                           :
                           <div>
                             <p>Your subscription is scheduled for cancellation at end of this term.</p>
                           </div>}

                       </div>
                     </div>
                   </div>
                  }
                </div>
                : <div className="empty">
                  <h2>{Strings.billingEmptyTitle}</h2>
                  {
                    Strings.billingEmptyText.map((data, i) => <p key={i}>{data}</p>)
                  }

                  <div className="spacer-20"/>
                </div>
            }
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            {subscription.property && subscription.property.length < 1 ?
              <div className={'text-center'}>
                <div className="mx-auto px-5 mt-2 featured-image">
                  <img className={'w-100'} alt={'Property List'} src={require('../../images/drawings/Property.png')}/>
                </div>

                <h3>{Strings.billingConsumptionEmptyTitle}</h3>
                {
                  Strings.billingConsumptionEmptyDescription.map((data, i) => <p key={i}>{data}</p>)
                }
                <Link to={'/property/create'} className="btn btn-success">
                  Get Started <span className="fa fa-arrow-right"/>
                </Link>


              </div>
              : <div>
                <ul className="list-group mt-3">
                  <li className="list-group-item">
                    <p className="mb-1"><strong>No of Properties
                      :</strong> {subscription.property ? subscription.property.length : 0}
                    </p>
                  </li>
                </ul>
              </div>}

          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      plans: [],
      orderData: []
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
