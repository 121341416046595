/**
 *
 * POS Details
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Link, NavLink, Redirect, Route, Switch} from 'react-router-dom';
// Consts and Libs

import AppAPI from '../../lib/api';
// Components
import {Constants} from '../../constants';
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import PageHeader from '../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowLeft, faLongArrowAltRight, faPrint, faSync } from '@fortawesome/free-solid-svg-icons';

import POSOrder from './posOrder';
import POSAnalytics from './analytics';
import POSRoomList from './POSRoomList';
import POSOverView from './POSOverView';
import POSTableList from './POSTableList';
import POSItemsList from './POSItemsList';
import POSBillingOrder from './posBillingOrder';
import POSTableDetails from './POSTableDetails';
import POSTableHistory from './POSTableHistory';
import POSOrderKOTList from './POSOrderKOTList';
import POSOrderListIndex from './POSOrderListIndex';
import POSOrderKOTDetails from './POSOrderKOTDetails';
import POSPrinterManage from './models/POSPrinterManage';
import ReportsListIndex from '../reports/ReportsListIndex';
import POSActiveOrdersView from './models/POSActiveOrdersView.js';
import POSOrderKOTHistoryHistory from './POSOrderKOTHistoryHistory';

// Actions
import * as POSActions from '../../redux/pos/actions';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  posDetails: state.pos.posDetails,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPOSDetails: POSActions.setPOSDetails,
};

/* Component ==================================================================== */
class POSDetails extends Component {
  static componentName = 'POSDetails';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
  };


  fetchInitData = (refresh = false) => {
    this.setState({loading: true});
    this.props.setPOSDetails(this.props.match.params.POSId, refresh, false)
      .then(() => {
        this.setState({
          loading: false,
        });
        if (refresh === false) {
          this.props.setPOSDetails(this.props.match.params.POSId, true, false);
        }
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };

  render = () => {

    const {loading, error} = this.state;
    const {posDetails} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    let baseUrl = '/point-of-sale';
    let absBaseUrl = `${baseUrl}/${this.props.match.params.POSId}`;
    const menu = [
      {
        title: 'Overview',
        link: `${absBaseUrl}/`,
        isExact: true,
      },
      {
        title: 'Product(s)',
        link: `${absBaseUrl}/products/`,
        isExact: true,
      },
      {
        title: 'Order / Tab(s)',
        link: `${absBaseUrl}/pos-order/`,
      },
    ];

    if (posDetails.pos_has_table) {
      menu.push(
        {
          title: 'Table(s)',
          link: `${absBaseUrl}/tables/`,
        }
      );
    }

    if (posDetails.pos_has_room) {
      menu.push(
        {
          title: 'Room(s)',
          link: `${absBaseUrl}/rooms/`,
        }
      );
    }

    if (posDetails.pos_has_kot) {
      menu.push(
        {
          title: 'KOT(s)',
          link: `${absBaseUrl}/pos-order-kot/`,
        }
      );
    }

    // Pushing last to get menu item at the end
    menu.push(
      {
        title: 'Billing Order',
        link: `${absBaseUrl}/pos-billing-order/`,
        isExact: false,
      },
      {
        title: 'Reports',
        link: `${absBaseUrl}/reports/list`,
        isExact: false,
      },
      {
        title: 'Analytics',
        link: `${absBaseUrl}/analytics`,
        isExact: false,
      }
    );

    let menuItems = [];
    menu.map((item) => {
      const {title, link, isExact} = item;
      return menuItems.push(
        <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
          <NavLink
            to={{
              pathname: link,
              state: {fromDashboard: true},
            }}
            exact={isExact}
            className="nav-link rounded-0"
            activeClassName="selected">
            {title}
          </NavLink>
        </li>,
      );
    });

    const {analytics} = posDetails;
    const defaultValue = _.isEmpty(analytics) ? '...' : 0;
    const POSOrderData = {
      'tabsActive': {
        'title': 'Active Tab(s)',
        'class': 'yellow-bg black-cl',
        'key': Constants.POS_ORDER_STATUS.OPEN,
        'count': defaultValue,
        'total': defaultValue,
        'occupancy': defaultValue
      },
      'tabsHold': {
        'title': 'Hold Tab(s)',
        'class': 'blue-bg white-cl',
        'key': Constants.POS_ORDER_STATUS.HOLD,
        'count': defaultValue,
        'total': defaultValue,
        'occupancy': defaultValue
      },
    };

    if (!_.isEmpty(analytics)) {
      if (!_.isEmpty(analytics.pos_orders_status)) {
        analytics.pos_orders_status.forEach((data) => {
          switch (data.pos_order_status) {
          case  Constants.POS_ORDER_STATUS.OPEN:
            POSOrderData['tabsActive']['count'] = data.count;
            POSOrderData['tabsActive']['total'] = data.total;
            POSOrderData['tabsActive']['occupancy'] = data.occupancy;
            break;
          case  Constants.POS_ORDER_STATUS.HOLD:
            POSOrderData['tabsHold']['count'] = data.count;
            POSOrderData['tabsHold']['total'] = data.total;
            POSOrderData['tabsHold']['occupancy'] = data.occupancy;
            break;
          default:
            break;
          }
        });
      }
    }

    return (
      <div className="booking-details screen-container ">
        <Helmet>
          <title>Point of Sale | Overview</title>
        </Helmet>

        <Link to={'/point-of-sale/'} className={'btn btn-outline-dark mr-2 btn-sm mt-2'}>
          <FontAwesomeIcon icon={faArrowLeft} size={'sm'} className={'mr-2'}/> Point of Sales List
        </Link>

        <button onClick={() => this.fetchInitData(true)} className={'btn btn-outline-dark mr-2 btn-sm mt-2'}>
          <FontAwesomeIcon icon={faSync} size={'sm'} className={'mr-2'}/> Refresh
        </button>

        {posDetails.print_allow_LPRP &&
          <button onClick={() => this.setState({showPOSPrinterManage: true})} className={'btn btn-outline-dark mr-2 btn-sm mt-2'}>
            <FontAwesomeIcon icon={faPrint} size={'sm'} className={'mr-2'}/> Print Config
          </button>
        }

        <div className={'row'}>
          <div className={'col-sm-12 col-md-8'}>
            <PageHeader
              border={false}
              history={this.props.history} title={posDetails.name}
            />
          </div>
          <div className={'col-sm-12 col-md-4'}>
            <div className={'row align-items-center'}>
              {Object.entries(POSOrderData).map(([key, value]) => (
                <div className={'col-6'} key={key}>
                  <div
                    className={`${value.class} p-2 card a-class my-3`}
                    onClick={()=>this.setState({showPOSActiveOrdersView: true, posOrderStatus: value.key})}
                  >
                    <small>{value.title}</small>
                    <h6 className={'card-title mb-0 pb-0'}>
                      {`${value.count} Nos`}
                      <small>{value.occupancy > 0 ? ` / ${value.occupancy} Cover(s)` : ''}</small>
                      <FontAwesomeIcon icon={faLongArrowAltRight} className={'float-right'}/>
                    </h6>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <ul className="nav nav-tabs mb-4">
          {menuItems}
        </ul>

        <Switch>
          <Route path={`${baseUrl}/:POSId/`} exact component={POSOverView}/>
          <Route path={`${baseUrl}/:POSId/products/`} exact component={POSItemsList}/>

          <Route path={`${baseUrl}/:POSId/tables/`} exact component={POSTableList}/>
          <Route path={`${baseUrl}/:POSId/rooms/`} exact component={POSRoomList}/>
          <Route path={`${baseUrl}/:POSId/pos-billing-order/`} component={POSBillingOrder}/>
          <Route path={`${baseUrl}/:POSId/tables/:POSTableId`} exact component={POSTableDetails}/>
          <Route path={`${baseUrl}/:POSId/tables/:POSTableId/history`} exact component={POSTableHistory}/>

          <Route
            exact path={`${absBaseUrl}/pos-order/`} render={() => (<Redirect to={`${absBaseUrl}/pos-order/list`}/>)}
          />
          <Route
            path={`${absBaseUrl}/pos-order/list`} exact
            render={(props) =>
              <POSOrderListIndex
                baseUrl={`${absBaseUrl}`}
                {...props}
              />
            }
          />
          <Route path={`${baseUrl}/:POSId/pos-order/:POSOrderId`} exact component={(POSOrder)}/>
          <Route path={`${baseUrl}/:POSId/pos-order-kot/`} exact component={(POSOrderKOTList)}/>
          <Route path={`${baseUrl}/:POSId/pos-order-kot/:POSOrderKOTId/`} exact component={(POSOrderKOTDetails)}/>
          <Route
            path={`${baseUrl}/:POSId/pos-order-kot/:POSOrderKOTId/history`} exact
            component={(POSOrderKOTHistoryHistory)}
          />

          <Route
            exact
            path={`${baseUrl}/:POSId/reports/`}
            render={() => (<Redirect to={`${absBaseUrl}/reports/list`}/>)}
          />

          <Route
            path={`${baseUrl}/:POSId/reports/list`} exact
            render={(props) =>
              <ReportsListIndex
                {...props}
                pos={posDetails}
                hideHeader={true}
                source={Constants.REPORT_SOURCE.POINT_OF_SALE}
                reportOptions={Constants.REPORTS_POST}
                baseUrl={absBaseUrl}
              />
            }
          />
          <Route path={`${baseUrl}/:POSId/analytics/`} component={POSAnalytics}/>
        </Switch>
        <POSActiveOrdersView
          posDetails={posDetails}
          posOrderStatus={this.state.posOrderStatus}
          show={this.state.showPOSActiveOrdersView}
          onHide={()=>this.setState({showPOSActiveOrdersView: false})}
          history={this.props.history}
        />
        <POSPrinterManage
          posDetails={posDetails}
          show={this.state.showPOSPrinterManage}
          onHide={()=>this.setState({showPOSPrinterManage: false})}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      showPOSPrinterManage: false,
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(POSDetails);
