import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import {Constants} from '../../constants';

import PaymentView from './PaymentView';
import PaymentAnalytics from './Analytics';
import PaymentDetails from './PaymentDetails';
import PaymentListIndex from './PaymentListIndex';
import PGatewayView from './PGateway/PGatewayView';
import PGatewayList from './PGateway/PGatewayList';
import PTerminalView from './PTerminal/PTerminalView';
import PTerminalList from './PTerminal/PTerminalList';
import PGatewayDetails from './PGateway/PGatewayDetails';
import PTerminalDetails from './PTerminal/PTerminalDetails';
import PGatewayTransferDetails from './PGateway/PGatewayTransferDetails';

import NotFound from '../NotFound';

import RefundView from '../refund/RefundView';
import RefundDetails from '../refund/RefundDetails';
import RefundListIndex from '../refund/RefundListIndex';

import ReportsListIndex from '../reports/ReportsListIndex';

import paymentRequired from '../../components/decorators/paymentRequired';

const PaymentRoute = () => (
  <div>

    <div className="container">
      <Switch>
        <Route
          exact
          path={'/payment/reports/'}
          render={() => (<Redirect to={'/payment/reports/list'}/>)}
        />

        <Route
          path={'/payment/reports/list'} exact
          render={(props) =>
            <ReportsListIndex
              {...props}
              baseUrl={'/payment'}
              source={Constants.REPORT_SOURCE.PAYMENT}
              headerTitle={'Payment Report\'s'}
              headerSubTitle={'Consolidated payment reports.'}
              reportOptions={Constants.REPORTS_PAYMENT}
            />
          }
        />

        <Route path="/payment/refund/" exact component={RefundView}/>
        <Route path="/payment/refund/list" component={RefundListIndex}/>
        <Route path="/payment/refund/:refundId" exact component={RefundDetails}/>

        <Route path="/payment/" exact component={PaymentView}/>
        <Route path="/payment/add" exact component={PaymentView}/>
        <Route path="/payment/list" component={PaymentListIndex}/>

        <Route path="/payment/payment-gateway/" exact component={PGatewayView}/>
        <Route path="/payment/payment-gateway/list/" component={PGatewayList}/>

        <Route path="/payment/payment-terminal/" exact component={PTerminalView}/>
        <Route path="/payment/payment-terminal/list/" component={PTerminalList}/>
        <Route path="/payment/payment-terminal/:pterminalId/" exact component={PTerminalDetails}/>

        <Route path="/payment/payment-gateway/:pgatewayId/" exact component={PGatewayDetails}/>
        <Route path="/payment/payment-gateway/:pgatewayId/transfer/:transferId" exact component={PGatewayTransferDetails}/>


        <Route path="/payment/analytics/" component={PaymentAnalytics}/>
        <Route path="/payment/:paymentId" component={paymentRequired(PaymentDetails)}/>

        <Route exact component={NotFound}/>
      </Switch>
    </div>

  </div>
);

export default PaymentRoute;
