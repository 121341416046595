/**
 * POS Order Item List View
 *
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faPen} from '@fortawesome/free-solid-svg-icons';
import PosItemTypeDetailsView from '../../../components/dashboard/PosItemTypeDetailsView';

/* Component ==================================================================== */
class POSOrderItemListView extends Component {
  static componentName = 'POSOrderItemListView';

  static propTypes = {
    lock: PropTypes.bool,
    data: PropTypes.object,
    disabled: PropTypes.bool,
    currency: PropTypes.string,
    action: PropTypes.func,
    actionRight: PropTypes.func,
    rowClassName: PropTypes.string,
  };

  static defaultProps = {
    rowClassName: '',
    disabled: false,
  };

  action = (data) => {
    if (this.props.action && !this.props.disabled) {
      this.props.action(data);
    }
  };


  actionRight = (data) => {
    if (this.props.actionRight && !this.props.disabled) {
      this.props.actionRight(data);
    }
  };


  render() {
    const {data, currency, lock, rowClassName, disabled} = this.props;
    return (
      <React.Fragment>
        <tr className={rowClassName} onClick={()=>this.action()}>
          <td className={'w-10'}>
            <p className={'mb-0 small text-right'}>
              {data.quantity} <strong>x</strong>
              <br/> {data.quantity_type_display}
            </p>
          </td>
          <td className={'w-50'}>
            <strong>{data.description} </strong>
            <div className={'float-right'}><PosItemTypeDetailsView size={'sm'} item={data}/></div>

            <p className={'mb-0 small'}>
              {(parseInt(data.sub_quantity) > 1 || parseInt(data.sub_quantity_type) !== 1) &&
              <React.Fragment>
                {data.sub_quantity} {data.sub_quantity_type_display}
              </React.Fragment>
              }
            </p>
            {data.additional_description &&
            <p className={'mb-0 small'}>Note : {data.additional_description}</p>
            }
            {parseFloat(data.discount) > 0 &&
            <p className={'mb-0 small'}>
              Discount: {currency} {data.discount} ({data.discount_percent}%)
            </p>
            }
            {data.complimentary && <span className={'badge badge-info'}>{data.complimentary_type_display}</span>}
            {data.no_charge && <span className={'badge badge-info'}>{data.no_charge_type_display}</span>}
          </td>
          <td className="w-35 text-right">
            <strong>{`${currency} ${data.total}`}</strong>
            {this.props.actionRight &&
              <button
                className={'btn btn-sm btn-outline-primary ml-2'}
                onClick={() => this.actionRight(data)} disabled={disabled}
              >
                <FontAwesomeIcon size={'sm'} icon={lock ? faEye : faPen}/>
              </button>
            }
          </td>
        </tr>
      </React.Fragment>
    );
  };
}

/* Export Component ==================================================================== */
export default POSOrderItemListView;
