/**
 * Payment analytics overview
 *  Payment analytics overview
 *
 */

import PropTypes from 'prop-types';
import React, {Component} from 'react';
// Consts and Libs
import AppUtil from '../../../../lib/util';
import { Constants } from '../../../../constants';
// Components
import PageHeader from '../../../../components/dashboard/PageHeader';


/* Component ==================================================================== */
class PAOverview extends Component {
  static componentName = 'PAOverview';

  static propTypes = {
    property: PropTypes.object.isRequired,
    paymentData: PropTypes.object.isRequired,
  };


  render = () => {
    const {paymentData, property} = this.props;

    // Payment status contains cancelled payments also so subtract it to get active payments.
    const paidPayments = (
      AppUtil.sumArray(Object.values(paymentData.paymentStatusTotal), 'float') -
      (paymentData.paymentStatusTotal[Constants.PAYMENT_STATUS.CANCELLED] || 0)
    ).toFixed(2);
    const paidPaymentsCount = AppUtil.sumArray(Object.values(paymentData.paymentStatusCount)) - (paymentData.paymentStatusCount[Constants.PAYMENT_STATUS.CANCELLED] || 0);

    const paidPaymentPercentage = AppUtil.calculatePercentage(
      (paymentData.paymentStatusTotal[Constants.PAYMENT_STATUS.PAID] || 0),
      paidPayments
    );
    const settlementPercentage = AppUtil.calculatePercentage(
      paymentData.settlementStatusTotalSettled[Constants.SETTLEMENT_STATUS.SETTLED] || 0,
      AppUtil.sumArray(Object.values(paymentData.settlementStatusTotalSettled), 'float')
    );

    return (
      <>
        <PageHeader
          subHeader={true}
          title={'Payment Overview'}
          description={'Incoming payments & bifurcation based on method of payment.'}
        />


        <div className={'row'}>
          <div className={'col-12 col-lg-5 mb-3'}>
            <div className={'card mb-5'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col '}>
                    <p className={'text-muted mb-1'}>Total Active Payment(s)</p>
                    <h3 className={'card-title mb-0 pb-0'}>
                      {`${property.currency} ${paidPayments}`}
                    </h3>
                    <p className={'text-muted mb-1'}>
                      {paidPaymentsCount} payment entries.
                    </p>
                  </div>
                </div>
                <hr/>
                <div className={'row'}>
                  <div className={'col '}>
                    <p className={'text-muted mb-1'}>Total Paid</p>
                    <h5 className={'card-title mb-0 pb-0 green-cl'}>
                      {`${property.currency} ${paymentData.paymentStatusTotal[Constants.PAYMENT_STATUS.PAID] || 0}`}
                    </h5>
                  </div>
                  <div className={'col'}>
                    <p className={'text-muted mb-1'}>Pending Payment</p>
                    <h5 className={'card-title mb-0 pb-0 red-cl'}>
                      {`${property.currency} ${paymentData.paymentStatusTotal[Constants.PAYMENT_STATUS.NOTPAID] || 0}`}
                    </h5>
                  </div>
                </div>
                <div className="progress mt-3 red-bg">
                  <div
                    className="progress-bar green-bg"
                    style={{width: `${paidPaymentPercentage}%`}}
                    aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"/>
                </div>
                <div className={'row'}>
                  <div className={'col-8'}>
                    <p className={'text-muted mb-0 mt-1 small'}>
                      Count - Paid : {paymentData.paymentStatusCount[Constants.PAYMENT_STATUS.PAID] || 0} |
                      Pending : {paymentData.paymentStatusCount[Constants.PAYMENT_STATUS.NOTPAID] || 0}
                    </p>
                  </div>
                  <div className={'text-right col'}>
                    <p className={'text-muted mb-0 mt-1 small'}>{paidPaymentPercentage}%</p>
                  </div>
                </div>
              </div>
            </div>

            <div className={'row'}>
              <div className={'col-6'}>
                <div className={'card '}>
                  <div className="card-body ">
                    <p className={'text-muted mb-1'}>Cancelled</p>
                    <h5 className={'grey-cl'}>
                      {`${property.currency} ${paymentData.paymentStatusTotal[Constants.PAYMENT_STATUS.CANCELLED] || 0}`}
                    </h5>
                    <p className={'text-muted mb-1'}>
                      {paymentData.paymentStatusCount[Constants.PAYMENT_STATUS.CANCELLED] || 0} payment entries.
                    </p>
                  </div>
                </div>
              </div>
              <div className={'col-6'}>
                <div className={'card '}>
                  <div className="card-body ">
                    <p className={'text-muted mb-1'}>Overall</p>
                    <h4>
                      {AppUtil.sumArray(Object.values(paymentData.paymentStatusCount))}
                    </h4>
                    <p className={'text-muted mb-1'}>
                      payment entries.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={'col-12 col-lg-7 mb-3'}>
            <table className={'table table-bordered'}>
              <thead>
                <tr>
                  <th className={'data-table-cell-md'}>Payment Method</th>
                  <th className={'data-table-cell-sm'}>Count</th>
                  <th className={'data-table-cell-md'}>Total Paid <small>({property.currency})</small></th>
                </tr>
              </thead>
              <tbody>
                {Constants.PAYMENT_METHODS_INPUTS.map((data, i) => (
                  <tr key={i}>
                    <td className={'data-table-cell-lg'}>{data.label}</td>
                    <td
                      className={'data-table-cell-sm text-center'}>{paymentData.paidPaymentMethodCount[parseInt(data.value)] || 0}</td>
                    <td className={'data-table-cell-md text-center'}>
                      {paymentData.paidPaymentMethodTotal[parseInt(data.value)] || 0}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td><strong>Total</strong></td>
                  <td className={'text-center'}>
                    <strong>{AppUtil.sumArray(Object.values(paymentData.paidPaymentMethodCount))}</strong></td>
                  <td className={'text-center'}>
                    <strong>{AppUtil.sumArray(Object.values(paymentData.paidPaymentMethodTotal), 'float')}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <PageHeader
          subHeader={true}
          title={'Settlement Overview'}
          description={'Payment settlement status & information.'}
        />

        <div className={'row'}>
          <div className={'col-12 col-lg-5 mb-3'}>
            <div className={'card mb-5'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col'}>
                    <p className={'text-muted mb-1'}>Total Paid(s)</p>
                    <h3 className={'card-title mb-0 pb-0 green-cl'}>
                      {`${property.currency} ${paymentData.paymentStatusTotal[Constants.PAYMENT_STATUS.PAID] || 0}`}
                    </h3>
                    <p className={'text-muted mb-1'}>
                      {AppUtil.sumArray(Object.values(paymentData.settlementStatusCount))} paid payment entries.
                    </p>
                  </div>
                </div>
                <hr/>
                <div className={'row'}>
                  <div className={'col '}>
                    <p className={'text-muted mb-1'}>Total Settled</p>
                    <h5 className={'card-title mb-0 pb-0 green-cl'}>
                      {`${property.currency} ${paymentData.settlementStatusTotalSettled[Constants.SETTLEMENT_STATUS.SETTLED] || 0}`}
                    </h5>
                  </div>
                  <div className={'col'}>
                    <p className={'text-muted mb-1'}>Pending Settlement</p>
                    <h5 className={'card-title mb-0 pb-0 yellow-cl'}>
                      {`${property.currency} ${paymentData.settlementStatusTotalSettled[Constants.SETTLEMENT_STATUS.NOT_SETTLED] || 0}`}
                    </h5>
                  </div>
                </div>
                <div className="progress mt-3 yellow-bg">
                  <div
                    className="progress-bar green-bg"
                    style={{width: `${settlementPercentage}%`}}
                    aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"/>
                </div>
                <div className={'row'}>
                  <div className={'col-8'}>
                    <p className={'text-muted mb-0 mt-1 small'}>
                      Count - Settled : {paymentData.settlementStatusCount[Constants.SETTLEMENT_STATUS.SETTLED] || 0} |
                      Pending : {paymentData.settlementStatusCount[Constants.SETTLEMENT_STATUS.NOT_SETTLED] || 0}
                    </p>
                  </div>
                  <div className={'text-right col'}>
                    <p className={'text-muted mb-0 mt-1 small'}>{settlementPercentage}%</p>
                  </div>
                </div>
              </div>
            </div>

            <div className={'row'}>
              <div className={'col'}>
                <div className={'card '}>
                  <div className="card-body ">
                    <p className={'mb-1'}>Settlement Fee / Charges</p>
                    <h4 className={'blue-cl'}>
                      {`${property.currency} 
                      ${((paymentData.settlementStatusTotalPaid[Constants.SETTLEMENT_STATUS.SETTLED] || 0) - (paymentData.settlementStatusTotalSettled[Constants.SETTLEMENT_STATUS.SETTLED] || 0)).toFixed(2)}`}
                    </h4>
                    <p className={'text-muted mb-1'}>
                      from {paymentData.settlementStatusCount[Constants.SETTLEMENT_STATUS.SETTLED] || 0} settled
                      entries.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'col-12 col-lg-7 mb-3'}>
            <div className="table-responsive">
              <table className={'table table-bordered'}>
                <thead>
                  <tr>
                    <th>Payment Method</th>
                    <th>
                      Paid
                      <br/><small>(A)</small>
                    </th>
                    <th>
                      Settled
                      <br/><small>(B)</small>
                    </th>
                    <th>
                      Fee
                      <br/><small>(C)</small>
                    </th>
                    <th>
                      Pending
                      <br/><small>(A - B)</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Constants.PAYMENT_METHODS_INPUTS.map((data, i) => (
                    <tr key={i}>
                      <td className={'data-table-cell-lg'}>{data.label}</td>
                      <td className={'data-table-cell-sm text-center'}>
                        {paymentData.paidPaymentMethodTotal[parseInt(data.value)] || 0}
                      </td>
                      <td className={'data-table-cell-sm text-center'}>
                        {paymentData.paidPaymentMethodSettled[parseInt(data.value)] || 0}
                      </td>
                      <td className={'data-table-cell-sm text-center'}>
                        {((paymentData.paidPaymentMethodSettledTotal[parseInt(data.value)] || 0) - (paymentData.paidPaymentMethodSettled[parseInt(data.value)] || 0)).toFixed(2)}
                      </td>
                      <td className={'data-table-cell-sm text-center'}>
                        {paymentData.paidPaymentMethodNotSettled[parseInt(data.value)] || 0}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td><strong>Total</strong></td>
                    <td className={'text-center'}>
                      <strong>{AppUtil.sumArray(Object.values(paymentData.paidPaymentMethodTotal), 'float')}</strong>
                    </td>
                    <td className={'text-center'}>
                      <strong>{paymentData.settlementStatusTotalSettled[Constants.SETTLEMENT_STATUS.SETTLED] || 0}</strong>
                    </td>
                    <td className={'text-center'}>
                      <strong>
                        {((paymentData.settlementStatusTotalPaid[Constants.SETTLEMENT_STATUS.SETTLED] || 0) - (paymentData.settlementStatusTotalSettled[Constants.SETTLEMENT_STATUS.SETTLED] || 0)).toFixed(2)}
                      </strong>
                    </td>
                    <td className={'text-center'}>
                      <strong>{paymentData.settlementStatusTotalSettled[Constants.SETTLEMENT_STATUS.NOT_SETTLED] || 0}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className={'text-muted small'}>All values in {property.currency}.</p>
          </div>
        </div>
      </>
    );
  };
}

/* Export Component ==================================================================== */
export default PAOverview;

