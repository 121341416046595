/**
 *
 * Event Booking Engine Settings
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import { Img } from 'react-image';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../../../lib/api';
import AppUtil from '../../../../lib/util';
import {Constants} from '../../../../constants';
// Components
import Error from '../../../../components/general/Error';
import Loading from '../../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ClickToView from '../../../../components/general/ClickToView';
import {faMapMarker, faPen} from '@fortawesome/free-solid-svg-icons';
import EventEngineCoverImageManage from './EventEngineCoverImageManage';
// Actions
import * as EventActions from '../../../../redux/event/actions';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  event: state.event.event,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setEvent: EventActions.setEvent,
};

/* Component ==================================================================== */
class EventBookingEngineContent extends Component {
  static componentName = 'EventBookingEngineContent';

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    const {event} = this.props;
    AppAPI.eventapi.get(`event/${event.id}/?content=True`)
      .then((res) => {
        this.setState({
          loading: false,
          error: null,
          event: res,
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };

  render = () => {
    const {loading, error, event} = this.state;
    const {property} = this.props;
    if (!event || loading) return <Loading heightMatch={faPen}/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="channel-manager-settings screen-container">
        <Helmet>
          <title>Booking Engine Content</title>
        </Helmet>
        <div className={'row'}>
          <div className="col-lg-12 col-md-12 col-sm-12 mb-5">
            <button
              onClick={() => this.setState({showEventEngineCoverImageManage: true})}
              className="btn btn-link mb-2 float-right"
            >
              <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit Cover
            </button>
            <div
              className={'border p-2 text-center anchor w-100'} data-toggle="collapse"
              role="button" aria-expanded="false" aria-controls="orderActions">
              <h6 className={'mb-0'}>
                Event Header
              </h6>
            </div>
            <div className={'border'} id={'primaryAction'}>
              <div
                className={'home-header'}
                style={event.event_cover_image && event.event_cover_image.cover && {
                  backgroundImage: `url(${event.event_cover_image.cover})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                <table className={'w-100 h-100  header-inner'}>
                  <tbody>
                    <tr>
                      <td className="align-middle">
                        <div className={'text-light'}>
                          <h1>{event.name}</h1>
                          <p>
                            <FontAwesomeIcon icon={faMapMarker} className={'mr-2'}/>
                            {event.full_address ? event.full_address : property.full_address}
                          </p>
                          <p>
                            <span className={'badge ' + (AppUtil.bookingStatusColor(event.event_status))}>
                              {event.event_status_display}
                            </span>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <Link to={'manage'} className="btn btn-link mb-2 float-right">
              <FontAwesomeIcon icon={faPen} size={'sm'} className={'mr-2'}/> Edit
            </Link>
            <div
              className={'border p-2 text-center anchor w-100'} data-toggle="collapse"
              role="button" aria-expanded="false" aria-controls="orderActions">
              <h6 className={'mb-0'}>
                Slider / Description
              </h6>
            </div>
            <div className={'border p-2'}>
              <div className={'row w-100'}>
                <div className={'col-8'}>
                  {event.slider_description ?
                    <p>{event.slider_description}</p>
                    :
                    <p className={'text-muted'}>Slider description.</p>
                  }
                  <hr/>
                  {(event.slider_image && event.slider_image.length > 0) ?
                    <div>
                      <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                        <ol className="carousel-indicators">
                          {event.slider_image.map((data, i) => (
                            <li
                              data-target="#carouselExampleIndicators"
                              key={i}
                              data-slide-to={i}
                              className={i === 0 ? 'active' : ''}
                            />
                          ))}
                        </ol>
                        <div className="carousel-inner">
                          {event.slider_image.map((data, i) => (
                            <div className={`carousel-item ${i === 0 && 'active'}`} key={i}>
                              <Img
                                className="d-block w-100"
                                src={data.image.slider}
                                loader={<Loading heightMatch={false}/>}
                              />
                            </div>
                          ))}
                        </div>
                        <a
                          className="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                          data-slide="prev">
                          <span className="carousel-control-prev-icon" aria-hidden="true"/>
                          <span className="sr-only">Previous</span>
                        </a>
                        <a
                          className="carousel-control-next" href="#carouselExampleIndicators" role="button"
                          data-slide="next">
                          <span className="carousel-control-next-icon" aria-hidden="true"/>
                          <span className="sr-only">Next</span>
                        </a>
                      </div>
                    </div>
                    : <table className={'w-100 bg-dark'}>
                      <tbody>
                        <tr>
                          <td className="align-middle">
                            <h1 className={'text-white text-center align-middle'}>Slider</h1>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  }
                  <hr/>
                  {event.description ?
                    <p>{event.description}</p>
                    :
                    <p className={'text-muted'}>Event description.</p>
                  }
                </div>
                <div className={'col-4'}>
                  <table className={'table border mb-5'}>
                    <thead>
                      <tr>
                        <th colSpan={2} className={'text-center table-info'}>Event Schedule</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td width={'40%'}><p className={'mb-0'}><strong>Schedule</strong></p></td>
                        <td>
                          <p className={'mb-0 small'}>
                            {AppUtil.formatDateTime(event.event_start_time, 'time')} to {parseInt(event.event_schedule_length) > 1 && ` + ${event.event_schedule_length} day(s)`} {AppUtil.formatDateTime(event.event_end_time, 'time')}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td><p className={'mb-0'}><strong>Event Type</strong></p></td>
                        <td><p className={'mb-0'}>{event.event_type_display}</p></td>
                      </tr>

                      {event.event_type === Constants.EVENT_TYPE.RECURRING &&
                       <React.Fragment>
                         <tr>
                           <td><p className={'mb-0'}><strong>Recurring Type</strong></p></td>
                           <td><p className={'mb-0'}>{event.event_recurring_type_display}</p></td>
                         </tr>
                         {event.event_recurring_type === Constants.EVENT_RECURRING_TYPE.WEEKLY &&
                          <tr>
                            <td><p className={'mb-0'}><strong>Week day</strong></p></td>
                            <td><p className={'mb-0'}>{event.event_recurring_week_day_display}</p></td>
                          </tr>
                         }
                       </React.Fragment>
                      }
                      <tr>
                        <td><p className={'mb-0'}><strong>Event Status</strong></p></td>
                        <td>
                          <span className={'badge ' + (AppUtil.bookingStatusColor(event.event_status))}>
                            {event.event_status_display}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table className={'table border'}>
                    <tbody>
                      {event.full_address &&
                       <tr>
                         <td width={'30%'}><p className={'mb-0'}><strong>Address</strong></p></td>
                         <td>
                           <p className={'small mb-0'}>{event.full_address}</p>
                         </td>
                       </tr>
                      }
                      <tr>
                        <td width={'30%'}><p className={'mb-0'}><strong>Email</strong></p></td>
                        <td>
                          <p className={'mb-0'}>
                            <ClickToView
                              buttonClass={'btn-link'}
                              content={event.sales_email ? event.sales_email : property.sales_email}
                            />
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td width={'30%'}><p className={'mb-0'}><strong>Phone</strong></p></td>
                        <td>
                          <p className={'mb-0'}>
                            <ClickToView
                              buttonClass={'btn-link'}
                              content={event.sales_phone ? event.sales_phone : property.sales_phone}
                            />
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <EventEngineCoverImageManage
          show={this.state.showEventEngineCoverImageManage}
          onHide={() => this.setState({showEventEngineCoverImageManage: false})}
          onSave={() => {
            this.setState({loading: true}, () => {
              this.fetchInitData();
            });
          }}
          event={event}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      event: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      showEventEngineCoverImageManage: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventBookingEngineContent);
