/**
 * POS Actions
 *  Actions with point of sales
 */
import queryString from 'query-string';

import AppAPI from '../../lib/api';

/**
 * Set POS Details
 */
export const setPOSDetails = (POSData, content = true, refresh = false) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof POSData === 'object') {
    dispatch({
      type: 'SET_POS_DETAILS',
      data: POSData,
    });
    return resolve(POSData);
  } else if (POSData) {
    // Fetch POS Data

    const urlParams = {};
    if (content) {
      urlParams['content'] = true;
    }

    if (refresh) {
      urlParams['refresh'] = true;
    }

    return AppAPI.posapi.get(`pos-details/${POSData}/?${queryString.stringify(urlParams)}`)
      .then((res) => {
        dispatch({
          type: 'SET_POS_DETAILS',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update POS
 */
export const updatePOSDetails = (POSData) => setPOSDetails(POSData);

/**
 * Remove POS
 */
export function unSetPOSDetails() {
  return (dispatch) => {
    dispatch({
      type: 'SET_POS_DETAILS',
      data: null,
    });
  };
}


/**
 * Set POS
 */
export const setPOS = (POSData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof POSData === 'object') {
    dispatch({
      type: 'SET_POS',
      data: POSData,
    });
    return resolve(POSData);
  } else if (POSData) {
    // Fetch POS Data
    return AppAPI.posapi.get(`pos-settings/${POSData}/`)
      .then((res) => {
        dispatch({
          type: 'SET_POS',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update POS
 */
export const updatePOS = (POSData) => setPOS(POSData);

/**
 * Remove POS
 */
export function unSetPOS() {
  return (dispatch) => {
    dispatch({
      type: 'SET_POS',
      data: null,
    });
  };
}

/**
 * Set POS Item
 */
export const setPOSItem = (POSItemData, posId) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof POSItemData === 'object') {
    dispatch({
      type: 'SET_POS_ITEM',
      data: POSItemData,
    });
    return resolve(POSItemData);
  } else if (POSItemData) {
    // Fetch POS Data
    return AppAPI.posapi.get(`pos-item-settings/${POSItemData}/?pos=${posId}`)
      .then((res) => {
        dispatch({
          type: 'SET_POS_ITEM',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update POS Item
 */
export const updatePOSItem = (POSItemData) => setPOSItem(POSItemData);

/**
 * Remove POS Item
 */
export function unSetPOSItem() {
  return (dispatch) => {
    dispatch({
      type: 'SET_POS_ITEM',
      data: null,
    });
  };
}


/**
 * Set POS Table
 */
export const setPOSTable = (POSTableData, posId, settings = true) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof POSTableData === 'object') {
    dispatch({
      type: 'SET_POS_TABLE',
      data: POSTableData,
    });
    return resolve(POSTableData);
  } else if (POSTableData) {
    // Fetch POS Data
    return AppAPI.posapi.get(`${settings ? 'pos-table-settings' : 'pos-table'}/${POSTableData}/?pos=${posId}`)
      .then((res) => {
        dispatch({
          type: 'SET_POS_TABLE',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update POS Table
 */
export const updatePOSTable = (POSTableData) => setPOSTable(POSTableData);

/**
 * Remove POS Table
 */
export function unSetPOSTable() {
  return (dispatch) => {
    dispatch({
      type: 'SET_POS_TABLE',
      data: null,
    });
  };
}

/**
 * Set POS Category
 */
export const setPOSCategory = (POSCategoryData, posId) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof POSCategoryData === 'object') {
    dispatch({
      type: 'SET_POS_CATEGORY',
      data: POSCategoryData,
    });
    return resolve(POSCategoryData);
  } else if (POSCategoryData) {
    // Fetch POS Data
    return AppAPI.posapi.get(`pos-category-settings/${POSCategoryData}/?pos=${posId}`)
      .then((res) => {
        dispatch({
          type: 'SET_POS_CATEGORY',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update POS Category
 */
export const updatePOSCategory = (POSCategoryData) => setPOSCategory(POSCategoryData);

/**
 * Remove POS Category
 */
export function unSetPOSCategory() {
  return (dispatch) => {
    dispatch({
      type: 'SET_POS_CATEGORY',
      data: null,
    });
  };
}

/**
 * Set POS Membership
 */
export const setPOSMembership = (POSMembershipData, posId) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof POSMembershipData === 'object') {
    dispatch({
      type: 'SET_POS_MEMBERSHIP',
      data: POSMembershipData,
    });
    return resolve(POSMembershipData);
  } else if (POSMembershipData) {
    // Fetch POS Data
    return AppAPI.posapi.get(`pos-membership-settings/${POSMembershipData}/?pos=${posId}`)
      .then((res) => {
        dispatch({
          type: 'SET_POS_MEMBERSHIP',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update POS Membership
 */
export const updatePOSMembership = (POSMembershipData) => setPOSMembership(POSMembershipData);

/**
 * Remove POS Membership
 */
export function unSetPOSMembership() {
  return (dispatch) => {
    dispatch({
      type: 'SET_POS_MEMBERSHIP',
      data: null,
    });
  };
}


/**
 * Set POS Order
 */
export const setPOSOrder = (POSOrderData, posId) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof POSOrderData === 'object') {
    dispatch({
      type: 'SET_POS_ORDER',
      data: POSOrderData
    });
    return resolve(POSOrderData);
  } else if (POSOrderData) {
    // Fetch Expense Data
    return AppAPI.posapi.get(`pos-order/${POSOrderData}/?pos=${posId}`)
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_POS_ORDER',
          data: res
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update POS Order
 */
export const updatePOSOrder = (expenseData) => setPOSOrder(expenseData);

/**
 * Remove POS Order
 */
export function unSetPOSOrder() {
  return (dispatch) => {
    dispatch({
      type: 'SET_POS_ORDER',
      data: null
    });
  };
}

/**
 * Set POS Order KOT
 */
export const setPOSOrderKOT = (POSOrderKOTData, posId) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof POSOrderKOTData === 'object') {
    dispatch({
      type: 'SET_POS_ORDER_KOT',
      data: POSOrderKOTData,
    });
    return resolve(POSOrderKOTData);
  } else if (POSOrderKOTData) {
    // Fetch POS Data
    return AppAPI.posapi.get(`pos-order-kot/${POSOrderKOTData}/?pos=${posId}`)
      .then((res) => {
        dispatch({
          type: 'SET_POS_ORDER_KOT',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update POS Table
 */
export const updatePOSOrderKOT = (POSOrderKOTData) => setPOSOrderKOT(POSOrderKOTData);

/**
 * Remove POS Table
 */
export function unSetPOSOrderKOT() {
  return (dispatch) => {
    dispatch({
      type: 'SET_POS_ORDER_KOT',
      data: null,
    });
  };
}