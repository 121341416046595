/**
 *
 * Room Type List
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// Consts and Libs
import AppAPI from '../../../lib/api';
import Strings from '../../../constants/strings';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import ListView from '../../../components/general/ListView';
import PageHeader from '../../../components/dashboard/PageHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faPlus, faSnowflake, faTimesCircle} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class RoomTypeList extends Component {
  static componentName = 'RoomList';
  static propTypes = {
    refreshable: PropTypes.bool,
    roomSettings: PropTypes.bool,
  };
  componentDidMount = () => {
  };
  fetchRoomType = (page = 1, callback) => {
    AppAPI.roomtype.get('?page=' + page)
      .then((res) => {
        this.setState({
          loading: false,
          error: null,
        });
        if (res.results.length > 0) {
          if (res.next) {
            callback(res.results);
          } else {
            callback(res.results, {
              allLoaded: true, // the end of the list is reached
            });
          }
        } else {
          callback();
        }
      })
      .catch((err) => {
        callback(null, {
          allLoaded: true, // the end of the list is reached
        });
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };
  emptyView = () => {
    return (
      <tr>
        <td colSpan={6}>
          <div className={'text-center table-list-empty'}>
            <h4><FontAwesomeIcon icon={faSnowflake}/> No Room Types </h4>
            {Strings.roomTypeListEmptyText.map((data, i) => <p key={i}>{data}</p>)}
          </div>
        </td>
      </tr>
    );
  };
  renderRow = (data, key) => {
    return (
      <tr key={key}>
        <td>
          <Link to={'/settings/inventory/room-type/' + data.id}>{data.name}</Link>
        </td>
        <td>
          <div className="mb-0 small">
            <span className="fa fa-user fa-sm"/> {data.base_occupancy} |
            <span className="fa mx-2 fa-sm fa-child"/> {data.child_occupancy} |
            <span className="fa mx-2 fa-sm fa-baby"/> {data.infant_occupancy}
          </div>
        </td>
        <td>{data.channel_manager_room_type_id}</td>
        <td>
          <FontAwesomeIcon
            icon={data.channel_manager_ok ? faCheckCircle : faTimesCircle}
            className={data.channel_manager_ok ? 'green-cl' : 'red-cl'}
          />
        </td>
        <td>
          <FontAwesomeIcon
            icon={data.booking_engine_ok ? faCheckCircle : faTimesCircle}
            className={data.booking_engine_ok ? 'green-cl' : 'red-cl'}
          />
        </td>
        <td>
          <FontAwesomeIcon
            icon={data.active ? faCheckCircle : faTimesCircle}
            className={data.active ? 'green-cl' : 'red-cl'}
          />
        </td>
      </tr>
    );
  };
  render = () => {

    const {loading, error} = this.state;
    const {property} = this.props;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className="room-type-list screen-container ">
        <Helmet>
          <title>Room Types</title>
        </Helmet>

        <PageHeader
          subHeader={true} history={this.props.history} title={'Room Type(s)'}
          description={`Room Types in ${property.name}`}
        />

        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12 mb-5">
            <table className={'table border'}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Occupancy</th>
                  <th>Code</th>
                  <th>CM<sup>*</sup></th>
                  <th>BE<sup>*</sup></th>
                  <th>Active</th>
                </tr>
              </thead>
              <tbody>
                <ListView
                  rowView={this.renderRow}
                  onFetch={this.fetchRoomType}
                  firstLoader={true}
                  pagination
                  emptyView={this.emptyView}
                  class={'list-group'}
                  colSpan={6}
                />
              </tbody>
            </table>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <ul className={'list-inline'}>
              <li className={'list-inline-item'}><strong>CM</strong> : Channel Manager</li>
              <li className={'list-inline-item'}><strong>BE</strong> : Booking Engine</li>
            </ul>

            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <Link to={'/settings/inventory/room-type/new'} className={'btn btn-link p-0'}>
                  <FontAwesomeIcon icon={faPlus} size={'sm'} className={'mr-2'}/> Add Room Type
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomTypeList);