/**
 * TimeLineView
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faLongArrowAltRight} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */
class TimeLineList extends Component {
  static componentName = 'TimeLineList';

  static propTypes = {
    containerClass: PropTypes.string,
    timeLine: PropTypes.array,
  };

  static defaultProps = {
    containerClass: ''
  };

  render = () => {
    const {containerClass, timeLine} = this.props;
    const timeLineLength = !_.isEmpty(timeLine) ? Object.keys(timeLine).length : 0;
    return (
      <div className={containerClass}>
        {!_.isEmpty(timeLine) &&
          <ul className={'list-inline d-inline-flex list-inline-formatted mb-0'}>
            {timeLine.map((data, i)=>(
              <React.Fragment key={i}>
                <li className={'list-inline-item align-items-center'}>
                  <p className={'mb-0'}>
                    <FontAwesomeIcon icon={data.icon || faCheckCircle} size={'sm'} className={`${data.iconClass} mr-2`}/>
                    {data.title && <strong>{data.title}</strong>}
                  </p>
                  {data.description && <p className={'text-muted small'}>{data.description}</p>}
                </li>
                {(i < (timeLineLength-1)) &&
                  <li className={'mx-3 list-inline-item d-inline-flex align-items-center'}>
                    <FontAwesomeIcon icon={faLongArrowAltRight}/>
                  </li>
                }
              </React.Fragment>
            ))}
          </ul>
        }
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default TimeLineList;
