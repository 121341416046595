/**
 *
 * Property Payment Settings
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {NavLink, Redirect, Route, Switch} from 'react-router-dom';
// Components
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import PageHeader from '../../../components/dashboard/PageHeader';

import PaymentTerminalList from './PaymentTerminalList';
import PaymentTerminalManage from './PaymentTerminalManage';
import PaymentGatewaySettings from './PaymentGatewaySettings';
import PaymentTerminalSettings from './PaymentTerminalSettings';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PropertyChannelManager extends Component {
  static componentName = 'PropertyChannelManager';

  componentDidMount = () => {
  };


  render = () => {
    const {loading, error} = this.state;

    if (!this.props.property.name || loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;


    let baseUrl = '/settings/property/payment-settings';
    const menu = [
      {
        title: 'Payment Gateway',
        link: `${baseUrl}/payment-gateway`,
        isExact: true,
      },
      {
        title: 'Payment Terminal',
        link: `${baseUrl}/payment-terminal`,
      },
    ];

    let menuItems = [];
    menu.map((item) => {
      const {title, link, isExact} = item;
      return menuItems.push(
        <li className="nav-item" role="presentation" key={`menu-item-${title}`}>
          <NavLink
            to={{
              pathname: link,
              state: {fromDashboard: true},
            }}
            exact={isExact}
            className="nav-link rounded-0"
            activeClassName="selected">
            {title}
          </NavLink>
        </li>,
      );
    });

    return (
      <div className="channel-manager-settings screen-container">
        <Helmet>
          <title>Payment Settings</title>
        </Helmet>

        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'Payment Settings'}
        />

        <ul className="nav nav-tabs mb-3">
          {menuItems}
        </ul>
        <Switch>
          <Route
            exact
            path={baseUrl}
            render={() => (<Redirect to={`${baseUrl}/payment-gateway`}/>)}
          />
          <Route path={`${baseUrl}/payment-terminal`} exact component={PaymentTerminalList}/>
          <Route path={`${baseUrl}/payment-gateway`} exact component={PaymentGatewaySettings}/>
          <Route path={`${baseUrl}/payment-terminal/new`} exact component={PaymentTerminalManage}/>
          <Route path={`${baseUrl}/payment-terminal/:paymentTerminalId`} exact component={PaymentTerminalSettings}/>
          <Route path={`${baseUrl}/payment-terminal/:paymentTerminalId/edit`} exact component={PaymentTerminalManage}/>
        </Switch>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyChannelManager);
