/**
 *
 * Order analytics
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
// Consts and Libs
import AppUtil from '../../../lib/util';
import {Constants} from '../../../constants';
import Colors from '../../../constants/colors';
// Components
import Loading from '../../../components/general/Loading';
import Error from '../../../components/general/Error';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle, faCircleNotch, faLongArrowAltRight} from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */

class OrderAnalyticsOverView extends Component {
  static componentName = 'OrderAnalyticsOverView';

  static propTypes = {
    responseData: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
  };


  renderOrderValueSourceRow = (dataSet, title, className = '') => {
    return (
      <tr className={className}>
        <td className={'text-left bg-light'}>{title}</td>
        <td>{dataSet['sub_total']}</td>
        <td>{dataSet['discount_total']}</td>
        <td>{dataSet['complimentary_total']}</td>
        <td>{dataSet['no_charge_total']}</td>
        <td>{dataSet['tax_total']}</td>
        <td>{dataSet['order_tax']}</td>
        <td>{dataSet['order_discount']}</td>
        <td className={'table-info'}>{dataSet['total']}</td>
        <td>{dataSet['total_unpaid_payment']}</td>
        <td>{dataSet['total_paid']}</td>
        <td>{dataSet['total_settled']}</td>
        <td>{dataSet['total_refunded']}</td>
        <td className={'table-info'}>{dataSet['total_available_fund']}</td>
      </tr>
    );
  }

  renderPaymentValueMethod = (dataSet, graphData, title, currency, distributionKey) => {
    return (
      <div>
        <h4 className={'text-left'}>{title}</h4>
        <div className={'row'}>
          <div className={'col-md-6 col-sm-12 mb-3'}>
            {!_.isEmpty(graphData) &&
            <div className={'mb-3'}>
              {this.renderPieChart(graphData)}

            </div>
            }
          </div>
          <div className={'col-md-6 col-sm-12 mb-3'}>
            <table className={'table table-bordered table-sm format-table'}>
              <thead>
                <tr>
                  <th className={'w-75'}>Method</th>
                  <th className={'data-table-cell-lg'}>Amount ({currency})</th>
                </tr>
              </thead>
              <tbody>
                {distributionKey.map((dData, i) => (
                  <tr key={i}>
                    <td><strong>{dData.label}</strong></td>
                    <td>{dataSet[dData.value]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  renderPieChart = (graphData, height = 300) => (
    <ResponsiveContainer height={height}>
      <PieChart>
        <Pie
          data={graphData}
          labelLine={false}
          label={AppUtil.renderCustomizedLabel}
          outerRadius={100}
          fill="#8884d8"
          dataKey="value"
        >
          {graphData.map((entry, index) =>
            <Cell key={`cell-${index}`} fill={entry.color}/>,
          )}
        </Pie>
        <Tooltip/>
        <Legend onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}/>
      </PieChart>
    </ResponsiveContainer>
  )

  renderDistribution = (graphData, distributionData, distributionKey, title, currency) => (
    <div>
      <h4 className={'text-left'}>{title}</h4>
      <div className={'row'}>
        <div className={'col-md-6 col-sm-12 mb-3'}>
          {!_.isEmpty(graphData) &&
          <div className={'mb-3'}>
            {this.renderPieChart(graphData)}

          </div>
          }
        </div>
        <div className={'col-md-6 col-sm-12 mb-3'}>
          <table className={'table table-bordered table-sm format-table'}>
            <thead>
              <tr>
                <th className={'w-75'}>Method</th>
                <th className={'data-table-cell-lg'}>Amount ({currency})</th>
              </tr>
            </thead>
            <tbody>
              {distributionKey.map((dData, i) => (
                <tr key={i}>
                  <td><strong>{dData.label}</strong></td>
                  <td>{distributionData[dData.value]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )

  render = () => {
    const {loading, error, distributionSelectCategory, paymentSelectCategory} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const {currency, aggregate_data, tax_classes} = this.props.responseData;


    let orderSourceCount = [], orderSourceValue = [], paymentSourceValue = [], orderSourceSettledValue = [],
      refundSourceValue = [], discountTypeValue = [], orderDiscountType = [], orderNoChargeType = [],
      orderComplimentaryType = [];

    if (aggregate_data.order_count) {

      orderComplimentaryType.push(
        {name: 'Complimentary', value: aggregate_data.order_value['complimentary_type'][0], color: Colors.blue},
        {name: 'Management', value: aggregate_data.order_value['complimentary_type'][1], color: Colors.green},
        {name: 'Marketing', value: aggregate_data.order_value['complimentary_type'][2], color: Colors.purple},
        {name: 'Package ', value: aggregate_data.order_value['complimentary_type'][3], color: Colors.grey},
        {name: 'Agent', value: aggregate_data.order_value['complimentary_type'][4], color: Colors.greenLight},
        {name: 'Corporate', value: aggregate_data.order_value['complimentary_type'][5], color: Colors.yellow},
        {name: 'Dispute', value: aggregate_data.order_value['complimentary_type'][6], color: Colors.orange},
        {name: 'Staff', value: aggregate_data.order_value['complimentary_type'][7], color: Colors.greyDark},
        {name: 'Other', value: aggregate_data.order_value['complimentary_type'][8], color: Colors.lime},
      );

      orderNoChargeType.push(
        {name: 'No Charge', value: aggregate_data.order_value['no_charge_type'][0], color: Colors.blue},
        {name: 'MD/Owner NC', value: aggregate_data.order_value['no_charge_type'][1], color: Colors.green},
        {name: 'Manager NC', value: aggregate_data.order_value['no_charge_type'][2], color: Colors.purple},
        {name: 'Dispute NC', value: aggregate_data.order_value['no_charge_type'][3], color: Colors.orange},
        {name: 'Staff NC', value: aggregate_data.order_value['no_charge_type'][4], color: Colors.greenLight},
        {name: 'Other NC', value: aggregate_data.order_value['no_charge_type'][5], color: Colors.yellow},
      );

      discountTypeValue.push(
        {name: 'Discount', value: aggregate_data.order_value['discount_type'][0], color: Colors.blue},
        {name: 'Management ', value: aggregate_data.order_value['discount_type'][1], color: Colors.green},
        {name: 'Marketing ', value: aggregate_data.order_value['discount_type'][2], color: Colors.purple},
        {name: 'Package ', value: aggregate_data.order_value['discount_type'][3], color: Colors.grey},
        {name: 'Agent ', value: aggregate_data.order_value['discount_type'][4], color: Colors.orange},
        {name: 'Corporate ', value: aggregate_data.order_value['discount_type'][5], color: Colors.greenLight},
        {name: 'Dispute ', value: aggregate_data.order_value['discount_type'][6], color: Colors.yellow},
        {name: 'Staff ', value: aggregate_data.order_value['discount_type'][7], color: Colors.greyDark},
        {name: 'Other ', value: aggregate_data.order_value['discount_type'][8], color: Colors.lime},
      );

      orderDiscountType.push(
        {name: 'Discount', value: aggregate_data.order_value['order_discount_type'][0], color: Colors.blue},
        {name: 'Management ', value: aggregate_data.order_value['order_discount_type'][1], color: Colors.green},
        {name: 'Marketing ', value: aggregate_data.order_value['order_discount_type'][2], color: Colors.purple},
        {name: 'Package ', value: aggregate_data.order_value['order_discount_type'][3], color: Colors.grey},
        {name: 'Agent ', value: aggregate_data.order_value['order_discount_type'][4], color: Colors.orange},
        {name: 'Corporate ', value: aggregate_data.order_value['order_discount_type'][5], color: Colors.greenLight},
        {name: 'Dispute ', value: aggregate_data.order_value['order_discount_type'][6], color: Colors.yellow},
        {name: 'Staff ', value: aggregate_data.order_value['order_discount_type'][7], color: Colors.greyDark},
        {name: 'Other ', value: aggregate_data.order_value['order_discount_type'][8], color: Colors.lime},
      );

      orderSourceCount.push(
        {
          name: 'System',
          value: aggregate_data.order_source_count[Constants.ORDER_SOURCE.SYSTEM][Constants.ORDER_STATUS.TOTAL_PAID],
          color: Colors.green
        },
        {
          name: 'Booking',
          value: aggregate_data.order_source_count[Constants.ORDER_SOURCE.BOOKING][Constants.ORDER_STATUS.TOTAL_PAID],
          color: Colors.yellow
        },
        {
          name: 'Point of Sale',
          value: aggregate_data.order_source_count[Constants.ORDER_SOURCE.POINT_OF_SALES][Constants.ORDER_STATUS.TOTAL_PAID],
          color: Colors.blue
        },
        {
          name: 'Amendment',
          value: aggregate_data.order_source_count[Constants.ORDER_SOURCE.AMENDMENT][Constants.ORDER_STATUS.TOTAL_PAID],
          color: Colors.grey
        },
        {
          name: 'External',
          value: aggregate_data.order_source_count[Constants.ORDER_SOURCE.EXTERNAL][Constants.ORDER_STATUS.TOTAL_PAID],
          color: Colors.orange
        },
        {
          name: 'User',
          value: aggregate_data.order_source_count[Constants.ORDER_SOURCE.USER][Constants.ORDER_STATUS.TOTAL_PAID],
          color: Colors.greyDark
        },
        {
          name: 'Event',
          value: aggregate_data.order_source_count[Constants.ORDER_SOURCE.EVENT][Constants.ORDER_STATUS.TOTAL_PAID],
          color: Colors.lime
        },
      );

      orderSourceValue.push(
        {
          name: 'System',
          value: aggregate_data.order_source_value[Constants.ORDER_SOURCE.SYSTEM]['total_paid'],
          color: Colors.green
        },
        {
          name: 'Booking',
          value: aggregate_data.order_source_value[Constants.ORDER_SOURCE.BOOKING]['total_paid'],
          color: Colors.yellow
        },
        {
          name: 'Point of Sale',
          value: aggregate_data.order_source_value[Constants.ORDER_SOURCE.POINT_OF_SALES]['total_paid'],
          color: Colors.blue
        },
        {
          name: 'Amendment',
          value: aggregate_data.order_source_value[Constants.ORDER_SOURCE.AMENDMENT]['total_paid'],
          color: Colors.grey
        },
        {
          name: 'External',
          value: aggregate_data.order_source_value[Constants.ORDER_SOURCE.EXTERNAL]['total_paid'],
          color: Colors.orange
        },
        {
          name: 'User',
          value: aggregate_data.order_source_value[Constants.ORDER_SOURCE.USER]['total_paid'],
          color: Colors.greyDark
        },
        {
          name: 'Event',
          value: aggregate_data.order_source_value[Constants.ORDER_SOURCE.EVENT]['total_paid'],
          color: Colors.lime
        },
      );

      orderSourceSettledValue.push(
        {
          name: 'System',
          value: aggregate_data.order_source_value[Constants.ORDER_SOURCE.SYSTEM]['total_settled'],
          color: Colors.green
        },
        {
          name: 'Booking',
          value: aggregate_data.order_source_value[Constants.ORDER_SOURCE.BOOKING]['total_settled'],
          color: Colors.yellow
        },
        {
          name: 'Point of Sale',
          value: aggregate_data.order_source_value[Constants.ORDER_SOURCE.POINT_OF_SALES]['total_settled'],
          color: Colors.blue
        },
        {
          name: 'Amendment',
          value: aggregate_data.order_source_value[Constants.ORDER_SOURCE.AMENDMENT]['total_settled'],
          color: Colors.grey
        },
        {
          name: 'External',
          value: aggregate_data.order_source_value[Constants.ORDER_SOURCE.EXTERNAL]['total_settled'],
          color: Colors.orange
        },
        {
          name: 'User',
          value: aggregate_data.order_source_value[Constants.ORDER_SOURCE.USER]['total_settled'],
          color: Colors.greyDark
        },
        {
          name: 'Event',
          value: aggregate_data.order_source_value[Constants.ORDER_SOURCE.EVENT]['total_settled'],
          color: Colors.lime
        },
      );

      paymentSourceValue.push(
        {name: 'Cash', value: aggregate_data.payment_value[Constants.PAYMENT_METHODS.CASH], color: Colors.green},
        {name: 'Card', value: aggregate_data.payment_value[Constants.PAYMENT_METHODS.CARD], color: Colors.blue},
        {
          name: 'Bank Transfer',
          value: aggregate_data.payment_value[Constants.PAYMENT_METHODS.BANK_TRANSFER],
          color: Colors.orange
        },
        {
          name: 'Online Payment',
          value: aggregate_data.payment_value[Constants.PAYMENT_METHODS.ONLINE_PAYMENT],
          color: Colors.lime
        },
        {name: 'Cheque', value: aggregate_data.payment_value[Constants.PAYMENT_METHODS.CHECK], color: Colors.yellow},
        {name: 'Other', value: aggregate_data.payment_value[Constants.PAYMENT_METHODS.OTHER], color: Colors.grey},
      );

      refundSourceValue.push(
        {name: 'Cash', value: aggregate_data.refund_value[Constants.PAYMENT_METHODS.CASH], color: Colors.green},
        {name: 'Card', value: aggregate_data.refund_value[Constants.PAYMENT_METHODS.CARD], color: Colors.blue},
        {
          name: 'Bank Transfer',
          value: aggregate_data.refund_value[Constants.PAYMENT_METHODS.BANK_TRANSFER],
          color: Colors.orange
        },
        {
          name: 'Online Payment',
          value: aggregate_data.refund_value[Constants.PAYMENT_METHODS.ONLINE_PAYMENT],
          color: Colors.lime
        },
        {name: 'Cheque', value: aggregate_data.refund_value[Constants.PAYMENT_METHODS.CHECK], color: Colors.yellow},
        {name: 'Other', value: aggregate_data.refund_value[Constants.PAYMENT_METHODS.OTHER], color: Colors.grey},
      );
    }

    const paymentMenu = [
      {
        name: `Payment (${currency} ${aggregate_data.order_value['total_paid']})`,
        key: 'payment',
      },
      {
        name: `Refund (${currency} ${aggregate_data.order_value['total_refunded']})`,
        key: 'refund',
      },
    ];

    const distributionMenu = [
      {
        name: `Discount (${currency} ${aggregate_data.order_value['discount_total']})`,
        key: 'discount',
      },
      {
        name: `Order Discount (${currency} ${aggregate_data.order_value['order_discount']})`,
        key: 'order-discount',
      },
      {
        name: `No Charge (${currency} ${aggregate_data.order_value['no_charge_total']})`,
        key: 'no-charge',
      },
      {
        name: `Complimentary (${currency} ${aggregate_data.order_value['complimentary_total']})`,
        key: 'complimentary',
      },
    ];

    let paymentData;
    switch(paymentSelectCategory){
    case 'refund':
      paymentData = this.renderPaymentValueMethod(aggregate_data.refund_value, refundSourceValue, 'Refunds', currency, Constants.PAYMENT_METHODS_INPUTS);
      break;
    case 'payment':
    default:
      paymentData = this.renderPaymentValueMethod(aggregate_data.payment_value, paymentSourceValue, 'Payments', currency, Constants.PAYMENT_METHODS_INPUTS);
    }

    let distributionData;
    switch (distributionSelectCategory) {
    case 'no-charge':
      distributionData = this.renderDistribution(orderNoChargeType, aggregate_data.order_value['no_charge_type'], Constants.ORDER_NO_CHARGE_TYPE, 'No Charge', currency);
      break;
    case 'order-discount':
      distributionData =this.renderDistribution(orderDiscountType, aggregate_data.order_value['order_discount_type'], Constants.ORDER_DISCOUNT_TYPE, 'Order Discount', currency);
      break;
    case 'complimentary':
      distributionData =this.renderDistribution(orderComplimentaryType, aggregate_data.order_value['complimentary_type'], Constants.ORDER_COMPLIMENTARY_TYPE, 'Complimentary', currency);
      break;
    case 'discount':
    default:
      distributionData = this.renderDistribution(discountTypeValue, aggregate_data.order_value['discount_type'], Constants.ORDER_DISCOUNT_TYPE, 'Discount', currency);
    }

    return (
      <div className="dashboard screen-container">
        <Helmet>
          <title>Order Analytics</title>
        </Helmet>

        <div className={'row'}>
          <div className={'col-xl-4 col-md-4 col-sm-12 mb-3'}>
            <h6>Order(s)</h6>
            <div className={'card mb-3'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col'}>
                    <small>Paid</small>
                    <h4 className={'card-title mb-0 pb-0 green-cl'}>
                      {aggregate_data.order_count[Constants.ORDER_STATUS.TOTAL_PAID]}
                    </h4>
                  </div>
                  <div className={'col'}>
                    <small>Pending</small>
                    <h4 className={'card-title mb-0 pb-0 green-cl'}>
                      {aggregate_data.order_count[Constants.ORDER_STATUS.NOTPAID]}
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            {!_.isEmpty(orderSourceCount) &&
            <div className={'mb-3'}>
              {this.renderPieChart(orderSourceCount)}
            </div>
            }
          </div>
          <div className={'col-xl-4 col-md-4 col-sm-12 mb-3'}>
            <h6>Revenue</h6>
            <div className={'card mb-3'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col'}>
                    <small>Total Paid</small>
                    <h4 className={'card-title mb-0 pb-0 green-cl'}>
                      {`${currency} ${aggregate_data.order_value['total_paid']}`}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            {!_.isEmpty(orderSourceValue) &&
            <div className={'mb-3'}>
              {this.renderPieChart(orderSourceValue)}
            </div>
            }
          </div>
          <div className={'col-xl-4 col-md-4 col-sm-12 mb-3'}>
            <h6>Settled</h6>
            <div className={'card mb-3'}>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col'}>
                    <small>Total Settled</small>
                    <h4 className={'card-title mb-0 pb-0 green-cl'}>
                      {`${currency} ${aggregate_data.order_value['total_settled']}`}
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            {!_.isEmpty(orderSourceSettledValue) &&
            <div className={'mb-3'}>
              {this.renderPieChart(orderSourceSettledValue)}

            </div>
            }
          </div>
        </div>

        <div className={'row'}>
          <div className={'col-md-12 col-sm-12 mb-3'}>
            <div className="table-responsive border">
              <table className={'table table-bordered table-sm text-center fixed-table'}>
                <thead>
                  <tr>
                    <th className={'text-left data-table-cell-md bg-light'}>
                      Info <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'} size={'sm'}/>
                    </th>
                    <th colSpan={7}>Order Information ({currency})</th>
                    <th colSpan={6}>Payment Information ({currency})</th>
                  </tr>
                  <tr>
                    <th className={'text-left data-table-cell-md bg-light'}>Source</th>
                    <th className={'data-table-cell-sm'}>Sub Total</th>
                    <th className={'data-table-cell-sm'}>Discount</th>
                    <th className={'data-table-cell-sm'}>Comp</th>
                    <th className={'data-table-cell-sm'}>NC</th>
                    <th className={'data-table-cell-sm'}>Tax Total</th>
                    <th className={'data-table-cell-sm small'}>Order Tax</th>
                    <th className={'data-table-cell-sm small'}>Order Discount</th>
                    <th className={'data-table-cell-sm table-info'}>Total</th>
                    <th className={'data-table-cell-sm'}>Pending</th>
                    <th className={'data-table-cell-sm'}>Paid</th>
                    <th className={'data-table-cell-sm'}>Settled</th>
                    <th className={'data-table-cell-sm'}>Refunded</th>
                    <th className={'data-table-cell-sm table-info'}>Net</th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderOrderValueSourceRow(aggregate_data.order_source_value[Constants.ORDER_SOURCE.SYSTEM], 'System')}
                  {this.renderOrderValueSourceRow(aggregate_data.order_source_value[Constants.ORDER_SOURCE.BOOKING], 'Booking')}
                  {this.renderOrderValueSourceRow(aggregate_data.order_source_value[Constants.ORDER_SOURCE.POINT_OF_SALES], 'Point of Sale')}
                  {this.renderOrderValueSourceRow(aggregate_data.order_source_value[Constants.ORDER_SOURCE.AMENDMENT], 'Amendment')}
                  {this.renderOrderValueSourceRow(aggregate_data.order_source_value[Constants.ORDER_SOURCE.USER], 'User')}
                  {this.renderOrderValueSourceRow(aggregate_data.order_source_value[Constants.ORDER_SOURCE.EVENT], 'Event')}
                  {this.renderOrderValueSourceRow(aggregate_data.order_source_value[Constants.ORDER_SOURCE.EXTERNAL], 'External')}
                  {this.renderOrderValueSourceRow(aggregate_data.order_value, 'Total', 'table-info')}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <hr className={'mb-4'}/>
        <div>
          <div className="card text-center">
            <div className="card-header">
              <ul className="nav nav-tabs card-header-tabs">
                {!_.isEmpty(distributionMenu) &&
                distributionMenu.map((data, i) => (
                  <li className="nav-item" key={i}>
                    <div
                      className={`nav-link a-class ${distributionSelectCategory === data.key && 'active'}`}
                      onClick={() => this.setState({distributionSelectCategory: data.key})}
                    >
                      <FontAwesomeIcon
                        icon={distributionSelectCategory === data.key ? faCircle : faCircleNotch} size={'sm'}
                        className={'mr-2'}
                      /> {data.name}
                    </div>
                  </li>
                ))
                }
              </ul>
            </div>
            <div className="card-body">
              {distributionData}
            </div>
          </div>
        </div>

        <hr className={'mb-4'}/>
        <div>
          <div className="card text-center">
            <div className="card-header">
              <ul className="nav nav-tabs card-header-tabs">
                {!_.isEmpty(paymentMenu) &&
                paymentMenu.map((data, i) => (
                  <li className="nav-item" key={i}>
                    <div
                      className={`nav-link a-class ${paymentSelectCategory === data.key && 'active'}`}
                      onClick={() => this.setState({paymentSelectCategory: data.key})}
                    >
                      <FontAwesomeIcon
                        icon={paymentSelectCategory === data.key ? faCircle : faCircleNotch} size={'sm'}
                        className={'mr-2'}
                      /> {data.name}
                    </div>
                  </li>
                ))
                }
              </ul>
            </div>
            <div className="card-body">
              {paymentData}
            </div>
          </div>
        </div>

        {(!_.isEmpty(tax_classes) && !_.isEmpty(aggregate_data.tax_expanded)) &&
        <React.Fragment>
          <hr className={'mb-4'}/>
          <div className={'row'}>
            <div className={'col-md-12 col-sm-12 mb-3'}>
              <h4 className={'text-left'}>Tax Classes</h4>
              <div className="table-responsive border">
                <table className={'table table-bordered table-sm text-center fixed-table'}>
                  <thead>
                    <tr>
                      <th className={'data-table-cell-lg bg-light'}>
                        Tax Classes <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'} size={'sm'}/>
                      </th>
                      {tax_classes.map((data, i)=>(
                        <th className={'data-table-cell-md'} key={i}>{data.name}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className={'bg-light'}>Value ({currency})</td>
                      {aggregate_data.tax_expanded.map((data,i)=>(
                        <td key={i}>{data['total']}</td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </React.Fragment>
        }
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loading_inner: false,
      error: null,
      distributionSelectCategory: 'discount',
      paymentSelectCategory: 'payment'
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderAnalyticsOverView);
