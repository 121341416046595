/**
 *
 * Payment Terminal Settings
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

// Consts and Libs
import AppAPI from '../../../lib/api';

// Components
import {History} from '../../../components/ui';
import Error from '../../../components/general/Error';
import Loading from '../../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PageHeader from '../../../components/dashboard/PageHeader';
import {faCheckCircle, faEdit, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as PropertyActions from '../../../redux/property/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  taxclass: state.property.taxclass,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPropertyTax: PropertyActions.setPropertyTax,
};

/* Component ==================================================================== */
class PaymentTerminalSettings extends Component {
  static componentName = 'PaymentTerminalSettings';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    AppAPI.propertyapi.get(`payment-terminal/${this.props.match.params.paymentTerminalId}/`)
      .then((res) => {
        this.setState({
          paymentTerminal: res,
          loading: false
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };

  render = () => {

    const {loading, error, paymentTerminal} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    return (
      <div className="room-type-details screen-container ">
        <Helmet>
          <title>Payment Terminal</title>
        </Helmet>


        <PageHeader
          subHeader={true}
          history={this.props.history}
          title={'Payment Terminal Details'}
          description={`Payment terminal reference no: ${paymentTerminal.ref_no}`}
        />


        <div className={'row'}>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className={'row'}>
                  <div className={'col-12 col-md-6 mb-3'}>
                    <ul className="list-group">
                      <li className="list-group-item">
                        <strong>Reference</strong> : {paymentTerminal.ref_no}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={'row'}>
                  <div className={'col-12 col-md-6 mb-3'}>
                    <ul className="list-group">
                      <li className="list-group-item"><strong>Name</strong> : {paymentTerminal.name}</li>
                      <li className="list-group-item">
                        <strong>Payment Method</strong> : {paymentTerminal.payment_method_display}
                      </li>
                      <li className="list-group-item">
                        <strong>Active</strong> :
                        <FontAwesomeIcon
                          icon={paymentTerminal.active ? faCheckCircle : faTimesCircle}
                          className={`ml-2 ${paymentTerminal.active ? 'green-cl' : 'grey-cl'}`}
                        />
                      </li>
                    </ul>
                  </div>
                  <div className={'col-12 col-md-6 mb-3'}>
                    <ul className="list-group">
                      <li className="list-group-item">
                        <strong>Payment Allowed</strong> :
                        <FontAwesomeIcon
                          icon={paymentTerminal.payment_allowed ? faCheckCircle : faTimesCircle}
                          className={`ml-2 ${paymentTerminal.payment_allowed ? 'green-cl' : 'grey-cl'}`}
                        />
                      </li>
                      <li className="list-group-item">
                        <strong>Refund Allowed</strong> :
                        <FontAwesomeIcon
                          icon={paymentTerminal.refund_allowed ? faCheckCircle : faTimesCircle}
                          className={`ml-2 ${paymentTerminal.refund_allowed ? 'green-cl' : 'grey-cl'}`}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={'row'}>
                  <div className={'col-12 col-md-12 mb-3'}>
                    <ul className="list-group">
                      <li className="list-group-item">
                        <strong>Short Description</strong> : {paymentTerminal.short_description || 'N.A'}
                      </li>
                      <li className="list-group-item">
                        <strong>Description</strong> : {paymentTerminal.description || 'N.A'}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <History object={paymentTerminal}/>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item">
                <Link
                  to={`/settings/property/payment-settings/payment-terminal/${paymentTerminal.id}/edit`}
                  className="btn btn-link m-0 p-0"
                >
                  <FontAwesomeIcon icon={faEdit} size={'sm'} className={'mr-2'}/> Edit
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      paymentTerminal: {},
      loading: true,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTerminalSettings);